/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PeriodDtoModel")
export class PeriodDtoModel {
  @JsonProperty("numDays", Number, true)
  numDays?: number = undefined;

  @JsonProperty("numMonths", Number, true)
  numMonths?: number = undefined;

  @JsonProperty("numWeeks", Number, true)
  numWeeks?: number = undefined;

  @JsonProperty("numYears", Number, true)
  numYears?: number = undefined;
}
