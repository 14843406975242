<div style="position: relative" (resized)="onResized($event)">
  <ngx-datatable
    #itlsDynamicTable
    class="table table-hover dynamic-table-provision"
    [rows]="rows"
    [columnMode]="columnModeSetting"
    [footerHeight]="50"
    [scrollbarH]="true"
    [headerHeight]="70"
    [limit]="300"
    [rowHeight]="'auto'"
  >
    <ng-container *ngFor="let col of columns; let columnIndex = index">
      <ngx-datatable-column
        [name]="col.name"
        [canAutoResize]="true"
        *ngIf="col.type == 'BUTTONS'"
        [sortable]="false"
        [maxWidth]="20"
        [prop]="col.prop"
      >
        <ng-template ngx-datatable-header-template let-value="value">
        </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-id="id"
          let-value="value"
          let-rowIndex="rowIndex"
        >
          <div
            class="btn-group mr-3"
            container="body"
            *ngIf="editable && rowIndex !== 0"
          >
            <button
              id="button-split"
              type="button"
              [matMenuTriggerFor]="menu"
              style="padding: 7px; border: none"
              class="action-btn btn btn-white dropdown-toggle-split"
            >
              <span class="fa fa-ellipsis-v"></span>
              <span class="sr-only">Split button!</span>
            </button>
            <mat-menu #menu="matMenu">
              <button
                (click)="onChangeTable('ADD', 'RB', rowIndex)"
                mat-menu-item
              >
                <i class="fa fa-plus-circle mr-2"></i>
                <span>Add Row Below</span>
              </button>
              <button
                mat-menu-item
                (click)="onChangeTable('ADD', 'RT', rowIndex)"
              >
                <i class="fa fa-plus-circle mr-2"></i>
                <span>Add Row Top</span>
              </button>
              <button
                mat-menu-item
                *ngIf="minimumRows <= 0 || rows.length > minimumRows"
                (click)="onChangeTable('REMOVE', 'RB', rowIndex)"
              >
                <i class="fa fa-remove mr-2"></i>
                <span>Remove Row Below</span>
              </button>
              <button
                mat-menu-item
                *ngIf="minimumRows <= 0 || rows.length > minimumRows"
                (click)="onChangeTable('REMOVE', 'RT', rowIndex)"
              >
                <i class="fa fa-remove mr-2"></i>
                <span>Remove Row Top</span>
              </button>
              <button
                mat-menu-item
                *ngIf="minimumRows <= 0 || rows.length > minimumRows"
                (click)="onChangeTable('REMOVE', 'SELF_ROW', rowIndex)"
              >
                <i class="fa fa-remove mr-2"></i>
                <span>Remove</span>
              </button>
            </mat-menu>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [name]="col.name"
        *ngIf="col.type !== 'BUTTONS'"
        [prop]="col.prop"
        [sortable]="false"
        [headerClass]="'pl-4'"
      >
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-rowIndex="rowIndex"
          let-row="row"
        >
          <div
            class="btn-group mr-3"
            container="body"
            style="
              margin-right: 0 !important;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 8px;
            "
          >
            <div
              class="btn-group M1"
              container="body"
              *ngIf="editable && rows?.length === 1 && columnIndex === 1"
            >
              <button
                id="button-split4"
                type="button"
                [matMenuTriggerFor]="menu"
                style="padding: 7px; border: none"
                class="action-btn btn btn-white dropdown-toggle-split"
              >
                <span class="fa fa-ellipsis-v"></span>
                <span class="sr-only">Split button!</span>
              </button>
              <mat-menu
                id="dropdowns-split4"
                #menu="matMenu"
                class="dropdown-menu"
              >
                <button mat-menu-item (click)="onChangeTable('ADD', 'RB', 0)">
                  <i class="fa fa-plus-circle mr-2"></i>
                  <span>Add Row Below</span>
                </button>
              </mat-menu>
            </div>
            <!--<span>{{col.name}}</span>-->
            <textarea
              type="text"
              *ngIf="col.prop"
              class="form-control"
              style="
                font-weight: bold;
                font-size: 11px;
                border-color: #999999b8;
              "
              (blur)="onCellBlur(rows[0][col.prop])"
              (focus)="onCellFocus(rows[0][col.prop])"
              [(ngModel)]="rows[0][col.prop]"
              [value]="value"
              [disabled]="!editable"
            >
            </textarea>
            <div *ngIf="editable">
              <button
                style="border: none; padding: 10px 10px 5px 9px"
                id="button-split3"
                type="button"
                [matMenuTriggerFor]="menu"
                class="action-btn btn btn-white dropdown-toggle-split"
                aria-controls="dropdown-split2"
              >
                <span class="fa fa-ellipsis-v"></span>
                <span class="sr-only">Split button!</span>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  (click)="onChangeTable('ADD', 'CL', columnIndex)"
                >
                  <i class="fa fa-plus-circle mr-2"></i>
                  <span>Add Column Left</span>
                </button>
                <button
                  mat-menu-item
                  (click)="onChangeTable('ADD', 'CR', columnIndex)"
                >
                  <i class="fa fa-plus-circle mr-2"></i>
                  <span>Add Column Right</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="minimumColumns <= 0 || columns.length > minimumColumns"
                  (click)="onChangeTable('REMOVE', 'CR', columnIndex)"
                >
                  <i class="fa fa-remove mr-2"></i>
                  <span>Remove Column Right</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="minimumColumns <= 0 || columns.length > minimumColumns"
                  (click)="onChangeTable('REMOVE', 'CL', columnIndex)"
                >
                  <i class="fa fa-remove mr-2"></i>
                  <span>Remove Column Left</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="minimumColumns <= 0 || columns.length > minimumColumns"
                  (click)="onChangeTable('REMOVE', 'SELF_COLUMN', columnIndex)"
                >
                  <i class="fa fa-remove mr-2"></i>
                  <span>Remove</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </ng-template>
        <ng-template
          let-value="value"
          let-rowIndex="rowIndex"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="rowIndex !== 0">
            <textarea
              type="text"
              style="font-size: 10px"
              class="form-control"
              (blur)="onCellBlur(rows[rowIndex][col.prop])"
              (focus)="onCellFocus(rows[rowIndex][col.prop])"
              [(ngModel)]="rows[rowIndex][col.prop]"
              [value]="value"
              [disabled]="!editable"
            >
            </textarea>
          </span>
        </ng-template>
      </ngx-datatable-column>
    </ng-container>
  </ngx-datatable>
</div>
