import { Injectable } from "@angular/core";

import { select, Store } from "@ngrx/store";

import { CalendarPartialState, CalendarState } from "./calendar.reducer";
import { calendarQuery } from "./calendar.selectors";
import {
  CleanCalendarState,
  DeleteMultipleCalendar,
  DeleteSelectedCalendar,
  DeleteSelectedCalendarQuery,
  LoadCalendar,
  LoadCalendarDisplayableFields,
  LoadCalendarFilterFields,
  LoadCalendarQueries,
  LoadCalendars,
  LoadSelectedCalendar,
  RunCalendarQuery,
  SaveCalendar,
  SaveCalendarQuery,
  SaveSelectedCalendarField,
  SaveSelectedCalendarFilters,
  SearchCalendarFinalAbstracts,
  UpdateCalendar,
  UpdateCalendarQuery,
  UpdateSelectedCalendarQueryActive,
} from "./calendar.actions";
import { Observable } from "rxjs";

@Injectable()
export class CalendarFacade {
  loaded$ = this.store.pipe(select(calendarQuery.getLoaded));
  allCalendar$ = this.store.pipe(select(calendarQuery.getAllCalendar));
  selectedCalendar$ = this.store.pipe(
    select(calendarQuery.getSelectedCalendar)
  );
  calendarFilterFieldsLoaded$ = this.store.pipe(
    select(calendarQuery.getCalendarFilterFieldsLoaded)
  );
  calendarFilterFields$ = this.store.pipe(
    select(calendarQuery.getCalendarFilterFields)
  );
  calendarFilterFieldsLoadError$ = this.store.pipe(
    select(calendarQuery.getCalendarFilterFieldsLoadError)
  );
  provisionsOperator$ = this.store.pipe(
    select(calendarQuery.getProvisionsOperator)
  );
  groupedCalendarFilterFields$: Observable<any[]> = this.store.pipe(
    select(calendarQuery.getGroupedCalendarFilterFields)
  );

  calendarDisplayableFieldsLoaded$ = this.store.pipe(
    select(calendarQuery.getCalendarDisplayableFieldsLoaded)
  );
  calendarDisplayableFields$ = this.store.pipe(
    select(calendarQuery.getCalendarDisplayableFields)
  );
  calendarDisplayableFieldsLoadError$ = this.store.pipe(
    select(calendarQuery.getCalendarDisplayableFieldsLoadError)
  );
  groupedCalendarDisplayableFieldsList$: Observable<any[]> = this.store.pipe(
    select(calendarQuery.getGroupedCalendarDisplayableFieldsList)
  );

  searchCalendarFinalAbstractsResultLoaded$ = this.store.pipe(
    select(calendarQuery.getSearchCalendarFinalAbstractsResultLoaded)
  );
  isSearchCalendarFinalAbstractsLoading$ = this.store.pipe(
    select(calendarQuery.isSearchCalendarFinalAbstractsLoading)
  );
  searchCalendarFinalAbstractsResult$ = this.store.pipe(
    select(calendarQuery.getSearchCalendarFinalAbstractsResult)
  );
  searchCalendarFinalAbstractsResultMap$ = this.store.pipe(
    select(calendarQuery.searchCalendarFinalAbstractsResultMap)
  );
  searchCalendarFinalAbstractsResultLoadError$ = this.store.pipe(
    select(calendarQuery.getSearchCalendarFinalAbstractsResultLoadError)
  );

  selectedCalendarFilters$ = this.store.pipe(
    select(calendarQuery.getSelectedCalendarFilters)
  );
  selectedCalendarFields$ = this.store.pipe(
    select(calendarQuery.getSelectedCalendarFields)
  );

  isLoadCalendarsLoading$ = this.store.pipe(
    select(calendarQuery.isLoadCalendarsLoading)
  );
  calendarsLoaded$ = this.store.pipe(select(calendarQuery.calendarsLoaded));
  getCalendarList$ = this.store.pipe(select(calendarQuery.getCalendarList));

  isSaveCalendarPending$ = this.store.pipe(
    select(calendarQuery.isSaveCalendarPending)
  );
  saveCalendarSucceeded$ = this.store.pipe(
    select(calendarQuery.saveCalendarSucceeded)
  );

  isSelectedCalendarDetailLoading$ = this.store.pipe(
    select(calendarQuery.isSelectedCalendarDetailLoading)
  );
  isSelectedCalendarLoaded$ = this.store.pipe(
    select(calendarQuery.isSelectedCalendarLoaded)
  );
  isSelectedCalendarLoadError$ = this.store.pipe(
    select(calendarQuery.isSelectedCalendarLoadError)
  );
  getSelectedCalendarDetail$ = this.store.pipe(
    select(calendarQuery.getSelectedCalendarDetail)
  );
  getCalendarsDetailMap$ = this.store.pipe(
    select(calendarQuery.calendarsDetailMap)
  );

  isDeleteSelectedCalendarPending$ = this.store.pipe(
    select(calendarQuery.isDeleteSelectedCalendarPending)
  );
  isDeleteSelectedCalendarSucceeded$ = this.store.pipe(
    select(calendarQuery.isDeleteSelectedCalendarSucceeded)
  );

  isSaveCalendarQueryPending$ = this.store.pipe(
    select(calendarQuery.isSaveCalendarQueryPending)
  );
  isSaveCalendarQuerySucceeded$ = this.store.pipe(
    select(calendarQuery.isSaveCalendarQuerySucceeded)
  );
  isLoadCalendarQueriesPending$ = this.store.pipe(
    select(calendarQuery.isLoadCalendarQueriesPending)
  );
  isCalendarQueriesLoaded$ = this.store.pipe(
    select(calendarQuery.isCalendarQueriesLoaded)
  );
  getSelectedCalendarQueries$ = this.store.pipe(
    select(calendarQuery.getSelectedCalendarQueries)
  );
  isDeleteSelectedCalendarQueryPending$ = this.store.pipe(
    select(calendarQuery.isDeleteSelectedCalendarQueryPending)
  );
  isDeleteSelectedCalendarQuerySucceeded$ = this.store.pipe(
    select(calendarQuery.isDeleteSelectedCalendarQuerySucceeded)
  );
  isUpdateSelectedCalendarQueryActivePending$ = this.store.pipe(
    select(calendarQuery.isUpdateSelectedCalendarQueryActivePending)
  );
  isUpdateSelectedCalendarQueryActiveSucceeded$ = this.store.pipe(
    select(calendarQuery.isUpdateSelectedCalendarQueryActiveSucceeded)
  );
  isUpdateCalendarQueryPending$ = this.store.pipe(
    select(calendarQuery.isUpdateCalendarQueryPending)
  );
  isUpdateCalendarQuerySucceeded$ = this.store.pipe(
    select(calendarQuery.isUpdateCalendarQuerySucceeded)
  );

  isDeleteMultipleCalendarPending$ = this.store.pipe(
    select(calendarQuery.isDeleteMultipleCalendarPending)
  );
  deleteMultipleCalendarSucceeded$ = this.store.pipe(
    select(calendarQuery.deleteMultipleCalendarSucceeded)
  );

  isUpdateCalendarPending$ = this.store.pipe(
    select(calendarQuery.isUpdateCalendarPending)
  );
  updateCalendarSucceeded$ = this.store.pipe(
    select(calendarQuery.updateCalendarSucceeded)
  );

  constructor(private store: Store<CalendarPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadCalendar());
  }

  loadCalendarFilterFields() {
    this.store.dispatch(new LoadCalendarFilterFields());
  }

  loadCalendarDisplayableFields() {
    this.store.dispatch(new LoadCalendarDisplayableFields());
  }

  searchCalendarFinalAbstracts(
    payload,
    calendarQueryUid?: string,
    selectedInstanceId?: string,
    dashboardUid?: string,
    dateRange?: { start: string; end: string }
  ) {
    this.store.dispatch(
      new SearchCalendarFinalAbstracts(
        payload,
        calendarQueryUid,
        selectedInstanceId,
        dashboardUid,
        dateRange
      )
    );
  }

  saveSelectedCalendarFilters(payload) {
    this.store.dispatch(new SaveSelectedCalendarFilters(payload));
  }

  saveSelectedCalendarField(payload) {
    this.store.dispatch(new SaveSelectedCalendarField(payload));
  }

  loadCalendars(payload) {
    this.store.dispatch(new LoadCalendars(payload));
  }

  saveCalendar(payload) {
    this.store.dispatch(new SaveCalendar(payload));
  }

  updateCalendar(payload: { calendarUid: string; data: any }) {
    this.store.dispatch(new UpdateCalendar(payload));
  }

  cleanCalendarState(payload: CalendarState) {
    this.store.dispatch(new CleanCalendarState(payload));
  }

  loadSelectedCalendar(payload, selectedInstanceId: string) {
    this.store.dispatch(new LoadSelectedCalendar(payload, selectedInstanceId));
  }

  deleteSelectedCalendar(calendarUid: string) {
    this.store.dispatch(new DeleteSelectedCalendar(calendarUid));
  }

  saveCalendarQuery(calendarUid: string, payload) {
    this.store.dispatch(new SaveCalendarQuery(calendarUid, payload));
  }

  updateCalendarQuery(calendarUid: string, calendarQueryUid: string, payload) {
    this.store.dispatch(
      new UpdateCalendarQuery(calendarUid, calendarQueryUid, payload)
    );
  }

  loadCalendarQueries(calendarUid: string) {
    this.store.dispatch(new LoadCalendarQueries(calendarUid));
  }

  deleteSelectedCalendarQuery(calendarUid: string, calendarQueryUid: string) {
    this.store.dispatch(
      new DeleteSelectedCalendarQuery(calendarUid, calendarQueryUid)
    );
  }

  updateSelectedCalendarQueryActive(
    calendarUid: string,
    calendarQueryUid: string,
    payload: { active: boolean }
  ) {
    this.store.dispatch(
      new UpdateSelectedCalendarQueryActive(
        calendarUid,
        calendarQueryUid,
        payload
      )
    );
  }

  runCalendarQuery(payload: any) {
    this.store.dispatch(new RunCalendarQuery(payload));
  }

  deleteMultipleCalendar(payload) {
    this.store.dispatch(new DeleteMultipleCalendar(payload));
  }
}
