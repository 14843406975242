/* tslint:disable */
/* eslint-disable */
import { FinalProcessingRequestDtoModel } from "./final-processing-request-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqFinalProcessingRequestDtoModel")
export class OApiReqFinalProcessingRequestDtoModel {
  @JsonProperty("data", FinalProcessingRequestDtoModel, true)
  data?: FinalProcessingRequestDtoModel = undefined;
}
