<div
  style="position: relative; height: 100%; min-height: 215px"
  (click)="$event.stopPropagation()"
>
  <mat-progress-spinner
    diameter="50"
    mode="indeterminate"
    style="position: absolute; top: 42%; left: 45%"
    *ngIf="(calendarFacade.calendarFilterFieldsLoaded$ | async) !== true"
  ></mat-progress-spinner>
  <ng-container *ngIf="calendarFacade.calendarFilterFieldsLoaded$ | async">
    <div class="p-8" style="margin: 0 1%">
      <mat-form-field style="margin-bottom: -18px">
        <mat-label>Add field</mat-label>
        <input
          type="text"
          matInput
          [formControl]="calendarColumnControl"
          itlsFocusIn
          [matAutocomplete]="calendarFields"
        />
        <mat-autocomplete #calendarFields="matAutocomplete">
          <mat-option
            *ngFor="let field of filterFieldsOptions | async"
            [value]="field.uiName"
            (onSelectionChange)="onFilterFieldOptionSelected(field)"
            [matTooltip]="field.uiName"
            [matTooltipDisabled]="field?.uiName?.length < 45"
            [id]="field.uiName"
          >
            {{ field.uiName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="filter-fields-list">
        <ng-scrollbar style="max-height: 100%">
          <div class="main-body">
            <mat-list style="padding-top: 0">
              <ng-container *ngIf="selectedFilterFields.length">
                <mat-list-item
                  class="my-1 column-list-item"
                  *ngFor="let field of selectedFilterFields; let i = index"
                  style="border-radius: 5px"
                >
                  <button
                    style="
                      width: 30px;
                      height: 30px;
                      line-height: 30px;
                      margin-right: 2px;
                    "
                    mat-icon-button
                    matTooltip="Remove"
                    (click)="onRemoveFilterFields(i)"
                  >
                    <mat-icon
                      style="font-size: 13px"
                      mat-list-icon
                      svgIcon="icon-remove"
                    ></mat-icon>
                  </button>
                  <p
                    mat-line
                    [matTooltip]="field?.uiName"
                    [matTooltipDisabled]="field?.uiName?.length < 45"
                  >
                    {{ field?.uiName | shorten: 45:"..." }}
                  </p>
                </mat-list-item>
              </ng-container>
              <ng-container *ngIf="selectedFilterFields.length === 0">
                <mat-list-item
                  class="my-1 column-list-item empty"
                  style="border-radius: 5px"
                >
                  No column found.
                </mat-list-item>
              </ng-container>
            </mat-list>
          </div>
        </ng-scrollbar>
      </div>
    </div>
  </ng-container>
</div>
