import { JsonObject, JsonProperty } from "json2typescript";
import { DocSetCategoryModel } from "@common/models";
import { LbxRoleDto } from "./lbx-role-type.model";

@JsonObject("ServerConfigsModel")
export class ServerConfigsModel {
  @JsonProperty("allDocSetCategories", [DocSetCategoryModel])
  allDocSetCategories: DocSetCategoryModel[] = [];

  @JsonProperty("allLbxRoles", [LbxRoleDto])
  allLbxRoles: LbxRoleDto[] = [];
}
