import { DeleteApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DeleteProvisionValueService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly PROVISIONS_URL = "/provisions";
  private static readonly VALUES_URL = "/values";

  constructor(private deleteApiService: DeleteApiService) {}

  /**
   * When user clicks on "X" in small menu for a highlighted provision value, then call this function.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value the user is deleting
   * @param provisionValueUid - the provision value option id to delete
   * @return response from server
   */
  deleteProvisionValueNoView(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string
  ): Observable<ServerResponseModel> {
    return this.deleteApiService.sendRequestNoView(
      DeleteProvisionValueService.API_VERSION_1,
      `${DeleteProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}` +
        `${DeleteProvisionValueService.PROVISIONS_URL}/${provisionUid}${DeleteProvisionValueService.VALUES_URL}`,
      provisionValueUid
      // {
      //   subProvisionsPath: [provisionValueUid]
      // }
    );
  }
}
