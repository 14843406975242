/* tslint:disable */
/* eslint-disable */
import { UniquePortfolioIdModel } from "./unique-portfolio-id-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UniquePropertyIdModel")
export class UniquePropertyIdModel {
  @JsonProperty("property", String, true)
  property?: string = "";

  @JsonProperty("uniquePortfolioId", UniquePortfolioIdModel, true)
  uniquePortfolioId?: UniquePortfolioIdModel = undefined;
}
