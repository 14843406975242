import {
  Any,
  JsonConvert,
  JsonConverter,
  JsonCustomConvert,
  OperationMode,
} from "json2typescript";
import { cloneDeep } from "lodash";
import { DateModel } from "../models";
import { Json2TypescriptHelper } from "../../intelease/utils";
import { DateTimeConvertService } from "./date-time-convert.service";
import { DateConvertService } from "./date-convert.service";

@JsonConverter
export class FilterValueConvertService implements JsonCustomConvert<any> {
  jsonConvert: JsonConvert = new JsonConvert();

  constructor() {
    this.jsonConvert.operationMode = OperationMode.LOGGING;
  }

  serialize(data): any {}

  deserialize(data: any) {
    return this.deSerializeFilter(data);
  }

  private deSerializeFilter(data) {
    const colList = ["fieldName", "uiName", "fieldType"];
    if (data && data.operator) {
      data.value = data.value.map((item) => {
        if (!item.operator) {
          const { field, value } = item;
          const colObj: any = {};
          field.split("::").forEach((_item, index) => {
            colObj[colList[index]] = _item;
          });
          return {
            ...item,
            value: this.deserializeFilterValue(value, colObj.fieldType),
          };
        } else {
          return this.deSerializeFilter(cloneDeep(item));
        }
      });
    }
    return data;
  }

  private deserializeFilterValue(value, type) {
    if (type === "LOCAL_DATE") {
      return DateConvertService.deserializeDate(value);
    } else if (type === "DATE") {
      return DateTimeConvertService.deserializeDateTime(value);
    }
    return value;
  }
}
