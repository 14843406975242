export enum QUEUE_STATUS {
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
  IN_REVIEW = "IN_REVIEW",
  READY = "READY",
  PROCESSING = "PROCESSING",
  IN_QA_REVIEW = "IN_QA_REVIEW",
  NEED_QA_REVIEW = "NEED_QA_REVIEW",
}

export const TranslateQueueStage = {
  FAILED: "Failed",
  COMPLETED: "Completed",
  IN_REVIEW: "In Review",
  READY: "Ready",
  PROCESSING: "Processing",
  IN_QA_REVIEW: "In QA Review",
  NEED_QA_REVIEW: "Need QA Review",
};
