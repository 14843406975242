<div class="itls-filter-group">
  <ng-container *ngFor="let item of filter.value; let filterIndex = index">
    <ng-container
      *ngIf="item.operator && item.value.length > 0; else filterItem"
    >
      <div class="filter-group-item-container" style="align-items: flex-start">
        <div class="filter-group-item-container">
          <div *ngIf="filterIndex == 0" class="item">Where</div>
          <mat-form-field
            *ngIf="filterIndex == 1"
            class="item"
            style="width: 60px !important; min-width: 60px"
          >
            <mat-select
              [(value)]="filter.operator"
              (selectionChange)="onFilterOperationChange()"
            >
              <mat-option
                *ngFor="let operation of operations"
                [value]="operation.value"
              >
                {{ operation.viewValue | ucfirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="item" *ngIf="filterIndex > 1">
            {{ filter.operator | ucfirst }}
          </div>
        </div>
        <div
          style="
            margin: 4px 6px;
            padding: 4px;
            border-radius: 3px;
            box-shadow: rgba(55, 53, 47, 0.1) 0 0 0 1px;
          "
          [style.backgroundColor]="colors[level]"
        >
          <il-filter-group
            [level]="level + 1"
            [filter]="item"
            [filterFields]="filterFields"
            [filterOperators]="filterOperators"
            (modelChange)="onFilterGroupModelChange($event)"
          >
          </il-filter-group>
        </div>
        <button
          mat-icon-button
          [matMenuTriggerFor]="moreMenu"
          class="more-options"
        >
          <mat-icon
            svgIcon="icon-more"
            style="
              width: 16px;
              height: 16px;
              min-height: 16px;
              min-width: 16px;
              line-height: 16px;
            "
          ></mat-icon>
        </button>
        <mat-menu #moreMenu="matMenu" class="itls-filter-menu">
          <button
            mat-menu-item
            class="icon-btn"
            (click)="onRemoveClick(filterIndex)"
          >
            <span>Remove</span>
          </button>
        </mat-menu>
      </div>
    </ng-container>
    <ng-template #filterItem>
      <div class="filter-group-item-container">
        <div *ngIf="filterIndex == 0" class="item">Where</div>
        <mat-form-field
          *ngIf="filterIndex == 1"
          class="item"
          style="width: 60px !important; min-width: 60px"
        >
          <mat-select
            [(value)]="filter.operator"
            (selectionChange)="onFilterOperationChange()"
          >
            <mat-option
              *ngFor="let operation of operations"
              [value]="operation.value"
            >
              {{ operation.viewValue | ucfirst }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="item" *ngIf="filterIndex > 1">
          {{ filter.operator | ucfirst }}
        </div>
        <il-filter-item
          [filterFields]="filterFields"
          [filterOperators]="filterOperators"
          [filter]="item"
          (filterModelChange)="onFilterItemModelChange($event)"
          (remove)="onRemoveFilter($event, filterIndex)"
        >
        </il-filter-item>
      </div>
    </ng-template>
  </ng-container>
  <mat-form-field
    appearance="outline"
    class="add-filter"
    *ngIf="filter.value.length > 0 || level === 1"
  >
    <itls-icon svgIcon="icon-add" style="margin-right: 7px"></itls-icon>
    <mat-select value="NEW_FILTER">
      <mat-option value="NEW_FILTER" (click)="onAddNewFilter()">
        <itls-icon
          [height]="14"
          svgIcon="icon-add"
          style="margin-right: 7px"
        ></itls-icon>
        Add a filter
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
