/* tslint:disable */
/* eslint-disable */
import { ErrorDataModel } from "./error-data-model";
import { OApiMultiEntityContainerResponseIPermissionTypeModel } from "./o-api-multi-entity-container-response-i-permission-type-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiRespMultiIPermissionTypeModel")
export class OApiRespMultiIPermissionTypeModel {
  @JsonProperty(
    "data",
    OApiMultiEntityContainerResponseIPermissionTypeModel,
    true
  )
  data?: OApiMultiEntityContainerResponseIPermissionTypeModel = undefined;

  @JsonProperty("devErrors", [ErrorDataModel], true)
  devErrors?: Array<ErrorDataModel> = [];

  @JsonProperty("errors", [ErrorDataModel], true)
  errors?: Array<ErrorDataModel> = [];

  @JsonProperty("message", String, true)
  message?: string = "";
}
