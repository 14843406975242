import { cloneDeep, keyBy } from "lodash";

export enum IMentionElementType {
  TEXT = "TEXT",
  USER = "USER",
}

interface InteleaseMentionInterface {
  id: string;
  display: string;
  markup: string;
}

interface IMention {
  uid: string;
  email: string;
  fullName: string;
}

interface IMentionTextElement {
  text: string;
}

interface IMentionUserElement {
  userUid: string;
}

export interface IMentionElement {
  type: IMentionElementType;
  data: IMentionTextElement | IMentionUserElement;
}

export interface IntelFullMention {
  elements: IMentionElement[];
  mentions: IMention[];
}

export class InteleaseMentionHelper {
  private static readonly mentionsRegExp: RegExp = /(@\[(.*?)\])(\((.*?)\))/g;
  private static readonly idRegExp: RegExp = /\((.*?)\)/;
  private static readonly displayRegExp: RegExp = /(\[(.*?)\])/;
  private sampleMention = "@[ali+2@xgdigitalsolutions.com](ali)";

  // public static serialize() {}

  public static deserialize(text: string): InteleaseMentionInterface[] {
    const mentions: InteleaseMentionInterface[] = [];
    const matchedMentions: string[] =
      InteleaseMentionHelper.findMentionsInText(text) || [];
    matchedMentions.forEach((mention) => {
      mentions.push(
        InteleaseMentionHelper.extractIdAndDisplayFromMention(mention)
      );
    });
    return mentions;
  }

  public static findMentionsInText(text: string): string[] {
    return text.match(InteleaseMentionHelper.mentionsRegExp);
  }

  public static extractIdAndDisplayFromMention(
    mention: string
  ): InteleaseMentionInterface {
    return {
      id: mention.match(InteleaseMentionHelper.idRegExp)[1],
      display: mention.match(InteleaseMentionHelper.displayRegExp)[2],
      markup: mention,
    };
  }

  public static transformMentionsToLink(text: string): string {
    let _text = cloneDeep(text);
    const mentions = InteleaseMentionHelper.deserialize(_text);
    mentions.forEach((mention) => {
      const { id, display } = mention;
      _text = _text.replace(
        mention.markup,
        InteleaseMentionHelper.createMentionLink(id, display)
      );
    });
    return _text;
  }

  public static createMentionLink(id: string, display: string): string {
    return `<a href="mailto:${id}" title="${id}">${display}</a>`;
  }

  public static deserializeMention(
    element: Element,
    mentionList: { key: string; value: string; email: string }[]
  ): IntelFullMention {
    const fullMention: IntelFullMention = { elements: [], mentions: [] };
    const usersMap = keyBy(mentionList, "key");
    element.childNodes.forEach((item: HTMLElement) => {
      if (
        item.nodeType === 1 &&
        item.id &&
        usersMap[item.id] &&
        usersMap[item.id].key
      ) {
        //nodeType is element
        fullMention.elements.push({
          type: IMentionElementType.USER,
          data: {
            userUid: item.id,
          },
        });
        fullMention.mentions.push({
          uid: usersMap[item.id].key,
          email: usersMap[item.id].email,
          fullName: usersMap[item.id].value,
        });
      } else if (
        (item.nodeType === 1 && item.nodeName === "DIV") ||
        (item.nodeType === 1 && item.nodeName === "BR")
      ) {
        fullMention.elements.push({
          type: IMentionElementType.TEXT,
          data: {
            text: item.innerHTML ? `${item.innerHTML}\n` : "\n",
          },
        });
      } else if (item.nodeType === 3) {
        //nodeType is text
        fullMention.elements.push({
          type: IMentionElementType.TEXT,
          data: {
            text: item.nodeValue || "",
          },
        });
      } else {
        fullMention.elements.push({
          type: IMentionElementType.TEXT,
          data: {
            text: item.nodeValue || "",
          },
        });
      }
    });
    return fullMention;
  }

  public static serializeMention(fullMention: IntelFullMention): string {
    let mentionText = "";
    const mentionsMap = keyBy(fullMention.mentions, "uid");
    fullMention.elements.forEach((item) => {
      const { type } = item;
      if (type === IMentionElementType.TEXT) {
        const data: IMentionTextElement = item.data as IMentionTextElement;
        //nodeType is text
        if (
          data.text &&
          (data.text.startsWith("\n") || data.text.endsWith("\n"))
        ) {
          if ((data.text.match(/\n/g) || []).length > 1) {
            data.text = data.text.replace("\n", "");
          }
          mentionText += `<div>${data.text}</div>`;
        } else {
          mentionText += data.text;
        }
      } else if (type === IMentionElementType.USER) {
        const data: IMentionUserElement = item.data as IMentionUserElement;
        //nodeType is element
        mentionText += `<span class="mention-text" contenteditable="false" id="${
          data.userUid
        }">${mentionsMap[data.userUid]?.fullName}</span>`;
      }
    });
    return mentionText;
  }
}
