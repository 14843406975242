import { JsonConverter, JsonCustomConvert } from "json2typescript";

//TODO(reza) i don't think current implementation supports nested objects in Map
@JsonConverter
export class MapConvertService implements JsonCustomConvert<Map<any, any>> {
  serialize(map: Map<any, any>): any {
    if (typeof map === "undefined") {
      return undefined;
    }
    const obj = {};
    map.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  deserialize(obj: any): Map<any, any> {
    if (typeof obj === "undefined") {
      return undefined;
    }
    const map = new Map();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        map.set(key, obj[key]);
      }
    }
    return map;
  }
}
