/* tslint:disable */
/* eslint-disable */
import { ObjectNodeModel } from "./object-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("WidgetDtoModel")
export class WidgetDtoModel {
  @JsonProperty("type", Any, true)
  type?: "CHART" | "KANBAN" | "CALENDAR" | "TABLE" = undefined;

  @JsonProperty("uiData", ObjectNodeModel, true)
  uiData?: ObjectNodeModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
