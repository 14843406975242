/* tslint:disable */
/* eslint-disable */
import { EditMentionValueApiDtoModel } from "./edit-mention-value-api-dto-model";
import { OApiReturnParamsRequestPdfProvisionValViewTypeModel } from "./o-api-return-params-request-pdf-provision-val-view-type-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqEditMentionValueDtoModel")
export class OApiReqEditMentionValueDtoModel {
  @JsonProperty("data", EditMentionValueApiDtoModel, true)
  data?: EditMentionValueApiDtoModel = undefined;

  @JsonProperty(
    "returnParams",
    OApiReturnParamsRequestPdfProvisionValViewTypeModel,
    true
  )
  returnParams?: OApiReturnParamsRequestPdfProvisionValViewTypeModel = undefined;
}
