/* tslint:disable */
/* eslint-disable */

/**
 * The user details such as userId, email, name, etc. who marked this provision as reviewed
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionReviewerViewModel")
export class ProvisionReviewerViewModel {
  /**
   * the reviewer user's email
   */

  @JsonProperty("emailAddress", String, true)
  emailAddress?: string = "";

  /**
   * the reviewer user's name
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the user-facing reviewer id
   */

  @JsonProperty("userUid", String, true)
  userUid?: string = "";
}
