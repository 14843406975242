import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { map } from "rxjs/operators";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";

@Injectable({
  providedIn: "root",
})
export class FetchApiService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Send a GET request to retrieve an existing entity from backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including final uid)
   * @param uid the final uid at end of url, representing the object to be fetched
   * @param view the view of the object to return
   * @param classRef the class of the returned object
   * @return the fetched object
   */
  sendRequest<T extends object>(
    apiVersion: string,
    baseUrl: string,
    uid: string,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    const params = {
      params: {
        view: view,
      },
    };
    return this.httpClient
      .get<ServerResponseModel>(apiVersion + baseUrl + "/" + uid, params)
      .pipe(
        map((res) => Json2TypescriptHelper.convertToEntity(res.data, classRef))
      );
  }

  /**
   * Send a GET request to retrieve an existing arbitrary entity from backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including final uid)
   * @param uid the final uid at end of url, representing the object to be fetched
   * @param params
   * @return the fetched object
   */
  sendUnknownEntityRequest(
    apiVersion: string,
    baseUrl: string,
    uid: string,
    params?: any
  ): Observable<any> {
    return this.httpClient
      .get<ServerResponseModel>(apiVersion + baseUrl + "/" + uid, {
        params,
      })
      .pipe(map((res) => res.data));
  }
}
