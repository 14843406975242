export class ExportIssueRequestModel {
  private exportType: "DOCX" | "XLSX" | "CSV" | "PDF";

  private includeNotes: boolean;

  private includeDocumentChronology: boolean;

  private includeSourceAttribution: boolean;

  private includeAnnotatedDocs: boolean;

  private excludeEmptyProvisions: boolean;

  private includeSectionHeaders: boolean;

  private emptyProvisionText: string;

  constructor(
    exportType: "DOCX" | "XLSX" | "CSV" | "PDF",
    includeNotes: boolean,
    includeDocumentChronology: boolean,
    includeSourceAttribution: boolean,
    includeAnnotatedDocs: boolean,
    excludeEmptyProvisions: boolean,
    includeSectionHeaders: boolean,
    emptyProvisionText: string
  ) {
    this.exportType = exportType;
    this.includeNotes = includeNotes;
    this.includeDocumentChronology = includeDocumentChronology;
    this.includeSourceAttribution = includeSourceAttribution;
    this.includeAnnotatedDocs = includeAnnotatedDocs;
    this.excludeEmptyProvisions = excludeEmptyProvisions;
    this.includeSectionHeaders = includeSectionHeaders;
    this.emptyProvisionText = emptyProvisionText;
  }
}
