import { MediaObserver } from "@angular/flex-layout";
import { DestroyRef, inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class InteleaseMatchMediaService {
  activeMediaQuery: string;
  onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>("");

  destroyRef = inject(DestroyRef);

  constructor(private _observableMedia: MediaObserver) {
    this.activeMediaQuery = "";
    this._init();
  }

  private _init(): void {
    this._observableMedia
      .asObservable()
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((change: any) => {
        if (this.activeMediaQuery !== change.mqAlias) {
          this.activeMediaQuery = change.mqAlias;
          this.onMediaChange.next(change.mqAlias);
        }
      });
  }
}
