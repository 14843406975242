/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UserActivityModel")
export class UserActivityModel {
  @JsonProperty("ipsUsed", [String], true)
  ipsUsed?: Array<string> = [];

  @JsonProperty("lastIp", String, true)
  lastIp?: string = "";

  @JsonProperty("lastLoginDate", DateTimeConvertService, true)
  lastLoginDate?: Date | undefined = undefined;

  @JsonProperty("lastUserAgent", String, true)
  lastUserAgent?: string = "";

  @JsonProperty("notificationCategoryToLastSeenDate", Any, true)
  notificationCategoryToLastSeenDate?: {
    [key: string]: any;
  } = {};

  @JsonProperty("registrationDate", DateTimeConvertService, true)
  registrationDate?: Date | undefined = undefined;

  @JsonProperty("registrationUserAgent", String, true)
  registrationUserAgent?: string = "";
}
