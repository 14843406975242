export class IssueExportModel {
  projectUid: string;
  kanbanBoardUid: string;
  issueListUid: string;
  issueUid: string;

  constructor(
    projectUid: string,
    kanbanBoardUid: string,
    issueListUid: string,
    issueUid: string
  ) {
    this.projectUid = projectUid;
    this.kanbanBoardUid = kanbanBoardUid;
    this.issueListUid = issueListUid;
    this.issueUid = issueUid;
  }
}
