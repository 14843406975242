export const itls_ICONS_CONST = [
  {
    iconName: "icon-dashboard",
    iconAddress: "assets/images/itls-icons/icon-dashboard.svg",
  },
  {
    iconName: "icon-documents",
    iconAddress: "assets/images/itls-icons/icon-documents.svg",
  },
  {
    iconName: "icon-documents-share",
    iconAddress: "assets/images/itls-icons/icon-document-shared.svg",
  },
  {
    iconName: "icon-form",
    iconAddress: "assets/images/itls-icons/icon-form.svg",
  },
  {
    iconName: "icon-task",
    iconAddress: "assets/images/itls-icons/icon-task.svg",
  },
  {
    iconName: "icon-report",
    iconAddress: "assets/images/itls-icons/icon-report.svg",
  },
  {
    iconName: "icon-filter",
    iconAddress: "assets/images/itls-icons/icon-filter.svg",
  },
  {
    iconName: "icon-search",
    iconAddress: "assets/images/itls-icons/icon-search.svg",
  },
  {
    iconName: "icon-notification",
    iconAddress: "assets/images/itls-icons/icon-notification.svg",
  },
  {
    iconName: "icon-arrow",
    iconAddress: "assets/images/itls-icons/icon-arrow.svg",
  },
  {
    iconName: "icon-note",
    iconAddress: "assets/images/itls-icons/icon-note.svg",
  },
  {
    iconName: "icon-review",
    iconAddress: "assets/images/itls-icons/icon-review.svg",
  },
  {
    iconName: "icon-assigned-to-me",
    iconAddress: "assets/images/itls-icons/icon-assigned-to-me.svg",
  },
  {
    iconName: "icon-attached",
    iconAddress: "assets/images/itls-icons/icon-attached.svg",
  },
  {
    iconName: "icon-add",
    iconAddress: "assets/images/itls-icons/icon-add.svg",
  },
  {
    iconName: "icon-share",
    iconAddress: "assets/images/itls-icons/icon-share.svg",
  },
  {
    iconName: "icon-export",
    iconAddress: "assets/images/itls-icons/icon-export.svg",
  },
  {
    iconName: "icon-more",
    iconAddress: "assets/images/itls-icons/icon-more.svg",
  },
  {
    iconName: "icon-menu",
    iconAddress: "assets/images/itls-icons/icon-menu.svg",
  },
  {
    iconName: "icon-remove",
    iconAddress: "assets/images/itls-icons/icon-remove.svg",
  },
  {
    iconName: "icon-arrow-right",
    iconAddress: "assets/images/itls-icons/icon-arrow-right.svg",
  },
  {
    iconName: "icon-arrow-left",
    iconAddress: "assets/images/itls-icons/icon-arrow-left.svg",
  },
  {
    iconName: "icon-more-vertical",
    iconAddress: "assets/images/itls-icons/icon-more-vertical.svg",
  },
  {
    iconName: "icon-cancel",
    iconAddress: "assets/images/itls-icons/icon-cancel.svg",
  },
  {
    iconName: "icon-toc",
    iconAddress: "assets/images/itls-icons/icon-toc.svg",
  },
  {
    iconName: "icon-arrow-down",
    iconAddress: "assets/images/itls-icons/icon-arrow-down.svg",
  },
  {
    iconName: "icon-take-me-there",
    iconAddress: "assets/images/itls-icons/icon-take-me-there.svg",
  },
  {
    iconName: "icon-minimize",
    iconAddress: "assets/images/itls-icons/icon-minimize.svg",
  },
  {
    iconName: "icon-expand",
    iconAddress: "assets/images/itls-icons/icon-expand.svg",
  },
  {
    iconName: "icon-setting",
    iconAddress: "assets/images/itls-icons/icon-setting.svg",
  },
  {
    iconName: "icon-bar-chart",
    iconAddress: "assets/images/itls-icons/icon-bar-chart.svg",
  },
  {
    iconName: "icon-polar",
    iconAddress: "assets/images/itls-icons/icon-polar.svg",
  },
  {
    iconName: "icon-radar",
    iconAddress: "assets/images/itls-icons/icon-radar.svg",
  },
  {
    iconName: "icon-polar-chart",
    iconAddress: "assets/images/itls-icons/icon-polar-chart.svg",
  },
  {
    iconName: "icon-donut-chart",
    iconAddress: "assets/images/itls-icons/icon-donut-chart.svg",
  },
  {
    iconName: "icon-histogram-2-chart",
    iconAddress: "assets/images/itls-icons/icon-histogram-2-chart.svg",
  },
  {
    iconName: "icon-histogram-chart",
    iconAddress: "assets/images/itls-icons/icon-histogram-chart.svg",
  },
  {
    iconName: "icon-line-chart",
    iconAddress: "assets/images/itls-icons/icon-line-chart.svg",
  },
  {
    iconName: "icon-pie-chart",
    iconAddress: "assets/images/itls-icons/icon-pie-chart.svg",
  },
  {
    iconName: "icon-time-chart",
    iconAddress: "assets/images/itls-icons/icon-time-chart.svg",
  },
  {
    iconName: "icon-configuration",
    iconAddress: "assets/images/itls-icons/icon-configuration.svg",
  },
  {
    iconName: "icon-widget-maximize",
    iconAddress: "assets/images/itls-icons/icon-widget-maximize.svg",
  },
  {
    iconName: "icon-widget-minimize",
    iconAddress: "assets/images/itls-icons/icon-widget-minimize.svg",
  },
  {
    iconName: "icon-upload",
    iconAddress: "assets/images/itls-icons/icon-upload.svg",
  },
  {
    iconName: "icon-next",
    iconAddress: "assets/images/itls-icons/icon-next.svg",
  },
  {
    iconName: "icon-link",
    iconAddress: "assets/images/itls-icons/icon-link.svg",
  },
  {
    iconName: "icon-edit",
    iconAddress: "assets/images/itls-icons/icon-edit.svg",
  },
  {
    iconName: "icon-cancel-search",
    iconAddress: "assets/images/itls-icons/icon-cancel-search.svg",
  },
  {
    iconName: "icon-pen",
    iconAddress: "assets/images/itls-icons/icon-pen.svg",
  },
  {
    iconName: "icon-tick",
    iconAddress: "assets/images/itls-icons/icon-tick.svg",
  },
  {
    iconName: "icon-view-hidden",
    iconAddress: "assets/images/itls-icons/icon-view-hidden.svg",
  },
  {
    iconName: "icon-view-visible",
    iconAddress: "assets/images/itls-icons/icon-view-visible.svg",
  },
  {
    iconName: "icon-send",
    iconAddress: "assets/images/itls-icons/icon-send.svg",
  },
  {
    iconName: "icon-arrow-up",
    iconAddress: "assets/images/itls-icons/icon-arrow-up.svg",
  },
  {
    iconName: "icon-assign-team",
    iconAddress: "assets/images/itls-icons/icon-team.svg",
  },
  {
    iconName: "icon-folder-share",
    iconAddress: "assets/images/itls-icons/icon-folder-share.svg",
  },
  {
    iconName: "icon-new-window",
    iconAddress: "assets/images/itls-icons/icon-new-window.svg",
  },
  {
    iconName: "icon-provisions",
    iconAddress: "assets/images/itls-icons/icon-provisions.svg",
  },
  {
    iconName: "icon-rename",
    iconAddress: "assets/images/itls-icons/icon-rename.svg",
  },
  {
    iconName: "icon-download",
    iconAddress: "assets/images/itls-icons/icon-download.svg",
  },
  {
    iconName: "icon-bookmark",
    iconAddress: "assets/images/itls-icons/icon-bookmark.svg",
  },
  {
    iconName: "icon-comment",
    iconAddress: "assets/images/itls-icons/icon-comment.svg",
  },
  {
    iconName: "add-comment",
    iconAddress: "assets/images/itls-icons/icon-add-comment.svg",
  },
  {
    iconName: "icon-move",
    iconAddress: "assets/images/itls-icons/icon-move.svg",
  },
  {
    iconName: "icon-reminders",
    iconAddress: "assets/images/itls-icons/icon-reminders.svg",
  },
  {
    iconName: "icon-time",
    iconAddress: "assets/images/itls-icons/icon-time.svg",
  },
  {
    iconName: "icon-minimized-comments",
    iconAddress: "assets/images/itls-icons/icon-topics.svg",
  },
  {
    iconName: "icon-open-minimized-comments",
    iconAddress: "assets/images/itls-icons/icon-comment-outline.svg",
  },
  {
    iconName: "icon-location",
    iconAddress: "assets/images/itls-icons/icon-location.svg",
  },
  {
    iconName: "icon-mention",
    iconAddress: "assets/images/itls-icons/icon-mention.svg",
  },
  {
    iconName: "icon-done",
    iconAddress: "assets/images/itls-icons/icon-done.svg",
  },
  {
    iconName: "icon-done-gray",
    iconAddress: "assets/images/itls-icons/icon-done-gray.svg",
  },
  {
    iconName: "icon-advanced-search",
    iconAddress: "assets/images/itls-icons/icon-advanced-search.svg",
  },
  {
    iconName: "icon-date",
    iconAddress: "assets/images/itls-icons/icon-date.svg",
  },
  {
    iconName: "icon-email",
    iconAddress: "assets/images/itls-icons/icon-email.svg",
  },
  {
    iconName: "icon-money",
    iconAddress: "assets/images/itls-icons/icon-money.svg",
  },
  {
    iconName: "icon-name",
    iconAddress: "assets/images/itls-icons/icon-name.svg",
  },
  {
    iconName: "icon-phone",
    iconAddress: "assets/images/itls-icons/icon-phone.svg",
  },
  {
    iconName: "icon-table",
    iconAddress: "assets/images/itls-icons/icon-table.svg",
  },
  {
    iconName: "icon-text",
    iconAddress: "assets/images/itls-icons/icon-text.svg",
  },
  {
    iconName: "icon-text-underlined",
    iconAddress: "assets/images/itls-icons/icon-text-underlined.svg",
  },
  {
    iconName: "icon-year",
    iconAddress: "assets/images/itls-icons/icon-year.svg",
  },
  {
    iconName: "icon-numeric",
    iconAddress: "assets/images/itls-icons/icon-numeric.svg",
  },
  {
    iconName: "icon-document-location",
    iconAddress: "assets/images/itls-icons/icon-document-location.svg",
  },
];
