import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { Observable } from "rxjs";
import { DeleteApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DeleteProvisionFormService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly PROVISION_FORMS_URL = "/provisionForms";

  constructor(private deleteApiService: DeleteApiService) {}

  deleteFormByIdNoView(formUid: string): Observable<ServerResponseModel> {
    return this.deleteApiService.sendRequestNoView(
      DeleteProvisionFormService.API_VERSION_1,
      DeleteProvisionFormService.PROVISION_FORMS_URL,
      formUid
    );
  }
}
