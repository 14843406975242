<div class="wrapper">
  <label
    [class.bold]="isHighlighted(suggestion.name, 'ABSTRACT_FILE_NAME')"
    style="padding-left: 10px"
    >{{ suggestion.name }}</label
  >
  <ul>
    <li *ngFor="let fileName of suggestion.fileNames">
      <label [class.bold]="isHighlighted(fileName, 'FILE_NAMES')">{{
        fileName
      }}</label>
    </li>
  </ul>
  <div class="hierarchy-item-container">
    <label
      class="hierarchy-item-text"
      [class.bold]="isHighlighted(suggestion.portfolio, 'PORTFOLIO')"
      >{{ suggestion.portfolio }}</label
    >
    <label class="hierarchy-item-arrow">-></label>
    <label
      class="hierarchy-item-text"
      [class.bold]="isHighlighted(suggestion.property, 'PROPERTY')"
      >{{ suggestion.property }}</label
    >
    <label class="hierarchy-item-arrow">-></label>
    <label
      class="hierarchy-item-text"
      [class.bold]="isHighlighted(suggestion.suite, 'SUITE')"
      >{{ suggestion.suite }}</label
    >
  </div>
</div>
