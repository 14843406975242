/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AutoCompleteResultModel")
export class AutoCompleteResultModel {
  @JsonProperty("format", Any, true)
  format?:
    | "LOCAL_DATE"
    | "ADDRESS"
    | "EMAIL"
    | "PHONE"
    | "PROPER_NOUN_UNKNOWN"
    | "PROPER_NOUN_CONSUMER_GOOD"
    | "PROPER_NOUN_PERSON"
    | "PROPER_NOUN_ORGANIZATION"
    | "PROPER_NOUN_LOCATION"
    | "PROPER_NOUN_WORK_OF_ART"
    | "PROPER_NOUN_EVENT"
    | "PROPER_NOUN_OTHER"
    | "KEYWORD"
    | "DOCUMENT_NAME" = undefined;

  @JsonProperty("score", Number, true)
  score?: number = undefined;

  @JsonProperty("unlabeledValueId", String, true)
  unlabeledValueId?: string = "";

  @JsonProperty("value", String, true)
  value?: string = "";
}
