/* tslint:disable */
/* eslint-disable */
import { EditReviewStatusApiV2DtoModel } from "./edit-review-status-api-v-2-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqEditReviewStatusV2DtoModel")
export class OApiReqEditReviewStatusV2DtoModel {
  @JsonProperty("data", EditReviewStatusApiV2DtoModel, true)
  data?: EditReviewStatusApiV2DtoModel = undefined;
}
