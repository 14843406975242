import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import {
  ListResponseModel,
  PaginationModel,
  ServerResponseModel,
} from "@@intelease/web/intelease/models";
import { map } from "rxjs/operators";
import { Json2TypescriptHelper } from "../../../utils/json2Typescript-helper";
import { KeyValueInterface } from "@@intelease/web/intelease/interfaces";

@Injectable({
  providedIn: "root",
})
export class FetchBatchApiService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Send a GET request to retrieve a listing of all existing entities from backend (as accessible by current user).
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param view the view of the objects to return
   * @param classRef the class of the returned objects
   * @param sortCriteria optional criteria by which to sort the returned list
   * @param pageCriteria optional pagination data for which items to retrieve
   * @param typeObjects optional key-value pairs to send in the request
   * @return the list of fetched objects
   */
  sendBatchRequest<T extends object>(
    apiVersion: string,
    baseUrl: string,
    view: string,
    classRef: new () => T,
    sortCriteria?: string,
    pageCriteria?: PaginationModel,
    typeObjects?: KeyValueInterface[]
  ): Observable<ListResponseModel<T>> {
    let pageSize = 25;
    const inParams: { [k: string]: string } = {};
    inParams["view"] = view;
    if (sortCriteria) {
      inParams["sort"] = sortCriteria;
    }
    if (pageCriteria) {
      pageSize = pageCriteria.size;
      inParams["perPage"] = pageSize.toString();
      inParams["page"] = pageCriteria.page.toString();
    }
    if (typeObjects) {
      for (const typeObject of typeObjects) {
        inParams[typeObject.key] = typeObject.value;
      }
    }
    const params = {
      params: inParams,
    };
    return this.httpClient
      .get<ServerResponseModel>(apiVersion + baseUrl, params)
      .pipe(
        map((res) =>
          Json2TypescriptHelper.convertToEntities(res.data, classRef, pageSize)
        )
      );
  }
}
