/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ReportAggGroupRequestModel")
export class ReportAggGroupRequestModel {
  @JsonProperty("category", Any, true)
  category?: "GENERAL" | "PROVISION" = undefined;

  @JsonProperty("field", String, true)
  field?: string = "";

  /**
   * the format of a structured value
   */

  @JsonProperty("provisionType", Any, true)
  provisionType?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;

  @JsonProperty("type", Any, true)
  type?: "NORMAL" = "NORMAL";
}
