/* tslint:disable */
/* eslint-disable */
import { PolarPagePdfViewModel } from "./polar-page-pdf-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullPagePdfViewViewModel")
export class FullPagePdfViewViewModel {
  @JsonProperty("docPdfViewUid", String, true)
  docPdfViewUid?: string = "";

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  @JsonProperty("pagePdfView", PolarPagePdfViewModel, true)
  pagePdfView?: PolarPagePdfViewModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
