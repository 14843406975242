import { Component, OnInit } from "@angular/core";

import { ControlWidget } from "../../widget";

@Component({
  selector: "sf-multi-select-dropdown-widget",
  template: `
    {{ updateOn }}
    <div class="widget form-group">
      <label
        *ngIf="schema.title"
        [attr.for]="id"
        class="horizontal control-label"
      >
        {{ schema.title }}
      </label>
      <span *ngIf="schema.description" class="formHelp"
        >{{ schema.description
        }}<i
          *ngIf="schema.tooltip"
          class="fa fa-info-circle ml-1"
          [matTooltip]="schema.tooltip"
        ></i
      ></span>
      <div class="input-dropdown-container">
        <ng-container *ngIf="updateOn === 'change'">
          <input
            style="margin-right: 5px;"
            [attr.type]="schema.options.inputType"
            class="form-control form-widget"
            [style.flex]="schema.options.styles.input.flex"
            (input)="control.setValue(control.value)"
            [(ngModel)]="control.value.inputModel"
          />
        </ng-container>
        <ng-container *ngIf="updateOn === 'blur'">
          <input
            style="margin-right: 5px;"
            [attr.type]="schema.options.inputType"
            class="form-control form-widget"
            [style.flex]="schema.options.styles.input.flex"
            (blur)="control.setValue(control.value)"
            [(ngModel)]="control.value.inputModel"
          />
        </ng-container>
        <ng-select
          [items]="schema.items"
          class="dropdown form-widget"
          [style.flex]="schema.options.styles.dropdown.flex"
          [appendTo]="'body'"
          [disabled]="schema.readOnly"
          [(ngModel)]="control.value.dropdownModel"
          [bindLabel]="schema.options.bindLabel"
          [bindValue]="schema.options.bindValue"
          [closeOnSelect]="schema.options.closeOnSelect"
          [multiple]="schema.options.multiple"
          [notFoundText]="schema.options.notFoundText"
          [placeholder]="schema.options.placeholder"
          [searchable]="schema.options.searchable"
          [loading]="schema.options.loading"
          (change)="control.setValue(control.value)"
        >
        </ng-select>
      </div>
    </div>
  `,
  styles: [
    `
      .input-dropdown-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class InputDropdownWidgetComponent extends ControlWidget {}
