import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ProfileUserModel } from "@@intelease/web/common/models/user";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { EditedComment } from "@@intelease/web/ui/src/lib/itls-comment/detail/itls-comment-detail.component";
import { CommonModalService } from "@@intelease/web/common/services";
import {
  CommentModel,
  CommentUserMentionModel,
} from "@@intelease/web/abstraction-page/src/lib/models";
import { take } from "lodash";
import { MatMenuItem } from "@angular/material/menu";
import { Guid, IntelFullMention } from "@@intelease/web/utils";

export interface NewComment {
  text: string;
  mentions: CommentUserMentionModel[];
  elements?;
}

@Component({
  selector: "il-comment",
  templateUrl: "./itls-comment.component.html",
  styleUrls: ["./itls-comment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsCommentComponent {
  @Input() inputId = Guid.generate();
  @Input() maxHeight = "220px";
  @Input() isLoading: boolean;
  @Input() isSaveLoading: boolean;
  _comments = [];
  get comments() {
    return this._comments;
  }

  @Input()
  set comments(comments) {
    this._comments = comments.map(
      (comment) =>
        //TODO(reza) change AbstractReviewEffects.loadProvisionComments$ instead (it should convert according to model classes!)
        new CommentModel({
          elements: comment.elements,
          mentions: comment.mentions,
          text: comment.text,
          uid: comment.uid,
          createdBy: {
            name: comment.createdBy?.name,
            uid: comment.createdBy?.uid,
            hasProfileImage: comment.createdBy?.profileImageFileRegistered,
          },
          createdAt: comment.createdAt,
        })
    );
  }

  @Output() save: EventEmitter<NewComment> = new EventEmitter<NewComment>();
  newComment = "";
  newCommentTextCursorPosition = 0;
  @Input() canAddNewComment: boolean;
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  editEvent: EventEmitter<EditedComment> = new EventEmitter<EditedComment>();
  mentions: CommentUserMentionModel[] = [];

  // @Input() accountUsers: ProfileUserModel[]
  @Input() set accountUsers(value) {
    this._accountUsers = value;
    this.mentionsList = this._accountUsers.map((item) => ({
      key: item.uid,
      email: item.email,
      value: item.name,
    }));
  }

  get accountUsers(): ProfileUserModel[] {
    return this._accountUsers;
  }

  filteredAccountUsers: ProfileUserModel[];
  userMentionMenuSearchInput = "";
  @ViewChild("userMentionSearchInputElement")
  userMentionSearchInputElement: MatMenuItem;
  private _accountUsers: ProfileUserModel[] = [];
  mentionsList: { key: string; value: string; email: string }[] = [];
  mentionModel: IntelFullMention = { mentions: [], elements: [] };

  //TODO(reza) remove coupling to web abstraction page
  constructor(
    private commonModalService: CommonModalService,
    private cdr: ChangeDetectorRef
  ) {}

  onSaveCommentClick() {
    if (this.mentionModel.elements.length) {
      // users mentions could have been deleted, so check them again here
      // this.mentions = this.mentions.filter((mention) =>
      //     this.newComment.includes(
      //         this.getUserMentionValueInText(
      //             mention.email,
      //             mention.fullName,
      //         ),
      //     ),
      // )
      this.save.emit({
        text: this.newComment || "TEMP",
        mentions: this.mentions,
        elements: this.mentionModel,
      });
      this.newComment = "";
      this.mentions = [];
      this.mentionModel = undefined;
      setTimeout(() => {
        this.mentionModel = { elements: [], mentions: [] };
      }, 10);
      this.cdr.detectChanges();
    }
  }

  onDeleteComment(comment) {
    const modalData = new ModalInputModel();
    modalData.mode = ComponentModeEnum.REMOVE;
    modalData.payload = {
      customMessage: false,
      message: "Are you sure?",
      title: "Delete Comment",
      showDismissIcon: false,
    };
    this.commonModalService
      .openGenericOkCancelModal(modalData)
      .afterClosed()
      .subscribe((res) => {
        if (res?.data?.exitType === ModalsResponseTypeEnum.CLOSE) {
          const deletedCommentIdx = this._comments.findIndex(
            (item) => item.uid === comment.uid
          );
          if (deletedCommentIdx !== -1) {
            this._comments.splice(deletedCommentIdx, 1);
          }
          this.deleteEvent.emit(comment);
          this.cdr.detectChanges();
        }
      });
  }

  onEditComment(editedComment: EditedComment) {
    this.editEvent.emit(editedComment);
  }

  onMentionUserSelected(accountUser: ProfileUserModel) {
    const textToInsert = this.getUserMentionValueInText(
      accountUser.email,
      accountUser.name
    );
    if (!this.newComment.length) {
      this.newComment = textToInsert;
    } else {
      let prefix = this.newComment.substr(0, this.newCommentTextCursorPosition);
      let postfix = "";
      if (this.newComment.length > this.newCommentTextCursorPosition) {
        postfix =
          " " + this.newComment.substr(this.newCommentTextCursorPosition);
      }
      if (!prefix.endsWith(" ")) {
        prefix = prefix + " ";
      }
      if (postfix.length && !postfix.startsWith(" ")) {
        postfix = " " + postfix;
      }
      this.newComment = prefix + textToInsert + postfix;
    }
    // don't add duplicate mention
    if (!this.mentions.find((item) => item.uid === accountUser.uid)) {
      this.mentions.push({
        uid: accountUser.uid,
        email: accountUser.email,
        fullName: accountUser.name,
      });
    }
  }

  onMentionUserSelectedFromDropdown(accountUser: ProfileUserModel) {
    setTimeout(() => {
      const textToInsert = this.getUserMentionValueInText(
        accountUser.email,
        accountUser.name
      );
      this.newComment = this.newComment.replace(
        "@" + accountUser.mentionText,
        textToInsert
      );
      // don't add duplicate mention
      if (!this.mentions.find((item) => item.uid === accountUser.uid)) {
        this.mentions.push({
          uid: accountUser.uid,
          email: accountUser.email,
          fullName: accountUser.name,
        });
      }
      this.cdr.detectChanges();
    }, 1);
    return accountUser;
  }

  onNewCommentTextFocusOut(event: any) {
    this.newCommentTextCursorPosition = event.target.selectionStart;
  }

  private getUserMentionValueInText(id: string, display: string) {
    return `@[${display}](${id})`;
  }

  onSearchInputChanged() {
    this.reloadFilteredAccountUsers();
  }

  onUserMentionMenuOpened() {
    this.userMentionMenuSearchInput = "";
    this.reloadFilteredAccountUsers();
    if (this.userMentionSearchInputElement) {
      // we need this timeout because button is clicked alongside textarea which gets focus
      setTimeout(() => {
        this.userMentionSearchInputElement.focus();
      }, 0);
    }
  }

  private reloadFilteredAccountUsers() {
    const lowerCasedSearchInput = this.userMentionMenuSearchInput.toLowerCase();
    if (!lowerCasedSearchInput.length) {
      this.filteredAccountUsers = this.accountUsers;
    } else {
      this.filteredAccountUsers = this.accountUsers.filter((item) =>
        item.name.toLowerCase().includes(lowerCasedSearchInput)
      );
    }
    // select top 5 account users
    this.filteredAccountUsers = take(this.filteredAccountUsers, 5);
  }
}
