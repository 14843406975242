import { Component, DestroyRef, inject, Inject, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { isEmpty } from "lodash";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  QueueDtoModel,
  QueueService,
  SimpleQueueDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { QueueFacade } from "@@intelease/app-state/queue/src";
import { AuthService } from "@@intelease/app-services/auth/src";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import { UserInfoService } from "@@intelease/web/intelease/services";
import { CreateButtonPipeTypesEnum } from "../add-new-queue/add-queue-modal.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

type QueueSharePermissionType = "PRIVATE" | "PUBLIC" | "VIEWABLE";

@Component({
  selector: "intelease-duplicate-queue-modal",
  templateUrl: "./duplicate-queue-modal.component.html",
  styleUrls: [],
})
export class DuplicateQueueModalComponent implements OnInit {
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;
  onlyMyQueues: SimpleQueueDtoModel[];
  queue?: QueueDtoModel = undefined;
  queueName = "";
  queueDescription = "";
  queuePermission: QueueSharePermissionType = "PRIVATE";
  createButtonTooltip = "You should choose a name for the queue.";

  public CreateButtonPipeTypesEnum = CreateButtonPipeTypesEnum;

  queuePermissionOptions: {
    name: string;
    value: QueueSharePermissionType;
  }[] = [
    {
      name: "Private to me",
      value: "PRIVATE",
    },
    {
      name: "Others can see it",
      value: "VIEWABLE",
    },
    {
      name: "Others can see and use it",
      value: "PUBLIC",
    },
  ];

  destroyRef = inject(DestroyRef);

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<DuplicateQueueModalComponent>,
    private queueService: QueueService,
    private queueFacade: QueueFacade,
    private notificationService: MessageService
  ) {}

  ngOnInit() {
    this.payload = this.dialogData.payload;
    this.mode = this.dialogData.mode;
    this.queueFacade.loadUsableQueueList();

    if (this.dialogData.payload.queue instanceof QueueDtoModel) {
      this.queue = this.dialogData.payload.queue;
      this.queueName = this.queue.name;
      this.queueDescription = this.queue.description;
    } else {
      throw new Error(
        "ModalInput payload must contain the queue data to be duplicated"
      );
    }

    this.queueFacade.usableQueueListLoaded$
      .pipe(filter((item) => !isEmpty(item)))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((usableQueues) => {
        const currentUserUid = UserInfoService.getUserUid();
        this.onlyMyQueues = usableQueues.filter((queue) => {
          return queue?.createdBy?.uid === currentUserUid;
        });

        this.setQueueName(this.queue.name);

        this.queuePermission = this.onlyMyQueues.filter(
          (queue) => queue.uid === this.queue.uid
        )[0].permissionCategory;
      });
  }

  setQueueName(newQueueName: string) {
    this.queueName = newQueueName;
    this.onlyMyQueues?.forEach((queue) => {
      if (
        queue.name.toLocaleLowerCase() ===
        newQueueName.trim().toLocaleLowerCase()
      ) {
        this.setQueueName(`${newQueueName} (copy)`);
      }
    });
  }

  isFormValid(notify?: boolean): boolean {
    if (!this.queuePermission) {
      if (notify) {
        this.notificationService.warning("Queue permission is required");
      }
      return false;
    }
    const matchingQueue = this.onlyMyQueues.filter((queue) => {
      if (
        queue.name.toLocaleLowerCase() ===
        this.queueName.trim().toLocaleLowerCase()
      ) {
        return true;
      }
      return false;
    });
    if (matchingQueue.length) {
      if (notify) {
        this.notificationService.warning(
          "Queue name shouldn't match with an existing active owned queue name"
        );
      }
      return false;
    }
    return true;
  }

  onDuplicateClick() {
    if (!this.isFormValid(true)) {
      return;
    }

    this.queueService
      .cloneQueue({
        body: {
          data: {
            uid: this.queue.uid,
            permissionCategory: this.queuePermission,
            name: this.queueName,
            description: this.queueDescription,
          },
        },
      })
      .subscribe(
        (response) => {
          if (response.data?.uid) {
            this.modalRes.data = {
              name: response.data.name,
              description: response.data.description,
              uid: response.data?.uid,
            };

            this.dialogRef.close({
              ...this.modalRes,
              type: ModalsResponseTypeEnum.CLOSE,
            });
          } else {
            this.dialogRef.close({
              ...this.modalRes,
              type: ModalsResponseTypeEnum.DISMISS,
            });
          }
        },
        (err) => {
          this.notificationService.error(
            "An error occurred while cloning the queue"
          );
        }
      );
  }

  onQueuePermissionChange(evt: any) {
    this.queuePermission = evt.value as QueueSharePermissionType;
  }

  onDismissModalClick() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}
