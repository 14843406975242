/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("GoogleDriveFolderSyncReqDtoModel")
export class GoogleDriveFolderSyncReqDtoModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("driveError", String, true)
  driveError?: string = "";

  @JsonProperty("driveSuccess", Boolean, true)
  driveSuccess?: boolean = undefined;

  @JsonProperty("embedUrl", String, true)
  embedUrl?: string = "";

  @JsonProperty("iconUrl", String, true)
  iconUrl?: string = "";

  @JsonProperty("id", String, true)
  id?: string = "";

  @JsonProperty("lastEditedUtc", Number, true)
  lastEditedUtc?: number = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("organizationDisplayName", String, true)
  organizationDisplayName?: string = "";

  @JsonProperty("serviceId", String, true)
  serviceId?: string = "";

  @JsonProperty("shared", Boolean, true)
  shared?: boolean = undefined;

  @JsonProperty("type", String, true)
  type?: string = "";

  @JsonProperty("url", String, true)
  url?: string = "";
}
