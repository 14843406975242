import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MinimalProvisionFormModel } from "@@intelease/web/common/models";
import {
  ListResponseModel,
  PaginationModel,
  ServerResponseModel,
} from "@@intelease/web/intelease/models";
import {
  DeleteProvisionFormService,
  EditProvisionFormService,
  SearchProvisionFormService,
} from "@@intelease/web/intelease/services/models";
import { RestClient } from "@@intelease/web/utils";
import { OApiSingleEntityNoDataResponseModel } from "@@intelease/api-models/adex-api-model-src";

@Injectable({
  providedIn: "root",
})
export class ProvisionFormsService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly PROVISION_FORMS_URL = "/provisionForms";

  constructor(
    private restClient: RestClient,
    private deleteProvisionFormService: DeleteProvisionFormService,
    private editProvisionFormService: EditProvisionFormService,
    private searchProvisionFormService: SearchProvisionFormService
  ) {}

  searchProvisionForms(
    pagination: PaginationModel,
    sort: string
  ): Observable<ListResponseModel<MinimalProvisionFormModel>> {
    return this.searchProvisionFormService.searchProvisionForms(
      pagination,
      sort,
      MinimalProvisionFormModel.view,
      MinimalProvisionFormModel
    );
  }

  getRecentlyUploadedProvisionForms(): Observable<
    ListResponseModel<MinimalProvisionFormModel>
  > {
    return this.restClient.sendGetListRequest(
      ProvisionFormsService.API_VERSION_1,
      ProvisionFormsService.PROVISION_FORMS_URL + "/recentlyUploaded",
      MinimalProvisionFormModel,
      MinimalProvisionFormModel.view
    );
  }

  searchByFormName(searchStr: string): Observable<MinimalProvisionFormModel[]> {
    return this.searchProvisionFormService.searchByFormName(
      searchStr,
      MinimalProvisionFormModel.view,
      MinimalProvisionFormModel
    );
  }

  deleteFormById(formUid: string): Observable<ServerResponseModel> {
    return this.deleteProvisionFormService.deleteFormByIdNoView(formUid);
  }

  renameFormById(
    formUid: string,
    newName: string,
    description: string
  ): Observable<ServerResponseModel> {
    return this.editProvisionFormService.renameFormByIdNoView(
      formUid,
      newName,
      description
    );
  }

  renameFormByIdMinimalView(
    formUid: string,
    newName: string,
    description: string
  ): Observable<ServerResponseModel> {
    return this.editProvisionFormService.renameFormByIdMinimalForm(
      formUid,
      newName,
      description
    );
  }

  deleteFormsByIds(
    ids: string[]
  ): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.restClient.sendPostRequest<OApiSingleEntityNoDataResponseModel>(
      "/v1",
      `/deleteBatch/provisionForms`,
      {
        data: {
          objectUids: ids,
        },
        returnParams: {
          view: "minimal",
        },
      },
      OApiSingleEntityNoDataResponseModel
    );
  }
}
