import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  OnInit,
  Output,
} from "@angular/core";
import { cloneDeep, first as _first } from "lodash";
import { NotificationsService } from "@@intelease/app-services/notifications";
import { ComponentStateHooksInterface } from "@@intelease/app-models/common";
import { NotificationsFacade } from "@@intelease/app-state/notifications";
import { Router } from "@angular/router";
import { NotificationTypesEnum } from "@@intelease/app-models/notifications";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "intelease-web-notifications",
  templateUrl: "./web-notifications.component.html",
  styleUrls: ["./web-notifications.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebNotificationsComponent
  implements OnInit, ComponentStateHooksInterface
{
  selectedTab;
  notifications: any[] = [];
  notificationCategories: any[] = [];
  private isInitialized: boolean;
  categoryNotificationsMap: any = {};
  @Output() selectNotification: EventEmitter<any> = new EventEmitter<any>();
  notificationTypesEnum = NotificationTypesEnum;
  destroyRef = inject(DestroyRef);

  constructor(
    private notificationsService: NotificationsService,
    public notificationsFacade: NotificationsFacade,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.initActions();
    this.initListeners();
  }

  initActions(): void {
    this.notificationsFacade.loadNotificationsCategory({});
  }

  initListeners(): void {
    this.notificationsFacade.getNotificationsCategory$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          this.notificationCategories = res;
          if (!this.selectedTab)
            this.selectedTab = _first(this.notificationCategories);
          if (!this.isInitialized) {
            this.onTabSelected(this.selectedTab);
          }
          this.isInitialized = true;
          this.cdr.detectChanges();
        }
      });

    this.notificationsFacade.getCategoryNotifications$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          this.categoryNotificationsMap = cloneDeep(res);
          this.cdr.detectChanges();
        }
      });
  }

  onTabSelected(selectedTab) {
    const { key, unseenCount } = selectedTab;
    this.selectedTab = selectedTab;
    if (unseenCount > 0) {
      if (key === "ALL") {
        this.notificationsFacade.seenAllCategoryNotifications({});
      } else {
        this.notificationsFacade.seenSelectedCategoryNotifications({
          category: key,
        });
      }
      this.notificationsFacade.decrementCountOfUnseenNotifications({
        numberOfNewNotifications: unseenCount,
      });
    }
    this.cdr.detectChanges();
    this.notificationsFacade.loadSelectedCategoryNotifications({
      category: this.selectedTab.key,
    });
  }

  onScroll() {
    // this.notificationsFacade.loadSelectedCategoryNotifications({category: this.selectedTab.key});
    // console.log('scrolled!!');
  }

  onReadNotificationClick(evt, notification) {
    evt.stopPropagation();
    if (!notification.read) {
      notification.read = true;
      this.notificationsFacade.readSelectedNotification({
        notificationCategory: this.selectedTab.key,
        notificationUid: notification.uid,
        isOpen: true,
      });
      this.cdr.detectChanges();
    }
  }

  onNotificationClick(evt, notification) {
    evt.stopPropagation();
    this.selectNotification.emit(notification);
    const url = this.notificationsService.getNotificationUrl(notification).link;
    const queryParams =
      this.notificationsService.getNotificationQueryParams(notification);
    this.router.navigate([url], { queryParams });

    if (!notification.read) {
      notification.read = true;
      this.notificationsFacade.readSelectedNotification({
        notificationCategory: this.selectedTab.key,
        notificationUid: notification.uid,
        isOpen: false,
      });
    }
  }

  getNotificationValue(notification) {
    const { type } = notification;
    return this.notificationsService.getNotificationUITextValueByType(
      type,
      notification
    );
  }

  getUiEntityTypeFrom(entityType: string): string {
    switch (entityType) {
      case "FINAL_ABSTRACT":
        return "Records";
      case "PROJECT":
        return "Projects";
      case "CALENDAR":
        return "Calendars";
      case "REPORT":
        return "Reports";
      case "PROVISION_FORM":
        return "Provision Form";
      case "DASHBOARD":
        return "Dashboards";
      default:
        return "Records";
    }
  }
}
