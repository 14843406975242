<div>
  <div mat-dialog-title class="clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5 class="mt-0">
      {{ title }}
    </h5>
  </div>
  <div mat-dialog-content>
    <p>
      {{ message }}
    </p>
    <input
      type="text"
      class="form-control"
      [(ngModel)]="newName"
      (keyup.enter)="onRename()"
    />
  </div>
  <div mat-dialog-actions>
    <button
      type="button"
      class="btn btn-primary btn-cons pull-left inline"
      (click)="onRename()"
    >
      Rename
    </button>
    <button
      type="button"
      class="btn btn-default btn-cons no-margin pull-left inline"
      (click)="onDismissModal()"
    >
      Cancel
    </button>
  </div>
</div>
