import { Action } from "@ngrx/store";

export enum NotificationsActionTypes {
  LoadNotifications = "[Notifications] Load Notifications",
  NotificationsLoaded = "[Notifications] Notifications Loaded",
  NotificationsLoadError = "[Notifications] Notifications Load Error",

  LoadCountOfUnseenNotifications = "[Notifications] Load Count Of Unseen Notifications",
  IncrementCountOfUnseenNotifications = "[Notifications] Increment Count Of Unseen Notifications",
  DecrementCountOfUnseenNotifications = "[Notifications] Decrement Count Of Unseen Notifications",
  CountOfUnseenNotificationsLoaded = "[Notifications] Count Of Unseen Notifications Loaded",
  CountOfUnseenNotificationsLoadError = "[Notifications] Count Of Unseen Notifications Load Error",

  LoadUnseenNotifications = "[Notifications] Load Unseen Notifications",
  UnseenNotificationsLoaded = "[Notifications] Unseen Notifications Loaded",
  UnseenNotificationsLoadError = "[Notifications] Unseen Notifications Load Error",

  LoadNotificationsCategory = "[Notifications] Load Notifications Category",
  NotificationsCategoryLoaded = "[Notifications] Notifications Category Loaded",
  NotificationsCategoryLoadError = "[Notifications] Notifications Category Load Error",

  LoadSelectedCategoryNotifications = "[Notifications] Load Selected Category Notifications",
  SelectedCategoryNotificationsLoaded = "[Notifications] Selected Category Notifications Loaded",
  SelectedCategoryNotificationsLoadError = "[Notifications] Selected Category Notifications Load Error",
  SeenSelectedCategoryNotifications = "[Notifications] Seen Selected Category Notifications",
  SeenAllCategoryNotifications = "[Notifications] Seen All Category Notifications",
  IncrementCountOfUnseenSelectedCategoryNotifications = "[Notifications] Increment Count Of Unseen Selected Category Notifications",
  AddNotificationToSelectedCategoryNotifications = "[Notifications] Add Notification To Selected Category Notifications",

  ReadSelectedNotification = "[Notifications] Read Selected Notification",
  ReadSelectedNotificationSucceeded = "[Notifications] Read Selected Notification Succeeded",
  ReadSelectedNotificationFailed = "[Notifications] Read Selected Notification Failed",
}

export class LoadNotifications implements Action {
  readonly type = NotificationsActionTypes.LoadNotifications;
}

export class NotificationsLoadError implements Action {
  readonly type = NotificationsActionTypes.NotificationsLoadError;

  constructor(public payload) {}
}

export class NotificationsLoaded implements Action {
  readonly type = NotificationsActionTypes.NotificationsLoaded;

  constructor(public payload: []) {}
}

export class LoadCountOfUnseenNotifications implements Action {
  readonly type = NotificationsActionTypes.LoadCountOfUnseenNotifications;

  constructor(public payload) {}
}

export class CountOfUnseenNotificationsLoaded implements Action {
  readonly type = NotificationsActionTypes.CountOfUnseenNotificationsLoaded;

  constructor(public payload) {}
}

export class IncrementCountOfUnseenNotifications implements Action {
  readonly type = NotificationsActionTypes.IncrementCountOfUnseenNotifications;

  constructor(public payload) {}
}

export class DecrementCountOfUnseenNotifications implements Action {
  readonly type = NotificationsActionTypes.DecrementCountOfUnseenNotifications;

  constructor(public payload) {}
}

export class CountOfUnseenNotificationsLoadError implements Action {
  readonly type = NotificationsActionTypes.CountOfUnseenNotificationsLoadError;

  constructor(public payload) {}
}

export class LoadUnseenNotifications implements Action {
  readonly type = NotificationsActionTypes.LoadUnseenNotifications;

  constructor(public payload) {}
}

export class UnseenNotificationsLoaded implements Action {
  readonly type = NotificationsActionTypes.UnseenNotificationsLoaded;

  constructor(public payload) {}
}

export class UnseenNotificationsLoadError implements Action {
  readonly type = NotificationsActionTypes.UnseenNotificationsLoadError;

  constructor(public payload) {}
}

export class LoadNotificationsCategory implements Action {
  readonly type = NotificationsActionTypes.LoadNotificationsCategory;

  constructor(public payload) {}
}

export class NotificationsCategoryLoaded implements Action {
  readonly type = NotificationsActionTypes.NotificationsCategoryLoaded;

  constructor(public payload) {}
}

export class NotificationsCategoryLoadError implements Action {
  readonly type = NotificationsActionTypes.NotificationsCategoryLoadError;

  constructor(public payload) {}
}

export class LoadSelectedCategoryNotifications implements Action {
  readonly type = NotificationsActionTypes.LoadSelectedCategoryNotifications;

  constructor(public payload) {}
}

export class SelectedCategoryNotificationsLoaded implements Action {
  readonly type = NotificationsActionTypes.SelectedCategoryNotificationsLoaded;

  constructor(public payload) {}
}

export class SelectedCategoryNotificationsLoadError implements Action {
  readonly type =
    NotificationsActionTypes.SelectedCategoryNotificationsLoadError;

  constructor(public payload) {}
}

export class SeenSelectedCategoryNotifications implements Action {
  readonly type = NotificationsActionTypes.SeenSelectedCategoryNotifications;

  constructor(public payload) {}
}

export class SeenAllCategoryNotifications implements Action {
  readonly type = NotificationsActionTypes.SeenAllCategoryNotifications;

  constructor(public payload) {}
}

export class IncrementCountOfUnseenSelectedCategoryNotifications
  implements Action
{
  readonly type =
    NotificationsActionTypes.IncrementCountOfUnseenSelectedCategoryNotifications;

  constructor(public payload) {}
}

export class AddNotificationToSelectedCategoryNotifications implements Action {
  readonly type =
    NotificationsActionTypes.AddNotificationToSelectedCategoryNotifications;

  constructor(public payload) {}
}

export class ReadSelectedNotification implements Action {
  readonly type = NotificationsActionTypes.ReadSelectedNotification;

  constructor(public payload) {}
}

export class ReadSelectedNotificationSucceeded implements Action {
  readonly type = NotificationsActionTypes.ReadSelectedNotificationSucceeded;

  constructor(public payload) {}
}

export class ReadSelectedNotificationFailed implements Action {
  readonly type = NotificationsActionTypes.ReadSelectedNotificationFailed;

  constructor(public payload) {}
}

export type NotificationsAction =
  | LoadNotifications
  | NotificationsLoaded
  | NotificationsLoadError
  | LoadCountOfUnseenNotifications
  | CountOfUnseenNotificationsLoaded
  | IncrementCountOfUnseenNotifications
  | DecrementCountOfUnseenNotifications
  | CountOfUnseenNotificationsLoadError
  | LoadUnseenNotifications
  | UnseenNotificationsLoaded
  | UnseenNotificationsLoadError
  | LoadNotificationsCategory
  | NotificationsCategoryLoaded
  | NotificationsCategoryLoadError
  | LoadSelectedCategoryNotifications
  | SelectedCategoryNotificationsLoaded
  | SelectedCategoryNotificationsLoadError
  | SeenSelectedCategoryNotifications
  | SeenAllCategoryNotifications
  | IncrementCountOfUnseenSelectedCategoryNotifications
  | AddNotificationToSelectedCategoryNotifications
  | ReadSelectedNotification
  | ReadSelectedNotificationSucceeded
  | ReadSelectedNotificationFailed;

export const fromNotificationsActions = {
  LoadNotifications,
  NotificationsLoaded,
  NotificationsLoadError,

  LoadCountOfUnseenNotifications,
  CountOfUnseenNotificationsLoaded,
  IncrementCountOfUnseenNotifications,
  DecrementCountOfUnseenNotifications,
  CountOfUnseenNotificationsLoadError,

  LoadUnseenNotifications,
  UnseenNotificationsLoaded,
  UnseenNotificationsLoadError,

  LoadNotificationsCategory,
  NotificationsCategoryLoaded,
  NotificationsCategoryLoadError,

  LoadSelectedCategoryNotifications,
  SelectedCategoryNotificationsLoaded,
  SelectedCategoryNotificationsLoadError,
  SeenSelectedCategoryNotifications,
  SeenAllCategoryNotifications,
  IncrementCountOfUnseenSelectedCategoryNotifications,
  AddNotificationToSelectedCategoryNotifications,

  ReadSelectedNotification,
  ReadSelectedNotificationSucceeded,
  ReadSelectedNotificationFailed,
};
