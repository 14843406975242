import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItlsExportComponent } from "./itls-export.component";
import { MatCardModule } from "@angular/material/card";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";

const MATERIAL_MODULES = [
  MatCardModule,
  MatSlideToggleModule,
  FormsModule,
  MatIconModule,
  MatButtonModule,
  MatFormFieldModule,
  MatSelectModule,
];

@NgModule({
  declarations: [ItlsExportComponent],
  imports: [CommonModule, FlexLayoutModule, ...MATERIAL_MODULES],
  exports: [ItlsExportComponent],
})
export class ItlsExportModule {}
