/* tslint:disable */
/* eslint-disable */
import { NodeSearchResultNavigationDtoModel } from "./node-search-result-navigation-dto-model";
import { UserDtoModel } from "./user-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("NodeSearchResultDtoModel")
export class NodeSearchResultDtoModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("googleDriveFileId", String, true)
  googleDriveFileId?: string = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("navigation", NodeSearchResultNavigationDtoModel, true)
  navigation?: NodeSearchResultNavigationDtoModel = undefined;

  @JsonProperty("owner", UserDtoModel, true)
  owner?: UserDtoModel = undefined;

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];

  @JsonProperty("queueName", String, true)
  queueName?: string = "";

  /**
   * the stage of the queue
   */

  @JsonProperty("queueStage", Any, true)
  queueStage?:
    | "PROCESSING"
    | "READY"
    | "IN_REVIEW"
    | "NEED_QA_REVIEW"
    | "IN_QA_REVIEW"
    | "COMPLETED"
    | "FAILED" = undefined;

  @JsonProperty("queueUid", String, true)
  queueUid?: string = "";

  @JsonProperty("recordUid", String, true)
  recordUid?: string = "";

  @JsonProperty("shared", Boolean, true)
  shared?: boolean = undefined;

  @JsonProperty("status", Any, true)
  status?:
    | "UPLOADING"
    | "UPLOADING_CANCELED"
    | "QUEUED"
    | "INTERMEDIATE_PROCESSING"
    | "INTERMEDIATE_PROCESSING_COMPLETED"
    | "INTERMEDIATE_PROCESSING_COMPLETED_ERROR"
    | "INTERMEDIATE_PROCESSING_ERROR"
    | "INTERMEDIATE_PROCESSING_ERROR_NO_RETRY"
    | "FINAL_PROCESSING"
    | "FINAL_PROCESSING_COMPLETED"
    | "FINAL_PROCESSING_COMPLETED_ERROR"
    | "FINAL_PROCESSING_ERROR"
    | "FINAL_PROCESSING_ERROR_NO_RETRY"
    | "PROCESSING"
    | "PROCESSING_COMPLETED"
    | "PROCESSING_COMPLETED_ERROR"
    | "UNDER_REVIEW"
    | "COMPLETED"
    | "ERROR"
    | "ERROR_NO_RETRY"
    | "UI_DELETED"
    | "UI_INVISIBLE" = undefined;

  @JsonProperty("type", Any, true)
  type?: "DIRECTORY" | "DOC_SET" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("viewer", Any, true)
  viewer?: "DEFAULT" | "GOOGLE_DRIVE_PREVIEW" = undefined;
}
