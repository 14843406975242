import { Injectable } from "@angular/core";
import {
  HighlightTypeEnum,
  ProvisionBoxActionTypeEnum,
} from "@@intelease/web/abstraction-page/src/lib/enums";
import { Subject } from "rxjs";
import { WebAbstractionPageService } from "@@intelease/web/abstraction-page/src/lib/services/web-abstraction-page.service";
import { TextHighlightTypeEnum } from "@@intelease/app-models/common";
import { last, values } from "lodash";
import {
  FullValMultiPdfProvViewModel,
  PartialValPdfProvisionViewModel,
} from "@@intelease/api-models/adex-api-model-src";

@Injectable({
  providedIn: "root",
})
export class AbstractTextHighlightsService {
  public static onTextHighlightActionFromProvisionBox: Subject<{
    type: ProvisionBoxActionTypeEnum;
    provisionValue?: PartialValPdfProvisionViewModel;
    provision?: FullValMultiPdfProvViewModel;
    payload?;
  }> = new Subject<{
    type: ProvisionBoxActionTypeEnum;
    provisionValue?: PartialValPdfProvisionViewModel;
    provision?: FullValMultiPdfProvViewModel;
    payload?;
  }>();

  public static onTextHighlightActionToProvisionBox: Subject<{
    type: ProvisionBoxActionTypeEnum;
    payload?;
  }> = new Subject<{ type: ProvisionBoxActionTypeEnum; payload? }>();

  public static selectedDocumentMentionTextHighlight;
  public static selectedHighlightedNestedMentionUid;

  public static selectedHighlightedNestedAttributionsMentionUid;

  constructor(private webAbstractionPageService: WebAbstractionPageService) {}

  public static getHighlightByPageNumAndHighlightId(
    pageNum: number,
    highlightId: any,
    abstractTextHighlight: any
  ) {
    return abstractTextHighlight["actualDocPdfView"]["pageMetas"][pageNum][
      "textHighlights"
    ][highlightId];
  }

  public static getHighlightByPageNumAndMentionId(
    pageNum: number,
    mentionId: any,
    abstractTextHighlight: any
  ) {
    const highlightData =
      abstractTextHighlight["actualDocPdfView"]["pageMetas"][pageNum];
    if (highlightData) {
      let foundTextHighlight;
      const textHighlights = highlightData["textHighlights"];
      for (const highlightId in textHighlights) {
        if (textHighlights[highlightId]) {
          const textHighlight = textHighlights[highlightId];
          if (textHighlight) {
            if (
              textHighlight["itlsData"] &&
              textHighlight["itlsData"]["uid"] &&
              textHighlight["itlsData"]["uid"] === mentionId
            ) {
              foundTextHighlight = textHighlight;
            }
          }
        }
      }
      const areaHighlights = highlightData["areaHighlights"];
      for (const areaHighlightId in areaHighlights) {
        if (areaHighlights[areaHighlightId]) {
          const areaHighlight = areaHighlights[areaHighlightId];
          if (areaHighlight) {
            if (
              areaHighlight["itlsData"] &&
              areaHighlight["itlsData"]["uid"] &&
              areaHighlight["itlsData"]["uid"] === mentionId
            ) {
              foundTextHighlight = areaHighlight;
            }
          }
        }
      }
      return foundTextHighlight;
    }
  }

  public static addNewTextHighlightToAbstractHighlights(
    pdfViewerRef: any,
    pageNum: any,
    highlightId: any,
    textHighlight: any
  ) {
    pdfViewerRef.addTextHighlight(pageNum, highlightId, textHighlight);
  }

  public static getMentionUidFromTextHighlight(textHighlight): string {
    return textHighlight["itlsData"]["uid"];
  }

  public static getProvisionUidFromTextHighlight(textHighlight): string {
    return textHighlight["itlsData"]["provisionData"]["provisionUid"];
  }

  public static getCommentUidFromTextHighlight(textHighlight): string {
    return textHighlight["itlsData"]["commentData"]["commentUid"];
  }

  public static getBookmarkDataFromTextHighlight(textHighlight): string {
    return textHighlight["itlsData"]["bookmarkData"];
  }

  public static deleteTextHighlightFromReader(
    pdfViewerRef: any,
    textHighlight: any
  ) {
    pdfViewerRef.onDeleteAnnotation(textHighlight);
  }

  public static isTextHighlight(highlightData: any): boolean {
    return (
      !!(
        highlightData.annotation &&
        highlightData.annotation.itlsData &&
        highlightData.annotation.itlsData.type &&
        (highlightData.annotation.itlsData.type === "PROVISION" ||
          highlightData.annotation.itlsData.type === "SAVE_AS_PROVISION")
      ) ||
      !!(
        highlightData.extraParams &&
        highlightData.extraParams.type &&
        (highlightData.extraParams.type === "PROVISION" ||
          highlightData.extraParams.type === "SAVE_AS_PROVISION")
      ) ||
      !!(
        highlightData.itlsData &&
        highlightData.itlsData.type &&
        highlightData.itlsData.type === "PROVISION"
      )
    );
  }

  public static getLatestHighlightFromHighlights(highlights) {
    return last(
      values({ ...highlights }).sort(
        (a: any, b: any) =>
          new Date(a.created).getTime() - new Date(b.created).getTime()
      )
    );
  }

  public static getTextHighlightType(
    highlightData: any
  ): TextHighlightTypeEnum {
    const { itlsData } = highlightData;
    const { type } = itlsData;
    if (highlightData && itlsData && type) {
      if (type === "PROVISION" || type === "SAVE_AS_PROVISION") {
        return TextHighlightTypeEnum.PROVISION;
      } else if (type === TextHighlightTypeEnum.BOOKMARK) {
        return TextHighlightTypeEnum.BOOKMARK;
      } else if (type === TextHighlightTypeEnum.COMMENT) {
        return TextHighlightTypeEnum.COMMENT;
      }
    }
  }

  public static isProvisionTextHighlight(highlightData: any): boolean {
    const { itlsData } = highlightData;
    const { type } = itlsData;
    if (highlightData && itlsData && type) {
      return type === "PROVISION" || type === "SAVE_AS_PROVISION";
    }
  }

  public static isBookmarkTextHighlight(highlightData: any): boolean {
    const { itlsData } = highlightData;
    const { type } = itlsData;
    if (highlightData && itlsData && type) {
      return type === TextHighlightTypeEnum.BOOKMARK;
    }
  }

  public static isCommentTextHighlight(highlightData: any): boolean {
    const { itlsData } = highlightData;
    const { type } = itlsData;
    if (highlightData && itlsData && type) {
      return type === TextHighlightTypeEnum.COMMENT;
    }
  }

  public static isTableTextHighlight(textHighlight: any): boolean {
    return !!(
      textHighlight.extraParams.data &&
      textHighlight.extraParams.data.type &&
      textHighlight.extraParams.data.type === "TABLE"
    );
  }

  public static convertTextHighlightType(
    type: HighlightTypeEnum,
    textHighlight: any,
    provisionType: string
  ) {
    textHighlight.itlsData.highlightType = type;
    textHighlight.color = `${type}${provisionType}`;
    return textHighlight;
  }

  public static changeTextHighlightsCommentUid(
    pdfViewerRef: any,
    firstPage: number,
    textHighlights: any[],
    commentUid: string
  ) {
    textHighlights.forEach((textHighlight, index) => {
      textHighlight.itlsData.commentData = {
        commentUid,
      };
      pdfViewerRef.onUpdateHighlight(
        firstPage + index,
        textHighlight.id,
        textHighlight
      );
    });
  }

  public static changeTextHighlightType(
    pdfViewerRef: any,
    type: HighlightTypeEnum,
    textHighlight: any,
    provisionType: string
  ) {
    const { id, page } = textHighlight;
    pdfViewerRef.onUpdateHighlight(
      page,
      id,
      AbstractTextHighlightsService.convertTextHighlightType(
        type,
        textHighlight,
        provisionType
      )
    );
  }

  public static removeTempMentionTextHighlightCreatedFromReader(mention) {
    if (!mention) return;
    if (mention.isTwoPageTextHighlight) {
      AbstractTextHighlightsService.removeTextHighlightsCreatedFromReader(
        mention.provisionHighlight
      );
    } else {
      const { highlightType } = mention;
      AbstractTextHighlightsService.onTextHighlightActionFromProvisionBox.next({
        type: ProvisionBoxActionTypeEnum.DELETE_TEMP_HIGHLIGHT,
        payload: {
          highlight: mention.provisionHighlight,
          highlightType,
        },
      });
    }
  }

  public static removeTextHighlightsCreatedFromReader(textHighlights: any[]) {
    textHighlights.forEach((item) => {
      AbstractTextHighlightsService.onTextHighlightActionFromProvisionBox.next({
        type: ProvisionBoxActionTypeEnum.DELETE_TEMP_HIGHLIGHT,
        payload: {
          textHighlight: item,
        },
      });
    });
  }

  public static convertTextHighlightToDocumentMentionTextHighlight(
    textHighlight
  ) {
    const _textHighlight = { ...textHighlight };
    _textHighlight.itlsData.type = "DOCUMENT_MENTION";
    _textHighlight.itlsData.highlightType = "HEAVY";
    return _textHighlight;
  }

  public changeTextHighlightTypeByMentionValueType(
    pdfViewerRef: any,
    page: number,
    mentionUid: string,
    valueType: string
  ) {
    const textHighlight: any =
      this.webAbstractionPageService.getHighlightMetaByProvisionOptionUid(
        mentionUid
      );
    if (textHighlight) {
      const { id } = textHighlight;
      textHighlight.color = valueType;
      textHighlight.itlsData.provisionData.type = valueType;
      pdfViewerRef.onUpdateHighlight(page, id, textHighlight);
    }
  }
}
