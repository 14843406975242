/* tslint:disable */
/* eslint-disable */
import { FolderStructureNodeDtoModel } from "./folder-structure-node-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("NodeNavigationResultModel")
export class NodeNavigationResultModel {
  /**
   * the category in the user's filesystem where this file exists
   */

  @JsonProperty("category", Any, true)
  category?:
    | "ALL"
    | "DELETED"
    | "FAVORITES"
    | "OWNED"
    | "SHARED"
    | "DOCUMENTS"
    | "GOOGLE_DRIVE" = undefined;

  /**
   * the path to this file
   */

  @JsonProperty("navigations", [FolderStructureNodeDtoModel], true)
  navigations?: Array<FolderStructureNodeDtoModel> = [];
}
