import { JsonObject, JsonProperty } from "json2typescript";
import { MapConvertService } from "@@intelease/web/common/services/map-convert.service";

@JsonObject("AbstractNamesSuggestionModel")
export class AbstractNamesSuggestionModel {
  @JsonProperty("numberPrefix", String)
  numberPrefix = "";

  @JsonProperty("numberSuffix", String)
  numberSuffix = "";

  @JsonProperty("nameToNumber", MapConvertService)
  nameToNumber: Map<string, number> = undefined;
}
