/* tslint:disable */
/* eslint-disable */
import { OldPermissionViewModel } from "./old-permission-view-model";
import { ViewUserModel } from "./view-user-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UserAndPermissionDataV2Model")
export class UserAndPermissionDataV2Model {
  @JsonProperty("permissions", [OldPermissionViewModel], true)
  permissions?: Array<OldPermissionViewModel> = [];

  @JsonProperty("user", ViewUserModel, true)
  user?: ViewUserModel = undefined;
}
