import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("LightNodeNameModel")
export class LightNodeNameModel {
  static view = "light";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("type", String)
  type: "RECORD" | "DIRECTORY" | "DOC_SET" | undefined = undefined;

  @JsonProperty("name", String)
  name = "";

  constructor(data?: Partial<LightNodeNameModel>) {
    Object.assign(this, data);
  }
}

@JsonObject("LightNodeNameV2Model")
export class LightNodeNameV2Model {
  static view = "light";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("type", String)
  type: "RECORD" | "DIRECTORY" | "DOC_SET" | undefined = undefined;

  @JsonProperty("name", String)
  name = "";

  constructor(data?: Partial<LightNodeNameV2Model>) {
    Object.assign(this, data);
  }
}
