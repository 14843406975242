/* tslint:disable */
/* eslint-disable */
import { GeneralPdfMentionHighlightDtoModel } from "./general-pdf-mention-highlight-dto-model";
import { MultiplePdfProvisionValViewModel } from "./multiple-pdf-provision-val-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MultiPdfProvViewAndHighlightsModel")
export class MultiPdfProvViewAndHighlightsModel {
  @JsonProperty("pdfHighlights", [GeneralPdfMentionHighlightDtoModel], true)
  pdfHighlights?: Array<GeneralPdfMentionHighlightDtoModel> = [];

  @JsonProperty("realValue", Boolean, true)
  realValue?: boolean = undefined;

  @JsonProperty("view", MultiplePdfProvisionValViewModel, true)
  view?: MultiplePdfProvisionValViewModel = undefined;
}
