/* tslint:disable */
/* eslint-disable */
import { QueueAccuracyStatsDtoModel } from "./queue-accuracy-stats-dto-model";
import { QueueDocTypeStatsModel } from "./queue-doc-type-stats-model";
import { QueueReviewerActivityStatsDtoModel } from "./queue-reviewer-activity-stats-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("QueueStatsDtoModel")
export class QueueStatsDtoModel {
  @JsonProperty("accuracyStats", QueueAccuracyStatsDtoModel, true)
  accuracyStats?: QueueAccuracyStatsDtoModel = undefined;

  @JsonProperty("docTypeToStats", Any, true)
  docTypeToStats?: {
    [key: string]: QueueDocTypeStatsModel;
  } = {};

  @JsonProperty(
    "reviewerActivityStats",
    QueueReviewerActivityStatsDtoModel,
    true
  )
  reviewerActivityStats?: QueueReviewerActivityStatsDtoModel = undefined;
}
