/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";

/**
 * one row of the option rolling reminders calculated table
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OptionRollingReminderItemModel")
export class OptionRollingReminderItemModel {
  /**
   * the text value of the option corresponding to these reminders
   */

  @JsonProperty("clauseSummary", String, true)
  clauseSummary?: string = "";

  /**
   * when the rolling reminder is effective, offset from notice date
   */

  @JsonProperty("effective", String, true)
  effective?: string = "";

  @JsonProperty("endDate", SimpleOpenApiDateModel, true)
  endDate?: SimpleOpenApiDateModel = undefined;

  /**
   * the reminder number (1-based)
   */

  @JsonProperty("number", Number, true)
  number?: number = undefined;

  /**
   * the option type
   */

  @JsonProperty("optionType", String, true)
  optionType?: string = "";

  @JsonProperty("startDate", SimpleOpenApiDateModel, true)
  startDate?: SimpleOpenApiDateModel = undefined;
}
