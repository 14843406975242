import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SchemaFormModule } from "@@intelease/schema-form";
import { ProgressModule } from "@@intelease/web/intelease/components";
import { ProvisionFormExportModalComponent } from "./provision-form-export-modal.component";
import { ProvisionFormExportModalService } from "./provision-form-export-modal.service";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { ItlsExportModule } from "@@intelease/web/ui";
import { ItlsProvisionFormExportModule } from "../../../ui/src";

const INTELEASE_MODULES = [ProgressModule.forRoot()];

const MATERIAL_MODULES = [MatDialogModule, MatButtonModule];

@NgModule({
  declarations: [ProvisionFormExportModalComponent],
  imports: [
    CommonModule,
    SchemaFormModule.forRoot(),
    ...INTELEASE_MODULES,
    ...MATERIAL_MODULES,
    ItlsProvisionFormExportModule,
  ],
  providers: [ProvisionFormExportModalService],
  exports: [ProvisionFormExportModalComponent],
})
export class ProvisionFormExportModalModule {}
