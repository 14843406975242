import {
  DoubleProvisionUnitOrCurrencyChangeDtoModel,
  FullValMultiPdfProvViewModel,
  PartialValPdfProvisionViewModel,
} from "@@intelease/api-models/adex-api-model-src";
import { createAction, props } from "@ngrx/store";
import { FormModel } from "../models/provision-review.model";

export enum ProvisionReviewActionTypes {
  EXPAND_NOTE_PANEL = "[ProvisionReview] Expand note panel!",
  EXPAND_SNIPPED_PANEL = "[ProvisionReview] Expand snipped panel!",
  GO_TO_PROVISION_OPTION_HIGHLIGHT_ON_VIEWER = "[ProvisionReview] Go to provision option highlight on viewer!",
  PICK_SNIPPET = "[ProvisionReview] Pick Snippet!",
  DELETE_PROVISION_OPTION = "[ProvisionReview] Delete Provision Option!",
  DELETE_PROVISION_OPTION_CONFIRMED = "[ProvisionReview] Delete Provision Option Confirmed!",
  DELETE_PROVISION_OPTION_CANCELED = "[ProvisionReview] Delete Provision Option Canceled!",
  DELETE_PROVISION_OPTION_SUCCESS = "[ProvisionReview] Delete Provision Option Success!",
  DELETE_PROVISION_OPTION_FAIL = "[ProvisionReview] Delete Provision Option Fail!",
  SAVE_PROVISION_OPTION = "[ProvisionReview] Save Provision Option!",
  SAVE_PROVISION_OPTION_SUCCESS = "[ProvisionReview] Save Provision Option Success!",
  SAVE_PROVISION_OPTION_FAIL = "[ProvisionReview] Save Provision Option Fail!",
  OPEN_TABLE_PROVISION = "[ProvisionReview] Open Table Provision!",
  OPEN_TABLE_PROVISION_SUCCESS = "[ProvisionReview] Open Table Provision Success!",
  SELECT_PROVISION_OPTION = "[ProvisionReview] Select Provision Option!",
  SELECT_PROVISION_OPTION_SUCCESS = "[ProvisionReview] Select Provision Option Success!",
  UPDATE_PROVISION_OPTION_REVIEW_STATUS = "[ProvisionReview] Update Provision Option Review Status!",
  SCROLL_TO_PROVISION_OPTION = "[ProvisionReview] Scroll to Provision Option!",
  DUPLICATE_PROVISION_OPTION = "[ProvisionReview] Duplicate Provision Option!",
  UPDATE_PROVISION_OPTION_UID = "[ProvisionReview] Update Provision Option Uid!",
  SAVING_PROVISION_OPTION = "[ProvisionReview] Saving Provision Option!",
  SAVING_PROVISION_OPTION_FINISHED = "[ProvisionReview] Saving Provision Option Finished!",
}

export const expandNotePanel = createAction(
  ProvisionReviewActionTypes.EXPAND_NOTE_PANEL,
  props<{ uid: PartialValPdfProvisionViewModel["uid"] }>()
);

export const expandSnippedPanel = createAction(
  ProvisionReviewActionTypes.EXPAND_SNIPPED_PANEL,
  props<{ uid: PartialValPdfProvisionViewModel["uid"] }>()
);

export const goToProvisionOptionHighlightOnViewer = createAction(
  ProvisionReviewActionTypes.GO_TO_PROVISION_OPTION_HIGHLIGHT_ON_VIEWER,
  props<{ payload: PartialValPdfProvisionViewModel }>()
);

export const pickSnippet = createAction(
  ProvisionReviewActionTypes.PICK_SNIPPET,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      snippet: PartialValPdfProvisionViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const deleteProvisionOption = createAction(
  ProvisionReviewActionTypes.DELETE_PROVISION_OPTION,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const deleteProvisionOptionConfirmed = createAction(
  ProvisionReviewActionTypes.DELETE_PROVISION_OPTION_CONFIRMED,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const deleteProvisionOptionCanceled = createAction(
  ProvisionReviewActionTypes.DELETE_PROVISION_OPTION_CANCELED,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const deleteProvisionOptionSuccess = createAction(
  ProvisionReviewActionTypes.DELETE_PROVISION_OPTION_SUCCESS,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const deleteProvisionOptionFailed = createAction(
  ProvisionReviewActionTypes.DELETE_PROVISION_OPTION_FAIL,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const saveProvisionOption = createAction(
  ProvisionReviewActionTypes.SAVE_PROVISION_OPTION,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
      model: FormModel;
      doubleProvisionUnitOrCurrencyChanged?: DoubleProvisionUnitOrCurrencyChangeDtoModel;
    };
  }>()
);

export const saveProvisionOptionSuccess = createAction(
  ProvisionReviewActionTypes.SAVE_PROVISION_OPTION_SUCCESS,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const saveProvisionOptionFailed = createAction(
  ProvisionReviewActionTypes.SAVE_PROVISION_OPTION_FAIL,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const openTableProvision = createAction(
  ProvisionReviewActionTypes.OPEN_TABLE_PROVISION,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const openTableProvisionSuccess = createAction(
  ProvisionReviewActionTypes.OPEN_TABLE_PROVISION_SUCCESS,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const selectProvisionOption = createAction(
  ProvisionReviewActionTypes.SELECT_PROVISION_OPTION,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const selectProvisionOptionSuccess = createAction(
  ProvisionReviewActionTypes.SELECT_PROVISION_OPTION_SUCCESS,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const updateProvisionOptionReviewStatus = createAction(
  ProvisionReviewActionTypes.UPDATE_PROVISION_OPTION_REVIEW_STATUS,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const scrollToProvisionOption = createAction(
  ProvisionReviewActionTypes.SCROLL_TO_PROVISION_OPTION,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const updateProvisionOptionUid = createAction(
  ProvisionReviewActionTypes.UPDATE_PROVISION_OPTION_UID,
  props<{
    payload: {
      provisionOptionOldUid: PartialValPdfProvisionViewModel["uid"];
      provisionOptionNewUid: PartialValPdfProvisionViewModel["uid"];
    };
  }>()
);

export const duplicateProvisionOption = createAction(
  ProvisionReviewActionTypes.DUPLICATE_PROVISION_OPTION,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
    };
  }>()
);

export const savingProvisionOption = createAction(
  ProvisionReviewActionTypes.SAVING_PROVISION_OPTION,
  props<{
    payload: {
      provisionUid: FullValMultiPdfProvViewModel["uid"];
      provisionOptionUid: PartialValPdfProvisionViewModel["uid"];
    };
  }>()
);

export const savingProvisionOptionFinished = createAction(
  ProvisionReviewActionTypes.SAVING_PROVISION_OPTION_FINISHED
);
