import { ISchedule } from "tui-calendar";

export const EVENT_DETAIL_TEMPLATE_CONFIG = {
  popupDetailUser: function (schedule) {
    return "User : " + (schedule.attendees || []).join(", ");
  },
  popupDetailState: function (schedule) {
    return "State : " + schedule.state || "Busy";
  },
  popupDetailRepeat: function (schedule) {
    return "Repeat : " + schedule.recurrenceRule;
  },
  popupDetailBody: function (schedule: ISchedule) {
    const {
      abstractLink,
      abstractName,
      // provisionLink,
      // provisionName,
      mentionLink,
      mentionValue,
      generalFieldName,
    } = schedule.raw;
    return (
      `<strong>Record: </strong><a target="_blank" href="${abstractLink}">${abstractName}</a><br/>` +
      (generalFieldName
        ? "<strong>Name: </strong><br/>" + generalFieldName
        : "") +
      (mentionLink
        ? `<strong>Mention: </strong><a target="_blank" href="${mentionLink}">${mentionValue}</a>`
        : "<br/>")
    );
  },
};
