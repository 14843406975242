/* tslint:disable */
/* eslint-disable */
import { DashboardDtoModel } from "./dashboard-dto-model";
import { OApiReturnParamsRequestDashboardViewTypeModel } from "./o-api-return-params-request-dashboard-view-type-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqDashboardDtoModel")
export class OApiReqDashboardDtoModel {
  @JsonProperty("data", DashboardDtoModel, true)
  data?: DashboardDtoModel = undefined;

  @JsonProperty("replace", Boolean, true)
  replace?: boolean = undefined;

  @JsonProperty(
    "returnParams",
    OApiReturnParamsRequestDashboardViewTypeModel,
    true
  )
  returnParams?: OApiReturnParamsRequestDashboardViewTypeModel = undefined;
}
