import { finalize } from "rxjs/operators";
import { EntityPermissionsService } from "../../../ui/src/lib/new-share-entity/services/entity-permissions.service";
import { AppAuthorities } from "@@intelease/web/intelease/constants";
import { ChangeDetectorRef, Directive, Input } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { InteleasePermissionsService } from "@@intelease/web/intelease/services";
import { EntityType } from "@@intelease/web/utils";
import { isEqual } from "lodash";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseEntityPermissionComponent {
  protected givenEntityPermissionsChanged$: Subject<string[]> = new Subject<
    string[]
  >();
  @Input()
  givenEntityPermissions: string[];
  // _givenEntityPermissions: string[]
  // @Input()
  // set givenEntityPermissions(givenEntityPermissions: string[]) {
  //     this._givenEntityPermissions = givenEntityPermissions
  //     this.givenEntityPermissionsChanged$.next(givenEntityPermissions)
  // }
  //
  // get givenEntityPermissions(): string[] {
  //     console.log(this._givenEntityPermissions)
  //     return this._givenEntityPermissions
  // }

  entityPermissionsAreLoading = false;
  AUTH = AppAuthorities;
  componentPermissions: { [key: string]: boolean } = {
    isReadable: false,
    isEditable: false,
    isDeletable: false,
    isExportable: false,
    isMovable: false,
    isCommentable: false,
    isShareable: false,
  };

  protected loadEntityPermissions(
    entityUid: string,
    entityType: EntityType,
    entityPermissionsService: EntityPermissionsService,
    cdr?: ChangeDetectorRef
  ): Observable<string[]> {
    this.resetPermissions();
    const loaded$ = new Subject<string[]>();
    this.entityPermissionsAreLoading = true;
    entityPermissionsService
      .getPermissionForCurrentUser(entityUid, entityType)
      .pipe(
        finalize(() => {
          this.entityPermissionsAreLoading = false;
          if (cdr) {
            cdr.detectChanges();
          }
        })
      )
      .subscribe(
        (permissions) => {
          if (
            !this.givenEntityPermissions ||
            !isEqual(this.givenEntityPermissions, permissions)
          ) {
            this.givenEntityPermissions = permissions;
            this.setComponentPermissions(permissions);
            this.givenEntityPermissionsChanged$.next(permissions);
            loaded$.next(permissions);
            loaded$.complete();
          }
        },
        (error) => loaded$.error(error)
      );
    return loaded$.asObservable();
  }

  protected emptyGivenOrHasPermissions(expected: string | string[]): boolean {
    if (!this.givenEntityPermissions) {
      return true;
    }

    return InteleasePermissionsService.allExpectationsCovered(
      expected,
      this.givenEntityPermissions
    );
  }

  private setComponentPermissions(permissions) {
    this.componentPermissions = {
      ...this.componentPermissions,
      isReadable: permissions.includes(AppAuthorities.READ),
      isEditable: permissions.includes(AppAuthorities.EDIT),
      isDeletable: permissions.includes(AppAuthorities.DELETE),
      isExportable: permissions.includes(AppAuthorities.EXPORT),
      isMovable: permissions.includes(AppAuthorities.MOVE),
      isCommentable: permissions.includes(AppAuthorities.COMMENT),
      isShareable: permissions.includes(AppAuthorities.SHARE),
    };
  }

  private resetPermissions() {
    this.componentPermissions = {
      ...this.componentPermissions,
      isReadable: false,
      isEditable: false,
      isDeletable: false,
      isExportable: false,
      isMovable: false,
      isCommentable: false,
      isShareable: false,
    };
  }
}
