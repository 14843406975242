import {
  FullProvisionGroupModel,
  FullProvisionModel,
  MinimalProvisionModel,
} from "@@intelease/web/common/models";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "validateOnAddProvisionFields",
  pure: true,
})
export class ValidateOnAddProvisionFieldsPipe implements PipeTransform {
  constructor(private inputValidationService: InputValidationService) {}

  transform(payload: {
    newProvision: MinimalProvisionModel;
    chosenProvisionGroup: FullProvisionGroupModel;
    preProvisionLocation: FullProvisionModel;
  }): boolean {
    const { chosenProvisionGroup, newProvision, preProvisionLocation } =
      payload;

    const idx =
      preProvisionLocation &&
      preProvisionLocation.provisionInfo &&
      preProvisionLocation.provisionInfo.name
        ? this.findIdxOfSelectedLocation(
            preProvisionLocation,
            chosenProvisionGroup?.provisions
          )
        : -1;

    return (
      !this.inputValidationService.usefulObjectTextField(newProvision, "uid") ||
      !this.inputValidationService.usefulObjectTextField(
        chosenProvisionGroup,
        "uid"
      ) ||
      !(
        preProvisionLocation &&
        preProvisionLocation.provisionInfo &&
        preProvisionLocation.provisionInfo.name
      ) ||
      idx === -1
    );
  }

  private findIdxOfSelectedLocation(
    provisionLocation: MinimalProvisionModel,
    provisionsList: MinimalProvisionModel[]
  ): number {
    if (provisionLocation.provisionInfo.name === "Add to start of category") {
      return 0;
    }
    for (let idx = 0; idx < provisionsList?.length; idx++) {
      const provision = provisionsList[idx];
      if (provision.uid === provisionLocation.uid) {
        return idx + 1;
      }
    }
    return -1;
  }
}

@Pipe({
  name: "tooltipOnAddProvisionFields",
  pure: true,
})
export class TooltipOnAddProvisionFieldsPipe implements PipeTransform {
  constructor(private inputValidationService: InputValidationService) {}

  transform(payload: {
    newProvision: MinimalProvisionModel;
    chosenProvisionGroup: FullProvisionGroupModel;
    preProvisionLocation: FullProvisionModel;
  }): string {
    const { chosenProvisionGroup, newProvision, preProvisionLocation } =
      payload;

    if (
      !this.inputValidationService.usefulObjectTextField(newProvision, "uid")
    ) {
      return "You must choose a new provision to add!";
    }
    if (
      !this.inputValidationService.usefulObjectTextField(
        chosenProvisionGroup,
        "uid"
      )
    ) {
      return "You must choose an existing provision category to add to!";
    }
    if (
      !(
        preProvisionLocation &&
        preProvisionLocation.provisionInfo &&
        preProvisionLocation.provisionInfo.name
      )
    ) {
      return "You must choose a provision location in the selected provision category!";
    }
    const idx = this.findIdxOfSelectedLocation(
      preProvisionLocation,
      chosenProvisionGroup.provisions
    );
    if (idx === -1) {
      return "Invalid provision location selected in the provision category!";
    }
    return "";
  }

  private findIdxOfSelectedLocation(
    provisionLocation: MinimalProvisionModel,
    provisionsList: MinimalProvisionModel[]
  ): number {
    if (provisionLocation.provisionInfo.name === "Add to start of category") {
      return 0;
    }
    for (let idx = 0; idx < provisionsList?.length; idx++) {
      const provision = provisionsList[idx];
      if (provision.uid === provisionLocation.uid) {
        return idx + 1;
      }
    }
    return -1;
  }
}
