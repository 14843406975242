/* tslint:disable */
/* eslint-disable */
import { JsonNodeModel } from "./json-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("HighlightPartialMentionApiDtoModel")
export class HighlightPartialMentionApiDtoModel {
  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  /**
   * the format of a structured value
   */

  @JsonProperty("format", Any, true)
  format?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;

  @JsonProperty("formatValues", Any, true)
  formatValues?: {
    [key: string]: JsonNodeModel;
  } = {};

  @JsonProperty("highlightType", Any, true)
  highlightType?: "TEXT" | "AREA" = undefined;

  @JsonProperty("originalText", String, true)
  originalText?: string = "";

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  @JsonProperty("pdfHighlightIds", [String], true)
  pdfHighlightIds?: Array<string> = [];

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("value", Any, true)
  value?: any = undefined;
}
