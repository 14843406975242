/* tslint:disable */
/* eslint-disable */
import { UploadUserActionModel } from "./upload-user-action-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UploadUserActionsModel")
export class UploadUserActionsModel {
  @JsonProperty("actions", [UploadUserActionModel], true)
  actions?: Array<UploadUserActionModel> = [];
}
