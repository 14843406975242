/* tslint:disable */
/* eslint-disable */
import { CustomCommentDataDtoModel } from "./custom-comment-data-dto-model";
import { CustomProvisionDataDtoModel } from "./custom-provision-data-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CustomDataDtoModel")
export class CustomDataDtoModel {
  @JsonProperty("commentData", CustomCommentDataDtoModel, true)
  commentData?: CustomCommentDataDtoModel = undefined;

  @JsonProperty("highlightType", String, true)
  highlightType?: string = "";

  @JsonProperty("provisionData", CustomProvisionDataDtoModel, true)
  provisionData?: CustomProvisionDataDtoModel = undefined;

  @JsonProperty("type", String, true)
  type?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
