import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { map } from "rxjs/operators";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";

@Injectable({
  providedIn: "root",
})
export class DeleteApiService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Send a DELETE request to delete an existing entity in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including final uid)
   * @param uid the final uid at end of url, representing the object to be deleted
   * @param view the view of the object to return
   * @param classRef the class of the returned object
   * @return the deleted object
   */
  sendRequest<T extends object>(
    apiVersion: string,
    baseUrl: string,
    uid: string,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    const params = {
      params: {
        view: view,
      },
    };
    return this.helpSendRequest(params, apiVersion, baseUrl, uid, classRef);
  }

  /**
   * Send a DELETE request to delete an existing entity in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including final uid)
   * @param uid the final uid at end of url, representing the object to be deleted
   */
  sendRequestNoResponse(
    apiVersion: string,
    baseUrl: string,
    uid: string
  ): void {
    this.sendRequestNoView(apiVersion, baseUrl, uid).subscribe((res) => {});
  }

  /**
   * Send a DELETE request to delete an existing entity in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including final uid)
   * @param uid the final uid at end of url, representing the object to be deleted
   * @param params
   */
  sendRequestNoView(
    apiVersion: string,
    baseUrl: string,
    uid: string,
    params?: any
  ): Observable<ServerResponseModel> {
    const _params = {
      params: {
        ...params,
        view: "none",
      },
    };
    return this.helpSendRequestNoView(_params, apiVersion, baseUrl, uid);
  }

  /*
   * Below are private helper methods
   */

  private helpSendRequest<T extends object>(
    params: { params: any },
    apiVersion: string,
    baseUrl: string,
    uid: string,
    classRef: new () => T
  ): Observable<T> {
    return this.helpSendRequestNoView(params, apiVersion, baseUrl, uid).pipe(
      map((res) => Json2TypescriptHelper.convertToEntity(res.data, classRef))
    );
  }

  private helpSendRequestNoView(
    params: { params: any },
    apiVersion: string,
    baseUrl: string,
    uid: string
  ): Observable<ServerResponseModel> {
    return this.httpClient.delete<ServerResponseModel>(
      apiVersion + baseUrl + "/" + uid,
      params
    );
  }
}
