import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProvisionFormsService } from "@@intelease/web/forms/src/lib/services";
import { PaginationModel } from "@@intelease/web/intelease/models";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { SortDirectionEnum } from "@@intelease/web/common/enums/sort-direction.enum";
import { finalize } from "rxjs/operators";
import { cloneDeep, findIndex } from "lodash";
import {
  DocumentTypeModel,
  MinimalProvisionFormModel,
} from "@@intelease/web/common/models";
import {
  ProvisionFormService,
  QueueService,
} from "@@intelease/api-models/adex-api-model-src";

export interface FilteredProvisionForm {
  uid: string;
  name: string;
  documentTypes: string[];
}

const DEFAULT_PROVISION_FORM_SORT =
  "lastModifiedDate::Last Modified Date::" +
  PROVISIONS_DATA_CONST.DATE.name +
  "::" +
  SortDirectionEnum.DESC;

export interface ProvisionsGroup {
  documentType: string;
  items: FilteredProvisionForm[];
}

@Component({
  selector: "il-choose-form",
  templateUrl: "./choose-form.component.html",
  styleUrls: ["./choose-form.component.scss"],
})
export class ChooseFormComponent implements OnInit {
  searchInput = "";
  isLoading = false;
  allProvisionForms: FilteredProvisionForm[] = [];
  filteredProvisionsGroups: ProvisionsGroup[] = [];
  selectedProvisionForm: FilteredProvisionForm;
  recentForms: FilteredProvisionForm[] = [];
  @Output()
  selectedProvisionFormUidEvent: EventEmitter<FilteredProvisionForm> = new EventEmitter<FilteredProvisionForm>();
  @Input() verticalView = true;

  constructor(private provisionFormsService: ProvisionFormsService) {}

  ngOnInit(): void {
    this.fetchInitialData();
  }

  private fetchInitialData() {
    this.isLoading = true;
    this.provisionFormsService
      .searchProvisionForms(
        new PaginationModel(1, 1000),
        DEFAULT_PROVISION_FORM_SORT
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res) => {
        this.allProvisionForms = res.items;
        this.getFilteredProvisionForms();
      });
  }

  private getFilteredProvisionForms() {
    if (this.searchInput?.length === 0) {
      this.filteredProvisionsGroups = this.createProvisionsGroups(
        this.allProvisionForms
      );
    } else {
      const lowerCasedSearchInput = this.searchInput.toLowerCase();
      const filteredProvisionForms = this.allProvisionForms.filter(
        (provisionForm) =>
          provisionForm.name.toLowerCase().includes(lowerCasedSearchInput)
      );
      this.filteredProvisionsGroups = this.createProvisionsGroups(
        filteredProvisionForms
      );
    }
  }

  private createProvisionsGroups(
    provisionForms: FilteredProvisionForm[]
  ): ProvisionsGroup[] {
    const provisionsGroupList = [];
    const documentTypeToProvisionsGroup = new Map<string, ProvisionsGroup>();
    for (const provisionForm of provisionForms) {
      for (const documentType of provisionForm.documentTypes) {
        let provisionsGroup = documentTypeToProvisionsGroup.get(documentType);
        if (!provisionsGroup) {
          provisionsGroup = {
            documentType: documentType,
            items: [],
          };
          documentTypeToProvisionsGroup.set(documentType, provisionsGroup);
          provisionsGroupList.push(provisionsGroup);
        }
        provisionsGroup.items.push(provisionForm);
      }
    }
    return provisionsGroupList;
  }

  onSearchInputChanged() {
    this.getFilteredProvisionForms();
  }

  onSelectProvisionForm(filteredForm: FilteredProvisionForm) {
    this.selectedProvisionForm = { ...filteredForm };
    this.selectedProvisionFormUidEvent.emit({ ...filteredForm });
  }

  reset() {
    this.searchInput = "";
    this.selectedProvisionForm = undefined;
    this.getFilteredProvisionForms();
  }

  addProvisionFormToRecentForms(uploadedProvisionForm: FilteredProvisionForm) {
    const existingProvisionFormIdx = findIndex(
      this.recentForms,
      (recentForm) => recentForm.uid === uploadedProvisionForm.uid
    );
    if (existingProvisionFormIdx !== 0) {
      // if it's already 0 index ignore it
      if (existingProvisionFormIdx !== -1) {
        this.recentForms.splice(existingProvisionFormIdx, 1);
      } else if (this.recentForms.length >= 5) {
        this.recentForms.splice(this.recentForms.length - 1, 1);
      }
      this.recentForms.unshift(cloneDeep(uploadedProvisionForm));
    }
  }
}
