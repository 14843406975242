import { Component, DestroyRef, inject, Inject, OnInit } from "@angular/core";
import {
  ModalInputModel,
  ModalResponseModel,
} from "@@intelease/web/intelease/models";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  IssueExportModel,
  LightNodeNameModel,
} from "@@intelease/web/common/models";
import { CommonFacade } from "@@intelease/app-state/common";
import { cloneDeep, keyBy } from "lodash";
import { SettingItemKeys } from "../../../../app-models/settings/src";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-abstract-export-modal-component",
  templateUrl: "./abstract-export-modal.component.html",
  styleUrls: ["./abstract-export-modal.component.scss"],
})
export class AbstractExportModalComponent implements OnInit {
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;
  fileNode: LightNodeNameModel;
  fileNodes: LightNodeNameModel[];
  issueExportModel: IssueExportModel;
  exportSettings = {
    includeNotes: false,
    includeAnnotatedDocs: false,
    excludeEmptyProvisions: false,
    includeSectionHeaders: false,
    includeSourceAttribution: false,
    emptyProvisionText: "N/A",
  };
  entityType: "RECORD" | "DIRECTORY" | "ISSUE" = "RECORD";
  accountSettingsMap = {};

  destroyRef = inject(DestroyRef);

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ModalInputModel,
    public dialogRef: MatDialogRef<AbstractExportModalComponent>,
    private commonFacade: CommonFacade
  ) {}

  ngOnInit() {
    this.initModalData();
    this.initSettings();
  }

  private initModalData() {
    if (this.dialogData) {
      const { fileNodes, fileNode, type, issueExportModel } =
        this.dialogData.payload;
      this.fileNodes = fileNodes;
      this.fileNode = fileNode;
      this.entityType = type;
      this.issueExportModel = issueExportModel;
    }
  }

  onDismissModalClick() {
    this.dialogRef.close({ ...this.modalRes, type: "DISMISS" });
  }

  private closeModal() {
    this.modalRes.data = {};
    this.dialogRef.close({ ...this.modalRes, type: "CLOSE" });
  }

  onCancelExportAbstractClick() {
    this.onDismissModalClick();
  }

  onAbstractExport() {
    this.closeModal();
  }

  private initSettings() {
    this.commonFacade.getAccountSettings$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          this.accountSettingsMap = keyBy(cloneDeep(res), "key");
          if (this.accountSettingsMap[SettingItemKeys.INCLUDE_ANNOTATED_DOCS])
            this.exportSettings.includeAnnotatedDocs =
              this.accountSettingsMap[
                SettingItemKeys.INCLUDE_ANNOTATED_DOCS
              ].value;
          if (this.accountSettingsMap[SettingItemKeys.INCLUDE_NOTES])
            this.exportSettings.includeNotes =
              this.accountSettingsMap[SettingItemKeys.INCLUDE_NOTES].value;
          if (this.accountSettingsMap[SettingItemKeys.EXCLUDE_EMPTY_PROVISIONS])
            this.exportSettings.excludeEmptyProvisions =
              this.accountSettingsMap[
                SettingItemKeys.EXCLUDE_EMPTY_PROVISIONS
              ].value;
          if (this.accountSettingsMap[SettingItemKeys.INCLUDE_SECTION_HEADER])
            this.exportSettings.includeSectionHeaders =
              this.accountSettingsMap[
                SettingItemKeys.INCLUDE_SECTION_HEADER
              ].value;
          if (
            this.accountSettingsMap[SettingItemKeys.INCLUDE_SOURCE_ATTRIBUTION]
          )
            this.exportSettings.includeSourceAttribution =
              this.accountSettingsMap[
                SettingItemKeys.INCLUDE_SOURCE_ATTRIBUTION
              ].value;
          if (this.accountSettingsMap[SettingItemKeys.EMPTY_PROVISION_TEXT])
            this.exportSettings.emptyProvisionText =
              this.accountSettingsMap[
                SettingItemKeys.EMPTY_PROVISION_TEXT
              ].value;
        }
      });
  }
}
