import { NestedGroupProvisionSubfieldModel } from "@@intelease/api-models/adex-api-model-src";
import { Any, JsonObject, JsonProperty } from "json2typescript";

@JsonObject("MinimalProvisionModel")
export class MinimalProvisionModel {
  static view = "minimal";

  constructor(name?: string, categoryName?: string) {
    this.name = name;
    this.categoryName = categoryName;
  }

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("type", String)
  type = "";

  @JsonProperty("possibleValues", [String], true)
  possibleValues: string[] = undefined; // Populated for SINGLE_CAT and MULTIPLE_CAT provision types

  @JsonProperty("possibleUnits", [String], true)
  possibleUnits: string[] = undefined; // Populated for DOUBLE provision type

  @JsonProperty("possibleCurrencies", [String], true)
  possibleCurrencies: string[] = undefined; // Populated for MONEY provision type

  @JsonProperty("possibleStates", [String], true)
  possibleStates: string[] = undefined; // Populated for ADDRESS provision type

  @JsonProperty("possibleCountries", [String], true)
  possibleCountries: string[] = undefined; // Populated for ADDRESS provision type

  @JsonProperty("properNounType", String, true)
  properNounType: string = undefined; // Populated for PROPER_NOUN provision type

  @JsonProperty("fieldType", String, true)
  fieldType: "DEFAULT" | "MANUAL" = "DEFAULT";

  @JsonProperty("allowedTypes", [String], true)
  allowedTypes: string[] = [];

  optionNum: number;

  isTop = false;

  categoryName? = "";

  provisionInfo: any = {};

  @JsonProperty(
    "nestedGroupSubfields",
    [NestedGroupProvisionSubfieldModel],
    true
  )
  nestedGroupSubfields?: Array<NestedGroupProvisionSubfieldModel> = [];

  @JsonProperty("nestedProcessingMode", String, true)
  nestedProcessingMode?: "FLEXIBLE" | "STRICT" = undefined;

  @JsonProperty("singleton", Boolean, true)
  singleton?: boolean = undefined;

  @JsonProperty("modelKey", String, true)
  modelKey? = "";

  @JsonProperty("nestedGroupFormats", Any, true)
  nestedGroupFormats?: Array<"ADDRESS" | "OTHER"> = [];
}
