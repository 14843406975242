/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UploadedDocumentResultDtoModel")
export class UploadedDocumentResultDtoModel {
  @JsonProperty("directoryPath", String, true)
  directoryPath?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("recordUid", String, true)
  recordUid?: string = "";
}
