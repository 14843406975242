/* tslint:disable */
/* eslint-disable */
import { FullSearchItemViewModel } from "./full-search-item-view-model";
import { PaginationDataModel } from "./pagination-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiMultiEntityContainerResponseFullSearchItemViewModel")
export class OApiMultiEntityContainerResponseFullSearchItemViewModel {
  @JsonProperty("items", [FullSearchItemViewModel], true)
  items?: Array<FullSearchItemViewModel> = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
