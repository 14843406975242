/* tslint:disable */
/* eslint-disable */

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EditProvisionNotesRespV3DtoModel")
export class EditProvisionNotesRespV3DtoModel {
  @JsonProperty("mentionUid", String, true)
  mentionUid?: string = "";

  @JsonProperty("notes", String, true)
  notes?: string = "";

  @JsonProperty("reviewStatus", Any, true)
  reviewStatus?:
    | "REVIEWED"
    | "NEED_REVIEW"
    | "NEED_REVIEW_TOUCHED"
    | "DEFAULT"
    | "COMPLETED_BUT_NEED_REVIEW" = undefined;
}
