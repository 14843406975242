/* tslint:disable */
/* eslint-disable */
import { EditReviewStatusApiDtoModel } from "./edit-review-status-api-dto-model";
import { OApiReturnParamsRequestMultiPdfProvViewTypeModel } from "./o-api-return-params-request-multi-pdf-prov-view-type-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqEditReviewStatusDtoModel")
export class OApiReqEditReviewStatusDtoModel {
  @JsonProperty("data", EditReviewStatusApiDtoModel, true)
  data?: EditReviewStatusApiDtoModel = undefined;

  @JsonProperty(
    "returnParams",
    OApiReturnParamsRequestMultiPdfProvViewTypeModel,
    true
  )
  returnParams?: OApiReturnParamsRequestMultiPdfProvViewTypeModel = undefined;
}
