import { Any, JsonObject, JsonProperty } from "json2typescript";
import { SettingItemFormatEnum } from "./enums";

@JsonObject("SettingItemModel")
export class SettingItemModel {
  static view = "full";

  @JsonProperty("key", String)
  key = "";

  @JsonProperty("value", Any)
  value = undefined;

  @JsonProperty("format", String)
  format: SettingItemFormatEnum = undefined;

  @JsonProperty("source", String)
  source: SettingItemFormatEnum = undefined;

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("possibleValues", [Any])
  possibleValues: Array<Any> = [];
}
