/* tslint:disable */
/* eslint-disable */
import { OptionMultipleReminderItemModel } from "./option-multiple-reminder-item-model";
import { PaginationDataModel } from "./pagination-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiMultiEntityContainerResponseOptionMultipleReminderItemModel")
export class OApiMultiEntityContainerResponseOptionMultipleReminderItemModel {
  @JsonProperty("items", [OptionMultipleReminderItemModel], true)
  items?: Array<OptionMultipleReminderItemModel> = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
