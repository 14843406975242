import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { combineLatest, Observable } from "rxjs";
import scrollIntoView from "scroll-into-view-if-needed";
import { first as _first, last as _last } from "lodash";
import { PdfReaderService } from "@@intelease/web/ui/src/lib/pdf-reader/pdf-reader.service";
import { last, map, switchMap, take } from "rxjs/operators";
import { environment } from "../../../../../../apps/ui/src/environments/environment";
import { BaseEntityPermissionComponent } from "@@intelease/web/common/components";
import { AbstractTextHighlightsService } from "@@intelease/web/abstraction-page/src/lib/services/abstract-text-highlights.service";
import { ProvisionInfoModel } from "@@intelease/app-models/provision";
import { RecordReviewModeEnum } from "@@intelease/app-models/common";
import { WebAbstractionPageService } from "@@intelease/web/abstraction-page/src/lib/services/web-abstraction-page.service";
import { ActivatedRoute } from "@angular/router";
import {
  AbstractReviewFacade,
  MentionEditTrackerFacade,
} from "@@intelease/app-state/abstract-review/src";
import { firstNotNill } from "@@intelease/web/utils/rxjs-operators";
import { UserInfoService } from "@@intelease/web/intelease/services";
import { generateUUID } from "@@intelease/web/utils";
import { LocalStorageKey } from "@common/enums/local-storage.keys";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-pdf-reader",
  templateUrl: "./pdf-reader.component.html",
  styleUrls: ["./pdf-reader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfReaderComponent
  extends BaseEntityPermissionComponent
  implements OnInit
{
  constructor(
    private applicationRef: ApplicationRef,
    public domSanitizer: DomSanitizer,
    private pdfReaderService: PdfReaderService,
    private readonly ngZone: NgZone,
    private readonly abstractReviewFacade: AbstractReviewFacade,
    private readonly mentionEditTrackerFacade: MentionEditTrackerFacade,
    private readonly webAbstractionPageService: WebAbstractionPageService
  ) {
    super();
  }

  private static readonly documentUrl: string =
    "assets/pdf-reader/web/index.html";
  @Input() isReadOnly = false;
  @Input() isCommentMode = false;
  @Input() readerVersion: RecordReviewModeEnum = RecordReviewModeEnum.V1;
  @Input() pdfSrc = "";
  @Input() pdfReaderInstanceId = "baseDocument";
  @Input() viewerContainerClass = "defaultViewerContainerClass";
  @Input() isRangeRequest = true;
  @Input() permission: (
    | "READ"
    | "EDIT"
    | "DELETE"
    | "EXPORT"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  )[];
  @ViewChild("pdfviewer", { static: true }) pdfviewer: ElementRef;
  @Output() readerIsReadyOutput: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentLoadedOutput: EventEmitter<any> = new EventEmitter<any>();
  @Output() newAnnotation: EventEmitter<any> = new EventEmitter<any>();
  @Output() newBookmark: EventEmitter<any> = new EventEmitter<any>();
  @Output() newComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() annotationUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() annotationClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() docMetaUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() annotationDeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewerHeightChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewerScrollChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() zoomChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() firstPageLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  selectedPageLoaded: EventEmitter<number> = new EventEmitter<number>();

  isTestbed = environment.testbed;
  pages = [];

  nestedMentionUidUnderLocate?: string;
  nestedSubfieldKeyUnderLocate?: string;

  get pdfReaderContentWindow() {
    return this.pdfviewer.nativeElement.contentWindow["InteleasePDFReader"];
  }

  destroyRef = inject(DestroyRef);

  private prepareReaderAnnotations(
    pageMeta,
    highlightKey: "textHighlights" | "areaHighlights"
  ) {
    const highlights: any = pageMeta[highlightKey];
    Object.values(highlights).map((highlight: any) => {
      if (
        highlight &&
        highlight.itlsData &&
        highlight.itlsData.type === "PROVISION"
      ) {
        highlight.color =
          (highlight.itlsData.highlightType || "HEAVY") + highlight.color;
      }
    });
  }

  private filterOutIrrelevantNestedMentions(pageMeta: any) {
    const highlightTypes = ["textHighlights", "areaHighlights"];
    const toBeRemovedHighlightIds: string[] = [];
    const _ignore = highlightTypes.forEach((highlightKey) => {
      const highlights: any = pageMeta[highlightKey];
      Object.values(highlights).map((highlight: any) => {
        if (
          highlight &&
          highlight.itlsData &&
          highlight.itlsData.type === "PROVISION"
        ) {
          if (highlight.itlsData.provisionData?.type === "NESTED_GROUP") {
            toBeRemovedHighlightIds.push(highlight.id);
          }
        }
      });
    });

    const _ignore2 = highlightTypes.forEach((highlightKey) => {
      const highlights: any = pageMeta[highlightKey];
      toBeRemovedHighlightIds.forEach((removeId) => {
        delete highlights[removeId];
      });
    });
  }

  ngOnInit() {
    this.doUpdatePdfSrc();
    this.initListeners();
    this.toggleFindBar(false);

    this.mentionEditTrackerFacade.getLocateNestedMentionOperation$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((locateMention) => {
        this.nestedMentionUidUnderLocate = locateMention.nestedMentionUid;
        this.nestedSubfieldKeyUnderLocate = locateMention.nestedSubfieldKey;
      });
  }

  updatePdfSrc(pdfSrc: string) {
    this.pages = [];
    this.pdfSrc = pdfSrc;
    localStorage.removeItem(LocalStorageKey.DATABASE);
    this.pdfReaderService.isFirstPageLoaded = false;
    this.doUpdatePdfSrc();
  }

  scrollToAnnotation(id: string, pageNum: number) {
    const selector = `.page div[data-annotation-id='${id}']`;
    const pageElements: HTMLElement[] = Array.from(
      this.pdfviewer.nativeElement.contentDocument.querySelectorAll(".page")
    );
    const pageElement = pageElements[pageNum - 1];
    if (!pageElement) {
      console.error(
        `Could not find page ${pageNum} of N pages: ${pageElements.length}`
      );
      return;
    }
    this.scrollToElement(pageElement);
    // TODO: convert to Promise
    setTimeout(() => {
      const annotationElement =
        this.pdfviewer.nativeElement.contentDocument.querySelector(
          selector
        ) as HTMLElement;
      this.scrollToElement(annotationElement);
    }, 1000);
  }

  scrollToTextHighlight(id: string, pageNum: number) {
    const selector = `.page div[data-annotation-id='${id}']`;
    this.pdfReaderService.selectedPage = pageNum;
    if (this.pdfReaderService.isFirstPageLoaded) {
      this.onScrollToTextHighlight(selector, pageNum);
    } else {
      this.firstPageLoaded
        .pipe(last())
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res) => {
          this.onScrollToTextHighlight(selector, pageNum);
        });
    }
  }

  private onScrollToTextHighlight(selector: string, pageNum: number) {
    this.goToPage(pageNum);
    let annotationElement =
      this.pdfviewer.nativeElement.contentDocument.querySelector(
        selector
      ) as HTMLElement;
    let retryCount = 0;
    const payload = setInterval(() => {
      annotationElement =
        this.pdfviewer.nativeElement.contentDocument.querySelector(
          selector
        ) as HTMLElement;
      if (annotationElement) {
        this.scrollToElement(annotationElement);
        this.pdfReaderService.onScrolledToTextHighlight$.next();
        clearInterval(payload);
      } else {
        retryCount += 1;
      }
      if (retryCount > 25) {
        clearInterval(payload);
        this.pdfReaderService.onScrolledToTextHighlight$.next();
        retryCount = 0;
      }
    }, 100);
  }

  private scrollToElement(element: HTMLElement) {
    if (element) {
      scrollIntoView(element, {
        behavior: "auto",
        scrollMode: "always",
      });
      document.getElementsByTagName("html")[0].scrollTop = 0;
      // element.scrollIntoView({
      //   behavior: 'auto',
      //   block: 'center',
      //   inline: 'center'
      // });
    }
  }

  private prepareAnnotations(annotations: any) {
    Object.values(annotations.pageMetas).map((pageMeta: any) => {
      this.filterOutIrrelevantNestedMentions(pageMeta);
      this.prepareReaderAnnotations(pageMeta, "textHighlights");
      this.prepareReaderAnnotations(pageMeta, "areaHighlights");
    });
    return JSON.stringify({ ...annotations });
  }

  setAnnotations(annotations) {
    this.pdfReaderContentWindow["setAnnotations"](
      this.prepareAnnotations(annotations)
    );
  }

  toggleReadOnlyMode(isReadOnly: boolean) {
    // this.pdfviewer.nativeElement.contentWindow.window.document.getElementById(
    //     'areaHighlightToggler',
    // ).style.display = isReadOnly ? 'none' : 'block'
    this.pdfReaderContentWindow["toggleReadOnlyMode"](isReadOnly);
  }

  toggleReaderVersion(readerVersion: RecordReviewModeEnum) {
    this.readerVersion = readerVersion;
    this.pdfReaderContentWindow["toggleReaderVersion"](readerVersion);
  }

  toggleCommentMode(isCommentMode: boolean) {
    this.isCommentMode = isCommentMode;
    this.pdfReaderContentWindow["toggleCommentMode"](isCommentMode);
  }

  setProvisionList(provisionList) {
    if (this.pdfReaderContentWindow)
      this.pdfReaderContentWindow["setProvisionList"](provisionList);
  }

  setReviewMetadata(metadata: {
    lastEditedMentionUiName: string;
    lastTouchedMentionSubfieldUiName: string;
    lastEditedMentionUid?: string;
    lastTouchedMentionSubfieldKey?: string;
    skipMentionAndSubfieldSelection: boolean;
  }) {
    if (this.pdfReaderContentWindow) {
      this.pdfReaderContentWindow["setReviewMetadata"](metadata);
    }
  }

  setPermissions(
    permissions: (
      | "READ"
      | "EDIT"
      | "COMPLETE"
      | "DELETE"
      | "EXPORT"
      | "FULL_EXPORT"
      | "HISTORY_EXPORT"
      | "LOCK"
      | "EXPLORE"
      | "MOVE"
      | "COMMENT"
      | "SHARE"
    )[]
  ) {
    if (this.pdfReaderContentWindow)
      this.pdfReaderContentWindow["setPermissions"](permissions);
  }

  setDefaultSelectedProvision(provision: ProvisionInfoModel) {
    if (provision) {
      const { uid, type, name, htmlName } = provision;
      const _provision: {
        uid: string;
        htmlName: string;
        label: string;
        uiName: string;
        type: string;
      } = {
        uid,
        htmlName,
        label: name,
        uiName: htmlName,
        type,
      };
      this.pdfReaderContentWindow["setDefaultSelectedProvision"](_provision);
    } else {
      this.pdfReaderContentWindow["setDefaultSelectedProvision"](undefined);
    }
  }

  setCommentMentionList(mentionList: { id: string; name: string }[] = []) {
    this.pdfReaderContentWindow["setCommentMentionList"](mentionList);
  }

  onDeleteAnnotation(annotation, isAreaHighlight: boolean) {
    this.pdfReaderContentWindow["deleteAnnotation"](
      annotation,
      isAreaHighlight
    );
  }

  onUpdateHighlight(pageNum: any, textHighlightId: any, data: any) {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.pdfviewer.nativeElement.contentWindow["InteleasePDFReader"][
          "updateHighlight"
        ](pageNum, textHighlightId, data);
      }, 100);
    });
  }

  findTerm(term: string) {
    this.pdfReaderContentWindow["find"](term);
  }

  /**
   * indirect text search for hacking pdf-viewer search input
   * @param term
   */
  indirectTextSearch(term: string) {
    this.findTerm(term.trim().toLowerCase());
    const matchCaseBtn =
      this.pdfviewer.nativeElement.contentWindow.document.getElementById(
        "findMatchCase"
      );
    const $findInput =
      this.pdfviewer.nativeElement.contentWindow.document.getElementById(
        "findInput"
      );
    $findInput.value = term;
    if (matchCaseBtn.checked) {
      matchCaseBtn.click();
      matchCaseBtn.click();
    }
  }

  /**
   * scroll to x, y position in pdf
   * @param x
   * @param y
   */
  scrollToPosition(x: number, y: number) {
    setTimeout(() => {
      const viewerElement =
        this.pdfviewer.nativeElement.contentWindow.document.getElementById(
          "viewerContainer"
        );
      viewerElement.scrollTo(x, y);
    }, 1);
  }

  getPageHeight(): number {
    let pageHeight =
      this.pdfviewer.nativeElement.contentWindow.document.getElementById(
        "pageContainer1"
      );
    if (pageHeight) {
      pageHeight = pageHeight.style.height;
      pageHeight.replace("px", "");
      this.applicationRef.tick();
      return parseFloat(pageHeight);
    }
  }

  onPDFReaderIsReady(data) {
    this.readerIsReadyOutput.emit(data);
    this.addClickListenerForIframe();
  }

  /**
   * listen to pagerendred event of PDFJS
   * @description it will trigger for each page when renders into viewer
   */
  private addListenerToPageRenderedEvent() {
    this.pdfviewer.nativeElement.contentWindow.document.addEventListener(
      "pagerendered",
      (e) => {
        this.updatePageAndScrollHeight();
      }
    );
  }

  private updatePageAndScrollHeight() {
    // const viewerElement = this.pdfviewer.nativeElement.contentWindow.document.getElementById(
    //     'viewerContainer',
    // )
    // const pageHeight = this.pdfviewer.nativeElement.contentWindow.document.getElementById(
    //     'pageContainer1',
    // ).style.height
    // pageHeight.replace('px', '')
    this.viewerHeightChanged.emit(this.getScrollAndPageHeight());
    this.applicationRef.tick();
  }

  private getScrollAndPageHeight() {
    const viewerElement =
      this.pdfviewer.nativeElement.contentWindow.document.getElementById(
        "viewerContainer"
      );
    const viewerElements =
      this.pdfviewer.nativeElement.contentWindow.document.getElementById(
        "viewer"
      );
    if (this.pages.length === 0) {
      this.pages = [];
      const pageElements =
        this.pdfviewer.nativeElement.contentWindow.document.getElementsByClassName(
          "page"
        );
      const pageElementsLength = pageElements.length;
      for (let i = 0; i < pageElementsLength; i++) {
        const pageNumber = i + 1;
        this.pages.push({
          pageHeight: parseInt(
            pageElements[i].style.height.replace("px", ""),
            10
          ),
          // pageHeight: pageElements[i].getBoundingClientRect().height,
          pageNumber,
        });
      }
    }
    const pageHeight =
      this.pdfviewer.nativeElement.contentWindow.document.getElementById(
        "pageContainer1"
      ).style.height;
    pageHeight.replace("px", "");
    return {
      scrollHeight: viewerElement.scrollHeight,
      pageHeight: parseInt(pageHeight, 10),
      pages: this.pages,
      viewerScrollHeight: viewerElements.scrollHeight,
    };
  }

  public toggleFindBar(isShow: boolean) {
    const findBar =
      this.pdfviewer.nativeElement.contentWindow.document.getElementById(
        "findbar"
      );
    if (findBar) {
      findBar.style.display = isShow ? "block" : "none";
    }
  }

  expandViewerHeight(height: number) {
    const lastPage: HTMLElement = _last(
      this.pdfviewer.nativeElement.contentWindow.document.getElementsByClassName(
        "page"
      )
    );
    lastPage.style.minHeight =
      parseInt(lastPage.style.height.replace("px", ""), 10) + height + "px";
  }

  onNewAnnotation(data, extraParams) {
    const { isAreaHighlight } = extraParams;
    let textHighlight;
    if (isAreaHighlight) {
      textHighlight =
        AbstractTextHighlightsService.getLatestHighlightFromHighlights(
          data.areaHighlights
        );
    } else {
      textHighlight =
        AbstractTextHighlightsService.getLatestHighlightFromHighlights(
          data.textHighlights
        );
    }
    if (AbstractTextHighlightsService.isProvisionTextHighlight(textHighlight)) {
      this.newAnnotation.emit({
        annotationData: data,
        extraParams: extraParams,
      });
    } else if (
      AbstractTextHighlightsService.isBookmarkTextHighlight(textHighlight)
    ) {
      this.newBookmark.emit({
        annotationData: data,
        extraParams: extraParams,
      });
    } else if (
      AbstractTextHighlightsService.isCommentTextHighlight(textHighlight)
    ) {
      this.newComment.emit({
        annotationData: data,
        extraParams: extraParams,
      });
    }
  }

  onAnnotationUpdated(data, extraParams) {
    this.annotationUpdate.emit({
      annotationData: data,
      extraParams: extraParams,
    });
  }

  onAnnotationClicked(annotation) {
    this.annotationClick.emit(annotation);
  }

  onDocMetaUpdated(data) {
    this.docMetaUpdated.emit(JSON.parse(data));
  }

  onAnnotationDeleted(data, extraParams) {
    this.annotationDeleted.emit(data);
  }

  getCurrentPage() {
    return (
      this.pdfviewer.nativeElement.contentWindow.window?.PDFViewerApplication
        ?.page || 1
    );
  }

  getTotalPages() {
    return this.pdfviewer.nativeElement.contentWindow.window
      .PDFViewerApplication.pagesCount;
  }

  getCurrentScale(): number {
    const contentWindow = this.pdfviewer.nativeElement.contentWindow;

    if (!contentWindow) {
      return 1;
    }

    const pdfViewer = contentWindow.window.PDFViewerApplication;

    if (pdfViewer && pdfViewer.pdfViewer) {
      return parseFloat(pdfViewer.pdfViewer._currentScale);
    }

    return 1;
  }

  onDocumentLoaded(e: {
    document: Document;
    pdfReaderInstanceId: string;
    viewerContainerClassName: string;
  }) {
    this.listenToViewerScrollEvent(
      e.viewerContainerClassName,
      e.document,
      e.pdfReaderInstanceId
    );
    this.documentLoadedOutput.emit();
  }

  private listenToViewerScrollEvent(
    viewerContainerClassName: string,
    documentRef: Document,
    pdfReaderInstanceId: string
  ) {
    const viewerElement = documentRef.getElementsByClassName(
      viewerContainerClassName
    );
    const viewerContainer = _first(viewerElement) as HTMLElement;
    viewerContainer.scrollTop = viewerContainer.scrollTop + 0.5;
    viewerContainer.onscroll = (e) => {
      this.viewerScrollChange.emit({
        pdfReaderInstanceId,
        scrollTop: viewerContainer.scrollTop,
        ...this.getScrollAndPageHeight(),
      });
    };
    setTimeout(() => {
      viewerContainer.scrollTop = viewerContainer.scrollTop + 0.5;
    }, 200);
  }

  onZoomChanged(direction: string, scale: number) {
    this.zoomChanged.emit({ direction, scale });
  }

  onFirstPageLoaded() {
    this.firstPageLoaded.emit();
    this.pdfReaderService.firstPageLoaded$.next();
    this.pdfReaderService.isFirstPageLoaded = true;
    this.addListenerToPageRenderedEvent();
    if (this.pdfReaderService.selectedPage) {
      this.loadSelectedPage(this.pdfReaderService.selectedPage);
    }
    this.toggleReadOnlyMode(this.isReadOnly);
    this.toggleReaderVersion(this.readerVersion);
    this.toggleCommentMode(this.isCommentMode);
  }

  /**
   * add new text highlight on viewer
   * @param pageNum
   * @param textHighlightId
   * @param textHighligh
   */
  addTextHighlight(pageNum: any, textHighlightId: any, textHighligh: any) {
    this.pdfReaderContentWindow["addTextHighlight"](
      pageNum,
      textHighlightId,
      textHighligh
    );
  }

  /**
   * add new text highlight on viewer
   * @param pageNum
   * @param areaHighlightId
   * @param areaHighlight
   */
  addAreaHighlight(pageNum: any, areaHighlightId: any, areaHighligh: any) {
    this.pdfReaderContentWindow["addAreaHighlight"](
      pageNum,
      areaHighlightId,
      areaHighligh
    );
  }

  goToPage(pageNum: number): void {
    this.pdfviewer.nativeElement.contentWindow.window.PDFViewerApplication.page =
      pageNum;
  }

  loadSelectedPage(pageNum: number) {
    const loadingElem =
      this.pdfviewer.nativeElement.contentWindow.window.document.getElementById(
        "loadingBar"
      );
    if (loadingElem && loadingElem.style) {
      loadingElem.style.display = "none";
    }
    if (this.pdfReaderService.selectedPage) {
      this.goToPage(this.pdfReaderService.selectedPage);
      this.selectedPageLoaded.emit(pageNum);
      this.pdfReaderService.selectedPage = 0;
      this.pdfReaderService.onScrolledToTextHighlight$.next();
    }
  }

  private pageContentHasLoaded(pageElem: any) {
    let isLoaded = false;
    pageElem.childNodes.forEach((item) => {
      if (item.className === "textLayer") {
        isLoaded = true;
      }
    });
    return isLoaded;
  }

  isViewerPageElementsLoaded(): Observable<boolean> {
    return new Observable<boolean>((obs) => {
      const payload = setInterval(() => {
        const firstPageElem =
          this.pdfviewer.nativeElement.contentWindow.window.document.getElementById(
            "pageContainer1"
          );
        if (firstPageElem) {
          const loadingElem =
            this.pdfviewer.nativeElement.contentWindow.window.document.getElementById(
              "loadingBar"
            );
          if (loadingElem && loadingElem.style) {
            loadingElem.style.display = "none";
          }
          obs.next(true);
          obs.complete();
          clearInterval(payload);
        }
      }, 100);
    });
  }

  private addClickListenerForIframe() {
    this.pdfviewer.nativeElement.contentWindow.document.addEventListener(
      "click",
      (event) => {
        document.body.click();
      },
      false
    );
  }

  private initListeners() {
    if (!window["InteleasePDFReader"]) {
      window["InteleasePDFReader"] = {};
    }

    window["InteleasePDFReader"]["onPDFReaderIsReady"] = (data) => {
      this.onPDFReaderIsReady(data);
      this.applicationRef.tick();
    };

    window["InteleasePDFReader"]["onNewAnnotation"] = (data, extraParams) => {
      this.onNewAnnotation(data, extraParams);
      this.applicationRef.tick();
    };

    window["InteleasePDFReader"]["onDocMetaUpdated"] = (data) => {
      this.onDocMetaUpdated(data);
      this.applicationRef.tick();
    };

    window["InteleasePDFReader"]["onUpdateAnnotation"] = (
      data,
      extraParams
    ) => {
      this.onAnnotationUpdated(data, extraParams);
      this.applicationRef.tick();
    };

    window["InteleasePDFReader"]["onAnnotationDeleted"] = (
      data,
      extraParams
    ) => {
      this.onAnnotationDeleted(data, extraParams);
      this.applicationRef.tick();
    };

    window["InteleasePDFReader"]["onAnnotationClick"] = (annotation) => {
      this.onAnnotationClicked(annotation);
      this.applicationRef.tick();
    };

    window["onDocumentLoaded"] = (e: {
      document: Document;
      pdfReaderInstanceId: string;
      viewerContainerClassName: string;
    }) => {
      this.onDocumentLoaded(e);
      this.applicationRef.tick();
    };

    window["zoomChanged"] = (direction: string, scale: string) => {
      this.onZoomChanged(direction, parseFloat(scale));
      this.applicationRef.tick();
    };

    window["firstPageLoaded"] = () => {
      this.onFirstPageLoaded();
    };
  }

  private doUpdatePdfSrc() {
    combineLatest([
      this.abstractReviewFacade.selectedAbstractDetail$.pipe(firstNotNill()),
      this.abstractReviewFacade.getSelectedDocument$.pipe(firstNotNill()),
    ])
      .pipe(
        take(1),
        switchMap(([details, doc]: any) =>
          this.webAbstractionPageService.getETag(details.uid, doc.uid)
        )
      )
      .subscribe((etag) => {
        this.pdfviewer.nativeElement.src = `${
          PdfReaderComponent.documentUrl
        }?file=${this.pdfSrc}&isRangeRequest=${
          this.isRangeRequest ? "yes" : "no"
        }&apiRandKey=true&pdfReaderInstanceId=${
          this.pdfReaderInstanceId
        }&viewerContainerClass=${this.viewerContainerClass}&testBed=${
          this.isTestbed
        }&X-Request-Id=${generateUUID()}&isReadOnly=${!!this
          .isReadOnly}&token=Bearer ${localStorage.getItem(
          LocalStorageKey.AUTH
        )}&readerVersion=${this.readerVersion}${
          this.isRangeRequest && !etag ? "" : `&etag=${etag ? etag : ""}`
        }`;
      });
  }
}
