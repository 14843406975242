/* tslint:disable */
/* eslint-disable */
import { DocumentSetUploadDtoModel } from "./document-set-upload-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocumentSetsUploadDtoModel")
export class DocumentSetsUploadDtoModel {
  @JsonProperty("directoryUid", String, true)
  directoryUid?: string = "";

  @JsonProperty("documentSets", [DocumentSetUploadDtoModel], true)
  documentSets?: Array<DocumentSetUploadDtoModel> = [];

  @JsonProperty("provisionFormUid", String, true)
  provisionFormUid?: string = "";

  @JsonProperty("uploadingFolder", Boolean, true)
  uploadingFolder?: boolean = undefined;
}
