import { SimpleOpenApiDateModel } from "@@intelease/api-models/adex-api-model-src";
import { keys } from "lodash";
import * as moment from "moment";
import { unitOfTime } from "moment";

export class DateUtil {
  static diffDates(a: Date, b: Date, unitOfTimeInput: unitOfTime.Base) {
    const first = moment(a);
    const second = moment(b);
    return first.diff(second, unitOfTimeInput);
  }

  static subtractDate(a: Date, num: number, unitOfTimeInput: unitOfTime.Base) {
    return moment(a).subtract(num, unitOfTimeInput);
  }

  static addDate(a: Date, num: number, unitOfTimeInput: unitOfTime.Base) {
    return moment(a).add(num, unitOfTimeInput);
  }

  static getExactDate(date: Date) {
    return {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    };
  }

  static serializeToExactDate(date: Date | undefined) {
    if (!date) {
      return;
    }
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  static deserializeExactDate(model: {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    second: number;
  }): Date {
    if (!model || !this.isSerializedDate(model)) {
      return;
    }
    const { year, month, day, hour, minute, second } = model;
    return moment()
      .set("year", year)
      .set("month", month - 1)
      .set("date", day || 0)
      .set("hours", hour || 0)
      .set("minutes", minute || 0)
      .set("seconds", second || 0)
      .toDate();
  }

  static deserializeSimpleDate(model: SimpleOpenApiDateModel) {
    if (!model || !this.isSerializedDate(model)) {
      return;
    }
    const { year, month, day } = model;
    return moment()
      .set("year", year)
      .set("month", month - 1)
      .set("date", day || 0)
      .toDate();
  }

  static isSerializedDate(
    model:
      | {
          year?: number;
          month?: number;
          day?: number;
          hour?: number;
          minute?: number;
          second?: number;
        }
      | Date
      | string
  ): boolean {
    const modelKeys = keys(model);
    return (
      modelKeys.includes("year") ||
      modelKeys.includes("month") ||
      modelKeys.includes("day") ||
      modelKeys.includes("hour") ||
      modelKeys.includes("minute") ||
      modelKeys.includes("second")
    );
  }

  static startOf(date: Date, unitOfTimeInput: unitOfTime.StartOf) {
    return moment(date).startOf(unitOfTimeInput).toDate();
  }

  static startOfByFormat(
    date: Date,
    unitOfTimeInput: unitOfTime.StartOf,
    format: string
  ) {
    return moment(date).startOf(unitOfTimeInput).format(format);
  }

  static endOf(date: Date, unitOfTimeInput: unitOfTime.StartOf) {
    return moment(date).endOf(unitOfTimeInput).toDate();
  }

  static getStartAndEndOfDate(date: Date, unitOfTimeInput: unitOfTime.StartOf) {
    return {
      start: DateUtil.startOf(date, unitOfTimeInput),
      end: DateUtil.endOf(date, unitOfTimeInput),
    };
  }

  static getStartAndEndOfDateByFormat(
    date: Date,
    unitOfTimeInput: unitOfTime.StartOf,
    format: string
  ) {
    return {
      start: moment(DateUtil.startOf(date, unitOfTimeInput)).format(format),
      end: moment(DateUtil.endOf(date, unitOfTimeInput)).format(format),
    };
  }
}
