import { cloneDeep } from "lodash";
import { NESTED_MENTION_ATTRIBUTIONS_FIELD_KEY } from "../constants";

export function toggleFormSchemaMode(formSchema: any, isReadOnly: boolean) {
  if (formSchema && formSchema.properties) {
    const schemaFormCp: any = cloneDeep(formSchema);
    for (const schemaAttr of Object.keys(schemaFormCp.properties)) {
      if (schemaAttr === NESTED_MENTION_ATTRIBUTIONS_FIELD_KEY) {
        schemaFormCp.properties[schemaAttr]["readOnly"] = true;
        continue;
      }
      schemaFormCp.properties[schemaAttr]["readOnly"] = isReadOnly;
    }
    return schemaFormCp;
  }
  return formSchema;
}
