import { MatButtonModule } from "@angular/material/button";
import { ItlsDriveTreeFolderPickerComponent } from "./tree-view/itls-drive-tree.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTreeModule } from "@angular/material/tree";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatListModule } from "@angular/material/list";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExtendedModule, FlexModule } from "@angular/flex-layout";
import { ItlsDriveCreateNewFolderModule } from "../itls-drive-create-new-folder";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";

const MATERIAL_MODULES = [
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatTreeModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatProgressBarModule,
  MatTabsModule,
  MatListModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatExpansionModule,
];

@NgModule({
  declarations: [ItlsDriveTreeFolderPickerComponent],
  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexModule,
    ExtendedModule,
    ItlsCustomIconModule,
    ItlsDriveCreateNewFolderModule,
  ],
  exports: [ItlsDriveTreeFolderPickerComponent],
  providers: [],
})
export class ItlsDriveTreeViewFolderPickerModule {}
