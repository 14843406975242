import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TaskListComponent } from "./task-list.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { InteleasePipesModule } from "@@intelease/web/intelease/pipes";
import { ProgressModule } from "@@intelease/web/intelease/components";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { ItlsDataTableModule } from "@@intelease/web/ui";

const INTELEASE_MODULES = [InteleasePipesModule, ProgressModule.forRoot()];
const UI_MODULES = [ItlsDataTableModule];

@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    InteleaseCommonModule,
    ...INTELEASE_MODULES,
    ...UI_MODULES,
  ],
  exports: [TaskListComponent],
  declarations: [TaskListComponent],
})
export class TaskListNoRoutingModule {}
