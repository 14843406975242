/* tslint:disable */
/* eslint-disable */
import { HighlightUpdateNestedMentionApiDtoModel } from "./highlight-update-nested-mention-api-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqHighlightUpdateNestedProvisionValueDtoModel")
export class OApiReqHighlightUpdateNestedProvisionValueDtoModel {
  @JsonProperty("data", HighlightUpdateNestedMentionApiDtoModel, true)
  data?: HighlightUpdateNestedMentionApiDtoModel = undefined;
}
