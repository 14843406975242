/* tslint:disable */
/* eslint-disable */
import { SignupUserDtoModel } from "./signup-user-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqSignupUserDtoModel")
export class OApiReqSignupUserDtoModel {
  @JsonProperty("data", SignupUserDtoModel, true)
  data?: SignupUserDtoModel = undefined;
}
