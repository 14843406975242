/* tslint:disable */
/* eslint-disable */
import { FullProvisionGroupViewModel } from "./full-provision-group-view-model";
import { PaginationDataModel } from "./pagination-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiMultiEntityContainerResponseFullProvisionGroupViewModel")
export class OApiMultiEntityContainerResponseFullProvisionGroupViewModel {
  @JsonProperty("items", [FullProvisionGroupViewModel], true)
  items?: Array<FullProvisionGroupViewModel> = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
