import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("MinimalUserModel")
export class MinimalUserModel {
  static view = "minimal";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("firstName", String)
  firstName = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("email", String)
  email = "";

  @JsonProperty("flags", [String])
  flags: string[] = [];

  @JsonProperty("accountUid", String)
  accountUid = "";
}
