import { ModuleWithProviders, NgModule } from "@angular/core";
import { ActionButtonComponent } from "@@intelease/web/intelease/components/action-button/action-button.component";
import { CommonModule } from "@angular/common";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

@NgModule({
  imports: [CommonModule, BsDropdownModule.forRoot()],
  declarations: [ActionButtonComponent],
  exports: [ActionButtonComponent],
})
export class ActionButtonModule {
  static forRoot(): ModuleWithProviders<ActionButtonModule> {
    return {
      ngModule: ActionButtonModule,
    };
  }
}
