import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import {
  MinimalAbstractModel,
  NodeNavigationModel,
} from "@@intelease/web/common/models";
import { QueueInfoViewModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("FormNameAbstractModel")
export class FormNameAbstractModel {
  static view = "formName";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("version", Number)
  version = 0;

  @JsonProperty("abstractVersions", [MinimalAbstractModel], true)
  abstractVersions: MinimalAbstractModel[] = [];

  @JsonProperty("abstractFilename", String)
  abstractFilename = "";

  @JsonProperty("abstractName", String)
  abstractName = "";

  @JsonProperty("provisionFormUid", String)
  provisionFormUid = "";

  @JsonProperty("provisionFormName", String)
  provisionFormName = "";

  @JsonProperty("status", String)
  status = "";

  @JsonProperty("abstractCreationDate", DateTimeConvertService)
  abstractCreationDate: Date = undefined;

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("abstractorCode", String)
  abstractorCode = "";

  @JsonProperty("nodeNavigation", NodeNavigationModel, true)
  nodeNavigation: NodeNavigationModel = undefined;

  @JsonProperty("queueInfoView", QueueInfoViewModel, true)
  queueInfoView?: QueueInfoViewModel = undefined;
}
