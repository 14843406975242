import { Title } from "@angular/platform-browser";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TitleService {
  static readonly FINAL_PART = "ADEx AI";

  constructor(private titleService: Title) {}

  setTitle(firstPart: string): void {
    this.titleService.setTitle(firstPart);
    // this.titleService.setTitle(firstPart + ' | ' + TitleService.FINAL_PART);
  }

  getTitle(): string {
    return this.titleService.getTitle();
  }
}
