import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { ProgressModule } from "@@intelease/web/intelease/components";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { AppStateQueueModule } from "@@intelease/app-state/queue/src";
import { ChooseQueueDropdownComponent } from "./choose-queue.component";

@NgModule({
  imports: [
    InteleaseCommonModule,
    MatIconModule,
    ProgressModule,
    TooltipModule,
    AppStateQueueModule,
  ],
  declarations: [ChooseQueueDropdownComponent],
  exports: [ChooseQueueDropdownComponent],
})
export class ItlsChooseQueueModule {}
