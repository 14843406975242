/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqCalculateOptionRemindersRequestModel } from "../models/o-api-req-calculate-option-reminders-request-model";
import { OApiRespMultiOptionMultipleReminderItemModel } from "../models/o-api-resp-multi-option-multiple-reminder-item-model";
import { OApiRespMultiOptionRollingReminderItemModel } from "../models/o-api-resp-multi-option-rolling-reminder-item-model";
import { OApiRespOptionsCalculatorInputDataResponseModel } from "../models/o-api-resp-options-calculator-input-data-response-model";

@Injectable({
  providedIn: "root",
})
export class OptionRemindersCalculatorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation calculateOptionReminders
   */
  static readonly CalculateOptionRemindersPath =
    "/api/v1/calculator/calc-option-reminders";

  /**
   * Calculate the reminder dates for the option.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateOptionReminders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateOptionReminders$Response(params: {
    body: OApiReqCalculateOptionRemindersRequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiOptionMultipleReminderItemModel
      | OApiRespMultiOptionRollingReminderItemModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      OptionRemindersCalculatorService.CalculateOptionRemindersPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiOptionMultipleReminderItemModel
            | OApiRespMultiOptionRollingReminderItemModel
          >;
        })
      );
  }

  /**
   * Calculate the reminder dates for the option.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calculateOptionReminders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateOptionReminders(params: {
    body: OApiReqCalculateOptionRemindersRequestModel;
  }): Observable<
    | OApiRespMultiOptionMultipleReminderItemModel
    | OApiRespMultiOptionRollingReminderItemModel
  > {
    return this.calculateOptionReminders$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiOptionMultipleReminderItemModel
            | OApiRespMultiOptionRollingReminderItemModel
          >
        ) =>
          r.body as
            | OApiRespMultiOptionMultipleReminderItemModel
            | OApiRespMultiOptionRollingReminderItemModel
      )
    );
  }

  /**
   * Path part for operation retrieveOptionRemindersCalculatorData
   */
  static readonly RetrieveOptionRemindersCalculatorDataPath =
    "/api/v1/calculator/option-reminders-data";

  /**
   * Get the inputs/initial data for the option reminders calculator.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveOptionRemindersCalculatorData()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveOptionRemindersCalculatorData$Response(params: {
    /**
     * The unique id of the record.
     */
    recordUid: string;

    /**
     * The type of reminders to calculate.
     */
    optionRemindersType: "ROLLING" | "MULTIPLE" | "RECURRING";
  }): Observable<
    StrictHttpResponse<OApiRespOptionsCalculatorInputDataResponseModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      OptionRemindersCalculatorService.RetrieveOptionRemindersCalculatorDataPath,
      "get"
    );
    if (params) {
      rb.query("recordUid", params.recordUid, {});
      rb.query("optionRemindersType", params.optionRemindersType, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespOptionsCalculatorInputDataResponseModel>;
        })
      );
  }

  /**
   * Get the inputs/initial data for the option reminders calculator.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retrieveOptionRemindersCalculatorData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveOptionRemindersCalculatorData(params: {
    /**
     * The unique id of the record.
     */
    recordUid: string;

    /**
     * The type of reminders to calculate.
     */
    optionRemindersType: "ROLLING" | "MULTIPLE" | "RECURRING";
  }): Observable<OApiRespOptionsCalculatorInputDataResponseModel> {
    return this.retrieveOptionRemindersCalculatorData$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<OApiRespOptionsCalculatorInputDataResponseModel>
        ) => r.body as OApiRespOptionsCalculatorInputDataResponseModel
      )
    );
  }
}
