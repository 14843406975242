import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { isPlatformServer } from "@angular/common";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "@@intelease/app-services/auth/src/lib/auth";
import { LocalStorageService } from "@@intelease/app-services/common";
import { LocalStorageKey } from "../../common/enums/local-storage.keys";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly isServer: boolean;

  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) platformId,
    private authService: AuthService
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        const { data } = err.error;
        if (err.status === 403) {
          // Unauthorized
          if (data === "UNAUTHORIZED") {
            // Cannot even show the page.
            this.router.navigateByUrl("/miscellaneous/forbidden");
          }
        } else if (err.status === 401) {
          // Unauthenticated
          // TODO(apoorv): Need this stupid extra reload here b/c, otherwise, for some reason, the following problem exists:
          // If user logs out and then immediately logs in as a user in PRE_TOS status,
          // the form for terms-of-service behaves incorrectly. Specifically, the "Approve" checkbox
          // does not update on the UI. So this extra reload sidesteps that issue.
          AuthService.removeUserInfo();
          LocalStorageService.clear();
          localStorage.setItem(LocalStorageKey.REDIRECT_URL, this.router.url);
          this.router.navigateByUrl("/auth/login").then((inRes) => {
            if (!this.router.url.includes("/auth/login")) {
              setTimeout(() => {
                window.location.reload();
              }, 100);
            }
          });
        }
        return throwError(err);
      })
    );
  }
}
