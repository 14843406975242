import { Injectable } from "@angular/core";
import { environment } from "apps/ui/src/environments/environment";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay } from "rxjs/operators";

export enum FeaturesToggle {
  LEFT_MENU = "left-menu",
  DASHBOARD = "dashboard",
}

@Injectable({
  providedIn: "root",
})
export class FeaturesToggleService {
  private toggles: BehaviorSubject<{ [key: string]: boolean }> =
    new BehaviorSubject({});

  leftMenu$: Observable<boolean> = this.getFeatureFlag(
    FeaturesToggle.LEFT_MENU
  );
  dashboard$: Observable<boolean> = this.getFeatureFlag(
    FeaturesToggle.DASHBOARD
  );

  static getValue(flag: FeaturesToggle): boolean {
    const value = localStorage.getItem(flag);

    if (value === null) {
      return true;
    }

    return value === "true";
  }

  public getFeatureFlag<T>(flag: FeaturesToggle): Observable<T> {
    this.toggles.next({
      ...this.toggles.getValue(),
      [flag]: FeaturesToggleService.getValue(flag),
    });

    return this.toggles.asObservable().pipe(
      map((event) => event[flag]),
      distinctUntilChanged()
    ) as unknown as Observable<T>;
  }

  public toggleFeatureFlag(flag: FeaturesToggle, value: boolean): void {
    localStorage.setItem(flag, JSON.stringify(value));
    this.toggles.next({ ...this.toggles.getValue(), [flag]: value });
  }
}
