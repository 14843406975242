/* tslint:disable */
/* eslint-disable */
import { BookmarkDtoModel } from "./bookmark-dto-model";
import { OApiReturnParamsRequestBookmarkViewTypeModel } from "./o-api-return-params-request-bookmark-view-type-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqBookmarkDtoModel")
export class OApiReqBookmarkDtoModel {
  @JsonProperty("data", BookmarkDtoModel, true)
  data?: BookmarkDtoModel = undefined;

  @JsonProperty(
    "returnParams",
    OApiReturnParamsRequestBookmarkViewTypeModel,
    true
  )
  returnParams?: OApiReturnParamsRequestBookmarkViewTypeModel = undefined;
}
