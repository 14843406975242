/* tslint:disable */
/* eslint-disable */
import { MentionInconsistencyTypeDtoModel } from "./mention-inconsistency-type-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionInconsistencyApiDtoModel")
export class ProvisionInconsistencyApiDtoModel {
  @JsonProperty("mentionUidToInconsistencies", Any, true)
  mentionUidToInconsistencies?: {
    [key: string]: Array<MentionInconsistencyTypeDtoModel>;
  } = {};
}
