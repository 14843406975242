/* tslint:disable */
/* eslint-disable */
import { PageDataModel } from "./page-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FileSearchRequestDtoModel")
export class FileSearchRequestDtoModel {
  @JsonProperty("category", Any, true)
  category?:
    | "ALL"
    | "DELETED"
    | "FAVORITES"
    | "OWNED"
    | "SHARED"
    | "DOCUMENTS"
    | "GOOGLE_DRIVE" = undefined;

  @JsonProperty("createdBy", String, true)
  createdBy?: string = "";

  @JsonProperty("directoryUid", String, true)
  directoryUid?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("nodeType", Any, true)
  nodeType?: "DIRECTORY" | "DOC_SET" = undefined;

  @JsonProperty("pageData", PageDataModel, true)
  pageData?: PageDataModel = undefined;

  @JsonProperty("sorts", [String], true)
  sorts?: Array<string> = [];

  @JsonProperty("view", Any, true)
  view?: "FULL" | "LIGHT" = undefined;
}
