import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";

@JsonObject("MinimalProvisionGroupModel")
export class MinimalProvisionGroupModel {
  static view = "minimalGroup";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("version", Number)
  version = 0;

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("creationDate", DateTimeConvertService)
  creationDate: Date = undefined;

  @JsonProperty("createdByEmail", String)
  createdByEmail = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("lastModifiedByEmail", String)
  lastModifiedByEmail = "";

  @JsonProperty("numProvisions", Number)
  numProvisions = 0;
}
