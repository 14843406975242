import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import { HEADER_ARRAY } from "../../constants/itls-table.const";
import {
  TableValue,
  CreateDataModel,
  TableCellDataType,
} from "../../interfaces/table-create-edit-model";
import { cloneDeep, isEqual } from "lodash";

@Component({
  selector: "il-table-detail",
  templateUrl: "./table-detail.component.html",
  styleUrls: ["./table-detail.component.scss"],
})
export class TableDetailComponent implements OnInit, OnDestroy {
  static maximumRowsAllowed = 200;
  static maximumColumnsAllowed = 30;

  headers: {
    data: string | number;
    colIndex: number;
    rowIndex: number;
    dataType: TableCellDataType;
  }[];
  rows: {
    data: string | number | string[];
    colIndex: number;
    rowIndex: number;
    dataType: TableCellDataType;
  }[][];
  oldRows: {
    data: string | number | string[];
    colIndex: number;
    rowIndex: number;
    dataType: TableCellDataType;
  }[][];
  columnsLength: number;
  rowsLength: number;
  headerArray = HEADER_ARRAY;
  rowsHeight: number[];
  isDataChanged = false;

  @Input()
  createModel: CreateDataModel;

  @Input()
  tableDetail: TableValue;

  @Input()
  requiredColumns: string[];

  @Input()
  readOnly: boolean;

  @Input()
  allowClosing: boolean;

  @Input()
  strictColumn: boolean;

  @Input()
  allowMerge: boolean;

  @Output()
  bottomTableDismiss: EventEmitter<TableValue> = new EventEmitter();
  // @Output() reduceHeight: EventEmitter<TableValue> = new EventEmitter()

  constructor() {
    //
  }

  ngOnInit(): void {
    if (this.strictColumn) {
      this.headerArray = [
        ...this.tableDetail.headers.map((header) => `${header}`),
      ];
    }
    this.headers = cloneDeep(this.tableDetail.headers).map((col, colIndex) => {
      return this.setTableDetailValue(this.headerArray[colIndex], colIndex, 0);
    });
    this.rows = cloneDeep(this.tableDetail.rows).map((row, rowIndex) => {
      return row.map((data, colIndex) => {
        return this.setTableDetailValue(data, colIndex, rowIndex);
      });
    });
    this.oldRows = cloneDeep(this.rows);
    this.setColumnRowLength(this.headers.length, this.rows.length);
    this.setRowsHeight();
  }

  ngOnDestroy(): void {
    this.tableDismiss();
  }

  public tableDismiss(): void {
    setTimeout(() => {
      const tableValue = this.transformRowsToTableValue(this.rows);
      this.bottomTableDismiss.emit(tableValue);
    }, 100);
  }

  public get tableValue(): TableValue {
    return this.transformRowsToTableValue(this.rows);
  }

  private transformRowsToTableValue(
    rows: {
      data: string | number | string[];
      colIndex: number;
      rowIndex: number;
      dataType: TableCellDataType;
    }[][]
  ): TableValue {
    return {
      headers: rows[0].slice(1).map((col) => col.data.toString()),
      rows: rows.slice(1).map((row) => {
        return row.slice(1).map((col) => col.data);
      }),
      types: rows[0].slice(1).map((col) => col.dataType),
    };
  }

  public getUpdatedTableValue(): Promise<TableValue> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const tableValue = {
          headers: this.headers.map((header) => header.data),
          rows: this.rows.map((row) => row.map((col) => col.data)),
          types: this.headers.map((col) => col.dataType),
        };
        resolve(tableValue);
      }, 100);
    });
  }

  onAddColumnLeft(columnIndex: number) {
    if (this.headers.length < TableDetailComponent.maximumColumnsAllowed) {
      this.setColumnRowLength(this.headers.length + 1, this.rows.length);
      this.headers.splice(
        columnIndex,
        0,
        this.setTableDetailValue("", columnIndex, 0)
      );
      this.rows.map((row, rowIndex) => {
        row.splice(
          columnIndex,
          0,
          this.setTableDetailValue("", columnIndex, rowIndex)
        );
        return row;
      });
      this.headers = this.headers.map((header, colIndex) => {
        return this.setTableDetailValue(
          this.headerArray[colIndex],
          colIndex,
          0
        );
      });
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
    }
  }

  onAddColumnRight(columnIndex: number) {
    if (this.headers.length < TableDetailComponent.maximumColumnsAllowed) {
      this.setColumnRowLength(this.headers.length + 1, this.rows.length);
      this.headers.splice(
        columnIndex,
        0,
        this.setTableDetailValue("", columnIndex, 0)
      );
      this.rows.map((row, rowIndex) => {
        row.splice(
          columnIndex,
          0,
          this.setTableDetailValue("", columnIndex, rowIndex)
        );
        return row;
      });
      this.headers = this.headers.map((header, colIndex) => {
        return this.setTableDetailValue(
          this.headerArray[colIndex],
          colIndex,
          0
        );
      });
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
    }
  }

  onDuplicateColumn(columnIndex: number) {
    if (this.headers.length < TableDetailComponent.maximumColumnsAllowed) {
      this.setColumnRowLength(this.headers.length + 1, this.rows.length);
      this.headers.splice(
        columnIndex + 1,
        0,
        this.setTableDetailValue("", columnIndex, 0)
      );
      this.rows.map((row, rowIndex) => {
        row.splice(
          columnIndex + 1,
          0,
          this.setTableDetailValue(row[columnIndex].data, columnIndex, rowIndex)
        );
        return row;
      });
      this.headers = this.headers.map((header, colIndex) => {
        return this.setTableDetailValue(
          this.headerArray[colIndex],
          colIndex,
          0
        );
      });
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
    }
  }

  onRemoveColumn(columnIndex: number) {
    if (this.headers.length > 2) {
      this.setColumnRowLength(this.headers.length - 1, this.rows.length);
      this.headers.splice(columnIndex, 1);
      this.rows.map((row) => {
        row.splice(columnIndex, 1);
        return row;
      });
      this.headers = this.headers.map((header, colIndex) => {
        return this.setTableDetailValue(
          this.headerArray[colIndex],
          colIndex,
          0
        );
      });
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
    }
  }

  onMergeColumnRight(columnIndex: number) {
    if (columnIndex < this.columnsLength - 1) {
      this.setColumnRowLength(this.headers.length - 1, this.rows.length);
      this.rows.map((row, rowIndex) => {
        const newValue = this.setTableDetailValue(
          `${row[columnIndex].data} ${row[columnIndex + 1].data}`,
          columnIndex,
          rowIndex
        );
        row.splice(columnIndex, 2, newValue);
        return row;
      });
      this.headers = this.headerArray
        .slice(0, this.columnsLength)
        .map((item, colIndex) => {
          return this.setTableDetailValue(item, colIndex, 0);
        });
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
    }
  }

  onMergeColumnLeft(columnIndex: number) {
    if (columnIndex > 1) {
      this.setColumnRowLength(this.headers.length - 1, this.rows.length);
      this.rows.map((row, rowIndex) => {
        const newValue = this.setTableDetailValue(
          `${row[columnIndex].data} ${row[columnIndex - 1].data}`,
          columnIndex - 1,
          rowIndex
        );
        row.splice(columnIndex - 1, 2, newValue);
        return row;
      });
      this.headers = this.headerArray
        .slice(0, this.columnsLength)
        .map((item, colIndex) => {
          return this.setTableDetailValue(item, colIndex, 0);
        });
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
    }
  }

  onMoveColumnRight(columnIndex: number) {
    this.rows.map((row) => {
      const cloneColumn = cloneDeep(row?.[columnIndex]);
      row.splice(columnIndex, 1);
      row.splice(columnIndex + 1, 0, cloneColumn);
      return row;
    });

    this.checkIsDataChanged();
    this.setRowsAndHeaders();
  }

  onMoveColumnLeft(columnIndex: number) {
    this.rows.map((row) => {
      const cloneColumn = cloneDeep(row?.[columnIndex]);
      row.splice(columnIndex, 1);
      row.splice(columnIndex - 1, 0, cloneColumn);
      return row;
    });

    this.checkIsDataChanged();
    this.setRowsAndHeaders();
  }

  onAddRowBelow(rowIndex: number) {
    if (this.rows.length <= TableDetailComponent.maximumRowsAllowed) {
      this.setColumnRowLength(this.headers.length, this.rows.length + 1);
      const newRow = [];
      for (let colIndex = 0; colIndex < this.columnsLength; colIndex++) {
        let cellData;
        if (this.tableDetail.types?.[colIndex] === "ARRAY") {
          cellData = [];
        } else {
          cellData = "";
        }
        newRow.push(this.setTableDetailValue(cellData, colIndex, rowIndex));
      }
      this.rows.splice(rowIndex + 1, 0, newRow);
      this.rows.map((row, index) => {
        row[0].data = index + 1;
      });
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
    }
  }

  onAddRowAbove(rowIndex: number) {
    if (this.rows.length <= TableDetailComponent.maximumRowsAllowed) {
      this.setColumnRowLength(this.headers.length, this.rows.length + 1);
      const newRow = [];
      for (let colIndex = 0; colIndex < this.columnsLength; colIndex++) {
        let cellData;
        if (this.tableDetail.types?.[colIndex] === "ARRAY") {
          cellData = [];
        } else {
          cellData = "";
        }
        newRow.push(this.setTableDetailValue(cellData, colIndex, rowIndex));
      }
      this.rows.splice(rowIndex, 0, newRow);
      this.rows.map((row, index) => {
        row[0].data = index + 1;
      });
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
    }
  }

  onDuplicateRow(rowIndex: number) {
    if (this.rows.length <= TableDetailComponent.maximumRowsAllowed) {
      this.setColumnRowLength(this.headers.length, this.rows.length + 1);
      const newRow = [];
      for (let colIndex = 0; colIndex < this.columnsLength; colIndex++) {
        newRow.push(
          this.setTableDetailValue(
            this.rows[rowIndex][colIndex].data,
            colIndex,
            rowIndex
          )
        );
      }
      this.rows.splice(rowIndex + 1, 0, newRow);
      this.rows.map((row, _rowIndex) => {
        row[0].data = _rowIndex + 1;
      });
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
    }
  }

  onRemoveRow(rowIndex: number) {
    if (this.headers.length >= 1) {
      this.setColumnRowLength(this.headers.length, this.rows.length - 1);
      this.rows.splice(rowIndex, 1);
      this.rows.map((row, index) => {
        row[0].data = index + 1;
        return row;
      });
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
      // this.reduceHeight.emit()
    }
  }

  onMergeRowAbove(rowIndex: number) {
    if (this.rowsLength > 1 && rowIndex > 0) {
      this.setColumnRowLength(this.columnsLength, this.rowsLength - 1);
      const newValue = this.mergeArrays(
        this.rows[rowIndex],
        this.rows[rowIndex - 1]
      );
      this.rows.splice(rowIndex - 1, 2, newValue);
      this.rows.map((row, index) => {
        row[0].data = index + 1;
      });
      this.rowsHeight.splice(rowIndex - 1, 1);
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
      // this.reduceHeight.emit()
    }
  }

  onMergeRowBelow(rowIndex: number) {
    if (this.rowsLength > 1 && rowIndex < this.rowsLength - 1) {
      this.setColumnRowLength(this.columnsLength, this.rowsLength - 1);
      const newValue = this.mergeArrays(
        this.rows[rowIndex],
        this.rows[rowIndex + 1]
      );
      this.rows.splice(rowIndex, 2, newValue);
      this.rows.map((row, index) => {
        row[0].data = index + 1;
      });
      this.rowsHeight.splice(rowIndex + 1, 1);
      this.checkIsDataChanged();
      this.setRowsAndHeaders();
      // this.reduceHeight.emit()
    }
  }

  onMoveRowAbove(rowIndex: number) {
    const cloneRow = this.rows[rowIndex];
    this.rows.splice(rowIndex, 1);
    this.rows.splice(rowIndex - 1, 0, cloneRow);
    this.checkIsDataChanged();
    this.setRowsAndHeaders();
  }

  onMoveRowBelow(rowIndex: number) {
    const cloneRow = cloneDeep(this.rows[rowIndex]);
    this.rows.splice(rowIndex, 1);
    this.rows.splice(rowIndex + 1, 0, cloneRow);
    this.checkIsDataChanged();
    this.setRowsAndHeaders();
  }

  onSaveEditedRowData(evt: {
    data: string | string[];
    colIndex: number;
    rowIndex: number;
  }) {
    if (
      evt.colIndex !== undefined &&
      evt.rowIndex !== undefined &&
      evt.data !== undefined
    ) {
      this.rows[evt.rowIndex][evt.colIndex].data = evt.data;
      this.checkIsDataChanged();
    }
  }

  private checkIsDataChanged(): void {
    const current = this.transformRowsToTableValue(this.rows);
    const prev = this.transformRowsToTableValue(this.oldRows);
    this.isDataChanged = this.checkIfDataChanged(
      current,
      prev,
      !!this.createModel
    );
  }

  checkIfDataChanged(
    current: TableValue,
    previous: TableValue,
    createModel: boolean = false
  ): boolean {
    if (createModel) {
      return current.headers.some((header) => header);
    }
    return !isEqual(current, previous);
  }

  private setColumnRowLength(headerlength, rowslength) {
    this.columnsLength = headerlength;
    this.rowsLength = rowslength;
  }

  mouseDown(evt, rowIndex) {
    const firstCellOfRow: any = document.querySelector(
      `tbody tr:nth-child(${rowIndex + 1}) textarea`
    );
    const prevHeight = parseInt(firstCellOfRow.style.height, 10);
    const MouseMove = (event: any) => {
      this.rowsHeight[rowIndex] = event.clientY - evt.clientY + prevHeight;
    };
    const MouseUp = () => {
      window.removeEventListener("mousemove", MouseMove, false);
      window.removeEventListener("mouseup", MouseUp, false);
    };
    window.addEventListener("mousemove", MouseMove);
    window.addEventListener("mouseup", MouseUp);
  }

  onRowHeightChangedByInputs(evt: HTMLTableCellElement, rowIndex: number) {
    const height = evt.clientHeight - 4;
    this.rowsHeight[rowIndex] = height;
  }

  private mergeArrays(
    value1: {
      data: string | number | string[];
      colIndex: number;
      rowIndex: number;
      dataType: TableCellDataType;
    }[],
    value2: {
      data: string | number | string[];
      colIndex: number;
      rowIndex: number;
      dataType: TableCellDataType;
    }[]
  ): {
    data: string | number | string[];
    colIndex: number;
    rowIndex: number;
    dataType: TableCellDataType;
  }[] {
    if (value1.length === value2.length) {
      value1.map((item) => {
        return { ...item, data: item.data.toString() };
      });
      value2.map((item) => {
        return { ...item, data: item.data.toString() };
      });
      const newvalue = value1.map((item, index) => {
        if (item.data === "") {
          return { ...item, data: `${value2[index].data}` };
        } else {
          return value2[index].data === ""
            ? { ...item, data: `${item.data}` }
            : {
                ...item,
                data: `${item.data}\n${value2[index].data}`,
              };
        }
      });
      return newvalue;
    }
  }

  private setRowsAndHeaders = () => {
    this.headers = cloneDeep(this.headers).map((header, colIndex) => {
      return this.setTableDetailValue(header.data, colIndex, 0);
    });
    this.rows = cloneDeep(this.rows).map((row, rowIndex) => {
      return row.map((col, colIndex) => {
        if (colIndex === 0) {
          return this.setTableDetailValue(rowIndex + 1, colIndex, rowIndex);
        }
        return this.setTableDetailValue(col.data, colIndex, rowIndex);
      });
    });
  };

  private setTableDetailValue(data, colIndex, rowIndex) {
    let dataType: TableCellDataType = "COMMON_NOUN";
    if (this.tableDetail.types?.length > colIndex) {
      dataType = this.tableDetail.types[colIndex];
    }
    return {
      data,
      colIndex,
      rowIndex,
      dataType,
    };
  }

  private setRowsHeight() {
    this.rowsHeight = this.rows.map(() => {
      return 35;
    });
  }
}
