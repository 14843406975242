import {
  FolderStructureNodeDtoModel,
  IngestDocSetIntoQueueResponseDtoModel,
  MediumNodeDtoModel,
  OApiReqUpdateCustomTagsRequestDtoModel,
  DocSetNodeDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { Action } from "@ngrx/store";
import {
  IDriveNode,
  DriveNodeCategoryUppercaseEnum,
  SelectedColumnFieldsModel,
  SelectedFilterModel,
  User,
  Permission,
} from "../models/drive-node.types";

export enum DriveActionTypes {
  UpdateRecordStatus = "[DriveNode] Update Record Status",
  UpdateDocument = "[DriveNode] Update Document",
  AddDocSetPartial = "[DriveNode] Add DocSet Just After Upload",

  UpdateRecord = "[DriveNode] Update Record",
  UpdateRecordSucceeded = "[DriveNode] Update Record Succeeded",
  UpdateRecordFailed = "[DriveNode] Update Record Failed",

  AddRecord = "[DriveNode] Add Record",
  AddRecordSucceeded = "[DriveNode] Add Record Succeeded",
  AddRecordFailed = "[DriveNode] Add Record Failed",

  AddDocSet = "[DriveNode] Add DocSet",
  AddDocSetSucceeded = "[DriveNode] Add DocSet Succeeded",
  AddDocSetFailed = "[DriveNode] Add DocSet Failed",

  AddDirectory = "[DriveNode] Add Directory",
  AddDirectorySucceeded = "[DriveNode] Add Directory Succeeded",
  AddDirectoryFailed = "[DriveNode] Add Directory Failed",

  LoadAllDriveNodes = "[DriveNode] Load All DriveNodes",
  LoadAllDriveNodesSucceeded = "[DriveNode] Load All DriveNodes Succeeded",
  LoadAllDriveNodesFailed = "[DriveNode] Load All DriveNodes Failed",

  SetSelectedColumnFields = "[DriveNode] Set Selected Column Fields",
  ApplyAdvancedSearch = "[DriveNode] Apply Advanced Search",
  CancelAdvancedSearch = "[DriveNode] Cancel Advanced Search",
  CloseAdvancedSearch = "[DriveNode] Close Advanced Search",

  ChangePage = "[DriveNode] Change Page",
  ChangePageSucceeded = "[DriveNode] Change Page Succeeded",
  ChangePageError = "[DriveNode] Change Page Error",

  CurrentSelectedColumnFields = "[DriveNode] Current Selected Column Fields",

  SetSelectedFilter = "[DriveNode] Set Selected Filter",

  SortDriveNodes = "[DriveNode] Sorts Records",
  SortsRecordsSucceeded = "[DriveNode] Sorts Records Succeeded",
  SortsRecordsError = "[DriveNode] Sorts Records Error",

  RenameDriveNode = "[DriveNode] Rename DriveNode",
  RenameDriveNodeSucceeded = "[DriveNode] Rename DriveNode Succeeded",
  RenameDriveNodeError = "[DriveNode] Rename DriveNode Error",

  UpdateCustomTags = "[DriveNode] Update Custom Tags",
  UpdateCustomTagsSucceeded = "[DriveNode] Update Custom Tags Succeeded",
  UpdateCustomTagsFailed = "[DriveNode] Update Custom Tags Failed",

  UpdateShareRecordWithUid = "[DriveNode] Update Share Record With Record Uid",

  AddBatchDocSetsToQueue = "[DriveNode] Add Batch DocSet To Queue",
  AddBatchDocSetToQueueSuccessful = "[DriveNode] Add Batch DocSet To Queue Successful",
  AddBatchDocSetToQueueFailed = "[DriveNode] Add Batch DocSet To Queue Failed",
}

export class AddDirectory implements Action {
  readonly type = DriveActionTypes.AddDirectory;

  constructor(
    public payload: {
      category:
        | "ALL"
        | "DELETED"
        | "FAVORITES"
        | "OWNED"
        | "SHARED"
        | "DOCUMENTS";
      directoryUid?: string;
      data: FolderStructureNodeDtoModel;
    }
  ) {}
}

export class AddDirectorySucceeded implements Action {
  readonly type = DriveActionTypes.AddDirectorySucceeded;

  constructor(
    public payload: {
      category:
        | "ALL"
        | "DELETED"
        | "FAVORITES"
        | "OWNED"
        | "SHARED"
        | "DOCUMENTS";
      directoryUid?: string;
      data: FolderStructureNodeDtoModel;
    }
  ) {}
}

export class AddDirectoryFailed implements Action {
  readonly type = DriveActionTypes.AddDirectoryFailed;

  constructor(public payload: any) {}
}

export class AddRecord implements Action {
  readonly type = DriveActionTypes.AddRecord;

  constructor(
    public payload: {
      recordUid: string;
      category:
        | "ALL"
        | "DELETED"
        | "FAVORITES"
        | "OWNED"
        | "SHARED"
        | "DOCUMENTS";
      directoryUid?: string;
      data: DocSetNodeDtoModel;
    }
  ) {}
}

export class AddRecordSucceeded implements Action {
  readonly type = DriveActionTypes.AddRecordSucceeded;

  constructor(
    public payload: {
      category:
        | "ALL"
        | "DELETED"
        | "FAVORITES"
        | "OWNED"
        | "SHARED"
        | "DOCUMENTS";
      directoryUid?: string;
      data: DocSetNodeDtoModel;
    }
  ) {}
}

export class AddRecordFailed implements Action {
  readonly type = DriveActionTypes.AddRecordFailed;

  constructor(public payload: any) {}
}

export class AddDocSet implements Action {
  readonly type = DriveActionTypes.AddDocSet;
  constructor(
    public payload: {
      docSetUid: string;
      category:
        | "ALL"
        | "DELETED"
        | "FAVORITES"
        | "OWNED"
        | "SHARED"
        | "DOCUMENTS";
      directoryUid?: string;
      data: DocSetNodeDtoModel;
    }
  ) {}
}

export class AddDocSetSucceeded implements Action {
  readonly type = DriveActionTypes.AddDocSetSucceeded;
  constructor(
    public payload: {
      category:
        | "ALL"
        | "DELETED"
        | "FAVORITES"
        | "OWNED"
        | "SHARED"
        | "DOCUMENTS";
      directoryUid?: string;
      data: DocSetNodeDtoModel;
    }
  ) {}
}

export class AddDocSetFailed implements Action {
  readonly type = DriveActionTypes.AddDocSetFailed;
  constructor(public payload: any) {}
}

export class UpdateRecordStatus implements Action {
  readonly type = DriveActionTypes.UpdateRecordStatus;

  constructor(public payload: { recordUid: string; status: string }) {}
}

export class UpdateDocument implements Action {
  readonly type = DriveActionTypes.UpdateDocument;

  constructor(
    public payload: {
      recordUid: string;
      queueName?: string;
      queueStage?: string;
      queueUid?: string;
    }
  ) {}
}

export class AddDocSetPartial implements Action {
  readonly type = DriveActionTypes.AddDocSetPartial;

  constructor(
    public payload: {
      docSetName: string;
      docSetUid?: string;
      directoryUid?: string;
      permissions?: Permission[];
      queueName?: string;
      queueUid?: string;
      queueStage?: string;
      recordUid?: string;
      owner?: User;
      documentCount?: number;
    }
  ) {}
}

export class UpdateRecord implements Action {
  readonly type = DriveActionTypes.UpdateRecord;

  constructor(public payload: { recordUid: string }) {}
}

export class UpdateRecordSucceeded implements Action {
  readonly type = DriveActionTypes.UpdateRecordSucceeded;

  constructor(public payload: any) {}
}

export class UpdateRecordFailed implements Action {
  readonly type = DriveActionTypes.UpdateRecordFailed;

  constructor(public payload: any) {}
}

export class SortDriveNodes implements Action {
  readonly type = DriveActionTypes.SortDriveNodes;

  constructor(
    public payload: {
      category?: DriveNodeCategoryUppercaseEnum;
      directoryUid?: string;
      page?: number;
      perPage?: number;
      view?: string;
      sorts?: string;
    }
  ) {}
}

export class SortsRecordsSucceeded implements Action {
  readonly type = DriveActionTypes.SortsRecordsSucceeded;

  constructor(public payload: { items?: any[] }) {}
}

export class SortsRecordsError implements Action {
  readonly type = DriveActionTypes.SortsRecordsError;
}

export class ChangePage implements Action {
  readonly type = DriveActionTypes.ChangePage;

  constructor(public payload: number) {}
}

export class ChangePageSucceeded implements Action {
  readonly type = DriveActionTypes.ChangePageSucceeded;

  constructor(public payload: number) {}
}

export class ChangePageError implements Action {
  readonly type = DriveActionTypes.ChangePageError;

  constructor(public payload: any) {}
}

export class LoadAllDriveNodes implements Action {
  readonly type = DriveActionTypes.LoadAllDriveNodes;

  constructor(
    public payload: {
      category?: DriveNodeCategoryUppercaseEnum;
      directoryUid?: string;
      page?: number;
      perPage?: number;
      view?: string;
      sorts?: string;
    }
  ) {}
}

export class LoadAllDriveNodesSucceeded implements Action {
  readonly type = DriveActionTypes.LoadAllDriveNodesSucceeded;

  constructor(public payload: any) {}
}

export class LoadAllDriveNodesFailed implements Action {
  readonly type = DriveActionTypes.LoadAllDriveNodesFailed;

  constructor(public payload: any) {}
}

export class SetSelectedColumnFields implements Action {
  readonly type = DriveActionTypes.SetSelectedColumnFields;

  constructor(public payload: SelectedColumnFieldsModel[]) {}
}

export class CurrentSelectedColumnFields implements Action {
  readonly type = DriveActionTypes.CurrentSelectedColumnFields;

  constructor(public payload: SelectedColumnFieldsModel[]) {}
}

export class SetSelectedFilter implements Action {
  readonly type = DriveActionTypes.SetSelectedFilter;

  constructor(public payload?: SelectedFilterModel) {}
}

export class ApplyAdvancedSearch implements Action {
  readonly type = DriveActionTypes.ApplyAdvancedSearch;
}

export class CancelAdvancedSearch implements Action {
  readonly type = DriveActionTypes.CancelAdvancedSearch;
}

export class CloseAdvancedSearch implements Action {
  readonly type = DriveActionTypes.CloseAdvancedSearch;
}

export class RenameDriveNode implements Action {
  readonly type = DriveActionTypes.RenameDriveNode;

  constructor(public payload: { row: IDriveNode; newName: string }) {}
}

export class RenameDriveNodeSucceeded implements Action {
  readonly type = DriveActionTypes.RenameDriveNodeSucceeded;

  constructor(public payload: MediumNodeDtoModel) {}
}

export class RenameDriveNodeError implements Action {
  readonly type = DriveActionTypes.RenameDriveNodeError;

  constructor(public payload: { oldName: string; uid: string }) {}
}

export class UpdateCustomTags implements Action {
  readonly type = DriveActionTypes.UpdateCustomTags;

  constructor(
    public payload: {
      recordUid: string;
      body: OApiReqUpdateCustomTagsRequestDtoModel;
    }
  ) {}
}

export class UpdateCustomTagsSucceeded implements Action {
  readonly type = DriveActionTypes.UpdateCustomTagsSucceeded;

  constructor(
    public payload: {
      recordUid: string;
      body: OApiReqUpdateCustomTagsRequestDtoModel;
    }
  ) {}
}

export class UpdateCustomTagsFailed implements Action {
  readonly type = DriveActionTypes.UpdateCustomTagsFailed;
}

export class UpdateShareRecordWithUid implements Action {
  readonly type = DriveActionTypes.UpdateShareRecordWithUid;

  constructor(public recordUid: string, public shared: boolean) {}
}

export class AddBatchDocSetsToQueue implements Action {
  readonly type = DriveActionTypes.AddBatchDocSetsToQueue;

  constructor(
    public payload: {
      queueUid: string;
      queueName: string;
      docSetUids: string[];
    }
  ) {}
}

export class AddBatchDocSetToQueueSuccessful implements Action {
  readonly type = DriveActionTypes.AddBatchDocSetToQueueSuccessful;

  constructor(
    public payload: {
      docSetUids: string[];
      ingestionResult: IngestDocSetIntoQueueResponseDtoModel[];
    }
  ) {}
}

export class AddBatchDocSetToQueueFailed implements Action {
  readonly type = DriveActionTypes.AddBatchDocSetToQueueFailed;

  constructor(public docSetUids: string[]) {}
}

export type DriveAction =
  | UpdateRecordStatus
  | UpdateDocument
  | UpdateRecord
  | UpdateRecordSucceeded
  | UpdateRecordFailed
  | AddDocSetPartial
  | AddDirectory
  | AddDirectorySucceeded
  | AddDirectoryFailed
  | AddRecord
  | AddRecordSucceeded
  | AddRecordFailed
  | AddDocSet
  | AddDocSetSucceeded
  | AddDocSetFailed
  | LoadAllDriveNodes
  | LoadAllDriveNodesSucceeded
  | LoadAllDriveNodesFailed
  | ChangePage
  | ChangePageSucceeded
  | ChangePageError
  | SetSelectedColumnFields
  | CurrentSelectedColumnFields
  | SetSelectedFilter
  | ApplyAdvancedSearch
  | CancelAdvancedSearch
  | CloseAdvancedSearch
  | SortDriveNodes
  | SortsRecordsSucceeded
  | SortsRecordsError
  | RenameDriveNode
  | RenameDriveNodeSucceeded
  | RenameDriveNodeError
  | UpdateCustomTags
  | UpdateCustomTagsSucceeded
  | UpdateCustomTagsFailed
  | UpdateShareRecordWithUid
  | AddBatchDocSetsToQueue
  | AddBatchDocSetToQueueSuccessful
  | AddBatchDocSetToQueueFailed;

export const fromDriveActions = {
  UpdateRecordStatus,

  UpdateDocument,

  UpdateRecord,
  UpdateRecordSucceeded,
  UpdateRecordFailed,

  AddDocSetPartial,

  AddDirectory,
  AddDirectorySucceeded,
  AddDirectoryFailed,

  AddRecord,
  AddRecordSucceeded,
  AddRecordFailed,

  AddDocSet,
  AddDocSetSucceeded,
  AddDocSetFailed,

  LoadAllDriveNodes,
  LoadAllDriveNodesSucceeded,
  LoadAllDriveNodesFailed,

  ChangePage,
  ChangePageSucceeded,
  ChangePageError,

  SetSelectedColumnFields,
  CurrentSelectedColumnFields,
  SetSelectedFilter,
  ApplyAdvancedSearch,
  CancelAdvancedSearch,
  CloseAdvancedSearch,

  SortDriveNodes,
  SortsRecordsSucceeded,
  SortsRecordsError,

  RenameDriveNode,
  RenameDriveNodeSucceeded,
  RenameDriveNodeError,

  UpdateCustomTags,
  UpdateCustomTagsSucceeded,
  UpdateCustomTagsFailed,

  UpdateShareRecordWithUid,

  AddBatchDocSetsToQueue,
  AddBatchDocSetToQueueSuccessful,
  AddBatchDocSetToQueueFailed,
};
