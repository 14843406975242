<div
  class="modal-content"
  [@animateStagger]="{ value: '100' }"
  cdkDragBoundary="body"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  ngResizable
  [rzMinHeight]="isDatePickerOpen ? 850 : 530"
  [rzMinWidth]="634"
  (rzResizing)="onResizing($event)"
  rzHandles="e,w,se"
>
  <div class="modal-header clearfix text-left" cdkDragHandle>
    <button
      style="padding: 10px"
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5>Options calculator</h5>
  </div>

  <div class="modal-body">
    <div
      class="form-container"
      [ngStyle]="{
        height: isDatePickerOpen
          ? 'calc(100% - 315px)'
          : isTableHidden
          ? '100%'
          : 'calc(100% - 140px)'
      }"
      [ngClass]="{ loading: !formData }"
    >
      <mat-progress-spinner
        *ngIf="!formData"
        diameter="50"
        mode="indeterminate"
      ></mat-progress-spinner>
      <div class="form-rows" *ngIf="formData">
        <ng-container *ngFor="let field of regularFields">
          <il-options-calculator-input-field
            *ngIf="field.fieldKey !== 'optionType'"
            [inputField]="formData[field.fieldKey]"
            [openDatePickerFieldKey]="selectedFieldKey"
            [toolTip]="getToolTip(field.fieldKey)"
            (click)="onOpenDatePicker(field.fieldKey)"
            (reset)="onFieldReset(field.fieldKey)"
          >
          </il-options-calculator-input-field>
        </ng-container>
      </div>

      <div class="summary-clause" *ngIf="formData">
        <il-options-calculator-input-field
          [inputField]="formData.optionType"
          [openDatePickerFieldKey]="selectedFieldKey"
          [toolTip]="getToolTip(formData.optionType.fieldKey)"
          (click)="onOpenDatePicker(formData.optionType.fieldKey)"
        >
        </il-options-calculator-input-field>
        <div class="form-group">
          <label>
            Clause Summary
            <i
              class="fa fa-info-circle ml-1 info-icon"
              container="modal-container"
              [tooltip]="'The summary of the chosen Option Type'"
            ></i>
          </label>
          <div>
            {{ getClauseSummaryText(formData?.optionType?.fieldValue) }}
          </div>
        </div>
      </div>
    </div>

    <div class="date-container">
      <div class="date-picker-container" *ngIf="isDatePickerOpen">
        <i
          class="fa fa-close"
          (click)="onDatePickerClosed()"
          *ngIf="isDatePickerOpen"
        ></i>
        <bs-datepicker-inline
          *ngIf="isDatePickerOpen"
          [(bsValue)]="datePickerValue"
          (bsValueChange)="onDateChange($event, selectedFieldKey)"
        ></bs-datepicker-inline>
      </div>
    </div>

    <div class="table-field-container" *ngIf="!isTableHidden">
      <div
        class="table-container"
        [@animateStagger]="{ value: '100' }"
        *ngIf="!isTableHidden"
      >
        <table
          class="table table-hover"
          [@animate]="{ value: '*', params: { y: '100px' } }"
        >
          <tr>
            <ng-container *ngFor="let column of tableColumns">
              <th class="text-center table-cell-value">
                {{ column.title }}
              </th>
            </ng-container>
          </tr>
          <tr *ngFor="let item of calculatedItems; let i = index">
            <ng-container *ngFor="let column of tableColumns">
              <td>
                <input
                  type="text"
                  [(ngModel)]="item[column.key]"
                  class="form-control text-center"
                />
              </td>
            </ng-container>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn btn-default btn-cons pull-left inline"
        style="padding: 8px"
        (click)="onResetClick()"
      >
        Reset
      </button>
      <button
        type="button"
        class="btn btn-primary btn-cons pull-left inline calculateBtn"
        (click)="onCalculateClick()"
      >
        Calculate
      </button>
      <button
        type="button"
        [disabled]="calculatedItems.length === 0 && isSavingCalculatedMention"
        class="btn btn-primary btn-cons pull-left inline importBtn"
        (click)="onImportClick()"
      >
        Import
      </button>
    </ng-container>
  </div>
</div>
