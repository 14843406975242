/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqCalculateRentsRequestModel } from "../models/o-api-req-calculate-rents-request-model";
import { OApiReqRentScheduleInputsRequestModel } from "../models/o-api-req-rent-schedule-inputs-request-model";
import { OApiReqRentScheduleInputsV2DtoModel } from "../models/o-api-req-rent-schedule-inputs-v-2-dto-model";
import { OApiRespMultiPriceIndexDtoModel } from "../models/o-api-resp-multi-price-index-dto-model";
import { OApiRespMultiRentItemModel } from "../models/o-api-resp-multi-rent-item-model";
import { OApiRespRentScheduleInputsModel } from "../models/o-api-resp-rent-schedule-inputs-model";

@Injectable({
  providedIn: "root",
})
export class RentScheduleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation calculateRents
   */
  static readonly CalculateRentsPath = "/api/v1/calculator/calc-rent-schedule";

  /**
   * Calculate rent schedule.
   *
   * If successful, return computed rent schedule with status 200.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateRents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateRents$Response(params: {
    body: OApiReqCalculateRentsRequestModel;
  }): Observable<StrictHttpResponse<OApiRespMultiRentItemModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RentScheduleService.CalculateRentsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiRentItemModel>;
        })
      );
  }

  /**
   * Calculate rent schedule.
   *
   * If successful, return computed rent schedule with status 200.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calculateRents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateRents(params: {
    body: OApiReqCalculateRentsRequestModel;
  }): Observable<OApiRespMultiRentItemModel> {
    return this.calculateRents$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiRentItemModel>) =>
          r.body as OApiRespMultiRentItemModel
      )
    );
  }

  /**
   * Path part for operation getPriceIndices
   */
  static readonly GetPriceIndicesPath = "/api/v1/calculator/price-index";

  /**
   * Get price indices.
   *
   * Return 200 status if able to successfully get price indices.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPriceIndices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPriceIndices$Response(params?: {
    /**
     * The price index country (if provided).
     */
    country?: Array<
      | "AFGHANISTAN"
      | "ALAND_ISLANDS"
      | "ALBANIA"
      | "ALGERIA"
      | "AMERICAN_SAMOA"
      | "ANDORRA"
      | "ANGOLA"
      | "ANGUILLA"
      | "ANTARCTICA"
      | "ANTIGUA_AND_BARBUDA"
      | "ARGENTINA"
      | "ARMENIA"
      | "ARUBA"
      | "AUSTRALIA"
      | "AUSTRIA"
      | "AZERBAIJAN"
      | "BAHAMAS"
      | "BAHRAIN"
      | "BANGLADESH"
      | "BARBADOS"
      | "BELARUS"
      | "BELGIUM"
      | "BELIZE"
      | "BENIN"
      | "BERMUDA"
      | "BHUTAN"
      | "BOLIVIA"
      | "BONAIRE"
      | "BOSNIA_AND_HERZEGOVINA"
      | "BOTSWANA"
      | "BOUVET_ISLAND"
      | "BRAZIL"
      | "BRITISH_INDIAN_OCEAN_TERRITORY"
      | "BRUNEI_DARUSSALAM"
      | "BULGARIA"
      | "BURKINA_FASO"
      | "BURUNDI"
      | "CAMBODIA"
      | "CAMEROON"
      | "CANADA"
      | "CAPE_VERDE"
      | "CAYMAN_ISLANDS"
      | "CENTRAL_AFRICAN_REPUBLIC"
      | "CHAD"
      | "CHILE"
      | "CHINA"
      | "CHRISTMAS_ISLAND"
      | "COCOS_ISLANDS"
      | "COLOMBIA"
      | "COMOROS"
      | "CONGO"
      | "DEMOCRATIC_REPUBLIC_OF_THE_CONGO"
      | "COOK_ISLANDS"
      | "COSTA_RICA"
      | "CROATIA"
      | "CUBA"
      | "CURAAO"
      | "CYPRUS"
      | "CZECH_REPUBLIC"
      | "COTE_DIVOIRE"
      | "DENMARK"
      | "DJIBOUTI"
      | "DOMINICA"
      | "DOMINICAN_REPUBLIC"
      | "ECUADOR"
      | "EGYPT"
      | "EL_SALVADOR"
      | "EQUATORIAL_GUINEA"
      | "ERITREA"
      | "ESTONIA"
      | "ETHIOPIA"
      | "FALKLAND_ISLANDS"
      | "FAROE_ISLANDS"
      | "FIJI"
      | "FINLAND"
      | "FRANCE"
      | "FRENCH_GUIANA"
      | "FRENCH_POLYNESIA"
      | "FRENCH_SOUTHERN_TERRITORIES"
      | "GABON"
      | "GAMBIA"
      | "GEORGIA"
      | "GERMANY"
      | "GHANA"
      | "GIBRALTAR"
      | "GREECE"
      | "GREENLAND"
      | "GRENADA"
      | "GUADELOUPE"
      | "GUAM"
      | "GUATEMALA"
      | "GUERNSEY"
      | "GUINEA"
      | "GUINEA_BISSAU"
      | "GUYANA"
      | "HAITI"
      | "HEARD_ISLAND_AND_MCDONALD_MCDONALD_ISLANDS"
      | "VATICAN_CITY_STATE"
      | "HONDURAS"
      | "HONG_KONG"
      | "HUNGARY"
      | "ICELAND"
      | "INDIA"
      | "INDONESIA"
      | "IRAN"
      | "IRAQ"
      | "IRELAND"
      | "ISLE_OF_MAN"
      | "ISRAEL"
      | "ITALY"
      | "JAMAICA"
      | "JAPAN"
      | "JERSEY"
      | "JORDAN"
      | "KAZAKHSTAN"
      | "KENYA"
      | "KIRIBATI"
      | "KUWAIT"
      | "KYRGYZSTAN"
      | "LAOS"
      | "LATVIA"
      | "LEBANON"
      | "LESOTHO"
      | "LIBERIA"
      | "LIBYA"
      | "LIECHTENSTEIN"
      | "LITHUANIA"
      | "LUXEMBOURG"
      | "MACAO"
      | "MACEDONIA"
      | "MADAGASCAR"
      | "MALAWI"
      | "MALAYSIA"
      | "MALDIVES"
      | "MALI"
      | "MALTA"
      | "MARSHALL_ISLANDS"
      | "MARTINIQUE"
      | "MAURITANIA"
      | "MAURITIUS"
      | "MAYOTTE"
      | "MEXICO"
      | "MICRONESIA"
      | "MOLDOVA"
      | "MONACO"
      | "MONGOLIA"
      | "MONTENEGRO"
      | "MONTSERRAT"
      | "MOROCCO"
      | "MOZAMBIQUE"
      | "MYANMAR"
      | "NAMIBIA"
      | "NAURU"
      | "NEPAL"
      | "NETHERLANDS"
      | "NEW_CALEDONIA"
      | "NEW_ZEALAND"
      | "NICARAGUA"
      | "NIGER"
      | "NIGERIA"
      | "NIUE"
      | "NORFOLK_ISLAND"
      | "NORTH_KOREA"
      | "NORTHERN_MARIANA_ISLANDS"
      | "NORWAY"
      | "OMAN"
      | "PAKISTAN"
      | "PALAU"
      | "PALESTINE"
      | "PANAMA"
      | "PAPUA_NEW_GUINEA"
      | "PARAGUAY"
      | "PERU"
      | "PHILIPPINES"
      | "PITCAIRN"
      | "POLAND"
      | "PORTUGAL"
      | "PUERTO_RICO"
      | "QATAR"
      | "ROMANIA"
      | "RUSSIAN_FEDERATION"
      | "RWANDA"
      | "REUNION"
      | "SAINT_BARTHELEMY"
      | "SAINT_HELENA"
      | "SAINT_KITTS_AND_NEVIS"
      | "SAINT_LUCIA"
      | "SAINT_MARTIN"
      | "SAINT_PIERRE_AND_MIQUELON"
      | "SAINT_VINCENT_AND_THE_GRENADINES"
      | "SAMOA"
      | "SAN_MARINO"
      | "SAO_TOME_AND_PRINCIPE"
      | "SAUDI_ARABIA"
      | "SENEGAL"
      | "SERBIA"
      | "SEYCHELLES"
      | "SIERRA_LEONE"
      | "SINGAPORE"
      | "SINT_MAARTEN"
      | "SLOVAKIA"
      | "SLOVENIA"
      | "SOLOMON_ISLANDS"
      | "SOMALIA"
      | "SOUTH_AFRICA"
      | "SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS"
      | "SOUTH_KOREA"
      | "SOUTH_SUDAN"
      | "SPAIN"
      | "SRI_LANKA"
      | "SUDAN"
      | "SURINAME"
      | "SVALBARD_AND_JAN_MAYEN"
      | "SWAZILAND"
      | "SWEDEN"
      | "SWITZERLAND"
      | "SYRIAN_ARAB_REPUBLIC"
      | "TAIWAN"
      | "TAJIKISTAN"
      | "UNITED_REPUBLIC_OF_TANZANIA"
      | "THAILAND"
      | "TIMOR_LESTE"
      | "TOGO"
      | "TOKELAU"
      | "TONGA"
      | "TRINIDAD_AND_TOBAGO"
      | "TUNISIA"
      | "TURKEY"
      | "TURKMENISTAN"
      | "TURKS_AND_CAICOS_ISLANDS"
      | "TUVALU"
      | "UGANDA"
      | "UKRAINE"
      | "UNITED_ARAB_EMIRATES"
      | "UNITED_KINGDOM"
      | "UNITED_STATES"
      | "UNITED_STATES_MINOR_OUTLYING_ISLANDS"
      | "URUGUAY"
      | "UZBEKISTAN"
      | "VANUATU"
      | "VENEZUELA"
      | "VIET_NAM"
      | "BRITISH_VIRGIN_ISLANDS"
      | "US_VIRGIN_ISLANDS"
      | "WALLIS_AND_FUTUNA"
      | "WESTERN_SAHARA"
      | "YEMEN"
      | "ZAMBIA"
      | "ZIMBABWE"
    >;
  }): Observable<StrictHttpResponse<OApiRespMultiPriceIndexDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RentScheduleService.GetPriceIndicesPath,
      "get"
    );
    if (params) {
      rb.query("country", params.country, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiPriceIndexDtoModel>;
        })
      );
  }

  /**
   * Get price indices.
   *
   * Return 200 status if able to successfully get price indices.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPriceIndices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPriceIndices(params?: {
    /**
     * The price index country (if provided).
     */
    country?: Array<
      | "AFGHANISTAN"
      | "ALAND_ISLANDS"
      | "ALBANIA"
      | "ALGERIA"
      | "AMERICAN_SAMOA"
      | "ANDORRA"
      | "ANGOLA"
      | "ANGUILLA"
      | "ANTARCTICA"
      | "ANTIGUA_AND_BARBUDA"
      | "ARGENTINA"
      | "ARMENIA"
      | "ARUBA"
      | "AUSTRALIA"
      | "AUSTRIA"
      | "AZERBAIJAN"
      | "BAHAMAS"
      | "BAHRAIN"
      | "BANGLADESH"
      | "BARBADOS"
      | "BELARUS"
      | "BELGIUM"
      | "BELIZE"
      | "BENIN"
      | "BERMUDA"
      | "BHUTAN"
      | "BOLIVIA"
      | "BONAIRE"
      | "BOSNIA_AND_HERZEGOVINA"
      | "BOTSWANA"
      | "BOUVET_ISLAND"
      | "BRAZIL"
      | "BRITISH_INDIAN_OCEAN_TERRITORY"
      | "BRUNEI_DARUSSALAM"
      | "BULGARIA"
      | "BURKINA_FASO"
      | "BURUNDI"
      | "CAMBODIA"
      | "CAMEROON"
      | "CANADA"
      | "CAPE_VERDE"
      | "CAYMAN_ISLANDS"
      | "CENTRAL_AFRICAN_REPUBLIC"
      | "CHAD"
      | "CHILE"
      | "CHINA"
      | "CHRISTMAS_ISLAND"
      | "COCOS_ISLANDS"
      | "COLOMBIA"
      | "COMOROS"
      | "CONGO"
      | "DEMOCRATIC_REPUBLIC_OF_THE_CONGO"
      | "COOK_ISLANDS"
      | "COSTA_RICA"
      | "CROATIA"
      | "CUBA"
      | "CURAAO"
      | "CYPRUS"
      | "CZECH_REPUBLIC"
      | "COTE_DIVOIRE"
      | "DENMARK"
      | "DJIBOUTI"
      | "DOMINICA"
      | "DOMINICAN_REPUBLIC"
      | "ECUADOR"
      | "EGYPT"
      | "EL_SALVADOR"
      | "EQUATORIAL_GUINEA"
      | "ERITREA"
      | "ESTONIA"
      | "ETHIOPIA"
      | "FALKLAND_ISLANDS"
      | "FAROE_ISLANDS"
      | "FIJI"
      | "FINLAND"
      | "FRANCE"
      | "FRENCH_GUIANA"
      | "FRENCH_POLYNESIA"
      | "FRENCH_SOUTHERN_TERRITORIES"
      | "GABON"
      | "GAMBIA"
      | "GEORGIA"
      | "GERMANY"
      | "GHANA"
      | "GIBRALTAR"
      | "GREECE"
      | "GREENLAND"
      | "GRENADA"
      | "GUADELOUPE"
      | "GUAM"
      | "GUATEMALA"
      | "GUERNSEY"
      | "GUINEA"
      | "GUINEA_BISSAU"
      | "GUYANA"
      | "HAITI"
      | "HEARD_ISLAND_AND_MCDONALD_MCDONALD_ISLANDS"
      | "VATICAN_CITY_STATE"
      | "HONDURAS"
      | "HONG_KONG"
      | "HUNGARY"
      | "ICELAND"
      | "INDIA"
      | "INDONESIA"
      | "IRAN"
      | "IRAQ"
      | "IRELAND"
      | "ISLE_OF_MAN"
      | "ISRAEL"
      | "ITALY"
      | "JAMAICA"
      | "JAPAN"
      | "JERSEY"
      | "JORDAN"
      | "KAZAKHSTAN"
      | "KENYA"
      | "KIRIBATI"
      | "KUWAIT"
      | "KYRGYZSTAN"
      | "LAOS"
      | "LATVIA"
      | "LEBANON"
      | "LESOTHO"
      | "LIBERIA"
      | "LIBYA"
      | "LIECHTENSTEIN"
      | "LITHUANIA"
      | "LUXEMBOURG"
      | "MACAO"
      | "MACEDONIA"
      | "MADAGASCAR"
      | "MALAWI"
      | "MALAYSIA"
      | "MALDIVES"
      | "MALI"
      | "MALTA"
      | "MARSHALL_ISLANDS"
      | "MARTINIQUE"
      | "MAURITANIA"
      | "MAURITIUS"
      | "MAYOTTE"
      | "MEXICO"
      | "MICRONESIA"
      | "MOLDOVA"
      | "MONACO"
      | "MONGOLIA"
      | "MONTENEGRO"
      | "MONTSERRAT"
      | "MOROCCO"
      | "MOZAMBIQUE"
      | "MYANMAR"
      | "NAMIBIA"
      | "NAURU"
      | "NEPAL"
      | "NETHERLANDS"
      | "NEW_CALEDONIA"
      | "NEW_ZEALAND"
      | "NICARAGUA"
      | "NIGER"
      | "NIGERIA"
      | "NIUE"
      | "NORFOLK_ISLAND"
      | "NORTH_KOREA"
      | "NORTHERN_MARIANA_ISLANDS"
      | "NORWAY"
      | "OMAN"
      | "PAKISTAN"
      | "PALAU"
      | "PALESTINE"
      | "PANAMA"
      | "PAPUA_NEW_GUINEA"
      | "PARAGUAY"
      | "PERU"
      | "PHILIPPINES"
      | "PITCAIRN"
      | "POLAND"
      | "PORTUGAL"
      | "PUERTO_RICO"
      | "QATAR"
      | "ROMANIA"
      | "RUSSIAN_FEDERATION"
      | "RWANDA"
      | "REUNION"
      | "SAINT_BARTHELEMY"
      | "SAINT_HELENA"
      | "SAINT_KITTS_AND_NEVIS"
      | "SAINT_LUCIA"
      | "SAINT_MARTIN"
      | "SAINT_PIERRE_AND_MIQUELON"
      | "SAINT_VINCENT_AND_THE_GRENADINES"
      | "SAMOA"
      | "SAN_MARINO"
      | "SAO_TOME_AND_PRINCIPE"
      | "SAUDI_ARABIA"
      | "SENEGAL"
      | "SERBIA"
      | "SEYCHELLES"
      | "SIERRA_LEONE"
      | "SINGAPORE"
      | "SINT_MAARTEN"
      | "SLOVAKIA"
      | "SLOVENIA"
      | "SOLOMON_ISLANDS"
      | "SOMALIA"
      | "SOUTH_AFRICA"
      | "SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS"
      | "SOUTH_KOREA"
      | "SOUTH_SUDAN"
      | "SPAIN"
      | "SRI_LANKA"
      | "SUDAN"
      | "SURINAME"
      | "SVALBARD_AND_JAN_MAYEN"
      | "SWAZILAND"
      | "SWEDEN"
      | "SWITZERLAND"
      | "SYRIAN_ARAB_REPUBLIC"
      | "TAIWAN"
      | "TAJIKISTAN"
      | "UNITED_REPUBLIC_OF_TANZANIA"
      | "THAILAND"
      | "TIMOR_LESTE"
      | "TOGO"
      | "TOKELAU"
      | "TONGA"
      | "TRINIDAD_AND_TOBAGO"
      | "TUNISIA"
      | "TURKEY"
      | "TURKMENISTAN"
      | "TURKS_AND_CAICOS_ISLANDS"
      | "TUVALU"
      | "UGANDA"
      | "UKRAINE"
      | "UNITED_ARAB_EMIRATES"
      | "UNITED_KINGDOM"
      | "UNITED_STATES"
      | "UNITED_STATES_MINOR_OUTLYING_ISLANDS"
      | "URUGUAY"
      | "UZBEKISTAN"
      | "VANUATU"
      | "VENEZUELA"
      | "VIET_NAM"
      | "BRITISH_VIRGIN_ISLANDS"
      | "US_VIRGIN_ISLANDS"
      | "WALLIS_AND_FUTUNA"
      | "WESTERN_SAHARA"
      | "YEMEN"
      | "ZAMBIA"
      | "ZIMBABWE"
    >;
  }): Observable<OApiRespMultiPriceIndexDtoModel> {
    return this.getPriceIndices$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiPriceIndexDtoModel>) =>
          r.body as OApiRespMultiPriceIndexDtoModel
      )
    );
  }

  /**
   * Path part for operation retrieveRentScheduleInputs
   */
  static readonly RetrieveRentScheduleInputsPath =
    "/api/v1/calculator/rent-schedule";

  /**
   * Get the inputs for the rent schedule calculator.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveRentScheduleInputs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retrieveRentScheduleInputs$Response(params: {
    body: OApiReqRentScheduleInputsRequestModel;
  }): Observable<StrictHttpResponse<OApiRespRentScheduleInputsModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RentScheduleService.RetrieveRentScheduleInputsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRentScheduleInputsModel>;
        })
      );
  }

  /**
   * Get the inputs for the rent schedule calculator.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retrieveRentScheduleInputs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retrieveRentScheduleInputs(params: {
    body: OApiReqRentScheduleInputsRequestModel;
  }): Observable<OApiRespRentScheduleInputsModel> {
    return this.retrieveRentScheduleInputs$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRentScheduleInputsModel>) =>
          r.body as OApiRespRentScheduleInputsModel
      )
    );
  }

  /**
   * Path part for operation retrieveRentScheduleInputsV2
   */
  static readonly RetrieveRentScheduleInputsV2Path =
    "/api/v2/calculator/rent-schedule";

  /**
   * Get the inputs for the rent schedule calculator.
   *
   * Return status 200 if able to successfully get rent schedule calculator inputs.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveRentScheduleInputsV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retrieveRentScheduleInputsV2$Response(params: {
    body: OApiReqRentScheduleInputsV2DtoModel;
  }): Observable<StrictHttpResponse<OApiRespRentScheduleInputsModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RentScheduleService.RetrieveRentScheduleInputsV2Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRentScheduleInputsModel>;
        })
      );
  }

  /**
   * Get the inputs for the rent schedule calculator.
   *
   * Return status 200 if able to successfully get rent schedule calculator inputs.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retrieveRentScheduleInputsV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retrieveRentScheduleInputsV2(params: {
    body: OApiReqRentScheduleInputsV2DtoModel;
  }): Observable<OApiRespRentScheduleInputsModel> {
    return this.retrieveRentScheduleInputsV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRentScheduleInputsModel>) =>
          r.body as OApiRespRentScheduleInputsModel
      )
    );
  }
}
