import { FetchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FetchMultiProvisionValueService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly PROVISIONS_URL = "/provisions";

  constructor(private fetchApiService: FetchApiService) {}

  /**
   * When user searches for a provision from the upper-right search or if chooses a provision upon highlighting
   * on the PDF, then call this API to be able to populate the upper-right provision box that opens up.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose possible values we want to show the user
   * @param view - the view type to return
   * @param classRef - the class of the view to return
   * @return the multi-provision value for this provision for this abstract
   */
  getMultiProvisionValueView<T extends object>(
    abstractUid: string,
    provisionUid: string,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    return this.fetchApiService.sendRequest(
      FetchMultiProvisionValueService.API_VERSION_1,
      `${FetchMultiProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}${FetchMultiProvisionValueService.PROVISIONS_URL}`,
      provisionUid,
      view,
      classRef
    );
  }
}
