/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("GoogleDriveInfoViewModel")
export class GoogleDriveInfoViewModel {
  @JsonProperty("autoSyncEnabled", Boolean, true)
  autoSyncEnabled?: boolean = undefined;

  @JsonProperty("connected", Boolean, true)
  connected?: boolean = undefined;

  @JsonProperty("syncFolderId", String, true)
  syncFolderId?: string = "";

  @JsonProperty("syncFolderName", String, true)
  syncFolderName?: string = "";

  @JsonProperty("syncFolderWebViewUrl", String, true)
  syncFolderWebViewUrl?: string = "";
}
