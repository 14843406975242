/* tslint:disable */
/* eslint-disable */
import { AbstractProvisionGroupModel } from "./abstract-provision-group-model";
import { CustomTagViewModel } from "./custom-tag-view-model";
import { DocSetCategoryViewModel } from "./doc-set-category-view-model";
import { ProvisionFormStructureViewModel } from "./provision-form-structure-view-model";
import { QueueInfoDtoModel } from "./queue-info-dto-model";
import { QueueInfoViewModel } from "./queue-info-view-model";
import { RecordLockInfoDtoModel } from "./record-lock-info-dto-model";
import { RecordPathModel } from "./record-path-model";
import { RelatedDocumentModel } from "./related-document-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullFinalAbstractViewModel")
export class FullFinalAbstractViewModel {
  @JsonProperty("abstractCreationDate", DateTimeConvertService, true)
  abstractCreationDate?: Date | undefined = undefined;

  @JsonProperty("abstractFilename", String, true)
  abstractFilename?: string = "";

  @JsonProperty("abstractName", String, true)
  abstractName?: string = "";

  @JsonProperty("commentCountInRecord", Number, true)
  commentCountInRecord?: number = undefined;

  @JsonProperty("customTags", [CustomTagViewModel], true)
  customTags?: Array<CustomTagViewModel> = [];

  @JsonProperty("docSetCategory", DocSetCategoryViewModel, true)
  docSetCategory?: DocSetCategoryViewModel = undefined;

  @JsonProperty("docSetUid", String, true)
  docSetUid?: string = "";

  @JsonProperty("documents", [RelatedDocumentModel], true)
  documents?: Array<RelatedDocumentModel> = [];

  @JsonProperty("formStructure", ProvisionFormStructureViewModel, true)
  formStructure?: ProvisionFormStructureViewModel = undefined;

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("lockInfo", RecordLockInfoDtoModel, true)
  lockInfo?: RecordLockInfoDtoModel = undefined;

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];

  @JsonProperty("provisionGroups", [AbstractProvisionGroupModel], true)
  provisionGroups?: Array<AbstractProvisionGroupModel> = [];

  @JsonProperty("queueInfo", QueueInfoDtoModel, true)
  queueInfo?: QueueInfoDtoModel = undefined;

  @JsonProperty("queueInfoView", QueueInfoViewModel, true)
  queueInfoView?: QueueInfoViewModel = undefined;

  @JsonProperty("suiteVersionAbstractId", RecordPathModel, true)
  suiteVersionAbstractId?: RecordPathModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("versions", [Number], true)
  versions?: Array<number> = [];
}
