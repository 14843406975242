import { ArrayHelper } from "@@intelease/web/intelease/utils";
import { ReportsAction, ReportsActionTypes } from "./reports.actions";

export const REPORTS_FEATURE_KEY = "reports";

/**
 * Interface for the 'Reports' data used in
 *  - ReportsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* eslint-disable-next-line */
export interface Entity {}

export interface ReportsState {
  list?: Entity[]; // list of Reports; analogous to a sql normalized table
  selectedId?: string | number; // which Reports record has been selected
  loaded?: boolean; // has the Reports list been loaded
  isSavingReport?: boolean;
  reportSaved?: boolean;
  reportsList?: any;
  reportsListLoaded?: boolean;
  reportsListLoadError?: boolean;
  selectedReportDetail?: any;
  selectedReportLoadError?: any;
  selectedReportDetailMap?: any;
  error?: any; // last none error (if any),
  reportColumnsLoaded?: boolean;
  reportColumnsLoadError?: any;
  reportFilterFields?: any;
  reportFilterFieldsLoaded?: boolean;
  reportFilterFieldsLoadError?: any;
  fieldTypeToOperationAggregations?: any;
  abstractsReport?: any;
  abstractsReportsMap?: any;
  abstractsReportLoaded?: boolean;
  abstractsReportLoadError?: any;
  provisionsOperator?: any;
  selectedColumns?: any;
  selectedFilters?: any;
  selectedReportDeleteSucceeded?: boolean;
  selectedReportDeleteFailed?: any;
  selectedReportExportSucceeded?: boolean;
  selectedReportExportFailed?: any;

  isSelectedReportUpdatePending?: boolean;
  selectedReportUpdateSucceeded?: boolean;
  selectedReportUpdateFailed?: any;

  isMultipleReportDeletePending?: boolean;
  multipleReportDeleteSucceeded?: boolean;
  multipleReportDeleteFailed?: any;

  isDuplictSelectedReportPending?: boolean;
  duplictSelectedReportSucceeded?: boolean;
  duplictSelectedReportFailed?: any;
}

export interface ReportsPartialState {
  readonly [REPORTS_FEATURE_KEY]: ReportsState;
}

export const initialState: ReportsState = {
  list: [],
  loaded: false,
  reportsList: undefined,
  reportsListLoaded: false,
  reportsListLoadError: undefined,
  reportColumnsLoaded: false,
  reportColumnsLoadError: undefined,
  reportFilterFields: {},
  reportFilterFieldsLoaded: false,
  reportFilterFieldsLoadError: undefined,
  abstractsReport: undefined,
  abstractsReportLoaded: false,
  abstractsReportLoadError: undefined,
  provisionsOperator: undefined,
  selectedColumns: { generalViewColumns: [], provisionViewColumns: [] },
  selectedFilters: { generalFilters: [], provisionFilters: [] },
  abstractsReportsMap: {},
  selectedReportDetailMap: {},
  selectedReportLoadError: {},
};

export function reducer(
  state: ReportsState = initialState,
  action: ReportsAction
): ReportsState {
  switch (action.type) {
    case ReportsActionTypes.LoadReports: {
      state = {
        ...state,
        reportsList: undefined,
        reportsListLoaded: false,
        reportsListLoadError: undefined,
      };
      break;
    }
    case ReportsActionTypes.ReportsLoaded: {
      state = {
        ...state,
        reportsListLoaded: true,
        reportsList: action.payload,
        reportsListLoadError: undefined,
      };
      break;
    }
    case ReportsActionTypes.ReportsLoadError: {
      state = {
        ...state,
        reportsList: undefined,
        reportsListLoaded: false,
        reportsListLoadError: action.payload,
      };
      break;
    }
    case ReportsActionTypes.SaveReport: {
      state = {
        ...state,
        isSavingReport: true,
        reportSaved: false,
      };
      break;
    }
    case ReportsActionTypes.SaveReportSucceeded: {
      state = {
        ...state,
        isSavingReport: false,
        reportSaved: true,
      };
      break;
    }
    case ReportsActionTypes.SaveReportFailed: {
      state = {
        ...state,
        isSavingReport: false,
        reportSaved: false,
      };
      break;
    }
    case ReportsActionTypes.LoadSelectedReport: {
      state = {
        ...state,
        abstractsReport: undefined,
        abstractsReportLoaded: false,
        abstractsReportLoadError: undefined,
        selectedReportDetail: undefined,
        selectedReportLoadError: {
          ...state.selectedReportLoadError,
          [action.selectedInstanceId]: undefined,
        },
        selectedReportDetailMap: {
          ...state.selectedReportDetailMap,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case ReportsActionTypes.SelectedReportLoaded: {
      state = {
        ...state,
        selectedReportDetail: action.payload,
        selectedReportLoadError: {
          ...state.selectedReportLoadError,
          [action.selectedInstanceId]: undefined,
        },
        selectedReportDetailMap: {
          ...state.selectedReportDetailMap,
          [action.selectedInstanceId]: action.payload,
        },
      };
      break;
    }
    case ReportsActionTypes.SelectedReportLoadError: {
      state = {
        ...state,
        selectedReportDetail: undefined,
        selectedReportLoadError: {
          ...state.selectedReportLoadError,
          [action.selectedInstanceId]: action.payload,
        },
        selectedReportDetailMap: {
          ...state.selectedReportDetailMap,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case ReportsActionTypes.LoadReportColumns: {
      state = {
        ...state,
        reportColumnsLoaded: false,
        reportColumnsLoadError: undefined,
      };
      break;
    }
    case ReportsActionTypes.ReportColumnsLoaded: {
      state = {
        ...state,
        reportColumnsLoaded: true,
        reportColumnsLoadError: undefined,
      };
      break;
    }
    case ReportsActionTypes.ReportColumnsLoadError: {
      state = {
        ...state,
        reportColumnsLoaded: false,
        reportColumnsLoadError: action.payload,
      };
      break;
    }
    case ReportsActionTypes.LoadReportFilterFields: {
      state = {
        ...state,
        reportFilterFieldsLoaded: false,
        reportFilterFieldsLoadError: undefined,
      };
      break;
    }
    case ReportsActionTypes.ReportFilterFieldsLoaded: {
      const {
        filterProvisions,
        provisionsOperator,
        fieldTypeToOperationAggregations,
      } = action.payload;
      state = {
        ...state,
        reportFilterFields: filterProvisions,
        provisionsOperator,
        fieldTypeToOperationAggregations,
        reportFilterFieldsLoaded: true,
        reportFilterFieldsLoadError: undefined,
      };
      break;
    }
    case ReportsActionTypes.ReportFilterFieldsLoadError: {
      state = {
        ...state,
        reportFilterFieldsLoaded: false,
        reportFilterFieldsLoadError: action.payload,
      };
      break;
    }
    case ReportsActionTypes.LoadAbstractsReport: {
      state = {
        ...state,
        abstractsReportLoaded: false,
        abstractsReportLoadError: undefined,
        abstractsReport: undefined,
        abstractsReportsMap: {
          ...state.abstractsReportsMap,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case ReportsActionTypes.AbstractsReportLoaded: {
      state = {
        ...state,
        abstractsReport: action.payload,
        abstractsReportsMap: {
          ...state.abstractsReportsMap,
          [action.selectedInstanceId]: action.payload,
        },
        abstractsReportLoaded: true,
        abstractsReportLoadError: undefined,
      };
      break;
    }
    case ReportsActionTypes.AbstractsReportLoadError: {
      state = {
        ...state,
        abstractsReport: undefined,
        abstractsReportLoaded: false,
        abstractsReportLoadError: action.payload,
        abstractsReportsMap: {
          ...state.abstractsReportsMap,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case ReportsActionTypes.SaveSelectedColumns: {
      state = {
        ...state,
        selectedColumns: action.payload,
      };
      break;
    }
    case ReportsActionTypes.RemoveSelectedColumns: {
      state = {
        ...state,
        selectedColumns: {
          generalViewColumns: [],
          provisionViewColumns: [],
        },
      };
      break;
    }
    case ReportsActionTypes.RemoveSelectedFilters: {
      state = {
        ...state,
        selectedFilters: { generalFilters: [], provisionFilters: [] },
      };
      break;
    }
    case ReportsActionTypes.SaveSelectedFilters: {
      state = {
        ...state,
        selectedFilters: action.payload,
      };
      break;
    }
    case ReportsActionTypes.CleanReportState: {
      state = {
        ...state,
        ...action.payload,
      };
      break;
    }
    case ReportsActionTypes.EmptyAbstractReport: {
      state = {
        ...state,
        abstractsReport: [],
        abstractsReportLoadError: undefined,
        abstractsReportLoaded: false,
      };
      break;
    }
    case ReportsActionTypes.EmptySelectedReport: {
      state = {
        ...state,
        selectedReportDetail: undefined,
      };
      break;
    }
    case ReportsActionTypes.DeleteSelectedReport: {
      state = {
        ...state,
        selectedReportDeleteFailed: undefined,
        selectedReportDeleteSucceeded: false,
      };
      break;
    }
    case ReportsActionTypes.DeleteSelectedReportFailed: {
      state = {
        ...state,
        selectedReportDeleteSucceeded: false,
        selectedReportDeleteFailed: action.payload,
      };
      break;
    }
    case ReportsActionTypes.DeleteSelectedReportSucceeded: {
      state = {
        ...state,
        selectedReportDeleteSucceeded: true,
        selectedReportDeleteFailed: undefined,
      };
      break;
    }
    case ReportsActionTypes.ExportSelectedReport: {
      state = {
        ...state,
        selectedReportExportFailed: undefined,
        selectedReportExportSucceeded: false,
      };
      break;
    }
    case ReportsActionTypes.ExportSelectedReportFailed: {
      state = {
        ...state,
        selectedReportExportSucceeded: false,
        selectedReportExportFailed: action.payload,
      };
      break;
    }
    case ReportsActionTypes.ExportSelectedReportSucceeded: {
      state = {
        ...state,
        selectedReportExportSucceeded: true,
        selectedReportExportFailed: undefined,
      };
      break;
    }
    case ReportsActionTypes.UpdateSelectedReport: {
      state = {
        ...state,
        isSelectedReportUpdatePending: true,
        selectedReportUpdateSucceeded: false,
        selectedReportUpdateFailed: undefined,
      };
      break;
    }
    case ReportsActionTypes.UpdateSelectedReportSucceeded: {
      const { report } = action;
      return {
        ...state,
        reportsList: {
          ...state.reportsList,
          items: state.reportsList?.items
            ? ArrayHelper.replaceItem(state.reportsList?.items, report, ["uid"])
            : undefined,
        },
        isSelectedReportUpdatePending: false,
        selectedReportUpdateSucceeded: true,
        selectedReportUpdateFailed: undefined,
      };
    }
    case ReportsActionTypes.UpdateSelectedReportFailed: {
      state = {
        ...state,
        isSelectedReportUpdatePending: false,
        selectedReportUpdateSucceeded: false,
        selectedReportUpdateFailed: action.payload,
      };
      break;
    }

    case ReportsActionTypes.DeleteMultipleReport: {
      state = {
        ...state,
        multipleReportDeleteSucceeded: false,
        isMultipleReportDeletePending: true,
        multipleReportDeleteFailed: undefined,
      };
      break;
    }
    case ReportsActionTypes.DeleteMultipleReportSucceeded: {
      state = {
        ...state,
        multipleReportDeleteSucceeded: true,
        isMultipleReportDeletePending: false,
        multipleReportDeleteFailed: undefined,
      };
      break;
    }
    case ReportsActionTypes.DeleteMultipleReportFailed: {
      state = {
        ...state,
        multipleReportDeleteSucceeded: false,
        isMultipleReportDeletePending: false,
        multipleReportDeleteFailed: action.payload,
      };
      break;
    }
    case ReportsActionTypes.DuplicateSelectedReport: {
      state = {
        ...state,
        isDuplictSelectedReportPending: true,
        duplictSelectedReportSucceeded: false,
        duplictSelectedReportFailed: undefined,
      };
      break;
    }
    case ReportsActionTypes.DuplicateSelectedReportSucceeded: {
      state = {
        ...state,
        reportsList: {
          ...state.reportsList,
          items: [action.payload, ...state.reportsList.items],
          pagination: {
            ...state.reportsList.pagination,
            totalResults: state.reportsList.pagination.totalResults + 1,
          },
        },
        isDuplictSelectedReportPending: false,
        duplictSelectedReportFailed: undefined,
        duplictSelectedReportSucceeded: true,
      };
      break;
    }
    case ReportsActionTypes.DuplicateSelectedReportFailed: {
      state = {
        ...state,
        isDuplictSelectedReportPending: false,
        duplictSelectedReportFailed: action.payload,
      };
      break;
    }
  }
  return state;
}
