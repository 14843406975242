import { Injectable } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import { PermissionApiService } from "@@intelease/web/intelease/services/backend";
import { AuthService } from "../../../app-services/auth/src/lib/auth/auth.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class InteleasePermissionsService {
  //TODO(reza) this is bad but i had to do it to prevent duplication
  public static allExpectationsCovered(
    expected: string | string[],
    given: string[]
  ): boolean {
    if (!expected || !expected.length) {
      return true;
    }
    if (!given || !given.length) {
      return false;
    }
    if (typeof expected === "string") {
      return given.includes(expected);
    } else {
      for (const expectation of expected) {
        if (!given.includes(expectation)) {
          return false;
        }
      }
      return true;
    }
  }

  constructor(
    private permissionsService: NgxPermissionsService,
    private permissionsApiService: PermissionApiService,
    private authService: AuthService,
    private ngxPermissionsService: NgxPermissionsService
  ) {}

  setup() {
    if (this.authService.isLoggedIn()) {
      const givenPermissions = this.ngxPermissionsService.getPermissions();
      const permissions = Object.keys(givenPermissions);
      if (!permissions.length) {
        this.permissionsApiService
          .getOperationalAuthoritiesForUser()
          .subscribe((operationalPermissions) => {
            this.permissionsService.loadPermissions(operationalPermissions);
          });
      }
    }
    this.authService.onLogin().subscribe(() =>
      this.permissionsApiService
        .getOperationalAuthoritiesForUser()
        .subscribe((operationalPermissions) => {
          this.permissionsService.loadPermissions(operationalPermissions);
        })
    );
  }
}
