import { Injectable } from "@angular/core";

import { select, Store } from "@ngrx/store";

import { NotificationsPartialState } from "./notifications.reducer";
import { notificationsQuery } from "./notifications.selectors";
import {
  AddNotificationToSelectedCategoryNotifications,
  DecrementCountOfUnseenNotifications,
  IncrementCountOfUnseenNotifications,
  IncrementCountOfUnseenSelectedCategoryNotifications,
  LoadCountOfUnseenNotifications,
  LoadNotifications,
  LoadNotificationsCategory,
  LoadSelectedCategoryNotifications,
  LoadUnseenNotifications,
  ReadSelectedNotification,
  SeenAllCategoryNotifications,
  SeenSelectedCategoryNotifications,
} from "./notifications.actions";

@Injectable()
export class NotificationsFacade {
  loaded$ = this.store.pipe(select(notificationsQuery.getLoaded));
  allNotifications$ = this.store.pipe(
    select(notificationsQuery.getAllNotifications)
  );
  selectedNotifications$ = this.store.pipe(
    select(notificationsQuery.getSelectedNotifications)
  );
  isNotificationsCategoryLoading$ = this.store.pipe(
    select(notificationsQuery.isNotificationsCategoryLoading)
  );
  notificationsCategoryLoaded$ = this.store.pipe(
    select(notificationsQuery.notificationsCategoryLoaded)
  );
  getNotificationsCategory$ = this.store.pipe(
    select(notificationsQuery.getNotificationsCategory)
  );

  isSelectedCategoryNotificationsLoading$ = this.store.pipe(
    select(notificationsQuery.isSelectedCategoryNotificationsLoading)
  );
  selectedCategoryNotificationsLoaded$ = this.store.pipe(
    select(notificationsQuery.selectedCategoryNotificationsLoaded)
  );
  getCategoryNotifications$ = this.store.pipe(
    select(notificationsQuery.getCategoryNotifications)
  );
  countOfUnseenNotifications$ = this.store.pipe(
    select(notificationsQuery.countOfUnseenNotifications)
  );
  readSelectedNotificationSucceeded$ = this.store.pipe(
    select(notificationsQuery.readSelectedNotificationSucceeded)
  );

  constructor(private store: Store<NotificationsPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadNotifications());
  }

  loadCountOfUnseenNotifications(payload) {
    this.store.dispatch(new LoadCountOfUnseenNotifications(payload));
  }

  incrementCountOfUnseenNotifications(payload: {
    numberOfNewNotifications: number;
  }) {
    this.store.dispatch(new IncrementCountOfUnseenNotifications(payload));
  }

  decrementCountOfUnseenNotifications(payload: {
    numberOfNewNotifications: number;
  }) {
    this.store.dispatch(new DecrementCountOfUnseenNotifications(payload));
  }

  loadUnseenNotifications(payload) {
    this.store.dispatch(new LoadUnseenNotifications(payload));
  }

  loadNotificationsCategory(payload) {
    this.store.dispatch(new LoadNotificationsCategory(payload));
  }

  loadSelectedCategoryNotifications(payload) {
    this.store.dispatch(new LoadSelectedCategoryNotifications(payload));
  }

  seenSelectedCategoryNotifications(payload: { category: string }) {
    this.store.dispatch(new SeenSelectedCategoryNotifications(payload));
  }

  seenAllCategoryNotifications(payload) {
    this.store.dispatch(new SeenAllCategoryNotifications(payload));
  }

  incrementCountOfUnseenSelectedCategoryNotifications(payload: {
    category: string;
    numberOfNotifications: number;
  }) {
    this.store.dispatch(
      new IncrementCountOfUnseenSelectedCategoryNotifications(payload)
    );
  }

  addNotificationToSelectedCategoryNotifications(payload: {
    category: string;
    data;
  }) {
    this.store.dispatch(
      new AddNotificationToSelectedCategoryNotifications(payload)
    );
  }

  readSelectedNotification(payload: {
    notificationCategory: string;
    notificationUid: string;
    isOpen: boolean;
  }) {
    this.store.dispatch(new ReadSelectedNotification(payload));
  }
}
