import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ExportMultiNodeModel } from "@@intelease/web/intelease/models/export-multi-node.model";

@Injectable({
  providedIn: "root",
})
export class FileApiService {
  private static readonly EXPORT_PART = "/export";

  constructor(private httpClient: HttpClient) {}

  /**
   * Send a GET request to retrieve a file for download for user, representing one entity
   *
   * @param apiVersion the version of the API
   * @param url the url designating the type of object to fetch
   * @param uid the uid of the entity to be exported
   * @param dataFormat the format of the data to download
   * @param filename the filename to retrieve
   * @param includeNotes whether to include notes in the export
   * @param includeDocumentChronology
   * @param includeSourceAttribution
   * @param includeAnnotatedDocs whether to export a zip file, also containing the annotated docs
   * @param excludeEmptyProvisions
   * @param includeSectionHeaders
   * @param emptyProvisionText the text to show for empty provisions
   */
  sendExportOneRequest(
    apiVersion: string,
    url: string,
    uid: string,
    dataFormat: string,
    filename: string,
    includeNotes?: boolean,
    includeDocumentChronology?: boolean,
    includeSourceAttribution?: boolean,
    includeAnnotatedDocs?: boolean,
    excludeEmptyProvisions?: boolean,
    includeSectionHeaders?: boolean,
    emptyProvisionText?: string
  ): void {
    const queryString = this.createQueryString(
      includeNotes,
      includeDocumentChronology,
      includeSourceAttribution,
      includeAnnotatedDocs,
      excludeEmptyProvisions,
      includeSectionHeaders,
      emptyProvisionText
    );
    this.httpClient
      .get(
        `${apiVersion}${FileApiService.EXPORT_PART}${dataFormat}${url}/${uid}` +
          queryString,
        {
          responseType: "blob",
        }
      )
      .subscribe((res) => this.downloadFromBrowser(filename, res));
  }

  /**
   * Send a GET request to retrieve a file for download for user, representing one entity
   *
   * @param apiVersion the version of the API
   * @param url the url designating the type of object to fetch
   * @param uid the uid of the entity to be exported
   * @param dataFormat the format of the data to download
   * @param filename the filename to retrieve
   * @param includeNotes whether to include notes in the export
   * @param includeDocumentChronology
   * @param includeSourceAttribution
   * @param includeAnnotatedDocs whether to include annotated docs in the zip export
   * @param excludeEmptyProvisions
   * @param includeSectionHeaders
   * @param emptyProvisionText the text to show for empty provisions
   */
  sendExportOneRequestNew(
    apiVersion: string,
    url: string,
    uid: string,
    dataFormat: string,
    filename: string,
    includeNotes?: boolean,
    includeDocumentChronology?: boolean,
    includeSourceAttribution?: boolean,
    includeAnnotatedDocs?: boolean,
    excludeEmptyProvisions?: boolean,
    includeSectionHeaders?: boolean,
    emptyProvisionText?: string
  ): void {
    const queryString = this.createQueryString(
      includeNotes,
      includeDocumentChronology,
      includeSourceAttribution,
      includeAnnotatedDocs,
      excludeEmptyProvisions,
      includeSectionHeaders,
      emptyProvisionText
    );
    let headers = new HttpHeaders();
    headers = headers.set("Accept", dataFormat).set("Content-Type", dataFormat);
    let fullUrl;
    if (uid?.length) {
      fullUrl = `${apiVersion}${url}/${uid}`;
    } else {
      fullUrl = `${apiVersion}${url}`;
    }
    this.httpClient
      .get(fullUrl + queryString, {
        headers: headers,
        responseType: "blob",
      })
      .subscribe((res) => this.downloadFromBrowser(filename, res));
  }

  export(exportData: ExportMultiNodeModel): void {
    this.httpClient
      .post(
        "/v1/files/export",
        {
          data: exportData,
        },
        {
          responseType: "blob",
          observe: "response",
        }
      )
      .subscribe((res) => this.downloadFromBrowserWithNameFromServer(res));
  }

  /**
   * Send a GET request to open the file in a new browser tab
   *
   * @param apiVersion the version of the API
   * @param abstractUid the uid of the abstract being accessed
   * @param docAbstractUid the uid of the document to be opened
   * @param filename the user-facing filename for this
   */
  sendDownloadOriginalFile(
    apiVersion: string,
    abstractUid: string,
    docAbstractUid: string,
    filename: string
  ): void {
    this.httpClient
      .get(
        `${apiVersion}/viewDocuments/${docAbstractUid}/abstracts/${abstractUid}`,
        { responseType: "blob" }
      )
      .subscribe((res) => this.downloadFromBrowser(filename, res));
  }

  /*
   * Below are private helper methods
   */

  private createQueryString(
    includeNotes: boolean,
    includeDocumentChronology: boolean,
    includeSourceAttribution: boolean,
    includeAnnotatedDocs: boolean,
    excludeEmptyProvisions: boolean,
    includeSectionHeaders: boolean,
    emptyProvisionText: string
  ): string {
    const queryStringParts = [];
    if (includeNotes) {
      queryStringParts.push("notes=true");
    }
    if (includeDocumentChronology) {
      queryStringParts.push("includeDocumentChronology=true");
    }
    if (includeSourceAttribution) {
      queryStringParts.push("includeSourceAttribution=true");
    }
    if (includeAnnotatedDocs) {
      queryStringParts.push("annotatedDocs=true");
    }
    if (excludeEmptyProvisions) {
      queryStringParts.push("excludeEmptyProvisions=true");
    }
    if (includeSectionHeaders) {
      queryStringParts.push("includeSectionHeaders=true");
    }
    if (emptyProvisionText) {
      queryStringParts.push("emptyProvisionText=" + emptyProvisionText);
    }
    if (queryStringParts.length > 0) {
      return "?" + queryStringParts.join("&");
    }
    return "";
  }

  /**
   * Note: This does not let us give name to the file opened in the new tab. Apparently, it's impossible.
   * https://stackoverflow.com/a/41947861/1145925
   * @param res
   */
  private openInNewTab(res: Blob): void {
    const link = document.createElement("a");
    link.target = "_blank";
    this.commonHandleBlob(res, link);
  }

  /**
   * Save the fetched Blob file by using the file name and extension from the server through the content-disposition header
   *
   * @param res HttpResponse that contains Blob file and relevant headers
   */
  public downloadFromBrowserWithNameFromServer(res: HttpResponse<Blob>) {
    const contentDisposition = res.headers.get("content-disposition");
    if (
      contentDisposition &&
      contentDisposition.includes(";") &&
      contentDisposition.includes("filename=")
    ) {
      const fileNameEncodedAndExt = contentDisposition
        .split(";")[1]
        .split("filename=")[1]
        .replaceAll('"', "");
      const firstDotIndex = fileNameEncodedAndExt.indexOf(".");
      const extension = fileNameEncodedAndExt.substring(firstDotIndex);
      const baseFileNameEncoded = fileNameEncodedAndExt.substring(
        0,
        firstDotIndex
      );
      const fileName =
        decodeURIComponent(atob(baseFileNameEncoded)) + extension;
      this.downloadFromBrowser(fileName, res.body);
    } else {
      console.error(
        "Something is not right in the content-disposition header: " +
          contentDisposition
      );
    }
  }

  public downloadFromBrowser(filename: string, res: Blob): void {
    const link = document.createElement("a");
    link.download = filename;
    this.commonHandleBlob(res, link);
  }

  private commonHandleBlob(res: Blob, link: HTMLAnchorElement): void {
    link.href = window.URL.createObjectURL(res);
    link.click();
    link.remove();
  }
}
