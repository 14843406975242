export function getNestedValue(data, _key) {
  const keys = ("data." + _key).split(".");
  let nested = keys[0];
  for (let i = 1; i < keys.length; i++) {
    // tslint:disable-next-line:no-eval
    if (eval(nested) == null) {
      nested = null;
      break;
    }
    nested += "." + keys[i];
  }
  if (nested) {
    // tslint:disable-next-line:no-eval
    return eval("data." + _key);
  }
  return data[_key];
}
