/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProperNounValueModel")
export class ProperNounValueModel {
  @JsonProperty("type", Any, true)
  type?:
    | "ENTITY"
    | "PLACE"
    | "HOLIDAY"
    | "TITLE_OF_WORK"
    | "ID"
    | "UNKNOWN"
    | "CONSUMER_GOOD"
    | "PERSON"
    | "ORGANIZATION"
    | "LOCATION"
    | "WORK_OF_ART"
    | "EVENT"
    | "OTHER"
    | "KEYWORD"
    | "DOCUMENT_NAME" = undefined;

  @JsonProperty("value", String, true)
  value?: string = "";
}
