<div>
  <div mat-dialog-title class="clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModalClick()"
      aria-hidden="true"
      dataCy="closeAddQueueModal"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5 class="mt-0">Create Queue</h5>
  </div>

  <div mat-dialog-content>
    <div class="form-group" itlsPgFormGroupDefault>
      <label> Queue Name </label>
      <input
        itlsFocusIn
        [(ngModel)]="name"
        (keyup)="checkValidateFields()"
        type="text"
        class="form-control"
        dataCy="addProvisionFormName"
      />
    </div>
    <div class="form-group" itlsPgFormGroupDefault>
      <label>Queue Description</label>
      <input
        [(ngModel)]="description"
        dataCy="addProvisionFormDescription"
        type="text"
        class="form-control"
      />
    </div>

    <div class="form-group" itlsPgFormGroupDefault>
      <label> Queue Permission </label>
      <mat-select
        (selectionChange)="onQueuePermissionChange($event)"
        [value]="queuePermission"
        class="form-control"
      >
        <ng-container>
          <mat-option
            *ngFor="let permissionOption of queuePermissionOptions"
            [value]="permissionOption.value"
            >{{ permissionOption.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </div>

    <div class="form-group" itlsPgFormGroupDefault>
      <label>Provision Form</label>
      <il-choose-provision-form
        (selectedProvisionFormUidEvent)="onProvisionFormSelection($event)"
        [height]="'sm'"
        [autofocus]="false"
      ></il-choose-provision-form>
    </div>
  </div>

  <div mat-dialog-actions>
    <div>
      <button
        type="button"
        dataCy="submitAddProvisionFormModal"
        class="btn btn-primary btn-cons pull-left inline"
        (click)="onSaveClick()"
        [disabled]="
          CreateButtonPipeTypesEnum.CreateButtonIsDisabled
            | createButtonPipe: onlyMyQueues:name:provisionFormUid
        "
      >
        <span
          [matTooltip]="
            CreateButtonPipeTypesEnum.CreateButtonTooltip
              | createButtonPipe: onlyMyQueues:name:provisionFormUid
          "
          class="create-queue-button-container"
        >
          Create
        </span>
      </button>
    </div>
  </div>
</div>
