<div>
  <div mat-dialog-title class="clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModalClick()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5 class="mt-0" *ngIf="mode === 'ADD'">Create New Task</h5>
    <h5 class="mt-0" *ngIf="mode === 'EDIT'">Update Task</h5>
    <h5 class="mt-0" *ngIf="mode === 'VIEW'">Task Details</h5>
  </div>
  <div class="loading" *ngIf="!formSchema">
    <mat-progress-spinner
      diameter="50"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <div mat-dialog-content *ngIf="formSchema">
    <!--        <div class="loading-container" *ngIf="isLoadingEnabled">-->
    <!--            <itls-pg-progress class="progress" [type]="'circle'" [thick]="true" [indeterminate]="true">-->
    <!--            </itls-pg-progress>-->
    <!--        </div>-->
    <div [style.opacity]="isLoadingEnabled ? 0.5 : 1" style="height: 100%">
      <sf-form
        [schema]="formSchema"
        [model]="taskModel"
        [updateOn]="'blur'"
        (change)="onModelChange($event)"
      >
      </sf-form>
    </div>
  </div>
  <div mat-dialog-actions *ngIf="mode !== 'VIEW' || payload.editable">
    <ng-container *ngIf="mode === 'ADD'">
      <button
        type="button"
        class="btn btn-primary btn-cons pull-left inline"
        [promiseBtn]="isLoadingEnabled"
        (click)="onSaveClick()"
      >
        Create
      </button>
    </ng-container>
    <ng-container *ngIf="mode === 'VIEW'">
      <button
        type="button"
        class="btn btn-primary btn-cons pull-left inline"
        (click)="onEditClick()"
      >
        Edit
      </button>
    </ng-container>
    <ng-container *ngIf="mode === 'EDIT'">
      <button
        type="button"
        class="btn btn-primary btn-cons pull-left inline"
        [promiseBtn]="isLoadingEnabled"
        (click)="onUpdateClick()"
      >
        Save
      </button>
      <button
        type="button"
        class="btn btn-default btn-cons pull-left inline"
        (click)="onCancelClick()"
      >
        Cancel
      </button>
    </ng-container>
  </div>
</div>
