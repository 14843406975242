const SPECIAL_CHARACTERS = ["-", "."];

export class StringHelper {
  static removeNoneDigitChars(str: string): string {
    return str
      .split("")
      .filter(
        (character) =>
          !isNaN(character as unknown as number) ||
          SPECIAL_CHARACTERS.includes(character)
      )
      .join("");
  }
}
