import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import {
  DocumentReorderingEvent,
  UploadedDocSetModel,
} from "@@intelease/web/ui/src/lib/itls-new-upload";
import {
  DocumentSetService,
  UploadDocSetDataReqDtoModel,
  UploadDocSetGroupDataResDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { UploadDocSetModel } from "../models/upload-doc-set.model";
import { UploadDocSetResponseModel } from "../models/upload-doc-set-response.model";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";

@Injectable({
  providedIn: "root",
})
export class UploadDocSetService {
  constructor(private documentSetService: DocumentSetService) {}

  public static getRelativePath(file: File): string {
    return file["webkitRelativePath"];
  }

  uploadDocSetData(
    uploadDocSetModel: UploadDocSetModel
  ): Observable<UploadDocSetGroupDataResDtoModel> {
    const uploadingFolder = uploadDocSetModel.isUploadingFolder;

    const documentSets: UploadDocSetDataReqDtoModel[] =
      uploadDocSetModel.uploadedDocSets.map((docSet) => ({
        directoryPath: uploadingFolder ? docSet.folderPath : null,
        fileInfos: docSet.myFiles.map(({ file, name }) => ({
          name: name + this.getExtension(file),
          size: file.size,
        })),
        name: docSet.name,
        uploadDocEvents: {
          actions: this.updateUploadDocEvents(docSet.documentReorderingEvents),
        },
      }));

    return this.documentSetService
      .uploadData({
        body: {
          data: {
            directoryUid: uploadDocSetModel.directoryUid,
            uploadingFolder,
            documentSets,
          },
        },
      })
      .pipe(
        map((resp) => {
          return Json2TypescriptHelper.convertToEntity(
            resp.data,
            UploadDocSetGroupDataResDtoModel
          );
        })
      );
  }

  private updateUploadDocEvents(
    documentReorderingEvents: DocumentReorderingEvent[]
  ): any[] {
    const lastReorderingEvent =
      documentReorderingEvents[documentReorderingEvents.length - 1];
    const filenameToName = {};
    for (const myFile of lastReorderingEvent.post) {
      filenameToName[myFile.file.name] = myFile.name;
    }
    return documentReorderingEvents.map((documentReorderingEvent) => {
      return {
        pre: documentReorderingEvent.pre.map(
          (myFile) => filenameToName[myFile.file.name]
        ),
        post: documentReorderingEvent.post.map(
          (myFile) => filenameToName[myFile.file.name]
        ),
      };
    });
  }

  private getExtension(file: File) {
    const lastDotIdx = file.name.lastIndexOf(".");
    let extension = "";
    if (lastDotIdx !== -1) {
      extension = file.name.substr(lastDotIdx);
    }
    return extension;
  }
}
