/* tslint:disable */
/* eslint-disable */
import { PdfMentionTextHighlightDtoModel } from "./pdf-mention-text-highlight-dto-model";
import { ViewPdfProvisionModel } from "./view-pdf-provision-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RealtimeTextHighlightProvisionParseOutputModel")
export class RealtimeTextHighlightProvisionParseOutputModel {
  @JsonProperty("hasRealValue", Boolean, true)
  hasRealValue?: boolean = undefined;

  @JsonProperty("maybeExistingOption", Number, true)
  maybeExistingOption?: number = undefined;

  @JsonProperty("newProvision", ViewPdfProvisionModel, true)
  newProvision?: ViewPdfProvisionModel = undefined;

  @JsonProperty("pdfMentionHighlights", [PdfMentionTextHighlightDtoModel], true)
  pdfMentionHighlights?: Array<PdfMentionTextHighlightDtoModel> = [];

  @JsonProperty("textValue", String, true)
  textValue?: string = "";
}
