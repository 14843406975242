/* tslint:disable */
/* eslint-disable */
import { NestedGroupProvisionSubfieldModel } from "./nested-group-provision-subfield-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateProvisionV2ApiDtoModel")
export class CreateProvisionV2ApiDtoModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("docSetCategory", String, true)
  docSetCategory?: string = "";

  @JsonProperty("entityScope", Any, true)
  entityScope?: "GLOBAL" | "ACCOUNT" | "USER" | "ABSTRACT" = undefined;

  /**
   * the type of datapoint this field is
   */

  @JsonProperty("fieldType", Any, true)
  fieldType?: "DEFAULT" | "MANUAL" = undefined;

  /**
   * the format of a structured value
   */

  @JsonProperty("format", Any, true)
  format?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;

  @JsonProperty("modelKey", String, true)
  modelKey?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("nestedGroupFormats", Any, true)
  nestedGroupFormats?: Array<"ADDRESS" | "OTHER"> = [];

  @JsonProperty(
    "nestedGroupSubfields",
    [NestedGroupProvisionSubfieldModel],
    true
  )
  nestedGroupSubfields?: Array<NestedGroupProvisionSubfieldModel> = [];

  @JsonProperty("possibleUnits", Any, true)
  possibleUnits?: Array<
    | "SQUARE_FEET"
    | "SQUARE_METERS"
    | "PING"
    | "TSUBO"
    | "ACRES"
    | "HECTARES"
    | "SQUARE_KILOMETERS"
    | "SQUARE_MILES"
    | "CENTIMETERS"
    | "INCHES"
    | "FEET"
    | "YARDS"
    | "METERS"
    | "KILOMETERS"
    | "MILES"
    | "PERCENT"
    | "MONEY"
    | "SECONDS"
    | "MINUTES"
    | "HOURS"
    | "DAYS"
    | "WEEKS"
    | "MONTHS"
    | "YEARS"
    | "METERS_PER_SECOND"
    | "METERS_PER_SECOND_PER_SECOND"
    | "GRAMS"
    | "CUBIC_CENTIMETERS"
    | "GRAMS_PER_CUBIC_CENTIMETER"
    | "VOLTS"
    | "AMPERES"
    | "PASCALS"
    | "INCHES_WATER"
    | "POUNDS_PER_SQUARE_INCH"
    | "ATMOSPHERES"
    | "KELVIN"
    | "CELSIUS"
    | "FAHRENHEIT"
    | "NONE"
  > = [];

  @JsonProperty("possibleValues", [String], true)
  possibleValues?: Array<string> = [];

  @JsonProperty("properNounType", Any, true)
  properNounType?:
    | "ENTITY"
    | "PLACE"
    | "HOLIDAY"
    | "TITLE_OF_WORK"
    | "ID"
    | "UNKNOWN"
    | "CONSUMER_GOOD"
    | "PERSON"
    | "ORGANIZATION"
    | "LOCATION"
    | "WORK_OF_ART"
    | "EVENT"
    | "OTHER"
    | "KEYWORD"
    | "DOCUMENT_NAME" = undefined;

  @JsonProperty("provisionProcessingMode", Any, true)
  provisionProcessingMode?: "FLEXIBLE" | "STRICT" = undefined;

  @JsonProperty("recordUid", String, true)
  recordUid?: string = "";

  @JsonProperty("singleton", Boolean, true)
  singleton?: boolean = undefined;
}
