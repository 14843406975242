import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("DocumentModel2")
export class DocumentModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("date", String)
  date = "";
}
