import { Component, ViewEncapsulation } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { SidebarService } from "@@intelease/web/ui";
import {
  FeaturesToggleService,
  MainDrawerService,
  UserInfoService,
} from "@@intelease/web/intelease/services";
import { AuthService } from "@@intelease/app-services/auth";
import { NotificationsFacade } from "@@intelease/app-state/notifications";
import { LocalStorageKey } from "../../../common/enums/local-storage.keys";

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  // selectedLanguage: any
  userStatusOptions: any[];
  leftMenu$: Observable<boolean> = this.featuresToggleService.leftMenu$;

  options: any[] = [];

  userImage = "assets/images/user.png";
  userName = UserInfoService.getUserFullName();
  email = UserInfoService.getUserEmail();

  constructor(
    private _itlsSidebarService: SidebarService,
    private router: Router,
    public mainDrawerService: MainDrawerService,
    private authService: AuthService,
    public notificationsFacade: NotificationsFacade,
    private readonly featuresToggleService: FeaturesToggleService
  ) {
    this.languages = [
      {
        id: "en",
        title: "English",
        flag: "us",
      },
      {
        id: "es",
        title: "Spanish",
        flag: "es",
      },
      {
        id: "de",
        title: "German",
        flag: "de",
      },
      {
        id: "it",
        title: "Italian",
        flag: "it",
      },
    ];

    this.navigation = [];
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  toggleSidebarOpen(): void {
    this.mainDrawerService.openSidebar();
  }

  search(value): void {
    // Do your search here...
  }

  onLogoutClick() {
    this.router.navigateByUrl("/login").then(() => {
      //
    });
  }

  onNavigateToClick(key: string) {
    if (key === "logout") {
      this.authService.logout();
    } else {
      const userUid = localStorage.getItem(LocalStorageKey.USER_UID);
      this.router.navigate([`/user-profile/${userUid}/${key}/`], {
        queryParams: {
          tab: key,
        },
      });
    }
  }
}
