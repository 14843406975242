/* tslint:disable */
/* eslint-disable */
import { EditCalendarDtoModel } from "./edit-calendar-dto-model";
import { OApiReturnParamsRequestCalendarViewTypeModel } from "./o-api-return-params-request-calendar-view-type-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqEditCalendarDtoModel")
export class OApiReqEditCalendarDtoModel {
  @JsonProperty("data", EditCalendarDtoModel, true)
  data?: EditCalendarDtoModel = undefined;

  @JsonProperty("replace", Boolean, true)
  replace?: boolean = undefined;

  @JsonProperty(
    "returnParams",
    OApiReturnParamsRequestCalendarViewTypeModel,
    true
  )
  returnParams?: OApiReturnParamsRequestCalendarViewTypeModel = undefined;
}
