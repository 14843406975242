import { NgModule } from "@angular/core";
import { EnumsPipe } from "@@intelease/web/intelease/pipes/enums/enums.pipe";

const PIPES = [EnumsPipe];

@NgModule({
  declarations: [...PIPES],
  exports: [...PIPES],
})
export class InteleasePipesModule {}
