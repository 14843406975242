import { JsonObject, JsonProperty } from "json2typescript";
import { RelatedDocModel } from "@@intelease/web/common/models/doc-abstract/abstract-part/related-doc.model";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { DocSetCategoryModel } from "@common/models/doc-abstract/doc-set-category.model";
import { QueueInfoDtoModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("RelatedDocsAbstractModel")
export class RelatedDocsAbstractModel {
  static view = "relatedDocs";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("abstractFilename", String)
  abstractFilename = "";

  @JsonProperty("abstractName", String)
  abstractName = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("docSetCategory", DocSetCategoryModel)
  docSetCategory: DocSetCategoryModel = undefined;

  @JsonProperty("documents", [RelatedDocModel])
  documents: RelatedDocModel[] = [];

  @JsonProperty("defaultDoc", RelatedDocModel)
  defaultDoc: RelatedDocModel = undefined;

  @JsonProperty("queueInfo", QueueInfoDtoModel, true)
  queueInfo?: QueueInfoDtoModel;

  @JsonProperty("docSetUid", String)
  docSetUid = "";

  constructor(uid?: string, abstractName?: string) {
    this.uid = uid;
    this.abstractName = abstractName;
  }
}
