import { Component, ViewChild } from "@angular/core";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { InteleaseNotificationService } from "@@intelease/web/intelease/services";
import { Observable, of, Subject } from "rxjs";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { CommonModalService } from "@@intelease/web/common/services";
import { finalize } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import {
  ChooseFormComponent,
  FilesUploadComponent,
  FilteredProvisionForm,
  UploadedDocSetModel,
  UploadLeaseResponseModel,
} from "@@intelease/web/ui/src/lib/itls-new-upload";
import { NewUploadService } from "@@intelease/web/ui/src/lib/itls-new-upload/services/new-upload.service";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive.service";
import { UploadingBoxService } from "@@intelease/web/ui/src/lib/itls-new-upload/services/uploading-box.service";
import { forEach, keyBy } from "lodash";
import { DriveFacade } from "@@intelease/app-state/drive/src";

const ALLOWED_FILE_EXTENSIONS = [
  ".pdf",
  ".docx",
  ".doc",
  ".odt",
  ".ppt",
  ".pptx",
  ".odp",
  ".png",
  ".jpeg",
  ".jpg",
  ".svg",
  ".bmp",
  ".tif",
  ".tiff",
];

const INVALID_FILE_NAMES_CHARACTERS = ['"', "/", "%"];

const MAX_DOCS_ALLOWED = 5;

type PanelView = "UPLOAD" | "PICK_REVIEW_FORM" | "SELECT_DEST_FOLDER";

@Component({
  selector: "intelease-new-upload-modal",
  templateUrl: "./itls-new-upload-modal.component.html",
  styleUrls: ["./itls-new-upload-modal.component.scss"],
})
export class ItlsNewUploadModalComponent {
  selectedProvisionForm: FilteredProvisionForm;
  isUploading = false;
  @ViewChild("uploadPanel") uploadPanel: MatExpansionPanel;
  @ViewChild("selectFormCategoryPanel", { static: false })
  selectFormCategoryPanel: MatExpansionPanel;
  @ViewChild("pickReviewFormPanel") pickReviewFormPanel: MatExpansionPanel;
  @ViewChild("selectDestFolderPanel", { static: false })
  selectDestFolderPanel: MatExpansionPanel;
  @ViewChild("filesUploadComponent")
  filesUploadComponent: FilesUploadComponent;
  @ViewChild("chooseFormComponent", { static: false })
  chooseFormComponent: ChooseFormComponent;
  selectedView: PanelView = "UPLOAD";
  selectedFolderUid: string;
  isUploadingFolder: boolean;
  uploadedDocSets: UploadedDocSetModel[];
  disallowedFiles: string[] = [];

  constructor(
    private inputValidationService: InputValidationService,
    private inteleaseNotificationService: InteleaseNotificationService,
    private commonModalService: CommonModalService,
    private newUploadService: NewUploadService,
    private dialogRef: MatDialogRef<any>,
    private uploadingBoxService: UploadingBoxService,
    private driveFacade: DriveFacade
  ) {}

  onModelChanged(uploadedDocSetModels: UploadedDocSetModel[]) {
    const filteredUploadedDocSetModels = [];
    for (const uploadedDocSet of uploadedDocSetModels) {
      uploadedDocSet.myFiles = uploadedDocSet.myFiles.filter((myFile) => {
        const filename = myFile.file.name;
        const isValid = this.inputValidationService.validTextSuffixesIgnoreCase(
          filename,
          ALLOWED_FILE_EXTENSIONS
        );
        if (!isValid) {
          this.disallowedFiles.push(filename);
        }
        return isValid;
      });
      if (uploadedDocSet.myFiles.length > 0) {
        filteredUploadedDocSetModels.push(uploadedDocSet);
      }
    }
    this.uploadedDocSets = filteredUploadedDocSetModels;
  }

  onProvisionFormSelection(provisionForm: FilteredProvisionForm) {
    this.selectedProvisionForm = provisionForm;
  }

  onUpload() {
    if (!this.areFilesValid()) {
      return;
    }
    this.confirmWithUserCreditsUsage().subscribe((confirmed) => {
      if (confirmed) {
        this.uploadLease();
      }
    });
  }

  private confirmWithUserCreditsUsage(): Observable<boolean> {
    const numCredits = this.uploadedDocSets
      .map((item) => Math.ceil(item.myFiles.length / MAX_DOCS_ALLOWED))
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    if (numCredits === 1) {
      return of(true);
    }
    const numFiles = this.uploadedDocSets
      .map((item) => item.myFiles.length)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const modalData = new ModalInputModel();
    if (this.isUploadingFolder) {
      modalData.payload = {
        customMessage: true,
        params: {
          numCredits,
          numRecords: this.uploadedDocSets.length,
          numFiles,
        },
        message: `You are uploading ${this.uploadedDocSets.length} records (${numFiles} documents), using ${numCredits} credits. Are you sure you wish to upload?`,
        title: "Confirm Upload",
      };
    } else {
      modalData.payload = {
        customMessage: true,
        params: {
          numCredits: numCredits,
          numFiles: numFiles,
        },
        message: `You are uploading ${numFiles} new files. This will use ${numCredits} of your account credits. Are you sure you wish to upload?`,
        title: "Confirm Upload",
      };
    }
    const userConfirmedCreditsUsage = new Subject<boolean>();
    this.commonModalService
      .openGenericOkCancelModal(modalData)
      .afterClosed()
      .subscribe((res) => {
        userConfirmedCreditsUsage.next(
          res.data.exitType === ModalsResponseTypeEnum.CLOSE
        );
      });
    return userConfirmedCreditsUsage;
  }

  private areFilesValid(): boolean {
    for (const uploadedDocSet of this.uploadedDocSets) {
      const originalFileNames = uploadedDocSet.myFiles.map(
        (myFile) => myFile.file.name
      );
      const isValid = this.inputValidationService.validTextsSuffixesIgnoreCase(
        originalFileNames,
        ALLOWED_FILE_EXTENSIONS
      );
      if (!isValid) {
        this.inteleaseNotificationService.openSnackBar(
          `One or more files are not supported! (allowed file extensions: '` +
            ALLOWED_FILE_EXTENSIONS +
            `')`
        );
        return false;
      }
      const fileNames = uploadedDocSet.myFiles.map((myFile) => myFile.name);
      for (const fileName of fileNames) {
        if (
          !INVALID_FILE_NAMES_CHARACTERS.every(
            (invalidFileNameChar) => !fileName.includes(invalidFileNameChar)
          )
        ) {
          this.inteleaseNotificationService.openSnackBar(
            "Found a file with invalid name: " +
              fileName +
              " (these characters are not allowed: " +
              INVALID_FILE_NAMES_CHARACTERS.join(", ") +
              " )"
          );
          return false;
        }
      }
    }
    return true;
  }

  private uploadLease() {
    this.isUploading = true;
    const parentFolderUid = ItlsDriveService.getNonRootDirectoryUid(
      this.selectedFolderUid
    );
    this.newUploadService
      .uploadLease({
        provisionFormUid: this.selectedProvisionForm.uid,
        directoryUid: parentFolderUid,
        uploadedDocSets: this.uploadedDocSets,
        isUploadingFolder: this.isUploadingFolder,
      })
      .pipe(finalize(() => (this.isUploading = false)))
      .subscribe((resp) => {
        this.openUploadingBox(parentFolderUid, resp);
        this.dialogRef.close();
        const category: any =
          window.location.pathname.split("/")[2]?.toUpperCase() === "FOLDERS"
            ? "OWNED"
            : window.location.pathname.split("/")[2]?.toUpperCase();
        forEach(resp.items, (item) => {
          forEach(this.uploadedDocSets, (uploadedDocSet) => {
            if (item.directoryPath) {
              this.driveFacade.addDirectory({
                category,
                directoryUid: parentFolderUid,
                data: {
                  uid: resp.selectedDirectoryUid,
                  name: uploadedDocSet.name,
                },
              });
            } else {
              this.driveFacade.addRecord({
                category,
                recordUid: item.recordUid,
                directoryUid: parentFolderUid,
                data: {
                  name: uploadedDocSet.name,
                },
              });
            }
          });
        });
      });
  }

  onPanelClosed(closedView: PanelView) {
    if (closedView === this.selectedView) {
      switch (this.selectedView) {
        case "UPLOAD":
          this.changeCurrentView("PICK_REVIEW_FORM");
          break;
        case "PICK_REVIEW_FORM":
          this.changeCurrentView("UPLOAD");
          break;
        case "SELECT_DEST_FOLDER":
          this.changeCurrentView("PICK_REVIEW_FORM");
          break;
      }
    }
  }

  onPanelOpened(openedView: PanelView) {
    this.selectedView = openedView;
  }

  onBackClicked() {
    switch (this.selectedView) {
      case "PICK_REVIEW_FORM":
        this.changeCurrentView("UPLOAD");
        break;
      case "SELECT_DEST_FOLDER":
        this.changeCurrentView("PICK_REVIEW_FORM");
        break;
    }
  }

  onNextClicked() {
    switch (this.selectedView) {
      case "UPLOAD":
        this.changeCurrentView("PICK_REVIEW_FORM");
        break;
      case "PICK_REVIEW_FORM":
        this.changeCurrentView("SELECT_DEST_FOLDER");
        break;
    }
  }

  private changeCurrentView(newView: PanelView) {
    this.selectedView = newView;
    switch (this.selectedView) {
      case "UPLOAD":
        this.uploadPanel.expanded = true;
        break;
      case "PICK_REVIEW_FORM":
        this.pickReviewFormPanel.expanded = true;
        break;
      case "SELECT_DEST_FOLDER":
        this.selectDestFolderPanel.expanded = true;
        break;
    }
  }

  private openUploadingBox(
    parentFolderUid: string,
    resp: UploadLeaseResponseModel
  ) {
    const items = resp.items;
    if (this.isUploadingFolder) {
      const directoryPathToItem = keyBy(items, (item) => item.directoryPath);
      // we don't store explicit name for root selected folder to upload (selected from file browser)
      //  but instead we can use the first part of the 'filesPath'
      const rootSelectedFolderName =
        this.uploadedDocSets[0].folderPath.split("/")[0];
      const records = this.uploadedDocSets.map((uploadedDocSet) => ({
        uid: directoryPathToItem[uploadedDocSet.folderPath].recordUid,
        name: uploadedDocSet.name,
        files: uploadedDocSet.myFiles,
      }));
      this.uploadingBoxService.uploadFolder(
        parentFolderUid,
        resp.selectedDirectoryUid,
        rootSelectedFolderName,
        records
      );
    } else {
      const item = items[0];
      const uploadedDocSet = this.uploadedDocSets[0];
      this.uploadingBoxService.uploadRecord(parentFolderUid, {
        uid: item.recordUid,
        name: uploadedDocSet.name,
        files: uploadedDocSet.myFiles,
      });
    }
  }
}
