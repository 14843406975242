import { FormControl, FormGroup, Validators } from "@angular/forms";

export const CALENDAR_FILTER_FORM = {
  create: () => {
    return new FormGroup({
      name: new FormControl("", [Validators.required]),
      description: new FormControl(""),
    });
  },
};
