import { Any, JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ServerResponseModel")
export class ServerResponseModel {
  @JsonProperty("message", String)
  message = "";

  @JsonProperty("data", Any)
  data: any = [];

  @JsonProperty("errors", Any)
  errors: any = [];
}
