import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TaskModalComponent } from "./task-modal.component";
import { SchemaFormModule } from "@@intelease/schema-form";
import { TaskModalService } from "./task-modal.service";
import { ProgressModule } from "@@intelease/web/intelease/components";
import { Angular2PromiseButtonModule } from "angular2-promise-buttons";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

const INTELEASE_MODULES = [ProgressModule.forRoot()];
const MATERIAL_MODULES = [MatDialogModule, MatProgressSpinnerModule];

@NgModule({
  declarations: [TaskModalComponent],
  imports: [
    CommonModule,
    SchemaFormModule.forRoot(),
    ...INTELEASE_MODULES,
    Angular2PromiseButtonModule.forRoot(),
    ...MATERIAL_MODULES,
  ],
  providers: [TaskModalService],
  exports: [TaskModalComponent],
})
export class TaskModalModule {}
