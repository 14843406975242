import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FeaturesToggleService,
  MainDrawerService,
} from "@@intelease/web/intelease/services";
import { AuthService } from "@@intelease/app-services/auth";
import { MatDrawer } from "@angular/material/sidenav";
import { Observable } from "rxjs";
import {
  LeftMenuAction,
  LeftMenuService,
} from "../left-menu/left-menu.service";
import { filter } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "itls-vertical-sidebar-layout",
  templateUrl: "./vertical-sidebar.component.html",
  styleUrls: ["./vertical-sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalSidebarComponent implements OnInit {
  @ViewChild("root", { static: true }) root;
  @ViewChild("drawer", { static: true }) drawer: MatDrawer;
  isOpened: boolean;
  leftMenu$: Observable<boolean> = this.featuresToggleService.leftMenu$;
  turnOffMenu = false;
  public isLoggedIn: boolean = this.authService.isLoggedIn();
  destroyRef = inject(DestroyRef);

  constructor(
    public mainDrawerService: MainDrawerService,
    private authService: AuthService,
    private readonly leftMenuService: LeftMenuService,
    private readonly featuresToggleService: FeaturesToggleService
  ) {}

  ngOnInit() {
    this.leftMenuService.leftMenuAction$
      .pipe(filter((action) => action === LeftMenuAction.TURN_OFF))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.turnOffMenu = true;
      });

    if (this.authService.isLoggedIn()) {
      this.mainDrawerService.enableToolbar();
      this.mainDrawerService.enableSidebar();
    }
    this.authService.onLogin().subscribe(() => {
      this.isLoggedIn = true;
      this.mainDrawerService.enableToolbar();
      this.mainDrawerService.enableSidebar();
    });

    this.mainDrawerService
      .onOpenSidebar$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.authService.isLoggedIn()) {
          this.drawer.open();
        }
      });

    this.mainDrawerService
      .onCloseSidebar$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.drawer.close();
      });

    AuthService.setLoggedIn$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isLoggedIn = this.authService.isLoggedIn();
      });
    this.isLoggedIn = this.authService.isLoggedIn();
  }
}
