import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class InteleaseNotificationService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(
    message: string,
    action: string = "Close",
    config: MatSnackBarConfig = {
      duration: 3000,
      verticalPosition: "bottom",
      horizontalPosition: "left",
    }
  ) {
    return this.snackBar.open(message, action, config);
  }
}
