/* tslint:disable */
/* eslint-disable */
import { MinimalEntityViewModel } from "./minimal-entity-view-model";
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";
import { TaskAssigneeDtoModel } from "./task-assignee-dto-model";
import { UserViewModel } from "./user-view-model";

/**
 * a full view of the task
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullTaskViewModel")
export class FullTaskViewModel {
  @JsonProperty("assignee", TaskAssigneeDtoModel, true)
  assignee?: TaskAssigneeDtoModel = undefined;

  /**
   * the {@link User#getEmail()} who created this task
   */

  @JsonProperty("createdByEmail", String, true)
  createdByEmail?: string = "";

  /**
   * the {@link User#getName()} who created this task
   */

  @JsonProperty("createdByName", String, true)
  createdByName?: string = "";

  /**
   * the user-facing {@link User#getId()} who created the task
   */

  @JsonProperty("createdByUid", String, true)
  createdByUid?: string = "";

  /**
   * the task's creation date
   */

  @JsonProperty("creationDate", DateTimeConvertService, true)
  creationDate?: Date | undefined = undefined;

  @JsonProperty("creator", UserViewModel, true)
  creator?: UserViewModel = undefined;

  /**
   * the task's description
   */

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("dueDate", SimpleOpenApiDateModel, true)
  dueDate?: SimpleOpenApiDateModel = undefined;

  /**
   * the entities associated with this task
   */

  @JsonProperty("entities", [MinimalEntityViewModel], true)
  entities?: Array<MinimalEntityViewModel> = [];

  /**
   * the {@link User#getEmail()} who last modified this task
   */

  @JsonProperty("lastModifiedByEmail", String, true)
  lastModifiedByEmail?: string = "";

  /**
   * the {@link User#getName()} who last modified this task
   */

  @JsonProperty("lastModifiedByName", String, true)
  lastModifiedByName?: string = "";

  /**
   * the date this task was last modified
   */

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  /**
   * the task's name
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the task's notes
   */

  @JsonProperty("notes", String, true)
  notes?: string = "";

  /**
   * the task's priority
   */

  @JsonProperty("priority", String, true)
  priority?: string = "";

  /**
   * the task's status
   */

  @JsonProperty("status", String, true)
  status?: string = "";

  /**
   * the task's tags
   */

  @JsonProperty("tags", [String], true)
  tags?: Array<string> = [];

  /**
   * the task's type
   */

  @JsonProperty("type", String, true)
  type?: string = "";

  /**
   * the user-facing unique {@link Task#getId()}
   */

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("updater", UserViewModel, true)
  updater?: UserViewModel = undefined;
}
