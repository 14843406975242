/* tslint:disable */
/* eslint-disable */

/**
 * the request body for modifying the existing attributions for a NESTED_GROUP provision mention
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ModifyNestedMentionAttributionsApiDtoModel")
export class ModifyNestedMentionAttributionsApiDtoModel {
  /**
   * the updated attribution ids
   */

  @JsonProperty("attributionUids", [String], true)
  attributionUids?: Array<string> = [];
}
