import { Injectable } from "@angular/core";
import { RestClient } from "@@intelease/web/utils";
import { ExportIssueRequestModel } from "@intelease/models/export-issue-request.model";
import { DeleteBatchApiService, FileApiService } from "@intelease/services";
import { HttpClient } from "@angular/common/http";
import { IssueExportModel } from "@common/models";
import {
  OApiRespMultiEntityAllPermissionsFullDtoModel,
  OApiRespMultiProjectDtoModel,
  OApiRespProjectDtoModel,
  OApiRespSharedModel,
} from "@@intelease/api-models/adex-api-model-src";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectsService {
  private static PROJECT_BASE_URL = "/project";

  constructor(
    private restClient: RestClient,
    private fileApiService: FileApiService,
    private httpClient: HttpClient,
    private deleteBatchApiService: DeleteBatchApiService
  ) {}

  saveProject(payload): Observable<OApiRespProjectDtoModel> {
    const data = {
      returnParams: {
        view: "full",
      },
      data: payload,
    };
    return this.restClient.sendPostRequest<OApiRespProjectDtoModel>(
      "/v1",
      `${ProjectsService.PROJECT_BASE_URL}`,
      data,
      OApiRespProjectDtoModel
    );
  }

  removeProjectByUid(uid: string): Observable<OApiRespProjectDtoModel> {
    return this.restClient.sendDeleteRequest<OApiRespProjectDtoModel>(
      "/v1",
      `${ProjectsService.PROJECT_BASE_URL}/${uid}`,
      OApiRespProjectDtoModel
    );
  }

  removeBatchProject(uids: string[]) {
    return this.deleteBatchApiService.sendBatchRequestNoView(
      "/v1",
      ProjectsService.PROJECT_BASE_URL,
      uids,
      false
    );
  }

  getProjects(): Observable<OApiRespMultiProjectDtoModel> {
    return this.restClient.sendGetRequest<OApiRespMultiProjectDtoModel>(
      "/v1",
      `${ProjectsService.PROJECT_BASE_URL}`,
      OApiRespMultiProjectDtoModel
    );
  }

  updateProjectByUid(
    projectUid: string,
    payload,
    replace: boolean
  ): Observable<OApiRespProjectDtoModel> {
    const _payload = {
      returnParams: {
        view: "minimal",
      },
      replace: replace,
      data: payload,
    };
    return this.restClient.sendPutRequest<OApiRespProjectDtoModel>(
      "/v1",
      `${ProjectsService.PROJECT_BASE_URL}/${projectUid}`,
      _payload,
      OApiRespProjectDtoModel
    );
  }

  getProjectByUid({
    projectUid,
    dashboardUid,
  }): Observable<OApiRespProjectDtoModel> {
    if (dashboardUid) {
      return this.restClient.sendGetRequest<OApiRespProjectDtoModel>(
        "/v1",
        `${ProjectsService.PROJECT_BASE_URL}/${projectUid}`,
        OApiRespProjectDtoModel,
        {
          params: {
            dashboardUid,
            [RestClient.HTTP_CONTEXT_PARAM_KEY]: {
              doNotNotifyNotFoundError: true,
            },
          },
        }
      );
    } else {
      return this.restClient.sendGetRequest<OApiRespProjectDtoModel>(
        "/v1",
        `${ProjectsService.PROJECT_BASE_URL}/${projectUid}`,
        OApiRespProjectDtoModel
      );
    }
  }

  shareProjectByUid(
    projectUid: string,
    users: string[]
  ): Observable<OApiRespSharedModel> {
    const payload = {
      data: {
        domains: [...users],
      },
    };
    return this.restClient.sendPostRequest<OApiRespSharedModel>(
      "/v1",
      `${ProjectsService.PROJECT_BASE_URL}/${projectUid}/share`,
      payload,
      OApiRespSharedModel
    );
  }

  getProjectUsersByUid(
    uid: string
  ): Observable<OApiRespMultiEntityAllPermissionsFullDtoModel> {
    return this.restClient.sendGetRequest<OApiRespMultiEntityAllPermissionsFullDtoModel>(
      "/v1",
      `${ProjectsService.PROJECT_BASE_URL}/${uid}/permissions`,
      OApiRespMultiEntityAllPermissionsFullDtoModel
    );
  }

  exportIssue(
    nameWithExtension: string,
    fileType,
    issueExportModel: IssueExportModel,
    includeNotes?: boolean,
    includeDocumentChronology?: boolean,
    includeSourceAttribution?: boolean,
    includeAnnotatedDocs?: boolean,
    excludeEmptyProvisions?: boolean,
    includeSectionHeaders?: boolean,
    emptyProvisionText?: string
  ) {
    const { projectUid, kanbanBoardUid, issueListUid, issueUid } =
      issueExportModel;
    return this.restClient
      .sendPostRequestNoView(
        "/v1",
        `${ProjectsService.PROJECT_BASE_URL}/${projectUid}/kanbanBoard/${kanbanBoardUid}/issueList/${issueListUid}/issue/${issueUid}/export`,
        {
          data: new ExportIssueRequestModel(
            fileType,
            includeNotes,
            includeDocumentChronology,
            false,
            includeAnnotatedDocs,
            excludeEmptyProvisions,
            includeSectionHeaders,
            emptyProvisionText
          ),
        },
        {
          responseType: "blob",
        }
      )
      .subscribe((res: any) =>
        this.fileApiService.downloadFromBrowser(nameWithExtension, res)
      );
  }
}
