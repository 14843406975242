/* tslint:disable */
/* eslint-disable */
import { EntityPermissionUpdateDtoModel } from "./entity-permission-update-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EditEntityPermissionRequestDtoModel")
export class EditEntityPermissionRequestDtoModel {
  @JsonProperty("domainType", Any, true)
  domainType?: "PUBLIC" | "ACCOUNT" | "ROLE" | "USER" = undefined;

  @JsonProperty("updates", [EntityPermissionUpdateDtoModel], true)
  updates?: Array<EntityPermissionUpdateDtoModel> = [];
}
