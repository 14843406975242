import { WeekOptions } from "./models/WeekOptions";
import { MonthOptions } from "./models/MonthOptions";
import { Template } from "./models/Template";
import { ITemplateConfig } from "tui-calendar";
import { Injectable } from "@angular/core";

export interface TuiCalendarOptions {
  defaultView?: string;
  taskView?: boolean;
  scheduleView?: boolean;
  template?: ITemplateConfig;
  month?: MonthOptions;
  week?: WeekOptions;
  disableDblClick?: boolean;
  disableClick?: boolean;
  isReadOnly?: boolean;
}

@Injectable()
export class TuiCalendarDefaults {
  defaultView = "month";
  taskView = false;
  useCreationPopup = false;
  useDetailPopup = false;
  scheduleView = true;
  disableDblClick = false;
  disableClick = true;
  isReadOnly = true;
  template = {
    popupEdit: function () {
      return "";
    },
    popupDelete: function () {
      return "";
    },
  };

  week: WeekOptions = {
    daynames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    startDayOfWeek: 1,
    narrowWeekend: false,
  };
}
