/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.common.AddressValue', null, global);
goog.exportSymbol('proto.common.CommonNounValue', null, global);
goog.exportSymbol('proto.common.DoubleValue', null, global);
goog.exportSymbol('proto.common.EmailValue', null, global);
goog.exportSymbol('proto.common.FullValMultiPdfProvisionDTO', null, global);
goog.exportSymbol('proto.common.LocalDateValue', null, global);
goog.exportSymbol('proto.common.MessageInfo', null, global);
goog.exportSymbol('proto.common.MessageInfo.ErrorCode', null, global);
goog.exportSymbol('proto.common.MessageInfo.SuccessCode', null, global);
goog.exportSymbol('proto.common.MoneyValue', null, global);
goog.exportSymbol('proto.common.MultipleCatValue', null, global);
goog.exportSymbol('proto.common.PartialValPdfProvisionDTO', null, global);
goog.exportSymbol('proto.common.PhoneValue', null, global);
goog.exportSymbol('proto.common.ProperNounValue', null, global);
goog.exportSymbol('proto.common.ProvisionType', null, global);
goog.exportSymbol('proto.common.RentScheduleCalculatorRequest', null, global);
goog.exportSymbol('proto.common.RentScheduleCalculatorResponse', null, global);
goog.exportSymbol('proto.common.SingleCatValue', null, global);
goog.exportSymbol('proto.common.TableValue', null, global);
goog.exportSymbol('proto.common.TableValue.Row', null, global);
goog.exportSymbol('proto.common.TermCalculatorRequest', null, global);
goog.exportSymbol('proto.common.TermCalculatorResponse', null, global);
goog.exportSymbol('proto.common.YearValue', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.RentScheduleCalculatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.RentScheduleCalculatorRequest.repeatedFields_, null);
};
goog.inherits(proto.common.RentScheduleCalculatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.RentScheduleCalculatorRequest.displayName = 'proto.common.RentScheduleCalculatorRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.RentScheduleCalculatorRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.RentScheduleCalculatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.RentScheduleCalculatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.RentScheduleCalculatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.RentScheduleCalculatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    abstractUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    provisionFormUid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    additionalHtmlNamesList: jspb.Message.getRepeatedField(msg, 3)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.RentScheduleCalculatorRequest}
 */
proto.common.RentScheduleCalculatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.RentScheduleCalculatorRequest;
  return proto.common.RentScheduleCalculatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.RentScheduleCalculatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.RentScheduleCalculatorRequest}
 */
proto.common.RentScheduleCalculatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbstractUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvisionFormUid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdditionalHtmlNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.RentScheduleCalculatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.RentScheduleCalculatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.RentScheduleCalculatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.RentScheduleCalculatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAbstractUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProvisionFormUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdditionalHtmlNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string abstract_uid = 1;
 * @return {string}
 */
proto.common.RentScheduleCalculatorRequest.prototype.getAbstractUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.RentScheduleCalculatorRequest.prototype.setAbstractUid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string provision_form_uid = 2;
 * @return {string}
 */
proto.common.RentScheduleCalculatorRequest.prototype.getProvisionFormUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.common.RentScheduleCalculatorRequest.prototype.setProvisionFormUid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string additional_html_names = 3;
 * @return {!Array<string>}
 */
proto.common.RentScheduleCalculatorRequest.prototype.getAdditionalHtmlNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<string>} value */
proto.common.RentScheduleCalculatorRequest.prototype.setAdditionalHtmlNamesList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.RentScheduleCalculatorRequest.prototype.addAdditionalHtmlNames = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


proto.common.RentScheduleCalculatorRequest.prototype.clearAdditionalHtmlNamesList = function() {
  this.setAdditionalHtmlNamesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.RentScheduleCalculatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.RentScheduleCalculatorResponse.repeatedFields_, null);
};
goog.inherits(proto.common.RentScheduleCalculatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.RentScheduleCalculatorResponse.displayName = 'proto.common.RentScheduleCalculatorResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.RentScheduleCalculatorResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.RentScheduleCalculatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.common.RentScheduleCalculatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.RentScheduleCalculatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.RentScheduleCalculatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageInfoList: jspb.Message.toObjectList(msg.getMessageInfoList(),
    proto.common.MessageInfo.toObject, includeInstance),
    htmlNameToFullValMap: (f = msg.getHtmlNameToFullValMap()) ? f.toObject(includeInstance, proto.common.FullValMultiPdfProvisionDTO.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.RentScheduleCalculatorResponse}
 */
proto.common.RentScheduleCalculatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.RentScheduleCalculatorResponse;
  return proto.common.RentScheduleCalculatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.RentScheduleCalculatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.RentScheduleCalculatorResponse}
 */
proto.common.RentScheduleCalculatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.MessageInfo;
      reader.readMessage(value,proto.common.MessageInfo.deserializeBinaryFromReader);
      msg.addMessageInfo(value);
      break;
    case 2:
      var value = msg.getHtmlNameToFullValMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.common.FullValMultiPdfProvisionDTO.deserializeBinaryFromReader, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.RentScheduleCalculatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.RentScheduleCalculatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.RentScheduleCalculatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.RentScheduleCalculatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.MessageInfo.serializeBinaryToWriter
    );
  }
  f = message.getHtmlNameToFullValMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.common.FullValMultiPdfProvisionDTO.serializeBinaryToWriter);
  }
};


/**
 * repeated MessageInfo message_info = 1;
 * @return {!Array<!proto.common.MessageInfo>}
 */
proto.common.RentScheduleCalculatorResponse.prototype.getMessageInfoList = function() {
  return /** @type{!Array<!proto.common.MessageInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.MessageInfo, 1));
};


/** @param {!Array<!proto.common.MessageInfo>} value */
proto.common.RentScheduleCalculatorResponse.prototype.setMessageInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.MessageInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.MessageInfo}
 */
proto.common.RentScheduleCalculatorResponse.prototype.addMessageInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.MessageInfo, opt_index);
};


proto.common.RentScheduleCalculatorResponse.prototype.clearMessageInfoList = function() {
  this.setMessageInfoList([]);
};


/**
 * map<string, FullValMultiPdfProvisionDTO> html_name_to_full_val = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.common.FullValMultiPdfProvisionDTO>}
 */
proto.common.RentScheduleCalculatorResponse.prototype.getHtmlNameToFullValMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.common.FullValMultiPdfProvisionDTO>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.common.FullValMultiPdfProvisionDTO));
};


proto.common.RentScheduleCalculatorResponse.prototype.clearHtmlNameToFullValMap = function() {
  this.getHtmlNameToFullValMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.TermCalculatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.TermCalculatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.TermCalculatorRequest.displayName = 'proto.common.TermCalculatorRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.TermCalculatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.TermCalculatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.TermCalculatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TermCalculatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    abstractUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    provisionFormUid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.TermCalculatorRequest}
 */
proto.common.TermCalculatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.TermCalculatorRequest;
  return proto.common.TermCalculatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.TermCalculatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.TermCalculatorRequest}
 */
proto.common.TermCalculatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbstractUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvisionFormUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.TermCalculatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.TermCalculatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.TermCalculatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TermCalculatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAbstractUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProvisionFormUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string abstract_uid = 1;
 * @return {string}
 */
proto.common.TermCalculatorRequest.prototype.getAbstractUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.TermCalculatorRequest.prototype.setAbstractUid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string provision_form_uid = 2;
 * @return {string}
 */
proto.common.TermCalculatorRequest.prototype.getProvisionFormUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.common.TermCalculatorRequest.prototype.setProvisionFormUid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.TermCalculatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.TermCalculatorResponse.repeatedFields_, null);
};
goog.inherits(proto.common.TermCalculatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.TermCalculatorResponse.displayName = 'proto.common.TermCalculatorResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.TermCalculatorResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.TermCalculatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.common.TermCalculatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.TermCalculatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TermCalculatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageInfoList: jspb.Message.toObjectList(msg.getMessageInfoList(),
    proto.common.MessageInfo.toObject, includeInstance),
    htmlNameToFullvalMap: (f = msg.getHtmlNameToFullvalMap()) ? f.toObject(includeInstance, proto.common.FullValMultiPdfProvisionDTO.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.TermCalculatorResponse}
 */
proto.common.TermCalculatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.TermCalculatorResponse;
  return proto.common.TermCalculatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.TermCalculatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.TermCalculatorResponse}
 */
proto.common.TermCalculatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.MessageInfo;
      reader.readMessage(value,proto.common.MessageInfo.deserializeBinaryFromReader);
      msg.addMessageInfo(value);
      break;
    case 2:
      var value = msg.getHtmlNameToFullvalMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.common.FullValMultiPdfProvisionDTO.deserializeBinaryFromReader, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.TermCalculatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.TermCalculatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.TermCalculatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TermCalculatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.MessageInfo.serializeBinaryToWriter
    );
  }
  f = message.getHtmlNameToFullvalMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.common.FullValMultiPdfProvisionDTO.serializeBinaryToWriter);
  }
};


/**
 * repeated MessageInfo message_info = 1;
 * @return {!Array<!proto.common.MessageInfo>}
 */
proto.common.TermCalculatorResponse.prototype.getMessageInfoList = function() {
  return /** @type{!Array<!proto.common.MessageInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.MessageInfo, 1));
};


/** @param {!Array<!proto.common.MessageInfo>} value */
proto.common.TermCalculatorResponse.prototype.setMessageInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.MessageInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.MessageInfo}
 */
proto.common.TermCalculatorResponse.prototype.addMessageInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.MessageInfo, opt_index);
};


proto.common.TermCalculatorResponse.prototype.clearMessageInfoList = function() {
  this.setMessageInfoList([]);
};


/**
 * map<string, FullValMultiPdfProvisionDTO> html_name_to_fullVal = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.common.FullValMultiPdfProvisionDTO>}
 */
proto.common.TermCalculatorResponse.prototype.getHtmlNameToFullvalMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.common.FullValMultiPdfProvisionDTO>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.common.FullValMultiPdfProvisionDTO));
};


proto.common.TermCalculatorResponse.prototype.clearHtmlNameToFullvalMap = function() {
  this.getHtmlNameToFullvalMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MessageInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.MessageInfo.oneofGroups_);
};
goog.inherits(proto.common.MessageInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.MessageInfo.displayName = 'proto.common.MessageInfo';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.MessageInfo.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.common.MessageInfo.CodevalueCase = {
  CODEVALUE_NOT_SET: 0,
  ERROR_CODE: 2,
  SUCCESS_CODE: 3
};

/**
 * @return {proto.common.MessageInfo.CodevalueCase}
 */
proto.common.MessageInfo.prototype.getCodevalueCase = function() {
  return /** @type {proto.common.MessageInfo.CodevalueCase} */(jspb.Message.computeOneofCase(this, proto.common.MessageInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MessageInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MessageInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MessageInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MessageInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    successCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MessageInfo}
 */
proto.common.MessageInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MessageInfo;
  return proto.common.MessageInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MessageInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MessageInfo}
 */
proto.common.MessageInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.common.MessageInfo.ErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = /** @type {!proto.common.MessageInfo.SuccessCode} */ (reader.readEnum());
      msg.setSuccessCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MessageInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MessageInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MessageInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MessageInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!proto.common.MessageInfo.ErrorCode} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.common.MessageInfo.SuccessCode} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.MessageInfo.ErrorCode = {
  DEFAULT: 0,
  MISSING_ITEM: 1,
  DUPLICATE_ITEM: 2,
  INVALID_CRED: 3,
  UNAUTHORIZED: 4,
  LOGGED_OUT: 5,
  INSUFFICIENT_CREDITS: 6,
  MISSING_PARAM: 7,
  BAD_PARSE: 8,
  DEACTIVE_ITEM: 9,
  INCORRECT_ARG: 10,
  INCORRECT_MODE: 11
};

/**
 * @enum {number}
 */
proto.common.MessageInfo.SuccessCode = {
  CREATED: 0,
  EDITED: 1,
  DELETED: 2,
  SHARED: 3
};

/**
 * optional string message = 1;
 * @return {string}
 */
proto.common.MessageInfo.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.MessageInfo.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ErrorCode error_code = 2;
 * @return {!proto.common.MessageInfo.ErrorCode}
 */
proto.common.MessageInfo.prototype.getErrorCode = function() {
  return /** @type {!proto.common.MessageInfo.ErrorCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.common.MessageInfo.ErrorCode} value */
proto.common.MessageInfo.prototype.setErrorCode = function(value) {
  jspb.Message.setOneofField(this, 2, proto.common.MessageInfo.oneofGroups_[0], value);
};


proto.common.MessageInfo.prototype.clearErrorCode = function() {
  jspb.Message.setOneofField(this, 2, proto.common.MessageInfo.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.MessageInfo.prototype.hasErrorCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SuccessCode success_code = 3;
 * @return {!proto.common.MessageInfo.SuccessCode}
 */
proto.common.MessageInfo.prototype.getSuccessCode = function() {
  return /** @type {!proto.common.MessageInfo.SuccessCode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.common.MessageInfo.SuccessCode} value */
proto.common.MessageInfo.prototype.setSuccessCode = function(value) {
  jspb.Message.setOneofField(this, 3, proto.common.MessageInfo.oneofGroups_[0], value);
};


proto.common.MessageInfo.prototype.clearSuccessCode = function() {
  jspb.Message.setOneofField(this, 3, proto.common.MessageInfo.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.MessageInfo.prototype.hasSuccessCode = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FullValMultiPdfProvisionDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.FullValMultiPdfProvisionDTO.repeatedFields_, null);
};
goog.inherits(proto.common.FullValMultiPdfProvisionDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.FullValMultiPdfProvisionDTO.displayName = 'proto.common.FullValMultiPdfProvisionDTO';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.FullValMultiPdfProvisionDTO.repeatedFields_ = [7,8,9,10,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FullValMultiPdfProvisionDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FullValMultiPdfProvisionDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FullValMultiPdfProvisionDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    htmlName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    provisionUid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    provisionType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    viewsList: jspb.Message.toObjectList(msg.getViewsList(),
    proto.common.PartialValPdfProvisionDTO.toObject, includeInstance),
    possibleCurrenciesList: jspb.Message.getRepeatedField(msg, 8),
    possibleValuesList: jspb.Message.getRepeatedField(msg, 9),
    possibleUnitsList: jspb.Message.getRepeatedField(msg, 10),
    possibleStatesList: jspb.Message.getRepeatedField(msg, 11),
    possibleCountriesList: jspb.Message.getRepeatedField(msg, 12)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FullValMultiPdfProvisionDTO}
 */
proto.common.FullValMultiPdfProvisionDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FullValMultiPdfProvisionDTO;
  return proto.common.FullValMultiPdfProvisionDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FullValMultiPdfProvisionDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FullValMultiPdfProvisionDTO}
 */
proto.common.FullValMultiPdfProvisionDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtmlName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvisionUid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {!proto.common.ProvisionType} */ (reader.readEnum());
      msg.setProvisionType(value);
      break;
    case 7:
      var value = new proto.common.PartialValPdfProvisionDTO;
      reader.readMessage(value,proto.common.PartialValPdfProvisionDTO.deserializeBinaryFromReader);
      msg.addViews(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addPossibleCurrencies(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addPossibleValues(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addPossibleUnits(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addPossibleStates(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addPossibleCountries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FullValMultiPdfProvisionDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FullValMultiPdfProvisionDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FullValMultiPdfProvisionDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHtmlName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProvisionUid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProvisionType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getViewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.common.PartialValPdfProvisionDTO.serializeBinaryToWriter
    );
  }
  f = message.getPossibleCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getPossibleValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getPossibleUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getPossibleStatesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getPossibleCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string html_name = 3;
 * @return {string}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getHtmlName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setHtmlName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string provision_uid = 4;
 * @return {string}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getProvisionUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setProvisionUid = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ProvisionType provision_type = 6;
 * @return {!proto.common.ProvisionType}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getProvisionType = function() {
  return /** @type {!proto.common.ProvisionType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.common.ProvisionType} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setProvisionType = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated PartialValPdfProvisionDTO views = 7;
 * @return {!Array<!proto.common.PartialValPdfProvisionDTO>}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getViewsList = function() {
  return /** @type{!Array<!proto.common.PartialValPdfProvisionDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.PartialValPdfProvisionDTO, 7));
};


/** @param {!Array<!proto.common.PartialValPdfProvisionDTO>} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setViewsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.PartialValPdfProvisionDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.PartialValPdfProvisionDTO}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.addViews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.PartialValPdfProvisionDTO, opt_index);
};


proto.common.FullValMultiPdfProvisionDTO.prototype.clearViewsList = function() {
  this.setViewsList([]);
};


/**
 * repeated string possible_currencies = 8;
 * @return {!Array<string>}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getPossibleCurrenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<string>} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setPossibleCurrenciesList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.addPossibleCurrencies = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


proto.common.FullValMultiPdfProvisionDTO.prototype.clearPossibleCurrenciesList = function() {
  this.setPossibleCurrenciesList([]);
};


/**
 * repeated string possible_values = 9;
 * @return {!Array<string>}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getPossibleValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/** @param {!Array<string>} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setPossibleValuesList = function(value) {
  jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.addPossibleValues = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


proto.common.FullValMultiPdfProvisionDTO.prototype.clearPossibleValuesList = function() {
  this.setPossibleValuesList([]);
};


/**
 * repeated string possible_units = 10;
 * @return {!Array<string>}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getPossibleUnitsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/** @param {!Array<string>} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setPossibleUnitsList = function(value) {
  jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.addPossibleUnits = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


proto.common.FullValMultiPdfProvisionDTO.prototype.clearPossibleUnitsList = function() {
  this.setPossibleUnitsList([]);
};


/**
 * repeated string possible_states = 11;
 * @return {!Array<string>}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getPossibleStatesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/** @param {!Array<string>} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setPossibleStatesList = function(value) {
  jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.addPossibleStates = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


proto.common.FullValMultiPdfProvisionDTO.prototype.clearPossibleStatesList = function() {
  this.setPossibleStatesList([]);
};


/**
 * repeated string possible_countries = 12;
 * @return {!Array<string>}
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.getPossibleCountriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/** @param {!Array<string>} value */
proto.common.FullValMultiPdfProvisionDTO.prototype.setPossibleCountriesList = function(value) {
  jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.FullValMultiPdfProvisionDTO.prototype.addPossibleCountries = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


proto.common.FullValMultiPdfProvisionDTO.prototype.clearPossibleCountriesList = function() {
  this.setPossibleCountriesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.PartialValPdfProvisionDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.PartialValPdfProvisionDTO.repeatedFields_, proto.common.PartialValPdfProvisionDTO.oneofGroups_);
};
goog.inherits(proto.common.PartialValPdfProvisionDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.PartialValPdfProvisionDTO.displayName = 'proto.common.PartialValPdfProvisionDTO';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.PartialValPdfProvisionDTO.repeatedFields_ = [8];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.PartialValPdfProvisionDTO.oneofGroups_ = [[11,12,13,14,15,16,17,18,19,20,21,22]];

/**
 * @enum {number}
 */
proto.common.PartialValPdfProvisionDTO.ProvisionvalueCase = {
  PROVISIONVALUE_NOT_SET: 0,
  LOCAL_DATE_VALUE: 11,
  COMMON_NOUN_VALUE: 12,
  PROPER_NOUN_VALUE: 13,
  MONEY_VALUE: 14,
  ADDRESS_VALUE: 15,
  DOUBLE_VALUE: 16,
  SINGLE_CAT_VALUE: 17,
  MULTIPLE_CAT_VALUE: 18,
  TABLE_VALUE: 19,
  YEAR_VALUE: 20,
  EMAIL_VALUE: 21,
  PHONE_VALUE: 22
};

/**
 * @return {proto.common.PartialValPdfProvisionDTO.ProvisionvalueCase}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getProvisionvalueCase = function() {
  return /** @type {proto.common.PartialValPdfProvisionDTO.ProvisionvalueCase} */(jspb.Message.computeOneofCase(this, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.PartialValPdfProvisionDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.common.PartialValPdfProvisionDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.PartialValPdfProvisionDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PartialValPdfProvisionDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    textValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sectionHeader: jspb.Message.getFieldWithDefault(msg, 4, ""),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0),
    docAbstractUid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    source: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pdfHighlightIdsList: jspb.Message.getRepeatedField(msg, 8),
    selected: jspb.Message.getFieldWithDefault(msg, 9, false),
    provisionType: jspb.Message.getFieldWithDefault(msg, 23, 0),
    localDateValue: (f = msg.getLocalDateValue()) && proto.common.LocalDateValue.toObject(includeInstance, f),
    commonNounValue: (f = msg.getCommonNounValue()) && proto.common.CommonNounValue.toObject(includeInstance, f),
    properNounValue: (f = msg.getProperNounValue()) && proto.common.ProperNounValue.toObject(includeInstance, f),
    moneyValue: (f = msg.getMoneyValue()) && proto.common.MoneyValue.toObject(includeInstance, f),
    addressValue: (f = msg.getAddressValue()) && proto.common.AddressValue.toObject(includeInstance, f),
    doubleValue: (f = msg.getDoubleValue()) && proto.common.DoubleValue.toObject(includeInstance, f),
    singleCatValue: (f = msg.getSingleCatValue()) && proto.common.SingleCatValue.toObject(includeInstance, f),
    multipleCatValue: (f = msg.getMultipleCatValue()) && proto.common.MultipleCatValue.toObject(includeInstance, f),
    tableValue: (f = msg.getTableValue()) && proto.common.TableValue.toObject(includeInstance, f),
    yearValue: (f = msg.getYearValue()) && proto.common.YearValue.toObject(includeInstance, f),
    emailValue: (f = msg.getEmailValue()) && proto.common.EmailValue.toObject(includeInstance, f),
    phoneValue: (f = msg.getPhoneValue()) && proto.common.PhoneValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.PartialValPdfProvisionDTO}
 */
proto.common.PartialValPdfProvisionDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.PartialValPdfProvisionDTO;
  return proto.common.PartialValPdfProvisionDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.PartialValPdfProvisionDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.PartialValPdfProvisionDTO}
 */
proto.common.PartialValPdfProvisionDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSectionHeader(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocAbstractUid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addPdfHighlightIds(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    case 23:
      var value = /** @type {!proto.common.ProvisionType} */ (reader.readEnum());
      msg.setProvisionType(value);
      break;
    case 11:
      var value = new proto.common.LocalDateValue;
      reader.readMessage(value,proto.common.LocalDateValue.deserializeBinaryFromReader);
      msg.setLocalDateValue(value);
      break;
    case 12:
      var value = new proto.common.CommonNounValue;
      reader.readMessage(value,proto.common.CommonNounValue.deserializeBinaryFromReader);
      msg.setCommonNounValue(value);
      break;
    case 13:
      var value = new proto.common.ProperNounValue;
      reader.readMessage(value,proto.common.ProperNounValue.deserializeBinaryFromReader);
      msg.setProperNounValue(value);
      break;
    case 14:
      var value = new proto.common.MoneyValue;
      reader.readMessage(value,proto.common.MoneyValue.deserializeBinaryFromReader);
      msg.setMoneyValue(value);
      break;
    case 15:
      var value = new proto.common.AddressValue;
      reader.readMessage(value,proto.common.AddressValue.deserializeBinaryFromReader);
      msg.setAddressValue(value);
      break;
    case 16:
      var value = new proto.common.DoubleValue;
      reader.readMessage(value,proto.common.DoubleValue.deserializeBinaryFromReader);
      msg.setDoubleValue(value);
      break;
    case 17:
      var value = new proto.common.SingleCatValue;
      reader.readMessage(value,proto.common.SingleCatValue.deserializeBinaryFromReader);
      msg.setSingleCatValue(value);
      break;
    case 18:
      var value = new proto.common.MultipleCatValue;
      reader.readMessage(value,proto.common.MultipleCatValue.deserializeBinaryFromReader);
      msg.setMultipleCatValue(value);
      break;
    case 19:
      var value = new proto.common.TableValue;
      reader.readMessage(value,proto.common.TableValue.deserializeBinaryFromReader);
      msg.setTableValue(value);
      break;
    case 20:
      var value = new proto.common.YearValue;
      reader.readMessage(value,proto.common.YearValue.deserializeBinaryFromReader);
      msg.setYearValue(value);
      break;
    case 21:
      var value = new proto.common.EmailValue;
      reader.readMessage(value,proto.common.EmailValue.deserializeBinaryFromReader);
      msg.setEmailValue(value);
      break;
    case 22:
      var value = new proto.common.PhoneValue;
      reader.readMessage(value,proto.common.PhoneValue.deserializeBinaryFromReader);
      msg.setPhoneValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.PartialValPdfProvisionDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.PartialValPdfProvisionDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.PartialValPdfProvisionDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PartialValPdfProvisionDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTextValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSectionHeader();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDocAbstractUid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPdfHighlightIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getProvisionType();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getLocalDateValue();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.common.LocalDateValue.serializeBinaryToWriter
    );
  }
  f = message.getCommonNounValue();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.common.CommonNounValue.serializeBinaryToWriter
    );
  }
  f = message.getProperNounValue();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.common.ProperNounValue.serializeBinaryToWriter
    );
  }
  f = message.getMoneyValue();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.common.MoneyValue.serializeBinaryToWriter
    );
  }
  f = message.getAddressValue();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.common.AddressValue.serializeBinaryToWriter
    );
  }
  f = message.getDoubleValue();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.common.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getSingleCatValue();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.common.SingleCatValue.serializeBinaryToWriter
    );
  }
  f = message.getMultipleCatValue();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.common.MultipleCatValue.serializeBinaryToWriter
    );
  }
  f = message.getTableValue();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.common.TableValue.serializeBinaryToWriter
    );
  }
  f = message.getYearValue();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.common.YearValue.serializeBinaryToWriter
    );
  }
  f = message.getEmailValue();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.common.EmailValue.serializeBinaryToWriter
    );
  }
  f = message.getPhoneValue();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.common.PhoneValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.PartialValPdfProvisionDTO.prototype.setUid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text_value = 2;
 * @return {string}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getTextValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.common.PartialValPdfProvisionDTO.prototype.setTextValue = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string notes = 3;
 * @return {string}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.common.PartialValPdfProvisionDTO.prototype.setNotes = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string section_header = 4;
 * @return {string}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getSectionHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.common.PartialValPdfProvisionDTO.prototype.setSectionHeader = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 page = 5;
 * @return {number}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.common.PartialValPdfProvisionDTO.prototype.setPage = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string doc_abstract_uid = 6;
 * @return {string}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getDocAbstractUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.common.PartialValPdfProvisionDTO.prototype.setDocAbstractUid = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string source = 7;
 * @return {string}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.common.PartialValPdfProvisionDTO.prototype.setSource = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string pdf_highlight_ids = 8;
 * @return {!Array<string>}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getPdfHighlightIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<string>} value */
proto.common.PartialValPdfProvisionDTO.prototype.setPdfHighlightIdsList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.PartialValPdfProvisionDTO.prototype.addPdfHighlightIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearPdfHighlightIdsList = function() {
  this.setPdfHighlightIdsList([]);
};


/**
 * optional bool selected = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.common.PartialValPdfProvisionDTO.prototype.setSelected = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional ProvisionType provision_type = 23;
 * @return {!proto.common.ProvisionType}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getProvisionType = function() {
  return /** @type {!proto.common.ProvisionType} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/** @param {!proto.common.ProvisionType} value */
proto.common.PartialValPdfProvisionDTO.prototype.setProvisionType = function(value) {
  jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional LocalDateValue local_date_value = 11;
 * @return {?proto.common.LocalDateValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getLocalDateValue = function() {
  return /** @type{?proto.common.LocalDateValue} */ (
    jspb.Message.getWrapperField(this, proto.common.LocalDateValue, 11));
};


/** @param {?proto.common.LocalDateValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setLocalDateValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 11, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearLocalDateValue = function() {
  this.setLocalDateValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasLocalDateValue = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional CommonNounValue common_noun_value = 12;
 * @return {?proto.common.CommonNounValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getCommonNounValue = function() {
  return /** @type{?proto.common.CommonNounValue} */ (
    jspb.Message.getWrapperField(this, proto.common.CommonNounValue, 12));
};


/** @param {?proto.common.CommonNounValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setCommonNounValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 12, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearCommonNounValue = function() {
  this.setCommonNounValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasCommonNounValue = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional ProperNounValue proper_noun_value = 13;
 * @return {?proto.common.ProperNounValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getProperNounValue = function() {
  return /** @type{?proto.common.ProperNounValue} */ (
    jspb.Message.getWrapperField(this, proto.common.ProperNounValue, 13));
};


/** @param {?proto.common.ProperNounValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setProperNounValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 13, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearProperNounValue = function() {
  this.setProperNounValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasProperNounValue = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional MoneyValue money_value = 14;
 * @return {?proto.common.MoneyValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getMoneyValue = function() {
  return /** @type{?proto.common.MoneyValue} */ (
    jspb.Message.getWrapperField(this, proto.common.MoneyValue, 14));
};


/** @param {?proto.common.MoneyValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setMoneyValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 14, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearMoneyValue = function() {
  this.setMoneyValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasMoneyValue = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional AddressValue address_value = 15;
 * @return {?proto.common.AddressValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getAddressValue = function() {
  return /** @type{?proto.common.AddressValue} */ (
    jspb.Message.getWrapperField(this, proto.common.AddressValue, 15));
};


/** @param {?proto.common.AddressValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setAddressValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 15, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearAddressValue = function() {
  this.setAddressValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasAddressValue = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional DoubleValue double_value = 16;
 * @return {?proto.common.DoubleValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getDoubleValue = function() {
  return /** @type{?proto.common.DoubleValue} */ (
    jspb.Message.getWrapperField(this, proto.common.DoubleValue, 16));
};


/** @param {?proto.common.DoubleValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setDoubleValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 16, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearDoubleValue = function() {
  this.setDoubleValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional SingleCatValue single_cat_value = 17;
 * @return {?proto.common.SingleCatValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getSingleCatValue = function() {
  return /** @type{?proto.common.SingleCatValue} */ (
    jspb.Message.getWrapperField(this, proto.common.SingleCatValue, 17));
};


/** @param {?proto.common.SingleCatValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setSingleCatValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 17, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearSingleCatValue = function() {
  this.setSingleCatValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasSingleCatValue = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional MultipleCatValue multiple_cat_value = 18;
 * @return {?proto.common.MultipleCatValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getMultipleCatValue = function() {
  return /** @type{?proto.common.MultipleCatValue} */ (
    jspb.Message.getWrapperField(this, proto.common.MultipleCatValue, 18));
};


/** @param {?proto.common.MultipleCatValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setMultipleCatValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 18, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearMultipleCatValue = function() {
  this.setMultipleCatValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasMultipleCatValue = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional TableValue table_value = 19;
 * @return {?proto.common.TableValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getTableValue = function() {
  return /** @type{?proto.common.TableValue} */ (
    jspb.Message.getWrapperField(this, proto.common.TableValue, 19));
};


/** @param {?proto.common.TableValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setTableValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 19, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearTableValue = function() {
  this.setTableValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasTableValue = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional YearValue year_value = 20;
 * @return {?proto.common.YearValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getYearValue = function() {
  return /** @type{?proto.common.YearValue} */ (
    jspb.Message.getWrapperField(this, proto.common.YearValue, 20));
};


/** @param {?proto.common.YearValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setYearValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 20, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearYearValue = function() {
  this.setYearValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasYearValue = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional EmailValue email_value = 21;
 * @return {?proto.common.EmailValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getEmailValue = function() {
  return /** @type{?proto.common.EmailValue} */ (
    jspb.Message.getWrapperField(this, proto.common.EmailValue, 21));
};


/** @param {?proto.common.EmailValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setEmailValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 21, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearEmailValue = function() {
  this.setEmailValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasEmailValue = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PhoneValue phone_value = 22;
 * @return {?proto.common.PhoneValue}
 */
proto.common.PartialValPdfProvisionDTO.prototype.getPhoneValue = function() {
  return /** @type{?proto.common.PhoneValue} */ (
    jspb.Message.getWrapperField(this, proto.common.PhoneValue, 22));
};


/** @param {?proto.common.PhoneValue|undefined} value */
proto.common.PartialValPdfProvisionDTO.prototype.setPhoneValue = function(value) {
  jspb.Message.setOneofWrapperField(this, 22, proto.common.PartialValPdfProvisionDTO.oneofGroups_[0], value);
};


proto.common.PartialValPdfProvisionDTO.prototype.clearPhoneValue = function() {
  this.setPhoneValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.common.PartialValPdfProvisionDTO.prototype.hasPhoneValue = function() {
  return jspb.Message.getField(this, 22) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.LocalDateValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.LocalDateValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.LocalDateValue.displayName = 'proto.common.LocalDateValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.LocalDateValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.LocalDateValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.LocalDateValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.LocalDateValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.LocalDateValue}
 */
proto.common.LocalDateValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.LocalDateValue;
  return proto.common.LocalDateValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.LocalDateValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.LocalDateValue}
 */
proto.common.LocalDateValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.LocalDateValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.LocalDateValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.LocalDateValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.LocalDateValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.common.LocalDateValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.LocalDateValue.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.CommonNounValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.CommonNounValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.CommonNounValue.displayName = 'proto.common.CommonNounValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.CommonNounValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.CommonNounValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.CommonNounValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CommonNounValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.CommonNounValue}
 */
proto.common.CommonNounValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.CommonNounValue;
  return proto.common.CommonNounValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.CommonNounValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.CommonNounValue}
 */
proto.common.CommonNounValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.CommonNounValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.CommonNounValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.CommonNounValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CommonNounValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.common.CommonNounValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.CommonNounValue.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ProperNounValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.ProperNounValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.ProperNounValue.displayName = 'proto.common.ProperNounValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ProperNounValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ProperNounValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ProperNounValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProperNounValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ProperNounValue}
 */
proto.common.ProperNounValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ProperNounValue;
  return proto.common.ProperNounValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ProperNounValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ProperNounValue}
 */
proto.common.ProperNounValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ProperNounValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ProperNounValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ProperNounValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ProperNounValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.common.ProperNounValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.ProperNounValue.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MoneyValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.MoneyValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.MoneyValue.displayName = 'proto.common.MoneyValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MoneyValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MoneyValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MoneyValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MoneyValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    majorValue: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MoneyValue}
 */
proto.common.MoneyValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MoneyValue;
  return proto.common.MoneyValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MoneyValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MoneyValue}
 */
proto.common.MoneyValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMajorValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MoneyValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MoneyValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MoneyValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MoneyValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMajorValue();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSubValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 major_value = 1;
 * @return {number}
 */
proto.common.MoneyValue.prototype.getMajorValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.common.MoneyValue.prototype.setMajorValue = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sub_value = 2;
 * @return {number}
 */
proto.common.MoneyValue.prototype.getSubValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.common.MoneyValue.prototype.setSubValue = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.common.MoneyValue.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.common.MoneyValue.prototype.setCurrency = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AddressValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AddressValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.AddressValue.displayName = 'proto.common.AddressValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AddressValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AddressValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AddressValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AddressValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    streetAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suite: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AddressValue}
 */
proto.common.AddressValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AddressValue;
  return proto.common.AddressValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AddressValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AddressValue}
 */
proto.common.AddressValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuite(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AddressValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AddressValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AddressValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AddressValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreetAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuite();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string street_address = 1;
 * @return {string}
 */
proto.common.AddressValue.prototype.getStreetAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.AddressValue.prototype.setStreetAddress = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string suite = 2;
 * @return {string}
 */
proto.common.AddressValue.prototype.getSuite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.common.AddressValue.prototype.setSuite = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.common.AddressValue.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.common.AddressValue.prototype.setCity = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.common.AddressValue.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.common.AddressValue.prototype.setState = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.common.AddressValue.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.common.AddressValue.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.common.AddressValue.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.common.AddressValue.prototype.setPostalCode = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.DoubleValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.DoubleValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.DoubleValue.displayName = 'proto.common.DoubleValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.DoubleValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.DoubleValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.DoubleValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.DoubleValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    unit: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.DoubleValue}
 */
proto.common.DoubleValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.DoubleValue;
  return proto.common.DoubleValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.DoubleValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.DoubleValue}
 */
proto.common.DoubleValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.DoubleValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.DoubleValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.DoubleValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.DoubleValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.common.DoubleValue.prototype.getValue = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.common.DoubleValue.prototype.setValue = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string unit = 2;
 * @return {string}
 */
proto.common.DoubleValue.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.common.DoubleValue.prototype.setUnit = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.SingleCatValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.SingleCatValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.SingleCatValue.displayName = 'proto.common.SingleCatValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.SingleCatValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.SingleCatValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.SingleCatValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SingleCatValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.SingleCatValue}
 */
proto.common.SingleCatValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.SingleCatValue;
  return proto.common.SingleCatValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.SingleCatValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.SingleCatValue}
 */
proto.common.SingleCatValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.SingleCatValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.SingleCatValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.SingleCatValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SingleCatValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.common.SingleCatValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.SingleCatValue.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MultipleCatValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.MultipleCatValue.repeatedFields_, null);
};
goog.inherits(proto.common.MultipleCatValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.MultipleCatValue.displayName = 'proto.common.MultipleCatValue';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.MultipleCatValue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MultipleCatValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MultipleCatValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MultipleCatValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MultipleCatValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MultipleCatValue}
 */
proto.common.MultipleCatValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MultipleCatValue;
  return proto.common.MultipleCatValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MultipleCatValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MultipleCatValue}
 */
proto.common.MultipleCatValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MultipleCatValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MultipleCatValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MultipleCatValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MultipleCatValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.common.MultipleCatValue.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.common.MultipleCatValue.prototype.setValuesList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.MultipleCatValue.prototype.addValues = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.common.MultipleCatValue.prototype.clearValuesList = function() {
  this.setValuesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.TableValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.TableValue.repeatedFields_, null);
};
goog.inherits(proto.common.TableValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.TableValue.displayName = 'proto.common.TableValue';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.TableValue.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.TableValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.TableValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.TableValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TableValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    headersList: jspb.Message.getRepeatedField(msg, 1),
    typesList: jspb.Message.getRepeatedField(msg, 2),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.common.TableValue.Row.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.TableValue}
 */
proto.common.TableValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.TableValue;
  return proto.common.TableValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.TableValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.TableValue}
 */
proto.common.TableValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addHeaders(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addTypes(value);
      break;
    case 3:
      var value = new proto.common.TableValue.Row;
      reader.readMessage(value,proto.common.TableValue.Row.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.TableValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.TableValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.TableValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TableValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.common.TableValue.Row.serializeBinaryToWriter
    );
  }
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.TableValue.Row = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.TableValue.Row.repeatedFields_, null);
};
goog.inherits(proto.common.TableValue.Row, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.TableValue.Row.displayName = 'proto.common.TableValue.Row';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.TableValue.Row.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.TableValue.Row.prototype.toObject = function(opt_includeInstance) {
  return proto.common.TableValue.Row.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.TableValue.Row} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TableValue.Row.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.TableValue.Row}
 */
proto.common.TableValue.Row.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.TableValue.Row;
  return proto.common.TableValue.Row.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.TableValue.Row} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.TableValue.Row}
 */
proto.common.TableValue.Row.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.TableValue.Row.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.TableValue.Row.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.TableValue.Row} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TableValue.Row.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.common.TableValue.Row.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.common.TableValue.Row.prototype.setValuesList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.TableValue.Row.prototype.addValues = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.common.TableValue.Row.prototype.clearValuesList = function() {
  this.setValuesList([]);
};


/**
 * repeated string headers = 1;
 * @return {!Array<string>}
 */
proto.common.TableValue.prototype.getHeadersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.common.TableValue.prototype.setHeadersList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.TableValue.prototype.addHeaders = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.common.TableValue.prototype.clearHeadersList = function() {
  this.setHeadersList([]);
};


/**
 * repeated string types = 2;
 * @return {!Array<string>}
 */
proto.common.TableValue.prototype.getTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.common.TableValue.prototype.setTypesList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.common.TableValue.prototype.addTypes = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.common.TableValue.prototype.clearTypesList = function() {
  this.setTypesList([]);
};


/**
 * repeated Row rows = 3;
 * @return {!Array<!proto.common.TableValue.Row>}
 */
proto.common.TableValue.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.common.TableValue.Row>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.TableValue.Row, 3));
};


/** @param {!Array<!proto.common.TableValue.Row>} value */
proto.common.TableValue.prototype.setRowsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.TableValue.Row=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.TableValue.Row}
 */
proto.common.TableValue.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.TableValue.Row, opt_index);
};


proto.common.TableValue.prototype.clearRowsList = function() {
  this.setRowsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.YearValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.YearValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.YearValue.displayName = 'proto.common.YearValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.YearValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.YearValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.YearValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.YearValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.YearValue}
 */
proto.common.YearValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.YearValue;
  return proto.common.YearValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.YearValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.YearValue}
 */
proto.common.YearValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.YearValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.YearValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.YearValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.YearValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.common.YearValue.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.common.YearValue.prototype.setYear = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.EmailValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.EmailValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.EmailValue.displayName = 'proto.common.EmailValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.EmailValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.EmailValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.EmailValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EmailValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    localPart: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.EmailValue}
 */
proto.common.EmailValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.EmailValue;
  return proto.common.EmailValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.EmailValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.EmailValue}
 */
proto.common.EmailValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalPart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.EmailValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.EmailValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.EmailValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EmailValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocalPart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string local_part = 1;
 * @return {string}
 */
proto.common.EmailValue.prototype.getLocalPart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.EmailValue.prototype.setLocalPart = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string domain = 2;
 * @return {string}
 */
proto.common.EmailValue.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.common.EmailValue.prototype.setDomain = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.PhoneValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.PhoneValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.common.PhoneValue.displayName = 'proto.common.PhoneValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.PhoneValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.PhoneValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.PhoneValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PhoneValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.PhoneValue}
 */
proto.common.PhoneValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.PhoneValue;
  return proto.common.PhoneValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.PhoneValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.PhoneValue}
 */
proto.common.PhoneValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.PhoneValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.PhoneValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.PhoneValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PhoneValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string phone_number = 1;
 * @return {string}
 */
proto.common.PhoneValue.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.common.PhoneValue.prototype.setPhoneNumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.common.ProvisionType = {
  NONE: 0,
  LOCAL_DATE: 13,
  COMMON_NOUN: 1,
  PROPER_NOUN: 2,
  MONEY: 3,
  ADDRESS: 4,
  DOUBLE: 5,
  SINGLE_CAT: 6,
  MULTIPLE_CAT: 7,
  TABLE: 8,
  YEAR: 9,
  EMAIL: 10,
  PHONE: 11
};

goog.object.extend(exports, proto.common);
