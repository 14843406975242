<div class="filter-widget-chooser">
  <ng-container [ngSwitch]="type">
    <!--        <mat-form-field *ngSwitchCase="'ADDRESS'">-->
    <!--            <input-->
    <!--                matInput-->
    <!--                [(ngModel)]="model"-->
    <!--                placeholder="Value"-->
    <!--                (keyup)="onWidgetModelChange()"-->
    <!--            />-->
    <!--        </mat-form-field>-->
    <div
      *ngSwitchCase="'ADDRESS'"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      "
    >
      <ng-container
        *ngIf="relation !== 'FIELD_EXISTS' && relation !== 'FIELD_NOT_EXISTS'"
      >
        <mat-form-field>
          <mat-label>Street Address</mat-label>
          <input
            style="width: 77px"
            matInput
            [value]="model | convertAddressToString"
            placeholder="Value"
            readonly
            (keyup)="onWidgetModelChange()"
            [matTooltip]="model | convertAddressToString: true"
            [matTooltipShowDelay]="2000"
          />
        </mat-form-field>
        <button
          matTooltip="Full Address"
          style="
            width: 20px;
            height: 20px;
            line-height: 20px !important;
            margin-top: -4px;
            position: absolute;
            right: 8px;
            padding: 0 !important;
          "
          mat-icon-button
          (click)="onAddressModelChange(model.streetAddress, 'input')"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon style="font-size: 18px; line-height: 18px !important"
            >expand_more</mat-icon
          >
        </button>
        <mat-menu
          #menu="matMenu"
          xPosition="before"
          class="filter-address-widget"
        >
          <div click-stop-propagation>
            <sf-form
              *ngIf="formSchema"
              [schema]="formSchema"
              [model]="model"
              [updateOn]="'blur'"
              (change)="onAddressModelChange($event, 'schemaForm')"
            >
            </sf-form>
          </div>
        </mat-menu>
      </ng-container>
      <ng-container
        *ngIf="relation === 'FIELD_EXISTS' || relation === 'FIELD_NOT_EXISTS'"
      >
        <button
          mat-stroked-button
          style="
            font-size: 10px !important;
            padding-top: 3px !important;
            padding-bottom: 4.5px !important;
            min-width: 104px;
            margin-left: 4px;
            margin-right: 3px;
            font-weight: normal !important;
            border-radius: 5px;
            height: 26px;
            line-height: 19px !important;
          "
          [matMenuTriggerFor]="formField"
        >
          Select Fields
        </button>
        <mat-menu
          #formField="matMenu"
          xPosition="before"
          class="filter-address-fields-widget"
        >
          <mat-selection-list
            click-stop-propagation
            #fieldSelection
            [(ngModel)]="filter.nestedFields"
          >
            <mat-list-option
              style="height: 28px !important"
              *ngFor="let formField of formFieldList"
              [value]="formField.value"
              (click)="onWidgetModelChange()"
              >{{ formField.title }}</mat-list-option
            >
          </mat-selection-list>
        </mat-menu>
      </ng-container>
    </div>

    <mat-form-field *ngSwitchCase="'COMMON_NOUN'">
      <input
        matInput
        [(ngModel)]="model"
        placeholder="Value"
        (keyup)="onWidgetModelChange()"
      />
    </mat-form-field>

    <div
      *ngSwitchCase="'DOUBLE'"
      style="display: flex; align-items: center; justify-content: center"
    >
      <mat-form-field style="width: 40px; min-width: 40px">
        <input
          placeholder="Value"
          matInput
          [(ngModel)]="model.value"
          (keyup)="onWidgetModelChange()"
        />
      </mat-form-field>
      <mat-form-field
        style="width: 60px; min-width: 60px; margin-left: 0 !important"
      >
        <mat-select [(value)]="model.unit">
          <mat-option
            *ngFor="let possibleUnit of field?.possibleUnits"
            [value]="possibleUnit"
          >
            {{ possibleUnit }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field *ngSwitchCase="'EMAIL'">
      <input
        matInput
        [(ngModel)]="model"
        placeholder="Value"
        (keyup)="onWidgetModelChange()"
      />
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'DATE'">
      <input
        matInput
        [(ngModel)]="model"
        [matDatepicker]="picker"
        (ngModelChange)="onWidgetModelChange()"
        placeholder="Choose a date"
      />
      <mat-datepicker #picker (select)="onWidgetModelChange()"></mat-datepicker>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'NUMBER'">
      <input
        matInput
        [(ngModel)]="model"
        placeholder="Value"
        (keyup)="onWidgetModelChange()"
        type="number"
      />
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'STRING'">
      <input
        matInput
        [(ngModel)]="model"
        placeholder="Value"
        (keyup)="onWidgetModelChange()"
        type="text"
      />
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'LOCAL_DATE'">
      <input
        matInput
        [(ngModel)]="model"
        [matDatepicker]="picker"
        (ngModelChange)="onWidgetModelChange()"
        placeholder="Choose a date"
      />
      <mat-datepicker #picker (select)="onWidgetModelChange()"></mat-datepicker>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    </mat-form-field>

    <div
      *ngSwitchCase="'MONEY'"
      style="display: flex; align-items: center; justify-content: center"
    >
      <mat-form-field style="width: 40px; min-width: 40px">
        <input
          matInput
          [(ngModel)]="model.value"
          placeholder="Value"
          (keyup)="onWidgetModelChange()"
        />
      </mat-form-field>
      <mat-form-field
        style="width: 60px; min-width: 60px; margin-left: 0 !important"
      >
        <mat-select [(value)]="model.currency">
          <mat-option
            *ngFor="let possibleCurrency of field?.possibleCurrencies"
            [value]="possibleCurrency"
          >
            {{ possibleCurrency }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="'MULTIPLE_CAT'" class="multiple-cat">
      <ng-select
        [items]="multipleCatPossibleItems"
        [multiple]="true"
        appendTo="body"
        bindLabel="name"
        [closeOnSelect]="false"
        [(ngModel)]="selectedMultipleCatItems"
        (change)="onMultipleCatModelChange()"
      >
        <!-- <ng-template
                    ng-optgroup-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index"
                > -->
        <ng-option
          *ngFor="let item of multipleCatPossibleItems"
          [value]="item.index"
          >{{ item.name }}</ng-option
        >
        <!-- </ng-template> -->
      </ng-select>
    </div>

    <mat-form-field *ngSwitchCase="'PHONE'">
      <input
        matInput
        [(ngModel)]="model"
        placeholder="Value"
        (keyup)="onWidgetModelChange()"
      />
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'PROPER_NOUN'">
      <input
        matInput
        [(ngModel)]="model"
        placeholder="Value"
        (keyup)="onWidgetModelChange()"
      />
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'SINGLE_CAT'">
      <ng-container *ngIf="knownEnum; else elseBlock">
        <mat-select [(value)]="model" (selectionChange)="onWidgetModelChange()">
          <ng-container *ngIf="possibleSingleCatValues">
            <mat-option
              *ngFor="let singleCatValue of possibleSingleCatValues"
              [value]="singleCatValue.value"
            >
              {{ singleCatValue.title }}
            </mat-option>
          </ng-container>
        </mat-select>
      </ng-container>
      <ng-template #elseBlock>
        <input
          matInput
          [(ngModel)]="model"
          placeholder="Value"
          (keyup)="onWidgetModelChange()"
        />
      </ng-template>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'YEAR'">
      <input
        matInput
        [(ngModel)]="model"
        (ngModelChange)="onWidgetModelChange()"
        placeholder="Choose a year"
      />
      <input
        matInput
        style="display: none"
        [matDatepicker]="yearPicker"
        placeholder="Choose a year"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="yearPicker"
      ></mat-datepicker-toggle>
      <mat-datepicker
        startView="multi-year"
        (yearSelected)="onYearSelected($event, yearPicker)"
        #yearPicker
      ></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'TIME_PLAN'">
      <input
        matInput
        [(ngModel)]="model"
        placeholder="Value"
        (keyup)="onWidgetModelChange()"
      />
    </mat-form-field>

    <mat-form-field *ngSwitchDefault>
      <input
        matInput
        [(ngModel)]="model"
        placeholder="Value"
        (keyup)="onWidgetModelChange()"
      />
    </mat-form-field>
  </ng-container>
</div>
