/* tslint:disable */
/* eslint-disable */
import { FilePartTemporaryFileModel } from "./file-part-temporary-file-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqDemoDocumentSetsUploadFilesDtoModel")
export class OApiReqDemoDocumentSetsUploadFilesDtoModel {
  @JsonProperty("files", [FilePartTemporaryFileModel], true)
  files?: Array<FilePartTemporaryFileModel> = [];

  @JsonProperty("recordUid", String, true)
  recordUid?: string = "";
}
