import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FormElementComponent } from "./formelement.component";
import { FormComponent } from "./form.component";
import { WidgetChooserComponent } from "./widgetchooser.component";
import { ArrayWidgetComponent } from "./defaultwidgets/array/array.widget";
import { ButtonWidgetComponent } from "./defaultwidgets/button/button.widget";
import { ObjectWidgetComponent } from "./defaultwidgets/object/object.widget";
import { CheckboxWidgetComponent } from "./defaultwidgets/checkbox/checkbox.widget";
import { FileWidgetComponent } from "./defaultwidgets/file/file.widget";
import { IntegerWidgetComponent } from "./defaultwidgets/integer/integer.widget";
import {
  LocateSubfieldButtonTooltipPipe,
  TextAreaWidgetComponent,
} from "./defaultwidgets/textarea/textarea.widget";
import { RadioWidgetComponent } from "./defaultwidgets/radio/radio.widget";
import { RangeWidgetComponent } from "./defaultwidgets/range/range.widget";
import { SelectWidgetComponent } from "./defaultwidgets/select/select.widget";
import { StringWidgetComponent } from "./defaultwidgets/string/string.widget";
import { DefaultWidgetRegistry } from "./defaultwidgets/defaultwidgetregistry";
import { DefaultWidgetComponent } from "./default.widget";

import { WidgetRegistry } from "./widgetregistry";
import {
  SchemaValidatorFactory,
  ZSchemaValidatorFactory,
} from "./schemavalidatorfactory";
import { FormElementComponentActionComponent } from "./formelement.action.component";
import { MultiSelectDropdownWidgetComponent } from "./defaultwidgets/multi-select-dropdown/multi-select-dropdown.widget";
import { NgSelectModule } from "@ng-select/ng-select";
import { SchemaFormHelperService } from "./schema-form-helper.service";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { DatePickerWidgetComponent } from "./defaultwidgets/date-picker/date-picker.widget";
import { InputDropdownWidgetComponent } from "./defaultwidgets/input-dropdown/input-dropdown.widget";
// TODO circular dependency
import { CKEditorModule } from "@@intelease/web/ui/src/lib/ckeditor";
import { CkTextEditorWidgetComponent } from "./defaultwidgets/ckTextEditor/ck-text-editor.widget";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";

const moduleProviders = [
  SchemaFormHelperService,
  {
    provide: WidgetRegistry,
    useClass: DefaultWidgetRegistry,
  },
  {
    provide: SchemaValidatorFactory,
    useClass: ZSchemaValidatorFactory,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    CKEditorModule,
    CommonDirectivesModule,
    MatIconModule,
    MatRippleModule,
    ItlsCustomIconModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatFormFieldModule,
  ],
  declarations: [
    FormElementComponent,
    FormElementComponentActionComponent,
    FormComponent,
    WidgetChooserComponent,
    DefaultWidgetComponent,
    ArrayWidgetComponent,
    ButtonWidgetComponent,
    ObjectWidgetComponent,
    CheckboxWidgetComponent,
    FileWidgetComponent,
    IntegerWidgetComponent,
    TextAreaWidgetComponent,
    RadioWidgetComponent,
    RangeWidgetComponent,
    SelectWidgetComponent,
    StringWidgetComponent,
    MultiSelectDropdownWidgetComponent,
    InputDropdownWidgetComponent,
    DatePickerWidgetComponent,
    CkTextEditorWidgetComponent,
    LocateSubfieldButtonTooltipPipe,
  ],
  exports: [
    FormComponent,
    FormElementComponent,
    FormElementComponentActionComponent,
    WidgetChooserComponent,
    ArrayWidgetComponent,
    ButtonWidgetComponent,
    ObjectWidgetComponent,
    CheckboxWidgetComponent,
    FileWidgetComponent,
    IntegerWidgetComponent,
    TextAreaWidgetComponent,
    RadioWidgetComponent,
    RangeWidgetComponent,
    SelectWidgetComponent,
    StringWidgetComponent,
    MultiSelectDropdownWidgetComponent,
    InputDropdownWidgetComponent,
    DatePickerWidgetComponent,
  ],
  providers: [SchemaFormHelperService],
})
export class SchemaFormModule {
  static forRoot(): ModuleWithProviders<SchemaFormModule> {
    return {
      ngModule: SchemaFormModule,
      providers: [...moduleProviders],
    };
  }
}
