import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { hmrBootstrap } from "./hmr";
import { datadogRum } from "@datadog/browser-rum";

if (environment.production) {
  enableDatadogRum();
  // TODO(apoorv): Consider enabling Datadog logs (https://docs.datadoghq.com/logs/log_collection/javascript/#advanced-usage)
  enableProdMode();
}

function enableDatadogRum() {
  const datadogConfig = environment.datadog;
  datadogRum.init({
    applicationId: datadogConfig.applicationId,
    clientToken: datadogConfig.clientToken,
    site: datadogConfig.site,
    service: datadogConfig.service,
    env: datadogConfig.env,
    version: datadogConfig.version,
    sessionSampleRate: datadogConfig.sessionSampleRate,
    sessionReplaySampleRate: datadogConfig.sessionReplaySampleRate,
    trackUserInteractions: datadogConfig.trackUserInteractions,
    trackResources: datadogConfig.trackResources,
    trackLongTasks: datadogConfig.trackLongTasks,
    defaultPrivacyLevel: datadogConfig.defaultPrivacyLevel,
    allowedTracingUrls: datadogConfig.allowedTracingUrls,
    traceSampleRate: datadogConfig.traceSampleRate,
  });
}

const bootstrap = () =>
  platformBrowserDynamic()
    /*
            'ngZoneEventCoalescing' using this option will reduce unnecessary change detection
                  but sometimes reload the page will break the application
         */
    // .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
    .bootstrapModule(AppModule)
    .then((result) => {
      console.log(
        "%c%s",
        "color: #1cd5dc;text-shadow:1px 2px 2px #000;font-size: 70px;font-weight: bold;",
        "Intelease UI App"
      );
      console.log(
        "%c%s",
        "color: #ff0000;background-color:yellow ;font-size: 15px;font-weight: bold;",
        "WARNING!"
      );
      console.log(
        "%c%s",
        "color: #101010;font-size: 15px;font-weight: bold;",
        "Using this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.Do not enter or paste code that you do not understand."
      );
      return result;
    });

if (environment.hmr) {
  if (module["hot"]) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error("HMR is not enabled for webpack-dev-server!");
    console.warn("Are you using the --hmr flag for ng serve?");
  }
} else {
  bootstrap().catch((err) => console.error(err));
}
