/* tslint:disable */
/* eslint-disable */
import { MinimalProvisionGroupViewModel } from "./minimal-provision-group-view-model";
import { PaginationDataModel } from "./pagination-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiMultiEntityContainerResponseMinimalProvisionGroupViewModel")
export class OApiMultiEntityContainerResponseMinimalProvisionGroupViewModel {
  @JsonProperty("items", [MinimalProvisionGroupViewModel], true)
  items?: Array<MinimalProvisionGroupViewModel> = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
