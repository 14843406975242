import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { ListResponseModel } from "../../../../models";
import { FetchBatchApiService } from "../../../index";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { SortDirectionEnum } from "@@intelease/web/common/enums/sort-direction.enum";

@Injectable({
  providedIn: "root",
})
export class FetchBatchProvisionService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly PROVISIONS_URL = "/provisions";

  constructor(private fetchBatchApiService: FetchBatchApiService) {}

  /**
   * Get the list of provisions to be shown for this abstract.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @return the list of provisions for this abstract
   */
  getProvisionsByAbstractUid<T extends object>(
    abstractUid: string,
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    return this.fetchBatchApiService.sendBatchRequest(
      FetchBatchProvisionService.API_VERSION_1,
      `${FetchBatchProvisionService.FINAL_DOC_SETS_URL}/${abstractUid}${FetchBatchProvisionService.PROVISIONS_URL}`,
      view,
      classRef,
      "name::Name::" +
        PROVISIONS_DATA_CONST.STRING.name +
        "::" +
        SortDirectionEnum.ASC
    );
  }

  getPossibleProvisions<T extends object>(
    view: string,
    docSetCategory: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    const keyValues = [];
    keyValues.push({ key: "docSetCategory", value: docSetCategory });
    keyValues.push({ key: "includeUserProvisions", value: false });
    keyValues.push({ key: "activesOnly", value: true });
    return this.fetchBatchApiService.sendBatchRequest(
      FetchBatchProvisionService.API_VERSION_1,
      FetchBatchProvisionService.PROVISIONS_URL,
      view,
      classRef,
      "name::Name::" +
        PROVISIONS_DATA_CONST.STRING.name +
        "::" +
        SortDirectionEnum.ASC,
      undefined,
      keyValues
    );
  }
}
