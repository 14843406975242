/* tslint:disable */
/* eslint-disable */
import { BoundingBoxModel } from "./bounding-box-model";
import { JsonNodeModel } from "./json-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ParseHighlightSaveMentionApiV2DtoModel")
export class ParseHighlightSaveMentionApiV2DtoModel {
  @JsonProperty("boundingBox", BoundingBoxModel, true)
  boundingBox?: BoundingBoxModel = undefined;

  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  @JsonProperty("firstPage", Number, true)
  firstPage?: number = undefined;

  @JsonProperty("highlightType", Any, true)
  highlightType?: "TEXT" | "AREA" = undefined;

  @JsonProperty("justPrimaryFormat", Boolean, true)
  justPrimaryFormat?: boolean = undefined;

  @JsonProperty("lastPage", Number, true)
  lastPage?: number = undefined;

  @JsonProperty("mentionUid", String, true)
  mentionUid?: string = "";

  @JsonProperty("numHead", Number, true)
  numHead?: number = undefined;

  @JsonProperty("pdfProvisionHighlights", [JsonNodeModel], true)
  pdfProvisionHighlights?: Array<JsonNodeModel> = [];

  @JsonProperty("postMentionUid", String, true)
  postMentionUid?: string = "";

  @JsonProperty("preMentionUid", String, true)
  preMentionUid?: string = "";

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";

  @JsonProperty("subfieldKey", String, true)
  subfieldKey?: string = "";

  @JsonProperty("text", String, true)
  text?: string = "";
}
