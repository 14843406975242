export const APP_ENUMS_CONST = {
  DOCUMENT_STATUS: {
    UPLOADING: "Uploading",
    QUEUED: "Queued",
    PROCESSING: "Processing",
    ERROR: "Error",
    PROCESSING_COMPLETED: "Ready",
    UNDER_REVIEW: "Under Review",
    COMPLETED: "Completed",
    UI_DELETED: "Deleted",
    ERROR_NO_RETRY: "Error",
    READY: "Ready",
    IN_REVIEW: "In Review",
    IN_QA_REVIEW: "In QA Review",
    FAILED: "Error",
  },
  ABSTRACT_STATUS: {
    PROCESSING_COMPLETED: "Ready",
    UNDER_REVIEW: "Under Review",
    COMPLETED: "Completed",
  },
  TASK_PRIORITY: {
    LOW: "Low",
    MEDIUM: "Medium",
    HIGH: "High",
  },
  TASK_STATUS: {
    NOT_STARTED: "Not Started",
    IN_PROGRESS: "In Progress",
    BLOCKED: "Blocked",
    DEFERRED: "Deferred",
    COMPLETED: "Completed",
  },
  TASK_TYPE: {
    REVIEW: "Review",
    APPROVE: "Approve",
    OTHER: "Other",
  },
};
