import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SchemaFormModule } from "@@intelease/schema-form";
import { ProgressModule } from "@@intelease/web/intelease/components";
import { AbstractExportModalComponent } from "./abstract-export-modal.component";
import { AbstractExportModalService } from "./abstract-export-modal.service";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { ItlsExportModule } from "@@intelease/web/ui";

const INTELEASE_MODULES = [ProgressModule.forRoot()];

const MATERIAL_MODULES = [MatDialogModule, MatButtonModule];

@NgModule({
  declarations: [AbstractExportModalComponent],
  imports: [
    CommonModule,
    SchemaFormModule.forRoot(),
    ...INTELEASE_MODULES,
    ...MATERIAL_MODULES,
    ItlsExportModule,
  ],
  providers: [AbstractExportModalService],
  exports: [AbstractExportModalComponent],
})
export class AbstractExportModalModule {}
