/* tslint:disable */
/* eslint-disable */
import { IngestDocSetIntoQueueResponseDtoModel } from "./ingest-doc-set-into-queue-response-dto-model";
import { UserDtoModel } from "./user-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UploadDocSetFilesResDtoModel")
export class UploadDocSetFilesResDtoModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("directoryUid", String, true)
  directoryUid?: string = "";

  @JsonProperty("documentCount", Number, true)
  documentCount?: number = undefined;

  @JsonProperty("googleDriveFileId", String, true)
  googleDriveFileId?: string = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("owner", UserDtoModel, true)
  owner?: UserDtoModel = undefined;

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];

  @JsonProperty(
    "queueIngestionResult",
    IngestDocSetIntoQueueResponseDtoModel,
    true
  )
  queueIngestionResult?: IngestDocSetIntoQueueResponseDtoModel = undefined;

  @JsonProperty("queueSelected", Boolean, true)
  queueSelected?: boolean = undefined;

  @JsonProperty("shared", Boolean, true)
  shared?: boolean = undefined;

  @JsonProperty("size", Number, true)
  size?: number = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
