<div
  style="
    color: #505f79;
    font-size: 11px;
    margin-bottom: 6px;
    text-align: justify;
    position: relative;
  "
>
  <ng-container *ngIf="!snippet.isExpanded && type === 'OLD_PROVISION_BOX'"
    >{{ snippet.getViewValueByProvisionBoxWidth(provisionBoxWidth) }}
  </ng-container>
  <ng-container *ngIf="!snippet.isExpanded && type === 'PROVISION_BOX'">
    <span [innerHTML]="snippet.longRichText | shorten: 500:'...'"></span>
  </ng-container>
  <ng-container *ngIf="snippet.isExpanded">
    <span [innerHTML]="snippet.longRichText"></span>
  </ng-container>
  <i
    *ngIf="
      !snippet.isExpanded &&
      type === 'OLD_PROVISION_BOX' &&
      snippet.getViewValueByProvisionBoxWidth(provisionBoxWidth) &&
      snippet.getViewValueByProvisionBoxWidth(provisionBoxWidth).length <
        snippet.getFullLongValue().length
    "
    style="
      cursor: pointer;
      font-size: 14px;
      margin-left: 8px;
      position: absolute;
      bottom: -8px;
    "
    matTooltip="Show More"
    (click)="snippet.isExpanded = true"
  >
    <mat-icon svgIcon="icon-arrow-down"></mat-icon>
  </i>
  <i
    *ngIf="
      !snippet.isExpanded &&
      type === 'PROVISION_BOX' &&
      snippet.longRichText.length > 500
    "
    style="
      cursor: pointer;
      font-size: 14px;
      margin-left: 8px;
      position: absolute;
      bottom: -8px;
    "
    matTooltip="Show More"
    (click)="snippet.isExpanded = true"
  >
    <mat-icon svgIcon="icon-arrow-down"></mat-icon>
  </i>
  <i
    *ngIf="snippet.isExpanded"
    style="
      cursor: pointer;
      font-size: 14px;
      margin-left: 8px;
      position: absolute;
      bottom: -10px;
    "
    matTooltip="Show Less"
    (click)="snippet.isExpanded = false"
  >
    <mat-icon
      svgIcon="icon-arrow-down"
      style="transform: rotate(180deg)"
    ></mat-icon>
  </i>
</div>
