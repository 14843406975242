<div class="provision">
  <div class="loading" *ngIf="isLoading">
    <mat-progress-spinner
      diameter="50"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <div
    class="mentions-container"
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <il-mention
      *ngFor="let mention of provision?.multiplePdfProvision?.options"
      [mention]="mention"
      style="min-width: 79%"
      [selectedAbstractUid]="selectedAbstractUid"
      [provision]="provision"
      [selectedDocument]="selectedDocument"
      [abstractDocument]="abstractDocument"
      [provisionBoxInitTypes]="provisionBoxInitTypes"
      [hidden]="!(mention?.uid === selectedMention?.uid)"
      [selectedMention]="selectedMention"
      (reloadProvisionData)="onMentionReloadProvisionData($event)"
      (mentionCreated)="onMentionCreated()"
      (toggleProvisionLoading)="toggleProvisionLoading($event)"
      (gotoMentionHighlightOnViewer)="onGotoMentionHighlightOnViewer($event)"
      [editable]="editable"
    >
    </il-mention>
    <div
      style="padding: 20px 4px; font-size: 12px"
      class="no-mention"
      *ngIf="!provision?.multiplePdfProvision?.options.length"
    >
      No Mention Found.
    </div>
    <div
      style="border-left: 1px solid #dfe1e6; padding-left: 8px"
      [style.minHeight]="
        provision.provisionInfo.type === 'ADDRESS' ||
        provision.provisionInfo.type === 'TABLE'
          ? '200px'
          : '116px'
      "
    >
      <div
        cdkDropList
        class="example-list"
        dataCy="cyMentionTagList"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="mention-title"
          *ngFor="
            let mention of provision?.multiplePdfProvision?.options;
            let mentionIndex = index
          "
          dataCy="cyMentionTagItem"
          (click)="onChangeSelectedMentionClick(mention)"
          matRipple
          [ngClass]="{
            active: mention?.uid === selectedMention?.uid
          }"
          cdkDrag
        >
          <span
            *ngIf="
              mention?.source !== 'CALCULATED' &&
              mention?.source !== 'CALCULATED_MODIFY' &&
              mention?.source !== 'TEMP_NEW_TAB' &&
              mention?.source !== 'CUSTOMER' &&
              mention?.source !== 'DERIVED' &&
              mention?.source !== 'CUSTOMER_DERIVED' &&
              mention?.source !== 'DERIVED_MODIFY' &&
              !mention?.isTempHighlight
            "
          >
            {{
              documentsShortNameMap[mention?.docAbstractUid]?.shortLabel +
                ".P" +
                mention?.page
            }}
          </span>
          <span *ngIf="mention?.source === 'CUSTOMER'">Manual </span>
          <span
            *ngIf="
              mention?.source === 'DERIVED' ||
              mention?.source === 'CUSTOMER_DERIVED' ||
              mention?.source === 'DERIVED_MODIFY'
            "
            >Derived
          </span>
          <span
            *ngIf="
              mention?.source === 'CALCULATED' ||
              mention?.source === 'CALCULATED_MODIFY'
            "
            >Calculated
          </span>
          <span dataCy="cyAddMention" *ngIf="mention?.source === 'TEMP_NEW_TAB'"
            >New
          </span>
          <span
            *ngIf="
              mention?.source === 'CUSTOMER_HIGHLIGHTED' &&
              mention?.isTempHighlight
            "
            >Temp
          </span>
          <ng-container *ngxPermissionsOnly="AUTH.UPDATE_RECORD">
            <mat-icon
              svgIcon="icon-cancel"
              style="margin-left: 4px; cursor: pointer"
              dataCy="cyRemoveMentionBtn"
              matTooltip="Remove"
              *ngIf="
                editable &&
                !(
                  mention?.source === 'TEMP_NEW_TAB' &&
                  provision?.multiplePdfProvision?.options.length === 1
                )
              "
              (click)="
                onRemoveMentionClick(
                  $event,
                  mentionIndex,
                  mention?.uid,
                  mention?.source,
                  mention?.isTempHighlight
                )
              "
            ></mat-icon>
          </ng-container>
        </div>
      </div>
      <ng-container *ngxPermissionsOnly="AUTH.UPDATE_RECORD">
        <div
          class="mention-title add-mention"
          title="Add"
          matRipple
          dataCy="cyAddMention"
          *ngIf="editable && !isTempMentionAdded()"
          (click)="onAddMentionClick()"
        >
          <mat-icon svgIcon="icon-add"></mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
  <mat-divider [hidden]="isMinimized" style="margin-bottom: 12px"></mat-divider>
  <ng-container *ngIf="!isMinimized">
    <nav mat-tab-nav-bar *ngIf="selectedProvisionTab">
      <a
        mat-tab-link
        *ngFor="let provisionTab of provisionTabs"
        (click)="onSelectedProvisionTabChange(provisionTab)"
        class="provision-tab"
        [ngClass]="{
          active: provisionTab.key === selectedProvisionTab.key
        }"
        [active]="provisionTab.key === selectedProvisionTab.key"
      >
        {{ provisionTab.title }}
      </a>
    </nav>
    <div class="provision-tabs-container">
      <ng-container *ngIf="selectedProvisionTab.key === 'DOCUMENT_MENTIONS'">
        <il-provision-mentions
          [documentsShortNameMap]="documentsShortNameMap"
          [provisionBoxInitTypes]="provisionBoxInitTypes"
          [snippets]="provision?.multiplePdfProvision?.snippets"
          (pickSnippet)="onPickSnippetClick($event)"
          (gotoMention)="onGotoMentionByUid($event)"
          (gotoMentionHighlightOnViewer)="
            onGotoMentionHighlightOnViewer($event, true)
          "
          [editable]="editable"
        >
        </il-provision-mentions>
      </ng-container>
      <ng-container *ngIf="selectedProvisionTab.key === 'COMMENTS'">
        <il-comment
          [maxHeight]="'100%'"
          [comments]="this.abstractReviewFacade.getProvisionComments$ | async"
          [isLoading]="
            abstractReviewFacade.isLoadProvisionCommentsLoading$ | async
          "
          [isSaveLoading]="
            abstractReviewFacade.isCreateProvisionCommentLoading$ | async
          "
          (save)="onSaveProvisionCommentClick($event)"
          (deleteEvent)="onDeleteProvisionCommentClick($event)"
          (editEvent)="onEditProvisionCommentClick($event)"
          [canAddNewComment]="canComment"
          [accountUsers]="accountUsers"
        >
        </il-comment>
      </ng-container>
      <ng-container *ngIf="selectedProvisionTab.key === 'NOTES'">
        <il-provision-notes
          [note]="provision?.multiplePdfProvision?.notes"
          [isLoading]="
            abstractReviewFacade.isUpdateProvisionNotePending$ | async
          "
          (save)="onSaveProvisionNoteClick($event)"
          [editable]="editable"
        >
        </il-provision-notes>
      </ng-container>
    </div>
  </ng-container>
</div>
