/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiRespFullFinalAbstractTaskDtoModel } from "../models/o-api-resp-full-final-abstract-task-dto-model";
import { OApiRespMinimalFinalAbstractTaskDtoModel } from "../models/o-api-resp-minimal-final-abstract-task-dto-model";
import { OApiRespMultiFullFinalAbstractTaskDtoModel } from "../models/o-api-resp-multi-full-final-abstract-task-dto-model";
import { OApiRespMultiMinimalFinalAbstractTaskDtoModel } from "../models/o-api-resp-multi-minimal-final-abstract-task-dto-model";
import { OApiRespSingleFinalAbstractTaskSummaryDtoModel } from "../models/o-api-resp-single-final-abstract-task-summary-dto-model";
import { OApiSuccessResponseModel } from "../models/o-api-success-response-model";

@Injectable({
  providedIn: "root",
})
export class RelationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAbstractTasks
   */
  static readonly GetAbstractTasksPath =
    "/api/v1/finalDocSets/{recordUid}/tasks";

  /**
   * Get record tasks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbstractTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbstractTasks$Response(params: {
    view: "NONE" | "MINIMAL" | "FULL";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiMinimalFinalAbstractTaskDtoModel
      | OApiRespMultiFullFinalAbstractTaskDtoModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RelationService.GetAbstractTasksPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiMinimalFinalAbstractTaskDtoModel
            | OApiRespMultiFullFinalAbstractTaskDtoModel
          >;
        })
      );
  }

  /**
   * Get record tasks.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAbstractTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbstractTasks(params: {
    view: "NONE" | "MINIMAL" | "FULL";
  }): Observable<
    | OApiRespMultiMinimalFinalAbstractTaskDtoModel
    | OApiRespMultiFullFinalAbstractTaskDtoModel
  > {
    return this.getAbstractTasks$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiMinimalFinalAbstractTaskDtoModel
            | OApiRespMultiFullFinalAbstractTaskDtoModel
          >
        ) =>
          r.body as
            | OApiRespMultiMinimalFinalAbstractTaskDtoModel
            | OApiRespMultiFullFinalAbstractTaskDtoModel
      )
    );
  }

  /**
   * Path part for operation getUserTasks
   */
  static readonly GetUserTasksPath = "/api/v1/users/current/tasks";

  /**
   * Get user tasks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserTasks$Response(params: {
    view: "NONE" | "MINIMAL" | "FULL";
    page?: number;
    perPage?: number;
    sort?: string;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiMinimalFinalAbstractTaskDtoModel
      | OApiRespMultiFullFinalAbstractTaskDtoModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RelationService.GetUserTasksPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
      rb.query("page", params.page, {});
      rb.query("perPage", params.perPage, {});
      rb.query("sort", params.sort, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiMinimalFinalAbstractTaskDtoModel
            | OApiRespMultiFullFinalAbstractTaskDtoModel
          >;
        })
      );
  }

  /**
   * Get user tasks.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserTasks(params: {
    view: "NONE" | "MINIMAL" | "FULL";
    page?: number;
    perPage?: number;
    sort?: string;
  }): Observable<
    | OApiRespMultiMinimalFinalAbstractTaskDtoModel
    | OApiRespMultiFullFinalAbstractTaskDtoModel
  > {
    return this.getUserTasks$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiMinimalFinalAbstractTaskDtoModel
            | OApiRespMultiFullFinalAbstractTaskDtoModel
          >
        ) =>
          r.body as
            | OApiRespMultiMinimalFinalAbstractTaskDtoModel
            | OApiRespMultiFullFinalAbstractTaskDtoModel
      )
    );
  }

  /**
   * Path part for operation getUserTasksSummary
   */
  static readonly GetUserTasksSummaryPath =
    "/api/v1/users/current/tasks/summary";

  /**
   * Get user tasks frequency for each task status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserTasksSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserTasksSummary$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespSingleFinalAbstractTaskSummaryDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RelationService.GetUserTasksSummaryPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespSingleFinalAbstractTaskSummaryDtoModel>;
        })
      );
  }

  /**
   * Get user tasks frequency for each task status.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserTasksSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserTasksSummary(params?: {}): Observable<OApiRespSingleFinalAbstractTaskSummaryDtoModel> {
    return this.getUserTasksSummary$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<OApiRespSingleFinalAbstractTaskSummaryDtoModel>
        ) => r.body as OApiRespSingleFinalAbstractTaskSummaryDtoModel
      )
    );
  }

  /**
   * Path part for operation getTask
   */
  static readonly GetTaskPath = "/api/v1/finalDocSets/tasks/{taskUid}";

  /**
   * Get a task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTask$Response(params: {
    view: "NONE" | "MINIMAL" | "FULL";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMinimalFinalAbstractTaskDtoModel
      | OApiRespFullFinalAbstractTaskDtoModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      RelationService.GetTaskPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMinimalFinalAbstractTaskDtoModel
            | OApiRespFullFinalAbstractTaskDtoModel
          >;
        })
      );
  }

  /**
   * Get a task.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTask(params: {
    view: "NONE" | "MINIMAL" | "FULL";
  }): Observable<
    | OApiRespMinimalFinalAbstractTaskDtoModel
    | OApiRespFullFinalAbstractTaskDtoModel
  > {
    return this.getTask$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMinimalFinalAbstractTaskDtoModel
            | OApiRespFullFinalAbstractTaskDtoModel
          >
        ) =>
          r.body as
            | OApiRespMinimalFinalAbstractTaskDtoModel
            | OApiRespFullFinalAbstractTaskDtoModel
      )
    );
  }

  /**
   * Path part for operation deleteTask
   */
  static readonly DeleteTaskPath = "/api/v1/finalDocSets/tasks/{taskUid}";

  /**
   * Delete a task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTask$Response(params: {
    /**
     * The unique id of the task to be deleted.
     */
    taskUid: string;
  }): Observable<StrictHttpResponse<OApiSuccessResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RelationService.DeleteTaskPath,
      "delete"
    );
    if (params) {
      rb.path("taskUid", params.taskUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSuccessResponseModel>;
        })
      );
  }

  /**
   * Delete a task.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTask(params: {
    /**
     * The unique id of the task to be deleted.
     */
    taskUid: string;
  }): Observable<OApiSuccessResponseModel> {
    return this.deleteTask$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSuccessResponseModel>) =>
          r.body as OApiSuccessResponseModel
      )
    );
  }
}
