/* tslint:disable */
/* eslint-disable */
import { DocumentUploadDtoModel } from "./document-upload-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocumentsUploadDtoModel")
export class DocumentsUploadDtoModel {
  @JsonProperty("directoryUid", String, true)
  directoryUid?: string = "";

  @JsonProperty("documents", [DocumentUploadDtoModel], true)
  documents?: Array<DocumentUploadDtoModel> = [];
}
