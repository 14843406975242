import { JsonObject, JsonProperty } from "json2typescript";
import { MinimalProvisionModel } from "./minimal-provision.model";

@JsonObject("MinimalProvisionCategoryModel")
export class MinimalProvisionCategoryModel {
  static view = "minimal";

  @JsonProperty("categoryName", String)
  categoryName = "";

  @JsonProperty("provisions", [MinimalProvisionModel])
  provisions: Array<MinimalProvisionModel> = [];
}
