/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateDirectoryDtoModel")
export class CreateDirectoryDtoModel {
  @JsonProperty("category", Any, true)
  category?: "OWNED" | "DELETED" | "GOOGLE_DRIVE" = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("parentUid", String, true)
  parentUid?: string = "";
}
