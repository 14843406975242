import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("CommentUserMentionModel")
export class CommentUserMentionModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("email", String)
  email = "";

  @JsonProperty("fullName", String)
  fullName = "";
}
