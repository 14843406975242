/* tslint:disable */
/* eslint-disable */
import { ChatMessageModel } from "./chat-message-model";
import { ObjectIdModel } from "./object-id-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ChatGptConfigModel")
export class ChatGptConfigModel {
  @JsonProperty("&#x27;_id&#x27;", ObjectIdModel, true)
  "_id"?: ObjectIdModel = undefined;

  @JsonProperty("constantRequestMessages", [ChatMessageModel], true)
  constantRequestMessages?: Array<ChatMessageModel> = [];

  @JsonProperty("fixJsonViaChatGpt", Boolean, true)
  fixJsonViaChatGpt?: boolean = undefined;

  @JsonProperty("gpt35Engine", Any, true)
  gpt35Engine?:
    | "GPT_35_TURBO"
    | "GPT_35_TURBO_0613"
    | "GPT_35_TURBO_16K"
    | "GPT_35_TURBO_16K_0613"
    | "TEXT_DAVINCI_003"
    | "TEXT_DAVINCI_002"
    | "CODE_DAVINCI_002" = undefined;

  @JsonProperty("gpt4Engine", Any, true)
  gpt4Engine?: "GPT_4" | "GPT_4_0613" | "GPT_4_32K" | "GPT_4_32K_0613" =
    undefined;

  @JsonProperty("maxNumContinueResponses", Number, true)
  maxNumContinueResponses?: number = undefined;

  @JsonProperty("maxRequestTokens", Number, true)
  maxRequestTokens?: number = undefined;

  @JsonProperty("minConfidenceThreshold", Number, true)
  minConfidenceThreshold?: number = undefined;

  @JsonProperty("useGpt4", Boolean, true)
  useGpt4?: boolean = undefined;
}
