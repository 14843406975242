import { JsonObject, JsonProperty } from "json2typescript";
import { PartialProvisionValueModel } from "@@intelease/web/common/models/provision-value/partial-provision-value.model";

@JsonObject("FullMultiProvisionValueModel")
export class FullMultiProvisionValueModel {
  static view = "full";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("provisionUid", String)
  provisionUid = "";

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("type", String)
  type = "";

  @JsonProperty("options", [PartialProvisionValueModel])
  options: PartialProvisionValueModel[] = []; // ordered from best to worst
}
