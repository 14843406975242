/* tslint:disable */
/* eslint-disable */
import { ReportAggGroupRequestModel } from "./report-agg-group-request-model";
import { ReportAggOperationRequestModel } from "./report-agg-operation-request-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ReportAggRequestModel")
export class ReportAggRequestModel {
  @JsonProperty("groups", [ReportAggGroupRequestModel], true)
  groups?: Array<ReportAggGroupRequestModel> = [];

  @JsonProperty("operations", [ReportAggOperationRequestModel], true)
  operations?: Array<ReportAggOperationRequestModel> = [];
}
