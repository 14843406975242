<div class="edit-calendar-container">
  <h4 mat-dialog-title class="mb-2">
    Edit Filter
    <button
      matTooltip="Close"
      mat-icon-button
      mat-dialog-close
      class="filter-close-button"
    >
      <mat-icon svgIcon="icon-cancel"></mat-icon>
    </button>
  </h4>
  <div mat-dialog-content style="padding: 0 !important; margin-bottom: -20px">
    <div class="calendar-filter-menu">
      <intelease-calendar-filter
        [calendarUid]="calendarUid"
        [mode]="mode.SHARE"
        [isEditing]="true"
        [model]="model"
        (updated)="onFilterQueryUpdated()"
      >
      </intelease-calendar-filter>
    </div>
  </div>
</div>
