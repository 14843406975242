import { CreateBatchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { ListResponseModel } from "@@intelease/web/intelease/models";
import { Observable } from "rxjs";
import { cloneDeep } from "lodash";
import { ActorModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/actor.model";
import { DateConvertService } from "../../../../../common/services";

@Injectable({
  providedIn: "root",
})
export class CreateBatchTaskService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly USERS_URL = "/users";
  private static readonly TASKS_URL = "/tasks";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(private createBatchApiService: CreateBatchApiService) {}

  createAllTasks<T extends object>(
    assignee: ActorModel,
    task: any,
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    if (task.dueDate) {
      task.dueDate = DateConvertService.serializeDate(task["dueDate"]);
    }
    return this.createBatchApiService.sendAllRequest(
      CreateBatchTaskService.API_VERSION_1,
      `${CreateBatchTaskService.FINAL_DOC_SETS_URL}${CreateBatchTaskService.TASKS_URL}`,
      task,
      view,
      classRef
    );
  }

  createTasks<T extends object>(
    assignee: ActorModel,
    abstractUids: string[],
    task: any,
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    if (task.dueDate) {
      task.dueDate = DateConvertService.serializeDate(task["dueDate"]);
    }
    return this.createBatchApiService.sendBatchRequest(
      CreateBatchTaskService.API_VERSION_1,
      `${CreateBatchTaskService.FINAL_DOC_SETS_URL}${CreateBatchTaskService.TASKS_URL}`,
      this.duplicateTasks(task, abstractUids),
      view,
      classRef
    );
  }

  private duplicateTasks(task: any, abstractUids: string[]): any[] {
    const tasks = [];
    for (const abstractUid of abstractUids) {
      const copiedTask = cloneDeep(task);
      copiedTask.objectUid = abstractUid;
      tasks.push(copiedTask);
    }
    return tasks;
  }
}
