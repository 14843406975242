/* tslint:disable */
/* eslint-disable */

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CancelUploadingFilesResponseDtoModel")
export class CancelUploadingFilesResponseDtoModel {
  @JsonProperty("recordUids", [String], true)
  recordUids?: Array<string> = [];
}
