/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqEditCommentDtoModel } from "../models/o-api-req-edit-comment-dto-model";
import { OApiReqEditNoteDtoModel } from "../models/o-api-req-edit-note-dto-model";
import { OApiReqEditNoteV3DtoModel } from "../models/o-api-req-edit-note-v-3-dto-model";
import { OApiReqEditReviewStatusDtoModel } from "../models/o-api-req-edit-review-status-dto-model";
import { OApiReqEditReviewStatusV2DtoModel } from "../models/o-api-req-edit-review-status-v-2-dto-model";
import { OApiReqModifyNestedProvisionSubfieldsDtoModel } from "../models/o-api-req-modify-nested-provision-subfields-dto-model";
import { OApiResEditNoteDtoModel } from "../models/o-api-res-edit-note-dto-model";
import { OApiResEditNoteV3DtoModel } from "../models/o-api-res-edit-note-v-3-dto-model";
import { OApiRespDocumentCommentViewModel } from "../models/o-api-resp-document-comment-view-model";
import { OApiRespFullCommentViewModel } from "../models/o-api-resp-full-comment-view-model";
import { OApiRespFullMultiplePdfProvisionViewModel } from "../models/o-api-resp-full-multiple-pdf-provision-view-model";
import { OApiRespFullProvisionViewModel } from "../models/o-api-resp-full-provision-view-model";
import { OApiRespFullValMultiPdfProvViewModel } from "../models/o-api-resp-full-val-multi-pdf-prov-view-model";
import { OApiRespFullValMultiplePdfProvisionViewModel } from "../models/o-api-resp-full-val-multiple-pdf-provision-view-model";
import { OApiRespMinimalCommentViewModel } from "../models/o-api-resp-minimal-comment-view-model";
import { OApiRespMinimalProvisionViewModel } from "../models/o-api-resp-minimal-provision-view-model";
import { OApiRespMultiDocumentCommentViewModel } from "../models/o-api-resp-multi-document-comment-view-model";
import { OApiRespMultiFullCommentViewModel } from "../models/o-api-resp-multi-full-comment-view-model";
import { OApiRespMultiMinimalCommentViewModel } from "../models/o-api-resp-multi-minimal-comment-view-model";
import { OApiRespSearchMultiplePdfProvisionViewModel } from "../models/o-api-resp-search-multiple-pdf-provision-view-model";
import { OApiSingleEntityNoDataResponseModel } from "../models/o-api-single-entity-no-data-response-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class MultiProvisionValueService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getComments
   */
  static readonly GetCommentsPath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/comments";

  /**
   * Get list of comments for the provision specified by the request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComments$Response(params: {
    view: "FULL" | "MINIMAL" | "DOCUMENT" | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiFullCommentViewModel
      | OApiRespMultiMinimalCommentViewModel
      | OApiRespMultiDocumentCommentViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.GetCommentsPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiFullCommentViewModel
            | OApiRespMultiMinimalCommentViewModel
            | OApiRespMultiDocumentCommentViewModel
          >;
        })
      );
  }

  /**
   * Get list of comments for the provision specified by the request.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComments(params: {
    view: "FULL" | "MINIMAL" | "DOCUMENT" | "NONE";
  }): Observable<
    | OApiRespMultiFullCommentViewModel
    | OApiRespMultiMinimalCommentViewModel
    | OApiRespMultiDocumentCommentViewModel
  > {
    return this.getComments$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiFullCommentViewModel
            | OApiRespMultiMinimalCommentViewModel
            | OApiRespMultiDocumentCommentViewModel
          >
        ) =>
          r.body as
            | OApiRespMultiFullCommentViewModel
            | OApiRespMultiMinimalCommentViewModel
            | OApiRespMultiDocumentCommentViewModel
      )
    );
  }

  /**
   * Path part for operation createComment
   */
  static readonly CreateCommentPath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/comments";

  /**
   * Create a comment for this provision for this abstract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createComment()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  createComment$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.CreateCommentPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Create a comment for this provision for this abstract.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createComment$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  createComment(params?: {
    body?: RequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.createComment$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation getComment
   */
  static readonly GetCommentPath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/comments/{commentUid}";

  /**
   * Get the comment of the provision specified by the request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComment$Response(params: {
    view: "FULL" | "MINIMAL" | "DOCUMENT" | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullCommentViewModel
      | OApiRespMinimalCommentViewModel
      | OApiRespDocumentCommentViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.GetCommentPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullCommentViewModel
            | OApiRespMinimalCommentViewModel
            | OApiRespDocumentCommentViewModel
          >;
        })
      );
  }

  /**
   * Get the comment of the provision specified by the request.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComment(params: {
    view: "FULL" | "MINIMAL" | "DOCUMENT" | "NONE";
  }): Observable<
    | OApiRespFullCommentViewModel
    | OApiRespMinimalCommentViewModel
    | OApiRespDocumentCommentViewModel
  > {
    return this.getComment$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullCommentViewModel
            | OApiRespMinimalCommentViewModel
            | OApiRespDocumentCommentViewModel
          >
        ) =>
          r.body as
            | OApiRespFullCommentViewModel
            | OApiRespMinimalCommentViewModel
            | OApiRespDocumentCommentViewModel
      )
    );
  }

  /**
   * Path part for operation editComment
   */
  static readonly EditCommentPath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/comments/{commentUid}";

  /**
   * Edit the review status for this provision for this record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editComment$Response(params: {
    body: OApiReqEditCommentDtoModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.EditCommentPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Edit the review status for this provision for this record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editComment(params: {
    body: OApiReqEditCommentDtoModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.editComment$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation deleteComment
   */
  static readonly DeleteCommentPath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/comments/{commentUid}";

  /**
   * Delete a comment for this provision for this abstract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteComment$Response(params?: {}): Observable<
    StrictHttpResponse<OApiSingleEntityNoDataResponseModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.DeleteCommentPath,
      "delete"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Delete a comment for this provision for this abstract.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteComment(params?: {}): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.deleteComment$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation editProvisionNotes
   */
  static readonly EditProvisionNotesPath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/notes";

  /**
   * Change notes of the multi-provision value for this abstract for this provision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProvisionNotes()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editProvisionNotes$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.EditProvisionNotesPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >;
        })
      );
  }

  /**
   * Change notes of the multi-provision value for this abstract for this provision.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProvisionNotes$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editProvisionNotes(params?: {
    body?: RequestModel;
  }): Observable<
    OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
  > {
    return this.editProvisionNotes$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionViewModel
            | OApiRespMinimalProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation editProvisionNotesV2
   */
  static readonly EditProvisionNotesV2Path =
    "/api/v2/records/{recordUid}/provisions/{provisionUid}/notes";

  /**
   * Change notes of the multi-provision value for this abstract for this provision.
   *
   * If it succeeds, it returns status 200 and a view of the edited notes in provision.If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProvisionNotesV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionNotesV2$Response(params: {
    /**
     * The unique id of the record.
     */
    recordUid: string;

    /**
     * The unique id of the provision.
     */
    provisionUid: string;

    /**
     * The data provided by the user, for adding note.
     */
    body: OApiReqEditNoteDtoModel;
  }): Observable<StrictHttpResponse<OApiResEditNoteDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.EditProvisionNotesV2Path,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiResEditNoteDtoModel>;
        })
      );
  }

  /**
   * Change notes of the multi-provision value for this abstract for this provision.
   *
   * If it succeeds, it returns status 200 and a view of the edited notes in provision.If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProvisionNotesV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionNotesV2(params: {
    /**
     * The unique id of the record.
     */
    recordUid: string;

    /**
     * The unique id of the provision.
     */
    provisionUid: string;

    /**
     * The data provided by the user, for adding note.
     */
    body: OApiReqEditNoteDtoModel;
  }): Observable<OApiResEditNoteDtoModel> {
    return this.editProvisionNotesV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiResEditNoteDtoModel>) =>
          r.body as OApiResEditNoteDtoModel
      )
    );
  }

  /**
   * Path part for operation editProvisionNotesV3
   */
  static readonly EditProvisionNotesV3Path =
    "/api/v3/records/{recordUid}/provisions/{provisionUid}/notes";

  /**
   * Change notes of the multi-provision value for this abstract for this provision mention.
   *
   * If it succeeds, it returns status 200 and a view of the edited notes in provision mention.If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProvisionNotesV3()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionNotesV3$Response(params: {
    /**
     * The unique id of the record.
     */
    recordUid: string;

    /**
     * The unique id of the provision.
     */
    provisionUid: string;

    /**
     * The data provided by the user, for adding note.
     */
    body: OApiReqEditNoteV3DtoModel;
  }): Observable<StrictHttpResponse<OApiResEditNoteV3DtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.EditProvisionNotesV3Path,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiResEditNoteV3DtoModel>;
        })
      );
  }

  /**
   * Change notes of the multi-provision value for this abstract for this provision mention.
   *
   * If it succeeds, it returns status 200 and a view of the edited notes in provision mention.If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProvisionNotesV3$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionNotesV3(params: {
    /**
     * The unique id of the record.
     */
    recordUid: string;

    /**
     * The unique id of the provision.
     */
    provisionUid: string;

    /**
     * The data provided by the user, for adding note.
     */
    body: OApiReqEditNoteV3DtoModel;
  }): Observable<OApiResEditNoteV3DtoModel> {
    return this.editProvisionNotesV3$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiResEditNoteV3DtoModel>) =>
          r.body as OApiResEditNoteV3DtoModel
      )
    );
  }

  /**
   * Path part for operation editProvisionOrdering
   */
  static readonly EditProvisionOrderingPath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/ordering";

  /**
   * Edit the ordering of mentions of this multi-provision value for this abstract for this provision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProvisionOrdering()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editProvisionOrdering$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.EditProvisionOrderingPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >;
        })
      );
  }

  /**
   * Edit the ordering of mentions of this multi-provision value for this abstract for this provision.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProvisionOrdering$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editProvisionOrdering(params?: {
    body?: RequestModel;
  }): Observable<
    OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
  > {
    return this.editProvisionOrdering$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionViewModel
            | OApiRespMinimalProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation editProvisionReviewStatus
   */
  static readonly EditProvisionReviewStatusPath =
    "/api/v1/records/{recordUid}/provisions/{provisionUid}/review-status";

  /**
   * Change the status of a record's provision.
   *
   * If it succeeds, it returns status 200 and a view of the record's provision. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProvisionReviewStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionReviewStatus$Response(params: {
    /**
     * The unique id of the record whose provision status is to be modified.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose status is to be modified for a record.
     */
    provisionUid: string;
    body?: OApiReqEditReviewStatusDtoModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullMultiplePdfProvisionViewModel
      | OApiRespSearchMultiplePdfProvisionViewModel
      | OApiRespFullValMultiplePdfProvisionViewModel
      | OApiSingleEntityNoDataResponseModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.EditProvisionReviewStatusPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullMultiplePdfProvisionViewModel
            | OApiRespSearchMultiplePdfProvisionViewModel
            | OApiRespFullValMultiplePdfProvisionViewModel
            | OApiSingleEntityNoDataResponseModel
          >;
        })
      );
  }

  /**
   * Change the status of a record's provision.
   *
   * If it succeeds, it returns status 200 and a view of the record's provision. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProvisionReviewStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionReviewStatus(params: {
    /**
     * The unique id of the record whose provision status is to be modified.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose status is to be modified for a record.
     */
    provisionUid: string;
    body?: OApiReqEditReviewStatusDtoModel;
  }): Observable<
    | OApiRespFullMultiplePdfProvisionViewModel
    | OApiRespSearchMultiplePdfProvisionViewModel
    | OApiRespFullValMultiplePdfProvisionViewModel
    | OApiSingleEntityNoDataResponseModel
  > {
    return this.editProvisionReviewStatus$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullMultiplePdfProvisionViewModel
            | OApiRespSearchMultiplePdfProvisionViewModel
            | OApiRespFullValMultiplePdfProvisionViewModel
            | OApiSingleEntityNoDataResponseModel
          >
        ) =>
          r.body as
            | OApiRespFullMultiplePdfProvisionViewModel
            | OApiRespSearchMultiplePdfProvisionViewModel
            | OApiRespFullValMultiplePdfProvisionViewModel
            | OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation editProvisionReviewStatusV2
   */
  static readonly EditProvisionReviewStatusV2Path =
    "/api/v2/records/{recordUid}/provisions/{provisionUid}/review-status";

  /**
   * Change the status of a record's provision.
   *
   * If it succeeds, it returns status 200 and a view of the record's provision. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProvisionReviewStatusV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionReviewStatusV2$Response(params: {
    /**
     * The unique id of the record whose provision status is to be modified.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose status is to be modified for a record.
     */
    provisionUid: string;
    body?: OApiReqEditReviewStatusV2DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.EditProvisionReviewStatusV2Path,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>;
        })
      );
  }

  /**
   * Change the status of a record's provision.
   *
   * If it succeeds, it returns status 200 and a view of the record's provision. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProvisionReviewStatusV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionReviewStatusV2(params: {
    /**
     * The unique id of the record whose provision status is to be modified.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose status is to be modified for a record.
     */
    provisionUid: string;
    body?: OApiReqEditReviewStatusV2DtoModel;
  }): Observable<OApiRespFullValMultiplePdfProvisionViewModel> {
    return this.editProvisionReviewStatusV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>) =>
          r.body as OApiRespFullValMultiplePdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation editProvisionStatus
   */
  static readonly EditProvisionStatusPath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/status";

  /**
   * Change status of the multi-provision value for this abstract for this provision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProvisionStatus()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editProvisionStatus$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.EditProvisionStatusPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >;
        })
      );
  }

  /**
   * Change status of the multi-provision value for this abstract for this provision.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProvisionStatus$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editProvisionStatus(params?: {
    body?: RequestModel;
  }): Observable<
    OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
  > {
    return this.editProvisionStatus$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionViewModel
            | OApiRespMinimalProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation editProvisionUi
   */
  static readonly EditProvisionUiPath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/ui";

  /**
   * Edit the ui name and/or description of the multi-provision value for this abstract for this provision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProvisionUi()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editProvisionUi$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullMultiplePdfProvisionViewModel
      | OApiRespSearchMultiplePdfProvisionViewModel
      | OApiRespFullValMultiplePdfProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.EditProvisionUiPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullMultiplePdfProvisionViewModel
            | OApiRespSearchMultiplePdfProvisionViewModel
            | OApiRespFullValMultiplePdfProvisionViewModel
          >;
        })
      );
  }

  /**
   * Edit the ui name and/or description of the multi-provision value for this abstract for this provision.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProvisionUi$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editProvisionUi(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespFullMultiplePdfProvisionViewModel
    | OApiRespSearchMultiplePdfProvisionViewModel
    | OApiRespFullValMultiplePdfProvisionViewModel
  > {
    return this.editProvisionUi$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullMultiplePdfProvisionViewModel
            | OApiRespSearchMultiplePdfProvisionViewModel
            | OApiRespFullValMultiplePdfProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespFullMultiplePdfProvisionViewModel
            | OApiRespSearchMultiplePdfProvisionViewModel
            | OApiRespFullValMultiplePdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation modifyNestedProvisionSubfields
   */
  static readonly ModifyNestedProvisionSubfieldsPath =
    "/api/v1/records/{recordUid}/provisions/{provisionUid}/subfields";

  /**
   * Change the subfields of a record's NESTED_GROUP provision.
   *
   * If it succeeds, it returns status 200 and a view of the record's provision. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifyNestedProvisionSubfields()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyNestedProvisionSubfields$Response(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision&#x27;s subfields are to be modified.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose subfields are to be modified for a record.
     */
    provisionUid: string;
    body?: OApiReqModifyNestedProvisionSubfieldsDtoModel;
  }): Observable<StrictHttpResponse<OApiRespFullValMultiPdfProvViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.ModifyNestedProvisionSubfieldsPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullValMultiPdfProvViewModel>;
        })
      );
  }

  /**
   * Change the subfields of a record's NESTED_GROUP provision.
   *
   * If it succeeds, it returns status 200 and a view of the record's provision. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifyNestedProvisionSubfields$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyNestedProvisionSubfields(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision&#x27;s subfields are to be modified.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose subfields are to be modified for a record.
     */
    provisionUid: string;
    body?: OApiReqModifyNestedProvisionSubfieldsDtoModel;
  }): Observable<OApiRespFullValMultiPdfProvViewModel> {
    return this.modifyNestedProvisionSubfields$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullValMultiPdfProvViewModel>) =>
          r.body as OApiRespFullValMultiPdfProvViewModel
      )
    );
  }

  /**
   * Path part for operation getProvision
   */
  static readonly GetProvisionPath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}";

  /**
   * Get the multi-provision value for this record for this provision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProvision()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvision$Response(params: {
    view: string;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullMultiplePdfProvisionViewModel
      | OApiRespSearchMultiplePdfProvisionViewModel
      | OApiRespFullValMultiplePdfProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MultiProvisionValueService.GetProvisionPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullMultiplePdfProvisionViewModel
            | OApiRespSearchMultiplePdfProvisionViewModel
            | OApiRespFullValMultiplePdfProvisionViewModel
          >;
        })
      );
  }

  /**
   * Get the multi-provision value for this record for this provision.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProvision$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvision(params: {
    view: string;
  }): Observable<
    | OApiRespFullMultiplePdfProvisionViewModel
    | OApiRespSearchMultiplePdfProvisionViewModel
    | OApiRespFullValMultiplePdfProvisionViewModel
  > {
    return this.getProvision$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullMultiplePdfProvisionViewModel
            | OApiRespSearchMultiplePdfProvisionViewModel
            | OApiRespFullValMultiplePdfProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespFullMultiplePdfProvisionViewModel
            | OApiRespSearchMultiplePdfProvisionViewModel
            | OApiRespFullValMultiplePdfProvisionViewModel
      )
    );
  }
}
