export class CreateTeamModel {
  name: string;

  description: string;

  userUids: string[];

  constructor(data?: Partial<CreateTeamModel>) {
    Object.assign(this, data);
  }
}
