import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ProvisionUnitModel")
export class ProvisionUnitModel {
  static readonly view = "full";

  @JsonProperty("enumName", String)
  enumName = "";

  @JsonProperty("unitName", String)
  unitName = "";

  @JsonProperty("shortUnitName", String)
  shortUnitName = "";
}
