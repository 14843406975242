import {
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { ItlsNavigationItem } from "@@intelease/web/intelease/interfaces";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { filter } from "rxjs/operators";
import { AppAuthorities } from "@@intelease/web/intelease/constants";
import { NavigationComponent } from "@@intelease/web/common/components";
import { ItlsNavigationComponent } from "@@intelease/web/intelease/components/navigation/navigation.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "itls-nav-vertical-item",
  templateUrl: "./item.component.html",
  styleUrls: ["./item.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItlsNavVerticalItemComponent implements OnInit {
  @HostBinding("class")
  classes = "nav-item";

  @Input()
  set item(value: ItlsNavigationItem) {
    value.active = false;
    value.activeClass = "";
    this._item = value;
  }

  get item(): ItlsNavigationItem {
    return this._item;
  }

  public _item: ItlsNavigationItem;
  expectedAuthorities: string[];
  expectedIconAuthorities: string[];

  destroyRef = inject(DestroyRef);

  constructor(public activatedRoute: ActivatedRoute, private router: Router) {}

  public ngOnInit(): void {
    this.expectedAuthorities =
      ItlsNavigationComponent.determineExpectedAuthorities(this._item.id);
    this.expectedIconAuthorities =
      ItlsNavigationComponent.determineIconExpectedAuthorities(this._item.id);
    if (this.router.url === this._item.url) {
      this._item.active = true;
      this._item.activeClass = "active";
    }
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart || event instanceof NavigationEnd
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: NavigationStart | NavigationEnd) => {
        const { url } = event;
        this._item.active = false;
        this._item.activeClass = "inactive";
        if (this._item.url === url) {
          this._item.active = true;
          this._item.activeClass = "active";
        }
      });
  }
}
