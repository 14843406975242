/* tslint:disable */
/* eslint-disable */
import { DomainIdDtoModel } from "./domain-id-dto-model";
import { ReminderScheduleDtoModel } from "./reminder-schedule-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateReminderDtoModel")
export class CreateReminderDtoModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("mediumType", String, true)
  mediumType?: string = "";

  @JsonProperty("mentionUid", String, true)
  mentionUid?: string = "";

  @JsonProperty("metadata", Any, true)
  metadata?: {
    [key: string]: {};
  } = {};

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("recordUid", String, true)
  recordUid?: string = "";

  @JsonProperty("schedule", ReminderScheduleDtoModel, true)
  schedule?: ReminderScheduleDtoModel = undefined;

  @JsonProperty("sourceType", String, true)
  sourceType?: string = "";

  @JsonProperty("targetDomains", [DomainIdDtoModel], true)
  targetDomains?: Array<DomainIdDtoModel> = [];
}
