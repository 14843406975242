import { Component, Input, OnInit } from "@angular/core";
import {
  ModalInputModel,
  PaginationModel,
} from "@@intelease/web/intelease/models";
import { INTELEASE_ANIMATIONS_CONST } from "@@intelease/web/intelease/animations";
import { TaskModalService } from "@@intelease/web/tasks/src/lib/modals/task";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { ActivatedRoute } from "@angular/router";
import { UserInfoService } from "@@intelease/web/intelease/services";
import { TasksService } from "@@intelease/app-services/tasks";
import { FullUserAbstractTaskModel } from "@@intelease/app-models/tasks";
import { first as _first, get } from "lodash";
import { TASKS_LIST_CONST } from "@@intelease/web/tasks/src/lib/task-list/constants";
import { BaseEntityPermissionComponent } from "@@intelease/web/common/components";
import { TaskListItemModel } from "./models";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "intelease-ui-task-list",
  templateUrl: "./task-list.component.html",
  styleUrls: ["./task-list.component.scss"],
  animations: INTELEASE_ANIMATIONS_CONST,
})
export class TaskListComponent
  extends BaseEntityPermissionComponent
  implements OnInit
{
  @Input() mode = ComponentModeEnum.VIEW;
  rows: FullUserAbstractTaskModel[] = [];
  pagination: PaginationModel = new PaginationModel(1, 20);
  isInAbstractPage = false;
  abstractUid: string;
  isLoading = false;
  // selectedItems: any[] = []
  columns: Array<TaskListItemModel> =
    TASKS_LIST_CONST.TASKS_LIST_TABLE_CONFIG.COLUMNS;
  rowMenu = TASKS_LIST_CONST.TASKS_LIST_TABLE_CONFIG.ROW_MENU;
  rowBatchMenu = TASKS_LIST_CONST.TASKS_LIST_TABLE_CONFIG.ROW_BATCH_MENU;

  constructor(
    private taskModalService: TaskModalService,
    private activatedRoute: ActivatedRoute,
    private tasksService: TasksService,
    private userInfoService: UserInfoService
  ) {
    super();
    this.activatedRoute.data.pipe(takeUntilDestroyed()).subscribe((data) => {
      this.isInAbstractPage =
        data["parent"] && data["parent"] === "abstractPage";
      this.setColsBasedOnPage();
    });
    this.abstractUid = get(
      this.activatedRoute,
      "snapshot.parent.parent.parent.params.uid"
    );
  }

  ngOnInit() {
    this.init();
  }

  private setColsBasedOnPage() {
    if (this.isInAbstractPage) {
      this.columns = TASKS_LIST_CONST.ABSTRACT_TABLE_CONFIG.COLUMNS;
      this.rowMenu = TASKS_LIST_CONST.ABSTRACT_TABLE_CONFIG.ROW_MENU;
      this.rowBatchMenu = TASKS_LIST_CONST.ABSTRACT_TABLE_CONFIG.ROW_BATCH_MENU;
    } else {
      this.columns = TASKS_LIST_CONST.TASKS_LIST_TABLE_CONFIG.COLUMNS;
      this.rowMenu = TASKS_LIST_CONST.TASKS_LIST_TABLE_CONFIG.ROW_MENU;
      this.rowBatchMenu =
        TASKS_LIST_CONST.TASKS_LIST_TABLE_CONFIG.ROW_BATCH_MENU;
    }
  }

  onRowClick(row: FullUserAbstractTaskModel): void {
    const taskModel = new ModalInputModel();
    taskModel.mode = ComponentModeEnum.VIEW;
    taskModel.payload = row;
    if (row.entities && row.entities.length) {
      taskModel.payload.abstractUid = row.entities[0].uid;
    }
    taskModel.payload.editable = this.emptyGivenOrHasPermissions(
      this.AUTH.EDIT
    );
    this.taskModalService
      .openNewTaskModal(taskModel)
      .afterClosed()
      .subscribe((modalResponse) => {
        if (
          modalResponse &&
          modalResponse.type === ModalsResponseTypeEnum.CLOSE
        ) {
          this.init();
        }
      });
  }

  private init() {
    this.isLoading = true;
    if (this.isInAbstractPage) {
      this.tasksService
        .getTaskListByAbstractUid(this.abstractUid)
        .subscribe((res) => {
          this.pagination.totalResults = res.pagination.totalResults;
          this.processListResponse(res.items);
        });
    } else {
      this.tasksService.getTaskListForCurrentUser().subscribe((res) => {
        this.pagination.totalResults = res.pagination.totalResults;
        this.processListResponse(res.items);
      });
    }
  }

  private processListResponse(taskList: FullUserAbstractTaskModel[]) {
    taskList.forEach((task) => {
      if (task.entities && task.entities.length) {
        const _entity = _first(task.entities);
        task.entity.value = _entity.name;
        task.entity.link = `/individual-abstract/${_entity.uid}/related-documents`;
      }
      task.updatedByName =
        task.updatedBy && task.updatedBy.name ? task.updatedBy.name : "";
      task.assigneeName = task.assignee.getNameWithTypeTitle();
      task.createdByName =
        task.createdBy && task.createdBy.name ? task.createdBy.name : "";
    });
    this.rows = [...taskList];
    this.isLoading = false;
  }

  getValue(value) {
    return value;
  }
}
