import { Component, OnInit } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { openInNewWindow } from "@@intelease/web/intelease/utils";
import { ComponentStateHooksInterface } from "@@intelease/app-models/common";
import { ENTITY_FORM_SCHEMA_CONST } from "@@intelease/web/intelease/constants";

@Component({
  template: "",
})
export class BaseEntityEditComponent
  implements ComponentStateHooksInterface, OnInit
{
  public renameFormTitle: string;
  public formSchema = ENTITY_FORM_SCHEMA_CONST;
  formModalService;
  commonModalService;

  public static onOpenInNewTAbClick(url: string): void {
    openInNewWindow(url);
  }

  ngOnInit(): void {
    this.initListeners();
  }

  initActions(): void {
    //
  }

  initListeners(): void {
    //
  }

  private openEditModal(selectedRow: {
    name: string;
    description: string;
    uid: string;
  }): void {
    const modalData = new ModalInputModel();
    const {
      name: originalName,
      description: originalDescription,
      uid,
    } = selectedRow;
    modalData.mode = ComponentModeEnum.EDIT;
    modalData.payload = {
      editTitle: this.renameFormTitle,
      formSchema: { ...this.formSchema },
      model: { name: originalName, description: originalDescription },
    };
    const modalRef = this.formModalService.openNewFormModal(modalData, {
      minWidth: 340,
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res && res.data) {
        const { name, description } = res.data;
        if (name) {
          this.onRenameModalClose({ name, description, uid });
        }
      }
    });
  }

  protected onRenameModalClose(modalRes: {
    name: string;
    description: string;
    uid: string;
  }): void {
    //
  }

  protected onRenameClick(selectedRow: {
    name: string;
    description: string;
    uid: string;
  }): void {
    this.openEditModal(selectedRow);
  }

  protected showDeleteDialog(cb: () => void): void {
    const modalData: ModalInputModel = new ModalInputModel();
    this.commonModalService
      .openDeleteConfirmModal(modalData)
      .afterClosed()
      .subscribe((res) => {
        const { type } = res;
        if (type === ModalsResponseTypeEnum.CLOSE) {
          cb();
        }
      });
  }
}
