export class ArrayHelper {
  static moveArray(arr: any[], old_index: number, new_index: number): any[] {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  static replaceItem<T>(array: T[], item: T, propertyMap: string[]): T[] {
    if (!(array instanceof Array)) {
      return array;
    }
    return array.map((_item) => {
      let currentItem = _item;
      let selectedItem = item;
      propertyMap.forEach((property) => {
        currentItem = currentItem?.[property];
        selectedItem = selectedItem?.[property];
      });
      if (selectedItem === currentItem) {
        return {
          ..._item,
          ...item,
        };
      }
      return _item;
    });
  }
}
