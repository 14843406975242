import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { InteleasePermissionsService } from "@@intelease/web/intelease/services";

@Directive({
  selector:
    "[itlsEntityPermissions][itlsEntityPermissionsExpected],[itlsEntityPermissionsGiven]",
})
export class EntityPermissionsDirective implements OnInit, OnChanges {
  private _itlsEntityPermissionsExpected: string | string[];

  @Input() set itlsEntityPermissionsExpected(expected: string | string[]) {
    this._itlsEntityPermissionsExpected = expected;
  }

  get itlsEntityPermissionsExpected() {
    return this._itlsEntityPermissionsExpected;
  }

  _itlsEntityPermissionsGiven: string[];

  @Input() set itlsEntityPermissionsGiven(given: string[]) {
    this._itlsEntityPermissionsGiven = given;
  }

  get itlsEntityPermissionsGiven() {
    return this._itlsEntityPermissionsGiven;
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const expectedChanged = changes.itlsEntityPermissionsExpected;
    const givenChanged = changes.itlsEntityPermissionsGiven;
    if (expectedChanged || givenChanged) {
      const expected = this.itlsEntityPermissionsExpected;
      const given = this.itlsEntityPermissionsGiven;
      // this.logFields()
      if (InteleasePermissionsService.allExpectationsCovered(expected, given)) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
        // this.cdr.detectChanges()
      } else {
        this.viewContainer.clear();
        // this.cdr.detectChanges()
      }
    }
  }

  private logFields() {
    console.log("------------------------------------------------------");
    console.log("expected: " + this.itlsEntityPermissionsExpected);
    console.log("given: " + this.itlsEntityPermissionsGiven);
    console.log("------------------------------------------------------");
  }
}
