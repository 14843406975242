import { ProvisionTypeModel } from "../models/provision-type.model";
import {
  AddressProvisionModel,
  CategoryProvisionModel,
  DateProvisionModel,
  EmailProvisionModel,
  GeneralProvisionModel,
  ListProvisionModel,
  MoneyProvisionModel,
  NameProvisionModel,
  NestedGroupProvisionModel,
  NumericalProvisionModel,
  PhoneProvisionModel,
  TableProvisionModel,
  YearProvisionModel,
} from "../models";

export const PROVISIONS_DATA_CONST = {
  // Below are used for general site stuff
  STRING: new ProvisionTypeModel(
    "STRING",
    "Text",
    "fa fa-font",
    "string-form",
    "rgba(255, 255, 255, 0.85)", // white
    GeneralProvisionModel
  ),
  DATE: new ProvisionTypeModel(
    "DATE",
    "Date",
    "fa fa-calendar",
    "date-form",
    "rgba(255, 255, 255, 0.85)", // white
    GeneralProvisionModel
  ),
  NUMBER: new ProvisionTypeModel(
    "NUMBER",
    "Number",
    "fa fa-list-ol",
    "string-form",
    "rgb(255, 255, 255, 0.85)", // white
    GeneralProvisionModel
  ),

  // Below are used for provisions from documents
  DOUBLE: new ProvisionTypeModel(
    "DOUBLE",
    "Numerical",
    "fa fa-list-ol",
    "double-form",
    "rgba(0, 215, 100, 0.85)", // green
    NumericalProvisionModel
  ),
  MONEY: new ProvisionTypeModel(
    "MONEY",
    "Currency Amount",
    "fa fa-money",
    "money-form",
    "rgba(0, 215, 100, 0.85)", // green
    MoneyProvisionModel
  ),
  TABLE: new ProvisionTypeModel(
    "TABLE",
    "Table",
    "fa fa-table",
    "table-form",
    "rgba(0, 215, 100, 0.85)", // green
    TableProvisionModel
  ),

  COMMON_NOUN: new ProvisionTypeModel(
    "COMMON_NOUN",
    "Text",
    "fa fa-inbox",
    "common-noun-form",
    "rgba(254, 207, 51, 0.85)", // yellow
    GeneralProvisionModel
  ),
  PROPER_NOUN: new ProvisionTypeModel(
    "PROPER_NOUN",
    "Name",
    "fa fa-calendar",
    "proper-noun-form",
    "rgba(254, 207, 51, 0.85)", // yellow
    NameProvisionModel
  ),

  NESTED_GROUP: new ProvisionTypeModel(
    "NESTED_GROUP",
    "Nested Group",
    "fa fa-list",
    "nested-group-form",
    "rgba(235, 85, 58, 0.85)", // red
    NestedGroupProvisionModel
  ),

  // Below are dates
  LOCAL_DATE: new ProvisionTypeModel(
    "LOCAL_DATE",
    "Date",
    "fa fa-clock",
    "local-date-form",
    "rgba(65, 155, 249, 0.85)", // blue
    DateProvisionModel
  ),
  YEAR: new ProvisionTypeModel(
    "YEAR",
    "Year",
    "fa fa-calendar-day",
    "year-form",
    "rgba(65, 155, 249, 0.85)", // blue
    YearProvisionModel
  ),

  // Below are contact infos
  ADDRESS: new ProvisionTypeModel(
    "ADDRESS",
    "Address",
    "fa fa-location",
    "address-form",
    "rgba(219, 124, 238, 0.85)", // purple
    AddressProvisionModel
  ),
  EMAIL: new ProvisionTypeModel(
    "EMAIL",
    "Email",
    "fa fa-envelope",
    "email-form",
    "rgba(219, 124, 238, 0.85)", // purple
    EmailProvisionModel
  ),
  PHONE: new ProvisionTypeModel(
    "PHONE",
    "Phone",
    "fa fa-phone",
    "phone-form",
    "rgba(219, 124, 238, 0.85)", // purple
    PhoneProvisionModel
  ),

  // Below are categoricals
  MULTIPLE_CAT: new ProvisionTypeModel(
    "MULTIPLE_CAT",
    "Multiple Category",
    "fa fa-list",
    "multiple-cat-form",
    "rgba(235, 85, 58, 0.85)", // red
    ListProvisionModel
  ),
  SINGLE_CAT: new ProvisionTypeModel(
    "SINGLE_CAT",
    "Single Category",
    "fa fa-clipboard-list",
    "single-cat-form",
    "rgba(235, 85, 58, 0.85)", // red
    CategoryProvisionModel
  ),
  UID: new ProvisionTypeModel(
    "UID",
    "Uid",
    "fa fa-font",
    "string-form",
    "rgba(255, 255, 255, 0.85)", // white
    GeneralProvisionModel
  ),
};
