import { JsonConverter, JsonCustomConvert } from "json2typescript";
import * as moment from "moment-timezone";

@JsonConverter
export class DateTimeConvertService implements JsonCustomConvert<Date> {
  public static serializeDateTime(date) {
    if (!date) {
      return null;
    }
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return `${moment(date).tz(timeZone).format()}[${timeZone}]`;
  }

  public static deserializeDateTime(date) {
    if (!date) {
      return null;
    }
    const splittedDate = date.split("[");
    const _date = moment.tz(splittedDate[0], splittedDate[1].split("]")[0]);
    return _date.toDate();
  }

  public static serializeFromString(date: string): any {
    return DateTimeConvertService.serializeDateTime(date);
  }

  serialize(date: Date): any {
    return DateTimeConvertService.serializeDateTime(date);
  }

  deserialize(date: string): Date {
    return DateTimeConvertService.deserializeDateTime(date);
  }
}
