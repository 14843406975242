/* tslint:disable */
/* eslint-disable */
import { EntityPermissionFullDtoModel } from "./entity-permission-full-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EntityAllPermissionsFullDtoModel")
export class EntityAllPermissionsFullDtoModel {
  @JsonProperty("entityType", Any, true)
  entityType?:
    | "FINAL_ABSTRACT"
    | "AUTO_ABSTRACT"
    | "PDF_DOCUMENT_ABSTRACT"
    | "PROVISION_FORM"
    | "PROVISION_LIBRARY"
    | "PROVISION_INFO"
    | "PROJECT"
    | "CALENDAR"
    | "DASHBOARD"
    | "REPORT"
    | "DIRECTORY"
    | "STACK"
    | "DOC_SET"
    | "QUEUE" = undefined;

  @JsonProperty("entityUid", String, true)
  entityUid?: string = "";

  @JsonProperty("permissions", [EntityPermissionFullDtoModel], true)
  permissions?: Array<EntityPermissionFullDtoModel> = [];
}
