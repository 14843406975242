import { FetchBatchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { ListResponseModel } from "@@intelease/web/intelease/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FetchBatchTaskService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly USERS_URL = "/users";
  private static readonly TASKS_URL = "/tasks";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(private fetchBatchApiService: FetchBatchApiService) {}

  getTaskListByAbstractUid<T extends object>(
    abstractUid: string,
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    return this.fetchBatchApiService.sendBatchRequest(
      FetchBatchTaskService.API_VERSION_1,
      `${FetchBatchTaskService.FINAL_DOC_SETS_URL}/${abstractUid}${FetchBatchTaskService.TASKS_URL}`,
      view,
      classRef
    );
  }

  getTaskListForCurrentUser<T extends object>(
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    return this.fetchBatchApiService.sendBatchRequest(
      FetchBatchTaskService.API_VERSION_1,
      `${FetchBatchTaskService.USERS_URL}/current${FetchBatchTaskService.TASKS_URL}`,
      view,
      classRef
    );
  }
}
