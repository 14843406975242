<div class="modal-content">
  <div class="modal-header clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5>
      {{ title }}
    </h5>
  </div>
  <div class="modal-body">
    <p>{{message}</p>
    <div *ngFor="let formOption of formOptions">
      <input
        type="radio"
        name="formOption"
        value="{{ formOption }}"
        (click)="onChooseFormOption(formOption.name)"
      />
      <label for="formType1">
        {{ formOption.message }}
      </label>
    </div>
    <ng-select
      *ngIf="chosenFormOption === 'NEW'"
      [items]="provisionForms"
      [multiple]="false"
      [closeOnSelect]="true"
      bindLabel="name"
      [(ngModel)]="chosenProvisionForm"
    >
    </ng-select>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="chosenFormOption === 'CURRENT' || chosenProvisionForm"
      type="button"
      class="btn btn-primary btn-cons pull-left inline"
      (click)="onChoose()"
    >
      Select
    </button>
    <button
      type="button"
      class="btn btn-default btn-cons no-margin pull-left inline"
      (click)="onDismissModal()"
    >
      Cancel
    </button>
  </div>
</div>
