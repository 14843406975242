import { NgModule } from "@angular/core";
import { TermCalculatorModalComponent } from "./term-calculator-modal.component";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { InteleaseModule } from "@@intelease/web/intelease/intelease.module";
import { NgScrollbarModule } from "ngx-scrollbar";
import { InputDropdownModule } from "@@intelease/web/intelease/components";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";

const INTELEASE_MODULES = [InputDropdownModule];

const NGX_BOOTSTRAP = [
  TabsModule.forRoot(),
  BsDatepickerModule.forRoot(),
  ModalModule.forRoot(),
];

@NgModule({
  imports: [
    CommonModule,
    InteleaseCommonModule,
    InteleaseModule,
    NgScrollbarModule,
    ...INTELEASE_MODULES,
    ...NGX_BOOTSTRAP,
    TooltipModule,
  ],
  declarations: [TermCalculatorModalComponent],
})
export class TermCalculatorModalModule {}
