<div>
  <div mat-dialog-title class="clearfix text-left">
    <button
      *ngIf="showDismissIcon"
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5 class="mt-0">
      {{ title }}
    </h5>
  </div>
  <div mat-dialog-content>
    <p *ngIf="!isHtmlMessage">
      {{ message }}
    </p>
    <p *ngIf="isHtmlMessage" [innerHtml]="message"></p>
  </div>
  <div mat-dialog-actions>
    <button
      mat-flat-button
      color="warn"
      (click)="onConfirm()"
      dataCy="cyDialogOkBtn"
    >
      {{ confirmButtonTitle || "OK" }}
    </button>
    <button
      mat-flat-button
      (click)="onDismissModal()"
      dataCy="cyDialogCancelBtn"
    >
      {{ cancelButtonTitle || "Cancel" }}
    </button>
  </div>
</div>
