import {
  ChangeCustomTagsRequestDtoModel,
  CustomTagApiDtoModel,
  FolderStructureNodeDtoModel,
  OApiReqUpdateCustomTagsRequestDtoModel,
  DocSetNodeDtoModel,
  DriveService,
} from "@@intelease/api-models/adex-api-model-src";
import { LocalStorageKey } from "@@intelease/web/common/enums/local-storage.keys";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import {
  SelectedFilterModel,
  DocSetCategory,
  IDriveNode,
  Paginated,
  DriveNodeCategory,
  DriveNodeStatus,
  DriveNodeType,
  User,
  SelectedColumnFieldsModel,
  DriveNodeCategoryUppercaseEnum,
  Permission,
} from "../models/drive-node.types";

import {
  LoadAllDriveNodes,
  UpdateRecordStatus,
  AddRecord,
  AddDirectory,
  ChangePage,
  SetSelectedFilter,
  SetSelectedColumnFields,
  CurrentSelectedColumnFields,
  CancelAdvancedSearch,
  ApplyAdvancedSearch,
  CloseAdvancedSearch,
  SortDriveNodes,
  RenameDriveNode,
  AddDocSet,
  UpdateCustomTags,
  UpdateShareRecordWithUid,
  AddBatchDocSetsToQueue,
  UpdateDocument,
  AddDocSetPartial,
} from "./drive.actions";
import { DrivePartialState } from "./drive.reducer";
import { driveNodeQuery } from "./drive.selectors";
import { DatePipe } from "@angular/common";
import { DateUtil } from "@@intelease/web/utils";
import { FullNodeModel } from "@@intelease/web/common/models";

export interface FullNodeModelWithParentDirectoryUid {
  node: FullNodeModel;
  parentDirectoryUid: string;
  refreshDirectories: boolean;
}

@Injectable()
export class DriveFacade {
  newFolderCreated$: Subject<FullNodeModelWithParentDirectoryUid> =
    new Subject<FullNodeModelWithParentDirectoryUid>();

  renameFolder$: Subject<{ name: string; uid: string }> = new Subject<{
    name: string;
    uid: string;
  }>();

  deleteFolders$: Subject<string[]> = new Subject<string[]>();

  loadAllDriveNodesSucceeded$ = this.store.pipe(
    select(driveNodeQuery.loadAllDriveNodesSucceeded)
  );

  loadAllDriveNodesError$ = this.store.pipe(
    select(driveNodeQuery.loadAllDriveNodesError)
  );

  getAdvancedSearchFieldsList$: Observable<any[]> = this.store.pipe(
    select(driveNodeQuery.getAdvancedSearchFieldsList)
  );

  advancedSearchFieldsOperators$ = this.store.pipe(
    select(driveNodeQuery.getProvisionsOperator)
  );

  isAllDriveNodesLoading$ = this.store.pipe(
    select(driveNodeQuery.isAllDriveNodesLoading)
  );

  getAllRecordsList$ = this.store.pipe(
    select(driveNodeQuery.getAllRecordsList)
  );

  getAppliedSelectedColumnFields$ = this.store.pipe(
    select(driveNodeQuery.getAppliedSelectedColumnFields)
  );

  getCurrentSelectedColumnFields$ = this.store.pipe(
    select(driveNodeQuery.getCurrentSelectedColumnFields)
  );

  advancedSearchPossibleColumns$ = this.store.pipe(
    select(driveNodeQuery.advancedSearchPossibleColumns)
  );

  isAdvancedSearchActive$ = this.store.pipe(
    select(driveNodeQuery.isAdvancedSearchActive)
  );

  getPagination$ = this.store.pipe(select(driveNodeQuery.getPagination));

  getNumberOfFilters$ = this.store.pipe(
    select(driveNodeQuery.getNumberOfFilters)
  );

  getSelectedFilter$ = this.store.pipe(
    select(driveNodeQuery.getCurrentSelectedFilter)
  );

  getAppliedSelectedFilter$ = this.store.pipe(
    select(driveNodeQuery.getAppliedSelectedFilter)
  );

  isSortRecordFailed$ = this.store.pipe(
    select(driveNodeQuery.isSortRecordFailed)
  );

  getNodeNavigation$ = this.store.pipe(
    select(driveNodeQuery.getNodeNavigation)
  );

  getRecords$(category?: DriveNodeCategory): Observable<Paginated<IDriveNode>> {
    return this.getAllRecordsList$.pipe(
      map(
        (records) =>
          ({
            ...records,
            items: ((records?.items || []) as IDriveNode[])
              .map((item) => {
                return {
                  ...item,
                  owner: (item?.owner as User)?.name,
                  ownerUid: (item.owner as User)?.uid,
                  docSetCategory: item?.docSetCategory
                    ? this.convertDocSetCategory(item.docSetCategory)
                    : item?.docSetCategory,
                };
              })
              .filter((item) =>
                !category
                  ? true
                  : this.filterRecordByCategory(item as IDriveNode, category)
              ),
          } as Paginated<IDriveNode>)
      )
    );
  }

  private filterRecordByCategory(
    record: IDriveNode,
    category: DriveNodeCategory
  ): boolean {
    if (category === DriveNodeCategory.deleted) {
      return record?.type === DriveNodeType.RECORD
        ? record.status === DriveNodeStatus.UI_DELETED
        : true;
    }

    //TODO: add else categories, but i don't have knowledge
    return true;
  }

  private get docSetCategories(): DocSetCategory[] {
    return JSON.parse(
      localStorage.getItem(LocalStorageKey.DOC_SET_CATEGORIES) as any
    );
  }

  private convertDocSetCategory(
    docSetCategory: DocSetCategory["value"]
  ): DocSetCategory["uiName"] {
    return (
      this.docSetCategories.find(
        (category) => category.value === docSetCategory
      ) as DocSetCategory
    ).uiName;
  }

  constructor(
    private store: Store<DrivePartialState>,
    private driveService: DriveService,
    private datePipe: DatePipe
  ) {}

  loadAllRecords(payload: {
    category?: DriveNodeCategoryUppercaseEnum;
    directoryUid?: string;
    page?: number;
    perPage?: number;
    view?: string;
    sorts?: string;
  }) {
    this.store.dispatch(new LoadAllDriveNodes(payload));
  }

  sortDriveNodes(payload: {
    category?: DriveNodeCategoryUppercaseEnum;
    directoryUid?: string;
    page?: number;
    perPage?: number;
    view?: string;
    sorts?: string;
  }) {
    this.store.dispatch(new SortDriveNodes(payload));
  }

  setSelectedColumnFields(payload: SelectedColumnFieldsModel[]) {
    this.store.dispatch(new SetSelectedColumnFields(payload));
  }

  setCurrentSelectedColumnFields(payload: SelectedColumnFieldsModel[]) {
    this.store.dispatch(new CurrentSelectedColumnFields(payload));
  }

  addRecord(payload: {
    recordUid: string;
    category:
      | "ALL"
      | "DELETED"
      | "FAVORITES"
      | "OWNED"
      | "SHARED"
      | "DOCUMENTS";
    directoryUid?: string;
    data: DocSetNodeDtoModel;
  }) {
    this.store.dispatch(new AddRecord(payload));
  }

  addDocSet(payload: {
    docSetUid: string;
    category:
      | "ALL"
      | "DELETED"
      | "FAVORITES"
      | "OWNED"
      | "SHARED"
      | "DOCUMENTS";
    directoryUid?: string;
    data: DocSetNodeDtoModel;
  }) {
    this.store.dispatch(new AddDocSet(payload));
  }

  addDirectory(payload: {
    category:
      | "ALL"
      | "DELETED"
      | "FAVORITES"
      | "OWNED"
      | "SHARED"
      | "DOCUMENTS";
    directoryUid?: string;
    data: FolderStructureNodeDtoModel;
  }) {
    this.store.dispatch(new AddDirectory(payload));
  }

  changePage(payload: number) {
    this.store.dispatch(new ChangePage(payload));
  }

  updateRecordStatus(payload: {
    recordUid: string;
    status:
      | "UPLOADING"
      | "QUEUED"
      | "PROCESSING"
      | "PROCESSING_COMPLETED"
      | "UNDER_REVIEW"
      | "COMPLETED"
      | "ERROR"
      | "UI_DELETED";
  }) {
    this.store.dispatch(new UpdateRecordStatus(payload));
  }

  updateDocument(payload: {
    recordUid: string;
    queueName?: string;
    queueStage?: string;
    queueUid?: string;
  }) {
    this.store.dispatch(new UpdateDocument(payload));
  }

  addDocSetPartial(payload: {
    docSetName: string;
    docSetUid: string;
    directoryUid?: string;
    permissions?: Permission[];
    queueName?: string;
    queueUid?: string;
    queueStage?: string;
    recordUid?: string;
    owner?: User;
    documentCount?: number;
  }) {
    this.store.dispatch(new AddDocSetPartial(payload));
  }

  setSelectedFilter(payload: SelectedFilterModel) {
    this.store.dispatch(new SetSelectedFilter(payload));
  }

  applyAdvancedSearch() {
    this.store.dispatch(new ApplyAdvancedSearch());
  }

  cancelAdvancedSearch() {
    this.store.dispatch(new CancelAdvancedSearch());
  }

  closeAdvancedSearch() {
    this.store.dispatch(new CloseAdvancedSearch());
  }

  renameDriveNode(payload: { row: IDriveNode; newName: string }) {
    this.store.dispatch(new RenameDriveNode(payload));
  }

  updateCustomTags(recordUid: string, customTags: CustomTagApiDtoModel[]) {
    const data: ChangeCustomTagsRequestDtoModel = {
      customTags,
    };
    const updateCustomTagsDTO: OApiReqUpdateCustomTagsRequestDtoModel = {
      data,
    };
    const params = {
      recordUid,
      body: updateCustomTagsDTO,
    };
    this.store.dispatch(new UpdateCustomTags(params));
  }

  updateShareRecordWithUid(recordUid: string, shared: boolean) {
    this.store.dispatch(new UpdateShareRecordWithUid(recordUid, shared));
  }

  addBatchDocSetsToQueue(payload: {
    queueUid: string;
    queueName: string;
    docSetUids: string[];
  }) {
    this.store.dispatch(new AddBatchDocSetsToQueue(payload));
  }

  private _filter(value: any) {
    const _value = {
      ...value,
      value: value.value.map((item: any) => {
        if (item.field.fieldType === "LOCAL_DATE") {
          return {
            ...item,
            field: `${item?.field?.fieldName}::${item?.field?.uiName}::${item?.field?.fieldType}`,
            category: item?.field?.category,
            value: item.value
              ? DateUtil.serializeToExactDate(item.value)
              : item.value,
          };
        } else {
          return {
            ...item,
            field: `${item?.field?.fieldName}::${item?.field?.uiName}::${item?.field?.fieldType}`,
            category: item?.field?.category,
          };
        }
      }),
    };
    return _value;
  }
}
