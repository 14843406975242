/* tslint:disable */
/* eslint-disable */
import { JsonNodeModel } from "./json-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DashboardDtoModel")
export class DashboardDtoModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdBy", String, true)
  createdBy?: string = "";

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("schema", Any, true)
  schema?: any = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("updatedAt", DateTimeConvertService, true)
  updatedAt?: Date | undefined = undefined;

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
