import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AbstractReviewFacade } from "@@intelease/app-state/abstract-review";
import { ProvisionBoxModalComponent } from "@@intelease/web/abstraction-page/src/lib/modals/provision-box";
import { values } from "lodash";
import { ProvisionBoxInitTypesEnum } from "@@intelease/app-models/abstract-review";
import { Subject } from "rxjs";
import { WebAbstractionPageService } from "@@intelease/web/abstraction-page/src/lib/services/web-abstraction-page.service";

@Injectable({
  providedIn: "root",
})
export class ProvisionBoxHelperService {
  public static modalId = "196c3798-2d86-11eb-adc1-0242ac120002";
  static bringToFrontByModalId: Subject<string> = new Subject<string>();
  static reinitializeExistingProvisionBox: Subject<any> = new Subject<any>();
  static selectedModalId: string;
  public static removeMention$: Subject<{
    provisionUid: string;
    mentionUid: string;
    mentionIndex: number;
    isTempMention: boolean;
    isReloadDisabled?: boolean;
    cb?: () => void;
  }> = new Subject<{
    provisionUid: string;
    mentionUid: string;
    mentionIndex: number;
    isTempMention: boolean;
    isReloadDisabled?: boolean;
    cb?: () => void;
  }>();
  public static tableMentionTouched$: Subject<{
    provisionUid: string;
    mentionUid: string;
    isTouched: boolean;
  }> = new Subject<{
    provisionUid: string;
    mentionUid: string;
    isTouched: boolean;
  }>();
  public static removeTempHighlightedMention$: Subject<any> =
    new Subject<any>();
  public static reinitializeSelectedSelectedProvision: Subject<{
    provisionUid: string;
    selectedProvision;
  }> = new Subject<{ provisionUid: string; selectedProvision }>();
  private currentDialogIdList: Array<string> = [];

  constructor(
    private dialog: MatDialog,
    public readonly abstractReviewFacade: AbstractReviewFacade
  ) {}

  private open(
    abstractUid: string,
    givenEntityPermissions: string[],
    provisionGroupUid: string,
    provisionBoxInitTypes: ProvisionBoxInitTypesEnum,
    payload?
  ) {
    const modalId = ProvisionBoxHelperService.modalId;
    const _payload = {
      modalId: modalId,
      abstractUid,
      givenEntityPermissions,
      provisionGroupUid,
      ...payload,
      provisionBoxInitTypes,
    };
    if (!WebAbstractionPageService.IS_DUAL_WINDOW_MODE) {
      if (this.isOpen(modalId)) {
        ProvisionBoxHelperService.reinitializeExistingProvisionBox.next(
          _payload
        );
        // this.bringToFront(modalId)
        return this.dialog.getDialogById(modalId);
      } else {
        const dialogRef = this.dialog.open(ProvisionBoxModalComponent, {
          maxHeight: "720px",
          panelClass: "provision-box-modal-panel",
          hasBackdrop: false,
          id: modalId,
          data: _payload,
          disableClose: true,
        });
        dialogRef.afterOpened().subscribe(() => {
          this.currentDialogIdList.push(modalId);
          values(
            window.document.getElementsByClassName("cdk-global-overlay-wrapper")
          ).forEach((item: HTMLElement) => {
            item.style.zIndex = "unset";
          });
          ProvisionBoxHelperService.reinitializeExistingProvisionBox.next(
            _payload
          );
          // this.bringToFront(modalId)
        });
        dialogRef.afterClosed().subscribe(() => {
          values(
            window.document.getElementsByClassName("cdk-global-overlay-wrapper")
          ).forEach((item: HTMLElement) => {
            item.style.zIndex = "1000";
          });
        });
        return dialogRef;
      }
    } else {
      if (!WebAbstractionPageService.IS_DUAL_WINDOW_OPEN) {
        if (this.isOpen(modalId)) {
          WebAbstractionPageService.detachCurrentProvisionBoxInNewWindow$.next();
        } else {
          WebAbstractionPageService.openNewWindowByFirstProvisionGroup$.next(
            _payload
          );
        }
      } else {
        WebAbstractionPageService.sendReinitializeWindowMessageToDualWindow$.next(
          _payload
        );
      }
    }
  }

  private isOpen(dialogId: string) {
    return this.currentDialogIdList.includes(dialogId);
  }

  private close(modalId) {
    const modalRef = this.dialog.getDialogById(modalId);
    if (modalRef) {
      modalRef.close();
      modalRef.afterClosed().subscribe(() => {
        this.abstractReviewFacade.setSelectedProvision(undefined);
        this.currentDialogIdList = this.currentDialogIdList.filter(
          (id) => id !== modalId
        );
      });
    }
  }

  bringToFront(modalId: string) {
    if (modalId !== ProvisionBoxHelperService.selectedModalId) {
      ProvisionBoxHelperService.selectedModalId = modalId;
      ProvisionBoxHelperService.bringToFrontByModalId.next(modalId);
    }
  }

  openByProvisionGroupUid(
    abstractUid: string,
    givenEntityPermissions: string[],
    provisionGroupUid: string,
    payload?
  ) {
    return this.open(
      abstractUid,
      givenEntityPermissions,
      provisionGroupUid,
      ProvisionBoxInitTypesEnum.PROVISION_GROUP,
      payload
    );
  }

  openByProvisionUid(
    abstractUid: string,
    givenEntityPermissions: string[],
    provisionGroupUid: string,
    provisionUid: string,
    payload?
  ) {
    const _payload = {
      ...payload,
      provisionUid,
    };
    return this.open(
      abstractUid,
      givenEntityPermissions,
      provisionGroupUid,
      ProvisionBoxInitTypesEnum.PROVISION,
      _payload
    );
  }

  openByMentionUid(
    abstractUid: string,
    givenEntityPermissions: string[],
    provisionGroupUid: string,
    provisionUid: string,
    mentionUid: string,
    payload?
  ) {
    const _payload = {
      ...payload,
      provisionUid,
      mentionUid,
    };
    return this.open(
      abstractUid,
      givenEntityPermissions,
      provisionGroupUid,
      ProvisionBoxInitTypesEnum.MENTION,
      _payload
    );
  }

  openForNewMentionFromReader(
    abstractUid: string,
    givenEntityPermissions: string[],
    provisionGroupUid: string,
    provisionUid: string,
    payload?,
    mentionData?
  ) {
    const _payload = {
      ...payload,
      mentionData,
      provisionUid,
    };
    return this.open(
      abstractUid,
      givenEntityPermissions,
      provisionGroupUid,
      ProvisionBoxInitTypesEnum.NEW_MENTION,
      _payload
    );
  }

  closeByProvisionGroupUid(provisionGroupUid: string) {
    this.close(provisionGroupUid);
  }

  closeByProvisionUid(provisionUid: string) {
    this.close(provisionUid);
  }

  closeByDialogId(id: string) {
    this.close(id);
  }

  closeAll() {
    this.currentDialogIdList.forEach((modalId) => this.close(modalId));
  }

  clearAll() {
    this.currentDialogIdList = [];
  }
}
