/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("QueueRecordSummaryConfigDtoModel")
export class QueueRecordSummaryConfigDtoModel {
  @JsonProperty("emailAddressesToSendRecordSummary", [String], true)
  emailAddressesToSendRecordSummary?: Array<string> = [];

  @JsonProperty("produceAutomaticRecordSummary", Boolean, true)
  produceAutomaticRecordSummary?: boolean = undefined;

  @JsonProperty("recordSummaryOutputDirectoryName", String, true)
  recordSummaryOutputDirectoryName?: string = "";

  @JsonProperty("recordSummaryOutputDirectoryUid", String, true)
  recordSummaryOutputDirectoryUid?: string = "";

  @JsonProperty("useSpecificOutputFolder", Boolean, true)
  useSpecificOutputFolder?: boolean = undefined;
}
