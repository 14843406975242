/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AbstractEventModel")
export class AbstractEventModel {
  @JsonProperty("action", String, true)
  action?: string = "";

  @JsonProperty("date", DateTimeConvertService, true)
  date?: Date | undefined = undefined;

  @JsonProperty("email", String, true)
  email?: string = "";

  @JsonProperty("newValue", String, true)
  newValue?: string = "";

  @JsonProperty("notUiWorthy", Boolean, true)
  notUiWorthy?: boolean = undefined;

  @JsonProperty("oldValue", String, true)
  oldValue?: string = "";

  @JsonProperty("provision", String, true)
  provision?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
