import {
  CreateApiService,
  EditApiService,
} from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { FullValMultiProvisionValueModel } from "@@intelease/web/common/models/multi-provision-value";

@Injectable({
  providedIn: "root",
})
export class EditSnippetService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly PICK_SNIPPET_PROVISION_URL = "/pickSnippetProvisions";

  constructor(
    private editApiService: EditApiService,
    private createApiService: CreateApiService
  ) {}

  /**
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value option user is de-selecting
   * @param snippetUid
   * @return the response from server
   */
  pickSnippetProvisions(
    abstractUid: string,
    provisionUid: string,
    snippetUid: string
  ): Observable<FullValMultiProvisionValueModel> {
    const data = {
      provisionUid: provisionUid,
      snippetUid: snippetUid,
    };
    return this.createApiService.sendRequest(
      EditSnippetService.API_VERSION_1,
      `${EditSnippetService.FINAL_DOC_SETS_URL}/${abstractUid}${EditSnippetService.PICK_SNIPPET_PROVISION_URL}`,
      data,
      "fullVal",
      FullValMultiProvisionValueModel
    );
  }
}
