/* tslint:disable */
/* eslint-disable */
import { NextPrevQueueRecordDtoModel } from "./next-prev-queue-record-dto-model";

/**
 * the request body for completing or rejecting a record review
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RecordCompletedResponseDtoModel")
export class RecordCompletedResponseDtoModel {
  /**
   * the record's description
   */

  @JsonProperty("description", String, true)
  description?: string = "";

  /**
   * the name of the record
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("nextPrevQueueRecordInfo", NextPrevQueueRecordDtoModel, true)
  nextPrevQueueRecordInfo?: NextPrevQueueRecordDtoModel = undefined;

  /**
   * the user-facing record id
   */

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
