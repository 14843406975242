/* tslint:disable */
/* eslint-disable */
import { QueueProcessingConfigDtoModel } from "./queue-processing-config-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateQueueReqDtoModel")
export class CreateQueueReqDtoModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("permissionCategory", Any, true)
  permissionCategory?: "PRIVATE" | "PUBLIC" | "VIEWABLE" = undefined;

  @JsonProperty("processingConfig", QueueProcessingConfigDtoModel, true)
  processingConfig?: QueueProcessingConfigDtoModel = undefined;
}
