import { Component, ViewChild } from "@angular/core";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { InteleaseNotificationService } from "@@intelease/web/intelease/services";
import { Observable, of, Subject } from "rxjs";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { CommonModalService } from "@@intelease/web/common/services";
import { finalize, map } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import {
  FilesUploadComponent,
  UploadedDocSetModel,
} from "@@intelease/web/ui/src/lib/itls-new-upload";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive.service";
import { UploadingDocSetBoxService } from "@@intelease/web/ui/src/lib/itls-new-upload/services/uploading-doc-set-box.service";
import { forEach, keyBy } from "lodash";
import { UploadDocSetService } from "../services/upload-doc-set.service";
import { UploadDocSetResponseModel } from "../models/upload-doc-set-response.model";
import { DriveFacade } from "@@intelease/app-state/drive/src";
import {
  QueueDtoModel,
  UploadDocSetGroupDataResDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { CommonFacade } from "@@intelease/app-state/common/src";
import { SettingItemKeys } from "@@intelease/app-models/settings/src";
import { QueueFacade } from "@@intelease/app-state/queue/src";
import { QUEUE_STATUS } from "@@intelease/app-state/queue/src/lib/models/queue.types";

const ALLOWED_FILE_EXTENSIONS = [
  ".pdf",
  ".docx",
  ".doc",
  ".odt",
  ".ppt",
  ".pptx",
  ".odp",
  ".png",
  ".jpeg",
  ".jpg",
  ".svg",
  ".bmp",
  ".tif",
  ".tiff",
];

const INVALID_FILE_NAMES_CHARACTERS = ['"', "/", "%"];
const MAX_DOCS_ALLOWED = 5;

export enum PanelViewEnum {
  UPLOAD = "UPLOAD",
  SELECT_DEST_FOLDER = "SELECT_DEST_FOLDER",
  SELECT_QUEUE = "SELECT_QUEUE",
}

@Component({
  selector: "intelease-upload-doc-set-modal",
  templateUrl: "./upload-doc-set-modal.component.html",
  styleUrls: ["./upload-doc-set-modal.component.scss"],
})
export class UploadDocSetModalComponent {
  isUploading = false;
  @ViewChild("uploadPanel") uploadPanel: MatExpansionPanel;
  @ViewChild("selectDestFolderPanel", { static: false })
  selectDestFolderPanel: MatExpansionPanel;
  @ViewChild("selectQueuePanel", { static: false })
  selectQueuePanel: MatExpansionPanel;
  @ViewChild("filesUploadComponent")
  filesUploadComponent: FilesUploadComponent;
  PanelView = PanelViewEnum;
  selectedView: PanelViewEnum = PanelViewEnum.UPLOAD;
  selectedQueueUid: string = undefined;
  selectedFolderUid: string;
  isUploadingFolder: boolean;
  uploadedDocSets: UploadedDocSetModel[];
  disallowedFiles: string[] = [];
  allowedFileExtensions = ALLOWED_FILE_EXTENSIONS.join(",");
  allowedDirectoryExtensions = ["/directory", ...ALLOWED_FILE_EXTENSIONS]
    .map((item) => "*" + item)
    .join(", ");

  isQueueRequired$: Observable<boolean> = this.commonFacade
    .getAccountSettingByKey$(SettingItemKeys.FORCE_QUEUE_FOR_UPLOAD)
    .pipe(map((setting) => setting.value));

  constructor(
    private inputValidationService: InputValidationService,
    private inteleaseNotificationService: InteleaseNotificationService,
    private commonModalService: CommonModalService,
    private dialogRef: MatDialogRef<any>,
    private uploadDocSetService: UploadDocSetService,
    private uploadingBoxService: UploadingDocSetBoxService,
    private driveFacade: DriveFacade,
    private commonFacade: CommonFacade,
    private queueFacade: QueueFacade
  ) {}

  onModelChanged(uploadedDocSetModels: UploadedDocSetModel[]) {
    const filteredUploadedDocSetModels = [];
    for (const uploadedDocSet of uploadedDocSetModels) {
      uploadedDocSet.myFiles = uploadedDocSet.myFiles.filter((myFile) => {
        const filename = myFile.file.name;
        const isValid = this.inputValidationService.validTextSuffixesIgnoreCase(
          filename,
          ALLOWED_FILE_EXTENSIONS
        );
        if (!isValid) {
          this.disallowedFiles.push(filename);
        }
        return isValid;
      });
      if (uploadedDocSet.myFiles.length > 0) {
        filteredUploadedDocSetModels.push(uploadedDocSet);
      }
    }
    this.uploadedDocSets = filteredUploadedDocSetModels;
  }

  shouldDisableSection(): boolean {
    return !this.uploadedDocSets?.length || !this.selectedFolderUid;
  }

  onUpload() {
    if (!this.areFilesValid()) {
      return;
    }
    this.confirmWithUserCreditsUsage().subscribe((confirmed) => {
      if (confirmed) {
        this.uploadDocSetData();
      }
    });
  }

  onSelectQueue(queue: QueueDtoModel) {
    this.selectedQueueUid = queue?.uid || undefined;
  }

  private confirmWithUserCreditsUsage(): Observable<boolean> {
    if (!this.selectedQueueUid) {
      return of(true);
    }

    const numCredits = this.uploadedDocSets
      .map((item) => Math.ceil(item.myFiles.length / MAX_DOCS_ALLOWED))
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    if (numCredits === 1) {
      return of(true);
    }
    const numFiles = this.uploadedDocSets
      .map((item) => item.myFiles.length)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const modalData = new ModalInputModel();
    if (this.isUploadingFolder) {
      modalData.payload = {
        customMessage: true,
        params: {
          numCredits: numCredits,
          numRecords: this.uploadedDocSets.length,
          numFiles: numFiles,
        },
        message: "GeneralMessage.FolderUploadConfirm",
        title: "Confirm Upload",
      };
    } else {
      modalData.payload = {
        customMessage: true,
        params: {
          numCredits: numCredits,
          numFiles: numFiles,
        },
        message: "GeneralMessage.FileUploadConfirm",
        title: "Confirm Upload",
      };
    }
    const userConfirmedCreditsUsage = new Subject<boolean>();
    this.commonModalService
      .openGenericOkCancelModal(modalData)
      .afterClosed()
      .subscribe((res) => {
        userConfirmedCreditsUsage.next(
          res.data.exitType === ModalsResponseTypeEnum.CLOSE
        );
      });
    return userConfirmedCreditsUsage;
  }

  private areFilesValid(): boolean {
    for (const uploadedDocSet of this.uploadedDocSets) {
      const originalFileNames = uploadedDocSet.myFiles.map(
        (myFile) => myFile.file.name
      );
      const isValid = this.inputValidationService.validTextsSuffixesIgnoreCase(
        originalFileNames,
        ALLOWED_FILE_EXTENSIONS
      );
      if (!isValid) {
        this.inteleaseNotificationService.openSnackBar(
          `One or more files are not supported! (allowed file extensions: '` +
            ALLOWED_FILE_EXTENSIONS +
            `')`
        );
        return false;
      }
      const fileNames = uploadedDocSet.myFiles.map((myFile) => myFile.name);
      for (const fileName of fileNames) {
        if (
          !INVALID_FILE_NAMES_CHARACTERS.every(
            (invalidFileNameChar) => !fileName.includes(invalidFileNameChar)
          )
        ) {
          this.inteleaseNotificationService.openSnackBar(
            "Found a file with invalid name: " +
              fileName +
              " (these characters are not allowed: " +
              INVALID_FILE_NAMES_CHARACTERS.join(", ") +
              " )"
          );
          return false;
        }
      }
    }
    return true;
  }

  private uploadDocSetData() {
    this.isUploading = true;
    const parentDirectoryUid = ItlsDriveService.getNonRootDirectoryUid(
      this.selectedFolderUid
    );
    this.uploadDocSetService
      .uploadDocSetData({
        directoryUid: parentDirectoryUid,
        uploadedDocSets: this.uploadedDocSets,
        isUploadingFolder: this.isUploadingFolder,
      })
      .pipe(finalize(() => (this.isUploading = false)))
      .subscribe((data) => {
        const { items, selectedDirectoryUid } = data;
        this.openUploadingBox(parentDirectoryUid, data, this.selectedQueueUid);
        this.dialogRef.close();
        const category: any =
          window.location.pathname.split("/")[2]?.toUpperCase() === "FOLDERS"
            ? "OWNED"
            : window.location.pathname.split("/")[2]?.toUpperCase();
        if (selectedDirectoryUid && this.isUploadingFolder) {
          this.driveFacade.addDirectory({
            category,
            directoryUid: parentDirectoryUid,
            data: {
              uid: selectedDirectoryUid,
              name: items[0].directoryPath.split("/")[0],
            },
          });
        }
      });
  }

  private changeCurrentView(newView: PanelViewEnum) {
    this.selectedView = newView;
    switch (this.selectedView) {
      case PanelViewEnum.UPLOAD:
        this.uploadPanel.expanded = true;
        break;
      case PanelViewEnum.SELECT_DEST_FOLDER:
        this.selectDestFolderPanel.expanded = true;
        break;
      case PanelViewEnum.SELECT_QUEUE:
        this.selectQueuePanel.expanded = true;
        break;
    }
  }

  onPanelClosed(closedView: PanelViewEnum) {
    if (closedView === this.selectedView) {
      switch (this.selectedView) {
        case PanelViewEnum.UPLOAD:
          this.changeCurrentView(PanelViewEnum.SELECT_DEST_FOLDER);
          break;
        case PanelViewEnum.SELECT_DEST_FOLDER:
          this.changeCurrentView(PanelViewEnum.SELECT_QUEUE);
          break;
        case PanelViewEnum.SELECT_QUEUE:
          this.changeCurrentView(PanelViewEnum.SELECT_DEST_FOLDER);
          break;
      }
    }
  }

  onPanelOpened(openedView: PanelViewEnum) {
    this.selectedView = openedView;
  }

  onBackClicked() {
    switch (this.selectedView) {
      case PanelViewEnum.SELECT_DEST_FOLDER:
        this.changeCurrentView(PanelViewEnum.UPLOAD);
        break;
      case PanelViewEnum.SELECT_QUEUE:
        this.changeCurrentView(PanelViewEnum.SELECT_DEST_FOLDER);
        break;
    }
  }

  onNextClicked() {
    switch (this.selectedView) {
      case PanelViewEnum.UPLOAD:
        if (this.uploadedDocSets.length) {
          this.changeCurrentView(PanelViewEnum.SELECT_DEST_FOLDER);
        }
        break;
      case PanelViewEnum.SELECT_DEST_FOLDER:
        if (this.selectedFolderUid) {
          this.changeCurrentView(PanelViewEnum.SELECT_QUEUE);
        }
        break;
    }
  }

  private openUploadingBox(
    parentFolderUid: string,
    resp: UploadDocSetGroupDataResDtoModel,
    queueUid?: string
  ) {
    const items = resp.items;
    if (this.isUploadingFolder) {
      //because API returns .pdf with the name for the upload data API
      const itemsWithoutExtensionsInName = items.map((item) => {
        const lastIndexOfDot = item.name.lastIndexOf(".");
        if (lastIndexOfDot === -1) {
          return {
            ...item,
            fullPathAndName: item.directoryPath + "/" + item.name,
          };
        } else {
          return {
            ...item,
            fullPathAndName:
              item.directoryPath + "/" + item.name.substring(0, lastIndexOfDot),
            name: item.name.substring(0, lastIndexOfDot),
          };
        }
      });
      const docSetNameToItem = keyBy(
        itemsWithoutExtensionsInName,
        (item) => item.fullPathAndName
      );
      // we don't store explicit name for root selected folder to upload (selected from file browser)
      //  but instead we can use the first part of the 'filesPath'
      const rootSelectedFolderName =
        this.uploadedDocSets[0].folderPath.split("/")[0];
      const records = this.uploadedDocSets.map((uploadedDocSet) => ({
        uid: docSetNameToItem[
          uploadedDocSet.folderPath + "/" + uploadedDocSet.name
        ].uid,
        name: uploadedDocSet.name,
        files: uploadedDocSet.myFiles,
      }));
      this.uploadingBoxService.uploadFolder(
        parentFolderUid,
        resp.selectedDirectoryUid,
        rootSelectedFolderName,
        records,
        queueUid
      );
    } else {
      const item = items[0];
      const uploadedDocSet = this.uploadedDocSets[0];
      this.uploadingBoxService.uploadDocuments(
        parentFolderUid,
        {
          uid: item.uid,
          name: uploadedDocSet.name,
          files: uploadedDocSet.myFiles,
        },
        queueUid
      );
    }
  }
}
