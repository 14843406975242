import { Observable } from "rxjs";
import { filter, first } from "rxjs/operators";

export function firstNotNill() {
  return function <T>(source: Observable<T>) {
    return source.pipe(
      filter((value) => value !== undefined && value !== null),
      first()
    );
  };
}
