import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  concat,
  filter,
  flatten,
  flattenDepth,
  includes,
  isEmpty,
  isUndefined,
  keys,
  reduce,
  values,
} from "lodash";

import {
  QUEUE_FEATURE_KEY,
  QueueState,
  RecordStatusType,
  QueuePartialState,
} from "./queue.reducer";
import { QUEUE_STATUS } from "./models/queue.types";
import { RecordDtoModel } from "@@intelease/api-models/adex-api-model-src";

// Lookup the 'Queue' feature state managed by NgRx
const getQueueState = createFeatureSelector<QueuePartialState, QueueState>(
  QUEUE_FEATURE_KEY
);

export const getMyQueueList = createSelector(
  getQueueState,
  (state: QueueState) => state.myQueueList
);

export const getUsableQueueList = createSelector(
  getQueueState,
  (state: QueueState) => state.usableQueueList
);

export const getOthersQueueList = createSelector(
  getQueueState,
  (state: QueueState) => state.othersQueueList
);

export const getMyQueueListLoadingError = createSelector(
  getQueueState,
  (state: QueueState) => state.errorLoadingMyQueueList
);

export const getUsableQueueListLoadingError = createSelector(
  getQueueState,
  (state: QueueState) => state.errorLoadingUsableQueueList
);

export const getOthersQueueListLoadingError = createSelector(
  getQueueState,
  (state: QueueState) => state.errorLoadingOthersQueueList
);

export const getCurrentQueueInfo = createSelector(
  getQueueState,
  (state: QueueState) => state.currentQueue
);

export const getCurrentQueueLoadingError = createSelector(
  getQueueState,
  (state: QueueState) => state.errorLoadingCurrentQueue
);

export const getIsCurrentQueueLoaded = createSelector(
  getQueueState,
  (state: QueueState) =>
    state.isCurrentQueueLoaded || values(state.currentQueue?.records).length > 0
);

export const getIsSeachActive = createSelector(
  getQueueState,
  (state: QueueState) => state.isSearchActive
);

export const getQueueRecordsOfStatus = (recordStatus: RecordStatusType) => {
  return createSelector(getQueueState, (state: QueueState) => {
    return getRecords(state, recordStatus);
  });
};

export const currentQueuePermissions = createSelector(
  getQueueState,
  (state: QueueState) => state.currentQueue?.permissions || []
);

export const getQueueRecordsLoadedNumbers = createSelector(
  getQueueState,
  (state: QueueState) => {
    return keys(state.currentQueue?.records).reduce(
      (result, item) => ({
        ...result,
        [item === "READY" ? "TO_REVIEW" : item]: state.currentQueue?.records
          ? getRecords(state, item).length
          : 0,
      }),
      {}
    );
  }
);

export const autoCompleteSearch = createSelector(
  getQueueState,
  (state: QueueState) => getRecords(state)
);

export const getSearchText = createSelector(
  getQueueState,
  (state: QueueState) => state.searchText || ""
);

export const myQueueListLoadStatus = createSelector(
  getQueueState,
  (state: QueueState) => ({
    myQueueListLoading: state.myQueueListLoading,
    myQueueListLoaded: state.myQueueListLoaded,
    myQueueListFailed: state.myQueueListFailed,
  })
);

export const othersQueueListLoadStatus = createSelector(
  getQueueState,
  (state: QueueState) => ({
    othersQueueListLoading: state.othersQueueListLoading,
    othersQueueListLoaded: state.othersQueueListLoaded,
    othersQueueListFailed: state.othersQueueListFailed,
  })
);

const getRecords = (
  state: QueueState,
  queueStage?: QUEUE_STATUS | string
): RecordDtoModel[] => {
  if (isUndefined(queueStage)) {
    if (!state.isSearchActive) {
      return values(state.currentQueue?.records) as any;
    }

    if (!state.currentQueue?.records || isEmpty(state.searchText)) {
      return [];
    }

    return reduce(
      state.currentQueue.records,
      (result, _, key) => {
        const _records = getRecords(state, key);

        if (isEmpty(_records)) return result;

        return {
          ...result,
          [key]: _records,
        };
      },
      {}
    ) as any;
  }

  if (!state.currentQueue?.records?.[queueStage]) {
    return [];
  }

  if (!state.isSearchActive || isEmpty(state.searchText)) {
    return state.currentQueue.records[queueStage];
  }

  return filter(state.currentQueue?.records[queueStage], ({ name }) =>
    includes(name?.toLocaleLowerCase(), state.searchText?.toLocaleLowerCase())
  );
};
