import { JsonObject, JsonProperty } from "json2typescript";
import { LocalDateValue } from "@@intelease/web/grpc/dist/messages_pb";

@JsonObject("RentScheduleCalculationResponseModel")
export class RentScheduleCalculationResponseModel {
  @JsonProperty("startDate", LocalDateValue)
  startDate: LocalDateValue;

  @JsonProperty("endDate", LocalDateValue)
  endDate: LocalDateValue;

  @JsonProperty("values", [Number])
  values: number[] = [];
}
