import { Injectable } from "@angular/core";
import { ServerConfigsModel } from "@@intelease/app-models/common";
import { RestClient } from "@@intelease/web/utils";
import { DocSetCategoryModel } from "@common/models";
import { Subject } from "rxjs";
import { LocalStorageService } from "@@intelease/app-services/common";
import { LbxRoleDto } from "@@intelease/app-models/common/src/lib/global/lbx-role-type.model";

@Injectable({
  providedIn: "root",
})
export class ServerConfigsService {
  public configLoaded$: Subject<ServerConfigsModel> =
    new Subject<ServerConfigsModel>();

  private serverConfigs: ServerConfigsModel;
  private keyToDocSetCategoryModel: Map<string, DocSetCategoryModel> = new Map<
    string,
    DocSetCategoryModel
  >();
  private allLbxRoles: Array<LbxRoleDto>;

  constructor(private restClient: RestClient) {}

  initializeConfigs() {
    const serverConfigs = LocalStorageService.getServerConfigs();
    if (serverConfigs) {
      this.loadConfigs(serverConfigs);
    } else {
      this.fetchConfigs();
    }
  }

  private fetchConfigs() {
    this.restClient
      .sendGetRequest(
        "/v1",
        "/application/config",
        ServerConfigsModel,
        {},
        (resp) => resp.data
      )
      .subscribe((resp) => {
        LocalStorageService.storeServerConfigs(resp);
        this.loadConfigs(resp);
      });
  }

  private loadConfigs(serverConfigs: ServerConfigsModel) {
    this.serverConfigs = serverConfigs;
    this.keyToDocSetCategoryModel.clear();
    for (const docSetCategory of this.serverConfigs.allDocSetCategories) {
      this.keyToDocSetCategoryModel.set(docSetCategory.value, docSetCategory);
    }
    this.allLbxRoles = serverConfigs.allLbxRoles;
    this.configLoaded$.next(serverConfigs);
  }

  getConfigs() {
    return this.serverConfigs;
  }

  getDocSetCategoryView(key: string): DocSetCategoryModel {
    return this.keyToDocSetCategoryModel.get(key);
  }

  getAllLbxRoles(): Array<LbxRoleDto> {
    return this.allLbxRoles || [];
  }
}
