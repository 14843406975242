import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WebNotificationsRoutingModule } from "./web-notifications-routing.module";
import { WebNotificationsComponent } from "./web-notifications.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AppStateNotificationsModule } from "@@intelease/app-state/notifications";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatBadgeModule } from "@angular/material/badge";
import { NgStringPipesModule } from "ngx-pipes";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MatDividerModule } from "@angular/material/divider";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";

const MATERIAL_MODULES = [
  MatMenuModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatTooltipModule,
  MatTabsModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatBadgeModule,
  MatDividerModule,
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    WebNotificationsRoutingModule,
    ...MATERIAL_MODULES,
    AppStateNotificationsModule,
    NgStringPipesModule,
    InfiniteScrollModule,
    InteleaseCommonModule,
  ],
  declarations: [WebNotificationsComponent],
  exports: [WebNotificationsComponent],
})
export class WebNotificationsModule {}
