/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RevertRecordReviewStageReqDtoModel")
export class RevertRecordReviewStageReqDtoModel {
  /**
   * the stage of the queue
   */

  @JsonProperty("queueStage", Any, true)
  queueStage?:
    | "PROCESSING"
    | "READY"
    | "IN_REVIEW"
    | "NEED_QA_REVIEW"
    | "IN_QA_REVIEW"
    | "COMPLETED"
    | "FAILED" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
