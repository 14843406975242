/* tslint:disable */
/* eslint-disable */
import { CustomTagViewModel } from "./custom-tag-view-model";
import { DocSetCategoryViewModel } from "./doc-set-category-view-model";
import { QueueInfoViewModel } from "./queue-info-view-model";
import { RecordLockInfoDtoModel } from "./record-lock-info-dto-model";
import { RecordPathModel } from "./record-path-model";
import { RelatedDocumentModel } from "./related-document-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RelatedDocsAbstractViewModel")
export class RelatedDocsAbstractViewModel {
  @JsonProperty("abstractCreationDate", DateTimeConvertService, true)
  abstractCreationDate?: Date | undefined = undefined;

  @JsonProperty("abstractFilename", String, true)
  abstractFilename?: string = "";

  @JsonProperty("abstractName", String, true)
  abstractName?: string = "";

  @JsonProperty("customTags", [CustomTagViewModel], true)
  customTags?: Array<CustomTagViewModel> = [];

  @JsonProperty("defaultDoc", RelatedDocumentModel, true)
  defaultDoc?: RelatedDocumentModel = undefined;

  @JsonProperty("docSetCategory", DocSetCategoryViewModel, true)
  docSetCategory?: DocSetCategoryViewModel = undefined;

  @JsonProperty("documents", [RelatedDocumentModel], true)
  documents?: Array<RelatedDocumentModel> = [];

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("lockInfo", RecordLockInfoDtoModel, true)
  lockInfo?: RecordLockInfoDtoModel = undefined;

  @JsonProperty("queueInfoView", QueueInfoViewModel, true)
  queueInfoView?: QueueInfoViewModel = undefined;

  @JsonProperty("suiteVersionAbstractId", RecordPathModel, true)
  suiteVersionAbstractId?: RecordPathModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
