/* tslint:disable */
/* eslint-disable */
import { FullCountryViewModel } from "./full-country-view-model";
import { FullCurrencyViewModel } from "./full-currency-view-model";
import { FullDoubleProvisionUnitViewModel } from "./full-double-provision-unit-view-model";
import { FullProperNounTypeViewModel } from "./full-proper-noun-type-view-model";
import { FullProvisionTypeViewModel } from "./full-provision-type-view-model";
import { FullStateViewModel } from "./full-state-view-model";
import { NumericalUnitCategoryDtoModel } from "./numerical-unit-category-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionsMetadataModel")
export class ProvisionsMetadataModel {
  @JsonProperty("countries", [FullCountryViewModel], true)
  countries?: Array<FullCountryViewModel> = [];

  @JsonProperty("currencies", [FullCurrencyViewModel], true)
  currencies?: Array<FullCurrencyViewModel> = [];

  @JsonProperty(
    "numericalUnitCategories",
    [NumericalUnitCategoryDtoModel],
    true
  )
  numericalUnitCategories?: Array<NumericalUnitCategoryDtoModel> = [];

  @JsonProperty("properNounTypes", [FullProperNounTypeViewModel], true)
  properNounTypes?: Array<FullProperNounTypeViewModel> = [];

  @JsonProperty("singleCatProvHtmlNameToPossibleValues", Any, true)
  singleCatProvHtmlNameToPossibleValues?: {
    [key: string]: Array<string>;
  } = {};

  @JsonProperty("states", [FullStateViewModel], true)
  states?: Array<FullStateViewModel> = [];

  @JsonProperty("types", [FullProvisionTypeViewModel], true)
  types?: Array<FullProvisionTypeViewModel> = [];

  @JsonProperty("units", [FullDoubleProvisionUnitViewModel], true)
  units?: Array<FullDoubleProvisionUnitViewModel> = [];
}
