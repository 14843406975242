/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ExportIssueRequestModel")
export class ExportIssueRequestModel {
  @JsonProperty("emptyProvisionText", String, true)
  emptyProvisionText?: string = "";

  @JsonProperty("excludeEmptyProvisions", Boolean, true)
  excludeEmptyProvisions?: boolean = undefined;

  @JsonProperty("exportType", Any, true)
  exportType?:
    | "DOCX"
    | "XLSX"
    | "CSV"
    | "HTML"
    | "PDF"
    | "TSV"
    | "ZIP"
    | "WT_TSV" = undefined;

  @JsonProperty("includeAnnotatedDocs", Boolean, true)
  includeAnnotatedDocs?: boolean = undefined;

  @JsonProperty("includeDocumentChronology", Boolean, true)
  includeDocumentChronology?: boolean = undefined;

  @JsonProperty("includeNotes", Boolean, true)
  includeNotes?: boolean = undefined;

  @JsonProperty("includeSectionHeaders", Boolean, true)
  includeSectionHeaders?: boolean = undefined;

  @JsonProperty("includeSourceAttribution", Boolean, true)
  includeSourceAttribution?: boolean = undefined;
}
