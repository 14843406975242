import { EditApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { ProvisionFunctionsInterface } from "@@intelease/web/abstraction-page";
import { StringHelper } from "@@intelease/web/intelease/utils";
import { PartialValProvisionValueModel } from "@@intelease/web/common/models/provision-value";
import { ProvisionValueConvertService } from "../../../../../common/services/provision-value-convert.service";
import { ProvisionFormatValuesConvertService } from "../../../../../common/services/provision-format-values-convert.service";
import { cloneDeep, isArray, isEmpty, isObject, isUndefined } from "lodash";
import { DateUtil } from "@@intelease/web/utils";
import { NESTED_MENTION_ATTRIBUTIONS_FIELD_KEY } from "@@intelease/web/intelease/constants";
import { NestedMentionAttribution } from "@@intelease/web/abstract-review/src/lib/components/modals/attributions-details-modal/attributions.model";

@Injectable({
  providedIn: "root",
})
export class EditProvisionValueService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly API_VERSION_2 = "/v2";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly PROVISIONS_URL = "/provisions";
  private static readonly VALUES_URL = "/values";
  private static readonly TEXT_VALUE_URL = "/textValue";
  private static readonly VALUE_URL = "/value";
  private static readonly SELECT_URL = "/select";

  constructor(private editApiService: EditApiService) {}

  /**
   * call this API to save the updated provision value.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value user is changing
   * @param provisionValueUid - the option's id being updated
   * @param notes - the notes associated with this provision
   * @param sectionHeader - the section header associated with this provision
   * @param value - the new, user-edited value for this provision (data model is from provision-type directory)
   * @param type
   * @param formatValues
   * @param view - the view of the provision value to return
   * @param classRef - the class object to return
   * @return the updated single option in the upper-right provision box
   */
  updateProvisionValueOption<T extends object>(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string,
    notes: string,
    sectionHeader: string,
    value: any,
    type: string, // @FIXME: (Mohammad.haji)this have to be provision types<Make an enum for all of them>
    formatValues: any,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    const data = {
      notes: notes,
      sectionHeader: sectionHeader,
      provisionValue:
        ProvisionValueConvertService.serializeProvisionValue(value),
      type,
      formatValues:
        ProvisionFormatValuesConvertService.serializeFormatValues(formatValues),
    };
    return this.editApiService.sendRequest(
      EditProvisionValueService.API_VERSION_1,
      `${EditProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}${EditProvisionValueService.PROVISIONS_URL}` +
        `/${provisionUid}${EditProvisionValueService.VALUES_URL}`,
      provisionValueUid,
      data,
      false,
      view,
      classRef,
      EditProvisionValueService.VALUE_URL
    );
  }

  /**
   * call this API to save the updated provision text value.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value user is changing
   * @param provisionValueUid - the option's id being updated
   * @param notes - the notes associated with this provision
   * @param sectionHeader - the section header associated with this provision
   * @param textValue - the new, user-edited text value for this provision
   * @param view - the view of the provision value to return
   * @param classRef - the class object to return
   * @return the updated single option in the upper-right provision box
   */
  updateProvisionTextValueOption<T extends object>(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string,
    notes: string,
    sectionHeader: string,
    textValue: string,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    const data = {
      notes: notes,
      sectionHeader: sectionHeader,
      provisionTextValue: textValue,
    };
    return this.editApiService.sendRequest(
      EditProvisionValueService.API_VERSION_1,
      `${EditProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}${EditProvisionValueService.PROVISIONS_URL}` +
        `/${provisionUid}${EditProvisionValueService.VALUES_URL}`,
      provisionValueUid,
      data,
      false,
      view,
      classRef,
      EditProvisionValueService.TEXT_VALUE_URL
    );
  }

  /**
   * When user updates a text provision value, call this API.
   *
   * @param abstractUid - the uid of the abstract
   * @param provisionUid - the uid of the provisionInfo
   * @param provisionValueUid - the uid of the specific provision value option being updated
   * @param newTextValue - the new text value user wants to save
   */
  updateProvisionTextValueNoResponse(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string,
    newTextValue: string
  ): void {
    const data = {
      provisionTextValue: newTextValue,
    };
    this.editApiService.sendRequestNoResponse(
      EditProvisionValueService.API_VERSION_1,
      `${EditProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}` +
        `${EditProvisionValueService.PROVISIONS_URL}/${provisionUid}${EditProvisionValueService.VALUES_URL}`,
      provisionValueUid,
      data,
      false,
      EditProvisionValueService.TEXT_VALUE_URL
    );
  }

  /**
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value option user is choosing
   * @param provisionValueUid - the provision value option id that the user is selecting
   * @return response from server
   */
  selectProvisionValueOptionNoView(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string
  ): Observable<ServerResponseModel> {
    const data = {
      deselect: false,
    };
    return this.editApiService.sendRequestNoView(
      EditProvisionValueService.API_VERSION_1,
      `${EditProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}${EditProvisionValueService.PROVISIONS_URL}` +
        `/${provisionUid}${EditProvisionValueService.VALUES_URL}`,
      provisionValueUid,
      data,
      true,
      EditProvisionValueService.SELECT_URL
    );
  }

  /**
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value option user is de-selecting
   * @param provisionValueUid - the provision value option id that the user is de-selecting
   * @return the response from server
   */
  deSelectProvisionValueOptionNoView(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string
  ): Observable<ServerResponseModel> {
    const data = {
      deselect: true,
    };
    return this.editApiService.sendRequestNoView(
      EditProvisionValueService.API_VERSION_1,
      `${EditProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}${EditProvisionValueService.PROVISIONS_URL}` +
        `/${provisionUid}${EditProvisionValueService.VALUES_URL}`,
      provisionValueUid,
      data,
      true,
      EditProvisionValueService.SELECT_URL
    );
  }

  // TODO(mohammad): Probably should merge this function with prepareProvisionValueForReview, but currently they're different in some ways.
  prepareProvisionValueForRecord(
    provisionType: string
  ): ProvisionFunctionsInterface {
    const provisionsActionsMap = {
      STRING: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return {};
        },
      },
      PARSING_FAILED: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model.value || documentProvision.textValue;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return {};
        },
      },
      DATE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.model.year) {
            documentProvision.value = [];
            documentProvision.value.push(
              documentProvision.model.year.getFullYear()
            );
            documentProvision.value.push(
              documentProvision.model.year.getMonth()
            );
            documentProvision.value.push(
              documentProvision.model.year.getDate()
            );
          }
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return {};
        },
      },

      DOUBLE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (!documentProvision.value) {
            documentProvision.value = {};
          }
          documentProvision.value.unit =
            documentProvision.model.unit || undefined;
          documentProvision.value.value = StringHelper.removeNoneDigitChars(
            documentProvision.model.value.toString()
          );
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              unit: documentProvision.value.unit,
              value: documentProvision.value.value,
            };
          }
          return documentProvision.value;
        },
      },
      COMMON_NOUN: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              value: documentProvision.value.value,
            };
          }
          return {};
        },
      },
      MONEY: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (isUndefined(documentProvision.value)) {
            documentProvision.value = {};
          }
          documentProvision.value.currency =
            typeof documentProvision.model.currency !== "string"
              ? documentProvision.model.currency.value
              : documentProvision.model.currency;
          documentProvision.model.value = StringHelper.removeNoneDigitChars(
            documentProvision.model.value.toString()
          );
          documentProvision.value.majorValue = Math.floor(
            documentProvision.model.value
          );
          documentProvision.value.subValue = Math.round(
            (documentProvision.model.value -
              documentProvision.value.majorValue) *
              100
          );
          // // TODO(apoorv): actually convert based on currency (e.g. 100, 1000, etc.)
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              currency: documentProvision.value.currency,
              value:
                documentProvision.value.majorValue +
                documentProvision.value.subValue / 100.0,
            };
          }
          return {};
        },
      },
      YEAR: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return cloneDeep(documentProvision.value);
          }
          return {};
        },
      },
      PROPER_NOUN: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          let val = "";
          if (documentProvision.value) {
            val = documentProvision.value.value;
          }
          return {
            value: val,
          };
        },
      },
      ADDRESS: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          const { streetAddress, suite, city, state, country, postalCode } =
            documentProvision.model;
          const _state =
            state && state.value
              ? documentProvision.model.state.value
              : documentProvision.model.state;
          const _country =
            country && documentProvision.model.country.value
              ? documentProvision.model.country.value
              : documentProvision.model.country;
          return {
            streetAddress: isEmpty(streetAddress) ? undefined : streetAddress,
            suite: isEmpty(suite) ? undefined : suite,
            state: isEmpty(_state) ? undefined : _state,
            city: isEmpty(city) ? undefined : city,
            postalCode: isEmpty(postalCode) ? undefined : postalCode,
            country: isEmpty(_country) ? undefined : _country,
          };
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            const { streetAddress, suite, city, state, country, postalCode } =
              documentProvision.value;
            return {
              streetAddress,
              suite,
              city,
              state,
              country,
              postalCode,
            };
          } else {
            return "";
          }
        },
      },
      EMAIL: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.model.localPart) {
            const parts = documentProvision.model.localPart.split("@");
            return {
              localPart: parts[0],
              domain: parts[1],
            };
          }
          return {
            localPart: documentProvision.value,
            domain: "",
          };
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              localPart:
                documentProvision.value.localPart +
                "@" +
                documentProvision.value.domain,
            };
          }
          return {
            localPart: "",
          };
        },
      },
      PHONE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          documentProvision.value = documentProvision.model.phoneNumber;
          return { phoneNumber: documentProvision.value };
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              phoneNumber: documentProvision.value.phoneNumber,
            };
          }
          return {};
        },
      },
      LOCAL_DATE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return {
            value: DateUtil.serializeToExactDate(
              new Date(documentProvision.model.value)
            ),
          };
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            if (DateUtil.isSerializedDate(documentProvision.value.value)) {
              return {
                value: DateUtil.deserializeExactDate(
                  documentProvision.value.value
                ),
              };
            }
            return {
              value: new Date(documentProvision.value.value),
            };
          }
          return {};
        },
      },
      MULTIPLE_CAT: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          const model = documentProvision.model;
          const value = [];
          if (typeof model.value !== "string" && model.value.length) {
            model.value.forEach((item) => {
              value.push(item.value || item);
            });
          }
          documentProvision.value = { value };
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              value: documentProvision.value.value,
            };
          }
          return {};
        },
      },
      SINGLE_CAT: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          const model = documentProvision.model;
          documentProvision.value =
            typeof model.value !== "string"
              ? { value: model.value.value }
              : { value: model };
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              value: documentProvision.value.value,
            };
          }
          return {};
        },
      },
      TABLE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              sampleRow: documentProvision.value.headers.reduce(
                (rows, header, index) => ({
                  ...rows,
                  [`col_${index}`]: "",
                }),
                {}
              ),
              headerRow: documentProvision.value.headers.reduce(
                (rows, header, index) => ({
                  ...rows,
                  [`col_${index}`]: isObject(header)
                    ? (header as any).name
                    : header,
                }),
                {}
              ),
              headers: documentProvision.value.headers.map((header, index) => {
                return {
                  prop: "col_" + index,
                  name: isObject(header)
                    ? // @ts-ignore
                      header.name
                    : header,
                  editable: true,
                };
              }),
              rows: documentProvision.value.rows.map((rowItems) => {
                if (isArray(rowItems)) {
                  return rowItems.reduce(
                    (rows, row, index) => ({
                      ...rows,
                      [`col_${index}`]: row,
                    }),
                    {}
                  );
                }
                return rowItems;
              }),
            };
          }

          if (documentProvision.value) {
            const tableModel: any = documentProvision.value;
            const headerRowObj = {};
            tableModel.sampleRow = {};
            tableModel.headers.map((header, index) => {
              headerRowObj["col_" + index] = isObject(header)
                ? // @ts-ignore
                  header.name
                : header;
              tableModel.sampleRow["col_" + index] = "";
            });
            tableModel.headerRow = headerRowObj;
            tableModel.headers = tableModel.headers.map((header, index) => {
              return {
                prop: "col_" + index,
                name: isObject(header)
                  ? // @ts-ignore
                    header.name
                  : header,
                editable: true,
              };
            });
            tableModel.rows = tableModel.rows.map((rowItems) => {
              const rowObj = {};
              if (isArray(rowItems)) {
                rowItems.map((row, index) => {
                  rowObj["col_" + index] = row;
                });
                return rowObj;
              }
              return rowItems;
            });
            return tableModel;
          }
        },
      },
    };
    return provisionsActionsMap[provisionType];
  }

  // TODO(mohammad): Probably should merge this function with prepareProvisionValueForRecord, but currently they're different in some ways.
  prepareProvisionValueForReview(
    provisionType: string
  ): ProvisionFunctionsInterface {
    const provisionsActionsMap = {
      STRING: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return {};
        },
      },
      PARSING_FAILED: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model.value || documentProvision.textValue;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return {};
        },
      },
      DATE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.model.year) {
            documentProvision.value = [];
            documentProvision.value.push(
              documentProvision.model.year.getFullYear()
            );
            documentProvision.value.push(
              documentProvision.model.year.getMonth()
            );
            documentProvision.value.push(
              documentProvision.model.year.getDate()
            );
          }
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return {};
        },
      },

      DOUBLE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (!documentProvision.value) {
            documentProvision.value = {};
          }

          function getUnit() {
            if (documentProvision.model.unit) {
              return typeof documentProvision.model.unit !== "string"
                ? documentProvision.model.unit.value
                : documentProvision.model.unit;
            } else {
              return null;
            }
          }

          return {
            unit: getUnit(),
            value: StringHelper.removeNoneDigitChars(
              documentProvision.model.value.toString()
            ),
          };
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              unit: documentProvision.value.unit,
              value: documentProvision.value.value,
            };
          }
          return documentProvision.value;
        },
      },
      COMMON_NOUN: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              value: documentProvision.value.value,
            };
          }
          return {};
        },
      },
      MONEY: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (!documentProvision.value) {
            documentProvision.value = {};
          }

          function getCurrency() {
            if (documentProvision.model.currency) {
              return typeof documentProvision.model.currency !== "string"
                ? documentProvision.model.currency.value
                : documentProvision.model.currency;
            } else {
              return null;
            }
          }

          function getMajorAndSubValue() {
            const value: number = StringHelper.removeNoneDigitChars(
              documentProvision.model.value.toString()
            ) as unknown as number;

            return {
              majorValue: Math.floor(value),
              subValue: Math.round((value - Math.floor(value)) * 100),
            };
          }

          // // TODO(apoorv): actually convert based on currency (e.g. 100, 1000, etc.)
          return {
            currency: getCurrency(),
            ...getMajorAndSubValue(),
          };
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              currency: documentProvision.value.currency,
              value:
                documentProvision.value.majorValue +
                documentProvision.value.subValue / 100.0,
            };
          }
          return {};
        },
      },
      YEAR: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              year: documentProvision.value.year,
            };
          }
          return {};
        },
      },
      PROPER_NOUN: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          let val = "";
          if (documentProvision.value) {
            val = documentProvision.value.value;
          }
          if (val) {
            return {
              value: val,
            };
          }
          return {};
        },
      },
      ADDRESS: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          documentProvision.value = documentProvision.model;
          documentProvision.value.state =
            documentProvision.model.state && documentProvision.model.state.value
              ? documentProvision.model.state.value
              : documentProvision.model.state;
          documentProvision.value.country =
            documentProvision.model.country &&
            documentProvision.model.country.value
              ? documentProvision.model.country.value
              : documentProvision.model.country;
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            const { streetAddress, suite, city, state, country, postalCode } =
              documentProvision.value;
            return {
              streetAddress,
              suite,
              city,
              state,
              country,
              postalCode,
            };
          } else {
            return "";
          }
        },
      },
      EMAIL: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.model.localPart) {
            const parts = documentProvision.model.localPart.split("@");
            return {
              localPart: parts[0] || "",
              domain: parts[1] || "",
            };
          }
          return {
            localPart: documentProvision.value,
            domain: "",
          };
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              localPart:
                documentProvision.value.localPart +
                "@" +
                documentProvision.value.domain,
            };
          }
          return {};
        },
      },
      PHONE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          documentProvision.value = documentProvision.model.phoneNumber;
          return { phoneNumber: documentProvision.value };
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              phoneNumber: documentProvision.value.phoneNumber,
            };
          }
          return {};
        },
      },
      LOCAL_DATE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          // documentProvision.model.value = DateConvertService.serializeDate(documentProvision.model.value)
          // documentProvision.model.value = DateTimeConvertService.serializeDateTime(documentProvision.model.value)
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              // value: DateTimeConvertService.deserializeDateTime(documentProvision.value.value),
              value: new Date(documentProvision.value.value),
            };
          }
          return {};
        },
      },
      MULTIPLE_CAT: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          const model = documentProvision.model;
          const value = [];
          if (typeof model.value !== "string" && model.value.length) {
            model.value.forEach((item) => {
              value.push(item.value || item);
            });
          }
          documentProvision.value = { value };
          // documentProvision.value = (typeof model.value !== 'string') ? {value: model.value.value} : {value: model.value};
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              value: documentProvision.value.value,
            };
          }
          return {};
        },
      },
      SINGLE_CAT: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          const model = documentProvision.model;
          documentProvision.value =
            typeof model.value !== "string"
              ? { value: model.value.value }
              : { value: model.value };
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              value: documentProvision.value.value,
            };
          }
          return {};
        },
      },
      TABLE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              sampleRow: documentProvision.value.headers.reduce(
                (rows, header, index) => ({
                  ...rows,
                  [`col_${index}`]: "",
                }),
                {}
              ),
              headerRow: documentProvision.value.headers.reduce(
                (rows, header, index) => ({
                  ...rows,
                  [`col_${index}`]: isObject(header)
                    ? (header as any).name
                    : header,
                }),
                {}
              ),
              headers: documentProvision.value.headers.map((header, index) => {
                return {
                  prop: "col_" + index,
                  name: isObject(header)
                    ? // @ts-ignore
                      header.name
                    : header,
                  editable: true,
                };
              }),
              rows: documentProvision.value.rows.map((rowItems) => {
                if (isArray(rowItems)) {
                  return rowItems.reduce(
                    (rows, row, index) => ({
                      ...rows,
                      [`col_${index}`]: row,
                    }),
                    {}
                  );
                }
                return rowItems;
              }),
            };
          }
          // if (documentProvision.value) {
          //     const tableModel: any = documentProvision.value
          //     const headerRowObj = {}
          //     tableModel.sampleRow = {}
          //     tableModel.headers.map((header, index) => {
          //         headerRowObj['col_' + index] = isObject(header)
          //             ? // @ts-ignore
          //               header.name
          //             : header
          //         tableModel.sampleRow['col_' + index] = ''
          //     })
          //     tableModel.headerRow = headerRowObj
          //     tableModel.headers = tableModel.headers.map(
          //         (header, index) => {
          //             return {
          //                 prop: 'col_' + index,
          //                 name: isObject(header)
          //                     ? // @ts-ignore
          //                       header.name
          //                     : header,
          //                 editable: true,
          //             }
          //         },
          //     )
          //     tableModel.rows = tableModel.rows.map((rowItems) => {
          //         const rowObj = {}
          //         if (isArray(rowItems)) {
          //             rowItems.map((row, index) => {
          //                 rowObj['col_' + index] = row
          //             })
          //             return rowObj
          //         }
          //         return rowItems
          //     })
          //     return tableModel
          // }
        },
      },
      NESTED_GROUP: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          const {
            id,
            keyToMention,
            keyToMentionMetadata,
            attributions,
            type,
            ...others
          } = documentProvision.model;
          const updatedKeyToMention = { ...keyToMention };
          if (keyToMention) {
            Object.keys(keyToMention).forEach((key) => {
              updatedKeyToMention[key] = {
                ...updatedKeyToMention[key],
                value: others[key],
              };
            });
          }
          if (others) {
            Object.keys(others).forEach((key) => {
              updatedKeyToMention[key] = {
                ...updatedKeyToMention[key],
                value: others[key],
              };
            });
          }
          documentProvision.value = {
            id,
            keyToMention: updatedKeyToMention,
          };
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return documentProvision.value;
          }
          return {};
        },
      },
    };
    return provisionsActionsMap[provisionType];
  }

  prepareProvisionValueForReviewNew(
    provisionType: string
  ): ProvisionFunctionsInterface {
    const provisionsActionsMap = {
      STRING: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return {};
        },
      },
      PARSING_FAILED: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model.value || documentProvision.textValue;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return {};
        },
      },
      DATE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.model.year) {
            documentProvision.value = [];
            documentProvision.value.push(
              documentProvision.model.year.getFullYear()
            );
            documentProvision.value.push(
              documentProvision.model.year.getMonth()
            );
            documentProvision.value.push(
              documentProvision.model.year.getDate()
            );
          }
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return {};
        },
      },

      DOUBLE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (!documentProvision.value) {
            documentProvision.value = {};
          }
          if (documentProvision.model.unit) {
            documentProvision.value.unit =
              typeof documentProvision.model.unit !== "string"
                ? documentProvision.model.unit.value
                : documentProvision.model.unit;
          } else {
            documentProvision.value.unit = null;
          }
          documentProvision.value.value = StringHelper.removeNoneDigitChars(
            documentProvision.model.value.toString()
          );
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              unit: documentProvision.value.unit,
              value: documentProvision.value.value,
            };
          }
          return documentProvision.value;
        },
      },
      COMMON_NOUN: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              value: documentProvision.value.value,
            };
          }
          return {};
        },
      },
      MONEY: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (!documentProvision.value) {
            documentProvision.value = {};
          }
          if (documentProvision.model.currency) {
            documentProvision.value.currency =
              typeof documentProvision.model.currency !== "string"
                ? documentProvision.model.currency.value
                : documentProvision.model.currency;
          } else {
            documentProvision.value.currency = null;
          }
          documentProvision.model.value = StringHelper.removeNoneDigitChars(
            documentProvision.model.value.toString()
          );
          documentProvision.value.majorValue = Math.floor(
            documentProvision.model.value
          );
          documentProvision.value.subValue = Math.round(
            (documentProvision.model.value -
              documentProvision.value.majorValue) *
              100
          );
          // // TODO(apoorv): actually convert based on currency (e.g. 100, 1000, etc.)
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              currency: documentProvision.value.currency,
              value:
                documentProvision.value.majorValue +
                documentProvision.value.subValue / 100.0,
            };
          }
          return {};
        },
      },
      YEAR: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              year: documentProvision.value.year,
            };
          }
          return {};
        },
      },
      PROPER_NOUN: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          let val = "";
          if (documentProvision.value) {
            val = documentProvision.value.value;
          }
          if (val) {
            return {
              value: val,
            };
          }
          return {};
        },
      },
      ADDRESS: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          documentProvision.value = documentProvision.model;
          documentProvision.value.state =
            documentProvision.model.state && documentProvision.model.state.value
              ? documentProvision.model.state.value
              : documentProvision.model.state;
          documentProvision.value.country =
            documentProvision.model.country &&
            documentProvision.model.country.value
              ? documentProvision.model.country.value
              : documentProvision.model.country;
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            const { streetAddress, suite, city, state, country, postalCode } =
              documentProvision.value;
            return {
              streetAddress,
              suite,
              city,
              state,
              country,
              postalCode,
            };
          } else {
            return "";
          }
        },
      },
      EMAIL: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.model.localPart) {
            const parts = documentProvision.model.localPart.split("@");
            return {
              localPart: parts[0] || "",
              domain: parts[1] || "",
            };
          }
          return {
            localPart: documentProvision.value,
            domain: "",
          };
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              localPart:
                documentProvision.value.localPart +
                "@" +
                documentProvision.value.domain,
            };
          }
          return {};
        },
      },
      PHONE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          documentProvision.value = documentProvision.model.phoneNumber;
          return { phoneNumber: documentProvision.value };
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              phoneNumber: documentProvision.value.phoneNumber,
            };
          }
          return {};
        },
      },
      LOCAL_DATE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          // documentProvision.model.value = DateConvertService.serializeDate(documentProvision.model.value)
          // documentProvision.model.value = DateTimeConvertService.serializeDateTime(documentProvision.model.value)
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            const dataObject = documentProvision.value.value;

            if (dataObject instanceof Date) {
              return { value: new Date(dataObject) };
            }

            return {
              // value: DateTimeConvertService.deserializeDateTime(documentProvision.value.value),
              value: new Date(
                dataObject.year,
                dataObject.month - 1,
                dataObject.day
              ),
            };
          }
          return {};
        },
      },
      MULTIPLE_CAT: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          const model = documentProvision.model;
          const value = [];
          if (typeof model.value !== "string" && model.value.length) {
            model.value.forEach((item) => {
              value.push(item.value || item);
            });
          }
          documentProvision.value = { value };
          // documentProvision.value = (typeof model.value !== 'string') ? {value: model.value.value} : {value: model.value};
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              value: documentProvision.value.value,
            };
          }
          return {};
        },
      },
      SINGLE_CAT: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          const model = documentProvision.model;
          documentProvision.value =
            typeof model.value !== "string"
              ? { value: model.value.value }
              : { value: model.value };
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              value: documentProvision.value.value,
            };
          }
          return {};
        },
      },
      TABLE: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.value;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            return {
              sampleRow: documentProvision.value.headers.reduce(
                (rows, header, index) => ({
                  ...rows,
                  [`col_${index}`]: "",
                }),
                {}
              ),
              headerRow: documentProvision.value.headers.reduce(
                (rows, header, index) => ({
                  ...rows,
                  [`col_${index}`]: isObject(header)
                    ? (header as any).name
                    : header,
                }),
                {}
              ),
              headers: documentProvision.value.headers.map((header, index) => {
                return {
                  prop: "col_" + index,
                  name: isObject(header)
                    ? // @ts-ignore
                      header.name
                    : header,
                  editable: true,
                };
              }),
              rows: documentProvision.value.rows.map((rowItems) => {
                if (isArray(rowItems)) {
                  return rowItems.reduce(
                    (rows, row, index) => ({
                      ...rows,
                      [`col_${index}`]: row,
                    }),
                    {}
                  );
                }
                return rowItems;
              }),
            };
          }

          if (documentProvision.value) {
            const tableModel: any = documentProvision.value;
            const headerRowObj = {};
            tableModel.sampleRow = {};
            tableModel.headers.map((header, index) => {
              headerRowObj["col_" + index] = isObject(header)
                ? // @ts-ignore
                  header.name
                : header;
              tableModel.sampleRow["col_" + index] = "";
            });
            tableModel.headerRow = headerRowObj;
            tableModel.headers = tableModel.headers.map((header, index) => {
              return {
                prop: "col_" + index,
                name: isObject(header)
                  ? // @ts-ignore
                    header.name
                  : header,
                editable: true,
              };
            });
            tableModel.rows = tableModel.rows.map((rowItems) => {
              const rowObj = {};
              if (isArray(rowItems)) {
                rowItems.map((row, index) => {
                  rowObj["col_" + index] = row;
                });
                return rowObj;
              }
              return rowItems;
            });
            return tableModel;
          }
        },
      },
      NESTED_GROUP: {
        prepareProvisionValueForSave: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          return documentProvision.model;
        },
        prepareProvisionOptionModel: (
          documentProvision: PartialValProvisionValueModel
        ) => {
          if (documentProvision.value) {
            const keyToMention = documentProvision.value.keyToMention;
            let model = {
              id: documentProvision.value.id,
            };
            for (const key in keyToMention) {
              model = {
                ...model,
                [key]: keyToMention[key].value,
              };
            }
            const attributions = documentProvision.value
              .attributions as NestedMentionAttribution[];
            if (attributions && attributions.length > 0) {
              const pageRanges = attributions
                .map((atr) => {
                  const pageKeys = Object.keys(
                    atr.location.pageToPdfHighlightId
                  )
                    .map((key) => Number(key))
                    .sort();
                  return [pageKeys[0], pageKeys[pageKeys.length - 1]];
                })
                .sort((pair1, pair2) => {
                  if (pair1[0] < pair2[0]) return -1;
                  if (pair1[0] > pair2[0]) return 1;
                  if (pair1[1] < pair2[1]) return -1;
                  if (pair1[1] > pair2[1]) return 1;
                  return 0;
                })
                .map((pages) => {
                  return pages[0] === pages[1]
                    ? `${pages[0]}`
                    : `${pages[0]} - ${pages[1]}`;
                });
              const text = `Pages: ${pageRanges.join(", ")}`;
              model[NESTED_MENTION_ATTRIBUTIONS_FIELD_KEY] = text;
            }
            return model;
          }
          return {};
        },
      },
    };
    return provisionsActionsMap[provisionType];
  }
}
