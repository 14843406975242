<div class="add-calendar-container">
  <h1 mat-dialog-title>Create a New Calendar</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input [(ngModel)]="calendarName" matInput itlsFocusIn />
    </mat-form-field>
  </div>
  <div
    mat-dialog-actions
    style="display: flex; align-items: center; justify-content: space-around"
  >
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="onSaveCalendarClick()">
      Continue
    </button>
  </div>
</div>
