import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("TaskAssigneeModel")
export class TaskAssigneeModel {
  static view = "full";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("type", String)
  type: "USER" | "ROLE" | "UNREGISTERED_USER" | undefined = undefined;

  constructor(data?: Partial<TaskAssigneeModel>) {
    Object.assign(this, data);
  }

  getNameWithTypeTitle(): string {
    switch (this.type) {
      case "USER":
        return this.name + " (Member)";
      case "ROLE":
        return this.name + " (Team)";
      default:
        return this.name;
    }
  }
}
