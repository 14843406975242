/* tslint:disable */
/* eslint-disable */
import { CreateDirectoryDtoModel } from "./create-directory-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCreateDirectoryDtoModel")
export class OApiReqCreateDirectoryDtoModel {
  @JsonProperty("data", CreateDirectoryDtoModel, true)
  data?: CreateDirectoryDtoModel = undefined;
}
