/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AuthorizeGoogleDriveReqDtoModel")
export class AuthorizeGoogleDriveReqDtoModel {
  /**
   * Google authentication code for user.
   */

  @JsonProperty("code", String, true)
  code?: string = "";
}
