import { JsonObject, JsonProperty } from "json2typescript";
import { PartialValProvisionValueModel } from "@@intelease/web/common/models/provision-value/partial-val-provision-value.model";
import { ProvisionReviewCompletionInfoViewModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("FullValMultiProvisionValueModel")
export class FullValMultiProvisionValueModel {
  static view = "fullVal";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("provisionUid", String)
  provisionUid = "";

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("type", String)
  type = "";

  @JsonProperty("possibleCurrencies", [String], true)
  possibleCurrencies?: string[] = [];

  @JsonProperty("possibleUnits", [String], true)
  possibleUnits?: string[] = [];

  @JsonProperty("possibleValues", [String], true)
  possibleValues?: string[] = [];

  @JsonProperty("possibleStates", [String], true)
  possibleStates?: string[] = [];

  @JsonProperty("possibleCountries", [String], true)
  possibleCountries?: string[] = [];

  @JsonProperty("snippets", [PartialValProvisionValueModel], true)
  snippets?: PartialValProvisionValueModel[] = [];

  @JsonProperty("options", [PartialValProvisionValueModel])
  options: PartialValProvisionValueModel[] = []; // ordered from best to worst

  /**
   * provision review completion info
   */

  @JsonProperty(
    "reviewCompletionInfos",
    [ProvisionReviewCompletionInfoViewModel],
    true
  )
  reviewCompletionInfos?: Array<ProvisionReviewCompletionInfoViewModel> = [];

  @JsonProperty("fieldType", String, true)
  fieldType: "DEFAULT" | "MANUAL" = "DEFAULT";

  @JsonProperty("allowedTypes", [String], true)
  allowedTypes: string[] = [];

  @JsonProperty("notes", String, true)
  notes = "";
}
