/* tslint:disable */
/* eslint-disable */
import { KanbanBoardDtoModel } from "./kanban-board-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqKanbanBoardDtoModel")
export class OApiReqKanbanBoardDtoModel {
  @JsonProperty("data", KanbanBoardDtoModel, true)
  data?: KanbanBoardDtoModel = undefined;
}
