/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("QueueDocTypeStatsModel")
export class QueueDocTypeStatsModel {
  @JsonProperty("numDocsProcessed", Number, true)
  numDocsProcessed?: number = undefined;

  @JsonProperty("numPagesProcessed", Number, true)
  numPagesProcessed?: number = undefined;

  @JsonProperty("numProvisionsProcessed", Number, true)
  numProvisionsProcessed?: number = undefined;

  @JsonProperty("numProvisionsReviewed", Number, true)
  numProvisionsReviewed?: number = undefined;

  @JsonProperty("numRecordsProcessed", Number, true)
  numRecordsProcessed?: number = undefined;
}
