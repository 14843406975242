<div class="provision-mention-body">
  <div class="provision-box-card" style="box-shadow: none !important">
    <div class="form-group mb-0" style="width: 35%; padding: 5px">
      <mat-form-field
        style="margin-bottom: -24px; margin-top: 4px"
        class="select-format"
        *ngIf="editable"
      >
        <mat-label>Value Format</mat-label>
        <mat-select
          (selectionChange)="onMentionValueTypeChange()"
          [(ngModel)]="mention.valueType"
        >
          <ng-container
            *ngIf="
              provision?.provisionInfo?.allowedTypes &&
              provision?.provisionInfo?.allowedTypes.length
            "
          >
            <mat-option
              *ngFor="let allowedType of provision?.provisionInfo?.allowedTypes"
              [value]="allowedType"
              >{{ provisionDataMap[allowedType]?.uiName }}
            </mat-option>
          </ng-container>
          <ng-container
            *ngIf="
              !provision?.provisionInfo?.allowedTypes ||
              provision?.provisionInfo?.allowedTypes.length === 0
            "
          >
            <mat-option [value]="provision?.provisionInfo?.type">
              {{ provisionDataMap[provision?.provisionInfo?.type]?.uiName }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <sf-form
      style="display: inline-block; margin-left: -5px"
      [style.width]="
        mention?.valueType === 'ADDRESS' ||
        mention?.valueType === 'COMMON_NOUN' ||
        mention?.valueType === 'PROPER_NOUN'
          ? '100%'
          : '75%'
      "
      #sfForm
      *ngIf="mention.formSchema && mention?.valueType !== 'TABLE'"
      [schema]="mention.formSchema"
      [model]="mention.model"
      [updateOn]="'blur'"
      (change)="onNonTableMentionModelChange($event)"
    >
    </sf-form>
    <il-dynamic-table
      style="
        display: inline-block;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        padding-top: 6px;
        margin-top: 4px;
        margin-bottom: 7px;
        margin-left: 5px;
        width: 98%;
      "
      #dynamicTable
      *ngIf="mention?.model?.rows && mention?.valueType === 'TABLE'"
      (change)="onTableMentionModelChange()"
      [model]="mention.model"
      [editable]="editable"
      [minimumColumns]="1"
      [minimumRows]="2"
    >
    </il-dynamic-table>
    <ng-container *ngxPermissionsOnly="AUTH.UPDATE_RECORD">
      <button
        mat-icon-button
        (click)="onSaveNewMentionClick()"
        matTooltip="Save"
        *ngIf="mention?.source === 'TEMP_NEW_TAB'"
        color="primary"
        dataCy="cySaveManualMentionBtn"
        class="create-mention-btn"
      >
        <mat-icon svgIcon="icon-task"></mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="onSaveNewMentionCreatedFromReaderClick()"
        matTooltip="Save"
        *ngIf="
          mention?.source === 'CUSTOMER_HIGHLIGHTED' && mention?.isTempHighlight
        "
        color="primary"
        class="create-mention-btn"
      >
        <mat-icon svgIcon="icon-task"></mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="onUpdateTableMention(mention)"
        matTooltip="Update"
        *ngIf="
          editable &&
          mention?.source !== 'TEMP_NEW_TAB' &&
          !mention?.isTempHighlight &&
          mention?.valueType === 'TABLE'
        "
        [style.right]="
          mention?.source !== 'CALCULATED' &&
          mention?.source !== 'CALCULATED_MODIFY' &&
          mention?.source !== 'TEMP_NEW_TAB' &&
          mention?.source !== 'CUSTOMER' &&
          mention?.source !== 'DERIVED' &&
          mention?.source !== 'CUSTOMER_DERIVED' &&
          mention?.source !== 'DERIVED_MODIFY'
            ? '60px'
            : 0
        "
        class="create-mention-btn"
      >
        <mat-icon svgIcon="icon-task"></mat-icon>
      </button>
    </ng-container>
    <button
      mat-icon-button
      (click)="onExpandMentionTableClick(mention)"
      matTooltip="Expand Table"
      *ngIf="mention?.valueType === 'TABLE'"
      style="right: 30px"
      class="create-mention-btn"
    >
      <mat-icon svgIcon="icon-expand"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="onGotoMentionHighlightOnViewer(mention)"
      title="Show the location"
      *ngIf="
        isSelectedDocumentFullyLoaded &&
        mention?.source !== 'CALCULATED' &&
        mention?.source !== 'CALCULATED_MODIFY' &&
        mention?.source !== 'TEMP_NEW_TAB' &&
        mention?.source !== 'CUSTOMER' &&
        mention?.source !== 'DERIVED' &&
        mention?.source !== 'CUSTOMER_DERIVED' &&
        mention?.source !== 'DERIVED_MODIFY' &&
        !mention?.isTempHighlight
      "
      class="create-mention-btn"
    >
      <mat-icon svgIcon="icon-take-me-there"></mat-icon>
    </button>
  </div>
</div>
