import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItlsFilterComponent } from "./itls-filter.component";
import { MatButtonModule } from "@angular/material/button";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  MatFormFieldControl,
  MatFormFieldModule,
} from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";
import { ClipboardModule, ClipboardService } from "ngx-clipboard";
import { NgStringPipesModule } from "ngx-pipes";
import {
  ConvertAddressToStringPipe,
  ItlsFilterGroupComponent,
  ItlsFilterItemComponent,
  ItlsFilterWidgetChooserComponent,
} from "./components";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SchemaFormModule } from "@@intelease/schema-form";
import { MatListModule } from "@angular/material/list";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgSelectModule } from "@ng-select/ng-select";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";
import { CommonPipesModule } from "@@intelease/web/common/pipes";
import { ScrollingModule } from "@angular/cdk/scrolling";

const MATERIAL_MODULES = [
  MatSelectModule,
  MatFormFieldModule,
  MatButtonModule,
  MatInputModule,
  MatIconModule,
  MatMenuModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatListModule,
  ScrollingModule,
];

const COMPONENTS = [
  ItlsFilterGroupComponent,
  ItlsFilterItemComponent,
  ItlsFilterWidgetChooserComponent,
];

const NGX_BOOTSTRAP = [ModalModule.forRoot()];

@NgModule({
  declarations: [
    ItlsFilterComponent,
    ConvertAddressToStringPipe,
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    ItlsCustomIconModule,
    CommonPipesModule,
    FlexLayoutModule,
    ...MATERIAL_MODULES,
    ...NGX_BOOTSTRAP,
    ReactiveFormsModule,
    CommonDirectivesModule,
    ClipboardModule,
    NgStringPipesModule,
    FormsModule,
    NgSelectModule,
    SchemaFormModule.forRoot(),
  ],
  exports: [ItlsFilterComponent],
  providers: [ClipboardService],
})
export class ItlsFilterModule {}
