/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RentScheduleInputsApiDtoModel")
export class RentScheduleInputsApiDtoModel {
  @JsonProperty("additionalHtmlNames", [String], true)
  additionalHtmlNames?: Array<string> = [];

  @JsonProperty("provisionFormUid", String, true)
  provisionFormUid?: string = "";

  @JsonProperty("recordUid", String, true)
  recordUid?: string = "";
}
