/* tslint:disable */
/* eslint-disable */
import { ChangeReviewOperationModeRequestDtoModel } from "./change-review-operation-mode-request-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqChangeReviewOperationModeRequestDtoModel")
export class OApiReqChangeReviewOperationModeRequestDtoModel {
  @JsonProperty("data", ChangeReviewOperationModeRequestDtoModel, true)
  data?: ChangeReviewOperationModeRequestDtoModel = undefined;
}
