import { NgModule } from "@angular/core";
import { ProvisionComponent } from "./provision.component";
import { InteleaseModule } from "@@intelease/web/intelease/intelease.module";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
import { ItlsCommentModule } from "@@intelease/web/ui/src/lib/itls-comment";
import { ProvisionMentionsModule } from "@@intelease/web/abstraction-page/src/lib/components/provision-mentions";
import { PortfolioMentionsModule } from "@@intelease/web/abstraction-page/src/lib/components/portfolio-mentions";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SchemaFormModule } from "@@intelease/schema-form";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MentionModule } from "../mention";
import { MatDividerModule } from "@angular/material/divider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRippleModule } from "@angular/material/core";
import { ProvisionNotesModule } from "@@intelease/web/abstraction-page/src/lib/components/provision-notes";

const MATERIAL_MODULES = [
  MatDialogModule,
  MatButtonModule,
  MatTabsModule,
  MatIconModule,
  MatTooltipModule,
  DragDropModule,
  MatDividerModule,
  MatProgressSpinnerModule,
  MatRippleModule,
];

const UI_MODULES = [ItlsCommentModule];

@NgModule({
  imports: [
    CommonModule,
    InteleaseModule,
    InteleaseCommonModule,
    FormsModule,
    FlexLayoutModule,
    ProvisionMentionsModule,
    PortfolioMentionsModule,
    ProvisionNotesModule,
    SchemaFormModule.forRoot(),
    ...MATERIAL_MODULES,
    ...UI_MODULES,
    MentionModule,
  ],
  declarations: [ProvisionComponent],
  exports: [ProvisionComponent],
})
export class ProvisionModule {}
