import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("TableProvisionModel")
export class TableProvisionModel {
  @JsonProperty("headers", [String])
  headers: string[] = [];

  @JsonProperty("types", [String])
  types: string[] = [];

  @JsonProperty("rows", [[String]])
  rows: any = undefined;
}
