/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AbstractProvisionModel")
export class AbstractProvisionModel {
  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("type", Any, true)
  type?:
    | "ADDRESS"
    | "COMMON_NOUN"
    | "DOUBLE"
    | "EMAIL"
    | "DATE"
    | "NUMBER"
    | "STRING"
    | "UID"
    | "LOCAL_DATE"
    | "MONEY"
    | "MULTIPLE_CAT"
    | "PHONE"
    | "PROPER_NOUN"
    | "SINGLE_CAT"
    | "TABLE"
    | "YEAR"
    | "TIME_PLAN"
    | "DOC_TEXT" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
