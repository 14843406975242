import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class InputValidationService {
  constructor() {}

  /*
   * Below are basic corrections for user-entered text fields.
   */

  sanitizeText(text: string): string {
    if (!text) {
      return text;
    }
    return text.trim();
  }

  sanitizeTexts(texts: string[]): string[] {
    return texts.map((text) => this.sanitizeText(text));
  }

  /*
   * Below are validations for user-entered text fields.
   */

  nonMatchingUsefulTextElseAlert(
    newText: string,
    oldText: string,
    alertMessage: string
  ): boolean {
    if (!this.nonMatchingUsefulText(newText, oldText)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  nonMatchingUsefulText(newText: string, oldText: string): boolean {
    if (!this.usefulText(newText)) {
      return false;
    }
    if (oldText && newText === oldText) {
      return false;
    }
    return true;
  }

  matchingUsefulTextElseAlert(
    text1: string,
    text2: string,
    alertMessage: string
  ): boolean {
    if (!this.matchingUsefulText(text1, text2)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  matchingUsefulText(text1: string, text2: string): boolean {
    if (!this.usefulText(text1) || !this.usefulText(text2)) {
      return false;
    }
    if (text1 !== text2) {
      return false;
    }
    return true;
  }

  usefulTextElseAlert(text: string, alertMessage: string): boolean {
    if (!this.usefulText(text)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulText(text: string): boolean {
    const isUseless = !text || text.trim().length === 0;
    return !isUseless;
  }

  usefulTextsElseAlert(texts: string[], alertMessage: string): boolean {
    if (!this.usefulTexts(texts)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulTexts(texts: string[]): boolean {
    const areUseless = !texts || texts.length === 0;
    if (areUseless) {
      return false;
    }
    for (const text of texts) {
      if (!this.usefulText(text)) {
        return false;
      }
    }
    return true;
  }

  validTextSuffixesIgnoreCaseElseAlert(
    text: string,
    validSuffixes: string[],
    alertMessage: string
  ): boolean {
    if (!this.validTextSuffixesIgnoreCase(text, validSuffixes)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  validTextSuffixesIgnoreCase(text: string, validSuffixes: string[]): boolean {
    if (!this.usefulText(text)) {
      return false;
    }
    for (const suffix of validSuffixes) {
      if (text.toLowerCase().endsWith(suffix.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  validTextsSuffixesIgnoreCaseElseAlert(
    texts: string[],
    validSuffixes: string[],
    alertMessage: string
  ): boolean {
    if (!this.validTextsSuffixesIgnoreCase(texts, validSuffixes)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  validTextsSuffixesIgnoreCase(
    texts: string[],
    validSuffixes: string[]
  ): boolean {
    if (!this.usefulTexts(texts)) {
      return false;
    }
    for (const text of texts) {
      if (!this.validTextSuffixesIgnoreCase(text, validSuffixes)) {
        return false;
      }
    }
    return true;
  }

  /*
   * Below are validations for user-entered number fields.
   */

  usefulNumberElseAlert(number: number, alertMessage: string): boolean {
    if (!this.usefulNumber(number)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulNumber(number: number): boolean {
    const isUseless = !number || number === 0;
    return !isUseless;
  }

  usefulNumbersElseAlert(numbers: number[], alertMessage: string): boolean {
    if (!this.usefulNumbers(numbers)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulNumbers(numbers: number[]): boolean {
    const areUseless = !numbers || numbers.length === 0;
    if (areUseless) {
      return false;
    }
    for (const number of numbers) {
      if (!this.usefulNumber(number)) {
        return false;
      }
    }
    return true;
  }

  /*
   * Below are validations for user-entered list fields.
   */

  usefulListElseAlert(list: any[], alertMessage: string): boolean {
    if (!this.usefulList(list)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulList(list: any[]): boolean {
    const isUseless = !list || list.length === 0;
    return !isUseless;
  }

  /*
   * Below are validations for user-entered date fields.
   */

  usefulDateElseAlert(date: any, alertMessage: string): boolean {
    if (!this.usefulDate(date)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulDate(date: any): boolean {
    const isUseless = !date || !(date instanceof Date);
    return !isUseless;
  }

  /*
   * Below are validations that user selected / created objects with correct relevant text fields.
   */

  usefulObjectTextFieldElseAlert(
    object: any,
    textFieldName: string,
    alertMessage: string
  ): boolean {
    if (!this.usefulObjectTextField(object, textFieldName)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulObjectTextField(object: any, textFieldName: string): boolean {
    if (!object) {
      return false;
    }
    if (!this.usefulText(object[textFieldName])) {
      return false;
    }
    return true;
  }

  usefulObjectsTextFieldElseAlert(
    objects: any[],
    fieldName: string,
    alertMessage: string
  ): boolean {
    if (!this.usefulObjectsTextField(objects, fieldName)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulObjectsTextField(objects: any[], fieldName: string): boolean {
    const areUseless = !objects || objects.length === 0;
    if (areUseless) {
      return false;
    }
    for (const object of objects) {
      if (!this.usefulObjectTextField(object, fieldName)) {
        return false;
      }
    }
    return true;
  }

  /*
   * Below are validations that user selected / created objects with correct relevant numerical fields.
   */

  usefulObjectNumberFieldElseAlert(
    object: any,
    numberFieldName: string,
    alertMessage: string
  ): boolean {
    if (!this.usefulObjectNumberField(object, numberFieldName)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulObjectNumberField(object: any, numberFieldName: string): boolean {
    if (!object) {
      return false;
    }
    if (!this.usefulNumber(object[numberFieldName])) {
      return false;
    }
    return true;
  }

  usefulObjectsNumberFieldElseAlert(
    objects: any[],
    fieldName: string,
    alertMessage: string
  ): boolean {
    if (!this.usefulObjectsNumberField(objects, fieldName)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulObjectsNumberField(objects: any[], fieldName: string): boolean {
    const areUseless = !objects || objects.length === 0;
    if (areUseless) {
      return false;
    }
    for (const object of objects) {
      if (!this.usefulObjectNumberField(object, fieldName)) {
        return false;
      }
    }
    return true;
  }

  /*
   * Below are nested objects which should have expected fields.
   */

  usefulNestedObjectListField(object: any, nestedFields: string[]): boolean {
    if (!object) {
      return false;
    }
    let thisObj = object;
    for (const nestedField of nestedFields) {
      thisObj = thisObj[nestedField];
      if (!thisObj) {
        return false;
      }
    }
    return this.usefulList(thisObj);
  }

  usefulNestedObjectTextField(object: any, nestedFields: string[]): boolean {
    if (!object) {
      return false;
    }
    let thisObj = object;
    for (const nestedField of nestedFields) {
      thisObj = thisObj[nestedField];
      if (!thisObj) {
        return false;
      }
    }
    return this.usefulText(thisObj);
  }

  usefulNestedObjectDateField(object: any, nestedFields: string[]): boolean {
    if (!object) {
      return false;
    }
    let thisObj = object;
    for (const nestedField of nestedFields) {
      thisObj = thisObj[nestedField];
      if (!thisObj) {
        return false;
      }
    }
    return this.usefulDate(thisObj);
  }

  usefulNestedObjectNumberField(object: any, nestedFields: string[]): boolean {
    if (!object) {
      return false;
    }
    let thisObj = object;
    for (const nestedField of nestedFields) {
      thisObj = thisObj[nestedField];
      if (!thisObj) {
        return false;
      }
    }
    return this.usefulNumber(thisObj);
  }

  /*
   * Below are nested objects which should have expected fields, elser alert.
   */

  usefulNestedObjectListFieldElseAlert(
    object: any,
    nestedFields: string[],
    alertMessage: string
  ): boolean {
    if (!this.usefulNestedObjectListField(object, nestedFields)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulNestedObjectTextFieldElseAlert(
    object: any,
    nestedFields: string[],
    alertMessage: string
  ): boolean {
    if (!this.usefulNestedObjectTextField(object, nestedFields)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulNestedObjectDateFieldElseAlert(
    object: any,
    nestedFields: string[],
    alertMessage: string
  ): boolean {
    if (!this.usefulNestedObjectDateField(object, nestedFields)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }

  usefulNestedObjectNumberFieldElseAlert(
    object: any,
    nestedFields: string[],
    alertMessage: string
  ): boolean {
    if (!this.usefulNestedObjectNumberField(object, nestedFields)) {
      alert(alertMessage);
      return false;
    }
    return true;
  }
}
