import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { map } from "rxjs/operators";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";

@Injectable({
  providedIn: "root",
})
export class CreateApiService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Send a POST request to create a new entity in backend, returning a view of it.
   *
   * @param apiVersion the version of the API
   * @param fullUrl the full url
   * @param data the data used for creating
   * @param view the view of the created object to return
   * @param classRef the class of the returned object
   * @return the newly created object
   */
  sendRequest<T extends object>(
    apiVersion: string,
    fullUrl: string,
    data: any,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    const body = this.createCreateRequestBody(data, view);
    return this.helpSendRequest(body, apiVersion, fullUrl, classRef);
  }

  /**
   * Send a POST request to create a new entity in backend, returning no view of it.
   *
   * @param apiVersion the version of the API
   * @param fullUrl the full url
   * @param data the data used for creating the object
   */
  sendRequestNoResponse(apiVersion: string, fullUrl: string, data: any): void {
    this.sendRequestNoView(apiVersion, fullUrl, data).subscribe((res) => {});
  }

  /**
   * Send a synchronous POST request to create a new entity in backend, returning no view of it.
   *
   * @param apiVersion the version of the API
   * @param fullUrl the full url
   * @param data the data used for creating the object
   * @return response, not containing object
   */
  sendRequestNoView(
    apiVersion: string,
    fullUrl: string,
    data: any
  ): Observable<ServerResponseModel> {
    const body = this.createCreateRequestBody(data, "none");
    return this.helpSendRequestNoView(body, apiVersion, fullUrl);
  }

  /*
   * Below are private helper methods
   */

  private helpSendRequest<T extends object>(
    body: any,
    apiVersion: string,
    fullUrl: string,
    classRef: new () => T
  ): Observable<T> {
    return this.helpSendRequestNoView(body, apiVersion, fullUrl).pipe(
      map((res) => Json2TypescriptHelper.convertToEntity(res.data, classRef))
    );
  }

  private helpSendRequestNoView(
    body: any,
    apiVersion: string,
    fullUrl: string
  ): Observable<ServerResponseModel> {
    return this.httpClient.post<ServerResponseModel>(
      apiVersion + fullUrl,
      body
    );
  }

  private createCreateRequestBody(data: any, view: string): any {
    return {
      data: data,
      returnParams: {
        view: view,
      },
    };
  }
}
