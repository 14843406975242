import { Injectable } from "@angular/core";
import {
  NavigationBehaviorOptions,
  NavigationExtras,
  Router,
  UrlTree,
} from "@angular/router";
import { RecordReviewModeEnum } from "../../../app-models/common/src";
import { LocalStorageKey } from "../../common/enums/local-storage.keys";
import { openInNewWindow } from "./open-in-new-window";

/**
 * Extended version of the angular router to support some special page navigation
 */
@Injectable({ providedIn: "root" })
export class AdexRouterService {
  constructor(private readonly router: Router) {}

  /**
   * Navigates to the record review page based on the review mode
   *
   * @param recordUid recordUid to be reviewed
   * @param shouldOpenNewWindow whether to open in new window/tab or not
   * @param extras NavigationExtras params such as queryParams, replaceUrl, etc
   */
  navigateToRecordReviewPage(
    recordUid: string,
    shouldOpenNewWindow: boolean = false,
    extras?: NavigationExtras | undefined
  ) {
    let reviewSection: "/record-review/" | "/abstract-review/";
    switch (localStorage.getItem(LocalStorageKey.RECORD_REVIEW_MODE)) {
      case RecordReviewModeEnum.V2:
        reviewSection = "/record-review/";
        break;

      case RecordReviewModeEnum.V1:
      default:
        reviewSection = "/abstract-review/";
        break;
    }

    const reviewPageUrl = `${reviewSection}${recordUid}`;
    if (shouldOpenNewWindow === true) {
      openInNewWindow(reviewPageUrl);
    } else {
      this.router.navigate([reviewPageUrl], extras);
    }
  }

  navigate(commands: any[], extras?: NavigationExtras) {
    return this.router.navigate(commands, extras);
  }

  navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions) {
    return this.router.navigateByUrl(url, extras);
  }

  openInNewTab(url: string) {
    openInNewWindow(url);
  }

  openExternalLink(link: string) {
    window.open(link, "_blank");
  }

  openGoogleDriveFilePreview(fileId: string) {
    const googleDrivePreviewUrl = `https://drive.google.com/file/d/${fileId}/view`;
    window.open(googleDrivePreviewUrl, "_blank");
  }
}
