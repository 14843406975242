/* tslint:disable */
/* eslint-disable */
import { PartialPdfProvisionViewModel } from "./partial-pdf-provision-view-model";
import { ProvisionReviewCompletionInfoViewModel } from "./provision-review-completion-info-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SearchMultiplePdfProvisionViewModel")
export class SearchMultiplePdfProvisionViewModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  /**
   * the type of datapoint this field is
   */

  @JsonProperty("fieldType", Any, true)
  fieldType?: "DEFAULT" | "MANUAL" = undefined;

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("options", [PartialPdfProvisionViewModel], true)
  options?: Array<PartialPdfProvisionViewModel> = [];

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";

  @JsonProperty(
    "reviewCompletionInfos",
    [ProvisionReviewCompletionInfoViewModel],
    true
  )
  reviewCompletionInfos?: Array<ProvisionReviewCompletionInfoViewModel> = [];

  @JsonProperty("searchOptions", [PartialPdfProvisionViewModel], true)
  searchOptions?: Array<PartialPdfProvisionViewModel> = [];

  @JsonProperty("snippetOptions", [PartialPdfProvisionViewModel], true)
  snippetOptions?: Array<PartialPdfProvisionViewModel> = [];

  @JsonProperty("type", String, true)
  type?: string = "";
}
