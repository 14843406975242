/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { AuthorizeGoogleDriveReqDtoModel } from "../models/authorize-google-drive-req-dto-model";
import { OApiReqGoogleDriveChangeAutoSyncDtoModel } from "../models/o-api-req-google-drive-change-auto-sync-dto-model";
import { OApiReqGoogleDriveFolderSyncDtoModel } from "../models/o-api-req-google-drive-folder-sync-dto-model";
import { OApiResFetchGoogleDriveAuthUrlResDtoModel } from "../models/o-api-res-fetch-google-drive-auth-url-res-dto-model";
import { OApiRespAuthorizeGoogleDriveModel } from "../models/o-api-resp-authorize-google-drive-model";
import { OApiRespGoogleDriveChangeAutoSyncDtoModel } from "../models/o-api-resp-google-drive-change-auto-sync-dto-model";
import { OApiRespGoogleDriveFolderSyncDtoModel } from "../models/o-api-resp-google-drive-folder-sync-dto-model";

@Injectable({
  providedIn: "root",
})
export class GoogleDriveService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation authorize
   */
  static readonly AuthorizePath = "/api/v1/google-drive-api/authorize";

  /**
   * Fetch authorization url for google drive.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorize()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorize$Response(params: {
    /**
     * The file to upload, as well as supporting parameters.
     */
    body: AuthorizeGoogleDriveReqDtoModel;
  }): Observable<StrictHttpResponse<OApiRespAuthorizeGoogleDriveModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      GoogleDriveService.AuthorizePath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespAuthorizeGoogleDriveModel>;
        })
      );
  }

  /**
   * Fetch authorization url for google drive.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authorize$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorize(params: {
    /**
     * The file to upload, as well as supporting parameters.
     */
    body: AuthorizeGoogleDriveReqDtoModel;
  }): Observable<OApiRespAuthorizeGoogleDriveModel> {
    return this.authorize$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespAuthorizeGoogleDriveModel>) =>
          r.body as OApiRespAuthorizeGoogleDriveModel
      )
    );
  }

  /**
   * Path part for operation changeAutoSyncSetting
   */
  static readonly ChangeAutoSyncSettingPath =
    "/api/v1/google-drive-api/change-auto-sync";

  /**
   * Change Google Drive auto synchronization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeAutoSyncSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeAutoSyncSetting$Response(params: {
    /**
     * Turn on/off Google Drive automatic synchronization
     */
    body: OApiReqGoogleDriveChangeAutoSyncDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespGoogleDriveChangeAutoSyncDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      GoogleDriveService.ChangeAutoSyncSettingPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespGoogleDriveChangeAutoSyncDtoModel>;
        })
      );
  }

  /**
   * Change Google Drive auto synchronization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeAutoSyncSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeAutoSyncSetting(params: {
    /**
     * Turn on/off Google Drive automatic synchronization
     */
    body: OApiReqGoogleDriveChangeAutoSyncDtoModel;
  }): Observable<OApiRespGoogleDriveChangeAutoSyncDtoModel> {
    return this.changeAutoSyncSetting$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespGoogleDriveChangeAutoSyncDtoModel>) =>
          r.body as OApiRespGoogleDriveChangeAutoSyncDtoModel
      )
    );
  }

  /**
   * Path part for operation getAuthorizationUrl
   */
  static readonly GetAuthorizationUrlPath = "/api/v1/google-drive-api/auth-url";

  /**
   * Fetch authorization url for google drive.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuthorizationUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthorizationUrl$Response(params?: {}): Observable<
    StrictHttpResponse<OApiResFetchGoogleDriveAuthUrlResDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      GoogleDriveService.GetAuthorizationUrlPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiResFetchGoogleDriveAuthUrlResDtoModel>;
        })
      );
  }

  /**
   * Fetch authorization url for google drive.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAuthorizationUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthorizationUrl(params?: {}): Observable<OApiResFetchGoogleDriveAuthUrlResDtoModel> {
    return this.getAuthorizationUrl$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiResFetchGoogleDriveAuthUrlResDtoModel>) =>
          r.body as OApiResFetchGoogleDriveAuthUrlResDtoModel
      )
    );
  }

  /**
   * Path part for operation syncFolder
   */
  static readonly SyncFolderPath = "/api/v1/google-drive-api/sync-folder";

  /**
   * Set the Google Drive folder to be synchronized with our system.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncFolder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  syncFolder$Response(params: {
    /**
     * The Google Drive folder to be sync'd for this user.
     */
    body: OApiReqGoogleDriveFolderSyncDtoModel;
  }): Observable<StrictHttpResponse<OApiRespGoogleDriveFolderSyncDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      GoogleDriveService.SyncFolderPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespGoogleDriveFolderSyncDtoModel>;
        })
      );
  }

  /**
   * Set the Google Drive folder to be synchronized with our system.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `syncFolder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  syncFolder(params: {
    /**
     * The Google Drive folder to be sync'd for this user.
     */
    body: OApiReqGoogleDriveFolderSyncDtoModel;
  }): Observable<OApiRespGoogleDriveFolderSyncDtoModel> {
    return this.syncFolder$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespGoogleDriveFolderSyncDtoModel>) =>
          r.body as OApiRespGoogleDriveFolderSyncDtoModel
      )
    );
  }
}
