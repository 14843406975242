<div class="modal-content">
  <div class="modal-header clearfix text-left">
    <button
      style="padding: 10px"
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5>Term calculator</h5>
  </div>
  <div class="modal-body">
    <div class="form-container">
      <div class="form-group">
        <label (click)="changeSelectedField('commencementDate')">
          Commencement date
          <span
            *ngIf="selectedField === 'commencementDate'"
            class="text-success"
            >(Calc)</span
          >
          <i
            class="fa fa-info-circle ml-1 info-icon"
            tooltip="Please choose a start date for the term."
            container="modal-container"
          ></i>
        </label>
        <i
          class="fa fa-edit"
          style="right: 50px"
          *ngIf="
            selectedField !== 'commencementDate' &&
            formData?.commencementDate?.value === 'New'
          "
          (click)="
            toggleDatePicker(
              dateFieldsEnum.COMMENCEMENT_DATE,
              'commencementDate'
            )
          "
        ></i>
        <ng-container *ngIf="selectedField !== 'commencementDate'">
          <ng-select
            [items]="listItems.commencementDate"
            [closeOnSelect]="true"
            bindLabel="label"
            [clearable]="false"
            [ngClass]="{
              active: false
            }"
            [(ngModel)]="formData.commencementDate"
            (change)="
              onDateValueChange($event, 'COMMENCEMENT_DATE', 'commencementDate')
            "
          >
          </ng-select>
        </ng-container>
        <ng-container *ngIf="selectedField === 'commencementDate'">
          <div
            style="
              height: 36px;
              background: #f0f0f0;
              border-radius: 5px;
              padding: 8px;
              font-size: 12px;
              border: 1px solid #10cfbd;
            "
          >
            {{ calculatedItem?.value | date }}
          </div>
        </ng-container>
      </div>
      <div class="form-group">
        <label (click)="changeSelectedField('expirationDate')">
          Expiration date
          <span *ngIf="selectedField === 'expirationDate'" class="text-success"
            >(Calc)</span
          >
          <i
            class="fa fa-info-circle ml-1 info-icon"
            tooltip="Please choose an end date for the term."
            container="modal-container"
          ></i>
        </label>
        <i
          class="fa fa-edit"
          *ngIf="
            selectedField !== 'expirationDate' &&
            formData?.expirationDate?.value === 'New'
          "
          (click)="
            toggleDatePicker(dateFieldsEnum.EXPIRATION_DATE, 'expirationDate')
          "
        ></i>
        <ng-container *ngIf="selectedField !== 'expirationDate'">
          <ng-select
            [items]="listItems.expiryDate"
            [closeOnSelect]="true"
            appendTo="body"
            [clearable]="false"
            [(ngModel)]="formData.expirationDate"
            [ngClass]="{
              active: false
            }"
            bindLabel="label"
            (change)="
              onDateValueChange($event, 'EXPIRATION_DATE', 'expirationDate')
            "
          >
          </ng-select>
        </ng-container>
        <ng-container *ngIf="selectedField === 'expirationDate'">
          <div
            style="
              height: 36px;
              background: #f0f0f0;
              border-radius: 5px;
              padding: 8px;
              font-size: 12px;
              border: 1px solid #10cfbd;
            "
          >
            {{ calculatedItem?.value | date }}
          </div>
        </ng-container>
      </div>

      <div class="form-group">
        <label (click)="changeSelectedField('term')">
          Term
          <span *ngIf="selectedField === 'term'" class="text-success"
            >(Calc)</span
          >
          <i
            class="fa fa-info-circle ml-1 info-icon"
            tooltip="Please choose a time period representing the term for the lease."
            container="modal-container"
          ></i>
        </label>
        <i
          style="left: 25px"
          class="fa fa-edit"
          *ngIf="selectedField !== 'term' && formData?.term?.value === 'New'"
          (click)="toggleDatePicker(dateFieldsEnum.TERM, 'term')"
        ></i>
        <ng-container *ngIf="selectedField !== 'term'">
          <ng-select
            [items]="listItems.leaseTerm"
            [closeOnSelect]="true"
            bindLabel="label"
            appendTo="body"
            [clearable]="false"
            (change)="onDateValueChange($event, 'TERM', 'term')"
            [(ngModel)]="formData.term"
          >
          </ng-select>
        </ng-container>
        <ng-container *ngIf="selectedField === 'term'">
          <itls-input-dropdown-component
            inputWidth="50%"
            selectWidth="50%"
            [model]="calculatedItem.value"
            [items]="items"
            [inputDisabled]="true"
            [class]="
              selectedField === 'term' ? 'active-input-dropdown' : 'term'
            "
            (change)="onTermChange($event, 'calc')"
            [digitsOnly]="true"
          >
          </itls-input-dropdown-component>
        </ng-container>
      </div>
    </div>

    <div class="date-picker-container">
      <div class="form-item" *ngIf="selectedDate === 'TERM'">
        <label (click)="selectedField = 'term'">Term </label>
        <itls-input-dropdown-component
          inputWidth="50%"
          selectWidth="50%"
          [model]="termModel"
          [items]="items"
          [class]="selectedField === 'term' ? 'active-input-dropdown' : 'term'"
          (change)="onTermChange($event, 'New')"
          [digitsOnly]="true"
        >
        </itls-input-dropdown-component>
      </div>
      <i
        class="fa fa-close"
        (click)="selectedDate = undefined"
        *ngIf="
          selectedDate === 'COMMENCEMENT_DATE' ||
          selectedDate === 'EXPIRATION_DATE' ||
          selectedDate === 'TERM'
        "
      ></i>
      <bs-datepicker-inline
        *ngIf="selectedDate === 'COMMENCEMENT_DATE'"
        [(bsValue)]="datePickerValue"
        (bsValueChange)="onDateChange($event, 'commencementDate')"
      ></bs-datepicker-inline>
      <bs-datepicker-inline
        *ngIf="selectedDate === 'EXPIRATION_DATE'"
        [(bsValue)]="datePickerValue"
        (bsValueChange)="onDateChange($event, 'expirationDate')"
      ></bs-datepicker-inline>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-default btn-cons pull-left inline"
      style="padding: 8px"
      (click)="onResetClick()"
    >
      Reset
    </button>
    <button
      type="button"
      class="btn btn-primary btn-cons pull-left inline calculateBtn"
      (click)="onCalculateClick()"
    >
      Calculate
    </button>
    <button
      type="button"
      [disabled]="
        !calculatedItem.value ||
        (this.calculatedItem.field === 'term' && !calculatedItem.value.input)
      "
      class="btn btn-primary btn-cons pull-left inline importBtn"
      (click)="onImportClick()"
    >
      Import
    </button>
  </div>
</div>
