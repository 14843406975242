/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqSettingItemListDtoModel } from "../models/o-api-req-setting-item-list-dto-model";
import { OApiRespCustomTagsViewModel } from "../models/o-api-resp-custom-tags-view-model";
import { OApiRespMultiDocumentTypeNameModel } from "../models/o-api-resp-multi-document-type-name-model";
import { OApiRespMultiSettingItemDtoModel } from "../models/o-api-resp-multi-setting-item-dto-model";
import { OApiRespMultiSettingItemViewModel } from "../models/o-api-resp-multi-setting-item-view-model";

@Injectable({
  providedIn: "root",
})
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getSettingItemsForCurrentAccount
   */
  static readonly GetSettingItemsForCurrentAccountPath =
    "/api/v1/accounts/settings";

  /**
   * Get UI settings for current user's account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingItemsForCurrentAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingItemsForCurrentAccount$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMultiSettingItemViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AccountService.GetSettingItemsForCurrentAccountPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiSettingItemViewModel>;
        })
      );
  }

  /**
   * Get UI settings for current user's account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSettingItemsForCurrentAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingItemsForCurrentAccount(params?: {}): Observable<OApiRespMultiSettingItemViewModel> {
    return this.getSettingItemsForCurrentAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiSettingItemViewModel>) =>
          r.body as OApiRespMultiSettingItemViewModel
      )
    );
  }

  /**
   * Path part for operation editSettingItemsForCurrentAccount
   */
  static readonly EditSettingItemsForCurrentAccountPath =
    "/api/v1/accounts/settings";

  /**
   * Edit settings for current user's account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editSettingItemsForCurrentAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSettingItemsForCurrentAccount$Response(params: {
    body: OApiReqSettingItemListDtoModel;
  }): Observable<StrictHttpResponse<OApiRespMultiSettingItemDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AccountService.EditSettingItemsForCurrentAccountPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiSettingItemDtoModel>;
        })
      );
  }

  /**
   * Edit settings for current user's account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editSettingItemsForCurrentAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSettingItemsForCurrentAccount(params: {
    body: OApiReqSettingItemListDtoModel;
  }): Observable<OApiRespMultiSettingItemDtoModel> {
    return this.editSettingItemsForCurrentAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiSettingItemDtoModel>) =>
          r.body as OApiRespMultiSettingItemDtoModel
      )
    );
  }

  /**
   * Path part for operation getAllSettingItemsForCurrentAccount
   */
  static readonly GetAllSettingItemsForCurrentAccountPath =
    "/api/v1/accounts/all-settings";

  /**
   * Get all settings for current user's account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSettingItemsForCurrentAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSettingItemsForCurrentAccount$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMultiSettingItemViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AccountService.GetAllSettingItemsForCurrentAccountPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiSettingItemViewModel>;
        })
      );
  }

  /**
   * Get all settings for current user's account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSettingItemsForCurrentAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSettingItemsForCurrentAccount(params?: {}): Observable<OApiRespMultiSettingItemViewModel> {
    return this.getAllSettingItemsForCurrentAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiSettingItemViewModel>) =>
          r.body as OApiRespMultiSettingItemViewModel
      )
    );
  }

  /**
   * Path part for operation getCustomTags
   */
  static readonly GetCustomTagsPath = "/api/v1/custom-tags";

  /**
   * Get the custom tags allowed for this account, sorted alphabetically.
   *
   * Return the custom tags allowed for this account. Status 200 if successful.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomTags$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespCustomTagsViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AccountService.GetCustomTagsPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespCustomTagsViewModel>;
        })
      );
  }

  /**
   * Get the custom tags allowed for this account, sorted alphabetically.
   *
   * Return the custom tags allowed for this account. Status 200 if successful.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomTags(params?: {}): Observable<OApiRespCustomTagsViewModel> {
    return this.getCustomTags$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespCustomTagsViewModel>) =>
          r.body as OApiRespCustomTagsViewModel
      )
    );
  }

  /**
   * Path part for operation getDocumentTypes
   */
  static readonly GetDocumentTypesPath = "/api/v1/documentTypes";

  /**
   * Get the document types allowed for this user / account, sorted alphabetically.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMultiDocumentTypeNameModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AccountService.GetDocumentTypesPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiDocumentTypeNameModel>;
        })
      );
  }

  /**
   * Get the document types allowed for this user / account, sorted alphabetically.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes(params?: {}): Observable<OApiRespMultiDocumentTypeNameModel> {
    return this.getDocumentTypes$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiDocumentTypeNameModel>) =>
          r.body as OApiRespMultiDocumentTypeNameModel
      )
    );
  }
}
