/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("QueueAccuracyStatsDtoModel")
export class QueueAccuracyStatsDtoModel {
  @JsonProperty("calculatedAt", DateTimeConvertService, true)
  calculatedAt?: Date | undefined = undefined;

  @JsonProperty("finalAccuracy", Number, true)
  finalAccuracy?: number = undefined;

  @JsonProperty("l1CorrectMentionCount", Number, true)
  l1CorrectMentionCount?: number = undefined;

  @JsonProperty("l2CorrectMentionCount", Number, true)
  l2CorrectMentionCount?: number = undefined;

  @JsonProperty("totalMentionCount", Number, true)
  totalMentionCount?: number = undefined;
}
