import { QueueActionTypes, QueueAction } from "./queue.actions";
import {
  QueueDetailsDtoModel,
  QueueDtoModel,
  SimpleQueueDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import { cloneDeep, forEach, isEmpty, keys, remove, values } from "lodash";
import { QUEUE_STATUS } from "./models/queue.types";

export type RecordStatusType =
  | "IN_REVIEW"
  | "COMPLETED"
  | "PROCESSING"
  | "IN_QA_REVIEW"
  | "READY"
  | "FAILED";
export const QUEUE_FEATURE_KEY = "queue";

export interface QueueState {
  myQueueList: QueueDtoModel[];
  myQueueListLoading: boolean;
  myQueueListLoaded: boolean;
  myQueueListFailed: boolean;
  usableQueueList: SimpleQueueDtoModel[];
  othersQueueList: QueueDtoModel[];
  othersQueueListLoading: boolean;
  othersQueueListLoaded: boolean;
  othersQueueListFailed: boolean;
  isUsableQueueListLoaded: boolean;
  errorLoadingMyQueueList?: any;
  errorLoadingUsableQueueList?: any;
  errorLoadingOthersQueueList?: any;
  currentQueue?: QueueDetailsDtoModel;
  currentQueueUid?: string;
  isCurrentQueueLoaded: boolean;
  errorLoadingCurrentQueue?: any;
  searchText?: string;
  isSearchActive: boolean;
}

export interface QueuePartialState {
  readonly [QUEUE_FEATURE_KEY]: QueueState;
}

export const initialState: QueueState = {
  myQueueList: [],
  myQueueListLoading: false,
  myQueueListLoaded: false,
  myQueueListFailed: false,
  usableQueueList: [],
  othersQueueList: [],
  othersQueueListLoading: false,
  othersQueueListLoaded: false,
  othersQueueListFailed: false,
  errorLoadingMyQueueList: undefined,
  errorLoadingUsableQueueList: undefined,
  errorLoadingOthersQueueList: undefined,
  isUsableQueueListLoaded: false,

  currentQueueUid: undefined,
  currentQueue: undefined,
  isCurrentQueueLoaded: false,
  errorLoadingCurrentQueue: undefined,

  isSearchActive: false,
};

export function reducer(
  state: QueueState = initialState,
  action: QueueAction
): QueueState {
  switch (action.type) {
    case QueueActionTypes.LoadMyQueueList: {
      state = {
        ...state,
        myQueueList: [],
        myQueueListLoading: true,
        myQueueListFailed: false,
        myQueueListLoaded: false,
        errorLoadingMyQueueList: undefined,
      };
      break;
    }

    case QueueActionTypes.LoadUsableQueueList: {
      state = {
        ...state,
        errorLoadingUsableQueueList: undefined,
        isUsableQueueListLoaded: false,
      };
      break;
    }

    case QueueActionTypes.LoadOthersQueueList: {
      state = {
        ...state,
        othersQueueList: [],
        othersQueueListLoading: true,
        othersQueueListLoaded: false,
        othersQueueListFailed: false,
        errorLoadingOthersQueueList: undefined,
      };
      break;
    }

    case QueueActionTypes.MyQueueListLoaded: {
      state = {
        ...state,
        myQueueList: action.payload,
        myQueueListLoading: false,
        myQueueListFailed: false,
        myQueueListLoaded: true,
        errorLoadingMyQueueList: undefined,
      };
      break;
    }

    case QueueActionTypes.UsableQueueListLoaded: {
      state = {
        ...state,
        usableQueueList: action.payload,
        errorLoadingUsableQueueList: undefined,
        isUsableQueueListLoaded: true,
      };
      break;
    }

    case QueueActionTypes.OthersQueueListLoaded: {
      state = {
        ...state,
        othersQueueList: action.payload,
        othersQueueListLoading: false,
        othersQueueListLoaded: true,
        othersQueueListFailed: false,
        errorLoadingOthersQueueList: undefined,
      };
      break;
    }

    case QueueActionTypes.MyQueueListLoadingError: {
      state = {
        ...state,
        myQueueList: [],
        myQueueListLoading: false,
        myQueueListLoaded: false,
        myQueueListFailed: true,
        errorLoadingMyQueueList: action.payload,
      };
      break;
    }

    case QueueActionTypes.UsableQueueListLoadingError: {
      state = {
        ...state,
        usableQueueList: [],
        errorLoadingUsableQueueList: action.payload,
        isUsableQueueListLoaded: true,
      };
      break;
    }

    case QueueActionTypes.OthersQueueListLoadingError: {
      state = {
        ...state,
        othersQueueList: [],
        othersQueueListLoading: false,
        othersQueueListLoaded: false,
        othersQueueListFailed: true,
        errorLoadingOthersQueueList: action.payload,
      };
      break;
    }

    case QueueActionTypes.LoadQueueDetails: {
      state = {
        ...state,
        currentQueue:
          state.currentQueueUid === action.payload.queueUid
            ? state.currentQueue
            : undefined,
        currentQueueUid: action.payload.queueUid,
        errorLoadingCurrentQueue: undefined,
        isCurrentQueueLoaded: false,
      };
      break;
    }

    case QueueActionTypes.QueueDetailsLoaded: {
      state = {
        ...state,
        currentQueue: action.payload,
        errorLoadingCurrentQueue: undefined,
        isCurrentQueueLoaded: true,
      };
      break;
    }

    case QueueActionTypes.QueueDetailsLoadingError: {
      state = {
        ...state,
        currentQueue: undefined,
        isCurrentQueueLoaded: true,
        errorLoadingCurrentQueue: action.payload,
      };
      break;
    }

    case QueueActionTypes.SearchRecordInQueue: {
      const { searchText } = action;
      if (searchText.length < 2) {
        return {
          ...state,
          searchText: undefined,
        };
      }
      return {
        ...state,
        searchText: isEmpty(searchText) ? undefined : searchText,
      };
    }

    case QueueActionTypes.ToggleSearchbar: {
      return {
        ...state,
        searchText: "",
        isSearchActive: !state.isSearchActive,
      };
    }

    case QueueActionTypes.AddRecordToQueueTabs: {
      const { queueStatus, queueUid, recordName, recordUid } = action.payload;
      if (state.currentQueue?.uid === queueUid) {
        const records = cloneDeep(state.currentQueue?.records);

        let newItem: any = {
          createdAt: Json2TypescriptHelper.convertToEntity(new Date(), Date),
          updatedAt: Json2TypescriptHelper.convertToEntity(new Date(), Date),
          uid: recordUid,
          name: recordName,
          queueInfo: {
            name: "",
            stage: queueStatus,
            uid: queueUid,
          },
        };

        forEach(records, (item) => {
          const removedItem = remove(
            item,
            (record) => record.uid === recordUid
          );
          if (!isEmpty(removedItem)) {
            newItem = removedItem[0];
          }
        });

        state = {
          ...state,
          currentQueue: {
            ...state.currentQueue,
            records: state.currentQueue?.records
              ? {
                  ...records,
                  [queueStatus]: [...records[queueStatus], newItem],
                }
              : state.currentQueue?.records,
          },
        };
      }
      break;
    }

    case QueueActionTypes.FailedRecordSuccessful: {
      const records = cloneDeep(state.currentQueue?.records);
      let cloneRemovedRecord;
      if (records) {
        forEach(keys(records), (queueStage) => {
          cloneRemovedRecord = remove(records[queueStage], {
            uid: action.data?.uid,
          });
        });

        if (cloneRemovedRecord) {
          records[QUEUE_STATUS.FAILED].push(cloneRemovedRecord);
        }
      }

      return {
        ...state,
        currentQueue: {
          ...state.currentQueue,
          records: records,
        },
      };
    }
  }
  return state;
}
