import { Injectable } from "@angular/core";

import { select, Store } from "@ngrx/store";

import {
  AbstractReviewPartialState,
  AbstractReviewState,
  createTempMentionOption,
} from "./abstract-review.reducer";
import { abstractReviewQuery } from "./abstract-review.selectors";
import {
  AddTemporaryMention,
  CleanAbstractReviewState,
  CreateManualMention,
  CreateProvisionComment,
  CreateProvisionNote,
  DeSelectMention,
  LoadAbstractReview,
  LoadProvisionComments,
  LoadProvisionFullDetail,
  LoadSelectedAbstractDetail,
  LoadSelectedAbstractTOC,
  PickSnippet,
  RemoveSelectedMention,
  RemoveTemporaryMention,
  SaveDocumentsShortName,
  SaveSelectedDocument,
  SelectedDocumentFullyLoaded,
  SelectMention,
  SelectProvisionOption,
  SetCommentCountOnRecord,
  SetSelectedProvision,
  ToggleCommentPanelSideBar,
  UpdateProvisionNote,
  UpdateSelectedMentionLocally,
  UpdateMention,
  RemoveMention,
  AddMention,
  UpdateCustomTags,
  UpdateNoteWithProvisionUid,
  UpdateSelectedProvisionReviewStatus,
  SetMultiplePdfProvision,
  ProvisionFullDetailLoaded,
  AddNewlyCreatedProvision,
  UpdateProvisionMentionNote,
  UpdateSelectedProvisionMentionReviewStatus,
  UpdateMentionWithMentionIndex,
  SelectedAbstractDetailLoaded,
  RenameProvisionMention,
  SetAllCommentNodes,
  AddCommentNode,
  DeleteCommentNode,
  EditCommentNode,
  GetOptionRemindersData,
  GetOptionRemindersDataFailed,
  LockRecordSuccessful,
  UnlockRecordSuccessful,
  TogglePromptPlaygroundSideBar,
  AddNestedSubfields,
  MakeAnchorProvision,
  LastTouchedMentionUid,
  LastTouchedNestedSubfieldKey,
  UpdateProvisionReviewCompletionInfoSucceeded,
} from "./abstract-review.actions";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision";
import { ProvisionInfoModel } from "@@intelease/app-models/provision";
import {
  CustomTagViewModel,
  DocPdfViewModel,
  DocSetFullViewModel,
  FullValMultiPdfProvViewModel,
  FullValMultiplePdfProvisionViewModel,
  NestedProvisionSubfieldDtoModel,
  PartialValPdfProvisionViewModel,
  ProvisionInconsistenciesApiDtoModel,
  ProvisionReviewCompletionInfoViewModel,
  RecordLockInfoDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { Observable, Subject } from "rxjs";
import { ProvisionReviewStatusEnum } from "@@intelease/app-models/common/src";
import { filter, first, map } from "rxjs/operators";
import { DocumentsMapModel } from "./models/abstract-review.model";
import { PartialValProvisionValueModel } from "@@intelease/web/common/models";
import { CommentItemNode } from "@@intelease/web/abstraction-page/src/lib/components/web-abstraction-page-sidebar/components/toc/web-abstraction-page-sidebar-toc.component";
import { RemindersTypeEnum } from "@@intelease/web/abstraction-page/src";
import { NestedProvisionUtils } from "@@intelease/app-services/abstract-review";

@Injectable({
  providedIn: "root",
})
export class AbstractReviewFacade {
  public onMentionDuplicated$ = new Subject<PartialValPdfProvisionViewModel>();

  loaded$ = this.store.pipe(select(abstractReviewQuery.getLoaded));

  allAbstractReview$ = this.store.pipe(
    select(abstractReviewQuery.getAllAbstractReview)
  );
  selectedAbstractReview$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedAbstractReview)
  );
  selectedAbstractUid$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedAbstractUid)
  );
  selectedAbstractDetailLoaded$ = this.store.pipe(
    select(abstractReviewQuery.selectedAbstractDetailLoaded)
  );
  selectedAbstractDetail$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedAbstractDetail)
  );
  selectedAbstractFormDetail$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedAbstractForm)
  );
  getSelectedAbstractProvisions$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedAbstractProvisions)
  );
  getAbstractFullProvisionGroups$ = this.store.pipe(
    select(abstractReviewQuery.getAbstractFullProvisionGroups)
  );
  getMultiplePdfProvisionsList$ = this.store.pipe(
    select(abstractReviewQuery.getMultiplePdfProvisionsList)
  );
  getRecordDetails$ = this.store.pipe(
    select(abstractReviewQuery.getRecordDetails)
  );
  selectedAbstractTOCLoaded$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedAbstractTOCLoaded)
  );
  selectedAbstractTOC$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedAbstractTOC)
  );
  onPickSnippetSuccess$ = this.store.pipe(
    select(abstractReviewQuery.getPickSnippetSuccess)
  );
  getDocumentsShortNameMap$ = this.store.pipe(
    select(abstractReviewQuery.getDocumentsShortNameMap)
  );
  getSelectedDocument$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedDocument)
  );

  isCreateProvisionCommentLoading$ = this.store.pipe(
    select(abstractReviewQuery.isCreateProvisionCommentLoading)
  );
  createProvisionCommentSucceeded$ = this.store.pipe(
    select(abstractReviewQuery.createProvisionCommentSucceeded)
  );

  isUpdateProvisionNotePending$ = this.store.pipe(
    select(abstractReviewQuery.isUpdateProvisionNotePending)
  );
  isUpdateProvisionNoteSucceeded$ = this.store.pipe(
    select(abstractReviewQuery.isUpdateProvisionNoteSucceeded)
  );

  isLoadProvisionCommentsLoading$ = this.store.pipe(
    select(abstractReviewQuery.isLoadProvisionCommentsLoading)
  );
  getProvisionComments$ = this.store.pipe(
    select(abstractReviewQuery.getProvisionComments)
  );

  getSelectedProvision$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedProvision)
  );

  getProvisions$ = this.store.pipe(select(abstractReviewQuery.getProvisions));

  getNestedProvisionInconsistencies$ = this.store.pipe(
    select(abstractReviewQuery.getNestedProvisionInconsistencies)
  );

  getAnchorProvisionUid$ = this.store.pipe(
    select(abstractReviewQuery.getAnchorProvisionUid)
  );

  getLastTouchedMentionUid$ = this.store.pipe(
    select(abstractReviewQuery.getLastTouchedMentionUid)
  );

  getLastTouchedNestedSubfield$ = this.store.pipe(
    select(abstractReviewQuery.getLastTouchedNestedSubfieldKey)
  );

  getMentionApprovalCompletion$ = this.store.pipe(
    select(abstractReviewQuery.getMentionApprovalCompletion)
  );

  hasUnsavedMention$ = this.getProvisions$.pipe(
    map((provisions) =>
      Object.values(provisions || {}).some(
        (provision) =>
          provision.multiplePdfProvision.options.length > 1 &&
          provision.multiplePdfProvision.options.some(
            (option) => option.source === "TEMP_LOCAL"
          )
      )
    )
  );

  isSelectedDocumentFullyLoaded$ = this.store.pipe(
    select(abstractReviewQuery.isSelectedDocumentFullyLoaded)
  );

  isSelectedAbstractDetailLoading$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedAbstractDetailLoading)
  );

  getCommentCountInRecord$ = this.store.pipe(
    select(abstractReviewQuery.getCommentCountInRecord)
  );

  getCommentPanelSideBarVisibilityStatus$ = this.store.pipe(
    select(abstractReviewQuery.getCommentPanelSideBarVisibilityStatus)
  );

  getPromptPlaygroundSideBarVisibilityStatus$ = this.store.pipe(
    select(abstractReviewQuery.getPromptPlaygroundSideBarVisibilityStatus)
  );

  getCustomTags$ = this.store.pipe(select(abstractReviewQuery.getCustomTags));

  isUpdateCustomTagsPending$ = this.store.pipe(
    select(abstractReviewQuery.isUpdateCustomTagsPending)
  );
  isUpdateCustomTagsSucceeded$ = this.store.pipe(
    select(abstractReviewQuery.isUpdateCustomTagsSucceeded)
  );

  getUpdateNoteWithProvisionUid$ = this.store.pipe(
    select(abstractReviewQuery.getUpdateNoteWithProvisionUid)
  );

  getAbstractReviewPermissions$ = this.store.pipe(
    select(abstractReviewQuery.getAbstractReviewPermissions)
  );

  getSelectedDocInfo$ = this.store.pipe(
    select(abstractReviewQuery.getSelectedDocument)
  );

  getRecordCategory$ = this.store.pipe(
    select(abstractReviewQuery.getRecordCategory)
  );

  getQueueInfo$ = this.store.pipe(select(abstractReviewQuery.getQueueInfo));

  getCommentNodes$ = this.store.pipe(
    select(abstractReviewQuery.getCommentNodes)
  );

  getCommentNodeLoaded$ = this.store.pipe(
    select(abstractReviewQuery.getCommentNodeLoaded)
  );

  getDocumentUids$ = this.store
    .pipe(select(abstractReviewQuery.getDocuments))
    .pipe(map((documents) => documents.map((document) => document.uid)));

  getOptionReminderDataLoading$ = this.store.pipe(
    select(abstractReviewQuery.getOptionReminderDataLoading)
  );

  getOptionRemindersData$ = (optionRemindersType: RemindersTypeEnum) =>
    this.store.pipe(
      select(abstractReviewQuery.getOptionRemindersData, optionRemindersType)
    );

  getOptionTypeToSurroundingText$ = (optionRemindersType: RemindersTypeEnum) =>
    this.store.pipe(
      select(
        abstractReviewQuery.getOptionTypeToSurroundingText,
        optionRemindersType
      )
    );

  getProvisionWithhtmlName$ = (htmlName) =>
    this.store.pipe(
      select(abstractReviewQuery.getProvisionWithhtmlName, htmlName)
    );

  getSelectedAbstractProvisionByProvisionUid$ = (uid: string) =>
    this.store.pipe(
      select(abstractReviewQuery.getSelectedAbstractProvisionByProvisionUid, {
        uid,
      })
    );
  getProvisionGroupByProvisionUid$ = (uid: string) =>
    this.store.pipe(
      select(abstractReviewQuery.getProvisionGroupByProvisionUid, {
        uid,
      })
    );

  constructor(private store: Store<AbstractReviewPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadAbstractReview());
  }

  loadSelectedAbstract(abstractUid: string) {
    this.store.dispatch(new LoadSelectedAbstractDetail({ abstractUid }));
  }

  addNewlyCreatedProvision(
    provision: FullValMultiPdfProvModel,
    provisionCategoryOrGroupUid: string,
    preProvisionLocationUid?: string,
    postProvisionLocationUid?: string
  ) {
    this.store.dispatch(
      new AddNewlyCreatedProvision({
        provision,
        provisionCategoryOrGroupUid,
        preProvisionLocationUid,
        postProvisionLocationUid,
      })
    );
  }

  selectAbstractProvisionOption() {
    this.store.dispatch(new SelectProvisionOption({ provisionOptionUid: "" }));
  }

  loadSelectedAbstractTOC(payload) {
    this.store.dispatch(new LoadSelectedAbstractTOC(payload));
  }

  updateSelectedProvisionReviewStatus(payload: {
    provisionUid: string;
    reviewStatus: string;
  }) {
    this.store.dispatch(new UpdateSelectedProvisionReviewStatus(payload));
  }

  updateSelectedProvisionMentionReviewStatus(
    provisionUid: FullValMultiPdfProvViewModel["uid"],
    provisionOptionUid: PartialValPdfProvisionViewModel["uid"],
    reviewStatus: string
  ) {
    this.store.dispatch(
      new UpdateSelectedProvisionMentionReviewStatus({
        provisionUid,
        provisionOptionUid,
        reviewStatus,
      })
    );
  }

  public getProvisionByUid(
    uid: FullValMultiPdfProvViewModel["uid"]
  ): Observable<FullValMultiPdfProvViewModel> {
    return this.store.select(abstractReviewQuery.getProvisionByUid(uid));
  }

  pickSnippet(payload) {
    this.store.dispatch(new PickSnippet(payload));
  }

  selectMention(payload) {
    this.store.dispatch(new SelectMention(payload));
  }

  deSelectMention(payload) {
    this.store.dispatch(new DeSelectMention(payload));
  }

  createManualMention(payload) {
    this.store.dispatch(new CreateManualMention(payload));
  }

  loadProvisionFullDetail(payload: {
    abstractUid: string;
    provisionUid: string;
  }) {
    this.store.dispatch(new LoadProvisionFullDetail(payload));
  }

  setMultiplePdfProvision(payload: {
    provisionUid: string;
    provisionDetail: FullValMultiPdfProvModel;
  }) {
    this.store.dispatch(new SetMultiplePdfProvision(payload));
  }

  updateMention(payload: {
    provisionUid: string;
    mention: PartialValPdfProvisionViewModel;
  }) {
    this.store.dispatch(new UpdateMention(payload));
  }

  addTemporaryMention(payload: {
    provisionUid: string;
    mention: PartialValProvisionValueModel;
  }) {
    this.store.dispatch(new AddTemporaryMention(payload));
  }

  duplicateMention(
    provision: FullValMultiPdfProvViewModel,
    provisionOption: PartialValPdfProvisionViewModel
  ): void {
    let mention = createTempMentionOption(
      provision,
      ProvisionReviewStatusEnum.DEFAULT
    );
    if (provision.provisionType === "NESTED_GROUP") {
      const ids = provision.multiplePdfProvision.options
        .slice()
        .filter((opt) => (opt.value as any)?.id !== undefined)
        .sort(NestedProvisionUtils.compareByIndexAndId)
        .map((opt) => (opt.value as any)?.id);
      const id = ids && ids.length > 0 ? ids[ids.length - 1] + 1 : 1;
      mention = {
        ...mention,
        value: {
          ...mention?.value,
          id: id,
        },
      };
      this.onMentionDuplicated$.next(mention);
    }
    this.store.dispatch(
      new AddTemporaryMention({
        provisionUid: provision.provisionUid,
        mention,
        prevMentionUid: provisionOption.uid,
      })
    );
  }

  addMention(payload: {
    provisionUid: string;
    data: PartialValProvisionValueModel;
  }) {
    this.store.dispatch(new AddMention(payload));
  }

  removeTemporaryMention(payload: {
    provisionUid: string;
    mentionUid: string;
  }) {
    this.store.dispatch(new RemoveTemporaryMention(payload));
  }

  removeMention(payload: { provisionUid: string }) {
    this.store.dispatch(new RemoveMention(payload));
  }

  removeSelectedMention(payload: { provisionUid: string; mentionUid: string }) {
    this.store.dispatch(new RemoveSelectedMention(payload));
  }

  saveDocumentsShortName(payload: {
    [documentUid: string]: DocumentsMapModel;
  }) {
    this.store.dispatch(new SaveDocumentsShortName(payload));
  }

  saveSelectedDocument(payload) {
    this.store.dispatch(new SaveSelectedDocument(payload));
  }

  updateSelectedMentionLocally(payload) {
    this.store.dispatch(new UpdateSelectedMentionLocally(payload));
  }

  createProvisionComment(payload) {
    this.store.dispatch(new CreateProvisionComment(payload));
  }

  loadProvisionComments(abstractUid: string, provisionUid: string) {
    this.store.dispatch(
      new LoadProvisionComments({ abstractUid, provisionUid })
    );
  }

  cleanAbstractReviewState(payload: Partial<AbstractReviewState>) {
    this.store.dispatch(new CleanAbstractReviewState(payload));
  }

  createProvisionNote(payload) {
    this.store.dispatch(new CreateProvisionNote(payload));
  }

  updateProvisionNote(payload) {
    this.store.dispatch(new UpdateProvisionNote(payload));
  }

  setSelectedProvision(provisionInfo: ProvisionInfoModel) {
    this.store.dispatch(new SetSelectedProvision(provisionInfo));
  }

  setSelectedDocumentFullyLoaded(loaded: boolean) {
    this.store.dispatch(new SelectedDocumentFullyLoaded({ loaded }));
  }

  setCommentCountInRecord(count: number) {
    this.store.dispatch(new SetCommentCountOnRecord(count));
  }

  toggleCommentPanelSideBarVisibility(isVisible: boolean) {
    this.store.dispatch(new ToggleCommentPanelSideBar(isVisible));
  }

  togglePromptPlaygroundSideBarVisibility(isVisible: boolean) {
    this.store.dispatch(new TogglePromptPlaygroundSideBar(isVisible));
  }

  setAllCommentNodes(comments: CommentItemNode) {
    this.store.dispatch(new SetAllCommentNodes(comments));
  }

  addCommentNode(comment: CommentItemNode) {
    this.store.dispatch(new AddCommentNode(comment));
  }

  editCommentNode(commentUid: string, comment: CommentItemNode) {
    this.store.dispatch(new EditCommentNode(commentUid, comment));
  }

  deleteCommentNode(commentUid: string, documentUid: string) {
    this.store.dispatch(new DeleteCommentNode(commentUid, documentUid));
  }

  updateCustomTags(payload: {
    recordUid: string;
    customTags: CustomTagViewModel[];
  }) {
    this.store.dispatch(new UpdateCustomTags(payload));
  }

  updateNoteWithProvisionUid(payload: { provisionUid: string; notes: string }) {
    this.store.dispatch(new UpdateNoteWithProvisionUid(payload));
  }

  updateMentionWithMentionIndex(
    provisionUid: string,
    newMention: PartialValPdfProvisionViewModel,
    mentionIndex: number
  ) {
    this.store.dispatch(
      new UpdateMentionWithMentionIndex(provisionUid, newMention, mentionIndex)
    );
  }

  setFullProvisionDetailWithProvisionUid(payload: {
    provisionUid: string;
    provisionDetail;
    provisionInconsistencies?: ProvisionInconsistenciesApiDtoModel;
  }) {
    this.store.dispatch(new ProvisionFullDetailLoaded(payload));
  }

  updateProvisionMentionNote(payload: {
    provision: FullValMultiPdfProvViewModel;
    provisionOption: PartialValPdfProvisionViewModel;
    notes: FullValMultiplePdfProvisionViewModel["notes"];
  }): void {
    this.store.dispatch(UpdateProvisionMentionNote({ payload }));
  }

  getAnnotationsByDocumentUid(
    uid: DocPdfViewModel["docAbstractUid"]
  ): Observable<DocPdfViewModel> {
    return this.store.select(
      abstractReviewQuery.getAnnotationsByDocumentUid(uid)
    );
  }

  renameProvisionMention(payload: {
    mentionUid: string;
    provisionUid: string;
    newMentionName: string;
  }) {
    this.store.dispatch(new RenameProvisionMention(payload));
  }

  addNestedSubfields(payload: {
    provisionUid: string;
    additionalSubfields: NestedProvisionSubfieldDtoModel[];
  }) {
    this.store.dispatch(new AddNestedSubfields(payload));
  }

  makeAnchorProvision(payload: { provisionUid: string }) {
    this.store.dispatch(new MakeAnchorProvision(payload));
  }

  updateProvisionReviewCompletionInfoSucceeded(payload: {
    provisionUid: string;
    reviewCompletionInfos: ProvisionReviewCompletionInfoViewModel[];
  }) {
    this.store.dispatch(
      new UpdateProvisionReviewCompletionInfoSucceeded(payload)
    );
  }

  touchMentionUid(mentionUid?: string) {
    this.store.dispatch(new LastTouchedMentionUid({ mentionUid }));
  }

  touchNestedSubfield(subfieldKey?: string) {
    this.store.dispatch(new LastTouchedNestedSubfieldKey({ subfieldKey }));
  }

  getOptionReminderData(payload: {
    recordUid: string;
    optionRemindersType: RemindersTypeEnum;
  }) {
    this.store
      .pipe(
        select(
          abstractReviewQuery.getOptionRemindersData,
          payload.optionRemindersType
        )
      )
      .pipe(
        first(),
        filter((optionRemindersData) => !optionRemindersData)
      )
      .subscribe(() =>
        this.store.dispatch(new GetOptionRemindersData(payload))
      );
  }

  clearOptionReminderData() {
    this.store.dispatch(new GetOptionRemindersDataFailed());
  }

  /**
   *
   * @param docSet The DocSet for which the PDF Preview will be shown
   *
   * @description Stores the DocSet Details as the abstract details to trick the PdfReader.
   * The PdfReader needs a abstractDetails to extract the abstractUid from.
   */
  saveDocSetAsRecordToTrickThePdfReader(docSet: DocSetFullViewModel) {
    const payload = {
      abstractDetail: docSet as any,
      provisions: {} as any,
      formStructure: {} as any,
      provisionInconsistencies: (docSet as any)?.provisionInconsistencies,
    };
    return this.store.dispatch(new SelectedAbstractDetailLoaded(payload));
  }

  lockRecord(lockInfo: RecordLockInfoDtoModel) {
    this.store.dispatch(new LockRecordSuccessful({ lockInfo }));
  }

  unlockRecord() {
    this.store.dispatch(new UnlockRecordSuccessful());
  }
}
