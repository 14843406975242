import {
  ListResponseModel,
  PaginationModel,
} from "@@intelease/web/intelease/models";
import { SortDirectionEnum } from "@@intelease/web/common/enums/sort-direction.enum";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { Observable } from "rxjs";
import { SearchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { DocSetCategoryViewModel } from "@@intelease/api-models/adex-api-model-src";

@Injectable({
  providedIn: "root",
})
export class SearchProvisionService {
  private static readonly API_VERSION_2 = "/v2";
  private static readonly PROVISIONS_URL = "/provisions";

  constructor(private searchApiService: SearchApiService) {}

  getNewPossibleProvisions<T extends object>(
    abstractUid: string,
    docSetCategory: DocSetCategoryViewModel,
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    const data = {
      filters: [
        {
          field: "abstracts::Abstracts::" + PROVISIONS_DATA_CONST.STRING.name,
          relation: "NOT_CONTAIN",
          value: abstractUid,
        },
      ],
      includeUserProvisions: false,
      activesOnly: true,
      docSetCategory: docSetCategory.value,
    };
    return this.searchApiService.sendCustomDataRequest(
      SearchProvisionService.API_VERSION_2,
      SearchProvisionService.PROVISIONS_URL,
      view,
      classRef,
      data,
      "name::Name::" +
        PROVISIONS_DATA_CONST.STRING.name +
        "::" +
        SortDirectionEnum.ASC,
      new PaginationModel(1, 1000)
    );
  }
}
