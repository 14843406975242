import { Directive, ElementRef, HostListener, Input } from "@angular/core";

/*
  copied from:
    https://stackoverflow.com/questions/41465542/angular2-input-field-to-accept-only-numbers
  author:
    https://stackoverflow.com/users/2425396/omeralper
 */
@Directive({
  selector: "[digitsOnly]",
})
export class DigitsOnlyDirective {
  constructor(private el: ElementRef) {}

  @Input() digitsOnly: boolean;

  @HostListener("keydown", ["$event"]) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    if (this.digitsOnly) {
      if (
        /*
                    BS	08	backspace
                    HT	09	horizontal tab
                    CR	13	carriage return
                    ESC	27	escape
                 */
        [8, 9, 13, 27].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if (
        (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105)
      ) {
        e.preventDefault();
      }
    }
  }
}
