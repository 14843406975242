import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

export enum LeftMenuAction {
  EXPAND,
  COLLAPSE,
  TOGGLE,
  TURN_OFF,
}

@Injectable({
  providedIn: "root",
})
export class LeftMenuService {
  private _leftMenu: BehaviorSubject<LeftMenuAction> = new BehaviorSubject(
    LeftMenuAction.COLLAPSE
  );
  leftMenuAction$: Observable<LeftMenuAction> = this._leftMenu.asObservable();

  public expand(): void {
    this.sendAction(LeftMenuAction.EXPAND);
  }

  public collapse(): void {
    this.sendAction(LeftMenuAction.COLLAPSE);
  }

  public toggle(): void {
    this.sendAction(LeftMenuAction.TOGGLE);
  }

  public turnOff(): void {
    this.sendAction(LeftMenuAction.TURN_OFF);
  }

  private sendAction(action: LeftMenuAction): void {
    this._leftMenu.next(action);
  }
}
