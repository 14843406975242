/* tslint:disable */
/* eslint-disable */
import { FrontendErrorInfoDtoModel } from "./frontend-error-info-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FrontendErrorInfosDtoModel")
export class FrontendErrorInfosDtoModel {
  @JsonProperty("errors", [FrontendErrorInfoDtoModel], true)
  errors?: Array<FrontendErrorInfoDtoModel> = [];
}
