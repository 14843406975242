import { FullValMultiPdfProvViewModel } from "@@intelease/api-models/adex-api-model-src";
import { ProvisionReviewStatusEnum } from "@@intelease/app-models/common/src";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision/src";
import { AbstractReviewFacade } from "@@intelease/app-state/abstract-review";
import { ProvisionReviewUIFacade } from "@@intelease/web/abstract-review/src/lib/store/provision-review.facade";
import { PartialValProvisionValueModel } from "@@intelease/web/common/models";
import { shouldSetStatusToNeedReviewTouched } from "@@intelease/web/utils/provision-review-utils";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CalculatorUtilsService {
  constructor(
    private readonly abstractReviewFacade: AbstractReviewFacade,
    private readonly provisionReviewUIFacade: ProvisionReviewUIFacade
  ) {}

  public updateViewAfterChangeInProvisions(
    backendProvisionDefinition: FullValMultiPdfProvViewModel,
    currentProvisionDefinition: FullValMultiPdfProvViewModel,
    newMentionUid: string,
    selectedMentionIndex?: number
  ): void {
    const { provisionUid } = backendProvisionDefinition?.multiplePdfProvision;
    const provisionDetail = backendProvisionDefinition;
    const options = [
      ...currentProvisionDefinition.multiplePdfProvision.options,
    ];
    const newOption = provisionDetail.multiplePdfProvision.options.find(
      (option) => option.uid === newMentionUid
    );

    if (selectedMentionIndex !== undefined) {
      options.splice(
        selectedMentionIndex,
        1,
        newOption as PartialValProvisionValueModel
      );
    } else {
      options.push(newOption as PartialValProvisionValueModel);
    }

    this.abstractReviewFacade.setFullProvisionDetailWithProvisionUid({
      provisionUid,
      provisionDetail: {
        ...provisionDetail,
        multiplePdfProvision: {
          ...provisionDetail.multiplePdfProvision,
          options,
        },
        reviewStatus: shouldSetStatusToNeedReviewTouched(
          currentProvisionDefinition as unknown as FullValMultiPdfProvModel
        )
          ? ProvisionReviewStatusEnum.NEED_REVIEW_TOUCHED
          : provisionDetail.reviewStatus,
      },
    });

    this.provisionReviewUIFacade.scrollToProvisionOption(
      currentProvisionDefinition,
      newOption
    );
  }
}
