/* tslint:disable */
/* eslint-disable */
import { ViewMultiplePdfProvisionModel } from "./view-multiple-pdf-provision-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AbstractProvisionGroupStringsModel")
export class AbstractProvisionGroupStringsModel {
  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("provisions", [ViewMultiplePdfProvisionModel], true)
  provisions?: Array<ViewMultiplePdfProvisionModel> = [];

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
