import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { map } from "rxjs/operators";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";

@Injectable({
  providedIn: "root",
})
export class ShareApiService {
  private static readonly SHARE_URL_PART = "/share";

  constructor(private httpClient: HttpClient) {}

  /**
   * Send a POST request to share a single object with a set of accounts / users.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param objectUid the object being shared
   * @param entityUids the set of entities with which the object is being shared
   * @param view the view of the shared object to return
   * @param classRef the class ref for the returned object
   * @param allVersions whether this share will be for all versions of this object
   */
  sendRequest<T extends object>(
    apiVersion: string,
    baseUrl: string,
    objectUid: string,
    entityUids: string[],
    view: string,
    classRef: new () => T,
    allVersions: boolean
  ): Observable<T> {
    const body = this.createShareRequestBody(entityUids, view, allVersions);
    return this.helpSendRequest(body, apiVersion, baseUrl, objectUid, classRef);
  }

  /**
   * Send a POST request to share a single object with a set of accounts / users, don't return anything.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param objectUid the object being shared
   * @param entityUids the set of entities with which the object is being shared
   * @param allVersions whether this share will be for all versions of this object
   */
  sendRequestNoResponse(
    apiVersion: string,
    baseUrl: string,
    objectUid: string,
    entityUids: string[],
    allVersions: boolean
  ): void {
    this.sendRequestNoView(
      apiVersion,
      baseUrl,
      objectUid,
      entityUids,
      allVersions
    ).subscribe((res) => {});
  }

  /**
   * Send a POST request to share a single object with a set of accounts / users, don't return anything.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param objectUid the object being shared
   * @param entityUids the set of entities with which the object is being shared
   * @param allVersions whether this share will be for all versions of this object
   * @return response, not containing the object
   */
  sendRequestNoView(
    apiVersion: string,
    baseUrl: string,
    objectUid: string,
    entityUids: string[],
    allVersions: boolean
  ): Observable<ServerResponseModel> {
    const body = this.createShareRequestBody(entityUids, "none", allVersions);
    return this.helpSendRequestNoView(body, apiVersion, baseUrl, objectUid);
  }

  /*
   * Below are private helper methods
   */

  private helpSendRequest<T extends object>(
    body: any,
    apiVersion: string,
    baseUrl: string,
    objectUid: string,
    classRef: new () => T
  ): Observable<T> {
    return this.helpSendRequestNoView(
      body,
      apiVersion,
      baseUrl,
      objectUid
    ).pipe(
      map((res) => Json2TypescriptHelper.convertToEntity(res.data, classRef))
    );
  }

  private helpSendRequestNoView(
    body: any,
    apiVersion: string,
    baseUrl: string,
    objectUid: string
  ): Observable<ServerResponseModel> {
    return this.httpClient.post<ServerResponseModel>(
      apiVersion + ShareApiService.SHARE_URL_PART + baseUrl + "/" + objectUid,
      body
    );
  }

  private createShareRequestBody(
    entityUids: string[],
    view: string,
    allVersions: boolean
  ): any {
    return {
      data: {
        scopeUids: entityUids,
        affectAllVersions: allVersions,
      },
      returnParams: {
        view: view,
      },
    };
  }
}
