import { Action } from "@ngrx/store";
import {
  OApiReqMarkRecordFailedModel,
  QueueDetailsDtoModel,
  QueueDtoModel,
  RecordCompletedResponseDtoModel,
  SimpleQueueDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { QUEUE_STATUS } from "./models/queue.types";

export enum QueueActionTypes {
  LoadMyQueueList = "[Queue] Load My Queue List",
  MyQueueListLoaded = "[Queue] My Queue List Loaded",
  MyQueueListLoadingError = "[Queue] My Queue List Loading Error",

  LoadUsableQueueList = "[Queue] Load Usable Queue List",
  UsableQueueListLoaded = "[Queue] Usable Queue List Loaded",
  UsableQueueListLoadingError = "[Queue] Usable Queue List Loading Error",

  LoadOthersQueueList = "[Queue] Load Others Queue List",
  OthersQueueListLoaded = "[Queue] Others Queue List Loaded",
  OthersQueueListLoadingError = "[Queue] Others Queue List Loading Error",

  LoadQueueDetails = "[Queue] Load Queue Details",
  QueueDetailsLoaded = "[Queue] Queue Details Loaded",
  QueueDetailsLoadingError = "[Queue] Queue Details Loading Error",

  AddRecordToQueueTabs = "[Queue] Add Record To Queue Tabs",
  SearchRecordInQueue = "[Queue] Search Record In Queue",
  OnSearchRecordInQueue = "[Queue] On Search Record In Queue",
  ToggleSearchbar = "[Queue] Toggle Searchbar",
  FailedRecord = "[Queue] Failed Record",
  FailedRecordSuccessful = "[Queue] Failed Record Successful",
  FailedRecordError = "[Queue] Failed Record Error",
}

export class LoadMyQueueList implements Action {
  readonly type = QueueActionTypes.LoadMyQueueList;
}

export class LoadUsableQueueList implements Action {
  readonly type = QueueActionTypes.LoadUsableQueueList;
}

export class LoadOthersQueueList implements Action {
  readonly type = QueueActionTypes.LoadOthersQueueList;
}

export class MyQueueListLoaded implements Action {
  readonly type = QueueActionTypes.MyQueueListLoaded;

  constructor(public payload: QueueDtoModel[]) {}
}

export class UsableQueueListLoaded implements Action {
  readonly type = QueueActionTypes.UsableQueueListLoaded;

  constructor(public payload: SimpleQueueDtoModel[]) {}
}

export class OthersQueueListLoaded implements Action {
  readonly type = QueueActionTypes.OthersQueueListLoaded;

  constructor(public payload: QueueDtoModel[]) {}
}

export class MyQueueListLoadingError {
  readonly type = QueueActionTypes.MyQueueListLoadingError;

  constructor(public payload: any) {}
}

export class UsableQueueListLoadingError {
  readonly type = QueueActionTypes.UsableQueueListLoadingError;

  constructor(public payload: any) {}
}

export class OthersQueueListLoadingError {
  readonly type = QueueActionTypes.OthersQueueListLoadingError;

  constructor(public payload: any) {}
}

export class LoadQueueDetails implements Action {
  readonly type = QueueActionTypes.LoadQueueDetails;
  constructor(public payload: { queueUid: string }) {}
}

export class QueueDetailsLoaded implements Action {
  readonly type = QueueActionTypes.QueueDetailsLoaded;

  constructor(public payload: QueueDetailsDtoModel) {}
}

export class QueueDetailsLoadingError {
  readonly type = QueueActionTypes.QueueDetailsLoadingError;

  constructor(public payload: any) {}
}

export class AddRecordToQueueTabs {
  readonly type = QueueActionTypes.AddRecordToQueueTabs;

  constructor(
    public payload: {
      recordUid: string;
      recordName: string;
      queueUid: string;
      queueStatus: QUEUE_STATUS;
    }
  ) {}
}

export class SearchRecordInQueueApply {
  readonly type = QueueActionTypes.SearchRecordInQueue;
  constructor(public searchText: string) {}
}

export class OnSearchRecordInQueue {
  readonly type = QueueActionTypes.OnSearchRecordInQueue;
  constructor(public searchText: string) {}
}

export class ToggleSearchbar {
  readonly type = QueueActionTypes.ToggleSearchbar;
}

export class FailedRecord {
  readonly type = QueueActionTypes.FailedRecord;
  constructor(
    public params: {
      body: OApiReqMarkRecordFailedModel;
    }
  ) {}
}

export class FailedRecordSuccessful {
  readonly type = QueueActionTypes.FailedRecordSuccessful;
  constructor(public data?: RecordCompletedResponseDtoModel) {}
}

export class FailedRecordError {
  readonly type = QueueActionTypes.FailedRecord;
}

export type QueueAction =
  | LoadMyQueueList
  | LoadUsableQueueList
  | LoadOthersQueueList
  | MyQueueListLoaded
  | UsableQueueListLoaded
  | OthersQueueListLoaded
  | MyQueueListLoadingError
  | UsableQueueListLoadingError
  | OthersQueueListLoadingError
  | LoadQueueDetails
  | QueueDetailsLoaded
  | QueueDetailsLoadingError
  | AddRecordToQueueTabs
  | SearchRecordInQueueApply
  | ToggleSearchbar
  | OnSearchRecordInQueue
  | FailedRecord
  | FailedRecordSuccessful
  | FailedRecordError;

export const QueueActions = {
  LoadMyQueueList,
  LoadUsableQueueList,
  LoadOthersQueueList,
  MyQueueListLoaded,
  UsableQueueListLoaded,
  OthersQueueListLoaded,
  MyQueueListLoadingError,
  UsableQueueListLoadingError,
  OthersQueueListLoadingError,
  LoadQueueDetails,
  QueueDetailsLoaded,
  QueueDetailsLoadingError,

  AddRecordToQueueTabs,

  SearchRecordInQueueApply,
  OnSearchRecordInQueue,
  ToggleSearchbar,

  FailedRecord,
  FailedRecordSuccessful,
  FailedRecordError,
};
