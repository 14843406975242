/* tslint:disable */
/* eslint-disable */
import { FormFieldOptionModel } from "./form-field-option-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FormFieldModel")
export class FormFieldModel {
  @JsonProperty("defaultValue", Any, true)
  defaultValue?: any = {};

  @JsonProperty("fieldFormat", Any, true)
  fieldFormat?:
    | "TEXT"
    | "TEXT_AREA"
    | "NUMBER"
    | "DATE"
    | "SELECT"
    | "RELATIVE_TIME_TO_EVENT"
    | "TIME_PERIOD" = undefined;

  @JsonProperty("fieldKey", String, true)
  fieldKey?: string = "";

  @JsonProperty("fieldLabel", String, true)
  fieldLabel?: string = "";

  @JsonProperty("fieldOptions", [FormFieldOptionModel], true)
  fieldOptions?: Array<FormFieldOptionModel> = [];

  @JsonProperty("multiSelect", Boolean, true)
  multiSelect?: boolean = undefined;

  @JsonProperty("optional", Boolean, true)
  optional?: boolean = undefined;
}
