import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromAbstractReview from "./abstract-review.reducer";
import { AbstractReviewEffects } from "./abstract-review.effects";
import * as fromIndividualAbstract from "./individual-review/individual-abstract.reducer";
import { IndividualAbstractEffects } from "./individual-review/individual-abstract.effects";
import * as editMentionTracker from "./mention-edit-tracker/mention-edit-tracker.reducer";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromAbstractReview.ABSTRACTREVIEW_FEATURE_KEY,
      fromAbstractReview.reducer
    ),
    StoreModule.forFeature(
      fromIndividualAbstract.INDIVIDUALABSTRACT_FEATURE_KEY,
      fromIndividualAbstract.reducerIndividualAbstract
    ),
    StoreModule.forFeature(
      editMentionTracker.MENTION_EDIT_TRACKER_FEATURE_KEY,
      editMentionTracker.mentionEditTrackerReducer
    ),
    EffectsModule.forFeature([
      AbstractReviewEffects,
      IndividualAbstractEffects,
    ]),
  ],
})
export class AppStateAbstractReviewModule {}
