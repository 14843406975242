import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";

export enum UpdateMention {
  Override,
  Append,
}

@Component({
  standalone: true,
  imports: [MatButtonModule, MatDialogModule],
  selector: "intelease-append-highlighted-text",
  templateUrl: "./append-highlighted-text.component.html",
  styleUrls: ["./append-highlighted-text.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppendHighlightedTextComponent {
  private dialogRef: MatDialogRef<AppendHighlightedTextComponent> =
    inject(MatDialogRef);

  public append(): void {
    this.dialogRef.close({
      type: ModalsResponseTypeEnum.CLOSE,
      value: UpdateMention.Append,
    });
  }
  public override(): void {
    this.dialogRef.close({
      type: ModalsResponseTypeEnum.CLOSE,
      value: UpdateMention.Override,
    });
  }
  public cancel(): void {
    this.dialogRef.close({ type: ModalsResponseTypeEnum.DISMISS });
  }
}
