/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PriceIndexDtoModel")
export class PriceIndexDtoModel {
  @JsonProperty("countries", Any, true)
  countries?: Array<
    | "AFGHANISTAN"
    | "ALAND_ISLANDS"
    | "ALBANIA"
    | "ALGERIA"
    | "AMERICAN_SAMOA"
    | "ANDORRA"
    | "ANGOLA"
    | "ANGUILLA"
    | "ANTARCTICA"
    | "ANTIGUA_AND_BARBUDA"
    | "ARGENTINA"
    | "ARMENIA"
    | "ARUBA"
    | "AUSTRALIA"
    | "AUSTRIA"
    | "AZERBAIJAN"
    | "BAHAMAS"
    | "BAHRAIN"
    | "BANGLADESH"
    | "BARBADOS"
    | "BELARUS"
    | "BELGIUM"
    | "BELIZE"
    | "BENIN"
    | "BERMUDA"
    | "BHUTAN"
    | "BOLIVIA"
    | "BONAIRE"
    | "BOSNIA_AND_HERZEGOVINA"
    | "BOTSWANA"
    | "BOUVET_ISLAND"
    | "BRAZIL"
    | "BRITISH_INDIAN_OCEAN_TERRITORY"
    | "BRUNEI_DARUSSALAM"
    | "BULGARIA"
    | "BURKINA_FASO"
    | "BURUNDI"
    | "CAMBODIA"
    | "CAMEROON"
    | "CANADA"
    | "CAPE_VERDE"
    | "CAYMAN_ISLANDS"
    | "CENTRAL_AFRICAN_REPUBLIC"
    | "CHAD"
    | "CHILE"
    | "CHINA"
    | "CHRISTMAS_ISLAND"
    | "COCOS_ISLANDS"
    | "COLOMBIA"
    | "COMOROS"
    | "CONGO"
    | "DEMOCRATIC_REPUBLIC_OF_THE_CONGO"
    | "COOK_ISLANDS"
    | "COSTA_RICA"
    | "CROATIA"
    | "CUBA"
    | "CURAAO"
    | "CYPRUS"
    | "CZECH_REPUBLIC"
    | "COTE_DIVOIRE"
    | "DENMARK"
    | "DJIBOUTI"
    | "DOMINICA"
    | "DOMINICAN_REPUBLIC"
    | "ECUADOR"
    | "EGYPT"
    | "EL_SALVADOR"
    | "EQUATORIAL_GUINEA"
    | "ERITREA"
    | "ESTONIA"
    | "ETHIOPIA"
    | "FALKLAND_ISLANDS"
    | "FAROE_ISLANDS"
    | "FIJI"
    | "FINLAND"
    | "FRANCE"
    | "FRENCH_GUIANA"
    | "FRENCH_POLYNESIA"
    | "FRENCH_SOUTHERN_TERRITORIES"
    | "GABON"
    | "GAMBIA"
    | "GEORGIA"
    | "GERMANY"
    | "GHANA"
    | "GIBRALTAR"
    | "GREECE"
    | "GREENLAND"
    | "GRENADA"
    | "GUADELOUPE"
    | "GUAM"
    | "GUATEMALA"
    | "GUERNSEY"
    | "GUINEA"
    | "GUINEA_BISSAU"
    | "GUYANA"
    | "HAITI"
    | "HEARD_ISLAND_AND_MCDONALD_MCDONALD_ISLANDS"
    | "VATICAN_CITY_STATE"
    | "HONDURAS"
    | "HONG_KONG"
    | "HUNGARY"
    | "ICELAND"
    | "INDIA"
    | "INDONESIA"
    | "IRAN"
    | "IRAQ"
    | "IRELAND"
    | "ISLE_OF_MAN"
    | "ISRAEL"
    | "ITALY"
    | "JAMAICA"
    | "JAPAN"
    | "JERSEY"
    | "JORDAN"
    | "KAZAKHSTAN"
    | "KENYA"
    | "KIRIBATI"
    | "KUWAIT"
    | "KYRGYZSTAN"
    | "LAOS"
    | "LATVIA"
    | "LEBANON"
    | "LESOTHO"
    | "LIBERIA"
    | "LIBYA"
    | "LIECHTENSTEIN"
    | "LITHUANIA"
    | "LUXEMBOURG"
    | "MACAO"
    | "MACEDONIA"
    | "MADAGASCAR"
    | "MALAWI"
    | "MALAYSIA"
    | "MALDIVES"
    | "MALI"
    | "MALTA"
    | "MARSHALL_ISLANDS"
    | "MARTINIQUE"
    | "MAURITANIA"
    | "MAURITIUS"
    | "MAYOTTE"
    | "MEXICO"
    | "MICRONESIA"
    | "MOLDOVA"
    | "MONACO"
    | "MONGOLIA"
    | "MONTENEGRO"
    | "MONTSERRAT"
    | "MOROCCO"
    | "MOZAMBIQUE"
    | "MYANMAR"
    | "NAMIBIA"
    | "NAURU"
    | "NEPAL"
    | "NETHERLANDS"
    | "NEW_CALEDONIA"
    | "NEW_ZEALAND"
    | "NICARAGUA"
    | "NIGER"
    | "NIGERIA"
    | "NIUE"
    | "NORFOLK_ISLAND"
    | "NORTH_KOREA"
    | "NORTHERN_MARIANA_ISLANDS"
    | "NORWAY"
    | "OMAN"
    | "PAKISTAN"
    | "PALAU"
    | "PALESTINE"
    | "PANAMA"
    | "PAPUA_NEW_GUINEA"
    | "PARAGUAY"
    | "PERU"
    | "PHILIPPINES"
    | "PITCAIRN"
    | "POLAND"
    | "PORTUGAL"
    | "PUERTO_RICO"
    | "QATAR"
    | "ROMANIA"
    | "RUSSIAN_FEDERATION"
    | "RWANDA"
    | "REUNION"
    | "SAINT_BARTHELEMY"
    | "SAINT_HELENA"
    | "SAINT_KITTS_AND_NEVIS"
    | "SAINT_LUCIA"
    | "SAINT_MARTIN"
    | "SAINT_PIERRE_AND_MIQUELON"
    | "SAINT_VINCENT_AND_THE_GRENADINES"
    | "SAMOA"
    | "SAN_MARINO"
    | "SAO_TOME_AND_PRINCIPE"
    | "SAUDI_ARABIA"
    | "SENEGAL"
    | "SERBIA"
    | "SEYCHELLES"
    | "SIERRA_LEONE"
    | "SINGAPORE"
    | "SINT_MAARTEN"
    | "SLOVAKIA"
    | "SLOVENIA"
    | "SOLOMON_ISLANDS"
    | "SOMALIA"
    | "SOUTH_AFRICA"
    | "SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS"
    | "SOUTH_KOREA"
    | "SOUTH_SUDAN"
    | "SPAIN"
    | "SRI_LANKA"
    | "SUDAN"
    | "SURINAME"
    | "SVALBARD_AND_JAN_MAYEN"
    | "SWAZILAND"
    | "SWEDEN"
    | "SWITZERLAND"
    | "SYRIAN_ARAB_REPUBLIC"
    | "TAIWAN"
    | "TAJIKISTAN"
    | "UNITED_REPUBLIC_OF_TANZANIA"
    | "THAILAND"
    | "TIMOR_LESTE"
    | "TOGO"
    | "TOKELAU"
    | "TONGA"
    | "TRINIDAD_AND_TOBAGO"
    | "TUNISIA"
    | "TURKEY"
    | "TURKMENISTAN"
    | "TURKS_AND_CAICOS_ISLANDS"
    | "TUVALU"
    | "UGANDA"
    | "UKRAINE"
    | "UNITED_ARAB_EMIRATES"
    | "UNITED_KINGDOM"
    | "UNITED_STATES"
    | "UNITED_STATES_MINOR_OUTLYING_ISLANDS"
    | "URUGUAY"
    | "UZBEKISTAN"
    | "VANUATU"
    | "VENEZUELA"
    | "VIET_NAM"
    | "BRITISH_VIRGIN_ISLANDS"
    | "US_VIRGIN_ISLANDS"
    | "WALLIS_AND_FUTUNA"
    | "WESTERN_SAHARA"
    | "YEMEN"
    | "ZAMBIA"
    | "ZIMBABWE"
  > = [];

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("seriesId", String, true)
  seriesId?: string = "";

  @JsonProperty("source", Any, true)
  source?: "BLS" | "ONS" = undefined;

  @JsonProperty("type", Any, true)
  type?: "CPI" | "ECI" | "EPI" | "IPD" | "IPI" | "PPI" | "RPI" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("version", String, true)
  version?: string = "";
}
