import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromCommon from "./common.reducer";
import { CommonEffects } from "./common.effects";
import { CommonFacade } from "./common.facade";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCommon.COMMON_FEATURE_KEY, fromCommon.reducer),
    EffectsModule.forFeature([CommonEffects]),
  ],
  providers: [CommonFacade],
})
export class AppStateCommonModule {}
