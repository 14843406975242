<div [fxLayout]="verticalView ? 'column' : 'row'" style="height: 100%">
  <div
    ngfDrop
    selectable="1"
    (dragleave)="onDragLeave($event)"
    class="upload-area"
    [style.backgroundColor]="isDragOver ? '#f0f0f0' : '#f5f6f7'"
    fxLayout="column"
    fxLayoutAlign="center center"
    [fxFlex]="
      verticalView || !uploadedDocSets?.length ? '100 1 auto' : '1 1 auto'
    "
    [ngStyle]="{
      'margin-bottom': verticalView ? '10px' : null,
      'margin-right': verticalView || !uploadedDocSets?.length ? null : '16px'
    }"
    (fileOver)="onDragOver($event)"
    (filesChange)="onFilesChanged()"
    (drop)="onDrop($event)"
    multiple="1"
    [files]="files"
    [(validDrag)]="validComboDrag"
    [(invalidDrag)]="invalidComboDrag"
    [ngClass]="{
      goodDragClass: validComboDrag,
      badDragClass: invalidComboDrag
    }"
    [accept]="accept"
  >
    <div
      class="upload-icon-container"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <itls-icon svgIcon="icon-upload" class="sidenav-upload-icon"> </itls-icon>
    </div>
    <div style="margin-bottom: 10px; text-align: center">
      Drag or <a style="color: #2979ff">Browse Files</a>
    </div>
    <ng-container>
      <div style="line-height: 1">OR</div>
      <div
        style="padding: 10px"
        (click)="onSelectFolderClicked($event, selectFolderInput)"
      >
        <a style="color: #2979ff">Select Folder</a>
      </div>
    </ng-container>
    <input
      #selectFolderInput
      hidden
      click-stop-propagation
      ngf
      type="file"
      [files]="files"
      (filesChange)="onFilesChanged()"
      multiple="1"
      webkitdirectory
      [accept]="acceptDirectory"
    />
  </div>
  <div fxLayout="column">
    <div
      *ngIf="isUploadingFolder && uploadedDocSets?.length"
      style="font-size: 12px; margin-bottom: 10px"
    >
      Uploading <strong>{{ uploadedDocSets?.length }}</strong>
      {{ uploadedDocSets?.length > 1 ? "document sets" : "document set" }}
      (
      <strong>{{ getDocumentCounts(uploadedDocSets) }}</strong>
      {{ getDocumentCounts(uploadedDocSets) > 1 ? "documents" : "document" }})
    </div>
    <div
      fxLayout="column"
      [style.max-height]="
        isUploadingFolder ? (verticalView ? '159px' : '181px') : null
      "
      [style.overflow-y]="isUploadingFolder ? 'auto' : null"
    >
      <il-uploaded-doc-set
        *ngFor="let uploadedDocSet of uploadedDocSets; let i = index"
        [recordIndex]="i"
        [isUploadingFolder]="isUploadingFolder"
        [verticalView]="verticalView"
        [uploadedDocSet]="uploadedDocSet"
        (allFilesRemoved)="onAllFilesRemoved(uploadedDocSet)"
      ></il-uploaded-doc-set>
    </div>
  </div>
</div>
