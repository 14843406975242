/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiRespCountUnseenModel } from "../models/o-api-resp-count-unseen-model";
import { OApiRespMultiNotificationCategoryDtoModel } from "../models/o-api-resp-multi-notification-category-dto-model";
import { OApiRespMultiNotificationDtoModel } from "../models/o-api-resp-multi-notification-dto-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class NotificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation archive
   */
  static readonly ArchivePath =
    "/api/v1/notification/{notificationUid}/archive";

  /**
   * Archive a notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `archive()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  archive$Response(params: {
    /**
     * The unique id of the notification
     */
    notificationUid: string;
    body?: RequestModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationService.ArchivePath,
      "put"
    );
    if (params) {
      rb.path("notificationUid", params.notificationUid, {});
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Archive a notification.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `archive$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  archive(params: {
    /**
     * The unique id of the notification
     */
    notificationUid: string;
    body?: RequestModel;
  }): Observable<void> {
    return this.archive$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation countUnseen
   */
  static readonly CountUnseenPath = "/api/v1/notification/unseen/count";

  /**
   * Count unseen notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countUnseen()` instead.
   *
   * This method doesn't expect any request body.
   */
  countUnseen$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespCountUnseenModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationService.CountUnseenPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespCountUnseenModel>;
        })
      );
  }

  /**
   * Count unseen notifications.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countUnseen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countUnseen(params?: {}): Observable<OApiRespCountUnseenModel> {
    return this.countUnseen$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespCountUnseenModel>) =>
          r.body as OApiRespCountUnseenModel
      )
    );
  }

  /**
   * Path part for operation getAll3
   */
  static readonly GetAll3Path = "/api/v1/notification";

  /**
   * List of notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll3()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll3$Response(params: {
    /**
     * List of notification categories for which we want to fetch notifications.
     */
    category: Array<"ALL" | "ARCHIVED" | "INBOX" | "FOLLOW" | "TASK" | "SHARE">;
  }): Observable<StrictHttpResponse<OApiRespMultiNotificationDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationService.GetAll3Path,
      "get"
    );
    if (params) {
      rb.query("category", params.category, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiNotificationDtoModel>;
        })
      );
  }

  /**
   * List of notifications.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll3(params: {
    /**
     * List of notification categories for which we want to fetch notifications.
     */
    category: Array<"ALL" | "ARCHIVED" | "INBOX" | "FOLLOW" | "TASK" | "SHARE">;
  }): Observable<OApiRespMultiNotificationDtoModel> {
    return this.getAll3$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiNotificationDtoModel>) =>
          r.body as OApiRespMultiNotificationDtoModel
      )
    );
  }

  /**
   * Path part for operation getAllCategories
   */
  static readonly GetAllCategoriesPath = "/api/v1/notification/category";

  /**
   * List of notification categories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCategories$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMultiNotificationCategoryDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationService.GetAllCategoriesPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiNotificationCategoryDtoModel>;
        })
      );
  }

  /**
   * List of notification categories.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCategories(params?: {}): Observable<OApiRespMultiNotificationCategoryDtoModel> {
    return this.getAllCategories$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiNotificationCategoryDtoModel>) =>
          r.body as OApiRespMultiNotificationCategoryDtoModel
      )
    );
  }

  /**
   * Path part for operation getAllV2
   */
  static readonly GetAllV2Path = "/api/v2/notification";

  /**
   * List of notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllV2$Response(params: {
    /**
     * List of notification categories for which we want to fetch notifications.
     */
    category: Array<"ALL" | "ARCHIVED" | "INBOX" | "FOLLOW" | "TASK" | "SHARE">;
  }): Observable<StrictHttpResponse<OApiRespMultiNotificationDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationService.GetAllV2Path,
      "get"
    );
    if (params) {
      rb.query("category", params.category, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiNotificationDtoModel>;
        })
      );
  }

  /**
   * List of notifications.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllV2(params: {
    /**
     * List of notification categories for which we want to fetch notifications.
     */
    category: Array<"ALL" | "ARCHIVED" | "INBOX" | "FOLLOW" | "TASK" | "SHARE">;
  }): Observable<OApiRespMultiNotificationDtoModel> {
    return this.getAllV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiNotificationDtoModel>) =>
          r.body as OApiRespMultiNotificationDtoModel
      )
    );
  }

  /**
   * Path part for operation markAsRead
   */
  static readonly MarkAsReadPath =
    "/api/v1/notification/{notificationUid}/read";

  /**
   * Mark a notification that has been read.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAsRead()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  markAsRead$Response(params: {
    /**
     * The unique id of the notification
     */
    notificationUid: string;
    body?: RequestModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationService.MarkAsReadPath,
      "put"
    );
    if (params) {
      rb.path("notificationUid", params.notificationUid, {});
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Mark a notification that has been read.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markAsRead$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  markAsRead(params: {
    /**
     * The unique id of the notification
     */
    notificationUid: string;
    body?: RequestModel;
  }): Observable<void> {
    return this.markAsRead$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
