import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { AppAuthorities } from "@@intelease/web/intelease/constants";

@Component({
  selector: "il-provision-notes",
  templateUrl: "./provision-notes.component.html",
  styleUrls: ["./provision-notes.component.scss"],
})
export class ProvisionNotesComponent {
  @Input() isLoading: boolean;
  @Input() note: string;
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Input() editable: boolean;
  @Input() recordReviewMode: string;
  @Input() limitCharacters = 500;
  @ViewChild("fieldV2", { read: ElementRef }) field: ElementRef;

  AUTH = AppAuthorities;
}
