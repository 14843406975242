/* tslint:disable */
/* eslint-disable */
import { PdfMentionAreaHighlightDtoModel } from "./pdf-mention-area-highlight-dto-model";
import { PdfMentionTextHighlightDtoModel } from "./pdf-mention-text-highlight-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UpdatedHighlightInfoApiDtoModel")
export class UpdatedHighlightInfoApiDtoModel {
  @JsonProperty(
    "modifiedAreaHighlights",
    [PdfMentionAreaHighlightDtoModel],
    true
  )
  modifiedAreaHighlights?: Array<PdfMentionAreaHighlightDtoModel> = [];

  @JsonProperty(
    "modifiedTextHighlights",
    [PdfMentionTextHighlightDtoModel],
    true
  )
  modifiedTextHighlights?: Array<PdfMentionTextHighlightDtoModel> = [];

  @JsonProperty("newAreaHighlights", [PdfMentionAreaHighlightDtoModel], true)
  newAreaHighlights?: Array<PdfMentionAreaHighlightDtoModel> = [];

  @JsonProperty("newTextHighlights", [PdfMentionTextHighlightDtoModel], true)
  newTextHighlights?: Array<PdfMentionTextHighlightDtoModel> = [];

  @JsonProperty("removedHighlightIds", [String], true)
  removedHighlightIds?: Array<string> = [];
}
