/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AutoSelectMentionsConfigModel")
export class AutoSelectMentionsConfigModel {
  @JsonProperty("canAutoSelectNonPrimaryTextFormat", Boolean, true)
  canAutoSelectNonPrimaryTextFormat?: boolean = undefined;

  @JsonProperty("maxToAutoSelect", Number, true)
  maxToAutoSelect?: number = undefined;

  @JsonProperty("minToAutoSelect", Number, true)
  minToAutoSelect?: number = undefined;

  @JsonProperty("thresholdScore", Number, true)
  thresholdScore?: number = undefined;
}
