import { JsonObject, JsonProperty } from "json2typescript";
import { MinimalProvisionGroupModel } from "@@intelease/web/common/models/provision-group";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";

@JsonObject("GroupsProvisionFormModel")
export class GroupsProvisionFormModel {
  static view = "groupsForm";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("version", Number)
  version = 0;

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("creationDate", DateTimeConvertService)
  creationDate: Date = undefined;

  @JsonProperty("createdByEmail", String)
  createdByEmail = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("lastModifiedByEmail", String)
  lastModifiedByEmail = "";

  @JsonProperty("provisionGroups", [MinimalProvisionGroupModel])
  provisionGroups: MinimalProvisionGroupModel[] = [];

  @JsonProperty("numProvisions", Number)
  numProvisions = 0;

  @JsonProperty("docSetCategory", String)
  docSetCategory = "";

  @JsonProperty("permissions", [String])
  permissions?: Array<
    "READ" | "EDIT" | "DELETE" | "EXPORT" | "MOVE" | "COMMENT" | "SHARE"
  > = [];
}
