/* tslint:disable */
/* eslint-disable */
import { CalculateProvisionReqDtoModel } from "./calculate-provision-req-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCalculateProvisionDtoModel")
export class OApiReqCalculateProvisionDtoModel {
  @JsonProperty("data", CalculateProvisionReqDtoModel, true)
  data?: CalculateProvisionReqDtoModel = undefined;
}
