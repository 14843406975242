import { Injectable } from "@angular/core";

import { CanHavePendingChangesComponent } from "@intelease/components";

@Injectable({
  providedIn: "root",
})
export class PendingChangesGuard {
  canDeactivate(component: CanHavePendingChangesComponent) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
