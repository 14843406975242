/* tslint:disable */
/* eslint-disable */
import { AddNewProvisionApiDtoModel } from "./add-new-provision-api-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqAddNewProvisionModel")
export class OApiReqAddNewProvisionModel {
  @JsonProperty("data", AddNewProvisionApiDtoModel, true)
  data?: AddNewProvisionApiDtoModel = undefined;
}
