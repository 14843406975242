import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("AbstractReviewOperationModeModel")
export class AbstractReviewOperationModeModel {
  @JsonProperty("uiName", String)
  uiName = "";

  @JsonProperty("value", String)
  value = "";
}
