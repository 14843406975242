/* tslint:disable */
/* eslint-disable */
import { GoogleDriveInfoModel } from "./google-drive-info-model";
import { LinkedAccountModel } from "./linked-account-model";
import { NamedDocQueryModel } from "./named-doc-query-model";
import { ObjectIdModel } from "./object-id-model";
import { SearchItemModel } from "./search-item-model";
import { SignupVerificationCodeModel } from "./signup-verification-code-model";
import { UserActivityModel } from "./user-activity-model";
import { UserImageFileIdModel } from "./user-image-file-id-model";
import { UserRecordActivityModel } from "./user-record-activity-model";
import { UserSettingsModel } from "./user-settings-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UserModel")
export class UserModel {
  @JsonProperty("&#x27;_id&#x27;", ObjectIdModel, true)
  "_id"?: ObjectIdModel = undefined;

  @JsonProperty("accountId", ObjectIdModel, true)
  accountId?: ObjectIdModel = undefined;

  @JsonProperty("accountRole", Any, true)
  accountRole?: "ITLS_ADMIN" | "OWNER" | "ADMIN" | "USER" = undefined;

  @JsonProperty("activity", UserActivityModel, true)
  activity?: UserActivityModel = undefined;

  @JsonProperty("adminPassword", String, true)
  adminPassword?: string = "";

  @JsonProperty("dashboardId", ObjectIdModel, true)
  dashboardId?: ObjectIdModel = undefined;

  @JsonProperty("email", String, true)
  email?: string = "";

  @JsonProperty("firebaseTokens", [String], true)
  firebaseTokens?: Array<string> = [];

  @JsonProperty("firstName", String, true)
  firstName?: string = "";

  @JsonProperty("flags", Any, true)
  flags?: Array<
    | "ADMIN"
    | "ANNOTATOR"
    | "RE_ABSTRACTOR"
    | "SALESFORCE_PUSHER"
    | "PSA_PROVISIONS"
  > = [];

  @JsonProperty("googleDriveInfo", GoogleDriveInfoModel, true)
  googleDriveInfo?: GoogleDriveInfoModel = undefined;

  @JsonProperty("lastName", String, true)
  lastName?: string = "";

  @JsonProperty("lbxRoles", Any, true)
  lbxRoles?: Array<
    "ADMIN" | "FORMS_ADMIN" | "ANALYST" | "REVIEWER" | "DATA_SCIENTIST"
  > = [];

  @JsonProperty("linkedAccounts", [LinkedAccountModel], true)
  linkedAccounts?: Array<LinkedAccountModel> = [];

  @JsonProperty("loginTokens", [String], true)
  loginTokens?: Array<string> = [];

  @JsonProperty("migrated", Boolean, true)
  migrated?: boolean = undefined;

  @JsonProperty("password", String, true)
  password?: string = "";

  @JsonProperty("passwordResetId", String, true)
  passwordResetId?: string = "";

  @JsonProperty("passwordResetRequestTime", DateTimeConvertService, true)
  passwordResetRequestTime?: Date | undefined = undefined;

  @JsonProperty("phoneNumber", String, true)
  phoneNumber?: string = "";

  @JsonProperty("pipelineConfigId", ObjectIdModel, true)
  pipelineConfigId?: ObjectIdModel = undefined;

  @JsonProperty("profileImageFileId", UserImageFileIdModel, true)
  profileImageFileId?: UserImageFileIdModel = undefined;

  @JsonProperty("recentAbstracts", Any, true)
  recentAbstracts?: {
    [key: string]: any;
  } = {};

  @JsonProperty("recentRecordActivities", [UserRecordActivityModel], true)
  recentRecordActivities?: Array<UserRecordActivityModel> = [];

  @JsonProperty("recentSearchResults", [SearchItemModel], true)
  recentSearchResults?: Array<SearchItemModel> = [];

  @JsonProperty("recentUploadedProvisionFormIds", [ObjectIdModel], true)
  recentUploadedProvisionFormIds?: Array<ObjectIdModel> = [];

  @JsonProperty("roleIds", [ObjectIdModel], true)
  roleIds?: Array<ObjectIdModel> = [];

  @JsonProperty("salesforceAccessToken", String, true)
  salesforceAccessToken?: string = "";

  @JsonProperty("salesforceInstanceUrl", String, true)
  salesforceInstanceUrl?: string = "";

  @JsonProperty("salesforceNamespace", String, true)
  salesforceNamespace?: string = "";

  @JsonProperty("salesforceRefreshToken", String, true)
  salesforceRefreshToken?: string = "";

  @JsonProperty("savedDocQueries", [NamedDocQueryModel], true)
  savedDocQueries?: Array<NamedDocQueryModel> = [];

  @JsonProperty("settings", UserSettingsModel, true)
  settings?: UserSettingsModel = undefined;

  @JsonProperty("signupVerificationCode", SignupVerificationCodeModel, true)
  signupVerificationCode?: SignupVerificationCodeModel = undefined;

  @JsonProperty("status", Any, true)
  status?: "ACTIVE" | "PRE_TOS" | "PRE_REGISTER" | "PRE_ACTIVE" | "DEACTIVE" =
    undefined;

  @JsonProperty("tier", Any, true)
  tier?: "FREE" | "PRO" = undefined;
}
