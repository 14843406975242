import { JsonObject, JsonProperty } from "json2typescript";
import { CommentModel } from "@@intelease/web/abstraction-page/src/lib/models/comment.model";

@JsonObject("DocumentCommentModel")
export class DocumentCommentModel extends CommentModel {
  public static readonly VIEW = "document";

  @JsonProperty("replies", [CommentModel])
  replies: CommentModel[] = [];

  @JsonProperty("pdfHighlightIds", [String])
  pdfHighlightIds: string[] = [];
}
