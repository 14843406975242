<div>
  <div mat-dialog-title class="clearfix text-left">
    <button (click)="cancel()" type="button" class="close" aria-hidden="true">
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5 style="margin-top: -10px">Alert</h5>
  </div>
  <div mat-dialog-content>
    <p>
      The selected mention currently has a value. Choose if you want to override
      it or add new value to the current one.
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button color="warn" (click)="override()">Override</button>
    <button mat-flat-button color="primary" (click)="append()">Append</button>
    <button mat-flat-button (click)="cancel()">Cancel</button>
  </div>
</div>
