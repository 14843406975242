import { SimpleQueueDtoModel } from "@@intelease/api-models/adex-api-model-src";
import { QueueFacade } from "@@intelease/app-state/queue/src";
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-choose-queue-dropdown",
  templateUrl: "./choose-queue.component.html",
  styleUrls: ["./choose-queue.component.scss"],
})
export class ChooseQueueDropdownComponent implements OnInit {
  availableQueues: SimpleQueueDtoModel[] = [];
  selectedQueue: SimpleQueueDtoModel;

  destroyRef = inject(DestroyRef);

  @Output()
  select: EventEmitter<SimpleQueueDtoModel> = new EventEmitter<SimpleQueueDtoModel>();
  @Input() height: "md" | "sm" = "md";

  @Input() clearable = false;

  constructor(private queueFacade: QueueFacade) {}

  ngOnInit(): void {
    this.queueFacade.loadUsableQueueList();

    this.queueFacade.usableQueueListLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((queues) => {
        this.availableQueues = queues
          .filter((queue) => queue.status === "ACTIVE")
          .map((queue) => {
            return {
              uid: queue.uid,
              name: queue.name,
              createdBy: queue.createdBy,
            };
          });
      });
  }

  onSelectQueue(queue: SimpleQueueDtoModel) {
    this.select.emit({ ...queue });
  }
}
