import { FormFieldModel } from "@@intelease/api-models/adex-api-model-src";
import { inteleaseAnimations } from "@@intelease/web/intelease/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

export enum FormFieldFormat {
  TEXT = "TEXT",
  TEXT_AREA = "TEXT_AREA",
  NUMBER = "NUMBER",
  DATE = "DATE",
  SELECT = "SELECT",
  RELATIVE_TIME_TO_EVENT = "RELATIVE_TIME_TO_EVENT",
  TIME_PERIOD = "TIME_PERIOD",
}

export type OptionCalculatorInputField = FormFieldModel & {
  fieldValue: any; // String representation
  rawValue: any; //Actual Data such as Date
};

@Component({
  selector: "il-options-calculator-input-field",
  templateUrl: "./input-field.component.html",
  styleUrls: ["./input-field.component.scss"],
  animations: inteleaseAnimations,
})
export class CustomInputFieldComponent implements OnInit, OnChanges {
  @Input()
  inputField: OptionCalculatorInputField;

  @Input()
  openDatePickerFieldKey: string | undefined;

  @Input()
  toolTip: string;

  @Output()
  // eslint-disable-next-line
  change: EventEmitter<{ key: string; value: any; rawValue?: any }> =
    new EventEmitter<{
      key: string;
      value: any;
      rawValue?: any;
    }>();

  @Output()
  // eslint-disable-next-line
  click: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  // eslint-disable-next-line
  reset: EventEmitter<string> = new EventEmitter<string>();

  FieldFormat = FormFieldFormat;

  ngOnInit(): void {
    this.initCustomField();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputField) {
      this.initCustomField();
    }
  }

  initCustomField() {
    if (this.inputField?.fieldFormat === this.FieldFormat.TIME_PERIOD) {
      if (!this.inputField.rawValue) {
        this.inputField.rawValue = {
          input: undefined,
          dropdown: "",
        };
      }
    } else if (
      this.inputField?.fieldFormat === this.FieldFormat.RELATIVE_TIME_TO_EVENT
    ) {
      if (!this.inputField.rawValue) {
        this.inputField.rawValue = {
          input: undefined,
          dropdown1: "",
          dropdown2: "",
        };
      }
    }
  }

  handleClick() {
    this.click.emit(this.inputField.fieldKey);
  }

  handleChange() {
    this.change.emit({
      key: this.inputField.fieldKey,
      value: this.inputField.fieldValue,
      rawValue: this.inputField.rawValue,
    });
  }

  private isClearableField() {
    return (
      this.inputField.fieldFormat === this.FieldFormat.NUMBER ||
      this.inputField.fieldFormat === this.FieldFormat.TEXT ||
      this.inputField.fieldFormat === this.FieldFormat.TEXT_AREA ||
      this.inputField.fieldFormat === this.FieldFormat.DATE
    );
  }

  canClear() {
    return this.isClearableField() && this.inputField.fieldValue;
  }

  onReset(event: any) {
    if (event && event.stopPropagation) event.stopPropagation();
    if (
      this.inputField.fieldFormat === this.FieldFormat.TEXT ||
      this.inputField.fieldFormat === this.FieldFormat.TEXT_AREA
    ) {
      this.inputField.fieldValue = "";
    } else if (this.inputField.fieldFormat === this.FieldFormat.DATE) {
      this.inputField.rawValue = undefined;
      this.inputField.fieldValue = undefined;
    } else if (this.inputField.fieldFormat === this.FieldFormat.NUMBER) {
      this.inputField.fieldValue = undefined;
    }

    this.reset.emit(this.inputField.fieldKey);
  }
}
