import {
  Component,
  ComponentRef,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewContainerRef,
  OnInit,
} from "@angular/core";
import { TerminatorService } from "./terminator.service";
import { WidgetFactory } from "./widgetfactory";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "sf-widget-chooser",
  template: ` <div #target></div> `,
})
export class WidgetChooserComponent implements OnChanges {
  @Input() widgetInfo: any;

  @Output() widgetInstantiated = new EventEmitter<any>();

  @ViewChild("target", { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  private ref: ComponentRef<any>;

  constructor(
    private widgetFactory: WidgetFactory = null,
    private cdr: ChangeDetectorRef,
    private terminator: TerminatorService
  ) {
    this.terminator.onDestroy
      .pipe(takeUntilDestroyed())
      .subscribe((destroy) => {
        if (destroy) {
          this.ref.destroy();
        }
      });
  }

  ngOnChanges() {
    this.ref = this.widgetFactory.createWidget(
      this.container,
      this.widgetInfo.id
    );
    this.widgetInstantiated.emit(this.ref.instance);
    // this.cdr.detectChanges() // disabling this makes it faster
  }
}
