import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { ProgressModule } from "@@intelease/web/intelease/components";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ChooseProvisionFormComponent } from "./choose-provision-form.component";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";

@NgModule({
  imports: [
    InteleaseCommonModule,
    ItlsCustomIconModule,
    MatIconModule,
    ProgressModule,
    TooltipModule,
  ],
  declarations: [ChooseProvisionFormComponent],
  exports: [ChooseProvisionFormComponent],
})
export class ItlsChooseProvisionFormModule {}
