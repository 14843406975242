export enum ProvisionBoxActionTypeEnum {
  SAVE = "SAVE",
  DELETE = "DELETE",
  DELETE_HIGHLIGHTS = "DELETE_HIGHLIGHTS",
  INITIATE_DELETE_MENTION_FROM_VIEWER = "INITIATE_DELETE_MENTION_FROM_VIEWER",
  DELETE_TEMP_HIGHLIGHT = "DELETE_TEMP_HIGHLIGHT",
  TEMP_HIGHLIGHT_SAVED = "TEMP_HIGHLIGHT_SAVED",
  UPDATE = "UPDATE",
  SELECT = "SELECT",
  DESELECT = "DESELECT",
  PICK_SNIPPET = "PICK_SNIPPET",
  SELECT_DOCUMENT_MENTION = "SELECT_DOCUMENT_MENTION",
  DELETE_SNIPPET_HIGHLIGHT = "DELETE_SNIPPET_HIGHLIGHT",
  UPDATE_TEXT_HIGHLIGHT_TYPE = "UPDATE_TEXT_HIGHLIGHT_TYPE",
  ADD_DOCUMENT_MENTION_TEXT_HIGHLIGHT_TO_VIEWER = "ADD_DOCUMENT_MENTION_TEXT_HIGHLIGHT_TO_VIEWER",
  ADD_SELECTED_MENTION_TEXT_HIGHLIGHT_TO_VIEWER = "ADD_SELECTED_MENTION_TEXT_HIGHLIGHT_TO_VIEWER",
  UPDATE_EXISTED_HIGHLIGHT_DATA = "UPDATE_EXISTED_HIGHLIGHT_DATA",
  REMOVE_DOCUMENT_MENTION_TEXT_HIGHLIGHT_FROM_VIEWER = "REMOVE_DOCUMENT_MENTION_TEXT_HIGHLIGHT_FROM_VIEWER",
  ADD_NESTED_SUBFIELD_HIGHLIGHT_TO_VIEWER = "ADD_NESTED_SUBFIELD_HIGHLIGHT_TO_VIEWER",
  ADD_NESTED_ATTRIBUTIONS_HIGHLIGHT_TO_VIEWER = "ADD_NESTED_ATTRIBUTIONS_HIGHLIGHT_TO_VIEWER",
  REMOVE_NESTED_ATTRIBUTIONS_HIGHLIGHT_FROM_VIEWER = "REMOVE_NESTED_ATTRIBUTIONS_HIGHLIGHT_FROM_VIEWER",
  REMOVE_DELETED_HIGHLIGHTS_FROM_META_DATA = "REMOVE_DELETED_HIGHLIGHTS_FROM_META_DATA",
}
