/* tslint:disable */
/* eslint-disable */
import { UserModel } from "./user-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("LoggedInUserModel")
export class LoggedInUserModel {
  @JsonProperty("loginType", Any, true)
  loginType?: "REGULAR" | "GHOST" | "SYSTEM" = undefined;

  @JsonProperty("user", UserModel, true)
  user?: UserModel = undefined;
}
