import { StringKeyValue } from "@@intelease/web/common/models/string-key-value.model";

/**
 * For Search API for Provision forms
 */
export class AbstractKeyValue implements StringKeyValue {
  [propName: string]: string;

  constructor(item: any, viewColumns: string[]) {
    for (const viewColumn of viewColumns) {
      const fieldName = viewColumn.split("::")[0];
      if (!AbstractKeyValue.requiredFields.includes(fieldName)) {
        this[fieldName] = item[fieldName];
      }
    }

    for (const requiredField of AbstractKeyValue.requiredFields) {
      if (item.hasOwnProperty(requiredField)) {
        const val = item[requiredField];
        if (val) {
          Reflect.set(this, requiredField, val);
        }
      }
    }
  }

  private static readonly requiredFields: string[] = [
    "uid",
    "suiteVersionIdUser",
    "suiteVersionIdPortfolio",
    "suiteVersionIdProperty",
    "suiteVersionIdSuite",
    "suiteVersionIdVersion",
    "abstractName",
    "isColliersOldTouched",
  ];

  uid: string;
  suiteVersionIdUser: string;
  suiteVersionIdPortfolio: string;
  suiteVersionIdProperty: string;
  suiteVersionIdSuite: string;
  suiteVersionIdVersion: string;
  abstractName: string;
  // TODO(apoorv): This should be a number, but not allowed to have non-string fields in this class,
  // ERROR in src/app/common/models/abstract-key-value.model.ts(13,3): error TS2411: Property
  // 'suiteVersionIdVersion' of type 'number' is not assignable to string index type 'string'.
  isColliersOldTouched: string; // TODO(apoorv): This should be a  boolean, but same problem as above
}
