import { Injectable } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import {
  OptionsCalculatorModalComponent,
  OptionsReminderCalculatorModalComponent,
  RentScheduleCalculatorModalComponent,
  TermCalculatorModalComponent,
  WebAbstractionPageSaveAbstractModalComponent,
} from "@@intelease/web/abstraction-page/src/lib/modals";
import { WebAbstractionPageAddProvisionModalComponent } from "../modals/add-provision/web-abstraction-page-add-provision-modal.component";
import { CalculatorNamesEnum } from "@@intelease/web/abstraction-page/src/lib/enums";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";

@Injectable({
  providedIn: "root",
})
export class AbstractionPageModalService {
  constructor(private modalService: BsModalService) {}

  openSaveAbstractModal(modalData?: ModalInputModel): BsModalRef {
    const initialState = { ...modalData };
    const modalRef = this.modalService.show(
      WebAbstractionPageSaveAbstractModalComponent,
      {
        initialState,
        animated: false,
      }
    );
    //modalRef.content.modalData = modalData
    return modalRef;
  }

  openAddProvisionModal(modalData: ModalInputModel): BsModalRef {
    const initialState = { ...modalData };
    const modalRef = this.modalService.show(
      WebAbstractionPageAddProvisionModalComponent,
      {
        initialState,
        animated: false,
      }
    );
    //modalRef.content.modalData = modalData
    return modalRef;
  }

  openCalculatorModal(modalData: ModalInputModel): BsModalRef {
    const initialState = { ...modalData };
    const modalRef = this.getSelectedCalculatorModalRef(initialState);
    //modalRef.content.modalData = modalData
    return modalRef;
  }

  private getSelectedCalculatorModalRef(initialState) {
    const modalConfig: ModalOptions = {
      initialState,
      animated: false,
      class: "calculator-modal",
      ignoreBackdropClick: true,
    };
    switch (initialState.payload.calculator.calculatorName) {
      case CalculatorNamesEnum.RentSchedule:
        return this.modalService.show(
          RentScheduleCalculatorModalComponent,
          modalConfig
        );
      case CalculatorNamesEnum.TermCalculator:
        return this.modalService.show(
          TermCalculatorModalComponent,
          modalConfig
        );
      case CalculatorNamesEnum.OptionsCalculator:
        return this.modalService.show(
          OptionsCalculatorModalComponent,
          modalConfig
        );
      case CalculatorNamesEnum.OptionsReminderCalculator:
        return this.modalService.show(
          OptionsReminderCalculatorModalComponent,
          modalConfig
        );
    }
  }
}
