/* tslint:disable */
/* eslint-disable */
import { UserDtoModel } from "./user-dto-model";

/**
 * the path to this file
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FolderStructureNodeDtoModel")
export class FolderStructureNodeDtoModel {
  /**
   * when this node was created
   */

  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  /**
   * the unique id of the Google Drive file this node may correspond to
   */

  @JsonProperty("googleDriveFileId", String, true)
  googleDriveFileId?: string = "";

  /**
   * wen this node was last modified
   */

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  /**
   * the name of this node
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("owner", UserDtoModel, true)
  owner?: UserDtoModel = undefined;

  /**
   * the permissions for the user for this node
   */

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];

  /**
   * whether this node is shared with this user
   */

  @JsonProperty("shared", Boolean, true)
  shared?: boolean = undefined;

  /**
   * the size of this node (in bytes)
   */

  @JsonProperty("size", Number, true)
  size?: number = undefined;

  /**
   * the type of node this is
   */

  @JsonProperty("type", Any, true)
  type?: "ACCOUNT" | "DIRECTORY" | "DOC_SET" | "ROLE" | "USER" = undefined;

  /**
   * the unique id of this node
   */

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
