/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("TimeSeriesPointModel")
export class TimeSeriesPointModel {
  @JsonProperty("estimated", Boolean, true)
  estimated?: boolean = undefined;

  @JsonProperty("month", Any, true)
  month?:
    | "JANUARY"
    | "FEBRUARY"
    | "MARCH"
    | "APRIL"
    | "MAY"
    | "JUNE"
    | "JULY"
    | "AUGUST"
    | "SEPTEMBER"
    | "OCTOBER"
    | "NOVEMBER"
    | "DECEMBER" = undefined;

  @JsonProperty("title", String, true)
  title?: string = "";

  @JsonProperty("value", Number, true)
  value?: number = undefined;

  @JsonProperty("year", Number, true)
  year?: number = undefined;

  @JsonProperty("yearMonth", Any, true)
  yearMonth?: {
    year?: number;
    month?:
      | "JANUARY"
      | "FEBRUARY"
      | "MARCH"
      | "APRIL"
      | "MAY"
      | "JUNE"
      | "JULY"
      | "AUGUST"
      | "SEPTEMBER"
      | "OCTOBER"
      | "NOVEMBER"
      | "DECEMBER";
    monthValue?: number;
    leapYear?: boolean;
  } = {};
}
