import { NgModule } from "@angular/core";
import { SidebarComponent } from "./sidebar.component";
import { ItlsMatSidenavDirective } from "./itls-mat-sidenav.directive";
import { ItlsMatSidenavTogglerDirective } from "./itls-mat-sidenav-toggler.directive";
import { MatSidenav } from "@angular/material/sidenav";

@NgModule({
  declarations: [
    SidebarComponent,
    ItlsMatSidenavDirective,
    ItlsMatSidenavTogglerDirective,
  ],
  exports: [
    SidebarComponent,
    ItlsMatSidenavDirective,
    ItlsMatSidenavTogglerDirective,
  ],
  providers: [MatSidenav],
})
export class SidebarModule {}
