<div [class]="'input-dropdown-container '">
  <input
    [disabled]="inputDisabled"
    (change)="onChange($event, eventSource.INPUT)"
    [(ngModel)]="model.input"
    type="number"
    [min]="min"
    [onlyNumber]="min >= 0"
    class="form-control"
    [style.width]="inputWidth"
    [digitsOnly]="digitsOnly"
    (click)="onClick(eventSource.INPUT)"
  />
  <ng-select
    (change)="onChange($event, eventSource.SELECT)"
    (clear)="onClear($event)"
    [(ngModel)]="model.dropdown"
    [items]="items"
    class="dropdown"
    [appendTo]="'body'"
    [style.width]="selectWidth"
    (click)="onClick(eventSource.SELECT)"
  ></ng-select>
</div>
