import {
  FetchApiService,
  FileApiService,
} from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ChargebeePortalModel,
  SettingsAccountModel,
} from "@@intelease/web/common/models";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "@@intelease/app-services/auth";

@Injectable({
  providedIn: "root",
})
export class FetchAccountService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly USERS_URL = "/users";
  private static readonly ACCOUNT = "account";
  private static readonly ACCOUNTS_URL = "/accounts";
  private static readonly CHARGEBEE_PORTAL_URL = "chargebeePortalUrl";

  constructor(
    private fetchApiService: FetchApiService,
    private httpClient: HttpClient,
    private fileApiService: FileApiService
  ) {}

  getAccountForCurrentUser(): Observable<SettingsAccountModel> {
    return this.fetchApiService.sendRequest(
      FetchAccountService.API_VERSION_1,
      FetchAccountService.USERS_URL,
      FetchAccountService.ACCOUNT,
      SettingsAccountModel.view,
      SettingsAccountModel
    );
  }

  exportAccountCreditsEventsForCurrentUser() {
    this.httpClient
      .get(
        FetchAccountService.API_VERSION_1 +
          FetchAccountService.USERS_URL +
          "/" +
          FetchAccountService.ACCOUNT +
          "/exportCreditsEvents",
        {
          responseType: "blob",
        }
      )
      .subscribe((resp) => {
        const filename =
          "Account_credit_events__" + new Date().toString() + ".xlsx";
        this.fileApiService.downloadFromBrowser(filename, resp);
      });
  }

  getBillingPortalUrl<T extends object>(classRef: new () => T): Observable<T> {
    return this.fetchApiService.sendRequest(
      FetchAccountService.API_VERSION_1,
      FetchAccountService.ACCOUNTS_URL,
      FetchAccountService.CHARGEBEE_PORTAL_URL,
      ChargebeePortalModel.view,
      classRef
    );
  }
}
