export class EditTeamModel {
  name: string;

  description: string;

  uidsToAdd: string[];

  uidsToRemove: string[];

  constructor(data?: Partial<EditTeamModel>) {
    Object.assign(this, data);
  }
}
