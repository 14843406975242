import { ComponentModeEnum } from "@@intelease/web/intelease/enums/component-mode.enum";
import { Any, JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ModalInputModel")
export class ModalInputModel {
  @JsonProperty("mode", String)
  mode: ComponentModeEnum = ComponentModeEnum.ADD;

  @JsonProperty("payload", Any)
  payload: any;

  constructor(mode?: ComponentModeEnum, payload?: unknown) {
    this.mode = mode;
    this.payload = payload;
  }

  reset() {
    this.mode = ComponentModeEnum.ADD;
    this.payload = {};
    return this;
  }
}
