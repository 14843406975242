/* tslint:disable */
/* eslint-disable */
import { ProvisionInconsistencyApiDtoModel } from "./provision-inconsistency-api-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionInconsistenciesApiDtoModel")
export class ProvisionInconsistenciesApiDtoModel {
  @JsonProperty("anchorProvisionUid", String, true)
  anchorProvisionUid?: string = "";

  @JsonProperty("provisionUidToInconsistency", Any, true)
  provisionUidToInconsistency?: {
    [key: string]: ProvisionInconsistencyApiDtoModel;
  } = {};
}
