import { NgModule } from "@angular/core";
import { FilesUploadComponent } from "@@intelease/web/ui/src/lib/itls-new-upload/files-upload/files-upload.component";
import { ItlsNewUploadModalComponent } from "@@intelease/web/ui/src/lib/itls-new-upload/modal/itls-new-upload-modal.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { ngfModule } from "angular-file";
import { MatIconModule } from "@angular/material/icon";
import { ExtendedModule, FlexModule } from "@angular/flex-layout";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { ChooseFormComponent } from "@@intelease/web/ui/src/lib/itls-new-upload/choose-form/choose-form.component";
import { ProgressModule } from "@@intelease/web/intelease/components";
import { ItlsNewUploadDashboardComponent } from "@@intelease/web/ui/src/lib/itls-new-upload/dashboard/itls-new-upload-dashboard.component";
import { AbstractNameSuggestionComponent } from "@@intelease/web/ui/src/lib/itls-new-upload/abstract-name-suggestion/abstract-name-suggestion.component";
import { NgPipesModule } from "ngx-pipes";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { UploadedDocSetComponent } from "@@intelease/web/ui/src/lib/itls-new-upload/uploaded-doc-set/uploaded-doc-set.component";
import { UploadModalService } from "@@intelease/web/ui/src/lib/itls-new-upload/services/upload-modal.service";
import { MatListModule } from "@angular/material/list";
import { MatRadioModule } from "@angular/material/radio";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { UploadingBoxComponent } from "@@intelease/web/ui/src/lib/itls-new-upload/uploading-box";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";
import { ItlsUploadDocSetWidgetComponent } from "./upload-doc-set-widget/upload-doc-set-widget.component";
import { UploadingDocSetBoxComponent } from "./uploading-doc-set-box";
import { AppStateDriveModule } from "@@intelease/app-state/drive/src";
import { UploadDocSetModalComponent } from "./upload-doc-set-modal/upload-doc-set-modal.component";
import { ItlsChooseQueueModule } from "../itls-choose-queue/choose-queue.module";
import { ItlsDriveTreeViewFolderPickerModule } from "../itls-drive-tree-folder-picker";

const MATERIAL_MODULES = [
  MatExpansionModule,
  MatIconModule,
  MatProgressBarModule,
  MatListModule,
  MatRadioModule,
  MatTooltipModule,
  MatButtonModule,
];

@NgModule({
  declarations: [
    FilesUploadComponent,
    ChooseFormComponent,
    ItlsNewUploadModalComponent,
    UploadDocSetModalComponent,
    ItlsNewUploadDashboardComponent,
    ItlsUploadDocSetWidgetComponent,
    AbstractNameSuggestionComponent,
    UploadedDocSetComponent,
    UploadingDocSetBoxComponent,
    UploadingBoxComponent,
  ],
  exports: [
    ItlsNewUploadModalComponent,
    UploadDocSetModalComponent,
    ItlsNewUploadDashboardComponent,
    ItlsUploadDocSetWidgetComponent,
    ChooseFormComponent,
  ],
  providers: [UploadModalService],
  imports: [
    InteleaseCommonModule,
    ItlsCustomIconModule,
    ...MATERIAL_MODULES,
    ngfModule,
    FlexModule,
    ExtendedModule,
    ProgressModule,
    NgPipesModule,
    TooltipModule,
    DragDropModule,
    ItlsDriveTreeViewFolderPickerModule,
    AppStateDriveModule,
    ItlsChooseQueueModule,
  ],
})
export class ItlsNewUploadModule {}
