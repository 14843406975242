import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("UploadLeaseResponseItemModel")
export class UploadLeaseResponseItemModel {
  @JsonProperty("directoryPath", String, true)
  directoryPath: string = undefined;

  @JsonProperty("recordUid", String)
  recordUid = "";
}
