import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableProvisionModalComponent } from "@@intelease/web/common/modals/table-provision-modal/table-provision-modal.component";
import { DynamicTableModule } from "@@intelease/web/ui";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

const UI_MODULES = [DynamicTableModule];
const MATERIAL_MODULES = [MatDialogModule, MatButtonModule];

@NgModule({
  declarations: [TableProvisionModalComponent],
  imports: [CommonModule, ...UI_MODULES, ...MATERIAL_MODULES],
  providers: [],
  exports: [TableProvisionModalComponent],
})
export class TableProvisionModalModule {}
