import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { ProvisionGroupModel } from "@@intelease/web/common/models/doc-abstract/abstract-part";

// TODO(apoorv): Deprecate this in favor of IndividualAbstractMultiProvisionValuesModel
@JsonObject("ProvisionValuesAbstractModel")
export class ProvisionValuesAbstractModel {
  static view = "provisionValues";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("provisionGroups", [ProvisionGroupModel])
  provisionGroups: ProvisionGroupModel[] = [];

  @JsonProperty("abstractFilename", String)
  name = "";

  @JsonProperty("abstractName", String)
  abstractName = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("status", String)
  status = "";
}
