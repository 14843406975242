import { Component } from "@angular/core";

import { ControlWidget } from "../../widget";

@Component({
  selector: "sf-select-widget",
  template: `
    <div class="widget form-group">
      <label
        *ngIf="schema.title"
        [attr.for]="id"
        class="horizontal control-label"
      >
        {{ schema.title }}
      </label>

      <span *ngIf="schema.description" class="formHelp">
        {{ schema.description }}
        <i
          *ngIf="schema.tooltip"
          class="fa fa-info-circle ml-1"
          [matTooltip]="schema.tooltip"
        ></i>
      </span>

      <select
        *ngIf="schema.type !== 'array'"
        [formControl]="control"
        [attr.name]="name"
        [disabled]="schema.readOnly"
        class="form-control form-widget"
      >
        <option *ngFor="let option of schema.oneOf" [ngValue]="option.enum[0]">
          {{ option.description }}
        </option>
      </select>

      <select
        *ngIf="schema.type === 'array'"
        multiple
        [formControl]="control"
        [attr.name]="name"
        [disabled]="schema.readOnly"
        class="form-control"
      >
        <option
          *ngFor="let option of schema.items.oneOf"
          [ngValue]="option.enum[0]"
        >
          {{ option.description }}
        </option>
      </select>

      <input
        *ngIf="schema.readOnly"
        [attr.name]="name"
        type="hidden"
        [formControl]="control"
      />
    </div>
  `,
})
export class SelectWidgetComponent extends ControlWidget {}
