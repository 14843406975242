/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";

/**
 * a related document for a record
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RelatedDocumentModel")
export class RelatedDocumentModel {
  @JsonProperty("date", SimpleOpenApiDateModel, true)
  date?: SimpleOpenApiDateModel = undefined;

  /**
   * the actual stored filename for the document
   */

  @JsonProperty("filename", String, true)
  filename?: string = "";

  /**
   * the user-facing name for the document
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the notes for this document
   */

  @JsonProperty("notes", String, true)
  notes?: string = "";

  /**
   * the original file size
   */

  @JsonProperty("originalFileSize", Number, true)
  originalFileSize?: number = undefined;

  /**
   * the user-facing {@link DocAbstract#getId()}
   */

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
