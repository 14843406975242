import { Component, Inject, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { isEmpty } from "lodash";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { NgxTranslateService } from "@@intelease/web/common/services/ngx-translate.service";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "itls-rename-modal",
  templateUrl: "./rename-modal.component.html",
  styleUrls: ["./rename-modal.component.scss"],
})
export class RenameModalComponent implements OnInit {
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.EDIT;
  payload: any;
  customMessage = false;
  title = "Rename";
  message = "What would you like to rename as?";
  params: Object = {};
  newName = "";
  originalName: string = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<RenameModalComponent>,
    private ngxTranslateService: NgxTranslateService,
    private inputValidationService: InputValidationService
  ) {}

  ngOnInit() {
    this.payload = this.dialogData.payload;
    this.mode = this.dialogData.mode;
    if (!isEmpty(this.payload) && this.payload.customMessage) {
      this.ngxTranslateService
        .getTranslateByKey(this.payload.message, this.payload.params)
        .subscribe((message: string) => {
          this.message = message;
          this.title = this.payload.title;
        });
    }
    if (this.payload && this.payload.oldName) {
      this.originalName = this.payload.oldName.toLowerCase().endsWith(".pdf")
        ? this.payload.oldName.slice(0, -4)
        : this.payload.oldName;
      this.newName = this.originalName;
    }
  }

  onRename() {
    let newName = this.inputValidationService.sanitizeText(this.newName);
    if (!this.validateOnRenameFields(newName)) {
      return;
    }
    if (!newName.toLowerCase().endsWith(".pdf")) {
      newName += ".pdf";
    }
    this.modalRes = {
      data: {
        newName: newName,
      },
    };
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.CLOSE,
    });
  }

  private validateOnRenameFields(newName: string): boolean {
    if (
      !this.inputValidationService.usefulTextElseAlert(
        newName,
        "You must enter in a new name!"
      )
    ) {
      return false;
    }
    const noPdfName = newName.toLowerCase().endsWith(".pdf")
      ? newName.slice(0, -4)
      : newName;
    if (
      !this.inputValidationService.nonMatchingUsefulTextElseAlert(
        noPdfName,
        this.originalName,
        "The name you provided matches the old name!"
      )
    ) {
      return false;
    }
    return true;
  }

  onDismissModal() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}
