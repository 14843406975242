import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItlsQueueSearchBarComponent } from "./itls-queue-search-bar.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";

const COMPONENTS = [ItlsQueueSearchBarComponent];

const MATERIAL_UI = [
  MatButtonModule,
  MatIconModule,
  MatAutocompleteModule,
  MatFormFieldModule,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    ItlsCustomIconModule,
    CommonDirectivesModule,
    ...MATERIAL_UI,
  ],
  exports: [...COMPONENTS],
})
export class ItlsQueueSearchBarModule {}
