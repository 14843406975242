/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CheckPermissionItemDtoModel")
export class CheckPermissionItemDtoModel {
  @JsonProperty("domainName", String, true)
  domainName?: string = "";

  @JsonProperty("domainType", Any, true)
  domainType?: "PUBLIC" | "ACCOUNT" | "ROLE" | "USER" | "UNREGISTERED_USER" =
    undefined;

  @JsonProperty("domainUid", String, true)
  domainUid?: string = "";

  @JsonProperty("hasAccess", Boolean, true)
  hasAccess?: boolean = undefined;
}
