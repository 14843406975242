/* tslint:disable */
/* eslint-disable */
import { AutoSelectMentionsConfigModel } from "./auto-select-mentions-config-model";
import { KeywordScoreConfigModel } from "./keyword-score-config-model";
import { NumMentionsConfigModel } from "./num-mentions-config-model";
import { ObjectIdModel } from "./object-id-model";
import { ScoreThresholdConfigModel } from "./score-threshold-config-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionProcessingConfigModel")
export class ProvisionProcessingConfigModel {
  @JsonProperty("&#x27;_id&#x27;", ObjectIdModel, true)
  "_id"?: ObjectIdModel = undefined;

  @JsonProperty("addFormattedToValues", Boolean, true)
  addFormattedToValues?: boolean = undefined;

  @JsonProperty("addProvisionValuesBasedOnSectionHeader", Boolean, true)
  addProvisionValuesBasedOnSectionHeader?: boolean = undefined;

  @JsonProperty("addUnformattedToValues", Boolean, true)
  addUnformattedToValues?: boolean = undefined;

  @JsonProperty("autoSelectMentionConfig", AutoSelectMentionsConfigModel, true)
  autoSelectMentionConfig?: AutoSelectMentionsConfigModel = undefined;

  @JsonProperty("badPagesForValues", ["TABLE_OF_CONTENTS"], true)
  badPagesForValues?: Array<"TABLE_OF_CONTENTS"> = [];

  @JsonProperty("embeddingModels", Any, true)
  embeddingModels?: Array<
    | "ML_10K_ALL_MINI_LM_L6_V2"
    | "ML_10K_FT_MINI_LM_L6_V2"
    | "ML_2M_ALL_MINI_LM_L6_V2"
    | "ML_2M_FT_MINI_LM_L6_V2"
    | "ML_CONTRA_10K_FT_MINI_LM_L6_V2"
    | "ML_CONTRA_10K_FT_MINI_LM_L6_V2_V1"
    | "ML_CONTRA_10K_FT_MINI_LM_L6_V2_V2"
    | "ML_CONTRA_410K_LI_MINI_LM_L6_V2"
    | "ML_CONTRA_410K_LI_FT_MINI_LM_L6_V2"
    | "ML_CONTRA_FT_ALL_MPNET_BASE_V2_V1"
    | "MULTI_2M_QA_MPNET_BASE_DOT_V1"
    | "AGENCY_AGREEMENT_V1"
    | "ESCROW_AGREEMENT_V1"
    | "ASSET_PURCHASE_AGREEMENT_V1"
    | "ACQUISITION_AGREEMENT_V1"
    | "SALE_AND_PURCHASE_AGREEMENT_V1"
    | "VESTING_DEED_V1"
    | "VENDOR_AGREEMENT_V1"
    | "TAX_CERTIFICATE_V1"
    | "RENT_ROLL_V1"
    | "OFFERING_MEMORANDUM_V1"
    | "LETTER_OF_CREDIT_AGREEMENT_V1"
    | "COMMISSION_AGREEMENT_V1"
  > = [];

  @JsonProperty("formatParserSources", Any, true)
  formatParserSources?: Array<"GCP_API" | "CUSTOM"> = [];

  @JsonProperty("keywordScoreConfig", KeywordScoreConfigModel, true)
  keywordScoreConfig?: KeywordScoreConfigModel = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("numMentionsConfig", NumMentionsConfigModel, true)
  numMentionsConfig?: NumMentionsConfigModel = undefined;

  @JsonProperty("ocrTxtOrients", Any, true)
  ocrTxtOrients?: Array<"ORIG" | "HORIZ"> = [];

  @JsonProperty("provisionParseCompletionModels", Any, true)
  provisionParseCompletionModels?: Array<
    | "PROVISION_PARSE_V1"
    | "PROVISION_PARSE_V2"
    | "PROVISION_PARSE_V3"
    | "PROVISION_PARSE_V4"
    | "PROVISION_PARSE_V5"
    | "PROVISION_PARSE_V6"
    | "PROVISION_PARSE_V7"
  > = [];

  @JsonProperty("provisionScorerModels", Any, true)
  provisionScorerModels?: Array<
    | "KEYWORD"
    | "REAL_DEAL_DOC_VECTOR"
    | "LAW_INSIDER_VECTOR"
    | "RDD_V1"
    | "RDD_V2"
    | "MLP_GLOVE_RDD"
    | "BERT_BASE_RDD"
    | "BERT_V2"
    | "SECTION_HEADER"
    | "RDD_V3"
    | "RDD_V3_SUB"
    | "RDD_V4"
    | "ESCROW_V1"
    | "LETTER_OF_CREDIT_V1"
    | "ASSET_PURCHASE_V1"
    | "COMMISSION_V1"
    | "LOAN_V1"
    | "SEARCH_EMBEDDING"
  > = [];

  @JsonProperty("removedScoreAlgoTypes", Any, true)
  removedScoreAlgoTypes?: Array<
    | "KEYWORD"
    | "OPENAI"
    | "PREFIX"
    | "POSTFIX"
    | "PRETOPOSTFIX"
    | "TF_IDF_KEYWORD"
    | "TF_IDF_PREFIX"
    | "TF_IDF_POSTFIX"
    | "TF_IDF_PRETOPOSTFIX"
    | "TF_IDF"
    | "ML_KEYWORD"
    | "ML_PREFIX"
    | "ML_POSTFIX"
    | "ML_PRETOPOSTFIX"
    | "ML"
    | "SECTION_HEADER"
    | "GCP_DOCUMENT_AI"
    | "DERIVED"
    | "ML_SEARCH"
    | "CHAT_GPT"
    | "LBX"
  > = [];

  @JsonProperty("scoreFormattedVsKeywords", Boolean, true)
  scoreFormattedVsKeywords?: boolean = undefined;

  @JsonProperty("scoreFormattedVsUnformattedMentions", Boolean, true)
  scoreFormattedVsUnformattedMentions?: boolean = undefined;

  @JsonProperty("scoreThresholdConfig", ScoreThresholdConfigModel, true)
  scoreThresholdConfig?: ScoreThresholdConfigModel = undefined;

  @JsonProperty("updatingConfigIds", [ObjectIdModel], true)
  updatingConfigIds?: Array<ObjectIdModel> = [];

  @JsonProperty("useTitleInfoForEmbeddingSearch", Boolean, true)
  useTitleInfoForEmbeddingSearch?: boolean = undefined;
}
