import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { cloneDeep, keyBy } from "lodash";
import {
  AbstractReviewPartialState,
  FetchDefaultDocumentAnnotation,
  SelectedAbstractDetailLoaded,
} from "@@intelease/app-state/abstract-review";
import { RecordReviewModeEnum } from "@@intelease/app-models/common";
import { ErrorUtils, EntityType } from "@@intelease/web/utils";
import { RecordService } from "@@intelease/api-models/adex-api-model-src";
import { SelectedAbstractDetailLoadedPayloadInterface } from "@@intelease/app-models/abstract-review/src";

@Injectable()
export class AbstractDetailRouterCanActivateChild {
  constructor(
    private router: Router,
    private store: Store<AbstractReviewPartialState>,
    private recordService: RecordService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<any> | boolean | any {
    if (childRoute.routeConfig.path !== "pdf") {
      return true;
    }

    this.store.dispatch(
      new FetchDefaultDocumentAnnotation({
        recordUid: this.getAbstractUid(childRoute),
      })
    );

    return this.recordService
      .getComplete({
        recordUid: this.getAbstractUid(childRoute),
      })
      .pipe(
        map(({ data }) => {
          const { formStructure, provisions } = data;
          return {
            abstractDetail: data,
            formStructure: formStructure
              ? {
                  ...formStructure,
                  categories: keyBy(formStructure.categories, "uid"),
                }
              : formStructure,
            provisions: keyBy(provisions, "provisionUid"),
            provisionInconsistencies: data.provisionInconsistencies,
          } as SelectedAbstractDetailLoadedPayloadInterface;
        }),
        map((res) => {
          if (res && res.abstractDetail) {
            const { recordReviewMode } = res.abstractDetail;
            const url = cloneDeep(state).url;
            setTimeout(() => {
              this.store.dispatch(new SelectedAbstractDetailLoaded(res));
            });
            if (
              recordReviewMode === RecordReviewModeEnum.V2 &&
              url.indexOf("/abstract-review/") !== -1
            ) {
              this.router.navigate([
                url.replace("/abstract-review/", "/record-review/"),
              ]);
              return false;
            } else if (
              recordReviewMode === RecordReviewModeEnum.V1 &&
              url.indexOf("/record-review/") !== -1
            ) {
              this.router.navigate([
                url.replace("/record-review/", "/abstract-review/"),
              ]);
              return false;
            }
            return true;
          }
        }),
        catchError((error) => {
          if (ErrorUtils.isForbidden(error)) {
            this.router.navigate(["/miscellaneous/forbidden"], {
              skipLocationChange: true,
              state: {
                entityUid: this.getAbstractUid(childRoute),
                entityType: EntityType.FINAL_ABSTRACT,
              },
            });
          } else {
            this.router.navigate(["/miscellaneous/not-found"], {
              skipLocationChange: true,
              state: {
                entityUid: this.getAbstractUid(childRoute),
                entityType: EntityType.FINAL_ABSTRACT,
              },
            });
          }
          return of(false);
        })
      );
  }

  private getAbstractUid(childRoute: ActivatedRouteSnapshot): string {
    return childRoute.parent.params["uid"] || location.pathname.split("/")[2];
  }
}
