/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";

/**
 * the request to calculate the term
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CalculateTermRequestModel")
export class CalculateTermRequestModel {
  /**
   * the term field type for which the value is to be computed
   */

  @JsonProperty("calculatedField", Any, true)
  calculatedField?: "COMMENCE_DATE" | "EXPIRY_DATE" | "TERM" = undefined;

  @JsonProperty("commencementDate", SimpleOpenApiDateModel, true)
  commencementDate?: SimpleOpenApiDateModel = undefined;

  @JsonProperty("expirationDate", SimpleOpenApiDateModel, true)
  expirationDate?: SimpleOpenApiDateModel = undefined;

  /**
   * number of the term unit (could be null if this is being computed)
   */

  @JsonProperty("termNumber", Number, true)
  termNumber?: number = undefined;

  /**
   * the specific unit for a numerical value
   */

  @JsonProperty("termUnit", Any, true)
  termUnit?:
    | "SQUARE_FEET"
    | "SQUARE_METERS"
    | "PING"
    | "TSUBO"
    | "ACRES"
    | "HECTARES"
    | "SQUARE_KILOMETERS"
    | "SQUARE_MILES"
    | "CENTIMETERS"
    | "INCHES"
    | "FEET"
    | "YARDS"
    | "METERS"
    | "KILOMETERS"
    | "MILES"
    | "PERCENT"
    | "MONEY"
    | "SECONDS"
    | "MINUTES"
    | "HOURS"
    | "DAYS"
    | "WEEKS"
    | "MONTHS"
    | "YEARS"
    | "METERS_PER_SECOND"
    | "METERS_PER_SECOND_PER_SECOND"
    | "GRAMS"
    | "CUBIC_CENTIMETERS"
    | "GRAMS_PER_CUBIC_CENTIMETER"
    | "VOLTS"
    | "AMPERES"
    | "PASCALS"
    | "INCHES_WATER"
    | "POUNDS_PER_SQUARE_INCH"
    | "ATMOSPHERES"
    | "KELVIN"
    | "CELSIUS"
    | "FAHRENHEIT"
    | "NONE" = undefined;
}
