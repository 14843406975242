import { Action } from "@ngrx/store";
import { Entity } from "./common.reducer";

export enum CommonActionTypes {
  LoadCommon = "[Common] Load Common",
  CommonLoaded = "[Common] Common Loaded",
  CommonLoadError = "[Common] Common Load Error",

  LoadProvisionsMetadata = "[Common] Load Provisions Metadata",
  ProvisionsMetadataLoaded = "[Common] Provisions Metadata Loaded",
  ProvisionsMetadataLoadError = "[Common] Provisions Metadata Load Error",

  LoadAccountSettings = "[Common] Load Account Settings",
  AccountSettingsLoaded = "[Common] Account Settings Loaded",
  AccountSettingsLoadError = "[Common] Account Settings Load Error",

  UpdateAccountSettings = "[Common] Update Account Settings",
  UpdateAccountSettingsSucceeded = "[Common] Update Account Settings Succeeded",
  UpdateAccountSettingsFailed = "[Common] Update Account Settings Failed",

  LoadAccountCustomTags = "[Common] Load Account Custom Tags",
  AccountCustomTagsLoaded = "[Common] Account Custom Tags Loaded",
  AccountCustomTagsLoadError = "[Common] Account Custom Tags Load Error",

  UpdateProvisionsMetadata = "[Common] Update Provisions Metadata",
}

export class LoadCommon implements Action {
  readonly type = CommonActionTypes.LoadCommon;
}

export class CommonLoadError implements Action {
  readonly type = CommonActionTypes.CommonLoadError;

  constructor(public payload: any) {}
}

export class CommonLoaded implements Action {
  readonly type = CommonActionTypes.CommonLoaded;

  constructor(public payload: Entity[]) {}
}

export class LoadProvisionsMetadata implements Action {
  readonly type = CommonActionTypes.LoadProvisionsMetadata;

  constructor(public payload: any) {}
}

export class ProvisionsMetadataLoaded implements Action {
  readonly type = CommonActionTypes.ProvisionsMetadataLoaded;

  constructor(public payload: any) {}
}

export class ProvisionsMetadataLoadError implements Action {
  readonly type = CommonActionTypes.ProvisionsMetadataLoadError;

  constructor(public payload: any) {}
}

export class LoadAccountSettings implements Action {
  readonly type = CommonActionTypes.LoadAccountSettings;

  constructor(public payload: any) {}
}

export class AccountSettingsLoaded implements Action {
  readonly type = CommonActionTypes.AccountSettingsLoaded;

  constructor(public payload: any) {}
}

export class AccountSettingsLoadError implements Action {
  readonly type = CommonActionTypes.AccountSettingsLoadError;

  constructor(public payload: any) {}
}

export class UpdateAccountSettings implements Action {
  readonly type = CommonActionTypes.UpdateAccountSettings;

  constructor(public uid: string, public payload: any) {}
}

export class UpdateAccountSettingsSucceeded implements Action {
  readonly type = CommonActionTypes.UpdateAccountSettingsSucceeded;

  constructor(public payload: any) {}
}

export class UpdateAccountSettingsFailed implements Action {
  readonly type = CommonActionTypes.UpdateAccountSettingsFailed;

  constructor(public payload: any) {}
}

export class LoadAccountCustomTags implements Action {
  readonly type = CommonActionTypes.LoadAccountCustomTags;

  constructor(public payload: any) {}
}

export class AccountCustomTagsLoaded implements Action {
  readonly type = CommonActionTypes.AccountCustomTagsLoaded;

  constructor(public payload: any) {}
}

export class AccountCustomTagsLoadError implements Action {
  readonly type = CommonActionTypes.AccountCustomTagsLoadError;

  constructor(public payload: any) {}
}

export class UpdateProvisionsMetadata implements Action {
  readonly type = CommonActionTypes.UpdateProvisionsMetadata;
}

export type CommonAction =
  | LoadCommon
  | CommonLoaded
  | CommonLoadError
  | LoadProvisionsMetadata
  | ProvisionsMetadataLoaded
  | ProvisionsMetadataLoadError
  | LoadAccountSettings
  | AccountSettingsLoaded
  | AccountSettingsLoadError
  | UpdateAccountSettings
  | UpdateAccountSettingsSucceeded
  | UpdateAccountSettingsFailed
  | LoadAccountCustomTags
  | AccountCustomTagsLoaded
  | AccountCustomTagsLoadError
  | UpdateProvisionsMetadata;

export const fromCommonActions = {
  LoadCommon,
  CommonLoaded,
  CommonLoadError,
  LoadProvisionsMetadata,
  ProvisionsMetadataLoaded,
  ProvisionsMetadataLoadError,

  LoadAccountSettings,
  AccountSettingsLoaded,
  AccountSettingsLoadError,

  UpdateAccountSettings,
  UpdateAccountSettingsSucceeded,
  UpdateAccountSettingsFailed,

  LoadAccountCustomTags,
  AccountCustomTagsLoaded,
  AccountCustomTagsLoadError,

  UpdateProvisionsMetadata,
};
