export enum AbstractViewEnum {
  COMPLETE = "COMPLETE",
  EVENTS = "EVENTS",
  FORM_NAME = "FORM_NAME",
  FULL = "FULL",
  MINIMAL = "MINIMAL",
  PROVISION_VALUES = "PROVISION_VALUES",
  MULTI_PROVISION_STRINGS = "MULTI_PROVISION_STRINGS",
  MULTI_PROVISION_STRINGS_EXPORT = "MULTI_PROVISION_STRINGS_EXPORT",
  MULTI_PROVISION_STRINGS_NO_TABLES_EXPORT = "MULTI_PROVISION_STRINGS_NO_TABLES_EXPORT",
  MULTI_PROVISION_STRINGS_ONLY_TABLES_EXPORT = "MULTI_PROVISION_STRINGS_ONLY_TABLES_EXPORT",
  MULTI_PROVISION_VALUES = "MULTI_PROVISION_VALUES",
  PROVISION_FORM = "PROVISION_FORM",
  RELATED_DOCS = "RELATED_DOCS",
  PERMISSIONS = "PERMISSIONS",
  TABLE_OF_CONTENTS = "TABLE_OF_CONTENTS",
  NONE = "NONE",
}
