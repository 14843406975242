import { FetchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FetchProvisionValueService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly PROVISIONS_URL = "/provisions";
  private static readonly VALUES_URL = "/values";

  constructor(private fetchApiService: FetchApiService) {}

  getProvisionValueView<T extends object>(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    return this.fetchApiService.sendRequest(
      FetchProvisionValueService.API_VERSION_1,
      `${FetchProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}` +
        `${FetchProvisionValueService.PROVISIONS_URL}/${provisionUid}${FetchProvisionValueService.VALUES_URL}`,
      provisionValueUid,
      view,
      classRef
    );
  }

  getProvisionValueSnippets(
    abstractUid: string,
    htmlName: string,
    snippetAddresses: string[]
  ): Observable<any> {
    return this.fetchApiService.sendUnknownEntityRequest(
      FetchProvisionValueService.API_VERSION_1,
      `${FetchProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}/htmlName`,
      htmlName,
      {
        addresses: snippetAddresses,
      }
    );
  }
}
