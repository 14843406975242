/* tslint:disable */
/* eslint-disable */
import { DocEditRequestApiDtoModel } from "./doc-edit-request-api-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqDocEditRequestModel")
export class OApiReqDocEditRequestModel {
  @JsonProperty("data", DocEditRequestApiDtoModel, true)
  data?: DocEditRequestApiDtoModel = undefined;
}
