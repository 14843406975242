import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "il-web-abstraction-page-save-abstract-modal",
  templateUrl: "./web-abstraction-page-save-abstract-modal.component.html",
  styleUrls: ["./web-abstraction-page-save-abstract.modal-component.scss"],
})
export class WebAbstractionPageSaveAbstractModalComponent implements OnInit {
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;
  modalTitle = "Save Abstract";
  abstractName = "";
  deselectIncomplete = false;

  constructor(
    public bsModalRef: BsModalRef,
    private inputValidationService: InputValidationService
  ) {}

  ngOnInit() {
    if (this.payload && this.payload.oldName) {
      const oldName = this.payload.oldName;
      this.abstractName = this.hasPdfExtension(oldName)
        ? oldName.slice(0, -4)
        : oldName;
    }
  }

  onGiveFilename() {
    let abstractName = this.inputValidationService.sanitizeText(
      this.abstractName
    );
    if (!this.validateOnGiveFilenameFields(abstractName)) {
      return;
    }
    abstractName = this.correctPdfFilename(abstractName);
    this.bsModalRef.hide();
    this.modalRes.data = {
      ...{
        abstractName: abstractName,
        deselectIncomplete: this.deselectIncomplete,
      },
    };
    this.onClose.next(this.modalRes);
  }

  private validateOnGiveFilenameFields(abstractName: string): boolean {
    if (
      !this.inputValidationService.usefulTextElseAlert(
        abstractName,
        "You must specify a name for the abstract!"
      )
    ) {
      return false;
    }
    return true;
  }

  onDismissModal() {
    this.bsModalRef.hide();
    this.onDismiss.next(this.modalRes);
  }

  private correctPdfFilename(abstractName: string): string {
    if (this.hasPdfExtension(abstractName)) {
      abstractName = abstractName.slice(0, -4);
    }
    return abstractName + ".pdf";
  }

  private hasPdfExtension(abstractName: string): boolean {
    return abstractName.toLowerCase().endsWith(".pdf");
  }
}
