import { ProvisionReviewStatusEnum } from "@@intelease/app-models/common/src";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision/src";

export function shouldSetStatusToNeedReviewTouched(
  provision: FullValMultiPdfProvModel
): boolean {
  return [
    ProvisionReviewStatusEnum.NEED_REVIEW_TOUCHED,
    ProvisionReviewStatusEnum.NEED_REVIEW,
  ].includes(provision.reviewStatus as ProvisionReviewStatusEnum);
}
