import { Action } from "@ngrx/store";
import { Entity, KanbanState } from "./kanban.reducer";

export enum KanbanActionTypes {
  LoadKanban = "[Kanban] Load Kanban",
  KanbanLoaded = "[Kanban] Kanban Loaded",
  KanbanLoadError = "[Kanban] Kanban Load Error",

  SaveKanban = "[Kanban] Save Kanban",
  SaveKanbanSucceeded = "[Kanban] Save Kanban Succeeded",
  SaveKanbanFailed = "[Kanban] Save Kanban Failed",

  LoadSelectedKanban = "[Kanban] Load Selected Kanban",
  SelectedKanbanLoaded = "[Kanban] Selected Kanban Loaded",
  SelectedKanbanLoadError = "[Kanban] Selected Kanban Load Error",

  UpdateSelectedKanban = "[Kanban] Update Selected Kanban",
  UpdateSelectedKanbanSucceeded = "[Kanban] Update Selected Kanban Succeeded",
  UpdateSelectedKanbanFailed = "[Kanban] Update Selected Kanban Failed",

  LoadSelectedKanbanIssue = "[Kanban] Load Selected Kanban Issue",
  SelectedKanbanIssueLoaded = "[Kanban] Selected Kanban Issue Loaded",
  SelectedKanbanIssueLoadError = "[Kanban] Selected Kanban Issue Load Error",

  LoadSelectedKanbanIssueTasks = "[Kanban] Load Selected Kanban Issue Tasks",
  SelectedKanbanIssueTasksLoaded = "[Kanban] Selected Kanban Issue Tasks Loaded",
  SelectedKanbanIssueTasksLoadError = "[Kanban] Selected Kanban Issue Tasks Load Error",

  LoadSelectedKanbanIssueHistory = "[Kanban] Load Selected Kanban Issue History",
  SelectedKanbanIssueHistoryLoaded = "[Kanban] Selected Kanban Issue History Loaded",
  SelectedKanbanIssueHistoryLoadError = "[Kanban] Selected Kanban Issue History Load Error",

  DeleteKanbanIssue = "[Kanban] Delete Kanban Issue",
  DeleteKanbanIssueSucceeded = "[Kanban] Delete Kanban Issue Succeeded",
  DeleteKanbanIssueFailed = "[Kanban] Delete Kanban Issue Failed",

  SaveKanbanComment = "[Kanban] Save Kanban Comment",
  SaveKanbanCommentSucceeded = "[Kanban] Save Kanban Comment Succeeded",
  SaveKanbanCommentFailed = "[Kanban] Save Kanban Comment Failed",

  DeleteKanbanComment = "[Kanban] Delete Kanban Comment",
  DeleteKanbanCommentSucceeded = "[Kanban] Delete Kanban Comment Succeeded",
  DeleteKanbanCommentFailed = "[Kanban] Delete Kanban Comment Failed",

  UpdateKanbanComment = "[Kanban] Update Kanban Comment",
  UpdateKanbanCommentSucceeded = "[Kanban] Update Kanban Comment Succeeded",
  UpdateKanbanCommentFailed = "[Kanban] Update Kanban Comment Failed",

  AddIssueList = "[Kanban] Add Issue List",

  CleanKanbanState = "[Kanban] Clean Kanban State",
}

export class LoadKanban implements Action {
  readonly type = KanbanActionTypes.LoadKanban;
}

export class KanbanLoadError implements Action {
  readonly type = KanbanActionTypes.KanbanLoadError;

  constructor(public payload: any) {}
}

export class KanbanLoaded implements Action {
  readonly type = KanbanActionTypes.KanbanLoaded;

  constructor(public payload: Entity[]) {}
}

export class SaveKanban implements Action {
  readonly type = KanbanActionTypes.SaveKanban;

  constructor(public payload: any) {}
}

export class SaveKanbanSucceeded implements Action {
  readonly type = KanbanActionTypes.SaveKanbanSucceeded;

  constructor(public payload: any) {}
}

export class SaveKanbanFailed implements Action {
  readonly type = KanbanActionTypes.SaveKanbanFailed;

  constructor(public payload: any) {}
}

export class LoadSelectedKanban implements Action {
  readonly type = KanbanActionTypes.LoadSelectedKanban;

  constructor(
    public dashboardUid: string,
    public projectUid: string,
    public kanbanUid: string,
    public selectedInstanceId: string
  ) {}
}

export class SelectedKanbanLoaded implements Action {
  readonly type = KanbanActionTypes.SelectedKanbanLoaded;

  constructor(public payload: any, public selectedInstanceId: string) {}
}

export class SelectedKanbanLoadError implements Action {
  readonly type = KanbanActionTypes.SelectedKanbanLoadError;

  constructor(public payload: any, public selectedInstanceId: string) {}
}

export class UpdateSelectedKanban implements Action {
  readonly type = KanbanActionTypes.UpdateSelectedKanban;

  constructor(
    public projectUid: string,
    public kanbanUid: string,
    public payload: any
  ) {}
}

export class UpdateSelectedKanbanSucceeded implements Action {
  readonly type = KanbanActionTypes.UpdateSelectedKanbanSucceeded;

  constructor(public payload: any) {}
}

export class UpdateSelectedKanbanFailed implements Action {
  readonly type = KanbanActionTypes.UpdateSelectedKanbanFailed;

  constructor(public payload: any) {}
}

export class LoadSelectedKanbanIssue implements Action {
  readonly type = KanbanActionTypes.LoadSelectedKanbanIssue;

  constructor(
    public dashboardUid: string,
    public projectUid: string,
    public kabanBoardUid: string,
    public issueListUid: string,
    public issueUid: string
  ) {}
}

export class SelectedKanbanIssueLoaded implements Action {
  readonly type = KanbanActionTypes.SelectedKanbanIssueLoaded;

  constructor(public payload: any) {}
}

export class SelectedKanbanIssueLoadError implements Action {
  readonly type = KanbanActionTypes.SelectedKanbanIssueLoadError;

  constructor(public payload: any) {}
}

export class LoadSelectedKanbanIssueTasks implements Action {
  readonly type = KanbanActionTypes.LoadSelectedKanbanIssueTasks;

  constructor(
    public projectUid: string,
    public kabanBoardUid: string,
    public issueListUid: string,
    public issueUid: string
  ) {}
}

export class SelectedKanbanIssueTasksLoaded implements Action {
  readonly type = KanbanActionTypes.SelectedKanbanIssueTasksLoaded;

  constructor(public payload: any) {}
}

export class SelectedKanbanIssueTasksLoadError implements Action {
  readonly type = KanbanActionTypes.SelectedKanbanIssueTasksLoadError;

  constructor(public payload: any) {}
}

export class LoadSelectedKanbanIssueHistory implements Action {
  readonly type = KanbanActionTypes.LoadSelectedKanbanIssueHistory;

  constructor(
    public projectUid: string,
    public kabanBoardUid: string,
    public issueListUid: string,
    public issueUid: string
  ) {}
}

export class SelectedKanbanIssueHistoryLoaded implements Action {
  readonly type = KanbanActionTypes.SelectedKanbanIssueHistoryLoaded;

  constructor(public payload: any) {}
}

export class SelectedKanbanIssueHistoryLoadError implements Action {
  readonly type = KanbanActionTypes.SelectedKanbanIssueHistoryLoadError;

  constructor(public payload: any) {}
}

export class DeleteKanbanIssue implements Action {
  readonly type = KanbanActionTypes.DeleteKanbanIssue;

  constructor(
    public projectUid: string,
    public kanbanBoardUid: string,
    public issueListUid: string,
    public issueUid: string
  ) {}
}

export class DeleteKanbanIssueSucceeded implements Action {
  readonly type = KanbanActionTypes.DeleteKanbanIssueSucceeded;

  constructor(public payload: any) {}
}

export class DeleteKanbanIssueFailed implements Action {
  readonly type = KanbanActionTypes.DeleteKanbanIssueFailed;

  constructor(public payload: any) {}
}

export class SaveKanbanComment implements Action {
  readonly type = KanbanActionTypes.SaveKanbanComment;

  constructor(
    public projectUid: string,
    public kabanBoardUid: string,
    public issueListUid: string,
    public issueUid: string,
    public text: string,
    public elements
  ) {}
}

export class SaveKanbanCommentSucceeded implements Action {
  readonly type = KanbanActionTypes.SaveKanbanCommentSucceeded;

  constructor(public payload: any) {}
}

export class SaveKanbanCommentFailed implements Action {
  readonly type = KanbanActionTypes.SaveKanbanCommentFailed;

  constructor(public payload: any) {}
}

export class CleanKanbanState implements Action {
  readonly type = KanbanActionTypes.CleanKanbanState;

  constructor(public payload: KanbanState) {}
}

export class AddIssueList implements Action {
  readonly type = KanbanActionTypes.AddIssueList;

  constructor(public payload: any) {}
}

export class DeleteKanbanComment implements Action {
  readonly type = KanbanActionTypes.DeleteKanbanComment;

  constructor(
    public projectUid: string,
    public kabanBoardUid: string,
    public issueListUid: string,
    public issueUid: string,
    public commentUid: string
  ) {}
}

export class DeleteKanbanCommentSucceeded implements Action {
  readonly type = KanbanActionTypes.DeleteKanbanCommentSucceeded;

  constructor(public payload: any) {}
}

export class DeleteKanbanCommentFailed implements Action {
  readonly type = KanbanActionTypes.DeleteKanbanCommentFailed;

  constructor(public payload: any) {}
}

export class UpdateKanbanComment implements Action {
  readonly type = KanbanActionTypes.UpdateKanbanComment;

  constructor(
    public projectUid: string,
    public kabanBoardUid: string,
    public issueListUid: string,
    public issueUid: string,
    public commentUid: string,
    public text: string,
    public elements
  ) {}
}

export class UpdateKanbanCommentSucceeded implements Action {
  readonly type = KanbanActionTypes.UpdateKanbanCommentSucceeded;

  constructor(public payload: any) {}
}

export class UpdateKanbanCommentFailed implements Action {
  readonly type = KanbanActionTypes.UpdateKanbanCommentFailed;

  constructor(public payload: any) {}
}

export type KanbanAction =
  | LoadKanban
  | KanbanLoaded
  | KanbanLoadError
  | SaveKanban
  | SaveKanbanSucceeded
  | SaveKanbanFailed
  | LoadSelectedKanban
  | SelectedKanbanLoaded
  | SelectedKanbanLoadError
  | UpdateSelectedKanban
  | UpdateSelectedKanbanSucceeded
  | UpdateSelectedKanbanFailed
  | LoadSelectedKanbanIssue
  | SelectedKanbanIssueLoaded
  | SelectedKanbanIssueLoadError
  | LoadSelectedKanbanIssueTasks
  | SelectedKanbanIssueTasksLoaded
  | SelectedKanbanIssueTasksLoadError
  | LoadSelectedKanbanIssueHistory
  | SelectedKanbanIssueHistoryLoaded
  | SelectedKanbanIssueHistoryLoadError
  | CleanKanbanState
  | AddIssueList
  | DeleteKanbanIssue
  | DeleteKanbanIssueSucceeded
  | DeleteKanbanIssueFailed
  | SaveKanbanComment
  | SaveKanbanCommentSucceeded
  | SaveKanbanCommentFailed
  | DeleteKanbanComment
  | DeleteKanbanCommentSucceeded
  | DeleteKanbanCommentFailed
  | UpdateKanbanComment
  | UpdateKanbanCommentSucceeded
  | UpdateKanbanCommentFailed;

export const fromKanbanActions = {
  LoadKanban,
  KanbanLoaded,
  KanbanLoadError,
  SaveKanban,
  SaveKanbanSucceeded,
  SaveKanbanFailed,
  LoadSelectedKanban,
  SelectedKanbanLoaded,
  SelectedKanbanLoadError,
  UpdateSelectedKanban,
  UpdateSelectedKanbanSucceeded,
  UpdateSelectedKanbanFailed,
  LoadSelectedKanbanIssue,
  SelectedKanbanIssueLoaded,
  SelectedKanbanIssueLoadError,
  LoadSelectedKanbanIssueTasks,
  SelectedKanbanIssueTasksLoaded,
  SelectedKanbanIssueTasksLoadError,
  LoadSelectedKanbanIssueHistory,
  SelectedKanbanIssueHistoryLoaded,
  SelectedKanbanIssueHistoryLoadError,
  CleanKanbanState,
  AddIssueList,
  SaveKanbanComment,
  SaveKanbanCommentSucceeded,
  SaveKanbanCommentFailed,

  DeleteKanbanComment,
  DeleteKanbanCommentSucceeded,
  DeleteKanbanCommentFailed,

  UpdateKanbanComment,
  UpdateKanbanCommentSucceeded,
  UpdateKanbanCommentFailed,
};
