import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
// eslint-disable-next-line
// @ts-ignore
import Centrifuge from "centrifuge/dist/centrifuge";
import { environment } from "../../../../../apps/ui/src/environments/environment";
import { LocalStorageKey } from "@common/enums/local-storage.keys";
@Injectable({
  providedIn: "root",
})
export class CentrifugeService {
  public static onNewNotification: Subject<any> = new Subject<any>();
  public static onRecordStatusUpdate: Subject<any> = new Subject<any>();
  private client: Centrifuge;
  private debug = true;

  private connected = false;
  stateEmitter = new EventEmitter<any>();

  getStates(): Observable<any> {
    return this.stateEmitter;
  }

  connect(parameters): void {
    if (this.connected) {
      // throw new Error('Centrifuge is already connected.');
      return;
    }
    this.client = new Centrifuge(environment.centrifugoServerUrl, {
      // sockjs: SockJS,
      ...parameters,
    });

    this.client.setToken(localStorage.getItem(LocalStorageKey.AUTH));
    // this.client.setConnectData({
    //   user: UserInfoService.getUserUid()
    // });
    this.debug = true;
    this.client
      .on("connect", (data) => {
        this.connected = true;
        if (this.debug) {
          console.log("Connected", data);
        }
        this.stateEmitter.emit({
          type: "state",
          state: "connected",
          info: data,
        });
      })
      .on("disconnect", (data) => {
        this.connected = false;
        if (this.debug) {
          console.log("Disconnected from", data);
        }
        this.stateEmitter.emit({
          type: "state",
          state: "disconnected",
          info: data,
        });
        delete this.client;
      })
      .on("error", (error) => {
        if (this.debug) {
          console.error("Error :", error);
        }
        this.stateEmitter.emit({ type: "error", info: error });
      });

    this.client.connect();
  }

  getMessages(channel: string): Observable<any> {
    const subscription = this.client.subscribe(channel, (message) => {
      // handle new message coming from channel "news"
      const { data } = message;
      CentrifugeService.onNewNotification.next(data);
      CentrifugeService.onRecordStatusUpdate.next(data);
    });

    subscription.on("message", function (data) {
      if (this.debug) {
        console.log(`Subscribed to ' ${channel} ' :`, data);
      }
    });

    subscription.on("subscribe", function (data) {
      if (this.debug) {
        console.log(`Subscribed to ' ${channel} ' :`, data);
      }
    });
    subscription.on("error", function (error) {
      if (this.debug) {
        console.log(" Subscribe error :", error);
      }
      this.stateEmitter.emit({ type: "error", info: error });
    });
    return subscription;
  }

  disconnect(): void {
    this.client.disconnect();
  }
}
