/* tslint:disable */
/* eslint-disable */
import { SaveHighlightMentionV2ApiDtoModel } from "./save-highlight-mention-v-2-api-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqSaveHighlightMentionV2DtoModel")
export class OApiReqSaveHighlightMentionV2DtoModel {
  @JsonProperty("data", SaveHighlightMentionV2ApiDtoModel, true)
  data?: SaveHighlightMentionV2ApiDtoModel = undefined;
}
