/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("QuickSearchResultHighlightModel")
export class QuickSearchResultHighlightModel {
  @JsonProperty("highlights", [String], true)
  highlights?: Array<string> = [];

  @JsonProperty("searchCategory", Any, true)
  searchCategory?:
    | "PROPERTY"
    | "PORTFOLIO"
    | "SUITE"
    | "ABSTRACT_FILE_NAME"
    | "FILE_NAMES" = undefined;
}
