/* tslint:disable */
/* eslint-disable */
import { IngestDocSetIntoQueueResponseDtoModel } from "./ingest-doc-set-into-queue-response-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("IngestBatchDocSetsIntoQueueResponseDtoModel")
export class IngestBatchDocSetsIntoQueueResponseDtoModel {
  @JsonProperty("errorMessages", [String], true)
  errorMessages?: Array<string> = [];

  @JsonProperty("ingestionAttemptCount", Number, true)
  ingestionAttemptCount?: number = undefined;

  @JsonProperty("ingestionFailureCount", Number, true)
  ingestionFailureCount?: number = undefined;

  @JsonProperty(
    "ingestionResult",
    [IngestDocSetIntoQueueResponseDtoModel],
    true
  )
  ingestionResult?: Array<IngestDocSetIntoQueueResponseDtoModel> = [];

  @JsonProperty("ingestionSuccessCount", Number, true)
  ingestionSuccessCount?: number = undefined;
}
