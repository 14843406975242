/* tslint:disable */
/* eslint-disable */
import { BoundingBoxModel } from "./bounding-box-model";
import { JsonNodeModel } from "./json-node-model";

/**
 * the request body for saving the attribution for an existing NESTED_GROUP provision mention
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("HighlightAttributeNestedMentionApiDtoModel")
export class HighlightAttributeNestedMentionApiDtoModel {
  @JsonProperty("boundingBox", BoundingBoxModel, true)
  boundingBox?: BoundingBoxModel = undefined;

  /**
   * the user-facing {@link DocAbstract#getId()} on which user highlighted
   */

  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  /**
   * the first page of the highlight (1-based)
   */

  @JsonProperty("firstPage", Number, true)
  firstPage?: number = undefined;

  /**
   * the type of the highlight
   */

  @JsonProperty("highlightType", Any, true)
  highlightType?: "TEXT" | "AREA" = undefined;

  /**
   * the last page of the highlight (1-based)
   */

  @JsonProperty("lastPage", Number, true)
  lastPage?: number = undefined;

  @JsonProperty("mentionUid", String, true)
  mentionUid?: string = "";

  /**
   * the metadata of the highlights by the user
   */

  @JsonProperty("pdfProvisionHighlights", [JsonNodeModel], true)
  pdfProvisionHighlights?: Array<JsonNodeModel> = [];

  @JsonProperty("postMentionUid", String, true)
  postMentionUid?: string = "";

  @JsonProperty("preMentionUid", String, true)
  preMentionUid?: string = "";

  /**
   * the text that was highlighted
   */

  @JsonProperty("text", String, true)
  text?: string = "";
}
