import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ReportLogicalOperatorEnum } from "@intelease/constants";

@Component({
  selector: "il-filter",
  templateUrl: "./itls-filter.component.html",
  styleUrls: ["./itls-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsFilterComponent {
  @Input() model: { operator: ReportLogicalOperatorEnum; value: any[] } = {
    operator: ReportLogicalOperatorEnum.AND,
    value: [],
  };
  @Input() filterFields: {
    fieldName: string;
    fieldType: string;
    uiName: string;
    category: string;
    docSetCategories: string[];
  }[] = [];
  @Input() filterOperators = {};
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
}
