import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { fetch, pessimisticUpdate } from "@ngrx/router-store/data-persistence";
import {
  LoadProjects,
  ProjectsLoaded,
  ProjectsLoadError,
  ProjectsActionTypes,
  SaveProject,
  SaveProjectFailed,
  SaveProjectSucceeded,
  UpdateProject,
  UpdateProjectSucceeded,
  UpdateProjectFailed,
  DeleteProject,
  DeleteProjectSucceeded,
  DeleteProjectFailed,
  LoadProject,
  LoadProjectSucceeded,
  LoadProjectFailed,
  LoadAllProjects,
  LoadAllProjectsSucceeded,
  LoadAllProjectsFailed,
  ShareProject,
  ShareProjectSucceeded,
  ShareProjectFailed,
  DeleteBatchProject,
  DeleteBatchProjectFailed,
  DeleteBatchProjectSucceeded,
} from "./projects.actions";
import { map } from "rxjs/operators";
import { ProjectsService } from "@@intelease/app-services/projects";
import { cloneDeep, keyBy } from "lodash";

@Injectable()
export class ProjectsEffects {
  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.LoadProjects),
      fetch({
        run: () => {
          // Your custom REST 'load' logic goes here. For now just return an empty list...
          return new ProjectsLoaded([]);
        },

        onError: (action: LoadProjects, error) => {
          //eslint-disable-line
          console.error("Error", error);
          return new ProjectsLoadError(error);
        },
      })
    )
  );

  saveProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.SaveProject),
      pessimisticUpdate({
        run: (action: SaveProject) => {
          return this.projectsService
            .saveProject(action.payload)
            .pipe(map((res) => new SaveProjectSucceeded(res.data)));
        },
        onError(action: SaveProject, error) {
          //eslint-disable-line
          return new SaveProjectFailed(error);
        },
      })
    )
  );

  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.UpdateProject),
      pessimisticUpdate({
        run: (action: UpdateProject) => {
          const { projectUid, data, replace } = action.payload;
          return this.projectsService
            .updateProjectByUid(projectUid, data, replace)
            .pipe(map((res) => new UpdateProjectSucceeded(res)));
        },
        onError(action: UpdateProject, error) {
          //eslint-disable-line
          return new UpdateProjectFailed(error);
        },
      })
    )
  );

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteProject),
      pessimisticUpdate({
        run: (action: DeleteProject) => {
          return this.projectsService
            .removeProjectByUid(action.payload)
            .pipe(map((res) => new DeleteProjectSucceeded(res)));
        },
        onError(action: DeleteProject, error) {
          //eslint-disable-line
          return new DeleteProjectFailed(error);
        },
      })
    )
  );

  deleteBatchProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteBatchProject),
      pessimisticUpdate({
        run: (action: DeleteBatchProject) => {
          return this.projectsService
            .removeBatchProject(action.payload)
            .pipe(map(() => new DeleteBatchProjectSucceeded(action.payload)));
        },
        onError(action: DeleteBatchProject, error) {
          //eslint-disable-line
          return new DeleteBatchProjectFailed(error);
        },
      })
    )
  );

  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.LoadProject),
      pessimisticUpdate({
        run: (action: LoadProject) => {
          const { payload, selectedInstanceId } = action;
          return this.projectsService.getProjectByUid(payload).pipe(
            map((res) => {
              const { data } = res;
              const projectDetail = cloneDeep(data);
              delete projectDetail.widgets;
              const widgets = keyBy(data.widgets, "uid");
              return new LoadProjectSucceeded(
                { projectDetail, widgets },
                selectedInstanceId
              );
            })
          );
        },
        onError(action: LoadProject, error) {
          return new LoadProjectFailed(error, action.selectedInstanceId);
        },
      })
    )
  );

  loadAllProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.LoadAllProjects),
      pessimisticUpdate({
        run: () => {
          return this.projectsService
            .getProjects()
            .pipe(map((res) => new LoadAllProjectsSucceeded(res.data)));
        },
        onError(action: LoadAllProjects, error) {
          //eslint-disable-line
          return new LoadAllProjectsFailed(error);
        },
      })
    )
  );

  shareProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.ShareProject),
      pessimisticUpdate({
        run: (action: ShareProject) => {
          const { projectUid, users } = action.payload;
          return this.projectsService
            .shareProjectByUid(projectUid, users)
            .pipe(map((res) => new ShareProjectSucceeded(res)));
        },
        onError(action: ShareProject, error) {
          //eslint-disable-line
          return new ShareProjectFailed(error);
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private readonly projectsService: ProjectsService
  ) {}
}
