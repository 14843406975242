import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Platform } from "@angular/cdk/platform";
import { filter } from "rxjs/operators";
import PerfectScrollbar from "perfect-scrollbar";
import { debounce, merge } from "lodash";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Directive({
  selector: "[inteleasePerfectScrollbar]",
})
export class InteleasePerfectScrollbarDirective
  implements AfterViewInit, OnDestroy
{
  destroyRef = inject(DestroyRef);
  isInitialized: boolean;
  isMobile: boolean;
  ps: PerfectScrollbar;

  private _enabled: boolean | "";
  private _debouncedUpdate: any;
  private _options: any;

  constructor(
    public elementRef: ElementRef,
    private _platform: Platform,
    private _router: Router
  ) {
    // Set the defaults
    this.isInitialized = false;
    this.isMobile = false;

    // Set the private defaults
    this._enabled = true;
    this._debouncedUpdate = debounce(this.update, 150);
    this._options = {
      updateOnRouteChange: false,
    };
  }

  @Input()
  set intelesePerfectScrollbarOptions(value) {
    this._options = merge({}, this._options, value);
  }

  get intelesePerfectScrollbarOptions(): any {
    return this._options;
  }

  @Input("intelesePerfectScrollbar")
  set enabled(value: boolean | "") {
    if (value === "") {
      value = true;
    }

    if (this.enabled === value) {
      return;
    }

    this._enabled = value;

    if (this.enabled) {
      this._init();
    } else {
      this._destroy();
    }
  }

  get enabled(): boolean | "" {
    return this._enabled;
  }

  ngAfterViewInit(): void {
    if (this.intelesePerfectScrollbarOptions.updateOnRouteChange) {
      this._router.events
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          filter((event) => event instanceof NavigationEnd)
        )
        .subscribe(() => {
          setTimeout(() => {
            this.scrollToTop();
            this.update();
          }, 0);
        });
    }
  }

  ngOnDestroy(): void {
    this._destroy();
  }

  _init(): void {
    if (this.isInitialized) {
      return;
    }

    if (this._platform.ANDROID || this._platform.IOS) {
      this.isMobile = true;
    }

    if (this.isMobile) {
      return;
    }

    this.isInitialized = true;

    this.ps = new PerfectScrollbar(this.elementRef.nativeElement, {
      ...this.intelesePerfectScrollbarOptions,
    });
  }

  _destroy(): void {
    if (!this.isInitialized || !this.ps) {
      return;
    }

    this.ps.destroy();

    this.ps = null;
    this.isInitialized = false;
  }

  @HostListener("window:resize")
  _updateOnResize(): void {
    this._debouncedUpdate();
  }

  @HostListener("document:click", ["$event"])
  documentClick(event: Event): void {
    if (!this.isInitialized || !this.ps) {
      return;
    }

    this.ps.update();
  }

  update(): void {
    if (!this.isInitialized) {
      return;
    }

    this.ps.update();
  }

  destroy(): void {
    this.ngOnDestroy();
  }

  scrollToX(x: number, speed?: number): void {
    this.animateScrolling("scrollLeft", x, speed);
  }

  scrollToY(y: number, speed?: number): void {
    this.animateScrolling("scrollTop", y, speed);
  }

  scrollToTop(offset?: number, speed?: number): void {
    this.animateScrolling("scrollTop", offset || 0, speed);
  }

  scrollToLeft(offset?: number, speed?: number): void {
    this.animateScrolling("scrollLeft", offset || 0, speed);
  }

  scrollToRight(offset?: number, speed?: number): void {
    const width = this.elementRef.nativeElement.scrollWidth;

    this.animateScrolling("scrollLeft", width - (offset || 0), speed);
  }

  scrollToBottom(offset?: number, speed?: number): void {
    const height = this.elementRef.nativeElement.scrollHeight;

    this.animateScrolling("scrollTop", height - (offset || 0), speed);
  }

  animateScrolling(target: string, value: number, speed?: number): void {
    if (!speed) {
      this.elementRef.nativeElement[target] = value;

      this.update();
      this.update();
    } else if (value !== this.elementRef.nativeElement[target]) {
      let newValue = 0;
      let scrollCount = 0;

      let oldTimestamp = performance.now();
      let oldValue = this.elementRef.nativeElement[target];

      const cosParameter = (oldValue - value) / 2;

      const step = (newTimestamp) => {
        scrollCount += Math.PI / (speed / (newTimestamp - oldTimestamp));

        newValue = Math.round(
          value + cosParameter + cosParameter * Math.cos(scrollCount)
        );

        // Only continue animation if scroll position has not changed
        if (this.elementRef.nativeElement[target] === oldValue) {
          if (scrollCount >= Math.PI) {
            this.elementRef.nativeElement[target] = value;

            // PS has weird event sending order, this is a workaround for that
            this.update();

            this.update();
          } else {
            this.elementRef.nativeElement[target] = oldValue = newValue;

            oldTimestamp = newTimestamp;

            window.requestAnimationFrame(step);
          }
        }
      };

      window.requestAnimationFrame(step);
    }
  }
}
