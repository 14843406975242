<mat-toolbar class="p-0 mat-elevation-z0 main-navigation" style="height: 46px">
  <div
    fxLayout="row"
    fxLayoutAlign="start start"
    style="margin-top: 0; background: transparent"
  >
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="
          !hiddenNavbar &&
          !rightNavbar &&
          !mainDrawerService.isOpen &&
          (leftMenu$ | async) !== true
        "
        (click)="toggleSidebarOpen()"
      >
        <span
          class="new-notification"
          *ngIf="(notificationsFacade.countOfUnseenNotifications$ | async) > 0"
          >{{ notificationsFacade.countOfUnseenNotifications$ | async }}</span
        >
        <itls-icon
          style="
            width: 16px;
            font-size: 16px;
            min-width: 16px;
            height: 16px;
            line-height: 16px;
          "
          class="secondary-text"
          svgIcon="icon-menu"
          [height]="16"
        ></itls-icon>
      </button>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="/" />
        </div>
      </div>

      <div>
        <breadcrumb></breadcrumb>
      </div>

      <div style="position: absolute; right: 14px; top: 8px">
        <button
          mat-icon-button
          class="navbar-toggle-button"
          *ngIf="!hiddenNavbar && rightNavbar && (leftMenu$ | async) !== true"
          (click)="toggleSidebarOpen()"
          fxHide.gt-md
        >
          <mat-icon class="secondary-text">menu</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-toolbar>
