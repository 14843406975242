import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QuicklinkModule } from "ngx-quicklink";
import { AppAuthorities } from "@@intelease/web/intelease/constants";
import { PermissionsGuard } from "@@intelease/web/intelease/guards";

const routes: Routes = [
  {
    path: "",
    redirectTo: "list",
    pathMatch: "full",
  },
  {
    path: "list",
    loadChildren: () =>
      import("./task-list/task-list.module").then((m) => m.TaskListModule),
    canActivate: [PermissionsGuard],
    data: {
      TITLE_TAG_KEY: "tasks.list",
      permissions: {
        only: [AppAuthorities.READ_TASK],
        redirectTo: "/miscellaneous/not-found",
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), QuicklinkModule],
  exports: [RouterModule],
})
export class WebTasksRoutingModule {}
