import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import {
  ListResponseModel,
  PaginationModel,
  ServerResponseModel,
} from "@@intelease/web/intelease/models";
import { map } from "rxjs/operators";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import { AbstractKeyValue } from "@@intelease/web/common/models";

@Injectable({
  providedIn: "root",
})
export class SearchApiService {
  private static readonly SEARCH_URL_PART = "/search";
  private static readonly SEARCH_TABLE_URL_PART = "/searchTable";

  constructor(private httpClient: HttpClient) {}

  /**
   * Send a POST request to retrieve a filtered listing of existing entities from backend (as accessible by current user).
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param view the view of the objects to return
   * @param filters the filters for the returned objects
   * @param classRef the class of the returned objects
   * @param sortCriteria optional criteria by which to sort the returned list
   * @param pageCriteria optional pagination data for which items to retrieve
   * @return the list of fetched objects
   */
  sendRequest<T extends object>(
    apiVersion: string,
    baseUrl: string,
    view: string,
    classRef: new () => T,
    filters: { field: string; relation: string; value: any }[],
    sortCriteria?: string,
    pageCriteria?: PaginationModel
  ): Observable<ListResponseModel<T>> {
    return this.sendCustomDataRequest<T>(
      apiVersion,
      baseUrl,
      view,
      classRef,
      { filters },
      sortCriteria,
      pageCriteria
    );
  }

  /**
   * Send a POST request to retrieve a filtered listing of existing entities from backend (as accessible by current user).
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param view the view of the objects to return
   * @param data the data containing the filters for the returned objects
   * @param classRef the class of the returned objects
   * @param sortCriteria optional criteria by which to sort the returned list
   * @param pageCriteria optional pagination data for which items to retrieve
   * @return the list of fetched objects
   */
  sendCustomDataRequest<T extends object>(
    apiVersion: string,
    baseUrl: string,
    view: string,
    classRef: new () => T,
    data: { filters: { field: string; relation: string; value: any }[] },
    sortCriteria?: string,
    pageCriteria?: PaginationModel
  ): Observable<ListResponseModel<T>> {
    const body: any = {
      data,
      returnParams: {
        view: view,
      },
    };
    if (sortCriteria) {
      body.data.sort = sortCriteria;
    }
    let pageSize = 25;
    if (pageCriteria) {
      pageSize = pageCriteria.size;
      body.data.perPage = pageSize.toString();
      body.data.page = pageCriteria.page.toString();
    }
    return this.httpClient
      .post<ServerResponseModel>(
        apiVersion + SearchApiService.SEARCH_URL_PART + baseUrl,
        body
      )
      .pipe(
        map((res) =>
          Json2TypescriptHelper.convertToEntities(res.data, classRef, pageSize)
        )
      );
  }

  /**
   * Send a POST request to retrieve a tabular-format filtered listing of existing entities from backend (as accessible by current user).
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param viewColumns the desired projections of the fetched objects
   * @param filters the filters for the returned objects
   * @param sortCriteria optional criteria by which to sort the returned list
   * @param pageCriteria optional pagination data for which items to retrieve
   * @return the list of fetched objects
   */
  sendTableRequest(
    apiVersion: string,
    baseUrl: string,
    viewColumns: string[],
    filters: { field: string; relation: string; value: any }[],
    sortCriteria?: string,
    pageCriteria?: PaginationModel
  ): Observable<ListResponseModel<AbstractKeyValue>> {
    const body: any = {
      data: {
        filters: filters,
        viewColumns: viewColumns,
      },
    };
    if (sortCriteria) {
      body.data.sort = sortCriteria;
    }
    if (pageCriteria) {
      body.data.perPage = pageCriteria.size.toString();
      body.data.page = pageCriteria.page.toString();
    }
    return this.httpClient
      .post<ServerResponseModel>(
        apiVersion + SearchApiService.SEARCH_TABLE_URL_PART + baseUrl,
        body
      )
      .pipe(
        map((res) =>
          Json2TypescriptHelper.convertToAbstract(
            res.data,
            viewColumns,
            pageCriteria.size
          )
        )
      );
  }
}
