/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";
import { TaskAssigneeDtoModel } from "./task-assignee-dto-model";

/**
 * the minimal representation of the task
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MinimalTaskViewModel")
export class MinimalTaskViewModel {
  @JsonProperty("assignee", TaskAssigneeDtoModel, true)
  assignee?: TaskAssigneeDtoModel = undefined;

  /**
   * the minimal representation of the task
   */

  @JsonProperty("createdByUid", String, true)
  createdByUid?: string = "";

  @JsonProperty("dueDate", SimpleOpenApiDateModel, true)
  dueDate?: SimpleOpenApiDateModel = undefined;

  /**
   * the user-facing {@link Entity#getId()}s associated with this task
   */

  @JsonProperty("entityUids", [String], true)
  entityUids?: Array<string> = [];

  /**
   * the task's name
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the task's notes
   */

  @JsonProperty("notes", String, true)
  notes?: string = "";

  /**
   * the task's priority
   */

  @JsonProperty("priority", String, true)
  priority?: string = "";

  /**
   * the task's status
   */

  @JsonProperty("status", String, true)
  status?: string = "";

  /**
   * the task's type
   */

  @JsonProperty("type", String, true)
  type?: string = "";

  /**
   * the user-facing {@link Task#getId()}
   */

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
