/* tslint:disable */
/* eslint-disable */
import { RemoveSharedEntityRequestModel } from "./remove-shared-entity-request-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqRemoveSharedEntityRequestModel")
export class OApiReqRemoveSharedEntityRequestModel {
  @JsonProperty("data", RemoveSharedEntityRequestModel, true)
  data?: RemoveSharedEntityRequestModel = undefined;
}
