/* tslint:disable */
/* eslint-disable */
import { ViewDocDiffModel } from "./view-doc-diff-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullDocPairDiffViewModel")
export class FullDocPairDiffViewModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdByUid", String, true)
  createdByUid?: string = "";

  @JsonProperty("source", ViewDocDiffModel, true)
  source?: ViewDocDiffModel = undefined;

  @JsonProperty("target", ViewDocDiffModel, true)
  target?: ViewDocDiffModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
