import { InteleaseCommonModule } from "@@intelease/web/common";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { NgSelectModule } from "@ng-select/ng-select";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SelectMentionConfirmModalComponent } from "./select-mention-confirm-modal.component";

const NGX_BOOTSTRAP = [
  TooltipModule.forRoot(),
  PopoverModule.forRoot(),
  ModalModule.forRoot(),
];

@NgModule({
  imports: [
    CommonModule,
    InteleaseCommonModule,
    FormsModule,
    CommonDirectivesModule,
    NgSelectModule,
    ...NGX_BOOTSTRAP,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    TooltipModule,
  ],
  declarations: [SelectMentionConfirmModalComponent],
})
export class SelectMentionConfirmModalModule {}
