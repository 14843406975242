import { compact } from "lodash";
import { TableColumnsModel } from "@@intelease/web/intelease/models";

export function convertTableColumnsToServerField(
  fields: TableColumnsModel[]
): string[] {
  return compact(
    fields.map((field) => {
      const { prop, name, type } = field;
      if (type !== "CHECKBOX" && type !== "ACTION") {
        return [prop, name, type].join("::");
      }
    })
  );
}
