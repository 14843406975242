/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SearchAutoCompleteModel")
export class SearchAutoCompleteModel {
  @JsonProperty("fileAccessCategories", Any, true)
  fileAccessCategories?: Array<
    "ALL" | "MY_DRIVE" | "SHARED_WITH_ME" | "GOOGLE_DRIVE"
  > = [];

  @JsonProperty("searchTxt", String, true)
  searchTxt?: string = "";
}
