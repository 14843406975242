import { AuthService } from "@@intelease/app-services/auth/src";
import { UserInfoService } from "@@intelease/web/intelease/services";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "itls-left-menu-logo",
  templateUrl: "./left-menu-logo.component.html",
  styleUrls: ["./left-menu-logo.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftMenuLogoComponent {
  userEmail = UserInfoService.getUserEmail();
  userName = UserInfoService.getUserFullName();

  constructor(
    private readonly authService: AuthService,
    private router: Router
  ) {}

  public logout(): void {
    this.authService.logout();
  }

  public onNavigateToClick(key: "info" | "logout") {
    switch (key) {
      case "info":
        this.router.navigate([`/settings/profile`]);
        break;
      case "logout":
        this.authService.logout();
        break;
    }
  }
}
