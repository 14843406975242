/* tslint:disable */
/* eslint-disable */
import { LightNodeDtoModel } from "./light-node-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DeleteMultipleNodesRequestModel")
export class DeleteMultipleNodesRequestModel {
  @JsonProperty("allowQueueRecordSummaryDirectoryDeletion", Boolean, true)
  allowQueueRecordSummaryDirectoryDeletion?: boolean = undefined;

  @JsonProperty("deletedNodes", [LightNodeDtoModel], true)
  deletedNodes?: Array<LightNodeDtoModel> = [];
}
