<div class="menu" [ngClass]="{ expanded: expanded }">
  <div class="left-menu-logo">
    <button mat-icon-button (click)="toggleMenu()">
      <itls-icon svgIcon="icon-menu-white" [height]="16"></itls-icon>
    </button>
    <itls-left-menu-logo *ngIf="expanded"></itls-left-menu-logo>
  </div>

  <itls-left-menu-navigation-item
    [link]="(dashboards$ | async) ? '/home/dashboard' : '/dashboards'"
    tooltip="Home"
    routerLinkActive="active"
    [routerLink]="(dashboards$ | async) ? '/home/dashboard' : '/dashboards'"
    [expanded]="expanded"
    icon="icon-home"
  >
    Home
  </itls-left-menu-navigation-item>

  <!-- <itls-left-menu-navigation-item
        tooltip="Search"
        [expanded]="expanded"
        icon="icon-search-white"
    >
        Search
    </itls-left-menu-navigation-item> -->

  <!-- <itls-left-menu-navigation-item
        [expanded]="expanded"
        tooltip="Notifcations"
        icon="icon-notification-white"
    >
        Notifcations
    </itls-left-menu-navigation-item> -->

  <itls-left-menu-navigation-item
    link="/settings/profile"
    [expanded]="expanded"
    tooltip="Settings"
    icon="icon-setting-white"
  >
    Settings
  </itls-left-menu-navigation-item>

  <itls-left-menu-navigation-item
    *ngxPermissionsOnly="[AppAuthorities.READ_PROVISION_FORM]"
    link="/provision-forms/forms"
    [expanded]="expanded"
    tooltip="Forms"
    icon="icon-form-white"
  >
    Forms
  </itls-left-menu-navigation-item>

  <div class="border-divider"></div>

  <ng-container *ngxPermissionsOnly="[AppAuthorities.READ_DOC_SET]">
    <itls-left-menu-navigation-item
      *ngIf="!isQueueEnabled"
      link="/drive/all"
      [expanded]="expanded"
      icon="icon-dashboard-white"
      tooltip="All Records"
    >
      All Records
    </itls-left-menu-navigation-item>

    <itls-left-menu-navigation-item
      *ngIf="isQueueEnabled"
      link="/drive/documents"
      [expanded]="expanded"
      icon="icon-dashboard-white"
      tooltip="All Documents"
    >
      All Documents
    </itls-left-menu-navigation-item>

    <itls-left-menu-navigation-item
      link="/drive/owned"
      [expanded]="expanded"
      tooltip="My Drive"
      icon="icon-drive-white"
    >
      My Drive
    </itls-left-menu-navigation-item>

    <itls-left-menu-navigation-item
      link="/drive/google-drive"
      [expanded]="expanded"
      tooltip="Google Drive"
      icon="icon-google-drive"
      *ngIf="!!googleDriveInfoView?.syncFolderId"
    >
      Google Drive
    </itls-left-menu-navigation-item>

    <itls-left-menu-navigation-item
      link="/drive/shared"
      [expanded]="expanded"
      tooltip="Shared with Me"
      icon="icon-share-drive-white"
    >
      Shared with Me
    </itls-left-menu-navigation-item>

    <itls-left-menu-navigation-item
      link="/drive/deleted"
      [expanded]="expanded"
      tooltip="Trash"
      icon="icon-delete-white"
    >
      Trash
    </itls-left-menu-navigation-item>
  </ng-container>

  <div class="border-divider"></div>

  <itls-left-menu-navigation-item
    *ngxPermissionsOnly="[AppAuthorities.READ_RECORD]"
    link="/tasks/list"
    tooltip="Task"
    [expanded]="expanded"
    icon="icon-task-white"
  >
    Tasks
  </itls-left-menu-navigation-item>

  <ng-container *ngxPermissionsOnly="[AppAuthorities.READ_QUEUE]">
    <itls-left-menu-navigation-item
      *ngIf="isQueueEnabled"
      link="/queues/list"
      [expanded]="expanded"
      tooltip="Queues"
      icon="icon-Queue-white"
    >
      Queues
    </itls-left-menu-navigation-item>
  </ng-container>

  <!--    <itls-left-menu-navigation-item-->
  <!--        *ngxPermissionsOnly="[AppAuthorities.READ_REPORT]"-->
  <!--        link="/reports/list"-->
  <!--        tooltip="Reports"-->
  <!--        [expanded]="expanded"-->
  <!--        icon="icon-report-white"-->
  <!--    >-->
  <!--        Reports-->
  <!--    </itls-left-menu-navigation-item>-->

  <!--    <itls-left-menu-navigation-item-->
  <!--        *ngxPermissionsOnly="[AppAuthorities.READ_PROJECT]"-->
  <!--        link="/projects/list"-->
  <!--        tooltip="Projects"-->
  <!--        [expanded]="expanded"-->
  <!--        icon="icon-project"-->
  <!--    >-->
  <!--        Projects-->
  <!--    </itls-left-menu-navigation-item>-->
</div>

<div
  *ngIf="isQueueEnabled && canUploadDocSet()"
  class="upload"
  (click)="openUploadModal()"
>
  <button
    matTooltip="Upload for Queue"
    [matTooltipShowDelay]="500"
    mat-icon-button
  >
    <itls-icon svgIcon="icon-upload" [height]="14"></itls-icon>
  </button>
  <span class="text" *ngIf="expanded"> Upload </span>
</div>

<div *ngIf="!isQueueEnabled" class="upload" (click)="openUploadModal()">
  <button
    matTooltip="Upload Documents"
    [matTooltipShowDelay]="500"
    mat-icon-button
  >
    <itls-icon svgIcon="icon-upload" [height]="14"></itls-icon>
  </button>
  <span class="text" *ngIf="expanded"> Upload Documents </span>
</div>
