import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { ServerResponseModel } from "@@intelease/web/intelease/models";

@Injectable({
  providedIn: "root",
})
export class ShareBatchApiService {
  private static readonly SHARE_BATCH_URL_PART = "/shareBatch";

  constructor(private httpClient: HttpClient) {}

  /**
   * Send a POST request to share a set of objects with a set of accounts / users.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param objectUids the objects being shared
   * @param entityUids the set of entities with which the objects are being shared
   * @param allVersions whether to affect all versions of the object
   */
  sendBatchRequestNoResponse(
    apiVersion: string,
    baseUrl: string,
    objectUids: string[],
    entityUids: string[],
    allVersions: boolean
  ): void {
    this.sendBatchRequestNoView(
      apiVersion,
      baseUrl,
      objectUids,
      entityUids,
      allVersions
    ).subscribe((res) => {});
  }

  /**
   * Send a POST request to share a set of objects with a set of accounts / users.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param objectUids the objects being shared
   * @param entityUids the set of entities with which the objects are being shared
   * @param allVersions whether to affect all versions of the object
   * @returns response, not containing objects
   */
  sendBatchRequestNoView(
    apiVersion: string,
    baseUrl: string,
    objectUids: string[],
    entityUids: string[],
    allVersions: boolean
  ): Observable<ServerResponseModel> {
    const body = this.createBatchRequestBody(
      objectUids,
      entityUids,
      allVersions
    );
    return this.helpSendRequestNoView(body, apiVersion, baseUrl);
  }

  /**
   * Send a POST request to share all objects with a set of accounts / users.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param entityUids the set of entities with which the objects are being shared
   * @param allVersions whether to do the request for all versions of the entities
   * @returns response, not containing objects
   */
  sendAllRequestNoResponse(
    apiVersion: string,
    baseUrl: string,
    entityUids: string[],
    allVersions: boolean
  ): void {
    this.sendAllRequestNoView(
      apiVersion,
      baseUrl,
      entityUids,
      allVersions
    ).subscribe((res) => {});
  }

  /**
   * Send a POST request to share all objects with a set of accounts / users.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param entityUids the set of entities with which the objects are being shared
   * @param allVersions whether to do the request for all versions of the objects
   * @returns response, not containing objects
   */
  sendAllRequestNoView(
    apiVersion: string,
    baseUrl: string,
    entityUids: string[],
    allVersions: boolean
  ): Observable<ServerResponseModel> {
    const body = this.createAllRequestBody(entityUids, allVersions);
    return this.helpSendRequestNoView(body, apiVersion, baseUrl);
  }

  /*
   * Below are private helper methods
   */

  private helpSendRequestNoView(
    body: any,
    apiVersion: string,
    baseUrl: string
  ): Observable<ServerResponseModel> {
    return this.httpClient.post<ServerResponseModel>(
      apiVersion + ShareBatchApiService.SHARE_BATCH_URL_PART + baseUrl,
      body
    );
  }

  private createAllRequestBody(
    entityUids: string[],
    allVersions: boolean
  ): any {
    return {
      data: {
        scopeUids: entityUids,
        selectAll: true,
        affectAllVersions: allVersions,
      },
    };
  }

  private createBatchRequestBody(
    objectUids: string[],
    entityUids: string[],
    allVersions: boolean
  ): any {
    return {
      data: {
        objectUids: objectUids,
        scopeUids: entityUids,
        selectAll: false,
        affectAllVersions: allVersions,
      },
    };
  }
}
