import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  CancelUploadingLeaseFilesResponseModel,
  MyFile,
  UploadingBoxComponent,
} from "@@intelease/web/ui/src/lib/itls-new-upload";
import { Observable, Subject } from "rxjs";
import { ServerResponseModel } from "@intelease/models";
import { HttpClient } from "@angular/common/http";
import { UploadLeaseFilesResponseModel } from "@@intelease/web/ui/src/lib/itls-new-upload/models/upload-lease-files-response.model";
import { map } from "rxjs/operators";
import { RestClient } from "@@intelease/web/utils";
import {
  CommonModalService,
  UploadingBoxDialogService,
} from "@common/services";

export enum UploadDataStatus {
  UPLOADING = "UPLOADING",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
}

export interface UploadData {
  isFolder: boolean;
  isExpanded: boolean;
  folderUid?: string;
  folderName?: string;
  folderStatus?: UploadDataStatus;
  record?: UploadRecordData;
  records?: UploadRecordData[];
  parentFolderUid?: string;
  isAbleToCancelUpload: boolean;
}

export interface UploadRecordData {
  uid: string;
  name: string;
  status: UploadDataStatus;
  files: MyFile[];
  parentFolderUid?: string;
  isAbleToCancelUpload: boolean;
}

export interface RecordData {
  uid: string;
  name: string;
  files: MyFile[];
}

@Injectable({
  providedIn: "root",
})
export class UploadingBoxService {
  public static MODAL_ID = "9797f89b-405f-4532-b36f-ba244ea72de7";
  private static readonly API_VERSION_3 = "/v3";
  private static readonly UPLOAD_FILES_URL = "/uploadLeaseFiles";
  private static readonly CANCEL_UPLOADING_LEASE_FILES =
    "/cancelUploadingLeaseFiles";

  $onNewUpload: Subject<UploadData> = new Subject<UploadData>();

  constructor(
    private matDialog: MatDialog,
    private httpClient: HttpClient,
    private restClient: RestClient,
    private uploadingBoxDialogService: UploadingBoxDialogService
  ) {}

  cancelUploadingLeaseFiles(
    abstractUids: string[]
  ): Observable<CancelUploadingLeaseFilesResponseModel> {
    return this.restClient.sendPostRequest(
      UploadingBoxService.API_VERSION_3,
      UploadingBoxService.CANCEL_UPLOADING_LEASE_FILES,
      {
        data: {
          recordUids: abstractUids,
        },
      },
      CancelUploadingLeaseFilesResponseModel,
      {},
      (resp) => resp.data
    );
  }

  uploadLeaseFiles(
    abstractUid: string,
    files: MyFile[]
  ): Observable<UploadLeaseFilesResponseModel> {
    const formData = new FormData();
    for (const myFile of files) {
      formData.append(
        "files",
        myFile.file,
        encodeURIComponent(myFile.name + this.getExtension(myFile.file))
      );
    }
    formData.set("abstractUid", abstractUid);

    return this.httpClient
      .post<ServerResponseModel>(
        UploadingBoxService.API_VERSION_3 +
          UploadingBoxService.UPLOAD_FILES_URL,
        formData
      )
      .pipe(map((resp) => resp.data));
  }

  uploadFolder(
    parentFolderUid: string,
    uid: string,
    name: string,
    records: RecordData[]
  ) {
    this.upload({
      isFolder: true,
      isExpanded: false,
      folderUid: uid,
      folderName: name,
      folderStatus: UploadDataStatus.UPLOADING,
      parentFolderUid,
      isAbleToCancelUpload: false,
      records: records.map((record) => ({
        uid: record.uid,
        name: record.name,
        files: record.files,
        status: UploadDataStatus.UPLOADING,
        isAbleToCancelUpload: false,
      })),
    });
  }

  uploadRecord(parentFolderUid: string, record: RecordData) {
    this.upload({
      isFolder: false,
      isExpanded: false,
      parentFolderUid,
      isAbleToCancelUpload: false,
      record: {
        uid: record.uid,
        name: record.name,
        files: record.files,
        status: UploadDataStatus.UPLOADING,
        isAbleToCancelUpload: false,
      },
    });
  }

  private upload(uploadData: UploadData) {
    // if dialog is already opened
    if (this.getMatDialog()) {
      this.$onNewUpload.next(uploadData);
    } else {
      const matDialogRef = this.matDialog.open(UploadingBoxComponent, {
        maxHeight: "300px",
        panelClass: "uploading-box-panel",
        hasBackdrop: false,
        id: UploadingBoxService.MODAL_ID,
        data: uploadData,
        disableClose: true,
        position: {
          bottom: "30px",
          right: "90px",
        },
        autoFocus: false,
      });
      matDialogRef.afterOpened().subscribe((resp) => {
        const modalElement = document.getElementById(
          UploadingBoxService.MODAL_ID
        );
        /*
                    modalElement.parentElement.parentElement:
                       <div class="cdk-global-overlay-wrapper">...</div>
                    modalElement.parentElement:
                         <div id="cdk-overlay-1">...</div>
                 */
        modalElement.parentElement.parentElement.style["zIndex"] = "unset";
        this.uploadingBoxDialogService.uploadingBoxDialogOpened = true;
      });
      matDialogRef.afterClosed().subscribe((resp) => {
        this.uploadingBoxDialogService.uploadingBoxDialogOpened = false;
      });
    }
  }

  close() {
    return this.getMatDialog().close();
  }

  private getMatDialog(): MatDialogRef<UploadingBoxComponent> {
    return this.matDialog.getDialogById(UploadingBoxService.MODAL_ID);
  }

  private getExtension(file: File) {
    const lastDotIdx = file.name.lastIndexOf(".");
    let extension = "";
    if (lastDotIdx !== -1) {
      extension = file.name.substr(lastDotIdx);
    }
    return extension;
  }
}
