import { Component, Inject, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { FormSchemaLoaderService } from "@@intelease/web/intelease/services";
import { toggleFormSchemaMode } from "@@intelease/web/intelease/utils";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { isEmpty, isEqual } from "lodash";
import { TooltipModel } from ".";

@Component({
  selector: "il-form-modal-component",
  templateUrl: "./form-modal.component.html",
  styleUrls: ["./form-modal.component.scss"],
})
export class FormModalComponent implements OnInit {
  onClose: Subject<ModalResponseModel> = new Subject<ModalResponseModel>();
  onDismiss: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;
  formDescription = "";
  formSchema: any;
  model: any = {};
  oldModel: any;
  newModel: any;
  isLoadingEnabled = false;
  formKey: string;
  addTitle: "";
  editTitle: "";
  isFormValid = false;
  propertiesType: string[];
  buttonTooltip: string;
  saveButtonTooltip: TooltipModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<FormModalComponent>,
    // public bsModalRef: BsModalRef,
    private formSchemaLoaderService: FormSchemaLoaderService
  ) {}

  ngOnInit() {
    this.initModalData();
  }

  /**
   * initialize modal data
   */
  private initModalData() {
    this.mode = this.dialogData.mode;
    const { addTitle, editTitle, formKey, formSchema, model } =
      this.dialogData.payload;
    this.addTitle = addTitle;
    this.editTitle = editTitle;
    this.formDescription = (model?.formDescription as string)?.trim();
    this.setFormSchema(formKey, formSchema);
    if (model) {
      this.model = model;
      this.newModel = model;
      this.oldModel = isEmpty(model.description)
        ? {
            name: model.name,
          }
        : model;
    }
    if (this.dialogData.modalTooltip) {
      this.saveButtonTooltip = this.dialogData.modalTooltip;
      this.buttonTooltip = this.saveButtonTooltip.firstMessage;
      setTimeout(() => {
        isEmpty(model.description)
          ? this.checkIsFormValid({
              name: this.dialogData.payload.model.name,
            })
          : this.checkIsFormValid(this.dialogData.payload.model);
      }, 200);
    }
  }

  /**
   * set form schema
   * @param formKey dynamic form key
   * @param staticFormSchema static form schema definition
   */
  private setFormSchema(formKey: string, staticFormSchema: any) {
    if (!staticFormSchema) {
      this.getTaskFormSchema(formKey);
    } else {
      this.setStaticFormSchema(staticFormSchema);
    }
  }

  /**
   * set formSchema by static form schema
   * @param staticFormSchema
   */
  private setStaticFormSchema(staticFormSchema: any) {
    if (this.mode === ComponentModeEnum.VIEW) {
      this.formSchema = toggleFormSchemaMode(staticFormSchema, true);
    } else {
      this.formSchema = staticFormSchema;
    }
  }

  onModelChange(evt) {
    if (evt.value !== undefined) this.checkIsFormValid(evt.value);
  }

  onEnterUp(evt) {
    if (this.isFormValid && !(evt.target.tagName.toLowerCase() === "textarea"))
      switch (this.mode) {
        case "EDIT":
          this.onUpdateClick();
          break;
        case "VIEW":
          this.onEditClick();
          break;
        case "ADD":
          this.onSaveClick();
          break;
        default:
          break;
      }
  }

  private checkIsFormValid(value) {
    const { properties } = this.dialogData.payload.formSchema;
    this.propertiesType = Object.keys(properties).map((property) => {
      return property;
    });

    if (!isEmpty(value) && !isEqual(value, this.newModel)) {
      this.newModel = value;
    }
    this.propertiesType.map((propertyType) => {
      switch (propertyType) {
        case "numColumns":
          this.isFormValid =
            !isEmpty(value.numColumns) &&
            !isEqual(this.oldModel, value) &&
            !(parseInt(value.numColumns, 10) <= 0);
          if (this.saveButtonTooltip !== undefined) {
            if (this.mode === "EDIT" && isEmpty(value.numColumns))
              this.buttonTooltip = this.saveButtonTooltip.emptyField;
            if (this.mode === "EDIT" && !isEmpty(value.numColumns))
              this.buttonTooltip = this.saveButtonTooltip.notValidField;
          }
          break;

        case "name":
          this.isFormValid =
            !isEmpty(value.name) && !isEqual(this.oldModel, value);
          if (this.saveButtonTooltip !== undefined) {
            if (this.mode === "EDIT" && isEmpty(value.name))
              this.buttonTooltip = this.saveButtonTooltip.emptyField;
            if (this.mode === "EDIT" && !isEmpty(value.name))
              this.buttonTooltip = this.saveButtonTooltip.notValidField;
          }
          break;

        default:
          break;
      }
    });
  }

  onSaveClick() {
    this.modalRes.data = this.newModel;
    this.closeModal();
  }

  onEditClick() {
    this.mode = ComponentModeEnum.EDIT;
    this.formSchema = toggleFormSchemaMode(this.formSchema, false);
  }

  onUpdateClick() {
    this.modalRes.data = this.newModel;
    this.closeModal();
  }

  onCancelClick() {
    this.mode = ComponentModeEnum.VIEW;
    this.formSchema = toggleFormSchemaMode(this.formSchema, true);
  }

  onDismissModalClick() {
    // this.bsModalRef.hide();
    // this.onDismiss.next(this.modalRes);
    this.dialogRef.close({ ...this.modalRes, type: "DISMISS" });
  }

  private getTaskFormSchema(formKey: string) {
    this.formSchemaLoaderService
      .getFormSchemaByName(formKey)
      .subscribe((res) => {
        if (this.mode === ComponentModeEnum.VIEW) {
          this.formSchema = toggleFormSchemaMode(res, true);
        } else {
          this.formSchema = res;
        }
      });
  }

  private closeModal() {
    // this.bsModalRef.hide();
    this.modalRes.data = this.newModel;
    // this.onClose.next(this.modalRes);
    this.dialogRef.close({ ...this.modalRes, type: "CLOSE" });
  }
}
