/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ScoreThresholdConfigModel")
export class ScoreThresholdConfigModel {
  @JsonProperty("searchResultThresholdScore", Number, true)
  searchResultThresholdScore?: number = undefined;

  @JsonProperty("thresholdScore", Number, true)
  thresholdScore?: number = undefined;
}
