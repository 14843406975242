<div class="itls-filter-item">
  <div class="itls-filter-item-container">
    <mat-form-field style="margin-bottom: -18px">
      <mat-label>Field</mat-label>
      <input
        type="text"
        matInput
        [formControl]="filterFieldControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <cdk-virtual-scroll-viewport
          style="height: 200px; overflow-x: hidden"
          itemSize="25"
        >
          <div
            class="filter-item-fields-group"
            *cdkVirtualFor="let field of fields | async"
          >
            <span
              *ngIf="field.groupTitle"
              class="fields-group-label"
              [matTooltipShowDelay]="300"
              [matTooltip]="field.groupTitle"
            >
              {{ field.groupTitle }}
            </span>
            <mat-option
              [value]="field.uiName"
              (onSelectionChange)="
                hideTooltip(tooltip); onFilterFieldOptionSelected($event, field)
              "
              [matTooltipShowDelay]="300"
              #tooltip="matTooltip"
              [matTooltip]="field.uiName"
              [id]="field.uiName"
            >
              <div
                fxLayout
                fxLayoutAlign="space-between center"
                style="width: 100%"
              >
                <div style="margin-right: 8px; margin-top: -2px">
                  <itls-icon
                    [svgIcon]="$any(field.fieldType | translateIconType)"
                    [height]="12"
                  ></itls-icon>
                </div>
                <div
                  style="
                    margin-bottom: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 10px;
                    width: 100%;
                  "
                >
                  {{ field.uiName }}
                </div>
              </div>
            </mat-option>
          </div>
        </cdk-virtual-scroll-viewport>

        <!-- <div
                    class="filter-item-fields-group"
                    *ngFor="let group of filterFieldGroups | async"
                >
                    <span class="fields-group-label" [matTooltip]="group.title">
                        {{ group.title }}
                    </span>
                    <cdk-virtual-scroll-viewport
                        style="height: 200px"
                        itemSize="10"
                    >
                        <mat-option
                            *cdkVirtualFor="let field of group.fields"
                            [value]="field.uiName"
                            (onSelectionChange)="
                                onFilterFieldOptionSelected($event, field)
                            "
                            [matTooltip]="field.uiName"
                            [id]="field.uiName"
                        >
                            <div
                                fxLayout
                                fxLayoutAlign="space-between center"
                                style="width: 100%"
                            >
                                <div
                                    style="margin-right: 8px; margin-top: -2px"
                                >
                                    <itls-icon
                                        [svgIcon]="
                                            $any(
                                                field.fieldType
                                                    | translateIconType
                                            )
                                        "
                                        [height]="12"
                                    ></itls-icon>
                                </div>
                                <div
                                    style="
                                        margin-bottom: 0;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        font-size: 10px;
                                        width: 100%;
                                    "
                                >
                                    {{ field.uiName }}
                                </div>
                            </div>
                        </mat-option>
                    </cdk-virtual-scroll-viewport>
                </div> -->
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Format</mat-label>
      <mat-select [(value)]="filter.field.fieldType">
        <mat-option
          *ngFor="let allowedType of filter?.field?.allowedTypes"
          [value]="allowedType.value"
        >
          {{ allowedType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Operator</mat-label>
      <mat-select
        [(value)]="filter.relation"
        (selectionChange)="onFilterRelationChange($event)"
      >
        <ng-container *ngIf="filter && filter?.field?.fieldType">
          <mat-option
            *ngFor="
              let filterOperator of filterOperators[filter?.field?.fieldType]
            "
            [value]="filterOperator.value"
          >
            {{ filterOperator.label || filterOperator.value }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <il-filter-widget-chooser
      [hidden]="
        filter?.relation === 'EXISTS' || filter?.relation === 'NOT_EXISTS'
      "
      [type]="filter?.field?.fieldType"
      [field]="filter?.field"
      [relation]="filter.relation"
      [filter]="filter"
      [filterFields]="filterFields"
      [model]="filter?.value"
      (widgetModelChange)="onFieldModelChange($event)"
    >
    </il-filter-widget-chooser>
    <div
      *ngIf="filter?.relation === 'EXISTS' || filter?.relation === 'NOT_EXISTS'"
    ></div>
    <button
      mat-icon-button
      [matMenuTriggerFor]="moreMenu"
      class="more-options"
      style="background-color: transparent !important"
    >
      <itls-icon
        svgIcon="icon-more"
        style="margin-top: 0 !important"
      ></itls-icon>
    </button>
  </div>
</div>

<mat-menu #moreMenu="matMenu" class="itls-filter-menu">
  <button mat-menu-item (click)="onRemoveClick()">
    <span>Remove</span>
  </button>
</mat-menu>
