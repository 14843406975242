import { EditApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EditProvisionFormService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly PROVISION_FORMS_URL = "/provisionForms";
  private static readonly PROVISIONS_URL = "/provisions";
  private static readonly NAME_URL = "/name";

  constructor(private editApiService: EditApiService) {}

  updateAbstractProvisionFormNoView(
    abstractUid: string,
    provisionFormUid: string,
    newProvisionUid: string,
    chosenProvisionGroupUid: string,
    preProvisionLocationUid: string,
    postProvisionLocationUid: string
  ): Observable<ServerResponseModel> {
    const data = {
      provisionUid: newProvisionUid,
      provisionGroupUid: chosenProvisionGroupUid,
      preProvisionLocationUid: preProvisionLocationUid,
      postProvisionLocationUid: postProvisionLocationUid,
    };
    return this.editApiService.sendRequestNoView(
      EditProvisionFormService.API_VERSION_1,
      `${EditProvisionFormService.FINAL_DOC_SETS_URL}/${abstractUid}${EditProvisionFormService.PROVISION_FORMS_URL}`,
      provisionFormUid,
      data,
      false
    );
  }

  addProvisionToAbstractNoView(
    abstractUid: string,
    newProvisionUid: string,
    chosenProvisionGroupUid: string,
    preProvisionLocationUid: string,
    postProvisionLocationUid: string
  ): Observable<ServerResponseModel> {
    const data = {
      provisionUid: newProvisionUid,
      provisionGroupUid: chosenProvisionGroupUid,
      preProvisionLocationUid: preProvisionLocationUid,
      postProvisionLocationUid: postProvisionLocationUid,
    };
    return this.editApiService.sendRequestNoView(
      EditProvisionFormService.API_VERSION_1,
      `/finalDocSets/${abstractUid}`,
      "newProvision",
      data,
      false
    );
  }

  saveModifiedProvisionFormNoView(
    provFormData: any,
    provisionFormUid: string
  ): Observable<ServerResponseModel> {
    return this.editApiService.sendRequestNoView(
      EditProvisionFormService.API_VERSION_1,
      EditProvisionFormService.PROVISION_FORMS_URL,
      provisionFormUid,
      provFormData,
      true,
      EditProvisionFormService.PROVISIONS_URL
    );
  }

  renameFormByIdNoView(
    formUid: string,
    newName: string,
    description: string
  ): Observable<ServerResponseModel> {
    const data = {
      newName: newName,
      description,
    };
    return this.editApiService.sendRequestNoView(
      EditProvisionFormService.API_VERSION_1,
      EditProvisionFormService.PROVISION_FORMS_URL,
      formUid,
      data,
      false,
      EditProvisionFormService.NAME_URL
    );
  }

  renameFormByIdMinimalForm(
    formUid: string,
    newName: string,
    description: string
  ): Observable<ServerResponseModel> {
    const data = {
      newName: newName,
      description,
    };
    return this.editApiService.sendRequestMinimalView(
      EditProvisionFormService.API_VERSION_1,
      EditProvisionFormService.PROVISION_FORMS_URL,
      formUid,
      data,
      false,
      EditProvisionFormService.NAME_URL
    );
  }
}
