import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  Injector,
  Input,
  OnInit,
  Optional,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { cloneDeep, first as _first, isEmpty, isEqual, uniqBy } from "lodash";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ComponentStateHooksInterface } from "@@intelease/app-models/common";
import { AbstractReviewFacade } from "@@intelease/app-state/abstract-review";
import { RelatedDocModel } from "@@intelease/web/common/models";
import {
  FinalAbstractModel,
  PROVISION_BOX_TABS_KEY,
  ProvisionBoxInitTypesEnum,
} from "@@intelease/app-models/abstract-review";
import { ProvisionBoxHelperService } from "@@intelease/web/abstraction-page/src/lib/services/provision-box-helper.service";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { CommonModalService } from "@@intelease/web/common/services";
import { ProvisionComponent } from "@@intelease/web/abstraction-page/src/lib/modals/provision-box/provision";
import {
  AbstractTextHighlightsService,
  WebAbstractionPageService,
} from "@@intelease/web/abstraction-page/src/lib/services";
import { ProvisionBoxActionTypeEnum } from "@@intelease/web/abstraction-page/src/lib/enums";
import { DomHelper } from "@@intelease/web/utils";
import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { BaseEntityPermissionComponent } from "@@intelease/web/common/components";
import { IResizeEvent } from "angular2-draggable/lib/models/resize-event";
import { ISize } from "angular2-draggable/lib/models/size";
import { ProfileUserModel } from "@@intelease/web/common/models/user";
import { map } from "rxjs/operators";
import { FetchBatchUserService } from "@@intelease/web/intelease/services/models";
import { ShortenPipe } from "ngx-pipes";
import { DOCUMENT } from "@angular/common";
import { PROVISIONS_DATA_CONST } from "@common/enums/provision-data.const";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-provision-box-modal",
  templateUrl: "./provision-box-modal.component.html",
  styleUrls: ["./provision-box-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProvisionBoxModalComponent
  extends BaseEntityPermissionComponent
  implements OnInit, ComponentStateHooksInterface
{
  @ViewChildren("provisionComponentRef")
  provisionComponentsRefs: QueryList<ProvisionComponent>;
  @Input() isInNewBrowserTabMode: boolean;
  selectedProvision = undefined;
  isMinimized: boolean;
  modalId: string;
  fullProvisionsGroup = undefined;
  selectedProvisionGroup = undefined;
  isLoading = true;
  @Input() documentsShortNameMap: { [documentUid: string]: any } = {};
  provisionsGroupWorkflow = {
    currentIndex: 0,
  };
  selectedAbstractUid: string;
  @Input() selectedDocument: RelatedDocModel;
  abstractDocument: FinalAbstractModel = new FinalAbstractModel();
  provisionBoxInitTypes: ProvisionBoxInitTypesEnum;
  provisionBoxInitTypesEnum = ProvisionBoxInitTypesEnum;
  selectedMentionUid: string;
  selectedProvisionUid: string;
  private dialogRef = null;
  readerOverlayElemRef;
  provisionBoxSize: ISize = {
    height: 720,
    width: 500,
  };
  canComment: boolean;
  editable: boolean;
  accountUsers: ProfileUserModel[];
  selectedProvisionBoxTab: PROVISION_BOX_TABS_KEY;
  lastDragTopPosition: string;
  isProvisionBoxResized = false;
  isSelectedDocumentFullyLoaded = false;
  destroyRef = inject(DestroyRef);

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public modalData: any,
    private injector: Injector,
    private dialog: MatDialog,
    public readonly abstractReviewFacade: AbstractReviewFacade,
    private provisionBoxHelperService: ProvisionBoxHelperService,
    private commonModalService: CommonModalService,
    private webAbstractionPageService: WebAbstractionPageService,
    private fetchBatchUserService: FetchBatchUserService,
    private shortenPipe: ShortenPipe,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.initActions();
  }

  ngOnInit(): void {
    this.initListeners();
    this.fetchBatchUserService
      .getAllUsersInTheCurrentUserAccount(
        ProfileUserModel.view,
        ProfileUserModel
      )
      .pipe(map((res) => res.items))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((accountUsers) => {
        this.accountUsers = accountUsers.map((item) => {
          item.mentionText = this.shortenPipe.transform(
            `${item.name} (${item.email})`,
            25,
            "..."
          );
          return item;
        });
      });
  }

  // eslint-disable-next-line
  initActions(): void {}

  initListeners(): void {
    this.abstractReviewFacade.getDocumentsShortNameMap$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res && !this.isInNewBrowserTabMode) {
          this.documentsShortNameMap = res;
        }
      });

    ProvisionBoxHelperService.bringToFrontByModalId
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((modalId) => {
        if (this.dialogRef) {
          if (modalId === this.modalId) {
            this.dialogRef.addPanelClass("top");
          } else {
            this.dialogRef.removePanelClass("top");
          }
        }
      });

    this.abstractReviewFacade.getAbstractFullProvisionGroups$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          this.fullProvisionsGroup = res;
        }
      });

    this.abstractReviewFacade.selectedAbstractDetail$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          this.selectedAbstractUid = res.uid;
        }
      });

    this.abstractReviewFacade.getSelectedDocument$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          this.selectedDocument = res;
          this.abstractDocument = res;
        }
      });

    this.abstractReviewFacade.isSelectedDocumentFullyLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.isSelectedDocumentFullyLoaded = res;
      });

    WebAbstractionPageService.detachCurrentProvisionBoxInNewWindow$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.onOpenInNewWindow();
      });

    ProvisionBoxHelperService.reinitializeExistingProvisionBox
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((payload) => {
        const {
          provisionGroupUid,
          documentsShortNameMap,
          isDualWindowInitialMessage,
        } = payload;
        this.modalData = payload;
        const {
          modalId,
          givenEntityPermissions,
          provisionBoxInitTypes,
          selectedDocument,
          provisionUid,
          selectedProvisionBoxTab,
        } = this.modalData;
        this.selectedProvisionBoxTab = selectedProvisionBoxTab;
        this.modalId = modalId;
        this.provisionBoxInitTypes = provisionBoxInitTypes;
        this.givenEntityPermissions = givenEntityPermissions;
        this.canComment = this.emptyGivenOrHasPermissions(this.AUTH.COMMENT);
        this.editable = this.emptyGivenOrHasPermissions(this.AUTH.EDIT);
        this.hasProvisionTempMention(() => {
          this.toggleLoading(true);
          setTimeout(() => {
            this.setProvisionsGroupWorkflowCurrentIndex(provisionGroupUid);
            this.initProvisionBox();
            if (!this.isInNewBrowserTabMode) {
              DomHelper.scrollToElementSelector(
                `#${this.selectedProvision.provisionInfo.uid}`,
                "if-needed"
              );
            } else {
              DomHelper.scrollToElementSelector(
                `#${provisionUid}`,
                "if-needed"
              );
              if (
                this.provisionBoxInitTypes ===
                ProvisionBoxInitTypesEnum.NEW_WINDOW
              ) {
                this.documentsShortNameMap = documentsShortNameMap;
                this.selectedDocument = selectedDocument;
                this.abstractDocument = selectedDocument;
              }
            }
            this.toggleLoading(false);
          }, 100);
        }, isDualWindowInitialMessage);
      });

    ProvisionBoxHelperService.removeTempHighlightedMention$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.selectedProvision) {
          const tempMention = this.webAbstractionPageService.getTempMention(
            this.selectedProvision
          );
          if (tempMention) {
            this.selectedProvision.multiplePdfProvision.options =
              this.selectedProvision.multiplePdfProvision.options.filter(
                (item) => !item.isTempHighlight
              );
            this.selectedProvisionGroup.provisions.forEach(
              (provision, index) => {
                if (
                  provision.provisionInfo.uid ===
                  this.selectedProvision.provisionInfo.uid
                ) {
                  this.selectedProvisionGroup.provisions[
                    index
                  ].multiplePdfProvision.options =
                    this.selectedProvision.multiplePdfProvision.options;
                }
              }
            );
          }
        }
      });

    ProvisionBoxHelperService.tableMentionTouched$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        const { provisionUid, isTouched } = res;
        this.selectedProvisionGroup.provisions.forEach((provision) => {
          if (provision.provisionInfo.uid === provisionUid) {
            provision.multiplePdfProvision.options[0].isTouched = isTouched;
          }
        });
      });

    ProvisionBoxHelperService.reinitializeSelectedSelectedProvision
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        const selectedProvisionIndex =
          this.selectedProvisionGroup.provisions.findIndex(
            (provision) =>
              this.selectedProvision.provisionInfo.uid ===
              provision.provisionInfo.uid
          );
        if (selectedProvisionIndex !== -1) {
          this.selectedProvisionGroup.provisions[selectedProvisionIndex] =
            res.selectedProvision;
        }
        this.selectedProvision = res.selectedProvision;
        this.cdr.detectChanges();
      });
  }

  private hasProvisionTempMention(cb, isDualWindowInitialMessage: boolean) {
    if (this.provisionBoxInitTypes === ProvisionBoxInitTypesEnum.NEW_WINDOW) {
      cb();
      return;
    }
    const isUnsavedTableMention = this.isUnsavedTableMention();
    if (this.selectedProvision && !isDualWindowInitialMessage) {
      const tempMention = this.webAbstractionPageService.getTempMention(
        this.selectedProvision
      );
      const dialogInstance = this.dialog.getDialogById(
        "SingleUnsavedMentionsAlert"
      );
      if (tempMention) {
        const { uid: mentionUid } = tempMention;
        if (dialogInstance) {
          dialogInstance.close({
            type: ModalsResponseTypeEnum.DISMISS,
          });
          dialogInstance.afterClosed().subscribe(() => {
            this.handleTempMention(tempMention, mentionUid, cb);
          });
        } else {
          this.handleTempMention(tempMention, mentionUid, cb);
        }
      } else if (isUnsavedTableMention) {
        this.showUnsavedMentionsAlert()
          .afterClosed()
          .subscribe((modalResponse) => {
            const { type } = modalResponse;
            if (type === ModalsResponseTypeEnum.CLOSE) {
              isUnsavedTableMention.isTouched = false;
              cb();
            }
          });
      } else {
        cb();
      }
    } else if (isUnsavedTableMention) {
      this.showUnsavedMentionsAlert()
        .afterClosed()
        .subscribe((modalResponse) => {
          const { type } = modalResponse;
          if (type === ModalsResponseTypeEnum.CLOSE) {
            isUnsavedTableMention.isTouched = false;
            cb();
          }
        });
    } else {
      cb();
    }
  }

  private handleTempMention(tempMention, mentionUid, cb) {
    this.showSingleUnsavedMentionsAlert(tempMention)
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse) {
          const { type } = modalResponse;
          if (type === ModalsResponseTypeEnum.CLOSE) {
            // this.abstractReviewFacade.removeTemporaryMention({
            //     provisionUid:
            //         this.selectedProvision.provisionInfo.uid,
            //     mentionUid,
            // })
            const mentionIndex =
              this.selectedProvision.multiplePdfProvision.options.findIndex(
                (item) => item.uid === mentionUid
              );
            ProvisionBoxHelperService.removeMention$.next({
              provisionUid: this.selectedProvision.provisionInfo.uid,
              mentionIndex,
              mentionUid,
              isTempMention: true,
              isReloadDisabled: false,
              cb,
            });
            // this.selectedProvision.multiplePdfProvision.options.splice(
            //     mentionIndex,
            //     1,
            // )
            // AbstractTextHighlightsService.removeTempMentionTextHighlightCreatedFromReader(
            //     tempMention,
            // )
            // cb()
          } else {
            if (this.modalData?.mentionData?.uid !== mentionUid) {
              AbstractTextHighlightsService.removeTempMentionTextHighlightCreatedFromReader(
                this.modalData.mentionData
              );
            }
            this.toggleLoading(false);
          }
        } else {
          if (this.modalData?.mentionData?.uid !== mentionUid) {
            AbstractTextHighlightsService.removeTempMentionTextHighlightCreatedFromReader(
              this.modalData.mentionData
            );
          }
          this.toggleLoading(false);
        }
      });
  }

  private initProvisionBox() {
    this.resetInitState();
    switch (this.provisionBoxInitTypes) {
      case ProvisionBoxInitTypesEnum.FIRST_PROVISION_GROUP: {
        this.initWithFirstProvisionGroup();
        break;
      }
      case ProvisionBoxInitTypesEnum.PROVISION_GROUP: {
        const { provisionGroupUid } = this.modalData;
        this.initWithProvisionGroup(provisionGroupUid);
        break;
      }
      case ProvisionBoxInitTypesEnum.PROVISION: {
        const { provisionGroupUid, provisionUid } = this.modalData;
        this.initWithProvision(provisionGroupUid, provisionUid);
        break;
      }
      case ProvisionBoxInitTypesEnum.MENTION: {
        const { provisionGroupUid, provisionUid, mentionUid } = this.modalData;
        this.initWithMention(provisionGroupUid, provisionUid, mentionUid);
        break;
      }
      case ProvisionBoxInitTypesEnum.NEW_MENTION: {
        const { provisionGroupUid, provisionUid } = this.modalData;
        this.initWithNewMention(provisionGroupUid, provisionUid);
        break;
      }
      case ProvisionBoxInitTypesEnum.NEW_WINDOW: {
        const { provisionGroupUid, provisionUid } = this.modalData;
        this.initWithProvision(provisionGroupUid, provisionUid);
        break;
      }
      default:
        this.initWithFirstProvisionGroup();
    }
    this.toggleLoading(false);
    this.cdr.detectChanges();
  }

  private initWithFirstProvisionGroup() {
    this.selectedProvisionGroup = cloneDeep(
      _first(this.fullProvisionsGroup.provisionGroupList)
    );
    this.selectedProvision = _first(this.selectedProvisionGroup.provisions);
    this.abstractReviewFacade.setSelectedProvision(
      this.selectedProvision.provisionInfo
    );
  }

  private initWithProvisionGroup(provisionGroupUid) {
    this.selectedProvisionGroup = cloneDeep(
      this.fullProvisionsGroup.provisionGroupList.find(
        (provisionGroup) => provisionGroup.uid === provisionGroupUid
      )
    );
    this.selectedProvision = _first(this.selectedProvisionGroup.provisions);
    this.abstractReviewFacade.setSelectedProvision(
      this.selectedProvision.provisionInfo
    );
  }

  private initWithProvision(provisionGroupUid: string, provisionUid: string) {
    this.selectedProvisionGroup = cloneDeep(
      this.fullProvisionsGroup.provisionGroupList.find(
        (provisionGroup) => provisionGroup.uid === provisionGroupUid
      )
    );
    this.selectedProvision = cloneDeep(
      this.selectedProvisionGroup.provisions.find(
        (provision) => provision.provisionInfo.uid === provisionUid
      )
    );
    this.selectedProvisionUid = provisionUid;
    this.selectedProvision = cloneDeep(this.selectedProvision);
    this.selectedProvision.multiplePdfProvision.options = cloneDeep(
      uniqBy(this.selectedProvision.multiplePdfProvision.options, "uid")
    );
    this.abstractReviewFacade.setSelectedProvision(
      this.selectedProvision.provisionInfo
    );
    this.cdr.detectChanges();
  }

  private initWithMention(
    provisionGroupUid: string,
    provisionUid: string,
    mentionUid: string
  ) {
    this.initWithProvision(provisionGroupUid, provisionUid);
    this.selectedMentionUid = mentionUid;
  }

  private initWithNewMention(provisionGroupUid: string, provisionUid: string) {
    const _payload = {
      provisionUid,
      mention: this.modalData.mentionData,
    };
    this.abstractReviewFacade.addTemporaryMention(_payload);
    this.initWithProvision(provisionGroupUid, provisionUid);
  }

  toggleLoading(isShow: boolean) {
    this.isLoading = isShow;
  }

  onBringToFront() {
    this.provisionBoxHelperService.bringToFront(this.modalId);
  }

  onSelectProvision(provision) {
    const isUnsavedTableMention = this.isUnsavedTableMention();
    if (
      provision &&
      this.selectedProvision &&
      provision?.provisionInfo?.uid !==
        this.selectedProvision?.provisionInfo?.uid
    ) {
      const tempMention = this.webAbstractionPageService.getTempMention(
        this.selectedProvision
      );
      const dialogInstance = this.dialog.getDialogById(
        "SingleUnsavedMentionsAlert"
      );
      if (tempMention && !dialogInstance) {
        const { uid: mentionUid } = tempMention;
        this.handleTempMention(tempMention, mentionUid, () => {
          this.changeSelectedProvision(provision);
        });
      } else if (isUnsavedTableMention) {
        this.showUnsavedMentionsAlert()
          .afterClosed()
          .subscribe((modalResponse) => {
            const { type } = modalResponse;
            if (type === ModalsResponseTypeEnum.CLOSE) {
              isUnsavedTableMention.isTouched = false;
              this.changeSelectedProvision(provision);
            }
          });
      } else {
        this.changeSelectedProvision(provision);
      }
    } else if (isUnsavedTableMention) {
      this.showUnsavedMentionsAlert()
        .afterClosed()
        .subscribe((modalResponse) => {
          const { type } = modalResponse;
          if (type === ModalsResponseTypeEnum.CLOSE) {
            isUnsavedTableMention.isTouched = false;
            this.changeSelectedProvision(provision);
          }
        });
    } else {
      this.changeSelectedProvision(provision);
    }
  }

  private changeSelectedProvision(provision) {
    this.selectedProvision = provision;
    this.abstractReviewFacade.setSelectedProvision(
      this.selectedProvision.provisionInfo
    );
    this.abstractReviewFacade.cleanAbstractReviewState({
      selectedProvisionComments: [],
    });
    this.abstractReviewFacade.loadProvisionComments(
      this.selectedAbstractUid,
      this.selectedProvision.provisionInfo.uid
    );
    this.cdr.detectChanges();
  }

  onToggleMinimized() {
    this.isMinimized = !this.isMinimized;
    setTimeout(() => {
      this.restrictFromViewportTop();
    }, 500);
  }

  private setProvisionsGroupWorkflowCurrentIndex(provisionGroupUid: string) {
    this.provisionsGroupWorkflow.currentIndex =
      this.fullProvisionsGroup.provisionGroupList.findIndex(
        (provisionGroup) => provisionGroup.uid === provisionGroupUid
      );
    this.toggleLoading(true);
    this.changeSelectedProvisionGroupAndProvision(
      this.provisionsGroupWorkflow.currentIndex
    );
  }

  onPreviousProvisionsGroupClick() {
    AbstractTextHighlightsService.onTextHighlightActionFromProvisionBox.next({
      type: ProvisionBoxActionTypeEnum.REMOVE_DOCUMENT_MENTION_TEXT_HIGHLIGHT_FROM_VIEWER,
    });
    if (this.provisionsGroupWorkflow.currentIndex > 0) {
      const isUnsavedTableMention = this.isUnsavedTableMention();
      if (this.isUnsavedMentionExists()) {
        this.showUnsavedMentionsAlert()
          .afterClosed()
          .subscribe((modalResponse) => {
            const { type } = modalResponse;
            if (type === ModalsResponseTypeEnum.CLOSE) {
              this.cleanProvisionsUnsavedMentionsLocalState();
              this.gotoPreviousProvisionGroup();
              this.cdr.detectChanges();
            }
          });
      } else if (isUnsavedTableMention) {
        this.showUnsavedMentionsAlert()
          .afterClosed()
          .subscribe((modalResponse) => {
            const { type } = modalResponse;
            if (type === ModalsResponseTypeEnum.CLOSE) {
              isUnsavedTableMention.isTouched = false;
              this.gotoPreviousProvisionGroup();
              this.cdr.detectChanges();
            }
          });
      } else {
        this.gotoPreviousProvisionGroup();
      }
    }
  }

  private gotoPreviousProvisionGroup() {
    this.toggleLoading(true);
    this.provisionsGroupWorkflow.currentIndex -= 1;
    this.changeSelectedProvisionGroupAndProvision(
      this.provisionsGroupWorkflow.currentIndex
    );
  }

  onNextProvisionsGroupClick() {
    AbstractTextHighlightsService.onTextHighlightActionFromProvisionBox.next({
      type: ProvisionBoxActionTypeEnum.REMOVE_DOCUMENT_MENTION_TEXT_HIGHLIGHT_FROM_VIEWER,
    });
    if (
      this.fullProvisionsGroup.provisionGroupList.length >
      this.provisionsGroupWorkflow.currentIndex
    ) {
      const isUnsavedTableMention = this.isUnsavedTableMention();
      if (this.isUnsavedMentionExists()) {
        this.showUnsavedMentionsAlert()
          .afterClosed()
          .subscribe((modalResponse) => {
            const { type } = modalResponse;
            if (type === ModalsResponseTypeEnum.CLOSE) {
              this.cleanProvisionsUnsavedMentionsLocalState();
              this.gotoNextProvisionGroup();
              this.cdr.detectChanges();
            }
          });
      } else if (isUnsavedTableMention) {
        this.showUnsavedMentionsAlert()
          .afterClosed()
          .subscribe((modalResponse) => {
            const { type } = modalResponse;
            if (type === ModalsResponseTypeEnum.CLOSE) {
              isUnsavedTableMention.isTouched = false;
              this.gotoNextProvisionGroup();
              this.cdr.detectChanges();
            }
          });
      } else {
        this.gotoNextProvisionGroup();
      }
    }
  }

  private gotoNextProvisionGroup() {
    this.toggleLoading(true);
    this.provisionsGroupWorkflow.currentIndex += 1;
    this.changeSelectedProvisionGroupAndProvision(
      this.provisionsGroupWorkflow.currentIndex
    );
  }

  private changeSelectedProvisionGroupAndProvision(
    provisionGroupIndex: number
  ) {
    this.selectedProvisionGroup =
      this.fullProvisionsGroup.provisionGroupList[provisionGroupIndex];
    this.selectedProvision = _first(this.selectedProvisionGroup.provisions);
    this.toggleLoading(false);
  }

  private reloadProvisionData() {
    const payload = {
      abstractUid: this.selectedAbstractUid,
      provisionUid: this.selectedProvision.provisionInfo.uid,
    };
    this.abstractReviewFacade.loadProvisionFullDetail(payload);
  }

  private isUnsavedTableMention() {
    if (this.selectedProvision) {
      if (
        this.selectedProvision.provisionType ===
        PROVISIONS_DATA_CONST.TABLE.name
      ) {
        const selectedProvision = this.selectedProvisionGroup.provisions.find(
          (provision) =>
            provision.provisionInfo.uid ===
            this.selectedProvision.provisionInfo.uid
        );
        const hasTouchedMention =
          selectedProvision.multiplePdfProvision.options.find(
            (item) => item.isTouched
          );
        return hasTouchedMention;
      }
    }
  }

  onCloseProvisionBox(cb?: () => void) {
    AbstractTextHighlightsService.onTextHighlightActionFromProvisionBox.next({
      type: ProvisionBoxActionTypeEnum.REMOVE_DOCUMENT_MENTION_TEXT_HIGHLIGHT_FROM_VIEWER,
    });
    const isUnsavedTableMention = this.isUnsavedTableMention();
    if (this.isUnsavedMentionExists()) {
      this.showUnsavedMentionsAlert()
        .afterClosed()
        .subscribe((modalResponse) => {
          const { type } = modalResponse;
          if (type === ModalsResponseTypeEnum.CLOSE) {
            this.cleanProvisionsUnsavedMentionsLocalState();
            this.closeProvisionBox();
            if (cb) {
              cb();
            }
          }
        });
    } else if (isUnsavedTableMention) {
      this.showUnsavedMentionsAlert()
        .afterClosed()
        .subscribe((modalResponse) => {
          const { type } = modalResponse;
          if (type === ModalsResponseTypeEnum.CLOSE) {
            isUnsavedTableMention.isTouched = false;
            this.closeProvisionBox();
            if (cb) {
              cb();
            }
          }
        });
    } else {
      this.closeProvisionBox();
      if (cb) {
        cb();
      }
    }
  }

  private showUnsavedMentionsAlert() {
    const modalData = new ModalInputModel();
    modalData.payload = {
      customMessage: true,
      title: "Alert",
      message: "You have unsaved changes. Are you sure you want to leave?",
      btnTitle: "No",
      closeBtnTitle: "Yes",
      closeBtnClass: "btn-success",
    };
    return this.commonModalService.openConfirmPermissionModal(modalData);
  }

  private showSingleUnsavedMentionsAlert(mention) {
    const { name } = mention;
    const modalData = new ModalInputModel();
    modalData.payload = {
      customMessage: true,
      title: "Alert",
      message: `Are you sure you want to create this provision mention? <br/> You have unsaved changes for the provision: "<strong>${name}</strong>". They will be discarded.`,
      btnTitle: "No",
      closeBtnTitle: "Yes",
      closeBtnClass: "btn-success",
    };
    return this.commonModalService.openConfirmPermissionModal(
      modalData,
      "SingleUnsavedMentionsAlert"
    );
  }

  private closeProvisionBox() {
    this.reloadProvisionData();
    this.provisionBoxHelperService.closeByDialogId(this.modalId);
  }

  private isUnsavedMentionExists(): boolean {
    let hasUnsavedMention: boolean;
    if (this.selectedProvision && this.selectedProvision.multiplePdfProvision) {
      this.selectedProvision.multiplePdfProvision.options.some((mention) => {
        if (!hasUnsavedMention) {
          hasUnsavedMention =
            mention.isTempHighlight ||
            (mention.source === "TEMP_NEW_TAB" &&
              !isEmpty(mention.model) &&
              !isEqual(mention.model, mention.value));
        }
        return hasUnsavedMention;
      });
    }
    return hasUnsavedMention;
  }

  private cleanProvisionsUnsavedMentionsLocalState() {
    this.toggleLoading(true);
    this.selectedProvision.multiplePdfProvision.options.forEach(
      (mention, mentionIndex) => {
        if (mention.isTempHighlight) {
          ProvisionBoxHelperService.removeMention$.next({
            provisionUid: this.selectedProvision.provisionInfo.uid,
            mentionIndex,
            mentionUid: mention.uid,
            isTempMention: true,
            isReloadDisabled: true,
          });
          this.abstractReviewFacade.removeTemporaryMention({
            provisionUid: this.selectedProvision.provisionInfo.uid,
            mentionUid: mention.uid,
          });
          this.selectedProvision.multiplePdfProvision.options.splice(
            mentionIndex,
            1
          );
          AbstractTextHighlightsService.removeTempMentionTextHighlightCreatedFromReader(
            mention
          );
        }
      }
    );
    this.toggleLoading(false);
  }

  private resetInitState() {
    this.selectedProvisionGroup = undefined;
    this.selectedProvision = undefined;
    this.selectedMentionUid = undefined;
    this.abstractReviewFacade.setSelectedProvision(this.selectedProvision);
  }

  onOpenInNewWindow() {
    this.onCloseProvisionBox(() => {
      const data = {
        selectedDocumentUid: this.selectedDocument.uid,
        documentsShortNameMap: this.documentsShortNameMap,
        index: 0,
        selectedProvisionUid: this.selectedProvision.provisionInfo.uid,
        pdfViewerCurrentPage: 0,
        modalId: this.modalId,
        selectedProvisionGroupUid: this.selectedProvisionGroup.uid,
        provisionBoxInitTypes: ProvisionBoxInitTypesEnum.NEW_WINDOW,
        givenEntityPermissions: this.givenEntityPermissions,
        isSelectedDocumentFullyLoaded: this.isSelectedDocumentFullyLoaded,
        // pdfViewerCurrentPage: this.getCurrentPage()
      };
      const queryParamData = JSON.stringify(data);
      const url = `${window.location.origin}/abstract-review/${
        this.selectedAbstractUid
      }/provisions/${this.selectedProvision.provisionInfo.uid}?data=${btoa(
        queryParamData
      )}`;
      this.webAbstractionPageService.openProvisionBoxInNewWindow$.next(url);
    });
  }

  onDragStarted() {
    this.togglePdfReaderOverlay(true);
  }

  onDragMoved() {
    this.restrictFromViewportTop();
  }

  onResized() {
    if (!this.isProvisionBoxResized && !this.isInNewBrowserTabMode) {
      this.isProvisionBoxResized = true;
      setTimeout(() => {
        const bodyHeight = this.document
          .getElementsByTagName("body")
          .item(0).offsetHeight;
        const provisionBoxHeight =
          this.el.nativeElement.parentNode.offsetHeight;
        const topPosition = (bodyHeight - provisionBoxHeight) / 2;
        this.lastDragTopPosition = `-${Math.abs(topPosition)}px`;
        this.restrictFromViewportTop();
        this.isProvisionBoxResized = false;
      }, 150);
    }
  }

  private restrictFromViewportTop() {
    if (this.el.nativeElement) {
      const boundingClientRect = this.el.nativeElement.getBoundingClientRect();
      const rects =
        this.el.nativeElement.parentNode.parentNode.style.transform.split(
          "translate3d("
        )[1];
      if (rects) {
        const x = rects.split(",")[0];
        if (boundingClientRect.top < 0 && this.lastDragTopPosition) {
          this.el.nativeElement.parentNode.parentNode.style.transform = `translate3d(${x}, ${this.lastDragTopPosition}, 0px)`;
          // this.el.nativeElement.parentNode.parentNode.style.transform = this.el.nativeElement.parentNode.parentNode.style.transform.replace(/(,\s[-.0-9]+px,\s)/g, `, ${this.lastDragTopPosition}, `)
        }
      }
    }
  }

  onDragReleased() {
    this.togglePdfReaderOverlay(false);
    this.restrictFromViewportTop();
  }

  onResizeStart() {
    this.togglePdfReaderOverlay(true);
  }

  onResizing(evt: IResizeEvent) {
    const { size } = evt;
    this.fixProvisionBoxSize(size);
    this.restrictFromViewportTop();
  }

  onResizeEnd(evt: IResizeEvent) {
    const { size } = evt;
    this.fixProvisionBoxSize(size);
    this.togglePdfReaderOverlay(false);
    this.restrictFromViewportTop();
  }

  private fixProvisionBoxSize(size: ISize) {
    const newWidth = size.width > 500 ? size.width : 500;
    const newHeight = size.height > 44 ? size.height : 44;
    this.provisionBoxSize = {
      width: newWidth,
      height: newHeight,
    };
  }

  private togglePdfReaderOverlay(show: boolean) {
    if (!this.readerOverlayElemRef) {
      this.readerOverlayElemRef = document.getElementById("pdf-reader-overlay");
    }
    this.readerOverlayElemRef.style.display = show ? "block" : "none";
  }

  onMentionRemoved(evt: { mentionUid }) {
    const { mentionUid } = evt;
    this.selectedProvision.multiplePdfProvision.options.splice(
      this.selectedProvision.multiplePdfProvision.options.findIndex(
        (item) => item.uid === mentionUid
      ),
      1
    );
  }
}
