import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("DocumentModel")
export class DocumentModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("suiteVersionIdPortfolio", String, true)
  portfolio = "";

  @JsonProperty("lastModifiedDate", String, true)
  lastModifiedDate = "";

  @JsonProperty("status", String, true)
  status = "";

  @JsonProperty("suiteVersionIdProperty", String, true)
  property = "";

  @JsonProperty("suiteVersionIdSuite", String, true)
  suite = "";

  @JsonProperty("suiteVersionIdVersion", String, true)
  version = "1";

  @JsonProperty("abstractFilename", String, true)
  abstractFilename = "";

  selected = false;
}
