/* tslint:disable */
/* eslint-disable */
import { SnippetModel } from "./snippet-model";
import { ViewModel } from "./view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullValPdfProvisionViewModel")
export class FullValPdfProvisionViewModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("dirtyText", String, true)
  dirtyText?: string = "";

  /**
   * the user-facing unique id of the document containing this provision mention
   */

  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  /**
   * all the formats' values for this provision mention
   */

  @JsonProperty("formatValues", Any, true)
  formatValues?: {
    [key: string]: ViewModel;
  } = {};

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("indexInText", Number, true)
  indexInText?: number = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the notes for this provision mention
   */

  @JsonProperty("notes", String, true)
  notes?: string = "";

  /**
   * the 1-based page number containing this provision mention
   */

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  /**
   * the unique id of each highlight for this provision mention
   */

  @JsonProperty("pdfHighlightIds", [String], true)
  pdfHighlightIds?: Array<string> = [];

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";

  /**
   * the unique id of each other provision mention related to this one
   */

  @JsonProperty("relatedOptionUids", [String], true)
  relatedOptionUids?: Array<string> = [];

  /**
   * the review status of this provision mention
   */

  @JsonProperty("reviewStatus", String, true)
  reviewStatus?: string = "";

  /**
   * the section header preceding the value for this provision mention
   */

  @JsonProperty("sectionHeader", String, true)
  sectionHeader?: string = "";

  /**
   * the user-facing unique id of the section header preceding the value for this provision mention
   */

  @JsonProperty("sectionHeadingUid", String, true)
  sectionHeadingUid?: string = "";

  /**
   * whether this mention is selected
   */

  @JsonProperty("selected", Boolean, true)
  selected?: boolean = undefined;

  /**
   * the snippets for this provision mention
   */

  @JsonProperty("snippets", [SnippetModel], true)
  snippets?: Array<SnippetModel> = [];

  /**
   * the source of this provision mention
   */

  @JsonProperty("source", String, true)
  source?: string = "";

  @JsonProperty("surroundingSentence", String, true)
  surroundingSentence?: string = "";

  @JsonProperty("textSurroundingOriginalProvision", String, true)
  textSurroundingOriginalProvision?: string = "";

  /**
   * the format of the value for this provision mention
   */

  @JsonProperty("type", String, true)
  type?: string = "";

  /**
   * the name of this provision mention chosen by the user
   */

  @JsonProperty("uiProvisionName", String, true)
  uiProvisionName?: string = "";

  /**
   * the user-facing unique id of this provision mention
   */

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("value", ViewModel, true)
  value?: ViewModel = undefined;
}
