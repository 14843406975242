/* tslint:disable */
/* eslint-disable */
import { UploadDocSetDataReqDtoModel } from "./upload-doc-set-data-req-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UploadDocSetGroupDataReqDtoModel")
export class UploadDocSetGroupDataReqDtoModel {
  @JsonProperty("directoryUid", String, true)
  directoryUid?: string = "";

  @JsonProperty("documentSets", [UploadDocSetDataReqDtoModel], true)
  documentSets?: Array<UploadDocSetDataReqDtoModel> = [];

  @JsonProperty("uploadingFolder", Boolean, true)
  uploadingFolder?: boolean = undefined;
}
