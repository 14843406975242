/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";

/**
 * the request to edit a record's document
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocEditRequestApiDtoModel")
export class DocEditRequestApiDtoModel {
  @JsonProperty("docDate", SimpleOpenApiDateModel, true)
  docDate?: SimpleOpenApiDateModel = undefined;

  /**
   * the user-specified document notes (null means removed)
   */

  @JsonProperty("docNotes", String, true)
  docNotes?: string = "";

  /**
   * the user-specified filename (empty means removed)
   */

  @JsonProperty("filename", String, true)
  filename?: string = "";
}
