import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LeftMenuComponent } from "./left-menu.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";
import { RouterModule } from "@angular/router";
import { LeftMenuLogoComponent } from "./left-menu-logo/left-menu-logo.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDividerModule } from "@angular/material/divider";
import { LeftMenuNavigationItemComponent } from "./left-menu-navigation-item/left-menu-navigation-item.component";
import { NgxPermissionsModule } from "ngx-permissions";
import { QueueModalModule } from "@@intelease/web/queues/src/lib/modals/queue-modal.module";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    ItlsCustomIconModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatDividerModule,
    NgxPermissionsModule,
    QueueModalModule,
  ],
  declarations: [
    LeftMenuComponent,
    LeftMenuLogoComponent,
    LeftMenuNavigationItemComponent,
  ],
  exports: [LeftMenuComponent],
})
export class LeftMenuModule {}
