export enum SortDirectionEnum {
  DESC = "DESC",
  ASC = "ASC",
  NONE = "ACTIVE",
}

export enum SortDirectionLowcaseEnum {
  DESC = "desc",
  ASC = "asc",
  NONE = "active",
}
