/* tslint:disable */
/* eslint-disable */
import { NodeSearchResultDtoModel } from "./node-search-result-dto-model";
import { PaginationDataModel } from "./pagination-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiMultiEntityContainerResponseNodeSearchResultDtoModel")
export class OApiMultiEntityContainerResponseNodeSearchResultDtoModel {
  @JsonProperty("items", [NodeSearchResultDtoModel], true)
  items?: Array<NodeSearchResultDtoModel> = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
