/* tslint:disable */
/* eslint-disable */
import { FilterInterfaceModel } from "./filter-interface-model";
import { NamedSearchFieldModel } from "./named-search-field-model";
import { SearchEntityItemModel } from "./search-entity-item-model";
import { TableModel } from "./table-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("TableFilterModel")
export class TableFilterModel {}
