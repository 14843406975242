/* tslint:disable */
/* eslint-disable */
import { FileInfoUploadDtoModel } from "./file-info-upload-dto-model";
import { UploadUserActionsModel } from "./upload-user-actions-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocumentSetUploadDtoModel")
export class DocumentSetUploadDtoModel {
  @JsonProperty("directoryPath", String, true)
  directoryPath?: string = "";

  @JsonProperty("fileInfos", [FileInfoUploadDtoModel], true)
  fileInfos?: Array<FileInfoUploadDtoModel> = [];

  @JsonProperty("fileNames", [String], true)
  fileNames?: Array<string> = [];

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("uploadDocEvents", UploadUserActionsModel, true)
  uploadDocEvents?: UploadUserActionsModel = undefined;
}
