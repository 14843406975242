import { JsonObject, JsonProperty } from "json2typescript";
import { FullNodeModel } from "@@intelease/web/common/models/drive/full-node.model";

@JsonObject("NodeNavigationModel")
export class NodeNavigationModel {
  @JsonProperty("navigations", [FullNodeModel])
  navigations: FullNodeModel[] = [];

  @JsonProperty("category", String)
  category: "OWNED" | "SHARED" | "DELETED" | "ALL" | undefined = undefined;
}
