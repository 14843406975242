import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { Observable } from "rxjs";

import { DeleteBatchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DeleteBatchAbstractService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(private deleteBatchApiService: DeleteBatchApiService) {}

  deleteAbstractsNoView(
    abstractUids: string[],
    allVersions: boolean
  ): Observable<ServerResponseModel> {
    return this.deleteBatchApiService.sendBatchRequestNoView(
      DeleteBatchAbstractService.API_VERSION_1,
      DeleteBatchAbstractService.FINAL_DOC_SETS_URL,
      abstractUids,
      allVersions
    );
  }

  deleteAllAbstractsNoView(): Observable<ServerResponseModel> {
    return this.deleteBatchApiService.sendAllRequestNoView(
      DeleteBatchAbstractService.API_VERSION_1,
      DeleteBatchAbstractService.FINAL_DOC_SETS_URL
    );
  }
}
