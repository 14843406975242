import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { InteleaseNotificationService } from "@@intelease/web/intelease/services";
import { finalize, tap } from "rxjs/operators";
import { DriveService } from "@@intelease/api-models/adex-api-model-src";
import { DriveFacade } from "@@intelease/app-state/drive/src";

@Component({
  selector: "il-add-new-folder",
  templateUrl: "./add-folder.component.html",
  styleUrls: ["./add-folder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewFolderComponent {
  name: string;
  parentFolderUid: string;
  isCreatingNewFolder = false;
  isValidateFields = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private dialogRef: MatDialogRef<AddNewFolderComponent>,
    private driveService: DriveService,
    private driveFacade: DriveFacade,
    private inteleaseNotificationService: InteleaseNotificationService,
    private cdr: ChangeDetectorRef
  ) {
    this.parentFolderUid = modalData?.parentFolderUid;
  }

  onSaveFolderClicked() {
    if (this.areValidInputs()) {
      this.isCreatingNewFolder = true;
      this.driveService
        .create4({
          body: {
            data: {
              name: this.name,
              parentUid: this.parentFolderUid,
            },
          },
        })
        .pipe(
          tap((resp) => {
            this.driveFacade.newFolderCreated$.next({
              node: resp.data as any,
              parentDirectoryUid: this.parentFolderUid,
              refreshDirectories: true,
            });
          })
        )
        .pipe(
          finalize(() => {
            this.isCreatingNewFolder = false;
            this.cdr.detectChanges();
          })
        )
        .subscribe(() => {
          this.dialogRef.close({ name: this.name });
        });
    }
  }

  private areValidInputs() {
    if (!this.name || !this.name.length) {
      this.inteleaseNotificationService.openSnackBar(
        "Please enter name for new folder"
      );
      return false;
    }
    return true;
  }
}
