import { JsonObject, JsonProperty } from "json2typescript";
import { BaseUserModel } from "@@intelease/web/common/models/user/base-user.model";

@JsonObject("ProfileUserModel")
export class ProfileUserModel implements BaseUserModel {
  static view = "profile";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("firstName", String)
  firstName = "";

  @JsonProperty("lastName", String)
  lastName = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("email", String)
  email = "";

  @JsonProperty("flags", [String])
  flags: string[] = [];

  @JsonProperty("accountUid", String)
  accountUid = "";

  @JsonProperty("accountRole", String, true)
  accountRole: "ITLS_ADMIN" | "ADMIN" | "OWNER" | "USER" | undefined =
    undefined;

  @JsonProperty("lbxRoles", [String])
  lbxRoles: (
    | "ADMIN"
    | "FORMS_ADMIN"
    | "ANALYST"
    | "REVIEWER"
    | "DATA_SCIENTIST"
  )[] = [];

  @JsonProperty("accountRoleName", String, true)
  accountRoleName = "";

  @JsonProperty("accountRoleRank", Number, true)
  accountRoleRank = -1;

  @JsonProperty("phoneNumber", String)
  phoneNumber = "";

  @JsonProperty("profileImageFileRegistered", Boolean)
  profileImageFileRegistered = false;

  // TODO(apoorv): This should also include a large version of the user's profile photo.

  mentionText = "";
}
