/* tslint:disable */
/* eslint-disable */
import { CheckPermissionItemDtoModel } from "./check-permission-item-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CheckPermissionResponseDtoModel")
export class CheckPermissionResponseDtoModel {
  @JsonProperty("accessibleToAll", Boolean, true)
  accessibleToAll?: boolean = undefined;

  @JsonProperty("items", [CheckPermissionItemDtoModel], true)
  items?: Array<CheckPermissionItemDtoModel> = [];
}
