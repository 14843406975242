/* tslint:disable */
/* eslint-disable */
import { FormFieldModel } from "./form-field-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OptionsCalculatorDataResponseDtoModel")
export class OptionsCalculatorDataResponseDtoModel {
  @JsonProperty("formFields", [FormFieldModel], true)
  formFields?: Array<FormFieldModel> = [];

  @JsonProperty("optionTypeToConditionalFields", Any, true)
  optionTypeToConditionalFields?: {
    [key: string]: Array<FormFieldModel>;
  } = {};

  @JsonProperty("optionTypeToSurroundingText", Any, true)
  optionTypeToSurroundingText?: {
    [key: string]: string;
  } = {};
}
