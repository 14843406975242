/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateProvisionV1ApiDtoModel")
export class CreateProvisionV1ApiDtoModel {
  @JsonProperty("abstractUid", String, true)
  abstractUid?: string = "";

  @JsonProperty("allowedTypes", Any, true)
  allowedTypes?: Array<
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN"
  > = [];

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("docSetCategory", String, true)
  docSetCategory?: string = "";

  @JsonProperty("entityScope", Any, true)
  entityScope?: "GLOBAL" | "ACCOUNT" | "USER" | "ABSTRACT" = undefined;

  /**
   * the type of datapoint this field is
   */

  @JsonProperty("fieldType", Any, true)
  fieldType?: "DEFAULT" | "MANUAL" = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the format of a structured value
   */

  @JsonProperty("type", Any, true)
  type?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;

  @JsonProperty("units", Any, true)
  units?: Array<
    | "SQUARE_FEET"
    | "SQUARE_METERS"
    | "PING"
    | "TSUBO"
    | "ACRES"
    | "HECTARES"
    | "SQUARE_KILOMETERS"
    | "SQUARE_MILES"
    | "CENTIMETERS"
    | "INCHES"
    | "FEET"
    | "YARDS"
    | "METERS"
    | "KILOMETERS"
    | "MILES"
    | "PERCENT"
    | "MONEY"
    | "SECONDS"
    | "MINUTES"
    | "HOURS"
    | "DAYS"
    | "WEEKS"
    | "MONTHS"
    | "YEARS"
    | "METERS_PER_SECOND"
    | "METERS_PER_SECOND_PER_SECOND"
    | "GRAMS"
    | "CUBIC_CENTIMETERS"
    | "GRAMS_PER_CUBIC_CENTIMETER"
    | "VOLTS"
    | "AMPERES"
    | "PASCALS"
    | "INCHES_WATER"
    | "POUNDS_PER_SQUARE_INCH"
    | "ATMOSPHERES"
    | "KELVIN"
    | "CELSIUS"
    | "FAHRENHEIT"
    | "NONE"
  > = [];

  @JsonProperty("values", [String], true)
  values?: Array<string> = [];
}
