/* tslint:disable */
/* eslint-disable */
import { AssigneeProfileDtoModel } from "./assignee-profile-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("IssueLightDtoModel")
export class IssueLightDtoModel {
  @JsonProperty("assignees", [AssigneeProfileDtoModel], true)
  assignees?: Array<AssigneeProfileDtoModel> = [];

  @JsonProperty("attachmentsCount", Number, true)
  attachmentsCount?: number = undefined;

  @JsonProperty("commentsCount", Number, true)
  commentsCount?: number = undefined;

  @JsonProperty("finalAbstractUid", String, true)
  finalAbstractUid?: string = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("ownerName", String, true)
  ownerName?: string = "";

  @JsonProperty("title", String, true)
  title?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
