/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EditReviewStatusApiV2DtoModel")
export class EditReviewStatusApiV2DtoModel {
  @JsonProperty("mentionReviewStatus", Any, true)
  mentionReviewStatus?:
    | "REVIEWED"
    | "NEED_REVIEW"
    | "NEED_REVIEW_TOUCHED"
    | "DEFAULT"
    | "COMPLETED_BUT_NEED_REVIEW" = undefined;

  @JsonProperty("mentionUid", String, true)
  mentionUid?: string = "";

  @JsonProperty("provisionReviewStatus", Any, true)
  provisionReviewStatus?:
    | "REVIEWED"
    | "NEED_REVIEW"
    | "NEED_REVIEW_TOUCHED"
    | "DEFAULT"
    | "COMPLETED_BUT_NEED_REVIEW" = undefined;
}
