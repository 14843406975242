/* tslint:disable */
/* eslint-disable */
import { UserDtoModel } from "./user-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("IngestDocSetIntoQueueResponseDtoModel")
export class IngestDocSetIntoQueueResponseDtoModel {
  @JsonProperty("docSetName", String, true)
  docSetName?: string = "";

  @JsonProperty("docSetUid", String, true)
  docSetUid?: string = "";

  @JsonProperty("documentCount", Number, true)
  documentCount?: number = undefined;

  @JsonProperty("ingestionFailed", Boolean, true)
  ingestionFailed?: boolean = undefined;

  @JsonProperty("ingestionFailureReason", String, true)
  ingestionFailureReason?: string = "";

  @JsonProperty("owner", UserDtoModel, true)
  owner?: UserDtoModel = undefined;

  @JsonProperty("queueName", String, true)
  queueName?: string = "";

  /**
   * the stage of the queue
   */

  @JsonProperty("queueStage", Any, true)
  queueStage?:
    | "PROCESSING"
    | "READY"
    | "IN_REVIEW"
    | "NEED_QA_REVIEW"
    | "IN_QA_REVIEW"
    | "COMPLETED"
    | "FAILED" = undefined;

  @JsonProperty("queueUid", String, true)
  queueUid?: string = "";

  @JsonProperty("recordDescription", String, true)
  recordDescription?: string = "";

  @JsonProperty("recordName", String, true)
  recordName?: string = "";

  @JsonProperty("recordUid", String, true)
  recordUid?: string = "";
}
