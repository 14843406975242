export const PROVISIONS_FORMS_NEW = {
  address: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      streetAddress: {
        type: "string",
        widget: "string",
        class: "streetAddress-formField",
        placeholder: "",
        readOnly: false,
        description: "Street Address",
      },
      suite: {
        readOnly: false,
        class: "suite-formField",
        type: "string",
        widget: "string",
        placeholder: "",
        description: "Street Address 2",
      },
      city: {
        type: "string",
        widget: "string",
        class: "city-formField",
        placeholder: "",
        readOnly: false,
        description: "City",
      },
      postalCode: {
        readOnly: false,
        type: "string",
        class: "postalCode-formField",
        widget: "string",
        placeholder: "",
        description: "Postal Code",
      },
      state: {
        type: "string",
        widget: "multi-select-dropdown",
        class: "state-formField",
        placeholder: "",
        readOnly: false,
        description: "State/Province",
        items: [],
        options: {
          bindLabel: "title",
          bindValue: "",
          notFoundText: "No items found",
          placeholder: "",
          searchable: true,
          loading: false,
          multiple: false,
          closeOnSelect: true,
          appendToBody: true,
        },
      },
      country: {
        type: "string",
        widget: "multi-select-dropdown",
        placeholder: "",
        class: "country-formField",
        readOnly: false,
        description: "Country",
        items: [],
        options: {
          bindLabel: "title",
          bindValue: "",
          notFoundText: "No items found",
          placeholder: "",
          searchable: true,
          loading: false,
          multiple: false,
          closeOnSelect: true,
          appendToBody: true,
        },
      },
    },
    buttons: [],
    required: [],
  },
  common_noun: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        readOnly: false,
        widget: "string",
        class: "commonNoun-formField",
        placeholder: "",
        description: "",
        cols: "20",
        rows: "2",
      },
    },
    buttons: [],
    required: [],
  },
  date: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        readOnly: false,
        class: "date-value-formField",
        widget: "date-picker",
        placeholder: "Choose Date",
        description: "",
      },
    },
    buttons: [],
  },
  double: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        class: "double-value-formField",
        widget: "string",
        readOnly: false,
        mask: "NUMBER_WITH_DECIMAL",
        placeholder: "",
        description: "",
      },
      unit: {
        type: "string",
        widget: "multi-select-dropdown",
        placeholder: "",
        class: "unit-formField",
        readOnly: false,
        description: "",
        items: [],
        options: {
          bindLabel: "title",
          bindValue: "value",
          notFoundText: "No items found",
          placeholder: "",
          searchable: true,
          loading: false,
          multiple: false,
          closeOnSelect: true,
          appendToBody: true,
        },
      },
    },
    buttons: [],
  },
  email: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      localPart: {
        type: "string",
        class: "localPart-formField",
        widget: "string",
        placeholder: "",
        readOnly: false,
        description: "",
      },
    },
    buttons: [],
  },
  failed_parsed: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        readOnly: false,
        widget: "string",
        placeholder: "",
        class: "failed-parsed-formField",
        description: "",
        cols: "20",
        rows: "4",
      },
    },
    buttons: [],
  },
  local_date: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        readOnly: false,
        class: "local-date-value-formField",
        widget: "date-picker",
        placeholder: "Choose Date",
        description: "",
      },
    },
    buttons: [],
  },
  money: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        class: "money-value-formField",
        widget: "string",
        placeholder: "",
        mask: "NUMBER_WITH_DECIMAL",
        readOnly: false,
        description: "",
      },
      currency: {
        type: "string",
        widget: "multi-select-dropdown",
        placeholder: "",
        class: "currency-formField",
        readOnly: false,
        description: "",
        items: [],
        options: {
          bindLabel: "title",
          bindValue: "value",
          notFoundText: "No items found",
          placeholder: "",
          searchable: true,
          loading: false,
          multiple: false,
          closeOnSelect: true,
          appendToBody: true,
        },
      },
    },
    buttons: [],
  },
  multiple_cat: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        widget: "multi-select-dropdown",
        placeholder: "",
        readOnly: false,
        description: "",
        items: [],
        options: {
          bindLabel: "title",
          bindValue: "",
          notFoundText: "No items found",
          placeholder: "",
          searchable: true,
          loading: false,
          multiple: true,
          closeOnSelect: false,
        },
      },
    },
    buttons: [],
    required: [],
  },
  number: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        class: "double-value-formField",
        widget: "string",
        readOnly: false,
        mask: "NUMBER_WITH_DECIMAL",
        placeholder: "",
        description: "",
      },
    },
    buttons: [],
  },
  phone: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      phoneNumber: {
        type: "string",
        widget: "string",
        readOnly: false,
        placeholder: "",
        description: "",
      },
    },
    buttons: [],
  },
  proper_noun: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        readOnly: false,
        widget: "string",
        class: "properNoun-formField",
        placeholder: "",
        description: "",
        cols: "20",
        rows: "2",
      },
    },
    buttons: [],
  },
  single_cat: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        widget: "multi-select-dropdown",
        class: "single-cat-value-formField",
        placeholder: "",
        readOnly: false,
        description: "",
        items: [],
        options: {
          bindLabel: "title",
          bindValue: "",
          notFoundText: "No items found",
          placeholder: "",
          searchable: true,
          loading: false,
          multiple: false,
          closeOnSelect: true,
        },
      },
    },
    buttons: [],
    required: [],
  },
  string: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        readOnly: false,
        widget: "string",
        placeholder: "",
        description: "",
      },
    },
    buttons: [],
    required: [],
  },
  table: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      value: {
        type: "string",
        widget: "textarea",
        placeholder: "",
        description: "",
      },
    },
    buttons: [],
  },
  year: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      year: {
        type: "integer",
        widget: {
          id: "integer",
        },
        class: "baseYear-formField",
        minimum: 1800,
        maximum: 2300,
        placeholder: "",
        readOnly: false,
        description: "",
      },
    },
    buttons: [],
    required: [],
  },
  nested_group: {
    $schema: "http://json-schema.org/draft-04/hyper-schema#",
    type: "object",
    properties: {
      id: {
        type: "integer",
        widget: {
          id: "integer",
        },
        class: "nested-group-provision-subfield",
        placeholder: "",
        readOnly: false,
        description: "Index",
      },
    },
    buttons: [],
  },
};
