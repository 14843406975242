/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RecordLockInfoDtoModel")
export class RecordLockInfoDtoModel {
  @JsonProperty("lockedAt", DateTimeConvertService, true)
  lockedAt?: Date | undefined = undefined;

  @JsonProperty("lockedByUserFullName", String, true)
  lockedByUserFullName?: string = "";

  @JsonProperty("lockedByUserUid", String, true)
  lockedByUserUid?: string = "";
}
