import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("PriceIndexResponseModel")
export class PriceIndexResponseModel {
  /*
            {
                "uid": "MDliYWQ0ZTY2M2UxYjVkMDhhNzVmY2U1",
                "seriesId": "CUURS35CSA0",
                "version": null,
                "description": "All items in Atlanta-Sandy Springs-Roswell, GA, all urban consumers, not seasonally adjusted",
                "countries": [
                    "UNITED_STATES"
                ],
                "type": "CPI",
                "source": "BLS",
                "name": "..."
            }
   */

  @JsonProperty("uid", String)
  uid: string;

  @JsonProperty("name", String)
  name: string;

  @JsonProperty("description", String)
  description: string;
}
