import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("SettingsAccountModel")
export class SettingsAccountModel {
  static view = "settings";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("remainingCredits", Number)
  remainingCredits = 0;
}
