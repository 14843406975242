/* tslint:disable */
/* eslint-disable */
import { PdfMentionAreaHighlightModel } from "./pdf-mention-area-highlight-model";
import { PdfMentionTextHighlightModel } from "./pdf-mention-text-highlight-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PolarPagePdfViewModel")
export class PolarPagePdfViewModel {
  @JsonProperty("areaHighlights", [PdfMentionAreaHighlightModel], true)
  areaHighlights?: Array<PdfMentionAreaHighlightModel> = [];

  @JsonProperty("textHighlights", [PdfMentionTextHighlightModel], true)
  textHighlights?: Array<PdfMentionTextHighlightModel> = [];
}
