import { JsonObject, JsonProperty } from "json2typescript";
import { SnippetModel } from "./snippet.model";

@JsonObject("FullProvisionValueModel")
export class FullProvisionValueModel {
  static view = "full";

  @JsonProperty("uid", String, true)
  uid = "";

  @JsonProperty("provisionUid", String)
  provisionUid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("source", String)
  source = "";

  @JsonProperty("type", String)
  type = "";

  @JsonProperty("value", String)
  value = "";

  @JsonProperty("textValue", String, true)
  textValue = "";

  @JsonProperty("notes", String)
  notes = "";

  @JsonProperty("sectionHeader", String)
  sectionHeader = "";

  @JsonProperty("page", Number)
  page = -1;

  @JsonProperty("pdfHighlightIds", [String])
  pdfHighlightId: string[] = [];

  @JsonProperty("docAbstractUid", String)
  docAbstractUid = "";

  @JsonProperty("selected", Boolean)
  selected = false;

  @JsonProperty("relatedOptionUids", [String], true)
  relatedOptionUids?: string[] = [];

  @JsonProperty("snippets", [SnippetModel], true)
  snippets?: SnippetModel[] = [];
}
