<div class="form-group">
  <label>
    {{ inputField.fieldLabel }}
    <i
      *ngIf="toolTip"
      class="fa fa-info-circle ml-1 info-icon"
      container="modal-container"
      [tooltip]="toolTip"
    ></i>
    <span *ngIf="!inputField.optional"> * </span>
  </label>

  <input
    *ngIf="inputField.fieldFormat === FieldFormat.TEXT"
    [required]="!inputField.optional"
    [(ngModel)]="inputField.fieldValue"
    (click)="handleClick()"
    type="text"
    class="form-control"
  />

  <textarea
    *ngIf="inputField.fieldFormat === FieldFormat.TEXT_AREA"
    [required]="!inputField.optional"
    [(ngModel)]="inputField.fieldValue"
    (click)="handleClick()"
    rows="5"
    class="form-control"
  ></textarea>

  <input
    *ngIf="inputField.fieldFormat === FieldFormat.NUMBER"
    [required]="!inputField.optional"
    [(ngModel)]="inputField.fieldValue"
    (click)="handleClick()"
    type="number"
    min="1"
    class="form-control"
  />

  <ng-select
    *ngIf="inputField.fieldFormat === FieldFormat.SELECT"
    [required]="!inputField.optional"
    [items]="inputField.fieldOptions"
    [closeOnSelect]="true"
    [clearable]="true"
    bindLabel="label"
    bindValue="value"
    appendTo="body"
    (click)="handleClick()"
    [(ngModel)]="inputField.fieldValue"
  >
  </ng-select>

  <input
    *ngIf="inputField.fieldFormat === FieldFormat.DATE"
    [required]="!inputField.optional"
    class="form-control"
    [readOnly]="openDatePickerFieldKey === inputField.fieldKey"
    [value]="inputField.fieldValue || ''"
    (click)="handleClick()"
  />

  <il-input-dropdown-dropdown-component
    *ngIf="inputField.fieldFormat === FieldFormat.RELATIVE_TIME_TO_EVENT"
    [model]="inputField.rawValue"
    [items1]="['Days', 'Weeks', 'Months', 'Years']"
    [items2]="['Before', 'After']"
    (click)="handleClick()"
  >
  </il-input-dropdown-dropdown-component>

  <itls-input-dropdown-component
    *ngIf="inputField.fieldFormat === FieldFormat.TIME_PERIOD"
    [model]="inputField.rawValue"
    [items]="['Days', 'Weeks', 'Months', 'Years']"
    [inputWidth]="'40%'"
    [selectWidth]="'60%'"
    [min]="inputField?.fieldKey === 'offset' ? 0 : 1"
    (click)="handleClick()"
  >
  </itls-input-dropdown-component>

  <itls-icon
    *ngIf="canClear()"
    class="clear-icon"
    svgIcon="icon-cancel"
    [height]="10"
    (click)="onReset($event)"
  ></itls-icon>
</div>
