import { Component, DestroyRef, inject, Inject, OnInit } from "@angular/core";
import { NgxTranslateService } from "@@intelease/web/common/services/ngx-translate.service";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  NavigationError,
  NavigationCancel,
  RouterEvent,
  Event,
} from "@angular/router";
import {
  AppServiceWorkerService,
  InteleasePermissionsService,
  ItlsIconRegistryService,
  PageTitleTagsService,
  ThemeConfigService,
  TitleService,
  UserInfoService,
} from "@@intelease/web/intelease/services";
import { CommonFacade } from "@@intelease/app-state/common";
import { ComponentStateHooksInterface } from "@@intelease/app-models/common";
import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import { WindowRef } from "ngx-multi-window";
import { AuthService } from "@@intelease/app-services/auth";
import { ServerConfigsService } from "@@intelease/app-services/common";
import { Observable, Subject } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import { PermissionApiService } from "@@intelease/web/intelease/services";
import { LocalStorageKey } from "@common/enums/local-storage.keys";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

declare global {
  interface Window {
    analytics: any;
  }
  var gapi: any;
  var google: any;
}

@Component({
  selector: "itls-app",
  templateUrl: "./app.component.html",
  styles: [
    `
      .app-breakpoint {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 999;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        text-align: center;
        flex-direction: column;
        line-height: 30px;
      }
    `,
  ],
})
export class AppComponent implements OnInit, ComponentStateHooksInterface {
  destroyRef = inject(DestroyRef);
  isInAppBreakPoint: boolean;
  appBreakpoint = 768;
  loading = false;
  showLoadingOnNavigationPaths = ["record-review", "abstract-review"];
  public hasPermissions$: Observable<boolean> =
    this.permissionApiService.permissions$.pipe(
      startWith(null),
      map(
        (permissions: string[]) =>
          !permissions?.length && this.authService.isLoggedIn()
      )
    );

  constructor(
    private ngxTranslateService: NgxTranslateService,
    private readonly router: Router,
    private readonly inteleasePermissionsService: InteleasePermissionsService,
    private readonly itlsIconRegistryService: ItlsIconRegistryService,
    private readonly appServiceWorkerService: AppServiceWorkerService,
    private commonFacade: CommonFacade,
    private pageTitleTagsService: PageTitleTagsService,
    private platform: Platform,
    @Inject(DOCUMENT) private document: any,
    private windowRef: WindowRef,
    private themeConfigService: ThemeConfigService,
    private serverConfigsService: ServerConfigsService,
    private authService: AuthService,
    private permissionApiService: PermissionApiService
  ) {
    this.inteleasePermissionsService.setup();
    this.itlsIconRegistryService.setup();
    this.appServiceWorkerService.setup();
    this.ngxTranslateService.setup();
    this.loadAccountSettings();
    this.pageTitleTagsService.updatePageTitleTags();
    if (this.platform.ANDROID || this.platform.IOS) {
      this.document.body.classList.add("is-mobile");
    }
  }

  private loadAccountSettings(): void {
    const accountSettings = localStorage.getItem(LocalStorageKey.SETTING_ITEMS);
    if (accountSettings) {
      this.commonFacade.accountSettingsLoaded(JSON.parse(accountSettings));
    }
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(
          (event: Event | RouterEvent): event is RouterEvent =>
            event instanceof RouterEvent
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((event: RouterEvent) => {
        switch (true) {
          case event instanceof NavigationStart:
            this.loading = event?.url
              ?.split("/")
              .some((path) => this.showLoadingOnNavigationPaths.includes(path));
            break;
          case event instanceof NavigationError:
            this.router.navigate(["/"]);
            this.loading = false;
          case event instanceof NavigationCancel:
          case event instanceof NavigationEnd:
            this.loading = false;
            break;
        }
      });

    if (UserInfoService.getUserHash()) {
      this.initActions();
      this.serverConfigsService.initializeConfigs();
    }

    this.authService
      .onLogin()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.serverConfigsService.initializeConfigs();
      });

    this.themeConfigService
      .getConfig()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((config) => {
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];
          if (className.startsWith("theme-")) {
            this.document.body.classList.remove(className);
          }
        }
        this.document.body.classList.add(config.colorTheme);
      });
  }

  initListeners(): void {}

  initActions(): void {
    this.commonFacade.loadProvisionsMetadata({});
  }

  onResized() {
    this.isInAppBreakPoint =
      this.windowRef.nativeWindow.outerWidth < this.appBreakpoint;
  }
}
