/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";
import { TaskAssigneeDtoModel } from "./task-assignee-dto-model";

/**
 * the request to create a record's task
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateFinalAbstractTaskDtoModel")
export class CreateFinalAbstractTaskDtoModel {
  @JsonProperty("assignee", TaskAssigneeDtoModel, true)
  assignee?: TaskAssigneeDtoModel = undefined;

  @JsonProperty("dueDate", SimpleOpenApiDateModel, true)
  dueDate?: SimpleOpenApiDateModel = undefined;

  /**
   * the task's name
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the task's notes
   */

  @JsonProperty("notes", String, true)
  notes?: string = "";

  /**
   * the task's priority
   */

  @JsonProperty("priority", Any, true)
  priority?: "LOW" | "MEDIUM" | "HIGH" = undefined;

  /**
   * the task's status
   */

  @JsonProperty("status", Any, true)
  status?:
    | "NOT_STARTED"
    | "IN_PROGRESS"
    | "COMPLETED"
    | "BLOCKED"
    | "DEFERRED" = undefined;

  /**
   * the task's type
   */

  @JsonProperty("type", Any, true)
  type?: "REVIEW" | "APPROVE" | "OTHER" = undefined;
}
