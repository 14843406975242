<div class="modal-content" [@animateStagger]="{ value: '100' }">
  <div class="modal-header clearfix text-left">
    <button
      style="padding: 10px"
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5>Rent schedule calculator</h5>
  </div>
  <div class="modal-body">
    <tabset [justified]="true" #staticTabs>
      <tab
        heading="Choose Columns"
        id="ChooseColumns"
        (selectTab)="onSelectTab($event)"
      >
        <div class="table-field-container">
          <div class="table-field" *ngFor="let tableColumn of tableColumns">
            <div
              *ngIf="!tableColumn.manual"
              class="pretty p-icon p-curve"
              style="float: right; margin-top: 4px"
            >
              <input
                type="checkbox"
                [checked]="tableColumn.selected"
                dataCy="mark-provision-status-checkbox"
                (change)="onSelectTableColumn(tableColumn)"
              />
              <div class="state" style="margin-top: -5px">
                <span><i class="fa fa-check icon mdi mdi-check"></i></span>
                <label>{{ tableColumn.title }}</label>
              </div>
            </div>
            <div *ngIf="tableColumn.manual" style="float: right; width: 100%">
              <div style="margin-right: 5px" class="pretty p-icon p-curve">
                <input
                  type="checkbox"
                  [disabled]="!tableColumn.title"
                  [checked]="tableColumn.selected"
                  dataCy="mark-provision-status-checkbox"
                  (change)="onSelectTableColumn(tableColumn)"
                />
                <div class="state" style="margin-top: -5px">
                  <span><i class="fa fa-check icon mdi mdi-check"></i></span>
                  <label
                    ><span style="opacity: 0; margin-right: -15px"
                      >1</span
                    ></label
                  >
                </div>
              </div>
              <input
                type="text"
                [(ngModel)]="tableColumn.title"
                placeholder="Add a custom column here."
                class="form-control manual-input-text"
                style="display: inline-block; width: 55%"
                (change)="onTitleChangedForTableColumn($event, tableColumn)"
              />
            </div>
          </div>
          <div
            *ngIf="getManualTableColumns().length < MAX_MANUAL_TABLE_COLUMNS"
            class="table-field"
          >
            <div style="float: right; width: 100%">
              <div style="margin-right: 5px" class="pretty p-icon p-curve">
                <input
                  type="checkbox"
                  [disabled]="!newTableColumnTitle"
                  [checked]="false"
                  dataCy="mark-provision-status-checkbox"
                  (click)="addNewManualTableColumn()"
                />
                <div class="state" style="margin-top: -5px">
                  <label
                    ><span style="opacity: 0; margin-right: -15px"
                      >1</span
                    ></label
                  >
                </div>
              </div>
              <input
                type="text"
                [(ngModel)]="newTableColumnTitle"
                placeholder="Add a custom column here."
                class="form-control manual-input-text"
                style="display: inline-block; width: 55%"
              />
            </div>
          </div>
        </div>
      </tab>
      <tab
        heading="Calculation Method"
        id="CalculationMethod"
        (selectTab)="onSelectTab($event)"
      >
        <div class="table-field-container">
          <div
            class="table-field"
            *ngFor="let calculationMethod of calculationMethods"
          >
            <label>
              <input
                type="radio"
                name="calculationMethod"
                [value]="calculationMethod.type"
                [checked]="calculationMethod === selectedCalculationMethod"
                (change)="onSelectCalculationMethod(calculationMethod)"
              />
              {{ calculationMethod.title }}
            </label>
          </div>
        </div>
      </tab>
      <tab
        heading="Provide Inputs"
        id="ProvideInputs"
        (selectTab)="onSelectTab($event)"
      >
        <div class="form-container">
          <div class="form-rows">
            <div class="form-group">
              <label>
                Commencement date
                <i
                  class="fa fa-info-circle ml-1 info-icon"
                  tooltip="Please choose a start date for the rent schedule table."
                  container="modal-container"
                ></i>
              </label>
              <i
                class="fa fa-edit"
                style="right: 50px"
                *ngIf="formData?.commencementDate?.value === 'New'"
                (click)="
                  toggleDatePicker('COMMENCEMENT_DATE', 'commencementDate')
                "
              ></i>
              <ng-select
                [items]="listItems.commencementDate"
                [closeOnSelect]="true"
                [clearable]="false"
                bindLabel="value"
                appendTo="body"
                (change)="
                  onDateValueChange(
                    $event,
                    'COMMENCEMENT_DATE',
                    'commencementDate'
                  )
                "
                [(ngModel)]="formData.commencementDate"
              >
              </ng-select>
              <span
                class="date-value"
                *ngIf="formData?.commencementDate?.value === 'New'"
                >Value:
                {{ formData?.commencementDate?.customValue | date }}</span
              >
            </div>
            <div class="form-group">
              <label>
                Expiration date
                <i
                  class="fa fa-info-circle ml-1 info-icon"
                  tooltip="Please choose an end date for the rent schedule table."
                  container="modal-container"
                ></i>
              </label>
              <i
                class="fa fa-edit"
                style="right: 50px"
                *ngIf="formData?.expirationDate?.value === 'New'"
                (click)="toggleDatePicker('EXPIRATION_DATE', 'expirationDate')"
              ></i>
              <ng-select
                [items]="listItems.expiryDate"
                [closeOnSelect]="true"
                [clearable]="false"
                bindLabel="value"
                appendTo="body"
                (change)="
                  onDateValueChange($event, 'EXPIRATION_DATE', 'expirationDate')
                "
                [(ngModel)]="formData.expirationDate"
              >
              </ng-select>
              <span
                class="date-value"
                *ngIf="formData?.expirationDate?.value === 'New'"
                >Value: {{ formData?.expirationDate?.customValue | date }}</span
              >
            </div>
            <div class="form-group">
              <label>
                Period
                <i
                  class="fa fa-info-circle ml-1 info-icon"
                  tooltip="Please pick a timeframe for each row of the table."
                  container="modal-container"
                ></i>
              </label>
              <itls-input-dropdown-component
                inputWidth="45%"
                selectWidth="55%"
                [min]="1"
                [items]="periodItems"
                [model]="formData.period"
                (change)="onInputDropDownValueChange($event, 'period')"
                [digitsOnly]="true"
              >
              </itls-input-dropdown-component>
            </div>
            <div
              *ngIf="
                selectedCalculationMethod.type ===
                CalculateMethodType.FIXED_RATE
              "
              class="form-group"
            >
              <label>
                Rent escalation (%)
                <i
                  class="fa fa-info-circle ml-1 info-icon"
                  tooltip="Please enter in a number for the fixed rate of escalation for each row. For example, 0 means no change, 100 means to double, and -50 means cutting in half."
                  container="modal-container"
                ></i>
              </label>
              <i
                class="fa fa-edit"
                style="right: 50px"
                *ngIf="formData?.rentIncreasePercentage?.value === 'New'"
                (click)="
                  toggleDatePicker(
                    'rentIncreasePercentage',
                    'rentIncreasePercentage'
                  )
                "
              ></i>
              <ng-select
                [items]="listItems.rentIncreasePercentage"
                [closeOnSelect]="true"
                [clearable]="false"
                bindLabel="value"
                appendTo="body"
                (change)="onSelectFromList($event, 'rentIncreasePercentage')"
                [(ngModel)]="formData.rentIncreasePercentage"
              >
              </ng-select>
              <span
                class="date-value"
                *ngIf="formData?.rentIncreasePercentage?.value === 'New'"
                >Value:
                {{ formData?.rentIncreasePercentage?.customValue }}</span
              >
            </div>

            <div class="form-group">
              <label>
                Rentable Square Feet
                {{ formData.rentableSquareFoot?.customValue }}
                <i
                  class="fa fa-edit"
                  style="right: 30px"
                  *ngIf="formData?.rentableSquareFoot?.value === 'New'"
                  (click)="
                    toggleDatePicker('rentableSquareFoot', 'rentableSquareFoot')
                  "
                ></i>
              </label>
              <ng-select
                [items]="listItems.rentableSqFt"
                [closeOnSelect]="true"
                bindLabel="label"
                appendTo="body"
                (clear)="clearField(RentFields.RentableSquareFoot)"
                (change)="onSelectFromList($event, 'rentableSquareFoot')"
                [(ngModel)]="formData.rentableSquareFoot"
              >
              </ng-select>

              <span
                class="date-value"
                *ngIf="
                  formData?.rentableSquareFoot?.value === 'New' &&
                  formData?.rentableSquareFoot?.customValue
                "
              >
                Value:
                {{ formData?.rentableSquareFoot?.customValue }}
              </span>
            </div>

            <div
              *ngIf="
                selectedCalculationMethod.type ===
                CalculateMethodType.PRICE_INDEX_BASED
              "
              class="form-group"
            >
              <label>
                Price Index
                <i
                  class="fa fa-info-circle ml-1 info-icon"
                  tooltip="Please pick a price index, based off which to calculate the rent changes."
                  container="modal-container"
                ></i>
              </label>
              <ng-select
                [items]="priceIndexResponse"
                [closeOnSelect]="true"
                [clearable]="false"
                bindLabel="name"
                bindValue="uid"
                appendTo="body"
                [(ngModel)]="formData.priceIndexUid"
              >
              </ng-select>
            </div>
            <div
              *ngIf="
                selectedCalculationMethod.type ===
                CalculateMethodType.PRICE_INDEX_BASED
              "
              class="form-group"
            >
              <label>
                Effectiveness Rate (%)
                <i
                  class="fa fa-info-circle ml-1 info-icon"
                  tooltip="Please enter a multiplier to apply on top of the price index change. For example, if the price index changes by 10% but you have entered in an effectiveness rate of 80, then the rent will increase by only 8%."
                  container="modal-container"
                ></i>
              </label>
              <input
                [(ngModel)]="formData.effectivenessRate"
                type="number"
                class="form-control"
              />
            </div>

            <!-- these are default (not created by User) table columns which their values could be picked up from a rest api-->
            <div class="form-group">
              <label>Monthly Per Square Foot</label>
              <i
                class="fa fa-edit"
                style="right: 30px"
                *ngIf="formData?.monthlyPerSquareFoot?.value === 'New'"
                (click)="
                  toggleDatePicker(
                    'monthlyPerSquareFoot',
                    'monthlyPerSquareFoot'
                  )
                "
              ></i>
              <ng-select
                [items]="listItems.monthlyPerSquareFoot"
                [closeOnSelect]="true"
                bindLabel="label"
                appendTo="body"
                (clear)="clearField(RentFields.MonthlyPerSquareFoot)"
                (change)="onSelectFromList($event, 'monthlyPerSquareFoot')"
                [(ngModel)]="formData.monthlyPerSquareFoot"
              >
              </ng-select>
              <span
                class="date-value"
                *ngIf="
                  formData?.monthlyPerSquareFoot?.value === 'New' &&
                  formData?.monthlyPerSquareFoot?.customValue
                "
              >
                Value:
                {{
                  formData?.monthlyPerSquareFoot?.customValue?.input?.toFixed(4)
                }}
                {{
                  formData?.monthlyPerSquareFoot?.customValue?.dropdown?.value
                }}
              </span>
            </div>
            <div class="form-group">
              <label>Monthly rent</label>
              <i
                class="fa fa-edit"
                style="left: 75px"
                *ngIf="formData?.monthlyRent?.value === 'New'"
                (click)="toggleDatePicker('monthlyRent', 'monthlyRent')"
              ></i>
              <ng-select
                [items]="listItems.monthlyBaseRent"
                [closeOnSelect]="true"
                bindLabel="label"
                appendTo="body"
                (clear)="clearField(RentFields.MonthlyRent)"
                (change)="onSelectFromList($event, 'monthlyRent')"
                [(ngModel)]="formData.monthlyRent"
              >
              </ng-select>
              <span
                class="date-value"
                *ngIf="
                  formData?.monthlyRent?.value === 'New' &&
                  formData?.monthlyRent?.customValue
                "
              >
                Value:
                {{ formData?.monthlyRent?.customValue?.input?.toFixed(4) }}
                {{ formData?.monthlyRent?.customValue?.dropdown?.value }}
              </span>
            </div>
            <div class="form-group">
              <label>Annual per square foot</label>
              <i
                class="fa fa-edit"
                style="right: 40px"
                *ngIf="formData?.annualPerSquareFoot?.value === 'New'"
                (click)="
                  toggleDatePicker('annualPerSquareFoot', 'annualPerSquareFoot')
                "
              ></i>
              <ng-select
                [items]="listItems.annualPerSquareFoot"
                [closeOnSelect]="true"
                (clear)="clearField(RentFields.AnnualPerSquareFoot)"
                bindLabel="label"
                appendTo="body"
                (change)="onSelectFromList($event, 'annualPerSquareFoot')"
                [(ngModel)]="formData.annualPerSquareFoot"
              >
              </ng-select>
              <span
                class="date-value"
                *ngIf="
                  formData?.annualPerSquareFoot?.value === 'New' &&
                  formData?.annualPerSquareFoot?.customValue
                "
              >
                Value:
                {{
                  formData?.annualPerSquareFoot?.customValue?.input?.toFixed(4)
                }}
                {{
                  formData?.annualPerSquareFoot?.customValue?.dropdown?.value
                }}
              </span>
            </div>
            <div class="form-group">
              <label>Annual rent</label>
              <i
                class="fa fa-edit"
                style="left: 70px"
                *ngIf="formData?.annualRent?.value === 'New'"
                (click)="toggleDatePicker('annualRent', 'annualRent')"
              ></i>
              <ng-select
                [items]="listItems.annualRent"
                [closeOnSelect]="true"
                (clear)="clearField(RentFields.AnnualRent)"
                bindLabel="label"
                appendTo="body"
                (change)="onSelectFromList($event, 'annualRent')"
                [(ngModel)]="formData.annualRent"
              >
              </ng-select>
              <span
                class="date-value"
                *ngIf="
                  formData?.annualRent?.value === 'New' &&
                  formData?.annualRent?.customValue
                "
              >
                Value:
                {{ formData?.annualRent?.customValue?.input?.toFixed(4) }}
                {{ formData?.annualRent?.customValue?.dropdown?.value }}
              </span>
            </div>

            <!-- these are manual (created by User) table columns -->
            <div *ngFor="let manualColumn of tableColumns">
              <div
                class="form-group"
                *ngIf="manualColumn.selected && manualColumn.manual"
              >
                <label>{{ manualColumn.title }}</label>
                <i
                  class="fa fa-edit"
                  style="left: 75px"
                  (click)="toggleDatePicker(manualColumn.key, manualColumn.key)"
                ></i>
                <ng-select
                  class="hide-arrow"
                  [disabled]="true"
                  bindLabel="label"
                  [(ngModel)]="defaultNewSelectionValue"
                >
                </ng-select>
                <span
                  class="date-value"
                  *ngIf="
                    formData[manualColumn.key]?.value === 'New' &&
                    formData[manualColumn.key]?.customValue
                  "
                >
                  Value:
                  {{ formData[manualColumn.key]?.customValue?.input }}
                  {{ formData[manualColumn.key]?.customValue?.dropdown?.value }}
                </span>
              </div>
            </div>
          </div>
          <div class="date-picker-container">
            <div
              class="form-item"
              *ngIf="selectedField && formData[selectedField]?.value === 'New'"
            >
              <label>{{ getTitleOfSelectedField() }}</label>

              <input
                *ngIf="selectedField === 'rentableSquareFoot'"
                type="number"
                [onlyNumber]="true"
                min="0"
                [(ngModel)]="formData.rentableSquareFoot.customValue"
                class="form-control"
              />
              <input
                *ngIf="selectedField === 'rentIncreasePercentage'"
                type="number"
                [onlyNumber]="true"
                min="0"
                [(ngModel)]="formData.rentIncreasePercentage.customValue"
                class="form-control"
              />
              <itls-input-dropdown-component
                *ngIf="
                  selectedField !== 'rentableSquareFoot' &&
                  selectedField !== 'rentIncreasePercentage'
                "
                [items]="moneyUnitItems"
                [model]="formData[selectedField]"
                [min]="0"
                inputWidth="45%"
                selectWidth="55%"
                (change)="onInputDropDownValueChange($event, selectedField)"
              >
              </itls-input-dropdown-component>
            </div>
            <i
              class="fa fa-close"
              (click)="selectedDate = undefined; selectedField = undefined"
              *ngIf="selectedField || selectedDate"
            ></i>
            <bs-datepicker-inline
              *ngIf="selectedDate === 'COMMENCEMENT_DATE'"
              [(bsValue)]="datePickerValue"
              (bsValueChange)="onDateChange($event, 'commencementDate')"
            ></bs-datepicker-inline>
            <bs-datepicker-inline
              *ngIf="selectedDate === 'EXPIRATION_DATE'"
              [(bsValue)]="datePickerValue"
              (bsValueChange)="onDateChange($event, 'expirationDate')"
            ></bs-datepicker-inline>
          </div>
        </div>
        <div
          class="table-container"
          [@animateStagger]="{ value: '100' }"
          *ngIf="!isTableHidden && !selectedField"
        >
          <table
            class="table table-hover"
            [@animate]="{ value: '*', params: { y: '100px' } }"
          >
            <tr>
              <th class="text-center table-cell-date">
                {{ CALCULATED_TABLE__BEGIN_TITLE }}
              </th>
              <th class="text-center table-cell-date">
                {{ CALCULATED_TABLE__END_TITLE }}
              </th>
              <ng-container *ngFor="let column of tableColumns">
                <th
                  class="text-center table-cell-value"
                  *ngIf="column.selected"
                >
                  {{ getTitleForCalculatedTableColumn(column) }}
                </th>
              </ng-container>
              <th
                style="padding: 0 !important"
                [style.width]="selectedColumnsCount === 1 ? '100%' : '0'"
              ></th>
            </tr>
            <tr *ngFor="let item of calculatedItems; let i = index">
              <td>
                <input
                  type="text"
                  class="form-control text-center"
                  [(ngModel)]="item.begin"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="form-control text-center"
                  [(ngModel)]="item.end"
                />
              </td>
              <ng-container *ngFor="let column of tableColumns">
                <td *ngIf="column.selected">
                  <input
                    type="text"
                    [(ngModel)]="item[column.key]"
                    class="form-control text-center"
                  />
                </td>
              </ng-container>
            </tr>
          </table>
        </div>
      </tab>
    </tabset>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="selectedTab !== 'ChooseColumns'">
      <button
        type="button"
        class="btn btn-default btn-cons pull-left inline previous-btn"
        (click)="onPreviousTab()"
      >
        Previous
      </button>
    </ng-container>
    <ng-container *ngIf="selectedTab !== 'ProvideInputs'">
      <button
        type="button"
        class="btn btn-default btn-cons pull-left inline next-btn"
        (click)="onNextTab()"
      >
        Next
      </button>
    </ng-container>
    <ng-container *ngIf="selectedTab !== 'ChooseColumns'">
      <button
        *ngIf="selectedTab === 'ProvideInputs'"
        type="button"
        class="btn btn-default btn-cons pull-left inline"
        style="padding: 8px"
        (click)="onResetClick()"
      >
        Reset
      </button>
      <button
        *ngIf="selectedTab === 'ProvideInputs'"
        type="button"
        [disabled]="isCallingCalculationApi"
        class="btn btn-primary btn-cons pull-left inline calculateBtn"
        (click)="onCalculateClick()"
      >
        Calculate
        <i class="fa fa-spinner fa-spin" *ngIf="isCallingCalculationApi"></i>
      </button>
      <button
        *ngIf="selectedTab === 'ProvideInputs'"
        type="button"
        [disabled]="calculatedItems.length === 0 && isSavingCalculatedMention"
        class="btn btn-primary btn-cons pull-left inline importBtn"
        (click)="onImportClick()"
      >
        Import
      </button>
    </ng-container>
  </div>
</div>
