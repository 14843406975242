/* tslint:disable */
/* eslint-disable */
import { ErrorDataModel } from "./error-data-model";
import { LoggedInUserViewModel } from "./logged-in-user-view-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiRespLoggedInUserViewModel")
export class OApiRespLoggedInUserViewModel {
  @JsonProperty("data", LoggedInUserViewModel, true)
  data?: LoggedInUserViewModel = undefined;

  /**
   * the developer-facing errors for the request
   */

  @JsonProperty("devErrors", [ErrorDataModel], true)
  devErrors?: Array<ErrorDataModel> = [];

  /**
   * the user-facing errors for the request
   */

  @JsonProperty("errors", [ErrorDataModel], true)
  errors?: Array<ErrorDataModel> = [];

  /**
   * the response message
   */

  @JsonProperty("message", String, true)
  message?: string = "";
}
