import { Injectable } from "@angular/core";
import { RestClient } from "@@intelease/web/utils";
import { PaginationModel } from "@@intelease/web/intelease/models";
import { Observable } from "rxjs";
import { MinimalUserAbstractTaskModel } from "@@intelease/app-models/tasks";
import { CreateApiService } from "@@intelease/web/intelease/services";
import {
  OApiRespFullIssueViewModel,
  OApiRespKanbanBoardDtoModel,
  OApiRespMultiAbstractEventModel,
  OApiRespMultiMinimalTaskViewModel,
  OApiSingleEntityNoDataResponseModel,
} from "@@intelease/api-models/adex-api-model-src";
import { DateConvertService } from "@common/services";

@Injectable({
  providedIn: "root",
})
export class KanbanService {
  private static KANBAN_BASE_URL = "/kanbanBoard";
  private static PROJECT_BASE_URL = "/project";

  constructor(
    private readonly restClient: RestClient,
    private readonly createApiService: CreateApiService
  ) {}

  saveKanbanBoard(payload): Observable<OApiRespKanbanBoardDtoModel> {
    return this.restClient.sendPostRequest<OApiRespKanbanBoardDtoModel>(
      "/v1",
      KanbanService.KANBAN_BASE_URL,
      payload,
      OApiRespKanbanBoardDtoModel
    );
  }

  saveKanbanComment(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string,
    text: string,
    elements?
  ): Observable<OApiSingleEntityNoDataResponseModel> {
    const payload = {
      data: { text, ...elements },
      returnParams: { view: "none" },
    };
    return this.restClient.sendPostRequest<OApiSingleEntityNoDataResponseModel>(
      "/v1",
      `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kabanBoardUid}/issueList/${issueListUid}/issue/${issueUid}/comment`,
      payload,
      OApiSingleEntityNoDataResponseModel
    );
  }

  deleteKanbanComment(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string,
    commentUid: string
  ): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.restClient.sendDeleteRequest<OApiSingleEntityNoDataResponseModel>(
      "/v1",
      `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kabanBoardUid}/issueList/${issueListUid}/issue/${issueUid}/comment/${commentUid}`,
      OApiSingleEntityNoDataResponseModel
    );
  }

  updateKanbanComment(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string,
    commentUid: string,
    text: string,
    elements?
  ): Observable<OApiSingleEntityNoDataResponseModel> {
    const payload = {
      data: { text, ...elements },
      replace: false,
      returnParams: { view: "none" },
    };
    return this.restClient.sendPutRequest<OApiSingleEntityNoDataResponseModel>(
      "/v1",
      `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kabanBoardUid}/issueList/${issueListUid}/issue/${issueUid}/comment/${commentUid}`,
      payload,
      OApiSingleEntityNoDataResponseModel
    );
  }

  getKanbanBoard(
    dashboardUid: string,
    projectUid: string,
    kanbanUid: string
  ): Observable<OApiRespKanbanBoardDtoModel> {
    if (dashboardUid) {
      return this.restClient.sendGetRequest<OApiRespKanbanBoardDtoModel>(
        "/v1",
        `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kanbanUid}`,
        OApiRespKanbanBoardDtoModel,
        {
          params: { dashboardUid },
        }
      );
    } else {
      return this.restClient.sendGetRequest<OApiRespKanbanBoardDtoModel>(
        "/v1",
        `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kanbanUid}`,
        OApiRespKanbanBoardDtoModel
      );
    }
  }

  updateKanbanBoard(
    projectUid: string,
    kanbanUid: string,
    payload: any
  ): Observable<OApiRespKanbanBoardDtoModel> {
    const data = {
      returnParams: {
        view: "full",
      },
      replace: true,
      data: payload,
    };
    return this.restClient.sendPutRequest<OApiRespKanbanBoardDtoModel>(
      "/v1",
      `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kanbanUid}`,
      data,
      OApiRespKanbanBoardDtoModel
    );
  }

  getKanbanBoardIssueDetail(
    dashboardUid: string,
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string
  ): Observable<OApiRespFullIssueViewModel> {
    if (dashboardUid) {
      return this.restClient.sendGetRequest<OApiRespFullIssueViewModel>(
        "/v1",
        `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kabanBoardUid}/issueList/${issueListUid}/issue/${issueUid}`,
        OApiRespFullIssueViewModel,
        {
          params: { dashboardUid },
        }
      );
    } else {
      return this.restClient.sendGetRequest<OApiRespFullIssueViewModel>(
        "/v1",
        `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kabanBoardUid}/issueList/${issueListUid}/issue/${issueUid}`,
        OApiRespFullIssueViewModel
      );
    }
  }

  createKanbanBoardIssueTask(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string,
    task: any
  ): Observable<MinimalUserAbstractTaskModel> {
    if (task.dueDate) {
      task.dueDate = DateConvertService.serializeDate(task["dueDate"]);
    }
    return this.createApiService.sendRequest<MinimalUserAbstractTaskModel>(
      "/v1",
      `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kabanBoardUid}/issueList/${issueListUid}/issue/${issueUid}/task`,
      task,
      MinimalUserAbstractTaskModel.view,
      MinimalUserAbstractTaskModel
    );
  }

  getKanbanBoardIssueTasks(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string
  ): Observable<OApiRespMultiMinimalTaskViewModel> {
    return this.restClient.sendGetRequest<OApiRespMultiMinimalTaskViewModel>(
      "/v1",
      `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kabanBoardUid}/issueList/${issueListUid}/issue/${issueUid}/task`,
      OApiRespMultiMinimalTaskViewModel,
      {
        params: {
          view: "minimal",
        },
      }
    );
  }

  getKanbanBoardIssueHistory(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string
  ): Observable<OApiRespMultiAbstractEventModel> {
    return this.restClient.sendGetRequest<OApiRespMultiAbstractEventModel>(
      "/v1",
      `${KanbanService.PROJECT_BASE_URL}/${projectUid}${KanbanService.KANBAN_BASE_URL}/${kabanBoardUid}/issueList/${issueListUid}/issue/${issueUid}/history`,
      OApiRespMultiAbstractEventModel,
      {
        params: {
          view: "minimal",
        },
      }
    );
  }

  /**
   * search in Documents
   */
  searchFinalDocuments(
    kanbanUid: string,
    pageable?: PaginationModel
  ): Observable<any> {
    const payload = {
      data: {
        filters: [],
        viewColumns: [
          "abstractName::Name::STRING",
          "status::Status::STRING",
          "lastModifiedDate::Last Modified::DATE",
          "numDocuments::# Documents::NUMBER",
        ],
        sort: "lastModifiedDate::Last Modified::DATE::DESC",
        perPage: pageable.size,
        page: pageable.page,
      },
    };
    return this.restClient.sendPostRequestNoView<any>(
      "/v1",
      `${KanbanService.KANBAN_BASE_URL}/${kanbanUid}/searchTable/finalDocSets`,
      payload
    );
  }
}
