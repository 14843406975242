/* tslint:disable */
/* eslint-disable */
import { DocSetSearchResultModel } from "./doc-set-search-result-model";
import { PaginationDataModel } from "./pagination-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiMultiEntityContainerResponseDocSetSearchResultModel")
export class OApiMultiEntityContainerResponseDocSetSearchResultModel {
  @JsonProperty("items", [DocSetSearchResultModel], true)
  items?: Array<DocSetSearchResultModel> = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
