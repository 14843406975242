/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiDeleteRequestModel } from "../models/o-api-delete-request-model";
import { OApiReqCreateDirectoryDtoModel } from "../models/o-api-req-create-directory-dto-model";
import { OApiReqDeleteMultipleNodesRequestModel } from "../models/o-api-req-delete-multiple-nodes-request-model";
import { OApiReqExportMultipleNodesRequestModel } from "../models/o-api-req-export-multiple-nodes-request-model";
import { OApiReqFetchEntitiesPermissionsRequestModel } from "../models/o-api-req-fetch-entities-permissions-request-model";
import { OApiReqFileSearchRequestDtoModel } from "../models/o-api-req-file-search-request-dto-model";
import { OApiReqGetOffsetInSearchResultRequestModel } from "../models/o-api-req-get-offset-in-search-result-request-model";
import { OApiReqMoveMultipleNodesRequestModel } from "../models/o-api-req-move-multiple-nodes-request-model";
import { OApiReqRemoveSharedFileRequestModel } from "../models/o-api-req-remove-shared-file-request-model";
import { OApiReqRenameNodeDtoModel } from "../models/o-api-req-rename-node-dto-model";
import { OApiReqShareMultipleNodesRequestModel } from "../models/o-api-req-share-multiple-nodes-request-model";
import { OApiRespDeleteMultipleNodesOrFailureModel } from "../models/o-api-resp-delete-multiple-nodes-or-failure-model";
import { OApiRespDirectoryNodeDtoModel } from "../models/o-api-resp-directory-node-dto-model";
import { OApiRespGetOffsetInSearchResultResponseModel } from "../models/o-api-resp-get-offset-in-search-result-response-model";
import { OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel } from "../models/o-api-resp-list-with-navigation-and-pagination-result-directory-node-dto-model";
import { OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel } from "../models/o-api-resp-list-with-navigation-and-pagination-result-doc-set-node-dto-model";
import { OApiRespMediumNodeDtoModel } from "../models/o-api-resp-medium-node-dto-model";
import { OApiRespMultiEntityAllPermissionsFullDtoModel } from "../models/o-api-resp-multi-entity-all-permissions-full-dto-model";
import { OApiRespMultiNodeDtoModel } from "../models/o-api-resp-multi-node-dto-model";
import { OApiRespMultiNodeSearchResultDtoModel } from "../models/o-api-resp-multi-node-search-result-dto-model";
import { OApiRespRemoveSharedModel } from "../models/o-api-resp-remove-shared-model";
import { OApiSingleEntityNoDataResponseModel } from "../models/o-api-single-entity-no-data-response-model";

@Injectable({
  providedIn: "root",
})
export class DriveService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation create4
   */
  static readonly Create4Path = "/api/v1/directories";

  /**
   * Create a directory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create4()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create4$Response(params: {
    body: OApiReqCreateDirectoryDtoModel;
  }): Observable<StrictHttpResponse<OApiRespDirectoryNodeDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.Create4Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespDirectoryNodeDtoModel>;
        })
      );
  }

  /**
   * Create a directory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create4$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create4(params: {
    body: OApiReqCreateDirectoryDtoModel;
  }): Observable<OApiRespDirectoryNodeDtoModel> {
    return this.create4$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespDirectoryNodeDtoModel>) =>
          r.body as OApiRespDirectoryNodeDtoModel
      )
    );
  }

  /**
   * Path part for operation deleteDirectories
   */
  static readonly DeleteDirectoriesPath = "/api/v1/directories/deleteBatch";

  /**
   * Delete list of directories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDirectories()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteDirectories$Response(params: {
    body: OApiDeleteRequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.DeleteDirectoriesPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Delete list of directories.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDirectories$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteDirectories(params: {
    body: OApiDeleteRequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.deleteDirectories$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation getChildren
   */
  static readonly GetChildrenPath = "/api/v1/directories/{directoryUid}";

  /**
   * Get children directories, document sets and records of a directory.
   *
   * Return the list of directories in the given directory.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChildren()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChildren$Response(params: {
    /**
     * The unique id of the directory.
     */
    directoryUid: string;

    /**
     * The category of directories to fetch. If null or not provided, then gets ALL category.
     */
    category?:
      | "ALL"
      | "DELETED"
      | "FAVORITES"
      | "OWNED"
      | "SHARED"
      | "DOCUMENTS"
      | "GOOGLE_DRIVE";

    /**
     * The page number (1-based) to fetch. If sending this, should also send &#x27;perPage&#x27;.
     */
    page?: number;

    /**
     * The number of results per page to fetch. If sending this, should also send &#x27;page&#x27;.
     */
    perPage?: number;

    /**
     * The view of the results to fetch.
     */
    view?: string;

    /**
     * The sort criteria for the results to fetch.
     */
    sorts?: string;
  }): Observable<
    StrictHttpResponse<
      | OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel
      | OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.GetChildrenPath,
      "get"
    );
    if (params) {
      rb.path("directoryUid", params.directoryUid, {});
      rb.query("category", params.category, {});
      rb.query("page", params.page, {});
      rb.query("perPage", params.perPage, {});
      rb.query("view", params.view, {});
      rb.query("sorts", params.sorts, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel
            | OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
          >;
        })
      );
  }

  /**
   * Get children directories, document sets and records of a directory.
   *
   * Return the list of directories in the given directory.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChildren$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChildren(params: {
    /**
     * The unique id of the directory.
     */
    directoryUid: string;

    /**
     * The category of directories to fetch. If null or not provided, then gets ALL category.
     */
    category?:
      | "ALL"
      | "DELETED"
      | "FAVORITES"
      | "OWNED"
      | "SHARED"
      | "DOCUMENTS"
      | "GOOGLE_DRIVE";

    /**
     * The page number (1-based) to fetch. If sending this, should also send &#x27;perPage&#x27;.
     */
    page?: number;

    /**
     * The number of results per page to fetch. If sending this, should also send &#x27;page&#x27;.
     */
    perPage?: number;

    /**
     * The view of the results to fetch.
     */
    view?: string;

    /**
     * The sort criteria for the results to fetch.
     */
    sorts?: string;
  }): Observable<
    | OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel
    | OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
  > {
    return this.getChildren$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel
            | OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
          >
        ) =>
          r.body as
            | OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel
            | OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
      )
    );
  }

  /**
   * Path part for operation deleteDirectory
   */
  static readonly DeleteDirectoryPath = "/api/v1/directories/{directoryUid}";

  /**
   * Delete a directory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDirectory()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDirectory$Response(params?: {}): Observable<
    StrictHttpResponse<OApiSingleEntityNoDataResponseModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.DeleteDirectoryPath,
      "delete"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Delete a directory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDirectory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDirectory(params?: {}): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.deleteDirectory$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation getChildrenDirectories
   */
  static readonly GetChildrenDirectoriesPath =
    "/api/v1/directories/owned/children/directories";

  /**
   * Get children directories of a directory that is owned by current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChildrenDirectories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChildrenDirectories$Response(params?: {
    directoryUid?: string;
  }): Observable<StrictHttpResponse<OApiRespMultiNodeDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.GetChildrenDirectoriesPath,
      "get"
    );
    if (params) {
      rb.query("directoryUid", params.directoryUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiNodeDtoModel>;
        })
      );
  }

  /**
   * Get children directories of a directory that is owned by current user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChildrenDirectories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChildrenDirectories(params?: {
    directoryUid?: string;
  }): Observable<OApiRespMultiNodeDtoModel> {
    return this.getChildrenDirectories$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiNodeDtoModel>) =>
          r.body as OApiRespMultiNodeDtoModel
      )
    );
  }

  /**
   * Path part for operation getRootDirectories
   */
  static readonly GetRootDirectoriesPath = "/api/v1/rootDirectories";

  /**
   * Get root directories (e.g: My Drive, All DocSets, All Records).
   *
   * Get all the directories at the top level.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRootDirectories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRootDirectories$Response(params?: {
    /**
     * The category of directories to fetch. If null or not provided, then gets ALL category.
     */
    category?:
      | "ALL"
      | "DELETED"
      | "FAVORITES"
      | "OWNED"
      | "SHARED"
      | "DOCUMENTS"
      | "GOOGLE_DRIVE";

    /**
     * The page number (1-based) to fetch. If sending this, should also send &#x27;perPage&#x27;.
     */
    page?: number;

    /**
     * The number of results per page to fetch. If sending this, should also send &#x27;page&#x27;.
     */
    perPage?: number;

    /**
     * The view of the results to fetch.
     */
    view?: string;

    /**
     * The sort criteria for the results to fetch.
     */
    sorts?: string;
  }): Observable<
    StrictHttpResponse<
      | OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel
      | OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.GetRootDirectoriesPath,
      "get"
    );
    if (params) {
      rb.query("category", params.category, {});
      rb.query("page", params.page, {});
      rb.query("perPage", params.perPage, {});
      rb.query("view", params.view, {});
      rb.query("sorts", params.sorts, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel
            | OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
          >;
        })
      );
  }

  /**
   * Get root directories (e.g: My Drive, All DocSets, All Records).
   *
   * Get all the directories at the top level.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRootDirectories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRootDirectories(params?: {
    /**
     * The category of directories to fetch. If null or not provided, then gets ALL category.
     */
    category?:
      | "ALL"
      | "DELETED"
      | "FAVORITES"
      | "OWNED"
      | "SHARED"
      | "DOCUMENTS"
      | "GOOGLE_DRIVE";

    /**
     * The page number (1-based) to fetch. If sending this, should also send &#x27;perPage&#x27;.
     */
    page?: number;

    /**
     * The number of results per page to fetch. If sending this, should also send &#x27;page&#x27;.
     */
    perPage?: number;

    /**
     * The view of the results to fetch.
     */
    view?: string;

    /**
     * The sort criteria for the results to fetch.
     */
    sorts?: string;
  }): Observable<
    | OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel
    | OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
  > {
    return this.getRootDirectories$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel
            | OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
          >
        ) =>
          r.body as
            | OApiRespListWithNavigationAndPaginationResultDirectoryNodeDtoModel
            | OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
      )
    );
  }

  /**
   * Path part for operation delete2
   */
  static readonly Delete2Path = "/api/v1/files/deleteBatch";

  /**
   * Delete list of files/directories.
   *
   * Deletes the provided files or directories.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  delete2$Response(params: {
    /**
     * Data needed to be able to delete the files / directories.
     */
    body: OApiReqDeleteMultipleNodesRequestModel;
  }): Observable<
    StrictHttpResponse<OApiRespDeleteMultipleNodesOrFailureModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.Delete2Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespDeleteMultipleNodesOrFailureModel>;
        })
      );
  }

  /**
   * Delete list of files/directories.
   *
   * Deletes the provided files or directories.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `delete2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  delete2(params: {
    /**
     * Data needed to be able to delete the files / directories.
     */
    body: OApiReqDeleteMultipleNodesRequestModel;
  }): Observable<OApiRespDeleteMultipleNodesOrFailureModel> {
    return this.delete2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespDeleteMultipleNodesOrFailureModel>) =>
          r.body as OApiRespDeleteMultipleNodesOrFailureModel
      )
    );
  }

  /**
   * Path part for operation export
   */
  static readonly ExportPath = "/api/v1/files/export";

  /**
   * Export list of files/directories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export$Pdf()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  export$Pdf$Response(params: {
    body: OApiReqExportMultipleNodesRequestModel;
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.ExportPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/pdf",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export list of files/directories.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export$Pdf$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  export$Pdf(params: {
    body: OApiReqExportMultipleNodesRequestModel;
  }): Observable<any> {
    return this.export$Pdf$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Export list of files/directories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export$VndOpenxmlformatsOfficedocumentWordprocessingmlDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  export$VndOpenxmlformatsOfficedocumentWordprocessingmlDocument$Response(params: {
    body: OApiReqExportMultipleNodesRequestModel;
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.ExportPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export list of files/directories.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export$VndOpenxmlformatsOfficedocumentWordprocessingmlDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  export$VndOpenxmlformatsOfficedocumentWordprocessingmlDocument(params: {
    body: OApiReqExportMultipleNodesRequestModel;
  }): Observable<any> {
    return this.export$VndOpenxmlformatsOfficedocumentWordprocessingmlDocument$Response(
      params
    ).pipe(map((r: StrictHttpResponse<any>) => r.body as any));
  }

  /**
   * Export list of files/directories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  export$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params: {
    body: OApiReqExportMultipleNodesRequestModel;
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.ExportPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export list of files/directories.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  export$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(params: {
    body: OApiReqExportMultipleNodesRequestModel;
  }): Observable<any> {
    return this.export$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(
      params
    ).pipe(map((r: StrictHttpResponse<any>) => r.body as any));
  }

  /**
   * Export list of files/directories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export$Csv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  export$Csv$Response(params: {
    body: OApiReqExportMultipleNodesRequestModel;
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.ExportPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "text/csv",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export list of files/directories.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export$Csv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  export$Csv(params: {
    body: OApiReqExportMultipleNodesRequestModel;
  }): Observable<any> {
    return this.export$Csv$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Export list of files/directories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export$Zip()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  export$Zip$Response(params: {
    body: OApiReqExportMultipleNodesRequestModel;
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.ExportPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/zip",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export list of files/directories.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export$Zip$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  export$Zip(params: {
    body: OApiReqExportMultipleNodesRequestModel;
  }): Observable<any> {
    return this.export$Zip$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getOffsetInSearchResult
   */
  static readonly GetOffsetInSearchResultPath = "/api/v1/files/search/offset";

  /**
   * Find index of a file/directory from a search result.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOffsetInSearchResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOffsetInSearchResult$Response(params: {
    body: OApiReqGetOffsetInSearchResultRequestModel;
  }): Observable<
    StrictHttpResponse<OApiRespGetOffsetInSearchResultResponseModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.GetOffsetInSearchResultPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespGetOffsetInSearchResultResponseModel>;
        })
      );
  }

  /**
   * Find index of a file/directory from a search result.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOffsetInSearchResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOffsetInSearchResult(params: {
    body: OApiReqGetOffsetInSearchResultRequestModel;
  }): Observable<OApiRespGetOffsetInSearchResultResponseModel> {
    return this.getOffsetInSearchResult$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespGetOffsetInSearchResultResponseModel>) =>
          r.body as OApiRespGetOffsetInSearchResultResponseModel
      )
    );
  }

  /**
   * Path part for operation move
   */
  static readonly MovePath = "/api/v1/files/move";

  /**
   * Move list of files/directories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `move()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  move$Response(params: {
    body: OApiReqMoveMultipleNodesRequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(this.rootUrl, DriveService.MovePath, "put");
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Move list of files/directories.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `move$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  move(params: {
    body: OApiReqMoveMultipleNodesRequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.move$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation rename
   */
  static readonly RenamePath = "/api/v1/files/{nodeUid}/name";

  /**
   * Rename a file/directory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rename()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rename$Response(params: {
    nodeUid: string;
    body: OApiReqRenameNodeDtoModel;
  }): Observable<StrictHttpResponse<OApiRespMediumNodeDtoModel>> {
    const rb = new RequestBuilder(this.rootUrl, DriveService.RenamePath, "put");
    if (params) {
      rb.path("nodeUid", params.nodeUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMediumNodeDtoModel>;
        })
      );
  }

  /**
   * Rename a file/directory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rename$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rename(params: {
    nodeUid: string;
    body: OApiReqRenameNodeDtoModel;
  }): Observable<OApiRespMediumNodeDtoModel> {
    return this.rename$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMediumNodeDtoModel>) =>
          r.body as OApiRespMediumNodeDtoModel
      )
    );
  }

  /**
   * Path part for operation search
   */
  static readonly SearchPath = "/api/v1/files/search";

  /**
   * Search files / directories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  search$Response(params: {
    body: OApiReqFileSearchRequestDtoModel;
  }): Observable<StrictHttpResponse<OApiRespMultiNodeSearchResultDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.SearchPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiNodeSearchResultDtoModel>;
        })
      );
  }

  /**
   * Search files / directories.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  search(params: {
    body: OApiReqFileSearchRequestDtoModel;
  }): Observable<OApiRespMultiNodeSearchResultDtoModel> {
    return this.search$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiNodeSearchResultDtoModel>) =>
          r.body as OApiRespMultiNodeSearchResultDtoModel
      )
    );
  }

  /**
   * Path part for operation getPermissions2
   */
  static readonly GetPermissions2Path = "/api/v1/files/permissions";

  /**
   * Get permissions for a file/directory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermissions2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPermissions2$Response(params: {
    body: OApiReqFetchEntitiesPermissionsRequestModel;
  }): Observable<
    StrictHttpResponse<OApiRespMultiEntityAllPermissionsFullDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.GetPermissions2Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiEntityAllPermissionsFullDtoModel>;
        })
      );
  }

  /**
   * Get permissions for a file/directory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPermissions2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPermissions2(params: {
    body: OApiReqFetchEntitiesPermissionsRequestModel;
  }): Observable<OApiRespMultiEntityAllPermissionsFullDtoModel> {
    return this.getPermissions2$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<OApiRespMultiEntityAllPermissionsFullDtoModel>
        ) => r.body as OApiRespMultiEntityAllPermissionsFullDtoModel
      )
    );
  }

  /**
   * Path part for operation removeShared2
   */
  static readonly RemoveShared2Path = "/api/v1/files/removeShared";

  /**
   * Un-share from a file/directory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeShared2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeShared2$Response(params: {
    body: OApiReqRemoveSharedFileRequestModel;
  }): Observable<StrictHttpResponse<OApiRespRemoveSharedModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.RemoveShared2Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRemoveSharedModel>;
        })
      );
  }

  /**
   * Un-share from a file/directory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeShared2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeShared2(params: {
    body: OApiReqRemoveSharedFileRequestModel;
  }): Observable<OApiRespRemoveSharedModel> {
    return this.removeShared2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRemoveSharedModel>) =>
          r.body as OApiRespRemoveSharedModel
      )
    );
  }

  /**
   * Path part for operation share2
   */
  static readonly Share2Path = "/api/v1/files/share";

  /**
   * Share a file/directory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `share2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  share2$Response(params: {
    body: OApiReqShareMultipleNodesRequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DriveService.Share2Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Share a file/directory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `share2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  share2(params: {
    body: OApiReqShareMultipleNodesRequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.share2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }
}
