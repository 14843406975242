import { TaskListModel } from "../models";

export const TASKS_LIST_CONST: TaskListModel = {
  ABSTRACT_TABLE_CONFIG: {
    COLUMNS: [
      {
        isHidden: true,
        name: "checkbox",
        prop: "checkbox",
        type: "CHECKBOX",
      },
      {
        isHidden: false,
        name: "Subject",
        prop: "name",
        type: "STRING",
      },
      {
        isHidden: false,
        name: "Status",
        prop: "status.uiName",
        type: "ENUM",
        enumKey: "TASK_STATUS",
      },
      {
        isHidden: false,
        name: "Priority",
        prop: "priority",
        type: "ENUM",
        enumKey: "TASK_PRIORITY",
      },
      {
        isHidden: false,
        name: "Assigned To",
        prop: "assigneeName",
        type: "STRING",
      },
      // { isHidden: false, name: 'Created By', prop: 'createdByName', type: 'STRING' },
      {
        isHidden: false,
        name: "Due date",
        prop: "dueDate",
        type: "DATE",
      },
      // { isHidden: false, name: 'Updated By', prop: 'updatedByName', type: 'STRING' },
      {
        isHidden: false,
        name: "Notes",
        prop: "notes",
        type: "STRING",
      },
    ],
    ROW_MENU: [],
    ROW_BATCH_MENU: [],
  },
  TASKS_LIST_TABLE_CONFIG: {
    COLUMNS: [
      {
        isHidden: true,
        name: "checkbox",
        prop: "checkbox",
        type: "CHECKBOX",
      },
      {
        isHidden: false,
        name: "Documents",
        prop: "entity",
        type: "LINK",
        target: "_blank",
        sortable: false,
      },
      {
        isHidden: false,
        name: "Subject",
        prop: "name",
        type: "STRING",
      },
      {
        isHidden: false,
        name: "Status",
        prop: "status.uiName",
        type: "ENUM",
        enumKey: "TASK_STATUS",
      },
      // { isHidden: false, name: 'Assigned To', prop: 'assigneeName', type: 'STRING' },
      {
        isHidden: false,
        name: "Created At",
        prop: "createdAt",
        type: "DATE",
      },
      {
        isHidden: false,
        name: "Created By",
        prop: "createdByName",
        type: "STRING",
      },
      {
        isHidden: false,
        name: "Priority",
        prop: "priority",
        type: "ENUM",
        enumKey: "TASK_PRIORITY",
      },
      {
        isHidden: false,
        name: "Due date",
        prop: "dueDate",
        type: "DATE",
      },
      // { isHidden: false, name: 'Updated By', prop: 'updatedByName', type: 'STRING' },
      {
        isHidden: false,
        name: "Notes",
        prop: "notes",
        type: "STRING",
      },
    ],
    ROW_MENU: [],
    ROW_BATCH_MENU: [],
  },
};
