import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CALENDAR_FEATURE_KEY, CalendarState } from "./calendar.reducer";
import { values } from "lodash";

// Lookup the 'Calendar' feature state managed by NgRx
const getCalendarState =
  createFeatureSelector<CalendarState>(CALENDAR_FEATURE_KEY);

const getLoaded = createSelector(
  getCalendarState,
  (state: CalendarState) => state.loaded
);
const getError = createSelector(
  getCalendarState,
  (state: CalendarState) => state.error
);

const getAllCalendar = createSelector(
  getCalendarState,
  getLoaded,
  (state: CalendarState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getCalendarState,
  (state: CalendarState) => state.selectedId
);
const getSelectedCalendar = createSelector(
  getAllCalendar,
  getSelectedId,
  (calendar, id) => {
    const result = calendar.find((it) => it["id"] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

const getCalendarFilterFieldsLoaded = createSelector(
  getCalendarState,
  (state: CalendarState) => state.calendarFilterFieldsLoaded
);

const getCalendarFilterFields = createSelector(
  getCalendarState,
  getCalendarFilterFieldsLoaded,
  (state: CalendarState, isLoaded) =>
    isLoaded
      ? [
          ...values(state.calendarFilterFields.generalFields).map((item) => ({
            ...item,
            category: "GENERAL",
          })),
          ...values(state.calendarFilterFields.provisionFields).map((item) => ({
            ...item,
            category: "PROVISION",
          })),
        ]
      : []
);

const getCalendarFilterFieldsLoadError = createSelector(
  getCalendarState,
  (state: CalendarState) => state.calendarFilterFieldsLoadError
);

const getGroupedCalendarFilterFields = createSelector(
  getCalendarState,
  getCalendarFilterFieldsLoaded,
  (state: CalendarState, isLoaded) =>
    isLoaded
      ? [
          {
            title: "General",
            items: values(state.calendarFilterFields.generalFields),
          },
          {
            title: "Provisions",
            items: values(state.calendarFilterFields.provisionFields),
          },
        ]
      : []
);

const getProvisionsOperator = createSelector(
  getCalendarState,
  getCalendarFilterFieldsLoaded,
  (state: CalendarState, isLoaded) => (isLoaded ? state.provisionsOperator : {})
);

const getCalendarDisplayableFieldsLoaded = createSelector(
  getCalendarState,
  (state: CalendarState) => state.calendarDisplayableFieldsLoaded
);

const getCalendarDisplayableFields = createSelector(
  getCalendarState,
  getCalendarDisplayableFieldsLoaded,
  (state: CalendarState, isLoaded) =>
    isLoaded
      ? [
          ...values(state.calendarDisplayableFields.generalFields).map(
            (item) => ({ ...item, category: "GENERAL" })
          ),
          ...values(state.calendarDisplayableFields.provisionFields).map(
            (item) => ({ ...item, category: "PROVISION" })
          ),
        ]
      : []
);

const getGroupedCalendarDisplayableFieldsList = createSelector(
  getCalendarState,
  getCalendarDisplayableFieldsLoaded,
  (state: CalendarState, isLoaded) =>
    isLoaded
      ? [
          {
            title: "General",
            items: values(state.calendarDisplayableFields.generalFields),
          },
          {
            title: "Provisions",
            items: values(state.calendarDisplayableFields.provisionFields),
          },
        ]
      : []
);

const getCalendarDisplayableFieldsLoadError = createSelector(
  getCalendarState,
  (state: CalendarState) => state.calendarDisplayableFieldsLoadError
);

const getSearchCalendarFinalAbstractsResultLoaded = createSelector(
  getCalendarState,
  (state: CalendarState) => state.searchCalendarFinalAbstractsResultLoaded
);

const isSearchCalendarFinalAbstractsLoading = createSelector(
  getCalendarState,
  (state: CalendarState) => state.isSearchCalendarFinalAbstractsLoading
);

const getSearchCalendarFinalAbstractsResult = createSelector(
  getCalendarState,
  getSearchCalendarFinalAbstractsResultLoaded,
  (state: CalendarState, isLoaded) =>
    isLoaded ? state.searchCalendarFinalAbstractsResult : undefined
);

const searchCalendarFinalAbstractsResultMap = createSelector(
  getCalendarState,
  getSearchCalendarFinalAbstractsResultLoaded,
  (state: CalendarState, isLoaded) =>
    isLoaded ? state.searchCalendarFinalAbstractsResultMap : undefined
);

const getSearchCalendarFinalAbstractsResultLoadError = createSelector(
  getCalendarState,
  (state: CalendarState) => state.searchCalendarFinalAbstractsResultLoadError
);

const getSelectedCalendarFilters = createSelector(
  getCalendarState,
  (state: CalendarState) => state.selectedFilters
);

const getSelectedCalendarFields = createSelector(
  getCalendarState,
  (state: CalendarState) => state.selectedFields
);

const isLoadCalendarsLoading = createSelector(
  getCalendarState,
  (calendarState: CalendarState) => calendarState.isLoadCalendarsLoading
);

const calendarsLoaded = createSelector(
  getCalendarState,
  isLoadCalendarsLoading,
  (calendarState: CalendarState, isLoading) =>
    !isLoading && calendarState.calendarsLoaded
);

const getCalendarList = createSelector(
  getCalendarState,
  calendarsLoaded,
  (calendarState: CalendarState, isLoaded) =>
    isLoaded ? calendarState.calendarList : undefined
);

const isSaveCalendarPending = createSelector(
  getCalendarState,
  (calendarState: CalendarState) => calendarState.isSaveCalendarPending
);

const saveCalendarSucceeded = createSelector(
  getCalendarState,
  isSaveCalendarPending,
  (calendarState: CalendarState, isLoading) =>
    !isLoading && calendarState.saveCalendarSucceeded
);

const isSelectedCalendarDetailLoading = createSelector(
  getCalendarState,
  (calendarState: CalendarState) =>
    calendarState.isSelectedCalendarDetailLoading
);

const isSelectedCalendarLoaded = createSelector(
  getCalendarState,
  isSelectedCalendarDetailLoading,
  (calendarState: CalendarState, isLoading) =>
    !isLoading && calendarState.isSelectedCalendarLoaded
);

const isSelectedCalendarLoadError = createSelector(
  getCalendarState,
  (calendarState: CalendarState) => calendarState.selectedCalendarLoadError
);

const getSelectedCalendarDetail = createSelector(
  getCalendarState,
  isSelectedCalendarLoaded,
  (calendarState: CalendarState, loaded) =>
    loaded ? calendarState.selectedCalendarDetail : undefined
);

const calendarsDetailMap = createSelector(
  getCalendarState,
  isSelectedCalendarLoaded,
  (calendarState: CalendarState, loaded) =>
    loaded ? calendarState.calendarsDetailMap : undefined
);

const isDeleteSelectedCalendarPending = createSelector(
  getCalendarState,
  (calendarState: CalendarState) =>
    calendarState.isDeleteSelectedCalendarPending
);

const isDeleteSelectedCalendarSucceeded = createSelector(
  getCalendarState,
  isDeleteSelectedCalendarPending,
  (calendarState: CalendarState, isLoading) =>
    !isLoading && calendarState.isDeleteSelectedCalendarSucceeded
);

const isSaveCalendarQueryPending = createSelector(
  getCalendarState,
  (calendarState: CalendarState) => calendarState.isSaveCalendarQueryPending
);

const isSaveCalendarQuerySucceeded = createSelector(
  getCalendarState,
  isSaveCalendarQueryPending,
  (calendarState: CalendarState, isLoading) =>
    !isLoading && calendarState.isSaveCalendarQuerySucceeded
);

const isLoadCalendarQueriesPending = createSelector(
  getCalendarState,
  (calendarState: CalendarState) => calendarState.isLoadCalendarQueriesPending
);

const isCalendarQueriesLoaded = createSelector(
  getCalendarState,
  isLoadCalendarQueriesPending,
  (calendarState: CalendarState, isLoading) =>
    !isLoading && calendarState.isCalendarQueriesLoaded
);

const getSelectedCalendarQueries = createSelector(
  getCalendarState,
  isCalendarQueriesLoaded,
  (calendarState: CalendarState, isLoaded) =>
    isLoaded && calendarState.selectedCalendarQueries
);

const isDeleteSelectedCalendarQueryPending = createSelector(
  getCalendarState,
  (calendarState: CalendarState) =>
    calendarState.isDeleteSelectedCalendarQueryPending
);

const isDeleteSelectedCalendarQuerySucceeded = createSelector(
  getCalendarState,
  isDeleteSelectedCalendarQueryPending,
  (calendarState: CalendarState, isLoading) =>
    !isLoading && calendarState.isDeleteSelectedCalendarQuerySucceeded
);

const isUpdateSelectedCalendarQueryActivePending = createSelector(
  getCalendarState,
  (calendarState: CalendarState) =>
    calendarState.isUpdateSelectedCalendarQueryActivePending
);

const isUpdateSelectedCalendarQueryActiveSucceeded = createSelector(
  getCalendarState,
  isUpdateSelectedCalendarQueryActivePending,
  (calendarState: CalendarState, isLoading) =>
    !isLoading && calendarState.isUpdateSelectedCalendarQueryActiveSucceeded
);

const isUpdateCalendarQueryPending = createSelector(
  getCalendarState,
  (calendarState: CalendarState) => calendarState.isUpdateCalendarQueryPending
);

const isUpdateCalendarQuerySucceeded = createSelector(
  getCalendarState,
  isUpdateCalendarQueryPending,
  (calendarState: CalendarState, isLoading) =>
    !isLoading && calendarState.isUpdateCalendarQuerySucceeded
);

const isDeleteMultipleCalendarPending = createSelector(
  getCalendarState,
  (calendarState: CalendarState) =>
    calendarState.isDeleteMultipleCalendarPending
);

const deleteMultipleCalendarSucceeded = createSelector(
  getCalendarState,
  isDeleteMultipleCalendarPending,
  (calendarState: CalendarState, isLoading) =>
    !isLoading && calendarState.deleteMultipleCalendarSucceeded
);

const isUpdateCalendarPending = createSelector(
  getCalendarState,
  (calendarState: CalendarState) => calendarState.isUpdateCalendarPending
);

const updateCalendarSucceeded = createSelector(
  getCalendarState,
  isUpdateCalendarPending,
  (calendarState: CalendarState, isPending) =>
    !isPending && calendarState.updateCalendarSucceeded
);

export const calendarQuery = {
  getLoaded,
  getError,
  getAllCalendar,
  getSelectedCalendar,

  getCalendarFilterFieldsLoaded,
  getCalendarFilterFields,
  getCalendarFilterFieldsLoadError,
  getGroupedCalendarFilterFields,
  getProvisionsOperator,

  getCalendarDisplayableFieldsLoaded,
  getCalendarDisplayableFields,
  getCalendarDisplayableFieldsLoadError,
  getGroupedCalendarDisplayableFieldsList,

  getSearchCalendarFinalAbstractsResultLoaded,
  isSearchCalendarFinalAbstractsLoading,
  getSearchCalendarFinalAbstractsResult,
  searchCalendarFinalAbstractsResultMap,
  getSearchCalendarFinalAbstractsResultLoadError,

  getSelectedCalendarFilters,
  getSelectedCalendarFields,

  isLoadCalendarsLoading,
  calendarsLoaded,
  getCalendarList,

  isSaveCalendarPending,
  saveCalendarSucceeded,

  isUpdateCalendarPending,
  updateCalendarSucceeded,

  isSelectedCalendarDetailLoading,
  isSelectedCalendarLoaded,
  isSelectedCalendarLoadError,
  getSelectedCalendarDetail,
  calendarsDetailMap,

  isDeleteSelectedCalendarPending,
  isDeleteSelectedCalendarSucceeded,

  isSaveCalendarQueryPending,
  isSaveCalendarQuerySucceeded,
  isLoadCalendarQueriesPending,
  isCalendarQueriesLoaded,
  getSelectedCalendarQueries,
  isDeleteSelectedCalendarQueryPending,
  isDeleteSelectedCalendarQuerySucceeded,
  isUpdateSelectedCalendarQueryActivePending,
  isUpdateSelectedCalendarQueryActiveSucceeded,
  isUpdateCalendarQueryPending,
  isUpdateCalendarQuerySucceeded,

  isDeleteMultipleCalendarPending,
  deleteMultipleCalendarSucceeded,
};
