/* tslint:disable */
/* eslint-disable */
import { PaginationDataModel } from "./pagination-data-model";
import { ReportReminderResponseEntryDtoModel } from "./report-reminder-response-entry-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject(
  "OApiMultiEntityContainerResponseReportReminderResponseEntryDtoModel"
)
export class OApiMultiEntityContainerResponseReportReminderResponseEntryDtoModel {
  @JsonProperty("items", [ReportReminderResponseEntryDtoModel], true)
  items?: Array<ReportReminderResponseEntryDtoModel> = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
