import { FetchBatchApiService } from "../../../backend/fetch/fetch-batch-api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ListResponseModel } from "@@intelease/web/intelease/models";
import { SortDirectionEnum } from "@@intelease/web/common/enums/sort-direction.enum";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";

@Injectable({
  providedIn: "root",
})
export class FetchBatchUserService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly USERS_URL = "/users";
  private static readonly ACCOUNTS_URL = "/accounts";

  constructor(private fetchBatchApiService: FetchBatchApiService) {}

  public getAccountUsers<T extends object>(
    accountUid: string,
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    return this.fetchBatchApiService.sendBatchRequest(
      FetchBatchUserService.API_VERSION_1,
      `${FetchBatchUserService.ACCOUNTS_URL}/${accountUid}${FetchBatchUserService.USERS_URL}`,
      view,
      classRef,
      "email::Email Address::" +
        PROVISIONS_DATA_CONST.STRING.name +
        "::" +
        SortDirectionEnum.ASC
    );
  }

  public getAllUsersInTheCurrentUserAccount<T extends object>(
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    return this.fetchBatchApiService.sendBatchRequest(
      FetchBatchUserService.API_VERSION_1,
      `${FetchBatchUserService.ACCOUNTS_URL}${FetchBatchUserService.USERS_URL}`,
      view,
      classRef,
      "email::Email Address::" +
        PROVISIONS_DATA_CONST.STRING.name +
        "::" +
        SortDirectionEnum.ASC
    );
  }
}
