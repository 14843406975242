import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision/src";
import {
  MENTION_DELETED,
  ProvisionOptionSourceEnum,
} from "@@intelease/web/abstract-review/src/lib/models/provision-review.model";
import { PartialValProvisionValueModel } from "@@intelease/web/common/models";
import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  SelectMentionConfirmModalComponent,
  SelectMentionConfirmModalInputModel,
  SelectMentionConfirmModalOutputModel,
} from "./select-mention-confirm-modal.component";

export interface RelevantMentions {
  mentionUid?: string;
  preMentionUid?: string;
  postMentionUid?: string;
}

interface MentionOrPreMentionAndPostMentionUid {
  relevantMentions: RelevantMentions;
  selectedMentionIndex?: number;
  mentionName?: string;
  isCanceled: boolean;
}

interface MinimalProvisionOption {
  value: string;
  label: string;
}

enum MENTION_SEARCH_DIRECTION {
  FORWARD = "forward",
  BACKWARD = "backward",
}

@Injectable({ providedIn: "root" })
export class SelectMentionConfirmModalService {
  constructor(private matDialog: MatDialog) {}

  public openModalAndAskRelevantMentionUidToFillIn(
    multiPdfProv: FullValMultiPdfProvModel
  ): Observable<MentionOrPreMentionAndPostMentionUid> {
    const provisionName = multiPdfProv.multiplePdfProvision.name;
    let hasEmptyProvisionMention = false;
    const selectableMentionsList: MinimalProvisionOption[] =
      multiPdfProv.multiplePdfProvision.options.map((item, inx) => {
        if (!item.value) {
          hasEmptyProvisionMention = true;
        }
        return {
          value: item.uid,
          label: inx === 0 ? provisionName : provisionName + " " + (inx + 1),
        } as MinimalProvisionOption;
      });

    const modalInput = new SelectMentionConfirmModalInputModel();
    modalInput.payload = {
      allowCreateNew: !hasEmptyProvisionMention,
      optionItems: selectableMentionsList,
    };

    return this.matDialog
      .open<
        SelectMentionConfirmModalComponent,
        SelectMentionConfirmModalInputModel,
        SelectMentionConfirmModalOutputModel
      >(SelectMentionConfirmModalComponent, { data: modalInput })
      .afterClosed()
      .pipe(
        map((res) => {
          if (res.type === ModalsResponseTypeEnum.DISMISS) {
            return {
              isCanceled: true,
              relevantMentions: {},
            } as MentionOrPreMentionAndPostMentionUid;
          }
          if (res.data.createNew) {
            return {
              ...this.getRelevantMentions(multiPdfProv, undefined),
              mentionName: res?.data?.mentionName,
            };
          } else {
            return {
              ...this.getRelevantMentions(
                multiPdfProv,
                res.data.selectedOption.value
              ),
              mentionName: res?.data?.mentionName,
            };
          }
        })
      );
  }

  private isMentionValid(mention: PartialValProvisionValueModel): boolean {
    if (
      mention.source === ProvisionOptionSourceEnum.TEMP_LOCAL ||
      mention[MENTION_DELETED]
    ) {
      return false;
    }
    return true;
  }

  private getNearestValidMention(
    mentions: PartialValProvisionValueModel[],
    startIndex: number,
    searchDirection: MENTION_SEARCH_DIRECTION
  ): PartialValProvisionValueModel {
    if (searchDirection === MENTION_SEARCH_DIRECTION.FORWARD) {
      let inx = startIndex;
      while (inx < mentions.length) {
        if (this.isMentionValid(mentions[inx])) {
          return mentions[inx];
        }
        inx++;
      }
    } else {
      let inx = startIndex;
      while (inx >= 0) {
        if (this.isMentionValid(mentions[inx])) {
          return mentions[inx];
        }
        inx--;
      }
    }
    return undefined;
  }

  private getRelevantMentions(
    multiPdfProv: FullValMultiPdfProvModel,
    selectedMentionUid?: string
  ): MentionOrPreMentionAndPostMentionUid {
    let mentionUid: string;
    let preMentionUid: string;
    let postMentionUid: string;
    let selectedMentionIndex: number;

    const mentions = multiPdfProv.multiplePdfProvision.options || [];

    if (!selectedMentionUid) {
      //user selected the option to create a brand new mention, we will append the new mention at the end of the options list
      //hence might only need last valid element as the preMentionUid is required if it exists, otherwise undefined
      preMentionUid =
        this.getNearestValidMention(
          mentions,
          mentions.length - 1,
          MENTION_SEARCH_DIRECTION.BACKWARD
        )?.uid || undefined;
    } else {
      mentionUid = selectedMentionUid;
      selectedMentionIndex = mentions.findIndex(
        (mention) => mention.uid === mentionUid
      );

      if (!this.isMentionValid(mentions[selectedMentionIndex])) {
        mentionUid = undefined;
        preMentionUid =
          this.getNearestValidMention(
            mentions,
            selectedMentionIndex - 1,
            MENTION_SEARCH_DIRECTION.BACKWARD
          )?.uid || undefined;
        postMentionUid =
          this.getNearestValidMention(
            mentions,
            selectedMentionIndex + 1,
            MENTION_SEARCH_DIRECTION.FORWARD
          )?.uid || undefined;
      }
    }

    return {
      relevantMentions: {
        mentionUid,
        preMentionUid,
        postMentionUid,
      },
      selectedMentionIndex,
      isCanceled: false,
    } as MentionOrPreMentionAndPostMentionUid;
  }
}
