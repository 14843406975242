/* tslint:disable */
/* eslint-disable */
import { ActualDocPdfViewModel } from "./actual-doc-pdf-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocPdfViewModel")
export class DocPdfViewModel {
  @JsonProperty("abstractUid", String, true)
  abstractUid?: string = "";

  @JsonProperty("actualDocPdfView", ActualDocPdfViewModel, true)
  actualDocPdfView?: ActualDocPdfViewModel = undefined;

  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  @JsonProperty("docSetPdfViewUid", String, true)
  docSetPdfViewUid?: string = "";

  @JsonProperty("pagePdfViewUids", [String], true)
  pagePdfViewUids?: Array<string> = [];
}
