export enum ProvisionTypeEnum {
  DATE = "DATE",
  LOCAL_DATE = "LOCAL_DATE",
  COMMON_NOUN = "COMMON_NOUN",
  PROPER_NOUN = "PROPER_NOUN",
  STRING = "STRING",
  MONEY = "MONEY",
  ADDRESS = "ADDRESS",
  DOUBLE = "DOUBLE",
  SINGLE_CAT = "SINGLE_CAT",
  MULTIPLE_CAT = "MULTIPLE_CAT",
  TABLE = "TABLE",
  YEAR = "YEAR",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  TIME_PLAN = "TIME_PLAN",
  NESTED_GROUP = "NESTED_GROUP",
}
