/* tslint:disable */
/* eslint-disable */
import { JsonNodeModel } from "./json-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionMentionDtoModel")
export class ProvisionMentionDtoModel {
  @JsonProperty("id", String, true)
  id?: string = "";

  @JsonProperty("value", Any, true)
  value?: any = undefined;

  @JsonProperty("valueAsString", String, true)
  valueAsString?: string = "";
}
