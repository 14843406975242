import { JsonObject, JsonProperty } from "json2typescript";
import { DateModel } from "./date.model";

@JsonObject("DateTimeModel")
export class DateTimeModel extends DateModel {
  @JsonProperty("hour", Number, true)
  hour: number = undefined;

  @JsonProperty("minute", Number, true)
  minute: number = undefined;

  @JsonProperty("second", Number, true)
  second: number = undefined;
}
