import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { FeaturesToggleService, FeaturesToggle } from ".";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class MainDrawerService {
  private static readonly PATHS_TO_USE_PUSH_SIDEBAR_MODE = [
    "/drive/owned",
    "/drive/shared",
    "/drive/all",
    "/drive/documents",
    "/drive/deleted",
    "/drive/search",
    "/drive/folders/",
    "/tasks/list",
    "/provision-forms/forms",
    "/projects/list",
    "/reports/list",
    "/dashboards/",
    "/settings/",
  ];
  private _openSidebar$: Subject<void> = new Subject<void>();
  private _closeSidebar$: Subject<void> = new Subject<void>();
  public toolbarDisabled$: Subject<boolean> = new Subject<boolean>();
  public sidebarDisabled$: Subject<boolean> = new Subject<boolean>();
  public isOpen: boolean;
  public sidebarMode: "over" | "push" | "side" = "over";

  constructor(private router: Router) {
    this._openSidebar$
      .asObservable()
      .pipe(takeUntilDestroyed())
      .subscribe(() => (this.isOpen = true));
    this._closeSidebar$
      .asObservable()
      .pipe(takeUntilDestroyed())
      .subscribe(() => (this.isOpen = false));
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntilDestroyed())
      .subscribe((event) => {
        const url = router.routerState.snapshot.url;
        if (url) {
          const queryParamsStartIndex = url.indexOf("?");
          const onlyUrl =
            queryParamsStartIndex === -1
              ? url
              : url.substring(0, queryParamsStartIndex);
          if (
            MainDrawerService.PATHS_TO_USE_PUSH_SIDEBAR_MODE.find((path) =>
              onlyUrl.startsWith(path)
            )
          ) {
            this.sidebarMode = "side";
          } else {
            if (FeaturesToggleService.getValue(FeaturesToggle.LEFT_MENU)) {
              this.sidebarMode = "side";
              return;
            }

            this.sidebarMode = "over";
            this.closeSidebar();
          }
        }
      });
  }

  public getTopBarHeightOffset(): number {
    if (this.router.url.startsWith("/queues")) {
      if (!this.router.url.startsWith("/queues/list")) {
        // all the queue details page has a different topbar with an approx height of 190
        return 156;
      }
    }

    //default top bar height offset
    return 48;
  }

  openSidebar(): void {
    this._openSidebar$.next();
  }

  closeSidebar(): void {
    if (FeaturesToggleService.getValue(FeaturesToggle.LEFT_MENU)) {
      return;
    }

    this._closeSidebar$.next();
  }

  enableToolbar(): void {
    this.toolbarDisabled$.next(false);
  }

  disableToolbar(): void {
    this.toolbarDisabled$.next(true);
  }

  enableSidebar(): void {
    this.sidebarDisabled$.next(false);
  }

  disableSidebar(): void {
    this.sidebarDisabled$.next(true);
  }

  onOpenSidebar$(): Observable<void> {
    return this._openSidebar$.asObservable();
  }

  onCloseSidebar$(): Observable<void> {
    return this._closeSidebar$.asObservable();
  }

  checkForSidebarToBeOpened(url: string) {
    if (
      MainDrawerService.PATHS_TO_USE_PUSH_SIDEBAR_MODE.find((path) =>
        url.startsWith(path)
      )
    ) {
      this.openSidebar();
    }
  }
}
