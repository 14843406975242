<div class="search-box-container">
  <div class="search-container">
    <div class="search-input">
      <mat-icon svgIcon="icon-search"></mat-icon>
      <mat-form-field>
        <input
          matInput
          type="text"
          placeholder="Search ..."
          name="searchField"
          [(ngModel)]="searchTerm"
          (keyup)="displaySuggestions()"
        />
        <button
          mat-button
          *ngIf="searchTerm"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onReset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="search-result">
      <div class="suggestion-list">
        <div
          class="suggestion-describe-wrapper"
          *ngIf="suggestions && suggestions.length > 0"
        >
          <div class="suggestion-describe">
            <span class="suggestion-describe-text">
              {{ resultDescription }}
            </span>
          </div>
        </div>

        <div style="max-height: 350px; overflow-y: scroll">
          <itls-header-common-suggestionitem
            *ngFor="let suggestion of suggestions; let i = index"
            [suggestion]="suggestion"
            style="cursor: pointer"
            (click)="handleSuggestionClick(suggestion)"
            id="search-suggestion-item-{{ i }}"
            tabindex="0"
          >
          </itls-header-common-suggestionitem>
        </div>
      </div>
    </div>
  </div>
</div>
