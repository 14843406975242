<div class="add-calendar-container">
  <h1 mat-dialog-title>Create a New Report</h1>
  <form>
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          [(ngModel)]="reportName"
          matInput
          itlsFocusIn
          [ngModelOptions]="{ standalone: true }"
          (keyup)="
            isValidateFields = reportName === '' || reportName === undefined
          "
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input
          [(ngModel)]="description"
          matInput
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>
    <div
      mat-dialog-actions
      style="display: flex; align-items: center; justify-content: space-around"
    >
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <div
        [matTooltip]="
          isValidateFields ? 'The report name is required!' : 'Continue'
        "
      >
        <button
          mat-flat-button
          color="primary"
          type="submit"
          class="continue-button"
          [class.loading]="isSavingReport"
          (click)="onSaveReportClick()"
          [disabled]="isValidateFields || isSavingReport"
        >
          <itls-pg-progress
            class="progress abstractor-name-progress"
            style="
              height: 17px;
              width: 16px;
              margin-right: 5px;
              background: transparent;
              margin-bottom: 0;
            "
            [type]="'circle'"
            [thick]="true"
            [indeterminate]="true"
            *ngIf="isSavingReport"
          ></itls-pg-progress>
          Continue
        </button>
      </div>
    </div>
  </form>
</div>
