/* tslint:disable */
/* eslint-disable */
import { SaveHighlightMentionV3ApiDtoModel } from "./save-highlight-mention-v-3-api-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqSaveHighlightMentionV3DtoModel")
export class OApiReqSaveHighlightMentionV3DtoModel {
  @JsonProperty("data", SaveHighlightMentionV3ApiDtoModel, true)
  data?: SaveHighlightMentionV3ApiDtoModel = undefined;
}
