/* tslint:disable */
/* eslint-disable */
import { NavigationDirectoryDtoModel } from "./navigation-directory-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("NodeSearchResultNavigationDtoModel")
export class NodeSearchResultNavigationDtoModel {
  @JsonProperty("category", Any, true)
  category?:
    | "ALL"
    | "DELETED"
    | "FAVORITES"
    | "OWNED"
    | "SHARED"
    | "DOCUMENTS"
    | "GOOGLE_DRIVE" = undefined;

  @JsonProperty("categoryTitle", String, true)
  categoryTitle?: string = "";

  @JsonProperty("items", [NavigationDirectoryDtoModel], true)
  items?: Array<NavigationDirectoryDtoModel> = [];
}
