<div
  class="provision-notes"
  *ngIf="recordReviewMode === 'V1' || recordReviewMode === undefined"
>
  <ng-container *ngxPermissionsOnly="AUTH.UPDATE_RECORD">
    <div class="loading" *ngIf="isLoading">
      <mat-progress-spinner
        diameter="50"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>
    <div class="add-comment mt-8">
      <mat-form-field
        style="margin-bottom: -20px"
        *ngIf="note.length || editable"
      >
        <mat-label>Add Note</mat-label>
        <textarea
          matInput
          (blur)="save.emit(note)"
          [(ngModel)]="note"
          cdkTextareaAutosize
          placeholder="Add new note..."
          #message
          [disabled]="!editable"
          [maxlength]="limitCharacters"
          itlsFocusIn
          style="line-height: 20px; font-size: 14px"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        ></textarea>
        <mat-hint align="start"><strong></strong></mat-hint>
        <mat-hint align="end" *ngIf="editable"
          >{{ message.value.length }} / {{ limitCharacters }}</mat-hint
        >
      </mat-form-field>
    </div>
  </ng-container>
</div>
<div class="provision-notes-v2" *ngIf="recordReviewMode === 'V2'">
  <ng-container *ngxPermissionsOnly="AUTH.UPDATE_RECORD">
    <div class="add-comment mt-8">
      <mat-form-field style="margin-bottom: -20px" #fieldV2>
        <label>Notes</label>
        <textarea
          matInput
          (blur)="save.emit(note)"
          [(ngModel)]="note"
          cdkTextareaAutosize
          placeholder="Add notes..."
          #message
          [disabled]="!editable"
          [maxlength]="limitCharacters"
          style="line-height: 20px; font-size: 12px"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        ></textarea>
        <mat-hint align="start"><strong></strong></mat-hint>
        <mat-hint align="end" *ngIf="editable"
          >{{ message.value.length }} / {{ limitCharacters }}</mat-hint
        >
      </mat-form-field>
    </div>
  </ng-container>
</div>
