import { EditApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EditProfileUserModel } from "@@intelease/web/common/models/user/edit-profile-user.model";
import { ProfileUserModel } from "@@intelease/web/common/models/user";
import { RestClient } from "@@intelease/web/utils";

@Injectable({
  providedIn: "root",
})
export class EditUserService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly USERS_URL = "/users";
  private static readonly TERMS_OF_SERVICE_URL = "/termsOfService";
  private static readonly PROFILE = "profile";
  private static readonly CHANGE_PASSWORD = "changePassword";
  private static readonly ACCOUNT_ROLE = "/accountRole";

  constructor(
    private editApiService: EditApiService,
    private restClient: RestClient
  ) {}

  editUserToS<T extends object>(
    userUid: string,
    loginData: any,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    return this.editApiService.sendRequest(
      EditUserService.API_VERSION_1,
      EditUserService.USERS_URL,
      userUid,
      loginData,
      false,
      view,
      classRef,
      EditUserService.TERMS_OF_SERVICE_URL
    );
  }

  editProfile(editProfileUserModel: EditProfileUserModel): Observable<any> {
    return this.editApiService.sendRequestNoView(
      EditUserService.API_VERSION_1,
      EditUserService.USERS_URL,
      EditUserService.PROFILE,
      editProfileUserModel,
      true
    );
  }

  changePassword(currentPassword: string, password: string): Observable<any> {
    const data = {
      currentPassword: currentPassword,
      newPassword: password,
    };
    return this.editApiService.sendRequestNoView(
      EditUserService.API_VERSION_1,
      EditUserService.USERS_URL,
      EditUserService.CHANGE_PASSWORD,
      data,
      false
    );
  }

  editAccountRoleForUser(user: ProfileUserModel, accountRoleType: string) {
    return this.restClient.sendPutRequestNoView(
      EditUserService.API_VERSION_1,
      `${EditUserService.USERS_URL}/${user.uid}${EditUserService.ACCOUNT_ROLE}`,
      {
        returnParams: {
          view: "full",
        },
        replace: false,
        data: {
          accountRole: accountRoleType,
        },
      }
    );
  }
}
