import { forEach, includes, split } from "lodash";

export const convertToPascalCase = (value: string) => {
  if (!value) {
    return;
  }

  if (!includes(value, " ")) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  const words = split(value, " ");
  let result = "";

  forEach(words, (word) => {
    result += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
  });
  return result;
};
