export enum AppAuthorities {
  // operational level
  ADD_PROVISION_INFO = "ADD_PROVISION_INFO",
  // For dashboards.
  CREATE_DASHBOARD = "CREATE_DASHBOARD",
  READ_DASHBOARD = "READ_DASHBOARD",
  UPDATE_DASHBOARD = "UPDATE_DASHBOARD",
  DELETE_DASHBOARD = "DELETE_DASHBOARD",
  // For records.
  CREATE_RECORD = "CREATE_RECORD",
  READ_RECORD = "READ_RECORD",
  UPDATE_RECORD = "UPDATE_RECORD",
  DELETE_RECORD = "DELETE_RECORD",
  DOCUMENT_COMPARISON = "DOCUMENT_COMPARISON",
  // For doc sets.
  CREATE_DOC_SET = "CREATE_DOC_SET",
  READ_DOC_SET = "READ_DOC_SET",
  UPDATE_DOC_SET = "UPDATE_DOC_SET",
  DELETE_DOC_SET = "DELETE_DOC_SET",
  // For provision forms.
  CREATE_PROVISION_FORM = "CREATE_PROVISION_FORM",
  READ_PROVISION_FORM = "READ_PROVISION_FORM",
  UPDATE_PROVISION_FORM = "UPDATE_PROVISION_FORM",
  DELETE_PROVISION_FORM = "DELETE_PROVISION_FORM",
  // For reports.
  CREATE_REPORT = "CREATE_REPORT",
  READ_REPORT = "READ_REPORT",
  UPDATE_REPORT = "UPDATE_REPORT",
  DELETE_REPORT = "DELETE_REPORT",
  // For calendars.
  CREATE_CALENDAR = "CREATE_CALENDAR",
  READ_CALENDAR = "READ_CALENDAR",
  UPDATE_CALENDAR = "UPDATE_CALENDAR",
  DELETE_CALENDAR = "DELETE_CALENDAR",
  // For projects.
  CREATE_PROJECT = "CREATE_PROJECT",
  READ_PROJECT = "READ_PROJECT",
  UPDATE_PROJECT = "UPDATE_PROJECT",
  DELETE_PROJECT = "DELETE_PROJECT",
  // For queues.
  CREATE_QUEUE = "CREATE_QUEUE",
  READ_QUEUE = "READ_QUEUE",
  UPDATE_QUEUE = "UPDATE_QUEUE",
  DELETE_QUEUE = "DELETE_QUEUE",
  // For tasks.
  CREATE_TASK = "CREATE_TASK",
  READ_TASK = "READ_TASK",
  UPDATE_TASK = "UPDATE_TASK",
  DELETE_TASK = "DELETE_TASK",

  // entity level
  READ = "READ",
  EDIT = "EDIT",
  COMPLETE = "COMPLETE",
  DELETE = "DELETE",
  EXPORT = "EXPORT",
  FULL_EXPORT = "FULL_EXPORT",
  HISTORY_EXPORT = "HISTORY_EXPORT",
  LOCK = "LOCK",
  EXPLORE = "EXPLORE",
  MOVE = "MOVE",
  COMMENT = "COMMENT",
  SHARE = "SHARE",
}
