import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "il-table-head",
  templateUrl: "./table-head.component.html",
  styleUrls: ["./table-head.component.scss"],
})
export class TableHeadComponent implements OnChanges {
  disabledButtons = {
    addCL: false,
    addCR: false,
    remove: false,
    mergeCL: false,
    mergeCR: false,
    moveCL: false,
    moveCR: false,
    duplicateCol: true,
  };
  @Input()
  strictColumn: boolean;
  @Input()
  allowMerge: boolean;
  @Input() inputData: {
    data: string | number;
    colIndex: number;
    rowIndex: number;
  };
  @Input() readOnly: boolean;
  @Input()
  isRequired: boolean;
  @Input() colLength: number;
  @Output() addColumnLeft: EventEmitter<any> = new EventEmitter();
  @Output() addColumnRight: EventEmitter<any> = new EventEmitter();
  @Output() duplicateColumn: EventEmitter<any> = new EventEmitter();
  @Output() removeColumn: EventEmitter<any> = new EventEmitter();
  @Output() mergeColumnRight: EventEmitter<any> = new EventEmitter();
  @Output() mergeColumnLeft: EventEmitter<any> = new EventEmitter();
  @Output() moveColumnRight: EventEmitter<any> = new EventEmitter();
  @Output() moveColumnLeft: EventEmitter<any> = new EventEmitter();

  @ViewChild("matMenu1Trigger") matMenu1Trigger: any;

  ngOnChanges(changes: SimpleChanges): void {
    this.checkButtonsDisabled();
  }

  onAddColumnLeft() {
    this.addColumnLeft.emit(this.inputData.colIndex);
    this.checkButtonsDisabled();
  }

  onAddColumnRight() {
    this.addColumnRight.emit(this.inputData.colIndex + 1);
    this.checkButtonsDisabled();
  }

  onDuplicateColumn() {
    this.duplicateColumn.emit(this.inputData.colIndex);
    this.checkButtonsDisabled();
  }

  onRemoveColumn() {
    this.removeColumn.emit(this.inputData.colIndex);
    this.checkButtonsDisabled();
  }

  onMergeColumnRight() {
    this.mergeColumnRight.emit(this.inputData.colIndex);
    this.checkButtonsDisabled();
  }

  onMergeColumnLeft() {
    this.mergeColumnLeft.emit(this.inputData.colIndex);
    this.checkButtonsDisabled();
  }

  onMoveColumnRight() {
    this.moveColumnRight.emit(this.inputData.colIndex);
    this.checkButtonsDisabled();
  }

  onMoveColumnLeft() {
    this.moveColumnLeft.emit(this.inputData.colIndex);
    this.checkButtonsDisabled();
  }

  private checkButtonsDisabled() {
    setTimeout(() => {
      this.disabledButtons = {
        addCL: !!this.strictColumn || !!this.readOnly,
        addCR: !!this.strictColumn || !!this.readOnly,
        remove: !!this.strictColumn || !!this.readOnly,
        mergeCL: !!this.strictColumn || !!this.readOnly,
        mergeCR: !!this.strictColumn || !!this.readOnly,
        moveCL: !!this.strictColumn || !!this.readOnly,
        moveCR: !!this.strictColumn || !!this.readOnly,
        duplicateCol: !!this.strictColumn || !!this.readOnly,
      };
      if (this.strictColumn || this.readOnly) {
        return;
      }
      if (this.colLength <= 2) {
        this.disabledButtons.mergeCL = true;
        this.disabledButtons.mergeCR = true;
        this.disabledButtons.moveCL = true;
        this.disabledButtons.moveCR = true;
        this.disabledButtons.remove = true;
      } else if (this.inputData.colIndex <= 1) {
        this.disabledButtons.mergeCL = true;
        this.disabledButtons.moveCL = true;
      } else if (this.inputData.colIndex >= this.colLength - 1) {
        this.disabledButtons.mergeCR = true;
        this.disabledButtons.moveCR = true;
      }
      if (this.colLength >= 30) {
        this.disabledButtons.addCL = true;
        this.disabledButtons.addCR = true;
      }
      if (!this.allowMerge) {
        this.disabledButtons.mergeCL = true;
        this.disabledButtons.mergeCR = true;
      }
    }, 100);
  }

  closeMenu() {
    this.matMenu1Trigger.closeMenu();
  }
}
