import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import Tribute from "tributejs";
import {
  Caret,
  Guid,
  InteleaseMentionHelper,
  IntelFullMention,
} from "@@intelease/web/utils";
import { cloneDeep, take } from "lodash";

@Component({
  selector: "il-comment-input",
  templateUrl: "./itls-comment-input.component.html",
  styleUrls: ["./itls-comment-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsCommentInputComponent implements OnInit {
  @Input() mentionList: { key: string; value: string; email: string }[] = [];
  @Input() model: IntelFullMention = { elements: [], mentions: [] };
  @Input() id = Guid.generate();
  @Input() placeholder = "Enter some text here";
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  userMentionMenuSearchInput = "";
  filteredAccountUsers: { key: string; value: string; email: string }[] = [];
  caret: Caret;
  element;
  caretPosition: number;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.initMention();
    }, 100);
  }

  initMention() {
    // console.log(InteleaseMentionHelper.serializeMention(this.model))
    this.element = document.getElementById(this.id);
    const text = `<!--Hi from component Apoorv <span class="mention-text" contenteditable="false" id="U11B0GWRH">Apoorv Khandeval</span> slkdjfsdk <span class="mention-text" contenteditable="false" id="UFNADHU64">Mohammad haji</span> sss-->`;
    this.element.innerHTML = InteleaseMentionHelper.serializeMention(
      this.model
    );
    const tribute = new Tribute({
      // menuContainer: document.getElementById('content'),
      values: this.mentionList,
      lookup: "value",
      menuItemTemplate: function (item) {
        return `<span class="mention-dropdown-item" title="${item.original.email}">${item.original.value} (${item.original.email})</span>`;
      },
      selectTemplate: function (item) {
        if (typeof item === "undefined") return null;
        if (this.range.isContentEditable(this.current.element)) {
          // return item.original.value
          // return (
          //   '<span contenteditable="false"><a href="http://zurb.com" target="_blank" title="' +
          //   item.original.email +
          //   '">' +
          //   item.original.value +
          //   '</a></span>'
          // )
          return `<span class="mention-text" contenteditable="false" id="${item.original.key}">${item.original.value}</span>`;
        }
        return "@" + item.original.value;
      },
      requireLeadingSpace: false,
    });

    this.element.addEventListener("tribute-replaced", (e: any) => {
      this.modelChange.emit(
        InteleaseMentionHelper.deserializeMention(
          this.element,
          this.mentionList
        )
      );
    });
    tribute.attach(this.element);
    this.element.addEventListener("input", (e) => {
      this.modelChange.emit(
        InteleaseMentionHelper.deserializeMention(
          this.element,
          this.mentionList
        )
      );
    });
    this.caret = new Caret(this.element);
    this.cdr.detectChanges();
  }

  onSearchInputChanged(evt) {
    this.reloadFilteredAccountUsers();
  }

  onUserMentionMenuOpened() {
    this.userMentionMenuSearchInput = "";
    this.caretPosition = this.caret.getPos();
    this.reloadFilteredAccountUsers();
  }

  private reloadFilteredAccountUsers() {
    this.filteredAccountUsers = this.mentionList;
    const lowerCasedSearchInput = this.userMentionMenuSearchInput.toLowerCase();
    if (!lowerCasedSearchInput.length) {
      this.filteredAccountUsers = this.mentionList;
    } else {
      this.filteredAccountUsers = this.mentionList.filter((item) =>
        item.value.toLowerCase().includes(lowerCasedSearchInput)
      );
    }
    // select top 5 account users
    this.filteredAccountUsers = take(this.filteredAccountUsers, 5);
  }

  onMentionUserSelected(accountUser) {}

  onMentionItemClick(evt) {
    let newReplyText = cloneDeep(this.element.innerHTML);
    const textToInsert = "aaaaaaaaaaaaaaaaaa";
    if (!newReplyText.length) {
      newReplyText = textToInsert;
    } else {
      let prefix = newReplyText.substr(0, this.caretPosition);
      let postfix = "";
      if (newReplyText.length > this.caretPosition) {
        postfix = " " + newReplyText.substr(this.caretPosition);
      }
      if (!prefix.endsWith(" ")) {
        prefix = prefix + " ";
      }
      if (postfix.length && !postfix.startsWith(" ")) {
        postfix = " " + postfix;
      }
      newReplyText = prefix + textToInsert + postfix;
    }
    // this.element.innerText = newReplyText
    // evt.stopPropagation()
  }
}
