import {
  EditProvisionNotesRespDtoModel,
  FullValMultiPdfProvViewModel,
  MultiPdfProvViewAndDocHighlightInfoApiDtoModel,
  MultiProvisionValueService,
  NestedProvisionSubfieldDtoModel,
  ProvisionMentionService,
} from "@@intelease/api-models/adex-api-model-src";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision/src";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  DeleteExistingProvisionValueV3ParamsModel,
  EditProvisionNotesV2ParamsModel,
  ManualCreateProvisionMentionV2ParamsModel,
  PickSnippetAndReplaceProvisionValueParamsModel,
  UpdateRecordProvisionValueV2ParamsModel,
} from "../models/web-abstraction-page.model";

@Injectable({
  providedIn: "root",
})
export class WebAbstractionPageApiService {
  constructor(
    private provisionMentionService: ProvisionMentionService,
    private multiProvisionValueService: MultiProvisionValueService
  ) {}

  deleteExistingProvisionValueV3(
    params: DeleteExistingProvisionValueV3ParamsModel
  ): Observable<MultiPdfProvViewAndDocHighlightInfoApiDtoModel> {
    return this.provisionMentionService
      .deleteExistingProvisionValueV3(params)
      .pipe(
        map((res) => {
          return {
            ...res?.data,
            multiPdfProv: Json2TypescriptHelper.convertToEntity(
              res?.data?.multiPdfProv,
              FullValMultiPdfProvModel
            ),
          };
        }),
        catchError((err) => err)
      );
  }

  pickSnippetAndReplaceProvisionValue(
    params: PickSnippetAndReplaceProvisionValueParamsModel
  ): Observable<MultiPdfProvViewAndDocHighlightInfoApiDtoModel> {
    return this.provisionMentionService
      .pickSnippetAndReplaceProvisionValue(params)
      .pipe(
        map((res) => {
          return {
            ...Json2TypescriptHelper.convertToEntity(
              res?.data,
              MultiPdfProvViewAndDocHighlightInfoApiDtoModel
            ),
            multiPdfProv: Json2TypescriptHelper.convertToEntity(
              res?.data?.multiPdfProv,
              FullValMultiPdfProvModel
            ),
          };
        }),
        catchError((err) => err)
      );
  }

  editProvisionNotesV2(
    params: EditProvisionNotesV2ParamsModel
  ): Observable<EditProvisionNotesRespDtoModel> {
    return this.multiProvisionValueService.editProvisionNotesV2(params).pipe(
      map((res) => {
        return Json2TypescriptHelper.convertToEntity(
          res.data,
          EditProvisionNotesRespDtoModel
        );
      }),
      catchError((err) => err)
    );
  }

  updateRecordProvisionValueV2(
    params: UpdateRecordProvisionValueV2ParamsModel
  ): Observable<FullValMultiPdfProvModel> {
    return this.provisionMentionService
      .updateRecordProvisionValueV2(params)
      .pipe(
        map((res) => {
          return Json2TypescriptHelper.convertToEntity(
            res?.data?.multiPdfProv,
            FullValMultiPdfProvModel
          );
        })
      );
  }

  manualCreateProvisionMentionV2(
    params: ManualCreateProvisionMentionV2ParamsModel
  ): Observable<{
    newMentionUid?: string;
    multiPdfProv?: FullValMultiPdfProvModel;
  }> {
    return this.provisionMentionService
      .manualCreateProvisionMentionV2(params)
      .pipe(
        map((res) => {
          return {
            multiPdfProv: Json2TypescriptHelper.convertToEntity(
              res.data?.multiPdfProv,
              FullValMultiPdfProvModel
            ),
            newMentionUid:
              res.data.modifiedProvisionMentionUids?.newlyCreated?.[0],
          };
        }),
        catchError((err) => err)
      );
  }

  renameProvisionName(payload: {
    recordUid: string;
    provisionUid: string;
    mentionUid: string;
    body: {
      data: {
        uiProvisionName: string;
      };
    };
  }): Observable<MultiPdfProvViewAndDocHighlightInfoApiDtoModel> {
    return this.provisionMentionService.renameProvisionMention(payload).pipe(
      map((res) =>
        Json2TypescriptHelper.convertToEntity(
          res.data,
          MultiPdfProvViewAndDocHighlightInfoApiDtoModel
        )
      ),
      catchError((err) => err)
    );
  }

  addNestedSubfields(payload: {
    recordUid: string;
    provisionUid: string;
    body: {
      data: {
        additionalSubfields?: Array<NestedProvisionSubfieldDtoModel>;
      };
    };
  }): Observable<FullValMultiPdfProvViewModel> {
    return this.multiProvisionValueService
      .modifyNestedProvisionSubfields(payload)
      .pipe(
        map((res) => res.data),
        catchError((err) => err)
      );
  }
}
