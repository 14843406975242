/* tslint:disable */
/* eslint-disable */
import { IssueListDtoModel } from "./issue-list-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("KanbanBoardDtoModel")
export class KanbanBoardDtoModel {
  @JsonProperty("issueLists", [IssueListDtoModel], true)
  issueLists?: Array<IssueListDtoModel> = [];

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
