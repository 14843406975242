/* tslint:disable */
/* eslint-disable */
import { CreateDocumentCommentDtoModel } from "./create-document-comment-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCreateDocumentCommentDtoModel")
export class OApiReqCreateDocumentCommentDtoModel {
  @JsonProperty("data", CreateDocumentCommentDtoModel, true)
  data?: CreateDocumentCommentDtoModel = undefined;
}
