import { JsonObject, JsonProperty } from "json2typescript";
import { PermissionCheckItemModel } from "@@intelease/web/intelease/models/permission-check-item.model";

@JsonObject("PermissionCheckResponseModel")
export class PermissionCheckResponseModel {
  @JsonProperty("items", [PermissionCheckItemModel])
  items: PermissionCheckItemModel[] = [];

  @JsonProperty("accessibleToAll", Boolean)
  accessibleToAll = false;
}
