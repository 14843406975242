import { Injectable } from "@angular/core";
import { LocalStorageKey } from "../enums/local-storage.keys";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  public get<T>(key: LocalStorageKey): T {
    try {
      return JSON.parse(localStorage.getItem(key as string) as string);
    } catch (e) {
      return localStorage.getItem(key as string) as any;
    }
  }

  public save<T>(key: LocalStorageKey, payload: T): void {
    localStorage.setItem(key, JSON.stringify(payload));
  }

  public clear(key: LocalStorageKey): void {
    localStorage.removeItem(key);
  }
}
