import { ProvisionTypeEnum } from "@@intelease/app-models/provision/src";
import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";

const MATERIAL_UI = [
  MatButtonModule,
  MatDialogModule,
  MatRadioModule,
  MatFormFieldModule,
];

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ...MATERIAL_UI],
  selector: "intelease-specifications-append-modal",
  templateUrl: "./itls-specifications-append-modal.component.html",
  styleUrls: ["./itls-specifications-append-modal.component.scss"],
})
export class ItlsSpecificationsAppendModalComponent {
  private dialogRef: MatDialogRef<ItlsSpecificationsAppendModalComponent> =
    inject(MatDialogRef);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      provisionType: ProvisionTypeEnum;
    }
  ) {}

  public tableTypeOptions = [
    {
      uiValue: "Top",
      value: true,
    },
    {
      uiValue: "Bottom",
      value: false,
    },
  ];

  public commonNounTypeOptions = [
    {
      uiValue: "Beginning",
      value: true,
    },
    {
      uiValue: "End",
      value: false,
    },
  ];

  isTop = true;
  columnNum = 2;
  ProvisionTypeEnum = ProvisionTypeEnum;

  public apply() {
    this.dialogRef.close({
      type: ModalsResponseTypeEnum.CLOSE,
      isTop: this.isTop,
      columnNum: this.columnNum,
    });
  }

  public cancel(): void {
    this.dialogRef.close({ type: ModalsResponseTypeEnum.DISMISS });
  }
}
