/* tslint:disable */
/* eslint-disable */
import { UniquePropertyIdModel } from "./unique-property-id-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UniqueSuiteIdModel")
export class UniqueSuiteIdModel {
  @JsonProperty("suite", String, true)
  suite?: string = "";

  @JsonProperty("uniquePropertyId", UniquePropertyIdModel, true)
  uniquePropertyId?: UniquePropertyIdModel = undefined;
}
