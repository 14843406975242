import { Action } from "@ngrx/store";
import { Entity, ProjectsState } from "./projects.reducer";

export enum ProjectsActionTypes {
  LoadProjects = "[Projects] Load Projects",
  ProjectsLoaded = "[Projects] Projects Loaded",
  ProjectsLoadError = "[Projects] Projects Load Error",

  SaveProject = "[Projects] Save Project",
  SaveProjectSucceeded = "[Projects] Save Project Succeeded",
  SaveProjectFailed = "[Projects] Save Project Failed",

  UpdateProject = "[Projects] Update Project",
  UpdateProjectSucceeded = "[Projects] Update Project Succeeded",
  UpdateProjectFailed = "[Projects] Update Project Failed",

  DeleteProject = "[Projects] Delete Project",
  DeleteProjectSucceeded = "[Projects] Delete Project Succeeded",
  DeleteProjectFailed = "[Projects] Delete Project Failed",

  DeleteBatchProject = "[Projects] Delete Batch Project",
  DeleteBatchProjectSucceeded = "[Projects] Delete Batch Project Succeeded",
  DeleteBatchProjectFailed = "[Projects] Delete Batch Project Failed",

  LoadProject = "[Projects] Load Project",
  LoadProjectSucceeded = "[Projects] Load Project Succeeded",
  LoadProjectFailed = "[Projects] Load Project Failed",

  LoadAllProjects = "[Projects] Load All Projects",
  LoadAllProjectsSucceeded = "[Projects] Load All Projects Succeeded",
  LoadAllProjectsFailed = "[Projects] Load All Projects Failed",

  ShareProject = "[Projects] Share Project",
  ShareProjectSucceeded = "[Projects] Share Project Succeeded",
  ShareProjectFailed = "[Projects] Share Project Failed",

  CleanProjectState = "[Projects] Clean Project State",
}

export class LoadProjects implements Action {
  readonly type = ProjectsActionTypes.LoadProjects;
}

export class ProjectsLoadError implements Action {
  readonly type = ProjectsActionTypes.ProjectsLoadError;

  constructor(public payload: any) {}
}

export class ProjectsLoaded implements Action {
  readonly type = ProjectsActionTypes.ProjectsLoaded;

  constructor(public payload: Entity[]) {}
}

export class SaveProject implements Action {
  readonly type = ProjectsActionTypes.SaveProject;

  constructor(public payload: any) {}
}

export class SaveProjectSucceeded implements Action {
  readonly type = ProjectsActionTypes.SaveProjectSucceeded;

  constructor(public payload: any) {}
}

export class SaveProjectFailed implements Action {
  readonly type = ProjectsActionTypes.SaveProjectFailed;

  constructor(public payload: any) {}
}

export class UpdateProject implements Action {
  readonly type = ProjectsActionTypes.UpdateProject;

  constructor(
    public payload: { projectUid: string; data: any; replace: boolean }
  ) {}
}

export class UpdateProjectSucceeded implements Action {
  readonly type = ProjectsActionTypes.UpdateProjectSucceeded;

  constructor(public payload: any) {}
}

export class UpdateProjectFailed implements Action {
  readonly type = ProjectsActionTypes.UpdateProjectFailed;

  constructor(public payload: any) {}
}

export class DeleteProject implements Action {
  readonly type = ProjectsActionTypes.DeleteProject;

  constructor(public payload: any) {}
}

export class DeleteProjectSucceeded implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectSucceeded;

  constructor(public payload: any) {}
}

export class DeleteProjectFailed implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectFailed;

  constructor(public payload: any) {}
}

export class DeleteBatchProject implements Action {
  readonly type = ProjectsActionTypes.DeleteBatchProject;

  constructor(public payload: any) {}
}

export class DeleteBatchProjectSucceeded implements Action {
  readonly type = ProjectsActionTypes.DeleteBatchProjectSucceeded;

  constructor(public payload: any) {}
}

export class DeleteBatchProjectFailed implements Action {
  readonly type = ProjectsActionTypes.DeleteBatchProjectFailed;

  constructor(public payload: any) {}
}

export class LoadProject implements Action {
  readonly type = ProjectsActionTypes.LoadProject;

  constructor(public payload: any, public selectedInstanceId: string) {}
}

export class LoadProjectSucceeded implements Action {
  readonly type = ProjectsActionTypes.LoadProjectSucceeded;

  constructor(public payload: any, public selectedInstanceId: string) {}
}

export class LoadProjectFailed implements Action {
  readonly type = ProjectsActionTypes.LoadProjectFailed;

  constructor(public payload: any, public selectedInstanceId: string) {}
}

export class LoadAllProjects implements Action {
  readonly type = ProjectsActionTypes.LoadAllProjects;

  constructor(public payload: any) {}
}

export class LoadAllProjectsSucceeded implements Action {
  readonly type = ProjectsActionTypes.LoadAllProjectsSucceeded;

  constructor(public payload: any) {}
}

export class LoadAllProjectsFailed implements Action {
  readonly type = ProjectsActionTypes.LoadAllProjectsFailed;

  constructor(public payload: any) {}
}

export class ShareProject implements Action {
  readonly type = ProjectsActionTypes.ShareProject;

  constructor(public payload: any) {}
}

export class ShareProjectSucceeded implements Action {
  readonly type = ProjectsActionTypes.ShareProjectSucceeded;

  constructor(public payload: any) {}
}

export class ShareProjectFailed implements Action {
  readonly type = ProjectsActionTypes.ShareProjectFailed;

  constructor(public payload: any) {}
}

export class CleanProjectState implements Action {
  readonly type = ProjectsActionTypes.CleanProjectState;

  constructor(public payload: ProjectsState) {}
}

export type ProjectsAction =
  | LoadProjects
  | ProjectsLoaded
  | ProjectsLoadError
  | SaveProject
  | SaveProjectSucceeded
  | SaveProjectFailed
  | UpdateProject
  | UpdateProjectSucceeded
  | UpdateProjectFailed
  | DeleteProject
  | DeleteProjectSucceeded
  | DeleteProjectFailed
  | DeleteBatchProject
  | DeleteBatchProjectSucceeded
  | DeleteBatchProjectFailed
  | LoadProject
  | LoadProjectSucceeded
  | LoadProjectFailed
  | LoadAllProjects
  | LoadAllProjectsSucceeded
  | LoadAllProjectsFailed
  | ShareProject
  | ShareProjectSucceeded
  | ShareProjectFailed
  | CleanProjectState;

export const fromProjectsActions = {
  LoadProjects,
  ProjectsLoaded,
  ProjectsLoadError,
  SaveProject,
  SaveProjectSucceeded,
  SaveProjectFailed,
  UpdateProject,
  UpdateProjectSucceeded,
  UpdateProjectFailed,
  DeleteProject,
  DeleteProjectSucceeded,
  DeleteProjectFailed,
  DeleteBatchProject,
  DeleteBatchProjectSucceeded,
  DeleteBatchProjectFailed,
  LoadProject,
  LoadProjectSucceeded,
  LoadProjectFailed,
  LoadAllProjects,
  LoadAllProjectsSucceeded,
  LoadAllProjectsFailed,
  ShareProject,
  ShareProjectSucceeded,
  ShareProjectFailed,
  CleanProjectState,
};
