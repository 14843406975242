import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { BreadcrumbItemModel } from "@@intelease/web/intelease/components/toolbar/breadcrumb/models";
import { BreadcrumbItemTypeEnum } from "@@intelease/web/intelease/components/toolbar/breadcrumb/enums";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { DashboardModel } from "@@intelease/web/ui/src/lib/itls-dynamic-dashboard/services/model/dashboard.model";
import { Observable } from "rxjs";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { isObject } from "lodash";
import { map, startWith } from "rxjs/operators";
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: "breadcrumb-item",
  templateUrl: "./breadcrumb-item.component.html",
  styleUrls: ["./breadcrumb-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbItemComponent implements OnInit {
  BreadcrumbItemTypeEnum = BreadcrumbItemTypeEnum;
  @Input()
  breadcrumbItem: BreadcrumbItemModel;
  @Input()
  showArrowIcon = false;
  @Input()
  isRoot = false;
  screenWidth = 0;
  @Input()
  contentMaxWidth: number;
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  searchBoxControl = new FormControl();
  filteredSearchBoxOptions: Observable<DashboardModel[]>;

  constructor(private router: Router) {
    this.onWindowResize();
  }

  ngOnInit(): void {
    this.initSearchBox();
  }

  private _filter(value: { name: string } | string): DashboardModel[] {
    // happens when option is selected (a dashboard value will be selected)
    const lowerCaseName = (
      isObject(value) ? (value as { name: string }).name : (value as string)
    ).toLowerCase();
    return this.breadcrumbItem?.searchBoxData.options.filter((dashboard) =>
      dashboard.name.toLowerCase().includes(lowerCaseName)
    );
  }

  onOptionSelection(event: MatAutocompleteSelectedEvent) {
    this.searchBoxControl.setValue("");
    event.option.deselect();
    this.trigger.closeMenu();
    this.breadcrumbItem.searchBoxData.onOptionClicked(event.option.value);
  }

  public displayNull() {
    return null;
  }

  onEllipseChildClicked(child: BreadcrumbItemModel) {
    if (child.onClicked) {
      child.onClicked();
    } else if (child.link) {
      this.router.navigate([child.link]);
    }
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  private initSearchBox() {
    if (this.breadcrumbItem?.type === BreadcrumbItemTypeEnum.SEARCH_BOX) {
      this.filteredSearchBoxOptions = this.searchBoxControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
    }
  }
}
