import {
  DestroyRef,
  Directive,
  HostBinding,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { InteleaseMatchMediaService } from "@@intelease/web/ui";
import { ItlsMatSidenavService } from "./itls-mat-sidenav.service";
import { MediaObserver } from "@angular/flex-layout";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Directive({
  selector: "[ilMatSidenavHelper]",
})
export class ItlsMatSidenavDirective implements OnInit {
  @HostBinding("class.mat-is-locked-open")
  isLockedOpen: boolean;

  @Input()
  ilMatSidenavHelper: string;

  @Input()
  matIsLockedOpen: string;
  destroyRef = inject(DestroyRef);

  constructor(
    private _inteleaseMatchMediaService: InteleaseMatchMediaService,
    private itlsMatSidenavService: ItlsMatSidenavService,
    private _matSidenav: MatSidenav,
    private mediaObserver: MediaObserver
  ) {
    // Set the defaults
    this.isLockedOpen = true;
  }

  ngOnInit(): void {
    // Register the sidenav to the service
    this.itlsMatSidenavService.setSidenav(
      this.ilMatSidenavHelper,
      this._matSidenav
    );

    if (this.mediaObserver.isActive(this.matIsLockedOpen)) {
      this.isLockedOpen = true;
      this._matSidenav.mode = "side";
      this._matSidenav.toggle(true);
    } else {
      this.isLockedOpen = false;
      this._matSidenav.mode = "over";
      this._matSidenav.toggle(false);
    }

    this._inteleaseMatchMediaService.onMediaChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.mediaObserver.isActive(this.matIsLockedOpen)) {
          this.isLockedOpen = true;
          this._matSidenav.mode = "side";
          this._matSidenav.toggle(true);
        } else {
          this.isLockedOpen = false;
          this._matSidenav.mode = "over";
          this._matSidenav.toggle(false);
        }
      });
  }
}
