import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FileBatchApiService {
  private static readonly EXPORT_PART = "/export";
  private static readonly ZIP_PART = "/zip";

  constructor(private httpClient: HttpClient) {}

  /**
   * Send a POST request to retrieve a file for download for user, representing several entities
   *
   * @param apiVersion the version of the API
   * @param url the url designating the type of object to fetch
   * @param dataFormat the format of the data to download
   * @param data the additional data to send to determine which file to fetch (should contain the uids of the entities)
   * @param filename the filename to retrieve
   */
  sendExportRequest(
    apiVersion: string,
    url: string,
    dataFormat: string,
    data: any,
    filename: string
  ): void {
    this.httpClient
      .post(
        `${apiVersion}${FileBatchApiService.EXPORT_PART}${dataFormat}${url}`,
        data,
        { responseType: "blob" }
      )
      .subscribe((res) => this.downloadFromBrowser(filename, res));
  }

  /**
   * Send a POST request to retrieve a file for download for user, representing all the entities
   *
   * @param apiVersion the version of the API
   * @param url the url designating the type of object to fetch
   * @param dataFormat the format of the data to download
   * @param filename the filename to retrieve
   */
  sendZipExportAllRequest(
    apiVersion: string,
    url: string,
    dataFormat: string,
    filename: string
  ): Observable<any> {
    const body = {
      data: {
        selectAll: true,
      },
    };
    return new Observable<any>((obs) => {
      this.httpClient
        .post(
          `${apiVersion}${url}${FileBatchApiService.ZIP_PART}/${dataFormat}`,
          body,
          {
            responseType: "blob",
          }
        )
        .subscribe(
          (res) => {
            this.downloadFromBrowser(filename, res);
            obs.next();
          },
          (error) => obs.error(error)
        );
    });
  }

  /**
   * Send a POST request to retrieve a file for download for user, representing several entities
   *
   * @param apiVersion the version of the API
   * @param url the url designating the type of object to fetch
   * @param uids the uids of the entities to be exported
   * @param dataFormat the format of the data to download
   * @param filename the filename to retrieve
   */
  sendZipExportRequest(
    apiVersion: string,
    url: string,
    uids: string[],
    dataFormat: string,
    filename: string
  ): Observable<any> {
    const body = {
      data: {
        objectUids: uids,
        selectAll: false,
      },
    };
    return new Observable<any>((obs) => {
      this.httpClient
        .post(
          `${apiVersion}${url}${FileBatchApiService.ZIP_PART}/${dataFormat}`,
          body,
          {
            responseType: "blob",
          }
        )
        .subscribe(
          (res) => {
            this.downloadFromBrowser(filename, res);
            obs.next();
          },
          (error) => obs.error(error)
        );
    });
  }

  /*
   * Below are private helper methods
   */

  private downloadFromBrowser(filename: string, res: Blob): void {
    const link = document.createElement("a");
    link.download = filename;
    this.commonHandleBlob(res, link);
  }

  private commonHandleBlob(res: Blob, link: HTMLAnchorElement): void {
    link.href = window.URL.createObjectURL(res);
    link.click();
    link.remove();
  }
}
