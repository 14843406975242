import { JsonObject, JsonProperty } from "json2typescript";
import { SearchSuggestionItemCategoryTypeModel } from "@@intelease/web/common/models/search/search-suggestion-item-category-type.model";
import { SearchSuggestionItemCategoryTypeJsonConverter } from "@@intelease/web/common/models/search/search-suggestion-item-category-type-json-converter";

@JsonObject("SearchSuggestionItemCategoryModel")
export class SearchSuggestionItemCategoryModel {
  @JsonProperty("searchCategory", SearchSuggestionItemCategoryTypeJsonConverter)
  searchCategory: SearchSuggestionItemCategoryTypeModel = undefined;

  @JsonProperty("highlights", [String])
  highlights: string[] = [];
}
