/* tslint:disable */
/* eslint-disable */
import { FullStateViewModel } from "./full-state-view-model";
import { PartialValPdfProvisionViewModel } from "./partial-val-pdf-provision-view-model";
import { ProvisionReviewCompletionInfoViewModel } from "./provision-review-completion-info-view-model";

/**
 * the mentions for this provision in this record
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullValMultiplePdfProvisionViewModel")
export class FullValMultiplePdfProvisionViewModel {
  /**
   * the allowed formats for this provision
   */

  @JsonProperty("allowedTypes", [String], true)
  allowedTypes?: Array<string> = [];

  /**
   * the description for this provision
   */

  @JsonProperty("description", String, true)
  description?: string = "";

  /**
   * the type of field this provision is
   */

  @JsonProperty("fieldType", String, true)
  fieldType?: string = "";

  /**
   * the unique provision human-readable id
   */

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  /**
   * the name for this provision
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the notes for this provision's mentions
   */

  @JsonProperty("notes", String, true)
  notes?: string = "";

  /**
   * the selected mentions for this provision
   */

  @JsonProperty("options", [PartialValPdfProvisionViewModel], true)
  options?: Array<PartialValPdfProvisionViewModel> = [];

  /**
   * a value for this provision can only have among these possible countries
   */

  @JsonProperty("possibleCountries", [String], true)
  possibleCountries?: Array<string> = [];

  /**
   * a value for this provision can only have among these possible currencies
   */

  @JsonProperty("possibleCurrencies", [String], true)
  possibleCurrencies?: Array<string> = [];

  /**
   * a value for this provision can only have among these possible states
   */

  @JsonProperty("possibleFullStates", [FullStateViewModel], true)
  possibleFullStates?: Array<FullStateViewModel> = [];

  /**
   * a value for this provision can only have among these possible states
   */

  @JsonProperty("possibleStates", [String], true)
  possibleStates?: Array<string> = [];

  /**
   * a value for this provision can only have among these possible units
   */

  @JsonProperty("possibleUnits", [String], true)
  possibleUnits?: Array<string> = [];

  /**
   * a value for this provision can only be among this set
   */

  @JsonProperty("possibleValues", [String], true)
  possibleValues?: Array<string> = [];

  /**
   * the type of proper noun this PROPER_NOUN provision is
   */

  @JsonProperty("properNounType", String, true)
  properNounType?: string = "";

  /**
   * the user-facing unique provision id
   */

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";

  /**
   * provision review completion info
   */

  @JsonProperty(
    "reviewCompletionInfos",
    [ProvisionReviewCompletionInfoViewModel],
    true
  )
  reviewCompletionInfos?: Array<ProvisionReviewCompletionInfoViewModel> = [];

  /**
   * the un-selected mentions for this provision
   */

  @JsonProperty("snippets", [PartialValPdfProvisionViewModel], true)
  snippets?: Array<PartialValPdfProvisionViewModel> = [];

  /**
   * the format for this provision
   */

  @JsonProperty("type", String, true)
  type?: string = "";
}
