import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  NOTIFICATIONS_FEATURE_KEY,
  NotificationsState,
} from "./notifications.reducer";
import { values } from "lodash";

// Lookup the 'Notifications' feature state managed by NgRx
const getNotificationsState = createFeatureSelector<NotificationsState>(
  NOTIFICATIONS_FEATURE_KEY
);

const getLoaded = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.loaded
);
const getError = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.error
);

const getAllNotifications = createSelector(
  getNotificationsState,
  getLoaded,
  (state: NotificationsState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.selectedId
);
const getSelectedNotifications = createSelector(
  getAllNotifications,
  getSelectedId,
  (notifications, id) => {
    const result = notifications.find((it) => it["id"] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

const countOfUnseenNotifications = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.countOfUnseenNotifications
);

const isNotificationsCategoryLoading = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.isNotificationsCategoryLoading
);

const notificationsCategoryLoaded = createSelector(
  getNotificationsState,
  isNotificationsCategoryLoading,
  (state: NotificationsState, isLoading) =>
    !isLoading && state.notificationsCategoryLoaded
);

const getNotificationsCategory = createSelector(
  getNotificationsState,
  notificationsCategoryLoaded,
  (state: NotificationsState, isLoaded) =>
    isLoaded ? values(state.notificationsCategory) : undefined
);

const isSelectedCategoryNotificationsLoading = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.isSelectedCategoryNotificationsLoading
);

const selectedCategoryNotificationsLoaded = createSelector(
  getNotificationsState,
  isSelectedCategoryNotificationsLoading,
  (state: NotificationsState, isLoading: boolean) =>
    !isLoading && state.selectedCategoryNotificationsLoaded
);

const getCategoryNotifications = createSelector(
  getNotificationsState,
  selectedCategoryNotificationsLoaded,
  (state: NotificationsState, isLoaded: boolean) =>
    isLoaded ? state.categoryNotifications : undefined
);

const readSelectedNotificationSucceeded = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.readSelectedNotificationSucceeded
);

export const notificationsQuery = {
  getLoaded,
  getError,
  getAllNotifications,
  getSelectedNotifications,

  countOfUnseenNotifications,

  isNotificationsCategoryLoading,
  notificationsCategoryLoaded,
  getNotificationsCategory,

  isSelectedCategoryNotificationsLoading,
  selectedCategoryNotificationsLoaded,
  getCategoryNotifications,
  readSelectedNotificationSucceeded,
};
