import {
  AbstractReviewAction,
  AbstractReviewActionTypes,
} from "./abstract-review.actions";

import { FullValMultiProvisionValueModel } from "@@intelease/web/common/models/multi-provision-value";
import { ProvisionReviewStatusEnum } from "@@intelease/app-models/common/src";
import {
  CustomTagViewModel,
  DocPdfViewModel,
  DocSetFullDocOutlineViewModel,
  FinalAbstractViewCompleteModel,
  FullValMultiPdfProvViewModel,
  OApiRespDashboardDtoModel,
  OptionsCalculatorDataResponseDtoModel,
  PartialValPdfProvisionViewModel,
  ProvisionFormStructureViewModel,
  ProvisionInconsistenciesApiDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { shouldSetStatusToNeedReviewTouched } from "@@intelease/web/utils/provision-review-utils";
import {
  FullValMultiPdfProvModel,
  ProvisionFormStructureModel,
} from "@@intelease/app-models/provision/src";
import { generateUUID } from "@@intelease/web/utils";
import {
  DocumentsMapModel,
  PartialValPdfProvisionView2Model,
} from "./models/abstract-review.model";
import { cloneDeep, findIndex } from "lodash";
import { CommentItemNode } from "@@intelease/web/abstraction-page/src/lib/components/web-abstraction-page-sidebar/components/toc/web-abstraction-page-sidebar-toc.component";
import { RemindersTypeEnum } from "@@intelease/web/abstraction-page/src";
import { NestedProvisionUtils } from "@@intelease/app-services/abstract-review/src";

export const ABSTRACTREVIEW_FEATURE_KEY = "abstractReview";

/**
 * Interface for the 'AbstractReview' data used in
 *  - AbstractReviewState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* eslint-disable-next-line */
export interface Entity {}

export interface AbstractReviewState {
  list?: Entity[]; // list of AbstractReview; analogous to a sql normalized table
  selectedId?: string | number; // which AbstractReview record has been selected
  loaded?: boolean; // has the AbstractReview list been loaded
  selectedAbstractUid?: string; // Uid of selected abstract
  abstractDetail?: FinalAbstractViewCompleteModel;
  isSelectedAbstractDetailLoading?: boolean; // has the SelectedAbstractReview been loaded
  selectedAbstractDetailLoaded?: boolean; // has the SelectedAbstractReview been loaded
  selectedAbstractDetailLoadError?: boolean;
  formStructure?: ProvisionFormStructureViewModel;
  provisions?: { [id: string]: FullValMultiPdfProvViewModel };
  anchorProvisionUid?: string;
  provisionInconsistencies?: ProvisionInconsistenciesApiDtoModel;
  selectedAbstractTOC?: DocSetFullDocOutlineViewModel;
  selectedAbstractTOCLoaded?: boolean;
  pickSnippetSuccess?: FullValMultiProvisionValueModel;
  pickSnippetError?: OApiRespDashboardDtoModel;

  selectedProvisionReviewStatusUpdated?: boolean;

  documentsShortNameMap?: { [documentUid: string]: DocumentsMapModel };
  selectedDocument?;

  isCreateProvisionCommentLoading?: boolean;
  createProvisionCommentSucceeded?: boolean;
  createProvisionCommentError?: OApiRespDashboardDtoModel;

  isCreateProvisionNotePending?: boolean;
  createProvisionNoteSucceeded?: boolean;
  createProvisionNoteError?: OApiRespDashboardDtoModel;

  isUpdateProvisionNotePending?: boolean;
  isUpdateProvisionNoteSucceeded?: boolean;
  updateProvisionNoteFailed?: OApiRespDashboardDtoModel;

  isLoadProvisionCommentsLoading?: boolean;
  provisionCommentsLoadSucceeded?: boolean;
  provisionCommentsLoadError?: OApiRespDashboardDtoModel;
  selectedProvisionComments?;

  selectedProvision?;

  selectedDocumentFullyLoaded?: boolean;

  commentCountInRecord?: number;

  isCommentPanelSideBarVisible?: boolean;

  isPromptPlaygroundSideBarVisible?: boolean;

  customTags?: CustomTagViewModel[];
  isUpdateCustomTagsPending?: boolean;
  isUpdateCustomTagsSucceeded?: boolean;

  UpdatedNoteWithProvisionUid?: boolean;

  annotations: {
    [docAbstractUid: string]: DocPdfViewModel;
  };

  commentNodes: CommentItemNode[];
  commentNodesLoaded: boolean;

  optionRemindersCalculatorData?: {
    [optionRemindersType in RemindersTypeEnum]: OptionsCalculatorDataResponseDtoModel;
  };
  optionRemindersCalculatorDataLoading?: boolean;

  lastTouchedMentionUid?: string;
  lastNestedSubfieldTouched?: string;
  mentionApprovalCompletion?: {
    totalSubfieldCount: number;
    reviewedSubfieldCount: number;
    completionPercentage: number;
  };
}

export interface AbstractReviewPartialState {
  readonly [ABSTRACTREVIEW_FEATURE_KEY]: AbstractReviewState;
}

export const initialState: AbstractReviewState = {
  selectedAbstractUid: undefined,
  abstractDetail: undefined,
  provisions: undefined,
  provisionInconsistencies: undefined,
  selectedAbstractTOC: undefined,
  selectedAbstractTOCLoaded: false,
  selectedDocumentFullyLoaded: false,
  commentCountInRecord: 0,
  isCommentPanelSideBarVisible: false,
  isPromptPlaygroundSideBarVisible: false,
  annotations: {},
  commentNodes: [],
  commentNodesLoaded: false,
  lastTouchedMentionUid: undefined,
  lastNestedSubfieldTouched: undefined,
};

export function reducer(
  state: AbstractReviewState = initialState,
  action: AbstractReviewAction
): AbstractReviewState {
  switch (action.type) {
    case AbstractReviewActionTypes.SetAllCommentNodes: {
      const { comments } = action;
      state = {
        ...state,
        commentNodes: [...state.commentNodes, comments],
      };
      break;
    }
    case AbstractReviewActionTypes.AddCommentNode: {
      const { comment } = action;
      const documentUids = state.commentNodes.map(
        (commentNode) => commentNode.documentUid
      );
      let commentNodes = state.commentNodes;
      if (documentUids.includes(comment.documentUid)) {
        commentNodes = commentNodes
          .map((commentNode) => {
            if (commentNode.documentUid !== comment.documentUid)
              return commentNode;
            return {
              ...commentNode,
              headings: [...commentNode.headings, comment].sort(
                (a, b) => a.page - b.page
              ),
            };
          })
          .filter((commentNode) => commentNode.headings.length > 0);
      } else {
        commentNodes = commentNodes.concat({
          documentUid: comment.documentUid,
          headings: [comment],
        });
      }
      state = {
        ...state,
        commentNodes,
        commentNodesLoaded: true,
      };
      break;
    }
    case AbstractReviewActionTypes.EditCommentNode: {
      const { comment, commentUid } = action;
      const commentNodes = state.commentNodes
        .map((commentNode) => ({
          ...commentNode,
          headings: commentNode.headings.map((_comment) => {
            if (_comment.uid !== commentUid) return _comment;
            return {
              ..._comment,
              text: comment.text,
            };
          }),
        }))
        .filter((commentNode) => commentNode.headings.length > 0);
      state = {
        ...state,
        commentNodes,
      };
      break;
    }
    case AbstractReviewActionTypes.DeleteCommentNode: {
      const { commentUid, documentUid } = action;
      state = {
        ...state,
        commentNodes: state.commentNodes
          .map((commentNode) => {
            if (commentNode.documentUid !== documentUid) return commentNode;
            const headings = commentNode.headings.filter(
              (comment) => comment.uid !== commentUid
            );
            return {
              ...commentNode,
              headings: headings,
            };
          })
          .filter((commentNode) => commentNode.headings.length > 0),
      };
      break;
    }
    case AbstractReviewActionTypes.AbstractReviewLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true,
      };
      break;
    }
    case AbstractReviewActionTypes.LoadSelectedAbstractDetail: {
      const { abstractUid } = action.payload;
      state = {
        ...state,
        abstractDetail: undefined,
        selectedAbstractUid: abstractUid,
        selectedAbstractDetailLoadError: undefined,
        isSelectedAbstractDetailLoading: true,
      };
      break;
    }
    case AbstractReviewActionTypes.AddNewlyCreatedProvision: {
      const {
        provision,
        provisionCategoryOrGroupUid,
        preProvisionLocationUid,
        postProvisionLocationUid,
      } = action.payload;

      const targetProvisionGroup = state.formStructure.categories[
        provisionCategoryOrGroupUid
      ] as ProvisionFormStructureModel;

      const position = findPositionForInsertion(
        targetProvisionGroup,
        preProvisionLocationUid,
        postProvisionLocationUid
      );

      const newProvisionGroup = updateProvisionGroupWithNewProvision(
        targetProvisionGroup,
        provision.provisionUid,
        provision.htmlName,
        position
      );

      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provision.provisionUid]: {
            ...provision,
            multiplePdfProvision: {
              ...provision.multiplePdfProvision,
              options: provision.multiplePdfProvision.options.length
                ? provision.multiplePdfProvision.options
                : [
                    createTempMentionOption(
                      provision as FullValMultiPdfProvViewModel
                    ),
                  ],
            },
          } as FullValMultiPdfProvViewModel,
        },
        formStructure: {
          ...state.formStructure,
          categories: {
            ...state.formStructure.categories,
            [provisionCategoryOrGroupUid]: {
              ...newProvisionGroup,
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }
    case AbstractReviewActionTypes.SelectedAbstractDetailLoaded: {
      const {
        abstractDetail,
        formStructure,
        provisions,
        provisionInconsistencies,
      } = action.payload;
      state = {
        ...state,
        abstractDetail,
        formStructure,
        provisions: (Object.values(provisions) || []).reduce(
          (
            prev: {
              [id: string]: FullValMultiPdfProvViewModel;
            },
            provision: FullValMultiPdfProvViewModel
          ) => ({
            ...prev,
            [provision.provisionUid]: {
              ...provision,
              multiplePdfProvision: {
                ...provision.multiplePdfProvision,
                options: provision.multiplePdfProvision.options.length
                  ? provision.multiplePdfProvision.options
                  : [createTempMentionOption(provision)],
              },
            },
          }),
          {}
        ) as {
          [id: string]: FullValMultiPdfProvViewModel;
        },
        provisionInconsistencies:
          NestedProvisionUtils.mergeProvisionInconsistencies(
            state.provisionInconsistencies,
            provisionInconsistencies
          ),
        selectedAbstractDetailLoadError: undefined,
        selectedAbstractDetailLoaded: true,
        isSelectedAbstractDetailLoading: false,
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }
    case AbstractReviewActionTypes.SelectedAbstractDetailLoadError: {
      state = {
        ...state,
        selectedAbstractDetailLoadError: action.payload,
        selectedAbstractDetailLoaded: false,
        abstractDetail: undefined,
        formStructure: undefined,
        provisions: undefined,
        isSelectedAbstractDetailLoading: false,
        mentionApprovalCompletion: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.ProvisionOptionSelected: {
      state = {
        ...state,
        abstractDetail: action.payload,
      };
      break;
    }
    case AbstractReviewActionTypes.LoadSelectedAbstractTOC: {
      state = {
        ...state,
        selectedAbstractTOC: undefined,
        selectedAbstractTOCLoaded: false,
      };
      break;
    }
    case AbstractReviewActionTypes.SelectedAbstractTOCLoaded: {
      state = {
        ...state,
        selectedAbstractTOC: action.payload,
        selectedAbstractTOCLoaded: true,
      };
      break;
    }
    case AbstractReviewActionTypes.SelectedAbstractTOCLoadError: {
      state = {
        ...state,
        selectedAbstractTOC: undefined,
        selectedAbstractTOCLoaded: false,
      };
      break;
    }

    case AbstractReviewActionTypes.UpdateSelectedProvisionReviewStatus: {
      state = {
        ...state,
        selectedProvisionReviewStatusUpdated: false,
      };
      break;
    }

    case AbstractReviewActionTypes.UpdateSelectedProvisionReviewStatusSucceeded: {
      const { provisionUid, reviewStatus } = action.payload;
      state = {
        ...state,
        selectedProvisionReviewStatusUpdated: true,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            reviewStatus,
          },
        } as { [id: string]: FullValMultiPdfProvViewModel },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }

    case AbstractReviewActionTypes.UpdateSelectedProvisionMentionReviewStatus: {
      const { provisionUid, provisionOptionUid, reviewStatus } = action.payload;

      const optionIndex = state.provisions[
        provisionUid
      ].multiplePdfProvision.options.findIndex(
        (option) => option.uid === provisionOptionUid
      );
      const options = [
        ...state.provisions[provisionUid].multiplePdfProvision.options,
      ];

      options.splice(optionIndex, 1, {
        ...options[optionIndex],
        reviewStatus,
      } as PartialValPdfProvisionViewModel);

      state = {
        ...state,
        selectedProvisionReviewStatusUpdated: true,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              options,
            },
          },
        } as { [id: string]: FullValMultiPdfProvViewModel },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }

    case AbstractReviewActionTypes.UpdateSelectedProvisionReviewStatusError: {
      state = {
        ...state,
        selectedProvisionReviewStatusUpdated: false,
      };
      break;
    }

    case AbstractReviewActionTypes.PickSnippet: {
      state = {
        ...state,
        pickSnippetSuccess: undefined,
        pickSnippetError: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.PickSnippetSuccess: {
      state = {
        ...state,
        pickSnippetSuccess: action.payload,
        pickSnippetError: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.PickSnippetError: {
      state = {
        ...state,
        pickSnippetSuccess: undefined,
        pickSnippetError: action.payload,
      };
      break;
    }
    case AbstractReviewActionTypes.SelectMention: {
      state = {
        ...state,
      };
      break;
    }
    case AbstractReviewActionTypes.SelectMentionSuccess: {
      state = {
        ...state,
      };
      break;
    }
    case AbstractReviewActionTypes.SelectMentionError: {
      state = {
        ...state,
      };
      break;
    }

    case AbstractReviewActionTypes.DeSelectMention: {
      state = {
        ...state,
      };
      break;
    }
    case AbstractReviewActionTypes.DeSelectMentionSuccess: {
      state = {
        ...state,
      };
      break;
    }
    case AbstractReviewActionTypes.DeSelectMentionError: {
      state = {
        ...state,
      };
      break;
    }
    case AbstractReviewActionTypes.CreateManualMention: {
      state = {
        ...state,
      };
      break;
    }
    case AbstractReviewActionTypes.CreateManualMentionSuccess: {
      let multiPdfProv = action.payload as FullValMultiPdfProvViewModel;
      multiPdfProv = {
        ...multiPdfProv,
        multiplePdfProvision: {
          ...multiPdfProv.multiplePdfProvision,
          options: multiPdfProv.multiplePdfProvision.options?.map((option) => {
            if (!(option as any).valueType) {
              return {
                ...option,
                valueType: option.type,
              } as any;
            }
            return option;
          }),
        },
      };

      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [multiPdfProv.provisionUid]: multiPdfProv,
        } as { [id: string]: FullValMultiPdfProvViewModel },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }
    case AbstractReviewActionTypes.CreateManualMentionError: {
      state = {
        ...state,
      };
      break;
    }
    case AbstractReviewActionTypes.ProvisionFullDetailLoaded: {
      const { provisionUid, provisionDetail, provisionInconsistencies } =
        action.payload;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...provisionDetail,
            multiplePdfProvision: {
              ...provisionDetail.multiplePdfProvision,
              options: provisionDetail.multiplePdfProvision.options.length
                ? provisionDetail.multiplePdfProvision.options
                : [createTempMentionOption(provisionDetail)],
            },
          },
        },
        provisionInconsistencies:
          NestedProvisionUtils.mergeProvisionInconsistencies(
            state.provisionInconsistencies,
            provisionInconsistencies
          ),
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }
    case AbstractReviewActionTypes.ProvisionFullDetailLoadError: {
      state = {
        ...state,
      };
      break;
    }
    case AbstractReviewActionTypes.SetMultiplePdfProvision: {
      const { provisionUid, provisionDetail } = action.payload;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              ...provisionDetail,
              options: provisionDetail.multiplePdfProvision.options.length
                ? provisionDetail.multiplePdfProvision.options
                : [createTempMentionOption(provisionDetail)],
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }
    case AbstractReviewActionTypes.AddTemporaryMention: {
      const { provisionUid, mention, prevMentionUid } = action.payload;
      const prevMentionIndex = findIndex(
        state.provisions[provisionUid].multiplePdfProvision.options,
        (thisMention) => thisMention.uid === prevMentionUid
      );
      const mentions = cloneDeep(
        state.provisions[provisionUid].multiplePdfProvision.options
      );
      mentions.splice(prevMentionIndex + 1, 0, mention);

      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              options: mentions,
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }
    case AbstractReviewActionTypes.RemoveTemporaryMention:
    case AbstractReviewActionTypes.RemoveSelectedMention: {
      const { provisionUid, mentionUid } = action.payload;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              options: [
                ...state.provisions[
                  provisionUid
                ].multiplePdfProvision.options.filter(
                  (mention) => mention.uid !== mentionUid
                ),
              ],
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }
    case AbstractReviewActionTypes.SaveDocumentsShortName: {
      state = {
        ...state,
        documentsShortNameMap: action.payload,
      };
      break;
    }
    case AbstractReviewActionTypes.SaveSelectedDocument: {
      state = {
        ...state,
        selectedDocument: action.payload,
      };
      break;
    }
    case AbstractReviewActionTypes.UpdateSelectedMentionLocally: {
      const { provisionUid, mention } = action.payload;
      const { uid: mentionUid, valueType, value, model } = mention;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              options: state.provisions[
                provisionUid
              ].multiplePdfProvision.options.map((option) => {
                if (option.uid === mentionUid) {
                  return {
                    ...option,
                    value,
                    model,
                    valueType,
                  };
                } else {
                  return option;
                }
              }),
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }

    case AbstractReviewActionTypes.UpdateMentionSucceeded: {
      const { provisionUid, mention } = action.payload;
      const options =
        state.provisions?.[provisionUid]?.multiplePdfProvision?.options;

      if (!options) {
        return state;
      }

      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              options: options.length
                ? options.map((option) => {
                    if (option.uid === mention.uid) {
                      return {
                        ...option,
                        ...mention,
                      };
                    } else {
                      return option;
                    }
                  })
                : [mention],
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }

    case AbstractReviewActionTypes.CreateProvisionComment: {
      state = {
        ...state,
        isCreateProvisionCommentLoading: true,
        createProvisionCommentSucceeded: false,
        createProvisionCommentError: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.CreateProvisionCommentSuccess: {
      state = {
        ...state,
        isCreateProvisionCommentLoading: false,
        createProvisionCommentSucceeded: true,
        createProvisionCommentError: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.CreateProvisionCommentError: {
      state = {
        ...state,
        isCreateProvisionCommentLoading: false,
        createProvisionCommentSucceeded: false,
        createProvisionCommentError: action.payload,
      };
      break;
    }
    case AbstractReviewActionTypes.LoadProvisionComments: {
      state = {
        ...state,
        isLoadProvisionCommentsLoading: true,
        provisionCommentsLoadSucceeded: false,
        provisionCommentsLoadError: undefined,
        selectedProvisionComments: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.ProvisionCommentsLoaded: {
      state = {
        ...state,
        isLoadProvisionCommentsLoading: false,
        provisionCommentsLoadSucceeded: true,
        provisionCommentsLoadError: undefined,
        selectedProvisionComments: action.payload,
      };
      break;
    }
    case AbstractReviewActionTypes.ProvisionCommentsLoadError: {
      state = {
        ...state,
        isLoadProvisionCommentsLoading: false,
        provisionCommentsLoadSucceeded: false,
        provisionCommentsLoadError: action.payload,
        selectedProvisionComments: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.CreateProvisionNote: {
      state = {
        ...state,
        isCreateProvisionNotePending: true,
        createProvisionNoteSucceeded: false,
        createProvisionNoteError: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.CreateProvisionNoteSuccess: {
      state = {
        ...state,
        isCreateProvisionNotePending: false,
        createProvisionNoteSucceeded: true,
        createProvisionNoteError: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.CreateProvisionNoteError: {
      state = {
        ...state,
        isCreateProvisionNotePending: false,
        createProvisionNoteSucceeded: false,
        createProvisionNoteError: action.payload,
      };
      break;
    }

    case AbstractReviewActionTypes.UpdateProvisionNote: {
      state = {
        ...state,
        isUpdateProvisionNotePending: true,
        isUpdateProvisionNoteSucceeded: false,
        updateProvisionNoteFailed: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.UpdateProvisionNoteSucceeded: {
      state = {
        ...state,
        isUpdateProvisionNotePending: false,
        isUpdateProvisionNoteSucceeded: true,
        updateProvisionNoteFailed: undefined,
      };
      break;
    }
    case AbstractReviewActionTypes.UpdateProvisionNoteFailed: {
      state = {
        ...state,
        isUpdateProvisionNotePending: false,
        isUpdateProvisionNoteSucceeded: false,
        updateProvisionNoteFailed: action.payload,
      };
      break;
    }
    case AbstractReviewActionTypes.SetSelectedProvision: {
      state = {
        ...state,
        selectedProvision: action.payload,
      };
      break;
    }
    case AbstractReviewActionTypes.CleanAbstractReviewState: {
      state = {
        ...state,
        ...action.payload,
      };
      break;
    }
    case AbstractReviewActionTypes.SelectedDocumentFullyLoaded: {
      state = {
        ...state,
        selectedDocumentFullyLoaded: action?.payload?.loaded,
      };
      break;
    }

    case AbstractReviewActionTypes.RemoveMentionSucceeded: {
      const { provisionUid } = action.payload;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              options: [],
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }

    case AbstractReviewActionTypes.AddMentionSucceeded: {
      const { provisionUid, data } = action.payload;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            reviewStatus: shouldSetStatusToNeedReviewTouched(
              state.provisions[provisionUid] as FullValMultiPdfProvModel
            )
              ? ProvisionReviewStatusEnum.NEED_REVIEW_TOUCHED
              : ProvisionReviewStatusEnum.REVIEWED,
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              options: [data],
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }

    case AbstractReviewActionTypes.UpdateProvisionMentionNoteSucceeded: {
      const { provisionUid, notes } = action.payload;

      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              notes,
            },
            reviewStatus: shouldSetStatusToNeedReviewTouched(
              state.provisions[provisionUid] as FullValMultiPdfProvModel
            )
              ? ProvisionReviewStatusEnum.NEED_REVIEW_TOUCHED
              : ProvisionReviewStatusEnum.REVIEWED,
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }

    case AbstractReviewActionTypes.SetCommentCountOnRecord: {
      state = {
        ...state,
        commentCountInRecord: action.payload,
      };
      break;
    }
    case AbstractReviewActionTypes.ToggleCommentPanelSideBar: {
      state = {
        ...state,
        isCommentPanelSideBarVisible: action.payload,
      };
      break;
    }

    case AbstractReviewActionTypes.TogglePromptPlaygroundSideBar: {
      state = {
        ...state,
        isPromptPlaygroundSideBarVisible: action.payload,
      };
      break;
    }

    case AbstractReviewActionTypes.UpdateCustomTags: {
      state = {
        ...state,
        isUpdateCustomTagsPending: true,
        isUpdateCustomTagsSucceeded: false,
      };
      break;
    }
    case AbstractReviewActionTypes.UpdateCustomTagsSucceeded: {
      state = {
        ...state,
        isUpdateCustomTagsPending: false,
        isUpdateCustomTagsSucceeded: true,
        customTags: action.payload.data.customTags,
      };
      break;
    }
    case AbstractReviewActionTypes.UpdateCustomTagsFailed: {
      state = {
        ...state,
        isUpdateCustomTagsPending: false,
        isUpdateCustomTagsSucceeded: false,
      };
      break;
    }
    case AbstractReviewActionTypes.UpdateNoteWithProvisionUid: {
      state = {
        ...state,
        UpdatedNoteWithProvisionUid: false,
      };
      break;
    }
    case AbstractReviewActionTypes.UpdateNoteWithProvisionUidSucceeded: {
      const { notes, provisionUid } = action.payload;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              notes,
            },
          },
        },
        UpdatedNoteWithProvisionUid: true,
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }
    case AbstractReviewActionTypes.UpdateMentionWithMentionIndex: {
      const { mentionIndex, newMention, provisionUid } = action;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              options: state.provisions[
                provisionUid
              ].multiplePdfProvision.options.map((option, index) => {
                if (index === mentionIndex) return newMention;
                return option;
              }),
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      break;
    }
    case AbstractReviewActionTypes.FetchDefaultDocumentAnnotationSucceeded: {
      return {
        ...state,
        annotations: {
          ...state.annotations,
          [action.payload.docAbstractUid]: action.payload,
        },
      };
    }
    case AbstractReviewActionTypes.RenameProvisionMentionSucceeded: {
      const {
        payload: { mentionUid, uiProvisionName, provisionUid },
      } = action;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              options: state.provisions[
                provisionUid
              ].multiplePdfProvision.options.map((item) => {
                if (item.uid === mentionUid) {
                  return {
                    ...item,
                    uiProvisionName,
                  };
                } else {
                  return item;
                }
              }),
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      return state;
    }
    case AbstractReviewActionTypes.AddNestedSubfieldsSucceeded: {
      const {
        payload: { provisionUid, updatedProvision },
      } = action;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            ...updatedProvision,
            provisionInfo: {
              ...updatedProvision.provisionInfo,
              nestedGroupSubfields: [
                ...updatedProvision.provisionInfo.nestedGroupSubfields,
              ],
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      return state;
    }
    case AbstractReviewActionTypes.MakeAnchorProvisionSucceeded: {
      const {
        payload: { updatedProvisionInconsistencyDetails },
      } = action;
      return {
        ...state,
        provisionInconsistencies:
          NestedProvisionUtils.mergeProvisionInconsistencies(
            state.provisionInconsistencies,
            updatedProvisionInconsistencyDetails
          ),
      };
    }
    case AbstractReviewActionTypes.UpdateProvisionReviewCompletionInfoSucceeded: {
      const {
        payload: { provisionUid, reviewCompletionInfos },
      } = action;
      state = {
        ...state,
        provisions: {
          ...state.provisions,
          [provisionUid]: {
            ...state.provisions[provisionUid],
            multiplePdfProvision: {
              ...state.provisions[provisionUid].multiplePdfProvision,
              reviewCompletionInfos: reviewCompletionInfos,
            },
          },
        },
      };
      state.mentionApprovalCompletion = calculateApprovalCompletion(
        state.provisions
      );
      return state;
    }
    case AbstractReviewActionTypes.GetOptionRemindersData: {
      return {
        ...state,
        optionRemindersCalculatorDataLoading: true,
      };
    }
    case AbstractReviewActionTypes.GetOptionRemindersDataSuccessful: {
      const { optionRemindersCalculatorData, optionRemindersType } = action;
      return {
        ...state,
        optionRemindersCalculatorData: {
          ...state.optionRemindersCalculatorData,
          [optionRemindersType]: optionRemindersCalculatorData,
        },
        optionRemindersCalculatorDataLoading: false,
      };
    }
    case AbstractReviewActionTypes.GetOptionRemindersDataFailed: {
      return {
        ...state,
        optionRemindersCalculatorData: undefined,
        optionRemindersCalculatorDataLoading: false,
      };
    }
    case AbstractReviewActionTypes.LockRecordSuccessful: {
      return {
        ...state,
        abstractDetail: {
          ...state.abstractDetail,
          lockInfo: action.payload.lockInfo,
        },
      };
    }
    case AbstractReviewActionTypes.UnlockRecordSuccessful: {
      return {
        ...state,
        abstractDetail: {
          ...state.abstractDetail,
          lockInfo: undefined,
        },
      };
    }
    case AbstractReviewActionTypes.LastTouchedMentionUid: {
      return {
        ...state,
        lastTouchedMentionUid: action.payload.mentionUid,
      };
    }
    case AbstractReviewActionTypes.LastTouchedNestedSubfieldKey: {
      return {
        ...state,
        lastNestedSubfieldTouched: action.payload.subfieldKey,
      };
    }
  }
  return state;
}

function findPositionForInsertion(
  targetProvisionCategory: ProvisionFormStructureModel,
  preProvisionId?: string,
  postProvisionId?: string
): number | undefined {
  const provisionInfoIds = targetProvisionCategory.provisionInfoUids;

  for (let idx = 0; idx <= provisionInfoIds.length; idx++) {
    const preId = idx - 1 >= 0 ? provisionInfoIds[idx - 1] : undefined;
    const postId =
      idx < provisionInfoIds.length ? provisionInfoIds[idx] : undefined;

    if (preId === preProvisionId && postId === postProvisionId) {
      return idx;
    }
  }

  return undefined;
}

function updateProvisionGroupWithNewProvision(
  targetProvisionCategory: ProvisionFormStructureModel,
  provisionUid: string,
  provisionHtmlName: string,
  position: number
): ProvisionFormStructureModel {
  const newProvisionCategory = {
    ...targetProvisionCategory,
    provisionInfoUids: [...targetProvisionCategory.provisionInfoUids],
    provisionInfoHtmlNames: [...targetProvisionCategory.provisionInfoHtmlNames],
  };

  if (targetProvisionCategory.provisionInfoUids.includes(provisionUid)) {
    console.error("Cannot add provision into the group, it already exists!");
    return newProvisionCategory;
  }

  if (position > targetProvisionCategory.provisionInfoUids.length) {
    console.error("Cannot add provision at index " + position);
    return newProvisionCategory;
  }

  if (position === targetProvisionCategory.provisionInfoUids.length) {
    newProvisionCategory.provisionInfoUids.push(provisionUid);
    newProvisionCategory.provisionInfoHtmlNames.push(provisionHtmlName);
  } else {
    newProvisionCategory.provisionInfoUids.splice(position, 0, provisionUid);
    newProvisionCategory.provisionInfoHtmlNames.splice(
      position,
      0,
      provisionHtmlName
    );
  }

  return newProvisionCategory;
}

export function createTempMentionOption(
  provision: FullValMultiPdfProvViewModel,
  status?: ProvisionReviewStatusEnum
): PartialValPdfProvisionViewModel {
  const tempOption: PartialValPdfProvisionView2Model =
    new PartialValPdfProvisionView2Model();
  tempOption.valueType = provision.provisionInfo.type;
  tempOption.type = provision.provisionInfo.type;
  tempOption.source = "TEMP_LOCAL";
  tempOption.uid = generateUUID();
  if (provision.provisionInfo.type === "NESTED_GROUP") {
    if (
      !provision.multiplePdfProvision.options ||
      provision.multiplePdfProvision.options.length === 0
    ) {
      tempOption.value = {
        id: 1,
        keyToMention: {},
      };
    }
  }

  tempOption.value;

  if (status) {
    tempOption.reviewStatus = status;
  }

  return tempOption;
}

function calculateApprovalCompletion(provisions?: {
  [id: string]: FullValMultiPdfProvViewModel;
}): {
  totalSubfieldCount: number;
  reviewedSubfieldCount: number;
  completionPercentage: number;
} {
  if (!provisions) {
    return {
      totalSubfieldCount: 0,
      reviewedSubfieldCount: 0,
      completionPercentage: 0,
    };
  }

  let subfieldCount = 0;
  let subfieldReviewedCount = 0;
  Object.values(provisions)
    .filter((provision) => provision.provisionInfo.type === "NESTED_GROUP")
    .forEach((provision) => {
      subfieldCount++;
      if (provision.multiplePdfProvision.reviewCompletionInfos?.length !== 0) {
        subfieldReviewedCount++;
      }
    });
  if (subfieldCount > 0) {
    return {
      totalSubfieldCount: subfieldCount,
      reviewedSubfieldCount: subfieldReviewedCount,
      completionPercentage: (subfieldReviewedCount * 100.0) / subfieldCount,
    };
  }
  return {
    totalSubfieldCount: 0,
    reviewedSubfieldCount: 0,
    completionPercentage: 0,
  };
}
