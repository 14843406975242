<div [matMenuTriggerFor]="menu">
  <img class="logo-icon" src="assets/images/adex-logo-white-font.png" />
  <mat-icon>keyboard_arrow_down</mat-icon>
</div>
<mat-menu
  #menu="matMenu"
  xPosition="before"
  class="logout-menu"
  [overlapTrigger]="false"
>
  <div class="py-4">
    <button
      [matTooltip]="userName"
      mat-menu-item
      (click)="onNavigateToClick('info')"
      class="user-email"
    >
      <mat-icon>account_circle</mat-icon>
      <span>{{ userEmail }}</span>
    </button>
  </div>
  <mat-divider></mat-divider>
  <button
    mat-menu-item
    (click)="onNavigateToClick('logout')"
    class="logout-button"
  >
    <mat-icon style="transform: rotate(180deg)">exit_to_app</mat-icon>
    <span>Log Out</span>
  </button>
</mat-menu>
