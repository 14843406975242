/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("GeoJsonPointModel")
export class GeoJsonPointModel {
  @JsonProperty("coordinates", [Number], true)
  coordinates?: Array<number> = [];

  @JsonProperty("type", String, true)
  type?: string = "";

  @JsonProperty("&#x27;x&#x27;", Number, true)
  "x"?: number = undefined;

  @JsonProperty("&#x27;y&#x27;", Number, true)
  "y"?: number = undefined;
}
