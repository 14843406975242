/* tslint:disable */
/* eslint-disable */
import { ObjectIdModel } from "./object-id-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UserRecordActivityModel")
export class UserRecordActivityModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("recordId", ObjectIdModel, true)
  recordId?: ObjectIdModel = undefined;

  @JsonProperty("userAction", Any, true)
  userAction?: "CREATE" | "VIEW" | "UPDATE" | "DELETE" | "SHARE" = undefined;
}
