import { JsonObject, JsonProperty } from "json2typescript";
import { ProvisionModel } from "./provision.model";

// TODO(apoorv): Deprecate this in favor of ProvisionGroupNewModel
@JsonObject("ProvisionGroupModel")
export class ProvisionGroupModel {
  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("version", Number)
  version = 0;

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("provisions", [ProvisionModel])
  provisions: ProvisionModel[] = [];
}
