/* tslint:disable */
/* eslint-disable */
import { QueueInfoDtoModel } from "./queue-info-dto-model";
import { RecordAccuracyDtoModel } from "./record-accuracy-dto-model";
import { UserDtoModel } from "./user-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RecordDtoModel")
export class RecordDtoModel {
  @JsonProperty("apiUpdatedAt", DateTimeConvertService, true)
  apiUpdatedAt?: Date | undefined = undefined;

  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdBy", UserDtoModel, true)
  createdBy?: UserDtoModel = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("queueInfo", QueueInfoDtoModel, true)
  queueInfo?: QueueInfoDtoModel = undefined;

  @JsonProperty("recordAccuracy", RecordAccuracyDtoModel, true)
  recordAccuracy?: RecordAccuracyDtoModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("updatedAt", DateTimeConvertService, true)
  updatedAt?: Date | undefined = undefined;

  @JsonProperty("updatedBy", UserDtoModel, true)
  updatedBy?: UserDtoModel = undefined;
}
