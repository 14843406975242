/* tslint:disable */
/* eslint-disable */

/**
 * the developer-facing errors for the request
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ErrorDataModel")
export class ErrorDataModel {
  /**
   * the data associated with this error
   */

  @JsonProperty("data", Any, true)
  data?: any = {};

  /**
   * the code associated with this error
   */

  @JsonProperty("errorCode", Number, true)
  errorCode?: number = undefined;

  /**
   * the message associated with this error
   */

  @JsonProperty("message", String, true)
  message?: string = "";
}
