/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";

/**
 * the request to calculate option reminders
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CalculateOptionRemindersRequestModel")
export class CalculateOptionRemindersRequestModel {
  /**
   * the text value of the option (could have been adjusted by the user)
   */

  @JsonProperty("clauseSummary", String, true)
  clauseSummary?: string = "";

  /**
   * the earliest reminder date
   */

  @JsonProperty("earliestReminderDate", DateConvertService, true)
  earliestReminderDate?: Date | undefined = undefined;

  @JsonProperty("firstReminderDate", SimpleOpenApiDateModel, true)
  firstReminderDate?: SimpleOpenApiDateModel = undefined;

  /**
   * the numerical part of the frequency of the reminder
   */

  @JsonProperty("frequencyNumber", Number, true)
  frequencyNumber?: number = undefined;

  /**
   * the specific unit for a numerical value
   */

  @JsonProperty("frequencyUnit", Any, true)
  frequencyUnit?:
    | "SQUARE_FEET"
    | "SQUARE_METERS"
    | "PING"
    | "TSUBO"
    | "ACRES"
    | "HECTARES"
    | "SQUARE_KILOMETERS"
    | "SQUARE_MILES"
    | "CENTIMETERS"
    | "INCHES"
    | "FEET"
    | "YARDS"
    | "METERS"
    | "KILOMETERS"
    | "MILES"
    | "PERCENT"
    | "MONEY"
    | "SECONDS"
    | "MINUTES"
    | "HOURS"
    | "DAYS"
    | "WEEKS"
    | "MONTHS"
    | "YEARS"
    | "METERS_PER_SECOND"
    | "METERS_PER_SECOND_PER_SECOND"
    | "GRAMS"
    | "CUBIC_CENTIMETERS"
    | "GRAMS_PER_CUBIC_CENTIMETER"
    | "VOLTS"
    | "AMPERES"
    | "PASCALS"
    | "INCHES_WATER"
    | "POUNDS_PER_SQUARE_INCH"
    | "ATMOSPHERES"
    | "KELVIN"
    | "CELSIUS"
    | "FAHRENHEIT"
    | "NONE" = undefined;

  /**
   * the maximum number of reminders that can be output
   */

  @JsonProperty("maxNumReminders", Number, true)
  maxNumReminders?: number = undefined;

  @JsonProperty("maxReminderDate", SimpleOpenApiDateModel, true)
  maxReminderDate?: SimpleOpenApiDateModel = undefined;

  /**
   * the numerical part of the offset from the reference date
   */

  @JsonProperty("offsetNumber", Number, true)
  offsetNumber?: number = undefined;

  /**
   * the specific unit for a numerical value
   */

  @JsonProperty("offsetUnit", Any, true)
  offsetUnit?:
    | "SQUARE_FEET"
    | "SQUARE_METERS"
    | "PING"
    | "TSUBO"
    | "ACRES"
    | "HECTARES"
    | "SQUARE_KILOMETERS"
    | "SQUARE_MILES"
    | "CENTIMETERS"
    | "INCHES"
    | "FEET"
    | "YARDS"
    | "METERS"
    | "KILOMETERS"
    | "MILES"
    | "PERCENT"
    | "MONEY"
    | "SECONDS"
    | "MINUTES"
    | "HOURS"
    | "DAYS"
    | "WEEKS"
    | "MONTHS"
    | "YEARS"
    | "METERS_PER_SECOND"
    | "METERS_PER_SECOND_PER_SECOND"
    | "GRAMS"
    | "CUBIC_CENTIMETERS"
    | "GRAMS_PER_CUBIC_CENTIMETER"
    | "VOLTS"
    | "AMPERES"
    | "PASCALS"
    | "INCHES_WATER"
    | "POUNDS_PER_SQUARE_INCH"
    | "ATMOSPHERES"
    | "KELVIN"
    | "CELSIUS"
    | "FAHRENHEIT"
    | "NONE" = undefined;

  /**
   * the type of option reminders to calculate
   */

  @JsonProperty("optionRemindersType", Any)
  optionRemindersType: "ROLLING" | "MULTIPLE" | "RECURRING" = undefined;

  /**
   * the type of the option
   */

  @JsonProperty("optionType", String)
  optionType: string = "";

  /**
   * the user-facing {@link FinalDocSetAbstract#getId()} for which calculating the mention
   */

  @JsonProperty("recordUid", String)
  recordUid: string = "";

  @JsonProperty("referenceDate", SimpleOpenApiDateModel, true)
  referenceDate?: SimpleOpenApiDateModel = undefined;

  /**
   * the end date for rolling reminders
   */

  @JsonProperty("rollingEndDate", DateConvertService, true)
  rollingEndDate?: Date | undefined = undefined;

  /**
   * whether to offset before or after, from the notice date (for rolling reminders)
   */

  @JsonProperty("rollingOffsetBefore", Boolean, true)
  rollingOffsetBefore?: boolean = undefined;

  /**
   * the number for how much to offset, from the notice date (for rolling reminders)
   */

  @JsonProperty("rollingOffsetNumber", Number, true)
  rollingOffsetNumber?: number = undefined;

  /**
   * the specific unit for a numerical value
   */

  @JsonProperty("rollingOffsetUnit", Any, true)
  rollingOffsetUnit?:
    | "SQUARE_FEET"
    | "SQUARE_METERS"
    | "PING"
    | "TSUBO"
    | "ACRES"
    | "HECTARES"
    | "SQUARE_KILOMETERS"
    | "SQUARE_MILES"
    | "CENTIMETERS"
    | "INCHES"
    | "FEET"
    | "YARDS"
    | "METERS"
    | "KILOMETERS"
    | "MILES"
    | "PERCENT"
    | "MONEY"
    | "SECONDS"
    | "MINUTES"
    | "HOURS"
    | "DAYS"
    | "WEEKS"
    | "MONTHS"
    | "YEARS"
    | "METERS_PER_SECOND"
    | "METERS_PER_SECOND_PER_SECOND"
    | "GRAMS"
    | "CUBIC_CENTIMETERS"
    | "GRAMS_PER_CUBIC_CENTIMETER"
    | "VOLTS"
    | "AMPERES"
    | "PASCALS"
    | "INCHES_WATER"
    | "POUNDS_PER_SQUARE_INCH"
    | "ATMOSPHERES"
    | "KELVIN"
    | "CELSIUS"
    | "FAHRENHEIT"
    | "NONE" = undefined;

  /**
   * the start date for rolling reminders
   */

  @JsonProperty("rollingStartDate", DateConvertService, true)
  rollingStartDate?: Date | undefined = undefined;
}
