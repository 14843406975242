/* tslint:disable */
/* eslint-disable */
import { JsonNodeModel } from "./json-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SaveHighlightMentionApiDtoModel")
export class SaveHighlightMentionApiDtoModel {
  @JsonProperty("dirtyText", String, true)
  dirtyText?: string = "";

  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  /**
   * the format of a structured value
   */

  @JsonProperty("format", Any, true)
  format?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;

  @JsonProperty("formatValues", Any, true)
  formatValues?: {
    [key: string]: JsonNodeModel;
  } = {};

  @JsonProperty("highlightType", Any, true)
  highlightType?: "TEXT" | "AREA" = undefined;

  @JsonProperty("indexInText", Number, true)
  indexInText?: number = undefined;

  @JsonProperty("notes", String, true)
  notes?: string = "";

  /**
   * the category of units for a numerical values
   */

  @JsonProperty("numericalUnitCategory", Any, true)
  numericalUnitCategory?:
    | "TIME"
    | "AREA"
    | "VELOCITY"
    | "MONEY"
    | "RATIO"
    | "DISTANCE"
    | "ACCELERATION"
    | "MASS"
    | "VOLUME"
    | "DENSITY"
    | "ELECTRIC_POTENTIAL"
    | "ELECTRIC_CURRENT"
    | "PRESSURE"
    | "TEMPERATURE"
    | "NONE" = undefined;

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  @JsonProperty("pdfProvisionHighlights", [JsonNodeModel], true)
  pdfProvisionHighlights?: Array<JsonNodeModel> = [];

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";

  @JsonProperty("provisionValue", Any, true)
  provisionValue?: any = undefined;

  @JsonProperty("provisionValueUid", String, true)
  provisionValueUid?: string = "";

  @JsonProperty("sectionHeader", String, true)
  sectionHeader?: string = "";

  @JsonProperty("sectionHeadingUid", String, true)
  sectionHeadingUid?: string = "";

  @JsonProperty("subProvisionsPath", [String], true)
  subProvisionsPath?: Array<string> = [];

  @JsonProperty("surroundingSentence", String, true)
  surroundingSentence?: string = "";

  @JsonProperty("text", String, true)
  text?: string = "";

  @JsonProperty("textSurroundingOriginalProvision", String, true)
  textSurroundingOriginalProvision?: string = "";
}
