export const APP_FORMS = {
  LOGIN: "./assets/forms/auth/login-form-schema.json",
  TOS: "./assets/forms/auth/tos-form-schema.json",
  TOS_SOCIAL: "./assets/forms/auth/tos-social-form-schema.json",
  FORGOT_PASSWORD: "./assets/forms/auth/forgot-password-form-schema.json",
  RESET_PASSWORD: "./assets/forms/auth/reset-password-form-schema.json",
  NEW_TASK: "./assets/forms/work-flow/new-task.schema.json",
  TABLE_COLUMNS_NUM:
    "./assets/forms/abstract-review/columns-num-form-schema.json",
};
