import { SimpleFilterModel } from "@@intelease/app-models/documents/src/lib/documents";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  EntityFieldModel,
  ListResponseModel,
  PaginationModel,
  ServerResponseModel,
  TableColumnsModel,
} from "@@intelease/web/intelease/models";
import { convertTableColumnsToServerField } from "@@intelease/web/intelease/utils";
import { SortDirectionEnum } from "@@intelease/web/common/enums/sort-direction.enum";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { FileApiService } from "@@intelease/web/intelease/services/backend";
import {
  AbstractKeyValue,
  MinimalAbstractModel,
  UserPermissionsModel,
} from "@@intelease/web/common/models";
import { CreateApiService } from "@@intelease/web/intelease/services/backend/create";
import { DeleteApiService } from "@@intelease/web/intelease/services/backend/delete/delete-api.service";
import { FetchBatchApiService } from "@@intelease/web/intelease/services/backend/fetch/fetch-batch-api.service";
import { FormNameAbstractModel } from "@@intelease/web/common/models/doc-abstract/form-name-abstract.model";
import {
  FetchAbstractService,
  SearchAbstractService,
  ShareAbstractService,
  ShareBatchAbstractService,
} from "@@intelease/web/intelease/services/models";
import { DeleteBatchAbstractService } from "@@intelease/web/intelease/services/models/doc-abstract/delete";
import { FileBatchApiService } from "@@intelease/web/intelease/services/backend/file/file-batch-api.service";
import { map } from "rxjs/operators";
import { PROVISIONS_FORMS } from "@@intelease/app-models/common";
import { CommonFacade } from "@@intelease/app-state/common";
import { cloneDeep } from "lodash";
import { FinalAbstractModel } from "@@intelease/app-models/abstract-review";

const FIXED_COLUMNS = [];

const EXCEPTION_COLUMNS = ["checkbox", "action"];

@Injectable({
  providedIn: "root",
})
export class DocumentsService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly API_VERSION_2 = "/v2";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly FINAL_DOC_SETS_PROVISIONS_URL =
    "/finalDocSets/provisions";
  private static readonly SAVED_DOC_LISTS_URL = "/savedDocLists";
  private static readonly TSV_FORMAT_URL = "/tsv";
  private static readonly PROVISIONS_FORM_PATH = "/assets/forms/provisions";

  private tableColumns: TableColumnsModel[] = [];
  private tableFilters = [];

  constructor(
    private httpClient: HttpClient,
    private createApiService: CreateApiService,
    private deleteApiService: DeleteApiService,
    private fetchBatchApiService: FetchBatchApiService,
    private fileApiService: FileApiService,
    private fileBatchApiService: FileBatchApiService,
    private searchAbstractService: SearchAbstractService,
    private shareAbstractService: ShareAbstractService,
    private shareBatchAbstractService: ShareBatchAbstractService,
    private fetchAbstractService: FetchAbstractService,
    private deleteBatchAbstractService: DeleteBatchAbstractService,
    private commonFacade: CommonFacade
  ) {}

  deleteAbstracts(
    abstractUids: string[],
    allVersions: boolean
  ): Observable<ServerResponseModel> {
    return this.deleteBatchAbstractService.deleteAbstractsNoView(
      abstractUids,
      allVersions
    );
  }

  deleteAllAbstracts(): Observable<ServerResponseModel> {
    return this.deleteBatchAbstractService.deleteAllAbstractsNoView();
  }

  shareAllAbstracts(userUids: string[]): Observable<ServerResponseModel> {
    return this.shareBatchAbstractService.shareAllAbstractsNoView(
      userUids,
      true
    );
  }

  shareAbstracts(
    abstractUids: string[],
    userUids: string[]
  ): Observable<ServerResponseModel> {
    return this.shareBatchAbstractService.shareAbstractsNoView(
      abstractUids,
      userUids,
      true
    );
  }

  shareAbstract(abstractUid: string, userUids: string[]): void {
    this.shareAbstractService.shareAbstract(abstractUid, userUids, true);
  }

  /**
   * search in Documents
   */
  searchFinalDocuments(
    pageable?: PaginationModel,
    sort?: string
  ): Observable<ListResponseModel<AbstractKeyValue>> {
    return this.searchAbstractService.searchFinalDocuments(
      this.getViewColumns(),
      this.getFilters(),
      pageable,
      sort
    );
  }

  searchByDocumentName(searchStr: string): Observable<MinimalAbstractModel[]> {
    return this.searchAbstractService.searchByDocumentName(
      searchStr,
      MinimalAbstractModel.view,
      MinimalAbstractModel
    );
  }

  searchByDocumentNameRelatedDocs(
    searchStr: string
  ): Observable<FinalAbstractModel[]> {
    return this.searchAbstractService.searchByDocumentName(
      searchStr,
      "relatedDocs",
      FinalAbstractModel,
      1000
    );
  }

  /**
   * get column fields
   */
  getPossibleFields(): Observable<ListResponseModel<EntityFieldModel>> {
    return this.fetchBatchApiService.sendBatchRequest(
      DocumentsService.API_VERSION_1,
      DocumentsService.FINAL_DOC_SETS_PROVISIONS_URL,
      EntityFieldModel.view,
      EntityFieldModel,
      "uiName::Name::" +
        PROVISIONS_DATA_CONST.STRING.name +
        "::" +
        SortDirectionEnum.ASC
    );
  }

  getSavedFilterList(): Observable<ListResponseModel<SimpleFilterModel>> {
    return this.fetchBatchApiService.sendBatchRequest(
      DocumentsService.API_VERSION_2,
      DocumentsService.SAVED_DOC_LISTS_URL,
      SimpleFilterModel.view,
      SimpleFilterModel
    );
  }

  /**
   * delete saved document list by uid
   */
  deleteSavedDocListById(uid: string): Observable<ServerResponseModel> {
    return this.deleteApiService.sendRequestNoView(
      DocumentsService.API_VERSION_2,
      DocumentsService.SAVED_DOC_LISTS_URL,
      uid
    );
  }

  getFormNameAbstract(abstractUid: string): Observable<FormNameAbstractModel> {
    return this.fetchAbstractService.getAbstractView(
      abstractUid,
      FormNameAbstractModel.view,
      FormNameAbstractModel
    );
  }

  getUsersPermissionsAbstract(
    abstractUid: string
  ): Observable<UserPermissionsModel> {
    return this.fetchAbstractService.getAbstractView(
      abstractUid,
      UserPermissionsModel.view,
      UserPermissionsModel
    );
  }

  exportAbstract(
    uid: string,
    abstractFilename: string,
    fileType: string,
    options?: { url: string }
  ) {
    let filename = abstractFilename;
    let dataFormat;
    switch (fileType) {
      case "CSV":
        filename += ".tsv";
        dataFormat = "/tsv";
        break;
      case "WT TSV":
        filename += ".zip";
        dataFormat = "application/zip";
        break;
      case "XLSX":
        filename += ".xlsx";
        dataFormat =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "DOCX":
        filename += ".docx";
        dataFormat =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "PDF":
        filename += ".pdf";
        dataFormat = "application/pdf";
        break;
    }

    if (dataFormat === DocumentsService.TSV_FORMAT_URL) {
      this.fileApiService.sendExportOneRequestNew(
        DocumentsService.API_VERSION_1,
        options ? options.url : DocumentsService.FINAL_DOC_SETS_URL,
        "export/tsv",
        "text/csv",
        filename
      );
    } else {
      this.fileApiService.sendExportOneRequestNew(
        DocumentsService.API_VERSION_1,
        options ? options.url : DocumentsService.FINAL_DOC_SETS_URL,
        uid,
        dataFormat,
        filename
      );
    }
  }

  zipExportAllAbstracts(filename: string, dataFormat: string): Observable<any> {
    return this.fileBatchApiService.sendZipExportAllRequest(
      DocumentsService.API_VERSION_1,
      `${DocumentsService.FINAL_DOC_SETS_URL}`,
      dataFormat,
      filename
    );
  }

  zipExportAbstracts(
    filename: string,
    abstractUids: string[],
    dataFormat: string
  ): Observable<any> {
    return this.fileBatchApiService.sendZipExportRequest(
      DocumentsService.API_VERSION_1,
      `${DocumentsService.FINAL_DOC_SETS_URL}`,
      abstractUids,
      dataFormat,
      filename
    );
  }

  exportAllAbstracts(filename: string): void {
    const data = {
      filename: filename,
      selectAll: true,
    };
    const body = {
      data: data,
      returnParams: {
        view: "bytes",
      },
    };
    this.fileBatchApiService.sendExportRequest(
      DocumentsService.API_VERSION_1,
      DocumentsService.FINAL_DOC_SETS_URL,
      DocumentsService.TSV_FORMAT_URL,
      body,
      filename
    );
  }

  exportAbstracts(filename: string, abstractUids: string[]): void {
    const data = {
      filename: filename,
      abstractUids: abstractUids,
      selectAll: false,
    };
    const body = {
      data: data,
      returnParams: {
        view: "bytes",
      },
    };
    this.fileBatchApiService.sendExportRequest(
      DocumentsService.API_VERSION_1,
      DocumentsService.FINAL_DOC_SETS_URL,
      DocumentsService.TSV_FORMAT_URL,
      body,
      filename
    );
  }

  setTableColumns(columns: TableColumnsModel[]): void {
    this.tableColumns = columns;
  }

  getTableColumns(): TableColumnsModel[] {
    return this.tableColumns;
  }

  getFilters() {
    return this.tableFilters;
  }

  /**
   * get UI specific columns
   */
  getUITableColumns(): TableColumnsModel[] {
    return this.tableColumns.filter((column) => {
      if (EXCEPTION_COLUMNS.indexOf(column.prop) === -1) {
        return column;
      }
    });
  }

  /**
   * get server friendly columns
   */
  private getViewColumns(): string[] {
    return [
      ...convertTableColumnsToServerField(this.getTableColumns()),
      ...FIXED_COLUMNS,
    ];
  }

  getProvisionFormSchemaByType(
    type: string,
    selectedFilter: EntityFieldModel
  ): Observable<any> {
    return this.commonFacade.getProvisionsMetadata().pipe(
      map((provisionsMetadataRes) => {
        if (provisionsMetadataRes) {
          const { countries, currencies, states } = provisionsMetadataRes;
          const res = cloneDeep(PROVISIONS_FORMS[type.toLocaleLowerCase()]);
          switch (type) {
            case "MONEY":
              res.properties.currency.items = currencies.map((item) => {
                const { currency } = item;
                return { title: currency, value: currency };
              });
              return res;
            case "DOUBLE":
              res.properties.unit.items = selectedFilter.possibleUnits.map(
                (item) => {
                  return { title: item, value: item };
                }
              );
              return res;
            case "SINGLE_CAT":
            case "MULTIPLE_CAT":
              res.properties.value.items = selectedFilter.possibleValues.map(
                (item) => {
                  return { title: item, value: item };
                }
              );
              return res;
            case "ADDRESS":
              res.properties.state.items = states.map((item) => {
                const { name } = item;
                return { title: name, value: name };
              });
              res.properties.country.items = countries.map((item) => {
                const { name } = item;
                return { title: name, value: name };
              });
              return res;
            default:
              return res;
          }
        }
      })
    );
  }
}
