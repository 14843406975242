import { JsonObject, JsonProperty } from "json2typescript";
import { ProvisionGroupNewStringModel } from "@@intelease/web/common/models/doc-abstract/abstract-part";

@JsonObject("MultiProvisionStringsAbstractModel")
export class MultiProvisionStringsAbstractModel {
  static view = "multiProvisionStrings";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("provisionGroups", [ProvisionGroupNewStringModel])
  provisionGroups: ProvisionGroupNewStringModel[] = [];

  @JsonProperty("abstractFilename", String)
  name = "";

  @JsonProperty("abstractName", String)
  abstractName = "";

  @JsonProperty("lastModifiedDate", String)
  lastModifiedDate: string = undefined;

  @JsonProperty("docNames", [String])
  docNames: string[] = undefined;

  @JsonProperty("docAbstractUids", [String])
  docAbstractUids: string[] = undefined;

  @JsonProperty("status", String)
  status = "";

  @JsonProperty("abstractorCode", String)
  abstractorCode = "";
}
