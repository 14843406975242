import { JsonObject, JsonProperty } from "json2typescript";
import { FullNameUserModel } from "@@intelease/web/common/models/user";

@JsonObject("FullGroupModel")
export class FullTeamModel {
  static view = "full";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("users", [FullNameUserModel])
  users: FullNameUserModel[] = [];
}
