import { CalendarAction, CalendarActionTypes } from "./calendar.actions";

export const CALENDAR_FEATURE_KEY = "calendar";

/**
 * Interface for the 'Calendar' data used in
 *  - CalendarState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* eslint-disable-next-line */
export interface Entity {}

export interface CalendarState {
  list?: Entity[]; // list of Calendar; analogous to a sql normalized table
  selectedId?: string | number; // which Calendar record has been selected
  loaded?: boolean; // has the Calendar list been loaded
  error?: any; // last none error (if any),

  calendarFilterFields?: any;
  calendarFilterFieldsLoaded?: boolean;
  calendarFilterFieldsLoadError?: any;

  calendarDisplayableFields?: any;
  calendarDisplayableFieldsLoaded?: boolean;
  calendarDisplayableFieldsLoadError?: any;

  searchCalendarFinalAbstractsResult?: any;
  searchCalendarFinalAbstractsResultLoaded?: boolean;
  isSearchCalendarFinalAbstractsLoading?: number;
  searchCalendarFinalAbstractsResultLoadError?: any;
  searchCalendarFinalAbstractsResultMap?: any;

  provisionsOperator?: any;
  selectedFilters?: any;
  selectedFields?: any;

  isLoadCalendarsLoading?: boolean;
  calendarList?: any[];
  calendarsLoaded?: boolean;
  calendarsLoadError?: any;

  isSaveCalendarPending?: boolean;
  saveCalendarSucceeded?: boolean;
  saveCalendarFailed?: any;

  isUpdateCalendarPending?: boolean;
  updateCalendarSucceeded?: boolean;
  updateCalendarFailed?: boolean;

  selectedCalendarDetail?: any;
  isSelectedCalendarDetailLoading?: boolean;
  isSelectedCalendarLoaded?: boolean;
  selectedCalendarLoadError?: any;
  calendarsDetailMap?: any;

  isDeleteSelectedCalendarPending?: boolean;
  isDeleteSelectedCalendarSucceeded?: boolean;
  deleteSelectedCalendarFailed?: any;

  isSaveCalendarQueryPending?: boolean;
  isSaveCalendarQuerySucceeded?: boolean;
  saveCalendarQueryFailed?: any;

  isUpdateCalendarQueryPending?: boolean;
  isUpdateCalendarQuerySucceeded?: boolean;
  updateCalendarQueryFailed?: any;

  isLoadCalendarQueriesPending?: boolean;
  selectedCalendarQueries?: any;
  isCalendarQueriesLoaded?: boolean;
  calendarQueriesLoadError?: any;

  isDeleteSelectedCalendarQueryPending?: boolean;
  isDeleteSelectedCalendarQuerySucceeded?: boolean;
  deleteSelectedCalendarQueryFailed?: any;

  isUpdateSelectedCalendarQueryActivePending?: boolean;
  isUpdateSelectedCalendarQueryActiveSucceeded?: boolean;
  isUpdateSelectedCalendarQueryActiveFailed?: any;

  isRunCalendarQueryPending?: boolean;
  isRunCalendarQuerySucceeded?: boolean;
  runCalendarQueryFailed?: any;

  isDeleteMultipleCalendarPending?: boolean;
  deleteMultipleCalendarSucceeded?: boolean;
  deleteMultipleCalendarFailed?: any;
}

export interface CalendarPartialState {
  readonly [CALENDAR_FEATURE_KEY]: CalendarState;
}

export const initialState: CalendarState = {
  list: [],
  loaded: false,
  calendarFilterFields: {},
  calendarDisplayableFields: {},
  selectedFilters: { generalFilters: [], provisionFilters: [] },
  selectedFields: { generalViewColumns: [], provisionViewColumns: [] },
  calendarsDetailMap: {},
  selectedCalendarLoadError: {},
  searchCalendarFinalAbstractsResultMap: {},
  isSearchCalendarFinalAbstractsLoading: 0,
};

export function reducer(
  state: CalendarState = initialState,
  action: CalendarAction
): CalendarState {
  switch (action.type) {
    case CalendarActionTypes.CalendarLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true,
      };
      break;
    }
    case CalendarActionTypes.LoadCalendarFilterFields: {
      state = {
        ...state,
        calendarFilterFields: undefined,
        calendarFilterFieldsLoaded: false,
        calendarFilterFieldsLoadError: undefined,
      };
      break;
    }
    case CalendarActionTypes.CalendarFilterFieldsLoaded: {
      const { filterProvisions, provisionsOperator } = action.payload;
      state = {
        ...state,
        calendarFilterFields: filterProvisions,
        provisionsOperator,
        calendarFilterFieldsLoaded: true,
        calendarFilterFieldsLoadError: undefined,
      };
      break;
    }
    case CalendarActionTypes.CalendarFilterFieldsLoadError: {
      state = {
        ...state,
        calendarFilterFields: undefined,
        calendarFilterFieldsLoaded: false,
        calendarFilterFieldsLoadError: action.payload,
      };
      break;
    }
    case CalendarActionTypes.LoadCalendarDisplayableFields: {
      state = {
        ...state,
        calendarDisplayableFields: undefined,
        calendarDisplayableFieldsLoaded: false,
        calendarDisplayableFieldsLoadError: undefined,
      };
      break;
    }
    case CalendarActionTypes.CalendarDisplayableFieldsLoaded: {
      const { filterProvisions } = action.payload;
      state = {
        ...state,
        calendarDisplayableFields: filterProvisions,
        calendarDisplayableFieldsLoaded: true,
        calendarDisplayableFieldsLoadError: undefined,
      };
      break;
    }
    case CalendarActionTypes.CalendarDisplayableFieldsLoadError: {
      state = {
        ...state,
        calendarDisplayableFields: undefined,
        calendarDisplayableFieldsLoaded: false,
        calendarDisplayableFieldsLoadError: undefined,
      };
      break;
    }
    case CalendarActionTypes.SearchCalendarFinalAbstracts: {
      state = {
        ...state,
        searchCalendarFinalAbstractsResult: undefined,
        isSearchCalendarFinalAbstractsLoading:
          state.isSearchCalendarFinalAbstractsLoading + 1,
        searchCalendarFinalAbstractsResultLoaded: false,
        searchCalendarFinalAbstractsResultLoadError: undefined,
        searchCalendarFinalAbstractsResultMap: {
          ...state.searchCalendarFinalAbstractsResultMap,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case CalendarActionTypes.SearchCalendarFinalAbstractsLoaded: {
      state = {
        ...state,
        searchCalendarFinalAbstractsResult: {
          items: action.payload,
          calendarQueryUid: action.calendarQueryUid,
        },
        searchCalendarFinalAbstractsResultLoaded: true,
        isSearchCalendarFinalAbstractsLoading:
          state.isSearchCalendarFinalAbstractsLoading - 1,
        searchCalendarFinalAbstractsResultLoadError: undefined,
        searchCalendarFinalAbstractsResultMap: {
          ...state.searchCalendarFinalAbstractsResultMap,
          [action.selectedInstanceId]: {
            items: action.payload,
            calendarQueryUid: action.calendarQueryUid,
          },
        },
      };
      break;
    }
    case CalendarActionTypes.SearchCalendarFinalAbstractsLoadError: {
      state = {
        ...state,
        searchCalendarFinalAbstractsResult: undefined,
        searchCalendarFinalAbstractsResultLoaded: false,
        isSearchCalendarFinalAbstractsLoading:
          state.isSearchCalendarFinalAbstractsLoading - 1,
        searchCalendarFinalAbstractsResultLoadError: action.payload,
        searchCalendarFinalAbstractsResultMap: {
          ...state.searchCalendarFinalAbstractsResultMap,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case CalendarActionTypes.SaveSelectedCalendarFilters: {
      state = {
        ...state,
        selectedFilters: action.payload,
      };
      break;
    }
    case CalendarActionTypes.SaveSelectedCalendarField: {
      state = {
        ...state,
        selectedFields: action.payload,
      };
      break;
    }
    case CalendarActionTypes.LoadCalendars: {
      state = {
        ...state,
        calendarList: undefined,
        isLoadCalendarsLoading: true,
        calendarsLoaded: false,
        calendarsLoadError: undefined,
      };
      break;
    }
    case CalendarActionTypes.CalendarsLoaded: {
      state = {
        ...state,
        calendarList: action.payload,
        isLoadCalendarsLoading: false,
        calendarsLoaded: true,
        calendarsLoadError: undefined,
      };
      break;
    }
    case CalendarActionTypes.CalendarsLoadError: {
      state = {
        ...state,
        calendarList: undefined,
        isLoadCalendarsLoading: false,
        calendarsLoaded: false,
        calendarsLoadError: action.payload,
      };
      break;
    }

    case CalendarActionTypes.SaveCalendar: {
      state = {
        ...state,
        isSaveCalendarPending: true,
        saveCalendarSucceeded: false,
        saveCalendarFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.SaveCalendarSucceeded: {
      state = {
        ...state,
        isSaveCalendarPending: false,
        saveCalendarSucceeded: true,
        saveCalendarFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.SaveCalendarFailed: {
      state = {
        ...state,
        isSaveCalendarPending: false,
        saveCalendarSucceeded: false,
        saveCalendarFailed: action.payload,
      };
      break;
    }

    case CalendarActionTypes.UpdateCalendar: {
      state = {
        ...state,
        isUpdateCalendarPending: true,
        updateCalendarSucceeded: false,
        updateCalendarFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.UpdateCalendarSucceeded: {
      state = {
        ...state,
        isUpdateCalendarPending: false,
        updateCalendarSucceeded: true,
        updateCalendarFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.UpdateCalendarFailed: {
      state = {
        ...state,
        isUpdateCalendarPending: false,
        updateCalendarSucceeded: false,
        updateCalendarFailed: action.payload,
      };
      break;
    }

    case CalendarActionTypes.UpdateCalendarQuery: {
      state = {
        ...state,
        isUpdateCalendarQueryPending: true,
        isUpdateCalendarQuerySucceeded: false,
        updateCalendarQueryFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.UpdateCalendarQuerySucceeded: {
      state = {
        ...state,
        isUpdateCalendarQueryPending: false,
        isUpdateCalendarQuerySucceeded: true,
        updateCalendarQueryFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.UpdateCalendarQueryFailed: {
      state = {
        ...state,
        isUpdateCalendarQueryPending: false,
        isUpdateCalendarQuerySucceeded: false,
        updateCalendarQueryFailed: action.payload,
      };
      break;
    }

    case CalendarActionTypes.CleanCalendarState: {
      state = {
        ...state,
        ...action.payload,
      };
      break;
    }
    case CalendarActionTypes.LoadSelectedCalendar: {
      state = {
        ...state,
        selectedCalendarDetail: undefined,
        isSelectedCalendarDetailLoading: true,
        isSelectedCalendarLoaded: false,
        selectedCalendarLoadError: {
          ...state.selectedCalendarLoadError,
          [action.selectedInstanceId]: undefined,
        },
        calendarsDetailMap: {
          ...state.calendarsDetailMap,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case CalendarActionTypes.SelectedCalendarLoaded: {
      state = {
        ...state,
        selectedCalendarDetail: action.payload,
        isSelectedCalendarDetailLoading: false,
        isSelectedCalendarLoaded: true,
        selectedCalendarLoadError: {
          ...state.selectedCalendarLoadError,
          [action.selectedInstanceId]: undefined,
        },
        calendarsDetailMap: {
          ...state.calendarsDetailMap,
          [action.selectedInstanceId]: action.payload,
        },
      };
      break;
    }
    case CalendarActionTypes.SelectedCalendarLoadError: {
      state = {
        ...state,
        selectedCalendarDetail: undefined,
        isSelectedCalendarDetailLoading: false,
        isSelectedCalendarLoaded: false,
        selectedCalendarLoadError: {
          ...state.selectedCalendarLoadError,
          [action.selectedInstanceId]: action.payload,
        },
        calendarsDetailMap: {
          ...state.calendarsDetailMap,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case CalendarActionTypes.DeleteSelectedCalendar: {
      state = {
        ...state,
        isDeleteSelectedCalendarPending: true,
        isDeleteSelectedCalendarSucceeded: false,
        deleteSelectedCalendarFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.DeleteSelectedCalendarSucceeded: {
      state = {
        ...state,
        isDeleteSelectedCalendarPending: false,
        isDeleteSelectedCalendarSucceeded: true,
        deleteSelectedCalendarFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.DeleteSelectedCalendarFailed: {
      state = {
        ...state,
        isDeleteSelectedCalendarPending: false,
        isDeleteSelectedCalendarSucceeded: false,
        deleteSelectedCalendarFailed: action.payload,
      };
      break;
    }
    case CalendarActionTypes.SaveCalendarQuery: {
      state = {
        ...state,
        isSaveCalendarQueryPending: true,
        isSaveCalendarQuerySucceeded: false,
        saveCalendarQueryFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.SaveCalendarQuerySucceeded: {
      state = {
        ...state,
        isSaveCalendarQueryPending: false,
        isSaveCalendarQuerySucceeded: true,
        saveCalendarQueryFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.SaveCalendarQueryFailed: {
      state = {
        ...state,
        isSaveCalendarQueryPending: false,
        isSaveCalendarQuerySucceeded: false,
        saveCalendarQueryFailed: action.payload,
      };
      break;
    }
    case CalendarActionTypes.LoadCalendarQueries: {
      state = {
        ...state,
        isLoadCalendarQueriesPending: true,
        selectedCalendarQueries: undefined,
        isCalendarQueriesLoaded: false,
        calendarQueriesLoadError: undefined,
      };
      break;
    }
    case CalendarActionTypes.CalendarQueriesLoaded: {
      state = {
        ...state,
        isLoadCalendarQueriesPending: false,
        selectedCalendarQueries: action.payload,
        isCalendarQueriesLoaded: true,
        calendarQueriesLoadError: undefined,
      };
      break;
    }
    case CalendarActionTypes.CalendarQueriesLoadError: {
      state = {
        ...state,
        isLoadCalendarQueriesPending: false,
        selectedCalendarQueries: undefined,
        isCalendarQueriesLoaded: false,
        calendarQueriesLoadError: action.payload,
      };
      break;
    }
    case CalendarActionTypes.DeleteSelectedCalendarQuery: {
      state = {
        ...state,
        isDeleteSelectedCalendarQueryPending: true,
        isDeleteSelectedCalendarQuerySucceeded: false,
        deleteSelectedCalendarQueryFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.DeleteSelectedCalendarQuerySucceeded: {
      state = {
        ...state,
        isDeleteSelectedCalendarQueryPending: false,
        isDeleteSelectedCalendarQuerySucceeded: true,
        deleteSelectedCalendarQueryFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.DeleteSelectedCalendarQueryFailed: {
      state = {
        ...state,
        isDeleteSelectedCalendarQueryPending: false,
        isDeleteSelectedCalendarQuerySucceeded: true,
        deleteSelectedCalendarQueryFailed: action.payload,
      };
      break;
    }
    case CalendarActionTypes.UpdateSelectedCalendarQueryActive: {
      state = {
        ...state,
        isUpdateSelectedCalendarQueryActivePending: true,
        isUpdateSelectedCalendarQueryActiveSucceeded: false,
        isUpdateSelectedCalendarQueryActiveFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.UpdateSelectedCalendarQueryActiveSucceeded: {
      state = {
        ...state,
        isUpdateSelectedCalendarQueryActivePending: false,
        isUpdateSelectedCalendarQueryActiveSucceeded: true,
        isUpdateSelectedCalendarQueryActiveFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.UpdateSelectedCalendarQueryActiveFailed: {
      state = {
        ...state,
        isUpdateSelectedCalendarQueryActivePending: false,
        isUpdateSelectedCalendarQueryActiveSucceeded: false,
        isUpdateSelectedCalendarQueryActiveFailed: action.payload,
      };
      break;
    }
    case CalendarActionTypes.RunCalendarQuery: {
      state = {
        ...state,
        isRunCalendarQueryPending: true,
        isRunCalendarQuerySucceeded: false,
        runCalendarQueryFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.RunCalendarQuerySucceeded: {
      state = {
        ...state,
        isRunCalendarQueryPending: false,
        isRunCalendarQuerySucceeded: true,
        runCalendarQueryFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.RunCalendarQueryFailed: {
      state = {
        ...state,
        isRunCalendarQueryPending: false,
        isRunCalendarQuerySucceeded: false,
        runCalendarQueryFailed: action.payload,
      };
      break;
    }
    case CalendarActionTypes.DeleteMultipleCalendar: {
      state = {
        ...state,
        isDeleteMultipleCalendarPending: true,
        deleteMultipleCalendarSucceeded: false,
        deleteMultipleCalendarFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.DeleteMultipleCalendarSucceeded: {
      state = {
        ...state,
        isDeleteMultipleCalendarPending: false,
        deleteMultipleCalendarSucceeded: true,
        deleteMultipleCalendarFailed: undefined,
      };
      break;
    }
    case CalendarActionTypes.DeleteMultipleCalendarFailed: {
      state = {
        ...state,
        isDeleteMultipleCalendarPending: false,
        deleteMultipleCalendarSucceeded: false,
        deleteMultipleCalendarFailed: action.payload,
      };
      break;
    }
  }
  return state;
}
