<div>
  <div mat-dialog-title class="clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModalClick()"
      aria-hidden="true"
      dataCy="closeAddProvisionFormModal"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5 class="mt-0">Import a New Provision Form</h5>

    <div class="form-group">
      <label> Select TSV File </label>
      <input
        type="file"
        (change)="onFileChange($event)"
        accept="text/tab-separated-values,.tsv"
      />
    </div>
  </div>
  <div mat-dialog-content style="overflow: visible">
    <div mat-dialog-actions>
      <div [matTooltip]="'Import TSV file'">
        <button
          type="button"
          class="btn btn-primary btn-cons pull-left inline"
          (click)="onImportClick()"
          [disabled]="!isValidForm"
        >
          Import
        </button>
      </div>
    </div>
  </div>
</div>
