/* tslint:disable */
/* eslint-disable */
import { NotificationCreatorDtoModel } from "./notification-creator-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("NotificationDtoModel")
export class NotificationDtoModel {
  @JsonProperty("category", Any, true)
  category?: "ALL" | "ARCHIVED" | "INBOX" | "FOLLOW" | "TASK" | "SHARE" =
    undefined;

  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("creator", NotificationCreatorDtoModel, true)
  creator?: NotificationCreatorDtoModel = undefined;

  @JsonProperty("metadata", Any, true)
  metadata?: {
    [key: string]: {};
  } = {};

  @JsonProperty("read", Boolean, true)
  read?: boolean = undefined;

  @JsonProperty("type", Any, true)
  type?:
    | "REMINDER"
    | "MENTIONED_IN_COMMENT"
    | "DOCUMENT_MENTIONED_IN_COMMENT"
    | "PROVISION_MENTIONED_IN_COMMENT"
    | "ISSUE_MENTIONED_IN_COMMENT"
    | "NEW_TASK_ASSIGNED"
    | "ASSIGNED_TASK_UPDATED"
    | "ASSIGNED_TASK_UNASSIGNED"
    | "ASSIGNED_TASK_DELETED"
    | "ABSTRACTION_FINISHED"
    | "MASS_UPLOAD_ABSTRACTION_FINISHED"
    | "ABSTRACT_DELETED"
    | "ABSTRACT_RENAMED"
    | "ABSTRACT_ABSTRACTOR_NAME_CHANGED"
    | "ABSTRACT_STATUS_CHANGED"
    | "ADDED_PROVISION_MENTION"
    | "EDITED_PROVISION_MENTION"
    | "DELETED_PROVISION_MENTION"
    | "ADDED_COMMENT"
    | "EDITED_COMMENT"
    | "DELETED_COMMENT"
    | "ADDED_TASK"
    | "EDITED_TASK"
    | "DELETED_TASK"
    | "ABSTRACT_SHARED"
    | "DOC_SET_SHARED"
    | "PROJECT_SHARED"
    | "CALENDAR_SHARED"
    | "DASHBOARD_SHARED"
    | "DASHBOARD_SHARED"
    | "STACK_SHARED"
    | "ABSTRACT_REQUEST_ACCESS"
    | "PROJECT_REQUEST_ACCESS"
    | "CALENDAR_REQUEST_ACCESS"
    | "DASHBOARD_REQUEST_ACCESS" = undefined;

  @JsonProperty("typeTitle", String, true)
  typeTitle?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
