/* tslint:disable */
/* eslint-disable */
import { CreateCalendarQueryDtoModel } from "./create-calendar-query-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateCalendarDtoModel")
export class CreateCalendarDtoModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("name", String)
  name: string = "";

  @JsonProperty("queries", [CreateCalendarQueryDtoModel], true)
  queries?: Array<CreateCalendarQueryDtoModel> = [];

  @JsonProperty("view", Any)
  view: "month" | "week" | "day" | "agenda" = undefined;
}
