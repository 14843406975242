import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  MentionEditTrackerState,
  MENTION_EDIT_TRACKER_FEATURE_KEY,
} from "./mention-edit-tracker.reducer";

const getMentionEditTrackerState =
  createFeatureSelector<MentionEditTrackerState>(
    MENTION_EDIT_TRACKER_FEATURE_KEY
  );

const getLastTouchedMentionUid = createSelector(
  getMentionEditTrackerState,
  (state: MentionEditTrackerState) => state.lastTouchedMentionUid
);

const getLastTouchedNestedSubfieldKey = createSelector(
  getMentionEditTrackerState,
  (state: MentionEditTrackerState) => state.lastNestedSubfieldTouched
);

const getLastEditedNestedSubfieldKey = createSelector(
  getMentionEditTrackerState,
  (state: MentionEditTrackerState) => state.lastEditedNestedSubfieldKey
);

const getLocateNestedMentionOperation = createSelector(
  getMentionEditTrackerState,
  (state: MentionEditTrackerState) => ({
    nestedMentionUid: state.nestedMentionUid,
    nestedSubfieldKey: state.nestedSubfieldKey,
  })
);

const getAnchorMentionChangedIndex = createSelector(
  getMentionEditTrackerState,
  (state: MentionEditTrackerState) => state.anchorMentionIndex
);

export const mentionEditTrackerQuery = {
  getLastTouchedMentionUid,
  getLastTouchedNestedSubfieldKey,
  getLastEditedNestedSubfieldKey,
  getLocateNestedMentionOperation,
  getAnchorMentionChangedIndex,
};
