import { ItlsCustomIconType } from "@@intelease/web/common/components/itls-custom-icon";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";

@Component({
  selector: "itls-left-menu-navigation-item",
  templateUrl: "./left-menu-navigation-item.component.html",
  styleUrls: ["./left-menu-navigation-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftMenuNavigationItemComponent {
  @Input()
  expanded = false;

  @Input()
  icon: ItlsCustomIconType;

  @Input()
  tooltip: string;

  @Input()
  link: string;
}
