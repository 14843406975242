import { Component, Inject, OnInit } from "@angular/core";
import {
  ModalInputModel,
  ModalResponseModel,
} from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { UserInfoService } from "@@intelease/web/intelease/services";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ImportTsvFormModalComponent } from "./import-tsv-form-modal.component";

@Component({
  selector: "itls-add-provision-form-modal",
  templateUrl: "./add-provision-form-modal.component.html",
  styleUrls: ["./add-provision-form.modal-component.scss"],
})
export class AddProvisionFormModalComponent implements OnInit {
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload;
  name = "";
  description = "";
  documentTypeOptions = UserInfoService.getDocSetCategories();
  selectedDocumentType: string;
  buildOrImport: "FORM_BUILDER" | "IMPORT_TSV" = "FORM_BUILDER";
  isValidateFields = true;
  tooltipCreateBtn = "The provision form name is required!";
  isChildModalOpen = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ModalInputModel,
    public dialogRef: MatDialogRef<AddProvisionFormModalComponent>,
    private dialog: MatDialog,
    private inputValidationService: InputValidationService
  ) {}

  ngOnInit() {
    this.payload = this.dialogData.payload;
    this.mode = this.dialogData.mode;
  }

  onSaveClick() {
    const name = this.inputValidationService.sanitizeText(this.name);
    const desc = this.inputValidationService.sanitizeText(this.description);
    const selectedDocTypes = this.inputValidationService.sanitizeText(
      this.selectedDocumentType
    );
    if (!this.validateFields(name, selectedDocTypes)) {
      return;
    }

    if (this.buildOrImport === "IMPORT_TSV") {
      const modalData = new ModalInputModel();
      modalData.payload = {
        name,
        description: desc,
        docSetCategory: selectedDocTypes,
      };
      this.isChildModalOpen = true;
      this.dialog
        .open(ImportTsvFormModalComponent, {
          data: modalData,
          minWidth: "500px",
          autoFocus: false,
        })
        .afterClosed()
        .subscribe((res) => {
          this.isChildModalOpen = false;
          const { type } = res;
          if (type === ModalsResponseTypeEnum.CLOSE) {
            this.dialogRef.close({
              ...this.modalRes,
              type: ModalsResponseTypeEnum.DISMISS,
            });
          }
        });
    } else {
      this.modalRes.data = {
        ...{ name: name, description: desc, flags: selectedDocTypes },
      };
      this.dialogRef.close({
        ...this.modalRes,
        type: ModalsResponseTypeEnum.CLOSE,
      });
    }
  }

  private validateFields(name: string, selectedDocType: string): boolean {
    if (
      !this.inputValidationService.usefulTextElseAlert(
        name,
        "You must fill in a name!"
      )
    ) {
      return false;
    }
    if (
      !this.inputValidationService.usefulTextElseAlert(
        selectedDocType,
        "You must select at least a document type!"
      )
    ) {
      return false;
    }
    return true;
  }

  checkValidateFields() {
    if (this.name === "") {
      this.tooltipCreateBtn = "The provision form name is required!";
      this.isValidateFields = true;
    } else if (
      this.selectedDocumentType === undefined ||
      this.selectedDocumentType === null
    ) {
      this.tooltipCreateBtn = "The document category is required!";
      this.isValidateFields = true;
    } else {
      this.tooltipCreateBtn = "Create";
      this.isValidateFields = false;
    }
  }

  onDismissModalClick() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}
