import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import {
  InteleasePermissionsService,
  PermissionApiService,
} from "@@intelease/web/intelease/services";
import { NgxPermissionsService } from "ngx-permissions";
import { Injectable } from "@angular/core";

/**
 * Similar to https://stackoverflow.com/questions/60927168/load-permissions-before-loading-module-when-is-accesed-final-route-into-url
 */
@Injectable({ providedIn: "root" })
export class PermissionsGuard {
  constructor(
    private readonly router: Router,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly permissionApiService: PermissionApiService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const expectedPermissions = next.data.permissions.only as Array<string>;
    const redirectTo = next.data.permissions.redirectTo;
    return this.getPermissionsAndHandle(expectedPermissions, redirectTo);
  }

  private async getPermissionsAndHandle(
    expectedPermissions: string[],
    redirectTo: string
  ) {
    const givenPermissions = this.ngxPermissionsService.getPermissions();
    let permissions = Object.keys(givenPermissions);
    if (!permissions.length) {
      permissions = await this.permissionApiService
        .getOperationalAuthoritiesForUser()
        .toPromise();
      this.ngxPermissionsService.loadPermissions(permissions);
    }
    return this.handlePermissions(permissions, expectedPermissions, redirectTo);
  }

  private handlePermissions(
    givenPermissions: string[],
    expectedPermissions: string[],
    redirectTo: string
  ) {
    if (
      InteleasePermissionsService.allExpectationsCovered(
        expectedPermissions,
        givenPermissions
      )
    ) {
      return true;
    }
    this.router.navigateByUrl(redirectTo);
    return false;
  }
}
