import { InjectionToken } from "@angular/core";
import { IXPlatWindow } from "@@intelease/web/utils";

/**
 * Various InjectionTokens shared across all platforms
 * Always suffix with 'Token' for clarity and consistency
 */

export const PlatformLanguageToken = new InjectionToken<string>(
  "PlatformLanguageToken",
  {
    providedIn: "root",
    factory: () => "en",
  }
);
export const PlatformWindowToken = new InjectionToken<IXPlatWindow>(
  "PlatformWindowToken",
  {
    providedIn: "root",
    factory: () => undefined,
  }
);
