import { CkTextEditorWidgetComponent } from "./ckTextEditor/ck-text-editor.widget";
import { ArrayWidgetComponent } from "./array/array.widget";
import { ButtonWidgetComponent } from "./button/button.widget";
import { CheckboxWidgetComponent } from "./checkbox/checkbox.widget";
import { FileWidgetComponent } from "./file/file.widget";
import { IntegerWidgetComponent } from "./integer/integer.widget";
import { ObjectWidgetComponent } from "./object/object.widget";
import { RadioWidgetComponent } from "./radio/radio.widget";
import { RangeWidgetComponent } from "./range/range.widget";
import { SelectWidgetComponent } from "./select/select.widget";
import { StringWidgetComponent } from "./string/string.widget";
import { TextAreaWidgetComponent } from "./textarea/textarea.widget";

import { WidgetRegistry } from "../widgetregistry";
import { MultiSelectDropdownWidgetComponent } from "./multi-select-dropdown/multi-select-dropdown.widget";
import { DatePickerWidgetComponent } from "./date-picker/date-picker.widget";
import { InputDropdownWidgetComponent } from "./input-dropdown/input-dropdown.widget";
import { Injectable } from "@angular/core";

@Injectable()
export class DefaultWidgetRegistry extends WidgetRegistry {
  constructor() {
    super();

    this.register("array", ArrayWidgetComponent);
    this.register("object", ObjectWidgetComponent);

    this.register("string", StringWidgetComponent);
    this.register("search", StringWidgetComponent);
    this.register("tel", StringWidgetComponent);
    this.register("url", StringWidgetComponent);
    this.register("email", StringWidgetComponent);
    this.register("password", StringWidgetComponent);
    this.register("color", StringWidgetComponent);
    this.register("date", StringWidgetComponent);
    this.register("date-time", StringWidgetComponent);
    this.register("time", StringWidgetComponent);

    this.register("integer", IntegerWidgetComponent);
    this.register("number", IntegerWidgetComponent);
    this.register("range", RangeWidgetComponent);

    this.register("textarea", TextAreaWidgetComponent);

    this.register("file", FileWidgetComponent);
    this.register("select", SelectWidgetComponent);
    this.register("radio", RadioWidgetComponent);
    this.register("boolean", CheckboxWidgetComponent);
    this.register("checkbox", CheckboxWidgetComponent);
    this.register("multi-select-dropdown", MultiSelectDropdownWidgetComponent);
    this.register("date-picker", DatePickerWidgetComponent);
    this.register("input-dropdown", InputDropdownWidgetComponent);
    this.register("textEditorCK", CkTextEditorWidgetComponent);

    this.register("button", ButtonWidgetComponent);

    this.setDefaultWidget(StringWidgetComponent);
  }
}
