<button
  mat-flat-button
  class="search-btn"
  (click)="queueFacade.toggleSearchbar()"
  *ngIf="(isSearchActive$ | async) === false"
  [@showDelay]
>
  <itls-icon svgIcon="icon-search"></itls-icon>
  Search
</button>

<div class="field-container" [@expand] *ngIf="isSearchActive$ | async">
  <itls-icon svgIcon="icon-search" class="icon search"></itls-icon>

  <input
    #searchInput
    class="form-control"
    type="text"
    placeholder="Search ..."
    (input)="searchRecordInQueue($event.target)"
    [matAutocomplete]="autoGroup"
    itlsFocusIn
    [itlsFocusInDelay]="200"
  />

  <mat-autocomplete #autoGroup="matAutocomplete">
    <mat-optgroup
      *ngFor="let group of keys(queueFacade.searchAllRecords$ | async)"
      [label]="TranslateQueueStage[group]"
      class="option-group"
    >
      <mat-option
        *ngFor="let record of (queueFacade.searchAllRecords$ | async)[group]"
        (click)="openRecord(record)"
      >
        {{ record.name }}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>

  <itls-icon
    svgIcon="icon-cancel"
    class="icon cancel"
    (click)="queueFacade.toggleSearchbar()"
  ></itls-icon>
</div>
