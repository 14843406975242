import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActorModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/actor.model";
import { RestClient } from "@@intelease/web/utils";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DomainsService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly DOMAINS_URL = "/domains";

  constructor(private restClient: RestClient) {}

  getActors(): Observable<ActorModel[]> {
    return this.restClient
      .sendGetListRequest(
        DomainsService.API_VERSION_1,
        DomainsService.DOMAINS_URL,
        ActorModel,
        ActorModel.view
      )
      .pipe(map((resp) => resp.items));
  }
}
