/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionFormStructureViewModel")
export class ProvisionFormStructureViewModel {
  /**
   * the user-facing unique id of the anchor provision in this provision form
   */

  @JsonProperty("anchorProvisionUid", String, true)
  anchorProvisionUid?: string = "";

  /**
   * the groups of provisions in this provision form
   */

  @JsonProperty("categories", [ProvisionFormStructureViewModel], true)
  categories?: Array<ProvisionFormStructureViewModel> = [];

  /**
   * the description of this provision form
   */

  @JsonProperty("description", String, true)
  description?: string = "";

  /**
   * the unique provision form human-readable id
   */

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  /**
   * the name of this provision form
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the user-facing unique human-readable ids of the provisions in this provision form
   */

  @JsonProperty("provisionInfoHtmlNames", [String], true)
  provisionInfoHtmlNames?: Array<string> = [];

  /**
   * the user-facing unique ids of the provisions in this provision form
   */

  @JsonProperty("provisionInfoUids", [String], true)
  provisionInfoUids?: Array<string> = [];

  /**
   * the user-facing unique provision form id
   */

  @JsonProperty("uid", String, true)
  uid?: string = "";

  /**
   * the version of this provision form
   */

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
