import { JsonObject, JsonProperty } from "json2typescript";
import { ActorPermissionModel } from "@@intelease/web/ui/src/lib/new-share-entity";

@JsonObject("EntityActorsPermissionsModel")
export class EntityActorsPermissionsModel {
  @JsonProperty("entityUid", String)
  entityUid = "";

  @JsonProperty("entityType", String)
  entityType:
    | "FINAL_ABSTRACT"
    | "DASHBOARD"
    | "PROJECT"
    | "DIRECTORY"
    | undefined = undefined;

  @JsonProperty("permissions", [ActorPermissionModel])
  permissions: ActorPermissionModel[] = [];
}
