<div class="form-modal">
  <!--  <div class="clearfix text-left">-->
  <h5 mat-dialog-title *ngIf="mode === 'ADD'" style="margin-top: 0">
    {{ addTitle }}
  </h5>
  <h5 mat-dialog-title *ngIf="mode === 'EDIT'" style="margin-top: 0">
    {{ editTitle }}
  </h5>
  <h5 mat-dialog-title *ngIf="mode === 'VIEW'" style="margin-top: 0">
    Task Details
  </h5>
  <p
    mat-dialog-title
    *ngIf="!!formDescription && formDescription?.length"
    style="margin-top: 0; color: #7a8994; font-size: 14px; margin-left: 20px"
  >
    {{ formDescription }}
  </p>
  <button
    type="button"
    class="close"
    style="position: absolute; top: 0; right: 0"
    (click)="onDismissModalClick()"
    aria-hidden="true"
  >
    <i class="pg pg-close fs-14"></i>
  </button>
  <!--  </div>-->
  <div mat-dialog-content *ngIf="formSchema">
    <div [style.opacity]="isLoadingEnabled ? 0.5 : 1" style="height: 100%">
      <sf-form
        [schema]="formSchema"
        [model]="model"
        [updateOn]="$any('blur' && 'change')"
        (change)="onModelChange($event)"
        (keyup.enter)="onEnterUp($event)"
      >
      </sf-form>
    </div>
  </div>
  <div mat-dialog-actions style="justify-content: flex-end">
    <ng-container *ngIf="mode === 'ADD'">
      <button
        mat-flat-button
        color="primary"
        (click)="onSaveClick()"
        [disabled]="!isFormValid"
      >
        Create
      </button>
    </ng-container>
    <ng-container *ngIf="mode === 'VIEW'">
      <button
        mat-flat-button
        color="primary"
        (click)="onEditClick()"
        [disabled]="!isFormValid"
      >
        Edit
      </button>
    </ng-container>
    <ng-container *ngIf="mode === 'EDIT'">
      <span [matTooltip]="isFormValid ? 'Save' : buttonTooltip">
        <button
          mat-flat-button
          color="primary"
          (click)="onUpdateClick()"
          [disabled]="!isFormValid"
        >
          Save
        </button>
      </span>
      <!--<button type="button" class="btn btn-default btn-cons  pull-left inline"-->
      <!--(click)="onCancelClick();">-->
      <!--Cancel-->
      <!--</button>-->
    </ng-container>
  </div>
</div>
