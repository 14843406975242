import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("EmailProvisionModel")
export class EmailProvisionModel {
  @JsonProperty("localPart", String)
  localPart = "";

  @JsonProperty("domain", String)
  domain = "";
}
