import { transition, trigger, useAnimation } from "@angular/animations";
import {
  bounceIn,
  bounceOut,
  fadeIn,
  fadeOut,
  slideInLeft,
  slideOutLeft,
} from "ng-animate";

export const WEB_ABSTRACTION_SIDEBAR_ANIMATION_CONST = [
  trigger("fadeIn", [
    transition(
      "void => *",
      useAnimation(fadeIn, {
        params: {
          timing: 0.1,
        },
      })
    ),
    transition(
      "* => void",
      useAnimation(fadeOut, {
        params: {
          timing: 0.2,
        },
      })
    ),
  ]),
  trigger("bounceIn", [
    transition(
      "void => *",
      useAnimation(bounceIn, {
        params: {
          timing: 0.6,
        },
      })
    ),
    transition(
      "* => void",
      useAnimation(bounceOut, {
        params: {
          timing: 0.4,
        },
      })
    ),
  ]),
  trigger("slideIn", [
    transition(
      "void => *",
      useAnimation(slideInLeft, {
        params: {
          timing: 0.6,
        },
      })
    ),
    transition(
      "* => void",
      useAnimation(slideOutLeft, {
        params: {
          timing: 0.4,
        },
      })
    ),
  ]),
];
