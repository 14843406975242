import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SchemaFormModule } from "@@intelease/schema-form";
import { ProgressModule } from "@@intelease/web/intelease/components";
import { FormModalComponent } from "./form-modal.component";
import { FormModalService } from "./form-modal.service";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";

const INTELEASE_MODULES = [ProgressModule.forRoot()];

const MATERIAL_MODULES = [MatDialogModule, MatButtonModule, MatTooltipModule];

@NgModule({
  declarations: [FormModalComponent],
  imports: [
    CommonModule,
    SchemaFormModule.forRoot(),
    ...INTELEASE_MODULES,
    ...MATERIAL_MODULES,
  ],
  providers: [FormModalService],
  exports: [FormModalComponent],
})
export class FormModalModule {}
