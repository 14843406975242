/* tslint:disable */
/* eslint-disable */
import { PagePointModel } from "./page-point-model";

/**
 * the bounding box of the highlight
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("BoundingBoxModel")
export class BoundingBoxModel {
  @JsonProperty("firstPixel", PagePointModel, true)
  firstPixel?: PagePointModel = undefined;

  @JsonProperty("lastPixel", PagePointModel, true)
  lastPixel?: PagePointModel = undefined;
}
