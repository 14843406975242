import { Injectable } from "@angular/core";
import { RestClient } from "@@intelease/web/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EntityPermissionsService } from "@@intelease/web/ui/src/lib/new-share-entity";
import {
  OApiRespMultiSettingItemViewModel,
  OApiRespProvisionsMetadataModel,
} from "@@intelease/api-models/adex-api-model-src";

@Injectable({
  providedIn: "root",
})
export class InteleaseCommonService implements EntityPermissionsService {
  private static readonly ACCOUNTS_BASE_URL = "/accounts";

  constructor(private readonly restClient: RestClient) {}

  getProvisionsMetadata(): Observable<OApiRespProvisionsMetadataModel> {
    return this.restClient.sendGetRequest<OApiRespProvisionsMetadataModel>(
      "/v1",
      "/provisionMetadatas",
      OApiRespProvisionsMetadataModel
    );
  }

  getAccountSettings(): Observable<OApiRespMultiSettingItemViewModel> {
    return this.restClient.sendGetRequest<OApiRespMultiSettingItemViewModel>(
      "/v1",
      `${InteleaseCommonService.ACCOUNTS_BASE_URL}/settings`,
      OApiRespMultiSettingItemViewModel
    );
  }

  updateAccountSettingsById(id: string, payload: any): Observable<any> {
    return this.restClient.sendPutRequestNoView<any>(
      "/v1",
      `${InteleaseCommonService.ACCOUNTS_BASE_URL}/settings/${id}`,
      payload
    );
  }

  getPermissionForCurrentUser(
    entityUid: string,
    entityType: string
  ): Observable<string[]> {
    const payload = {
      data: {
        entityType,
        entityUid,
      },
      returnParams: {
        view: "none",
      },
    };
    return this.restClient
      .sendPostRequestNoView(
        "/v1",
        "/permissions/entity/authorities/currentUser",
        payload
      )
      .pipe(map((res: any) => res.data));
  }

  getOperatorsTitleMap() {
    return {
      EQ: "equals",
      NEQ: "does not equal",
      CONTAIN: "contains",
      NOT_CONTAIN: "does not contain",
      LT: "is less than",
      GT: "is greater than",
      LEQ: "is less than or equal to",
      GEQ: "is greater than or equal to",
      IN: "is in",
      EXISTS: "exists",
      NOT_EXISTS: "not exists",
      FIELD_EXISTS: "field exists",
      FIELD_NOT_EXISTS: "field not exists",
      FIELD_CONTAIN: "field contain",
      FIELD_NOT_CONTAIN: "field not contain",
      FIELD_EQ: "field equal",
      FIELD_NEQ: "field not equal",
    };
  }
}
