/* tslint:disable */
/* eslint-disable */
import { UpdateProvisionReviewCompletionInfoApiDtoModel } from "./update-provision-review-completion-info-api-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqUpdateProvisionReviewCompletionInfoApiDtoModel")
export class OApiReqUpdateProvisionReviewCompletionInfoApiDtoModel {
  @JsonProperty("data", UpdateProvisionReviewCompletionInfoApiDtoModel, true)
  data?: UpdateProvisionReviewCompletionInfoApiDtoModel = undefined;
}
