/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullCurrencyViewModel")
export class FullCurrencyViewModel {
  @JsonProperty("currency", String, true)
  currency?: string = "";

  @JsonProperty("currencyEnumName", String, true)
  currencyEnumName?: string = "";

  @JsonProperty("currencyName", String, true)
  currencyName?: string = "";

  @JsonProperty("currencySymbol", String, true)
  currencySymbol?: string = "";
}
