import { FormNameAbstractModel } from "@@intelease/web/common/models/doc-abstract/form-name-abstract.model";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  GetDocuments,
  SetDocuments,
  SetFormNameAbstractModel,
  SetPermission,
  UpdateDocument,
  UpdateLastModifiedDate,
} from "./individual-abstract.actions";
import { IndividualAbstractPartialState } from "./individual-abstract.reducer";
import { individualAbstractQuery } from "./individual-abstract.selectors";
import { take } from "rxjs/operators";
import { isEmpty } from "lodash";
import { RelatedDocumentModel } from "@@intelease/api-models/adex-api-model-src";

@Injectable({
  providedIn: "root",
})
export class IndividualAbstractFacade {
  getRecordPermissions$ = this.store.select(
    individualAbstractQuery.getRecordPermissions
  );

  getFormNameAbstractModel$ = this.store.select(
    individualAbstractQuery.getFormNameAbstractModel
  );

  getDocuments$ = this.store.select(individualAbstractQuery.getDocuments);

  constructor(private store: Store<IndividualAbstractPartialState>) {}

  setPermission(permissions: string[]) {
    this.store.dispatch(SetPermission({ permissions }));
  }

  setFormNameAbstractModel(formNameAbstractModel: FormNameAbstractModel) {
    this.store.dispatch(SetFormNameAbstractModel({ formNameAbstractModel }));
  }

  updateLastModifiedDate() {
    this.store.dispatch(UpdateLastModifiedDate());
  }

  getDocuments(recordUid) {
    this.getDocuments$.pipe(take(1)).subscribe((documents) => {
      if (isEmpty(documents)) {
        this.store.dispatch(GetDocuments({ recordUid }));
      }
    });
  }

  setDocuments(documents: Array<RelatedDocumentModel>) {
    this.store.dispatch(SetDocuments({ documents }));
  }

  updateDocument(document: RelatedDocumentModel) {
    this.store.dispatch(UpdateDocument({ document }));
  }
}
