import {
  OApiRespRelatedDocsAbstractViewModel,
  RecordService,
} from "@@intelease/api-models/adex-api-model-src";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  GetDocuments,
  GetDocumentsFailed,
  SetDocuments,
} from "./individual-abstract.actions";
import { AbstractViewEnum } from "@@intelease/app-models/abstract-review/src";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";

@Injectable()
export class IndividualAbstractEffects {
  getDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetDocuments),
      mergeMap((action) =>
        this.recordService
          .getRecord({
            recordUid: action.recordUid,
            view: AbstractViewEnum.RELATED_DOCS,
          })
          .pipe(
            map(
              ({ data: { documents } }: OApiRespRelatedDocsAbstractViewModel) =>
                SetDocuments({ documents })
            ),
            catchError(() => of(GetDocumentsFailed()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private recordService: RecordService
  ) {}
}
