import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromReports from "./reports.reducer";
import { ReportsEffects } from "./reports.effects";
import { ReportsFacade } from "./reports.facade";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromReports.REPORTS_FEATURE_KEY,
      fromReports.reducer
    ),
    EffectsModule.forFeature([ReportsEffects]),
  ],
  providers: [ReportsFacade],
})
export class AppStateReportsModule {}
