import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ItlsCustomIconComponent } from "./itls-custom-icon.component";

@NgModule({
  declarations: [ItlsCustomIconComponent],
  imports: [CommonModule, TooltipModule.forRoot(), MatButtonModule],
  providers: [],
  exports: [CommonModule, ItlsCustomIconComponent],
})
export class ItlsCustomIconModule {}
