/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MergeDocSetDocDataDtoModel")
export class MergeDocSetDocDataDtoModel {
  @JsonProperty("docSetUid", String, true)
  docSetUid?: string = "";

  @JsonProperty("docUid", String, true)
  docUid?: string = "";

  @JsonProperty("newFileName", String, true)
  newFileName?: string = "";
}
