import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BreadcrumbComponent } from "./breadcrumb.component";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { NgPipesModule } from "ngx-pipes";
import { BreadcrumbItemComponent } from "@@intelease/web/intelease/components/toolbar/breadcrumb/item/breadcrumb-item.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { InteleaseModule } from "@@intelease/web/intelease/intelease.module";
import { QuicklinkModule } from "ngx-quicklink";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";

const MATERIAL_MODULES = [
  MatIconModule,
  MatTooltipModule,
  MatMenuModule,
  MatButtonModule,
];

const UI_MODULES = [];

@NgModule({
  declarations: [BreadcrumbComponent, BreadcrumbItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    QuicklinkModule,
    ...MATERIAL_MODULES,
    ...UI_MODULES,
    NgPipesModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    CommonDirectivesModule,
  ],
  exports: [BreadcrumbComponent, BreadcrumbItemComponent],
})
export class BreadcrumbModule {}
