import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { fetch } from "@ngrx/router-store/data-persistence";
import {
  LoadNotifications,
  NotificationsLoaded,
  NotificationsLoadError,
  NotificationsActionTypes,
  LoadCountOfUnseenNotifications,
  CountOfUnseenNotificationsLoaded,
  CountOfUnseenNotificationsLoadError,
  LoadUnseenNotifications,
  UnseenNotificationsLoaded,
  UnseenNotificationsLoadError,
  LoadNotificationsCategory,
  NotificationsCategoryLoaded,
  NotificationsCategoryLoadError,
  LoadSelectedCategoryNotifications,
  SelectedCategoryNotificationsLoaded,
  SelectedCategoryNotificationsLoadError,
  ReadSelectedNotification,
  ReadSelectedNotificationSucceeded,
  ReadSelectedNotificationFailed,
} from "./notifications.actions";
import { map } from "rxjs/operators";
import { NotificationsService } from "@@intelease/app-services/notifications";
import { keyBy } from "lodash";

@Injectable()
export class NotificationsEffects {
  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.LoadNotifications),
      fetch({
        run: () => {
          // Your custom REST 'load' logic goes here. For now just return an empty list...
          return new NotificationsLoaded([]);
        },

        onError: (action: LoadNotifications, error) => {
          //eslint-disable-line
          console.error("Error", error);
          return new NotificationsLoadError(error);
        },
      })
    )
  );

  loadCountOfUnseenNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.LoadCountOfUnseenNotifications),
      fetch({
        run: () => {
          return this.notificationsService
            .loadCountOfUnseenNotifications()
            .pipe(map((res) => new CountOfUnseenNotificationsLoaded(res.data)));
        },
        onError(action: LoadCountOfUnseenNotifications, error) {
          //eslint-disable-line
          return new CountOfUnseenNotificationsLoadError(error);
        },
      })
    )
  );

  loadUnseenNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.LoadUnseenNotifications),
      fetch({
        run: () => {
          return this.notificationsService
            .loadUnseenNotifications()
            .pipe(map((res) => new UnseenNotificationsLoaded(res)));
        },
        onError(action: LoadUnseenNotifications, error) {
          //eslint-disable-line
          return new UnseenNotificationsLoadError(error);
        },
      })
    )
  );

  loadNotificationsCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.LoadNotificationsCategory),
      fetch({
        run: () => {
          return this.notificationsService.loadNotificationsCategory().pipe(
            map((res) => {
              const { data } = res;
              return new NotificationsCategoryLoaded(keyBy(data.items, "key"));
            })
          );
        },
        onError(action: LoadNotificationsCategory, error) {
          //eslint-disable-line
          return new NotificationsCategoryLoadError(error);
        },
      })
    )
  );

  loadSelectedCategoryNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.LoadSelectedCategoryNotifications),
      fetch({
        run: (action: LoadSelectedCategoryNotifications) => {
          const { category } = action.payload;
          return this.notificationsService
            .loadNotificationsByCategory(category)
            .pipe(
              map((res) => {
                const { data } = res;
                const payload = {
                  category,
                  items: data.items,
                };
                return new SelectedCategoryNotificationsLoaded(payload);
              })
            );
        },
        onError(action: LoadSelectedCategoryNotifications, error) {
          //eslint-disable-line
          return new SelectedCategoryNotificationsLoadError(error);
        },
      })
    )
  );

  readSelectedNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.ReadSelectedNotification),
      fetch({
        run: (action: ReadSelectedNotification) => {
          const { notificationUid, notificationCategory, isOpen } =
            action.payload;
          return this.notificationsService
            .readNotificationByUid(notificationUid)
            .pipe(
              map(
                () =>
                  new ReadSelectedNotificationSucceeded({
                    notificationCategory,
                    notificationUid,
                    isOpen,
                  })
              )
            );
        },
        onError(action: ReadSelectedNotification, error) {
          //eslint-disable-line
          return new ReadSelectedNotificationFailed(error);
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService
  ) {}
}
