/* tslint:disable */
/* eslint-disable */

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MinimalProvisionLibraryViewModel")
export class MinimalProvisionLibraryViewModel {
  @JsonProperty("createdByEmail", String, true)
  createdByEmail?: string = "";

  @JsonProperty("creationDate", DateTimeConvertService, true)
  creationDate?: Date | undefined = undefined;

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("documentType", String, true)
  documentType?: string = "";

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("lastModifiedByEmail", String, true)
  lastModifiedByEmail?: string = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("numProvisions", Number, true)
  numProvisions?: number = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
