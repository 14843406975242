import { Component, Inject, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { isEmpty } from "lodash";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { NgxTranslateService } from "@@intelease/web/common/services/ngx-translate.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "itls-generic-ok-cancel-modal",
  templateUrl: "./generic-ok-cancel-modal.component.html",
  styleUrls: ["./generic-ok-cancel-modal.component.scss"],
})
export class GenericOkCancelModalComponent implements OnInit {
  onCloseAnyWay: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.SHARE;
  payload: any;
  customMessage = false;
  title = "";
  message = "";
  isHtmlMessage = false;
  params: Object = {};
  showDismissIcon = true;
  confirmButtonTitle: string;
  cancelButtonTitle: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<GenericOkCancelModalComponent>,
    private ngxTranslateService: NgxTranslateService
  ) {}

  ngOnInit() {
    this.payload = this.dialogData.payload;
    this.mode = this.dialogData.mode;
    if (
      !isEmpty(this.payload) &&
      (this.payload.message || this.payload.title)
    ) {
      const messageRes: Subject<string> = new Subject<string>();
      messageRes.subscribe((message: string) => {
        this.message = message;
        this.title = this.payload.title;
      });
      this.isHtmlMessage = this.payload.isHtmlMessage;
      this.confirmButtonTitle = this.payload.confirmButtonTitle;
      this.cancelButtonTitle = this.payload.cancelButtonTitle;
      if (typeof this.payload.showDismissIcon !== "undefined") {
        this.showDismissIcon = this.payload.showDismissIcon;
      }
      if (this.payload.params && this.payload.customMessage) {
        this.ngxTranslateService
          .getTranslateByKey(this.payload.message, this.payload.params)
          .subscribe((res) => {
            messageRes.next(res);
          });
      } else {
        messageRes.next(this.payload.message);
      }
    }
  }

  onConfirm() {
    this.modalRes.data = {
      exitType: ModalsResponseTypeEnum.CLOSE,
    };
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.CLOSE,
    });
  }

  onDismissModal() {
    this.modalRes.data = {
      exitType: ModalsResponseTypeEnum.DISMISS,
    };
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}
