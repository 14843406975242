import { NgModule } from "@angular/core";
import { PortfolioMentionsComponent } from "./portfolio-mentions.component";
import { InteleaseModule } from "@@intelease/web/intelease/intelease.module";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";

const MATERIAL_MODULES = [
  MatButtonModule,
  MatListModule,
  MatDividerModule,
  MatIconModule,
  MatMenuModule,
];

const UI_MODULES = [];

@NgModule({
  imports: [
    CommonModule,
    InteleaseModule,
    InteleaseCommonModule,
    FormsModule,
    ...MATERIAL_MODULES,
    ...UI_MODULES,
  ],
  declarations: [PortfolioMentionsComponent],
  exports: [PortfolioMentionsComponent],
})
export class PortfolioMentionsModule {}
