import { JsonObject, JsonProperty } from "json2typescript";
import { TaskPriorityEnum } from "@@intelease/app-models/tasks/src/lib/enums/task-priority.enum";
import { TaskTypeEnum } from "@@intelease/app-models/tasks/src/lib/enums/task-type.enum";
import { TaskAssigneeModel } from "@@intelease/app-models/tasks/src/lib/tasks/task-assignee.model";
import { MinimalUserModel } from "@@intelease/web/common/models/user";
import { UltraLightDocumentModel } from "@@intelease/app-models/documents";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";

@JsonObject("FullUserAbstractTaskStatusModel")
export class FullUserAbstractTaskStatusModel {
  @JsonProperty("name", String)
  name = "";

  @JsonProperty("uiName", String)
  uiName = "";
}
@JsonObject("FullUserAbstractTaskModel")
export class FullUserAbstractTaskModel {
  static view = "full";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("assignee", TaskAssigneeModel)
  assignee: TaskAssigneeModel = undefined;

  @JsonProperty("dueDate", DateConvertService)
  dueDate = ""; // ISO foramt ("MM/dd/yyyy'T'HH:mm:ss.SSSzZ") (04/12/2019T12:04:12.000GMT+01:00)

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("notes", String)
  notes = "";

  @JsonProperty("type", String)
  type: TaskTypeEnum = TaskTypeEnum.APPROVE;

  @JsonProperty("priority", String)
  priority: TaskPriorityEnum = TaskPriorityEnum.MEDIUM;

  @JsonProperty("status", FullUserAbstractTaskStatusModel)
  status: FullUserAbstractTaskStatusModel = undefined;

  @JsonProperty("createdAt", DateTimeConvertService)
  createdAt = ""; // ISO format ("MM/dd/yyyy'T'HH:mm:ss.SSSzZ") (04/12/2019T12:04:12.000GMT+01:00)

  @JsonProperty("createdBy", MinimalUserModel)
  createdBy: MinimalUserModel = undefined;

  @JsonProperty("updatedAt", DateTimeConvertService)
  updatedAt = ""; // ISO format ("MM/dd/yyyy'T'HH:mm:ss.SSSzZ") (04/12/2019T12:04:12.000GMT+01:00)

  @JsonProperty("updatedBy", MinimalUserModel)
  updatedBy: MinimalUserModel = undefined;

  @JsonProperty("entities", [UltraLightDocumentModel])
  entities: UltraLightDocumentModel[] = [];

  entity: {
    value: string;
    link: string;
  } = {
    value: "",
    link: "",
  };

  assigneeName = "";

  createdByName = "";
  updatedByName = "";
}
