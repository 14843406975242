import { Any, JsonObject, JsonProperty } from "json2typescript";
import { PaginationModel } from "@@intelease/web/intelease/models";
import { NodeNavigationModel } from "@@intelease/web/common/models/drive/node-navigation.model";

@JsonObject("DriveListResponseModel")
export class DriveListResponseModel<T> {
  @JsonProperty("pagination", PaginationModel)
  pagination: PaginationModel = new PaginationModel();

  @JsonProperty("nodeNavigation", NodeNavigationModel)
  nodeNavigation: NodeNavigationModel = undefined;

  @JsonProperty("items", [Any])
  items: T[] = [];
}
