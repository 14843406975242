/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PagePointModel")
export class PagePointModel {
  @JsonProperty("offsetX", Number, true)
  offsetX?: number = undefined;

  @JsonProperty("offsetY", Number, true)
  offsetY?: number = undefined;

  @JsonProperty("pageHeight", Number, true)
  pageHeight?: number = undefined;

  @JsonProperty("pageNumber", Number, true)
  pageNumber?: number = undefined;

  @JsonProperty("pageWidth", Number, true)
  pageWidth?: number = undefined;
}
