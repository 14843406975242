import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "joinArray" })
export class JoinArrayPipe implements PipeTransform {
  transform(input: Array<any>, sep = ",", prefix = "", postfix = ""): string {
    if (!input?.length && !prefix?.length && !postfix?.length) {
      return;
    }
    // do not modify the original 'input' field
    if (!input) {
      input = [];
    } else {
      input = [...input];
    }
    if (prefix?.length > 0) {
      input.splice(0, 0, prefix);
    }
    if (postfix?.length > 0) {
      input.push(postfix);
    }
    return input.join(sep);
  }
}
