import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ItlsNewUploadModalComponent } from "@@intelease/web/ui/src/lib/itls-new-upload";
import { UploadDocSetModalComponent } from "../upload-doc-set-modal/upload-doc-set-modal.component";

@Injectable({
  providedIn: "root",
})
export class UploadModalService {
  constructor(private dialog: MatDialog) {}

  openUploadModal(): Observable<any> {
    const dialogRef = this.dialog.open(ItlsNewUploadModalComponent, {
      width: "400px",
      height: "600px",
      panelClass: "no-padding-modal-panel",
    });
    return dialogRef.afterClosed();
  }

  openUploadDocSetModal(): Observable<any> {
    const dialogRef = this.dialog.open(UploadDocSetModalComponent, {
      width: "400px",
      height: "600px",
      panelClass: "no-padding-modal-panel",
    });
    return dialogRef.afterClosed();
  }
}
