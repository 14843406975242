export enum WindowEventsEnum {
  WINDOW_CLOSED = "WINDOW_CLOSED",
  CLOSE = "CLOSE",
  NAVIGATE_TO_ANNOTATION_ON_PDF = "NAVIGATE_TO_ANNOTATION_ON_PDF",
  OPEN_NEW_PROVISION_BOX = "OPEN_NEW_PROVISION_BOX",
  ADD_NEW_TEXT_HIGHLIGHT_TO_ABSTRACT_HIGHLIGHTS = "ADD_NEW_TEXT_HIGHLIGHT_TO_ABSTRACT_HIGHLIGHTS",
  DELETE_TEXT_HIGHLIGHT_FROM_READER = "DELETE_TEXT_HIGHLIGHT_FROM_READER",
  PROVISION_BOX_REFRESHED = "PROVISION_BOX_REFRESHED",
  SCROLL_TO_ANNOTATION = "SCROLL_TO_ANNOTATION",
  GO_TO_ANNOTATION_BY_DOCUMENT_UID = "GO_TO_ANNOTATION_BY_DOCUMENT_UID",
  ON_TEXT_HIGHLIGHT_ACTION_FROM_PROVISION_BOX = "ON_TEXT_HIGHLIGHT_ACTION_FROM_PROVISION_BOX",
  REINITIALIZE_WINDOW = "REINITIALIZE_WINDOW",
  INITIAL_WINDOW = "INITIAL_WINDOW",
  SELECTED_DOCUMENT = "SELECTED_DOCUMENT",
  SELECTED_DOCUMENT_FULLY_LOADED = "SELECTED_DOCUMENT_FULLY_LOADED",
}
