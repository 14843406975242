/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqChangeUserPasswordDtoModel } from "../models/o-api-req-change-user-password-dto-model";
import { OApiReqEditLbxRolesModel } from "../models/o-api-req-edit-lbx-roles-model";
import { OApiReqEditUserProfileDtoModel } from "../models/o-api-req-edit-user-profile-dto-model";
import { OApiReqSettingItemListDtoModel } from "../models/o-api-req-setting-item-list-dto-model";
import { OApiReqSignupUserDtoModel } from "../models/o-api-req-signup-user-dto-model";
import { OApiRespAccountSettingsDtoModel } from "../models/o-api-resp-account-settings-dto-model";
import { OApiRespFullNameUserViewModel } from "../models/o-api-resp-full-name-user-view-model";
import { OApiRespGetAllAccountRolesModel } from "../models/o-api-resp-get-all-account-roles-model";
import { OApiRespHeaderUserViewModel } from "../models/o-api-resp-header-user-view-model";
import { OApiRespLoggedInUserViewModel } from "../models/o-api-resp-logged-in-user-view-model";
import { OApiRespMinimalUserViewModel } from "../models/o-api-resp-minimal-user-view-model";
import { OApiRespMultiFullNameUserViewModel } from "../models/o-api-resp-multi-full-name-user-view-model";
import { OApiRespMultiHeaderUserViewModel } from "../models/o-api-resp-multi-header-user-view-model";
import { OApiRespMultiLoggedInUserViewModel } from "../models/o-api-resp-multi-logged-in-user-view-model";
import { OApiRespMultiMinimalUserViewModel } from "../models/o-api-resp-multi-minimal-user-view-model";
import { OApiRespMultiProfileUserViewModel } from "../models/o-api-resp-multi-profile-user-view-model";
import { OApiRespMultiSettingItemDtoModel } from "../models/o-api-resp-multi-setting-item-dto-model";
import { OApiRespProfileUserViewModel } from "../models/o-api-resp-profile-user-view-model";
import { OApiSingleEntityNoDataResponseModel } from "../models/o-api-single-entity-no-data-response-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation changePassword
   */
  static readonly ChangePasswordPath = "/api/v1/users/changePassword";

  /**
   * Change current user's password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword$Response(params: {
    body: OApiReqChangeUserPasswordDtoModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.ChangePasswordPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Change current user's password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword(params: {
    body: OApiReqChangeUserPasswordDtoModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.changePassword$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation getProfileImageForCurrentUser
   */
  static readonly GetProfileImageForCurrentUserPath =
    "/api/v1/users/profileImage";

  /**
   * Get profile image for current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileImageForCurrentUser$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileImageForCurrentUser$Jpeg$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetProfileImageForCurrentUserPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "image/jpeg",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get profile image for current user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileImageForCurrentUser$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileImageForCurrentUser$Jpeg(params?: {}): Observable<any> {
    return this.getProfileImageForCurrentUser$Jpeg$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Get profile image for current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileImageForCurrentUser$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileImageForCurrentUser$Png$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetProfileImageForCurrentUserPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "image/png",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get profile image for current user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileImageForCurrentUser$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileImageForCurrentUser$Png(params?: {}): Observable<any> {
    return this.getProfileImageForCurrentUser$Png$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uploadProfileImage
   */
  static readonly UploadProfileImagePath = "/api/v1/users/profileImage";

  /**
   * Upload profile image.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadProfileImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadProfileImage$Response(params: {
    body: any;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.UploadProfileImagePath,
      "post"
    );
    if (params) {
      rb.body(params.body, "multipart/form-data");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Upload profile image.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadProfileImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadProfileImage(params: { body: any }): Observable<void> {
    return this.uploadProfileImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteProfileImage
   */
  static readonly DeleteProfileImagePath = "/api/v1/users/profileImage";

  /**
   * Delete profile image.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProfileImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProfileImage$Response(params?: {}): Observable<
    StrictHttpResponse<void>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.DeleteProfileImagePath,
      "delete"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Delete profile image.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteProfileImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProfileImage(params?: {}): Observable<void> {
    return this.deleteProfileImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation editAccountRoleForUser
   */
  static readonly EditAccountRoleForUserPath =
    "/api/v1/users/{userUid}/accountRole";

  /**
   * Change current user's account role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editAccountRoleForUser()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editAccountRoleForUser$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.EditAccountRoleForUserPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Change current user's account role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editAccountRoleForUser$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editAccountRoleForUser(params?: { body?: RequestModel }): Observable<void> {
    return this.editAccountRoleForUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation editLbxRoleForUser
   */
  static readonly EditLbxRoleForUserPath =
    "/api/v1/users/{userUid}/lbx-user-roles";

  /**
   * Change current user's LBX role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editLbxRoleForUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editLbxRoleForUser$Response(params: {
    /**
     * The unique uid of the user whose roles being altered.
     */
    userUid: string;
    body: OApiReqEditLbxRolesModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.EditLbxRoleForUserPath,
      "put"
    );
    if (params) {
      rb.path("userUid", params.userUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Change current user's LBX role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editLbxRoleForUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editLbxRoleForUser(params: {
    /**
     * The unique uid of the user whose roles being altered.
     */
    userUid: string;
    body: OApiReqEditLbxRolesModel;
  }): Observable<void> {
    return this.editLbxRoleForUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation editProfile
   */
  static readonly EditProfilePath = "/api/v1/users/profile";

  /**
   * Change current user's profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProfile$Response(params: {
    body: OApiReqEditUserProfileDtoModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.EditProfilePath,
      "put"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Change current user's profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProfile(params: {
    body: OApiReqEditUserProfileDtoModel;
  }): Observable<void> {
    return this.editProfile$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getSettingItemsForCurrentUser
   */
  static readonly GetSettingItemsForCurrentUserPath = "/api/v1/users/settings";

  /**
   * Get settings for current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingItemsForCurrentUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingItemsForCurrentUser$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMultiSettingItemDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetSettingItemsForCurrentUserPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiSettingItemDtoModel>;
        })
      );
  }

  /**
   * Get settings for current user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSettingItemsForCurrentUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingItemsForCurrentUser(params?: {}): Observable<OApiRespMultiSettingItemDtoModel> {
    return this.getSettingItemsForCurrentUser$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiSettingItemDtoModel>) =>
          r.body as OApiRespMultiSettingItemDtoModel
      )
    );
  }

  /**
   * Path part for operation editSettingItemsForCurrentUser
   */
  static readonly EditSettingItemsForCurrentUserPath = "/api/v1/users/settings";

  /**
   * Edit settings for current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editSettingItemsForCurrentUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSettingItemsForCurrentUser$Response(params: {
    body: OApiReqSettingItemListDtoModel;
  }): Observable<StrictHttpResponse<OApiRespMultiSettingItemDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.EditSettingItemsForCurrentUserPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiSettingItemDtoModel>;
        })
      );
  }

  /**
   * Edit settings for current user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editSettingItemsForCurrentUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSettingItemsForCurrentUser(params: {
    body: OApiReqSettingItemListDtoModel;
  }): Observable<OApiRespMultiSettingItemDtoModel> {
    return this.editSettingItemsForCurrentUser$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiSettingItemDtoModel>) =>
          r.body as OApiRespMultiSettingItemDtoModel
      )
    );
  }

  /**
   * Path part for operation exportAccountCreditsForCurrentUser
   */
  static readonly ExportAccountCreditsForCurrentUserPath =
    "/api/v1/users/account/exportCreditsEvents";

  /**
   * Export current user's account credits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportAccountCreditsForCurrentUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportAccountCreditsForCurrentUser$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.ExportAccountCreditsForCurrentUserPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export current user's account credits.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportAccountCreditsForCurrentUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportAccountCreditsForCurrentUser(params?: {}): Observable<any> {
    return this.exportAccountCreditsForCurrentUser$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getAccountForCurrentUser
   */
  static readonly GetAccountForCurrentUserPath = "/api/v1/users/account";

  /**
   * Get account of current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountForCurrentUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountForCurrentUser$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespAccountSettingsDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetAccountForCurrentUserPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespAccountSettingsDtoModel>;
        })
      );
  }

  /**
   * Get account of current user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccountForCurrentUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountForCurrentUser(params?: {}): Observable<OApiRespAccountSettingsDtoModel> {
    return this.getAccountForCurrentUser$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespAccountSettingsDtoModel>) =>
          r.body as OApiRespAccountSettingsDtoModel
      )
    );
  }

  /**
   * Path part for operation getAllAccountRoles
   */
  static readonly GetAllAccountRolesPath = "/api/v1/users/accountRole";

  /**
   * Get settings for current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAccountRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccountRoles$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespGetAllAccountRolesModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetAllAccountRolesPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespGetAllAccountRolesModel>;
        })
      );
  }

  /**
   * Get settings for current user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllAccountRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccountRoles(params?: {}): Observable<OApiRespGetAllAccountRolesModel> {
    return this.getAllAccountRoles$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespGetAllAccountRolesModel>) =>
          r.body as OApiRespGetAllAccountRolesModel
      )
    );
  }

  /**
   * Path part for operation getProfileImage
   */
  static readonly GetProfileImagePath = "/api/v1/users/{userUid}/profileImage";

  /**
   * Get profile image for a specific user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileImage$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileImage$Jpeg$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetProfileImagePath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "image/jpeg",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get profile image for a specific user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileImage$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileImage$Jpeg(params?: {}): Observable<any> {
    return this.getProfileImage$Jpeg$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Get profile image for a specific user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileImage$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileImage$Png$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetProfileImagePath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "image/png",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get profile image for a specific user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileImage$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileImage$Png(params?: {}): Observable<any> {
    return this.getProfileImage$Png$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getUser1
   */
  static readonly GetUser1Path = "/api/v1/users/{userUid}";

  /**
   * Get a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser1$Response(params: {
    view: "MINIMAL" | "HEADER" | "PROFILE" | "LOGGED_IN" | "FULL_NAME" | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMinimalUserViewModel
      | OApiRespFullNameUserViewModel
      | OApiRespHeaderUserViewModel
      | OApiRespProfileUserViewModel
      | OApiRespLoggedInUserViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetUser1Path,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
          >;
        })
      );
  }

  /**
   * Get a user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUser1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser1(params: {
    view: "MINIMAL" | "HEADER" | "PROFILE" | "LOGGED_IN" | "FULL_NAME" | "NONE";
  }): Observable<
    | OApiRespMinimalUserViewModel
    | OApiRespFullNameUserViewModel
    | OApiRespHeaderUserViewModel
    | OApiRespProfileUserViewModel
    | OApiRespLoggedInUserViewModel
  > {
    return this.getUser1$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
          >
        ) =>
          r.body as
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
      )
    );
  }

  /**
   * Path part for operation getUserInfo
   */
  static readonly GetUserInfoPath = "/api/v1/users";

  /**
   * Get current user info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInfo$Response(params: {
    view: "MINIMAL" | "HEADER" | "PROFILE" | "LOGGED_IN" | "FULL_NAME" | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMinimalUserViewModel
      | OApiRespFullNameUserViewModel
      | OApiRespHeaderUserViewModel
      | OApiRespProfileUserViewModel
      | OApiRespLoggedInUserViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetUserInfoPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
          >;
        })
      );
  }

  /**
   * Get current user info.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInfo(params: {
    view: "MINIMAL" | "HEADER" | "PROFILE" | "LOGGED_IN" | "FULL_NAME" | "NONE";
  }): Observable<
    | OApiRespMinimalUserViewModel
    | OApiRespFullNameUserViewModel
    | OApiRespHeaderUserViewModel
    | OApiRespProfileUserViewModel
    | OApiRespLoggedInUserViewModel
  > {
    return this.getUserInfo$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
          >
        ) =>
          r.body as
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
      )
    );
  }

  /**
   * Path part for operation listAccountUsers
   */
  static readonly ListAccountUsersPath = "/api/v1/accounts/{accountUid}/users";

  /**
   * Get list of users for a specific account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAccountUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAccountUsers$Response(params: {
    view: "MINIMAL" | "HEADER" | "PROFILE" | "LOGGED_IN" | "FULL_NAME" | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiMinimalUserViewModel
      | OApiRespMultiFullNameUserViewModel
      | OApiRespMultiHeaderUserViewModel
      | OApiRespMultiProfileUserViewModel
      | OApiRespMultiLoggedInUserViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.ListAccountUsersPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiMinimalUserViewModel
            | OApiRespMultiFullNameUserViewModel
            | OApiRespMultiHeaderUserViewModel
            | OApiRespMultiProfileUserViewModel
            | OApiRespMultiLoggedInUserViewModel
          >;
        })
      );
  }

  /**
   * Get list of users for a specific account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAccountUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAccountUsers(params: {
    view: "MINIMAL" | "HEADER" | "PROFILE" | "LOGGED_IN" | "FULL_NAME" | "NONE";
  }): Observable<
    | OApiRespMultiMinimalUserViewModel
    | OApiRespMultiFullNameUserViewModel
    | OApiRespMultiHeaderUserViewModel
    | OApiRespMultiProfileUserViewModel
    | OApiRespMultiLoggedInUserViewModel
  > {
    return this.listAccountUsers$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiMinimalUserViewModel
            | OApiRespMultiFullNameUserViewModel
            | OApiRespMultiHeaderUserViewModel
            | OApiRespMultiProfileUserViewModel
            | OApiRespMultiLoggedInUserViewModel
          >
        ) =>
          r.body as
            | OApiRespMultiMinimalUserViewModel
            | OApiRespMultiFullNameUserViewModel
            | OApiRespMultiHeaderUserViewModel
            | OApiRespMultiProfileUserViewModel
            | OApiRespMultiLoggedInUserViewModel
      )
    );
  }

  /**
   * Path part for operation listCurrentAccountUsers
   */
  static readonly ListCurrentAccountUsersPath = "/api/v1/accounts/users";

  /**
   * Get list of users in current user's account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCurrentAccountUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCurrentAccountUsers$Response(params: {
    view: "MINIMAL" | "HEADER" | "PROFILE" | "LOGGED_IN" | "FULL_NAME" | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiMinimalUserViewModel
      | OApiRespMultiFullNameUserViewModel
      | OApiRespMultiHeaderUserViewModel
      | OApiRespMultiProfileUserViewModel
      | OApiRespMultiLoggedInUserViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.ListCurrentAccountUsersPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiMinimalUserViewModel
            | OApiRespMultiFullNameUserViewModel
            | OApiRespMultiHeaderUserViewModel
            | OApiRespMultiProfileUserViewModel
            | OApiRespMultiLoggedInUserViewModel
          >;
        })
      );
  }

  /**
   * Get list of users in current user's account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listCurrentAccountUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCurrentAccountUsers(params: {
    view: "MINIMAL" | "HEADER" | "PROFILE" | "LOGGED_IN" | "FULL_NAME" | "NONE";
  }): Observable<
    | OApiRespMultiMinimalUserViewModel
    | OApiRespMultiFullNameUserViewModel
    | OApiRespMultiHeaderUserViewModel
    | OApiRespMultiProfileUserViewModel
    | OApiRespMultiLoggedInUserViewModel
  > {
    return this.listCurrentAccountUsers$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiMinimalUserViewModel
            | OApiRespMultiFullNameUserViewModel
            | OApiRespMultiHeaderUserViewModel
            | OApiRespMultiProfileUserViewModel
            | OApiRespMultiLoggedInUserViewModel
          >
        ) =>
          r.body as
            | OApiRespMultiMinimalUserViewModel
            | OApiRespMultiFullNameUserViewModel
            | OApiRespMultiHeaderUserViewModel
            | OApiRespMultiProfileUserViewModel
            | OApiRespMultiLoggedInUserViewModel
      )
    );
  }

  /**
   * Path part for operation generateVerificationCodeForSignedUpUser
   */
  static readonly GenerateVerificationCodeForSignedUpUserPath =
    "/api/v1/users/signup/verificationCode";

  /**
   * Generate verification code for signed-up user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateVerificationCodeForSignedUpUser()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  generateVerificationCodeForSignedUpUser$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GenerateVerificationCodeForSignedUpUserPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Generate verification code for signed-up user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateVerificationCodeForSignedUpUser$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  generateVerificationCodeForSignedUpUser(params?: {
    body?: RequestModel;
  }): Observable<void> {
    return this.generateVerificationCodeForSignedUpUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation signinGoogle
   */
  static readonly SigninGooglePath = "/api/v1/users/signin/google";

  /**
   * Google sign-in.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signinGoogle()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  signinGoogle$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMinimalUserViewModel
      | OApiRespFullNameUserViewModel
      | OApiRespHeaderUserViewModel
      | OApiRespProfileUserViewModel
      | OApiRespLoggedInUserViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.SigninGooglePath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
          >;
        })
      );
  }

  /**
   * Google sign-in.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `signinGoogle$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  signinGoogle(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespMinimalUserViewModel
    | OApiRespFullNameUserViewModel
    | OApiRespHeaderUserViewModel
    | OApiRespProfileUserViewModel
    | OApiRespLoggedInUserViewModel
  > {
    return this.signinGoogle$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
          >
        ) =>
          r.body as
            | OApiRespMinimalUserViewModel
            | OApiRespFullNameUserViewModel
            | OApiRespHeaderUserViewModel
            | OApiRespProfileUserViewModel
            | OApiRespLoggedInUserViewModel
      )
    );
  }

  /**
   * Path part for operation signup
   */
  static readonly SignupPath = "/api/v1/users/signup";

  /**
   * Signup.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signup$Response(params: {
    body: OApiReqSignupUserDtoModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.SignupPath, "post");
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Signup.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `signup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signup(params: { body: OApiReqSignupUserDtoModel }): Observable<void> {
    return this.signup$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation verifySignedUpUser
   */
  static readonly VerifySignedUpUserPath = "/api/v1/users/signup/verified";

  /**
   * Verify signed-up user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifySignedUpUser()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  verifySignedUpUser$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.VerifySignedUpUserPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Verify signed-up user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verifySignedUpUser$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  verifySignedUpUser(params?: { body?: RequestModel }): Observable<void> {
    return this.verifySignedUpUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
