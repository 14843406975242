import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  IndividualAbstractState,
  INDIVIDUALABSTRACT_FEATURE_KEY,
} from "./individual-abstract.reducer";

// Lookup the 'AbstractReview' feature state managed by NgRx
const getIndividualAbstractState =
  createFeatureSelector<IndividualAbstractState>(
    INDIVIDUALABSTRACT_FEATURE_KEY
  );

const getRecordPermissions = createSelector(
  getIndividualAbstractState,
  (state: IndividualAbstractState) => state.permissions
);

const getFormNameAbstractModel = createSelector(
  getIndividualAbstractState,
  (state: IndividualAbstractState) => state.formNameAbstractModel
);

export const getDocuments = createSelector(
  getIndividualAbstractState,
  (state: IndividualAbstractState) => state.documents
);

export const individualAbstractQuery = {
  getRecordPermissions,
  getFormNameAbstractModel,
  getDocuments,
};
