import { isEmpty, isObject } from "lodash";

export class Utilities {
  /**
   * returns true if provided object is not empty and has atleast one non-null field else false
   * @param obj a javascript object
   */
  public static areAllFieldsNull(obj: any) {
    for (const key in obj) {
      // check also if property is not inherited from prototype
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== null) {
          return false;
        }
      }
    }
    return true;
  }

  public static existsAndNotEmptyString(value: any) {
    return (
      value !== undefined &&
      value !== null &&
      (!value.hasOwnProperty("length") || value.length !== 0)
    );
  }

  public static isLocalDate(value): boolean {
    return (
      isObject(value) &&
      value.hasOwnProperty("year") &&
      value.hasOwnProperty("month") &&
      value.hasOwnProperty("day")
    );
  }

  public static isValueEmpty(value: any): boolean {
    return (
      !value ||
      isEmpty(value) ||
      (value.value &&
        value.value.hasOwnProperty("length") &&
        value.value.length === 0) || // empty array / string
      Utilities.areAllFieldsNull(value)
    );
  }
}
