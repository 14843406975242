import { Action } from "@ngrx/store";
import { ReportsState } from "@@intelease/app-state/reports";
import { MediumReportQueryViewModel } from "@@intelease/api-models/adex-api-model-src";

export enum ReportsActionTypes {
  LoadReports = "[Reports] Load Reports",
  ReportsLoaded = "[Reports] Reports Loaded",
  ReportsLoadError = "[Reports] Reports Load Error",

  SaveReport = "[Reports] Save Report",
  SaveReportSucceeded = "[Reports] Save Report Succeeded",
  SaveReportFailed = "[Reports] Save Report Failed",

  LoadSelectedReport = "[Reports] Load Selected Report",
  SelectedReportLoaded = "[Reports] Selected Report Loaded",
  SelectedReportLoadError = "[Reports] Selected Report Load Error",
  EmptySelectedReport = "[Reports] Empty Selected Report",

  DeleteSelectedReport = "[Reports] Delete Selected Report",
  DeleteSelectedReportSucceeded = "[Reports] Delete Selected Report Succeeded",
  DeleteSelectedReportFailed = "[Reports] Delete Selected Report Failed",

  ExportSelectedReport = "[Reports] Export Selected Report",
  ExportSelectedReportSucceeded = "[Reports] Export Selected Report Succeeded",
  ExportSelectedReportFailed = "[Reports] Export Selected Report Failed",

  UpdateSelectedReport = "[Reports] Update Selected Report",
  UpdateSelectedReportSucceeded = "[Reports] Update Selected Report Succeeded",
  UpdateSelectedReportFailed = "[Reports] Update Selected Report Failed",

  LoadReportColumns = "[Reports] Load Report Columns",
  ReportColumnsLoaded = "[Reports] Report Columns Loaded",
  ReportColumnsLoadError = "[Reports] Report Columns Load Error",

  LoadReportFilterFields = "[Reports] Load Report Filter Provisions",
  ReportFilterFieldsLoaded = "[Reports] Report Filter Provisions Loaded",
  ReportFilterFieldsLoadError = "[Reports] Report Filter Provisions Load Error",

  LoadAbstractsReport = "[Reports] Load Abstracts Report",
  AbstractsReportLoaded = "[Reports] Abstracts Report Loaded",
  AbstractsReportLoadError = "[Reports] Abstracts Report Load Error",
  EmptyAbstractReport = "[Reports] Empty Abstract Report",

  SaveSelectedColumns = "[Reports] Save Selected Columns",
  RemoveSelectedColumns = "[Reports] Remove Selected Columns",
  SaveSelectedFilters = "[Reports] Save Selected Filters",
  RemoveSelectedFilters = "[Reports] Remove Selected Filters",

  DeleteMultipleReport = "[Reports] Delete Multiple Report",
  DeleteMultipleReportSucceeded = "[Reports] Delete Multiple Report Succeeded",
  DeleteMultipleReportFailed = "[Reports] Delete Multiple Report Failed",

  CleanReportState = "[Reports] Clean Report State",

  DuplicateSelectedReport = "[Reports] Duplicate Selected Report",
  DuplicateSelectedReportSucceeded = "[Reports] Duplicate Selected Report Succeeded",
  DuplicateSelectedReportFailed = "[Reports] Duplicate Selected Report Failed",
}

export class LoadReports implements Action {
  readonly type = ReportsActionTypes.LoadReports;

  constructor(public payload: any) {}
}

export class ReportsLoadError implements Action {
  readonly type = ReportsActionTypes.ReportsLoadError;

  constructor(public payload: any) {}
}

export class ReportsLoaded implements Action {
  readonly type = ReportsActionTypes.ReportsLoaded;

  constructor(public payload: any) {}
}

export class SaveReport implements Action {
  readonly type = ReportsActionTypes.SaveReport;

  constructor(public payload: any) {}
}

export class SaveReportSucceeded implements Action {
  readonly type = ReportsActionTypes.SaveReportSucceeded;

  constructor(public payload: any) {}
}

export class SaveReportFailed implements Action {
  readonly type = ReportsActionTypes.SaveReportFailed;

  constructor(public payload: any) {}
}

export class LoadSelectedReport implements Action {
  readonly type = ReportsActionTypes.LoadSelectedReport;

  constructor(public payload: any, public selectedInstanceId: string) {}
}

export class SelectedReportLoaded implements Action {
  readonly type = ReportsActionTypes.SelectedReportLoaded;

  constructor(public payload: any, public selectedInstanceId: string) {}
}

export class SelectedReportLoadError implements Action {
  readonly type = ReportsActionTypes.SelectedReportLoadError;

  constructor(public payload: any, public selectedInstanceId: string) {}
}

export class EmptySelectedReport implements Action {
  readonly type = ReportsActionTypes.EmptySelectedReport;
}

export class DeleteSelectedReport implements Action {
  readonly type = ReportsActionTypes.DeleteSelectedReport;

  constructor(public payload: any) {}
}

export class DeleteSelectedReportSucceeded implements Action {
  readonly type = ReportsActionTypes.DeleteSelectedReportSucceeded;
}

export class DeleteSelectedReportFailed implements Action {
  readonly type = ReportsActionTypes.DeleteSelectedReportFailed;

  constructor(public payload: any) {}
}

export class DuplicateSelectedReport implements Action {
  readonly type = ReportsActionTypes.DuplicateSelectedReport;

  constructor(public payload: any) {}
}

export class DuplicateSelectedReportSucceeded implements Action {
  readonly type = ReportsActionTypes.DuplicateSelectedReportSucceeded;

  constructor(public payload: any) {}
}

export class DuplicateSelectedReportFailed implements Action {
  readonly type = ReportsActionTypes.DuplicateSelectedReportFailed;

  constructor(public payload: any) {}
}

export class ExportSelectedReport implements Action {
  readonly type = ReportsActionTypes.ExportSelectedReport;

  constructor(public payload: any) {}
}

export class ExportSelectedReportSucceeded implements Action {
  readonly type = ReportsActionTypes.ExportSelectedReportSucceeded;
}

export class ExportSelectedReportFailed implements Action {
  readonly type = ReportsActionTypes.ExportSelectedReportFailed;

  constructor(public payload: any) {}
}

export class UpdateSelectedReport implements Action {
  readonly type = ReportsActionTypes.UpdateSelectedReport;

  constructor(
    public uid: string,
    public payload: any,
    public replace: boolean
  ) {}
}

export class UpdateSelectedReportSucceeded implements Action {
  readonly type = ReportsActionTypes.UpdateSelectedReportSucceeded;

  constructor(public report: MediumReportQueryViewModel) {}
}

export class UpdateSelectedReportFailed implements Action {
  readonly type = ReportsActionTypes.UpdateSelectedReportFailed;

  constructor(public payload: any) {}
}

export class LoadReportColumns implements Action {
  readonly type = ReportsActionTypes.LoadReportColumns;
}

export class ReportColumnsLoaded implements Action {
  readonly type = ReportsActionTypes.ReportColumnsLoaded;
}

export class ReportColumnsLoadError implements Action {
  readonly type = ReportsActionTypes.ReportColumnsLoadError;

  constructor(public payload: any) {}
}

export class LoadReportFilterFields implements Action {
  readonly type = ReportsActionTypes.LoadReportFilterFields;
}

export class ReportFilterFieldsLoaded implements Action {
  readonly type = ReportsActionTypes.ReportFilterFieldsLoaded;

  constructor(
    public payload: {
      filterProvisions: any;
      provisionsOperator: any;
      fieldTypeToOperationAggregations: any;
    }
  ) {}
}

export class ReportFilterFieldsLoadError implements Action {
  readonly type = ReportsActionTypes.ReportFilterFieldsLoadError;

  constructor(public payload: any) {}
}

export class LoadAbstractsReport implements Action {
  readonly type = ReportsActionTypes.LoadAbstractsReport;

  constructor(
    public payload: any,
    public selectedInstanceId: string,
    public dashboardUid: string
  ) {}
}

export class AbstractsReportLoaded implements Action {
  readonly type = ReportsActionTypes.AbstractsReportLoaded;

  constructor(public payload: any, public selectedInstanceId: string) {}
}

export class AbstractsReportLoadError implements Action {
  readonly type = ReportsActionTypes.AbstractsReportLoadError;

  constructor(public payload: any, public selectedInstanceId: string) {}
}

export class EmptyAbstractReport implements Action {
  readonly type = ReportsActionTypes.EmptyAbstractReport;
}

export class SaveSelectedColumns implements Action {
  readonly type = ReportsActionTypes.SaveSelectedColumns;

  constructor(public payload: any) {}
}

export class RemoveSelectedColumns implements Action {
  readonly type = ReportsActionTypes.RemoveSelectedColumns;
}

export class SaveSelectedFilters implements Action {
  readonly type = ReportsActionTypes.SaveSelectedFilters;

  constructor(public payload: any) {}
}

export class RemoveSelectedFilters implements Action {
  readonly type = ReportsActionTypes.RemoveSelectedFilters;
}

export class DeleteMultipleReport implements Action {
  readonly type = ReportsActionTypes.DeleteMultipleReport;
  constructor(public payload: any) {}
}
export class DeleteMultipleReportSucceeded implements Action {
  readonly type = ReportsActionTypes.DeleteMultipleReportSucceeded;
  constructor(public payload: any) {}
}
export class DeleteMultipleReportFailed implements Action {
  readonly type = ReportsActionTypes.DeleteMultipleReportFailed;
  constructor(public payload: any) {}
}

export class CleanReportState implements Action {
  readonly type = ReportsActionTypes.CleanReportState;

  constructor(public payload: ReportsState) {}
}

export type ReportsAction =
  | LoadReports
  | ReportsLoaded
  | ReportsLoadError
  | LoadReportColumns
  | ReportColumnsLoaded
  | ReportColumnsLoadError
  | LoadReportFilterFields
  | ReportFilterFieldsLoaded
  | ReportFilterFieldsLoadError
  | LoadAbstractsReport
  | AbstractsReportLoaded
  | AbstractsReportLoadError
  | SaveSelectedColumns
  | SaveSelectedFilters
  | SaveReport
  | SaveReportSucceeded
  | SaveReportFailed
  | LoadSelectedReport
  | SelectedReportLoaded
  | SelectedReportLoadError
  | RemoveSelectedColumns
  | RemoveSelectedFilters
  | CleanReportState
  | EmptyAbstractReport
  | EmptySelectedReport
  | DeleteSelectedReport
  | DeleteSelectedReportSucceeded
  | DeleteSelectedReportFailed
  | ExportSelectedReport
  | ExportSelectedReportSucceeded
  | ExportSelectedReportFailed
  | UpdateSelectedReport
  | UpdateSelectedReportSucceeded
  | UpdateSelectedReportFailed
  | DeleteMultipleReport
  | DeleteMultipleReportSucceeded
  | DeleteMultipleReportFailed
  | DuplicateSelectedReport
  | DuplicateSelectedReportSucceeded
  | DuplicateSelectedReportFailed;

export const fromReportsActions = {
  LoadReports,
  ReportsLoaded,
  ReportsLoadError,
  LoadReportColumns,
  ReportColumnsLoaded,
  ReportColumnsLoadError,
  LoadReportFilterFields,
  ReportFilterFieldsLoaded,
  ReportFilterFieldsLoadError,
  LoadAbstractsReport,
  AbstractsReportLoaded,
  AbstractsReportLoadError,
  SaveSelectedColumns,
  SaveSelectedFilters,
  SaveReport,
  SaveReportSucceeded,
  SaveReportFailed,
  LoadSelectedReport,
  SelectedReportLoaded,
  SelectedReportLoadError,
  RemoveSelectedColumns,
  RemoveSelectedFilters,
  CleanReportState,
  EmptyAbstractReport,
  EmptySelectedReport,
  DeleteSelectedReport,
  DeleteSelectedReportSucceeded,
  DeleteSelectedReportFailed,
  ExportSelectedReport,
  ExportSelectedReportSucceeded,
  ExportSelectedReportFailed,
  UpdateSelectedReport,
  UpdateSelectedReportSucceeded,
  UpdateSelectedReportFailed,
  DeleteMultipleReport,
  DeleteMultipleReportSucceeded,
  DeleteMultipleReportFailed,
  DuplicateSelectedReport,
  DuplicateSelectedReportSucceeded,
  DuplicateSelectedReportFailed,
};
