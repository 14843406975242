<table>
  <thead>
    <tr>
      <th
        *ngFor="
          let header of $any(headers);
          let colIndex = index;
          let first = first
        "
      >
        <button
          *ngIf="first && allowClosing"
          [matTooltip]="isDataChanged ? 'Save and close' : 'Close'"
          mat-icon-button
          (click)="tableDismiss()"
          disableRipple
        >
          <mat-icon>cancel</mat-icon>
        </button>
        <il-table-head
          *ngIf="!first"
          [inputData]="header"
          [colLength]="columnsLength"
          [strictColumn]="strictColumn"
          [allowMerge]="allowMerge"
          [readOnly]="readOnly"
          [isRequired]="requiredColumns?.includes(header.data)"
          (addColumnLeft)="onAddColumnLeft($event)"
          (addColumnRight)="onAddColumnRight($event)"
          (duplicateColumn)="onDuplicateColumn($event)"
          (removeColumn)="onRemoveColumn($event)"
          (mergeColumnRight)="onMergeColumnRight($event)"
          (mergeColumnLeft)="onMergeColumnLeft($event)"
          (moveColumnRight)="onMoveColumnRight($event)"
          (moveColumnLeft)="onMoveColumnLeft($event)"
        ></il-table-head>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of $any(rows); let rowIndex = index">
      <td *ngFor="let column of row; let colIndex = index" #tableDataElement>
        <il-table-body
          [inputData]="column"
          [rowsLength]="rowsLength"
          [rowHeight]="rowsHeight[rowIndex]"
          [readOnly]="readOnly"
          [allowMerge]="allowMerge"
          [strictColumn]="strictColumn"
          (onInputBoxHeightChanged)="
            onRowHeightChangedByInputs(tableDataElement, rowIndex)
          "
          (addRowAbove)="onAddRowAbove($event)"
          (addRowBelow)="onAddRowBelow($event)"
          (duplicateRow)="onDuplicateRow($event)"
          (removeRow)="onRemoveRow($event)"
          (mergeRowAbove)="onMergeRowAbove($event)"
          (mergeRowBelow)="onMergeRowBelow($event)"
          (moveRowAbove)="onMoveRowAbove($event)"
          (moveRowBelow)="onMoveRowBelow($event)"
          (saveEditedRowData)="onSaveEditedRowData($event)"
        ></il-table-body>
      </td>
      <div
        class="resizer"
        (mousedown)="mouseDown($event, rowIndex)"
        #resizer
      ></div>
    </tr>
  </tbody>
</table>
