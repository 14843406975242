/* tslint:disable */
/* eslint-disable */
import { DocPdfPageInfoModel } from "./doc-pdf-page-info-model";
import { PdfMentionAreaHighlightDtoModel } from "./pdf-mention-area-highlight-dto-model";
import { PdfMentionTextHighlightDtoModel } from "./pdf-mention-text-highlight-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MapPageHighlightsViewModel")
export class MapPageHighlightsViewModel {
  @JsonProperty("areaHighlights", Any, true)
  areaHighlights?: {
    [key: string]: PdfMentionAreaHighlightDtoModel;
  } = {};

  @JsonProperty("comments", Any, true)
  comments?: {
    [key: string]: string;
  } = {};

  @JsonProperty("flashcards", Any, true)
  flashcards?: {
    [key: string]: string;
  } = {};

  @JsonProperty("pageInfo", DocPdfPageInfoModel, true)
  pageInfo?: DocPdfPageInfoModel = undefined;

  @JsonProperty("pagemarks", Any, true)
  pagemarks?: {
    [key: string]: string;
  } = {};

  @JsonProperty("textHighlights", Any, true)
  textHighlights?: {
    [key: string]: PdfMentionTextHighlightDtoModel;
  } = {};
}
