export enum EntityType {
  FINAL_ABSTRACT = "FINAL_ABSTRACT",
  DOC_SET = "DOC_SET",
  PROJECT = "PROJECT",
  CALENDAR = "CALENDAR",
  PROVISION_FORM = "PROVISION_FORM",
  DASHBOARD = "DASHBOARD",
  REPORT = "REPORT",

  QUEUE = "QUEUE",
}

export class EntityUtils {}
