import { Component, EventEmitter, Input, Output } from "@angular/core";
import { cloneDeep } from "lodash";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  MyFile,
  NewUploadService,
  UploadedDocSetModel,
} from "@@intelease/web/ui/src/lib/itls-new-upload";

@Component({
  selector: "il-uploaded-doc-set",
  templateUrl: "./uploaded-doc-set.component.html",
  styleUrls: ["./uploaded-doc-set.component.scss"],
})
export class UploadedDocSetComponent {
  @Input()
  recordIndex: number;
  @Input()
  isUploadingFolder: boolean;
  @Input()
  verticalView = true;
  @Input()
  uploadedDocSet: UploadedDocSetModel;
  @Output()
  allFilesRemoved: EventEmitter<any> = new EventEmitter<any>();

  onRemoveFile(index: number) {
    this.uploadedDocSet.myFiles.splice(index, 1);
    if (this.uploadedDocSet.myFiles.length) {
      this.updateUploadEvents();
    } else {
      this.allFilesRemoved.emit();
    }
  }

  onDropDocument(event: CdkDragDrop<any, any>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    this.updateUploadEvents();
  }

  onDocumentNameChanged(item: MyFile) {
    if (!item.name.length) {
      item.name = NewUploadService.getFilenameWithoutExtension(item.file);
    }
  }

  private updateUploadEvents() {
    const items = this.uploadedDocSet.myFiles;
    const obj = {
      pre: [],
      post: items,
    };
    obj.pre =
      this.uploadedDocSet.lastDocumentReorderingEvent.pre.length > 0
        ? this.uploadedDocSet.lastDocumentReorderingEvent.post
        : items;
    this.uploadedDocSet.lastDocumentReorderingEvent = cloneDeep(obj);
    this.uploadedDocSet.documentReorderingEvents.push(cloneDeep(obj));
  }

  onRecordNameChanged() {
    this.uploadedDocSet.pickDefaultNameIfEmpty();
  }
}
