import { Component } from "@angular/core";

@Component({
  selector: "sf-button-widget",
  template:
    '<button class="btn btn-light mr-1 ml-1" (click)="button.action($event)">{{button.label}}</button>',
})
export class ButtonWidgetComponent {
  public button;
  public formProperty;
}
