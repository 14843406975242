import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { TableDirectionsEnum } from "./enums/table-directions.enum";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { cloneDeep } from "lodash";
import { ResizedEvent } from "@@intelease/web/ui/src/lib/itls-angular-resize-event";
import { ColumnMode } from "@swimlane/ngx-datatable";

@Component({
  selector: "il-dynamic-table",
  templateUrl: "./dynamic-table.component.html",
  styleUrls: ["./dynamic-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTableComponent implements OnInit {
  @ViewChild("itlsDynamicTable") table: any;
  @Input() model: any;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  columnModeSetting: ColumnMode | keyof typeof ColumnMode =
    window.innerWidth < 960 ? "standard" : "force";
  editing = {};
  columns: any[] = [
    {
      prop: "",
      name: "",
      editable: true,
      type: "BUTTONS",
    },
  ];
  rows = [];
  updatedColumns: any = {};
  currentCellValue: any;
  @Input() editable: boolean;
  tableUpdated = false;
  @Input()
  minimumRows = 0;
  private _minimumColumns = 0;
  @Input()
  set minimumColumns(minimumColumns: number) {
    // +1 because we manually add another column for buttons
    this._minimumColumns = minimumColumns + 1;
  }
  get minimumColumns() {
    return this._minimumColumns;
  }

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.init(cloneDeep(this.model));
  }

  private init(
    model: { headers: []; rows: []; headerRow: any } = {
      headers: [],
      rows: [],
      headerRow: undefined,
    }
  ) {
    this.columns = model.headers || [];
    this.columns.unshift({
      prop: "buttons",
      name: "buttons",
      editable: true,
      type: "BUTTONS",
    });
    this.rows = model.rows || [];
    this.rows.unshift(model.headerRow);
  }

  getSampleRow(columns) {
    const obj = {};
    columns.forEach((item, index) => {
      if (index >= 1) {
        return (obj[item.prop] = "");
      }
    });
    return obj;
  }

  onChangeTable(mode: string, type: string, index: number) {
    let prop;
    if (mode === ComponentModeEnum.ADD) {
      switch (type) {
        case TableDirectionsEnum.RT:
          if (index === 0) {
            return;
          }
          this.rows.splice(index, 0, {
            ...this.getSampleRow(this.columns),
          });
          this.rows = [...this.rows];
          break;
        case TableDirectionsEnum.RB:
          this.rows.splice(index + 1, 0, {
            ...this.getSampleRow(this.columns),
          });
          this.rows = [...this.rows];
          break;
        case TableDirectionsEnum.CR:
          prop = "col_" + (this.columns.length + 1);
          this.columns.splice(index + 1, 0, {
            prop: prop,
            name: "",
            editable: true,
          });
          this.rows = [
            ...this.rows.map((row) => {
              let i = 0;
              const newRow = {};
              for (const key in row) {
                if (row.hasOwnProperty(key)) {
                  if (index === i) {
                    newRow[prop] = "";
                  }
                  newRow[key] = row[key];
                  if (index - i === 1) {
                    newRow[prop] = "";
                  }
                  i += 1;
                }
              }
              return newRow;
              // row[this.columns[index + 1].prop] = "";
            }),
          ];
          this.columns = [...this.columns];
          break;
        case TableDirectionsEnum.CL:
          if (index === 0) {
            index = 1;
          }
          prop = "col_" + this.columns.length + 1;
          this.columns.splice(index, 0, {
            prop: prop,
            name: "",
            editable: true,
          });
          this.rows = [
            ...this.rows.map((row) => {
              let i = 0;
              const newRow = {};
              for (const key in row) {
                if (row.hasOwnProperty(key)) {
                  if (index - 1 === i) {
                    newRow[prop] = "";
                  }
                  newRow[key] = row[key];
                  i++;
                }
              }
              return newRow;
              // row[this.columns[index + 1].prop] = "";
            }),
          ];
          this.rows = [...this.rows];
          this.columns = [...this.columns];
          break;
      }
    } else if (mode === ComponentModeEnum.REMOVE) {
      switch (type) {
        case TableDirectionsEnum.RT:
          if (index === 0) {
            return;
          }
          this.rows.splice(index - 1, 1);
          this.rows = [...this.rows];
          break;
        case TableDirectionsEnum.RB:
          this.rows.splice(index + 1, 1);
          this.rows = [...this.rows];
          break;
        case TableDirectionsEnum.CR:
          this.rows.forEach((row) => {
            delete row[this.columns[index + 1].prop];
          });
          this.rows = [...this.rows];
          this.columns.splice(index + 1, 1);
          this.columns = [...this.columns];
          break;
        case TableDirectionsEnum.CL:
          if (index === 1) {
            return;
          }
          this.rows.forEach((row) => {
            delete row[this.columns[index - 1].prop];
          });
          this.rows = [...this.rows];
          this.columns.splice(index - 1, 1);
          this.columns = [...this.columns];
          break;
        case TableDirectionsEnum.SELF_COLUMN:
          this.rows.forEach((row) => {
            delete row[this.columns[index].prop];
          });
          // delete this.rows[0][this.columns[index].prop];
          this.rows = [...this.rows];
          this.columns.splice(index, 1);
          this.columns = [...this.columns];
          break;
        case TableDirectionsEnum.SELF_ROW:
          if (index === 0) {
            return;
          }
          this.rows.splice(index, 1);
          this.rows = [...this.rows];
          break;
      }
    }
    this.change.emit({ rows: this.rows });
  }

  public getData() {
    return {
      rows: this.rows,
      columns: this.columns,
    };
  }

  onCellBlur(val: any) {
    if (val !== this.currentCellValue) this.change.emit({ rows: this.rows });
  }

  onCellFocus(val: any) {
    this.currentCellValue = val;
  }

  reset(model: any) {
    this.init(model);
  }

  onResized(event: ResizedEvent) {
    if (!this.tableUpdated) {
      this.tableUpdated = true;
      setTimeout(() => {
        this.rows = [...this.rows];
        this.table.ngDoCheck();
        this.tableUpdated = false;
        this._cdr.detectChanges();
      }, 150);
    }
  }
}
