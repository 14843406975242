/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AnnotatedImageViewModel")
export class AnnotatedImageViewModel {
  @JsonProperty("abstractUid", String, true)
  abstractUid?: string = "";

  @JsonProperty("docSetUid", String, true)
  docSetUid?: string = "";

  @JsonProperty("imageFileName", String, true)
  imageFileName?: string = "";

  @JsonProperty("labelName", String, true)
  labelName?: string = "";

  @JsonProperty("mid", String, true)
  mid?: string = "";

  @JsonProperty("pageNumber", Number, true)
  pageNumber?: number = undefined;

  @JsonProperty("score", Number, true)
  score?: number = undefined;

  @JsonProperty("sourceFilename", String, true)
  sourceFilename?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
