/* tslint:disable */
/* eslint-disable */
import { ViewHeadingLinkModel } from "./view-heading-link-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullHeadingLinkViewModel")
export class FullHeadingLinkViewModel {
  @JsonProperty("headings", Any, true)
  headings?: any = undefined;

  @JsonProperty("highlightIds", [String], true)
  highlightIds?: Array<string> = [];

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  @JsonProperty("sectionIndex", String, true)
  sectionIndex?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
