<div
  [class]="colIndex === 0 ? 'column-container-0' : 'column-container'"
  (click)="!readOnly && onEdit()"
>
  <textarea
    type="text"
    [id]="'cell-' + readOnly ? 'readonly-' : '' + rowIndex + '-' + colIndex"
    [class]="
      colIndex === 0
        ? 'cell-text-0'
        : isEditable
        ? 'cell-text'
        : 'disabled cell-text'
    "
    [style.height.px]="rowHeight"
    [readonly]="!isEditable"
    *ngIf="colIndex !== 0 && dataType !== 'ARRAY'"
    [(ngModel)]="data"
    (focusout)="onSave()"
    data-gramm="false"
  ></textarea>

  <ng-select
    *ngIf="colIndex !== 0 && dataType === 'ARRAY'"
    [readonly]="readOnly"
    style="width: 100%"
    [items]="[]"
    [addTag]="true"
    [multiple]="true"
    appendTo="body"
    [closeOnSelect]="false"
    [(ngModel)]="data"
    [clearSearchOnAdd]="true"
    (change)="onSelectionChange($event, rowIndex)"
  >
    <ng-template
      ng-optgroup-tmp
      let-item="item"
      let-item$="item$"
      let-index="index"
    >
      <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
      {{ item }}
    </ng-template>
  </ng-select>

  <p *ngIf="colIndex === 0">{{ data }}</p>
  <ng-container *ngIf="colIndex !== 0 && dataType !== 'ARRAY'">
    <div *ngIf="colIndex !== 0 && !readOnly">
      <mat-icon
        class="discard-icon"
        (mousedown)="onDiscard()"
        *ngIf="isEditable"
        >close</mat-icon
      >
    </div>
  </ng-container>
  <button
    mat-icon-button
    *ngIf="colIndex === 0"
    style="cursor: pointer"
    [matMenuTriggerFor]="menu"
    #matMenu1Trigger="matMenuTrigger"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu" [overlapTrigger]="true">
    <div (mouseleave)="closeMenu()">
      <button
        mat-menu-item
        (click)="onAddRowAbove()"
        [disabled]="disabledButtons.addRA"
        matTooltip="Add row above"
      >
        <mat-icon>add_circle</mat-icon>
        <span>Add Row Above</span>
      </button>
      <button
        mat-menu-item
        (click)="onAddRowBelow()"
        [disabled]="disabledButtons.addRB"
        matTooltip="Add row below"
      >
        <mat-icon>add_circle</mat-icon>
        <span>Add Row Below</span>
      </button>
      <button
        mat-menu-item
        (click)="onRemoveRow()"
        [disabled]="disabledButtons.remove"
      >
        <mat-icon>cancel</mat-icon>
        <span>Remove</span>
      </button>
      <button
        mat-menu-item
        (click)="onMoveRowAbove()"
        [disabled]="disabledButtons.moveRA"
        matTooltip="Move row up"
      >
        <mat-icon>unfold_more</mat-icon>
        <span>Move Row Up</span>
      </button>
      <button
        mat-menu-item
        (click)="onMoveRowBelow()"
        [disabled]="disabledButtons.moveRB"
        matTooltip="Move row down"
      >
        <mat-icon>unfold_more</mat-icon>
        <span>Move Row Down</span>
      </button>
      <button
        *ngIf="allowMerge"
        mat-menu-item
        (click)="onMergeRowAbove()"
        [disabled]="disabledButtons.mergeRA"
        matTooltip="Merge with the above row"
      >
        <mat-icon>merge_type</mat-icon>
        <span>Merge Row With Above</span>
      </button>
      <button
        *ngIf="allowMerge"
        mat-menu-item
        (click)="onMergeRowBelow()"
        [disabled]="disabledButtons.mergeRB"
        matTooltip="Merge with the below row"
      >
        <mat-icon>merge_type</mat-icon>
        <span>Merge Row With Below</span>
      </button>
      <button
        mat-menu-item
        (click)="onDuplicateRow()"
        [disabled]="disabledButtons.duplicateRow"
      >
        <mat-icon>file_copy</mat-icon>
        <span>Duplicate this row</span>
      </button>
    </div>
  </mat-menu>
</div>
