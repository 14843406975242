import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { ItlsDataTableComponent } from "./itls-data-table.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatMenuModule } from "@angular/material/menu";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CommonModule } from "@angular/common";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgPipesModule } from "ngx-pipes";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AngularResizedEventModule } from "@@intelease/web/ui/src/lib/itls-angular-resize-event";
import { InteleasePipesModule } from "@@intelease/web/intelease/pipes";
import { CommonDirectivesModule } from "@common/directives";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";
import { NgxPermissionsModule } from "ngx-permissions";

const MATERIAL_MODULES = [
  MatSelectModule,
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  MatMenuModule,
  MatListModule,
  MatFormFieldModule,
  MatProgressBarModule,
  MatTooltipModule,
];

const UI_MODULES = [];

@NgModule({
  imports: [
    NgxDatatableModule,
    CommonModule,
    NgPipesModule,
    ...UI_MODULES,
    ...MATERIAL_MODULES,
    AngularResizedEventModule,
    InteleasePipesModule,
    ItlsCustomIconModule,
    CommonDirectivesModule,
    NgxPermissionsModule,
  ],
  declarations: [ItlsDataTableComponent],
  exports: [ItlsDataTableComponent],
})
export class ItlsDataTableModule {}
