import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SuggestionService } from "@@intelease/web/common/components";
import { Router } from "@angular/router";
import { NavigateService } from "@@intelease/web/common/services";
import { SearchSuggestionItemModel } from "@@intelease/web/common/models/search";

@Component({
  selector: "search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  private readonly recentSearches = "Your Recent Searches";
  private readonly minCharForSearch = 1;

  searchTerm: string;
  suggestions: Array<SearchSuggestionItemModel>;
  resultDescription = this.recentSearches;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<SearchComponent>,
    private suggestionService: SuggestionService,
    private readonly cdr: ChangeDetectorRef,
    private router: Router,
    private navigate: NavigateService
  ) {}

  ngOnInit() {}

  onReset() {
    // if (!this.isUserClickOnSuggestion(event)) {
    this.clearSuggestions();
    this.searchTerm = "";
    // }
  }

  displaySuggestions() {
    if (this.validForSearch()) {
      this.displaySearchResults();
    } else {
      this.suggestions = [];
      this.cdr.detectChanges();
      //TODO(reza) we're not going to have recent searches for now
      // this.displayRecentSearches();
    }
  }

  handleSuggestionClick(searchSuggestion: SearchSuggestionItemModel) {
    //TODO(reza) ask from Apoorv
    // this.suggestionService.sendSearchRequest(suggestion);
    if (searchSuggestion.uid) {
      const detailsUrl = `/individual-abstract/${searchSuggestion.uid}/related-documents`;
      this.navigate.setActiveTabUrl(detailsUrl);
      this.router.navigate([detailsUrl]);
      this.onClose();
    }
    this.clearSuggestions();
  }

  private isUserClickOnSuggestion(event: FocusEvent): boolean {
    const relatedTarget = event.relatedTarget;
    if (relatedTarget && relatedTarget instanceof Element) {
      const relatedTargetId = (relatedTarget as Element).id;
      if (
        relatedTargetId &&
        relatedTargetId.startsWith("search-suggestion-item-")
      ) {
        return true;
      }
    }
    return false;
  }

  private validForSearch(): boolean {
    return this.searchTerm && this.searchTerm.length >= this.minCharForSearch;
  }

  private displaySearchResults() {
    this.suggestionService
      .getSearchResults(this.searchTerm)
      .subscribe((response) => {
        this.suggestions = response.items;
        this.cdr.detectChanges();
      });
    this.resultDescription = '"' + this.searchTerm + '" in your documents';
  }

  // private displayRecentSearches() {
  //   this.suggestionService
  //       .getRecentSearchHistory()
  //       .subscribe(response => (this.suggestions = response.items));
  //   this.resultDescription = this.recentSearches;
  // }

  private clearSuggestions() {
    this.suggestions = [];
  }

  private onClose() {
    this.dialogRef.close();
  }
}
