import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class DataTableService {
  constructor(private httpClient: HttpClient) {}

  getList(): Observable<any> {
    return this.httpClient.get<any>(
      "http://5b8133c497d8e500144f2de6.mockapi.io/api/user"
    );
  }
}
