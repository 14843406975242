/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullSearchItemViewModel")
export class FullSearchItemViewModel {
  @JsonProperty("abstractUid", String, true)
  abstractUid?: string = "";

  @JsonProperty("searchCategories", Any, true)
  searchCategories?: Array<
    "PROPERTY" | "PORTFOLIO" | "SUITE" | "DOCUMENT" | "ABSTRACT"
  > = [];

  @JsonProperty("searchItem", String, true)
  searchItem?: string = "";
}
