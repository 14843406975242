import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ActorModel")
export class ActorModel {
  static view = "full";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("type", String)
  type: "USER" | "ROLE" | "ACCOUNT" | "UNREGISTERED_USER" | undefined =
    undefined;

  @JsonProperty("userUids", [String], true)
  userUids: Array<string> = [];

  /** Only For type === USER */
  @JsonProperty("email", String, true)
  email = "";

  /* ------------------------*/

  constructor(data?: Partial<ActorModel>) {
    Object.assign(this, data);
  }

  getNameWithTypeTitle(): string {
    switch (this.type) {
      case "USER":
        return this.name + " (Member)";
      case "ROLE":
        return this.name + " (Team)";
      default:
        return this.name;
    }
  }
}
