<div>
  <div mat-dialog-title class="clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5 style="margin-top: -10px">
      {{ title }}
    </h5>
  </div>
  <div mat-dialog-content>
    <p [innerHTML]="message"></p>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button color="warn" (click)="onPermissionConfirm()">
      OK
    </button>
    <button mat-flat-button (click)="onDismissModal()">Cancel</button>
  </div>
</div>
