import { Injectable } from "@angular/core";
import { ShareBatchApiService } from "@@intelease/web/intelease/services";
import { Observable } from "rxjs";
import { ServerResponseModel } from "@@intelease/web/intelease/models";

@Injectable({
  providedIn: "root",
})
export class ShareBatchAbstractService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(private shareBatchApiService: ShareBatchApiService) {}

  shareAllAbstractsNoView(
    userUids: string[],
    allVersions: boolean
  ): Observable<ServerResponseModel> {
    return this.shareBatchApiService.sendAllRequestNoView(
      ShareBatchAbstractService.API_VERSION_1,
      ShareBatchAbstractService.FINAL_DOC_SETS_URL,
      userUids,
      allVersions
    );
  }

  shareAbstractsNoView(
    abstractUids: string[],
    userUids: string[],
    allVersions: boolean
  ): Observable<ServerResponseModel> {
    return this.shareBatchApiService.sendBatchRequestNoView(
      ShareBatchAbstractService.API_VERSION_1,
      ShareBatchAbstractService.FINAL_DOC_SETS_URL,
      abstractUids,
      userUids,
      allVersions
    );
  }
}
