/* tslint:disable */
/* eslint-disable */
import { EditEntityPermissionRequestDtoModel } from "./edit-entity-permission-request-dto-model";
import { OApiReturnParamsRequestEntityPermissionViewTypeModel } from "./o-api-return-params-request-entity-permission-view-type-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqEditEntityPermissionRequestDtoModel")
export class OApiReqEditEntityPermissionRequestDtoModel {
  @JsonProperty("data", EditEntityPermissionRequestDtoModel, true)
  data?: EditEntityPermissionRequestDtoModel = undefined;

  @JsonProperty("replace", Boolean, true)
  replace?: boolean = undefined;

  @JsonProperty(
    "returnParams",
    OApiReturnParamsRequestEntityPermissionViewTypeModel,
    true
  )
  returnParams?: OApiReturnParamsRequestEntityPermissionViewTypeModel = undefined;
}
