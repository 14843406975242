import { Any, JsonObject, JsonProperty } from "json2typescript";
import { SnippetModel } from "./snippet.model";
import { ProvisionValueConvertService } from "../../services/provision-value-convert.service";

@JsonObject("FullValProvisionValueModel")
export class FullValProvisionValueModel {
  static view = "fullVal";

  @JsonProperty("uid", String, true)
  uid = "";

  @JsonProperty("provisionUid", String)
  provisionUid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("source", String)
  source = "";

  @JsonProperty("type", String)
  type = "";

  @JsonProperty("value", ProvisionValueConvertService)
  value: any = undefined; // one of the models in provision-type directory

  @JsonProperty("textValue", String, true)
  textValue = "";

  @JsonProperty("notes", String)
  notes: string | undefined = "";

  @JsonProperty("sectionHeader", String)
  sectionHeader: string | undefined = "";

  @JsonProperty("page", Number)
  page = -1;

  @JsonProperty("pdfHighlightIds", [String])
  pdfHighlightId: string[] = [];

  @JsonProperty("docAbstractUid", String)
  docAbstractUid = "";

  highlightedText: string;

  provisionTypeUid = "";
  provisionHighlight: any = {};
  isTwoPageTextHighlight = false;

  @JsonProperty("selected", Boolean)
  selected = false;

  originalTextValue = "";

  @JsonProperty("relatedOptionUids", [String], true)
  relatedOptionUids?: string[] = [];

  @JsonProperty("snippets", [SnippetModel], true)
  snippets?: SnippetModel[] = [];

  @JsonProperty("indexInText", Number, true)
  indexInText: number | undefined = undefined;

  @JsonProperty("textSurroundingOriginalProvision", String, true)
  textSurroundingOriginalProvision = "";

  @JsonProperty("surroundingSentence", String, true)
  surroundingSentence = "";

  @JsonProperty("type", String, true)
  valueType = "";

  @JsonProperty("formatValues", Any, true)
  formatValues?: any = {};
}
