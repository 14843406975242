export const ENTITY_FORM_SCHEMA_CONST = {
  $schema: "http://json-schema.org/draft-04/hyper-schema#",
  type: "object",
  properties: {
    name: {
      readOnly: false,
      class: "suite-formField",
      type: "string",
      widget: "string",
      isRequired: true,
      placeholder: "Enter Name",
      description: "Name",
      autoFocus: true,
    },
    description: {
      type: "string",
      readOnly: false,
      isRequired: true,
      widget: "textarea",
      placeholder: "Enter Description",
      description: "Description",
    },
  },
  buttons: [],
};

export const ENTITY_FORM_SCHEMA_ONLY_NAME_CONST = {
  $schema: "http://json-schema.org/draft-04/hyper-schema#",
  type: "object",
  properties: {
    name: {
      readOnly: false,
      class: "suite-formField",
      type: "string",
      widget: "string",
      isRequired: true,
      placeholder: "Enter Name",
      description: "Name",
      autoFocus: true,
    },
  },
  buttons: [],
};
