/* tslint:disable */
/* eslint-disable */
import { EditLbxRolesDtoModel } from "./edit-lbx-roles-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqEditLbxRolesModel")
export class OApiReqEditLbxRolesModel {
  @JsonProperty("data", EditLbxRolesDtoModel, true)
  data?: EditLbxRolesDtoModel = undefined;
}
