<div>
  <div mat-dialog-title class="clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModalClick()"
      aria-hidden="true"
      dataCy="closeAddQueueModal"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5 class="mt-0">Duplicate a queue</h5>
  </div>

  <div mat-dialog-content>
    <div style="margin-top: 20px"></div>

    <div class="form-group" itlsPgFormGroupDefault>
      <label> Queue Name </label>
      <input
        itlsFocusIn
        [(ngModel)]="queueName"
        type="text"
        class="form-control"
        dataCy="addProvisionFormName"
      />
    </div>
    <div class="form-group" itlsPgFormGroupDefault>
      <label>Queue Description</label>
      <input
        [(ngModel)]="queueDescription"
        dataCy="addProvisionFormDescription"
        type="text"
        class="form-control"
      />
    </div>

    <div style="margin-bottom: 10px"></div>

    <div class="form-group" itlsPgFormGroupDefault>
      <label> Queue Permission </label>
      <mat-select
        (selectionChange)="onQueuePermissionChange($event)"
        [value]="queuePermission"
        class="form-control"
      >
        <ng-container>
          <mat-option
            *ngFor="let permissionOption of queuePermissionOptions"
            [value]="permissionOption.value"
            >{{ permissionOption.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </div>
  </div>

  <div mat-dialog-actions>
    <div>
      <button
        type="button"
        dataCy="submitAddProvisionFormModal"
        class="btn btn-primary btn-cons pull-left inline"
        (click)="onDuplicateClick()"
        [disabled]="
          CreateButtonPipeTypesEnum.CreateDuplicateButtonIsDisabled
            | createButtonPipe: onlyMyQueues:queueName
        "
      >
        <span
          [matTooltip]="
            CreateButtonPipeTypesEnum.CreateDuplicateButtonTooltip
              | createButtonPipe: onlyMyQueues:queueName
          "
        >
          Create Duplicate
        </span>
      </button>
    </div>
  </div>
</div>
