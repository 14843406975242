import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";

@JsonObject("RelatedDocModel")
export class RelatedDocModel {
  /**
   * unique id for this file
   */
  @JsonProperty("uid", String)
  uid = "";
  /**
   * user-facing name for this file
   */
  @JsonProperty("name", String)
  name = "";
  /**
   * stored in backend with this filename
   */
  @JsonProperty("filename", String)
  filename = "";
  /**
   * the date this file was added
   */
  @JsonProperty("date", DateTimeConvertService)
  date: Date = undefined;

  selected: boolean;
}
