/* tslint:disable */
/* eslint-disable */
import { IssueLightDtoModel } from "./issue-light-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("IssueListDtoModel")
export class IssueListDtoModel {
  @JsonProperty("color", String, true)
  color?: string = "";

  @JsonProperty("issues", [IssueLightDtoModel], true)
  issues?: Array<IssueLightDtoModel> = [];

  @JsonProperty("title", String, true)
  title?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
