/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UpdateProvisionReviewCompletionInfoApiDtoModel")
export class UpdateProvisionReviewCompletionInfoApiDtoModel {
  /**
   * whether the provision review is being marked completed (or not)
   */

  @JsonProperty("provisionReviewCompleted", Boolean, true)
  provisionReviewCompleted?: boolean = undefined;
}
