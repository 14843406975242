import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpResponseBase,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import { catchError, tap } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { InteleaseNotificationService } from "@@intelease/web/intelease/services";
import { HttpContext, RestClient } from "@@intelease/web/utils";

const exceptionList: Array<any> = ["/assets/"];

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
  constructor(
    private _notification: MessageService,
    private spinner: NgxSpinnerService,
    private inteleaseNotificationService: InteleaseNotificationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const httpContext = req?.params?.get(
      RestClient.HTTP_CONTEXT_PARAM_KEY
    ) as any;
    const { url } = req;
    // if (req.method === 'POST') {
    setTimeout(() => {
      this.spinner.show();
    }, 1);
    // }
    return next.handle(req).pipe(
      tap((res: HttpResponse<any>) => {
        this.spinner.hide();
        setTimeout(() => {
          this.spinner.hide();
        }, 1);
        if (res && res.body) {
          let isUrlException = false;
          exceptionList.forEach((exception) => {
            if (url.includes(exception)) {
              isUrlException = true;
            }
          });
          if (
            !isUrlException &&
            this.shouldShowNotification(res, httpContext)
          ) {
            this.popNotification(req, res);
          }
        }
        return res;
      }),
      catchError((res) => {
        this.spinner.hide();
        setTimeout(() => {
          this.spinner.hide();
        }, 1);
        if (
          res instanceof HttpErrorResponse &&
          this.shouldShowNotification(res, httpContext)
        ) {
          this.popNotification(req, res);
        }
        return throwError(res);
      })
    );
  }

  private popNotification(req, res): void {
    const { url } = req;
    if (req.method !== "GET" && res.status === 200) {
      if (!this.shouldNotShowNotification(url)) {
        if (res.body.message) {
          this._notification.success(res.body.message, {
            Style: "flip",
            Duration: 3000,
          });
        } else if (res.body.errors) {
          res.body.errors.forEach((error: any) => {
            if (error.message && error.message.length) {
              this._notification.error(error.message, {
                Style: "flip",
                Duration: 3000,
              });
            }
          });
        }
      }
    } else if (res.status !== 200) {
      if (res.error && res.error.errors) {
        res.error.errors.forEach((error: any) => {
          if (error.message && error.message.length) {
            this.inteleaseNotificationService.openSnackBar(error.message);
          }
        });
      }
    }
  }

  private shouldNotShowNotification(url: string): boolean {
    if (url.indexOf("/values/") !== -1) {
      return true;
    }
    if (url.indexOf("/tsvExports") !== -1) {
      return true;
    }
    if (url.indexOf("/dashboards/") !== -1) {
      return true;
    }
    return false;
  }

  private shouldShowNotification(
    res: HttpResponseBase,
    httpContext: HttpContext
  ) {
    if (res?.status === 404 && httpContext?.doNotNotifyNotFoundError) {
      return false;
    }
    return true;
  }
}
