import { NgModule } from "@angular/core";
import { AddProjectComponent } from "./add-project.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { AppStateKanbanModule } from "@@intelease/app-state/kanban";
import { AppStateProjectsModule } from "@@intelease/app-state/projects";
import { MatTooltipModule } from "@angular/material/tooltip";

const MATERIAL_MODULES = [
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatTooltipModule,
];

@NgModule({
  imports: [
    ...MATERIAL_MODULES,
    FormsModule,
    CommonModule,
    InteleaseCommonModule,
    AppStateKanbanModule,
    AppStateProjectsModule,
  ],
  declarations: [AddProjectComponent],
  exports: [AddProjectComponent],
})
export class AddProjectModule {}
