import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("LoginDtoModel")
export class LoginDtoModel {
  @JsonProperty("email", String)
  email = "";

  @JsonProperty("password", String)
  password = "";
}
