import { JsonObject, JsonProperty } from "json2typescript";
import { FullMultiProvisionValueModel } from "@@intelease/web/common/models/multi-provision-value";

@JsonObject("ProvisionGroupNewStringModel")
export class ProvisionGroupNewStringModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("version", Number)
  version = 0;

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("provisions", [FullMultiProvisionValueModel])
  provisions: FullMultiProvisionValueModel[] = [];

  isOpen = true;
}
