/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("KeywordInfoModel")
export class KeywordInfoModel {
  @JsonProperty("keyword", String, true)
  keyword?: string = "";

  @JsonProperty("levenshteinRatioMargin", Number, true)
  levenshteinRatioMargin?: number = undefined;

  @JsonProperty("maxNumCharsDistance", Number, true)
  maxNumCharsDistance?: number = undefined;

  @JsonProperty("type", Any, true)
  type?:
    | "ALIAS"
    | "ALIAS_POSS"
    | "ENUM"
    | "OBJECT_OF"
    | "LOWER_CASE_CLEAN_TEXT"
    | "PROVISION_VALUE"
    | "REGULAR_CASE_CLEAN_TEXT"
    | "HEADER"
    | "PROVISION_TYPE"
    | "PROVISION_TYPE_CONTAINS"
    | "POS_TAG"
    | "LOWER_CASE_CLEAN_CONTAINS"
    | "REGULAR_CASE_CLEAN_CONTAINS"
    | "DEFINITION"
    | "STATE" = undefined;
}
