/* tslint:disable */
/* eslint-disable */

/**
 * the request body for modifying the NESTED_GROUP provision mention subfield metadata
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ModifyNestedSubfieldMetadataApiDtoModel")
export class ModifyNestedSubfieldMetadataApiDtoModel {
  @JsonProperty("approvalStatus", Any, true)
  approvalStatus?: "DEFAULT" | "APPROVED" | "REJECTED" = undefined;

  @JsonProperty("mentionUid", String, true)
  mentionUid?: string = "";

  @JsonProperty("postMentionUid", String, true)
  postMentionUid?: string = "";

  @JsonProperty("preMentionUid", String, true)
  preMentionUid?: string = "";

  /**
   * The target subfield key, can be null for updating all subfields metadata/approval status
   */

  @JsonProperty("subfieldKey", String, true)
  subfieldKey?: string = "";
}
