import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { WEB_ABSTRACTION_PAGE_ANIMATION_CONST } from "@@intelease/web/abstraction-page/src/lib/constants";
import { UserInfoService } from "@@intelease/web/intelease/services";
import {
  CommentModel,
  CommentUserMentionModel,
} from "@@intelease/web/abstraction-page/src/lib/models";
import { SettingsService } from "@@intelease/app-services/settings";
import {
  InteleaseMentionHelper,
  IntelFullMention,
  REGULAR_EXPRESSION_CONST,
} from "@@intelease/web/utils";
import { ProfileUserModel } from "@@intelease/web/common/models/user";
import { take } from "lodash";

export interface EditedComment {
  uid: string;
  text: string;
  mentions: CommentUserMentionModel[];
  elements?;
}

@Component({
  selector: "il-comment-detail",
  templateUrl: "./itls-comment-detail.component.html",
  styleUrls: ["./itls-comment-detail.component.scss"],
  animations: WEB_ABSTRACTION_PAGE_ANIMATION_CONST,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsCommentDetailComponent implements OnInit {
  _comment: CommentModel;
  get comment(): CommentModel {
    return this._comment;
  }
  @Input()
  set comment(comment: CommentModel) {
    this.canEdit = UserInfoService.getUserUid() === comment.createdBy.uid;
    this.commentText = comment.text;
    // this.commentRichText = this.createRichText(this.commentText)
    this._comment = comment;
    if (comment.elements.length) {
      // this.mentionModel.elements = InteleaseMentionHelper.mapServerResponse(this._comment.elements)
      this.mentionModel.elements = comment.elements;
      this.mentionModel.mentions = comment.mentions;
    }
    this.commentRichText = InteleaseMentionHelper.serializeMention(
      this.mentionModel
    );
    this.cdr.detectChanges();
  }

  canEdit: boolean;
  commenterNameFirstCharacter: string;
  profileImageSrc: string;
  @Output() deleteCommentEvent: EventEmitter<any> = new EventEmitter<any>();
  editing: boolean;
  commentText: string;
  commentRichText: string;
  commentTextCursorPosition = 0;
  @Output()
  editCommentEvent: EventEmitter<EditedComment> = new EventEmitter<EditedComment>();
  @Input() accountUsers: ProfileUserModel[];
  mentions: CommentUserMentionModel[] = [];
  filteredAccountUsers: ProfileUserModel[];
  userMentionMenuSearchInput = "";
  @Input() mentionList: { key: string; value: string; email: string }[] = [];
  mentionModel: IntelFullMention = { mentions: [], elements: [] };

  constructor(
    private settingsService: SettingsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.commenterNameFirstCharacter = this.comment?.createdBy?.name?.charAt(0);
    if (this.comment?.createdBy?.hasProfileImage) {
      this.settingsService
        .getUserProfileImage(this.comment?.createdBy?.uid)
        .subscribe((profileImageSrc) => {
          this.profileImageSrc = profileImageSrc;
          this.cdr.detectChanges();
        });
    }
    this.cdr.detectChanges();
  }

  // this is called only in edit mode so no need to update 'this.commentRichText'
  onMentionUserSelected(accountUser: ProfileUserModel) {
    const textToInsert = this.getUserMentionValueInText(accountUser.email);
    if (!this.commentText.length) {
      this.commentText = textToInsert;
    } else {
      let prefix = this.commentText.substr(0, this.commentTextCursorPosition);
      let postfix = "";
      if (this.commentText.length > this.commentTextCursorPosition) {
        postfix = " " + this.commentText.substr(this.commentTextCursorPosition);
      }
      if (!prefix.endsWith(" ")) {
        prefix = prefix + " ";
      }
      if (postfix.length && !postfix.startsWith(" ")) {
        postfix = " " + postfix;
      }
      this.commentText = prefix + textToInsert + postfix;
    }
    // don't add duplicate mention
    if (!this.mentions.find((item) => item.uid === accountUser.uid)) {
      this.mentions.push({
        uid: accountUser.uid,
        email: accountUser.email,
        fullName: accountUser.name,
      });
    }
  }

  onCommentTextFocusOut(event: any) {
    this.commentTextCursorPosition = event.target.selectionStart;
  }

  private getUserMentionValueInText(userEmail: string) {
    return "@" + userEmail;
  }

  private createRichText(rawText: string): string {
    if (rawText && rawText.length) {
      return InteleaseMentionHelper.transformMentionsToLink(rawText);
      // let richText = ''
      // const rawTextLength = rawText.length
      // let startIdx = 0
      // const matchedItems = rawText.matchAll(
      //     REGULAR_EXPRESSION_CONST.ALL_EMAILS,
      // )
      // for (const matchedItem of matchedItems) {
      //     if (startIdx !== matchedItem.index) {
      //         const lengthBeforeEmailStart = matchedItem.index - startIdx
      //         richText += rawText.substr(startIdx, lengthBeforeEmailStart)
      //         startIdx += lengthBeforeEmailStart
      //     }
      //     const emailText = matchedItem[0]
      //     richText += this.createRichTextLink(emailText)
      //     startIdx += emailText.length
      // }
      // if (startIdx !== rawTextLength - 1) {
      //     richText += rawText.substr(startIdx)
      // }
      // return richText
    } else {
      return rawText;
    }
  }

  private getUserByEmail(email: string) {
    const user = this.accountUsers.find((item) => item.email === email);
    return user ? user.name : "";
  }

  private createRichTextLink(emailText: string) {
    return `<a href="mailto:${emailText}" target="_blank" title="${this.getUserByEmail(
      emailText
    )}">${emailText}</a>`;
  }
  onDeleteComment() {
    this.deleteCommentEvent.emit();
  }

  onEditComment() {
    this.editing = true;
    this.cdr.detectChanges();
  }

  onEditCommentSave() {
    this.editing = false;
    this._comment.text = this.commentText;
    // this.commentRichText = this.createRichText(this.commentText)
    this.commentRichText = InteleaseMentionHelper.serializeMention(
      this.mentionModel
    );
    this._comment.mentions = this.mentions;
    this.editCommentEvent.next({
      uid: this.comment.uid,
      text: this.comment.text,
      mentions: this.comment.mentions,
      elements: this.mentionModel,
    });
    this.mentions = [];
    this.cdr.detectChanges();
  }

  onEditCommentCancel() {
    this.editing = false;
    this.commentText = this.comment.text;
    // this.commentRichText = this.createRichText(this.commentText)
    this.commentRichText = InteleaseMentionHelper.serializeMention(
      this.mentionModel
    );
    this.mentions = [];
    this.cdr.detectChanges();
  }

  onSearchInputChanged() {
    this.reloadFilteredAccountUsers();
  }

  onUserMentionMenuOpened() {
    this.userMentionMenuSearchInput = "";
    this.reloadFilteredAccountUsers();
  }

  private reloadFilteredAccountUsers() {
    const lowerCasedSearchInput = this.userMentionMenuSearchInput.toLowerCase();
    if (!lowerCasedSearchInput.length) {
      this.filteredAccountUsers = this.accountUsers;
    } else {
      this.filteredAccountUsers = this.accountUsers.filter((item) =>
        item.name.toLowerCase().includes(lowerCasedSearchInput)
      );
    }
    // select top 5 account users
    this.filteredAccountUsers = take(this.filteredAccountUsers, 5);
  }
}
