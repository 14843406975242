import { RecordDtoModel } from "@@intelease/api-models/adex-api-model-src";
import {
  QueueFacade,
  TranslateQueueStage,
} from "@@intelease/app-state/queue/src";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { keys } from "lodash";
import { Observable } from "rxjs";
import { SEARCH_BAR_ANIMATION } from "./itls-queue-search-bar-animation.const";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";

@Component({
  selector: "itls-queue-search-bar",
  templateUrl: "./itls-queue-search-bar.component.html",
  styleUrls: ["./itls-queue-search-bar.component.scss"],
  animations: [...SEARCH_BAR_ANIMATION],
})
export class ItlsQueueSearchBarComponent {
  isSearchActive$: Observable<boolean> = this.queueFacade.getIsSeachActive$;

  TranslateQueueStage = TranslateQueueStage;

  keys = keys;

  constructor(public queueFacade: QueueFacade, private router: Router) {}

  searchRecordInQueue(inputElement: any) {
    this.queueFacade.searchRecordInQueue(inputElement?.value);
  }

  openRecord(record: RecordDtoModel) {
    this.queueFacade.toggleSearchbar();
    this.router.navigate(["record-review", record.uid, "pdf"]);
  }
}
