import { Pipe, PipeTransform } from "@angular/core";
import { ItlsCustomIconType } from "../../components/itls-custom-icon";

export enum IconTypeEnum {
  DATE = "DATE",
  LOCAL_DATE = "LOCAL_DATE",
  COMMON_NOUN = "COMMON_NOUN",
  PROPER_NOUN = "PROPER_NOUN",
  STRING = "STRING",
  MONEY = "MONEY",
  ADDRESS = "ADDRESS",
  DOUBLE = "DOUBLE",
  SINGLE_CAT = "SINGLE_CAT",
  MULTIPLE_CAT = "MULTIPLE_CAT",
  TABLE = "TABLE",
  YEAR = "YEAR",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  TIME_PLAN = "TIME_PLAN",
  ROLE = "ROLE",
  ACCOUNT = "ACCOUNT",
  UNREGISTERED_USER = "UNREGISTERED_USER",
  USER = "USER",
  NESTED_GROUP = "NESTED_GROUP",
}

@Pipe({
  name: "translateIconType",
  pure: true,
})
export class TranslateIconTypePipe implements PipeTransform {
  public static transform(iconType: string): string {
    return new TranslateIconTypePipe().transform(iconType);
  }

  transform(iconType?: string): ItlsCustomIconType {
    switch (iconType) {
      case IconTypeEnum.LOCAL_DATE:
      case IconTypeEnum.DATE:
        return "icon-date";

      case IconTypeEnum.PROPER_NOUN:
        return "icon-name";

      case IconTypeEnum.SINGLE_CAT:
      case IconTypeEnum.MULTIPLE_CAT:
        return "icon-text-underlined";

      case IconTypeEnum.EMAIL:
        return "icon-email";

      case IconTypeEnum.TABLE:
      case IconTypeEnum.NESTED_GROUP:
        return "icon-table";

      case IconTypeEnum.YEAR:
        return "icon-year";

      case IconTypeEnum.MONEY:
        return "icon-money";

      case IconTypeEnum.PHONE:
        return "icon-phone";

      case IconTypeEnum.DOUBLE:
        return "icon-numeric";

      case IconTypeEnum.ADDRESS:
        return "icon-location";

      case IconTypeEnum.USER:
      case IconTypeEnum.UNREGISTERED_USER:
        return "icon-person";

      case IconTypeEnum.ROLE:
      case IconTypeEnum.ACCOUNT:
        return "icon-team";

      case IconTypeEnum.COMMON_NOUN:
      case IconTypeEnum.STRING:
      default:
        return "icon-text";
    }
  }
}
