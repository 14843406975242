import { JsonObject, JsonProperty } from "json2typescript";
import { FilterDocQueryModel } from "./filter-doc-query.model";

@JsonObject("SimpleFilterModel")
export class SimpleFilterModel {
  static view = "full";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("docQuery", FilterDocQueryModel)
  docQuery: FilterDocQueryModel = new FilterDocQueryModel();
}
