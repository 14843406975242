import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { TableCellDataType } from "../../interfaces/table-create-edit-model";
import { isEqual } from "lodash";

@Component({
  selector: "il-table-body",
  templateUrl: "./table-body.component.html",
  styleUrls: ["./table-body.component.scss"],
})
export class TableBodyComponent {
  isEditable = false;
  shouldSave = false;
  oldData: string | string[];
  data: string | string[];
  dataType: TableCellDataType;
  disabledButtons = {
    addRA: false,
    addRB: false,
    remove: false,
    mergeRA: false,
    mergeRB: false,
    moveRA: false,
    moveRB: false,
    duplicateRow: false,
  };
  heightSize: number;
  resizables: any;
  maxHight: number;
  colIndex: number;
  rowIndex: number;

  @Input() get inputData() {
    return this._inputData;
  }

  set inputData(value) {
    this.data = value.data;
    this.colIndex = value.colIndex;
    this.rowIndex = value.rowIndex;
    this.dataType = value.dataType;
    this.checkButtonsDisabled();
  }

  @Input() rowsLength: number;
  @Input() rowHeight: number;
  @Input() readOnly: boolean;

  @Input()
  allowMerge: boolean;
  @Input() strictColumn: boolean;

  @Output() addRowBelow: EventEmitter<any> = new EventEmitter();
  @Output() addRowAbove: EventEmitter<any> = new EventEmitter();
  @Output() moveRowBelow: EventEmitter<any> = new EventEmitter();
  @Output() moveRowAbove: EventEmitter<any> = new EventEmitter();
  @Output() mergeRowBelow: EventEmitter<any> = new EventEmitter();
  @Output() mergeRowAbove: EventEmitter<any> = new EventEmitter();
  @Output() removeRow: EventEmitter<any> = new EventEmitter();
  @Output() duplicateRow: EventEmitter<any> = new EventEmitter();
  @Output() saveEditedRowData: EventEmitter<any> = new EventEmitter();

  @Output() onInputBoxHeightChanged: EventEmitter<any> = new EventEmitter();

  _inputData: {
    data: string | string[];
    colIndex: number;
    rowIndex: number;
    dataType: TableCellDataType;
  };

  @ViewChild("resizer") resizer: ElementRef;
  @ViewChild("matMenu1Trigger") matMenu1Trigger: any;

  onEdit() {
    this.shouldSave = false;

    if (this.isEditable) {
      this.focusCell();
      return;
    }

    this.isEditable = true;

    this.focusCell();

    this.oldData = JSON.parse(JSON.stringify(this.data));
    setTimeout(() => {
      this.focusCell();
    }, 1);
  }

  private focusCell(): void {
    const cellInput = document.getElementById(
      `cell-${this.rowIndex}-${this.colIndex}`
    );

    if (!cellInput) {
      return;
    }

    cellInput.focus();
  }

  onDiscard() {
    this.isEditable = false;
    this.data = this.oldData;
  }

  onSave() {
    this.shouldSave = true;

    setTimeout(() => {
      if (this.isEditable && this.shouldSave) {
        this.save();
      }
    }, 100);
  }

  onSelectionChange(event: Event, rowIndex: number) {
    setTimeout(() => {
      this.saveEditedRowData.emit({
        data: this.data,
        colIndex: this.colIndex,
        rowIndex: this.rowIndex,
        dataType: this.dataType,
      });
      this.onInputBoxHeightChanged.emit({ event, rowIndex });
    }, 100);
  }

  private save(): void {
    this.isEditable = false;
    this.shouldSave = false;
    if (this.data !== this.oldData) {
      this.saveEditedRowData.emit({
        data: this.data,
        colIndex: this.colIndex,
        rowIndex: this.rowIndex,
        dataType: this.dataType,
      });
    }
  }

  onAddRowAbove() {
    this.addRowAbove.emit(this.rowIndex);
    this.checkButtonsDisabled();
  }

  onAddRowBelow() {
    this.addRowBelow.emit(this.rowIndex);
    this.checkButtonsDisabled();
  }

  onRemoveRow() {
    this.removeRow.emit(this.rowIndex);
    this.checkButtonsDisabled();
  }

  onMoveRowAbove() {
    this.moveRowAbove.emit(this.rowIndex);
    this.checkButtonsDisabled();
  }

  onMoveRowBelow() {
    this.moveRowBelow.emit(this.rowIndex);
    this.checkButtonsDisabled();
  }

  onMergeRowAbove() {
    this.mergeRowAbove.emit(this.rowIndex);
    this.checkButtonsDisabled();
  }

  onMergeRowBelow() {
    this.mergeRowBelow.emit(this.rowIndex);
    this.checkButtonsDisabled();
  }

  onDuplicateRow() {
    this.duplicateRow.emit(this.rowIndex);
    this.checkButtonsDisabled();
  }

  private checkButtonsDisabled() {
    setTimeout(() => {
      this.disabledButtons = {
        addRA: !!this.readOnly,
        addRB: !!this.readOnly,
        mergeRA: !!this.readOnly,
        mergeRB: !!this.readOnly,
        moveRA: !!this.readOnly,
        moveRB: !!this.readOnly,
        remove: !!this.readOnly,
        duplicateRow: !!this.readOnly,
      };
      if (this.readOnly) {
        return;
      }

      if (this.rowsLength <= 1) {
        this.disabledButtons.remove = !this.strictColumn;
        this.disabledButtons.mergeRA = true;
        this.disabledButtons.mergeRB = true;
        this.disabledButtons.moveRA = true;
        this.disabledButtons.moveRB = true;
      } else if (this.rowIndex === 0) {
        this.disabledButtons.mergeRA = true;
        this.disabledButtons.moveRA = true;
      } else if (this.rowIndex >= this.rowsLength - 1) {
        this.disabledButtons.mergeRB = true;
        this.disabledButtons.moveRB = true;
      }
      if (!this.allowMerge) {
        this.disabledButtons.mergeRA = true;
        this.disabledButtons.mergeRB = true;
      }
    }, 100);
  }

  closeMenu() {
    this.matMenu1Trigger.closeMenu();
  }
}
