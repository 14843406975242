import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { FormControl } from "@angular/forms";
import { CalendarViewsEnum } from "@@intelease/app-models/calendar";

@Component({
  selector: "il-calendar-toolbar-component",
  templateUrl: "./itls-calendar-toolbar.component.html",
  styleUrls: ["./itls-calendar-toolbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsCalendarToolbarComponent {
  @Output() today: EventEmitter<any> = new EventEmitter<any>();
  @Output() next: EventEmitter<any> = new EventEmitter<any>();
  @Output() pre: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>> =
    new EventEmitter<MatDatepickerInputEvent<any>>();
  @Output()
  calendarViewChange: EventEmitter<CalendarViewsEnum> = new EventEmitter<CalendarViewsEnum>();

  @Input() set todayDate(value) {
    this._todayDate = value;
    this.setDatePickerValue(value);
  }

  get todayDate() {
    return this._todayDate;
  }

  private _todayDate = new Date();
  calendarViews: string[] = [
    // CalendarViewsEnum.day,
    CalendarViewsEnum.week,
    CalendarViewsEnum.month,
    CalendarViewsEnum.agenda,
  ];
  @Input()
  selectedCalendarView: CalendarViewsEnum;
  datepickerControl = new FormControl(this.todayDate);
  calendarViewsEnum = CalendarViewsEnum;

  constructor() {}

  onCalendarViewSelectionChange(evt: MatSelectChange) {
    this.calendarViewChange.emit(evt.value);
  }

  onPreviousClick() {
    this.pre.emit();
  }

  onNextClick() {
    this.next.emit();
  }

  onTodayClick() {
    this.today.emit();
  }

  addEvent(event: MatDatepickerInputEvent<any>) {
    this.dateChange.emit(event);
  }

  private setDatePickerValue(value) {
    this.datepickerControl.setValue(new Date(value));
  }
}
