/* tslint:disable */
/* eslint-disable */
import { TemporaryFileModel } from "./temporary-file-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FilePartTemporaryFileModel")
export class FilePartTemporaryFileModel {
  @JsonProperty("contentType", String, true)
  contentType?: string = "";

  @JsonProperty("dispositionType", String, true)
  dispositionType?: string = "";

  @JsonProperty("fileSize", Number, true)
  fileSize?: number = undefined;

  @JsonProperty("filename", String, true)
  filename?: string = "";

  @JsonProperty("key", String, true)
  key?: string = "";

  @JsonProperty("ref", TemporaryFileModel, true)
  ref?: TemporaryFileModel = undefined;
}
