<div class="intelease-web-notifications" (click)="$event.stopPropagation()">
  <div class="notification-body">
    <mat-progress-spinner
      [diameter]="50"
      style="position: absolute; top: 230px; right: 44%"
      mode="indeterminate"
      *ngIf="notificationsFacade.isNotificationsCategoryLoading$ | async"
    >
    </mat-progress-spinner>
    <ng-container
      *ngIf="
        (notificationsFacade.isNotificationsCategoryLoading$ | async) !==
          true && (notificationsFacade.notificationsCategoryLoaded$ | async)
      "
    >
      <nav mat-tab-nav-bar>
        <a
          mat-tab-link
          *ngFor="let tab of notificationCategories"
          [disabled]="
            notificationsFacade.isSelectedCategoryNotificationsLoading$ | async
          "
          (click)="onTabSelected(tab)"
          [active]="selectedTab.key === tab.key"
        >
          {{ tab.title }}
          <span
            class="notification-badge"
            *ngIf="tab.unseenCount > 0 && tab.key !== 'ALL'"
            >{{ tab.unseenCount }}</span
          >
        </a>
      </nav>

      <mat-progress-spinner
        [diameter]="50"
        style="position: absolute; top: 230px; right: 44%"
        mode="indeterminate"
        *ngIf="
          notificationsFacade.isSelectedCategoryNotificationsLoading$ | async
        "
      >
      </mat-progress-spinner>

      <ng-container
        *ngIf="
          (notificationsFacade.isSelectedCategoryNotificationsLoading$
            | async) !== true
        "
      >
        <div
          class="comment-list"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          [scrollWindow]="false"
          (scrolled)="onScroll()"
          *ngIf="
            categoryNotificationsMap[selectedTab?.key] &&
            categoryNotificationsMap[selectedTab?.key].length > 0
          "
        >
          <div
            class="comment-item"
            *ngFor="
              let notification of categoryNotificationsMap[selectedTab?.key]
            "
            (click)="onNotificationClick($event, notification)"
            [style.backgroundColor]="notification.read ? 'unset' : '#edf3ff'"
          >
            <div class="comment-item-container">
              <div class="detail">
                <img src="/assets/images/user.png" alt="" />
                <span
                  class="user"
                  matTooltipClass="multi-line-tooltip"
                  [matTooltip]="
                    'Name: ' +
                    notification?.creator?.fullName +
                    '\n Email: ' +
                    notification?.creator?.email
                  "
                >
                  {{ notification?.creator?.fullName | shorten: 16:"..." }}
                </span>
                <span class="text" [matTooltip]="notification?.typeTitle">
                  {{
                    notification?.typeTitle
                      | shorten
                        : (notification.abstractUid &&
                          notification.abstractTitle
                            ? 36
                            : 35)
                        : "..."
                  }}</span
                >
                <span class="date">
                  <!-- RECORD NOTIFICATION --->
                  <span
                    *ngIf="
                      notification.abstractUid && notification.abstractTitle
                    "
                    [matTooltip]="
                      notification.metadata?.paths | joinArray: ' > ':'Records'
                    "
                  >
                    <a
                      class="notification-link"
                      (click)="onNotificationClick($event, notification)"
                      >Records</a
                    >
                    <mat-icon
                      svgIcon="icon-arrow"
                      style="
                        margin-left: 4px;
                        min-width: 6px;
                        min-height: 6px;
                        line-height: 0;
                        width: 8px;
                        height: 8px;
                        vertical-align: middle;
                        margin-right: 4px;
                      "
                    >
                    </mat-icon>
                    <span *ngIf="notification.metadata?.paths?.length > 1"
                      >. . .</span
                    >
                    <mat-icon
                      *ngIf="notification.metadata?.paths?.length > 1"
                      svgIcon="icon-arrow"
                      style="
                        margin-left: 4px;
                        min-width: 6px;
                        min-height: 6px;
                        line-height: 0;
                        width: 8px;
                        height: 8px;
                        vertical-align: middle;
                        margin-right: 4px;
                      "
                    ></mat-icon>
                    <a
                      class="notification-link entity-name"
                      (click)="onNotificationClick($event, notification)"
                      >{{ notification.abstractTitle | shorten: 40:".." }}</a
                    >
                  </span>
                  <!-- DRIVE NOTIFICATION --->
                  <span
                    *ngIf="
                      notification.metadata?.folderUid &&
                      notification.metadata?.folderName
                    "
                    [matTooltip]="
                      notification.metadata?.paths | joinArray: ' > ':'My Drive'
                    "
                  >
                    <a
                      class="notification-link"
                      (click)="onNotificationClick($event, notification)"
                      >My Drive</a
                    >
                    <mat-icon
                      svgIcon="icon-arrow"
                      style="
                        margin-left: 4px;
                        min-width: 6px;
                        min-height: 6px;
                        line-height: 0;
                        width: 8px;
                        height: 8px;
                        vertical-align: middle;
                        margin-right: 4px;
                      "
                    >
                    </mat-icon>
                    <span *ngIf="notification.metadata?.paths?.length > 1"
                      >. . .</span
                    >
                    <mat-icon
                      *ngIf="notification.metadata?.paths?.length > 1"
                      svgIcon="icon-arrow"
                      style="
                        margin-left: 4px;
                        min-width: 6px;
                        min-height: 6px;
                        line-height: 0;
                        width: 8px;
                        height: 8px;
                        vertical-align: middle;
                        margin-right: 4px;
                      "
                    >
                    </mat-icon>
                    <a
                      class="notification-link entity-name"
                      (click)="onNotificationClick($event, notification)"
                      >{{
                        notification.metadata?.folderName | shorten: 40:".."
                      }}</a
                    >
                  </span>
                  <!-- ABSTRACT NOTIFICATION --->
                  <span
                    *ngIf="
                      notification.entityName &&
                      notification.entityType &&
                      !notification.metadata?.calendarUid &&
                      !notification.metadata?.calendarName &&
                      !notification.metadata?.fieldUiName
                    "
                  >
                    <a
                      class="notification-link"
                      (click)="onNotificationClick($event, notification)"
                      >{{ getUiEntityTypeFrom(notification.entityType) }}</a
                    >
                    <mat-icon
                      svgIcon="icon-arrow"
                      style="
                        margin-left: 4px;
                        min-width: 6px;
                        min-height: 6px;
                        line-height: 0;
                        width: 8px;
                        height: 8px;
                        vertical-align: middle;
                        margin-right: 4px;
                      "
                    >
                    </mat-icon>
                    <a
                      class="notification-link entity-name"
                      (click)="onNotificationClick($event, notification)"
                      [matTooltip]="notification.entityName"
                      >{{ notification.entityName | shorten: 40:".." }}</a
                    >
                  </span>
                  <!-- REMINDER NOTIFICATION --->
                  <span
                    *ngIf="
                      notification.entityName &&
                      notification.entityType &&
                      notification.metadata?.calendarUid &&
                      notification.metadata?.calendarName &&
                      notification.metadata?.fieldUiName
                    "
                  >
                    <a class="notification-link" routerLink="/calendars/list"
                      >Calendars</a
                    >
                    <mat-icon
                      svgIcon="icon-arrow"
                      style="
                        margin-left: 4px;
                        min-width: 6px;
                        min-height: 6px;
                        line-height: 0;
                        width: 8px;
                        height: 8px;
                        vertical-align: middle;
                        margin-right: 4px;
                      "
                    >
                    </mat-icon>
                    <a
                      class="notification-link entity-name"
                      (click)="onNotificationClick($event, notification)"
                      [matTooltip]="notification.metadata?.calendarName"
                      [matTooltipDisabled]="
                        notification.metadata?.calendarName?.length < 40
                      "
                      >{{
                        notification.metadata?.calendarName | shorten: 40:".."
                      }}</a
                    >
                  </span>
                  <!-- TASK NOTIFICATION --->
                  <span
                    *ngIf="notification.taskTitle && notification.taskUid"
                    (click)="onNotificationClick($event, notification)"
                  >
                    <a class="notification-link" routerLink="/tasks/list"
                      >Tasks</a
                    >
                    <mat-icon
                      svgIcon="icon-arrow"
                      style="
                        margin-left: 4px;
                        min-width: 6px;
                        min-height: 6px;
                        line-height: 0;
                        width: 8px;
                        height: 8px;
                        vertical-align: middle;
                        margin-right: 4px;
                      "
                    >
                    </mat-icon>
                    <a
                      class="notification-link entity-name"
                      [matTooltip]="notification.taskTitle"
                      routerLink="/tasks/list"
                      >{{ notification.taskTitle | shorten: 35:".." }}</a
                    >
                  </span>
                </span>
              </div>
              <div>
                <span
                  style="margin-right: 6px; font-size: 10px; color: #505f79"
                  >{{ notification?.createdAt | date: "short" }}</span
                >
                <span
                  class="detail-btn"
                  [style.backgroundColor]="
                    notification.isOpen ? '#f5f6f7' : '#fff'
                  "
                  (click)="
                    notification.isOpen = !notification.isOpen;
                    onReadNotificationClick($event, notification)
                  "
                >
                  Details
                </span>
              </div>
            </div>
            <div class="comment-text" *ngIf="notification.isOpen">
              <ng-container
                *ngFor="
                  let notificationTextValue of getNotificationValue(
                    notification
                  )
                "
              >
                <i
                  *ngIf="notificationTextValue?.title"
                  style="font-weight: bold"
                  >{{ notificationTextValue?.title }}</i
                >
                <div
                  style="
                    color: #505f79;
                    font-weight: bold;
                    padding-bottom: 4px;
                    padding-top: 4px;
                  "
                  *ngIf="notificationTextValue?.to"
                >
                  From value:
                </div>
                <strong *ngIf="notificationTextValue?.from">
                  <span
                    [innerHTML]="notificationTextValue?.from | safeHTML"
                  ></span>
                </strong>
                <div
                  style="
                    color: #505f79;
                    font-weight: bold;
                    padding-top: 4px;
                    padding-bottom: 4px;
                  "
                  *ngIf="notificationTextValue?.to"
                >
                  To value:
                </div>
                <strong *ngIf="notificationTextValue?.to">
                  <span
                    [innerHTML]="notificationTextValue?.to | safeHTML"
                  ></span>
                </strong>
                <mat-divider
                  style="margin: 4px 0"
                  *ngIf="notificationTextValue?.title"
                ></mat-divider>
              </ng-container>
            </div>
          </div>
        </div>

        <div
          class="empty-notification"
          *ngIf="
            categoryNotificationsMap[selectedTab?.key] &&
            categoryNotificationsMap[selectedTab?.key].length === 0
          "
        >
          <img src="/assets/images/empty-notifications.svg" />
          <p class="title">No notifications</p>
          <p class="body"></p>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
