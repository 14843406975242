import { NgModule } from "@angular/core";
import { AddCalendarComponent } from "./add-calendar.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";

const MATERIAL_MODULES = [
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
];

@NgModule({
  imports: [
    ...MATERIAL_MODULES,
    FormsModule,
    CommonModule,
    InteleaseCommonModule,
  ],
  declarations: [AddCalendarComponent],
  exports: [AddCalendarComponent],
})
export class AddCalendarModule {}
