import { Component, Inject, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { isEmpty } from "lodash";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { NgxTranslateService } from "@@intelease/web/common/services/ngx-translate.service";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { MinimalProvisionFormModel } from "@@intelease/web/common/models";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "itls-choose-provision-form-modal",
  templateUrl: "./choose-provision-form-modal.component.html",
  styleUrls: ["./choose-provision-form-modal.component.scss"],
})
export class ChooseProvisionFormModalComponent implements OnInit {
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.EDIT;
  payload: any;
  customMessage = false;
  title = "Choose a Provision Form";
  message = "Which provision form would you like to use?";
  params: Object = {};
  chosenProvisionForm: MinimalProvisionFormModel = undefined;
  provisionForms: MinimalProvisionFormModel[] = [];
  currentProvisionFormUid: string;
  chosenFormOption = "";
  formOptions = [
    {
      name: "CURRENT",
      message: "Find the same provisions as for the current abstract.",
    },
    {
      name: "NEW",
      message: "Choose an account-level provision form to use for abstracting.",
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<ChooseProvisionFormModalComponent>,
    private ngxTranslateService: NgxTranslateService,
    private inputValidationService: InputValidationService
  ) {}

  ngOnInit() {
    if (
      !isEmpty(this.dialogData.payload) &&
      this.dialogData.payload.customMessage
    ) {
      this.ngxTranslateService
        .getTranslateByKey(
          this.dialogData.payload.message,
          this.dialogData.payload.params
        )
        .subscribe((message: string) => {
          this.message = message;
          this.title = this.dialogData.payload.title;
        });
    }
    this.currentProvisionFormUid =
      this.dialogData.payload.params.currentProvisionFormUid;
    this.provisionForms = this.dialogData.payload.params.provisionForms;
  }

  onChooseFormOption(formOption: string) {
    this.chosenFormOption = formOption;
    if (this.chosenFormOption === "CURRENT") {
      this.chosenProvisionForm = undefined;
    }
  }

  onChoose() {
    if (
      !this.validateOnChooseFields(
        this.chosenFormOption,
        this.chosenProvisionForm
      )
    ) {
      return;
    }
    const thisProvisionFormUid =
      this.chosenFormOption === "CURRENT"
        ? this.currentProvisionFormUid
        : this.chosenProvisionForm.uid;
    this.modalRes = {
      data: {
        provisionFormUid: thisProvisionFormUid,
      },
    };
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.CLOSE,
    });
  }

  private validateOnChooseFields(
    chosenFormOption: string,
    chosenProvisionForm: MinimalProvisionFormModel
  ): boolean {
    if (chosenFormOption === "CURRENT") {
      return true;
    }
    if (
      !this.inputValidationService.usefulNestedObjectTextFieldElseAlert(
        chosenProvisionForm,
        ["uid"],
        "You must choose a provision form!"
      )
    ) {
      return false;
    }
    return true;
  }

  onDismissModal() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}
