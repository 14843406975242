import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromKanban from "./kanban.reducer";
import { KanbanEffects } from "./kanban.effects";
import { KanbanFacade } from "./kanban.facade";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromKanban.KANBAN_FEATURE_KEY, fromKanban.reducer),
    EffectsModule.forFeature([KanbanEffects]),
  ],
  providers: [KanbanFacade],
})
export class AppStateKanbanModule {}
