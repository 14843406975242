<ngx-spinner
  bdOpacity="1"
  bdColor="rgba(0,0,0,0.28)"
  size="small"
  color="#ffffff"
  type="ball-spin"
  [fullScreen]="false"
>
  <!--<p style="color: white"> Loading... </p>-->
</ngx-spinner>
<!--<intelease-progress-bar></intelease-progress-bar>-->

<!--<ng-scrollbar style="max-height: 100%;">-->
<itls-vertical-sidebar-layout (resized)="onResized()">
  <router-outlet *ngIf="!loading"></router-outlet>
</itls-vertical-sidebar-layout>
<div
  id="nb-global-spinner"
  class="spinner"
  *ngIf="loading || (hasPermissions$ | async)"
>
  <div class="blob blob-0"></div>
</div>
<div [fxShow]="isInAppBreakPoint" class="app-breakpoint" id="appBreakpoint">
  <img src="assets/images/adex-logo-font.png" style="margin-bottom: 15px" />
  ADEx is not currently compatible with mobile devices. <br />Please open ADEx
  on desktop.
</div>
<!--<router-outlet></router-outlet>-->
<!--</ng-scrollbar>-->
