/* tslint:disable */
/* eslint-disable */
import { MergeDocSetDocDataDtoModel } from "./merge-doc-set-doc-data-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MergeDocSetsReqDtoModel")
export class MergeDocSetsReqDtoModel {
  @JsonProperty("directoryUid", String, true)
  directoryUid?: string = "";

  @JsonProperty("docSetName", String, true)
  docSetName?: string = "";

  @JsonProperty("queueUid", String, true)
  queueUid?: string = "";

  @JsonProperty("selectedDocDatas", [MergeDocSetDocDataDtoModel], true)
  selectedDocDatas?: Array<MergeDocSetDocDataDtoModel> = [];
}
