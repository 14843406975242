import { Injectable } from "@angular/core";
import {
  RentScheduleCalculatorRequest,
  TermCalculatorRequest,
} from "@@intelease/web/grpc/dist/messages_pb";
import { has } from "lodash";

@Injectable({
  providedIn: "root",
})
export class AbstractionPageRequestPayloadService {
  TermCalculatorRequest(payload) {
    const { abstractUid, provisionFormUid } = payload;
    const req = new TermCalculatorRequest();
    req.setAbstractUid(abstractUid);
    req.setProvisionFormUid(provisionFormUid);
    return { data: req.toObject() };
  }

  RentScheduleCalculatorRequest(payload) {
    const { abstractUid, provisionformUid, additionalhtmlnamesList } = payload;
    const req = new RentScheduleCalculatorRequest();
    req.setAbstractUid(abstractUid);
    // req.setAdditionalHtmlNamesList(additionalhtmlnamesList);
    req.setProvisionFormUid(provisionformUid);
    const obj = {
      ...req.toObject(),
      additionalHtmlNames: additionalhtmlnamesList,
    };
    delete obj.additionalHtmlNamesList;
    return { data: obj };
  }

  RentScheduleCalculationRequest(payload) {
    const request = {};
    if (has(payload, "priceIndexUid")) {
      request["priceIndexUid"] = payload["priceIndexUid"];
    }
    return {
      data: {
        ...payload,
      },
    };
  }
}
