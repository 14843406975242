/* tslint:disable */
/* eslint-disable */
import { DomainIdWithPermissionGroupDtoModel } from "./domain-id-with-permission-group-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ShareEntityRequestModel")
export class ShareEntityRequestModel {
  @JsonProperty("domains", [DomainIdWithPermissionGroupDtoModel], true)
  domains?: Array<DomainIdWithPermissionGroupDtoModel> = [];
}
