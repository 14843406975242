import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PdfReaderService {
  private _selectedPage = 0;

  set selectedPage(value: number) {
    this._selectedPage = value;
  }

  get selectedPage() {
    return this._selectedPage;
  }

  isFirstPageLoaded = false;

  firstPageLoaded$ = new Subject<void>();
  onScrolledToTextHighlight$ = new Subject<void>();
}
