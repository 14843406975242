/* tslint:disable */
/* eslint-disable */
import { DomainIdDtoModel } from "./domain-id-dto-model";
import { ReminderScheduleDtoModel } from "./reminder-schedule-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ReminderDtoModel")
export class ReminderDtoModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdBy", String, true)
  createdBy?: string = "";

  @JsonProperty("deliveredAt", [Any], true)
  deliveredAt?: Array<any> = [];

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("mediumType", Any, true)
  mediumType?: "NOTIFICATION" | "EMAIL" = undefined;

  @JsonProperty("metadata", Any, true)
  metadata?: {
    [key: string]: {};
  } = {};

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("needsMoreDeliveries", Boolean, true)
  needsMoreDeliveries?: boolean = undefined;

  @JsonProperty("schedule", ReminderScheduleDtoModel, true)
  schedule?: ReminderScheduleDtoModel = undefined;

  @JsonProperty("targetDomains", [DomainIdDtoModel], true)
  targetDomains?: Array<DomainIdDtoModel> = [];

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
