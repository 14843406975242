/* tslint:disable */
/* eslint-disable */
import { FullStateViewModel } from "./full-state-view-model";
import { PdfProvisionPartialValDtoModel } from "./pdf-provision-partial-val-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MultiPdfProvFullValDtoModel")
export class MultiPdfProvFullValDtoModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("possibleCountries", [String], true)
  possibleCountries?: Array<string> = [];

  @JsonProperty("possibleCurrencies", [String], true)
  possibleCurrencies?: Array<string> = [];

  @JsonProperty("possibleFullStates", [FullStateViewModel], true)
  possibleFullStates?: Array<FullStateViewModel> = [];

  @JsonProperty("possibleStates", [String], true)
  possibleStates?: Array<string> = [];

  @JsonProperty("possibleUnits", [String], true)
  possibleUnits?: Array<string> = [];

  @JsonProperty("possibleValues", [String], true)
  possibleValues?: Array<string> = [];

  /**
   * the format of a structured value
   */

  @JsonProperty("provisionType", Any, true)
  provisionType?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";

  @JsonProperty("views", [PdfProvisionPartialValDtoModel], true)
  views?: Array<PdfProvisionPartialValDtoModel> = [];
}
