/* tslint:disable */
/* eslint-disable */
import { NamedSearchFieldModel } from "./named-search-field-model";
import { ProvisionInfoNamedSearchFieldDtoModel } from "./provision-info-named-search-field-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ReportFieldNamesResponseDtoModel")
export class ReportFieldNamesResponseDtoModel {
  @JsonProperty("fieldTypeToOperationAggregations", Any, true)
  fieldTypeToOperationAggregations?: {
    [key: string]: Array<
      "COUNT" | "UNIQUE_COUNT" | "RECORDS_COUNT" | "AVG" | "MAX" | "MIN" | "SUM"
    >;
  } = {};

  @JsonProperty("fieldTypeToRelations", Any, true)
  fieldTypeToRelations?: {
    [key: string]: Array<
      | "EXISTS"
      | "NOT_EXISTS"
      | "EQ"
      | "NEQ"
      | "LT"
      | "GT"
      | "LEQ"
      | "GEQ"
      | "IN"
      | "NOT_IN"
      | "CONTAIN"
      | "NOT_CONTAIN"
      | "FIELD_EXISTS"
      | "FIELD_NOT_EXISTS"
      | "FIELD_EQ"
      | "FIELD_NEQ"
      | "FIELD_CONTAIN"
      | "FIELD_NOT_CONTAIN"
    >;
  } = {};

  @JsonProperty("generalFields", [NamedSearchFieldModel], true)
  generalFields?: Array<NamedSearchFieldModel> = [];

  @JsonProperty(
    "provisionFields",
    [ProvisionInfoNamedSearchFieldDtoModel],
    true
  )
  provisionFields?: Array<ProvisionInfoNamedSearchFieldDtoModel> = [];
}
