import { Injectable } from "@angular/core";

import { select, Store } from "@ngrx/store";
import { QueuePartialState, RecordStatusType } from "./queue.reducer";
import * as queueQuery from "./queue.selectors";
import {
  LoadQueueDetails,
  LoadMyQueueList,
  LoadOthersQueueList,
  LoadUsableQueueList,
  AddRecordToQueueTabs,
  SearchRecordInQueueApply,
  ToggleSearchbar,
  OnSearchRecordInQueue,
  FailedRecord,
} from "./queue.actions";
import { QUEUE_STATUS } from "./models/queue.types";

@Injectable({
  providedIn: "root",
})
export class QueueFacade {
  currentQueueLoaded$ = this.store.pipe(select(queueQuery.getCurrentQueueInfo));

  currentQueuePermissions$ = this.store.pipe(
    select(queueQuery.currentQueuePermissions)
  );

  myQueueListLoaded$ = this.store.pipe(select(queueQuery.getMyQueueList));

  usableQueueListLoaded$ = this.store.pipe(
    select(queueQuery.getUsableQueueList)
  );

  OthersQueueListLoaded$ = this.store.pipe(
    select(queueQuery.getOthersQueueList)
  );

  getIsCurrentQueueLoaded$ = this.store.pipe(
    select(queueQuery.getIsCurrentQueueLoaded)
  );

  getQueueRecordsLoadedNumbers$ = this.store.pipe(
    select(queueQuery.getQueueRecordsLoadedNumbers)
  );

  getIsSeachActive$ = this.store.pipe(select(queueQuery.getIsSeachActive));

  searchAllRecords$ = this.store.pipe(select(queueQuery.autoCompleteSearch));

  getSearchText$ = this.store.pipe(select(queueQuery.getSearchText));

  myQueueListLoadStatus$ = this.store.pipe(
    select(queueQuery.myQueueListLoadStatus)
  );

  otherQueueListLoadStatus$ = this.store.pipe(
    select(queueQuery.othersQueueListLoadStatus)
  );

  constructor(private store: Store<QueuePartialState>) {}

  queueRecordsLoadedOfType$ = (recordStatusType: RecordStatusType) =>
    this.store.pipe(
      select(queueQuery.getQueueRecordsOfStatus(recordStatusType))
    );

  loadQueueDetails(queueUid: string) {
    this.store.dispatch(new LoadQueueDetails({ queueUid }));
  }

  loadMyQueueList() {
    this.store.dispatch(new LoadMyQueueList());
  }

  loadUsableQueueList() {
    this.store.dispatch(new LoadUsableQueueList());
  }

  loadOthersQueueList() {
    this.store.dispatch(new LoadOthersQueueList());
  }

  addRecordToQueueTabs(payload: {
    recordUid: string;
    recordName: string;
    queueUid: string;
    queueStatus: QUEUE_STATUS;
  }) {
    this.store.dispatch(new AddRecordToQueueTabs(payload));
  }

  searchRecordInQueue(searchText: string) {
    this.store.dispatch(new OnSearchRecordInQueue(searchText));
  }

  toggleSearchbar() {
    this.store.dispatch(new ToggleSearchbar());
  }

  failedRecord(recordUid: string) {
    this.store.dispatch(
      new FailedRecord({
        body: {
          data: {
            uid: recordUid,
          },
        },
      })
    );
  }
}
