/* tslint:disable */
/* eslint-disable */
import { UploadedDocumentResultDtoModel } from "./uploaded-document-result-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UploadedDocumentsResultDtoModel")
export class UploadedDocumentsResultDtoModel {
  @JsonProperty("items", [UploadedDocumentResultDtoModel], true)
  items?: Array<UploadedDocumentResultDtoModel> = [];
}
