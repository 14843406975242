import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { environment } from "../../../../../apps/ui/src/environments/environment";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[dataCy]",
})
export class DataCyDirective implements OnInit {
  @Input("dataCy") value: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (environment.production) {
      this.renderer.removeAttribute(this.el.nativeElement, "data-cy");
    } else {
      this.renderer.setAttribute(this.el.nativeElement, "data-cy", this.value);
    }
  }
}
