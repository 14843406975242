/* tslint:disable */
/* eslint-disable */
import { ExportMultipleNodesRequestModel } from "./export-multiple-nodes-request-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqExportMultipleNodesRequestModel")
export class OApiReqExportMultipleNodesRequestModel {
  @JsonProperty("data", ExportMultipleNodesRequestModel, true)
  data?: ExportMultipleNodesRequestModel = undefined;
}
