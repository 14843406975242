<div class="add-project-container">
  <h1 mat-dialog-title>
    {{ modalTitle }}
  </h1>
  <div mat-dialog-content>
    <div itlsPgFormGroupDefault style="border: none">
      <label> Choose a Provision to Add to this Abstract: </label>
      <ng-select
        [items]="possibleProvisions"
        appendTo="body"
        [multiple]="false"
        bindLabel="name"
        [(ngModel)]="newProvision"
      >
        <ng-template ng-label-tmp let-item="item">
          <itls-icon
            [svgIcon]="item.type | translateIconType"
            class="field-icon blue-icons"
          ></itls-icon>
          <span>{{ item.name }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <itls-icon
            [svgIcon]="item.type | translateIconType"
            class="field-icon gray-icons"
          ></itls-icon>
          <span>{{ item.name }}</span>
        </ng-template>
      </ng-select>
      <label> Choose Provision Category to Add to: </label>
      <ng-select
        [items]="existingProvisionGroups"
        [multiple]="false"
        appendTo="body"
        bindLabel="name"
        (change)="getPossiblePreProvisionsOfCategory()"
        [(ngModel)]="chosenProvisionGroup"
      >
      </ng-select>
      <div *ngIf="possiblePreProvisions && possiblePreProvisions.length > 0">
        <label> Choose Provision after which to Add: </label>
        <ng-select
          [items]="possiblePreProvisions"
          [multiple]="false"
          appendTo="body"
          bindLabel="provisionInfo.name"
          [(ngModel)]="preProvisionLocation"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div
    mat-dialog-actions
    style="display: flex; align-items: center; justify-content: space-around"
  >
    <button mat-button mat-dialog-close>Cancel</button>
    <span
      [matTooltip]="
        {
          newProvision: newProvision,
          chosenProvisionGroup: chosenProvisionGroup,
          preProvisionLocation: preProvisionLocation
        } | tooltipOnAddProvisionFields
      "
    >
      <button
        mat-button
        color="primary"
        (click)="onAddProvision()"
        [disabled]="
          {
            newProvision: newProvision,
            chosenProvisionGroup: chosenProvisionGroup,
            preProvisionLocation: preProvisionLocation
          } | validateOnAddProvisionFields
        "
      >
        Add
      </button>
    </span>
  </div>
</div>
