import { LightNodeModel } from "@@intelease/web/common/models";

export class ExportMultiNodeModel {
  private nodes: LightNodeModel[];

  private exportType:
    | "DOCX"
    | "XLSX"
    | "CSV"
    | "HTML"
    | "PDF"
    | "TSV"
    | "ZIP"
    | "WT_TSV";

  private includeNotes: boolean;

  private includeDocumentChronology: boolean;

  private includeSourceAttribution: boolean;

  private includeAnnotatedDocs: boolean;

  private excludeEmptyProvisions: boolean;

  private includeSectionHeaders: boolean;

  private emptyProvisionText: string;

  private specialExportType: "FULL" | "HISTORY" | undefined;

  constructor(
    nodes: LightNodeModel[],
    exportType:
      | "DOCX"
      | "XLSX"
      | "CSV"
      | "HTML"
      | "PDF"
      | "TSV"
      | "ZIP"
      | "WT_TSV",
    includeNotes: boolean,
    includeDocumentChronology: boolean,
    includeSourceAttribution: boolean,
    includeAnnotatedDocs: boolean,
    excludeEmptyProvisions: boolean,
    includeSectionHeaders: boolean,
    emptyProvisionText: string,
    specialExportType: "FULL" | "HISTORY"
  ) {
    this.nodes = nodes;
    this.exportType = exportType;
    this.includeNotes = includeNotes;
    this.includeDocumentChronology = includeDocumentChronology;
    this.includeSourceAttribution = includeSourceAttribution;
    this.includeAnnotatedDocs = includeAnnotatedDocs;
    this.excludeEmptyProvisions = excludeEmptyProvisions;
    this.includeSectionHeaders = includeSectionHeaders;
    this.emptyProvisionText = emptyProvisionText;
    this.specialExportType = specialExportType;
  }
}
