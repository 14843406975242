import { Injectable } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { TaskModalComponent } from "./task-modal.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class TaskModalService {
  constructor(private dialog: MatDialog) {}

  openNewTaskModal(modalData: ModalInputModel) {
    return this.dialog.open(TaskModalComponent, {
      data: modalData,
      minWidth: "500px",
    });
  }
}
