/* tslint:disable */
/* eslint-disable */
import { DoubleProvisionUnitAndCurrencyModel } from "./double-provision-unit-and-currency-model";

/**
 * the default units for a record or queue
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DefaultDataFieldUnitsModel")
export class DefaultDataFieldUnitsModel {
  /**
   * the currency for a monetary value
   */

  @JsonProperty("currency", Any, true)
  currency?:
    | "AFGHAN_AFGHANI"
    | "EURO"
    | "ALBANIAN_LEK"
    | "ALGERIAN_DINAR"
    | "ANGOLAN_KWANZA"
    | "EAST_CARIBBEAN_DOLLAR"
    | "ARGENTINE_PESO"
    | "ARMENIAN_DRAM"
    | "ARUBAN_FLORIN"
    | "AUSTRALIAN_DOLLAR"
    | "AZERBAIJANI_MANAT"
    | "BAHAMIAN_DOLLAR"
    | "BAHRAINI_DINAR"
    | "BANGLADESHI_TAKA"
    | "BARBADIAN_DOLLAR"
    | "NEW_BELARUSIAN_RUBLE"
    | "BELIZE_DOLLAR"
    | "WEST_AFRICAN_CFA_FRANC"
    | "BERMUDIAN_DOLLAR"
    | "BHUTANESE_NGULTRUM"
    | "BOLIVIAN_BOLIVIANO"
    | "UNITED_STATES_DOLLAR"
    | "BOSNIA_AND_HERZEGOVINA_CONVERTIBLE_MARK"
    | "BOTSWANA_PULA"
    | "BRAZILIAN_REAL"
    | "BRUNEI_DOLLAR"
    | "BULGARIAN_LEV"
    | "BURUNDIAN_FRANC"
    | "CAMBODIAN_RIEL"
    | "CENTRAL_AFRICAN_CFA_FRANC"
    | "CANADIAN_DOLLAR"
    | "CAPE_VERDEAN_ESCUDO"
    | "CAYMAN_ISLANDS_DOLLAR"
    | "CHILEAN_PESO"
    | "CHINESE_YUAN"
    | "COLOMBIAN_PESO"
    | "COMORIAN_FRANC"
    | "CONGOLESE_FRANC"
    | "NEW_ZEALAND_DOLLAR"
    | "COSTA_RICAN_COL\xF3N"
    | "CROATIAN_KUNA"
    | "CUBAN_CONVERTIBLE_PESO"
    | "NETHERLANDS_ANTILLEAN_GUILDER"
    | "CZECH_KORUNA"
    | "DANISH_KRONE"
    | "DJIBOUTIAN_FRANC"
    | "DOMINICAN_PESO"
    | "EGYPTIAN_POUND"
    | "ERITREAN_NAKFA"
    | "ETHIOPIAN_BIRR"
    | "FALKLAND_ISLANDS_POUND"
    | "FIJIAN_DOLLAR"
    | "CFP_FRANC"
    | "GAMBIAN_DALASI"
    | "GEORGIAN_LARI"
    | "GHANAIAN_CEDI"
    | "GIBRALTAR_POUND"
    | "GUATEMALAN_QUETZAL"
    | "GUINEAN_FRANC"
    | "GUYANESE_DOLLAR"
    | "HAITIAN_GOURDE"
    | "HONDURAN_LEMPIRA"
    | "HONG_KONG_DOLLAR"
    | "HUNGARIAN_FORINT"
    | "ICELANDIC_KR\xF3NA"
    | "INDIAN_RUPEE"
    | "INDONESIAN_RUPIAH"
    | "IRANIAN_RIAL"
    | "IRAQI_DINAR"
    | "ISRAELI_NEW_SHEKEL"
    | "JAMAICAN_DOLLAR"
    | "JAPANESE_YEN"
    | "JORDANIAN_DINAR"
    | "KAZAKHSTANI_TENGE"
    | "KENYAN_SHILLING"
    | "NORTH_KOREAN_WON"
    | "SOUTH_KOREAN_WON"
    | "KUWAITI_DINAR"
    | "KYRGYZSTANI_SOM"
    | "LAO_KIP"
    | "LATVIAN_LATS"
    | "LEBANESE_POUND"
    | "LESOTHO_LOTI"
    | "LIBERIAN_DOLLAR"
    | "LIBYAN_DINAR"
    | "SWISS_FRANC"
    | "MACANESE_PATACA"
    | "MACEDONIAN_DENAR"
    | "MALAGASY_ARIARY"
    | "MALAWIAN_KWACHA"
    | "MALAYSIAN_RINGGIT"
    | "MALDIVIAN_RUFIYAA"
    | "MAURITANIAN_OUGUIYA"
    | "MAURITIAN_RUPEE"
    | "MEXICAN_PESO"
    | "MOLDOVAN_LEU"
    | "MONGOLIAN_T\xF6GR\xF6G"
    | "MOROCCAN_DIRHAM"
    | "MOZAMBICAN_METICAL"
    | "BURMESE_KYAT"
    | "NAMIBIAN_DOLLAR"
    | "NEPALESE_RUPEE"
    | "NICARAGUAN_C\xF3RDOBA"
    | "NIGERIAN_NAIRA"
    | "TURKISH_LIRA"
    | "NORWEGIAN_KRONE"
    | "OMANI_RIAL"
    | "PAKISTANI_RUPEE"
    | "PANAMANIAN_BALBOA"
    | "PAPUA_NEW_GUINEAN_KINA"
    | "PARAGUAYAN_GUARAN\xED"
    | "PERUVIAN_SOL"
    | "PHILIPPINE_PESO"
    | "POLISH_Z\u0142OTY"
    | "QATARI_RIYAL"
    | "ROMANIAN_LEU"
    | "RUSSIAN_RUBLE"
    | "RWANDAN_FRANC"
    | "SAINT_HELENA_POUND"
    | "SAMOAN_T\u0101L\u0101"
    | "S\xE3O_TOM\xE9_AND_PR\xEDNCIPE_DOBRA"
    | "SAUDI_RIYAL"
    | "SERBIAN_DINAR"
    | "SEYCHELLOIS_RUPEE"
    | "SIERRA_LEONEAN_LEONE"
    | "SOLOMON_ISLANDS_DOLLAR"
    | "SOMALI_SHILLING"
    | "SOUTH_AFRICAN_RAND"
    | "BRITISH_POUND"
    | "SOUTH_SUDANESE_POUND"
    | "SRI_LANKAN_RUPEE"
    | "SUDANESE_POUND"
    | "SURINAMESE_DOLLAR"
    | "SWAZI_LILANGENI"
    | "SWEDISH_KRONA"
    | "SYRIAN_POUND"
    | "NEW_TAIWAN_DOLLAR"
    | "TAJIKISTANI_SOMONI"
    | "TANZANIAN_SHILLING"
    | "THAI_BAHT"
    | "TONGAN_PA\u02BBANGA"
    | "TRANSNISTRIAN_RUBLE"
    | "TRINIDAD_AND_TOBAGO_DOLLAR"
    | "TUNISIAN_DINAR"
    | "TURKMENISTAN_MANAT"
    | "UGANDAN_SHILLING"
    | "UKRAINIAN_HRYVNIA"
    | "UNITED_ARAB_EMIRATES_DIRHAM"
    | "URUGUAYAN_PESO"
    | "UZBEKISTANI_SOM"
    | "VANUATU_VATU"
    | "VENEZUELAN_BOL\xEDVAR"
    | "VIETNAMESE_\u0111\u1ED3NG"
    | "YEMENI_RIAL"
    | "ZAMBIAN_KWACHA"
    | "SINGAPORE_DOLLAR" = undefined;

  /**
   * the default specific unit for each numerical category
   */

  @JsonProperty("numericalCategoryToUnit", Any, true)
  numericalCategoryToUnit?: {
    [key: string]:
      | "SQUARE_FEET"
      | "SQUARE_METERS"
      | "PING"
      | "TSUBO"
      | "ACRES"
      | "HECTARES"
      | "SQUARE_KILOMETERS"
      | "SQUARE_MILES"
      | "CENTIMETERS"
      | "INCHES"
      | "FEET"
      | "YARDS"
      | "METERS"
      | "KILOMETERS"
      | "MILES"
      | "PERCENT"
      | "MONEY"
      | "SECONDS"
      | "MINUTES"
      | "HOURS"
      | "DAYS"
      | "WEEKS"
      | "MONTHS"
      | "YEARS"
      | "METERS_PER_SECOND"
      | "METERS_PER_SECOND_PER_SECOND"
      | "GRAMS"
      | "CUBIC_CENTIMETERS"
      | "GRAMS_PER_CUBIC_CENTIMETER"
      | "VOLTS"
      | "AMPERES"
      | "PASCALS"
      | "INCHES_WATER"
      | "POUNDS_PER_SQUARE_INCH"
      | "ATMOSPHERES"
      | "KELVIN"
      | "CELSIUS"
      | "FAHRENHEIT"
      | "NONE";
  } = {};

  /**
   * the default units for specific provisions (can override global defaults)
   */

  @JsonProperty("provisionHtmlNameToUnitAndCurrency", Any, true)
  provisionHtmlNameToUnitAndCurrency?: {
    [key: string]: DoubleProvisionUnitAndCurrencyModel;
  } = {};
}
