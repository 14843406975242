import { JsonObject, JsonProperty } from "json2typescript";
import { EntityFieldModel } from "@@intelease/web/intelease/models";
import { FilterModel } from "./filter.model";

@JsonObject("FilterDocQueryModel")
export class FilterDocQueryModel {
  @JsonProperty("viewCols", [EntityFieldModel])
  viewCols: EntityFieldModel[] = [];

  @JsonProperty("filters", [FilterModel])
  filters: FilterModel[] = [];
}
