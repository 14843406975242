/* tslint:disable */
/* eslint-disable */
import { NamedSearchFieldModel } from "./named-search-field-model";
import { SearchEntityItemModel } from "./search-entity-item-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FieldFilterInterfaceModel")
export class FieldFilterInterfaceModel {
  @JsonProperty("category", Any, true)
  category?: "GENERAL" | "PROVISION" = undefined;

  @JsonProperty("logical", Boolean, true)
  logical?: boolean = undefined;

  @JsonProperty("namedSearchField", NamedSearchFieldModel, true)
  namedSearchField?: NamedSearchFieldModel = undefined;

  @JsonProperty("relation", Any, true)
  relation?:
    | "EXISTS"
    | "NOT_EXISTS"
    | "EQ"
    | "NEQ"
    | "LT"
    | "GT"
    | "LEQ"
    | "GEQ"
    | "IN"
    | "NOT_IN"
    | "CONTAIN"
    | "NOT_CONTAIN"
    | "FIELD_EXISTS"
    | "FIELD_NOT_EXISTS"
    | "FIELD_EQ"
    | "FIELD_NEQ"
    | "FIELD_CONTAIN"
    | "FIELD_NOT_CONTAIN" = undefined;

  @JsonProperty("value", Any, true)
  value?: any = {};

  @JsonProperty("valueAsSearchEntityItem", SearchEntityItemModel, true)
  valueAsSearchEntityItem?: SearchEntityItemModel = undefined;
}
