import {
  Component,
  ComponentRef,
  Input,
  OnChanges,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { WidgetFactory } from "./widgetfactory";
import { TerminatorService } from "./terminator.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "sf-form-element-action",
  template: "<ng-template #target></ng-template>",
})
export class FormElementComponentActionComponent implements OnChanges {
  @Input()
  button: any;

  @Input()
  formProperty: any;

  @ViewChild("target", { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  private ref: ComponentRef<any>;

  constructor(
    private widgetFactory: WidgetFactory = null,
    private terminator: TerminatorService
  ) {
    this.terminator.onDestroy
      .pipe(takeUntilDestroyed())
      .subscribe((destroy) => {
        if (destroy) {
          this.ref.destroy();
        }
      });
  }

  ngOnChanges() {
    this.ref = this.widgetFactory.createWidget(
      this.container,
      this.button.widget || "button"
    );
    this.ref.instance.button = this.button;
    this.ref.instance.formProperty = this.formProperty;
  }
}
