import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { isPlatformServer } from "@angular/common";
import { Observable } from "rxjs";
import { LocalStorageKey } from "../../common/enums/local-storage.keys";

const exceptionList: Array<any> = ["/login"];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private readonly isServer: boolean;

  constructor(@Inject(PLATFORM_ID) platformId) {
    this.isServer = isPlatformServer(platformId);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = "";
    const { url } = request;
    let isUrlException = false;
    exceptionList.forEach((exception) => {
      if (url.includes(exception)) {
        isUrlException = true;
      }
    });

    if (!this.isServer && !isUrlException) {
      token = localStorage.getItem(LocalStorageKey.AUTH);
    }

    if (token) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token),
      });
    }

    if (!this.isFormDataRequest(request)) {
      request = request.clone({
        headers: request.headers.set("Content-Type", "application/json"),
      });
    }

    if (!request.headers.get("Accept")) {
      request = request.clone({
        headers: request.headers.set("Accept", "application/json"),
      });
    }
    return next.handle(request);
  }

  private isFormDataRequest(request: HttpRequest<any>): boolean {
    return !!(
      request.headers.get("Content-Type") ||
      (request.body && request.body.toString() === "[object FormData]")
    );
  }
}
