import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { isArray } from "lodash";
import { ReportLogicalOperatorEnum } from "@intelease/constants";

@Component({
  selector: "il-filter-group",
  templateUrl: "./itls-filter-group.component.html",
  styleUrls: ["./itls-filter-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsFilterGroupComponent {
  @Input() filterOperators = {};
  @Input() filter: { operator: ReportLogicalOperatorEnum; value: any[] };
  @Input() level;
  colors: string[] = [
    "#efefef",
    "rgba(0, 0, 0, 0.02)",
    "#efefef",
    "#ebebeb",
    "#efefef",
    "rgba(0, 0, 0, 0.02)",
    "#efefef",
    "#ebebeb",
    "#efefef",
    "rgba(0, 0, 0, 0.02)",
    "#efefef",
    "#ebebeb",
    "#efefef",
    "rgba(0, 0, 0, 0.02)",
    "#efefef",
    "#ebebeb",
  ];
  operations: any[] = [
    {
      value: ReportLogicalOperatorEnum.AND,
      viewValue: ReportLogicalOperatorEnum.AND,
    },
    // TODO(apoorv): Re-add OR once we support it.
    // { value: ReportLogicalOperatorEnum.OR, viewValue: ReportLogicalOperatorEnum.OR },
  ];
  @Input() filterFields: {
    fieldName: string;
    fieldType: string;
    uiName: string;
    category: string;
    docSetCategories: string[];
  }[] = [];
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

  onRemoveFilter(evt, filterIndex: number) {
    this.filter.value.splice(filterIndex, 1);
    this.modelChange.emit(this.filter);
  }

  onFilterGroupModelChange(evt) {
    if (evt.value.length === 0) {
      // find the empty group filter and remove it
      const emptyGroupFilterIndex = this.filter.value.findIndex(
        (item) => isArray(item.value) && item.value.length === 0
      );
      if (emptyGroupFilterIndex === -1) {
        console.log(
          `ERROR: why we couldn't find the empty group filter! (${JSON.stringify(
            this.filter
          )})`
        );
        this.modelChange.emit(evt);
      } else {
        this.filter.value.splice(emptyGroupFilterIndex, 1);
        this.modelChange.emit(this.filter);
      }
    } else {
      this.modelChange.emit(evt);
    }
  }

  onFilterItemModelChange(evt) {
    this.modelChange.emit(this.filter);
  }

  onFilterOperationChange() {
    this.modelChange.emit(this.filter);
  }

  onRemoveClick(filterIndex: number) {
    this.filter.value.splice(filterIndex, 1);
    this.modelChange.emit(this.filter);
  }

  onAddNewFilter() {
    this.filter.value.push({
      field: {},
      relation: "",
      value: "",
      // category: 'PROVISION'
    });
    this.modelChange.emit(this.filter);
  }

  onAddNewFilterGroup() {
    this.filter.value.push({
      operator: ReportLogicalOperatorEnum.AND,
      value: [
        {
          field: {},
          relation: "",
          value: "",
          // category: 'PROVISION'
        },
      ],
    });
    this.modelChange.emit(this.filter);
  }
}
