/* tslint:disable */
/* eslint-disable */
import { StageCompletionInfoDtoModel } from "./stage-completion-info-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("QueueInfoDtoModel")
export class QueueInfoDtoModel {
  @JsonProperty("firstReview", StageCompletionInfoDtoModel, true)
  firstReview?: StageCompletionInfoDtoModel = undefined;

  /**
   * the name of the queue, could be blank sometimes
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("recordProcessed", StageCompletionInfoDtoModel, true)
  recordProcessed?: StageCompletionInfoDtoModel = undefined;

  @JsonProperty("recordTouched", StageCompletionInfoDtoModel, true)
  recordTouched?: StageCompletionInfoDtoModel = undefined;

  @JsonProperty("secondReview", StageCompletionInfoDtoModel, true)
  secondReview?: StageCompletionInfoDtoModel = undefined;

  /**
   * the stage of the queue
   */

  @JsonProperty("stage", Any, true)
  stage?:
    | "PROCESSING"
    | "READY"
    | "IN_REVIEW"
    | "NEED_QA_REVIEW"
    | "IN_QA_REVIEW"
    | "COMPLETED"
    | "FAILED" = undefined;

  /**
   * the user-facing unique queue id
   */

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
