import { FormNameAbstractModel } from "@@intelease/web/common/models/doc-abstract/form-name-abstract.model";
import { createReducer, on } from "@ngrx/store";
import {
  GetDocumentsFailed,
  SetDocuments,
  SetFormNameAbstractModel,
  SetPermission,
  UpdateDocument,
  UpdateLastModifiedDate,
} from "./individual-abstract.actions";
import { RelatedDocumentModel } from "@@intelease/api-models/adex-api-model-src";
import { isEqual, map } from "lodash";

export const INDIVIDUALABSTRACT_FEATURE_KEY = "individualAbstract";
export interface IndividualAbstractState {
  permissions?: string[];
  formNameAbstractModel?: FormNameAbstractModel;
  documents?: Array<RelatedDocumentModel>;
}

export interface IndividualAbstractPartialState {
  readonly [INDIVIDUALABSTRACT_FEATURE_KEY]: IndividualAbstractState;
}

export const initialIndividualAbstractState: IndividualAbstractState = {
  permissions: undefined,
};

export const reducerIndividualAbstract = createReducer(
  initialIndividualAbstractState,
  on(SetPermission, (state: IndividualAbstractState, action) => ({
    ...state,
    permissions: action.permissions,
  })),
  on(SetFormNameAbstractModel, (state: IndividualAbstractState, action) => ({
    ...state,
    formNameAbstractModel: action.formNameAbstractModel,
  })),
  on(UpdateLastModifiedDate, (state: IndividualAbstractState) => ({
    ...state,
    formNameAbstractModel: {
      ...state.formNameAbstractModel,
      lastModifiedDate: new Date(),
    },
  })),
  on(SetDocuments, (state: IndividualAbstractState, action) => ({
    ...state,
    documents: action.documents,
  })),
  on(GetDocumentsFailed, (state: IndividualAbstractState) => ({
    ...state,
    documents: undefined,
  })),
  on(UpdateDocument, (state: IndividualAbstractState, action) => ({
    ...state,
    documents: map(state.documents, (document) => {
      if (isEqual(document.uid, action.document.uid)) {
        return action.document;
      }
      return document;
    }),
  }))
);
