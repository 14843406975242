/* tslint:disable */
/* eslint-disable */
import { OApiReturnParamsRequestProvisionGroupViewTypeModel } from "./o-api-return-params-request-provision-group-view-type-model";
import { ProvisionFormDtoModel } from "./provision-form-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqEditProvisionFormDtoModel")
export class OApiReqEditProvisionFormDtoModel {
  @JsonProperty("data", ProvisionFormDtoModel, true)
  data?: ProvisionFormDtoModel = undefined;

  @JsonProperty(
    "returnParams",
    OApiReturnParamsRequestProvisionGroupViewTypeModel,
    true
  )
  returnParams?: OApiReturnParamsRequestProvisionGroupViewTypeModel = undefined;
}
