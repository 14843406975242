import { JsonObject, JsonProperty } from "json2typescript";
import { UploadLeaseResponseItemModel } from "@@intelease/web/ui/src/lib/itls-new-upload/models/upload-lease-response-item.model";

@JsonObject("UploadLeaseResponseModel")
export class UploadLeaseResponseModel {
  @JsonProperty("selectedDirectoryUid", String, true)
  selectedDirectoryUid: string = undefined;

  @JsonProperty("items", [UploadLeaseResponseItemModel])
  items: UploadLeaseResponseItemModel[] = [];
}
