export class SettingItemKeys {
  public static EMAIL_NOTIFICATION_FOR_TASKS = "EMAIL_NOTIFICATION_FOR_TASKS";

  public static EMAIL_NOTIFICATION_FOR_DOCSET_ABSTRACTION =
    "EMAIL_NOTIFICATION_FOR_DOCSET_ABSTRACTION";

  public static ALLOW_TEXT_PROVISION_MENTIONS = "ALLOW_TEXT_PROVISION_MENTIONS";

  public static INCLUDE_ANNOTATED_DOCS = "INCLUDE_ANNOTATED_DOCS";

  public static INCLUDE_NOTES = "INCLUDE_NOTES";

  public static EXCLUDE_EMPTY_PROVISIONS = "EXCLUDE_EMPTY_PROVISIONS";

  public static INCLUDE_SECTION_HEADER = "INCLUDE_SECTION_HEADER";

  public static INCLUDE_SOURCE_ATTRIBUTION = "INCLUDE_SOURCE_ATTRIBUTION";

  public static EMPTY_PROVISION_TEXT = "EMPTY_PROVISION_TEXT";
  public static FORCE_QUEUE_FOR_UPLOAD = "FORCE_QUEUE_FOR_UPLOAD";
  public static SKIP_TASK_CREATION_FROM_QUEUE = "SKIP_TASK_CREATION_FROM_QUEUE";
  public static ALLOW_FULL_EXPORT = "ALLOW_FULL_EXPORT";
  public static DISABLE_CREATE_PROVISION = "DISABLE_CREATE_PROVISION";
  public static SKIP_MENTION_AND_SUBFIELD_SELECTIONS_ON_HIGHLIGHT =
    "SKIP_MENTION_AND_SUBFIELD_SELECTIONS_ON_HIGHLIGHT";
}
