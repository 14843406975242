<div [class]="'input-dropdown-container '">
  <input
    [disabled]="inputDisabled"
    (change)="onChange($event, eventSource.INPUT)"
    [(ngModel)]="model.input"
    type="number"
    class="form-control"
    [style.width]="inputWidth"
    [digitsOnly]="digitsOnly"
  />
  <ng-select
    (change)="onChange($event, eventSource.SELECT1)"
    (clear)="onClear()"
    [(ngModel)]="model.dropdown1"
    [items]="items1"
    class="dropdown"
    [appendTo]="'body'"
    [style.width]="selectWidth1"
  ></ng-select>
  <ng-select
    (change)="onChange($event, eventSource.SELECT2)"
    (clear)="onClear()"
    [(ngModel)]="model.dropdown2"
    [items]="items2"
    class="dropdown"
    [appendTo]="'body'"
    [style.width]="selectWidth2"
  ></ng-select>
</div>
