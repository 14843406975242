import { Injectable } from "@angular/core";
import { TitleValueModel } from "@@intelease/app-models/common";
import { AuthService } from "@@intelease/app-services/auth";
import { UserInfoService } from "@intelease/services";
import { forOwn } from "lodash";
import { ServerConfigsService } from "@@intelease/app-services/common";
import { CommonFacade } from "@@intelease/app-state/common";

@Injectable({
  providedIn: "root",
})
export class ItlsFilterWidgetService {
  private accountDocSetCategories: TitleValueModel[];
  private htmlNameToSingleCatProvisionPossibleValues: Map<
    string,
    TitleValueModel[]
  >;

  constructor(
    private authService: AuthService,
    private serverConfigsService: ServerConfigsService,
    private commonFacade: CommonFacade
  ) {
    authService.onLogin().subscribe(() => this.reloadData());

    this.reloadData();

    this.commonFacade.getProvisionsMetadata().subscribe((provisionMetadata) => {
      this.htmlNameToSingleCatProvisionPossibleValues = new Map<
        string,
        TitleValueModel[]
      >();
      forOwn(
        provisionMetadata.singleCatProvHtmlNameToPossibleValues,
        (values, key) => {
          this.htmlNameToSingleCatProvisionPossibleValues.set(
            key,
            values?.map((value) => new TitleValueModel(value, value))
          );
        }
      );
    });
  }

  private reloadData(): void {
    this.accountDocSetCategories = [];

    if (UserInfoService.getDocSetCategories()) {
      this.accountDocSetCategories.push(
        ...UserInfoService.getDocSetCategories().map(
          (item) => new TitleValueModel(item.uiName, item.value)
        )
      );
    }
  }

  getAccountDocSetCategories() {
    return this.accountDocSetCategories;
  }

  getPossibleValuesForSingleCatProvision(field?: string): TitleValueModel[] {
    let possibleValues: TitleValueModel[];
    if (field) {
      possibleValues =
        this.htmlNameToSingleCatProvisionPossibleValues?.get(field);
    }
    return possibleValues;
  }
}
