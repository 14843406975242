import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("CommenterModel")
export class CommenterModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("profileImageFileRegistered", Boolean)
  hasProfileImage = false;

  constructor(uid: string, name: string) {
    this.uid = uid;
    this.name = name;
  }
}
