import { NgModule } from "@angular/core";
import { TaskListRoutingModule } from "./task-list-routing.module";
import { TaskListNoRoutingModule } from "@@intelease/web/tasks/src/lib/task-list/task-list-no-routing.module";
import { PermissionsGuard } from "@@intelease/web/intelease/guards";

@NgModule({
  imports: [TaskListNoRoutingModule, TaskListRoutingModule],
  declarations: [],
  providers: [PermissionsGuard],
})
export class TaskListModule {}
