import { Injectable } from "@angular/core";

import { select, Store } from "@ngrx/store";

import { CommonPartialState } from "./common.reducer";
import { commonQuery } from "./common.selectors";
import {
  AccountCustomTagsLoaded,
  AccountSettingsLoaded,
  LoadAccountCustomTags,
  LoadAccountSettings,
  LoadCommon,
  LoadProvisionsMetadata,
  ProvisionsMetadataLoaded,
  UpdateAccountSettings,
  UpdateProvisionsMetadata,
} from "./common.actions";
import { map } from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import { firstNotNill } from "@@intelease/web/utils/rxjs-operators";
import { keys } from "lodash";

@Injectable({
  providedIn: "root",
})
export class CommonFacade {
  loaded$ = this.store.pipe(select(commonQuery.getLoaded));
  allCommon$ = this.store.pipe(select(commonQuery.getAllCommon));
  selectedCommon$ = this.store.pipe(select(commonQuery.getSelectedCommon));
  isAccountSettingsLoaded$ = this.store.pipe(
    select(commonQuery.isAccountSettingsLoaded)
  );
  getAccountSettings$ = this.store.pipe(select(commonQuery.getAccountSettings));
  isUpdateAccountSettingsSucceeded$ = this.store.pipe(
    select(commonQuery.isUpdateAccountSettingsSucceeded)
  );
  isProvisionsMetadataLoaded$ = this.store.pipe(
    select(commonQuery.isProvisionsMetadataLoaded)
  );

  getAccountCustomTags$ = this.store.pipe(
    select(commonQuery.getAccountCustomTags)
  );

  getProvisionsMetadataByKey$ = (key: string) =>
    this.store.pipe(select(commonQuery.getProvisionsMetadataByKey, { key }));

  getAccountSettingByKey$ = (key: string) =>
    this.store.pipe(select(commonQuery.getAccountSettingByKey, { key }));

  getAccountSettingValueByKey$ = (key: string) =>
    this.store.pipe(
      select(commonQuery.getAccountSettingByKey, { key }),
      map((accountSetting) => accountSetting?.value)
    );

  constructor(private store: Store<CommonPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadCommon());
  }

  loadProvisionsMetadata(payload) {
    this.store.dispatch(new LoadProvisionsMetadata(payload));
  }

  updateProvisionsMetadata() {
    this.store.dispatch(new UpdateProvisionsMetadata());
  }

  getProvisionsMetadata(): Observable<any> {
    return combineLatest([
      this.store.pipe(select(commonQuery.getProvisionsMetadata)),
      this.store.pipe(select(commonQuery.isProvisionsMetadataLoaded)),
    ]).pipe(
      map(([provisionsMetadata, isProvisionsMetadataLoaded]) => {
        if (isProvisionsMetadataLoaded) {
          if (
            keys(provisionsMetadata).includes("countries") &&
            keys(provisionsMetadata).includes("currencies") &&
            keys(provisionsMetadata).includes("numericalUnitCategories") &&
            keys(provisionsMetadata).includes("properNounTypes") &&
            keys(provisionsMetadata).includes(
              "singleCatProvHtmlNameToPossibleValues"
            ) &&
            keys(provisionsMetadata).includes("states") &&
            keys(provisionsMetadata).includes("types") &&
            keys(provisionsMetadata).includes("units")
          )
            return provisionsMetadata;
          return this.updateProvisionsMetadata();
        }
      }),
      firstNotNill()
    );
  }

  provisionsMetadataLoaded(payload) {
    this.store.dispatch(new ProvisionsMetadataLoaded(payload));
  }

  private loadAccountSettings(payload) {
    this.store.dispatch(new LoadAccountSettings(payload));
  }

  accountSettingsLoaded(payload) {
    this.store.dispatch(new AccountSettingsLoaded(payload));
  }

  updateAccountSettings(uid: string, payload) {
    this.store.dispatch(new UpdateAccountSettings(uid, payload));
  }

  getAccountSettings() {
    return this.store.pipe(select(commonQuery.getAccountSettings)).pipe(
      map((res) => {
        if (!res) {
          this.loadAccountSettings({});
        }
        return res;
      })
    );
  }

  private loadAccountCustomTags(payload) {
    this.store.dispatch(new LoadAccountCustomTags(payload));
  }

  accountCustomTagsLoaded(payload) {
    this.store.dispatch(new AccountCustomTagsLoaded(payload));
  }

  getAccountCustomTags() {
    return this.store.pipe(select(commonQuery.getAccountCustomTags)).pipe(
      map((res) => {
        if (!res) {
          this.loadAccountCustomTags({});
        }
        return res;
      })
    );
  }
}
