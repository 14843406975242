import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { ProvisionGroupNewModel } from "@@intelease/web/common/models/doc-abstract/abstract-part";

@JsonObject("MultiProvisionValuesAbstractModel")
export class MultiProvisionValuesAbstractModel {
  static view = "multiProvisionValues";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("provisionGroups", [ProvisionGroupNewModel])
  provisionGroups: ProvisionGroupNewModel[] = [];

  @JsonProperty("abstractFilename", String)
  name = "";

  @JsonProperty("abstractName", String)
  abstractName = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("status", String)
  status = "";
}
