/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";

/**
 * the request to calculate rents
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CalculateRentsRequestModel")
export class CalculateRentsRequestModel {
  /**
   * the base values for the first row of the table
   */

  @JsonProperty("baseValues", [Number])
  baseValues: Array<number> = [];

  @JsonProperty("commencementDate", SimpleOpenApiDateModel)
  commencementDate: SimpleOpenApiDateModel = undefined;

  /**
   * Multiplier that should be a fraction >= 0 (1.0 is default)
   */

  @JsonProperty("effectivenessRate", Number, true)
  effectivenessRate?: number = undefined;

  /**
   * if given, the escalation period (in months)
   */

  @JsonProperty("escalationPeriodMonths", Number, true)
  escalationPeriodMonths?: number = undefined;

  /**
   * if given, the escalation period (in years)
   */

  @JsonProperty("escalationPeriodYears", Number, true)
  escalationPeriodYears?: number = undefined;

  @JsonProperty("expirationDate", SimpleOpenApiDateModel)
  expirationDate: SimpleOpenApiDateModel = undefined;

  /**
   * the fixed escalation rate (0.05 means 5%)
   */

  @JsonProperty("fixedEscalationRate", Number, true)
  fixedEscalationRate?: number = undefined;

  /**
   * the maximum number of past points to consider for future estimate (>= 2)
   */

  @JsonProperty("numPastPointsForFutureEstimate", Number, true)
  numPastPointsForFutureEstimate?: number = undefined;

  /**
   * the user-facing unique id of the price index
   */

  @JsonProperty("priceIndexUid", String, true)
  priceIndexUid?: string = "";
}
