import { Injectable } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import {
  AddProvisionFormModalComponent,
  MyDocumentsAddFilterModalComponent,
  MyDocumentsMoveFolderModalComponent,
  MyDocumentsNewFolderModalComponent,
} from "../modals";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class ProvisionFormsModalService {
  static openNewFormModal$: Subject<void> = new Subject<void>();

  constructor(private dialog: MatDialog) {}

  openNewFolderModal(modalData: ModalInputModel) {
    return this.dialog.open(MyDocumentsNewFolderModalComponent, {
      data: modalData,
    });
  }

  openMoveFolderModal(modalData: ModalInputModel) {
    return this.dialog.open(MyDocumentsMoveFolderModalComponent, {
      data: modalData,
    });
  }

  openAddFilterModal(modalData: ModalInputModel) {
    return this.dialog.open(MyDocumentsAddFilterModalComponent, {
      data: modalData,
    });
  }

  openAddProvisionFormModal(modalData: ModalInputModel) {
    return this.dialog.open(AddProvisionFormModalComponent, {
      data: modalData,
      minWidth: "500px",
      autoFocus: false,
    });
  }
}
