import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { fetch, pessimisticUpdate } from "@ngrx/router-store/data-persistence";
import {
  LoadCommon,
  CommonLoaded,
  CommonLoadError,
  CommonActionTypes,
  LoadProvisionsMetadata,
  ProvisionsMetadataLoaded,
  ProvisionsMetadataLoadError,
  LoadAccountSettings,
  AccountSettingsLoaded,
  AccountSettingsLoadError,
  UpdateAccountSettings,
  UpdateAccountSettingsSucceeded,
  UpdateAccountSettingsFailed,
  LoadAccountCustomTags,
  AccountCustomTagsLoaded,
  AccountCustomTagsLoadError,
} from "./common.actions";
import { InteleaseCommonService } from "@@intelease/app-services/common";
import { map } from "rxjs/operators";
import {
  AccountService,
  OApiRespCustomTagsViewModel,
  ProvisionService,
} from "@@intelease/api-models/adex-api-model-src";
import { OApiRespProvisionsMetadataV2Model } from "@@intelease/api-models/adex-api-model-src/models/o-api-resp-provisions-metadata-v-2-model";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";

@Injectable()
export class CommonEffects {
  loadCommon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActionTypes.LoadCommon),
      fetch({
        run: () => {
          // Your custom REST 'load' logic goes here. For now just return an empty list...
          return new CommonLoaded([]);
        },

        onError: (action: LoadCommon, error) => {
          //eslint-disable-line
          console.error("Error", error);
          return new CommonLoadError(error);
        },
      })
    )
  );

  loadProvisionsMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActionTypes.LoadProvisionsMetadata),
      fetch({
        run: () => {
          return this.provisionService.getProvisionMetadatasV2({}).pipe(
            map((res: OApiRespProvisionsMetadataV2Model) => {
              const data = Json2TypescriptHelper.convertToEntity(
                res,
                OApiRespProvisionsMetadataV2Model
              ).data;
              return new ProvisionsMetadataLoaded(data);
            })
          );
        },

        onError: (action: LoadProvisionsMetadata, error) => {
          //eslint-disable-lint
          return new ProvisionsMetadataLoadError(error);
        },
      })
    )
  );

  updateProvisionsMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActionTypes.UpdateProvisionsMetadata),
      fetch({
        run: () => {
          return this.provisionService.getProvisionMetadatasV2({}).pipe(
            map((res: OApiRespProvisionsMetadataV2Model) => {
              const data = Json2TypescriptHelper.convertToEntity(
                res,
                OApiRespProvisionsMetadataV2Model
              ).data;
              return new ProvisionsMetadataLoaded(data);
            })
          );
        },

        onError: (action: LoadProvisionsMetadata, error) => {
          //eslint-disable-lint
          return new ProvisionsMetadataLoadError(error);
        },
      })
    )
  );

  loadAccountSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActionTypes.LoadAccountSettings),
      fetch({
        run: () => {
          return this.inteleaseCommonService
            .getAccountSettings()
            .pipe(map((res: any) => new AccountSettingsLoaded(res.data)));
        },

        onError: (action: LoadAccountSettings, error) => {
          //eslint-disable-lint
          return new AccountSettingsLoadError(error);
        },
      })
    )
  );

  updateAccountSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActionTypes.UpdateAccountSettings),
      pessimisticUpdate({
        run: (action: UpdateAccountSettings) => {
          const { uid, payload } = action;
          return this.inteleaseCommonService
            .updateAccountSettingsById(uid, payload)
            .pipe(map((res) => new UpdateAccountSettingsSucceeded(res)));
        },
        onError: (action: UpdateAccountSettings, error) => {
          //eslint-disable-line
          return new UpdateAccountSettingsFailed(error);
        },
      })
    )
  );

  loadAccountCustomTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActionTypes.LoadAccountCustomTags),
      fetch({
        run: () => {
          return this.accountService
            .getCustomTags()
            .pipe(
              map(
                (res: OApiRespCustomTagsViewModel) =>
                  new AccountCustomTagsLoaded(res.data.items)
              )
            );
        },

        onError: (action: LoadAccountCustomTags, error) => {
          return new AccountCustomTagsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private inteleaseCommonService: InteleaseCommonService,
    private accountService: AccountService,
    private provisionService: ProvisionService
  ) {}
}
