/* tslint:disable */
/* eslint-disable */
import { ProvisionMentionDtoModel } from "./provision-mention-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MultiPdfProvisionDtoModel")
export class MultiPdfProvisionDtoModel {
  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("mentions", [ProvisionMentionDtoModel], true)
  mentions?: Array<ProvisionMentionDtoModel> = [];

  @JsonProperty("provisionInfoUid", String, true)
  provisionInfoUid?: string = "";

  /**
   * the format of a structured value
   */

  @JsonProperty("provisionType", Any, true)
  provisionType?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;
}
