import { MatTooltipDefaultOptions } from "@angular/material/tooltip";

export const matTooltipOtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchGestures: "auto",
  position: "below",
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};
