import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("MinimalGroupModel")
export class MinimalTeamModel {
  static view = "minimal";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("description", String)
  description = "";
}
