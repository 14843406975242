/* tslint:disable */
/* eslint-disable */
import { FullValMultiPdfProvViewModel } from "./full-val-multi-pdf-prov-view-model";
import { ModifiedProvisionMentionUidDtoModel } from "./modified-provision-mention-uid-dto-model";
import { ProvisionInconsistenciesApiDtoModel } from "./provision-inconsistencies-api-dto-model";
import { UpdatedHighlightInfoApiDtoModel } from "./updated-highlight-info-api-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MultiPdfProvViewAndDocHighlightInfoApiDtoModel")
export class MultiPdfProvViewAndDocHighlightInfoApiDtoModel {
  @JsonProperty("docAbstractIdToHighlightInfo", Any, true)
  docAbstractIdToHighlightInfo?: {
    [key: string]: UpdatedHighlightInfoApiDtoModel;
  } = {};

  @JsonProperty(
    "modifiedProvisionMentionUids",
    ModifiedProvisionMentionUidDtoModel,
    true
  )
  modifiedProvisionMentionUids?: ModifiedProvisionMentionUidDtoModel = undefined;

  @JsonProperty("multiPdfProv", FullValMultiPdfProvViewModel, true)
  multiPdfProv?: FullValMultiPdfProvViewModel = undefined;

  @JsonProperty(
    "provisionInconsistencies",
    ProvisionInconsistenciesApiDtoModel,
    true
  )
  provisionInconsistencies?: ProvisionInconsistenciesApiDtoModel = undefined;

  @JsonProperty("realValue", Boolean, true)
  realValue?: boolean = undefined;
}
