/* tslint:disable */
/* eslint-disable */
import { ChatGptConfigModel } from "./chat-gpt-config-model";
import { ObjectIdModel } from "./object-id-model";
import { ProvisionInfoModel } from "./provision-info-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionInfoGroupModel")
export class ProvisionInfoGroupModel {
  @JsonProperty("&#x27;_id&#x27;", ObjectIdModel, true)
  "_id"?: ObjectIdModel = undefined;

  @JsonProperty("accountId", ObjectIdModel, true)
  accountId?: ObjectIdModel = undefined;

  @JsonProperty("anchorProvisionId", ObjectIdModel, true)
  anchorProvisionId?: ObjectIdModel = undefined;

  @JsonProperty("chatGptConfig", ChatGptConfigModel, true)
  chatGptConfig?: ChatGptConfigModel = undefined;

  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdBy", ObjectIdModel, true)
  createdBy?: ObjectIdModel = undefined;

  @JsonProperty("creatorPermissionReduced", Boolean, true)
  creatorPermissionReduced?: boolean = undefined;

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("docSetCategory", String, true)
  docSetCategory?: string = "";

  @JsonProperty("flags", Any, true)
  flags?: Array<
    | "ABSTRACT_TEMPLATE_GENERATOR_INTELEASE"
    | "REAL_ESTATE_LEASE_DOCUMENT_TYPE"
    | "REAL_ESTATE_PURCHASE_SALES_AGREEMENT_DOCUMENT_TYPE"
    | "REAL_ESTATE_TITLE_INSURANCE_DOCUMENT_TYPE"
    | "REAL_ESTATE_INSURANCE_CERTIFICATE_DOCUMENT_TYPE"
    | "REAL_ESTATE_MORTGAGE_DOCUMENT_TYPE"
    | "REAL_ESTATE_LOAN_AGREEMENT_DOCUMENT_TYPE"
    | "CORPORATE_CREDIT_AGREEMENT_DOCUMENT_TYPE"
    | "CORPORATE_LOAN_AGREEMENT_DOCUMENT_TYPE"
    | "CORPORATE_MORTGAGE_AGREEMENT_DOCUMENT_TYPE"
  > = [];

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("parent", ObjectIdModel, true)
  parent?: ObjectIdModel = undefined;

  @JsonProperty("provisionGroups", [ProvisionInfoGroupModel], true)
  provisionGroups?: Array<ProvisionInfoGroupModel> = [];

  @JsonProperty("provisionInfos", [ProvisionInfoModel], true)
  provisionInfos?: Array<ProvisionInfoModel> = [];

  @JsonProperty("source", Any, true)
  source?:
    | "ADMIN_FORM_CREATE"
    | "USER_FORM_CREATE"
    | "USER_FORM_MODIFY"
    | "IMPLICIT_FORM_ADD"
    | "FREE_FORM_CREATE" = undefined;

  @JsonProperty("status", Any, true)
  status?: "ACTIVE" | "DEACTIVE" = undefined;

  @JsonProperty("updatedAt", DateTimeConvertService, true)
  updatedAt?: Date | undefined = undefined;

  @JsonProperty("updatedBy", ObjectIdModel, true)
  updatedBy?: ObjectIdModel = undefined;

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
