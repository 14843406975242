import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { map } from "rxjs/operators";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";

@Injectable({
  providedIn: "root",
})
export class EditApiService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Send a PUT request to modify an existing entity in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including final uid)
   * @param uid the final uid at end of url, representing the object to be edited
   * @param data the data used for editing the object
   * @param view the view of the object to return
   * @param replace whether the provided data fully replaces this object's value
   * @param classRef the class of the returned object
   * @param afterUidUrl the part of the url after the final uid
   * @return the edited object
   */
  sendRequest<T extends object>(
    apiVersion: string,
    baseUrl: string,
    uid: string,
    data: any,
    replace: boolean,
    view: string,
    classRef: new () => T,
    afterUidUrl?: string
  ): Observable<T> {
    const body = this.createEditRequestBody(data, replace, view);
    if (afterUidUrl) {
      return this.helpSendRequest(
        body,
        apiVersion,
        baseUrl,
        uid,
        classRef,
        afterUidUrl
      );
    }
    return this.helpSendRequest(body, apiVersion, baseUrl, uid, classRef);
  }

  /**
   * Send a PUT request to modify an existing entity in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including the final uid)
   * @param uid the final uid at the end of the url, representing the object to be edited
   * @param data the data used for editing the object
   * @param afterUidUrl the part of the url after the final uid
   * @param replace whether the provided data fully replaces this object's value
   */
  sendRequestNoResponseWithSubscribe(
    apiVersion: string,
    baseUrl: string,
    uid: string,
    data: any,
    replace: boolean,
    afterUidUrl?: string
  ): Observable<void> {
    let response;
    if (afterUidUrl) {
      response = this.sendRequestNoView(
        apiVersion,
        baseUrl,
        uid,
        data,
        replace,
        afterUidUrl
      );
    } else {
      response = this.sendRequestNoView(
        apiVersion,
        baseUrl,
        uid,
        data,
        replace
      );
    }
    return response;
  }

  /**
   * Send a PUT request to modify an existing entity in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including the final uid)
   * @param uid the final uid at the end of the url, representing the object to be edited
   * @param data the data used for editing the object
   * @param afterUidUrl the part of the url after the final uid
   * @param replace whether the provided data fully replaces this object's value
   */
  sendRequestNoResponse(
    apiVersion: string,
    baseUrl: string,
    uid: string,
    data: any,
    replace: boolean,
    afterUidUrl?: string
  ): void {
    let response;
    if (afterUidUrl) {
      response = this.sendRequestNoView(
        apiVersion,
        baseUrl,
        uid,
        data,
        replace,
        afterUidUrl
      );
    } else {
      response = this.sendRequestNoView(
        apiVersion,
        baseUrl,
        uid,
        data,
        replace
      );
    }
    response.subscribe((res) => {});
  }

  /**
   * Send a synchronous PUT request to modify an existing entity in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including the final uid)
   * @param uid the final uid at the end of the url, representing the object to be edited
   * @param data the data used for editing the object
   * @param replace whether the provided data fully replaces this object's value
   * @param afterUidUrl the part of the url after the final uid
   * @return response, not containing the object
   */
  sendRequestNoView(
    apiVersion: string,
    baseUrl: string,
    uid: string,
    data: any,
    replace: boolean,
    afterUidUrl?: string
  ): Observable<ServerResponseModel> {
    const body = this.createEditRequestBody(data, replace, "none");
    if (afterUidUrl) {
      return this.helpSendRequestNoView(
        body,
        apiVersion,
        baseUrl,
        uid,
        afterUidUrl
      );
    }
    return this.helpSendRequestNoView(body, apiVersion, baseUrl, uid);
  }

  /**
   * Send a synchronous PUT request to modify an existing entity in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including the final uid)
   * @param uid the final uid at the end of the url, representing the object to be edited
   * @param data the data used for editing the object
   * @param replace whether the provided data fully replaces this object's value
   * @param afterUidUrl the part of the url after the final uid
   * @return response, not containing the object
   */
  sendRequestMinimalView(
    apiVersion: string,
    baseUrl: string,
    uid: string,
    data: any,
    replace: boolean,
    afterUidUrl?: string
  ): Observable<ServerResponseModel> {
    const body = this.createEditRequestBody(data, replace, "MINIMAL_FORM");
    if (afterUidUrl) {
      return this.helpSendRequestNoView(
        body,
        apiVersion,
        baseUrl,
        uid,
        afterUidUrl
      );
    }
    return this.helpSendRequestNoView(body, apiVersion, baseUrl, uid);
  }

  /*
   * Below are private helper methods
   */

  private helpSendRequest<T extends object>(
    body: any,
    apiVersion: string,
    baseUrl: string,
    uid: string,
    classRef: new () => T,
    afterUidUrl?: string
  ): Observable<T> {
    let response: Observable<ServerResponseModel>;
    if (afterUidUrl) {
      response = this.helpSendRequestNoView(
        body,
        apiVersion,
        baseUrl,
        uid,
        afterUidUrl
      );
    } else {
      response = this.helpSendRequestNoView(body, apiVersion, baseUrl, uid);
    }
    return response.pipe(
      map((res) => Json2TypescriptHelper.convertToEntity(res.data, classRef))
    );
  }

  private helpSendRequestNoView(
    body: any,
    apiVersion: string,
    baseUrl: string,
    uid: string,
    afterUidUrl?: string
  ): Observable<ServerResponseModel> {
    let url = apiVersion + baseUrl + "/" + uid;
    if (afterUidUrl) {
      url += afterUidUrl;
    }
    return this.httpClient.put<ServerResponseModel>(url, body);
  }

  private createEditRequestBody(
    data: any,
    replace: boolean,
    view: string
  ): any {
    return {
      data: data,
      replace: replace,
      returnParams: {
        view: view,
      },
    };
  }
}
