<div
  style="
    position: relative;
    text-align: left;
    min-height: 446px !important;
    padding-bottom: 50px;
  "
>
  <mat-progress-spinner
    diameter="50"
    mode="indeterminate"
    style="position: absolute; top: 42%; left: 45%"
    *ngIf="
      (calendarFacade.calendarFilterFieldsLoaded$ | async) !== true ||
      (calendarFacade.isSaveCalendarQueryPending$ | async) ||
      (calendarFacade.isUpdateCalendarQueryPending$ | async)
    "
  >
  </mat-progress-spinner>
  <ng-container
    *ngIf="
      (calendarFacade.calendarFilterFieldsLoaded$ | async) &&
      (calendarFacade.isSaveCalendarQueryPending$ | async) !== true &&
      (calendarFacade.isUpdateCalendarQueryPending$ | async) !== true
    "
  >
    <mat-tab-group
      [selectedIndex]="selectedFilterTab"
      (selectedIndexChange)="selectedFilterTab = $event"
      animationDuration="0"
    >
      <mat-tab label="Filter records">
        <div class="p-8">
          <il-filter
            [model]="selectedFilter"
            [filterFields]="filterFields"
            [filterOperators]="calendarFacade.provisionsOperator$ | async"
            (modelChange)="onFilterModelChange($event)"
          >
          </il-filter>
        </div>
      </mat-tab>
      <mat-tab label="Pick fields">
        <div class="p-8" style="margin: 0 2%">
          <intelease-calendar-fields
            [model]="filterFieldsModel"
            (modelChange)="onCalendarFieldsChange($event)"
          >
          </intelease-calendar-fields>
        </div>
      </mat-tab>
      <mat-tab label="Filter name">
        <div style="margin: 3% 4%; min-height: 340px; padding: 0 !important">
          <form [formGroup]="calendarFilterForm">
            <mat-form-field>
              <mat-label>Filter name</mat-label>
              <input
                formControlName="name"
                itlsFocusIn
                matInput
                placeholder="Enter filter name ..."
              />
              <mat-error
                *ngIf="calendarFilterForm.controls['name'].getError('required')"
                >Name is Required</mat-error
              >
            </mat-form-field>
            <mat-form-field>
              <mat-label>Filter description</mat-label>
              <textarea
                formControlName="description"
                matInput
                placeholder="Enter filter description ..."
              ></textarea>
            </mat-form-field>
            <div
              style="display: flex; align-items: center; justify-items: center"
            >
              <span class="mr-4 pr-2">color:</span>
              <github-picker [(color)]="filterColor"></github-picker>
              <!--              <swatches-picker [(color)]="filterColor"></swatches-picker>-->
              <!--              <il-ngx-color-picker-chrome-wrapper-->
              <!--                  style="width: 12px;height: 12px;border: none;margin-right:4px;margin-left:4px;"-->
              <!--                  [(color)]="filterColor"></il-ngx-color-picker-chrome-wrapper>-->
            </div>
          </form>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="filter-footer">
      <button
        mat-flat-button
        class="mr-2"
        *ngIf="selectedFilterTab > 0"
        (click)="selectedFilterTab = selectedFilterTab - 1"
      >
        Back
      </button>
      <button
        mat-flat-button
        color="primary"
        *ngIf="selectedFilterTab < 2"
        (click)="selectedFilterTab = selectedFilterTab + 1"
      >
        Next
      </button>
      <button
        mat-flat-button
        color="primary"
        *ngIf="selectedFilterTab === 2 && !isEditing"
        (click)="onCreateCalendarQueryClick()"
      >
        {{ mode === componentModeEnum.ADD ? "Apply" : "Create" }}
      </button>
      <button
        mat-flat-button
        color="primary"
        *ngIf="selectedFilterTab === 2 && isEditing"
        (click)="onUpdateCalendarQueryClick()"
      >
        Update
      </button>
    </div>
  </ng-container>
</div>
