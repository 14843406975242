import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { InteleaseModule } from "@@intelease/web/intelease/intelease.module";
import { NgScrollbarModule } from "ngx-scrollbar";
import { InputDropdownModule } from "@@intelease/web/intelease/components";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { SelectMentionConfirmModalModule } from "../../select-mention-confirm-modal/select-mention-confirm-modal.module";
import { AngularDraggableModule } from "angular2-draggable";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { OptionsReminderCalculatorModalComponent } from "./options-reminder-calculator-modal.component";
import { OptionsCalculatorModalModule } from "../options-calculator";
import { CustomInputFieldComponent } from "./components/input-field/input-field.component";
import { MatRadioModule } from "@angular/material/radio";
import { InputDropdownDropdownComponent } from "./components/input-dropdown-dropdown/input-dropdown.component";

const INTELEASE_MODULES = [InputDropdownModule, MatRadioModule];

const NGX_BOOTSTRAP = [
  TabsModule.forRoot(),
  BsDatepickerModule.forRoot(),
  ModalModule.forRoot(),
];

@NgModule({
  imports: [
    CommonModule,
    InteleaseCommonModule,
    InteleaseModule,
    NgScrollbarModule,
    ...INTELEASE_MODULES,
    ...NGX_BOOTSTRAP,
    TooltipModule,
    MatDialogModule,
    SelectMentionConfirmModalModule,
    AngularDraggableModule,
    DragDropModule,
    ItlsCustomIconModule,
    MatProgressSpinnerModule,
    OptionsCalculatorModalModule,
  ],
  declarations: [
    InputDropdownDropdownComponent,
    OptionsReminderCalculatorModalComponent,
    CustomInputFieldComponent,
  ],
})
export class OptionsReminderCalculatorModalModule {}
