import { Component, Inject, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import {
  ModalInputModel,
  ModalResponseModel,
} from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import {
  FormSchemaLoaderService,
  InteleaseNotificationService,
  UserInfoService,
} from "@@intelease/web/intelease/services";
import { CommonModalService } from "@@intelease/web/common/services";
import { map } from "rxjs/operators";
import { toggleFormSchemaMode } from "@@intelease/web/intelease/utils";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { TasksService } from "@@intelease/app-services/tasks";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomainsService } from "@@intelease/web/ui/src/lib/new-share-entity/services/domains.service";
import { ActorModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/actor.model";
import { TaskAssigneeModel } from "@@intelease/app-models/tasks/src/lib/tasks/task-assignee.model";
import { KanbanService } from "@@intelease/app-services/kanban";
import { MinimalUserAbstractTaskModel } from "@@intelease/app-models/tasks";
import { REGULAR_EXPRESSION_CONST } from "@@intelease/web/utils";
import { TranslateIconTypePipe } from "@@intelease/web/common/pipes";

@Component({
  selector: "intelease-task-modal-component",
  templateUrl: "./task-modal.component.html",
  styleUrls: ["./task-modal.component.scss"],
})
export class TaskModalComponent implements OnInit {
  modalRes: ModalResponseModel = new ModalResponseModel();
  /**
   * if 'mode' === ComponentModeEnum.EDIT you have to make sure 'payload.editable' is configured properly
   */
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload;
  description = "";
  formSchema;
  taskModel: any = {};
  newTaskModel;
  isLoadingEnabled = false;
  actors: ActorModel[] = [];
  oldAssignee: TaskAssigneeModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ModalInputModel,
    public dialogRef: MatDialogRef<TaskModalComponent>,
    private formSchemaLoaderService: FormSchemaLoaderService,
    private tasksService: TasksService,
    private userInfoService: UserInfoService,
    private commonModalService: CommonModalService,
    private inputValidationService: InputValidationService,
    private domainsService: DomainsService,
    private inteleaseNotificationService: InteleaseNotificationService,
    private kanbanService: KanbanService
  ) {
    this.payload = this.dialogData.payload;
    this.mode = this.dialogData.mode;
  }

  ngOnInit() {
    this.getActorList();
    if (this.mode === ComponentModeEnum.VIEW) {
      this.getTask();
    }
  }

  onModelChange(evt) {
    this.newTaskModel = evt.value;
  }

  onSaveClick() {
    if (this.newTaskModel?.assignee) {
      const preparedTaskModel = this.prepareTaskModel(this.newTaskModel);
      if (this.payload.abstractUid) {
        this.singleTaskSave(preparedTaskModel, this.payload.abstractUid);
      } else if (this.payload.abstractUids) {
        this.batchTasksSave(preparedTaskModel, this.payload.abstractUids);
      } else {
        alert("Unable to save this task!");
        this.closeModal({});
      }
    } else {
      this.inteleaseNotificationService.openSnackBar(
        "You need to assign the task to someone"
      );
    }
  }

  onUpdateClick() {
    const preparedTaskModel = this.prepareTaskModel(this.newTaskModel);
    const { abstractUid, uid } = this.payload;
    const taskType = this.inputValidationService.sanitizeText(
      preparedTaskModel.type
    );
    if (
      !this.validateSingleTaskSaveFields(
        preparedTaskModel.assignee,
        abstractUid,
        taskType
      )
    ) {
      return;
    }
    this.isLoadingEnabled = true;
    // if assignee is changed, we need to check new assignee's access to docSetAbstract
    if (preparedTaskModel.assignee.uid !== this.oldAssignee.uid) {
      //TODO(reza) create model for DomainId / EntityId
      this.tasksService
        .checkPermission(
          [preparedTaskModel.assignee],
          [{ uid: abstractUid, type: "FINAL_ABSTRACT" }],
          taskType
        )
        .subscribe(
          (permissionResponse) => {
            if (permissionResponse.accessibleToAll) {
              this.updateTask(preparedTaskModel, uid);
            } else {
              const modalData = new ModalInputModel();
              modalData.payload = {
                customMessage: false,
                message:
                  "Are you sure you want to give the relevant permissions and share this record?",
                title: "Confirm Permissions",
              };
              this.commonModalService
                .openGenericOkCancelModal(modalData)
                .afterClosed()
                .subscribe((res) => {
                  if (res.data.exitType === ModalsResponseTypeEnum.CLOSE) {
                    this.updateTask(preparedTaskModel, uid);
                  } else {
                    this.isLoadingEnabled = false;
                  }
                });
            }
          },
          () => (this.isLoadingEnabled = false)
        );
    } else {
      this.updateTask(preparedTaskModel, uid);
    }
  }

  onEditClick() {
    this.mode = ComponentModeEnum.EDIT;
    this.formSchema = toggleFormSchemaMode(this.formSchema, false);
  }

  onCancelClick() {
    this.mode = ComponentModeEnum.VIEW;
    this.formSchema = toggleFormSchemaMode(this.formSchema, true);
  }

  onDismissModalClick() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }

  private shareAndCreateTask(taskModel, abstractUid: string) {
    const modalData = new ModalInputModel();
    modalData.payload = {
      customMessage: false,
      title: "Confirm Permissions",
      message:
        "Are you sure you want to give the relevant permissions and share this record?",
    };
    this.commonModalService
      .openGenericOkCancelModal(modalData)
      .afterClosed()
      .subscribe((res) => {
        if (res.data.exitType === ModalsResponseTypeEnum.CLOSE) {
          this.createTask(taskModel, abstractUid);
        } else {
          this.isLoadingEnabled = false;
        }
      });
  }

  private shareAndCreateTasks(
    taskModel,
    abstractUids: string[],
    numUnallowed: number
  ) {
    this.isLoadingEnabled = false;
    const modalData = new ModalInputModel();
    modalData.payload = {
      customMessage: true,
      params: { numUnallowed },
      message: `${numUnallowed} document sets don't have relevant permissions for this user. Would you like to share these document sets with this user?`,
      title: "Confirm Permissions",
    };
    this.commonModalService
      .openGenericOkCancelModal(modalData)
      .afterClosed()
      .subscribe((res) => {
        if (res.data.exitType === ModalsResponseTypeEnum.CLOSE) {
          this.createTasks(taskModel, abstractUids);
        }
      });
  }

  private createTask(taskModel, abstractUid: string): void {
    let result: Observable<MinimalUserAbstractTaskModel>;
    if (this.payload.kanbanTaskCreationData) {
      const kanbanTaskCreationData = this.payload.kanbanTaskCreationData;
      result = this.kanbanService.createKanbanBoardIssueTask(
        kanbanTaskCreationData.projectUid,
        kanbanTaskCreationData.kanbanBoardUid,
        kanbanTaskCreationData.issueListUid,
        kanbanTaskCreationData.issueUid,
        taskModel
      );
    } else {
      result = this.tasksService.createTask(abstractUid, taskModel);
    }
    result.subscribe(
      (res) => this.closeModal(res),
      () => (this.isLoadingEnabled = false)
    );
  }

  private createTasks(taskModel, abstractUids: string[]): void {
    this.tasksService
      .createMultiEntitiesTask(abstractUids, taskModel)
      .subscribe(
        (res) => this.closeModal(res),
        () => (this.isLoadingEnabled = false)
      );
  }

  private updateTask(taskModel, uid: string) {
    this.tasksService.updateTask(uid, taskModel).subscribe(
      (res) => this.closeModal(res),
      () => (this.isLoadingEnabled = false)
    );
  }

  private getTask() {
    const { uid: taskUid } = this.payload;
    this.tasksService.getTaskDetail(taskUid).subscribe((res) => {
      this.taskModel = res;
      this.taskModel.assignee = {
        ...this.taskModel.assignee,
        title: this.taskModel.assignee.getNameWithTypeTitle(),
      };
      this.taskModel["dueDate"] = this.taskModel["dueDate"]
        ? new Date(this.taskModel["dueDate"])
        : undefined;
      this.oldAssignee = new TaskAssigneeModel(res.assignee);
      this.taskModel.status = res.status.name;
    });
  }

  private getActorList() {
    this.domainsService.getActors().subscribe((actors) => {
      this.actors = actors;
      this.getTaskFormSchema(this.actors);
    });
  }

  private getTaskFormSchema(actors: ActorModel[]) {
    this.formSchemaLoaderService
      .getFormSchemaByName("NEW_TASK")
      .pipe(
        map((taskFormSchema: any) => {
          taskFormSchema.properties.assignee.items = actors.map((actor) => {
            return {
              ...actor,
              title: actor.getNameWithTypeTitle(),
              icon: {
                type: TranslateIconTypePipe.transform(actor.type),
                height: 14,
              },
            };
          });
          return taskFormSchema;
        })
      )
      .subscribe((res) => {
        if (this.mode === ComponentModeEnum.VIEW) {
          this.formSchema = toggleFormSchemaMode(res, true);
        } else {
          this.formSchema = res;
        }
      });
  }

  private batchTasksSave(taskModel, abstractUids: string[]) {
    const assigneeActor: ActorModel = taskModel.assignee;
    const taskType = this.inputValidationService.sanitizeText(taskModel.type);
    if (
      !this.validateBatchTasksSaveFields(assigneeActor, abstractUids, taskType)
    ) {
      return;
    }
    this.isLoadingEnabled = true;
    if (assigneeActor.type === "USER") {
      // check if User has enough permissions
      this.tasksService
        .checkTasksPermission(assigneeActor.uid, abstractUids, taskType, [])
        .subscribe(
          (permissionsResponse) => {
            const numUnallowed = permissionsResponse.items.filter(
              (permissionResponse) => !permissionResponse.allowed
            ).length;
            if (numUnallowed === 0) {
              this.createTasks(taskModel, abstractUids);
            } else {
              this.shareAndCreateTasks(taskModel, abstractUids, numUnallowed);
            }
          },
          () => (this.isLoadingEnabled = false)
        );
    } else {
      this.createTasks(taskModel, abstractUids);
    }
  }

  private singleTaskSave(taskModel, abstractUid: string) {
    const assignee: ActorModel = taskModel.assignee;
    const taskType = this.inputValidationService.sanitizeText(taskModel.type);
    if (!this.validateSingleTaskSaveFields(assignee, abstractUid, taskType)) {
      return;
    }
    this.isLoadingEnabled = true;
    this.tasksService
      .checkPermission(
        [assignee],
        [{ uid: abstractUid, type: "FINAL_ABSTRACT" }],
        taskType
      )
      .subscribe(
        (permissionResponse) => {
          if (permissionResponse.accessibleToAll) {
            this.createTask(taskModel, abstractUid);
          } else {
            this.shareAndCreateTask(taskModel, abstractUid);
          }
        },
        () => (this.isLoadingEnabled = false)
      );
  }

  private prepareTaskModel(taskModel) {
    if (taskModel?.assignee) {
      const assignee = taskModel.assignee;
      const isManualEnteredEmail = typeof assignee.uid === "undefined";
      if (isManualEnteredEmail) {
        taskModel.assignee = new ActorModel({
          uid: assignee.title,
          name: assignee.title,
          type: "UNREGISTERED_USER",
        });
      } else {
        taskModel.assignee = new ActorModel({
          uid: assignee.uid,
          name: assignee.name,
          type: assignee.type,
        });
      }
    }
    return taskModel;
  }

  private validateBatchTasksSaveFields(
    assigneeActor: ActorModel,
    abstractUids: string[],
    taskType: string
  ): boolean {
    if (!this.commonValidateTaskSaveFields(assigneeActor, taskType)) {
      return false;
    }
    if (
      !this.inputValidationService.usefulTextsElseAlert(
        abstractUids,
        "You must choose some abstracts for this task!"
      )
    ) {
      return false;
    }
    return true;
  }

  private validateSingleTaskSaveFields(
    assignee: ActorModel,
    abstractUid: string,
    taskTypes: string
  ): boolean {
    if (!this.commonValidateTaskSaveFields(assignee, taskTypes)) {
      return false;
    }
    return this.inputValidationService.usefulTextElseAlert(
      abstractUid,
      "You must choose an abstract for this task!"
    );
  }

  private commonValidateTaskSaveFields(
    assigneeActor: ActorModel,
    taskType: string
  ): boolean {
    if (!assigneeActor) {
      this.inteleaseNotificationService.openSnackBar(
        "You must specify a Team / User to assign this task to!"
      );
      return false;
    }
    // if assignee was not selected and was entered manually, check it's format to be a valid email
    if (
      !assigneeActor.uid &&
      !REGULAR_EXPRESSION_CONST.EMAIL.test(assigneeActor.name)
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Please select assignee! (or enter valid email address)"
      );
      return false;
    }
    if (
      !this.inputValidationService.usefulTextElseAlert(
        taskType,
        "You must choose a task category!"
      )
    ) {
      return false;
    }
    return true;
  }

  private closeModal(res) {
    this.isLoadingEnabled = false;
    this.modalRes.data = { ...{ task: res } };
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.CLOSE,
    });
  }
}
