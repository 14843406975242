import { Component, ViewChild } from "@angular/core";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { InteleaseNotificationService } from "@@intelease/web/intelease/services";
import { Observable, of, Subject } from "rxjs";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { CommonModalService } from "@@intelease/web/common/services";
import { finalize } from "rxjs/operators";
import {
  FilesUploadComponent,
  UploadedDocSetModel,
} from "@@intelease/web/ui/src/lib/itls-new-upload";
import { keyBy } from "lodash";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive.service";
import { UploadDocSetService } from "../services/upload-doc-set.service";
import { UploadDocSetResponseModel } from "../models/upload-doc-set-response.model";
import { UploadingDocSetBoxService } from "@@intelease/web/ui/src/lib/itls-new-upload/services/uploading-doc-set-box.service";
import { UploadDocSetGroupDataResDtoModel } from "@@intelease/api-models/adex-api-model-src";

const ALLOWED_FILE_EXTENSIONS = [".pdf"];

const INVALID_FILE_NAMES_CHARACTERS = ['"', "%", "/"];

const MAX_DOCS_ALLOWED = 5;

export enum PanelViewEnum {
  UPLOAD = "UPLOAD",
  SELECT_DEST_FOLDER = "SELECT_DEST_FOLDER",
}

@Component({
  selector: "intelease-upload-doc-set-widget",
  templateUrl: "./upload-doc-set-widget.component.html",
  styleUrls: ["./upload-doc-set-widget.scss"],
})
export class ItlsUploadDocSetWidgetComponent {
  isUploading = false;
  selectedView: PanelViewEnum = PanelViewEnum.UPLOAD;
  PanelView = PanelViewEnum;

  @ViewChild("filesUploadComponent")
  filesUploadComponent: FilesUploadComponent;
  selectedFolderUid: string;
  isUploadingFolder: boolean;
  uploadedDocSets: UploadedDocSetModel[];
  disallowedFiles: string[] = [];

  constructor(
    private inputValidationService: InputValidationService,
    private inteleaseNotificationService: InteleaseNotificationService,
    private commonModalService: CommonModalService,
    private uploadDocSetService: UploadDocSetService,
    private uploadingBoxService: UploadingDocSetBoxService
  ) {}

  onModelChanged(uploadedDocSetModels: UploadedDocSetModel[]) {
    const filteredUploadedDocSetModels = [];
    for (const uploadedDocSet of uploadedDocSetModels) {
      uploadedDocSet.myFiles = uploadedDocSet.myFiles.filter((myFile) => {
        const filename = myFile.file.name;
        const isValid = this.inputValidationService.validTextSuffixesIgnoreCase(
          filename,
          ALLOWED_FILE_EXTENSIONS
        );
        if (!isValid) {
          this.disallowedFiles.push(filename);
        }
        return isValid;
      });
      if (uploadedDocSet.myFiles.length > 0) {
        filteredUploadedDocSetModels.push(uploadedDocSet);
      }
    }
    this.uploadedDocSets = filteredUploadedDocSetModels;
  }

  onUpload() {
    if (!this.areFilesValid()) {
      return;
    }
    this.confirmWithUserCreditsUsage().subscribe((confirmed) => {
      if (confirmed) {
        this.uploadDocSetData();
      }
    });
  }

  private confirmWithUserCreditsUsage(): Observable<boolean> {
    const numCredits = this.uploadedDocSets
      .map((item) => Math.ceil(item.myFiles.length / MAX_DOCS_ALLOWED))
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    if (numCredits === 1) {
      return of(true);
    }
    const numFiles = this.uploadedDocSets
      .map((item) => item.myFiles.length)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const modalData = new ModalInputModel();
    if (this.isUploadingFolder) {
      modalData.payload = {
        customMessage: true,
        params: {
          numCredits,
          numRecords: this.uploadedDocSets.length,
          numFiles,
        },
        message: `You are uploading ${this.uploadedDocSets.length} records (${numFiles} documents), using ${numCredits} credits. Are you sure you wish to upload?`,
        title: "Confirm Upload",
      };
    } else {
      modalData.payload = {
        customMessage: true,
        params: {
          numCredits,
          numFiles,
        },
        message: `You are uploading ${numFiles} new files. This will use ${numCredits} of your account credits. Are you sure you wish to upload?`,
        title: "Confirm Upload",
      };
    }
    const userConfirmedCreditsUsage = new Subject<boolean>();
    this.commonModalService
      .openGenericOkCancelModal(modalData)
      .afterClosed()
      .subscribe((res) => {
        userConfirmedCreditsUsage.next(
          res.data.exitType === ModalsResponseTypeEnum.CLOSE
        );
      });
    return userConfirmedCreditsUsage;
  }

  private areFilesValid(): boolean {
    for (const uploadedDocSet of this.uploadedDocSets) {
      const originalFileNames = uploadedDocSet.myFiles.map(
        (myFile) => myFile.file.name
      );
      const isValid = this.inputValidationService.validTextsSuffixesIgnoreCase(
        originalFileNames,
        ALLOWED_FILE_EXTENSIONS
      );
      if (!isValid) {
        this.inteleaseNotificationService.openSnackBar(
          `One or more files are not supported! (allowed file extensions: '` +
            ALLOWED_FILE_EXTENSIONS +
            `')`
        );
        return false;
      }
      const fileNames = uploadedDocSet.myFiles.map((myFile) => myFile.name);
      for (const fileName of fileNames) {
        if (
          !INVALID_FILE_NAMES_CHARACTERS.every(
            (invalidFileNameChar) => !fileName.includes(invalidFileNameChar)
          )
        ) {
          this.inteleaseNotificationService.openSnackBar(
            "Found a file with invalid name: " +
              fileName +
              " (these characters are not allowed: " +
              INVALID_FILE_NAMES_CHARACTERS.join(", ") +
              " )"
          );
          return false;
        }
      }
    }
    return true;
  }

  private uploadDocSetData() {
    this.isUploading = true;

    const parentDirectoryUid = ItlsDriveService.getNonRootDirectoryUid(
      this.selectedFolderUid
    );
    this.uploadDocSetService
      .uploadDocSetData({
        directoryUid: parentDirectoryUid,
        uploadedDocSets: this.uploadedDocSets,
        isUploadingFolder: this.isUploadingFolder,
      })
      .pipe(finalize(() => (this.isUploading = false)))
      .subscribe((resp) => {
        this.openUploadingBox(parentDirectoryUid, resp);
        this.onSuccessfullyUploadedResult();
      });
  }

  private onSuccessfullyUploadedResult() {
    this.filesUploadComponent.reset();
    this.selectedView = PanelViewEnum.UPLOAD;
    this.uploadedDocSets = undefined;
  }

  onBackClicked() {
    this.selectedView = PanelViewEnum.UPLOAD;
  }

  onNextClicked() {
    this.selectedView = PanelViewEnum.SELECT_DEST_FOLDER;
  }

  private openUploadingBox(
    parentFolderUid: string,
    resp: UploadDocSetGroupDataResDtoModel
  ) {
    const items = resp.items;
    if (this.isUploadingFolder) {
      const directoryPathToItem = keyBy(items, (item) => item.directoryPath);
      // we don't store explicit name for root selected folder to upload (selected from file browser)
      //  but instead we can use the first part of the 'filesPath'
      const rootSelectedFolderName =
        this.uploadedDocSets[0].folderPath.split("/")[0];
      const records = this.uploadedDocSets.map((uploadedDocSet) => ({
        uid: directoryPathToItem[uploadedDocSet.folderPath].uid,
        name: uploadedDocSet.name,
        files: uploadedDocSet.myFiles,
      }));
      this.uploadingBoxService.uploadFolder(
        parentFolderUid,
        resp.selectedDirectoryUid,
        rootSelectedFolderName,
        records,
        undefined
      );
    } else {
      const item = items[0];
      const uploadedDocSet = this.uploadedDocSets[0];
      this.uploadingBoxService.uploadDocuments(
        parentFolderUid,
        {
          uid: item.uid,
          name: uploadedDocSet.name,
          files: uploadedDocSet.myFiles,
        },
        undefined
      );
    }
  }
}
