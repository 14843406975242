import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ProvisionFormStructureModel")
export class ProvisionFormStructureModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("version", String)
  version: number;

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("provisionInfoUids", [String])
  provisionInfoUids: string[] = [];

  @JsonProperty("provisionInfoHtmlNames", [String])
  provisionInfoHtmlNames: string[] = [];

  @JsonProperty("categories", [ProvisionFormStructureModel])
  categories: Array<ProvisionFormStructureModel> = [];
}
