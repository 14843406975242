/* tslint:disable */
/* eslint-disable */

/**
 * the next and previous record information in this queue of current stage
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("NextPrevQueueRecordDtoModel")
export class NextPrevQueueRecordDtoModel {
  @JsonProperty("nextRecordUid", String, true)
  nextRecordUid?: string = "";

  @JsonProperty("previousRecordUid", String, true)
  previousRecordUid?: string = "";

  /**
   * the stage of the queue
   */

  @JsonProperty("queueStage", Any, true)
  queueStage?:
    | "PROCESSING"
    | "READY"
    | "IN_REVIEW"
    | "NEED_QA_REVIEW"
    | "IN_QA_REVIEW"
    | "COMPLETED"
    | "FAILED" = undefined;

  @JsonProperty("queueUid", String, true)
  queueUid?: string = "";
}
