import { LocalStorageKey } from "@@intelease/web/common/enums/local-storage.keys";
import { LocalStorageService } from "@@intelease/web/common/services";
import { generateUUID } from "@@intelease/web/utils";
import { Injectable } from "@angular/core";
import { environment } from "apps/ui/src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DocumentCacheService {
  private static readonly API_VERSION = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly DOC_SETS_URL = "/doc-sets";
  private static readonly DOCUMENTS_URL = "/documents";
  private static readonly CACHE_VERSION = "v1";

  constructor(private readonly localStorageService: LocalStorageService) {
    this.clearExpiredCache();
  }

  private getRequestUrl(abstractUid: string, docUid: string): string {
    return `${environment.appUrl}${environment.apiUrlPart}${DocumentCacheService.API_VERSION}${DocumentCacheService.FINAL_DOC_SETS_URL}/${abstractUid}${DocumentCacheService.DOCUMENTS_URL}/${docUid}`;
  }

  private getDocSetFileRequestUrl(docSetUid: string, docUid: string): string {
    return `${environment.appUrl}${environment.apiUrlPart}${DocumentCacheService.API_VERSION}${DocumentCacheService.DOC_SETS_URL}/${docSetUid}${DocumentCacheService.DOCUMENTS_URL}/${docUid}`;
  }

  private extractAbstractUidAndDocUidFromUrl(url: string): {
    abstractUid: string;
    docUid: string;
  } {
    const part =
      url.split(`${DocumentCacheService.FINAL_DOC_SETS_URL}/`)[1] ||
      url.split(`${DocumentCacheService.DOC_SETS_URL}/`)[1];

    const [abstractUid, docUid] = part.split(
      `${DocumentCacheService.DOCUMENTS_URL}/`
    );

    return { abstractUid, docUid };
  }

  private async clearExpiredCache(): Promise<void> {
    const cachedDocuments = this.localStorageService.get<
      Record<string, string>
    >(LocalStorageKey.DOCUMENT_CACHE_EXPIRATION);

    const currentDate = new Date();

    Object.keys(cachedDocuments || {}).forEach(async (document) => {
      if (currentDate > new Date(cachedDocuments[document])) {
        const cache = await caches.open(DocumentCacheService.CACHE_VERSION);
        cache.delete(document);
        const { abstractUid, docUid } =
          this.extractAbstractUidAndDocUidFromUrl(document);
        this.removeETag(abstractUid, docUid);
        this.removeDocumentFromCache(document);
      }
    });
  }

  private getETagFromResponse(response: Response): string {
    return response.headers.get("ETag");
  }

  public async getAndCacheDocument(
    abstractUid: string,
    docUid: string,
    ETag?: string
  ): Promise<Response> {
    const url = this.getRequestUrl(abstractUid, docUid);

    let useCache = true;

    if (ETag) {
      try {
        const response = await this.fetchDocument(url, {
          "If-None-Match": ETag,
        });

        useCache = response.status === 304;

        if (!useCache) {
          this.putInCache(url, response);
          this.saveETag(
            abstractUid,
            docUid,
            this.getETagFromResponse(response)
          );
          return response;
        }
      } catch (error) {
        console.warn(error);
      }
    }

    const cacheResponse = await this.getCachedResponse(url);

    if (!cacheResponse) {
      const response = await this.fetchDocument(url);
      this.putInCache(url, response);
      this.saveETag(abstractUid, docUid, this.getETagFromResponse(response));
      return response;
    }

    this.updateCacheExpirationDate(url);

    return cacheResponse;
  }

  public async getAndCacheDocSetFile(
    docSetUid: string,
    docUid: string,
    ETag?: string
  ): Promise<Response> {
    const url = this.getDocSetFileRequestUrl(docSetUid, docUid);

    let useCache = true;

    if (ETag) {
      try {
        const response = await this.fetchDocument(url, {
          "If-None-Match": ETag,
        });

        useCache = response.status === 304;

        if (!useCache) {
          this.putInCache(url, response);
          this.saveETag(docSetUid, docUid, this.getETagFromResponse(response));

          return response;
        }
      } catch (error) {
        console.warn(error);
      }
    }

    const cacheResponse = await this.getCachedResponse(url);

    if (!cacheResponse) {
      const response = await this.fetchDocument(url);
      this.putInCache(url, response);
      this.saveETag(docSetUid, docUid, this.getETagFromResponse(response));
      return response;
    }
    this.updateCacheExpirationDate(url);
    return cacheResponse;
  }

  private getETagCacheKey(abstractUid: string, docUid: string): string {
    return `${abstractUid}___${docUid}`;
  }

  private removeETag(abstractUid: string, docUid: string): void {
    const cacheItem = this.getETagCacheKey(abstractUid, docUid);

    const etags = this.getFromLocalStorage<Record<string, string>>(
      LocalStorageKey.ETAG
    );

    if (etags && etags[cacheItem]) {
      delete etags[cacheItem];
    }

    this.saveInLocalStorage(LocalStorageKey.ETAG, { ...etags });
  }

  private getFromLocalStorage<T>(key: LocalStorageKey): T {
    return JSON.parse(
      (localStorage.get ? localStorage.get(key) : localStorage[key]) || "{}"
    );
  }

  private saveInLocalStorage<T>(key: LocalStorageKey, value: T): void {
    localStorage.save
      ? localStorage.save(key, JSON.stringify(value))
      : (localStorage[key] = JSON.stringify(value));
  }

  private removeDocumentFromCache(document: string): void {
    const docs = this.getFromLocalStorage<Record<string, string>>(
      LocalStorageKey.DOCUMENT_CACHE_EXPIRATION
    );

    if (docs && docs[document]) {
      delete docs[document];
    }

    this.saveInLocalStorage(LocalStorageKey.DOCUMENT_CACHE_EXPIRATION, {
      ...docs,
    });
  }

  public saveETag(abstractUid: string, docUid: string, etag: string): void {
    const cacheItem = this.getETagCacheKey(abstractUid, docUid);

    this.saveInLocalStorage(LocalStorageKey.ETAG, {
      ...(this.getFromLocalStorage(LocalStorageKey.ETAG) as Record<
        string,
        string
      >),
      [cacheItem]: etag,
    });
  }

  private async hasCachedDocument(
    abstractUid: string,
    docUid: string
  ): Promise<boolean> {
    const cache = await caches.open(DocumentCacheService.CACHE_VERSION);
    const response = await cache.match(this.getRequestUrl(abstractUid, docUid));
    return !!response;
  }

  private async hasCachedDocSetFile(
    docSetUid: string,
    docUid: string
  ): Promise<boolean> {
    const cache = await caches.open(DocumentCacheService.CACHE_VERSION);
    const response = await cache.match(
      this.getDocSetFileRequestUrl(docSetUid, docUid)
    );
    return !!response;
  }

  public async getETag(abstractUid: string, docUid: string): Promise<string> {
    const hasCachedDocument = await this.hasCachedDocument(abstractUid, docUid);
    if (!hasCachedDocument) {
      return;
    }

    const cacheItem = this.getETagCacheKey(abstractUid, docUid);

    const etags = this.getFromLocalStorage(LocalStorageKey.ETAG);

    return etags?.[cacheItem];
  }

  public async getDocSetFileETag(
    docSetUid: string,
    docUid: string
  ): Promise<string> {
    const hasCachedDocument = await this.hasCachedDocSetFile(docSetUid, docUid);
    if (!hasCachedDocument) {
      return;
    }

    const cacheItem = this.getETagCacheKey(docSetUid, docUid);

    const etags = this.getFromLocalStorage(LocalStorageKey.ETAG);

    return etags?.[cacheItem];
  }

  private async putInCache(url: string, response: Response): Promise<void> {
    const cache = await caches.open(DocumentCacheService.CACHE_VERSION);
    await cache.put(url, response);
    this.updateCacheExpirationDate(url);
  }

  public updateCacheExpirationDateForDocument(
    abstractUid: string,
    docUid: string
  ): void {
    this.updateCacheExpirationDate(this.getRequestUrl(abstractUid, docUid));
  }

  private updateCacheExpirationDate(url: string): void {
    const date = new Date();
    date.setDate(new Date().getDate() + 4 * 7); //4 weeks cache
    const timestamp = date.toISOString();

    const cachedDocuments = this.localStorageService.get<
      Record<string, string>
    >(LocalStorageKey.DOCUMENT_CACHE_EXPIRATION);

    this.localStorageService.save(LocalStorageKey.DOCUMENT_CACHE_EXPIRATION, {
      ...cachedDocuments,
      [url]: timestamp,
    });
  }

  private async fetchDocument(url: string, headers = {}): Promise<Response> {
    return fetch(url, {
      headers: {
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + localStorage.getItem(LocalStorageKey.AUTH),
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Request-Id": generateUUID(),
        ...headers,
      },
    });
  }

  private async getCachedResponse(
    cacheKey: string
  ): Promise<Response | undefined> {
    const cache = await caches.open(DocumentCacheService.CACHE_VERSION);
    return cache.match(cacheKey);
  }
}
