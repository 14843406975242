import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { ComponentStateHooksInterface } from "@@intelease/app-models/common";
import { map, startWith } from "rxjs/operators";
import { CalendarFacade } from "@@intelease/app-state/calendar";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "intelease-calendar-fields",
  templateUrl: "./calendar-fields.component.html",
  styleUrls: ["./calendar-fields.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarFieldsComponent
  implements ComponentStateHooksInterface, OnInit
{
  @Input() set model(value) {
    this.selectedFilterFields = value;
    this._model = value;
  }

  get model() {
    return this._model;
  }

  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  filterFields: {
    fieldName: string;
    fieldType: string;
    uiName: string;
  }[] = [];
  _model = [];
  calendarColumnControl = new FormControl();
  filterFieldsOptions: Observable<any>;
  selectedFilterFields = [];
  destroyRef = inject(DestroyRef);

  constructor(public readonly calendarFacade: CalendarFacade) {
    this.initActions();
    this.initListeners();
  }

  ngOnInit(): void {
    this.filterFieldsOptions = this.calendarColumnControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.filterFields.filter((option) =>
      option.uiName.toLowerCase().includes(filterValue)
    );
  }

  initActions(): void {
    this.calendarFacade.calendarDisplayableFieldsLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoaded) => {
        if (!isLoaded) {
          this.calendarFacade.loadCalendarDisplayableFields();
        }
      });
  }

  initListeners(): void {
    this.calendarFacade.calendarDisplayableFields$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((calendarDisplayableFields) => {
        if (calendarDisplayableFields) {
          this.filterFields = calendarDisplayableFields;
        }
      });
  }

  onFilterFieldOptionSelected(field) {
    this.selectedFilterFields.push(field);
    this.modelChange.emit(this.selectedFilterFields);
    this.calendarColumnControl.setValue("");
  }

  onRemoveFilterFields(index: number) {
    this.selectedFilterFields.splice(index, 1);
    this.modelChange.emit(this.selectedFilterFields);
  }
}
