import { Component, Inject, OnInit } from "@angular/core";
import {
  ModalInputModel,
  ModalResponseModel,
} from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "itls-my-documents-move-folder-modal",
  templateUrl: "./my-documents-move-folder-modal.component.html",
  styleUrls: ["./my-documents-move-folder.modal-component.scss"],
})
export class MyDocumentsMoveFolderModalComponent implements OnInit {
  // onClose: Subject<any> = new Subject<any>()
  // onNewFolder$: Subject<any> = new Subject<any>()
  // onDismiss: Subject<any> = new Subject<any>()
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload;
  modalTitle = "Move to Folder";
  folderId = "";
  nodes = [
    {
      id: 3,
      name: "Folder 1",
      children: [
        {
          id: 11,
          name: "Sub 4.1 ",
          isExpanded: false,
        },
        {
          id: 14,
          name: "Sub 4.2",
          isExpanded: false,

          children: [
            { id: 15, name: "Sub 4.2.1", children: [] },
            { id: 16, name: "Sub 4.2.2", children: [] },
          ],
        },
      ],
    },
    {
      id: 10,
      name: "Folder 2",
      isExpanded: false,
      children: [
        {
          id: 4,
          name: "Sub 3.1",
          children: [
            { id: 5, name: "Sub 3.1.1" },
            { id: 6, name: "Sub 3.1.2" },
          ],
        },
        {
          id: 7,
          name: "Sub 3.2",
          children: [
            { id: 8, name: "Sub 3.2.1" },
            { id: 9, name: "Sub 3.2.2" },
          ],
        },
      ],
    },
  ];
  options = {
    animateExpand: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ModalInputModel,
    public dialogRef: MatDialogRef<MyDocumentsMoveFolderModalComponent>
  ) {}

  ngOnInit() {
    this.payload = this.dialogData.payload;
    this.mode = this.dialogData.mode;
    this.folderId = this.payload.folderId;
  }

  onMoveFolderClick() {
    this.modalRes.data = { ...{ folderId: this.folderId } };
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.CLOSE,
    });
  }

  onNewFolderClick() {
    this.modalRes.data = { ...{ folderId: this.folderId } };
    // this.onNewFolder$.next(this.modalRes);
  }

  onDismissModalClick() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}
