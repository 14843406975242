/* tslint:disable */
/* eslint-disable */
import { CloneReportDtoModel } from "./clone-report-dto-model";
import { OApiReturnParamsRequestReportQueryViewTypeModel } from "./o-api-return-params-request-report-query-view-type-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCloneReportDtoModel")
export class OApiReqCloneReportDtoModel {
  @JsonProperty("data", CloneReportDtoModel, true)
  data?: CloneReportDtoModel = undefined;

  @JsonProperty(
    "returnParams",
    OApiReturnParamsRequestReportQueryViewTypeModel,
    true
  )
  returnParams?: OApiReturnParamsRequestReportQueryViewTypeModel = undefined;
}
