<div fxLayout="column" fxLayoutAlign="start center">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="search-input-container"
  >
    <itls-icon
      svgIcon="icon-search"
      style="margin-right: 10px; position: absolute"
      *ngIf="!isLoading"
    ></itls-icon>
    <div class="loading-container" *ngIf="isLoading" style="z-index: 5">
      <itls-pg-progress
        style="
          height: 20px;
          width: 16px;
          background: transparent;
          margin-bottom: 0;
          position: absolute;
        "
        class="progress"
        [type]="'circle'"
        [thick]="true"
        [indeterminate]="true"
      >
      </itls-pg-progress>
    </div>
    <input
      type="text"
      fxFlex="100 1 auto"
      class="search-input"
      [disabled]="isLoading"
      [(ngModel)]="searchInput"
      (ngModelChange)="onSearchInputChanged()"
      #searchProvisionForm
    />
  </div>
  <div
    class="forms-container"
    [style.maxHeight]="height === 'md' ? '300px' : '155px'"
  >
    <div
      *ngIf="searchInput"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      class="filtered-forms-container"
    >
      <ng-container *ngFor="let provisionsGroup of filteredProvisionsGroups">
        <p class="document-type-name-text">
          {{ provisionsGroup.documentType }}
        </p>
        <p
          *ngFor="let filteredForm of provisionsGroup.items"
          class="form-name-text"
          [class.selected-provision-form]="
            this.selectedProvisionForm &&
            this.selectedProvisionForm.uid === filteredForm.uid
          "
          (click)="onSelectProvisionForm(filteredForm)"
        >
          {{ filteredForm.name }}
        </p>
      </ng-container>
    </div>
  </div>
</div>
