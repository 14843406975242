import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("SuggestionItem")
export class SuggestionItem {
  static view = "full";

  @JsonProperty("searchItem", String)
  searchItem = "";

  @JsonProperty("searchCategories", [String])
  searchCategories: string[] = [];

  @JsonProperty("abstractUid", String, true)
  abstractUid: string = undefined;
}
