import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SnippetModel } from "@@intelease/web/common/models";

@Component({
  selector: "il-snippet-text",
  templateUrl: "./snippet-text.component.html",
  styles: [
    `
      mat-icon {
        width: 10px;
        height: 10px;
        min-height: 10px;
        min-width: 10px;
        line-height: 10px;
      }
      strong {
        color: #101010;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnippetTextComponent {
  @Input() snippet: SnippetModel;
  @Input() provisionBoxWidth: number;
  @Input() type: "PROVISION_BOX" | "OLD_PROVISION_BOX" = "OLD_PROVISION_BOX";
}
