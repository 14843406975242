import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { fetch, pessimisticUpdate } from "@ngrx/router-store/data-persistence";
import {
  LoadReports,
  ReportsLoaded,
  ReportsLoadError,
  ReportsActionTypes,
  LoadReportColumns,
  ReportColumnsLoaded,
  ReportColumnsLoadError,
  LoadReportFilterFields,
  ReportFilterFieldsLoaded,
  ReportFilterFieldsLoadError,
  LoadAbstractsReport,
  AbstractsReportLoaded,
  AbstractsReportLoadError,
  SaveReport,
  SaveReportFailed,
  SaveReportSucceeded,
  LoadSelectedReport,
  SelectedReportLoaded,
  SelectedReportLoadError,
  DeleteSelectedReport,
  DeleteSelectedReportFailed,
  DeleteSelectedReportSucceeded,
  UpdateSelectedReport,
  UpdateSelectedReportFailed,
  UpdateSelectedReportSucceeded,
  ExportSelectedReport,
  ExportSelectedReportSucceeded,
  ExportSelectedReportFailed,
  DeleteMultipleReport,
  DeleteMultipleReportSucceeded,
  DeleteMultipleReportFailed,
  DuplicateSelectedReport,
  DuplicateSelectedReportSucceeded,
  DuplicateSelectedReportFailed,
} from "./reports.actions";
import { ReportsService } from "@@intelease/app-services/reports";
import { map } from "rxjs/operators";
import { cloneDeep, keyBy } from "lodash";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { ReportTypesEnum } from "@@intelease/app-models/reports";
import { InteleaseCommonService } from "@@intelease/app-services/common";
import {
  OApiRespMediumReportQueryViewModel,
  ReportService,
} from "@@intelease/api-models/adex-api-model-src";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";

@Injectable()
export class ReportsEffects {
  loadReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.LoadReports),
      fetch({
        run: () => {
          return this.reportsService
            .getReportsList()
            .pipe(map((res) => new ReportsLoaded(res.data)));
        },

        onError: (action: LoadReports, error: any) => {
          //eslint-disable-line
          return new ReportsLoadError(error);
        },
      })
    )
  );

  saveReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.SaveReport),
      pessimisticUpdate({
        run: (action: SaveReport) => {
          return this.reportsService
            .saveReport(action.payload)
            .pipe(map((res) => new SaveReportSucceeded(res)));
        },
        onError: (action: SaveReport, error: any) => {
          //eslint-disable-line
          return new SaveReportFailed(error);
        },
      })
    )
  );

  updateSelectedReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.UpdateSelectedReport),
      pessimisticUpdate({
        run: (action: UpdateSelectedReport) => {
          const { uid, payload, replace } = action;
          return this.reportsService
            .updateReportById(uid, payload, replace)
            .pipe(map((res) => new UpdateSelectedReportSucceeded(res.data)));
        },
        onError: (action: UpdateSelectedReport, error) => {
          //eslint-disable-line
          return new UpdateSelectedReportFailed(error);
        },
      })
    )
  );

  deleteSelectedReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.DeleteSelectedReport),
      pessimisticUpdate({
        run: (action: DeleteSelectedReport) => {
          return this.reportsService
            .deleteReportById(action.payload)
            .pipe(map(() => new DeleteSelectedReportSucceeded()));
        },
        onError(action: DeleteSelectedReport, error) {
          //eslint-disable-line
          return new DeleteSelectedReportFailed(error);
        },
      })
    )
  );

  duplicateSelectedReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.DuplicateSelectedReport),
      pessimisticUpdate({
        run: (action: DuplicateSelectedReport): any => {
          return this.reportService
            .cloneReport({
              body: action.payload,
            })
            .pipe(
              map(
                (res: OApiRespMediumReportQueryViewModel) =>
                  new DuplicateSelectedReportSucceeded(
                    Json2TypescriptHelper.convertToEntity(
                      res,
                      OApiRespMediumReportQueryViewModel
                    ).data
                  )
              )
            );
        },
        onError(action: DuplicateSelectedReport, error) {
          //eslint-disable-line
          return new DuplicateSelectedReportFailed(error);
        },
      })
    )
  );

  exportSelectedReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.ExportSelectedReport),
      pessimisticUpdate({
        run: (action: ExportSelectedReport) => {
          const payload = action.payload;
          return this.reportsService.exportReportById(
            payload.uid,
            payload.filename,
            () => new ExportSelectedReportSucceeded()
          );
        },
        onError(action: ExportSelectedReport, error) {
          //eslint-disable-line
          return new ExportSelectedReportFailed(error);
        },
      })
    )
  );

  loadSelectedReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.LoadSelectedReport),
      fetch({
        run: (action: LoadSelectedReport) => {
          return this.reportsService.getReportById(action.payload).pipe(
            map((res) => {
              return new SelectedReportLoaded(
                res.data,
                action.selectedInstanceId
              );
            })
          );
        },
        onError: (action: LoadSelectedReport, error) => {
          return new SelectedReportLoadError(error, action.selectedInstanceId);
        },
      })
    )
  );

  loadReportColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.LoadReportColumns),
      fetch({
        run: () => {
          return new ReportColumnsLoaded();
        },
        onError(action: LoadReportColumns, error) {
          //eslint-disable-line
          return new ReportColumnsLoadError(error);
        },
      })
    )
  );

  loadReportFilterFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.LoadReportFilterFields),
      fetch({
        run: () => {
          return this.reportsService.getReportFieldNames().pipe(
            map((_res: any) => {
              const res = cloneDeep(_res);
              const operatorsTitleMap =
                this.inteleaseCommonService.getOperatorsTitleMap();
              const aggregationsTitleMap =
                this.reportsService.getAggregationsTitleMap();
              const {
                provisionFields,
                generalFields,
                fieldTypeToRelations,
                fieldTypeToOperationAggregations,
              } = res.data;
              for (const key in fieldTypeToRelations) {
                if (fieldTypeToRelations[key]) {
                  fieldTypeToRelations[key] = fieldTypeToRelations[key].map(
                    (item) => ({
                      label: operatorsTitleMap[item] || item,
                      value: item,
                    })
                  );
                }
              }
              for (const key in fieldTypeToOperationAggregations) {
                if (fieldTypeToOperationAggregations[key]) {
                  fieldTypeToOperationAggregations[key] =
                    fieldTypeToOperationAggregations[key].map((item) => ({
                      label: aggregationsTitleMap[item] || item,
                      value: item,
                    }));
                }
              }
              const provisionFieldsWithoutAllowedTypes = provisionFields.map(
                (item) => ({
                  ...item.namedSearchField,
                  docSetCategories: item.docSetCategories,
                  allowedTypes: item.allowedTypes.map((allowedType) => ({
                    label: PROVISIONS_DATA_CONST[allowedType].uiName,
                    value: allowedType,
                  })),
                })
              );
              const _generalFields = generalFields.map((item) => ({
                ...item,
                docSetCategories: [
                  ReportsService.GENERAL_DOC_SET_CATEGORY__VALUE,
                ],
                allowedTypes:
                  item.allowedTypes && item.allowedTypes.length
                    ? item.allowedTypes.map((allowedType) => ({
                        label: PROVISIONS_DATA_CONST[allowedType].uiName,
                        value: allowedType,
                      }))
                    : [
                        {
                          label: PROVISIONS_DATA_CONST[item.fieldType].uiName,
                          value: item.fieldType,
                        },
                      ],
              }));

              const payload = {
                filterProvisions: {
                  provisionFields: keyBy(
                    provisionFieldsWithoutAllowedTypes,
                    "fieldName"
                  ),
                  generalFields: keyBy(_generalFields, "fieldName"),
                },
                provisionsOperator: fieldTypeToRelations,
                fieldTypeToOperationAggregations,
              };
              return new ReportFilterFieldsLoaded(payload);
            })
          );
        },
        onError: (action: LoadReportFilterFields, error) => {
          //eslint-disable-line
          return new ReportFilterFieldsLoadError(error);
        },
      })
    )
  );

  loadAbstractsReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.LoadAbstractsReport),
      fetch({
        run: (action: LoadAbstractsReport) => {
          return this.reportsService
            .getAbstractsReport(action.payload, action.dashboardUid)
            .pipe(
              map((res) => {
                const { selectedInstanceId } = action;
                if (res.data.type === ReportTypesEnum.SIMPLE) {
                  return new AbstractsReportLoaded(
                    {
                      reportType: ReportTypesEnum.SIMPLE,
                      result: res.data.result.items,
                    },
                    selectedInstanceId
                  );
                } else {
                  return new AbstractsReportLoaded(
                    {
                      reportType: ReportTypesEnum.AGGREGATED,
                      result: res.data.result,
                    },
                    selectedInstanceId
                  );
                }
              })
            );
        },
        onError: (action: LoadAbstractsReport, error) => {
          return new AbstractsReportLoadError(error, action.selectedInstanceId);
        },
      })
    )
  );

  deleteMultipleReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.DeleteMultipleReport),
      pessimisticUpdate({
        run: (action: DeleteMultipleReport) => {
          const { selectedReportsUids } = action.payload;
          return this.reportsService
            .deleteReportsByIds(selectedReportsUids)
            .pipe(map((res) => new DeleteMultipleReportSucceeded(res)));
        },
        onError(action: DeleteMultipleReport, error) {
          //eslint-disable-line
          return new DeleteMultipleReportFailed(error);
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private reportsService: ReportsService,
    private inteleaseCommonService: InteleaseCommonService,
    private reportService: ReportService
  ) {}
}
