<div
  class="modal-content"
  [@animateStagger]="{ value: '100' }"
  cdkDragBoundary="body"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  ngResizable
  [rzMinHeight]="isDatePickerOpen ? 850 : 530"
  [rzMinWidth]="634"
  (rzResizing)="onResizing($event)"
  rzHandles="e,w,se"
>
  <div class="modal-header clearfix text-left" cdkDragHandle>
    <button
      style="padding: 10px"
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5>Options Reminder calculator</h5>
  </div>

  <div class="modal-body">
    <div
      class="form-container"
      [ngStyle]="{
        height: isDatePickerOpen
          ? 'calc(530px - 315px)'
          : isTableHidden
          ? '100%'
          : 'calc(100% - 200px)'
      }"
    >
      <div class="modal-body">
        <tabset [justified]="true" #staticTabs>
          <tab
            heading="Choose Reminder Type"
            id="ChooseReminderType"
            (selectTab)="onSelectTab($event)"
          >
            <mat-radio-group
              aria-label="Select an option"
              class="reminder-type"
              [(ngModel)]="reminderType"
            >
              <mat-radio-button [value]="ReminderTypeEnum.MULTIPLE"
                >Multiple Reminders</mat-radio-button
              >
              <mat-radio-button [value]="ReminderTypeEnum.ROLLING"
                >Rolling Reminders</mat-radio-button
              >
              <mat-radio-button [value]="ReminderTypeEnum.RECURRING"
                >Recurring Reminders</mat-radio-button
              >
            </mat-radio-group>
          </tab>
          <tab
            heading="Provide Inputs"
            id="ProvideInputs"
            (selectTab)="onSelectTab($event)"
          >
            <div
              class="provide-inputs-container"
              [ngClass]="{ loading: regularFieldsLoading }"
              #provideInputsContainer
              [style.gridTemplateRows]="
                !isDatePickerOpen && isTableHidden ? '1fr' : '150px 7fr'
              "
              [style.height.px]="!isDatePickerOpen && isTableHidden ? 350 : 466"
            >
              <div class="loading-spinner" *ngIf="regularFieldsLoading">
                <mat-progress-spinner
                  diameter="50"
                  mode="indeterminate"
                ></mat-progress-spinner>
              </div>
              <div class="provide-inputs">
                <div
                  class="form-rows form-field"
                  *ngIf="formData && !regularFieldsLoading"
                >
                  <ng-container *ngFor="let field of regularFields">
                    <il-options-calculator-input-field
                      *ngIf="
                        field.fieldKey !== 'optionType' &&
                        field.fieldKey !== 'clauseSummary'
                      "
                      [inputField]="formData[field.fieldKey]"
                      [openDatePickerFieldKey]="selectedFieldKey"
                      [toolTip]="getToolTip(field.fieldKey)"
                      (click)="onOpenDatePicker(field.fieldKey)"
                      (reset)="onFieldReset(field.fieldKey)"
                    >
                    </il-options-calculator-input-field>
                  </ng-container>
                </div>

                <div
                  class="option-item"
                  *ngIf="formData && !regularFieldsLoading"
                >
                  <il-options-calculator-input-field
                    [inputField]="formData.optionType"
                    [openDatePickerFieldKey]="selectedFieldKey"
                    [toolTip]="getToolTip(formData.optionType.fieldKey)"
                    (click)="onOpenDatePicker(formData.optionType.fieldKey)"
                  >
                  </il-options-calculator-input-field>
                  <div class="form-group">
                    <label>
                      Clause Summary
                      <i
                        class="fa fa-info-circle ml-1 info-icon"
                        container="modal-container"
                        [tooltip]="'The summary of the chosen Option Type'"
                      ></i>
                    </label>
                    <div class="clause-summary">
                      {{
                        getClauseSummaryText(formData?.optionType?.fieldValue)
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="date-container" *ngIf="isDatePickerOpen">
                <div class="date-picker-container">
                  <i
                    class="fa fa-close"
                    (click)="onDatePickerClosed()"
                    *ngIf="isDatePickerOpen"
                  ></i>
                  <bs-datepicker-inline
                    *ngIf="isDatePickerOpen"
                    [(bsValue)]="datePickerValue"
                    (bsValueChange)="onDateChange($event, selectedFieldKey)"
                  ></bs-datepicker-inline>
                </div>
              </div>

              <div
                class="table-container"
                [@animateStagger]="{ value: '100' }"
                *ngIf="!isTableHidden"
              >
                <table
                  class="table table-hover"
                  [@animate]="{
                    value: '*',
                    params: { y: '100px' }
                  }"
                >
                  <tr>
                    <ng-container *ngFor="let column of tableColumns">
                      <th class="text-center table-cell-value">
                        {{ column.title }}
                      </th>
                    </ng-container>
                  </tr>
                  <tr *ngFor="let item of calculatedItems; let i = index">
                    <ng-container *ngFor="let column of tableColumns">
                      <td>
                        <input
                          type="text"
                          [(ngModel)]="item[column.key]"
                          class="form-control text-center"
                        />
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="selectedTab === 'ProvideInputs'">
      <button
        type="button"
        class="btn btn-default btn-cons pull-left inline"
        style="padding: 8px"
        (click)="onResetClick()"
      >
        Reset
      </button>
      <button
        type="button"
        class="btn btn-primary btn-cons pull-left inline calculateBtn"
        (click)="onCalculateClick()"
      >
        Calculate
      </button>
      <button
        type="button"
        [disabled]="calculatedItems.length === 0 && isSavingCalculatedMention"
        class="btn btn-primary btn-cons pull-left inline importBtn"
        (click)="onImportClick()"
      >
        Import
      </button>
    </ng-container>
    <ng-container *ngIf="selectedTab === 'ChooseReminderType'">
      <button
        type="button"
        class="btn btn-default btn-cons pull-left inline"
        style="padding: 8px"
        (click)="onDismissModal()"
      >
        Cancel
      </button>
      <button
        type="button"
        [disabled]="calculatedItems.length === 0 && isSavingCalculatedMention"
        class="btn btn-primary btn-cons pull-left inline importBtn"
        (click)="onNext()"
      >
        Next
      </button>
    </ng-container>
  </div>
</div>
