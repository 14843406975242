/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CustomProvisionDataModel")
export class CustomProvisionDataModel {
  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";

  @JsonProperty("type", String, true)
  type?: string = "";

  @JsonProperty("uiName", String, true)
  uiName?: string = "";
}
