<div class="container-fluid pt-4 mt-4 px-16" [@fadeIn]="'fadeIn'">
  <div class="row">
    <div class="col-sm-12 task-list-table">
      <div class="table-responsive" id="web-task-list">
        <il-ui-data-table
          [expandable]="false"
          [rows]="rows"
          [scrollH]="true"
          [hasMenu]="false"
          [rowMenu]="rowMenu"
          [rowBatchMenu]="rowBatchMenu"
          [pagination]="pagination"
          [columns]="columns"
          [loadingIndicator]="isLoading"
          [showAddBtn]="false"
          [addBtnTooltip]="'Add a new task'"
          (rowClick)="onRowClick($event)"
        >
        </il-ui-data-table>
      </div>
    </div>
  </div>
</div>
