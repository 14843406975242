import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Colors, DateUtil, Guid } from "@@intelease/web/utils";
import {
  isExcludeAggregationFunction,
  ReportTypesEnum,
} from "@@intelease/app-models/reports";
import { ChartType } from "chart.js";
import { isString, uniqBy, first as _first } from "lodash";

export enum ServiceWorkerEvnetNames {
  TRANSFORM_REPORT_RESPONSE = "TRANSFORM_REPORT_RESPONSE",
}

@Injectable({
  providedIn: "root",
})
export class AppServiceWorkerService {
  worker: Worker;

  constructor() {
    //
  }

  setup() {
    // if (typeof Worker !== 'undefined') {
    //   this.worker = new Worker('../../ui.worker', {type: 'module'});
    // } else {
    //   // Web workers are not supported in this environment.
    //   // You should add a fallback so that your program still executes correctly.
    // }
  }

  public transformReportData(
    res: any,
    filterProvisions: any,
    reportColumns: any,
    fieldTypeToOperationAggregations: any,
    reportFilterFieldMap: any,
    reportType: ReportTypesEnum
  ): Observable<any> {
    return new Observable((obs) => {
      // const event = {
      //   type: 'TRANSFORM_REPORT_RESPONSE',
      //   payload: data
      // };
      // this.worker.postMessage(event);
      // this.worker.onmessage = (res) => {
      obs.next(
        this.transformReportResponse(
          res,
          filterProvisions,
          reportColumns,
          fieldTypeToOperationAggregations,
          reportFilterFieldMap,
          reportType
        )
      );
      obs.complete();
      // };
      return obs;
    });
  }

  public transformCalendarData(
    res: any,
    query,
    calendarQuery
  ): Observable<any> {
    return new Observable((obs) => {
      obs.next(this.transformCalendarResponse(res, query, calendarQuery));
      obs.complete();
      return obs;
    });
  }

  public prepareChartData(
    chartType: ChartType,
    rows: any[],
    columnsSize: number,
    colIndex: number,
    columns: any[],
    aggregationField: string
  ): Observable<any> {
    return new Observable((obs) => {
      obs.next(
        this.transformReportDataForChart(
          chartType,
          rows,
          columnsSize,
          colIndex,
          columns,
          aggregationField
        )
      );
      obs.complete();
      return obs;
    });
  }

  private transformReportDataForChart(
    chartType: ChartType,
    rows: any[],
    columnsSize: number,
    colIndex: number,
    columns: any[],
    aggregationField: string = "count"
  ) {
    const chartLabels: string[] = [];
    const xAxis = [];
    columns.forEach((col, index) => {
      col.selectedAggregationFunctions.forEach((item) => {
        if (
          item.selected &&
          (col.fieldType !== "DATE" ||
            (col.fieldType === "DATE" &&
              item.value !== "MAX" &&
              item.value !== "MIN"))
        ) {
          const obj = {
            title: item.label + " Of " + col.fieldUiName,
            value: this.prepareAxisValue(item),
            colIndex: index,
          };
          xAxis.push(obj);
        }
      });
    });
    let chartData = [];
    if (chartType === "bar" || chartType === "radar") {
      const chartSeries = { data: [] };
      rows.forEach((item, index) => {
        const label = this.prepareChartLabels(
          (_first(item.cells) as { value: unknown }).value
        );
        chartLabels.push(label);
        // const chartSeries = {data: new Array(item.cells.length).fill(undefined), label};
        chartSeries.data[index] = this.prepareChartValue(
          item.cells[colIndex][aggregationField]
        );
      });
      chartData = [chartSeries];
    } else if (
      chartType === "pie" ||
      chartType === "doughnut" ||
      chartType === "polarArea"
    ) {
      chartData = rows.map((item, index) => {
        const label = this.prepareChartLabels(
          (_first(item.cells) as { value: unknown }).value
        );
        chartLabels.push(label);
        return this.prepareChartValue(item.cells[colIndex][aggregationField]);
      });
    }

    return {
      chartLabels,
      chartData,
      xAxis,
    };
  }

  prepareAxisValue(item) {
    if (item.value === "UNIQUE_COUNT") return "uniqueCount";
    if (item.value === "RECORDS_COUNT") return "recordsCount";
    return item.value.toLowerCase();
  }

  private prepareChartValue(value) {
    if (value === "N/A") return 0;
    return value;
  }

  private prepareChartLabels(data) {
    if (isString(data)) {
      return data.length > 20 ? data.substr(0, 20) + "..." : data;
    }
    return data;
  }

  private transformCalendarResponse(res, query, calendarQuery) {
    const schedules = [];
    const colorsMap = {};
    res.forEach((item) => {
      const { abstractName, abstractUid } = item;
      Object.keys(item).forEach((key) => {
        if (key === "provisionsValues") {
          item.provisionsValues.forEach((provision) => {
            const { htmlName, provisionInfoUid: provisionId } = provision;
            if (!colorsMap[htmlName]) {
              colorsMap[htmlName] = Colors.generate();
            }
            provision.mentions.forEach((mention, index) => {
              const {
                id: mentionId,
                valueAsString,
                value,
                reminders,
              } = mention;
              if (valueAsString) {
                const scheduleObj = {
                  mentionId,
                  id: mentionId,
                  isReadOnly: true,
                  body: "...",
                  calendarId: "1",
                  title:
                    abstractName +
                    " / " +
                    query.provisionColumns[htmlName]["uiName"],
                  category: "allday",
                  dueDateClass: "",
                  bgColor: calendarQuery.color,
                  raw: {
                    provisionName: query.provisionColumns[htmlName]["uiName"],
                    abstractName,
                    abstractUid,
                    reminders: reminders || [],
                    htmlName,
                    mentionId,
                    mentionValue: valueAsString,
                    provisionLink: `/abstract-review/${abstractUid}/pdf/provisions/${provisionId}`,
                    mentionLink: `/abstract-review/${abstractUid}/pdf/provisions/${provisionId}/mentions/${mentionId}`,
                    abstractLink: `/individual-abstract/${abstractUid}/related-documents`,
                  },
                  start: DateUtil.startOf(new Date(valueAsString), "day"),
                  end: DateUtil.startOf(new Date(valueAsString), "day"),
                };
                schedules.push(scheduleObj);
              }
            });
          });
        } else if (key === "generalValues") {
          Object.keys(item.generalValues).forEach((provKey) => {
            if (!colorsMap[provKey]) {
              colorsMap[provKey] = Colors.generate();
            }
            if (provKey !== "name") {
              const { reminders } = item.generalValues[provKey];
              const scheduleObj = {
                id: Guid.generate(),
                isReadOnly: true,
                body: "...",
                calendarId: "1",
                title:
                  abstractName +
                  " / " +
                  query.generalColumns[provKey]["uiName"],
                category: "allday",
                bgColor: calendarQuery.color,
                dueDateClass: "",
                raw: {
                  generalFieldName: query.generalColumns[provKey]["uiName"],
                  abstractName,
                  reminders: reminders || [],
                  htmlName: provKey,
                  abstractUid,
                  abstractLink: `/individual-abstract/${abstractUid}/related-documents`,
                },
                start: DateUtil.startOf(
                  new Date(item.generalValues[provKey]),
                  "day"
                ),
                end: DateUtil.startOf(
                  new Date(item.generalValues[provKey]),
                  "day"
                ),
              };
              schedules.push(scheduleObj);
            }
          });
        }
      });
    });
    return {
      schedules,
      colorsMap,
    };
  }

  private transformReportResponse(
    res,
    filterProvisions,
    reportColumns: any,
    fieldTypeToOperationAggregations: any,
    reportFilterFieldMap: any,
    reportType: ReportTypesEnum
  ) {
    const newData = {};
    const columns: any = [];
    const columnObj = {
      isHidden: false,
      sortable: true,
      name: "",
      prop: "",
      type: "STRING",
      target: "",
      category: "",
      fieldType: "STRING",
      aggregationFunctions: {},
      allowedTypes: [],
      selectedAllowedTypes: [],
      selectedAggregationFunctions: [],
      fieldName: "",
      fieldUiName: "",
    };
    /* if we have unknown fields from server, show them as standalone columns e.g:
    given this data from server:
      {
        abstractUid: ...,
        abstractName: ...,
        abstractFileNums: ...,
        provisionsValues: [...],
        generalValues: [...]
      }
    since 'abstractFileNums' is unknown field, push it to 'columns'
    */
    if (res && res[0]) {
      Object.keys(res[0]).forEach((key) => {
        if (
          key !== "provisionsValues" &&
          key !== "generalValues" &&
          key !== "abstractUid" &&
          key !== "abstractName"
        ) {
          columnObj.name = key;
          columnObj.prop = key;
          columns.push({ ...columnObj });
          newData[key] = "";
        }
      });
    }
    const result = {};
    const _filterProvisions = {};
    reportColumns.provisionViewColumns.forEach((provisionColumn) => {
      const _provision = provisionColumn.split("::");
      _filterProvisions[_provision[0]] = _provision;
      result[_provision[0]] = 1;
    });
    res.forEach((item) => {
      item.provisionsValues.forEach((provision) => {
        const provLen = provision.mentions.length;
        const { htmlName } = provision;
        if (!result[htmlName]) {
          result[htmlName] = provLen;
        }

        if (provLen > result[htmlName]) {
          result[htmlName] = provLen;
        }
      });
    });
    reportColumns.generalViewColumns.forEach((generalColumn) => {
      const _provision = generalColumn.split("::");
      columnObj.name = _provision[1];
      // columnObj.name = filterProvisions.generalFields[_provision[1]];
      columnObj.prop = _provision[0];
      columnObj.sortable = true;
      if (_provision[0] === "name") {
        columnObj.type = "LINK";
        columnObj.target = "_blank";
      } else {
        columnObj.type = "STRING";
      }
      columnObj.category = "GENERAL";
      columnObj.fieldType = _provision[2];
      columnObj.allowedTypes =
        reportFilterFieldMap.generalFields[columnObj.prop].allowedTypes || [];
      columnObj.allowedTypes = columnObj.allowedTypes.map((item) => ({
        ...item,
        selected: true,
      }));
      const aggregationFunctionsObj = {};
      columnObj.selectedAllowedTypes = [];
      columnObj.selectedAggregationFunctions = [];
      columnObj.allowedTypes.forEach((allowedType, index) => {
        if (allowedType.selected) {
          columnObj.selectedAllowedTypes.push(allowedType);
        }
        aggregationFunctionsObj[allowedType.value] =
          fieldTypeToOperationAggregations[allowedType.value].map((item) => {
            const aggregationFunction = {
              ...item,
              selected: false,
            };
            if (aggregationFunction.value === "COUNT") {
              aggregationFunction.selected = true;
            }
            if (allowedType.selected) {
              columnObj.selectedAggregationFunctions.push(aggregationFunction);
            }
            return aggregationFunction;
          }) || [];
      });
      columnObj.selectedAggregationFunctions = uniqBy(
        columnObj.selectedAggregationFunctions,
        "value"
      );
      if (columnObj.selectedAllowedTypes.length > 1) {
        columnObj.selectedAggregationFunctions =
          columnObj.selectedAggregationFunctions.filter((item) => {
            if (isExcludeAggregationFunction(item.value)) {
              return item;
            }
          });
      }
      columnObj.aggregationFunctions = aggregationFunctionsObj;
      columnObj.fieldUiName = columnObj.name;
      columns.push({ ...columnObj });
      newData[_provision[0]] = "";
    });

    // if (filterProvisions && filterProvisions.provisionFields) {
    // tslint:disable-next-line:forin
    for (const key in result) {
      let i = 1;
      while (i <= result[key]) {
        columnObj.name =
          `${_filterProvisions[key][1]}` +
          (reportType === ReportTypesEnum.SIMPLE ? ` (${i})` : "");
        // columnObj.name = `${filterProvisions.provisionFields[key]['uiName']} (${i})`;
        columnObj.prop = key + i;
        columnObj.sortable = false;
        columnObj.type = "LINK";
        columnObj.target = "_blank";
        columnObj.category = "PROVISION";
        columnObj.fieldType = _filterProvisions[key][2];
        columnObj.allowedTypes =
          reportFilterFieldMap.provisionFields[key].allowedTypes || [];
        columnObj.allowedTypes = columnObj.allowedTypes.map((item) => ({
          ...item,
          selected: false,
        }));
        const aggregationFunctionsObj = {};
        columnObj.selectedAllowedTypes = [];
        columnObj.selectedAggregationFunctions = [];
        columnObj.allowedTypes.forEach((allowedType, index) => {
          if (allowedType.selected) {
            columnObj.selectedAllowedTypes.push(allowedType);
          }
          aggregationFunctionsObj[allowedType.value] =
            fieldTypeToOperationAggregations[allowedType.value].map((item) => {
              const aggregationFunction = {
                ...item,
                selected: false,
              };
              if (allowedType.selected) {
                columnObj.selectedAggregationFunctions.push(
                  aggregationFunction
                );
              }
              return aggregationFunction;
            }) || [];
        });
        columnObj.selectedAggregationFunctions = uniqBy(
          columnObj.selectedAggregationFunctions,
          "value"
        );
        if (columnObj.selectedAllowedTypes.length > 1) {
          columnObj.selectedAggregationFunctions =
            columnObj.selectedAggregationFunctions.filter((item) => {
              if (isExcludeAggregationFunction(item.value)) {
                return item;
              }
            });
        }
        columnObj.fieldName = key;
        columnObj.aggregationFunctions = aggregationFunctionsObj;
        columnObj.fieldUiName = _filterProvisions[key][1];
        columns.push({ ...columnObj });
        i++;
      }
    }
    // }

    // reportColumns.generalViewColumns.forEach(generalColumn => {
    //   const _provision = generalColumn.split('::');
    //   columnObj.name = _provision[1];
    //   // columnObj.name = filterProvisions.generalFields[_provision[1]];
    //   columnObj.prop = _provision[0];
    //   columnObj.sortable = true;
    //   if (_provision[0] === 'name') {
    //     columnObj.type = 'LINK';
    //     columnObj.target = '_blank';
    //   } else {
    //     columnObj.type = 'STRING';
    //   }
    //   columns.push({...columnObj});
    //   newData[_provision[0]] = '';
    // });
    // columns.unshift({isHidden: true, name: 'checkbox', prop: 'checkbox', type: 'CHECKBOX'});
    // columns.push({isHidden: false, prop: 'action', sortable: false, type: 'ACTION'});
    const rows = [];
    res.forEach((item) => {
      const newDataCp = { ...newData };
      Object.keys(item).forEach((key) => {
        if (key === "provisionsValues") {
          item.provisionsValues.forEach((provision) => {
            const { htmlName, provisionInfoUid: provisionId } = provision;
            provision.mentions.forEach((mention, index) => {
              const { id: mentionId, valueAsString, value } = mention;
              newDataCp[htmlName + (index + 1)] = {
                value: valueAsString,
                link: `/abstract-review/${item.abstractUid}/pdf/provisions/${provisionId}/mentions/${mentionId}`,
              };
              newDataCp[htmlName + (index + 1)]["originalValue"] = value || {};
            });
          });
        } else if (key === "generalValues") {
          Object.keys(item.generalValues).forEach((provKey) => {
            if (provKey === "name") {
              newDataCp[provKey] = {
                value: item.generalValues[provKey],
                link: `/individual-abstract/${item.abstractUid}/related-documents`,
              };
            } else {
              newDataCp[provKey] = item.generalValues[provKey];
            }
          });
        } else if (key !== "abstractUid") {
          newDataCp[key] = item[key];
        }
      });
      rows.push({ ...newDataCp });
    });
    return {
      columns: columns,
      rows: rows,
    };
  }
}
