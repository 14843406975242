import { JsonObject, JsonProperty } from "json2typescript";
import { FullProvisionModel } from "@@intelease/web/common/models/provision";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";

@JsonObject("FullProvisionGroupModel")
export class FullProvisionGroupModel {
  static view = "fullGroup";

  @JsonProperty("uid", String)
  uid = "";

  editStatus = undefined;

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("version", Number)
  version = 0;

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("creationDate", DateTimeConvertService)
  creationDate: Date = undefined;

  @JsonProperty("createdByEmail", String)
  createdByEmail = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("lastModifiedByEmail", String)
  lastModifiedByEmail = "";

  @JsonProperty("provisions", [FullProvisionModel])
  provisions: FullProvisionModel[] = [];

  provisionGroupList: any = [];
}
