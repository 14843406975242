/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReturnParamsRequestMultiPdfProvViewTypeModel")
export class OApiReturnParamsRequestMultiPdfProvViewTypeModel {
  @JsonProperty("view", Any, true)
  view?:
    | "FULL"
    | "FULL_DEFAULT_OPTIONS_VALUE"
    | "FULL_VAL"
    | "FULL_POP"
    | "FULL_DEFAULT_OPTIONS_VALUE_POP"
    | "SEARCH"
    | "NONE" = undefined;
}
