import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("PaginationModel")
export class PaginationModel {
  @JsonProperty("currentPage", Number)
  currentPage = 0;

  @JsonProperty("perPage", Number)
  perPage = 25;

  @JsonProperty("totalItems", Number)
  totalItems = 100;

  @JsonProperty("inTable", Boolean)
  inTable = true;

  @JsonProperty("showTotal", Boolean)
  showTotal = false;

  @JsonProperty("ShowSizeChanger", Boolean)
  ShowSizeChanger = false;
}
