import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { Action } from "./model/action";
import { ActionRegistry } from "./model/actionregistry";
import { FormProperty } from "./model/formproperty";
import { FormPropertyFactory } from "./model/formpropertyfactory";
import { SchemaPreprocessor } from "./model/schemapreprocessor";
import { ValidatorRegistry } from "./model/validatorregistry";
import { Validator } from "./model/validator";
import { Binding } from "./model/binding";
import { BindingRegistry } from "./model/bindingregistry";

import { SchemaValidatorFactory } from "./schemavalidatorfactory";
import { WidgetFactory } from "./widgetfactory";
import { TerminatorService } from "./terminator.service";
import { PropertyBindingRegistry } from "./property-binding-registry";
import { cloneDeep } from "lodash";
import { isEmpty } from "lodash";
import { skip } from "rxjs/operators";
import { FormElementComponent } from "./formelement.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

const diff = require("deep-diff");

export function useFactory(
  schemaValidatorFactory,
  validatorRegistry,
  propertyBindingRegistry
) {
  return new FormPropertyFactory(
    schemaValidatorFactory,
    validatorRegistry,
    propertyBindingRegistry
  );
}

@Component({
  selector: "sf-form",
  template: `
    <form>
      <sf-form-element
        *ngIf="rootProperty"
        [updateOn]="updateOn"
        [formProperty]="rootProperty"
      ></sf-form-element>
    </form>
  `,
  providers: [
    ActionRegistry,
    ValidatorRegistry,
    PropertyBindingRegistry,
    BindingRegistry,
    SchemaPreprocessor,
    WidgetFactory,
    {
      provide: FormPropertyFactory,
      useFactory: useFactory,
      deps: [
        SchemaValidatorFactory,
        ValidatorRegistry,
        PropertyBindingRegistry,
      ],
    },
    TerminatorService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormComponent,
      multi: true,
    },
  ],
})
export class FormComponent implements OnChanges, ControlValueAccessor {
  destroyRef = inject(DestroyRef);
  @Input() schema: any = null;

  @Input() model: any;

  @Input() updateOn: "change" | "blur" | "submit";

  @Input() actions: { [actionId: string]: Action } = {};

  @Input() validators: { [path: string]: Validator } = {};

  @Input() bindings: { [path: string]: Binding } = {};

  @Output() change = new EventEmitter<{ value: any }>();

  @Output() modelChange = new EventEmitter<any>();

  @Output() isValid = new EventEmitter<boolean>();

  @Output() errorChange = new EventEmitter<{ value: any[] }>();

  @Output() errorsChange = new EventEmitter<{ value: any }>();

  rootProperty: FormProperty = null;

  private onChangeCallback: any;

  @ViewChild(FormElementComponent)
  formElementComponent: FormElementComponent;

  constructor(
    private formPropertyFactory: FormPropertyFactory,
    private actionRegistry: ActionRegistry,
    private validatorRegistry: ValidatorRegistry,
    private bindingRegistry: BindingRegistry,
    private cdr: ChangeDetectorRef,
    private terminator: TerminatorService
  ) {}

  writeValue(obj: any) {
    if (this.rootProperty) {
      this.rootProperty.reset(obj, false);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
    if (this.rootProperty) {
      this.rootProperty.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onValueChanges.bind(this));
    }
  }

  // TODO implement
  registerOnTouched(fn: any) {}

  // TODO implement
  // setDisabledState(isDisabled: boolean)?: void

  ngOnChanges(changes: SimpleChanges) {
    if (changes.validators) {
      this.setValidators();
    }

    if (changes.actions) {
      this.setActions();
    }

    if (changes.bindings) {
      this.setBindings();
    }

    if (this.schema && !this.schema.type) {
      this.schema.type = "object";
    }

    if (this.hasSchemaChange(changes)) {
      this.onSchemaChange(changes);
    }

    if (this.hasModelChange(changes)) {
      if (this.rootProperty && this.model) {
        this.rootProperty.reset(this.model, false);
        this.cdr.detectChanges();
      }
    }
  }

  private hasSchemaChange(changes: SimpleChanges): boolean {
    return (
      this.schema &&
      changes.schema &&
      this.isChanged(changes.schema.currentValue, changes.schema.previousValue)
    );
  }

  private onSchemaChange(changes: SimpleChanges): void {
    SchemaPreprocessor.preprocess(this.schema);
    const newRootProperty = this.formPropertyFactory.createProperty(
      this.schema
    );

    if (
      this.rootProperty &&
      !this.isChanged(this.rootProperty.schema, newRootProperty.schema)
    ) {
      return;
    }

    this.rootProperty = newRootProperty;

    if (!changes.schema.firstChange) {
      this.terminator.destroy();
    }

    if (this.model) {
      // this.rootProperty.reset(this.model, false);
    }

    this.rootProperty.valueChanges
      .pipe(skip(1))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onValueChanges.bind(this));

    this.rootProperty.errorsChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.errorChange.emit({ value: value });
        this.isValid.emit(!(value && value.length));
      });
  }

  private hasModelChange(changes: SimpleChanges): boolean {
    return (
      this.schema &&
      ((changes.schema &&
        this.isChanged(
          changes.schema.currentValue,
          changes.schema.previousValue
        )) ||
        (changes.model &&
          this.isChanged(
            changes.model.currentValue,
            changes.model.previousValue
          )))
    );
  }

  private isChanged<T>(prevValue: T, currentValue: T): boolean {
    return diff(prevValue, currentValue);
  }

  private setValidators() {
    this.validatorRegistry.clear();
    if (this.validators) {
      for (const validatorId in this.validators) {
        if (this.validators.hasOwnProperty(validatorId)) {
          this.validatorRegistry.register(
            validatorId,
            this.validators[validatorId]
          );
        }
      }
    }
  }

  private setActions() {
    this.actionRegistry.clear();
    if (this.actions) {
      for (const actionId in this.actions) {
        if (this.actions.hasOwnProperty(actionId)) {
          this.actionRegistry.register(actionId, this.actions[actionId]);
        }
      }
    }
  }

  private setBindings() {
    this.bindingRegistry.clear();
    if (this.bindings) {
      for (const bindingPath in this.bindings) {
        if (this.bindings.hasOwnProperty(bindingPath)) {
          this.bindingRegistry.register(
            bindingPath,
            this.bindings[bindingPath]
          );
        }
      }
    }
  }

  public reset() {
    this.rootProperty.reset(null, true);
  }

  private setModel(value: any) {
    if (this.model) {
      try {
        Object.assign(this.model, value);
      } catch {}
    } else {
      this.model = { ...value };
    }
  }

  private onValueChanges(value) {
    // this method is supposed to emit changes to 'value' when it is different from 'model'
    //  because we want to make 'value' and 'model' consistent, at some point their values are the same but this method get(s) called
    //  so we need to ignore calling callback(s) when they're the same
    if (diff(value, this.model)) {
      if (this.onChangeCallback) {
        this.onChangeCallback(value);
      }

      // two way binding is used
      if (this.modelChange.observers.length > 0) {
        this.modelChange.emit(value);
      }

      this.change.emit({ value: value });

      this.setModel(value);
    }
  }

  public reloadValueFromModel() {
    if (this.rootProperty) {
      this.rootProperty.reset(cloneDeep(this.model), true);
    }
  }
}
