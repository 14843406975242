/* tslint:disable */
/* eslint-disable */
import { FullValMultiplePdfProvisionViewModel } from "./full-val-multiple-pdf-provision-view-model";
import { ProvisionInfoViewModel } from "./provision-info-view-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullValMultiPdfProvViewModel")
export class FullValMultiPdfProvViewModel {
  /**
   * the user-facing unique id of this record
   */

  @JsonProperty("abstractUid", String, true)
  abstractUid?: string = "";

  /**
   * whether this provision is newly attached to the record
   */

  @JsonProperty("attached", Boolean, true)
  attached?: boolean = undefined;

  /**
   * the scope for this provision
   */

  @JsonProperty("entityScope", Any, true)
  entityScope?: "GLOBAL" | "ACCOUNT" | "USER" | "ABSTRACT" = undefined;

  /**
   * the unique human-readable id of this provision
   */

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty(
    "multiplePdfProvision",
    FullValMultiplePdfProvisionViewModel,
    true
  )
  multiplePdfProvision?: FullValMultiplePdfProvisionViewModel = undefined;

  @JsonProperty("provisionInfo", ProvisionInfoViewModel, true)
  provisionInfo?: ProvisionInfoViewModel = undefined;

  /**
   * the format of this provision
   */

  @JsonProperty("provisionType", String, true)
  provisionType?: string = "";

  /**
   * the user-facing unique id of this provision
   */

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";

  /**
   * the review status of this provision in this record
   */

  @JsonProperty("reviewStatus", Any, true)
  reviewStatus?:
    | "REVIEWED"
    | "NEED_REVIEW"
    | "NEED_REVIEW_TOUCHED"
    | "DEFAULT"
    | "COMPLETED_BUT_NEED_REVIEW" = undefined;

  /**
   * the status of this provision in this record
   */

  @JsonProperty("status", Any, true)
  status?: "COMPLETED" | "UNTOUCHED" | "NOT_COMPLETED" = undefined;

  /**
   * the mentions for the sub-provisions for this provision in this record
   */

  @JsonProperty("subProvisionMap", Any, true)
  subProvisionMap?: {
    [key: string]: FullValMultiPdfProvViewModel;
  } = {};

  /**
   * the user-facing unique id for the mentions for this provision in this record
   */

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
