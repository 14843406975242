import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { EntityFieldModel } from "@@intelease/web/intelease/models";
import { ComponentStateHooksInterface } from "@@intelease/app-models/common";
import { CalendarFacade } from "@@intelease/app-state/calendar";
import { CALENDAR_FILTER_FORM } from "./calendar-filter.form";
import { InteleaseNotificationService } from "@@intelease/web/intelease/services";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { ItlsFilterService } from "@@intelease/web/ui";
import { ReportLogicalOperatorEnum } from "@intelease/constants";
import { cloneDeep, isEmpty, uniqBy } from "lodash";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "intelease-calendar-filter",
  templateUrl: "./calendar-filter.component.html",
  styleUrls: ["./calendar-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarFilterComponent implements ComponentStateHooksInterface {
  @Input() calendarUid: string;
  calendarQueryUid: string;
  @Input() isEditing: boolean;
  @Input() mode: ComponentModeEnum = ComponentModeEnum.EDIT;
  calendarFilterForm: FormGroup = CALENDAR_FILTER_FORM.create();
  selectedFilter: {
    operator: ReportLogicalOperatorEnum;
    value: [];
  } = {
    operator: ReportLogicalOperatorEnum.AND,
    value: [],
  };
  filterFieldsModel = [];
  calendarQuery = {};

  @Input() set filterModel(value: any) {
    this._filterModel = value;
    this.selectedFilter = value;
  }

  get filterModel() {
    return this._filterModel;
  }

  @Input() set model(value) {
    this._model = value;
    const { color, name, description, query, uid } = value;
    const { filters, generalColumns, provisionColumns } = query;
    this.filterColor = color;
    this.calendarQueryUid = uid;
    this.calendarFilterForm.patchValue({ name, description });
    this.selectedFilter =
      filters && !isEmpty(filters)
        ? ItlsFilterService.deSerializeFilter(cloneDeep(filters))
        : cloneDeep(this.filterModel);
    if (isEmpty(filters) || !filters) {
      this.selectedFilter = {
        operator: ReportLogicalOperatorEnum.AND,
        value: [],
      };
    }
    this.selectedColumns = {
      generalViewColumns: generalColumns,
      provisionViewColumns: provisionColumns,
    };
    this.filterFieldsModel = ItlsFilterService.deSerializeColumns(
      this.selectedColumns
    );
    this.calendarQuery = {
      ...this.calendarQuery,
      filters: cloneDeep(filters),
      ...this.selectedColumns,
    };
  }

  get model() {
    return this._model;
  }

  private _model;

  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  @Output() updated: EventEmitter<any> = new EventEmitter<any>();
  _filterModel = {};
  selectedFilterTab = 0;
  propertyControl = new FormControl();
  stateGroupOptions: Observable<
    Array<{ title: string; items: Array<EntityFieldModel> }>
  >;
  filterColor = "green";
  filterFields: {
    fieldName: string;
    fieldType: string;
    uiName: string;
    category: string;
    docSetCategories: string[];
  }[] = [];
  selectedColumns;
  componentModeEnum = ComponentModeEnum;
  destroyRef = inject(DestroyRef);

  constructor(
    public readonly calendarFacade: CalendarFacade,
    private readonly inteleaseNotificationService: InteleaseNotificationService
  ) {
    this.initActions();
    this.initListeners();
  }

  initActions(): void {
    this.calendarFacade.calendarFilterFieldsLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoaded) => {
        if (!isLoaded) {
          this.calendarFacade.loadCalendarFilterFields();
        }
      });
  }

  initListeners(): void {
    this.calendarFacade.calendarFilterFields$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((calendarFilterFields) => {
        if (calendarFilterFields) {
          this.filterFields = calendarFilterFields;
        }
      });
    this.calendarFacade.isSaveCalendarQuerySucceeded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          this.created.emit();
          this.reset();
        }
      });
  }

  onFilterModelChange(evt) {
    this._filterModel = evt;
    this.calendarQuery = {
      ...this.calendarQuery,
      filters: ItlsFilterService.serializeFilter(cloneDeep(evt)),
    };
  }

  onCalendarFieldsChange(evt) {
    // @FIXME (Mohammad.haji) it's triggering function more than one
    const uniqFields = cloneDeep(uniqBy(evt, "fieldName"));
    this.filterFieldsModel = uniqFields;
    this.selectedColumns = ItlsFilterService.serializeColumns(
      cloneDeep(uniqFields)
    );
    this.calendarQuery = {
      ...this.calendarQuery,
      ...this.selectedColumns,
    };
  }

  onCreateCalendarQueryClick() {
    if (this.calendarFilterForm.valid) {
      this.calendarQuery = {
        ...this.calendarQuery,
        ...this.calendarFilterForm.getRawValue(),
        color: this.filterColor,
      };
      if (this.mode !== ComponentModeEnum.ADD) {
        this.calendarFacade.saveCalendarQuery(
          this.calendarUid,
          this.calendarQuery
        );
      } else {
        this.created.emit(this.calendarQuery);
      }
    } else {
      this.inteleaseNotificationService.openSnackBar("Name is Required");
    }
  }

  onUpdateCalendarQueryClick() {
    if (this.calendarFilterForm.valid) {
      this.calendarQuery = {
        ...this.calendarQuery,
        ...this.calendarFilterForm.getRawValue(),
        color: this.filterColor,
      };
      this.calendarFacade.updateCalendarQuery(
        this.calendarUid,
        this.calendarQueryUid,
        this.calendarQuery
      );
      this.updated.emit(this.calendarQuery);
    } else {
      this.inteleaseNotificationService.openSnackBar("Name is Required");
    }
  }

  private reset() {
    this.calendarQuery = {};
    this.calendarFilterForm.reset();
    this.selectedColumns = undefined;
    this.filterFieldsModel = [];
    this.selectedFilterTab = 0;
    this.selectedFilter = {
      operator: ReportLogicalOperatorEnum.AND,
      value: [],
    };
    this._filterModel = {};
  }
}
