/* tslint:disable */
/* eslint-disable */
import { TermCalculatorInputsApiV2DtoModel } from "./term-calculator-inputs-api-v-2-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqTermCalculatorInputsV2DtoModel")
export class OApiReqTermCalculatorInputsV2DtoModel {
  @JsonProperty("data", TermCalculatorInputsApiV2DtoModel, true)
  data?: TermCalculatorInputsApiV2DtoModel = undefined;
}
