/* tslint:disable */
/* eslint-disable */
import { BookmarkViewModel } from "./bookmark-view-model";
import { DocSetFullDocOutlineViewModel } from "./doc-set-full-doc-outline-view-model";
import { DocSetPathModel } from "./doc-set-path-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocSetTableOfContentsViewModel")
export class DocSetTableOfContentsViewModel {
  @JsonProperty("bookmarks", [[BookmarkViewModel]], true)
  bookmarks?: Array<Array<BookmarkViewModel>> = [];

  @JsonProperty("creationDate", DateTimeConvertService, true)
  creationDate?: Date | undefined = undefined;

  @JsonProperty("docOutlines", [DocSetFullDocOutlineViewModel], true)
  docOutlines?: Array<DocSetFullDocOutlineViewModel> = [];

  @JsonProperty("docSetPath", DocSetPathModel, true)
  docSetPath?: DocSetPathModel = undefined;

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
