import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
} from "@angular/core";
import { NgSelectComponent } from "@ng-select/ng-select";

import { ControlWidget } from "../../widget";
import {
  cloneDeep,
  filter,
  flatten,
  includes,
  isEmpty,
  keys,
  map,
  pickBy,
  zipObject,
} from "lodash";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from "@angular/material/autocomplete";

@Component({
  selector: "sf-multi-select-dropdown-widget",
  template: `
    <div
      class="widget-group form-group"
      *ngIf="schema?.isGrouped"
      style="position: relative;"
    >
      <label
        *ngIf="schema.title"
        [attr.for]="id"
        class="horizontal control-label"
      >
        {{ schema.title }}
      </label>

      <span *ngIf="schema.description" class="formHelp"
        >{{ schema.description
        }}<i
          *ngIf="schema.tooltip"
          class="fa fa-info-circle ml-1"
          [matTooltip]="schema.tooltip"
        ></i
      ></span>

      <input
        #searchInput
        class="form-control"
        type="text"
        [value]="value"
        (input)="autocompleteInput($event.target)"
        [matAutocomplete]="autoGroup"
        [disabled]="schema.readOnly"
      />

      <div
        *ngIf="!schema.readOnly"
        class="dropdown-trigger"
        style="
                    position: absolute;
                    top: 50%;
                    bottom: 0;
                    right: 5px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                "
        [ngStyle]="{ 'z-index': autoGroup.isOpen ? 2001 : 1 }"
        click-stop-propagation
      >
        <mat-icon
          class="arrow-btn"
          [svgIcon]="'icon-cancel'"
          (click)="clearAutoComplete()"
          style="
                        margin-right: 3px;
                        min-width: 8px;
                        min-height: 8px;
                        width: 8px;
                        height: 8px;
                    "
        >
        </mat-icon>
        <mat-icon
          class="arrow-btn"
          [svgIcon]="autoGroup.isOpen ? 'icon-arrow-up' : 'icon-arrow-down'"
          (click)="onArrowClick()"
          style="
                        min-width: 12px;
                        min-height: 12px;
                        width: 12px;
                        height: 12px;
                    "
        >
        </mat-icon>
      </div>

      <mat-autocomplete
        #autoGroup="matAutocomplete"
        (optionSelected)="onOptionSelected($event)"
        class="multi-select-dropdown"
      >
        <mat-optgroup
          *ngFor="let group of keys(items)"
          [label]="group"
          class="option-group"
        >
          <mat-option *ngFor="let item of items?.[group]" class="Milad">
            {{ item.title }}
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
    </div>
    <div class="widget form-group" *ngIf="!schema?.isGrouped">
      <label
        *ngIf="schema.title"
        [attr.for]="id"
        class="horizontal control-label"
      >
        {{ schema.title }}
      </label>
      <span *ngIf="schema.description" class="formHelp">{{
        schema.description
      }}</span>

      <div
        (click)="onArrowClick()"
        class="dropdown-trigger"
        style="display: none;"
        [ngStyle]="{ 'z-index': select.isOpen ? 2001 : 1 }"
        click-stop-propagation
      >
        <mat-icon
          class="arrow-btn"
          [svgIcon]="select.isOpen ? 'icon-arrow-up' : 'icon-arrow-down'"
        >
        </mat-icon>
      </div>

      <ng-select
        #select
        class="form-widget"
        [items]="schema.items"
        [formControl]="control"
        [addTag]="schema.options.ableToAddNewValues"
        [bindLabel]="schema.options.bindLabel"
        [bindValue]="schema.options.bindValue"
        [closeOnSelect]="schema.options.closeOnSelect"
        [multiple]="schema.options.multiple"
        [notFoundText]="schema.options.notFoundText"
        [placeholder]="schema.options.placeholder"
        [searchable]="schema.options.searchable"
        [loading]="schema.options.loading"
        (close)="onClose()"
        (open)="isOpen = true"
        [appendTo]="schema.options.appendToBody ? 'body' : null"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          appendTo="body"
          let-index="index"
        >
          <div *ngIf="!item?.email">
            <itls-icon
              matRipple
              *ngIf="item.icon"
              [svgIcon]="item.icon.type"
              [height]="item.icon.height"
              style="margin-right: 7px"
            ></itls-icon>
            {{ item.title }}
          </div>
          <div [matTooltip]="item?.email" *ngIf="item?.email">
            <itls-icon
              matRipple
              *ngIf="item.icon"
              [svgIcon]="item.icon.type"
              [height]="item.icon.height"
              style="margin-right: 7px"
            ></itls-icon>
            {{ item.title }}
          </div>
        </ng-template>
        <ng-template
          ng-label-tmp
          let-item="item"
          appendTo="body"
          let-index="index"
        >
          <div *ngIf="!item?.email">
            <itls-icon
              matRipple
              *ngIf="item.icon"
              [svgIcon]="item.icon.type"
              [height]="item.icon.height"
              style="margin-right: 7px"
            ></itls-icon>
            {{ item.title }}
          </div>
          <div
            [matTooltip]="item?.email"
            style="position: absolute; top: 25%; z-index: 1;"
            *ngIf="item?.email"
          >
            <itls-icon
              matRipple
              *ngIf="item.icon"
              [svgIcon]="item.icon.type"
              [height]="item.icon.height"
              style="margin-right: 7px"
            ></itls-icon>
            {{ item.title }}
          </div>
        </ng-template>
        <ng-template
          ng-multi-label-tmp
          let-items="items"
          let-clear="clear"
          *ngIf="!schema.readOnly"
        >
          <div class="ng-value" *ngFor="let item of $any(items | slice: 0:2)">
            <span class="ng-value-label">{{ item.title }}</span>
            <span
              class="ng-value-icon right"
              (click)="clear(item)"
              aria-hidden="true"
              >×</span
            >
          </div>
          <div class="ng-value" *ngIf="items.length > 2">
            <span class="ng-value-label">{{ items.length - 2 }} more...</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp *ngIf="schema.options.multiple">
          <div>
            <button
              class="btn btn-link"
              style="
                                font-size: 12px;
                                padding: 2px 8px;
                            "
              (click)="onSelectAll(); select.close()"
            >
              Select All
            </button>
            <button
              class="btn btn-link"
              style="
                                font-size: 12px;
                                padding: 2px 8px;
                            "
              (click)="onClearAll(); select.close()"
            >
              Clear All
            </button>
          </div>
        </ng-template>
      </ng-select>
    </div>
  `,
})
export class MultiSelectDropdownWidgetComponent
  extends ControlWidget
  implements OnInit
{
  @ViewChild(NgSelectComponent)
  ngSelect: NgSelectComponent;

  @ViewChild(MatAutocompleteTrigger) autoGroup: MatAutocompleteTrigger;

  public isOpen = false;

  public items;

  public value;

  public keys = keys;

  public onSelectAll() {
    this.control.patchValue(this.schema.items);
  }

  public onClearAll() {
    this.control.patchValue([]);
  }

  public onClose(): void {
    setTimeout(() => (this.isOpen = false), 200);
  }

  public onArrowClick(): void {
    if (!this.isOpen && !this.schema.isGrouped) {
      this.ngSelect.open();
    }
    if (this.schema.isGrouped) {
      this.autoGroup.panelOpen
        ? this.autoGroup.closePanel()
        : this.autoGroup.openPanel();
    }
  }

  public clearAutoComplete(): void {
    if (this.schema.isGrouped) {
      this.value = "";
      this.control.patchValue(this.value);
    }
  }

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  autocompleteInput(evt) {
    const { value } = evt;
    this.items = pickBy(
      zipObject(
        keys(this.schema.items),
        filter(
          map(keys(this.schema.items), (country) =>
            filter(this.schema?.items?.[country], (item) =>
              includes(item.value.toLowerCase(), value.toLowerCase())
            )
          )
        )
      ),
      (value) => !isEmpty(value)
    );
  }

  ngOnInit(): void {
    if (this.schema.isGrouped) {
      setTimeout(() => {
        this.value = this.control.value;
        this.items = this.schema.items;
        this.cdr.markForCheck();
      });
    }
  }

  onOptionSelected(item: MatAutocompleteSelectedEvent) {
    this.value = item.option.viewValue;
    this.control.patchValue(this.value);
  }
}

// <!--(change)="control.setValue(control.value)"-->
