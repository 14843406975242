import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Subject } from "rxjs";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import { inteleaseAnimations } from "@@intelease/web/intelease/animations";
import { cloneDeep, keys as _keys, keyBy } from "lodash";
import { DatePipe } from "@angular/common";
import {
  PartialValProvisionValueModel,
  RelatedDocModel,
} from "@@intelease/web/common/models";
import { AbstractReviewFacade } from "@@intelease/app-state/abstract-review";
import { finalize } from "rxjs/operators";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  ProvisionReviewStatusEnum,
  RecordReviewModeEnum,
} from "@@intelease/app-models/common";
import {
  FormFieldModel,
  FullValMultiPdfProvViewModel,
  OApiReqCalculateMentionDtoModel,
  OptionsCalculatorService,
  ProvisionMentionService,
} from "@@intelease/api-models/adex-api-model-src";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision/src";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import {
  FormFieldFormat,
  OptionCalculatorInputField,
} from "./components/input-field/input-field.component";
import { SelectMentionConfirmModalService } from "../../select-mention-confirm-modal";
import { shouldSetStatusToNeedReviewTouched } from "@@intelease/web/utils/provision-review-utils";
import { ProvisionReviewUIFacade } from "@@intelease/web/abstract-review/src/lib/store/provision-review.facade";
import { IResizeEvent } from "angular2-draggable/lib/models/resize-event";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

interface OptionCalculatorForm {
  reference: OptionCalculatorInputField;
  status: OptionCalculatorInputField;
  reminderCategory: OptionCalculatorInputField;
  name: OptionCalculatorInputField;
  optionType: OptionCalculatorInputField;
  controllingParty: OptionCalculatorInputField;
  actionable: OptionCalculatorInputField;
  actionDate: OptionCalculatorInputField;
  notificationDay: OptionCalculatorInputField;
  emailReminder: OptionCalculatorInputField;
  clauseType: OptionCalculatorInputField;
  startDate: OptionCalculatorInputField;
  endDate: OptionCalculatorInputField;
  effectiveDate: OptionCalculatorInputField;
  firstNoticeDate: OptionCalculatorInputField;
  cycle: OptionCalculatorInputField;
  maximumNumber: OptionCalculatorInputField;
  noticeDueDate: OptionCalculatorInputField;
  effective: OptionCalculatorInputField;
}

class TableColumn {
  title: string;
  readonly key: string;

  constructor(title: string, key: string) {
    this.title = title;
    this.key = key;
  }
}

@Component({
  selector: "il-options-calculator-modal",
  templateUrl: "./options-calculator-modal.component.html",
  styleUrls: ["./options-calculator-modal-component.scss"],
  animations: inteleaseAnimations,
})
export class OptionsCalculatorModalComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  imported: Subject<any> = new Subject<any>();

  modalRes: ModalResponseModel = new ModalResponseModel();
  payload: any;
  abstractName = "";

  calculatedItems: any[] = [];
  tableColumns: TableColumn[] = [];

  isTableHidden = true;
  provisionFormUid: string;
  abstractUid: string;
  isDatePickerOpen = false;
  isSavingCalculatedMention = false;
  selectedFieldKey: string;
  datePickerValue: Date;
  recordReviewMode: RecordReviewModeEnum;

  selectedDocument: RelatedDocModel;
  currentPage: number;

  optionsProvision: FullValMultiPdfProvModel;

  //input data for options calculator from the API
  regularFields: FormFieldModel[];
  optionTypeToConditionFields: { [key: string]: FormFieldModel[] };
  optionTypeToSurroundingText: { [key: string]: string };

  //form data
  formData: OptionCalculatorForm;

  FieldFormat = FormFieldFormat;
  private modalSizeBeforeOpenCalculator: DOMRect;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private datePipe: DatePipe,
    public readonly abstractReviewFacade: AbstractReviewFacade,
    private provisionMentionService: ProvisionMentionService,
    private optionsCalculatorService: OptionsCalculatorService,
    private _notification: MessageService,
    private selectMentionConfirmModalService: SelectMentionConfirmModalService,
    private readonly provisionReviewUIFacade: ProvisionReviewUIFacade,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const {
      abstractUid,
      provisionFormUid,
      selectedDocument,
      currentPage,
      optionsProvision,
      abstractName,
      recordReviewMode,
    } = this.payload;
    this.abstractUid = abstractUid;
    this.abstractName = abstractName;
    this.provisionFormUid = provisionFormUid;
    this.selectedDocument = selectedDocument;
    this.currentPage = currentPage;
    this.optionsProvision = optionsProvision;
    this.recordReviewMode = recordReviewMode;

    this.loadOptionsCalculatorData();
  }

  private loadOptionsCalculatorData() {
    this.optionsCalculatorService
      .retrieveOptionsCalculatorData({
        body: { data: { recordUid: this.abstractUid } },
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp) => {
        this.regularFields = resp.data.formFields;
        this.optionTypeToConditionFields =
          resp.data.optionTypeToConditionalFields;
        this.optionTypeToSurroundingText =
          resp.data.optionTypeToSurroundingText;

        this.initializeFormData();
      });
  }

  private initializeFormData() {
    const regularFieldModified = this.regularFields.map((field) => {
      return {
        ...field,
        fieldValue: undefined,
        rawValue: undefined,
      } as OptionCalculatorInputField;
    });

    this.formData = keyBy(
      cloneDeep(regularFieldModified),
      "fieldKey"
    ) as any as OptionCalculatorForm;
  }

  private transformDate(date) {
    return this.datePipe.transform(date, "MMM-d-y");
  }

  private showTable() {
    this.isTableHidden = false;
  }

  private hideTable() {
    this.isTableHidden = true;
  }

  onResizing(evt: IResizeEvent) {
    const hostRect = evt.host.getBoundingClientRect();

    if (hostRect.x <= 0) {
      this.renderer.setStyle(
        evt.host,
        "left",
        `${evt.host.style.left - hostRect.x}px`
      );
    }

    if (hostRect.right > document.body.clientWidth) {
      this.renderer.setStyle(
        evt.host,
        "width",
        `${document.body.clientWidth - hostRect.left}px`
      );
    }

    if (hostRect.bottom > document.body.clientHeight) {
      this.renderer.setStyle(
        evt.host,
        "height",
        `${document.body.clientHeight - hostRect.top}px`
      );
    }
  }

  getToolTip(fieldKey: string) {
    if (fieldKey === "optionType") {
      return "Choose option type to calculate the options";
    }
    return undefined;
  }

  getClauseSummaryText(optionType: string) {
    if (optionType && this.optionTypeToSurroundingText)
      return this.optionTypeToSurroundingText[optionType] || "";

    return "";
  }

  onOpenDatePicker(key: string) {
    if (!this.modalSizeBeforeOpenCalculator) {
      this.modalSizeBeforeOpenCalculator = this.modalRect;
    }

    this.hideTable();
    if (this.formData[key].fieldFormat !== this.FieldFormat.DATE) {
      if (this.isDatePickerOpen) {
        this.onDatePickerClosed();
      }
      return;
    }

    if (
      this.formData[key].rawValue &&
      this.formData[key].rawValue instanceof Date
    ) {
      this.datePickerValue = this.formData[key].rawValue;
      this.formData[key].fieldValue = this.transformDate(this.datePickerValue);
    } else {
      this.datePickerValue = null;
    }

    this.selectedFieldKey = key;
    this.isDatePickerOpen = true;
    this.updateModalSizeAfterDataPickerOpen(157.5);
    this.changeDetectorRef.detectChanges();
  }

  private get modalRect(): DOMRect {
    const content =
      this.elementRef.nativeElement.querySelector(".modal-content");

    if (!content) {
      return;
    }

    return content.getBoundingClientRect();
  }

  private updateModalSizeAfterDataPickerOpen(size: number): void {
    const content =
      this.elementRef.nativeElement.querySelector(".modal-content");

    if (!content) {
      return;
    }

    const rect = content.getBoundingClientRect();

    const height =
      rect.top + rect.height + size > document.body.clientHeight
        ? document.body.clientHeight - rect.top
        : rect.height + size;

    this.renderer.setStyle(content, "height", `${height}px`);
  }

  onDatePickerClosed() {
    if (this.isDatePickerOpen) {
      this.restoreModalSize();
    }
    this.isDatePickerOpen = false;
    this.selectedFieldKey = undefined;
    this.datePickerValue = undefined;
  }

  private restoreModalSize(): void {
    if (!this.modalSizeBeforeOpenCalculator) {
      return;
    }

    const content =
      this.elementRef.nativeElement.querySelector(".modal-content");

    this.renderer.setStyle(
      content,
      "height",
      `${this.modalSizeBeforeOpenCalculator.height}px`
    );

    this.modalSizeBeforeOpenCalculator = undefined;
  }

  onFieldReset(key: string) {
    if (this.formData[key].fieldFormat === this.FieldFormat.DATE) {
      this.onDatePickerClosed();
    }
  }

  onDateChange(date, key: string) {
    this.formData[key].rawValue = date;
    this.formData[key].fieldValue = this.transformDate(date);
  }

  private createCellValue(columnKey: string) {
    const inputField = this.formData[columnKey] as OptionCalculatorInputField;

    return this.getTextValueOfInputField(inputField);
  }

  private createTableRow() {
    let row = {} as any;
    this.tableColumns.map((column) => {
      if (this.formData[column.key]) {
        row = {
          ...row,
          [column.key]: this.createCellValue(column.key),
        };
      }
    });

    return row;
  }

  onCalculateClick() {
    this.hideTable();
    this.onDatePickerClosed();
    if (this.isValidForCalculation()) {
      this.calculatedItems = [];
      this.prepareTableColumns();
      const newRow = this.createTableRow();
      this.calculatedItems.push(newRow);
      this.showTable();
    }
  }

  private fieldContainsAnyValue(inputField: OptionCalculatorInputField) {
    let isValid = true;
    if (
      inputField.fieldFormat === this.FieldFormat.TEXT ||
      inputField.fieldFormat === this.FieldFormat.TEXT_AREA ||
      inputField.fieldFormat === this.FieldFormat.SELECT
    ) {
      if (!inputField.fieldValue || inputField.fieldValue?.trim() === "") {
        isValid = false;
      }
    } else if (inputField.fieldFormat === this.FieldFormat.NUMBER) {
      if (inputField.fieldValue === undefined || isNaN(inputField.fieldValue)) {
        isValid = false;
      }
    } else if (inputField.fieldFormat === this.FieldFormat.DATE) {
      if (
        !inputField.fieldValue ||
        !inputField.rawValue ||
        !(inputField.rawValue instanceof Date)
      ) {
        isValid = false;
      }
    } else if (inputField.fieldFormat === this.FieldFormat.TIME_PERIOD) {
      if (
        !inputField.rawValue ||
        !inputField.rawValue.input ||
        !inputField.rawValue.dropdown
      ) {
        isValid = true;
      }
    } else if (
      inputField.fieldFormat === this.FieldFormat.RELATIVE_TIME_TO_EVENT
    ) {
      if (
        !inputField.rawValue ||
        !inputField.rawValue.input ||
        !inputField.rawValue.dropdown1 ||
        !inputField.rawValue.dropdown2
      ) {
        isValid = false;
      }
    }
    return isValid;
  }

  private isValidForCalculation() {
    if (!this.formData) {
      this._notification.error("Data is not completely loaded yet!");
      return false;
    }

    let isValid = true;
    const missingFields: string[] = [];
    _keys(this.formData).map((key) => {
      const inputField = this.formData[key] as OptionCalculatorInputField;
      if (!inputField.optional) {
        const isFieldValid = this.fieldContainsAnyValue(inputField);
        if (!isFieldValid) {
          missingFields.push(inputField.fieldLabel);
          isValid = false;
        }
      }
    });

    if (!isValid) {
      if (missingFields.length) {
        this._notification.error(
          `Missing fields: ${missingFields.join(
            ", "
          )}, these are required fields!`
        );
      } else {
        this._notification.error(`Form is not valid`);
      }
    }
    return isValid;
  }

  private createNewTableColumn(columnKey: string) {
    const field = this.formData[columnKey] as OptionCalculatorInputField;
    if (!field) {
      console.error("target field not found");
    }
    const newTableColumn = new TableColumn(field.fieldLabel, field.fieldKey);
    return newTableColumn;
  }

  private getTextValueOfInputField(
    inputField: OptionCalculatorInputField
  ): string {
    if (
      inputField.fieldFormat === this.FieldFormat.TEXT ||
      inputField.fieldFormat === this.FieldFormat.TEXT_AREA
    ) {
      return inputField.fieldValue?.trim() || "";
    } else if (inputField.fieldFormat === this.FieldFormat.SELECT) {
      return (
        inputField.fieldOptions.find(
          (option) => !option.disabled && option.value === inputField.fieldValue
        )?.label || ""
      );
    } else if (inputField.fieldFormat === this.FieldFormat.NUMBER) {
      return inputField.fieldValue ? String(inputField.fieldValue) : "";
    } else if (inputField.fieldFormat === this.FieldFormat.DATE) {
      return inputField.rawValue ? this.transformDate(inputField.rawValue) : "";
    } else if (inputField.fieldFormat === this.FieldFormat.TIME_PERIOD) {
      if (
        !inputField.rawValue ||
        !inputField.rawValue.input ||
        !inputField.rawValue.dropdown
      ) {
        return "";
      } else {
        return (
          "Every " +
          inputField.rawValue.input +
          " " +
          inputField.rawValue.dropdown
        );
      }
    } else if (
      inputField.fieldFormat === this.FieldFormat.RELATIVE_TIME_TO_EVENT
    ) {
      if (
        !inputField.rawValue ||
        !inputField.rawValue.input ||
        !inputField.rawValue.dropdown1 ||
        !inputField.rawValue.dropdown2
      ) {
        return "";
      } else {
        return (
          inputField.rawValue.dropdown2 +
          " " +
          inputField.rawValue.input +
          " " +
          inputField.rawValue.dropdown1
        );
      }
    }

    return "";
  }

  private prepareTableColumns() {
    this.tableColumns = [];
    _keys(this.formData).map((key) => {
      const field = this.formData[key] as OptionCalculatorInputField;
      if (!field.optional) {
        this.tableColumns.push(this.createNewTableColumn(field.fieldKey));
      } else {
        const textValue = this.getTextValueOfInputField(field);
        if (textValue && textValue !== "") {
          this.tableColumns.push(this.createNewTableColumn(field.fieldKey));
        }
      }
    });
  }

  private importCalculatedProvision(
    {
      mentionUid,
      preMentionUid,
      postMentionUid,
    }: {
      mentionUid?: string;
      preMentionUid?: string;
      postMentionUid?: string;
    },
    selectedMentionIndex?: number,
    mentionName?: string
  ) {
    const value = {
      headers: [],
      types: [],
      rows: [],
    };
    for (const tableColumn of this.tableColumns) {
      value.headers.push(tableColumn.title);
      value.types.push("COMMON_NOUN");
    }
    this.calculatedItems.forEach((item) => {
      const row = [];
      for (const tableColumn of this.tableColumns) {
        row.push(item[tableColumn.key]);
      }
      value.rows.push(row);
    });

    const body: OApiReqCalculateMentionDtoModel = {
      data: {
        docAbstractUid: this.selectedDocument.uid,
        page: this.currentPage,
        provisionUid: this.optionsProvision.provisionInfo.uid,
        provisionValue: value,
        mentionName,
        mentionUid,
        postMentionUid,
        preMentionUid,
        format: "TABLE",
      },
    };
    this.isSavingCalculatedMention = true;
    this.provisionMentionService
      .calculateCreateProvisionValueV2({
        recordUid: this.abstractUid,
        body,
      })
      .pipe(
        finalize(() => {
          this.isSavingCalculatedMention = false;
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          if (!res.data) {
            this._notification.error(
              res.message ||
                "An unknown error occurred while saving the options"
            );
            return;
          }
          this.updateViewAfterChangeInProvisions(
            res.data.multiPdfProv,
            this.optionsProvision as any,
            res.data.modifiedProvisionMentionUids?.newlyCreated?.[0],
            selectedMentionIndex
          );
          this.hideTable();
          this.onDismissModal();
        },
        error: () => {
          this._notification.error(
            "An unknown error occurred while saving the options"
          );
        },
      });
  }

  onImportClick() {
    if (!this.isSavingCalculatedMention && this.calculatedItems.length > 0) {
      this.selectMentionConfirmModalService
        .openModalAndAskRelevantMentionUidToFillIn(this.optionsProvision)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res) => {
          if (!res.isCanceled) {
            this.importCalculatedProvision(
              res.relevantMentions,
              res.selectedMentionIndex,
              res?.mentionName
            );
          }
        });
    }
  }

  private updateViewAfterChangeInProvisions(
    backendProvisionDefinition: FullValMultiPdfProvViewModel,
    currentProvisionDefiniton: FullValMultiPdfProvViewModel,
    newMentionUid: string,
    selectedMentionIndex?: number
  ): void {
    const { provisionUid } = backendProvisionDefinition?.multiplePdfProvision;
    const provisionDetail = Json2TypescriptHelper.convertToEntity(
      backendProvisionDefinition,
      FullValMultiPdfProvModel
    );
    const options = [...currentProvisionDefiniton.multiplePdfProvision.options];
    const newOption = provisionDetail.multiplePdfProvision.options.find(
      (option) => option.uid === newMentionUid
    );

    if (selectedMentionIndex !== undefined) {
      options.splice(
        selectedMentionIndex,
        1,
        newOption as PartialValProvisionValueModel
      );
    } else {
      options.push(newOption as PartialValProvisionValueModel);
    }

    this.abstractReviewFacade.setFullProvisionDetailWithProvisionUid({
      provisionUid,
      provisionDetail: {
        ...provisionDetail,
        multiplePdfProvision: {
          ...provisionDetail.multiplePdfProvision,
          options,
        },
        reviewStatus: shouldSetStatusToNeedReviewTouched(
          currentProvisionDefiniton as unknown as FullValMultiPdfProvModel
        )
          ? ProvisionReviewStatusEnum.NEED_REVIEW_TOUCHED
          : provisionDetail.reviewStatus,
      },
    });

    this.provisionReviewUIFacade.scrollToProvisionOption(
      currentProvisionDefiniton,
      newOption
    );
  }

  onResetClick() {
    this.initializeFormData();
    this.hideTable();
    this.calculatedItems = [];
    this.tableColumns = [];
    this.onDatePickerClosed();
  }

  onDismissModal() {
    this.bsModalRef.hide();
    this.onDismiss.next(this.modalRes);
  }
}
