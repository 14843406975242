<div class="navbar-header navbar-vertical-style-2" style="box-shadow: none">
  <div
    class="logo"
    style="margin-left: 0; cursor: pointer"
    [matMenuTriggerFor]="menu"
  >
    <img class="logo-icon" src="assets/images/adex-logo-font.png" />
    <mat-icon class="ml-1" style="font-size: 17px; margin-top: 15px"
      >keyboard_arrow_down</mat-icon
    >
  </div>

  <div class="buttons">
    <button
      mat-icon-button
      class="toggle-sidebar-folded"
      style="margin-top: -2px"
      dataCy="mainNavigationToggleBtn"
      *ngIf="mainDrawerService.isOpen"
      (click)="toggleSidebarFolded()"
    >
      <itls-icon
        svgIcon="icon-menu"
        [height]="16"
        style="
          width: 16px;
          font-size: 16px;
          min-width: 16px;
          height: 16px;
          line-height: 16px;
        "
      ></itls-icon>
    </button>
  </div>
</div>

<div
  class="navbar-content"
  fxLayout="column"
  inteleasePerfectScrollbar
  [intelesePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
  <div
    class="menu-btn-container"
    style="position: relative"
    [matMenuTriggerFor]="notificationMenu"
    #notificationMenuTrigger="matMenuTrigger"
    (click)="
      isNotificationMenuOpen = true; isNotificationMenuInitialized = true
    "
    (menuClosed)="isNotificationMenuOpen = false"
    (menuOpened)="isNotificationMenuOpen = true"
    [style.backgroundColor]="isNotificationMenuOpen ? '#dfe1e6' : 'unset'"
  >
    <span
      class="notification-count"
      *ngIf="(notificationsFacade.countOfUnseenNotifications$ | async) > 0"
    >
      {{ notificationsFacade.countOfUnseenNotifications$ | async }}
    </span>
    <itls-icon
      svgIcon="icon-notification"
      style="color: #344563; margin-top: -2px; margin-right: 12px"
    ></itls-icon>
    <div>Notifications</div>
  </div>
  <div
    class="menu-btn-container"
    routerLink="/settings"
    style="margin-bottom: 12px"
  >
    <itls-icon
      svgIcon="icon-setting"
      style="margin-top: -2px; margin-right: 12px"
    ></itls-icon>
    Settings
  </div>
  <itls-navigation layout="vertical"></itls-navigation>
  <div
    class="menu-btn-container upload-button-container"
    (click)="openUploadModal()"
    *ngxPermissionsOnly="[AUTH.CREATE_RECORD]"
  >
    <itls-icon
      svgIcon="icon-upload"
      style="margin-top: -2px; margin-right: 12px; filter: invert(100%)"
    ></itls-icon>
    Upload Documents
  </div>
</div>

<mat-menu
  #notificationMenu="matMenu"
  yPosition="below"
  xPosition="after"
  [overlapTrigger]="false"
  class="notification-menu"
>
  <intelease-web-notifications
    (selectNotification)="onNotificationSelect($event)"
    *ngIf="isNotificationMenuInitialized"
    style="margin-right: 14px; display: block; width: 100%; height: 100%"
  >
  </intelease-web-notifications>
</mat-menu>

<mat-menu #menu="matMenu" xPosition="before" [overlapTrigger]="false">
  <div class="py-4">
    <button
      [matTooltip]="userName"
      mat-menu-item
      class=""
      (click)="onNavigateToClick('info')"
    >
      <mat-icon style="margin-right: 6px !important">account_circle</mat-icon>
      <span style="color: #505f79; font-weight: 300">{{ userEmail }}</span>
    </button>
  </div>
  <mat-divider></mat-divider>
  <button
    mat-menu-item
    class=""
    (click)="onNavigateToClick('logout')"
    style="font-weight: bold"
  >
    <mat-icon style="transform: rotate(180deg); margin-left: -5px !important"
      >exit_to_app</mat-icon
    >
    <span>Log Out</span>
  </button>
</mat-menu>
