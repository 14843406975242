/* tslint:disable */
/* eslint-disable */
import { EntityPermissionActorFullDtoModel } from "./entity-permission-actor-full-dto-model";
import { EntityPermissionCreatorDtoModel } from "./entity-permission-creator-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EntityPermissionFullDtoModel")
export class EntityPermissionFullDtoModel {
  @JsonProperty("actor", EntityPermissionActorFullDtoModel, true)
  actor?: EntityPermissionActorFullDtoModel = undefined;

  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdBy", EntityPermissionCreatorDtoModel, true)
  createdBy?: EntityPermissionCreatorDtoModel = undefined;

  @JsonProperty("permissionTypeGroup", Any, true)
  permissionTypeGroup?: "OWNER" | "EDITOR" | "COMMENTER" | "VIEWER" = undefined;
}
