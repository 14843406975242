<div
  class="provision-box-modal"
  [ngClass]="{ 'provision-box-modal-panel': isInNewBrowserTabMode }"
  cdkDrag
  (resized)="onResized()"
  (cdkDragStarted)="onDragStarted()"
  (cdkDragMoved)="onDragMoved()"
  (cdkDragReleased)="onDragReleased()"
  (cdkDragEnded)="onDragReleased()"
  [cdkDragDisabled]="isInNewBrowserTabMode"
  cdkDragRootElement=".cdk-overlay-pane"
  [ngResizable]="!isInNewBrowserTabMode"
  rzHandles="e,se,s"
  [rzMinWidth]="500"
  [rzMaxWidth]="824"
  [rzMinHeight]="44"
  [rzMaxHeight]="700"
  [style.width]="!isInNewBrowserTabMode ? '500px' : 'unset'"
  (rzStart)="onResizeStart()"
  (rzResizing)="onResizing($event)"
  (rzStop)="onResizeEnd($event)"
>
  <div
    class="intel-drag-handler left"
    *ngIf="!isInNewBrowserTabMode"
    cdkDragHandle
  ></div>
  <div
    class="intel-drag-handler right"
    *ngIf="!isInNewBrowserTabMode"
    cdkDragHandle
  ></div>
  <div
    class="intel-drag-handler bottom"
    *ngIf="!isInNewBrowserTabMode"
    cdkDragHandle
  ></div>
  <div
    class="provision-box-modal-container"
    [style.maxHeight]="provisionBoxSize.height + 'px'"
  >
    <div class="loading" *ngIf="isLoading">
      <mat-progress-spinner
        diameter="50"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>
    <ng-container *ngIf="!isLoading">
      <div
        class="provisions-category-header"
        fxLayout
        cdkDragHandle
        [cdkDragHandleDisabled]="isInNewBrowserTabMode"
        fxLayoutAlign="space-between center"
      >
        <div fxLayout fxLayoutAlign="start center">
          <button
            mat-icon-button
            class="minimize-btn"
            *ngIf="!isInNewBrowserTabMode"
            (click)="onToggleMinimized()"
            [matTooltip]="isMinimized ? 'Maximize' : 'Minimize'"
          >
            <mat-icon
              [svgIcon]="isMinimized ? 'icon-expand' : 'icon-minimize'"
            ></mat-icon>
          </button>
          <h5 dataCy="cySelectedProvisionGroupName">
            {{ selectedProvisionGroup?.name }}
          </h5>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div class="controls">
            <button
              dataCy="cyPreviousProvisionGroupBtn"
              class="previous ml-2"
              [matTooltip]="'Previous'"
              *ngIf="provisionsGroupWorkflow.currentIndex > 0"
              (click)="onPreviousProvisionsGroupClick()"
              mat-icon-button
            >
              <mat-icon svgIcon="icon-arrow-left"></mat-icon>
            </button>
            <button
              dataCy="cyNextProvisionGroupBtn"
              class="next ml-1"
              [matTooltip]="'Next'"
              *ngIf="
                this.provisionsGroupWorkflow.currentIndex <
                this.fullProvisionsGroup?.provisionGroupList?.length - 1
              "
              (click)="onNextProvisionsGroupClick()"
              mat-icon-button
            >
              <mat-icon svgIcon="icon-arrow-right"></mat-icon>
            </button>
          </div>
          <button
            class="new-window ml-2 mr-2"
            *ngIf="!isInNewBrowserTabMode"
            [matTooltip]="'Open in new window'"
            mat-icon-button
            (click)="onOpenInNewWindow()"
          >
            <mat-icon svgIcon="icon-new-window"></mat-icon>
          </button>
          <button
            dataCy="cyCloseProvisionBoxBtn"
            class="close"
            *ngIf="!isInNewBrowserTabMode"
            [matTooltip]="'Close'"
            mat-icon-button
            (click)="onCloseProvisionBox()"
          >
            <mat-icon svgIcon="icon-cancel"></mat-icon>
          </button>
        </div>
      </div>
      <mat-divider style="margin-bottom: 12px"></mat-divider>
      <div class="provision-tab-list">
        <div
          class="provision-title"
          *ngFor="let provision of selectedProvisionGroup?.provisions"
          [id]="provision?.provisionInfo?.uid"
          matRipple
          (click)="onSelectProvision(provision)"
          [title]="provision?.provisionInfo?.description"
          [ngClass]="{
            active:
              selectedProvision?.provisionInfo?.uid ===
              provision?.provisionInfo?.uid
          }"
        >
          {{ provision?.provisionInfo?.name }}
        </div>
      </div>
      <ng-container
        *ngFor="
          let provision of selectedProvisionGroup?.provisions;
          let provisionIndex = index
        "
      >
        <il-provision
          [selectedProvisionBoxTab]="selectedProvisionBoxTab"
          [editable]="editable"
          [provisionIndex]="provisionIndex"
          [canComment]="canComment"
          [givenEntityPermissions]="givenEntityPermissions"
          [provision]="provision"
          #provisionComponentRef
          [id]="provision?.provisionInfo?.uid"
          [dataCy]="'cyProvision_' + provision?.provisionInfo?.uid"
          [isMinimized]="isMinimized"
          [selectedAbstractUid]="selectedAbstractUid"
          [selectedMentionUid]="selectedMentionUid"
          [selectedProvisionUid]="selectedProvisionUid"
          [selectedProvision]="selectedProvision"
          [selectedDocument]="selectedDocument"
          [abstractDocument]="abstractDocument"
          [documentsShortNameMap]="documentsShortNameMap"
          [provisionBoxInitTypes]="provisionBoxInitTypes"
          [isInNewBrowserTabMode]="isInNewBrowserTabMode"
          [hidden]="
            !(
              selectedProvision?.provisionInfo?.uid ===
              provision?.provisionInfo?.uid
            )
          "
          [accountUsers]="accountUsers"
          (mentionRemoved)="onMentionRemoved($event)"
        >
        </il-provision>
      </ng-container>
    </ng-container>
  </div>
</div>
