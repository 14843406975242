import { JsonObject, JsonProperty } from "json2typescript";
import { PROVISION_BOX_TABS_KEY } from "@@intelease/app-models/abstract-review";

@JsonObject("WebAbstractionPageUrlStateModel")
export class WebAbstractionPageUrlStateModel {
  @JsonProperty("provisionUid", String)
  provisionUid = "";

  @JsonProperty("mentionUid", String)
  mentionUid = "";

  @JsonProperty("docUid", String)
  docUid = "";

  @JsonProperty("commentUid", String)
  commentUid = "";

  routingFinished: boolean;

  selectedProvisionBoxTab: PROVISION_BOX_TABS_KEY;

  reset() {
    this.provisionUid = "";
    this.mentionUid = "";
    this.docUid = "";
    this.commentUid = "";
    this.selectedProvisionBoxTab = undefined;
  }
}
