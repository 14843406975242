import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatMenuTrigger } from "@angular/material/menu";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive.service";
import { MatDialog } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { cloneDeep } from "lodash";
import { DriveFacade } from "@@intelease/app-state/drive/src";
import { DriveService } from "@@intelease/api-models/adex-api-model-src";
import { INode } from "@@intelease/web/common";
import { ItlsDriveDynamicDatasource } from "../datasource/itls-drive-dynamic.datasource";
import { AddNewFolderComponent } from "../../itls-drive-create-new-folder";

@Component({
  selector: "il-drive-tree",
  templateUrl: "./itls-drive-tree.component.html",
  styleUrls: ["./itls-drive-tree.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsDriveTreeFolderPickerComponent implements OnInit, OnDestroy {
  treeControl: FlatTreeControl<INode>;
  dataSource: ItlsDriveDynamicDatasource;
  @ViewChild("emptyItem") emptyItem: ElementRef;
  contextMenuContent;
  contextMenuEvent: MouseEvent;
  contextMenuPos: { x: number; y: number };
  @ViewChild("contextMenuTrigger", { static: false })
  contextMenu: MatMenuTrigger;
  @Output()
  nodeClicked: EventEmitter<INode> = new EventEmitter<INode>();
  @Input()
  selectedNodeUid: string;
  @Input()
  showContextMenu = false;
  @Input()
  nonSelectableNodeUids: string[];
  @Input()
  disabledNodeUids: string[];
  @Input()
  contentMaxHeight: number;
  contextMenuTarget: INode;
  rootNodeId = ItlsDriveService.ROOT_NODE_ID;

  constructor(
    private cdr: ChangeDetectorRef,
    private driveFacade: DriveFacade,
    private driveService: DriveService,
    private matDialog: MatDialog,
    private destroyRef: DestroyRef
  ) {
    this.treeControl = new FlatTreeControl<INode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new ItlsDriveDynamicDatasource(
      this.treeControl,
      this.driveFacade,
      this.driveService
    );
    this.dataSource.data = ItlsDriveService.getInitialNodes();
  }

  ngOnInit(): void {
    this.dataSource.dataChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((items) => {
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.dataSource.close();
  }

  getLevel = (node: INode) => node.level;

  isExpandable = (node: INode) => node.expandable;

  getChildren = (node: INode): INode[] => node.children;

  onNodeClick(node: INode) {
    if (
      !this.nonSelectableNodeUids?.includes(node.id) &&
      !this.disabledNodeUids?.includes(node.id)
    ) {
      this.nodeClicked.emit(node);
    }
  }

  onContextMenu(node: INode, evt) {
    if (this.showContextMenu) {
      this.contextMenuTarget = node;
      this.contextMenuEvent = evt;
      this.contextMenu.closeMenu();
      this.contextMenuPos = this.getPosition(this.contextMenuEvent);
      setTimeout(() => {
        this.contextMenu.openMenu();
      }, 150);
      evt.preventDefault();
      evt.stopPropagation();
    }
  }

  private getPosition(e) {
    let posx = 0;
    let posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }

    return {
      x: posx,
      y: posy,
    };
  }

  @HostListener("document:click", ["$event"])
  clickedOutside($event) {
    if (this.showContextMenu) {
      this.contextMenuTarget = undefined;
      this.contextMenu?.closeMenu();
      this.contextMenuEvent = undefined;
      this.contextMenuPos = undefined;
      this.contextMenuContent = undefined;
    }
  }

  onCreateNewFolderClicked() {
    this.matDialog.open(AddNewFolderComponent, {
      width: "275px",
      data: {
        parentFolderUid: ItlsDriveService.getNonRootDirectoryUid(
          this.contextMenuTarget.id
        ),
      },
    });
  }
}
