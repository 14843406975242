/* tslint:disable */
/* eslint-disable */
import { QueueStatsDtoModel } from "./queue-stats-dto-model";
import { QueueSummaryDtoModel } from "./queue-summary-dto-model";
import { UserDtoModel } from "./user-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("QueueDtoModel")
export class QueueDtoModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("creator", UserDtoModel, true)
  creator?: UserDtoModel = undefined;

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];

  @JsonProperty("stats", QueueStatsDtoModel, true)
  stats?: QueueStatsDtoModel = undefined;

  @JsonProperty("status", Any, true)
  status?: "ACTIVE" | "PAUSE" | "DEACTIVE" = undefined;

  @JsonProperty("summary", QueueSummaryDtoModel, true)
  summary?: QueueSummaryDtoModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("updatedAt", DateTimeConvertService, true)
  updatedAt?: Date | undefined = undefined;
}
