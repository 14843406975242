/* tslint:disable */
/* eslint-disable */
import { PdfBbxModel } from "./pdf-bbx-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PdfBbxAndTxtModel")
export class PdfBbxAndTxtModel {
  @JsonProperty("rect", PdfBbxModel, true)
  rect?: PdfBbxModel = undefined;

  @JsonProperty("text", String, true)
  text?: string = "";
}
