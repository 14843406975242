import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import {
  ListResponseModel,
  ServerResponseModel,
} from "@@intelease/web/intelease/models";
import { map } from "rxjs/operators";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";

@Injectable({
  providedIn: "root",
})
export class CreateBatchApiService {
  private static readonly CREATE_BATCH_URL = "/createBatch";

  constructor(private httpClient: HttpClient) {}

  /**
   * Send a POST request to create new entities in backend, returning no view of them.
   *
   * @param apiVersion the version of the API
   * @param fullUrl the full url
   * @param datas the datas used for creating the objects
   */
  sendBatchRequestNoResponse(
    apiVersion: string,
    fullUrl: string,
    datas: any[]
  ): void {
    this.sendBatchRequestNoView(apiVersion, fullUrl, datas).subscribe(
      (res) => {}
    );
  }

  /**
   * Send a synchronous POST request to create new entities in backend, returning no view of them.
   *
   * @param apiVersion the version of the API
   * @param fullUrl the full url
   * @param datas the datas used for creating the objects
   * @return response, not containing objects
   */
  sendBatchRequestNoView(
    apiVersion: string,
    fullUrl: string,
    datas: any[]
  ): Observable<ServerResponseModel> {
    const body = this.createBatchCreateRequestBody(datas, "none");
    return this.helpSendRequestNoView(body, apiVersion, fullUrl);
  }

  /**
   * Send a POST request to create new entities in backend, returning no view of them, for all relevant objects.
   *
   * @param apiVersion the version of the API
   * @param fullUrl the full url
   * @param data the data used for creating all the objects
   * @param view the view of each object to return
   * @param classRef the type of objects to return
   */
  sendAllRequest<T extends object>(
    apiVersion: string,
    fullUrl: string,
    data: any,
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    const body = this.createAllCreateRequestBody(data, view);
    return this.helpSendRequest(body, apiVersion, fullUrl, classRef);
  }

  /**
   * Send a POST request to create new entities in backend, returning no view of them.
   *
   * @param apiVersion the version of the API
   * @param fullUrl the full url
   * @param datas the datas used for creating the objects
   * @param view the view of each object to return
   * @param classRef the type of objects to return
   */
  sendBatchRequest<T extends object>(
    apiVersion: string,
    fullUrl: string,
    datas: any[],
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    const body = this.createBatchCreateRequestBody(datas, view);
    return this.helpSendRequest(body, apiVersion, fullUrl, classRef);
  }

  /*
   * Below are private helper methods
   */

  private helpSendRequest<T extends object>(
    body: any,
    apiVersion: string,
    fullUrl: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    return this.helpSendRequestNoView(body, apiVersion, fullUrl).pipe(
      map((res) => Json2TypescriptHelper.convertToEntities(res.data, classRef))
    );
  }

  private helpSendRequestNoView(
    body: any,
    apiVersion: string,
    fullUrl: string
  ): Observable<ServerResponseModel> {
    return this.httpClient.post<ServerResponseModel>(
      apiVersion + CreateBatchApiService.CREATE_BATCH_URL + fullUrl,
      body
    );
  }

  private createAllCreateRequestBody(data: any, view: string): any {
    return {
      datas: [data],
      selectAll: true,
      returnParams: {
        view: view,
      },
    };
  }

  private createBatchCreateRequestBody(datas: any[], view: string): any {
    return {
      datas: datas,
      selectAll: false,
      returnParams: {
        view: view,
      },
    };
  }
}
