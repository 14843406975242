import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import {
  ModalInputModel,
  ModalResponseModel,
} from "@@intelease/web/intelease/models";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { first } from "lodash";

export interface SelectMentionConfirmModalOptionItemModel {
  value: string;
  label: string;
}

export class SelectMentionConfirmModalInputModel extends ModalInputModel {
  payload: {
    optionItems: SelectMentionConfirmModalOptionItemModel[];
    allowCreateNew: boolean;
  };
}

export class SelectMentionConfirmModalOutputModel extends ModalResponseModel {
  data: {
    createNew: boolean;
    selectedOption?: SelectMentionConfirmModalOptionItemModel;
    mentionName?: string;
  };
}

@Component({
  selector: "il-select-mention-confirm-modal",
  templateUrl: "./select-mention-confirm-modal.component.html",
})
export class SelectMentionConfirmModalComponent {
  static TEMPORARY_NEW_OPTION: SelectMentionConfirmModalOptionItemModel = {
    value: "new",
    label: "Create New Mention",
  };

  title = "Select the mention to save";
  confirmButtonText = "Import";
  cancelButtonText = "Cancel";
  options: SelectMentionConfirmModalOptionItemModel[] = [];

  selectedValue: string;

  mentionName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: SelectMentionConfirmModalInputModel,
    private dialogRef: MatDialogRef<
      SelectMentionConfirmModalComponent,
      SelectMentionConfirmModalOutputModel
    >
  ) {
    this.options.push(...this.data.payload.optionItems);

    if (this.data.payload.allowCreateNew) {
      this.options.push(
        SelectMentionConfirmModalComponent.TEMPORARY_NEW_OPTION
      );
      this.selectedValue =
        SelectMentionConfirmModalComponent.TEMPORARY_NEW_OPTION.value;
    }

    if (!this.data.payload.allowCreateNew) {
      this.selectedValue = this.options.find((option) =>
        this.isEmptyMention(option.value)
      ).value;
      this.mentionName = this.options.find((option) =>
        this.isEmptyMention(option.value)
      ).label;
    } else {
      this.mentionName = `${first(this.options).label} ${this.options.length}`;
    }
  }

  onSelectProvision(evt) {
    this.mentionName = evt.label;
  }

  isEmptyMention(mentionUid: string) {
    return mentionUid.includes("-");
  }

  isMentionNameChange(): boolean {
    return (
      this.options.find((option) => option.value === this.selectedValue)
        .label === this.mentionName ||
      (this.selectedValue === "new" &&
        this.mentionName ===
          `${first(this.options).label} ${this.options.length}`)
    );
  }

  onConfirmClick(): void {
    let _mentionName = this.mentionName;
    let createNew = false;
    let selectedOption: SelectMentionConfirmModalOptionItemModel;
    if (
      this.selectedValue ===
      SelectMentionConfirmModalComponent.TEMPORARY_NEW_OPTION.value
    ) {
      createNew = true;
    } else {
      selectedOption = this.options.find(
        (item) => item.value === this.selectedValue
      );
    }
    const modalRes = new SelectMentionConfirmModalOutputModel();
    modalRes.type = ModalsResponseTypeEnum.CLOSE;
    if (this.isMentionNameChange()) {
      _mentionName = undefined;
    }
    modalRes.data = { createNew, selectedOption, mentionName: _mentionName };
    this.dialogRef.close(modalRes);
  }

  onCancelClick(): void {
    const modalRes = new SelectMentionConfirmModalOutputModel();
    modalRes.type = ModalsResponseTypeEnum.DISMISS;
    this.dialogRef.close(modalRes);
  }
}
