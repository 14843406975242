/* tslint:disable */
/* eslint-disable */

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DeleteMultipleNodesResponseModel")
export class DeleteMultipleNodesResponseModel {
  @JsonProperty("connectedQueues", [String], true)
  connectedQueues?: Array<string> = [];

  @JsonProperty("error", Boolean, true)
  error?: boolean = undefined;

  @JsonProperty("errorMessage", String, true)
  errorMessage?: string = "";

  @JsonProperty("nonRemovableFolders", [String], true)
  nonRemovableFolders?: Array<string> = [];
}
