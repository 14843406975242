<div class="column-container">
  <p class="cell-text">{{ inputData.data }} {{ isRequired ? "*" : "" }}</p>

  <ng-container *ngIf="!strictColumn">
    <button
      mat-icon-button
      disableRipple
      style="cursor: pointer"
      [matMenuTriggerFor]="menu"
      #matMenu1Trigger="matMenuTrigger"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" [overlapTrigger]="true">
      <div (mouseleave)="closeMenu()">
        <button
          mat-menu-item
          (click)="onAddColumnLeft()"
          [disabled]="disabledButtons.addCL"
          matTooltip="Add column on the left"
        >
          <mat-icon>add_circle</mat-icon>
          <span>Add Column To Left</span>
        </button>
        <button
          mat-menu-item
          (click)="onAddColumnRight()"
          [disabled]="disabledButtons.addCR"
          matTooltip="Add column on the right"
        >
          <mat-icon>add_circle</mat-icon>
          <span>Add Column To Right</span>
        </button>
        <button
          mat-menu-item
          (click)="onRemoveColumn()"
          [disabled]="disabledButtons.remove"
        >
          <mat-icon>cancel</mat-icon>
          <span>Remove</span>
        </button>
        <button
          mat-menu-item
          (click)="onMoveColumnLeft()"
          [disabled]="disabledButtons.moveCL"
          matTooltip="Move column left"
        >
          <mat-icon
            style="
              transform: rotate(90deg);
              margin: 10px 12px 0px -6px !important;
            "
            >unfold_more</mat-icon
          >
          <span>Move Column Left</span>
        </button>
        <button
          mat-menu-item
          (click)="onMoveColumnRight()"
          [disabled]="disabledButtons.moveCR"
          matTooltip="Move column right"
        >
          <mat-icon
            style="
              transform: rotate(90deg);
              margin: 10px 12px 0px -6px !important;
            "
            >unfold_more</mat-icon
          >
          <span>Move Column Right</span>
        </button>
        <button
          mat-menu-item
          (click)="onMergeColumnLeft()"
          [disabled]="disabledButtons.mergeCL"
          matTooltip="Merge with the left column"
        >
          <mat-icon
            style="
              transform: rotate(90deg);
              margin: 10px 12px 0px -6px !important;
            "
            >merge_type</mat-icon
          >
          <span>Merge With Left Column</span>
        </button>
        <button
          mat-menu-item
          (click)="onMergeColumnRight()"
          [disabled]="disabledButtons.mergeCR"
          matTooltip="Merge with the right column"
        >
          <mat-icon
            style="
              transform: rotate(90deg);
              margin: 10px 12px 0px -6px !important;
            "
            >merge_type</mat-icon
          >
          <span>Merge With Right Column</span>
        </button>
        <button
          mat-menu-item
          [disabled]="disabledButtons.duplicateCol"
          (click)="onDuplicateColumn()"
        >
          <mat-icon>file_copy</mat-icon>
          <span>Duplicate this column</span>
        </button>
      </div>
    </mat-menu>
  </ng-container>
</div>
