import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  FullProvisionModel,
  FullProvisionGroupModel,
} from "@@intelease/web/common/models";
import { HttpClient } from "@angular/common/http";
import { PartialValProvisionValueModel } from "@@intelease/web/common/models/provision-value";
import { HighlightedProvisionValueModel } from "@@intelease/web/abstraction-page/src/lib/models/highlighted-provision-value.model";
import { FullValMultiProvisionValueModel } from "@@intelease/web/common/models/multi-provision-value";
import { map } from "rxjs/operators";
import { ProvisionFunctionsInterface } from "@@intelease/web/abstraction-page/src/lib/interfaces";
import {
  ListResponseModel,
  ServerResponseModel,
} from "@@intelease/web/intelease/models";
import {
  DeleteProvisionValueService,
  EditProvisionValueService,
  FetchBatchProvisionService,
} from "@@intelease/web/intelease/services/models";
import { CreateProvisionValueService } from "@@intelease/web/intelease/services/models/provision-value/create/create-provision-value.service";
import { FetchMultiProvisionValueService } from "@@intelease/web/intelease/services/models/multi-provision-value/fetch/fetch-multi-provision-value.service";
import { EditMultiProvisionValueService } from "@@intelease/web/intelease/services/models/multi-provision-value/edit/edit-multi-provision-value.service";
import { EditSnippetService } from "@@intelease/web/intelease/services/models/snippet/edit";
import { DeleteSnippetService } from "@@intelease/web/intelease/services/models/snippet/delete";
import {
  PROVISIONS_FORMS,
  PROVISIONS_FORMS_NEW,
} from "@@intelease/app-models/common";
import { CommonFacade } from "@@intelease/app-state/common";
import { cloneDeep, keyBy } from "lodash";
import { AppHighlightTypeEnum } from "@@intelease/app-models/abstract-review";
import { FullValMultiPdfProvViewModel } from "@@intelease/api-models/adex-api-model-src";
import { MentionEditTrackerFacade } from "@@intelease/app-state/abstract-review/src";
import { NESTED_MENTION_ATTRIBUTIONS_FIELD_KEY } from "@@intelease/web/intelease/constants";
import { NestedGroupMentionValueModel } from "@@intelease/app-services/abstract-review/src";

@Injectable({
  providedIn: "root",
})
export class WebAbstractionProvisionService {
  private static readonly PROVISIONS_FORM_PATH = "assets/forms/provisions";

  constructor(
    private httpClient: HttpClient,
    private editProvisionValueService: EditProvisionValueService,
    private createProvisionValueService: CreateProvisionValueService,
    private deleteProvisionValueService: DeleteProvisionValueService,
    private fetchMultiProvisionValueService: FetchMultiProvisionValueService,
    private editMultiProvisionValueService: EditMultiProvisionValueService,
    private fetchBatchProvisionService: FetchBatchProvisionService,
    // private fetchProvisionService: FetchProvisionService,
    private editSnippetService: EditSnippetService,
    private deleteSnippetService: DeleteSnippetService,
    private commonFacade: CommonFacade,
    // private abstractReviewFacade: AbstractReviewFacade,
    private mentionEditTrackerFacade: MentionEditTrackerFacade
  ) {}

  getProvisionFormSchemaByType(
    type: string,
    multiProvisionValue: FullValMultiProvisionValueModel | undefined
  ): Observable<any> {
    return this.commonFacade.getProvisionsMetadata().pipe(
      map((provisionsMetadataRes) => {
        if (provisionsMetadataRes) {
          const { countries, currencies, states } = provisionsMetadataRes;
          const res = cloneDeep(PROVISIONS_FORMS[type.toLocaleLowerCase()]);
          switch (type) {
            case "MONEY":
              res.properties.currency.items = currencies.map((item) => {
                const { currency } = item;
                return { title: currency, value: currency };
              });
              return res;
            case "DOUBLE":
              res.properties.unit.items = multiProvisionValue.possibleUnits.map(
                (item) => {
                  return { title: item, value: item };
                }
              );
              return res;
            case "SINGLE_CAT":
            case "MULTIPLE_CAT":
              res.properties.value.items =
                multiProvisionValue.possibleValues.map((item) => {
                  return { title: item, value: item };
                });
              return res;
            case "ADDRESS":
              res.properties.state.items = states.map((item) => {
                const { name } = item;
                return { title: name, value: name };
              });
              res.properties.country.items = countries.map((item) => {
                const { name } = item;
                return { title: name, value: name };
              });
              return res;
            default:
              return res;
          }
        }
      })
    );
  }

  getProvisionFormSchemaByTypeNew(
    type: string,
    multiProvisionValue: FullValMultiProvisionValueModel | undefined,
    provision: FullValMultiPdfProvViewModel,
    mention: FullValMultiProvisionValueModel["options"][number],
    onNestedSubfieldLocate: (
      mention: FullValMultiProvisionValueModel["options"][number],
      fieldKey: string
    ) => void
  ): Observable<any> {
    return this.commonFacade.getProvisionsMetadata().pipe(
      map((provisionsMetadataRes) => {
        if (provisionsMetadataRes) {
          const { countries, states, units } = provisionsMetadataRes;
          const res = cloneDeep(PROVISIONS_FORMS_NEW[type.toLocaleLowerCase()]);
          const unitNameToUnitMap = keyBy(units, "unitName");
          const nestedValue = mention.value as NestedGroupMentionValueModel;
          const keyToMention = nestedValue?.keyToMention;
          const hasAttributions = nestedValue?.attributions?.length > 0;
          const keyToMentionMetadata = nestedValue?.keyToMentionMetadata;
          let subfieldKeys = new Set<string>();
          let removableSubfieldKeys = new Set<string>();
          switch (type) {
            case "MONEY":
              res.properties.currency.items =
                multiProvisionValue.possibleCurrencies.map((item) => {
                  const currency = item;
                  return {
                    title: currency,
                    value: currency,
                  };
                });
              return res;
            case "DOUBLE":
              res.properties.unit.items = multiProvisionValue.possibleUnits.map(
                (item) => {
                  return {
                    title: unitNameToUnitMap[item]?.shortUnitName,
                    value: item,
                  };
                }
              );
              return res;
            case "SINGLE_CAT":
            case "MULTIPLE_CAT":
              res.properties.value.items =
                multiProvisionValue.possibleValues.map((item) => {
                  return { title: item, value: item };
                });
              return res;
            case "ADDRESS":
              res.properties.state.items = states.map((item) => {
                const { name } = item;
                return { title: name, value: name };
              });
              res.properties.country.items = countries.map((item) => {
                const { name } = item;
                return { title: name, value: name };
              });
              return res;
            case "NESTED_GROUP":
              subfieldKeys = new Set(
                provision.provisionInfo.nestedGroupSubfields
                  .filter((subfield) => {
                    if (!keyToMention) {
                      return false;
                    }
                    const subfieldMention = keyToMention[subfield.modelKey];
                    return (
                      subfieldMention?.location?.page &&
                      subfieldMention?.location?.docAbstractUid
                    );
                  })
                  .map((subfield) => subfield.modelKey)
              );
              removableSubfieldKeys = new Set(
                provision.provisionInfo.nestedGroupSubfields
                  .filter((subfield) => {
                    if (!keyToMention) {
                      return false;
                    }
                    const subfieldMention = keyToMention[subfield.modelKey];
                    return !!subfieldMention;
                  })
                  .map((subfield) => subfield.modelKey)
              );

              res.properties = {
                ...res.properties,
                id: {
                  ...res.properties.id,
                  onTouched: () => {
                    this.mentionEditTrackerFacade.touchMentionUid(mention.uid);
                    this.mentionEditTrackerFacade.touchNestedSubfield(
                      undefined
                    );
                    this.mentionEditTrackerFacade.updateLastEditedNestedSubfield(
                      undefined
                    );
                  },
                },
                [NESTED_MENTION_ATTRIBUTIONS_FIELD_KEY]: {
                  modelKey: NESTED_MENTION_ATTRIBUTIONS_FIELD_KEY,
                  type: "string",
                  readOnly: true,
                  widget: "textarea",
                  class: "nested-group-provision-subfield",
                  placeholder: "",
                  description: "Attributions",
                  cols: "20",
                  rows: "5",
                  onTouched: (modelKey) => {
                    this.mentionEditTrackerFacade.touchMentionUid(mention.uid);
                    this.mentionEditTrackerFacade.touchNestedSubfield(modelKey);
                    this.mentionEditTrackerFacade.updateLastEditedNestedSubfield(
                      modelKey
                    );
                    this.mentionEditTrackerFacade.locateMentionSubfield(
                      undefined,
                      undefined
                    );
                  },
                  onOpenDetails: hasAttributions
                    ? (modelKey) => {
                        this.mentionEditTrackerFacade.nestedMentionAttributionOpen.next(
                          {
                            mentionUid: mention.uid,
                            subfieldKey: modelKey,
                          }
                        );
                      }
                    : undefined,
                },
                ...keyBy(
                  provision.provisionInfo.nestedGroupSubfields.map(
                    (subfield) => {
                      return {
                        modelKey: subfield.modelKey,
                        type: "string",
                        readOnly: false,
                        widget: "textarea",
                        class: "nested-group-provision-subfield",
                        placeholder: "",
                        description: subfield.uiName,
                        cols: "20",
                        rows: "5",
                        locationDocAbstractUid: subfieldKeys.has(
                          subfield.modelKey
                        )
                          ? keyToMention[subfield.modelKey]?.location
                              ?.docAbstractUid
                          : undefined,
                        locationPage: subfieldKeys.has(subfield.modelKey)
                          ? keyToMention[subfield.modelKey]?.location?.page
                          : undefined,
                        approvalStatus:
                          keyToMentionMetadata?.[subfield.modelKey]
                            ?.approvalStatus,
                        onTouched: (modelKey) => {
                          this.mentionEditTrackerFacade.touchMentionUid(
                            mention.uid
                          );
                          this.mentionEditTrackerFacade.touchNestedSubfield(
                            modelKey
                          );
                          this.mentionEditTrackerFacade.updateLastEditedNestedSubfield(
                            modelKey
                          );
                          this.mentionEditTrackerFacade.locateMentionSubfield(
                            undefined,
                            undefined
                          );
                        },
                        onLocate: subfieldKeys.has(subfield.modelKey)
                          ? (modelKey) => {
                              this.mentionEditTrackerFacade.locateMentionSubfield(
                                mention.uid,
                                modelKey
                              );
                              onNestedSubfieldLocate(mention, modelKey);
                            }
                          : undefined,
                        onDelete: removableSubfieldKeys.has(subfield.modelKey)
                          ? (modelKey) => {
                              this.mentionEditTrackerFacade.deleteSubfieldClick.next(
                                {
                                  mentionUid: mention.uid,
                                  subfieldKey: modelKey,
                                }
                              );
                            }
                          : undefined,
                        onApproveOrReject: (modelKey, approvalStatus) => {
                          this.mentionEditTrackerFacade.approveOrRejectSubfield.next(
                            {
                              mentionUid: mention.uid,
                              subfieldKey: modelKey,
                              approvalStatus: approvalStatus,
                            }
                          );
                        },
                      };
                    }
                  ),
                  "modelKey"
                ),
              };
              return res;
            default:
              return res;
          }
        }
      })
    );
  }

  getEmptyTableValue() {
    return {
      headers: ["", ""],
      types: ["COMMON_NOUN", "COMMON_NOUN"],
      rows: [
        ["", ""],
        ["", ""],
      ],
    };
  }

  getDefaultTableValue() {
    return {
      headers: [
        {
          prop: "col_0",
          name: "",
          editable: true,
        },
        {
          prop: "col_1",
          name: "",
          editable: true,
        },
      ],
      types: ["COMMON_NOUN", "COMMON_NOUN"],
      rows: [
        { col_0: "", col_1: "" },
        { col_0: "", col_1: "" },
      ],
      sampleRow: { col_0: "", col_1: "" },
      headerRow: {
        col_0: "",
        col_1: "",
      },
    };
  }

  getFailedParseTextFormSchema(): Observable<any> {
    return this.httpClient.get(
      `${WebAbstractionProvisionService.PROVISIONS_FORM_PATH}/failed_parsed.schema.json`
    );
  }

  /**
   * Get the list of provisions to be shown for this abstract.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @return the list of provisions for this abstract
   */
  getProvisionsByAbstractUid(
    abstractUid: string
  ): Observable<ListResponseModel<FullProvisionModel>> {
    return this.fetchBatchProvisionService.getProvisionsByAbstractUid(
      abstractUid,
      FullProvisionModel.view,
      FullProvisionModel
    );
  }

  updateMultiProvisionStatus(
    abstractUid: string,
    abstractProvisionGroups: FullProvisionGroupModel[],
    provision: FullProvisionModel
  ): Observable<FullProvisionModel> {
    const { uid, status } = provision;
    return this.editMultiProvisionValueService
      .updateMultiProvisionStatus(
        abstractUid,
        uid,
        status,
        FullProvisionModel.view,
        FullProvisionModel
      )
      .pipe(
        map((res) => {
          let found = false;
          for (const abstractProvisionGroup of abstractProvisionGroups) {
            if (found) {
              break;
            }
            for (
              let provIdx = 0;
              provIdx < abstractProvisionGroup.provisions.length;
              provIdx++
            ) {
              const thisProvision = abstractProvisionGroup.provisions[provIdx];
              if (thisProvision.uid === provision.uid) {
                abstractProvisionGroup.provisions[provIdx] = res;
                found = true;
                break;
              }
            }
          }
          return res;
        })
      );
  }

  updateMultiProvisionOrder(
    abstractUid: string,
    provisionUid: string,
    provisionValueUids: string[]
  ): Observable<ServerResponseModel> {
    return this.editMultiProvisionValueService.updateMultiProvisionOrderNoView(
      abstractUid,
      provisionUid,
      provisionValueUids
    );
  }

  /**
   * When user searches for a provision from the upper-right search or if chooses a provision upon highlighting
   * on the PDF, then call this API to be able to populate the upper-right provision box that opens up.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose possible values we want to show the user
   * @return the multi-provision value for this provision for this abstract
   */
  getMultiProvisionValue(
    abstractUid: string,
    provisionUid: string
  ): Observable<FullValMultiProvisionValueModel> {
    return this.fetchMultiProvisionValueService.getMultiProvisionValueView(
      abstractUid,
      provisionUid,
      FullValMultiProvisionValueModel.view,
      FullValMultiProvisionValueModel
    );
  }

  /**
   * When user manually creates a provision value himself (when backend had found no provision value options automatically),
   * then call this API when user saves his new manual value. Don't call this if user got his value from highlight.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param docAbstractUid - the uid of the document the user was at when he entered in the value
   * @param page - the page the user was at when he entered in the value
   * @param provisionUid - the uid of the provision whose possible values we want to show the user
   * @param notes - the notes associated with this provision
   * @param sectionHeader - the section header associated with this provision
   * @param value - the new, user-created value for this provision (data model from provision-type directory)
   * @param textValue - the new, user-created text value for this provision
   * @param type
   * @return response from server
   */
  manualCreateProvisionValueOption(
    abstractUid: string,
    docAbstractUid: string,
    page: number,
    provisionUid: string,
    notes: string,
    sectionHeader: string,
    value,
    textValue: string,
    type
  ): Observable<ServerResponseModel> {
    return this.createProvisionValueService.manualCreateProvisionValueOptionForReviewNoView(
      abstractUid,
      docAbstractUid,
      page,
      provisionUid,
      notes,
      sectionHeader,
      value,
      textValue,
      type
    );
  }

  /**
   * In the upper-right provision box, when user edits one or more of the fields for a currently selected provision value,
   * call this API to save the updated provision value.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value user is changing
   * @param provisionValueUid - the option's id being updated
   * @param notes - the notes associated with this provision
   * @param sectionHeader - the section header associated with this provision
   * @param value - the new, user-edited value for this provision (data model is from provision-type directory)
   * @param type
   * @param formatValues
   * @return the updated single option in the upper-right provision box
   */
  updateProvisionValueOption(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string,
    notes: string,
    sectionHeader: string,
    value,
    type: string,
    formatValues
  ): Observable<PartialValProvisionValueModel> {
    return this.editProvisionValueService.updateProvisionValueOption(
      abstractUid,
      provisionUid,
      provisionValueUid,
      notes,
      sectionHeader,
      value,
      type,
      formatValues,
      PartialValProvisionValueModel.view,
      PartialValProvisionValueModel
    );
  }

  /**
   * In the upper-right provision box, when user edits the textValue field for a currently selected provision value,
   * call this API to save the updated provision textValue.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value user is changing
   * @param provisionValueUid - the option's id being updated
   * @param notes - the notes associated with this provision
   * @param sectionHeader - the section header associated with this provision
   * @param textValue - the new, user-edited textValue for this provision
   * @return the updated single option in the upper-right provision box
   */
  updateProvisionTextValueOption(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string,
    notes: string,
    sectionHeader: string,
    textValue: string
  ): Observable<PartialValProvisionValueModel> {
    return this.editProvisionValueService.updateProvisionTextValueOption(
      abstractUid,
      provisionUid,
      provisionValueUid,
      notes,
      sectionHeader,
      textValue,
      PartialValProvisionValueModel.view,
      PartialValProvisionValueModel
    );
  }

  /**
   * In the upper-right provision box, when user selects a provision value option which
   * does not meet the criteria for chooseHighlightedProvisionValue function, then call this function.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value option user is choosing
   * @param provisionValueUid - the provision value option id that the user is selecting
   * @return response from server
   */
  selectProvisionValueOption(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string
  ): Observable<ServerResponseModel> {
    return this.editProvisionValueService.selectProvisionValueOptionNoView(
      abstractUid,
      provisionUid,
      provisionValueUid
    );
  }

  /**
   * In the upper-right provision box, when user de-selects a provision value option.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value option user is de-selecting
   * @param provisionValueUid - the provision value option id that the user is de-selecting
   * @return the response from server
   */
  deSelectProvisionValueOption(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string
  ): Observable<ServerResponseModel> {
    return this.editProvisionValueService.deSelectProvisionValueOptionNoView(
      abstractUid,
      provisionUid,
      provisionValueUid
    );
  }

  /**
   * In the upper-right provision box, when user selects a provision value whose {source
   * is "CUSTOMER_HIGHLIGHTED" or "CUSTOMER_HIGHLIGHTED_AND_MODIFY"} and which was just obtained
   * from parseProvisionFromHighlight function, then call this API.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value user is choosing
   * @param provisionValueUid - the uid of the provision value option the user is choosing
   * @param notes - the additional notes associated with this provision
   * @param sectionHeader - the section header associated with this provision
   * @param page - the first page of the pdf highlight for this value - lowest value is 1
   * @param highlightedText - the text that the user highlighted on the pdf
   * @param docUid - the uid of the specific document on which user highlighted
   * @param value - data model (from provision-type directory) representing the provision value
   * @param pdfProvisionHighlights - list of objects (exactly from the Polar library)
   * @param indexInText
   * @param textSurroundingOriginalProvision
   * @param surroundingSentence
   * representing the highlight for this provision (1 for each page on which it's highlighted)
   * @param type
   * @param formatValues
   * @param highlightType
   * @return response from server
   */
  createHighlightedProvisionValueOption(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string,
    notes: string,
    sectionHeader: string,
    page: number,
    highlightedText: string,
    docUid: string,
    value,
    pdfProvisionHighlights,
    indexInText: number,
    textSurroundingOriginalProvision: string,
    surroundingSentence: string,
    type: string,
    formatValues,
    highlightType: AppHighlightTypeEnum
  ): Observable<ServerResponseModel> {
    return this.createProvisionValueService.createHighlightedProvisionValueOptionNoView(
      abstractUid,
      provisionUid,
      provisionValueUid,
      notes,
      sectionHeader,
      page,
      highlightedText,
      docUid,
      value,
      pdfProvisionHighlights,
      indexInText,
      textSurroundingOriginalProvision,
      surroundingSentence,
      type,
      formatValues,
      highlightType
    );
  }

  /**
   * In the upper-right provision box, when user selects a provision value whose {source
   * is "CUSTOMER_HIGHLIGHTED" or "CUSTOMER_HIGHLIGHTED_AND_MODIFY"} and which was unable to be parsed
   * from parseProvisionFromHighlight function (i.e. stored in textValue instead of value), then call this API.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value user is choosing
   * @param provisionValueUid - the uid of the provision value option the user is choosing
   * @param notes - the additional notes associated with this provision
   * @param sectionHeader - the section header associated with this provision
   * @param page - the first page of the pdf highlight for this value - lowest value is 1
   * @param highlightedText - the text that the user highlighted on the pdf
   * @param docUid - the uid of the specific document on which user highlighted
   * @param textValue - the provision textValue
   * @param pdfProvisionHighlights - list of objects (exactly from the Polar library)
   * @param indexInText
   * @param textSurroundingOriginalProvision
   * @param surroundingSentence
   * representing the highlight for this provision (1 for each page on which it's highlighted)
   * @return response from server
   */
  createHighlightedProvisionTextValueOption(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string,
    notes: string,
    sectionHeader: string,
    page: number,
    highlightedText: string,
    docUid: string,
    textValue: string,
    pdfProvisionHighlights,
    indexInText: number,
    textSurroundingOriginalProvision: string,
    surroundingSentence: string
  ): Observable<ServerResponseModel> {
    return this.createProvisionValueService.createHighlightedProvisionTextValueOptionNoView(
      abstractUid,
      provisionUid,
      provisionValueUid,
      notes,
      sectionHeader,
      page,
      highlightedText,
      docUid,
      textValue,
      pdfProvisionHighlights,
      indexInText,
      textSurroundingOriginalProvision,
      surroundingSentence
    );
  }

  /**
   * When user clicks on "X" in small menu for a highlighted provision value, then call this function.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value the user is deleting
   * @param provisionValueUid - the provision value option id to delete
   * @return response from server
   */
  deleteProvisionValueOption(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string
  ): Observable<ServerResponseModel> {
    return this.deleteProvisionValueService.deleteProvisionValueNoView(
      abstractUid,
      provisionUid,
      provisionValueUid
    );
  }

  /**
   * When user highlights some text on the PDF and chooses a provision,
   * call this API to retrieve a provision value to display for this provision,
   * as parsed from the highlighted text.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param highlightedText - the text that the user highlighted on the pdf
   * @param firstPage - the first page of the highlight - lowest value is 1
   * @param lastPage - the last page of the highlight (same as firstPage unless user's highlight spanned multiple pages) - lowest value is 1
   * @param docUid - the uid of the specific document on which user highlighted
   * @param provisionUid - the uid of the provision the user chose
   * @param pdfProvisionHighlightIds - the list of string ids for the highlights for this highlighted provision value (1 for each page)
   * @param pdfProvisionHighlights
   * @param numHead
   * @param boundingBox
   * @param highlightType
   * @return the parsed provision value (else textValue, if unable to be parsed)
   */
  parseProvisionFromHighlight(
    abstractUid: string,
    highlightedText: string,
    firstPage: number,
    lastPage: number,
    docUid: string,
    provisionUid: string,
    pdfProvisionHighlightIds: string[],
    pdfProvisionHighlights,
    numHead?: number,
    boundingBox?,
    highlightType?: AppHighlightTypeEnum,
    primaryFormatOnly?: boolean
  ): Observable<HighlightedProvisionValueModel> {
    return this.createProvisionValueService.parseProvisionFromHighlight(
      abstractUid,
      highlightedText,
      firstPage,
      lastPage,
      docUid,
      provisionUid,
      pdfProvisionHighlightIds,
      pdfProvisionHighlights,
      HighlightedProvisionValueModel.view,
      HighlightedProvisionValueModel,
      numHead,
      boundingBox,
      highlightType,
      primaryFormatOnly
    );
  }

  prepareProvisionValue(provisionType: string): ProvisionFunctionsInterface {
    return this.editProvisionValueService.prepareProvisionValueForReview(
      provisionType
    );
  }

  prepareProvisionValueNew(provisionType: string): ProvisionFunctionsInterface {
    return this.editProvisionValueService.prepareProvisionValueForReviewNew(
      provisionType
    );
  }

  pickSnippetProvisions(
    abstractUid: string,
    provisionUid: string,
    snippetUid: string
  ) {
    return this.editSnippetService.pickSnippetProvisions(
      abstractUid,
      provisionUid,
      snippetUid
    );
  }

  deleteSnippet(abstractUid: string, provisionUid: string, snippetUid: string) {
    return this.deleteSnippetService.deleteSnippet(
      abstractUid,
      provisionUid,
      snippetUid
    );
  }
}
