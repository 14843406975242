import { Injectable } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { AbstractExportModalComponent } from "./abstract-export-modal.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class AbstractExportModalService {
  constructor(private dialog: MatDialog) {}

  openAbstractExportModal(
    modalData: ModalInputModel,
    options: MatDialogConfig = { minWidth: 340 }
  ) {
    return this.dialog.open(AbstractExportModalComponent, {
      data: modalData,
      disableClose: true,
      panelClass: "abstract-export-modal",
      minWidth: options.minWidth,
    });
  }
}
