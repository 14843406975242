import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/internal/Subject";
import { Observable } from "rxjs/internal/Observable";

@Injectable()
export class NavigateService {
  activeUrl: string;
  activeUrlChange: Subject<string> = new Subject<string>();

  constructor(private router: Router) {}

  setActiveTab() {
    this.setActiveTabUrl(this.router.url);
  }

  setActiveTabUrl(url: string) {
    const queryParamsIndex = url.indexOf("?");
    const baseUrl =
      queryParamsIndex === -1 ? url : url.slice(0, queryParamsIndex);
    const splitUrl = baseUrl.split("/");
    if (splitUrl.length && splitUrl[1]) {
      this.activeUrl = splitUrl[1];
      this.activeUrlChange.next(splitUrl[1]);
    }
  }

  getActiveUrlChange(): Observable<string> {
    return this.activeUrlChange.asObservable();
  }
}
