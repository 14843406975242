import { JsonConverter, JsonCustomConvert } from "json2typescript";
import { DateUtil } from "../../utils/src/lib/date-util";
import * as moment from "moment-timezone";
import { DateModel } from "../models/date.model";

@JsonConverter
export class DateConvertService implements JsonCustomConvert<Date> {
  public static serializeDate(date: Date): any {
    if (!date) {
      return null;
    }
    return DateUtil.serializeToExactDate(date);
  }

  public static deserializeDate(date: DateModel): Date {
    if (!date) {
      return null;
    }
    date.month = date.month - 1;
    return moment(date).toDate();
  }

  serialize(date: Date): any {
    return DateConvertService.serializeDate(date);
  }

  deserialize(date): Date {
    return DateConvertService.deserializeDate(date);
  }
}
