/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";

/**
 * a minimal document representation
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MinimalDocumentDtoModel")
export class MinimalDocumentDtoModel {
  @JsonProperty("date", SimpleOpenApiDateModel, true)
  date?: SimpleOpenApiDateModel = undefined;

  /**
   * the date of the document (as a string)
   */

  @JsonProperty("dateTxt", String, true)
  dateTxt?: string = "";

  /**
   * the name of the document
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the notes of the document
   */

  @JsonProperty("notes", String, true)
  notes?: string = "";

  /**
   * the type of the document
   */

  @JsonProperty("type", String, true)
  type?: string = "";
}
