/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SettingItemDtoModel")
export class SettingItemDtoModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("format", Any, true)
  format?:
    | "BOOLEAN"
    | "SINGLE_SELECT"
    | "MULTI_SELECT"
    | "INTEGER"
    | "DOUBLE"
    | "TEXT" = undefined;

  @JsonProperty("key", String, true)
  key?: string = "";

  @JsonProperty("possibleValues", [{}], true)
  possibleValues?: Array<{}> = [];

  @JsonProperty("source", Any, true)
  source?: "GENERAL" | "PIPELINE_PROCESSING" = undefined;

  @JsonProperty("value", Any, true)
  value?: any = {};
}
