import { ModuleWithProviders, NgModule } from "@angular/core";
import { SideMenuComponent } from "@@intelease/web/intelease/components/side-menu/side-menu.component";
import { CommonModule } from "@angular/common";
import { NgScrollbarModule } from "ngx-scrollbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";

@NgModule({
  imports: [CommonModule, TooltipModule.forRoot(), NgScrollbarModule],
  declarations: [SideMenuComponent],
  exports: [SideMenuComponent],
})
export class SideMenuModule {
  static forRoot(): ModuleWithProviders<SideMenuModule> {
    return {
      ngModule: SideMenuModule,
    };
  }
}
