<ng-container *ngIf="!item.hidden">
  <ng-container *ngxPermissionsOnly="expectedAuthorities">
    <div
      class="{{
        item.groupType === 'line' && item.id !== 'FIRST'
          ? 'group-title'
          : item.classes
      }}"
      [ngClass]="item.classes"
    >
      <span
        class="hint-text"
        *ngIf="item.groupType === 'line' && item.id !== 'FIRST'"
        style="border-bottom: 1px solid #ebebeb; width: 80%"
      ></span>
      <span class="hint-text" *ngIf="item.groupType === 'text'">{{
        item.title
      }}</span>
    </div>

    <div class="group-items">
      <ng-container *ngFor="let item of item.children">
        <itls-nav-vertical-group
          *ngIf="item.type == 'group'"
          [item]="item"
        ></itls-nav-vertical-group>
        <itls-nav-vertical-collapsable
          *ngIf="item.type == 'collapsable'"
          [item]="item"
        ></itls-nav-vertical-collapsable>
        <itls-nav-vertical-item
          *ngIf="item.type == 'item'"
          [item]="item"
        ></itls-nav-vertical-item>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
