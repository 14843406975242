/* tslint:disable */
/* eslint-disable */
import { DocumentsUploadDtoModel } from "./documents-upload-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqDocumentsUploadDtoModel")
export class OApiReqDocumentsUploadDtoModel {
  @JsonProperty("data", DocumentsUploadDtoModel, true)
  data?: DocumentsUploadDtoModel = undefined;
}
