import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
} from "@angular/core";
import createNumberMask from "text-mask-addons/dist/createNumberMask.js";
import emailMask from "text-mask-addons/dist/emailMask.js";
import {
  cloneDeep,
  first as _first,
  isEmpty,
  isNull,
  isString,
  isUndefined,
  keys,
  values,
} from "lodash";
import { WebAbstractionProvisionService } from "@@intelease/web/abstraction-page/src/lib/services/web-abstraction-provision.service";
import { TitleValueModel } from "@@intelease/app-models/common";
import { ItlsFilterWidgetService } from "@@intelease/web/ui/src/lib/itls-filter/services/itls-filter-widget.service";
import { Status } from "@@intelease/web/abstract-detail/src/lib/enums";
import { CommonFacade } from "@@intelease/app-state/common/src";
import { AddressProvisionModel } from "@@intelease/web/common/models";

@Component({
  selector: "il-filter-widget-chooser",
  templateUrl: "./itls-filter-widget-chooser.component.html",
  styleUrls: ["./itls-filter-widget-chooser.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsFilterWidgetChooserComponent {
  private _field: any;
  @Input() set field(value) {
    this._field = value;
    this.initModel();
  }

  get field() {
    return this._field;
  }

  @Input() relation: any;
  @Input() filter: any;

  @Input() set filterFields(value) {
    this._filterFields = value;
    this.initModel();
  }

  get filterFields(): any[] {
    return this._filterFields;
  }

  _filterFields: any[] = [];

  @Input() set type(value) {
    this._type = value;
    this.initModel();
  }

  get type() {
    return this._type;
  }

  private _type;

  @Input() set model(value) {
    this._model = value;
    this.initModel();
  }

  get model() {
    return this._model;
  }

  private _model;
  @Output() widgetModelChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("fieldSelection", { static: false }) fieldSelection;
  moneyMask = createNumberMask({ prefix: "", allowDecimal: true });
  emailMask = emailMask;
  formSchema;
  originalFormSchema;
  formFieldList: { title: string; value: string }[] = [];
  selectedYear = "";
  possibleSingleCatValues: TitleValueModel[];
  knownEnum = false;
  Status = Status;
  multipleCatPossibleItems: any[];
  selectedMultipleCatItems: any;
  // masks = [{
  //   name: 'US phone number',
  //   mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  //   placeholder: '(555) 495-3947'
  // }, {
  //   name: 'US phone number with country code',
  //   mask: ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  //   placeholder: '+1 (555) 495-3947'
  // }, {
  //   name: 'Date',
  //   mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  //   placeholder: '25/09/1970'
  // }, {
  //   name: 'Date (auto-corrected)',
  //   mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  //   pipe: createAutoCorrectedDatePipe(),
  //   placeholder: 'Please enter a date',
  //   keepCharPositions: true,
  // }, {
  //   name: 'US dollar amount',
  //   mask: createNumberMask(),
  //   placeholder: 'Enter an amount',
  // }, {
  //   name: 'US dollar amount (allows decimal)',
  //   mask: createNumberMask({allowDecimal: true}),
  //   placeholder: 'Enter an amount',
  // }, {
  //   name: 'Percentage amount',
  //   mask: createNumberMask({suffix: '%', prefix: ''}),
  //   placeholder: 'Enter an amount',
  // }, {
  //   name: 'Email',
  //   mask: emailMask,
  //   placeholder: 'john@smith.com',
  //   placeholderChar: placeholderChars.whitespace
  // }, {
  //   name: 'US zip code',
  //   mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
  //   placeholder: '94303',
  //   placeholderChar: placeholderChars.underscore
  // }, {
  //   name: 'Canadian postal code',
  //   mask: [alphabetic, digit, alphabetic, ' ', digit, alphabetic, digit],
  //   pipe: (conformedValue) => ({value: conformedValue.toUpperCase()}),
  //   placeholder: 'K1A 0B2',
  //   placeholderChar: placeholderChars.underscore
  // }, {
  //   name: 'Credit Card',
  //   mask: [
  //     /\d/, /\d/, /\d/, /\d/, ' ',
  //     /\d/, /\d/, /\d/, /\d/, ' ',
  //     /\d/, /\d/, /\d/, /\d/, ' ',
  //     /\d/, /\d/, /\d/, /\d/
  //   ],
  //   placeholder: '4242 4242 4242 4242'
  // }

  constructor(
    private webAbstractionProvisionService: WebAbstractionProvisionService,
    private _cdr: ChangeDetectorRef,
    private itlsFilterWidgetService: ItlsFilterWidgetService,
    private commonFacade: CommonFacade
  ) {}

  private initModel() {
    this.possibleSingleCatValues = undefined;
    this.knownEnum = false;
    if (this.field) {
      const selectedField = this.filterFields.find(
        (item) => item.fieldName === this.field.fieldName
      );
      this.field.allowedTypes =
        selectedField && selectedField.allowedTypes
          ? selectedField.allowedTypes
          : [{ label: "String", value: "STRING" }];
      switch (this.type) {
        case "DATE":
        case "LOCAL_DATE":
          this._model = this.model;
          return this._model;
        case "DOUBLE": {
          this._model =
            isUndefined(this.model) ||
            isNull(this.model) ||
            isEmpty(this.model) ||
            this.model.length === 0
              ? {
                  unit: _first(this.field.possibleUnits),
                  value: "",
                }
              : this.model;
          this.field.possibleUnits = selectedField
            ? selectedField.possibleUnits
            : [];
          return this._model;
        }
        case "MONEY": {
          this._model =
            isUndefined(this.model) ||
            isNull(this.model) ||
            isEmpty(this.model) ||
            this.model.length === 0
              ? {
                  currency: _first(this.field.possibleCurrencies),
                  value: "",
                }
              : this.model;
          this.field.possibleCurrencies = selectedField
            ? selectedField.possibleCurrencies
            : [];
          return this._model;
        }
        case "EMAIL": {
          return this._model;
        }
        case "ADDRESS": {
          this.getFormSchemaByType("ADDRESS", (formSchema) => {
            this.formSchema = formSchema;
            this.formFieldList = [];
            // tslint:disable-next-line:forin
            for (const key in formSchema.properties) {
              this.formFieldList.push({
                title: formSchema.properties[key].description,
                value: key,
              });
            }
            this.originalFormSchema = formSchema;
          });
          if (!this._model) {
            this._model = { streetAddress: "" };
          }
          return this._model;
        }
        case "SINGLE_CAT":
          this.initializeStateForKnownEnum();
          this._model = isString(this.model) ? this.model : undefined;
          return this._model;
        case "MULTIPLE_CAT":
          if (this.field?.uiName === "Tags") {
            this.commonFacade
              .getAccountCustomTags()
              .subscribe((customTags: any) => {
                this.multipleCatPossibleItems = customTags || [];

                if (this.model?.value) {
                  this.selectedMultipleCatItems =
                    this.multipleCatPossibleItems.filter((cat) =>
                      this.model.value.map((m) => m.value).includes(cat.name)
                    );
                }
              });
          } else {
            this.multipleCatPossibleItems = selectedField?.possibleValues || [];
            if (this.model?.value) {
              this.selectedMultipleCatItems =
                this.multipleCatPossibleItems.filter((cat) =>
                  this.model.value.map((m) => m.value).includes(cat)
                );
            }
          }
          return this._model;
        case "STRING":
        default:
          this._model = isString(this.model) ? this.model : "";
      }
    }
  }

  onYearSelected(evt, yearPicker) {
    this.model = new Date(evt).getFullYear().toString();
    this.onWidgetModelChange();
    yearPicker.close();
  }

  onWidgetModelChange() {
    this.widgetModelChange.emit({ model: this.model });
  }

  onMultipleCatModelChange() {
    if (this.field?.uiName === "Tags") {
      this.widgetModelChange.emit({
        model: {
          value: this.selectedMultipleCatItems.map((item) => {
            return { value: item.name };
          }),
        },
      });
    } else {
      this.widgetModelChange.emit({
        model: {
          value: this.selectedMultipleCatItems.map((item) => {
            return { value: item };
          }),
        },
      });
    }
  }

  onAddressModelChange(evt, type: "input" | "schemaForm") {
    if (type === "schemaForm") {
      const { value } = evt;
      if (evt && value && !isEmpty(value)) {
        this.model = { ...value };
        this.widgetModelChange.emit({ model: this.model });
      }
    } else {
      this.formSchema = undefined;
      setTimeout(() => {
        this.formSchema = cloneDeep(this.originalFormSchema);
      }, 1);
    }
  }

  getFormSchemaByType(type: string, cb?) {
    this.webAbstractionProvisionService
      .getProvisionFormSchemaByType(type, undefined)
      .subscribe((formSchema) => {
        if (cb) {
          cb(formSchema);
        }
      });
  }

  private initializeStateForKnownEnum(): void {
    if (this.field?.category === "GENERAL") {
      // for general fields
      if (this.field?.fieldName === "docSetCategory") {
        this.possibleSingleCatValues =
          this.itlsFilterWidgetService.getAccountDocSetCategories();
        this.knownEnum = typeof this.possibleSingleCatValues !== "undefined";
      }
    } else if (this.field?.category === "PROVISION") {
      // for provision fields
      this.possibleSingleCatValues =
        this.itlsFilterWidgetService.getPossibleValuesForSingleCatProvision(
          this.field?.fieldName
        );
      this.knownEnum = typeof this.possibleSingleCatValues !== "undefined";
    }
  }
}

export enum AddressKeyEnum {
  city = "city",
  country = "country",
  postalCode = "postalCode",
  state = "state",
  streetAddress = "streetAddress",
  suite = "suite",
}

@Pipe({
  name: "convertAddressToString",
  pure: true,
})
export class ConvertAddressToStringPipe implements PipeTransform {
  private static readonly ADDRESS_MAP: Record<AddressKeyEnum, string> = {
    [AddressKeyEnum.city]: "City",
    [AddressKeyEnum.country]: "Country",
    [AddressKeyEnum.postalCode]: "Postal Code",
    [AddressKeyEnum.state]: "State",
    [AddressKeyEnum.streetAddress]: "Street Address",
    [AddressKeyEnum.suite]: "Street Address 2",
  };

  public transform(model: AddressProvisionModel, withPrefixes = false): string {
    if (withPrefixes) {
      return keys(model)
        .filter((key) => model[key])
        .reduce((result, key) => {
          const title = this.getTitle(model[key]);
          return `${result} ${ConvertAddressToStringPipe.ADDRESS_MAP[key]} : ${title}\r\n`;
        }, "");
    }

    return values(model)
      .filter(Boolean)
      .reduce((result, item, index) => {
        const title = this.getTitle(item);
        return !index ? title : `${result}, ${title}`;
      }, "");
  }

  private getTitle(item): string {
    return typeof item === "object" ? (item as any).title : item;
  }
}
