import { NgModule } from "@angular/core";
import { InputDropdownComponent } from "./input-dropdown.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CommonDirectivesModule } from "@common/directives";
import { OnlyNumber } from "./only-numbers.directive";

@NgModule({
  declarations: [OnlyNumber, InputDropdownComponent],
  imports: [NgSelectModule, FormsModule, CommonModule, CommonDirectivesModule],
  exports: [InputDropdownComponent, OnlyNumber],
})
export class InputDropdownModule {}
