/* tslint:disable */
/* eslint-disable */
import { SupportingDataModel } from "./supporting-data-model";

/**
 * the escalation rate that was applied to reach this value
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EscalationRateModel")
export class EscalationRateModel {
  @JsonProperty("supportingData", SupportingDataModel, true)
  supportingData?: SupportingDataModel = undefined;

  @JsonProperty("value", Number, true)
  value?: number = undefined;
}
