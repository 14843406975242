/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqDocAbstractDiffRequestModel } from "../models/o-api-req-doc-abstract-diff-request-model";
import { OApiReqDocEditRequestModel } from "../models/o-api-req-doc-edit-request-model";
import { OApiRespDocPairDiffResultModel } from "../models/o-api-resp-doc-pair-diff-result-model";
import { OApiRespEventsAbstractViewModel } from "../models/o-api-resp-events-abstract-view-model";
import { OApiRespFinalAbstractViewCompleteModel } from "../models/o-api-resp-final-abstract-view-complete-model";
import { OApiRespFormNameAbstractViewModel } from "../models/o-api-resp-form-name-abstract-view-model";
import { OApiRespFullDocPairDiffViewModel } from "../models/o-api-resp-full-doc-pair-diff-view-model";
import { OApiRespFullFinalAbstractViewModel } from "../models/o-api-resp-full-final-abstract-view-model";
import { OApiRespMinimalFinalAbstractViewModel } from "../models/o-api-resp-minimal-final-abstract-view-model";
import { OApiRespMultiProvisionStringsAbstractViewModel } from "../models/o-api-resp-multi-provision-strings-abstract-view-model";
import { OApiRespMultiProvisionStringsExportAbstractViewModel } from "../models/o-api-resp-multi-provision-strings-export-abstract-view-model";
import { OApiRespMultiProvisionValuesAbstractViewModel } from "../models/o-api-resp-multi-provision-values-abstract-view-model";
import { OApiRespPermissionsAbstractViewV2Model } from "../models/o-api-resp-permissions-abstract-view-v-2-model";
import { OApiRespProvisionFormAbstractViewModel } from "../models/o-api-resp-provision-form-abstract-view-model";
import { OApiRespProvisionValuesAbstractViewModel } from "../models/o-api-resp-provision-values-abstract-view-model";
import { OApiRespRelatedDocsAbstractViewModel } from "../models/o-api-resp-related-docs-abstract-view-model";
import { OApiRespRelatedDocumentModel } from "../models/o-api-resp-related-document-model";
import { OApiRespTableOfContentsViewModel } from "../models/o-api-resp-table-of-contents-view-model";
import { OApiSingleEntityNoDataResponseModel } from "../models/o-api-single-entity-no-data-response-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class DocumentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getDocSetOriginalDocuments
   */
  static readonly GetDocSetOriginalDocumentsPath =
    "/api/v1/documents/doc-sets/{docSetUid}";

  /**
   * Get the doc set's original documents (as uploaded by the user).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocSetOriginalDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocSetOriginalDocuments$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.GetDocSetOriginalDocumentsPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/zip",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get the doc set's original documents (as uploaded by the user).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocSetOriginalDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocSetOriginalDocuments(params?: {}): Observable<any> {
    return this.getDocSetOriginalDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation docPairDiff
   */
  static readonly DocPairDiffPath = "/api/v1/docPairDiffs";

  /**
   * Get difference between 2 documents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `docPairDiff()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  docPairDiff$Response(params: {
    body: OApiReqDocAbstractDiffRequestModel;
  }): Observable<StrictHttpResponse<OApiRespDocPairDiffResultModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.DocPairDiffPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespDocPairDiffResultModel>;
        })
      );
  }

  /**
   * Get difference between 2 documents.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `docPairDiff$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  docPairDiff(params: {
    body: OApiReqDocAbstractDiffRequestModel;
  }): Observable<OApiRespDocPairDiffResultModel> {
    return this.docPairDiff$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespDocPairDiffResultModel>) =>
          r.body as OApiRespDocPairDiffResultModel
      )
    );
  }

  /**
   * Path part for operation docPairDiffAsync
   */
  static readonly DocPairDiffAsyncPath = "/api/v1/docPairDiffsAsync";

  /**
   * Get difference between 2 documents asynchronously.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `docPairDiffAsync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  docPairDiffAsync$Response(params: {
    body: OApiReqDocAbstractDiffRequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.DocPairDiffAsyncPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Get difference between 2 documents asynchronously.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `docPairDiffAsync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  docPairDiffAsync(params: {
    body: OApiReqDocAbstractDiffRequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.docPairDiffAsync$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation getDiffSourceDocument
   */
  static readonly GetDiffSourceDocumentPath =
    "/api/v1/sourceDiffDocs/finalDocSets/{recordUid}/docPairDiffs/{diffUid}/documents/{docUid}";

  /**
   * Get source of a specific annotated document part of a diff.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiffSourceDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiffSourceDocument$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.GetDiffSourceDocumentPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/pdf",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get source of a specific annotated document part of a diff.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDiffSourceDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiffSourceDocument(params?: {}): Observable<any> {
    return this.getDiffSourceDocument$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getDiffTargetDocument
   */
  static readonly GetDiffTargetDocumentPath =
    "/api/v1/targetDiffDocs/finalDocSets/{recordUid}/docPairDiffs/{diffUid}/documents/{docUid}";

  /**
   * Get target of a specific annotated document part of a diff.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiffTargetDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiffTargetDocument$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.GetDiffTargetDocumentPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/pdf",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get target of a specific annotated document part of a diff.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDiffTargetDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiffTargetDocument(params?: {}): Observable<any> {
    return this.getDiffTargetDocument$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getDocPairDiff
   */
  static readonly GetDocPairDiffPath = "/api/v1/docPairDiffs/{docDiffUid}";

  /**
   * Get result of the difference between 2 documents that was run asynchronously.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocPairDiff()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocPairDiff$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespFullDocPairDiffViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.GetDocPairDiffPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullDocPairDiffViewModel>;
        })
      );
  }

  /**
   * Get result of the difference between 2 documents that was run asynchronously.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocPairDiff$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocPairDiff(params?: {}): Observable<OApiRespFullDocPairDiffViewModel> {
    return this.getDocPairDiff$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullDocPairDiffViewModel>) =>
          r.body as OApiRespFullDocPairDiffViewModel
      )
    );
  }

  /**
   * Path part for operation editDocDetails
   */
  static readonly EditDocDetailsPath =
    "/api/v1/records/{recordUid}/documents/{docAbstractUid}";

  /**
   * Edit the details for a document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editDocDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editDocDetails$Response(params: {
    recordUid: string;
    docAbstractUid: string;
    body: OApiReqDocEditRequestModel;
  }): Observable<StrictHttpResponse<OApiRespRelatedDocsAbstractViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.EditDocDetailsPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("docAbstractUid", params.docAbstractUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRelatedDocsAbstractViewModel>;
        })
      );
  }

  /**
   * Edit the details for a document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editDocDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editDocDetails(params: {
    recordUid: string;
    docAbstractUid: string;
    body: OApiReqDocEditRequestModel;
  }): Observable<OApiRespRelatedDocsAbstractViewModel> {
    return this.editDocDetails$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRelatedDocsAbstractViewModel>) =>
          r.body as OApiRespRelatedDocsAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation editFilename
   */
  static readonly EditFilenamePath =
    "/api/v1/finalDocSets/{recordUid}/documents/{docUid}/filename";

  /**
   * Edit the filename for a document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editFilename()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editFilename$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullFinalAbstractViewModel
      | OApiRespFormNameAbstractViewModel
      | OApiRespMultiProvisionStringsAbstractViewModel
      | OApiRespMultiProvisionStringsExportAbstractViewModel
      | OApiRespFinalAbstractViewCompleteModel
      | OApiRespEventsAbstractViewModel
      | OApiRespRelatedDocsAbstractViewModel
      | OApiRespPermissionsAbstractViewV2Model
      | OApiRespMinimalFinalAbstractViewModel
      | OApiRespProvisionValuesAbstractViewModel
      | OApiRespTableOfContentsViewModel
      | OApiRespMultiProvisionValuesAbstractViewModel
      | OApiRespProvisionFormAbstractViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.EditFilenamePath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >;
        })
      );
  }

  /**
   * Edit the filename for a document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editFilename$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editFilename(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespFullFinalAbstractViewModel
    | OApiRespFormNameAbstractViewModel
    | OApiRespMultiProvisionStringsAbstractViewModel
    | OApiRespMultiProvisionStringsExportAbstractViewModel
    | OApiRespFinalAbstractViewCompleteModel
    | OApiRespEventsAbstractViewModel
    | OApiRespRelatedDocsAbstractViewModel
    | OApiRespPermissionsAbstractViewV2Model
    | OApiRespMinimalFinalAbstractViewModel
    | OApiRespProvisionValuesAbstractViewModel
    | OApiRespTableOfContentsViewModel
    | OApiRespMultiProvisionValuesAbstractViewModel
    | OApiRespProvisionFormAbstractViewModel
  > {
    return this.editFilename$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
          >
        ) =>
          r.body as
            | OApiRespFullFinalAbstractViewModel
            | OApiRespFormNameAbstractViewModel
            | OApiRespMultiProvisionStringsAbstractViewModel
            | OApiRespMultiProvisionStringsExportAbstractViewModel
            | OApiRespFinalAbstractViewCompleteModel
            | OApiRespEventsAbstractViewModel
            | OApiRespRelatedDocsAbstractViewModel
            | OApiRespPermissionsAbstractViewV2Model
            | OApiRespMinimalFinalAbstractViewModel
            | OApiRespProvisionValuesAbstractViewModel
            | OApiRespTableOfContentsViewModel
            | OApiRespMultiProvisionValuesAbstractViewModel
            | OApiRespProvisionFormAbstractViewModel
      )
    );
  }

  /**
   * Path part for operation getDefaultUpdatedPdfDocument
   */
  static readonly GetDefaultUpdatedPdfDocumentPath =
    "/api/v1/finalDocSets/{recordUid}/documents/default";

  /**
   * Get the default updated PDF document for the given abstract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultUpdatedPdfDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultUpdatedPdfDocument$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.GetDefaultUpdatedPdfDocumentPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/pdf",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get the default updated PDF document for the given abstract.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDefaultUpdatedPdfDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultUpdatedPdfDocument(params?: {}): Observable<any> {
    return this.getDefaultUpdatedPdfDocument$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getDocumentMetadata
   */
  static readonly GetDocumentMetadataPath =
    "/api/v1/finalDocSets/{recordUid}/documents/{docAbstractUid}/metadata";

  /**
   * Get a specific document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentMetadata$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespRelatedDocumentModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.GetDocumentMetadataPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespRelatedDocumentModel>;
        })
      );
  }

  /**
   * Get a specific document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentMetadata(params?: {}): Observable<OApiRespRelatedDocumentModel> {
    return this.getDocumentMetadata$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespRelatedDocumentModel>) =>
          r.body as OApiRespRelatedDocumentModel
      )
    );
  }

  /**
   * Path part for operation getRecordOriginalDocument
   */
  static readonly GetRecordOriginalDocumentPath =
    "/api/v1/viewDocuments/{docAbstractUid}/abstracts/{recordUid}";

  /**
   * Get a specific original document (as uploaded by the user).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecordOriginalDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecordOriginalDocument$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.GetRecordOriginalDocumentPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/pdf",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get a specific original document (as uploaded by the user).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecordOriginalDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecordOriginalDocument(params?: {}): Observable<any> {
    return this.getRecordOriginalDocument$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getRecordOriginalDocuments
   */
  static readonly GetRecordOriginalDocumentsPath =
    "/api/v1/documents/records/{recordUid}";

  /**
   * Get the record's original documents (as uploaded by the user).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecordOriginalDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecordOriginalDocuments$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.GetRecordOriginalDocumentsPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/zip",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get the record's original documents (as uploaded by the user).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecordOriginalDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecordOriginalDocuments(params?: {}): Observable<any> {
    return this.getRecordOriginalDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getUpdatedPdfDocument
   */
  static readonly GetUpdatedPdfDocumentPath =
    "/api/v1/finalDocSets/{recordUid}/documents/{docAbstractUid}";

  /**
   * Get the updated OCR PDF file associated with the given abstract and docAbstract id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUpdatedPdfDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpdatedPdfDocument$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentService.GetUpdatedPdfDocumentPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/pdf",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get the updated OCR PDF file associated with the given abstract and docAbstract id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUpdatedPdfDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpdatedPdfDocument(params?: {}): Observable<any> {
    return this.getUpdatedPdfDocument$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }
}
