export class MyFile {
  file: File;
  name: string;
  progress: number;
  inProgress: boolean;

  constructor(file: File, name: string, progress: number, inProgress: boolean) {
    this.file = file;
    this.name = name;
    this.progress = progress;
    this.inProgress = inProgress;
  }
}
