import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { fetch } from "@ngrx/router-store/data-persistence";
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  switchMap,
} from "rxjs/operators";
import { of } from "rxjs";

import { QueuePartialState } from "./queue.reducer";
import {
  QueueActionTypes,
  LoadQueueDetails,
  QueueDetailsLoaded,
  QueueDetailsLoadingError,
  LoadMyQueueList,
  LoadUsableQueueList,
  LoadOthersQueueList,
  MyQueueListLoaded,
  OthersQueueListLoaded,
  MyQueueListLoadingError,
  OthersQueueListLoadingError,
  UsableQueueListLoaded,
  UsableQueueListLoadingError,
  OnSearchRecordInQueue,
  SearchRecordInQueueApply,
  FailedRecord,
  FailedRecordSuccessful,
  FailedRecordError,
} from "./queue.actions";
import {
  OApiRespMultiQueueDtoModel,
  OApiRespMultiSimpleQueueDtoModel,
  OApiRespQueueDetailsDtoModel,
  QueueDetailsDtoModel,
  QueueService,
  RecordDtoModel,
  RecordService,
} from "@@intelease/api-models/adex-api-model-src";
import {
  AdexRouterService,
  Json2TypescriptHelper,
} from "@@intelease/web/intelease/utils";

@Injectable({ providedIn: "root" })
export class QueueEffects {
  loadMyQueueList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActionTypes.LoadMyQueueList),
      fetch({
        run: (action: LoadMyQueueList, state: QueuePartialState) => {
          const params: { category: "OWNED" | "SHARED" } = {
            category: "OWNED",
          };
          return this.queueService.getAll1(params).pipe(
            map((resp) => {
              const resData = Json2TypescriptHelper.convertToEntity(
                resp,
                OApiRespMultiQueueDtoModel
              );
              return new MyQueueListLoaded(resData.data?.items || []);
            })
          );
        },
        onError(a: LoadMyQueueList, e: any) {
          return new MyQueueListLoadingError(e);
        },
      })
    );
  });

  loadUsableQueueList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActionTypes.LoadUsableQueueList),
      fetch({
        run: (action: LoadUsableQueueList, state: QueuePartialState) => {
          return this.queueService.getUsable().pipe(
            map((resp) => {
              const resData = Json2TypescriptHelper.convertToEntity(
                resp,
                OApiRespMultiSimpleQueueDtoModel
              );
              return new UsableQueueListLoaded(resData.data?.items || []);
            })
          );
        },
        onError(a: LoadUsableQueueList, e: any) {
          return new UsableQueueListLoadingError(e);
        },
      })
    );
  });

  loadOthersQueueList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActionTypes.LoadOthersQueueList),
      fetch({
        run: (action: LoadOthersQueueList, state: QueuePartialState) => {
          const params: { category: "OWNED" | "SHARED" } = {
            category: "SHARED",
          };
          return this.queueService.getAll1(params).pipe(
            map((resp) => {
              const resData = Json2TypescriptHelper.convertToEntity(
                resp,
                OApiRespMultiQueueDtoModel
              );
              return new OthersQueueListLoaded(resData.data?.items || []);
            })
          );
        },
        onError(a: LoadOthersQueueList, e: any) {
          return new OthersQueueListLoadingError(e);
        },
      })
    );
  });

  loadQueueDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActionTypes.LoadQueueDetails),
      switchMap((action: LoadQueueDetails) => {
        return this.queueService.getOne1(action.payload).pipe(
          map((resp) => {
            const response = this.getQueueDetailsJson2TypeScript(resp);
            return new QueueDetailsLoaded(
              response.data as QueueDetailsDtoModel
            );
          }),
          catchError((e: any) => {
            if (e?.status == 403 || e?.status == 401) {
              this.adexRouter.navigateByUrl("/queues");
            }
            return of(new QueueDetailsLoadingError(e));
          })
        );
      })
    );
  });

  onSearchRecordInQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActionTypes.OnSearchRecordInQueue),
      debounceTime(300),
      switchMap((action: OnSearchRecordInQueue) => {
        return of(new SearchRecordInQueueApply(action.searchText));
      })
    );
  });

  failedRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActionTypes.FailedRecord),
      mergeMap((action: FailedRecord) => {
        return this.recordService.failRecord(action.params).pipe(
          map((res) => {
            return new FailedRecordSuccessful(res.data);
          }),
          catchError(() => of(new FailedRecordError()))
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private queueService: QueueService,
    private adexRouter: AdexRouterService,
    private recordService: RecordService
  ) {}

  getQueueDetailsJson2TypeScript(
    queueDetailsResp: OApiRespQueueDetailsDtoModel
  ): OApiRespQueueDetailsDtoModel {
    const queueDetailsResponse = Json2TypescriptHelper.convertToEntity(
      queueDetailsResp,
      OApiRespQueueDetailsDtoModel
    );

    if (queueDetailsResponse.data?.records) {
      const allRecords = queueDetailsResponse.data.records;
      for (const key in allRecords) {
        let records = allRecords[key];
        records = records.map((record) => {
          return Json2TypescriptHelper.convertToEntity(record, RecordDtoModel);
        });
        queueDetailsResponse.data.records[key] = records;
      }
    }
    return queueDetailsResponse;
  }
}
