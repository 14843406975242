import { NgModule } from "@angular/core";
import {
  FocusInDirective,
  ItlsFocusInDelayDirective,
} from "@@intelease/web/common/directives/focus-in/focus-in.directive";
import { DraggableDirective } from "@@intelease/web/common/directives/draggable/draggable.directive";
import { DataCyDirective } from "@@intelease/web/common/directives/data-cy/data-cy.directive";
import { InteleasePerfectScrollbarDirective } from "./intelease-perfect-scrollbar/intelease-perfect-scrollbar.directive";
import { EntityPermissionsDirective } from "@@intelease/web/common/directives/entity-permissions/entity-permissions.directive";
import { ClickStopPropagationDirective } from "@@intelease/web/common/directives/click-stop-propagation/click-stop-propagation.directive";
import { DigitsOnlyDirective } from "@common/directives/digits-only/digits-only.directive";
import { HoverClassDirective } from "./hover-class/hover-class.directive";

const DIRECTIVES = [
  FocusInDirective,
  DraggableDirective,
  DataCyDirective,
  InteleasePerfectScrollbarDirective,
  EntityPermissionsDirective,
  ClickStopPropagationDirective,
  DigitsOnlyDirective,
  HoverClassDirective,
  ItlsFocusInDelayDirective,
];

@NgModule({
  imports: [],
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES],
})
export class CommonDirectivesModule {}
