import { ColorPickerModule } from "@iplab/ngx-color-picker";
import { NgModule } from "@angular/core";
import { NgxColorChromePickerWrapperComponent } from "./ngx-color-chrome-picker-wrapper.component";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [ColorPickerModule, CommonModule],
  declarations: [NgxColorChromePickerWrapperComponent],
  exports: [NgxColorChromePickerWrapperComponent],
})
export class NgxColorChromePickerWrapperModule {}
