/* tslint:disable */
/* eslint-disable */

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MetricsModel")
export class MetricsModel {
  @JsonProperty("accuracyRate", Number, true)
  accuracyRate?: number = undefined;

  @JsonProperty("numCreditsRemaining", Number, true)
  numCreditsRemaining?: number = undefined;

  @JsonProperty("numDocumentSets", Number, true)
  numDocumentSets?: number = undefined;

  @JsonProperty("numDocuments", Number, true)
  numDocuments?: number = undefined;
}
