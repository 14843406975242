/* tslint:disable */
/* eslint-disable */

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ReportResultDtoModel")
export class ReportResultDtoModel {
  @JsonProperty("result", Any, true)
  result?: any = {};

  @JsonProperty("type", Any, true)
  type?: "AGGREGATED" | "SIMPLE" = undefined;
}
