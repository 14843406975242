import { Any, JsonObject, JsonProperty } from "json2typescript";

@JsonObject("SnippetModel2")
export class SnippetModel {
  static view = "fullVal";

  @JsonProperty("type", String)
  type: "TEXT" | "PNG" = "TEXT";

  @JsonProperty("value", String)
  value = "";

  @JsonProperty("longValue", [String], true)
  longValue?: string[] | undefined = [];

  @JsonProperty("longRichText", String, true)
  longRichText?: string | undefined = undefined;

  @JsonProperty("pngSnippetAddresses", Any, true)
  pngSnippetAddresses?: any | undefined = undefined;

  @JsonProperty("value", String)
  viewValue = "";

  defaultValueLen = 150;

  isExpanded = false;

  provisionBoxWidthThreshold = 4;

  private static transformViewValue(str: string, len: number = 10): string {
    if (!str) {
      return;
    }
    return str.slice(0, len) + (str.length > len ? "..." : "");
  }

  getCalculatedTextLen(provisionBoxWidth: number): number {
    return (
      this.defaultValueLen + provisionBoxWidth / this.provisionBoxWidthThreshold
    );
  }

  getFullLongValue(): string {
    return this.longValue.toString();
  }

  setAndGetViewValue(val: string): string {
    this.viewValue = val;
    return this.viewValue;
  }

  getViewValueByProvisionBoxWidthWithValueAndLongValue(
    provisionBoxWidth: number
  ) {
    const calculatedTextLen = this.getCalculatedTextLen(provisionBoxWidth);
    return SnippetModel.transformViewValue(
      this.value.length < calculatedTextLen
        ? this.setAndGetViewValue(this.getFullLongValue())
        : this.setAndGetViewValue(this.value),
      provisionBoxWidth > 480 ? calculatedTextLen : this.defaultValueLen
    );
  }

  getViewValueByProvisionBoxWidth(provisionBoxWidth: number) {
    const calculatedTextLen = this.getCalculatedTextLen(provisionBoxWidth);
    return SnippetModel.transformViewValue(
      this.setAndGetViewValue(this.value),
      provisionBoxWidth > 480 ? calculatedTextLen : this.defaultValueLen
    );
  }
}
