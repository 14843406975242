/* tslint:disable */
/* eslint-disable */
import { QueueAssigneeConfigDtoModel } from "./queue-assignee-config-dto-model";
import { QueueProcessingConfigDtoModel } from "./queue-processing-config-dto-model";
import { QueueRecordSummaryConfigDtoModel } from "./queue-record-summary-config-dto-model";
import { RecordDtoModel } from "./record-dto-model";
import { UserDtoModel } from "./user-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("QueueDetailsDtoModel")
export class QueueDetailsDtoModel {
  @JsonProperty("assigneeConfig", QueueAssigneeConfigDtoModel, true)
  assigneeConfig?: QueueAssigneeConfigDtoModel = undefined;

  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("creator", UserDtoModel, true)
  creator?: UserDtoModel = undefined;

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("permissionCategory", Any, true)
  permissionCategory?: "PRIVATE" | "PUBLIC" | "VIEWABLE" = undefined;

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];

  @JsonProperty("processingConfig", QueueProcessingConfigDtoModel, true)
  processingConfig?: QueueProcessingConfigDtoModel = undefined;

  @JsonProperty(
    "recordSummaryConfigDTO",
    QueueRecordSummaryConfigDtoModel,
    true
  )
  recordSummaryConfigDTO?: QueueRecordSummaryConfigDtoModel = undefined;

  @JsonProperty("records", Any, true)
  records?: {
    [key: string]: Array<RecordDtoModel>;
  } = {};

  @JsonProperty("status", Any, true)
  status?: "ACTIVE" | "PAUSE" | "DEACTIVE" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("updatedAt", DateTimeConvertService, true)
  updatedAt?: Date | undefined = undefined;
}
