/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocDataDtoModel")
export class DocDataDtoModel {
  @JsonProperty("fileName", String, true)
  fileName?: string = "";

  @JsonProperty("originalFileSize", Number, true)
  originalFileSize?: number = undefined;

  /**
   * a file format
   */

  @JsonProperty("originalFileType", Any, true)
  originalFileType?:
    | "DOCX"
    | "DOC"
    | "ODT"
    | "XLS"
    | "XLSX"
    | "ODS"
    | "PPT"
    | "PPTX"
    | "ODP"
    | "PDF"
    | "PDF_OCR"
    | "PDF_DIFF"
    | "EPUB"
    | "LOG"
    | "TXT"
    | "RTF"
    | "PDF_GCP_TXT_PAGE"
    | "RAW_GCP_TXT_PAGE"
    | "NLP_POS_GCP_TXT_PAGE"
    | "PDF_OCR_TXT"
    | "PDF_OCR_TXT_PAGE"
    | "PDF_OCR_BBX_TXT_PAGE"
    | "RAW_GCP_BBX_TXT_PAGE"
    | "PDF_OCR_UP_BBX_TXT_PAGE"
    | "HORIZ_NLP_POS_GCP_TXT_PAGE"
    | "HORIZ_PDF_OCR_TXT"
    | "HORIZ_PDF_OCR_TXT_PAGE"
    | "HORIZ_PDF_OCR_BBX_TXT_PAGE"
    | "HORIZ_PDF_GCP_TXT_PAGE"
    | "HORIZ_TO_GCP_TXT"
    | "HORIZ_TO_GCP_TXT_PAGE"
    | "GCP_TO_HORIZ_TXT"
    | "GCP_TO_HORIZ_TXT_PAGE"
    | "CSV"
    | "TSV"
    | "PNG"
    | "JPEG"
    | "JPEG_PAGE"
    | "JPG"
    | "JPG_PAGE"
    | "SVG"
    | "TIF"
    | "TIFF"
    | "BMP"
    | "OPENAI_INPUT_JSONL"
    | "PROVISION_MENTION_ML_SEARCH_ML_NPY"
    | "PROVISION_MENTION_ML_SEARCH_CANDIDATE"
    | "PNG_PAGE"
    | "LQ_PNG_PAGE"
    | "TXRCT_JSON"
    | "TBL_ML_JSON"
    | "HW_ML_JSON"
    | "GCP_DOC_AI_JSON"
    | "PNG_SNIPPET"
    | "ANNOTATED_PNG"
    | "RF_WEKA"
    | "ZIP"
    | "SPLIT_PDF" = undefined;

  @JsonProperty("originalFilename", String, true)
  originalFilename?: string = "";

  @JsonProperty("uiName", String, true)
  uiName?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
