import { Any, JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NestedGroupProvisionSubfieldModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("FullProvisionModel")
export class FullProvisionModel {
  static view = "full";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("type", String)
  type = "";

  @JsonProperty("status", String, true)
  status = ""; // Populated for abstracts (e.g. NOT_COMPLETED, COMPLETED)

  @JsonProperty("diffState", String, true)
  diffState = ""; // Populated for abstracts (e.g. HAS_NEW, ALL_OLD)

  @JsonProperty("creationDate", DateTimeConvertService, true)
  creationDate: Date = undefined;

  @JsonProperty("createdByEmail", String, true)
  createdByEmail = "";

  @JsonProperty("possibleValues", [String], true)
  possibleValues: string[] = undefined; // Populated for SINGLE_CAT and MULTIPLE_CAT provision types

  @JsonProperty("possibleUnits", [String], true)
  possibleUnits: string[] = undefined; // Populated for DOUBLE provision type

  @JsonProperty("possibleCurrencies", [String], true)
  possibleCurrencies: string[] = undefined; // Populated for MONEY provision type

  @JsonProperty("possibleStates", [String], true)
  possibleStates: string[] = undefined; // Populated for ADDRESS provision type

  @JsonProperty("possibleCountries", [String], true)
  possibleCountries: string[] = undefined; // Populated for ADDRESS provision type

  @JsonProperty("properNounType", String, true)
  properNounType: string = undefined; // Populated for PROPER_NOUN provision type

  @JsonProperty("fieldType", String, true)
  fieldType: "DEFAULT" | "MANUAL" = "DEFAULT";

  @JsonProperty("allowedTypes", [String], true)
  allowedTypes: string[] = [];

  provisionInfo: any = {};

  @JsonProperty(
    "nestedGroupSubfields",
    [NestedGroupProvisionSubfieldModel],
    true
  )
  nestedGroupSubfields: NestedGroupProvisionSubfieldModel[] = undefined;

  optionNum = 0;

  isTop = false;

  @JsonProperty("nestedProcessingMode", String, true)
  nestedProcessingMode?: "FLEXIBLE" | "STRICT" = undefined;

  @JsonProperty("singleton", Boolean, true)
  singleton?: boolean = undefined;

  @JsonProperty("modelKey", String, true)
  modelKey? = "";

  @JsonProperty("nestedGroupFormats", Any, true)
  nestedGroupFormats?: Array<"ADDRESS" | "OTHER"> = [];
}
