import { Component, Inject, OnInit } from "@angular/core";
import {
  ModalInputModel,
  ModalResponseModel,
} from "@@intelease/web/intelease/models";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "il-provision-form-export-modal-component",
  templateUrl: "./provision-form-export-modal.component.html",
  styleUrls: ["./provision-form-export-modal.component.scss"],
})
export class ProvisionFormExportModalComponent implements OnInit {
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;
  provisionFormUid: string;
  historyExport: boolean;
  exportSettings = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ModalInputModel,
    public dialogRef: MatDialogRef<ProvisionFormExportModalComponent>
  ) {}

  ngOnInit() {
    this.initModalData();
  }

  private initModalData() {
    if (this.dialogData) {
      const { uid, historyExport } = this.dialogData.payload;
      this.provisionFormUid = uid;
      this.historyExport = historyExport;
    }
  }

  onDismissModalClick() {
    this.dialogRef.close({ ...this.modalRes, type: "DISMISS" });
  }

  private closeModal() {
    this.modalRes.data = {};
    this.dialogRef.close({ ...this.modalRes, type: "CLOSE" });
  }

  onCancelExportProvisionFormClick() {
    this.onDismissModalClick();
  }

  onProvisionFormExport() {
    this.closeModal();
  }
}
