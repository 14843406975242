/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EditLbxRolesDtoModel")
export class EditLbxRolesDtoModel {
  @JsonProperty("lbxRoles", Any, true)
  lbxRoles?: Array<
    "ADMIN" | "FORMS_ADMIN" | "ANALYST" | "REVIEWER" | "DATA_SCIENTIST"
  > = [];
}
