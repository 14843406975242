import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import {
  ListResponseModel,
  PermissionCheckResponseModel,
  ServerResponseModel,
} from "@@intelease/web/intelease/models";
import { map, tap } from "rxjs/operators";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import { PermissionResponseModel } from "@@intelease/web/intelease/models/permission-response.model";
import { RestClient } from "@@intelease/web/utils";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PermissionApiService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly PERMISSIONS_BASE_URL = "/permissions";
  private static readonly CHECK_PERMISSIONS_URL_PART = "/checkPermissions";

  private readonly permissions: BehaviorSubject<string[]> = new BehaviorSubject(
    null
  );
  public readonly permissions$: Observable<string[]> =
    this.permissions.asObservable();

  constructor(private httpClient: HttpClient, private restClient: RestClient) {}

  getOperationalAuthoritiesForUser(): Observable<string[]> {
    return this.restClient
      .sendGetRequestNoView(
        PermissionApiService.API_VERSION_1,
        `${PermissionApiService.PERMISSIONS_BASE_URL}/authorities/currentUser`
      )
      .pipe(
        map((resp: any) => resp.data),
        tap((data) => this.permissions.next(data))
      );
  }

  requestAccess(
    message: string,
    entityType: string,
    entityUid: string
  ): Observable<any> {
    return this.restClient.sendPostRequestNoView(
      PermissionApiService.API_VERSION_1,
      `${PermissionApiService.PERMISSIONS_BASE_URL}/requestAccess`,
      {
        data: {
          message,
          entityType,
          entityUid,
        },
      }
    );
  }

  checkPermission(
    domains: { uid: string; type: string }[],
    entities: { uid: string; type: string }[],
    permissionTypes: string[]
  ): Observable<PermissionCheckResponseModel> {
    return this.restClient.sendPostRequest(
      PermissionApiService.API_VERSION_1,
      `${PermissionApiService.PERMISSIONS_BASE_URL}/check`,
      {
        data: {
          domains,
          entities,
          permissionTypes,
        },
      },
      PermissionCheckResponseModel,
      {},
      (resp) => resp.data
    );
  }

  /**
   * Send a POST request to query whether this is permission in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including final uid)
   * @param taskTypes the task types to check permissions for
   * @param permissionTypes the additional permissions to check
   * @return the permission reponse
   */
  sendRequest(
    apiVersion: string,
    baseUrl: string,
    taskTypes: string[],
    permissionTypes: string[]
  ): Observable<PermissionResponseModel> {
    const body = {
      data: {
        taskTypes: taskTypes,
        permissionTypes: permissionTypes,
      },
    };
    return this.helpSendRequest(body, apiVersion, baseUrl);
  }

  /**
   * Send a POST request to query whether this is permission in backend, for all the entities.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including final uid)
   * @param taskTypes the task types to check permissions for
   * @param permissionTypes the additional permissions to check
   * @return the permission reponse
   */
  sendAllRequest(
    apiVersion: string,
    baseUrl: string,
    taskTypes: string[],
    permissionTypes: string[]
  ): Observable<ListResponseModel<PermissionResponseModel>> {
    const body = {
      data: {
        taskTypes: taskTypes,
        permissionTypes: permissionTypes,
        selectAll: true,
      },
    };
    return this.helpSendBatchRequest(body, apiVersion, baseUrl);
  }

  /**
   * Send a POST request to query whether this is permission in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including final uid)
   * @param objectUids the uids of the objects whose permissions to check for the given scope
   * @param taskType
   * @param permissionTypes the additional permissions to check
   * @return the permission reponse
   */
  sendBatchRequest(
    apiVersion: string,
    baseUrl: string,
    objectUids: string[],
    taskType: string,
    permissionTypes: string[]
  ): Observable<ListResponseModel<PermissionResponseModel>> {
    const body = {
      data: {
        taskTypes: [taskType],
        permissionTypes: permissionTypes,
        objectUids: objectUids,
        selectAll: false,
      },
    };
    return this.helpSendBatchRequest(body, apiVersion, baseUrl);
  }

  /*
   * Below are private helper methods
   */

  private helpSendRequest(
    body: any,
    apiVersion: string,
    baseUrl: string
  ): Observable<PermissionResponseModel> {
    return this.helpSendRequestNoView(body, apiVersion, baseUrl).pipe(
      map((res) =>
        Json2TypescriptHelper.convertToEntity(res.data, PermissionResponseModel)
      )
    );
  }

  private helpSendBatchRequest(
    body: any,
    apiVersion: string,
    baseUrl: string
  ): Observable<ListResponseModel<PermissionResponseModel>> {
    return this.helpSendRequestNoView(body, apiVersion, baseUrl).pipe(
      map((res) =>
        Json2TypescriptHelper.convertToEntities(
          res.data,
          PermissionResponseModel
        )
      )
    );
  }

  private helpSendRequestNoView(
    body: any,
    apiVersion: string,
    baseUrl: string
  ): Observable<ServerResponseModel> {
    return this.httpClient.post<ServerResponseModel>(
      apiVersion + PermissionApiService.CHECK_PERMISSIONS_URL_PART + baseUrl,
      body
    );
  }
}
