import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ProvisionTypeModel")
export class ProvisionTypeModel {
  @JsonProperty("name", String)
  name = "";

  @JsonProperty("uiName", String)
  uiName = "";

  icon: string;

  formName: string;

  color: string;

  dataModel: any;

  constructor(
    name: string,
    uiName: string,
    icon: string,
    formName: string,
    color: string,
    dataModel: any
  ) {
    this.name = name;
    this.uiName = uiName;
    this.icon = icon;
    this.formName = formName;
    this.color = color;
    this.dataModel = dataModel;
  }
}
