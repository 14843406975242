import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonPipesModule } from "@@intelease/web/common/pipes";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";
import { CommonModalService } from "@@intelease/web/common/services";
import {
  DataTableComponent,
  DataTableService,
  NavigationComponent,
  SuggestionItemComponent,
  SuggestionService,
} from "@@intelease/web/common/components";
import {
  DeleteConfirmModalComponent,
  PermissionConfirmModalComponent,
  RenameModalComponent,
} from "@@intelease/web/common/modals";
import { NgxTranslateService } from "@@intelease/web/common/services/ngx-translate.service";
import { NavigateService } from "@@intelease/web/common/services/navigate.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { ChooseProvisionFormModalComponent } from "@@intelease/web/common/modals/choose-provision-form/choose-provision-form-modal.component";
import { GenericOkCancelModalComponent } from "@@intelease/web/common/modals/generic-ok-cancel/generic-ok-cancel-modal.component";
import { QuicklinkModule } from "ngx-quicklink";
import { NgScrollbarModule } from "ngx-scrollbar";
import { NgxPermissionsModule } from "ngx-permissions";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";

const COMPONENTS = [
  DataTableComponent,
  SuggestionItemComponent,
  NavigationComponent,
];

const MODALS = [
  ChooseProvisionFormModalComponent,
  GenericOkCancelModalComponent,
  PermissionConfirmModalComponent,
  RenameModalComponent,
  DeleteConfirmModalComponent,
];

const EXPORTS = [
  QuicklinkModule,
  NavigationComponent,
  DataTableComponent,
  NgSelectModule,
  FormsModule,
  CommonModule,
  CommonDirectivesModule,
  CommonPipesModule,
  ...MODALS,
  NgxPermissionsModule,
];

const PROVIDERS = [
  DataTableService,
  NgxTranslateService,
  SuggestionService,
  CommonModalService,
  NavigateService,
];

const MATERIAL_MODULES = [MatButtonModule, MatDialogModule];

const NGX_BOOTSTRAP = [
  TooltipModule.forRoot(),
  PopoverModule.forRoot(),
  ModalModule.forRoot(),
];

@NgModule({
  declarations: [...COMPONENTS, ...MODALS],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CommonDirectivesModule,
    CommonPipesModule,
    NgSelectModule,
    QuicklinkModule,
    NgScrollbarModule,
    ...NGX_BOOTSTRAP,
    ...MATERIAL_MODULES,
    NgxPermissionsModule,
  ],
  providers: [...PROVIDERS],
  exports: [...EXPORTS, SuggestionItemComponent],
})
export class InteleaseCommonModule {}
