/* tslint:disable */
/* eslint-disable */
import { DocSetNodeDtoModel } from "./doc-set-node-dto-model";
import { NodeNavigationResultModel } from "./node-navigation-result-model";
import { PaginationDataModel } from "./pagination-data-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiListWithNavigationAndPaginationResultDocSetNodeDtoModel")
export class OApiListWithNavigationAndPaginationResultDocSetNodeDtoModel {
  @JsonProperty("items", [DocSetNodeDtoModel], true)
  items?: Array<DocSetNodeDtoModel> = [];

  @JsonProperty("nodeNavigation", NodeNavigationResultModel, true)
  nodeNavigation?: NodeNavigationResultModel = undefined;

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
