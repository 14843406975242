/* tslint:disable */
/* eslint-disable */
import { NamedSearchFieldModel } from "./named-search-field-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CalendarDisplayableFieldsDtoModel")
export class CalendarDisplayableFieldsDtoModel {
  @JsonProperty("generalFields", [NamedSearchFieldModel], true)
  generalFields?: Array<NamedSearchFieldModel> = [];

  @JsonProperty("provisionFields", [NamedSearchFieldModel], true)
  provisionFields?: Array<NamedSearchFieldModel> = [];
}
