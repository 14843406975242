/* tslint:disable */
/* eslint-disable */
import { QuickSearchResultHighlightModel } from "./quick-search-result-highlight-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("QuickFindResultModel")
export class QuickFindResultModel {
  @JsonProperty("fileNames", [String], true)
  fileNames?: Array<string> = [];

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("portfolio", String, true)
  portfolio?: string = "";

  @JsonProperty("property", String, true)
  property?: string = "";

  @JsonProperty("searchCategories", [QuickSearchResultHighlightModel], true)
  searchCategories?: Array<QuickSearchResultHighlightModel> = [];

  @JsonProperty("suite", String, true)
  suite?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("userEmail", String, true)
  userEmail?: string = "";

  @JsonProperty("userFullName", String, true)
  userFullName?: string = "";
}
