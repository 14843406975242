/* tslint:disable */
/* eslint-disable */
import { FilterInterfaceModel } from "./filter-interface-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("LogicalFilterInterfaceModel")
export class LogicalFilterInterfaceModel {
  @JsonProperty("logical", Boolean, true)
  logical?: boolean = undefined;

  @JsonProperty("operator", Any, true)
  operator?: "AND" | "OR" = undefined;

  @JsonProperty("value", [FilterInterfaceModel], true)
  value?: Array<FilterInterfaceModel> = [];
}
