/* tslint:disable */
/* eslint-disable */
import { ProvisionGroupViewModel } from "./provision-group-view-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullProvisionFormViewModel")
export class FullProvisionFormViewModel {
  @JsonProperty("anchorProvisionUid", String, true)
  anchorProvisionUid?: string = "";

  @JsonProperty("createdByEmail", String, true)
  createdByEmail?: string = "";

  @JsonProperty("creationDate", DateTimeConvertService, true)
  creationDate?: Date | undefined = undefined;

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("docSetCategory", String, true)
  docSetCategory?: string = "";

  @JsonProperty("documentTypes", [String], true)
  documentTypes?: Array<string> = [];

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("numProvisions", Number, true)
  numProvisions?: number = undefined;

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];

  @JsonProperty("provisionGroups", [ProvisionGroupViewModel], true)
  provisionGroups?: Array<ProvisionGroupViewModel> = [];

  @JsonProperty("source", String, true)
  source?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
