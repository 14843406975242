import {
  NgZone,
  Directive,
  ElementRef,
  AfterContentInit,
  Renderer2,
  Input,
} from "@angular/core";

@Directive({
  selector: "[itlsFocusInDelay]",
})
export class ItlsFocusInDelayDirective {
  private _itlsFocusInDelay = 0;
  @Input() set itlsFocusInDelay(itlsFocusInDelay: number) {
    this._itlsFocusInDelay = itlsFocusInDelay || 0;
  }

  get itlsFocusInDelay() {
    return this._itlsFocusInDelay;
  }
}

@Directive({
  selector: "[itlsFocusIn]",
})
export class FocusInDirective
  extends ItlsFocusInDelayDirective
  implements AfterContentInit
{
  private _itlsFocusIn = true;
  @Input() set itlsFocusIn(itlsFocusIn: any) {
    this._itlsFocusIn = itlsFocusIn || itlsFocusIn === "";
    //* If you use itlsFocusIn without Input it send empty string.
    //* It take this as 'true' and work fine.
  }

  get itlsFocusIn() {
    return this._itlsFocusIn;
  }

  constructor(
    private el: ElementRef,
    private zone: NgZone,
    private renderer: Renderer2
  ) {
    super();
  }

  ngAfterContentInit() {
    if (this.itlsFocusIn) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          const nativeElement = this.el.nativeElement;
          const selectedRootElement =
            this.renderer.selectRootElement(nativeElement);
          selectedRootElement.focus();
        }, this.itlsFocusInDelay)
      );
    }
  }
}
