/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqCalculateTermRequestModel } from "../models/o-api-req-calculate-term-request-model";
import { OApiReqTermCalculatorInputsDtoModel } from "../models/o-api-req-term-calculator-inputs-dto-model";
import { OApiReqTermCalculatorInputsV2DtoModel } from "../models/o-api-req-term-calculator-inputs-v-2-dto-model";
import { OApiRespCalculateTermModel } from "../models/o-api-resp-calculate-term-model";
import { OApiRespTermCalculatorInputsResponseModel } from "../models/o-api-resp-term-calculator-inputs-response-model";

@Injectable({
  providedIn: "root",
})
export class TermService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation calculateTerm
   */
  static readonly CalculateTermPath = "/api/v1/calculator/calc-term";

  /**
   * Calculate term.
   *
   * If successful, return computed term with status 200.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateTerm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateTerm$Response(params: {
    body: OApiReqCalculateTermRequestModel;
  }): Observable<StrictHttpResponse<OApiRespCalculateTermModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TermService.CalculateTermPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespCalculateTermModel>;
        })
      );
  }

  /**
   * Calculate term.
   *
   * If successful, return computed term with status 200.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calculateTerm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateTerm(params: {
    body: OApiReqCalculateTermRequestModel;
  }): Observable<OApiRespCalculateTermModel> {
    return this.calculateTerm$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespCalculateTermModel>) =>
          r.body as OApiRespCalculateTermModel
      )
    );
  }

  /**
   * Path part for operation retrieveTermInputs
   */
  static readonly RetrieveTermInputsPath = "/api/v1/calculator/term";

  /**
   * Retrieve term inputs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveTermInputs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retrieveTermInputs$Response(params: {
    body: OApiReqTermCalculatorInputsDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespTermCalculatorInputsResponseModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      TermService.RetrieveTermInputsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespTermCalculatorInputsResponseModel>;
        })
      );
  }

  /**
   * Retrieve term inputs.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retrieveTermInputs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retrieveTermInputs(params: {
    body: OApiReqTermCalculatorInputsDtoModel;
  }): Observable<OApiRespTermCalculatorInputsResponseModel> {
    return this.retrieveTermInputs$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespTermCalculatorInputsResponseModel>) =>
          r.body as OApiRespTermCalculatorInputsResponseModel
      )
    );
  }

  /**
   * Path part for operation retrieveTermInputsV2
   */
  static readonly RetrieveTermInputsV2Path = "/api/v2/calculator/term";

  /**
   * Retrieve term inputs.
   *
   * The inputs to be able to compute the term, with response code 200.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveTermInputsV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retrieveTermInputsV2$Response(params: {
    body: OApiReqTermCalculatorInputsV2DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespTermCalculatorInputsResponseModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      TermService.RetrieveTermInputsV2Path,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespTermCalculatorInputsResponseModel>;
        })
      );
  }

  /**
   * Retrieve term inputs.
   *
   * The inputs to be able to compute the term, with response code 200.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retrieveTermInputsV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retrieveTermInputsV2(params: {
    body: OApiReqTermCalculatorInputsV2DtoModel;
  }): Observable<OApiRespTermCalculatorInputsResponseModel> {
    return this.retrieveTermInputsV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespTermCalculatorInputsResponseModel>) =>
          r.body as OApiRespTermCalculatorInputsResponseModel
      )
    );
  }
}
