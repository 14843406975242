import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("PermissionResponseModel")
export class PermissionResponseModel {
  @JsonProperty("objectUid", String)
  objectUid = "";

  @JsonProperty("allowed", Boolean)
  allowed = false;
}
