import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { TaskListComponent } from "./task-list.component";
import { AppAuthorities } from "@@intelease/web/intelease/constants";
import { PermissionsGuard } from "@@intelease/web/intelease/guards";

const routes: Routes = [
  {
    path: "",
    component: TaskListComponent,
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: [AppAuthorities.READ_TASK],
        redirectTo: "/miscellaneous/not-found",
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TaskListRoutingModule {}
