import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItlsTreeComponent } from "./itls-tree.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatTreeModule } from "@angular/material/tree";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";

const MATERIAL_MODULES = [
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatTreeModule,
  MatMenuModule,
];

@NgModule({
  declarations: [ItlsTreeComponent],
  imports: [CommonModule, ...MATERIAL_MODULES],
  exports: [ItlsTreeComponent],
})
export class ItlsTreeModule {}
