import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NewShareEntityComponent } from "./new-share-entity.component";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";
import { ClipboardModule, ClipboardService } from "ngx-clipboard";
import { NgStringPipesModule } from "ngx-pipes";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { NgSelectModule } from "@ng-select/ng-select";
import { CommonPipesModule } from "@@intelease/web/common/pipes";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";

const MATERIAL_MODULES = [
  MatCardModule,
  MatButtonModule,
  MatListModule,
  MatIconModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatMenuModule,
  MatDialogModule,
  MatTooltipModule,
];

@NgModule({
  declarations: [NewShareEntityComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ...MATERIAL_MODULES,
    ReactiveFormsModule,
    CommonDirectivesModule,
    ClipboardModule,
    NgStringPipesModule,
    FormsModule,
    NgSelectModule,
    CommonPipesModule,
    ItlsCustomIconModule,
  ],
  exports: [NewShareEntityComponent],
  providers: [ClipboardService],
})
export class NewShareEntityModule {}
