import {
  NotificationsAction,
  NotificationsActionTypes,
} from "./notifications.actions";
import { cloneDeep } from "lodash";

export const NOTIFICATIONS_FEATURE_KEY = "notifications";

export interface NotificationsState {
  list: []; // list of Notifications; analogous to a sql normalized table
  selectedId?: string | number; // which Notifications record has been selected
  loaded: boolean; // has the Notifications list been loaded
  error?: any; // last none error (if any)

  isCountOfUnseenNotificationsLoading?: boolean;
  countOfUnseenNotificationsLoaded?: boolean;
  countOfUnseenNotificationsLoadError?: any;
  countOfUnseenNotifications?: number;

  isUnseenNotificationsLoading?: boolean;
  unseenNotificationsLoaded?: boolean;
  unseenNotificationsLoadError?: any;

  notificationsCategory?: any;
  isNotificationsCategoryLoading?: boolean;
  notificationsCategoryLoaded?: boolean;
  notificationsCategoryLoadError?: any;

  categoryNotifications?: any;
  isSelectedCategoryNotificationsLoading?: boolean;
  selectedCategoryNotificationsLoaded?: boolean;
  selectedCategoryNotificationsLoadError?: any;

  isReadSelectedNotificationLoading?: boolean;
  readSelectedNotificationSucceeded?: boolean;
  readSelectedNotificationError?: any;
}

export interface NotificationsPartialState {
  readonly [NOTIFICATIONS_FEATURE_KEY]: NotificationsState;
}

export const initialState: NotificationsState = {
  list: [],
  loaded: false,
};

export function reducer(
  state: NotificationsState = initialState,
  action: NotificationsAction
): NotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.NotificationsLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true,
      };
      break;
    }
    case NotificationsActionTypes.LoadCountOfUnseenNotifications: {
      state = {
        ...state,
        countOfUnseenNotifications: undefined,
        isCountOfUnseenNotificationsLoading: true,
        countOfUnseenNotificationsLoaded: false,
        countOfUnseenNotificationsLoadError: undefined,
      };
      break;
    }
    case NotificationsActionTypes.CountOfUnseenNotificationsLoaded: {
      state = {
        ...state,
        countOfUnseenNotifications: action.payload,
        isCountOfUnseenNotificationsLoading: false,
        countOfUnseenNotificationsLoaded: true,
        countOfUnseenNotificationsLoadError: undefined,
      };
      break;
    }
    case NotificationsActionTypes.IncrementCountOfUnseenNotifications: {
      const { numberOfNewNotifications } = action.payload;
      state = {
        ...state,
        countOfUnseenNotifications:
          state.countOfUnseenNotifications + numberOfNewNotifications,
      };
      break;
    }
    case NotificationsActionTypes.DecrementCountOfUnseenNotifications: {
      const { numberOfNewNotifications } = action.payload;
      const unSeenNotifications =
        state.countOfUnseenNotifications - numberOfNewNotifications;
      state = {
        ...state,
        countOfUnseenNotifications:
          unSeenNotifications > 0 ? unSeenNotifications : 0,
      };
      break;
    }
    case NotificationsActionTypes.CountOfUnseenNotificationsLoadError: {
      state = {
        ...state,
        countOfUnseenNotifications: undefined,
        isCountOfUnseenNotificationsLoading: false,
        countOfUnseenNotificationsLoaded: false,
        countOfUnseenNotificationsLoadError: action.payload,
      };
      break;
    }
    case NotificationsActionTypes.LoadUnseenNotifications: {
      state = {
        ...state,
        isUnseenNotificationsLoading: true,
        unseenNotificationsLoaded: false,
        unseenNotificationsLoadError: undefined,
      };
      break;
    }
    case NotificationsActionTypes.UnseenNotificationsLoaded: {
      state = {
        ...state,
        isUnseenNotificationsLoading: false,
        unseenNotificationsLoaded: true,
        unseenNotificationsLoadError: undefined,
      };
      break;
    }
    case NotificationsActionTypes.UnseenNotificationsLoadError: {
      state = {
        ...state,
        isUnseenNotificationsLoading: false,
        unseenNotificationsLoaded: false,
        unseenNotificationsLoadError: action.payload,
      };
      break;
    }

    case NotificationsActionTypes.LoadNotificationsCategory: {
      state = {
        ...state,
        notificationsCategory: undefined,
        isNotificationsCategoryLoading: true,
        notificationsCategoryLoaded: false,
        notificationsCategoryLoadError: undefined,
      };
      break;
    }
    case NotificationsActionTypes.NotificationsCategoryLoaded: {
      state = {
        ...state,
        notificationsCategory: action.payload,
        isNotificationsCategoryLoading: false,
        notificationsCategoryLoaded: true,
        notificationsCategoryLoadError: undefined,
      };
      break;
    }
    case NotificationsActionTypes.NotificationsCategoryLoadError: {
      state = {
        ...state,
        notificationsCategory: undefined,
        isNotificationsCategoryLoading: false,
        notificationsCategoryLoaded: false,
        notificationsCategoryLoadError: action.payload,
      };
      break;
    }
    case NotificationsActionTypes.LoadSelectedCategoryNotifications: {
      const { category } = action.payload;
      state = {
        ...state,
        categoryNotifications: {
          ...state.categoryNotifications,
          [category]: [],
        },
        isSelectedCategoryNotificationsLoading: true,
        selectedCategoryNotificationsLoaded: false,
        selectedCategoryNotificationsLoadError: undefined,
      };
      break;
    }
    case NotificationsActionTypes.SelectedCategoryNotificationsLoaded: {
      const { category, items } = action.payload;
      state = {
        ...state,
        categoryNotifications: {
          ...state.categoryNotifications,
          [category]: items,
        },
        isSelectedCategoryNotificationsLoading: false,
        selectedCategoryNotificationsLoaded: true,
        selectedCategoryNotificationsLoadError: undefined,
      };
      break;
    }
    case NotificationsActionTypes.SelectedCategoryNotificationsLoadError: {
      state = {
        ...state,
        isSelectedCategoryNotificationsLoading: false,
        selectedCategoryNotificationsLoaded: false,
        selectedCategoryNotificationsLoadError: action.payload,
      };
      break;
    }
    case NotificationsActionTypes.SeenSelectedCategoryNotifications: {
      const { category } = action.payload;
      state = {
        ...state,
        notificationsCategory: {
          ...state.notificationsCategory,
          [category]: {
            ...state.notificationsCategory[category],
            unseenCount: 0,
          },
        },
      };
      break;
    }
    case NotificationsActionTypes.SeenAllCategoryNotifications: {
      const notificationCategories = cloneDeep(state.notificationsCategory);
      // tslint:disable-next-line:forin
      for (const key in notificationCategories) {
        const notificationCategory = notificationCategories[key];
        if (notificationCategory) {
          notificationCategories[key].unseenCount = 0;
        }
      }
      state = {
        ...state,
        notificationsCategory: notificationCategories,
      };
      break;
    }
    case NotificationsActionTypes.IncrementCountOfUnseenSelectedCategoryNotifications: {
      const { category, numberOfNotifications } = action.payload;
      if (
        state.notificationsCategory &&
        state.notificationsCategory[category]
      ) {
        state = {
          ...state,
          notificationsCategory: {
            ...state.notificationsCategory,
            [category]: {
              ...state.notificationsCategory[category],
              unseenCount:
                state.notificationsCategory[category].unseenCount +
                numberOfNotifications,
            },
          },
        };
      }
      break;
    }
    case NotificationsActionTypes.AddNotificationToSelectedCategoryNotifications: {
      const { category, data } = action.payload;
      if (
        state.categoryNotifications &&
        state.categoryNotifications[category]
      ) {
        state = {
          ...state,
          categoryNotifications: {
            ...state.categoryNotifications,
            [category]: [data, ...state.categoryNotifications[category]],
          },
        };
      }
      break;
    }

    case NotificationsActionTypes.ReadSelectedNotification: {
      state = {
        ...state,
        isReadSelectedNotificationLoading: true,
        readSelectedNotificationSucceeded: false,
        readSelectedNotificationError: undefined,
      };
      break;
    }
    case NotificationsActionTypes.ReadSelectedNotificationSucceeded: {
      const { notificationUid, notificationCategory, isOpen } = action.payload;
      const notifications = cloneDeep(
        state.categoryNotifications[notificationCategory]
      );
      notifications.forEach((item) => {
        if (item.uid === notificationUid) {
          item.read = true;
          item.isOpen = isOpen;
        } else {
          item.isOpen = false;
        }
      });
      state = {
        ...state,
        categoryNotifications: {
          ...state.categoryNotifications,
          [notificationCategory]: notifications,
        },
        isReadSelectedNotificationLoading: false,
        readSelectedNotificationSucceeded: true,
        readSelectedNotificationError: undefined,
      };
      break;
    }
    case NotificationsActionTypes.ReadSelectedNotificationFailed: {
      state = {
        ...state,
        isReadSelectedNotificationLoading: false,
        readSelectedNotificationSucceeded: false,
        readSelectedNotificationError: action.payload,
      };
      break;
    }
  }
  return state;
}
