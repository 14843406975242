import scrollIntoView from "scroll-into-view-if-needed";
import { ScrollMode } from "scroll-into-view-if-needed/typings/types";

export class DomHelper {
  public static scrollToElementSelector(
    selector: string,
    scrollMode: ScrollMode,
    parentQuerySelect?: string
  ): void {
    const element = document.querySelector(selector);
    if (element) {
      setTimeout(() => {
        if (parentQuerySelect) {
          scrollIntoView(element, {
            behavior: "smooth",
            scrollMode: scrollMode,
            boundary: document.querySelector(parentQuerySelect),
          });
        } else {
          scrollIntoView(element, {
            behavior: "smooth",
            scrollMode: scrollMode,
          });
        }
      }, 10);
    }
  }

  public static capitalizeOneWord(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  public static calculateLines(
    value: string,
    averageWidthCharacter: number,
    fieldWidth: number
  ): number {
    const paragraphs = value.split("\n").map((paragraph) => {
      return paragraph.split(" ");
    });
    let numberOfLines = 0;
    paragraphs.forEach((paragraph) => {
      let _numberOfLines = 0;
      let _lineWidth = 0;
      paragraph.forEach((word, index) => {
        const numberOfCharacters = word.length;
        _lineWidth += (numberOfCharacters + 1) * averageWidthCharacter;
        if (_lineWidth > fieldWidth) {
          _lineWidth = (numberOfCharacters + 1) * averageWidthCharacter;
          _numberOfLines++;
        }
        if (index === paragraph.length - 1) {
          _lineWidth = 0;
          _numberOfLines++;
        }
      });
      numberOfLines += _numberOfLines;
    });
    return numberOfLines;
  }
}
