import { REGULAR_EXPRESSION_CONST } from "@@intelease/web/utils";
import { Utilities } from "@@intelease/web/common/utils";

export class ValidatorsHelper {
  public static isValidEmailAddress(emailAddress: string): boolean {
    return REGULAR_EXPRESSION_CONST.EMAIL.test(emailAddress);
  }

  public static isValidDouble(mentionValue: any) {
    return (
      mentionValue &&
      Utilities.existsAndNotEmptyString(mentionValue.value) &&
      Utilities.existsAndNotEmptyString(mentionValue.unit)
    );
  }

  public static isValidMoney(mentionValue: any) {
    return (
      mentionValue &&
      Utilities.existsAndNotEmptyString(mentionValue.value) &&
      Utilities.existsAndNotEmptyString(mentionValue.currency)
    );
  }
}
