<ng-container *ngIf="!_item.hidden">
  <ng-container *ngxPermissionsOnly="expectedAuthorities">
    <!--  [routerLinkActive]="[_item.active?(_item.activeClass||'active'):'']"-->
    <!--  [routerLinkActiveOptions]="{exact: _item.exactMatch || false}"-->
    <!-- _item.url -->
    <a
      class="nav-link"
      [ngClass]="_item.classes"
      *ngIf="_item.url && !_item.externalUrl && !_item.function"
      [routerLink]="_item.url"
      [class]="_item.active ? _item.activeClass || 'active' : ''"
      [target]="_item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- _item.externalUrl -->
    <a
      class="nav-link"
      [ngClass]="_item.classes"
      *ngIf="_item.url && _item.externalUrl && !_item.function"
      [href]="_item.url"
      [target]="_item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- _item.function -->
    <span
      class="nav-link"
      [ngClass]="_item.classes"
      *ngIf="!_item.url && _item.function"
      (click)="_item.function()"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <a
      class="nav-link"
      [ngClass]="_item.classes"
      *ngIf="_item.url && !_item.externalUrl && _item.function"
      (click)="_item.function()"
      [routerLink]="_item.url"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{ exact: _item.exactMatch || false }"
      [target]="_item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- _item.externalUrl && _item.function -->
    <a
      class="nav-link"
      [ngClass]="_item.classes"
      *ngIf="_item.url && _item.externalUrl && _item.function"
      (click)="_item.function()"
      [href]="_item.url"
      [target]="_item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
      <!--    <mat-icon class="nav-link-icon" *ngIf="_item.icon"><img style="max-width: 20px;" [src]="_item.icon"/></mat-icon>-->
      <!--    <mat-icon [svgIcon]="_item.icon" class="nav-link-icon" style="max-width: 20px;" *ngIf="_item.icon"></mat-icon>-->
      <span class="nav-link-title">{{ _item.title }}</span>
      <span
        class="nav-link-badge"
        *ngIf="_item.badge"
        [ngStyle]="{
          'background-color': _item.badge.bg,
          color: _item.badge.fg
        }"
      >
        {{ _item.badge.title }}
      </span>
      <ng-container *ngxPermissionsOnly="expectedIconAuthorities">
        <mat-icon
          class="nav-link-icon"
          *ngIf="_item.icon && !_item.iconFunction"
          >{{ _item.icon }}</mat-icon
        >
        <mat-icon
          class="nav-link-icon"
          *ngIf="_item.icon && _item.iconFunction"
          [matTooltip]="_item.iconTooltip"
          (click)="_item.iconFunction($event)"
          >{{ _item.icon }}</mat-icon
        >
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>
