import {
  ListResponseModel,
  PaginationModel,
} from "@@intelease/web/intelease/models";
import { SortDirectionEnum } from "@@intelease/web/common/enums/sort-direction.enum";
import { FilterRelationEnum } from "@@intelease/web/common/enums/filter-relation.enum";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { Observable } from "rxjs";
import { SearchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SearchProvisionFormService {
  private static readonly API_VERSION_2 = "/v2";
  private static readonly PROVISION_FORMS_URL = "/provisionForms";

  constructor(private searchApiService: SearchApiService) {}

  getFormsForDocType<T extends object>(
    documentType: string,
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    const filters = [
      {
        field:
          "documentType::Document Type::" + PROVISIONS_DATA_CONST.STRING.name,
        relation: FilterRelationEnum.CONTAIN.toString(),
        value: documentType,
      },
    ];
    return this.searchApiService.sendRequest(
      SearchProvisionFormService.API_VERSION_2,
      SearchProvisionFormService.PROVISION_FORMS_URL,
      view,
      classRef,
      filters,
      "name::Name::" +
        PROVISIONS_DATA_CONST.STRING.name +
        "::" +
        SortDirectionEnum.ASC,
      new PaginationModel()
    );
  }

  searchProvisionForms<T extends object>(
    pagination: PaginationModel,
    sort: string,
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    return this.searchApiService.sendRequest(
      SearchProvisionFormService.API_VERSION_2,
      SearchProvisionFormService.PROVISION_FORMS_URL,
      view,
      classRef,
      [],
      sort,
      new PaginationModel(pagination.page, pagination.size)
    );
  }

  searchByFormName<T extends object>(
    searchStr: string,
    view: string,
    classRef: new () => T
  ): Observable<T[]> {
    const filters = [
      {
        field: "name::Name::" + PROVISIONS_DATA_CONST.STRING.name,
        relation: FilterRelationEnum.CONTAIN,
        value: searchStr,
      },
    ];
    return this.searchApiService
      .sendRequest(
        SearchProvisionFormService.API_VERSION_2,
        SearchProvisionFormService.PROVISION_FORMS_URL,
        view,
        classRef,
        filters,
        "lastModifiedDate::Last Modified Date::" +
          PROVISIONS_DATA_CONST.DATE.name +
          "::" +
          SortDirectionEnum.DESC,
        new PaginationModel()
      )
      .pipe(map((res) => res.items));
  }
}
