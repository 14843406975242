import { JsonObject, JsonProperty } from "json2typescript";
import { EntityScopeEnum } from "@@intelease/app-models/common";
import { ProvisionInfoModel } from "../provision-info/provision-info.model";

@JsonObject("MultiPdfProvModel")
export class MultiPdfProvModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("status", String, true)
  status = "";

  @JsonProperty("reviewStatus", String, true)
  reviewStatus = "";

  @JsonProperty("abstractUid", String)
  abstractUid = "";

  @JsonProperty("provisionUid", String)
  provisionUid = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("provisionType", String)
  provisionType = "";

  @JsonProperty("attached", Boolean)
  attached: boolean;

  @JsonProperty("entityScope", String)
  entityScope: EntityScopeEnum;

  @JsonProperty("provisionInfo", ProvisionInfoModel)
  provisionInfo: ProvisionInfoModel = new ProvisionInfoModel();

  isLoading: boolean;
}
