import { FetchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FetchTaskService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly USERS_URL = "/users";
  private static readonly TASKS_URL = "/tasks";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(private fetchApiService: FetchApiService) {}

  getTaskDetail<T extends object>(
    taskUid: string,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    return this.fetchApiService.sendRequest(
      FetchTaskService.API_VERSION_1,
      `${FetchTaskService.FINAL_DOC_SETS_URL}${FetchTaskService.TASKS_URL}`,
      taskUid,
      view,
      classRef
    );
  }
}
