/* tslint:disable */
/* eslint-disable */
import { LightNodeDtoModel } from "./light-node-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("GetOffsetInSearchResultRequestModel")
export class GetOffsetInSearchResultRequestModel {
  @JsonProperty("parentDirectoryUid", String, true)
  parentDirectoryUid?: string = "";

  @JsonProperty("targetNode", LightNodeDtoModel, true)
  targetNode?: LightNodeDtoModel = undefined;
}
