import { JsonConverter, JsonCustomConvert } from "json2typescript";
import { DateTimeConvertService } from "./date-time-convert.service";

@JsonConverter
export class NotificationValueConvertService implements JsonCustomConvert<any> {
  serialize(data): any {}

  deserialize(data: any) {
    if (data.length) {
      data.forEach((item) => {
        item.createdAt = item.createdAt
          ? DateTimeConvertService.deserializeDateTime(item.createdAt)
          : item.createdAt;
        if (item.targetDate) {
          item.targetDate = item.targetDate
            ? DateTimeConvertService.deserializeDateTime(item.targetDate)
            : item.targetDate;
        }
        return item;
      });
    }
    return data;
  }
}
