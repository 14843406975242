/* tslint:disable */
/* eslint-disable */
import { EscalationRateModel } from "./escalation-rate-model";
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";

/**
 * a row in the calculated rent schedule table
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RentItemModel")
export class RentItemModel {
  @JsonProperty("endDate", SimpleOpenApiDateModel, true)
  endDate?: SimpleOpenApiDateModel = undefined;

  @JsonProperty("escalationRate", EscalationRateModel, true)
  escalationRate?: EscalationRateModel = undefined;

  @JsonProperty("startDate", SimpleOpenApiDateModel, true)
  startDate?: SimpleOpenApiDateModel = undefined;

  /**
   * the numerical value itself
   */

  @JsonProperty("values", [Number], true)
  values?: Array<number> = [];
}
