import { Component, Inject, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { isEmpty } from "lodash";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { NgxTranslateService } from "@@intelease/web/common/services/ngx-translate.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "itls-delete-confirm-modal",
  templateUrl: "./delete-confirm-modal.component.html",
  styleUrls: ["./delete-confirm.modal-component.scss"],
})
export class DeleteConfirmModalComponent implements OnInit {
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.REMOVE;
  payload: any;
  customMessage = false;
  title = "Delete";
  message = "Are you sure you want to delete?";
  params: Object = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<DeleteConfirmModalComponent>,
    private ngxTranslateService: NgxTranslateService
  ) {}

  ngOnInit() {
    if (
      !isEmpty(this.dialogData.payload) &&
      this.dialogData.payload.customMessage
    ) {
      const { message, params, title } = this.dialogData.payload;
      this.ngxTranslateService
        .getTranslateByKey(message, params)
        .subscribe((res: string) => {
          this.message = res;
          this.title = title;
        });
    }
  }

  onDelete() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.CLOSE,
    });
  }

  onDismissModal() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}
