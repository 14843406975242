import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("LightUserModel")
export class LightUserModel {
  static view = "light";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";
}
