/* tslint:disable */
/* eslint-disable */
import { FullNameUserViewModel } from "./full-name-user-view-model";
import { RichTextElementViewModel } from "./rich-text-element-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MinimalCommentViewModel")
export class MinimalCommentViewModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdBy", FullNameUserViewModel, true)
  createdBy?: FullNameUserViewModel = undefined;

  @JsonProperty("elements", [RichTextElementViewModel], true)
  elements?: Array<RichTextElementViewModel> = [];

  @JsonProperty("mentions", [FullNameUserViewModel], true)
  mentions?: Array<FullNameUserViewModel> = [];

  @JsonProperty("text", String, true)
  text?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("updatedAt", DateTimeConvertService, true)
  updatedAt?: Date | undefined = undefined;
}
