/* tslint:disable */
/* eslint-disable */
import { FullHeadingLinkViewModel } from "./full-heading-link-view-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocSetFullDocOutlineViewModel")
export class DocSetFullDocOutlineViewModel {
  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  @JsonProperty("headings", [FullHeadingLinkViewModel], true)
  headings?: Array<FullHeadingLinkViewModel> = [];
}
