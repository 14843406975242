/* tslint:disable */
/* eslint-disable */
import { ProvisionInfoGroupModel } from "./provision-info-group-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionFormDtoModel")
export class ProvisionFormDtoModel {
  @JsonProperty("createdByEmail", String, true)
  createdByEmail?: string = "";

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("docSetCategory", String, true)
  docSetCategory?: string = "";

  @JsonProperty("flags", Any, true)
  flags?: Array<
    | "ABSTRACT_TEMPLATE_GENERATOR_INTELEASE"
    | "REAL_ESTATE_LEASE_DOCUMENT_TYPE"
    | "REAL_ESTATE_PURCHASE_SALES_AGREEMENT_DOCUMENT_TYPE"
    | "REAL_ESTATE_TITLE_INSURANCE_DOCUMENT_TYPE"
    | "REAL_ESTATE_INSURANCE_CERTIFICATE_DOCUMENT_TYPE"
    | "REAL_ESTATE_MORTGAGE_DOCUMENT_TYPE"
    | "REAL_ESTATE_LOAN_AGREEMENT_DOCUMENT_TYPE"
    | "CORPORATE_CREDIT_AGREEMENT_DOCUMENT_TYPE"
    | "CORPORATE_LOAN_AGREEMENT_DOCUMENT_TYPE"
    | "CORPORATE_MORTGAGE_AGREEMENT_DOCUMENT_TYPE"
  > = [];

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("numProvision", Number, true)
  numProvision?: number = undefined;

  @JsonProperty("provisionGroups", [ProvisionInfoGroupModel], true)
  provisionGroups?: Array<ProvisionInfoGroupModel> = [];

  @JsonProperty("scope", Any, true)
  scope?: "USER" | "ACCOUNT" = undefined;

  @JsonProperty("source", Any, true)
  source?:
    | "ADMIN_FORM_CREATE"
    | "USER_FORM_CREATE"
    | "USER_FORM_MODIFY"
    | "IMPLICIT_FORM_ADD"
    | "FREE_FORM_CREATE" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
