import { transition, trigger, useAnimation } from "@angular/animations";
import { bounceIn, bounceOut, fadeIn, fadeOut } from "ng-animate";

export const WEB_ABSTRACTION_PROVISION_BOX_ANIMATION_CONST = [
  trigger("fadeIn", [
    transition(
      "void => *",
      useAnimation(fadeIn, {
        params: {
          timing: 0.4,
        },
      })
    ),
    transition(
      "* => void",
      useAnimation(fadeOut, {
        params: {
          timing: 0.1,
        },
      })
    ),
  ]),
  trigger("bounceIn", [
    transition(
      "void => *",
      useAnimation(bounceIn, {
        params: {
          timing: 0.6,
        },
      })
    ),
    transition(
      "* => void",
      useAnimation(bounceOut, {
        params: {
          timing: 0.4,
        },
      })
    ),
  ]),
];
