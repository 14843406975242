import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromNotifications from "./notifications.reducer";
import { NotificationsEffects } from "./notifications.effects";
import { NotificationsFacade } from "./notifications.facade";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromNotifications.NOTIFICATIONS_FEATURE_KEY,
      fromNotifications.reducer
    ),
    EffectsModule.forFeature([NotificationsEffects]),
  ],
  providers: [NotificationsFacade],
})
export class AppStateNotificationsModule {}
