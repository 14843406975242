/* tslint:disable */
/* eslint-disable */
import { DoubleProvisionUnitViewModel } from "./double-provision-unit-view-model";
import { FullProvisionTypeViewModel } from "./full-provision-type-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionMetadataModel")
export class ProvisionMetadataModel {
  @JsonProperty("possibleValues", [String], true)
  possibleValues?: Array<string> = [];

  @JsonProperty("types", [FullProvisionTypeViewModel], true)
  types?: Array<FullProvisionTypeViewModel> = [];

  @JsonProperty("units", [DoubleProvisionUnitViewModel], true)
  units?: Array<DoubleProvisionUnitViewModel> = [];
}
