/* tslint:disable */
/* eslint-disable */

/**
 * the simplest representation of a date
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SimpleOpenApiDateModel")
export class SimpleOpenApiDateModel {
  /**
   * the day of the month
   */

  @JsonProperty("day", Number, true)
  day?: number = undefined;

  /**
   * the month of the year (1-based)
   */

  @JsonProperty("month", Number, true)
  month?: number = undefined;

  /**
   * the year
   */

  @JsonProperty("year", Number, true)
  year?: number = undefined;
}
