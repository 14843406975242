import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  CancelUploadingLeaseFilesResponseModel,
  MyFile,
} from "@@intelease/web/ui/src/lib/itls-new-upload";
import { Observable, of, Subject } from "rxjs";
import { ServerResponseModel } from "@intelease/models";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { UploadingBoxDialogService } from "@common/services";
import { UploadingDocSetBoxComponent } from "@@intelease/web/ui/src/lib/itls-new-upload";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import { UploadDocSetFilesResponseModel } from "../models/upload-doc-set-files-response.model";
import { UploadDocSetFilesResDtoModel } from "@@intelease/api-models/adex-api-model-src";

export enum UploadDocSetDataStatus {
  UPLOADING = "UPLOADING",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
}

export interface UploadDocSetData {
  isFolder: boolean;
  isExpanded: boolean;
  folderUid?: string;
  folderName?: string;
  folderStatus?: UploadDocSetDataStatus;
  docSet?: UploadDocData;
  docSets?: UploadDocData[];
  parentFolderUid?: string;
  isAbleToCancelUpload: boolean;
  queueUid?: string;
}

export interface UploadDocData {
  uid: string;
  name: string;
  status: UploadDocSetDataStatus;
  files: MyFile[];
  parentFolderUid?: string;
  isAbleToCancelUpload: boolean;
  queueUid?: string;
}

export interface DocSetData {
  uid: string;
  name: string;
  files: MyFile[];
}

@Injectable({
  providedIn: "root",
})
export class UploadingDocSetBoxService {
  public static MODAL_ID = "9797f89b-405f-4532-b36f-ba244ea72de7";
  private static readonly API_VERSION_1 = "/v1";
  private static readonly UPLOAD_DOC_SET_FILES_URL = "/doc-sets/files";

  $onNewUpload: Subject<UploadDocSetData> = new Subject<UploadDocSetData>();

  constructor(
    private matDialog: MatDialog,
    private httpClient: HttpClient,
    private uploadingBoxDialogService: UploadingBoxDialogService
  ) {}

  cancelUploadingLeaseFiles(
    abstractUids: string[]
  ): Observable<CancelUploadingLeaseFilesResponseModel> {
    //there is API yet
    return of(undefined);
  }

  uploadDocSetFiles(
    abstractUid: string,
    files: MyFile[],
    queueUid?: string
  ): Observable<UploadDocSetFilesResDtoModel> {
    const formData = new FormData();
    for (const myFile of files) {
      formData.append(
        "files",
        myFile.file,
        encodeURIComponent(myFile.name + this.getExtension(myFile.file))
      );
    }
    formData.set("uid", abstractUid);
    if (queueUid) {
      formData.set("queueUid", queueUid);
    }
    return this.httpClient
      .post<ServerResponseModel>(
        UploadingDocSetBoxService.API_VERSION_1 +
          UploadingDocSetBoxService.UPLOAD_DOC_SET_FILES_URL,
        formData
      )
      .pipe(
        map((resp) => {
          return Json2TypescriptHelper.convertToEntity(
            resp.data,
            UploadDocSetFilesResDtoModel
          );
        })
      );
  }

  uploadFolder(
    parentFolderUid: string,
    uid: string,
    name: string,
    docSets: DocSetData[],
    queueUid?: string
  ) {
    this.upload({
      isFolder: true,
      isExpanded: false,
      folderUid: uid,
      folderName: name,
      folderStatus: UploadDocSetDataStatus.UPLOADING,
      parentFolderUid,
      isAbleToCancelUpload: false,
      queueUid,
      docSets: docSets.map((docSet) => ({
        uid: docSet.uid,
        name: docSet.name,
        files: docSet.files,
        status: UploadDocSetDataStatus.UPLOADING,
        isAbleToCancelUpload: false,
      })),
    });
  }

  uploadDocuments(
    parentFolderUid: string,
    docSet: DocSetData,
    queueUid?: string
  ) {
    this.upload({
      isFolder: false,
      isExpanded: false,
      parentFolderUid,
      isAbleToCancelUpload: false,
      queueUid,
      docSet: {
        uid: docSet.uid,
        name: docSet.name,
        files: docSet.files,
        status: UploadDocSetDataStatus.UPLOADING,
        isAbleToCancelUpload: false,
      },
    });
  }

  private upload(uploadData: UploadDocSetData) {
    // if dialog is already opened
    if (this.getMatDialog()) {
      this.$onNewUpload.next(uploadData);
    } else {
      const matDialogRef = this.matDialog.open(UploadingDocSetBoxComponent, {
        maxHeight: "300px",
        panelClass: "uploading-box-panel",
        hasBackdrop: false,
        id: UploadingDocSetBoxService.MODAL_ID,
        data: uploadData,
        disableClose: true,
        position: {
          bottom: "30px",
          right: "90px",
        },
        autoFocus: false,
      });
      matDialogRef.afterOpened().subscribe((resp) => {
        const modalElement = document.getElementById(
          UploadingDocSetBoxService.MODAL_ID
        );
        modalElement.parentElement.parentElement.style["zIndex"] = "unset";
        this.uploadingBoxDialogService.uploadingBoxDialogOpened = true;
      });
      matDialogRef.afterClosed().subscribe((resp) => {
        this.uploadingBoxDialogService.uploadingBoxDialogOpened = false;
      });
    }
  }

  close() {
    return this.getMatDialog().close();
  }

  private getMatDialog(): MatDialogRef<UploadingDocSetBoxComponent> {
    return this.matDialog.getDialogById(UploadingDocSetBoxService.MODAL_ID);
  }

  private getExtension(file: File) {
    const lastDotIdx = file.name.lastIndexOf(".");
    let extension = "";
    if (lastDotIdx !== -1) {
      extension = file.name.substr(lastDotIdx);
    }
    return extension;
  }
}
