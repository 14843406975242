/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqOptionsCalculatorInputDataModel } from "../models/o-api-req-options-calculator-input-data-model";
import { OApiRespOptionsCalculatorInputDataResponseModel } from "../models/o-api-resp-options-calculator-input-data-response-model";

@Injectable({
  providedIn: "root",
})
export class OptionsCalculatorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation retrieveOptionsCalculatorData
   */
  static readonly RetrieveOptionsCalculatorDataPath =
    "/api/v1/calculator/options-data";

  /**
   * Get the inputs/initial data for the options calculator.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveOptionsCalculatorData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retrieveOptionsCalculatorData$Response(params: {
    body: OApiReqOptionsCalculatorInputDataModel;
  }): Observable<
    StrictHttpResponse<OApiRespOptionsCalculatorInputDataResponseModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      OptionsCalculatorService.RetrieveOptionsCalculatorDataPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespOptionsCalculatorInputDataResponseModel>;
        })
      );
  }

  /**
   * Get the inputs/initial data for the options calculator.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retrieveOptionsCalculatorData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retrieveOptionsCalculatorData(params: {
    body: OApiReqOptionsCalculatorInputDataModel;
  }): Observable<OApiRespOptionsCalculatorInputDataResponseModel> {
    return this.retrieveOptionsCalculatorData$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<OApiRespOptionsCalculatorInputDataResponseModel>
        ) => r.body as OApiRespOptionsCalculatorInputDataResponseModel
      )
    );
  }
}
