import { Injectable } from "@angular/core";
import { ShareApiService } from "@@intelease/web/intelease/services";

@Injectable({
  providedIn: "root",
})
export class ShareAbstractService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(private shareApiService: ShareApiService) {}

  shareAbstract(
    abstractUid: string,
    userUids: string[],
    allVersions: boolean
  ): void {
    this.shareApiService.sendRequestNoResponse(
      ShareAbstractService.API_VERSION_1,
      ShareAbstractService.FINAL_DOC_SETS_URL,
      abstractUid,
      userUids,
      allVersions
    );
  }
}
