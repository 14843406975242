<div class="provision-mentions">
  <mat-list class="pt-0">
    <ng-container *ngFor="let snippet of snippets; let snippetIndex = index">
      <mat-list-item class="mention-list-item" style="height: unset !important">
        <div class="mention-main-container" fxLayout="column">
          <div
            class="mention-list-item-container"
            fxLayout="row"
            fxLayoutAlign="space-between start"
          >
            <div class="mention-text mr-8">
              <ng-container
                *ngIf="snippet?.snippets && snippet?.snippets.length"
              >
                <ng-container *ngFor="let snippet of snippet?.snippets">
                  <il-snippet-text
                    [snippet]="snippet"
                    type="PROVISION_BOX"
                    [provisionBoxWidth]="5000"
                  >
                  </il-snippet-text>
                </ng-container>
              </ng-container>
            </div>
            <div style="min-width: 80px; text-align: right">
              <!--            <div style="min-width: 104px;text-align: right;">-->
              <ng-container *ngxPermissionsOnly="AUTH.UPDATE_RECORD">
                <button
                  mat-button
                  color="primary"
                  class="select-btn"
                  [hidden]="!(snippet.relatedOptionUids.length === 0)"
                  *ngIf="editable && isSelectedDocumentFullyLoaded"
                  (click)="onPickSnippet(snippet)"
                >
                  Select
                </button>
              </ng-container>
              <button
                mat-icon-button
                class="options-btn"
                *ngIf="
                  snippet?.relatedOptionUids.length > 0 &&
                  snippet?.source !== 'DERIVED' &&
                  snippet?.source !== 'CUSTOMER_DERIVED' &&
                  snippet?.source !== 'DERIVED_MODIFY'
                "
                [matMenuTriggerFor]="optionsMenu"
              >
                <mat-icon svgIcon="icon-more"></mat-icon>
              </button>
              <mat-menu #optionsMenu="matMenu" xPosition="before">
                <button
                  mat-menu-item
                  [disabled]="snippet?.relatedOptionUids.length === 0"
                  (click)="gotoMentionBySnippet(snippet)"
                >
                  Take me there
                </button>
              </mat-menu>
            </div>
          </div>
          <div
            class="mention-footer"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <ng-container
              *ngIf="
                snippet?.source === 'DERIVED' ||
                  snippet?.source === 'CUSTOMER_DERIVED' ||
                  snippet?.source === 'DERIVED_MODIFY';
                else elseBlock
              "
            >
              <div class="left-items">
                <span class="document-title">Automatically derived</span>
              </div>
            </ng-container>
            <ng-template #elseBlock>
              <div class="left-items">
                <span class="document-title">Location :</span>
                <span>{{
                  documentsShortNameMap[snippet?.docAbstractUid]?.name
                }}</span>
                <mat-icon
                  class="right-arrow"
                  svgIcon="icon-arrow-right"
                ></mat-icon>
                <span>{{
                  documentsShortNameMap[snippet?.docAbstractUid]?.shortLabel +
                    ".P" +
                    snippet?.page
                }}</span>
              </div>
              <button
                mat-icon-button
                class="take-me-there"
                matTooltip="Show the location"
                *ngIf="isSelectedDocumentFullyLoaded"
                (click)="gotoMentionHighlightOnViewer.emit(snippet)"
              >
                <mat-icon svgIcon="icon-take-me-there"></mat-icon>
              </button>
            </ng-template>
          </div>
        </div>
      </mat-list-item>
      <mat-divider
        *ngIf="snippets && snippetIndex < snippets.length - 1"
      ></mat-divider>
    </ng-container>
    <mat-list-item
      *ngIf="!snippets || (snippets && snippets.length === 0)"
      style="min-height: 50px"
    >
      No mentions found.
    </mat-list-item>
  </mat-list>
</div>
