/* tslint:disable */
/* eslint-disable */
import { FullStateViewModel } from "./full-state-view-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullNamedSearchFieldViewModel")
export class FullNamedSearchFieldViewModel {
  @JsonProperty("fieldName", String, true)
  fieldName?: string = "";

  @JsonProperty("fieldType", Any, true)
  fieldType?:
    | "ADDRESS"
    | "COMMON_NOUN"
    | "DOUBLE"
    | "EMAIL"
    | "DATE"
    | "NUMBER"
    | "STRING"
    | "UID"
    | "LOCAL_DATE"
    | "MONEY"
    | "MULTIPLE_CAT"
    | "PHONE"
    | "PROPER_NOUN"
    | "SINGLE_CAT"
    | "TABLE"
    | "YEAR"
    | "TIME_PLAN"
    | "DOC_TEXT" = undefined;

  @JsonProperty("possibleCountries", [String], true)
  possibleCountries?: Array<string> = [];

  @JsonProperty("possibleCurrencies", [String], true)
  possibleCurrencies?: Array<string> = [];

  @JsonProperty("possibleFullStates", [FullStateViewModel], true)
  possibleFullStates?: Array<FullStateViewModel> = [];

  @JsonProperty("possibleStates", [String], true)
  possibleStates?: Array<string> = [];

  @JsonProperty("possibleUnits", [String], true)
  possibleUnits?: Array<string> = [];

  @JsonProperty("possibleValues", [String], true)
  possibleValues?: Array<string> = [];

  @JsonProperty("uiName", String, true)
  uiName?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
