export enum PermissionTypeGroupEnum {
  OWNER = "OWNER",
  EDITOR = "EDITOR",
  COMMENTER = "COMMENTER",
  VIEWER = "VIEWER",
}

export namespace PermissionTypeGroupEnum {
  export function getValues() {
    return [
      PermissionTypeGroupEnum.VIEWER,
      PermissionTypeGroupEnum.COMMENTER,
      PermissionTypeGroupEnum.EDITOR,
      PermissionTypeGroupEnum.OWNER,
    ];
  }

  export function getUserSelectableValues() {
    return [
      PermissionTypeGroupEnum.VIEWER,
      PermissionTypeGroupEnum.COMMENTER,
      PermissionTypeGroupEnum.EDITOR,
    ];
  }

  export function getTitle(permissionTypeGroup: PermissionTypeGroupEnum) {
    switch (permissionTypeGroup) {
      case PermissionTypeGroupEnum.OWNER:
        return "Owner";
      case PermissionTypeGroupEnum.EDITOR:
        return "Editor";
      case PermissionTypeGroupEnum.COMMENTER:
        return "Commenter";
      case PermissionTypeGroupEnum.VIEWER:
        return "Viewer";
      default:
        throw new Error(
          "cannot handle PermissionTypeGroupEnum: " + permissionTypeGroup
        );
    }
  }

  export function getTitleDescription(
    permissionTypeGroup: PermissionTypeGroupEnum
  ) {
    switch (permissionTypeGroup) {
      case PermissionTypeGroupEnum.OWNER:
        return "Can Edit, Share";
      case PermissionTypeGroupEnum.EDITOR:
        return "Can Edit";
      case PermissionTypeGroupEnum.COMMENTER:
        return "Can Comment";
      case PermissionTypeGroupEnum.VIEWER:
        return "Can View";
      default:
        throw new Error(
          "cannot handle PermissionTypeGroupEnum: " + permissionTypeGroup
        );
    }
  }

  export function getDescription(permissionTypeGroup: PermissionTypeGroupEnum) {
    switch (permissionTypeGroup) {
      case PermissionTypeGroupEnum.OWNER:
        return "Can edit and share with others.";
      case PermissionTypeGroupEnum.EDITOR:
        return "Can edit, but not share with others.";
      case PermissionTypeGroupEnum.COMMENTER:
        return "Can view and comment, but not edit.";
      case PermissionTypeGroupEnum.VIEWER:
        return "Cannot edit, comment or share with others.";
      default:
        throw new Error(
          "cannot handle PermissionTypeGroupEnum: " + permissionTypeGroup
        );
    }
  }
}
