import { CalculatorInterface } from "@@intelease/web/abstraction-page";
import { CalculatorNamesEnum } from "@@intelease/web/abstraction-page/src/lib/enums";

export const CALCULATOR_LIST_CONST: CalculatorInterface[] = [
  {
    title: "Rent schedule calculator",
    calculatorName: CalculatorNamesEnum.RentSchedule,
    requiredProvisions: [{ htmlName: "rentSchedule", name: "Rent Schedule" }],
    errorMsg:
      "Rent Schedule Calculator requires Rent Schedule provision, but this record does not have it!",
  },
  {
    title: "Term calculator",
    calculatorName: CalculatorNamesEnum.TermCalculator,
    requiredProvisions: [
      {
        htmlName: "commencementDate",
        name: "Commencement Date",
      },
      {
        htmlName: "expiryDate",
        name: "Expiry Date",
      },
      {
        htmlName: "leaseTerm",
        name: "Lease Term",
      },
    ],
    errorMsg:
      "Term Calculator requires Commencement Date, Expiry Date, and Lease Term provisions, but this record does not have them!",
  },
];

export const CALCULATOR_LIST_CONST_V2: CalculatorInterface[] = [
  {
    title: "Rent schedule calculator",
    calculatorName: CalculatorNamesEnum.RentSchedule,
    requiredProvisions: [
      {
        htmlName: "rentSchedule_TABLE",
        name: "Option Notifications",
      },
    ],
    errorMsg:
      "Rent Schedule Calculator requires Rent Schedule provision, but this record does not have it!",
  },
  {
    title: "Term calculator",
    calculatorName: CalculatorNamesEnum.TermCalculator,
    requiredProvisions: [
      {
        htmlName: "commencementDate_LOCAL_DATE",
        name: "Commencement Date",
      },
      {
        htmlName: "expiryDate_LOCAL_DATE",
        name: "Expiry Date",
      },
      {
        htmlName: "leaseTerm_DOUBLE",
        name: "Lease Term",
      },
    ],
    errorMsg:
      "Term Calculator requires Commencement Date, Expiry Date and Lease Term provision, but this record does not have {missProvisionsMessage}!",
  },
  {
    title: "Options calculator",
    calculatorName: CalculatorNamesEnum.OptionsCalculator,
    requiredProvisions: [
      {
        htmlName: "options",
        name: "Options",
      },
    ],
    errorMsg:
      "Options calculator requires options provision, but this record does not have it!",
  },
  {
    title: "Options Reminder calculator",
    calculatorName: CalculatorNamesEnum.OptionsReminderCalculator,
    requiredProvisions: [
      {
        htmlName: "optionNotifications",
        name: "Option Notifications",
      },
    ],
    errorMsg:
      "Options Reminder calculator requires options notifications provision, but this record does not have it!",
  },
];
