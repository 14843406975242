import { NgModule } from "@angular/core";
import { SnippetTextComponent } from "./snippet-text.component";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgPipesModule } from "ngx-pipes";

const MATERIAL_MODULES = [MatIconModule, MatTooltipModule];

@NgModule({
  imports: [CommonModule, ...MATERIAL_MODULES, NgPipesModule],
  declarations: [SnippetTextComponent],
  exports: [SnippetTextComponent],
})
export class SnippetTextModule {}
