import { createFeatureSelector, createSelector } from "@ngrx/store";
import { KANBAN_FEATURE_KEY, KanbanState } from "./kanban.reducer";

// Lookup the 'Kanban' feature state managed by NgRx
const getKanbanState = createFeatureSelector<KanbanState>(KANBAN_FEATURE_KEY);

const getLoaded = createSelector(
  getKanbanState,
  (state: KanbanState) => state.loaded
);
const getError = createSelector(
  getKanbanState,
  (state: KanbanState) => state.error
);

const getAllKanban = createSelector(
  getKanbanState,
  getLoaded,
  (state: KanbanState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getKanbanState,
  (state: KanbanState) => state.selectedId
);
const getSelectedKanban = createSelector(
  getAllKanban,
  getSelectedId,
  (kanban, id) => {
    const result = kanban.find((it) => it["id"] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

const saveKanbanSucceeded = createSelector(
  getKanbanState,
  (state: KanbanState) => state.saveKanbanSucceeded
);

const savedKanbanDetail = createSelector(
  getKanbanState,
  saveKanbanSucceeded,
  (state: KanbanState, isSucceeded) =>
    isSucceeded ? state.savedKanbanDetail : undefined
);

const selectedKanbanLoaded = createSelector(
  getKanbanState,
  (state: KanbanState) => state.selectedKanbanLoaded
);

const isSelectedKanbanLoading = createSelector(
  getKanbanState,
  (state: KanbanState) => state.isSelectedKanbanLoading
);

const getSelectedKanbanDetail = createSelector(
  getKanbanState,
  selectedKanbanLoaded,
  (state: KanbanState, isLoaded) =>
    isLoaded ? state.selectedKanbanDetail : undefined
);

const getSelectedKanbanDetailMap = createSelector(
  getKanbanState,
  selectedKanbanLoaded,
  (state: KanbanState, isLoaded) =>
    isLoaded ? state.selectedKanbanDetailMap : undefined
);

const isSelectedKanbanIssueLoading = createSelector(
  getKanbanState,
  (state: KanbanState) => state.isSelectedKanbanIssueLoading
);

const selectedKanbanIssueLoaded = createSelector(
  getKanbanState,
  (state: KanbanState) => state.selectedKanbanIssueLoaded
);

const getSelectedKanbanIssueDetail = createSelector(
  getKanbanState,
  selectedKanbanIssueLoaded,
  (state: KanbanState, isLoaded: boolean) =>
    isLoaded ? state.selectedKanbanIssueDetail : undefined
);

const isDeleteKanbanIssueLoading = createSelector(
  getKanbanState,
  (state) => state.isDeleteKanbanIssueLoading
);

const deleteKanbanIssueSucceeded = createSelector(
  getKanbanState,
  isDeleteKanbanIssueLoading,
  (state, isPending) =>
    !isPending && state.deleteKanbanIssueSucceeded
      ? state.deletedIssueData
      : undefined
);

const isSaveKanbanCommentLoading = createSelector(
  getKanbanState,
  (state) => state.isSaveKanbanCommentLoading
);

const saveKanbanCommentSucceeded = createSelector(
  getKanbanState,
  (state) => state.saveKanbanCommentSucceeded
);

const isDeleteKanbanCommentPending = createSelector(
  getKanbanState,
  (state) => state.isDeleteKanbanCommentPending
);

const deleteKanbanCommentSucceeded = createSelector(
  getKanbanState,
  isDeleteKanbanCommentPending,
  (state, isPending) => !isPending && state.deleteKanbanCommentSucceeded
);

const isUpdateKanbanCommentPending = createSelector(
  getKanbanState,
  (state) => state.isUpdateKanbanCommentPending
);

const updateKanbanCommentSucceeded = createSelector(
  getKanbanState,
  isUpdateKanbanCommentPending,
  (state, isPending) => !isPending && state.updateKanbanCommentSucceeded
);

const isUpdateSelectedKanbanLoading = createSelector(
  getKanbanState,
  (state) => state.isUpdateSelectedKanbanLoading
);

export const kanbanQuery = {
  getLoaded,
  getError,
  getAllKanban,
  getSelectedKanban,

  saveKanbanSucceeded,
  savedKanbanDetail,

  selectedKanbanLoaded,
  getSelectedKanbanDetail,
  getSelectedKanbanDetailMap,
  isSelectedKanbanLoading,

  isSelectedKanbanIssueLoading,
  getSelectedKanbanIssueDetail,

  isDeleteKanbanIssueLoading,
  deleteKanbanIssueSucceeded,

  isSaveKanbanCommentLoading,
  saveKanbanCommentSucceeded,

  isDeleteKanbanCommentPending,
  deleteKanbanCommentSucceeded,

  isUpdateKanbanCommentPending,
  updateKanbanCommentSucceeded,

  isUpdateSelectedKanbanLoading,
};
