/* tslint:disable */
/* eslint-disable */
import { EntityPermissionActorFullDtoModel } from "./entity-permission-actor-full-dto-model";
import { PaginationDataModel } from "./pagination-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiMultiEntityContainerResponseEntityPermissionActorFullDtoModel")
export class OApiMultiEntityContainerResponseEntityPermissionActorFullDtoModel {
  @JsonProperty("items", [EntityPermissionActorFullDtoModel], true)
  items?: Array<EntityPermissionActorFullDtoModel> = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
