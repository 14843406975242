import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("DocumentTypeModel")
export class DocumentTypeModel {
  @JsonProperty("uiName", String)
  uiName = "";

  @JsonProperty("value", String)
  value = "";
}
