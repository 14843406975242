/* tslint:disable */
/* eslint-disable */
import { UserSettingItemModel } from "./user-setting-item-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UserSettingsModel")
export class UserSettingsModel {
  @JsonProperty("requireBlessing", Boolean, true)
  requireBlessing?: boolean = undefined;

  @JsonProperty("requireUploadToAdminAccount", Boolean, true)
  requireUploadToAdminAccount?: boolean = undefined;

  @JsonProperty("settingItems", [UserSettingItemModel], true)
  settingItems?: Array<UserSettingItemModel> = [];
}
