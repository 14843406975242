<div
  class="itls-breadcrumb px-8"
  [style.max-width]="
    screenWidth - toolbarWidth - (sidebarOpen ? 247 : 56) - 16 + 'px'
  "
>
  <breadcrumb-item
    *ngFor="let breadcrumb of modifiedBreadcrumbs; let i = index"
    [breadcrumbItem]="breadcrumb"
    [isRoot]="i == 0"
    [contentMaxWidth]="
      (screenWidth -
        toolbarWidth -
        (sidebarOpen ? 247 : 56) -
        16 -
        120 -
        (isShowingEllipses ? 50 : 0)) /
      2
    "
    [showArrowIcon]="
      modifiedBreadcrumbs.length > 1 && i < modifiedBreadcrumbs.length - 1
    "
  ></breadcrumb-item>
</div>
