/* tslint:disable */
/* eslint-disable */
import { NamedSearchFieldModel } from "./named-search-field-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionInfoNamedSearchFieldDtoModel")
export class ProvisionInfoNamedSearchFieldDtoModel {
  @JsonProperty("allowedTypes", Any, true)
  allowedTypes?: Array<
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN"
  > = [];

  @JsonProperty("docSetCategories", [String], true)
  docSetCategories?: Array<string> = [];

  @JsonProperty("namedSearchField", NamedSearchFieldModel, true)
  namedSearchField?: NamedSearchFieldModel = undefined;
}
