/* tslint:disable */
/* eslint-disable */
import { HighlightPartialMentionApiDtoModel } from "./highlight-partial-mention-api-dto-model";
import { UpdatedHighlightInfoApiDtoModel } from "./updated-highlight-info-api-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SaveHighlightMentionV2ApiDtoModel")
export class SaveHighlightMentionV2ApiDtoModel {
  @JsonProperty("docAbstractUidToHighlightInfo", Any, true)
  docAbstractUidToHighlightInfo?: {
    [key: string]: UpdatedHighlightInfoApiDtoModel;
  } = {};

  @JsonProperty(
    "highlightPartialMention",
    HighlightPartialMentionApiDtoModel,
    true
  )
  highlightPartialMention?: HighlightPartialMentionApiDtoModel = undefined;
}
