import {
  Any,
  JsonConvert,
  JsonConverter,
  JsonCustomConvert,
  OperationMode,
} from "json2typescript";
import { DateConvertService } from "./date-convert.service";
import { Utilities } from "../utils/utilities";
import { cloneDeep, isDate } from "lodash";

@JsonConverter
export class ProvisionValueConvertService implements JsonCustomConvert<any> {
  jsonConvert: JsonConvert = new JsonConvert();

  constructor() {
    this.jsonConvert.operationMode = OperationMode.LOGGING;
  }

  public static deserializeProvisionValue(value) {
    if (value.value && Utilities.isLocalDate(value.value)) {
      value.value = DateConvertService.deserializeDate(value.value);
    }
    return value;
  }

  public static serializeProvisionValue(value) {
    const _value = cloneDeep(value);
    if (_value.value && isDate(_value.value)) {
      _value.value = DateConvertService.serializeDate(_value.value);
    }
    return _value;
  }

  serialize(data): any {
    ProvisionValueConvertService.serializeProvisionValue(data);
  }

  deserialize(data: any) {
    return ProvisionValueConvertService.deserializeProvisionValue(data);
  }
}
