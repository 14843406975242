/* tslint:disable */
/* eslint-disable */
import { PaginationDataModel } from "./pagination-data-model";
import { PermissionsAbstractViewV2Model } from "./permissions-abstract-view-v-2-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiMultiEntityContainerResponsePermissionsAbstractViewV2Model")
export class OApiMultiEntityContainerResponsePermissionsAbstractViewV2Model {
  @JsonProperty("items", [PermissionsAbstractViewV2Model], true)
  items?: Array<PermissionsAbstractViewV2Model> = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
