<div class="add-calendar-container">
  <form (submit)="onSaveFolderClicked()">
    <h1 mat-dialog-title>Create a New Folder</h1>
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          [(ngModel)]="name"
          name="name"
          matInput
          itlsFocusIn
          (keyup)="isValidateFields = name === '' || name === undefined"
        />
      </mat-form-field>
    </div>
    <div
      mat-dialog-actions
      style="display: flex; align-items: center; justify-content: space-around"
    >
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <div
        [matTooltip]="
          isValidateFields ? 'The folder name is required!' : 'Continue'
        "
      >
        <button
          mat-flat-button
          type="submit"
          color="primary"
          [disabled]="isCreatingNewFolder || isValidateFields"
        >
          Continue
        </button>
      </div>
    </div>
  </form>
</div>
