/* tslint:disable */
/* eslint-disable */
import { ErrorDataModel } from "./error-data-model";
import { OApiMultiEntityContainerResponseFullRoleViewModel } from "./o-api-multi-entity-container-response-full-role-view-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiRespMultiFullRoleViewModel")
export class OApiRespMultiFullRoleViewModel {
  @JsonProperty("data", OApiMultiEntityContainerResponseFullRoleViewModel, true)
  data?: OApiMultiEntityContainerResponseFullRoleViewModel = undefined;

  @JsonProperty("devErrors", [ErrorDataModel], true)
  devErrors?: Array<ErrorDataModel> = [];

  @JsonProperty("errors", [ErrorDataModel], true)
  errors?: Array<ErrorDataModel> = [];

  @JsonProperty("message", String, true)
  message?: string = "";
}
