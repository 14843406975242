/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";
import { TaskAssigneeDtoModel } from "./task-assignee-dto-model";
import { TaskStatusDtoModel } from "./task-status-dto-model";
import { UserViewModel } from "./user-view-model";

/**
 * a minimal representation of a record's task
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MinimalFinalAbstractTaskDtoModel")
export class MinimalFinalAbstractTaskDtoModel {
  @JsonProperty("assignee", TaskAssigneeDtoModel, true)
  assignee?: TaskAssigneeDtoModel = undefined;

  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdBy", UserViewModel, true)
  createdBy?: UserViewModel = undefined;

  @JsonProperty("dueDate", SimpleOpenApiDateModel, true)
  dueDate?: SimpleOpenApiDateModel = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the task's notes
   */

  @JsonProperty("notes", String, true)
  notes?: string = "";

  @JsonProperty("priority", Any, true)
  priority?: "LOW" | "MEDIUM" | "HIGH" = undefined;

  @JsonProperty("status", TaskStatusDtoModel, true)
  status?: TaskStatusDtoModel = undefined;

  @JsonProperty("type", Any, true)
  type?: "REVIEW" | "APPROVE" | "OTHER" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
