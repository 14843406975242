import { CreateApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CreateProvisionFormService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly PROVISION_FORMS_URL = "/provisionForms";

  constructor(private createApiService: CreateApiService) {}

  saveNewProvisionFormNoView(
    newProvFormData: any
  ): Observable<ServerResponseModel> {
    return this.createApiService.sendRequestNoView(
      CreateProvisionFormService.API_VERSION_1,
      CreateProvisionFormService.PROVISION_FORMS_URL,
      newProvFormData
    );
  }
}
