import { JsonObject, JsonProperty } from "json2typescript";
import { TaskPriorityEnum } from "@@intelease/app-models/tasks/src/lib/enums/task-priority.enum";
import { TaskTypeEnum } from "@@intelease/app-models/tasks/src/lib/enums/task-type.enum";
import { TaskAssigneeModel } from "@@intelease/app-models/tasks/src/lib/tasks/task-assignee.model";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { FullUserAbstractTaskStatusModel } from "./full-user-abstract-task.model";

@JsonObject("MinimalUserAbstractTaskModel")
export class MinimalUserAbstractTaskModel {
  static view = "minimal";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("assignee", TaskAssigneeModel)
  assignee: TaskAssigneeModel = undefined;

  @JsonProperty("dueDate", DateConvertService)
  dueDate = ""; // ISO format ("MM/dd/yyyy'T'HH:mm:ss.SSSzZ") (04/12/2019T12:04:12.000GMT+01:00)

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("notes", String)
  notes = "";

  @JsonProperty("type", String)
  type: TaskTypeEnum = TaskTypeEnum.APPROVE;

  @JsonProperty("priority", String)
  priority: TaskPriorityEnum = TaskPriorityEnum.MEDIUM;

  @JsonProperty("status", FullUserAbstractTaskStatusModel)
  status: FullUserAbstractTaskStatusModel = undefined;
}
