import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RestClient } from "@@intelease/web/utils";
import { FileApiService } from "@@intelease/web/intelease/services";
import { map } from "rxjs/operators";
import { ReportFilterFieldsGroupModel } from "@@intelease/app-models/reports";
import { ServerConfigsService } from "@@intelease/app-services/common";
import {
  OApiRespFullReportQueryViewModel,
  OApiRespMediumReportQueryViewModel,
  OApiRespMinimalReportQueryViewModel,
  OApiRespMultiFullReportQueryViewModel,
  OApiRespMultiMediumReportQueryViewModel,
  OApiRespMultiMinimalReportQueryViewModel,
  OApiRespReportFieldNamesResponseDtoModel,
  OApiRespReportResultDtoModel,
  OApiSingleEntityNoDataResponseModel,
} from "@@intelease/api-models/adex-api-model-src";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  public static readonly GENERAL_DOC_SET_CATEGORY__VALUE = "GENERAL";
  public static readonly GENERAL_DOC_SET_CATEGORY__TITLE = "General";

  constructor(
    private readonly restClient: RestClient,
    private readonly fileApiService: FileApiService,
    private readonly serverConfigsService: ServerConfigsService
  ) {}

  getReportFieldNames(): Observable<OApiRespReportFieldNamesResponseDtoModel> {
    return this.restClient.sendGetRequest<OApiRespReportFieldNamesResponseDtoModel>(
      "/v1",
      "/report/reportFieldNames",
      OApiRespReportFieldNamesResponseDtoModel
    );
  }

  getAbstractsReport(
    payload,
    dashboardUid: string
  ): Observable<OApiRespReportResultDtoModel> {
    const _payload = {
      ...payload,
      ...{
        page: 1,
        perPage: 9000,
      },
    };
    return this.restClient.sendPostRequest<OApiRespReportResultDtoModel>(
      "/v1",
      "/report/finalAbstracts",
      { data: _payload },
      OApiRespReportResultDtoModel,
      {
        params: dashboardUid ? { dashboardUid } : {},
      }
    );
  }

  getReportsList(): Observable<
    | OApiRespMultiMediumReportQueryViewModel
    | OApiRespMultiMinimalReportQueryViewModel
    | OApiRespMultiFullReportQueryViewModel
  > {
    return this.restClient.sendGetRequest<
      | OApiRespMultiMediumReportQueryViewModel
      | OApiRespMultiMinimalReportQueryViewModel
      | OApiRespMultiFullReportQueryViewModel
    >(
      "/v1",
      "/report/finalAbstracts/query",
      OApiRespMultiMediumReportQueryViewModel,
      {
        params: {
          view: "medium",
          page: 1,
          perPage: 9000,
        },
      }
    );
  }

  getReportByIdssss({ dashboardUid, reportId }): Observable<any> {
    if (dashboardUid) {
      return this.restClient.sendGetRequestNoView(
        "/v1",
        `/report/finalAbstracts/query/${reportId}`,
        {
          params: {
            view: "full",
            dashboardUid,
            [RestClient.HTTP_CONTEXT_PARAM_KEY]: {
              doNotNotifyNotFoundError: true,
            },
          },
        }
      );
    } else {
      return this.restClient.sendGetRequestNoView(
        "/v1",
        `/report/finalAbstracts/query/${reportId}`,
        {
          params: {
            view: "full",
          },
        }
      );
    }
  }

  getReportById({
    dashboardUid,
    reportId,
  }): Observable<OApiRespFullReportQueryViewModel> {
    if (dashboardUid) {
      return this.restClient.sendGetRequest<OApiRespFullReportQueryViewModel>(
        "/v1",
        `/report/finalAbstracts/query/${reportId}`,
        OApiRespFullReportQueryViewModel,
        {
          params: {
            view: "full",
            dashboardUid,
            [RestClient.HTTP_CONTEXT_PARAM_KEY]: {
              doNotNotifyNotFoundError: true,
            },
          },
        }
      );
    } else {
      return this.restClient.sendGetRequest<OApiRespFullReportQueryViewModel>(
        "/v1",
        `/report/finalAbstracts/query/${reportId}`,
        OApiRespFullReportQueryViewModel,
        {
          params: {
            view: "full",
          },
        }
      );
    }
  }

  deleteReportById(
    id: string
  ): Observable<
    | OApiRespMediumReportQueryViewModel
    | OApiRespMinimalReportQueryViewModel
    | OApiRespFullReportQueryViewModel
  > {
    return this.restClient.sendDeleteRequest<
      | OApiRespMediumReportQueryViewModel
      | OApiRespMinimalReportQueryViewModel
      | OApiRespFullReportQueryViewModel
    >(
      "/v1",
      `/report/finalAbstracts/query/${id}`,
      OApiRespMinimalReportQueryViewModel,
      {
        params: {
          view: "minimal",
        },
      }
    );
  }

  exportReportById(id: string, filename: string, cb?): void {
    this.fileApiService.sendExportOneRequestNew(
      "/v1",
      "/report/finalAbstracts/export",
      id,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      filename,
      false
    );
    if (cb) {
      cb();
    }
  }

  saveReport(
    payload
  ): Observable<
    | OApiRespMediumReportQueryViewModel
    | OApiRespMinimalReportQueryViewModel
    | OApiRespFullReportQueryViewModel
  > {
    return this.restClient.sendPostRequest<
      | OApiRespMediumReportQueryViewModel
      | OApiRespMinimalReportQueryViewModel
      | OApiRespFullReportQueryViewModel
    >(
      "/v1",
      "/report/finalAbstracts/query",
      payload,
      OApiRespMediumReportQueryViewModel ||
        OApiRespMinimalReportQueryViewModel ||
        OApiRespFullReportQueryViewModel
    );
  }

  updateReportById(
    id: string,
    payload: any,
    replace: boolean
  ): Observable<
    | OApiRespMediumReportQueryViewModel
    | OApiRespMinimalReportQueryViewModel
    | OApiRespFullReportQueryViewModel
  > {
    const _payload = {
      returnParams: {
        view: "medium",
      },
      replace: replace,
      data: payload,
    };
    return this.restClient.sendPutRequest<
      | OApiRespMediumReportQueryViewModel
      | OApiRespMinimalReportQueryViewModel
      | OApiRespFullReportQueryViewModel
    >(
      "/v1",
      `/report/finalAbstracts/query/${id}`,
      _payload,
      OApiRespMediumReportQueryViewModel ||
        OApiRespMinimalReportQueryViewModel ||
        OApiRespFullReportQueryViewModel
    );
  }

  deleteReportsByIds(
    ids: string[]
  ): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.restClient.sendPostRequest<OApiSingleEntityNoDataResponseModel>(
      "/v1",
      `/deleteBatch/report/finalAbstracts/query`,
      {
        data: {
          objectUids: ids,
        },
        returnParams: {
          view: "minimal",
        },
      },
      OApiSingleEntityNoDataResponseModel
    );
  }

  getPermissionForCurrentUser(): Observable<string[]> {
    return this.restClient
      .sendGetRequestNoView("/v1", `/report/reportUid/currentUser/permission`)
      .pipe(map((resp: any) => resp.data));
  }

  getAggregationsTitleMap() {
    return {
      UNIQUE_COUNT: "Unique Count",
      MAX: "Maximum",
      MIN: "Minimum",
      AVG: "Average",
      SUM: "Sum",
      COUNT: "Count",
      RECORDS_COUNT: "Records Count",
    };
  }

  convertToFilterFieldsGroups(
    filterFields: {
      fieldName: string;
      fieldType: string;
      uiName: string;
      category: string;
      docSetCategories: string[];
    }[]
  ) {
    const keyToFilterFieldGroups: Map<string, ReportFilterFieldsGroupModel> =
      new Map<string, ReportFilterFieldsGroupModel>();
    for (const filteredField of filterFields) {
      for (const docSetCategory of filteredField.docSetCategories) {
        let filterFieldGroup = keyToFilterFieldGroups.get(docSetCategory);
        if (!filterFieldGroup) {
          filterFieldGroup = {
            title:
              docSetCategory === ReportsService.GENERAL_DOC_SET_CATEGORY__VALUE
                ? ReportsService.GENERAL_DOC_SET_CATEGORY__TITLE
                : this.serverConfigsService.getDocSetCategoryView(
                    docSetCategory
                  ).uiName,
            value: docSetCategory,
            fields: [],
          };
          keyToFilterFieldGroups.set(docSetCategory, filterFieldGroup);
        }
        filterFieldGroup.fields.push({
          ...filteredField,
          fieldName: filteredField.fieldName,
          fieldType: filteredField.fieldType,
          uiName: filteredField.uiName,
          category: filteredField.category,
        });
      }
    }
    const result = Array.from(keyToFilterFieldGroups.values());
    // GENERAL docSetCategory should be first and then sort ascending by docSetCategory's name
    result.sort((o1, o2) => {
      if (o1.title === o2.title) {
        return 0;
      }
      if (o1.title === ReportsService.GENERAL_DOC_SET_CATEGORY__TITLE) {
        return -1;
      }
      if (o2.title === ReportsService.GENERAL_DOC_SET_CATEGORY__TITLE) {
        return 1;
      }
      return o1.title > o2.title ? 1 : -1;
    });

    // for each docSetCategory sort fields by name
    result.forEach((item) =>
      item.fields.sort((o1, o2) =>
        o1.uiName === o2.uiName ? 0 : o1.uiName > o2.uiName ? 1 : -1
      )
    );

    return result;
  }
}
