import { JsonObject, JsonProperty } from "json2typescript";
import { FullValMultiProvisionValueModel } from "@@intelease/web/common/models/multi-provision-value/full-val-multi-provision-value.model";

@JsonObject("ProvisionGroupNewModel")
export class ProvisionGroupNewModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("version", Number)
  version = 0;

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("provisions", [FullValMultiProvisionValueModel])
  provisions: FullValMultiProvisionValueModel[] = [];

  isOpen = true;
}
