import { Component, EventEmitter, Input, Output } from "@angular/core";
import { cloneDeep, first, last } from "lodash";
import {
  MyFile,
  UploadedDocSetModel,
} from "@@intelease/web/ui/src/lib/itls-new-upload";
import { NewUploadService } from "@@intelease/web/ui/src/lib/itls-new-upload/services/new-upload.service";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";

@Component({
  selector: "il-files-upload",
  templateUrl: "./files-upload.component.html",
  styleUrls: ["./files-upload.component.scss"],
})
export class FilesUploadComponent {
  files: File[] = [];
  validComboDrag = false;
  invalidComboDrag = false;
  @Input() verticalView = true;

  @Input()
  accept: string;

  @Input()
  acceptDirectory: string;

  /**
   * maxFileSize
   * @description maximum size of file to add for upload; size is in bytes
   */
  maxFileSize = 131_072_000; // 125 mb
  // maxFileSize = 45728640 // 45mb
  @Output() nextSlide: EventEmitter<any> = new EventEmitter();

  isDragOver = false;
  isUploadingFolder = false;
  @Output()
  uploadingFolderEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  uploadedDocSets: UploadedDocSetModel[];
  @Output()
  modelChanged: EventEmitter<UploadedDocSetModel[]> = new EventEmitter<
    UploadedDocSetModel[]
  >();

  constructor(
    private newUploadService: NewUploadService,
    private messageService: MessageService
  ) {}

  onDragOver(evt) {
    this.isDragOver = true;
  }

  onFilesChanged() {
    if (this.newUploadService.isFolderSelected(this.files)) {
      this.uploadedDocSets = [];
      for (const file of this.files) {
        if (file.size < this.maxFileSize) {
          const fullPath = NewUploadService.getRelativePath(file);
          const fileNameInPathIndex = fullPath.lastIndexOf("/");
          if (fileNameInPathIndex === -1) {
            throw new Error(
              `cannot find file's name in full path! (` + fullPath + ")"
            );
          }
          const folderPath = `${fullPath.substring(0, fileNameInPathIndex)}`;
          const uploadedDocSet = new UploadedDocSetModel(
            folderPath,
            [],
            NewUploadService.getFilenameWithoutExtension(file),
            [],
            null
          );
          uploadedDocSet.pickDefaultNameIfEmpty();
          this.updateDocumentReorderingEvents(uploadedDocSet);
          const myFile = new MyFile(
            file,
            NewUploadService.getFilenameWithoutExtension(file),
            1,
            true
          );
          uploadedDocSet.myFiles.push(myFile);
          this.uploadedDocSets.push(uploadedDocSet);
          this.updateProgressForFile(myFile);
        } else {
          this.showFileSizeError();
        }
      }

      this.isUploadingFolder = true;
      this.uploadingFolderEvent.emit(this.isUploadingFolder);
      this.modelChanged.emit([...this.uploadedDocSets]);
      this.files = [];
    } else {
      if (first(this.files).size < this.maxFileSize) {
        // if folder was selected previously, clear it's states
        if (!this.uploadedDocSets?.length) {
          this.uploadedDocSets = [
            new UploadedDocSetModel(
              UploadedDocSetModel.FILES__FOLDER_PATH,
              [],
              "",
              [],
              null
            ),
          ];
          this.isUploadingFolder = false;
          this.uploadingFolderEvent.emit(this.isUploadingFolder);
        }
        const uploadedDocSet = last(this.uploadedDocSets);
        for (const file of this.files) {
          const myFile = new MyFile(
            file,
            NewUploadService.getFilenameWithoutExtension(file),
            1,
            true
          );
          uploadedDocSet.myFiles.push(myFile);
          this.updateProgressForFile(myFile);
        }

        for (const uploadedDocSet of this.uploadedDocSets) {
          uploadedDocSet.pickDefaultNameIfEmpty();
          this.updateDocumentReorderingEvents(uploadedDocSet);
        }
        this.modelChanged.emit([...this.uploadedDocSets]);
        // this is backed by the html element, we use this variable to only capture CHANGES not the whole state
        this.files = [];
      } else {
        this.showFileSizeError();
        this.files = [];
      }
    }
  }

  showFileSizeError() {
    this.messageService.error(`You can't upload document more than 45MB.`, {
      Duration: 3_000,
    });
  }

  onDragLeave(evt) {
    this.isDragOver = false;
  }

  onDrop(evt) {
    this.isDragOver = false;
  }

  reset() {
    this.uploadedDocSets = undefined;
    this.modelChanged.emit(this.uploadedDocSets || []);
  }

  private updateProgressForFile(myFile: MyFile) {
    if (myFile.progress < 100) {
      myFile.progress += Math.random() * 25 + 1;
      setTimeout(() => this.updateProgressForFile(myFile), 400);
    } else {
      myFile.inProgress = false;
    }
  }

  private updateDocumentReorderingEvents(
    uploadedDocSetModel: UploadedDocSetModel
  ) {
    const items = uploadedDocSetModel.myFiles;
    const obj = {
      pre: uploadedDocSetModel.lastDocumentReorderingEvent
        ? uploadedDocSetModel.lastDocumentReorderingEvent.post
        : items,
      post: items,
    };
    uploadedDocSetModel.lastDocumentReorderingEvent = cloneDeep(obj);
    uploadedDocSetModel.documentReorderingEvents.push(cloneDeep(obj));
  }

  onSelectFolderClicked(
    event: MouseEvent,
    selectFolderInput: HTMLInputElement
  ) {
    event.stopPropagation();
    selectFolderInput.click();
  }

  onAllFilesRemoved(uploadedDocSet: UploadedDocSetModel) {
    const index = this.uploadedDocSets.findIndex(
      (item) => item.name === uploadedDocSet.name
    );
    this.uploadedDocSets.splice(index, 1);
    if (!this.uploadedDocSets.length) {
      this.uploadedDocSets = undefined;
      this.modelChanged.emit(this.uploadedDocSets || []);
    } else {
      this.modelChanged.emit([...this.uploadedDocSets]);
    }
  }

  getDocumentCounts(uploadedDocSets: UploadedDocSetModel[]) {
    return uploadedDocSets
      .map((item) => item.myFiles.length)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
  }
}
