<ng-container *ngIf="!item.hidden">
  <ng-container *ngxPermissionsOnly="expectedAuthorities">
    <!-- normal collapse -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="!item.url && !item.function"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.url -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && !item.externalUrl && !item.function"
      [routerLink]="item.url"
      [routerLinkActive]="['active', 'accent']"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && item.externalUrl && !item.function"
      [href]="item.url"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="!item.url && item.function"
      (click)="item.function()"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && !item.externalUrl && item.function"
      (click)="item.function()"
      [routerLink]="item.url"
      [routerLinkActive]="['active', 'accent']"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && item.externalUrl && item.function"
      (click)="item.function()"
      [href]="item.url"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
      <mat-icon class="nav-link-icon" *ngIf="item.icon">{{
        item.icon
      }}</mat-icon>
      <span class="nav-link-title">{{ item.title }}</span>
      <span
        class="nav-link-badge"
        *ngIf="item.badge"
        [ngStyle]="{
          'background-color': item.badge.bg,
          color: item.badge.fg
        }"
      >
        {{ item.badge.title }}
      </span>
      <mat-icon class="collapsable-arrow">keyboard_arrow_right</mat-icon>
    </ng-template>

    <div class="children" [ngClass]="{ open: isOpen }">
      <div class="{{ tbConfig.layout.navbar.primaryBackground }}">
        <ng-container *ngFor="let item of item.children">
          <itls-nav-horizontal-item
            *ngIf="item.type == 'item'"
            [item]="item"
          ></itls-nav-horizontal-item>
          <itls-nav-horizontal-collapsable
            *ngIf="item.type == 'collapsable'"
            [item]="item"
          ></itls-nav-horizontal-collapsable>
          <itls-nav-horizontal-collapsable
            *ngIf="item.type == 'group'"
            [item]="item"
          ></itls-nav-horizontal-collapsable>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
