/* tslint:disable */
/* eslint-disable */
import { DomainIdDtoModel } from "./domain-id-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EditQueueAssigneeConfigDtoModel")
export class EditQueueAssigneeConfigDtoModel {
  @JsonProperty("isQaReviewAssigneeChanged", Boolean)
  isQaReviewAssigneeChanged: boolean = undefined;

  @JsonProperty("isReviewAssigneeChanged", Boolean)
  isReviewAssigneeChanged: boolean = undefined;

  @JsonProperty("qaReviewAssigneeId", DomainIdDtoModel, true)
  qaReviewAssigneeId?: DomainIdDtoModel = undefined;

  @JsonProperty("reviewAssigneeId", DomainIdDtoModel, true)
  reviewAssigneeId?: DomainIdDtoModel = undefined;
}
