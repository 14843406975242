/* tslint:disable */
/* eslint-disable */
import { UserDtoModel } from "./user-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SimpleQueueDtoModel")
export class SimpleQueueDtoModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdBy", UserDtoModel, true)
  createdBy?: UserDtoModel = undefined;

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("permissionCategory", Any, true)
  permissionCategory?: "PRIVATE" | "PUBLIC" | "VIEWABLE" = undefined;

  @JsonProperty("status", Any, true)
  status?: "ACTIVE" | "PAUSE" | "DEACTIVE" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("updatedAt", DateTimeConvertService, true)
  updatedAt?: Date | undefined = undefined;
}
