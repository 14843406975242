/* tslint:disable */
/* eslint-disable */
import { CheckPermissionRequestDtoModel } from "./check-permission-request-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCheckPermissionRequestDtoModel")
export class OApiReqCheckPermissionRequestDtoModel {
  @JsonProperty("data", CheckPermissionRequestDtoModel, true)
  data?: CheckPermissionRequestDtoModel = undefined;
}
