import { CommonAction, CommonActionTypes } from "./common.actions";
import { CustomTagViewModel } from "@@intelease/api-models/adex-api-model-src";

export const COMMON_FEATURE_KEY = "common";

/**
 * Interface for the 'Common' data used in
 *  - CommonState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* eslint-disable-next-line */
export interface Entity {}

export interface CommonState {
  list: Entity[]; // list of Common; analogous to a sql normalized table
  selectedId?: string | number; // which Common record has been selected
  loaded: boolean; // has the Common list been loaded
  error?: any; // last none error (if any)
  provisionsMetadata?: any;
  isLoadProvisionsMetadataLoading?: boolean;
  provisionsMetadataLoaded?: boolean;
  provisionsMetadataLoadFailed?: any;

  isLoadAccountSettingsPending?: boolean;
  isAccountSettingsLoaded?: boolean;
  accountSettingsLoadError?: any;
  accountSettings?: any;

  isUpdateAccountSettingsPending?: boolean;
  isUpdateAccountSettingsSucceeded?: boolean;
  updateAccountSettingsError?: any;

  isLoadAccountCustomTagsPending?: boolean;
  isAccountCustomTagsLoaded?: boolean;
  accountCustomTagsLoadError?: any;
  accountCustomTags: CustomTagViewModel[];
}

export interface CommonPartialState {
  readonly [COMMON_FEATURE_KEY]: CommonState;
}

export const initialState: CommonState = {
  list: [],
  loaded: false,
  accountCustomTags: [],
};

export function reducer(
  state: CommonState = initialState,
  action: CommonAction
): CommonState {
  switch (action.type) {
    case CommonActionTypes.CommonLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true,
      };
      break;
    }
    case CommonActionTypes.LoadProvisionsMetadata: {
      state = {
        ...state,
        provisionsMetadata: undefined,
        isLoadProvisionsMetadataLoading: true,
        provisionsMetadataLoaded: false,
        provisionsMetadataLoadFailed: undefined,
      };
      break;
    }
    case CommonActionTypes.ProvisionsMetadataLoaded: {
      state = {
        ...state,
        provisionsMetadata: action.payload,
        isLoadProvisionsMetadataLoading: false,
        provisionsMetadataLoaded: true,
        provisionsMetadataLoadFailed: undefined,
      };
      break;
    }
    case CommonActionTypes.ProvisionsMetadataLoadError: {
      state = {
        ...state,
        isLoadProvisionsMetadataLoading: false,
        provisionsMetadataLoaded: false,
        provisionsMetadataLoadFailed: action.payload,
      };
      break;
    }
    case CommonActionTypes.LoadAccountSettings: {
      state = {
        ...state,
        isLoadAccountSettingsPending: true,
        isAccountSettingsLoaded: false,
        accountSettingsLoadError: undefined,
        accountSettings: undefined,
      };
      break;
    }
    case CommonActionTypes.AccountSettingsLoaded: {
      state = {
        ...state,
        isLoadAccountSettingsPending: false,
        isAccountSettingsLoaded: true,
        accountSettingsLoadError: undefined,
        accountSettings: action.payload,
      };
      break;
    }
    case CommonActionTypes.AccountSettingsLoadError: {
      state = {
        ...state,
        isLoadAccountSettingsPending: false,
        isAccountSettingsLoaded: false,
        accountSettingsLoadError: action.payload,
        accountSettings: undefined,
      };
      break;
    }
    case CommonActionTypes.UpdateAccountSettings: {
      state = {
        ...state,
        isUpdateAccountSettingsPending: true,
        isUpdateAccountSettingsSucceeded: false,
        updateAccountSettingsError: undefined,
      };
      break;
    }
    case CommonActionTypes.UpdateAccountSettingsSucceeded: {
      state = {
        ...state,
        isUpdateAccountSettingsPending: false,
        isUpdateAccountSettingsSucceeded: true,
        updateAccountSettingsError: undefined,
      };
      break;
    }
    case CommonActionTypes.UpdateAccountSettingsFailed: {
      state = {
        ...state,
        isUpdateAccountSettingsPending: false,
        isUpdateAccountSettingsSucceeded: false,
        updateAccountSettingsError: action.payload,
      };
      break;
    }
    case CommonActionTypes.LoadAccountCustomTags: {
      state = {
        ...state,
        isLoadAccountCustomTagsPending: true,
        isAccountCustomTagsLoaded: false,
        accountCustomTagsLoadError: undefined,
        accountCustomTags: undefined,
      };
      break;
    }
    case CommonActionTypes.AccountCustomTagsLoaded: {
      state = {
        ...state,
        isLoadAccountCustomTagsPending: false,
        isAccountCustomTagsLoaded: true,
        accountCustomTagsLoadError: undefined,
        accountCustomTags: action.payload,
      };
      break;
    }
    case CommonActionTypes.AccountCustomTagsLoadError: {
      state = {
        ...state,
        isLoadAccountCustomTagsPending: false,
        isAccountCustomTagsLoaded: false,
        accountCustomTagsLoadError: action.payload,
        accountCustomTags: undefined,
      };
      break;
    }
  }
  return state;
}
