import { JsonObject, JsonProperty } from "json2typescript";
import { SearchSuggestionItemCategoryModel } from "@@intelease/web/common/models/search/search-suggestion-item-category.model";

@JsonObject("SearchSuggestionItemModel")
export class SearchSuggestionItemModel {
  static view = "full";

  @JsonProperty("uid", String, true)
  uid = "";

  @JsonProperty("portfolio", String)
  portfolio = "";

  @JsonProperty("property", String)
  property = "";

  @JsonProperty("suite", String)
  suite = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("userEmail", String)
  userEmail = "";

  @JsonProperty("userFullName", String)
  userFullName = "";

  @JsonProperty("fileNames", [String])
  fileNames: string[] = [];

  @JsonProperty("searchCategories", [SearchSuggestionItemCategoryModel])
  searchCategories: SearchSuggestionItemCategoryModel[] = [];
}
