import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ProvisionModel")
export class ProvisionModel {
  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("value", String)
  value = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("type", String)
  type = "";

  @JsonProperty("uid", String)
  uid = "";
}
