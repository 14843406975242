/* tslint:disable */
/* eslint-disable */
import { RequestAccessDtoModel } from "./request-access-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqRequestAccessDtoModel")
export class OApiReqRequestAccessDtoModel {
  @JsonProperty("data", RequestAccessDtoModel, true)
  data?: RequestAccessDtoModel = undefined;
}
