/* tslint:disable */
/* eslint-disable */
import { CancelUploadingFilesRequestDtoModel } from "./cancel-uploading-files-request-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCancelUploadingFilesRequestDtoModel")
export class OApiReqCancelUploadingFilesRequestDtoModel {
  @JsonProperty("data", CancelUploadingFilesRequestDtoModel, true)
  data?: CancelUploadingFilesRequestDtoModel = undefined;
}
