/* tslint:disable */
/* eslint-disable */
import { FullStateViewModel } from "./full-state-view-model";
import { NestedGroupProvisionSubfieldModel } from "./nested-group-provision-subfield-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MinimalProvisionViewModel")
export class MinimalProvisionViewModel {
  /**
   * the possible formats for this provision
   */

  @JsonProperty("allowedTypes", Any, true)
  allowedTypes?: Array<
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN"
  > = [];

  /**
   * the possible aliases for each possible value for this provision
   */

  @JsonProperty("categoryAliases", Any, true)
  categoryAliases?: {
    [key: string]: Array<string>;
  } = {};

  /**
   * the default headers for the table value for this provision
   */

  @JsonProperty("defaultTableHeaders", [String], true)
  defaultTableHeaders?: Array<string> = [];

  /**
   * the provision description
   */

  @JsonProperty("description", String, true)
  description?: string = "";

  /**
   * the scope of this provision
   */

  @JsonProperty("entityScope", Any, true)
  entityScope?: "GLOBAL" | "ACCOUNT" | "USER" | "ABSTRACT" = undefined;

  /**
   * the type of datapoint this field is
   */

  @JsonProperty("fieldType", Any, true)
  fieldType?: "DEFAULT" | "MANUAL" = undefined;

  /**
   * the unique provision human-readable id
   */

  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  /**
   * the key to use for the model for this nested group provision
   */

  @JsonProperty("modelKey", String, true)
  modelKey?: string = "";

  /**
   * the provision name
   */

  @JsonProperty("name", String, true)
  name?: string = "";

  /**
   * the formats of this nested group provision
   */

  @JsonProperty("nestedGroupFormats", Any, true)
  nestedGroupFormats?: Array<"ADDRESS" | "OTHER"> = [];

  /**
   * the subfields for this nested group provision
   */

  @JsonProperty(
    "nestedGroupSubfields",
    [NestedGroupProvisionSubfieldModel],
    true
  )
  nestedGroupSubfields?: Array<NestedGroupProvisionSubfieldModel> = [];

  /**
   * the processing mode for this nested group provision
   */

  @JsonProperty("nestedProcessingMode", Any, true)
  nestedProcessingMode?: "FLEXIBLE" | "STRICT" = undefined;

  /**
   * the category of units for a numerical values
   */

  @JsonProperty("numericalUnitCategory", Any, true)
  numericalUnitCategory?:
    | "TIME"
    | "AREA"
    | "VELOCITY"
    | "MONEY"
    | "RATIO"
    | "DISTANCE"
    | "ACCELERATION"
    | "MASS"
    | "VOLUME"
    | "DENSITY"
    | "ELECTRIC_POTENTIAL"
    | "ELECTRIC_CURRENT"
    | "PRESSURE"
    | "TEMPERATURE"
    | "NONE" = undefined;

  /**
   * a value for this provision can only have one of these possible countries
   */

  @JsonProperty("possibleCountries", [String], true)
  possibleCountries?: Array<string> = [];

  /**
   * a value for this provision can only have one of these possible currencies
   */

  @JsonProperty("possibleCurrencies", [String], true)
  possibleCurrencies?: Array<string> = [];

  /**
   * a value for this provision can only have one of these possible states
   */

  @JsonProperty("possibleFullStates", [FullStateViewModel], true)
  possibleFullStates?: Array<FullStateViewModel> = [];

  /**
   * a value for this provision can only have one of these possible states
   */

  @JsonProperty("possibleStates", [String], true)
  possibleStates?: Array<string> = [];

  /**
   * a value for this provision can only have one of these possible units
   */

  @JsonProperty("possibleUnits", [String], true)
  possibleUnits?: Array<string> = [];

  /**
   * the possible values for this provision
   */

  @JsonProperty("possibleValues", [String], true)
  possibleValues?: Array<string> = [];

  /**
   * the type of the name value this provision has
   */

  @JsonProperty("properNounType", Any, true)
  properNounType?:
    | "ENTITY"
    | "PLACE"
    | "HOLIDAY"
    | "TITLE_OF_WORK"
    | "ID"
    | "UNKNOWN"
    | "CONSUMER_GOOD"
    | "PERSON"
    | "ORGANIZATION"
    | "LOCATION"
    | "WORK_OF_ART"
    | "EVENT"
    | "OTHER"
    | "KEYWORD"
    | "DOCUMENT_NAME" = undefined;

  /**
   * whether this provision is a singleton
   */

  @JsonProperty("singleton", Boolean, true)
  singleton?: boolean = undefined;

  /**
   * the user-facing unique sub-provision ids
   */

  @JsonProperty("subProvisionUids", [String], true)
  subProvisionUids?: Array<string> = [];

  /**
   * the format of a structured value
   */

  @JsonProperty("type", Any, true)
  type?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;

  /**
   * the user-facing unique provision id
   */

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
