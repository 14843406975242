/* tslint:disable */
/* eslint-disable */
import { FieldFilterInterfaceModel } from "./field-filter-interface-model";
import { NamedSearchFieldModel } from "./named-search-field-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocQueryModel")
export class DocQueryModel {
  @JsonProperty("filters", [FieldFilterInterfaceModel], true)
  filters?: Array<FieldFilterInterfaceModel> = [];

  @JsonProperty("viewCols", [NamedSearchFieldModel], true)
  viewCols?: Array<NamedSearchFieldModel> = [];
}
