import { environment } from "../../../../apps/ui/src/environments/environment";
import { isElectron } from "@@intelease/web/utils";
// import {remote, screen} from 'electron';
// import * as url from "url";
// import * as path from "path";

// const BrowserWindow = remote.BrowserWindow;
// const sizes = screen.getPrimaryDisplay().workAreaSize;

export function openInNewWindow(url: string) {
  if (environment.isBrowserEnv) {
    window.open(`.${url}`, "_blank");
  } else {
    if (isElectron()) {
      // openNewElectronWindow(url)
      window.open(`#${url}`, "_blank");
    } else {
      window.open(`#${url}`, "_blank");
    }
  }
}

// const openNewElectronWindow = (_url: string) => {
//   let launchPath: string = "";
//   let win = new BrowserWindow({
//     // width: 800,
//     width: sizes.width,
//     // height: 600,
//     height: sizes.height,
//     center: true,
//     resizable: true,
//     frame: true,
//     transparent: false
//   });
//   if (!environment.production) {
//     launchPath = `http://localhost:4200/#${_url}`;
//     win.loadURL(launchPath);
//   } else {
//     console.log((__dirname+ `index.html#${_url}`));
//     launchPath = url.format({
//       pathname: path.join(__dirname, `index.html#${_url}`),
//       protocol: "file:",
//       slashes: true
//     });
//     win.loadURL(launchPath);
//     win.webContents.openDevTools();
//   }
// };
