/* tslint:disable */
/* eslint-disable */
import { EditQueueAssigneeConfigDtoModel } from "./edit-queue-assignee-config-dto-model";
import { EditQueueProcessingConfigDtoModel } from "./edit-queue-processing-config-dto-model";
import { QueueRecordSummaryConfigDtoModel } from "./queue-record-summary-config-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EditQueueDtoModel")
export class EditQueueDtoModel {
  @JsonProperty("assigneeConfig", EditQueueAssigneeConfigDtoModel, true)
  assigneeConfig?: EditQueueAssigneeConfigDtoModel = undefined;

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("isDescriptionChanged", Boolean)
  isDescriptionChanged: boolean = undefined;

  @JsonProperty("isNameChanged", Boolean)
  isNameChanged: boolean = undefined;

  @JsonProperty("isPermissionCategoryChanged", Boolean)
  isPermissionCategoryChanged: boolean = undefined;

  @JsonProperty("isStatusChanged", Boolean)
  isStatusChanged: boolean = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("permissionCategory", Any, true)
  permissionCategory?: "PRIVATE" | "PUBLIC" | "VIEWABLE" = undefined;

  @JsonProperty("processingConfig", EditQueueProcessingConfigDtoModel, true)
  processingConfig?: EditQueueProcessingConfigDtoModel = undefined;

  @JsonProperty(
    "recordSummaryConfigDTO",
    QueueRecordSummaryConfigDtoModel,
    true
  )
  recordSummaryConfigDTO?: QueueRecordSummaryConfigDtoModel = undefined;

  @JsonProperty("status", Any, true)
  status?: "ACTIVE" | "PAUSE" | "DEACTIVE" = undefined;
}
