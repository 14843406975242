/* tslint:disable */
/* eslint-disable */
import { FullValMultiPdfProvViewModel } from "./full-val-multi-pdf-prov-view-model";
import { GeneralPdfMentionHighlightDtoModel } from "./general-pdf-mention-highlight-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MultiPdfProvViewAndHighlightInfoModel")
export class MultiPdfProvViewAndHighlightInfoModel {
  @JsonProperty("pdfHighlights", [GeneralPdfMentionHighlightDtoModel], true)
  pdfHighlights?: Array<GeneralPdfMentionHighlightDtoModel> = [];

  @JsonProperty("realValue", Boolean, true)
  realValue?: boolean = undefined;

  @JsonProperty("removedHighlightIds", [String], true)
  removedHighlightIds?: Array<string> = [];

  @JsonProperty("view", FullValMultiPdfProvViewModel, true)
  view?: FullValMultiPdfProvViewModel = undefined;
}
