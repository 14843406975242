/* tslint:disable */
/* eslint-disable */
import { JsonNodeModel } from "./json-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SaveManualMentionApiDtoModel")
export class SaveManualMentionApiDtoModel {
  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  @JsonProperty("notes", String, true)
  notes?: string = "";

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";

  @JsonProperty("provisionValue", Any, true)
  provisionValue?: any = undefined;

  @JsonProperty("sectionHeader", String, true)
  sectionHeader?: string = "";

  /**
   * the format of a structured value
   */

  @JsonProperty("type", Any, true)
  type?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;
}
