/* tslint:disable */
/* eslint-disable */
import { ProjectDtoModel } from "./project-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqProjectDtoModel")
export class OApiReqProjectDtoModel {
  @JsonProperty("data", ProjectDtoModel, true)
  data?: ProjectDtoModel = undefined;
}
