/* tslint:disable */
/* eslint-disable */
import { ProvisionReviewerViewModel } from "./provision-reviewer-view-model";

/**
 * provision review completion info
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionReviewCompletionInfoViewModel")
export class ProvisionReviewCompletionInfoViewModel {
  /**
   * Timestamp when the provision was marked as reviewed
   */

  @JsonProperty("completedAt", DateTimeConvertService, true)
  completedAt?: Date | undefined = undefined;

  @JsonProperty("reviewer", ProvisionReviewerViewModel, true)
  reviewer?: ProvisionReviewerViewModel = undefined;
}
