import { Injectable } from "@angular/core";
import { DateUtil, ServiceInjectorHelper } from "@@intelease/web/utils";
import { cloneDeep, without } from "lodash";
import { UcWordsPipe } from "ngx-pipes";
import { DateConvertService, DateTimeConvertService } from "@common/services";

@Injectable({
  providedIn: "root",
})
export class ItlsFilterService {
  public static serializeColumns(data) {
    const columns = {
      generalViewColumns: [],
      provisionViewColumns: [],
    };
    data.map((item) => {
      if (item.category === "GENERAL") {
        columns.generalViewColumns.push(
          `${item.fieldName}::${item.uiName}::${item.fieldType}`
        );
      } else if (item.category === "PROVISION") {
        columns.provisionViewColumns.push(
          `${item.fieldName}::${item.uiName}::${item.fieldType}`
        );
      }
    });
    return columns;
  }

  public static deSerializeColumns(columns: any) {
    const _columns = [];
    const colList = ["fieldName", "uiName", "fieldType"];
    columns.generalViewColumns.map((itemCol) => {
      const colObj = {
        category: "GENERAL",
      };
      itemCol.split("::").forEach((item, index) => {
        colObj[colList[index]] = item;
      });
      _columns.push(colObj);
    });
    columns.provisionViewColumns.map((itemCol) => {
      const colObj = {
        category: "PROVISION",
      };
      itemCol.split("::").forEach((item, index) => {
        colObj[colList[index]] = item;
      });
      _columns.push(colObj);
    });
    return _columns;
  }

  public static serializeFilter(data) {
    if (data && data.operator) {
      data.value = data.value.map((item) => {
        if (!item.operator) {
          const { field, value, relation, nestedFields } = item;
          if (
            field &&
            relation &&
            (!nestedFields || nestedFields.length === 0)
          ) {
            return {
              value: this.serializeValue(field.fieldType, value),
              relation: relation,
              field: `${field.fieldName}::${field.uiName}::${field.fieldType}`,
              category: field.category,
            };
          } else if (
            field &&
            relation &&
            nestedFields &&
            nestedFields.length > 0
          ) {
            return {
              nestedFields,
              relation: relation,
              field: `${field.fieldName}::${field.uiName}::${field.fieldType}`,
              category: field.category,
            };
          }
        } else {
          return this.serializeFilter(cloneDeep(item));
        }
      });
      data.value = without(data.value, undefined);
    }
    return data;
  }

  private static serializeValue(fieldType: any, value: any) {
    if (!value) return;
    switch (fieldType) {
      case "DATE":
        return DateTimeConvertService.serializeDateTime(value);
      case "LOCAL_DATE":
        return DateConvertService.serializeDate(value);
      case "DOUBLE":
        return {
          value: value.value,
          unit: {
            value: value.unit,
          },
        };
      case "MONEY":
        return {
          value: value.value,
          currency: {
            value: value.currency,
          },
        };
      case "EMAIL":
        const parts = value.split("@");
        return {
          localPart: parts[0],
          domain: parts[1],
        };
      case "ADDRESS":
        const data = { ...value };
        if (value && value.country) {
          data["country"] = ServiceInjectorHelper.instance
            .get(UcWordsPipe)
            .transform(value.country.value);
        }
        if (value && value.state) {
          data["state"] = ServiceInjectorHelper.instance
            .get(UcWordsPipe)
            .transform(value.state.value);
        }
        return data;
      default:
        return value;
    }
  }

  public static deSerializeFilter(data) {
    const colList = ["fieldName", "uiName", "fieldType"];
    if (data && data.operator) {
      data.value = data.value.map((item) => {
        if (!item.operator) {
          const { field, value, relation, category, nestedFields } = item;
          const colObj = {
            category,
          };
          field.split("::").forEach((_item, index) => {
            colObj[colList[index]] = _item;
          });
          colObj["category"] = category;
          return {
            value: this.deSerializeValue(colObj["fieldType"], value),
            relation: relation,
            field: colObj,
            category,
            nestedFields,
          };
        } else {
          return this.deSerializeFilter(cloneDeep(item));
        }
      });
    }
    return data;
  }

  private static deSerializeValue(fieldType: any, value: any) {
    if (!value) return;
    switch (fieldType) {
      case "DATE":
        // return value
        return DateTimeConvertService.deserializeDateTime(value);
      case "LOCAL_DATE":
        return DateConvertService.deserializeDate(value);
      case "DOUBLE":
        return {
          value: value.value,
          unit: value.unit.value,
        };
      case "MONEY":
        return {
          value: value.value,
          currency: value.currency.value,
        };
      case "EMAIL":
        return value.localPart + "@" + value.domain;
      case "ADDRESS":
        const data = { ...value };
        if (value && value.country) {
          const country = ServiceInjectorHelper.instance
            .get(UcWordsPipe)
            .transform(value.country);
          data["country"] = {
            title: country,
            value: country,
          };
        }
        if (value && value.state) {
          const state = ServiceInjectorHelper.instance
            .get(UcWordsPipe)
            .transform(value.state);
          data["state"] = { title: state, value: state };
        }
        return data;
      default:
        return value;
    }
  }
}
