<div class="provision-form-export-modal">
  <div mat-dialog-content>
    <il-provision-form-export
      [historyExport]="historyExport"
      [provisionFormUid]="provisionFormUid"
      (cancel)="onCancelExportProvisionFormClick()"
      (export)="onProvisionFormExport()"
    >
    </il-provision-form-export>
  </div>
</div>
