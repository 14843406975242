import { FetchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AccountRoleTypeModel } from "@@intelease/web/common/models";
import { RestClient } from "@@intelease/web/utils";

@Injectable({
  providedIn: "root",
})
export class FetchUserService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly USERS_URL = "/users";
  private static readonly ACCOUNT_ROLE = "/accountRole";

  constructor(
    private fetchApiService: FetchApiService,
    private restClient: RestClient
  ) {}

  getUserView<T extends object>(
    uid: string,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    return this.fetchApiService.sendRequest(
      FetchUserService.API_VERSION_1,
      FetchUserService.USERS_URL,
      uid,
      view,
      classRef
    );
  }

  getAllAccountRoles(): Observable<AccountRoleTypeModel[]> {
    return this.restClient
      .sendGetRequestNoView(
        FetchUserService.API_VERSION_1,
        `${FetchUserService.USERS_URL}${FetchUserService.ACCOUNT_ROLE}`
      )
      .pipe(map((resp: any) => resp.data));
  }
}
