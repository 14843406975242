<div
  class="nav"
  [ngClass]="{
    horizontal: layout === 'horizontal',
    vertical: layout === 'vertical'
  }"
>
  <!-- Vertical Navigation Layout -->
  <ng-container *ngIf="layout === 'vertical'">
    <ng-container *ngFor="let item of navigation">
      <itls-nav-vertical-group
        *ngIf="item.type == 'group'"
        [item]="item"
      ></itls-nav-vertical-group>
      <itls-nav-vertical-collapsable
        *ngIf="item.type == 'collapsable'"
        [item]="item"
      ></itls-nav-vertical-collapsable>
      <itls-nav-vertical-item
        *ngIf="item.type == 'item'"
        [item]="item"
      ></itls-nav-vertical-item>
    </ng-container>
  </ng-container>
  <!-- / Vertical Navigation Layout -->

  <!-- Horizontal Navigation Layout -->
  <ng-container *ngIf="layout === 'horizontal'">
    <ng-container *ngFor="let item of navigation">
      <itls-nav-horizontal-collapsable
        *ngIf="item.type == 'group'"
        [item]="item"
      ></itls-nav-horizontal-collapsable>
      <itls-nav-horizontal-collapsable
        *ngIf="item.type == 'collapsable'"
        [item]="item"
      ></itls-nav-horizontal-collapsable>
      <itls-nav-horizontal-item
        *ngIf="item.type == 'item'"
        [item]="item"
      ></itls-nav-horizontal-item>
    </ng-container>
  </ng-container>
  <!-- / Horizontal Navigation Layout -->
</div>
