import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";

export enum EventSourceEnum {
  INPUT = "INPUT",
  SELECT = "SELECT",
}

interface ModelInterface {
  input: any;
  dropdown: any;
}

export interface ChangeEventInterface {
  model: ModelInterface;
  eventSource: EventSourceEnum;
}

@Component({
  selector: "itls-input-dropdown-component",
  templateUrl: "./input-dropdown.component.html",
  styleUrls: ["./input-dropdown.component.scss"],
})
export class InputDropdownComponent {
  @Input() inputWidth = "60%";
  @HostBinding("class")
  @Input()
  class = "";
  @Input() inputDisabled = false;
  @Input() selectWidth = "40%";
  @Input() model: ModelInterface = {
    input: undefined,
    dropdown: undefined,
  };
  @Input() items: any[] = [];
  @Output()
  change: EventEmitter<ChangeEventInterface> = new EventEmitter<ChangeEventInterface>();
  eventSource = EventSourceEnum;
  @Input() digitsOnly = false;
  @Input() min: number;

  @Output()
  click: EventEmitter<EventSourceEnum> = new EventEmitter<EventSourceEnum>();

  onChange(evt: any, eventSource: EventSourceEnum) {
    if (evt && evt.stopPropagation) evt.stopPropagation();
    this.change.emit({ model: this.model, eventSource: eventSource });
  }

  onClear(evt) {
    this.model.input = undefined;
  }

  onClick(eventSource: EventSourceEnum) {
    if (this.click) {
      this.click.emit(eventSource);
    }
  }
}
