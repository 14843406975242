/* tslint:disable */
/* eslint-disable */
import { DocSetCategoryViewModel } from "./doc-set-category-view-model";
import { GoogleDriveInfoViewModel } from "./google-drive-info-view-model";
import { ProvisionsMetadataModel } from "./provisions-metadata-model";
import { SettingItemViewModel } from "./setting-item-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("LoggedInUserViewModel")
export class LoggedInUserViewModel {
  @JsonProperty("accessToken", String, true)
  accessToken?: string = "";

  @JsonProperty("accountRole", String, true)
  accountRole?: string = "";

  @JsonProperty("accountRoleName", String, true)
  accountRoleName?: string = "";

  @JsonProperty("accountRoleRank", Number, true)
  accountRoleRank?: number = undefined;

  @JsonProperty("accountUid", String, true)
  accountUid?: string = "";

  @JsonProperty("docSetCategories", [DocSetCategoryViewModel], true)
  docSetCategories?: Array<DocSetCategoryViewModel> = [];

  @JsonProperty("email", String, true)
  email?: string = "";

  @JsonProperty("firstName", String, true)
  firstName?: string = "";

  @JsonProperty("flags", [String], true)
  flags?: Array<string> = [];

  @JsonProperty("googleDriveInfoView", GoogleDriveInfoViewModel, true)
  googleDriveInfoView?: GoogleDriveInfoViewModel = undefined;

  @JsonProperty("lbxAccountUser", Boolean, true)
  lbxAccountUser?: boolean = undefined;

  @JsonProperty("lbxRoles", Any, true)
  lbxRoles?: Array<
    "ADMIN" | "FORMS_ADMIN" | "ANALYST" | "REVIEWER" | "DATA_SCIENTIST"
  > = [];

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("profileImageFileRegistered", Boolean, true)
  profileImageFileRegistered?: boolean = undefined;

  @JsonProperty("profileImageUid", String, true)
  profileImageUid?: string = "";

  @JsonProperty("provisionMetadatas", ProvisionsMetadataModel, true)
  provisionMetadatas?: ProvisionsMetadataModel = undefined;

  @JsonProperty("queueFeatureEnabled", Boolean, true)
  queueFeatureEnabled?: boolean = undefined;

  @JsonProperty("recordReviewMode", String, true)
  recordReviewMode?: string = "";

  @JsonProperty("settingItems", [SettingItemViewModel], true)
  settingItems?: Array<SettingItemViewModel> = [];

  @JsonProperty("status", String, true)
  status?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("uidHash", String, true)
  uidHash?: string = "";
}
