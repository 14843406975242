import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import { inteleaseAnimations } from "@@intelease/web/intelease/animations";
import {
  cloneDeep,
  keys as _keys,
  keyBy,
  first as _first,
  isEmpty,
} from "lodash";
import { DatePipe } from "@angular/common";
import {
  PartialValProvisionValueModel,
  RelatedDocModel,
} from "@@intelease/web/common/models";
import { AbstractReviewFacade } from "@@intelease/app-state/abstract-review";
import { finalize, first } from "rxjs/operators";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  ProvisionReviewStatusEnum,
  RecordReviewModeEnum,
} from "@@intelease/app-models/common";
import {
  CalculateOptionRemindersRequestModel,
  FormFieldModel,
  FullValMultiPdfProvViewModel,
  OApiReqCalculateMentionDtoModel,
  OptionRemindersCalculatorService,
  ProvisionMentionService,
} from "@@intelease/api-models/adex-api-model-src";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision/src";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import {
  FormFieldFormat,
  OptionCalculatorInputField,
} from "../options-calculator/components/input-field/input-field.component";
import { SelectMentionConfirmModalService } from "../../select-mention-confirm-modal";
import { shouldSetStatusToNeedReviewTouched } from "@@intelease/web/utils/provision-review-utils";
import { ProvisionReviewUIFacade } from "@@intelease/web/abstract-review/src/lib/store/provision-review.facade";
import { IResizeEvent } from "angular2-draggable/lib/models/resize-event";
import {
  DateUtil,
  convertToPascalCase,
  firstNotNill,
} from "@@intelease/web/utils";
import { DateConvertService } from "@@intelease/web/common/services";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { isDefined } from "@@intelease/web/utils";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

type FormData = Record<string, OptionCalculatorInputField>;

class TableColumn {
  title: string;
  readonly key: string;

  constructor(title: string, key: string) {
    this.title = title;
    this.key = key;
  }
}

export enum RemindersTypeEnum {
  ROLLING = "ROLLING",
  MULTIPLE = "MULTIPLE",
  RECURRING = "RECURRING",
}

@Component({
  selector: "il-options-reminder-calculator-modal",
  templateUrl: "./options-reminder-calculator-modal.component.html",
  styleUrls: ["./options-reminder-calculator-modal-component.scss"],
  animations: inteleaseAnimations,
})
export class OptionsReminderCalculatorModalComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  @ViewChild("staticTabs", { static: false }) staticTabs: TabsetComponent;

  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  imported: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  payload: any;
  abstractName = "";

  calculatedItems: any[] = [];
  tableColumns: TableColumn[] = [];

  isTableHidden = true;
  provisionFormUid: string;
  abstractUid: string;
  isDatePickerOpen = false;
  isSavingCalculatedMention = false;
  selectedFieldKey: string;
  datePickerValue: Date;
  recordReviewMode: RecordReviewModeEnum;

  selectedDocument: RelatedDocModel;
  currentPage: number;

  optionNotifications: FullValMultiPdfProvModel;

  //input data for options calculator from the API
  regularFields: FormFieldModel[];
  regularFieldsLoading = false;
  optionTypeToConditionFields: { [key: string]: FormFieldModel[] };
  optionTypeToSurroundingText: { [key: string]: string };

  //form data
  formData: FormData;

  FieldFormat = FormFieldFormat;
  selectedTab = "ChooseReminderType";
  reminderType: RemindersTypeEnum = RemindersTypeEnum.MULTIPLE;

  ReminderTypeEnum = RemindersTypeEnum;

  private modalSizeBeforeOpenCalculator: DOMRect;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private datePipe: DatePipe,
    public readonly abstractReviewFacade: AbstractReviewFacade,
    private provisionMentionService: ProvisionMentionService,
    private optionReminderCalculatorService: OptionRemindersCalculatorService,
    private _notification: MessageService,
    private selectMentionConfirmModalService: SelectMentionConfirmModalService,
    private readonly provisionReviewUIFacade: ProvisionReviewUIFacade,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const {
      abstractUid,
      provisionFormUid,
      selectedDocument,
      currentPage,
      optionNotifications,
      abstractName,
      recordReviewMode,
    } = this.payload;
    this.abstractUid = abstractUid;
    this.abstractName = abstractName;
    this.provisionFormUid = provisionFormUid;
    this.selectedDocument = selectedDocument;
    this.currentPage = currentPage;
    this.optionNotifications = optionNotifications;
    this.recordReviewMode = recordReviewMode;

    this.abstractReviewFacade.getOptionReminderDataLoading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((optionRemindersDataLoading) => {
        this.regularFieldsLoading = optionRemindersDataLoading;
        this.abstractReviewFacade
          .getOptionRemindersData$(this.reminderType)
          .pipe(firstNotNill())
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((optionRemindersData) => {
            if (optionRemindersData) {
              this.regularFields = optionRemindersData;
              this.initializeFormData();
            }
          });

        this.abstractReviewFacade
          .getOptionTypeToSurroundingText$(this.reminderType)
          .pipe(first())
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((optionTypeToSurroundingText) => {
            if (optionTypeToSurroundingText) {
              this.optionTypeToSurroundingText = optionTypeToSurroundingText;
            }
          });
      });
  }

  private loadOptionsReminderCalculatorData() {
    this.abstractReviewFacade.getOptionReminderData({
      recordUid: this.abstractUid,
      optionRemindersType: this.reminderType,
    });
  }

  onSelectTab(tab) {
    this.selectedTab = tab.id.toString();
    this.hideTable();

    if (this.selectedTab === "ProvideInputs") {
      this.abstractReviewFacade
        .getOptionRemindersData$(this.reminderType)
        .pipe(first())
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((optionRemindersData) => {
          if (!optionRemindersData) {
            this.loadOptionsReminderCalculatorData();
          } else {
            this.regularFields = optionRemindersData;
            this.initializeFormData();
          }
        });
    }
  }

  onNext(): void {
    const currentTabIndex = this.staticTabs.tabs.findIndex((tab) => tab.active);
    const nextTabIndex = currentTabIndex + 1;
    this.staticTabs.tabs[nextTabIndex].active = true;
    this.selectedTab = this.staticTabs.tabs[nextTabIndex].id;
  }

  private initializeFormData() {
    const regularFieldModified = this.regularFields.map((field) => {
      return this.includeDefaultValue(field);
    });

    this.formData = keyBy(
      cloneDeep(regularFieldModified),
      "fieldKey"
    ) as FormData;

    this.calculatedItems = [];
    this.hideTable();
  }

  private includeDefaultValue(
    field: FormFieldModel
  ): OptionCalculatorInputField {
    const undefinedValueField = {
      ...field,
      fieldValue: undefined,
      rawValue: undefined,
    };

    if (!field.defaultValue) {
      return undefinedValueField;
    }

    switch (field.fieldFormat) {
      case FormFieldFormat.DATE:
        return {
          ...field,
          fieldValue: this.transformDate(field?.defaultValue),
          rawValue: field?.defaultValue,
        };
      case FormFieldFormat.SELECT:
        return {
          ...field,
          fieldValue: field?.defaultValue?.value,
          rawValue: undefined,
        };
      case FormFieldFormat.NUMBER:
        return {
          ...field,
          fieldValue: field?.defaultValue,
          rawValue: undefined,
        };
      case FormFieldFormat.RELATIVE_TIME_TO_EVENT:
      case FormFieldFormat.TIME_PERIOD:
        return {
          ...field,
          fieldValue: undefined,
          rawValue: {
            input: field.defaultValue?.value,
            dropdown: convertToPascalCase(field.defaultValue?.unit),
          },
        };
      default:
        return undefinedValueField;
    }
  }

  private transformDate(date) {
    return this.datePipe.transform(date, "MMM-d-y");
  }

  private showTable() {
    this.isTableHidden = false;
  }

  private hideTable() {
    this.isTableHidden = true;
  }

  onResizing(evt: IResizeEvent) {
    const provideInputsContainer = this.elementRef.nativeElement.querySelector(
      ".provide-inputs-container"
    );

    this.renderer.setStyle(
      provideInputsContainer,
      "height",
      `${evt.size.height - 200}px`
    );

    if (!this.isTableHidden) {
      const tableFieldContainer =
        this.elementRef.nativeElement.querySelector(".table-container");
      this.renderer.setStyle(
        tableFieldContainer,
        "width",
        `${evt.size.width - 42}px`
      );
    }

    const hostRect = evt.host.getBoundingClientRect();

    if (hostRect.x <= 0) {
      this.renderer.setStyle(
        evt.host,
        "left",
        `${evt.host.style.left - hostRect.x}px`
      );
    }

    if (hostRect.right > document.body.clientWidth) {
      this.renderer.setStyle(
        evt.host,
        "width",
        `${document.body.clientWidth - hostRect.left}px`
      );
    }

    if (hostRect.bottom > document.body.clientHeight) {
      this.renderer.setStyle(
        evt.host,
        "height",
        `${document.body.clientHeight - hostRect.top}px`
      );
    }
  }

  getToolTip(fieldKey: string) {
    if (fieldKey === "optionType") {
      return "Choose option type to calculate the options";
    }
    return undefined;
  }

  onOpenDatePicker(key: string) {
    if (!this.modalSizeBeforeOpenCalculator) {
      this.modalSizeBeforeOpenCalculator = this.modalRect;
    }

    this.hideTable();
    if (this.formData[key].fieldFormat !== this.FieldFormat.DATE) {
      if (this.isDatePickerOpen) {
        this.onDatePickerClosed();
      }
      return;
    }

    if (
      this.formData[key].rawValue &&
      this.formData[key].rawValue instanceof Date
    ) {
      this.datePickerValue = this.formData[key].rawValue;
      this.formData[key].fieldValue = this.transformDate(this.datePickerValue);
    } else {
      this.datePickerValue = null;
    }

    this.selectedFieldKey = key;
    this.isDatePickerOpen = true;
    this.updateModalSizeAfterDataPickerOpen(157.5);
    this.changeDetectorRef.detectChanges();
  }

  private get modalRect(): DOMRect {
    const content =
      this.elementRef.nativeElement.querySelector(".modal-content");

    if (!content) {
      return;
    }

    return content.getBoundingClientRect();
  }

  private updateModalSizeAfterDataPickerOpen(size: number): void {
    const content =
      this.elementRef.nativeElement.querySelector(".modal-content");

    if (!content) {
      return;
    }

    const rect = content.getBoundingClientRect();

    const height =
      rect.top + rect.height + size > document.body.clientHeight
        ? document.body.clientHeight - rect.top
        : rect.height + size;

    this.renderer.setStyle(content, "height", `${height}px`);
  }

  onDatePickerClosed() {
    if (this.isDatePickerOpen) {
      this.restoreModalSize();
    }
    this.isDatePickerOpen = false;
    this.selectedFieldKey = undefined;
    this.datePickerValue = undefined;
  }

  private restoreModalSize(): void {
    if (!this.modalSizeBeforeOpenCalculator) {
      return;
    }

    const content =
      this.elementRef.nativeElement.querySelector(".modal-content");

    this.renderer.setStyle(
      content,
      "height",
      `${this.modalSizeBeforeOpenCalculator.height}px`
    );

    this.modalSizeBeforeOpenCalculator = undefined;
  }

  onFieldReset(key: string) {
    if (this.formData[key].fieldFormat === this.FieldFormat.DATE) {
      this.onDatePickerClosed();
    }
  }

  onDateChange(date, key: string) {
    this.formData[key].rawValue = date;
    this.formData[key].fieldValue = this.transformDate(date);
  }

  onCalculateClick() {
    this.hideTable();
    this.onDatePickerClosed();
    if (this.isValidForCalculation()) {
      let data: CalculateOptionRemindersRequestModel = {
        optionRemindersType: this.reminderType,
        optionType: "",
        recordUid: this.abstractUid,
      };
      _keys(this.formData).forEach((key) => {
        const item = this.formData?.[key];
        switch (item.fieldFormat) {
          case "DATE": {
            if (isDefined(item.rawValue)) {
              let _key = key;
              switch (item.fieldKey) {
                case "earliestDate":
                  _key = "earliestReminderDate";
                  break;

                case "endDate":
                  _key = "rollingEndDate";
                  break;

                case "startDate":
                  _key = "rollingStartDate";
                  break;

                case "lastPossibleReminderDate":
                  _key = "maxReminderDate";
                  break;
              }
              data = {
                ...data,
                [_key]: DateUtil.serializeToExactDate(item.rawValue),
              };
            }
            break;
          }
          case "SELECT": {
            if (isDefined(item?.fieldValue)) {
              data = {
                ...data,
                [key]: item.fieldValue,
              };
            }
            break;
          }
          case "RELATIVE_TIME_TO_EVENT": {
            if (item.fieldKey === "effective") {
              data = {
                ...data,
                ["rollingOffsetNumber"]: item.rawValue?.input,
                ["rollingOffsetUnit"]: item.rawValue?.dropdown1?.toUpperCase(),
                ["rollingOffsetBefore"]: item.rawValue?.dropdown2 === "Before",
              };
            } else {
              data = {
                ...data,
                [key]: item?.rawValue,
              };
            }
            break;
          }
          case "TIME_PERIOD": {
            if (
              isDefined(item?.rawValue?.input, item?.rawValue?.dropdown) &&
              item?.fieldKey === "offset"
            ) {
              data = {
                ...data,
                [key + "Number"]: item?.rawValue?.input,
                [key + "Unit"]: item?.rawValue?.dropdown?.toUpperCase(),
              };
            } else if (item?.rawValue?.input && item?.rawValue?.dropdown) {
              data = {
                ...data,
                [key + "Number"]: item?.rawValue?.input,
                [key + "Unit"]: item?.rawValue?.dropdown?.toUpperCase(),
              };
            }
            break;
          }
          case "NUMBER": {
            if (isDefined(item?.fieldValue)) {
              switch (item?.fieldKey) {
                case "maximumReminders":
                  data = {
                    ...data,
                    ["maxNumReminders"]: item?.fieldValue,
                  };
                  break;

                default:
                  data = {
                    ...data,
                    [key]: item?.fieldValue,
                  };
                  break;
              }
            }
            break;
          }
          case "TEXT_AREA": {
            data = {
              ...data,
              [key]: item.fieldValue,
            };
            break;
          }
        }
      });

      data = {
        ...data,
        clauseSummary: isEmpty(
          this.getClauseSummaryText(this.formData?.optionType?.fieldValue)
        )
          ? undefined
          : this.getClauseSummaryText(this.formData?.optionType?.fieldValue),
      };

      this.calculatedItems = [];
      this.optionReminderCalculatorService
        .calculateOptionReminders({
          body: {
            data,
          },
        })
        .subscribe((resp) => {
          const possibleTableColumns = [
            new TableColumn("Number", "number"),
            new TableColumn("Options Type", "optionType"),
            new TableColumn("Date Type", "dateType"),
            new TableColumn("Start Date", "startDate"),
            new TableColumn("End Date", "endDate"),
            new TableColumn("Offset", "offset"),
            new TableColumn("Frequency", "frequency"),
            new TableColumn("Effective", "effective"),
            new TableColumn("Date", "date"),
            new TableColumn("Clause Summary", "clauseSummary"),
          ];

          this.tableColumns = possibleTableColumns.filter((tableColumn) =>
            _keys(_first(resp.data.items)).includes(tableColumn.key)
          );

          this.calculatedItems = resp.data.items.map((item) => {
            let calculatedItem = {};
            _keys(item).forEach((_item) => {
              calculatedItem = {
                ...calculatedItem,
                [_item]: DateUtil.isSerializedDate(item[_item])
                  ? this.transformDate(
                      DateConvertService.deserializeDate(item[_item])
                    )
                  : item[_item],
              };
            });
            return calculatedItem;
          });

          this.showTable();
        });
    }
  }

  private fieldContainsAnyValue(inputField: OptionCalculatorInputField) {
    let isValid = true;
    if (
      inputField.fieldFormat === this.FieldFormat.TEXT ||
      inputField.fieldFormat === this.FieldFormat.TEXT_AREA ||
      inputField.fieldFormat === this.FieldFormat.SELECT
    ) {
      if (!inputField.fieldValue || inputField.fieldValue?.trim() === "") {
        isValid = false;
      }
    } else if (inputField.fieldFormat === this.FieldFormat.NUMBER) {
      if (inputField.fieldValue === undefined || isNaN(inputField.fieldValue)) {
        isValid = false;
      }
    } else if (inputField.fieldFormat === this.FieldFormat.DATE) {
      if (
        !inputField.fieldValue ||
        !inputField.rawValue ||
        !(inputField.rawValue instanceof Date)
      ) {
        isValid = false;
      }
    } else if (inputField.fieldFormat === this.FieldFormat.TIME_PERIOD) {
      if (
        !inputField.rawValue ||
        !inputField.rawValue.input ||
        !inputField.rawValue.dropdown
      ) {
        isValid = true;
      }
    } else if (
      inputField.fieldFormat === this.FieldFormat.RELATIVE_TIME_TO_EVENT
    ) {
      if (
        !inputField.rawValue ||
        !inputField.rawValue.input ||
        !inputField.rawValue.dropdown1 ||
        !inputField.rawValue.dropdown2
      ) {
        isValid = false;
      }
    }
    return isValid;
  }

  private isValidForCalculation() {
    if (!this.formData) {
      this._notification.error("Data is not completely loaded yet!");
      return false;
    }

    let isValid = true;
    const missingFields: string[] = [];
    _keys(this.formData).map((key) => {
      const inputField = this.formData[key] as OptionCalculatorInputField;
      if (!inputField.optional) {
        const isFieldValid = this.fieldContainsAnyValue(inputField);
        if (!isFieldValid) {
          missingFields.push(inputField.fieldLabel);
          isValid = false;
        }
      }
    });

    if (!isValid) {
      if (missingFields.length) {
        this._notification.error(
          `Missing fields: ${missingFields.join(
            ", "
          )}, these are required fields!`
        );
      } else {
        this._notification.error(`Form is not valid`);
      }
    }

    const hasMaximumRemindersField = this.formData?.["maximumReminders"]
      ? this.fieldContainsAnyValue(this.formData?.["maximumReminders"])
      : undefined;
    const hasEarliestDateField = this.formData?.["earliestDate"]
      ? this.fieldContainsAnyValue(this.formData?.["earliestDate"])
      : undefined;

    if (hasMaximumRemindersField && hasEarliestDateField) {
      this._notification.error(
        "Maximum Reminders and Earliest Date cannot both be filled!"
      );
      return false;
    }
    if (
      !hasMaximumRemindersField &&
      !hasEarliestDateField &&
      this.reminderType === RemindersTypeEnum.MULTIPLE
    ) {
      this._notification.error(
        "One of Maximum Reminders or Earliest Date must be filled!"
      );
      return false;
    }

    return isValid;
  }

  private importCalculatedProvision(
    {
      mentionUid,
      preMentionUid,
      postMentionUid,
    }: {
      mentionUid?: string;
      preMentionUid?: string;
      postMentionUid?: string;
    },
    selectedMentionIndex?: number,
    mentionName?: string
  ) {
    const value = {
      headers: [],
      types: [],
      rows: [],
    };
    for (const tableColumn of this.tableColumns) {
      value.headers.push(tableColumn.title);
      value.types.push("COMMON_NOUN");
    }
    this.calculatedItems.forEach((item) => {
      const row = [];
      for (const tableColumn of this.tableColumns) {
        row.push(item[tableColumn.key]);
      }
      value.rows.push(row);
    });

    const body: OApiReqCalculateMentionDtoModel = {
      data: {
        docAbstractUid: this.selectedDocument.uid,
        page: this.currentPage,
        provisionUid: this.optionNotifications.provisionInfo.uid,
        provisionValue: value,
        mentionName,
        mentionUid,
        postMentionUid,
        preMentionUid,
        format: "TABLE",
      },
    };
    this.isSavingCalculatedMention = true;
    this.provisionMentionService
      .calculateCreateProvisionValueV2({
        recordUid: this.abstractUid,
        body,
      })
      .pipe(
        finalize(() => {
          this.isSavingCalculatedMention = false;
        })
      )
      .subscribe({
        next: (res) => {
          if (!res.data) {
            this._notification.error(
              res.message ||
                "An unknown error occurred while saving the options"
            );
            return;
          }
          this.updateViewAfterChangeInProvisions(
            res.data.multiPdfProv,
            this.optionNotifications as any,
            res.data.modifiedProvisionMentionUids?.newlyCreated?.[0],
            selectedMentionIndex
          );
          this.hideTable();
          this.onDismissModal();
        },
        error: () => {
          this._notification.error(
            "An unknown error occurred while saving the options"
          );
        },
      });
  }

  getClauseSummaryText(optionType: string) {
    if (optionType && this.optionTypeToSurroundingText)
      return this.optionTypeToSurroundingText[optionType] || "";

    return "";
  }

  onImportClick() {
    if (!this.isSavingCalculatedMention && this.calculatedItems.length > 0) {
      this.selectMentionConfirmModalService
        .openModalAndAskRelevantMentionUidToFillIn(this.optionNotifications)
        .subscribe((res) => {
          if (!res.isCanceled) {
            this.importCalculatedProvision(
              res.relevantMentions,
              res.selectedMentionIndex,
              res?.mentionName
            );
          }
        });
    }
  }

  private updateViewAfterChangeInProvisions(
    backendProvisionDefinition: FullValMultiPdfProvViewModel,
    currentProvisionDefiniton: FullValMultiPdfProvViewModel,
    newMentionUid: string,
    selectedMentionIndex?: number
  ): void {
    const { provisionUid } = backendProvisionDefinition?.multiplePdfProvision;
    const provisionDetail = Json2TypescriptHelper.convertToEntity(
      backendProvisionDefinition,
      FullValMultiPdfProvModel
    );
    const options = [...currentProvisionDefiniton.multiplePdfProvision.options];
    const newOption = provisionDetail.multiplePdfProvision.options.find(
      (option) => option.uid === newMentionUid
    );

    if (selectedMentionIndex !== undefined) {
      options.splice(
        selectedMentionIndex,
        1,
        newOption as PartialValProvisionValueModel
      );
    } else {
      options.push(newOption as PartialValProvisionValueModel);
    }

    this.abstractReviewFacade.setFullProvisionDetailWithProvisionUid({
      provisionUid,
      provisionDetail: {
        ...provisionDetail,
        multiplePdfProvision: {
          ...provisionDetail.multiplePdfProvision,
          options,
        },
        reviewStatus: shouldSetStatusToNeedReviewTouched(
          currentProvisionDefiniton as unknown as FullValMultiPdfProvModel
        )
          ? ProvisionReviewStatusEnum.NEED_REVIEW_TOUCHED
          : provisionDetail.reviewStatus,
      },
    });

    this.provisionReviewUIFacade.scrollToProvisionOption(
      currentProvisionDefiniton,
      newOption
    );
  }

  onResetClick() {
    this.initializeFormData();
    this.hideTable();
    this.calculatedItems = [];
    this.tableColumns = [];
    this.onDatePickerClosed();
  }

  onDismissModal() {
    this.bsModalRef.hide();
    this.onDismiss.next(this.modalRes);
    this.abstractReviewFacade.clearOptionReminderData();
  }
}
