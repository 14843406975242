/* tslint:disable */
/* eslint-disable */
import { UploadDocSetDataResDtoModel } from "./upload-doc-set-data-res-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UploadDocSetGroupDataResDtoModel")
export class UploadDocSetGroupDataResDtoModel {
  @JsonProperty("items", [UploadDocSetDataResDtoModel], true)
  items?: Array<UploadDocSetDataResDtoModel> = [];

  @JsonProperty("selectedDirectoryUid", String, true)
  selectedDirectoryUid?: string = "";
}
