/* tslint:disable */
/* eslint-disable */
import { JsonNodeModel } from "./json-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FrontendErrorInfoDtoModel")
export class FrontendErrorInfoDtoModel {
  @JsonProperty("apiRequestBody", Any, true)
  apiRequestBody?: any = undefined;

  @JsonProperty("apiRequestMethod", String, true)
  apiRequestMethod?: string = "";

  @JsonProperty("apiRequestPath", String, true)
  apiRequestPath?: string = "";

  @JsonProperty("apiRequestQueryParams", String, true)
  apiRequestQueryParams?: string = "";

  @JsonProperty("appUrl", String, true)
  appUrl?: string = "";

  @JsonProperty("browser", String, true)
  browser?: string = "";

  @JsonProperty("email", String, true)
  email?: string = "";

  @JsonProperty("frequency", Number, true)
  frequency?: number = undefined;

  @JsonProperty("message", String, true)
  message?: string = "";

  @JsonProperty("operatingSystem", String, true)
  operatingSystem?: string = "";

  @JsonProperty("requestTime", DateTimeConvertService, true)
  requestTime?: Date | undefined = undefined;

  @JsonProperty("stacktrace", [String], true)
  stacktrace?: Array<string> = [];

  @JsonProperty("timePeriod", Number, true)
  timePeriod?: number = undefined;
}
