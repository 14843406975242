import { JsonObject, JsonProperty } from "json2typescript";
import { DocumentModel } from "@@intelease/web/abstraction-page/src/lib/models/document.model";

@JsonObject("AbstractDocumentModel")
export class AbstractDocumentModel {
  static view = "relatedDocs";

  @JsonProperty("abstractFilename", String)
  abstractFilename = "";

  @JsonProperty("lastModifiedDate", String)
  lastModifiedDate = "";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("defaultDoc", DocumentModel)
  defaultDoc: DocumentModel = new DocumentModel();

  @JsonProperty("documents", [DocumentModel])
  documents: DocumentModel[] = [];
}
