import { JsonObject, JsonProperty } from "json2typescript";
import { DirectoryNavigationModel } from "@@intelease/web/common/models/drive/directory-navigation.model";

@JsonObject("NodeSearchResultNavigationModel")
export class NodeSearchResultNavigationModel {
  @JsonProperty("category", String)
  category: "OWNED" | "SHARED" | "DELETED" | undefined = undefined;

  @JsonProperty("categoryTitle", String)
  categoryTitle = "";

  @JsonProperty("items", [DirectoryNavigationModel])
  items: DirectoryNavigationModel[] = [];
}
