import { NgModule } from "@angular/core";
import { ProvisionNotesComponent } from "./provision-notes.component";
import { InteleaseModule } from "@@intelease/web/intelease/intelease.module";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { SnippetTextModule } from "@@intelease/web/abstraction-page/src/lib/components/snippet-text";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

const MATERIAL_MODULES = [
  MatButtonModule,
  MatListModule,
  MatDividerModule,
  MatIconModule,
  MatMenuModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
];

const UI_MODULES = [];

@NgModule({
  imports: [
    CommonModule,
    InteleaseModule,
    InteleaseCommonModule,
    FormsModule,
    ...MATERIAL_MODULES,
    ...UI_MODULES,
    SnippetTextModule,
  ],
  declarations: [ProvisionNotesComponent],
  exports: [ProvisionNotesComponent],
})
export class ProvisionNotesModule {}
