import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { DestroyRef, inject, Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { filter, map, mergeMap } from "rxjs/operators";
import { getNestedValue } from "@@intelease/web/intelease/utils";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class PageTitleTagsService {
  destroyRef = inject(DestroyRef);
  private titleTags: any;
  private defaultTitle = "ADEx AI";
  private JSON_DIR = "/assets/config/title-tags.config.json";

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private titleService: Title,
    private httpClient: HttpClient,
    private meta: Meta
  ) {
    this.loadTitleTags();
  }

  private loadTitleTags(event?: any) {
    this.httpClient.get(this.JSON_DIR).subscribe((titleTags) => {
      this.titleTags = titleTags;
      if (event) {
        this.setPageTitleTagsByKey(event);
      }
    });
  }

  updatePageTitleTags(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === "primary"))
      .pipe(mergeMap((route) => route.data))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        if (event) {
          this.setPageTitleTagsByKey(event["TITLE_TAG_KEY"]);
        }
      });
  }

  setPageTitle(title: string) {
    this.titleService.setTitle(`${title} | ${this.defaultTitle}`);
  }

  setPageTitleTagsByKey(titleTagKey) {
    if (titleTagKey && this.titleTags) {
      const PAGE_TITLE_TAGS = getNestedValue(this.titleTags, titleTagKey);
      if (PAGE_TITLE_TAGS) {
        PAGE_TITLE_TAGS["metaTags"].forEach((tag: any) => {
          this.meta.updateTag(tag, `name='${tag.name}'`);
        });
        const _title = PAGE_TITLE_TAGS["title"];
        const title = _title.includes(this.defaultTitle)
          ? _title
          : `${_title} | ${this.defaultTitle}`;
        this.titleService.setTitle(title);
      }
    } else if (!this.titleTags && titleTagKey) {
      this.loadTitleTags(titleTagKey);
    } else {
      this.titleService.setTitle(this.defaultTitle);
    }
  }
}
