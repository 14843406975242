import { NgModule } from "@angular/core";
import { EditCalendarComponent } from "./edit-calendar.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { CalendarFilterModule } from "../../calendar-detail/components/calendar-filter/calendar-filter.module";
import { MatTooltipModule } from "@angular/material/tooltip";

const MATERIAL_MODULES = [
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatTooltipModule,
];

@NgModule({
  imports: [
    ...MATERIAL_MODULES,
    FormsModule,
    CommonModule,
    InteleaseCommonModule,
    CalendarFilterModule,
  ],
  declarations: [EditCalendarComponent],
  exports: [EditCalendarComponent],
})
export class EditCalendarModule {}
