<div id="main">
  <div id="container-1" class="container1">
    <div id="container-2" class="container1">
      <mat-drawer-container
        autosize
        [hasBackdrop]="false"
        style="height: 100%"
        [ngClass]="{ 'new-menu': (leftMenu$ | async) }"
      >
        <mat-drawer
          position="start"
          style="max-width: 248px"
          #drawer
          class="sidenav"
          [opened]="isOpened || (leftMenu$ | async)"
          [mode]="mainDrawerService.sidebarMode"
        >
          <left-menu
            *ngIf="!turnOffMenu && (leftMenu$ | async) && isLoggedIn"
          ></left-menu>
          <navbar-vertical-style-2
            *ngIf="(leftMenu$ | async) !== true"
            [hidden]="mainDrawerService.sidebarDisabled$ | async"
            class="left-navbar"
          ></navbar-vertical-style-2>
        </mat-drawer>
        <mat-drawer-content id="itls-main-drawer-content">
          <toolbar
            [hidden]="mainDrawerService.toolbarDisabled$ | async"
          ></toolbar>
          <div
            id="container-3"
            class="container1"
            inteleasePerfectScrollbar
            [intelesePerfectScrollbarOptions]="{
              suppressScrollX: true,
              updateOnRouteChange: true
            }"
          >
            <ng-content select="router-outlet"></ng-content>
          </div>

          <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </div>
</div>

<ng-template #rightNavbar>
  <il-ui-sidebar
    name="notification-side-bar"
    ngStyle.gt-md="top: 85px;"
    ngStyle.lt-md="top: 64px;"
    ngStyle.md="top: 64px;"
    style="
      background-color: #fff;
      border-left: 1px solid #e5eced;
      min-width: 340px;
    "
    *ngIf="false"
    [invisibleOverlay]="true"
    position="right"
    class="navbar-tb-sidebar"
  >
  </il-ui-sidebar>
</ng-template>
