/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EntityPermissionActorFullDtoModel")
export class EntityPermissionActorFullDtoModel {
  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("type", Any, true)
  type?: "PUBLIC" | "ACCOUNT" | "ROLE" | "USER" | "UNREGISTERED_USER" =
    undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
