/* tslint:disable */
/* eslint-disable */
import { CloneQueueReqDtoModel } from "./clone-queue-req-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCloneQueueDtoModel")
export class OApiReqCloneQueueDtoModel {
  @JsonProperty("data", CloneQueueReqDtoModel, true)
  data?: CloneQueueReqDtoModel = undefined;
}
