import { Any, JsonObject, JsonProperty } from "json2typescript";
import { SnippetModel } from "./snippet.model";
import { ProvisionValueConvertService } from "../../services/provision-value-convert.service";
import { ProvisionFormatValuesConvertService } from "../../services/provision-format-values-convert.service";
import { PartialValPdfProvisionViewModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("PartialValProvisionValueModel")
export class PartialValProvisionValueModel extends PartialValPdfProvisionViewModel {
  static view = "partialVal";

  @JsonProperty("uid", String, true)
  uid = "";

  @JsonProperty("value", ProvisionValueConvertService)
  value: any = undefined; // one of the models in provision-type directory

  @JsonProperty("textValue", String, true)
  textValue = "";

  @JsonProperty("notes", String)
  notes = "";

  @JsonProperty("sectionHeader", String)
  sectionHeader = "";

  model: any = {};

  @JsonProperty("page", Number)
  page = -1;

  @JsonProperty("docAbstractUid", String)
  docAbstractUid = "";

  @JsonProperty("source", String)
  source = "";

  isCommentingMode = false;

  isSectionHeaderMode = false;

  @JsonProperty("pdfHighlightIds", [String])
  pdfHighlightIds: string[] = [];

  @JsonProperty("selected", Boolean)
  selected = false;

  @JsonProperty("relatedOptionUids", [String], true)
  relatedOptionUids?: string[] = [];

  @JsonProperty("snippets", [SnippetModel], true)
  snippets?: SnippetModel[] = [];

  formSchema: any = undefined;

  //@TODO: (Mohammad.haji) remove the optional part
  @JsonProperty("type", String, true)
  valueType = "";

  active = false;
  isLoading = false;

  eventType: "NEW_TAB" | "HIGHLIGHT" = "HIGHLIGHT";

  rows: any;

  isTouched = false;

  isNavigateEnabled = true;

  @JsonProperty("formatValues", ProvisionFormatValuesConvertService, true)
  formatValues = {};
}
