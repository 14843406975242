import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { fetch, pessimisticUpdate } from "@ngrx/router-store/data-persistence";
import {
  LoadCalendar,
  CalendarLoaded,
  CalendarLoadError,
  CalendarActionTypes,
  LoadCalendarFilterFields,
  CalendarFilterFieldsLoaded,
  CalendarFilterFieldsLoadError,
  LoadCalendarDisplayableFields,
  CalendarDisplayableFieldsLoaded,
  CalendarDisplayableFieldsLoadError,
  SearchCalendarFinalAbstracts,
  SearchCalendarFinalAbstractsLoaded,
  SearchCalendarFinalAbstractsLoadError,
  LoadCalendars,
  CalendarsLoaded,
  CalendarsLoadError,
  SaveCalendarSucceeded,
  SaveCalendarFailed,
  SaveCalendar,
  LoadSelectedCalendar,
  SelectedCalendarLoaded,
  SelectedCalendarLoadError,
  SaveCalendarQuery,
  SaveCalendarQuerySucceeded,
  SaveCalendarQueryFailed,
  LoadCalendarQueries,
  CalendarQueriesLoaded,
  CalendarQueriesLoadError,
  DeleteSelectedCalendarQuery,
  DeleteSelectedCalendarQuerySucceeded,
  DeleteSelectedCalendarQueryFailed,
  DeleteSelectedCalendar,
  DeleteSelectedCalendarSucceeded,
  DeleteSelectedCalendarFailed,
  UpdateSelectedCalendarQueryActive,
  UpdateSelectedCalendarQueryActiveSucceeded,
  UpdateSelectedCalendarQueryActiveFailed,
  UpdateCalendarQuery,
  UpdateCalendarQuerySucceeded,
  UpdateCalendarQueryFailed,
  RunCalendarQuery,
  RunCalendarQuerySucceeded,
  RunCalendarQueryFailed,
  DeleteMultipleCalendar,
  DeleteMultipleCalendarSucceeded,
  DeleteMultipleCalendarFailed,
  UpdateCalendar,
  UpdateCalendarSucceeded,
  UpdateCalendarFailed,
} from "./calendar.actions";
import { CalendarService } from "@@intelease/app-services/calendar";
import { map } from "rxjs/operators";
import { cloneDeep, keyBy } from "lodash";
import * as _PROVISIONS_DATA_CONST from "@@intelease/web/common/enums/provision-data.const";
import { InteleaseCommonService } from "@@intelease/app-services/common";

const PROVISIONS_DATA_CONST = _PROVISIONS_DATA_CONST.PROVISIONS_DATA_CONST;

@Injectable()
export class CalendarEffects {
  loadCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.LoadCalendar),
      fetch({
        run: () => {
          // Your custom REST 'load' logic goes here. For now just return an empty list...
          return new CalendarLoaded([]);
        },

        onError: (action: LoadCalendar, error) => {
          //eslint-disable-line
          console.error("Error", error);
          return new CalendarLoadError(error);
        },
      })
    )
  );

  loadCalendarFilterFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.LoadCalendarFilterFields),
      fetch({
        run: () => {
          return this.calendarService.getCalendarFilterFields().pipe(
            map((_res) => {
              const res: any = cloneDeep(_res);
              const operatorsTitleMap =
                this.inteleaseCommonService.getOperatorsTitleMap();
              const { provisionFields, generalFields, fieldTypeToRelations } =
                res.data;
              for (const key in fieldTypeToRelations) {
                if (fieldTypeToRelations[key]) {
                  fieldTypeToRelations[key] = fieldTypeToRelations[key].map(
                    (item) => ({
                      label: operatorsTitleMap[item],
                      value: item,
                    })
                  );
                }
              }

              const provisionFieldsWithoutAllowedTypes = provisionFields.map(
                (item) => {
                  return {
                    ...item.namedSearchField,
                    allowedTypes: item.allowedTypes.map((allowedType) => ({
                      label: PROVISIONS_DATA_CONST[allowedType].uiName,
                      value: allowedType,
                    })),
                  };
                }
              );

              const _generalFields = generalFields.map((item) => ({
                ...item,
                allowedTypes:
                  item.allowedTypes && item.allowedTypes.length
                    ? item.allowedTypes.map((allowedType) => ({
                        label: PROVISIONS_DATA_CONST[allowedType].uiName,
                        value: allowedType,
                      }))
                    : [
                        {
                          label: PROVISIONS_DATA_CONST[item.fieldType].uiName,
                          value: item.fieldType,
                        },
                      ],
              }));

              const payload = {
                filterProvisions: {
                  provisionFields: keyBy(
                    provisionFieldsWithoutAllowedTypes,
                    "fieldName"
                  ),
                  generalFields: keyBy(_generalFields, "fieldName"),
                },
                provisionsOperator: fieldTypeToRelations,
              };
              return new CalendarFilterFieldsLoaded(payload);
            })
          );
        },
        onError: (action: LoadCalendarFilterFields, error) => {
          //eslint-disable-line
          return new CalendarFilterFieldsLoadError(error);
        },
      })
    )
  );

  loadCalendarDisplayableFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.LoadCalendarDisplayableFields),
      fetch({
        run: () => {
          return this.calendarService.getCalendarDisplayableFields().pipe(
            map((res) => {
              const { provisionFields, generalFields } = res.data;
              const payload = {
                filterProvisions: {
                  provisionFields: keyBy(provisionFields, "fieldName"),
                  generalFields: keyBy(generalFields, "fieldName"),
                },
              };
              return new CalendarDisplayableFieldsLoaded(payload);
            })
          );
        },
        onError: (action: LoadCalendarDisplayableFields, error) => {
          //eslint-disable-line
          return new CalendarDisplayableFieldsLoadError(error);
        },
      })
    )
  );

  searchCalendarFinalAbstracts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.SearchCalendarFinalAbstracts),
      fetch({
        run: (action: SearchCalendarFinalAbstracts) => {
          const { dashboardUid, payload, dateRange } = action;
          return this.calendarService
            .searchCalendarFinalAbstracts(dashboardUid, payload, dateRange)
            .pipe(
              map(
                (res) =>
                  new SearchCalendarFinalAbstractsLoaded(
                    res.data.items,
                    action.calendarQueryUid,
                    action.selectedInstanceId
                  )
              )
            );
        },
        onError: (action: SearchCalendarFinalAbstracts, error) => {
          const { message } = error;
          return new SearchCalendarFinalAbstractsLoadError(
            message,
            action.selectedInstanceId
          );
        },
      })
    )
  );

  loadCalendars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.LoadCalendars),
      fetch({
        run: () => {
          return this.calendarService
            .getCalendars()
            .pipe(map((res) => new CalendarsLoaded(res.data.items)));
        },
        onError: (action: LoadCalendars, error) => {
          //eslint-disable-line
          const { message } = error;
          return new CalendarsLoadError(message);
        },
      })
    )
  );

  SaveCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.SaveCalendar),
      pessimisticUpdate({
        run: (action: SaveCalendar) => {
          return this.calendarService
            .saveCalendar(action.payload)
            .pipe(map((res) => new SaveCalendarSucceeded(res)));
        },
        onError(action: SaveCalendar, error) {
          //eslint-disable-line
          return new SaveCalendarFailed(error);
        },
      })
    )
  );

  updateCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.UpdateCalendar),
      pessimisticUpdate({
        run: (action: UpdateCalendar) => {
          const { calendarUid, data } = action.payload;
          return this.calendarService
            .updateCalendarById(calendarUid, data)
            .pipe(map((res) => new UpdateCalendarSucceeded(res)));
        },
        //eslint-disable-next-line
        onError(action: UpdateCalendar, error) {
          return new UpdateCalendarFailed(error);
        },
      })
    )
  );

  deleteSelectedCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.DeleteSelectedCalendar),
      pessimisticUpdate({
        run: (action: DeleteSelectedCalendar) => {
          return this.calendarService
            .deleteCalendarByUid(action.calendarUid)
            .pipe(map((res) => new DeleteSelectedCalendarSucceeded(res)));
        },
        //eslint-disable-next-line
        onError(action: DeleteSelectedCalendar, error) {
          return new DeleteSelectedCalendarFailed(error);
        },
      })
    )
  );

  loadSelectedCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.LoadSelectedCalendar),
      fetch({
        run: (action: LoadSelectedCalendar) => {
          const { dashboardUid, calendarUid } = action.payload;
          return this.calendarService
            .getCalendarById(dashboardUid, calendarUid)
            .pipe(
              map(
                (res) =>
                  new SelectedCalendarLoaded(
                    res.data,
                    action.selectedInstanceId
                  )
              )
            );
        },
        onError: (action: LoadSelectedCalendar, error) => {
          return new SelectedCalendarLoadError(
            {
              message: error.message,
              status: error.status,
            },
            action.selectedInstanceId
          );
        },
      })
    )
  );

  saveCalendarQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.SaveCalendarQuery),
      pessimisticUpdate({
        run: (action: SaveCalendarQuery) => {
          return this.calendarService
            .saveCalendarQuery(action.calendarUid, action.payload)
            .pipe(map((res) => new SaveCalendarQuerySucceeded(res)));
        },
        //eslint-disable-next-line
        onError(action: SaveCalendarQuery, error) {
          return new SaveCalendarQueryFailed(error);
        },
      })
    )
  );

  updateCalendarQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.UpdateCalendarQuery),
      pessimisticUpdate({
        run: (action: UpdateCalendarQuery) => {
          const { calendarUid, calendarQueryUid, payload } = action;
          return this.calendarService
            .updateCalendarQuery(calendarUid, calendarQueryUid, payload)
            .pipe(map((res) => new UpdateCalendarQuerySucceeded(res)));
        },
        //eslint-disable-next-line
        onError(action: UpdateCalendarQuery, error) {
          return new UpdateCalendarQueryFailed(error);
        },
      })
    )
  );

  loadCalendarQueries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.LoadCalendarQueries),
      fetch({
        run: (action: LoadCalendarQueries) => {
          return this.calendarService
            .getCalendarQueriesByCalendarUid(action.calendarUid)
            .pipe(map((res) => new CalendarQueriesLoaded(res.data)));
        },
        //eslint-disable-next-line
        onError: (action: LoadCalendarQueries, error) => {
          const { message } = error;
          return new CalendarQueriesLoadError(message);
        },
      })
    )
  );

  deleteSelectedCalendarQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.DeleteSelectedCalendarQuery),
      pessimisticUpdate({
        run: (action: DeleteSelectedCalendarQuery) => {
          const { calendarUid, calendarQueryUid } = action;
          return this.calendarService
            .deleteCalendarQuery(calendarUid, calendarQueryUid)
            .pipe(map((res) => new DeleteSelectedCalendarQuerySucceeded(res)));
        },
        //eslint-disable-next-line
        onError(action: DeleteSelectedCalendarQuery, error) {
          return new DeleteSelectedCalendarQueryFailed(error);
        },
      })
    )
  );

  updateSelectedCalendarQueryActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.UpdateSelectedCalendarQueryActive),
      pessimisticUpdate({
        run: (action: UpdateSelectedCalendarQueryActive) => {
          const { calendarUid, calendarQueryUid, payload } = action;
          return this.calendarService
            .updateCalendarQueryActive(calendarUid, calendarQueryUid, payload)
            .pipe(
              map((res) => new UpdateSelectedCalendarQueryActiveSucceeded(res))
            );
        },
        //eslint-disable-next-line
        onError(action: UpdateSelectedCalendarQueryActive, error) {
          return new UpdateSelectedCalendarQueryActiveFailed(error);
        },
      })
    )
  );

  runCalendarQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.RunCalendarQuery),
      fetch({
        run: (action: RunCalendarQuery) => {
          return this.calendarService
            .runCalendarQuery(action.payload)
            .pipe(map((res) => new RunCalendarQuerySucceeded(res.data)));
        },
        //eslint-disable-next-line
        onError: (action: RunCalendarQuery, error) => {
          const { message } = error;
          return new RunCalendarQueryFailed(message);
        },
      })
    )
  );

  deleteMultipleCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.DeleteMultipleCalendar),
      pessimisticUpdate({
        run: (action: DeleteMultipleCalendar) => {
          const { selectedCalendarsUids } = action.payload;
          return this.calendarService
            .deleteMultipleCalendarsByUids(selectedCalendarsUids)
            .pipe(map((res) => new DeleteMultipleCalendarSucceeded(res)));
        },
        //eslint-disable-next-line
        onError(action: DeleteMultipleCalendar, error) {
          return new DeleteMultipleCalendarFailed(error);
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private calendarService: CalendarService,
    private inteleaseCommonService: InteleaseCommonService
  ) {}
}
