import {
  FullValMultiPdfProvViewModel,
  PartialValPdfProvisionViewModel,
  ProvisionFormStructureViewModel,
} from "@@intelease/api-models/adex-api-model-src";

export interface CategoryWithProvisions
  extends ProvisionFormStructureViewModel {
  provisions: FullValMultiPdfProvViewModel[];
}

export enum ProvisionOptionSourceEnum {
  CALCULATED = "CALCULATED",
  CALCULATED_MODIFY = "CALCULATED_MODIFY",
  TEMP_NEW_TAB = "TEMP_NEW_TAB",
  CUSTOMER = "CUSTOMER",
  DERIVED = "DERIVED",
  TEMP_LOCAL = "TEMP_LOCAL",
  CUSTOMER_DERIVED = "CUSTOMER_DERIVED",
  DERIVED_MODIFY = "DERIVED_MODIFY",
}

export interface DocumentNamesDictionary {
  [uid: string]: string;
}

// eslint-disable-next-line
export interface FormSchema {}

// eslint-disable-next-line
export interface FormModel {}

export interface AddressValue {
  streetAddress: string;
  suite: string;
  city: string;
  state: string | { value: string };
  country: string | { value: string };
  postalCode: string;
}

export interface MentionPosition {
  preMentionUid?: PartialValPdfProvisionViewModel["uid"];
  postMentionUid?: PartialValPdfProvisionViewModel["uid"];
}

export const MENTION_DELETED = "deleted";
