/* tslint:disable */
/* eslint-disable */
import { LogicalFilterInterfaceModel } from "./logical-filter-interface-model";
import { NamedSearchFieldModel } from "./named-search-field-model";
import { SortCriteriaModel } from "./sort-criteria-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateReportDtoModel")
export class CreateReportDtoModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("filters", LogicalFilterInterfaceModel, true)
  filters?: LogicalFilterInterfaceModel = undefined;

  @JsonProperty("generalViewColumns", [NamedSearchFieldModel], true)
  generalViewColumns?: Array<NamedSearchFieldModel> = [];

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("provisionViewColumns", [NamedSearchFieldModel], true)
  provisionViewColumns?: Array<NamedSearchFieldModel> = [];

  @JsonProperty("sorts", [SortCriteriaModel], true)
  sorts?: Array<SortCriteriaModel> = [];
}
