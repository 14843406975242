import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { AbstractEventModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("EventsHistoryAbstractModel")
export class EventsHistoryAbstractModel {
  static view = "events";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("abstractFilename", String)
  abstractFilename = "";

  @JsonProperty("abstractName", String)
  abstractName = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("events", [AbstractEventModel])
  events: AbstractEventModel[] = [];
}
