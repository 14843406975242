/* tslint:disable */
/* eslint-disable */
import { CustomTagViewModel } from "./custom-tag-view-model";
import { DocSetCategoryViewModel } from "./doc-set-category-view-model";
import { NodeNavigationResultModel } from "./node-navigation-result-model";
import { QueueInfoViewModel } from "./queue-info-view-model";
import { RecordLockInfoDtoModel } from "./record-lock-info-dto-model";
import { RecordPathModel } from "./record-path-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FormNameAbstractViewModel")
export class FormNameAbstractViewModel {
  @JsonProperty("abstractCreationDate", DateTimeConvertService, true)
  abstractCreationDate?: Date | undefined = undefined;

  @JsonProperty("abstractFilename", String, true)
  abstractFilename?: string = "";

  @JsonProperty("abstractName", String, true)
  abstractName?: string = "";

  @JsonProperty("abstractorCode", String, true)
  abstractorCode?: string = "";

  @JsonProperty("customTags", [CustomTagViewModel], true)
  customTags?: Array<CustomTagViewModel> = [];

  @JsonProperty("docSetCategory", DocSetCategoryViewModel, true)
  docSetCategory?: DocSetCategoryViewModel = undefined;

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("lockInfo", RecordLockInfoDtoModel, true)
  lockInfo?: RecordLockInfoDtoModel = undefined;

  @JsonProperty("nodeNavigation", NodeNavigationResultModel, true)
  nodeNavigation?: NodeNavigationResultModel = undefined;

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];

  @JsonProperty("provisionFormName", String, true)
  provisionFormName?: string = "";

  @JsonProperty("provisionFormUid", String, true)
  provisionFormUid?: string = "";

  @JsonProperty("queueInfoView", QueueInfoViewModel, true)
  queueInfoView?: QueueInfoViewModel = undefined;

  @JsonProperty("status", String, true)
  status?: string = "";

  @JsonProperty("suiteVersionAbstractId", RecordPathModel, true)
  suiteVersionAbstractId?: RecordPathModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
