import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("PermissionCheckItemModel")
export class PermissionCheckItemModel {
  @JsonProperty("domainUid", String)
  domainUid = "";

  @JsonProperty("domainType", String)
  domainType: "USER" | "ROLE" | "ACCOUNT" | undefined = undefined;

  @JsonProperty("hasAccess", Boolean)
  hasAccess = false;
}
