import { FinalAbstractViewCompleteModel } from "@@intelease/api-models/adex-api-model-src";

type ExtractArrayType<T> = T extends (infer U)[] ? U : never;

export type PermissionType = ExtractArrayType<
  FinalAbstractViewCompleteModel["permissions"]
>;

export enum Permission {
  EXPORT = "EXPORT",
  FULL_EXPORT = "FULL_EXPORT",
  LOCK = "LOCK",
  EXPLORE = "EXPLORE",
  COMMENT = "COMMENT",
  EDIT = "EDIT",
  COMPLETE = "COMPLETE",
  MOVE = "MOVE",
  SHARE = "SHARE",
  READ = "READ",
  DELETE = "DELETE",
}
