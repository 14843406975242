/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SignupUserDtoModel")
export class SignupUserDtoModel {
  @JsonProperty("email", String, true)
  email?: string = "";

  @JsonProperty("firstName", String, true)
  firstName?: string = "";

  @JsonProperty("lastName", String, true)
  lastName?: string = "";

  @JsonProperty("password", String, true)
  password?: string = "";
}
