/* tslint:disable */
/* eslint-disable */
import { UniqueSuiteIdModel } from "./unique-suite-id-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocSetPathModel")
export class DocSetPathModel {
  @JsonProperty("abstractId", String, true)
  abstractId?: string = "";

  @JsonProperty("docSetId", String, true)
  docSetId?: string = "";

  @JsonProperty("id", String, true)
  id?: string = "";

  @JsonProperty("uniqueSuiteId", UniqueSuiteIdModel, true)
  uniqueSuiteId?: UniqueSuiteIdModel = undefined;

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
