/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DomainIdWithPermissionGroupDtoModel")
export class DomainIdWithPermissionGroupDtoModel {
  @JsonProperty("domainType", Any, true)
  domainType?: "PUBLIC" | "ACCOUNT" | "ROLE" | "USER" | "UNREGISTERED_USER" =
    undefined;

  @JsonProperty("domainUid", String, true)
  domainUid?: string = "";

  @JsonProperty("permissionTypeGroup", Any, true)
  permissionTypeGroup?: "OWNER" | "EDITOR" | "COMMENTER" | "VIEWER" = undefined;
}
