export class INode {
  value: any;
  id: string;
  title: string;
  level: number;
  expandable? = true;
  icon?: string;
  children?: INode[];
  isLoading? = false;
}

export enum RecordCategoryUppercaseEnum {
  ALL = "ALL",
  DELETED = "DELETED",
  FAVORITES = "FAVORITES",
  OWNED = "OWNED",
  SHARED = "SHARED",
}
