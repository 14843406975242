import { Any, JsonObject, JsonProperty } from "json2typescript";
import { FullValProvisionValueModel } from "@@intelease/web/common/models/provision-value";

@JsonObject("HighlightedProvisionValueModel")
export class HighlightedProvisionValueModel {
  static readonly view = "fullVal";

  @JsonProperty("newProvision", FullValProvisionValueModel)
  newProvision: FullValProvisionValueModel = undefined;

  @JsonProperty("maybeExistingOption", Any)
  maybeExistingOption: any = undefined;

  @JsonProperty("textValue", String, true)
  textValue = "";

  @JsonProperty("pdfProvisionHighlights", [Any], true)
  pdfProvisionHighlights?: any[] = [];

  @JsonProperty("pdfMentionHighlights", [Any], true)
  pdfMentionHighlights?: any[] = [];
}
