/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PdfProvisionPartialValDtoModel")
export class PdfProvisionPartialValDtoModel {
  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  @JsonProperty("notes", String, true)
  notes?: string = "";

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  @JsonProperty("pdfHighlightIds", [String], true)
  pdfHighlightIds?: Array<string> = [];

  /**
   * the format of a structured value
   */

  @JsonProperty("provisionType", Any, true)
  provisionType?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;

  @JsonProperty("provisionValue", Any, true)
  provisionValue?: any = {};

  @JsonProperty("sectionHeader", String, true)
  sectionHeader?: string = "";

  @JsonProperty("selected", Boolean, true)
  selected?: boolean = undefined;

  @JsonProperty("source", Any, true)
  source?:
    | "UNLABELED"
    | "AUTO"
    | "AUTO_SNIPPET"
    | "AUTO_SELECTED"
    | "AUTO_NEW"
    | "DERIVED"
    | "CUSTOMER_MODIFY"
    | "CUSTOMER"
    | "CUSTOMER_AUTO_CHOICE"
    | "CUSTOMER_AUTO_CHOICE_AND_MODIFY"
    | "ADMIN_CORRECTED"
    | "CUSTOMER_HIGHLIGHTED"
    | "CUSTOMER_HIGHLIGHTED_AND_MODIFY"
    | "CALCULATED"
    | "CALCULATED_MODIFY"
    | "CUSTOMER_SNIPPET"
    | "CUSTOMER_SNIPPET_MODIFY"
    | "CUSTOMER_DERIVED"
    | "DERIVED_MODIFY"
    | "CUSTOMER_API"
    | "CUSTOMER_API_MODIFY" = undefined;

  @JsonProperty("textValue", String, true)
  textValue?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
