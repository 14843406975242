/* tslint:disable */
/* eslint-disable */

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AbstractNameSuggestionsDtoModel")
export class AbstractNameSuggestionsDtoModel {
  @JsonProperty("nameToNumber", Any, true)
  nameToNumber?: {
    [key: string]: number;
  } = {};

  @JsonProperty("numberPrefix", String, true)
  numberPrefix?: string = "";

  @JsonProperty("numberSuffix", String, true)
  numberSuffix?: string = "";
}
