import { has, keys } from "lodash";
import {
  FullValMultiPdfProvViewModel,
  MentionInconsistencyTypeDtoModel,
  PartialValPdfProvisionViewModel,
  ProvisionInconsistenciesApiDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { ProvisionOptionSourceEnum } from "@@intelease/web/abstract-review/src/lib/models/provision-review.model";

export class NestedProvisionUtils {
  static compareByIndexAndId(
    mention1: PartialValPdfProvisionViewModel,
    mention2: PartialValPdfProvisionViewModel
  ): number {
    const index1 = (mention1?.value as any)?.id || 0;
    const index2 = (mention2?.value as any)?.id || 0;
    if (index1 !== index2) {
      return index1 - index2;
    }
    const uid1 = mention1?.uid || "";
    const uid2 = mention2?.uid || "";
    if (uid1 !== uid2) {
      return uid1.localeCompare(uid2);
    }
    return 0;
  }

  static isAnchorProvision(
    provision: FullValMultiPdfProvViewModel,
    provisionInconsistencies: ProvisionInconsistenciesApiDtoModel
  ) {
    return (
      provision &&
      provision.provisionInfo.type === "NESTED_GROUP" &&
      provisionInconsistencies &&
      provision.provisionUid === provisionInconsistencies.anchorProvisionUid
    );
  }

  static checkAndGetInconsistentMentions(
    provision: FullValMultiPdfProvViewModel,
    provisionInconsistencies: ProvisionInconsistenciesApiDtoModel
  ): {
    mentionIndex: number;
    mentionUid: string;
  }[] {
    if (
      !provision ||
      provision.provisionInfo.type !== "NESTED_GROUP" ||
      !provisionInconsistencies ||
      provisionInconsistencies?.anchorProvisionUid === provision.provisionUid
    ) {
      return [];
    }
    return provision.multiplePdfProvision.options
      .slice()
      .filter((opt) => opt.source !== ProvisionOptionSourceEnum.TEMP_LOCAL)
      .filter(
        (opt) =>
          NestedProvisionUtils.getMentionInconsistencyErrors(
            provisionInconsistencies,
            provision.provisionUid,
            opt.uid
          ).length > 0
      )
      .sort(NestedProvisionUtils.compareByIndexAndId)
      .map((opt) => {
        return {
          mentionIndex: (opt.value as any).id,
          mentionUid: opt.uid,
        };
      });
  }

  static getMentionInconsistencyErrors(
    provisionInconsistencies: ProvisionInconsistenciesApiDtoModel,
    provisionUid: string,
    mentionUid: string
  ): MentionInconsistencyTypeDtoModel[] {
    if (
      !provisionInconsistencies ||
      !provisionInconsistencies?.anchorProvisionUid ||
      !provisionInconsistencies?.provisionUidToInconsistency ||
      provisionInconsistencies?.anchorProvisionUid === provisionUid
    ) {
      return [];
    }
    const provUidToInc = provisionInconsistencies?.provisionUidToInconsistency;
    if (provUidToInc && has(provUidToInc, provisionUid)) {
      const mentionUidToInc =
        provUidToInc[provisionUid].mentionUidToInconsistencies;
      if (
        mentionUidToInc &&
        has(mentionUidToInc, mentionUid) &&
        mentionUidToInc[mentionUid] &&
        mentionUidToInc[mentionUid].length
      ) {
        return mentionUidToInc[mentionUid];
      }
    }
    return [];
  }

  static setMentionInconsistencyErrors(
    provisionInconsistencies: ProvisionInconsistenciesApiDtoModel,
    provisionUid: string,
    mentionUid: string,
    inconsistencyErrors: MentionInconsistencyTypeDtoModel[]
  ): ProvisionInconsistenciesApiDtoModel {
    const provUidToInc = provisionInconsistencies?.provisionUidToInconsistency;
    const updatedInconsistencies: ProvisionInconsistenciesApiDtoModel = {
      ...provisionInconsistencies,
      provisionUidToInconsistency: {
        ...provUidToInc,
        [provisionUid]: {
          mentionUidToInconsistencies: {
            ...provUidToInc[provisionUid]?.mentionUidToInconsistencies,
            [mentionUid]: inconsistencyErrors,
          },
        },
      },
    };
    return updatedInconsistencies;
  }

  static mergeProvisionInconsistencies(
    oldData?: ProvisionInconsistenciesApiDtoModel,
    newData?: ProvisionInconsistenciesApiDtoModel
  ): ProvisionInconsistenciesApiDtoModel {
    if (!oldData) {
      return { ...newData };
    }
    if (!newData) {
      return { ...oldData };
    }
    let updatedValue = { ...oldData };
    if (newData.anchorProvisionUid) {
      updatedValue = {
        ...updatedValue,
        anchorProvisionUid: newData.anchorProvisionUid,
      };
    }

    if (newData.provisionUidToInconsistency) {
      const provisionUidKeys = keys(newData.provisionUidToInconsistency);
      provisionUidKeys.forEach((provisionUid) => {
        const mentionUidToInc =
          newData.provisionUidToInconsistency[provisionUid]
            .mentionUidToInconsistencies;
        if (mentionUidToInc) {
          const mentionUidKeys = keys(mentionUidToInc);
          mentionUidKeys.forEach((mentionUid) => {
            const errors = NestedProvisionUtils.getMentionInconsistencyErrors(
              newData,
              provisionUid,
              mentionUid
            );
            updatedValue = NestedProvisionUtils.setMentionInconsistencyErrors(
              updatedValue,
              provisionUid,
              mentionUid,
              errors
            );
          });
        }
      });
    }

    return updatedValue;
  }
}
