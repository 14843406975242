import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ThemeConfigInterface } from "@@intelease/web/intelease/interfaces";
import { cloneDeep, merge } from "lodash";
import { THEME_CONFIG } from "../../../../apps/ui/src/app/theme.config";

@Injectable({
  providedIn: "root",
})
export class ThemeConfigService {
  private _configSubject: BehaviorSubject<ThemeConfigInterface> =
    new BehaviorSubject<ThemeConfigInterface>(THEME_CONFIG);

  /**
   * set theme config
   * @param value
   * @param opts
   */
  setConfig(value, opts = { emitEvent: true }): void {
    let config = this._configSubject.getValue();

    config = merge({}, config, value);

    if (opts.emitEvent === true) {
      this._configSubject.next(config);
    }
  }

  /**
   * get theme config
   */
  getConfig(): Observable<ThemeConfigInterface> {
    return this._configSubject.asObservable();
  }

  /**
   * reset to default theme config
   */
  resetToDefaults(): void {
    this._configSubject.next(cloneDeep(THEME_CONFIG));
  }
}
