import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ListResponseModel } from "@@intelease/web/intelease/models";
import { MinimalUserModel } from "@@intelease/web/common/models/user";
import { FetchBatchUserService } from "./models/user/fetch/fetch-batch-user.service";
import { DocumentTypeModel } from "@@intelease/web/common/models";
import { LoggedInUserModel } from "@@intelease/web/common/models/user/loggedin-user.model";
import { LocalStorageKey } from "../../common/enums/local-storage.keys";
import { LocalStorageService } from "../../common/services";

@Injectable({
  providedIn: "root",
})
export class UserInfoService {
  constructor(private fetchBatchUserService: FetchBatchUserService) {}

  public static getUserUid(): string {
    return localStorage.getItem(LocalStorageKey.USER_UID);
  }

  public static getUserFullName(): string {
    return localStorage.getItem(LocalStorageKey.NAME);
  }

  public static setUserFullName(newName: string) {
    localStorage.setItem(LocalStorageKey.NAME, newName);
  }

  public static getUserEmail(): string {
    return localStorage.getItem(LocalStorageKey.USER_EMAIL);
  }

  public static getUserHash(): string {
    return localStorage.getItem(LocalStorageKey.USER_HASH);
  }

  public static getAccountUid(): string {
    return localStorage.getItem(LocalStorageKey.ACCOUNT_UID);
  }

  public static getAccountRole():
    | "ITLS_ADMIN"
    | "ADMIN"
    | "OWNER"
    | "USER"
    | undefined {
    return localStorage.getItem(LocalStorageKey.ACCOUNT_ROLE) as
      | "ITLS_ADMIN"
      | "ADMIN"
      | "OWNER"
      | "USER"
      | undefined;
  }

  public static getAccountRoleName(): string {
    return localStorage.getItem(LocalStorageKey.ACCOUNT_ROLE_NAME);
  }

  public static getAccountRoleRank(): number {
    return Number.parseInt(
      localStorage.getItem(LocalStorageKey.ACCOUNT_ROLE_RANK),
      10
    );
  }

  public static getLbxRoles(): LoggedInUserModel["lbxRoles"] {
    const lbxRoles = localStorage.getItem(LocalStorageKey.LBX_ROLES);
    if (lbxRoles) {
      return JSON.parse(lbxRoles) as LoggedInUserModel["lbxRoles"];
    }
    return [];
  }

  public static isLbxAccountUser(): boolean {
    return localStorage.getItem(LocalStorageKey.LBX_ACCOUNT_USER) === "true";
  }

  public static getDocSetCategories(): DocumentTypeModel[] {
    const docSetCategories = localStorage.getItem(
      LocalStorageKey.DOC_SET_CATEGORIES
    );
    if (docSetCategories) {
      return JSON.parse(docSetCategories);
    }
    return undefined;
  }

  public static currentUserHasHigherOrEqualRankThan(
    rank: "ITLS_ADMIN" | "ADMIN" | "OWNER" | "USER"
  ) {
    return (
      this.getRankForAccountRole(this.getAccountRole()) <=
      this.getRankForAccountRole(rank)
    );
  }

  private static getRankForAccountRole(
    rank: "ITLS_ADMIN" | "ADMIN" | "OWNER" | "USER" | undefined
  ) {
    switch (rank) {
      case "ITLS_ADMIN":
        return 1;
      case "OWNER":
        return 2;
      case "ADMIN":
        return 3;
      case "USER":
      default:
        return 4;
    }
  }

  public getAccountUsers(): Observable<ListResponseModel<MinimalUserModel>> {
    return this.fetchBatchUserService.getAccountUsers(
      UserInfoService.getAccountUid(),
      MinimalUserModel.view,
      MinimalUserModel
    );
  }

  public getOrganizationName(): string {
    const accountUid = UserInfoService.getAccountUid();
    if (accountUid === "Y2U4MTNjMTAwMGQzZWQwYjA4YWYxZWM1") {
      return "leaseprobe";
    }
    return "intelease";
  }
}
