/* tslint:disable */
/* eslint-disable */

/**
 * a snippet for a mention in a document
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SnippetModel")
export class SnippetModel {
  /**
   * the long richly formatted text for this snippet
   */

  @JsonProperty("longRichText", String, true)
  longRichText?: string = "";

  /**
   * the sentences surrounding the snippet
   */

  @JsonProperty("longValue", [String], true)
  longValue?: Array<string> = [];

  /**
   * the page number (1-based), mapped to the image snapshot of the mention on that page
   */

  @JsonProperty("pngSnippetAddresses", Any, true)
  pngSnippetAddresses?: {
    [key: string]: string;
  } = {};

  /**
   * the type of a snippet
   */

  @JsonProperty("type", Any, true)
  type?: "PNG" | "TEXT" = undefined;

  /**
   * the actual snippet value
   */

  @JsonProperty("value", Any, true)
  value?: any = {};
}
