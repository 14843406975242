<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="itls-drive-tree"
  style="background: #f5f6f7"
  [style]="
    contentMaxHeight
      ? { 'max-height': contentMaxHeight + 'px', 'overflow-y': 'auto' }
      : null
  "
>
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodePadding
    [class.tree-node-active]="node.id === selectedNodeUid"
    [class.tree-node-disabled]="
      disabledNodeUids?.includes(node.id) ||
      nonSelectableNodeUids?.includes(node.id)
    "
    [matTreeNodePaddingIndent]="8"
    (click)="onNodeClick(node)"
    (contextmenu)="onContextMenu(node, $event)"
  >
    <button
      mat-icon-button
      matTreeNodeToggle
      [disabled]="disabledNodeUids?.includes(node.id)"
      [attr.aria-label]="'toggle ' + node.filename"
    >
      <mat-icon
        class="mat-icon-rtl-mirror"
        [hidden]="disabledNodeUids?.includes(node.id)"
      >
        {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
      </mat-icon>
    </button>

    <div>
      <div class="parent-node" (contextmenu)="onContextMenu(node, $event)">
        <div>
          <itls-icon
            *ngIf="node.icon"
            [svgIcon]="node.icon"
            class="folder-icon"
          ></itls-icon>
        </div>
        <div *ngIf="rootNodeId === node.id">
          {{ node.title }}
        </div>
        <div *ngIf="rootNodeId !== node.id">{{ node.title }}</div>
      </div>
    </div>
  </mat-tree-node>
</mat-tree>

<span #emptyItem></span>

<div
  class="context-menu-container"
  *ngIf="showContextMenu"
  [matMenuTriggerFor]="contextMenu"
  #contextMenuTrigger="matMenuTrigger"
  [hidden]="!contextMenuPos"
  [ngStyle]="{
    'left.px': contextMenuPos?.x,
    'top.px': contextMenuPos?.y
  }"
>
  <mat-menu #contextMenu="matMenu" hasBackdrop="false">
    <button mat-menu-item (click)="onCreateNewFolderClicked()">
      <span class="title">New Folder</span>
    </button>
  </mat-menu>
</div>
