/* tslint:disable */
/* eslint-disable */
import { ParseHighlightSaveMentionApiDtoModel } from "./parse-highlight-save-mention-api-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqParseHighlightSaveMentionV2DtoModel")
export class OApiReqParseHighlightSaveMentionV2DtoModel {
  @JsonProperty("data", ParseHighlightSaveMentionApiDtoModel, true)
  data?: ParseHighlightSaveMentionApiDtoModel = undefined;
}
