import { Injectable } from "@angular/core";

import { select, Store } from "@ngrx/store";

import {
  CleanReportState,
  DeleteMultipleReport,
  DeleteSelectedReport,
  DuplicateSelectedReport,
  ExportSelectedReport,
  LoadAbstractsReport,
  LoadReportColumns,
  LoadReportFilterFields,
  LoadReports,
  LoadSelectedReport,
  RemoveSelectedColumns,
  RemoveSelectedFilters,
  SaveReport,
  SaveSelectedColumns,
  SaveSelectedFilters,
  UpdateSelectedReport,
} from "./reports.actions";
import {
  ReportsPartialState,
  reportsQuery,
  ReportsState,
} from "@@intelease/app-state/reports/src";
import { Observable } from "rxjs";
import { OApiReqCloneReportDtoModel } from "@@intelease/api-models/adex-api-model-src";

@Injectable()
export class ReportsFacade {
  reportListLoaded$ = this.store.pipe(select(reportsQuery.getReportListLoaded));
  reportList$ = this.store.pipe(select(reportsQuery.getAllReports));
  selectedReports$ = this.store.pipe(select(reportsQuery.getSelectedReports));
  reportColumnsLoaded$: Observable<boolean> = this.store.pipe(
    select(reportsQuery.getReportColumnsLoaded)
  );
  reportFilterFieldsLoaded$: Observable<boolean> = this.store.pipe(
    select(reportsQuery.getReportFilterFieldsLoaded)
  );
  filterFieldsList$: Observable<any[]> = this.store.pipe(
    select(reportsQuery.getFilterFieldsList)
  );
  groupedFilterProvisionList$: Observable<any[]> = this.store.pipe(
    select(reportsQuery.getGroupedFilterFieldsList)
  );
  abstractsReportLoaded$: Observable<boolean> = this.store.pipe(
    select(reportsQuery.getAbstractsReportLoaded)
  );
  abstractsReportLoadError$: Observable<boolean> = this.store.pipe(
    select(reportsQuery.getAbstractsReportLoadError)
  );
  abstractsReport$ = this.store.pipe(select(reportsQuery.getAbstractsReport));
  reportFieldsOperators$ = this.store.pipe(
    select(reportsQuery.getProvisionsOperator)
  );
  getFieldTypeToOperationAggregations$ = this.store.pipe(
    select(reportsQuery.getFieldTypeToOperationAggregations)
  );
  filterProvisions$ = this.store.pipe(select(reportsQuery.getFilterFields));
  getFilterFieldsMap$ = this.store.pipe(select(reportsQuery.getFilterFields));
  selectedReportFilters$ = this.store.pipe(
    select(reportsQuery.getSelectedReportFilters)
  );
  selectedReportColumns$ = this.store.pipe(
    select(reportsQuery.getSelectedReportColumns)
  );
  selectedReport$ = this.store.pipe(select(reportsQuery.getSelectedReport));
  selectedReportLoadError$ = this.store.pipe(
    select(reportsQuery.getSelectedReportLoadError)
  );
  getReportsQueryMap$ = this.store.pipe(
    select(reportsQuery.getReportsQueryMap)
  );
  isReportSaved$ = this.store.pipe(select(reportsQuery.getReportSaved));
  isSavingReport$ = this.store.pipe(select(reportsQuery.getReportSaving));
  selectedReportDeleteSucceeded$ = this.store.pipe(
    select(reportsQuery.getSelectedReportDeleteSucceeded)
  );
  selectedReportExportSucceeded$ = this.store.pipe(
    select(reportsQuery.getSelectedReportExportSucceeded)
  );

  isSelectedReportUpdatePending$ = this.store.pipe(
    select(reportsQuery.isSelectedReportUpdatePending)
  );
  selectedReportUpdateSucceeded$ = this.store.pipe(
    select(reportsQuery.getSelectedReportUpdateSucceeded)
  );
  getReportsMap$ = this.store.pipe(select(reportsQuery.getAbstractsReportMap));

  isMultipleReportDeletePending$ = this.store.pipe(
    select(reportsQuery.isMultipleReportDeletePending)
  );
  multipleReportDeleteSucceeded$ = this.store.pipe(
    select(reportsQuery.multipleReportDeleteSucceeded)
  );
  getReportBySelectedInstanceId$ = (uid: string) =>
    this.store.pipe(
      select(reportsQuery.getAbstractsReportByInstanceId, { uid })
    );

  constructor(private store: Store<ReportsPartialState>) {}

  loadReports(payload) {
    this.store.dispatch(new LoadReports(payload));
  }

  saveReport(payload) {
    this.store.dispatch(new SaveReport(payload));
  }

  loadSelectedReportById(
    dashboardUid: string,
    reportId: string,
    selectedInstanceId: string
  ) {
    this.store.dispatch(
      new LoadSelectedReport({ dashboardUid, reportId }, selectedInstanceId)
    );
  }

  loadReportColumns() {
    this.store.dispatch(new LoadReportColumns());
  }

  loadReportFilterFields() {
    this.store.dispatch(new LoadReportFilterFields());
  }

  loadAbstractsReport(
    payload,
    selectedInstanceId: string,
    dashboardUid?: string
  ) {
    this.store.dispatch(
      new LoadAbstractsReport(payload, selectedInstanceId, dashboardUid)
    );
  }

  saveSelectedColumns(payload) {
    this.store.dispatch(new SaveSelectedColumns(payload));
  }

  saveSelectedFilters(payload) {
    this.store.dispatch(new SaveSelectedFilters(payload));
  }

  removeSelectedFilters() {
    this.store.dispatch(new RemoveSelectedFilters());
  }

  removeSelectedColumns() {
    this.store.dispatch(new RemoveSelectedColumns());
  }

  removeSelectedColumnsAndFilters() {
    this.removeSelectedColumns();
    this.removeSelectedFilters();
  }

  cleanReportState(payload: ReportsState) {
    this.store.dispatch(new CleanReportState(payload));
  }

  deleteSelectedReport(payload: any) {
    this.store.dispatch(new DeleteSelectedReport(payload));
  }

  duplicateSelectedReport(payload: OApiReqCloneReportDtoModel) {
    this.store.dispatch(new DuplicateSelectedReport(payload));
  }

  exportSelectedReport(payload: any) {
    this.store.dispatch(new ExportSelectedReport(payload));
  }

  updateSelectedReport(reportId: string, payload: any, replace: boolean) {
    this.store.dispatch(new UpdateSelectedReport(reportId, payload, replace));
  }

  deleteMultipleReport(payload: { selectedReportsUids: string[] }) {
    this.store.dispatch(new DeleteMultipleReport(payload));
  }
}
