/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqCreateProvisionV1DtoModel } from "../models/o-api-req-create-provision-v-1-dto-model";
import { OApiReqCreateProvisionV2DtoModel } from "../models/o-api-req-create-provision-v-2-dto-model";
import { OApiRespFullProvisionViewModel } from "../models/o-api-resp-full-provision-view-model";
import { OApiRespMinimalProvisionViewModel } from "../models/o-api-resp-minimal-provision-view-model";
import { OApiRespMultiFullProvisionViewModel } from "../models/o-api-resp-multi-full-provision-view-model";
import { OApiRespMultiMinimalProvisionViewModel } from "../models/o-api-resp-multi-minimal-provision-view-model";
import { OApiRespMultiProvisionCategoryModel } from "../models/o-api-resp-multi-provision-category-model";
import { OApiRespProvisionMetadataModel } from "../models/o-api-resp-provision-metadata-model";
import { OApiRespProvisionsMetadataModel } from "../models/o-api-resp-provisions-metadata-model";
import { OApiRespProvisionsMetadataV2Model } from "../models/o-api-resp-provisions-metadata-v-2-model";
import { OApiSingleEntityNoDataResponseModel } from "../models/o-api-single-entity-no-data-response-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class ProvisionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation createDataField
   */
  static readonly CreateDataFieldPath = "/api/v2/provisions";

  /**
   * Create a single-format data field.
   *
   * If it succeeds, it returns status 200 and a view of the created data field. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDataField()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDataField$Response(params: {
    /**
     * The data provided by the user, for creating a data field.
     */
    body: OApiReqCreateProvisionV2DtoModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullProvisionViewModel
      | OApiRespMinimalProvisionViewModel
      | OApiSingleEntityNoDataResponseModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionService.CreateDataFieldPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullProvisionViewModel
            | OApiRespMinimalProvisionViewModel
            | OApiSingleEntityNoDataResponseModel
          >;
        })
      );
  }

  /**
   * Create a single-format data field.
   *
   * If it succeeds, it returns status 200 and a view of the created data field. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDataField$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDataField(params: {
    /**
     * The data provided by the user, for creating a data field.
     */
    body: OApiReqCreateProvisionV2DtoModel;
  }): Observable<
    | OApiRespFullProvisionViewModel
    | OApiRespMinimalProvisionViewModel
    | OApiSingleEntityNoDataResponseModel
  > {
    return this.createDataField$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullProvisionViewModel
            | OApiRespMinimalProvisionViewModel
            | OApiSingleEntityNoDataResponseModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionViewModel
            | OApiRespMinimalProvisionViewModel
            | OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation listProvisions
   */
  static readonly ListProvisionsPath = "/api/v1/provisions";

  /**
   * Get list of provisions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listProvisions()` instead.
   *
   * This method doesn't expect any request body.
   */
  listProvisions$Response(params: {
    view: "MINIMAL" | "FULL" | "EMBED" | "NONE";
  }): Observable<StrictHttpResponse<OApiRespMultiProvisionCategoryModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionService.ListProvisionsPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiProvisionCategoryModel>;
        })
      );
  }

  /**
   * Get list of provisions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listProvisions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listProvisions(params: {
    view: "MINIMAL" | "FULL" | "EMBED" | "NONE";
  }): Observable<OApiRespMultiProvisionCategoryModel> {
    return this.listProvisions$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiProvisionCategoryModel>) =>
          r.body as OApiRespMultiProvisionCategoryModel
      )
    );
  }

  /**
   * Path part for operation createProvision
   */
  static readonly CreateProvisionPath = "/api/v1/provisions";

  /**
   * Create a dataField that allows multiple formats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProvision()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProvision$Response(params?: {
    body?: OApiReqCreateProvisionV1DtoModel;
  }): Observable<
    StrictHttpResponse<
      OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionService.CreateProvisionPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >;
        })
      );
  }

  /**
   * Create a dataField that allows multiple formats.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createProvision$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProvision(params?: {
    body?: OApiReqCreateProvisionV1DtoModel;
  }): Observable<
    OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
  > {
    return this.createProvision$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionViewModel
            | OApiRespMinimalProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation toggleProvisionStatus
   */
  static readonly ToggleProvisionStatusPath =
    "/api/v1/provision/toggleStatus/{provisionUid}";

  /**
   * Change status of a provision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleProvisionStatus()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  toggleProvisionStatus$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionService.ToggleProvisionStatusPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >;
        })
      );
  }

  /**
   * Change status of a provision.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `toggleProvisionStatus$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  toggleProvisionStatus(params?: {
    body?: RequestModel;
  }): Observable<
    OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
  > {
    return this.toggleProvisionStatus$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionViewModel
            | OApiRespMinimalProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation getProvision1
   */
  static readonly GetProvision1Path = "/api/v1/provisions/{provisionUid}";

  /**
   * Get a provision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProvision1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvision1$Response(params: {
    view: "MINIMAL" | "FULL" | "EMBED" | "NONE";
  }): Observable<
    StrictHttpResponse<
      OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionService.GetProvision1Path,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >;
        })
      );
  }

  /**
   * Get a provision.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProvision1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvision1(params: {
    view: "MINIMAL" | "FULL" | "EMBED" | "NONE";
  }): Observable<
    OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
  > {
    return this.getProvision1$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            OApiRespFullProvisionViewModel | OApiRespMinimalProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionViewModel
            | OApiRespMinimalProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation getProvisionMetadata
   */
  static readonly GetProvisionMetadataPath =
    "/api/v1/provisionMetadatas/{provisionUid}";

  /**
   * Get the metadatas (e.g. units, possible values) for a provision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProvisionMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionMetadata$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespProvisionMetadataModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionService.GetProvisionMetadataPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespProvisionMetadataModel>;
        })
      );
  }

  /**
   * Get the metadatas (e.g. units, possible values) for a provision.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProvisionMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionMetadata(params?: {}): Observable<OApiRespProvisionMetadataModel> {
    return this.getProvisionMetadata$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespProvisionMetadataModel>) =>
          r.body as OApiRespProvisionMetadataModel
      )
    );
  }

  /**
   * Path part for operation getProvisionMetadataV2
   */
  static readonly GetProvisionMetadataV2Path =
    "/api/v2/provision-metadatas/{provisionUid}";

  /**
   * Get the metadatas (e.g. units, possible values) for a provision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProvisionMetadataV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionMetadataV2$Response(params: {
    /**
     * The unique id of the provision.
     */
    provisionUids: string;
  }): Observable<StrictHttpResponse<OApiRespProvisionMetadataModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionService.GetProvisionMetadataV2Path,
      "get"
    );
    if (params) {
      rb.path("provisionUids", params.provisionUids, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespProvisionMetadataModel>;
        })
      );
  }

  /**
   * Get the metadatas (e.g. units, possible values) for a provision.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProvisionMetadataV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionMetadataV2(params: {
    /**
     * The unique id of the provision.
     */
    provisionUids: string;
  }): Observable<OApiRespProvisionMetadataModel> {
    return this.getProvisionMetadataV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespProvisionMetadataModel>) =>
          r.body as OApiRespProvisionMetadataModel
      )
    );
  }

  /**
   * Path part for operation getProvisionMetadatas
   */
  static readonly GetProvisionMetadatasPath = "/api/v1/provisionMetadatas";

  /**
   * Get the metadatas (e.g. units, possible values) for all provisions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProvisionMetadatas()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionMetadatas$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespProvisionsMetadataModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionService.GetProvisionMetadatasPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespProvisionsMetadataModel>;
        })
      );
  }

  /**
   * Get the metadatas (e.g. units, possible values) for all provisions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProvisionMetadatas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionMetadatas(params?: {}): Observable<OApiRespProvisionsMetadataModel> {
    return this.getProvisionMetadatas$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespProvisionsMetadataModel>) =>
          r.body as OApiRespProvisionsMetadataModel
      )
    );
  }

  /**
   * Path part for operation getProvisionMetadatasV2
   */
  static readonly GetProvisionMetadatasV2Path = "/api/v2/provision-metadatas";

  /**
   * Get the metadatas (e.g. units, possible values) for all provisions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProvisionMetadatasV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionMetadatasV2$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespProvisionsMetadataV2Model>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionService.GetProvisionMetadatasV2Path,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespProvisionsMetadataV2Model>;
        })
      );
  }

  /**
   * Get the metadatas (e.g. units, possible values) for all provisions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProvisionMetadatasV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionMetadatasV2(params?: {}): Observable<OApiRespProvisionsMetadataV2Model> {
    return this.getProvisionMetadatasV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespProvisionsMetadataV2Model>) =>
          r.body as OApiRespProvisionsMetadataV2Model
      )
    );
  }

  /**
   * Path part for operation searchProvisions
   */
  static readonly SearchProvisionsPath = "/api/v2/search/provisions";

  /**
   * Search over provisions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchProvisions()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  searchProvisions$Response(params: {
    view: "MINIMAL" | "FULL" | "EMBED" | "NONE";
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiFullProvisionViewModel
      | OApiRespMultiMinimalProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionService.SearchProvisionsPath,
      "post"
    );
    if (params) {
      rb.query("view", params.view, {});
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiFullProvisionViewModel
            | OApiRespMultiMinimalProvisionViewModel
          >;
        })
      );
  }

  /**
   * Search over provisions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchProvisions$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  searchProvisions(params: {
    view: "MINIMAL" | "FULL" | "EMBED" | "NONE";
    body?: RequestModel;
  }): Observable<
    OApiRespMultiFullProvisionViewModel | OApiRespMultiMinimalProvisionViewModel
  > {
    return this.searchProvisions$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiFullProvisionViewModel
            | OApiRespMultiMinimalProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespMultiFullProvisionViewModel
            | OApiRespMultiMinimalProvisionViewModel
      )
    );
  }
}
