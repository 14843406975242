import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { BreadcrumbItemModel } from "@@intelease/web/intelease/components/toolbar/breadcrumb/models";
import { ITLS_BREADCRUMS } from "@@intelease/web/intelease/components/toolbar/breadcrumb/itls-breadcrumb.const";
import { NodeNavigationModel } from "@@intelease/web/common/models";
import { DriveNodeType } from "../../../../../app-state/drive/src";
import { NodeNavigationResultModel } from "@@intelease/api-models/adex-api-model-src";

@Injectable({
  providedIn: "root",
})
export class ItlsBreadcrumbService {
  breadcrumbChange: Subject<{ key: string; payload: any }> = new Subject<{
    key: string;
    payload: any;
  }>();
  addBreadcrumb: Subject<{ key: string; payload: any }> = new Subject<{
    key: string;
    payload: any;
  }>();

  clearBreadcrumb: Subject<any> = new Subject<any>();

  recreateBreadcrumb$: Subject<BreadcrumbItemModel[]> = new Subject<
    BreadcrumbItemModel[]
  >();

  updateBreadcrumb(key: string, payload) {
    this.breadcrumbChange.next({ key, payload });
  }

  recreateBreadcrumb(breadcrumbItems: BreadcrumbItemModel[]) {
    this.recreateBreadcrumb$.next([...breadcrumbItems]);
  }

  recreateBreadcrumbByNodeCategory(
    category: NodeNavigationResultModel["category"],
    breadcrumbItems: BreadcrumbItemModel[]
  ) {
    const breadcrumbItemsWithCategory: BreadcrumbItemModel[] = [];
    breadcrumbItemsWithCategory.push(
      this.createBreadcrumbFromCategory(category)
    );
    breadcrumbItemsWithCategory.push(...breadcrumbItems);
    this.recreateBreadcrumb$.next(breadcrumbItemsWithCategory);
  }

  recreateBreadcrumbByBreadcrumbItems(breadcrumbItems: BreadcrumbItemModel[]) {
    const breadcrumbItemsWithCategory: BreadcrumbItemModel[] = [];
    breadcrumbItemsWithCategory.push(...breadcrumbItems);
    this.recreateBreadcrumb$.next(breadcrumbItemsWithCategory);
  }

  recreateBreadcrumbByNodeNavigation(nodeNavigation: NodeNavigationModel) {
    if (nodeNavigation) {
      const breadcrumbItems: BreadcrumbItemModel[] = [];
      breadcrumbItems.push(
        this.createBreadcrumbFromCategory(nodeNavigation.category)
      );
      nodeNavigation.navigations.forEach((navigation) => {
        breadcrumbItems.push(
          new BreadcrumbItemModel({
            title: navigation.name,
            key: navigation.uid,
            link:
              navigation.type === DriveNodeType.DIRECTORY
                ? `/drive/folders/${navigation.uid}`
                : null,
          })
        );
      });
      this.recreateBreadcrumb$.next(breadcrumbItems);
    }
  }

  private createBreadcrumbFromCategory(
    category: NodeNavigationResultModel["category"]
  ): BreadcrumbItemModel {
    switch (category) {
      case "OWNED":
        return new BreadcrumbItemModel({
          title: ITLS_BREADCRUMS.DRIVE_OWNED.title,
          key: "DRIVE_OWNED",
          link: ITLS_BREADCRUMS.DRIVE_OWNED.link,
        });
      case "SHARED":
        return new BreadcrumbItemModel({
          title: ITLS_BREADCRUMS.DRIVE_SHARED.title,
          key: "DRIVE_SHARED",
          link: ITLS_BREADCRUMS.DRIVE_SHARED.link,
        });
      case "DELETED":
        return new BreadcrumbItemModel({
          title: ITLS_BREADCRUMS.DRIVE_DELETED.title,
          key: "DRIVE_DELETED",
          link: ITLS_BREADCRUMS.DRIVE_DELETED.link,
        });
      case "ALL":
        return new BreadcrumbItemModel({
          title: ITLS_BREADCRUMS.DRIVE_ALL.title,
          key: "DRIVE_ALL",
          link: ITLS_BREADCRUMS.DRIVE_ALL.link,
        });
      case "DOCUMENTS":
        return new BreadcrumbItemModel({
          title: ITLS_BREADCRUMS.DRIVE_DOCUMENTS.title,
          key: "DRIVE_DOCUMENTS",
          link: ITLS_BREADCRUMS.DRIVE_DOCUMENTS.link,
        });
      case "GOOGLE_DRIVE":
        return new BreadcrumbItemModel({
          title: ITLS_BREADCRUMS.GOOGLE_DRIVE.title,
          key: "GOOGLE_DRIVE",
          link: ITLS_BREADCRUMS.GOOGLE_DRIVE.link,
        });
    }
  }
}
