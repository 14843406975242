/* tslint:disable */
/* eslint-disable */
import { MapPageHighlightsViewModel } from "./map-page-highlights-view-model";
import { PolarPdfDocSummaryViewInfoModel } from "./polar-pdf-doc-summary-view-info-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ActualDocPdfViewModel")
export class ActualDocPdfViewModel {
  @JsonProperty("annotationInfo", Any, true)
  annotationInfo?: {
    [key: string]: string;
  } = {};

  @JsonProperty("attachments", Any, true)
  attachments?: {
    [key: string]: string;
  } = {};

  @JsonProperty("docInfo", PolarPdfDocSummaryViewInfoModel, true)
  docInfo?: PolarPdfDocSummaryViewInfoModel = undefined;

  @JsonProperty("pageMetas", Any, true)
  pageMetas?: {
    [key: string]: MapPageHighlightsViewModel;
  } = {};

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
