import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { OwnerModel } from "@@intelease/web/common/models/drive/owner-model";
import { NodeSearchResultNavigationModel } from "@@intelease/web/common/models/drive/node-search-result-navigation.model";
import { CustomTagViewModel } from "../../../../api-models/adex-api-model-src/models/custom-tag-view-model";

@JsonObject("NodeAdvancedSearchResultModel")
export class NodeAdvancedSearchResultModel {
  static view = "full";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("status", String, true)
  status = "";

  @JsonProperty("owner", OwnerModel)
  owner?: OwnerModel = undefined;

  @JsonProperty("createdAt", DateTimeConvertService)
  createdAt?: Date = undefined;

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate?: Date = undefined;

  /**
   * Possible values:
   *  "RECORD",
   *  "DIRECTORY"
   */
  @JsonProperty("type", String)
  type?: "RECORD" | "DIRECTORY" | undefined = undefined;

  @JsonProperty("shared", Boolean)
  shared = false;

  @JsonProperty("navigation", NodeSearchResultNavigationModel)
  navigation?: NodeSearchResultNavigationModel = undefined;

  @JsonProperty("permissions", [String], true)
  permissions?: (
    | "SHARE"
    | "DELETE"
    | "COMMENT"
    | "MOVE"
    | "EXPORT"
    | "EDIT"
    | "READ"
  )[] = [];

  @JsonProperty("queueName", String)
  queueName?: string;

  @JsonProperty("customTags", [CustomTagViewModel])
  customTags?: CustomTagViewModel[];
}
