import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";

@Component({
  selector: "intelease-edit-calendar",
  templateUrl: "./edit-calendar.component.html",
  styleUrls: ["./edit-calendar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCalendarComponent {
  calendarUid: string;
  mode = ComponentModeEnum;
  model;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData,
    public dialogRef: MatDialogRef<EditCalendarComponent>
  ) {
    const { calendarQuery, calendarUid } = this.modalData;
    this.model = calendarQuery;
    this.calendarUid = calendarUid;
  }

  onFilterQueryUpdated() {
    this.dialogRef.close(true);
  }
}
