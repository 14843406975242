/* tslint:disable */
/* eslint-disable */
import { DefaultDataFieldUnitsDtoModel } from "./default-data-field-units-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EditQueueProcessingConfigDtoModel")
export class EditQueueProcessingConfigDtoModel {
  @JsonProperty("defaultDataFieldUnits", DefaultDataFieldUnitsDtoModel, true)
  defaultDataFieldUnits?: DefaultDataFieldUnitsDtoModel = undefined;

  @JsonProperty("isDataFieldUnitsChanged", Boolean)
  isDataFieldUnitsChanged: boolean = undefined;

  @JsonProperty("isProvisionFormChanged", Boolean)
  isProvisionFormChanged: boolean = undefined;

  @JsonProperty("provisionFormUid", String, true)
  provisionFormUid?: string = "";
}
