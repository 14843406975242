/* tslint:disable */
/* eslint-disable */
import { EditMentionValueApiDtoModel } from "./edit-mention-value-api-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqEditMentionValueV2DtoModel")
export class OApiReqEditMentionValueV2DtoModel {
  @JsonProperty("data", EditMentionValueApiDtoModel, true)
  data?: EditMentionValueApiDtoModel = undefined;
}
