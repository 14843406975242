/* tslint:disable */
/* eslint-disable */
import { EntityViewModel } from "./entity-view-model";
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";
import { TaskAssigneeDtoModel } from "./task-assignee-dto-model";
import { TaskStatusDtoModel } from "./task-status-dto-model";
import { UserViewModel } from "./user-view-model";

/**
 * a minimal representation of a record's task
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullFinalAbstractTaskDtoModel")
export class FullFinalAbstractTaskDtoModel {
  @JsonProperty("assignee", TaskAssigneeDtoModel, true)
  assignee?: TaskAssigneeDtoModel = undefined;

  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdBy", UserViewModel, true)
  createdBy?: UserViewModel = undefined;

  /**
   * the task's description
   */

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("dueDate", SimpleOpenApiDateModel, true)
  dueDate?: SimpleOpenApiDateModel = undefined;

  /**
   * the entities associated with this task
   */

  @JsonProperty("entities", [EntityViewModel], true)
  entities?: Array<EntityViewModel> = [];

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("notes", SimpleOpenApiDateModel, true)
  notes?: SimpleOpenApiDateModel = undefined;

  @JsonProperty("priority", Any, true)
  priority?: "LOW" | "MEDIUM" | "HIGH" = undefined;

  @JsonProperty("status", TaskStatusDtoModel, true)
  status?: TaskStatusDtoModel = undefined;

  /**
   * the task's tags
   */

  @JsonProperty("tags", [String], true)
  tags?: Array<string> = [];

  @JsonProperty("type", Any, true)
  type?: "REVIEW" | "APPROVE" | "OTHER" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  /**
   * when the task was last updated
   */

  @JsonProperty("updatedAt", DateTimeConvertService, true)
  updatedAt?: Date | undefined = undefined;

  @JsonProperty("updatedBy", UserViewModel, true)
  updatedBy?: UserViewModel = undefined;
}
