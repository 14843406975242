/* tslint:disable */
/* eslint-disable */
import { UserDtoModel } from "./user-dto-model";

/**
 * Details about second review
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("StageCompletionInfoDtoModel")
export class StageCompletionInfoDtoModel {
  @JsonProperty("doneAt", DateTimeConvertService, true)
  doneAt?: Date | undefined = undefined;

  @JsonProperty("doneByUser", UserDtoModel, true)
  doneByUser?: UserDtoModel = undefined;
}
