import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";

@JsonObject("MinimalAbstractModel")
export class MinimalAbstractModel {
  static view = "minimal";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("version", Number)
  version = 0;

  @JsonProperty("versions", [Number])
  versions: number[] = [];

  @JsonProperty("abstractFilename", String)
  abstractFilename = "";

  @JsonProperty("abstractName", String)
  abstractName = "";

  @JsonProperty("provisionFormUid", String)
  provisionFormUid = "";

  @JsonProperty("status", String)
  status = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;
}
