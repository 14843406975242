import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import {
  MinimalUserModel,
  ProfileUserModel,
} from "@@intelease/web/common/models/user";
import { FetchUserService } from "@@intelease/web/intelease/services/models/user/fetch/fetch-user.service";
import { EditProfileUserModel } from "@@intelease/web/common/models/user/edit-profile-user.model";
import { EditUserService } from "@@intelease/web/intelease/services/models";
import { UserInfoService } from "@@intelease/web/intelease/services";
import { HttpClient } from "@angular/common/http";
import { ProfileUserViewModel } from "@@intelease/api-models/adex-api-model-src";

@Injectable({
  providedIn: "root",
})
export class UserProfileService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly USERS_URL = "/users";
  private static readonly PROFILE_IMAGE = "/profileImage";

  private readonly userFullNameChanged$: Subject<string> =
    new Subject<string>();

  constructor(
    private fetchUserService: FetchUserService,
    private editUserService: EditUserService,
    private httpClient: HttpClient
  ) {}

  getProfileUserModel(uid: string): Observable<ProfileUserViewModel> {
    return this.fetchUserService.getUserView(
      uid,
      ProfileUserModel.view,
      ProfileUserViewModel
    );
  }

  getMinimalUserModel(uid: string): Observable<MinimalUserModel> {
    return this.fetchUserService.getUserView(
      uid,
      MinimalUserModel.view,
      MinimalUserModel
    );
  }

  editProfileUserModel(
    editProfileUserModel: EditProfileUserModel
  ): Observable<any> {
    return this.editUserService.editProfile(editProfileUserModel);
  }

  editProfileImage(image: File): Observable<any> {
    const formData = new FormData();

    formData.append("image", image);

    return this.httpClient.post(
      `${UserProfileService.API_VERSION_1}${UserProfileService.USERS_URL}${UserProfileService.PROFILE_IMAGE}`,
      formData
    );
  }

  getProfileImage(): Observable<any> {
    return this.httpClient.get(
      `${UserProfileService.API_VERSION_1}${UserProfileService.USERS_URL}${UserProfileService.PROFILE_IMAGE}`,
      {
        responseType: "blob",
      }
    );
  }

  getProfileImageForUser(userUid: string): Observable<any> {
    return this.httpClient.get(
      `${UserProfileService.API_VERSION_1}${UserProfileService.USERS_URL}/${userUid}${UserProfileService.PROFILE_IMAGE}`,
      {
        responseType: "blob",
      }
    );
  }

  deleteProfileImage(): Observable<void> {
    return this.httpClient.delete<void>(
      `${UserProfileService.API_VERSION_1}${UserProfileService.USERS_URL}${UserProfileService.PROFILE_IMAGE}`
    );
  }

  onUserFullNameChanged(): Observable<string> {
    return this.userFullNameChanged$.asObservable();
  }

  changeUserFullName(newName: string) {
    UserInfoService.setUserFullName(newName);
    this.userFullNameChanged$.next(newName);
  }
}
