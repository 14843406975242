import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { fetch, pessimisticUpdate } from "@ngrx/router-store/data-persistence";
import {
  LoadKanban,
  KanbanLoaded,
  KanbanLoadError,
  KanbanActionTypes,
  SaveKanban,
  SaveKanbanSucceeded,
  SaveKanbanFailed,
  LoadSelectedKanban,
  SelectedKanbanLoaded,
  SelectedKanbanLoadError,
  UpdateSelectedKanban,
  UpdateSelectedKanbanSucceeded,
  UpdateSelectedKanbanFailed,
  LoadSelectedKanbanIssue,
  SelectedKanbanIssueLoaded,
  SelectedKanbanIssueLoadError,
  LoadSelectedKanbanIssueTasks,
  SelectedKanbanIssueTasksLoaded,
  SelectedKanbanIssueTasksLoadError,
  LoadSelectedKanbanIssueHistory,
  SelectedKanbanIssueHistoryLoaded,
  SelectedKanbanIssueHistoryLoadError,
  SaveKanbanComment,
  SaveKanbanCommentSucceeded,
  SaveKanbanCommentFailed,
  DeleteKanbanComment,
  DeleteKanbanCommentSucceeded,
  DeleteKanbanCommentFailed,
  UpdateKanbanComment,
  UpdateKanbanCommentSucceeded,
  UpdateKanbanCommentFailed,
  DeleteKanbanIssue,
  DeleteKanbanIssueFailed,
  DeleteKanbanIssueSucceeded,
} from "./kanban.actions";
import { map } from "rxjs/operators";
import { KanbanService } from "@@intelease/app-services/kanban";

@Injectable()
export class KanbanEffects {
  loadKanban$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.LoadKanban),
      fetch({
        run: () => {
          // Your custom REST 'load' logic goes here. For now just return an empty list...
          return new KanbanLoaded([]);
        },

        onError: (action: LoadKanban, error) => {
          console.error("Error", error);
          return new KanbanLoadError(error);
        },
      })
    )
  );

  saveKanban$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.SaveKanban),
      pessimisticUpdate({
        run: (action: SaveKanban) => {
          return this.kanbanService
            .saveKanbanBoard(action.payload)
            .pipe(map((res) => new SaveKanbanSucceeded(res.data)));
        },
        onError(action: SaveKanban, error) {
          return new SaveKanbanFailed(error);
        },
      })
    )
  );

  loadSelectedKanban$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.LoadSelectedKanban),
      fetch({
        run: (action: LoadSelectedKanban) => {
          const { dashboardUid, projectUid, kanbanUid, selectedInstanceId } =
            action;
          return this.kanbanService
            .getKanbanBoard(dashboardUid, projectUid, kanbanUid)
            .pipe(
              map((res) => {
                return new SelectedKanbanLoaded(
                  {
                    ...res.data,
                    issueLists: res.data.issueLists || [],
                  },
                  selectedInstanceId
                );
              })
            );
        },
        onError(action: LoadSelectedKanban, error) {
          return new SelectedKanbanLoadError(error, action.selectedInstanceId);
        },
      })
    )
  );

  updateSelectedKanban$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.UpdateSelectedKanban),
      pessimisticUpdate({
        run: (action: UpdateSelectedKanban) => {
          const { projectUid, kanbanUid, payload } = action;
          return this.kanbanService
            .updateKanbanBoard(projectUid, kanbanUid, payload)
            .pipe(map((res) => new UpdateSelectedKanbanSucceeded(res.data)));
        },
        onError(action: UpdateSelectedKanban, error) {
          return new UpdateSelectedKanbanFailed(error);
        },
      })
    )
  );

  loadSelectedKanbanIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.LoadSelectedKanbanIssue),
      fetch({
        run: (action: LoadSelectedKanbanIssue) => {
          const {
            dashboardUid,
            projectUid,
            kabanBoardUid,
            issueListUid,
            issueUid,
          } = action;
          return this.kanbanService
            .getKanbanBoardIssueDetail(
              dashboardUid,
              projectUid,
              kabanBoardUid,
              issueListUid,
              issueUid
            )
            .pipe(map((res) => new SelectedKanbanIssueLoaded(res.data)));
        },
        onError(action: LoadSelectedKanbanIssue, error) {
          return new SelectedKanbanIssueLoadError(error);
        },
      })
    )
  );

  loadSelectedKanbanIssueTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.LoadSelectedKanbanIssueTasks),
      fetch({
        run: (action: LoadSelectedKanbanIssueTasks) => {
          const { projectUid, kabanBoardUid, issueListUid, issueUid } = action;
          return this.kanbanService
            .getKanbanBoardIssueTasks(
              projectUid,
              kabanBoardUid,
              issueListUid,
              issueUid
            )
            .pipe(map((res) => new SelectedKanbanIssueTasksLoaded(res)));
        },
        onError(action: LoadSelectedKanbanIssueTasks, error) {
          return new SelectedKanbanIssueTasksLoadError(error);
        },
      })
    )
  );

  loadSelectedKanbanIssueHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.LoadSelectedKanbanIssueHistory),
      fetch({
        run: (action: LoadSelectedKanbanIssueHistory) => {
          const { projectUid, kabanBoardUid, issueListUid, issueUid } = action;
          return this.kanbanService
            .getKanbanBoardIssueHistory(
              projectUid,
              kabanBoardUid,
              issueListUid,
              issueUid
            )
            .pipe(map((res) => new SelectedKanbanIssueHistoryLoaded(res)));
        },
        onError(action: LoadSelectedKanbanIssueHistory, error) {
          return new SelectedKanbanIssueHistoryLoadError(error);
        },
      })
    )
  );

  deleteKanbanIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.DeleteKanbanIssue),
      pessimisticUpdate({
        run: (action: DeleteKanbanIssue) => {
          const { projectUid, kanbanBoardUid, issueListUid, issueUid } = action;
          return new DeleteKanbanIssueSucceeded({
            projectUid,
            kanbanBoardUid,
            issueListUid,
            issueUid,
          });
        },
        onError(action: DeleteKanbanIssue, error) {
          return new DeleteKanbanIssueFailed(error);
        },
      })
    )
  );

  saveKanbanComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.SaveKanbanComment),
      pessimisticUpdate({
        run: (action: SaveKanbanComment) => {
          const {
            projectUid,
            kabanBoardUid,
            issueListUid,
            issueUid,
            text,
            elements,
          } = action;
          return this.kanbanService
            .saveKanbanComment(
              projectUid,
              kabanBoardUid,
              issueListUid,
              issueUid,
              text,
              elements
            )
            .pipe(map((res) => new SaveKanbanCommentSucceeded(res)));
        },
        onError(action: SaveKanbanComment, error) {
          return new SaveKanbanCommentFailed(error);
        },
      })
    )
  );

  deleteKanbanComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.DeleteKanbanComment),
      pessimisticUpdate({
        run: (action: DeleteKanbanComment) => {
          const {
            projectUid,
            kabanBoardUid,
            issueListUid,
            issueUid,
            commentUid,
          } = action;
          return this.kanbanService
            .deleteKanbanComment(
              projectUid,
              kabanBoardUid,
              issueListUid,
              issueUid,
              commentUid
            )
            .pipe(map((res) => new DeleteKanbanCommentSucceeded(res)));
        },
        onError(action: DeleteKanbanComment, error) {
          return new DeleteKanbanCommentFailed(error);
        },
      })
    )
  );

  updateKanbanComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KanbanActionTypes.UpdateKanbanComment),
      pessimisticUpdate({
        run: (action: UpdateKanbanComment) => {
          const {
            projectUid,
            kabanBoardUid,
            issueListUid,
            issueUid,
            commentUid,
            text,
            elements,
          } = action;
          return this.kanbanService
            .updateKanbanComment(
              projectUid,
              kabanBoardUid,
              issueListUid,
              issueUid,
              commentUid,
              text,
              elements
            )
            .pipe(map((res) => new UpdateKanbanCommentSucceeded(res)));
        },
        onError(action: UpdateKanbanComment, error) {
          return new UpdateKanbanCommentFailed(error);
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private readonly kanbanService: KanbanService
  ) {}
}
