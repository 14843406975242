import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Injectable()
export class NgxTranslateService {
  private readonly defaultLang: string = "en";

  constructor(private translate: TranslateService) {}

  setup() {
    this.translate.addLangs(["fa", "en"]);
    // this.translate.setDefaultLang('fa');
    // this.translate.currentLang = 'fa';

    const browserLang = this.translate.getBrowserLang();
    this.translate.use(
      browserLang.match(/en|fa/) ? browserLang : this.defaultLang
    );
  }

  getTranslateByKey(translateKey, params): Observable<any> {
    return this.translate.get(translateKey, params);
  }
}
