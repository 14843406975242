import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatListModule } from "@angular/material/list";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRippleModule } from "@angular/material/core";
import { NgScrollbarModule } from "ngx-scrollbar";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { SchemaFormModule } from "@@intelease/schema-form";
import { MatExpansionModule } from "@angular/material/expansion";
import { AbstractColumnsComponent } from "./abstract-columns.component";
import { SidebarModule } from "../../../sidebar/sidebar.module";
import { NgPipesModule } from "ngx-pipes";

const UI_MODULES = [SidebarModule];

const MATERIAL_MODULES = [
  MatIconModule,
  MatButtonModule,
  MatToolbarModule,
  MatTabsModule,
  MatListModule,
  MatCheckboxModule,
  MatRippleModule,
  MatInputModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatExpansionModule,
];

@NgModule({
  declarations: [AbstractColumnsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgScrollbarModule,
    NgPipesModule,
    ...UI_MODULES,
    ...MATERIAL_MODULES,
    SchemaFormModule.forRoot(),
  ],
  exports: [AbstractColumnsComponent],
})
export class AbstractColumnsModule {}
