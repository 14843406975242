import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("OwnerModel")
export class OwnerModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("email", String)
  email = "";
}
