<div fxLayout="column" style="height: 100%">
  <div class="upload-title-container">
    <div
      style="width: 24px; height: 24px; margin-right: 10px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <itls-icon svgIcon="icon-documents"> </itls-icon>
    </div>
    <div>Upload Documents</div>
  </div>
  <mat-accordion
    displayMode="flat"
    id="documentUploadModalAccordion"
    [class]="
      uploadedDocSets?.length
        ? 'uploadModalAccordionWithFiles'
        : 'uploadModalAccordionWithoutFiles'
    "
  >
    <mat-expansion-panel
      #uploadPanel
      expanded="true"
      (opened)="onPanelOpened('UPLOAD')"
      (closed)="onPanelClosed('UPLOAD')"
      [ngClass]="selectedView === 'UPLOAD' ? 'panel-opened' : 'panel-closed'"
    >
      <mat-expansion-panel-header
        [class.disabled-pointer]="!uploadedDocSets?.length"
      >
        <mat-panel-title> Upload </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <il-files-upload
          #filesUploadComponent
          (uploadingFolderEvent)="isUploadingFolder = $event"
          (modelChanged)="onModelChanged($event)"
        ></il-files-upload>
      </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel
      #pickReviewFormPanel
      class="pick-review-form-panel"
      style="border-top: solid 1px #dfe1e6 !important"
      (opened)="onPanelOpened('PICK_REVIEW_FORM')"
      (closed)="onPanelClosed('PICK_REVIEW_FORM')"
      [ngClass]="
        selectedView === 'PICK_REVIEW_FORM' ? 'panel-opened' : 'panel-closed'
      "
    >
      <mat-expansion-panel-header
        [class.disabled-pointer]="!uploadedDocSets?.length"
      >
        <mat-panel-title> Pick a review form </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <il-choose-form
          #chooseFormComponent
          (selectedProvisionFormUidEvent)="onProvisionFormSelection($event)"
        ></il-choose-form>
      </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel
      #selectDestFolderPanel
      class="select-dest-folder-panel"
      style="border-top: solid 1px #dfe1e6 !important"
      (opened)="onPanelOpened('SELECT_DEST_FOLDER')"
      (closed)="onPanelClosed('SELECT_DEST_FOLDER')"
      [ngClass]="
        selectedView === 'SELECT_DEST_FOLDER' ? 'panel-opened' : 'panel-closed'
      "
    >
      <mat-expansion-panel-header
        [class.disabled-pointer]="
          !uploadedDocSets?.length || !selectedProvisionForm
        "
      >
        <mat-panel-title> Select destination folder </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <il-drive-tree
          [contentMaxHeight]="346"
          [selectedNodeUid]="selectedFolderUid"
          (nodeClicked)="selectedFolderUid = $event.id"
          [showContextMenu]="true"
        ></il-drive-tree>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

  <div
    *ngIf="uploadedDocSets?.length"
    class="action-btn-container"
    fxLayout="row"
    fxLayoutAlign="end"
  >
    <button
      *ngIf="selectedView !== 'UPLOAD'"
      [disabled]="isUploading"
      type="button"
      class="btn btn-default upload-action-btn back-btn"
      (click)="onBackClicked()"
    >
      Back
    </button>
    <button
      type="button"
      *ngIf="selectedView !== 'SELECT_DEST_FOLDER'"
      class="btn btn-primary upload-action-btn next-btn"
      (click)="onNextClicked()"
      [disabled]="selectedView === 'PICK_REVIEW_FORM' && !selectedProvisionForm"
    >
      <div>
        <span> Next </span>
        <span>
          <itls-icon svgIcon="icon-next"></itls-icon>
        </span>
      </div>
    </button>
    <button
      type="button"
      *ngIf="selectedView === 'SELECT_DEST_FOLDER'"
      [disabled]="
        isUploading ||
        !this.selectedFolderUid ||
        !this.selectedProvisionForm ||
        !this.uploadedDocSets?.length
      "
      class="btn btn-primary upload-action-btn"
      (click)="onUpload()"
    >
      Upload
      <i class="fa fa-spinner fa-spin" *ngIf="isUploading"></i>
    </button>
  </div>
</div>
