/* tslint:disable */
/* eslint-disable */
import { NamedSearchFieldModel } from "./named-search-field-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SortCriteriaModel")
export class SortCriteriaModel {
  @JsonProperty("ascending", Boolean, true)
  ascending?: boolean = undefined;

  @JsonProperty("field", NamedSearchFieldModel, true)
  field?: NamedSearchFieldModel = undefined;
}
