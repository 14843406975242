<div class="itls-export" (click)="$event.stopPropagation()">
  <mat-card appearance="outlined">
    <div class="header" fxLayout fxLayoutAlign="space-between center">
      <div>
        <mat-icon svgIcon="icon-export" class="mr-2"></mat-icon>
        <span class="title">Export</span>
      </div>
      <div>
        <mat-form-field style="margin-bottom: -33px; margin-top: -44px">
          <mat-select [(value)]="selectedFileType">
            <mat-option
              *ngFor="let fileType of exportFileType"
              [value]="fileType.value"
            >
              {{ fileType.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-card-content>
      <div fxLayout fxLayoutAlign="space-between center" class="my-4">
        <div>Include notes</div>
        <mat-slide-toggle
          [checked]="includeNotes"
          [(ngModel)]="includeNotes"
          (toggleChange)="onToggleIncludeNotesChange($event)"
        >
        </mat-slide-toggle>
      </div>
      <div fxLayout fxLayoutAlign="space-between center" class="my-4">
        <div>Include document list</div>
        <mat-slide-toggle
          [checked]="includeDocumentChronology"
          [(ngModel)]="includeDocumentChronology"
          (toggleChange)="onToggleIncludeDocumentChronologyChange($event)"
        >
        </mat-slide-toggle>
      </div>
      <div fxLayout fxLayoutAlign="space-between center" class="my-4">
        <div>Include annotated docs</div>
        <mat-slide-toggle
          [checked]="includeAnnotatedDocs"
          [(ngModel)]="includeAnnotatedDocs"
          (toggleChange)="onToggleIncludeAnnotatedDocsChange($event)"
        >
        </mat-slide-toggle>
      </div>
      <div fxLayout fxLayoutAlign="space-between center" class="my-4">
        <div>Exclude empty provisions</div>
        <mat-slide-toggle
          [checked]="excludeEmptyProvisions"
          [(ngModel)]="excludeEmptyProvisions"
        >
        </mat-slide-toggle>
      </div>
    </mat-card-content>
    <mat-card-actions
      style="
        margin-bottom: 0;
        padding: 8px 22px;
        text-align: right;
        margin-right: 4px;
      "
    >
      <button mat-flat-button (click)="onCancelClick()" class="mr-2">
        Cancel
      </button>
      <button mat-flat-button color="primary" (click)="onExportClick()">
        Done
      </button>
    </mat-card-actions>
  </mat-card>
</div>
