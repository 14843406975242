/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiDeleteRequestModel")
export class OApiDeleteRequestModel {
  @JsonProperty("affectAllVersions", Boolean, true)
  affectAllVersions?: boolean = undefined;

  @JsonProperty("objectUids", [String], true)
  objectUids?: Array<string> = [];

  @JsonProperty("selectAll", Boolean, true)
  selectAll?: boolean = undefined;
}
