import { NgModule } from "@angular/core";
import { EllipsisDetectorDirective } from "./ellipsis-detector.directive";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [EllipsisDetectorDirective],
  imports: [MatTooltipModule],
  exports: [EllipsisDetectorDirective],
})
export class EllipsisDetectorModule {}
