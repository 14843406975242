/* tslint:disable */
/* eslint-disable */
import { CustomDataDtoModel } from "./custom-data-dto-model";
import { PdfBbxAndTxtDtoModel } from "./pdf-bbx-and-txt-dto-model";
import { PdfBbxDtoModel } from "./pdf-bbx-dto-model";
import { PdfTextDtoModel } from "./pdf-text-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PdfHighlightDtoModel")
export class PdfHighlightDtoModel {
  @JsonProperty("color", String, true)
  color?: string = "";

  @JsonProperty("created", DateTimeConvertService, true)
  created?: Date | undefined = undefined;

  @JsonProperty("guid", String, true)
  guid?: string = "";

  @JsonProperty("id", String, true)
  id?: string = "";

  @JsonProperty("itlsData", CustomDataDtoModel, true)
  itlsData?: CustomDataDtoModel = undefined;

  @JsonProperty("lastUpdated", DateTimeConvertService, true)
  lastUpdated?: Date | undefined = undefined;

  @JsonProperty("rects", Any, true)
  rects?: {
    [key: string]: PdfBbxDtoModel;
  } = {};

  @JsonProperty("text", PdfTextDtoModel, true)
  text?: PdfTextDtoModel = undefined;

  @JsonProperty("textSelections", Any, true)
  textSelections?: {
    [key: string]: PdfBbxAndTxtDtoModel;
  } = {};
}
