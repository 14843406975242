import { Action } from "@ngrx/store";
import { Entity } from "./calendar.reducer";

export enum CalendarActionTypes {
  LoadCalendar = "[Calendar] Load Calendar",
  CalendarLoaded = "[Calendar] Calendar Loaded",
  CalendarLoadError = "[Calendar] Calendar Load Error",

  LoadCalendarFilterFields = "[Calendar] Load Calendar Filter Fields",
  CalendarFilterFieldsLoaded = "[Calendar] Calendar Filter Fields Loaded",
  CalendarFilterFieldsLoadError = "[Calendar] Calendar Filter Fields Load Error",

  LoadCalendarDisplayableFields = "[Calendar] Load Calendar Displayable Fields",
  CalendarDisplayableFieldsLoaded = "[Calendar] Calendar Displayable Fields Loaded",
  CalendarDisplayableFieldsLoadError = "[Calendar] Calendar Displayable Fields Load Error",

  SearchCalendarFinalAbstracts = "[Calendar] Search Calendar FinalAbstracts",
  SearchCalendarFinalAbstractsLoaded = "[Calendar] Search Calendar FinalAbstracts Loaded",
  SearchCalendarFinalAbstractsLoadError = "[Calendar] Search Calendar FinalAbstracts Load Error",

  SaveSelectedCalendarFilters = "[Calendar] Save Selected Calendar Filters",
  RemoveSelectedCalendarFilters = "[Calendar] Remove Selected Calendar Filters",

  SaveSelectedCalendarField = "[Calendar] Save Selected Calendar Field",
  RemoveSelectedCalendarField = "[Calendar] Remove Selected Calendar Field",

  LoadCalendars = "[Calendar] Load Calendars",
  CalendarsLoaded = "[Calendar] Calendars Loaded",
  CalendarsLoadError = "[Calendar] Calendars Load Error",

  SaveCalendar = "[Calendar] Save Calendar",
  SaveCalendarSucceeded = "[Calendar] Save Calendar Succeeded",
  SaveCalendarFailed = "[Calendar] Save Calendar Failed",

  UpdateCalendar = "[Calendar] Update Calendar",
  UpdateCalendarSucceeded = "[Calendar] Update Calendar Succeeded",
  UpdateCalendarFailed = "[Calendar] Update Calendar Failed",

  LoadSelectedCalendar = "[Calendar] Load Selected Calendar",
  SelectedCalendarLoaded = "[Calendar] Selected Calendar Loaded",
  SelectedCalendarLoadError = "[Calendar] Selected Calendar Load Error",

  DeleteSelectedCalendar = "[Calendar] Delete Selected Calendar",
  DeleteSelectedCalendarSucceeded = "[Calendar] Delete Selected Calendar Succeeded",
  DeleteSelectedCalendarFailed = "[Calendar] Delete Selected Calendar Failed",

  SaveCalendarQuery = "[Calendar] Save Calendar Query",
  SaveCalendarQuerySucceeded = "[Calendar] Save Calendar Query Succeeded",
  SaveCalendarQueryFailed = "[Calendar] Save Calendar Query Failed",

  UpdateCalendarQuery = "[Calendar] Update Calendar Query",
  UpdateCalendarQuerySucceeded = "[Calendar] Update Calendar Query Succeeded",
  UpdateCalendarQueryFailed = "[Calendar] Update Calendar Query Failed",

  LoadCalendarQueries = "[Calendar] Load Calendar Queries",
  CalendarQueriesLoaded = "[Calendar] Calendar Queries Loaded",
  CalendarQueriesLoadError = "[Calendar] Calendar Queries Load Error",

  DeleteSelectedCalendarQuery = "[Calendar] Delete Selected Calendar Query",
  DeleteSelectedCalendarQuerySucceeded = "[Calendar] Delete Selected Calendar Query Succeeded",
  DeleteSelectedCalendarQueryFailed = "[Calendar] Delete Selected Calendar Query Failed",

  UpdateSelectedCalendarQueryActive = "[Calendar] update selected Calendar Query active",
  UpdateSelectedCalendarQueryActiveSucceeded = "[Calendar] update selected Calendar Query active Succeeded",
  UpdateSelectedCalendarQueryActiveFailed = "[Calendar] update selected Calendar Query active Failed",

  RunCalendarQuery = "[Calendar] Run Calendar Query",
  RunCalendarQuerySucceeded = "[Calendar] Run Calendar Query Succeeded",
  RunCalendarQueryFailed = "[Calendar] Run Calendar Query Failed",

  DeleteMultipleCalendar = "[Calendar] Delete Multiple Calendar",
  DeleteMultipleCalendarSucceeded = "[Calendar] Delete Multiple Calendar Succeeded",
  DeleteMultipleCalendarFailed = "[Calendar] Delete Multiple Calendar Failed",

  CleanCalendarState = "[Calendar] Clean Calendar State",
}

export class LoadCalendar implements Action {
  readonly type = CalendarActionTypes.LoadCalendar;
}

export class CalendarLoadError implements Action {
  readonly type = CalendarActionTypes.CalendarLoadError;

  constructor(public payload: any) {}
}

export class CalendarLoaded implements Action {
  readonly type = CalendarActionTypes.CalendarLoaded;

  constructor(public payload: Entity[]) {}
}

export class LoadCalendarFilterFields implements Action {
  readonly type = CalendarActionTypes.LoadCalendarFilterFields;
}

export class CalendarFilterFieldsLoaded implements Action {
  readonly type = CalendarActionTypes.CalendarFilterFieldsLoaded;

  constructor(
    public payload: { filterProvisions: any; provisionsOperator: any }
  ) {}
}

export class CalendarFilterFieldsLoadError implements Action {
  readonly type = CalendarActionTypes.CalendarFilterFieldsLoadError;

  constructor(public payload: any) {}
}

export class LoadCalendarDisplayableFields implements Action {
  readonly type = CalendarActionTypes.LoadCalendarDisplayableFields;
}

export class CalendarDisplayableFieldsLoaded implements Action {
  readonly type = CalendarActionTypes.CalendarDisplayableFieldsLoaded;

  constructor(public payload: { filterProvisions: any }) {}
}

export class CalendarDisplayableFieldsLoadError implements Action {
  readonly type = CalendarActionTypes.CalendarDisplayableFieldsLoadError;

  constructor(public payload: any) {}
}

export class SearchCalendarFinalAbstracts implements Action {
  readonly type = CalendarActionTypes.SearchCalendarFinalAbstracts;

  constructor(
    public payload: any,
    public calendarQueryUid?: string,
    public selectedInstanceId?: string,
    public dashboardUid?: string,
    public dateRange?: { start: string; end: string }
  ) {}
}

export class SearchCalendarFinalAbstractsLoaded implements Action {
  readonly type = CalendarActionTypes.SearchCalendarFinalAbstractsLoaded;

  constructor(
    public payload: any,
    public calendarQueryUid?: string,
    public selectedInstanceId?: string
  ) {}
}

export class SearchCalendarFinalAbstractsLoadError implements Action {
  readonly type = CalendarActionTypes.SearchCalendarFinalAbstractsLoadError;

  constructor(public payload: any, public selectedInstanceId?: string) {}
}

export class SaveSelectedCalendarFilters implements Action {
  readonly type = CalendarActionTypes.SaveSelectedCalendarFilters;

  constructor(public payload: any) {}
}

export class RemoveSelectedCalendarFilters implements Action {
  readonly type = CalendarActionTypes.RemoveSelectedCalendarFilters;
}

export class SaveSelectedCalendarField implements Action {
  readonly type = CalendarActionTypes.SaveSelectedCalendarField;

  constructor(public payload: any) {}
}

export class RemoveSelectedCalendarField implements Action {
  readonly type = CalendarActionTypes.RemoveSelectedCalendarField;
}

export class LoadCalendars implements Action {
  readonly type = CalendarActionTypes.LoadCalendars;

  constructor(public payload: any) {}
}

export class CalendarsLoaded implements Action {
  readonly type = CalendarActionTypes.CalendarsLoaded;

  constructor(public payload: any) {}
}

export class CalendarsLoadError implements Action {
  readonly type = CalendarActionTypes.CalendarsLoadError;

  constructor(public payload: any) {}
}

export class SaveCalendar implements Action {
  readonly type = CalendarActionTypes.SaveCalendar;

  constructor(public payload) {}
}

export class SaveCalendarSucceeded implements Action {
  readonly type = CalendarActionTypes.SaveCalendarSucceeded;

  constructor(public payload) {}
}

export class SaveCalendarFailed implements Action {
  readonly type = CalendarActionTypes.SaveCalendarFailed;

  constructor(public payload) {}
}

export class UpdateCalendar implements Action {
  readonly type = CalendarActionTypes.UpdateCalendar;

  constructor(public payload: { calendarUid: string; data: any }) {}
}

export class UpdateCalendarSucceeded implements Action {
  readonly type = CalendarActionTypes.UpdateCalendarSucceeded;

  constructor(public payload) {}
}

export class UpdateCalendarFailed implements Action {
  readonly type = CalendarActionTypes.UpdateCalendarFailed;

  constructor(public payload) {}
}

export class CleanCalendarState implements Action {
  readonly type = CalendarActionTypes.CleanCalendarState;

  constructor(public payload) {}
}

export class LoadSelectedCalendar implements Action {
  readonly type = CalendarActionTypes.LoadSelectedCalendar;

  constructor(public payload, public selectedInstanceId: string) {}
}

export class SelectedCalendarLoaded implements Action {
  readonly type = CalendarActionTypes.SelectedCalendarLoaded;

  constructor(public payload, public selectedInstanceId: string) {}
}

export class SelectedCalendarLoadError implements Action {
  readonly type = CalendarActionTypes.SelectedCalendarLoadError;

  constructor(public payload, public selectedInstanceId: string) {}
}

export class SaveCalendarQuery implements Action {
  readonly type = CalendarActionTypes.SaveCalendarQuery;

  constructor(public calendarUid: string, public payload) {}
}

export class SaveCalendarQuerySucceeded implements Action {
  readonly type = CalendarActionTypes.SaveCalendarQuerySucceeded;

  constructor(public payload) {}
}

export class SaveCalendarQueryFailed implements Action {
  readonly type = CalendarActionTypes.SaveCalendarQueryFailed;

  constructor(public payload) {}
}

export class UpdateCalendarQuery implements Action {
  readonly type = CalendarActionTypes.UpdateCalendarQuery;

  constructor(
    public calendarUid: string,
    public calendarQueryUid: string,
    public payload
  ) {}
}

export class UpdateCalendarQuerySucceeded implements Action {
  readonly type = CalendarActionTypes.UpdateCalendarQuerySucceeded;

  constructor(public payload) {}
}

export class UpdateCalendarQueryFailed implements Action {
  readonly type = CalendarActionTypes.UpdateCalendarQueryFailed;

  constructor(public payload) {}
}

export class LoadCalendarQueries implements Action {
  readonly type = CalendarActionTypes.LoadCalendarQueries;

  constructor(public calendarUid: string) {}
}

export class CalendarQueriesLoaded implements Action {
  readonly type = CalendarActionTypes.CalendarQueriesLoaded;

  constructor(public payload) {}
}

export class CalendarQueriesLoadError implements Action {
  readonly type = CalendarActionTypes.CalendarQueriesLoadError;

  constructor(public payload) {}
}

export class DeleteSelectedCalendarQuery implements Action {
  readonly type = CalendarActionTypes.DeleteSelectedCalendarQuery;

  constructor(public calendarUid: string, public calendarQueryUid: string) {}
}

export class DeleteSelectedCalendarQuerySucceeded implements Action {
  readonly type = CalendarActionTypes.DeleteSelectedCalendarQuerySucceeded;

  constructor(public payload) {}
}

export class DeleteSelectedCalendarQueryFailed implements Action {
  readonly type = CalendarActionTypes.DeleteSelectedCalendarQueryFailed;

  constructor(public payload) {}
}

export class DeleteSelectedCalendar implements Action {
  readonly type = CalendarActionTypes.DeleteSelectedCalendar;

  constructor(public calendarUid: string) {}
}

export class DeleteSelectedCalendarSucceeded implements Action {
  readonly type = CalendarActionTypes.DeleteSelectedCalendarSucceeded;

  constructor(public payload) {}
}

export class DeleteSelectedCalendarFailed implements Action {
  readonly type = CalendarActionTypes.DeleteSelectedCalendarFailed;

  constructor(public payload) {}
}

export class UpdateSelectedCalendarQueryActive implements Action {
  readonly type = CalendarActionTypes.UpdateSelectedCalendarQueryActive;

  constructor(
    public calendarUid: string,
    public calendarQueryUid: string,
    public payload: { active: boolean }
  ) {}
}

export class UpdateSelectedCalendarQueryActiveSucceeded implements Action {
  readonly type =
    CalendarActionTypes.UpdateSelectedCalendarQueryActiveSucceeded;

  constructor(public payload) {}
}

export class UpdateSelectedCalendarQueryActiveFailed implements Action {
  readonly type = CalendarActionTypes.UpdateSelectedCalendarQueryActiveFailed;

  constructor(public payload) {}
}

export class RunCalendarQuery implements Action {
  readonly type = CalendarActionTypes.RunCalendarQuery;

  constructor(public payload) {}
}

export class RunCalendarQuerySucceeded implements Action {
  readonly type = CalendarActionTypes.RunCalendarQuerySucceeded;

  constructor(public payload) {}
}

export class RunCalendarQueryFailed implements Action {
  readonly type = CalendarActionTypes.RunCalendarQueryFailed;

  constructor(public payload) {}
}

export class DeleteMultipleCalendar implements Action {
  readonly type = CalendarActionTypes.DeleteMultipleCalendar;

  constructor(public payload) {}
}

export class DeleteMultipleCalendarSucceeded implements Action {
  readonly type = CalendarActionTypes.DeleteMultipleCalendarSucceeded;

  constructor(public payload) {}
}

export class DeleteMultipleCalendarFailed implements Action {
  readonly type = CalendarActionTypes.DeleteMultipleCalendarFailed;

  constructor(public payload) {}
}

export type CalendarAction =
  | LoadCalendar
  | CalendarLoaded
  | CalendarLoadError
  | LoadCalendarFilterFields
  | CalendarFilterFieldsLoaded
  | CalendarFilterFieldsLoadError
  | LoadCalendarDisplayableFields
  | CalendarDisplayableFieldsLoaded
  | CalendarDisplayableFieldsLoadError
  | SearchCalendarFinalAbstracts
  | SearchCalendarFinalAbstractsLoaded
  | SearchCalendarFinalAbstractsLoadError
  | SaveSelectedCalendarFilters
  | RemoveSelectedCalendarFilters
  | SaveSelectedCalendarField
  | RemoveSelectedCalendarField
  | LoadCalendars
  | CalendarsLoaded
  | CalendarsLoadError
  | SaveCalendar
  | SaveCalendarSucceeded
  | SaveCalendarFailed
  | UpdateCalendar
  | UpdateCalendarSucceeded
  | UpdateCalendarFailed
  | CleanCalendarState
  | LoadSelectedCalendar
  | SelectedCalendarLoaded
  | SelectedCalendarLoadError
  | DeleteSelectedCalendar
  | DeleteSelectedCalendarSucceeded
  | DeleteSelectedCalendarFailed
  | SaveCalendarQuery
  | SaveCalendarQuerySucceeded
  | SaveCalendarQueryFailed
  | UpdateCalendarQuery
  | UpdateCalendarQuerySucceeded
  | UpdateCalendarQueryFailed
  | LoadCalendarQueries
  | CalendarQueriesLoaded
  | CalendarQueriesLoadError
  | DeleteSelectedCalendarQuery
  | DeleteSelectedCalendarQuerySucceeded
  | DeleteSelectedCalendarQueryFailed
  | UpdateSelectedCalendarQueryActive
  | UpdateSelectedCalendarQueryActiveSucceeded
  | UpdateSelectedCalendarQueryActiveFailed
  | RunCalendarQuery
  | RunCalendarQuerySucceeded
  | RunCalendarQueryFailed
  | DeleteMultipleCalendar
  | DeleteMultipleCalendarSucceeded
  | DeleteMultipleCalendarFailed;

export const fromCalendarActions = {
  LoadCalendar,
  CalendarLoaded,
  CalendarLoadError,
  LoadCalendarFilterFields,
  CalendarFilterFieldsLoaded,
  CalendarFilterFieldsLoadError,
  LoadCalendarDisplayableFields,
  CalendarDisplayableFieldsLoaded,
  CalendarDisplayableFieldsLoadError,
  SearchCalendarFinalAbstracts,
  SearchCalendarFinalAbstractsLoaded,
  SearchCalendarFinalAbstractsLoadError,
  SaveSelectedCalendarFilters,
  RemoveSelectedCalendarFilters,
  SaveSelectedCalendarField,
  RemoveSelectedCalendarField,

  LoadCalendars,
  CalendarsLoaded,
  CalendarsLoadError,

  SaveCalendar,
  SaveCalendarSucceeded,
  SaveCalendarFailed,

  UpdateCalendar,
  UpdateCalendarSucceeded,
  UpdateCalendarFailed,

  CleanCalendarState,

  LoadSelectedCalendar,
  SelectedCalendarLoaded,
  SelectedCalendarLoadError,

  DeleteSelectedCalendar,
  DeleteSelectedCalendarSucceeded,
  DeleteSelectedCalendarFailed,

  SaveCalendarQuery,
  SaveCalendarQuerySucceeded,
  SaveCalendarQueryFailed,

  UpdateCalendarQuery,
  UpdateCalendarQuerySucceeded,
  UpdateCalendarQueryFailed,

  LoadCalendarQueries,
  CalendarQueriesLoaded,
  CalendarQueriesLoadError,
  DeleteSelectedCalendarQuery,
  DeleteSelectedCalendarQuerySucceeded,
  DeleteSelectedCalendarQueryFailed,
  UpdateSelectedCalendarQueryActive,
  UpdateSelectedCalendarQueryActiveSucceeded,
  UpdateSelectedCalendarQueryActiveFailed,

  RunCalendarQuery,
  RunCalendarQuerySucceeded,
  RunCalendarQueryFailed,

  DeleteMultipleCalendar,
  DeleteMultipleCalendarSucceeded,
  DeleteMultipleCalendarFailed,
};
