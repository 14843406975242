/* tslint:disable */
/* eslint-disable */
import { SnippetModel } from "./snippet-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PartialPdfProvisionViewModel")
export class PartialPdfProvisionViewModel {
  @JsonProperty("dirtyText", String, true)
  dirtyText?: string = "";

  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  @JsonProperty("formatValues", Any, true)
  formatValues?: {
    [key: string]: {};
  } = {};

  @JsonProperty("notes", String, true)
  notes?: string = "";

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  @JsonProperty("pdfHighlightIds", [String], true)
  pdfHighlightIds?: Array<string> = [];

  @JsonProperty("pngSnippetAddresses", Any, true)
  pngSnippetAddresses?: {
    [key: string]: string;
  } = {};

  @JsonProperty("relatedOptionUids", [String], true)
  relatedOptionUids?: Array<string> = [];

  @JsonProperty("sectionHeader", String, true)
  sectionHeader?: string = "";

  @JsonProperty("sectionHeadingUid", String, true)
  sectionHeadingUid?: string = "";

  @JsonProperty("selected", Boolean, true)
  selected?: boolean = undefined;

  @JsonProperty("snippets", [SnippetModel], true)
  snippets?: Array<SnippetModel> = [];

  @JsonProperty("source", String, true)
  source?: string = "";

  @JsonProperty("surroundingText", String, true)
  surroundingText?: string = "";

  @JsonProperty("systemFound", Boolean, true)
  systemFound?: boolean = undefined;

  @JsonProperty("type", String, true)
  type?: string = "";

  @JsonProperty("uiProvisionName", String, true)
  uiProvisionName?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("value", Any, true)
  value?: any = {};
}
