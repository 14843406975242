import { Any, JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { OwnerModel } from "@@intelease/web/common/models/drive/owner-model";
import { NodeSearchResultNavigationModel } from "@@intelease/web/common/models/drive/node-search-result-navigation.model";
import { NodeSearchResultDtoModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("NodeSearchResultModel")
export class NodeSearchResultModel {
  static view = "full";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("recordUid", String, true)
  recordUid?: string = "";

  @JsonProperty("queueUid", String, true)
  queueUid?: string = "";

  @JsonProperty("queueName", String, true)
  queueName?: string = "";

  @JsonProperty("queueStage", Any, true)
  queueStage?: NodeSearchResultDtoModel["queueStage"] = undefined;

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("status", String, true)
  status = "";

  @JsonProperty("owner", OwnerModel)
  owner: OwnerModel = undefined;

  @JsonProperty("createdAt", DateTimeConvertService)
  createdAt: Date = undefined;

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  /**
   * Possible values:
   *  "RECORD",
   *  "DIRECTORY"
   */
  @JsonProperty("type", String)
  type: "RECORD" | "DIRECTORY" | "DOC_SET" | undefined = undefined;

  @JsonProperty("shared", Boolean)
  shared = false;

  @JsonProperty("navigation", NodeSearchResultNavigationModel)
  navigation: NodeSearchResultNavigationModel = undefined;

  @JsonProperty("permissions", [String], true)
  permissions: (
    | "SHARE"
    | "DELETE"
    | "COMMENT"
    | "MOVE"
    | "EXPORT"
    | "EDIT"
    | "READ"
  )[] = [];

  @JsonProperty("viewer", Any)
  viewer: NodeSearchResultDtoModel["viewer"] = undefined;

  @JsonProperty("googleDriveFileId", String)
  googleDriveFileId: NodeSearchResultDtoModel["googleDriveFileId"] = undefined;
}
