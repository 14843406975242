import { ProjectDtoModel } from "@@intelease/api-models/adex-api-model-src";
import { cloneDeep, remove } from "lodash";
import { ProjectsAction, ProjectsActionTypes } from "./projects.actions";

export const PROJECTS_FEATURE_KEY = "projects";

/**
 * Interface for the 'Projects' data used in
 *  - ProjectsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* eslint-disable-next-line */
export interface Entity {}

export interface ProjectsState {
  list?: Entity[]; // list of Projects; analogous to a sql normalized table
  selectedId?: string | number; // which Projects record has been selected
  loaded?: boolean; // has the Projects list been loaded
  error?: any; // last none error (if any)

  isSaveProjectLoading?: boolean;
  saveProjectDetail?: any;
  saveProjectSucceeded?: boolean;
  saveProjectError?: any;

  isUpdateProjectLoading?: boolean;
  updateProjectSucceeded?: boolean;
  updateProjectError?: any;

  isDeleteProjectLoading?: boolean;
  deleteProjectSucceeded?: boolean;
  deleteProjectError?: any;

  isDeleteBatchProjectLoading?: boolean;
  deleteBatchProjectSucceeded?: boolean;
  deleteBatchProjectError?: any;

  selectedProjectUid?: string;
  selectedProjectDetail?: any;
  selectedProjectDetailMap?: any;
  projectWidgets?: any;
  projectWidgetsMap?: any;
  isLoadProjectLoading?: boolean;
  loadProjectSucceeded?: boolean;
  loadProjectError?: any;

  projects?: {
    pagination: any;
    items;
  };
  isLoadAllProjectsLoading?: boolean;
  loadAllProjectsSucceeded?: boolean;
  loadAllProjectsError?: any;

  isShareProjectLoading?: boolean;
  shareProjectSucceeded?: boolean;
  shareProjectError?: boolean;
}

export interface ProjectsPartialState {
  readonly [PROJECTS_FEATURE_KEY]: ProjectsState;
}

export const initialState: ProjectsState = {
  list: [],
  loaded: false,
  selectedProjectDetailMap: {},
  loadProjectError: {},
  projectWidgetsMap: {},
};

export function reducer(
  state: ProjectsState = initialState,
  action: ProjectsAction
): ProjectsState {
  switch (action.type) {
    case ProjectsActionTypes.ProjectsLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true,
      };
      break;
    }
    case ProjectsActionTypes.SaveProject: {
      state = {
        ...state,
        saveProjectDetail: undefined,
        isSaveProjectLoading: true,
        saveProjectSucceeded: false,
        saveProjectError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.SaveProjectSucceeded: {
      state = {
        ...state,
        saveProjectDetail: action.payload,
        isSaveProjectLoading: false,
        saveProjectSucceeded: true,
        saveProjectError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.SaveProjectFailed: {
      state = {
        ...state,
        saveProjectDetail: undefined,
        isSaveProjectLoading: false,
        saveProjectSucceeded: false,
        saveProjectError: action.payload,
      };
      break;
    }
    case ProjectsActionTypes.UpdateProject: {
      state = {
        ...state,
        isUpdateProjectLoading: true,
        updateProjectSucceeded: false,
        updateProjectError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.UpdateProjectSucceeded: {
      const projectDetail = action?.payload?.data;
      const projectItems = cloneDeep(state?.projects?.items)?.map((project) => {
        if (projectDetail?.uid === project.uid) return projectDetail;
        return project;
      });
      state = {
        ...state,
        projects: {
          ...state?.projects,
          items: projectItems,
        },
        isUpdateProjectLoading: false,
        updateProjectSucceeded: true,
        updateProjectError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.UpdateProjectFailed: {
      state = {
        ...state,
        isUpdateProjectLoading: false,
        updateProjectSucceeded: false,
        updateProjectError: action.payload,
      };
      break;
    }
    case ProjectsActionTypes.DeleteProject: {
      state = {
        ...state,
        isDeleteProjectLoading: true,
        deleteProjectSucceeded: false,
        deleteProjectError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.DeleteProjectSucceeded: {
      const projectItems: ProjectDtoModel[] = cloneDeep(state?.projects?.items);
      const projectDetail: ProjectDtoModel = action?.payload?.data;
      remove(projectItems, (item) => item.uid === projectDetail.uid);
      state = {
        ...state,
        projects: {
          ...state.projects,
          items: projectItems,
        },
        isDeleteProjectLoading: false,
        deleteProjectSucceeded: true,
        deleteProjectError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.DeleteProjectFailed: {
      state = {
        ...state,
        isDeleteProjectLoading: false,
        deleteProjectSucceeded: false,
        deleteProjectError: action.payload,
      };
      break;
    }
    case ProjectsActionTypes.DeleteBatchProject: {
      state = {
        ...state,
        isDeleteBatchProjectLoading: true,
        deleteBatchProjectSucceeded: false,
        deleteBatchProjectError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.DeleteBatchProjectSucceeded: {
      const projectItems: ProjectDtoModel[] = cloneDeep(state?.projects?.items);
      const objectUids: string[] = action.payload;
      objectUids.forEach((uid) => {
        remove(projectItems, (item) => item.uid === uid);
      });
      state = {
        ...state,
        projects: {
          ...state?.projects,
          items: projectItems,
        },
        isDeleteBatchProjectLoading: false,
        deleteBatchProjectSucceeded: true,
        deleteBatchProjectError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.DeleteBatchProjectFailed: {
      state = {
        ...state,
        isDeleteBatchProjectLoading: false,
        deleteBatchProjectSucceeded: false,
        deleteBatchProjectError: action.payload,
      };
      break;
    }
    case ProjectsActionTypes.LoadProject: {
      state = {
        ...state,
        selectedProjectUid: action.payload.projectUid,
        selectedProjectDetail: undefined,
        selectedProjectDetailMap: {
          ...state.selectedProjectDetailMap,
          [action.selectedInstanceId]: undefined,
        },
        projectWidgetsMap: {
          ...state.projectWidgetsMap,
          [action.selectedInstanceId]: undefined,
        },
        projectWidgets: undefined,
        isLoadProjectLoading: true,
        loadProjectSucceeded: false,
        loadProjectError: {
          ...state.loadProjectError,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case ProjectsActionTypes.LoadProjectSucceeded: {
      const { projectDetail, widgets } = action.payload;
      state = {
        ...state,
        selectedProjectDetail: projectDetail,
        selectedProjectDetailMap: {
          ...state.selectedProjectDetailMap,
          [action.selectedInstanceId]: projectDetail,
        },
        projectWidgetsMap: {
          ...state.projectWidgetsMap,
          [action.selectedInstanceId]: widgets,
        },
        projectWidgets: widgets,
        isLoadProjectLoading: false,
        loadProjectSucceeded: true,
        loadProjectError: {
          ...state.loadProjectError,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case ProjectsActionTypes.LoadProjectFailed: {
      state = {
        ...state,
        selectedProjectDetail: undefined,
        projectWidgets: undefined,
        isLoadProjectLoading: false,
        loadProjectSucceeded: false,
        loadProjectError: {
          ...state.loadProjectError,
          [action.selectedInstanceId]: action.payload,
        },
        selectedProjectDetailMap: {
          ...state.selectedProjectDetailMap,
          [action.selectedInstanceId]: undefined,
        },
        projectWidgetsMap: {
          ...state.projectWidgetsMap,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case ProjectsActionTypes.LoadAllProjects: {
      state = {
        ...state,
        projects: undefined,
        isLoadAllProjectsLoading: true,
        loadAllProjectsSucceeded: false,
        loadAllProjectsError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.LoadAllProjectsSucceeded: {
      state = {
        ...state,
        projects: action.payload,
        isLoadAllProjectsLoading: false,
        loadAllProjectsSucceeded: true,
        loadAllProjectsError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.LoadAllProjectsFailed: {
      state = {
        ...state,
        projects: undefined,
        isLoadAllProjectsLoading: false,
        loadAllProjectsSucceeded: false,
        loadAllProjectsError: action.payload,
      };
      break;
    }

    case ProjectsActionTypes.ShareProject: {
      state = {
        ...state,
        isShareProjectLoading: true,
        shareProjectSucceeded: false,
        shareProjectError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.ShareProjectSucceeded: {
      state = {
        ...state,
        isShareProjectLoading: false,
        shareProjectSucceeded: true,
        shareProjectError: undefined,
      };
      break;
    }
    case ProjectsActionTypes.ShareProjectFailed: {
      state = {
        ...state,
        isShareProjectLoading: false,
        shareProjectSucceeded: false,
        shareProjectError: action.payload,
      };
      break;
    }
    case ProjectsActionTypes.CleanProjectState: {
      state = {
        ...state,
        ...action.payload,
      };
      break;
    }
  }
  return state;
}
