import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { ReactiveFormsModule } from "@angular/forms";
import { CalendarFieldsComponent } from "./calendar-fields.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AppStateCalendarModule } from "@@intelease/app-state/calendar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgScrollbarModule } from "ngx-scrollbar";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { NgPipesModule } from "ngx-pipes";

const UI_MODULES = [];

const MATERIAL_MODULES = [
  MatTabsModule,
  MatFormFieldModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatListModule,
  MatIconModule,
  MatProgressSpinnerModule,
];

const COMPONENTS = [];

@NgModule({
  declarations: [CalendarFieldsComponent],
  imports: [
    CommonModule,
    InteleaseCommonModule,
    ReactiveFormsModule,
    NgPipesModule,
    ...UI_MODULES,
    ...MATERIAL_MODULES,
    ...COMPONENTS,
    AppStateCalendarModule,
    NgScrollbarModule,
  ],
  exports: [CalendarFieldsComponent],
})
export class CalendarFieldsModule {}
