import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { ItlsNavigationComponent } from "@@intelease/web/intelease/components/navigation/navigation.component";

@Component({
  selector: "itls-nav-horizontal-item",
  templateUrl: "./item.component.html",
  styleUrls: ["./item.component.scss"],
})
export class ItlsNavHorizontalItemComponent implements OnInit {
  @HostBinding("class")
  classes = "nav-item";

  @Input()
  item: any;

  expectedAuthorities: string[];

  constructor() {}

  ngOnInit(): void {
    this.expectedAuthorities =
      ItlsNavigationComponent.determineExpectedAuthorities(this.item.id);
  }
}
