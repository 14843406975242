import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "../../../../web/common/services/date-time-convert.service";
import {
  DocSetCategoryModel,
  RelatedDocModel,
} from "@@intelease/web/common/models";
import { QueueInfoDtoModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("FinalAbstractModel")
export class FinalAbstractModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("abstractFilename", String)
  abstractFilename = "";

  @JsonProperty("abstractName", String)
  abstractName = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("docSetCategory", DocSetCategoryModel)
  docSetCategory: DocSetCategoryModel = undefined;

  // @JsonProperty('suiteVersionId', Any)
  // suiteVersionId: any = {};

  @JsonProperty("documents", [RelatedDocModel])
  documents: RelatedDocModel[] = [];

  @JsonProperty("defaultDoc", RelatedDocModel)
  defaultDoc: RelatedDocModel = undefined;

  @JsonProperty("commentCountInRecord", Number)
  commentCountInRecord? = 0;

  @JsonProperty("queueInfo", QueueInfoDtoModel, true)
  queueInfo?: QueueInfoDtoModel;

  @JsonProperty("docSetUid", String)
  docSetUid = "";

  @JsonProperty("status", String)
  status = "";
}
