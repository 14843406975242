import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  IssueExportModel,
  LightNodeNameModel,
} from "@@intelease/web/common/models";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive.service";
import { ProjectsService } from "@@intelease/app-services/projects";
import { DriveNodeType } from "@@intelease/app-state/drive/src";

@Component({
  selector: "il-export",
  templateUrl: "./itls-export.component.html",
  styleUrls: ["./itls-export.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsExportComponent {
  @Input() entityType: "RECORD" | "DIRECTORY" | "DOC_SET" | "ISSUE" = "RECORD";
  @Input() fileNode: LightNodeNameModel;
  @Input() fileNodes: LightNodeNameModel[];
  @Input() issueExportModel: IssueExportModel;
  @Input() includeNotes: boolean;
  @Input() includeDocumentChronology: boolean;
  @Input() includeSourceAttribution: boolean;
  @Input() includeAnnotatedDocs: boolean;
  @Input() excludeEmptyProvisions: boolean;
  @Input() includeSectionHeaders: boolean;
  @Input() emptyProvisionText: string;
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  exportFileType = [
    { value: "CSV", viewValue: "CSV" },
    { value: "PDF", viewValue: "PDF" },
    { value: "XLSX", viewValue: "XLSX" },
    { value: "DOCX", viewValue: "DOCX" },
  ];
  selectedFileType = "CSV";
  actionMaps: { [key: string]: any };

  constructor(
    private driveService: ItlsDriveService,
    private projectService: ProjectsService
  ) {}

  onToggleIncludeNotesChange(evt) {}

  onToggleIncludeDocumentChronologyChange(evt) {}

  onToggleIncludeAnnotatedDocsChange(evt) {}

  onCancelClick() {
    this.cancel.emit();
  }

  onExportClick() {
    this.onButtonActionClick(this.selectedFileType);
  }

  onButtonActionClick(actionKey: any): void {
    if (this.entityType === "ISSUE") {
      this.onIssueExport(actionKey);
    } else {
      this.onNodeExport(actionKey);
    }
    this.export.emit();
  }

  private onIssueExport(actionKey: any) {
    this.projectService.exportIssue(
      this.getNodeNameWithExtension(actionKey),
      actionKey,
      this.issueExportModel,
      this.includeNotes,
      this.includeDocumentChronology,
      this.includeSourceAttribution,
      this.includeAnnotatedDocs,
      this.excludeEmptyProvisions,
      this.includeSectionHeaders,
      this.emptyProvisionText
    );
  }

  private onNodeExport(actionKey: any) {
    const nodes = this.fileNode ? [this.fileNode] : this.fileNodes;
    this.driveService.exportNodes(
      actionKey,
      nodes,
      this.includeNotes,
      this.includeDocumentChronology,
      this.includeSourceAttribution,
      this.includeAnnotatedDocs,
      this.excludeEmptyProvisions,
      this.includeSectionHeaders,
      this.emptyProvisionText
    );
  }

  private getNodeNameWithExtension(fileType: "PDF" | "XLSX" | "DOCX" | "CSV") {
    const name = this.fileNode
      ? this.fileNode.name
      : this.issueExportModel
      ? "exported_issue"
      : "exported_records";
    return name + this.getExtension(fileType);
  }

  private getExtension(fileType: "PDF" | "XLSX" | "DOCX" | "CSV") {
    if (this.includeAnnotatedDocs) {
      return ".zip";
    }
    if (this.fileNodes) {
      return ".zip";
    }
    if (this.fileNode?.type === DriveNodeType.DIRECTORY) {
      return ".zip";
    }
    switch (fileType) {
      case "CSV":
        return ".tsv";
      case "PDF":
        return ".pdf";
      case "DOCX":
        return ".docx";
      case "XLSX":
        return ".xlsx";
      default:
        throw new Error("cannot handle fileType: " + fileType);
    }
  }
}
