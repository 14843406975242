/* tslint:disable */
/* eslint-disable */
import { ErrorDataModel } from "./error-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiRespFrontendPermissionTypeModel")
export class OApiRespFrontendPermissionTypeModel {
  /**
   * the response data
   */

  @JsonProperty("data", Any, true)
  data?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];

  /**
   * the developer-facing errors for the request
   */

  @JsonProperty("devErrors", [ErrorDataModel], true)
  devErrors?: Array<ErrorDataModel> = [];

  /**
   * the user-facing errors for the request
   */

  @JsonProperty("errors", [ErrorDataModel], true)
  errors?: Array<ErrorDataModel> = [];

  /**
   * the response message
   */

  @JsonProperty("message", String, true)
  message?: string = "";
}
