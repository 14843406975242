import { RelatedDocumentModel } from "@@intelease/api-models/adex-api-model-src";
import { FormNameAbstractModel } from "@@intelease/web/common/models/doc-abstract/form-name-abstract.model";
import { createAction, props } from "@ngrx/store";

export enum IndividualAbstractActionTypes {
  SetPermission = "[IndividualAbstract] Set Permission",
  SetFormNameAbstractModel = "[IndividualAbstract] Set Form Name Abstract Model",
  UpdateLastModifiedDate = "[IndividualAbstract] Update Last Modified Date",
  GetDocuments = "[IndividualAbstract] Get Documents",
  GetDocumentsFailed = "[IndividualAbstract] Get Documents Failed",
  SetDocuments = "[IndividualAbstract] Set Documents",
  UpdateDocument = "[IndividualAbstract] Update Document",
}

export const SetPermission = createAction(
  IndividualAbstractActionTypes.SetPermission,
  props<{ permissions: string[] }>()
);

export const SetFormNameAbstractModel = createAction(
  IndividualAbstractActionTypes.SetFormNameAbstractModel,
  props<{ formNameAbstractModel: FormNameAbstractModel }>()
);

export const UpdateLastModifiedDate = createAction(
  IndividualAbstractActionTypes.UpdateLastModifiedDate
);

export const GetDocuments = createAction(
  IndividualAbstractActionTypes.GetDocuments,
  props<{ recordUid: string }>()
);

export const GetDocumentsFailed = createAction(
  IndividualAbstractActionTypes.GetDocumentsFailed
);

export const SetDocuments = createAction(
  IndividualAbstractActionTypes.SetDocuments,
  props<{ documents: Array<RelatedDocumentModel> }>()
);

export const UpdateDocument = createAction(
  IndividualAbstractActionTypes.UpdateDocument,
  props<{ document: RelatedDocumentModel }>()
);
