import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
} from "@angular/core";
import { ProvisionBoxInitTypesEnum } from "@@intelease/app-models/abstract-review";
import { AppAuthorities } from "@@intelease/web/intelease/constants";
import { AbstractReviewFacade } from "@@intelease/app-state/abstract-review";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-provision-mentions",
  templateUrl: "./provision-mentions.component.html",
  styleUrls: ["./provision-mentions.component.scss"],
})
export class ProvisionMentionsComponent {
  @Input() snippets = undefined;
  @Input() documentsShortNameMap?: { [documentUid: string]: any } = {};
  @Output() pickSnippet: EventEmitter<any> = new EventEmitter<any>();
  @Output() gotoMention: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  gotoMentionHighlightOnViewer: EventEmitter<any> = new EventEmitter<any>();
  @Input() provisionBoxInitTypes: ProvisionBoxInitTypesEnum;
  provisionBoxInitTypesEnum = ProvisionBoxInitTypesEnum;
  @Input() editable: boolean;
  AUTH = AppAuthorities;
  isSelectedDocumentFullyLoaded = false;

  destroyRef = inject(DestroyRef);

  constructor(
    private abstractReviewFacade: AbstractReviewFacade,
    private cdr: ChangeDetectorRef
  ) {
    this.abstractReviewFacade.isSelectedDocumentFullyLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.isSelectedDocumentFullyLoaded = res;
        this.cdr.detectChanges();
      });
  }

  onPickSnippet(snippet) {
    this.pickSnippet.emit(snippet);
  }

  gotoMentionBySnippet(snippet) {
    this.gotoMention.emit(snippet.relatedOptionUids[0]);
  }
}
