<div class="il-comment-input">
  <div class="input-container">
    <p [id]="id" class="input"></p>
    <!--        <button-->
    <!--                mat-icon-button-->
    <!--                type="button"-->
    <!--                class="mention-btn"-->
    <!--                [matMenuTriggerFor]="menu"-->
    <!--                (menuOpened)="onUserMentionMenuOpened()"-->
    <!--        >-->
    <!--            @-->
    <!--        </button>-->
  </div>

  <mat-menu #menu="matMenu" xPosition="before">
    <div style="max-height: 250px">
      <input
        aria-label="search users to mention them in comment"
        mat-menu-item
        type="text"
        style="width: 100%"
        [(ngModel)]="userMentionMenuSearchInput"
        (ngModelChange)="onSearchInputChanged($event)"
      />
      <mat-option
        *ngFor="let filteredAccountUser of filteredAccountUsers"
        (click)="onMentionItemClick($event)"
        (onSelectionChange)="onMentionUserSelected(filteredAccountUser)"
      >
        <span
          >{{ filteredAccountUser.value }}
          <span style="font-size: 10px"
            >({{ filteredAccountUser.email }})</span
          ></span
        >
      </mat-option>
    </div>
  </mat-menu>
</div>
