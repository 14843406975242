import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";

@JsonObject("DocumentAssetModel")
export class DocumentAssetModel {
  static view = "full";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("sourceFilename", String)
  sourceFilename = "";

  @JsonProperty("page", Number)
  page = 0;

  @JsonProperty("label", String)
  label = "";

  @JsonProperty("createdAt", DateTimeConvertService)
  createdAt: Date = undefined;
}
