<form>
  <div class="add-project-container">
    <h1 mat-dialog-title>Create a New Project</h1>
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          [(ngModel)]="projectName"
          (keyup)="
            requiredFieldEmpty = projectName === '' || projectName === undefined
          "
          matInput
          itlsFocusIn
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input
          [(ngModel)]="description"
          matInput
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>
    <div
      mat-dialog-actions
      style="display: flex; align-items: center; justify-content: space-around"
    >
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <div
        [matTooltip]="
          requiredFieldEmpty ? 'The project name is required!' : 'Create'
        "
      >
        <button
          mat-flat-button
          color="primary"
          type="submit"
          (click)="onSaveProjectClick()"
          [disabled]="requiredFieldEmpty"
        >
          Create
        </button>
      </div>
    </div>
  </div>
</form>
