import { NgModule } from "@angular/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CommonModule } from "@angular/common";
import { ItlsChartsComponent } from "./itls-charts.component";
import { NgChartsModule } from "ng2-charts";

const MATERIAL_MODULES = [];

const UI_MODULES = [];

@NgModule({
  imports: [
    NgxDatatableModule,
    CommonModule,
    ...UI_MODULES,
    ...MATERIAL_MODULES,
    NgChartsModule,
  ],
  declarations: [ItlsChartsComponent],
  exports: [ItlsChartsComponent],
})
export class ItlsChartsModule {}
