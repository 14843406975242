<div>
  <div mat-dialog-title class="clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5>
      {{ modalTitle }}
    </h5>
  </div>
  <div mat-dialog-content>
    <div class="form-group form-group-default" itlsPgFormGroupDefault>
      <label>Filter Name</label>
      <input
        itlsFocusIn
        [(ngModel)]="filterName"
        type="text"
        class="form-control"
      />
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      *ngIf="mode === 'ADD'"
      type="button"
      class="btn btn-primary btn-cons pull-left inline"
      (click)="onCreateFilter()"
    >
      Create
    </button>
    <button
      *ngIf="mode === 'EDIT'"
      type="button"
      class="btn btn-primary btn-cons pull-left inline"
      (click)="onEditFilter()"
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-default btn-cons no-margin pull-left inline"
      (click)="onDismissModal()"
    >
      Cancel
    </button>
  </div>
</div>
