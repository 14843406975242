/* tslint:disable */
/* eslint-disable */
import { DoubleWithUnitModel } from "./double-with-unit-model";
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";

/**
 * the calculated term
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CalculatedTermModel")
export class CalculatedTermModel {
  @JsonProperty("endDate", SimpleOpenApiDateModel, true)
  endDate?: SimpleOpenApiDateModel = undefined;

  @JsonProperty("startDate", SimpleOpenApiDateModel, true)
  startDate?: SimpleOpenApiDateModel = undefined;

  @JsonProperty("term", DoubleWithUnitModel, true)
  term?: DoubleWithUnitModel = undefined;
}
