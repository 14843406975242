<div class="itls-comment" (click)="$event.stopPropagation()">
  <div class="loading" *ngIf="isLoading || isSaveLoading">
    <mat-progress-spinner
      diameter="30"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <div class="comment" fxLayout="column" fxLayoutAlign="start center">
    <div
      *ngIf="comments.length === 0"
      class="no-comment-found"
      style="width: 100%; margin-top: 10px; margin-left: 46px"
    >
      <p style="font-size: 12px">No comments yet.</p>
    </div>
    <div class="comment-list" [style.maxHeight]="maxHeight">
      <il-comment-detail
        *ngFor="let comment of comments; let i = index"
        [mentionList]="mentionsList"
        [accountUsers]="accountUsers"
        [comment]="comment"
        (editCommentEvent)="onEditComment($event)"
        (deleteCommentEvent)="onDeleteComment(comment)"
        [style.borderBottomWidth]="i < comments.length - 1 ? '1px' : '0'"
      >
      </il-comment-detail>
    </div>
    <div class="add-comment" *ngIf="canAddNewComment">
      <il-comment-input
        *ngIf="mentionModel"
        [mentionList]="mentionsList"
        style="
          background-color: #f5f6f7;
          margin-top: 3px;
          padding: 8px;
          width: 83%;
        "
        [(model)]="mentionModel"
        [id]="inputId"
        [placeholder]="'Add new comment...'"
      >
      </il-comment-input>
      <!--            <mat-form-field class="comment-input">-->
      <!--                <mat-label>Add Comment</mat-label>-->
      <!--                <textarea-->
      <!--                    matInput-->
      <!--                    [(ngModel)]="newComment"-->
      <!--                    cdkTextareaAutosize-->
      <!--                    placeholder="Add new comment..."-->
      <!--                    #message-->
      <!--                    (focusout)="onNewCommentTextFocusOut($event)"-->
      <!--                    [mention]="accountUsers"-->
      <!--                    [mentionConfig]="{ labelKey: 'mentionText', dropUp: true }"-->
      <!--                    (itemSelected)="onMentionUserSelectedFromDropdown($event)"-->
      <!--                    maxlength="200"-->
      <!--                    cdkAutosizeMinRows="1"-->
      <!--                    cdkAutosizeMaxRows="5"-->
      <!--                ></textarea>-->
      <!--                <button-->
      <!--                    type="button"-->
      <!--                    class="select-mention-users-btn"-->
      <!--                    [matMenuTriggerFor]="menu"-->
      <!--                    (menuOpened)="onUserMentionMenuOpened()"-->
      <!--                >-->
      <!--                    @-->
      <!--                </button>-->
      <!--                <mat-hint align="start"><strong></strong></mat-hint>-->
      <!--                <mat-hint align="end"-->
      <!--                    >{{ message.value.length }} / 200</mat-hint-->
      <!--                >-->
      <!--            </mat-form-field>-->
      <button
        mat-flat-button
        color="primary"
        class="ml-2 mt-1 send-btn"
        (click)="onSaveCommentClick()"
        [disabled]="isSaveLoading"
        [promiseBtn]="isSaveLoading"
      >
        <mat-icon svgIcon="icon-send"></mat-icon>
        Send
      </button>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu" xPosition="before">
  <div style="max-height: 250px">
    <input
      #userMentionSearchInputElement
      aria-label="search users to mention them in comment"
      mat-menu-item
      type="text"
      style="width: 100%"
      [(ngModel)]="userMentionMenuSearchInput"
      (ngModelChange)="onSearchInputChanged()"
    />
    <mat-option
      *ngFor="let filteredAccountUser of filteredAccountUsers"
      (onSelectionChange)="onMentionUserSelected(filteredAccountUser)"
    >
      <span
        >{{ filteredAccountUser.name }}
        <span style="font-size: 10px"
          >({{ filteredAccountUser.email }})</span
        ></span
      >
    </mat-option>
  </div>
</mat-menu>
