<div class="uploading-box">
  <div class="uploading-box-header" fxLayout="row" fxLayoutAlign="end center">
    <span style="color: white; margin-right: auto">Uploads</span>
    <button
      class="minimize-btn"
      mat-icon-button
      (click)="onToggleMinimized()"
      [matTooltip]="isMinimized ? 'Maximize' : 'Minimize'"
    >
      <mat-icon
        [svgIcon]="isMinimized ? 'icon-arrow-up' : 'icon-arrow-down'"
      ></mat-icon>
    </button>
    <button
      class="close-btn"
      [matTooltip]="'Close'"
      mat-icon-button
      (click)="onCloseClicked()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div
    class="uploading-box-content"
    fxLayout="column"
    fxLayoutAlign="start"
    [style.maxHeight]="isMinimized ? '0px' : '250px'"
  >
    <div class="content-item-container" *ngFor="let item of items">
      <div
        *ngIf="item.isFolder"
        class="content-item-folder"
        [class.clickable]="
          item.folderStatus === UploadDocSetDataStatus.COMPLETED ||
          item.folderStatus === UploadDocSetDataStatus.PARTIALLY_COMPLETED
        "
        (click)="onUploadedDataClicked(item)"
      >
        <mat-icon class="folder-icon" [svgIcon]="'icon-documents'"></mat-icon>
        <span
          class="content-item-folder-name"
          [matTooltip]="item.folderName"
          #folderNameTextSpan
          [matTooltipDisabled]="
            !(folderNameTextSpan.offsetWidth < folderNameTextSpan.scrollWidth)
          "
          >{{ item.folderName }}</span
        >
        <button
          mat-icon-button
          style="
            width: 24px;
            height: 24px;
            line-height: 24px;
            margin-right: 4px;
          "
          click-stop-propagation
          (click)="item.isExpanded = !item.isExpanded"
        >
          <mat-icon>{{
            item.isExpanded ? "expand_more" : "chevron_right"
          }}</mat-icon>
        </button>
        <div
          *ngIf="item.folderStatus === UploadDocSetDataStatus.UPLOADING"
          style="min-width: 24px; min-height: 24px"
          (mouseover)="item.isAbleToCancelUpload = true"
          (mouseout)="item.isAbleToCancelUpload = false"
        >
          <itls-pg-progress
            matTooltip="Uploading"
            class="progress"
            [type]="'circle'"
            [thick]="true"
            [indeterminate]="true"
            *ngIf="!item.isAbleToCancelUpload"
          >
          </itls-pg-progress>
          <button
            mat-icon-button
            style="width: 24px; height: 24px; line-height: 24px"
            *ngIf="item.isAbleToCancelUpload"
            click-stop-propagation
            (click)="onCancelUpload(item, item.docSets)"
          >
            <mat-icon class="cancel-upload-icon" matTooltip="Cancel upload"
              >cancel</mat-icon
            >
          </button>
        </div>
        <mat-icon
          style="color: #0f9d58"
          *ngIf="item.folderStatus === UploadDocSetDataStatus.COMPLETED"
          matTooltip="Upload completed"
          >check_circle</mat-icon
        >
        <mat-icon
          style="color: #ff8f00"
          *ngIf="
            item.folderStatus === UploadDocSetDataStatus.PARTIALLY_COMPLETED
          "
          matTooltip="Upload partially completed"
          >warning</mat-icon
        >
        <mat-icon
          style="color: #db4437"
          *ngIf="item.folderStatus === UploadDocSetDataStatus.CANCELED"
          matTooltip="Upload canceled"
          >error</mat-icon
        >
      </div>
      <ng-container *ngIf="item.isFolder && item.isExpanded">
        <div
          *ngFor="let record of item.docSets"
          class="content-item-record child-record"
          [class.clickable]="record.status === UploadDocSetDataStatus.COMPLETED"
          (click)="onUploadedDataChildRecordClicked(record)"
        >
          <mat-icon class="record-icon" [svgIcon]="'icon-note'"></mat-icon>
          <span
            class="content-item-record-name"
            [matTooltip]="record.name"
            #recordNameWithFolderTextSpan
            [matTooltipDisabled]="
              !(
                recordNameWithFolderTextSpan.offsetWidth <
                recordNameWithFolderTextSpan.scrollWidth
              )
            "
            >{{ record.name }}</span
          >
          <div
            *ngIf="record.status === UploadDocSetDataStatus.UPLOADING"
            style="min-width: 24px; min-height: 24px"
            (mouseover)="record.isAbleToCancelUpload = true"
            (mouseout)="record.isAbleToCancelUpload = false"
          >
            <itls-pg-progress
              matTooltip="Uploading"
              class="progress"
              [type]="'circle'"
              [thick]="true"
              [indeterminate]="true"
              *ngIf="!record.isAbleToCancelUpload"
            >
            </itls-pg-progress>
            <button
              mat-icon-button
              style="width: 24px; height: 24px; line-height: 24px"
              *ngIf="record.isAbleToCancelUpload"
              click-stop-propagation
              (click)="onCancelUpload(item, [record])"
            >
              <mat-icon class="cancel-upload-icon" matTooltip="Cancel upload"
                >cancel</mat-icon
              >
            </button>
          </div>
          <mat-icon
            style="color: #0f9d58"
            *ngIf="record.status === UploadDocSetDataStatus.COMPLETED"
            matTooltip="Upload completed"
            >check_circle</mat-icon
          >
          <mat-icon
            style="color: #db4437"
            *ngIf="record.status === UploadDocSetDataStatus.CANCELED"
            matTooltip="Upload canceled"
            >error</mat-icon
          >
        </div>
      </ng-container>
      <div
        *ngIf="!item.isFolder"
        class="content-item-record root-record"
        [class.clickable]="
          item.docSet.status === UploadDocSetDataStatus.COMPLETED
        "
        (click)="onUploadedDataClicked(item)"
      >
        <mat-icon class="record-icon" [svgIcon]="'icon-note'"></mat-icon>
        <span
          class="content-item-record-name"
          [matTooltip]="item.docSet.name"
          #recordNameTextSpan
          [matTooltipDisabled]="
            !(recordNameTextSpan.offsetWidth < recordNameTextSpan.scrollWidth)
          "
          >{{ item.docSet.name }}</span
        >
        <div
          *ngIf="item.docSet.status === UploadDocSetDataStatus.UPLOADING"
          style="min-width: 24px; min-height: 24px"
          (mouseover)="item.docSet.isAbleToCancelUpload = true"
          (mouseout)="item.docSet.isAbleToCancelUpload = false"
        >
          <itls-pg-progress
            matTooltip="Uploading"
            class="progress"
            [type]="'circle'"
            [thick]="true"
            [indeterminate]="true"
            *ngIf="!item.docSet.isAbleToCancelUpload"
          >
          </itls-pg-progress>
          <button
            mat-icon-button
            style="width: 24px; height: 24px; line-height: 24px"
            *ngIf="item.docSet.isAbleToCancelUpload"
            click-stop-propagation
            (click)="onCancelUpload(item, [item.docSet])"
          >
            <mat-icon class="cancel-upload-icon" matTooltip="Cancel upload"
              >cancel</mat-icon
            >
          </button>
        </div>
        <mat-icon
          style="color: #0f9d58"
          *ngIf="item.docSet.status === UploadDocSetDataStatus.COMPLETED"
          matTooltip="Upload completed"
          >check_circle</mat-icon
        >
        <mat-icon
          style="color: #db4437"
          *ngIf="item.docSet.status === UploadDocSetDataStatus.CANCELED"
          matTooltip="Upload canceled"
          >error</mat-icon
        >
      </div>
    </div>
  </div>
</div>
