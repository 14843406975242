import { Component, AfterViewInit, DestroyRef, inject } from "@angular/core";

import { ControlWidget } from "../../widget";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "sf-checkbox-widget",
  template: `
    <div class="widget form-group">
      <label
        *ngIf="schema.title"
        [attr.for]="id"
        class="horizontal control-label"
      >
        {{ schema.title }}
      </label>
      <div *ngIf="schema.type !== 'array'">
        <label class="horizontal control-label">
          <input
            [formControl]="control"
            [attr.name]="name"
            [indeterminate]="
              control.value !== false && control.value !== true ? true : null
            "
            type="checkbox"
            [disabled]="schema.readOnly"
          />
          <input
            *ngIf="schema.readOnly"
            [attr.name]="name"
            type="hidden"
            [formControl]="control"
          />
          {{ schema.description }}
          <i
            *ngIf="schema.tooltip"
            class="fa fa-info-circle ml-1"
            [matTooltip]="schema.tooltip"
          ></i>
          <a
            *ngIf="!!schema.link"
            [href]="schema.link.href"
            target="_blank"
            class="control-label"
            >{{ schema.link.title }}</a
          >.
        </label>
      </div>
      <ng-container *ngIf="schema.type === 'array'">
        <div *ngFor="let option of schema.items.oneOf">
          <label class="horizontal control-label">
            <input
              [attr.name]="name"
              value="{{ option.enum[0] }}"
              type="checkbox"
              [attr.disabled]="schema.readOnly"
              (change)="onCheck($event.target)"
              [attr.checked]="checked[option.enum[0]] ? true : null"
            />
            {{ option.description }}
          </label>
        </div>
      </ng-container>
    </div>
  `,
})
export class CheckboxWidgetComponent
  extends ControlWidget
  implements AfterViewInit
{
  destroyRef = inject(DestroyRef);
  checked: any = {};

  ngAfterViewInit() {
    const control = this.control;
    this.formProperty.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newValue) => {
        if (control.value !== newValue) {
          control.setValue(newValue, { emitEvent: false });
          if (newValue && Array.isArray(newValue)) {
            newValue.map((v) => (this.checked[v] = true));
          }
        }
      });
    this.formProperty.errorsChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((errors) => {
        control.setErrors(errors, { emitEvent: true });
      });
    control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newValue) => {
        this.formProperty.setValue(newValue, false);
      });
  }

  onCheck(el) {
    if (el.checked) {
      this.checked[el.value] = true;
    } else {
      delete this.checked[el.value];
    }
    this.formProperty.setValue(Object.keys(this.checked), false);
  }
}
