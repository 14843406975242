import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
// import {InteleaseModule} from '@intelease/intelease.module';
import { ReactiveFormsModule } from "@angular/forms";
import { CalendarFilterComponent } from "./calendar-filter.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import {
  ItlsFilterModule,
  NgxColorChromePickerWrapperModule,
} from "@@intelease/web/ui";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AppStateCalendarModule } from "@@intelease/app-state/calendar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CalendarFieldsModule } from "../calendar-fields/calendar-fields.module";
import { ColorPickerModule } from "@iplab/ngx-color-picker";

const UI_MODULES = [ItlsFilterModule, NgxColorChromePickerWrapperModule];

const MATERIAL_MODULES = [
  MatTabsModule,
  MatFormFieldModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
];

@NgModule({
  declarations: [CalendarFilterComponent],
  imports: [
    CommonModule,
    InteleaseCommonModule,
    ReactiveFormsModule,
    // InteleaseModule,
    ...UI_MODULES,
    ...MATERIAL_MODULES,
    ColorPickerModule,
    AppStateCalendarModule,
    CalendarFieldsModule,
  ],
  exports: [CalendarFilterComponent],
})
export class CalendarFilterModule {}
