import { Component, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";

export type ItlsCustomIconType =
  | "icon-add-comment"
  | "icon-add"
  | "icon-advanced-search"
  | "icon-arrow-down"
  | "icon-arrow-left"
  | "icon-arrow-right"
  | "icon-arrow"
  | "icon-arrow-up"
  | "icon-assigned-to-me"
  | "icon-attached"
  | "icon-bar-chart"
  | "icon-bookmark"
  | "icon-cancel-search"
  | "icon-cancel"
  | "icon-comment-outline"
  | "icon-comment"
  | "icon-configuration"
  | "icon-dashboard"
  | "icon-date"
  | "icon-document-location"
  | "icon-document-shared"
  | "icon-documents"
  | "icon-done-gray"
  | "icon-done"
  | "icon-donut-chart"
  | "icon-download"
  | "icon-edit"
  | "icon-email"
  | "icon-expand"
  | "icon-export"
  | "icon-filter"
  | "icon-folder-share"
  | "icon-form"
  | "icon-grid"
  | "icon-histogram-2-chart"
  | "icon-histogram-chart"
  | "icon-line-chart"
  | "icon-link"
  | "icon-home"
  | "icon-location"
  | "icon-mention"
  | "icon-menu"
  | "icon-menu-white"
  | "icon-arrow-down-white"
  | "icon-search-white"
  | "icon-setting-white"
  | "icon-form-white"
  | "icon-dashboard-white"
  | "icon-drive-white"
  | "icon-share-drive-white"
  | "icon-delete-white"
  | "icon-task-white"
  | "icon-Queue-white"
  | "icon-report-white"
  | "icon-notification-white"
  | "icon-minimize"
  | "icon-money"
  | "icon-more"
  | "icon-more-vertical"
  | "icon-move"
  | "icon-name"
  | "icon-new-window"
  | "icon-next"
  | "icon-note"
  | "icon-note-gray"
  | "icon-notification"
  | "icon-numeric"
  | "icon-pen"
  | "icon-pen-black"
  | "icon-phone"
  | "icon-pie-chart"
  | "icon-polar-chart"
  | "icon-polar"
  | "icon-provisions"
  | "icon-radar"
  | "icon-reminders"
  | "icon-remove"
  | "icon-remove-gray"
  | "icon-rename"
  | "icon-rename-gray"
  | "icon-report"
  | "icon-review"
  | "icon-saved-lists"
  | "icon-search"
  | "icon-send"
  | "icon-setting"
  | "icon-share"
  | "icon-table"
  | "icon-take-me-there"
  | "icon-task"
  | "icon-team"
  | "icon-text"
  | "icon-text-underlined"
  | "icon-tick"
  | "icon-time-chart"
  | "icon-time"
  | "icon-toc"
  | "icon-topics"
  | "icon-upload"
  | "icon-view-hidden"
  | "icon-view-visible"
  | "icon-widget-maximize"
  | "icon-widget-minimize"
  | "icon-year"
  | "icon-arrow-right-white"
  | "icon-arrow-left-white"
  | "icon-duplicate"
  | "icon-duplicate-gray"
  | "icon-team"
  | "icon-person"
  | "icon-drive"
  | "icon-shared-drive"
  | "icon-queue"
  | "icon-report-outline"
  | "icon-pdf"
  | "icon-image"
  | "icon-ppt"
  | "icon-bmp"
  | "icon-word"
  | "icon-excel"
  | "icon-share-me"
  | "icon-star-fill"
  | "icon-low"
  | "icon-Standard"
  | "icon-medium"
  | "icon-high"
  | "icon-Urgent"
  | "icon-share-drive"
  | "icon-shared-folder"
  | "icon-project"
  | "icon-google-drive";

@Component({
  selector: "itls-icon",
  template: `
    <img
      style="vertical-align: middle !important;"
      height="{{ size }}"
      width="{{ size }}"
      [src]="svgAssetPath + svgIcon + extension"
      [alt]="svgIcon"
    />
  `,
})
export class ItlsCustomIconComponent implements OnInit {
  /**
   * The name of the icon, it should be one of the valid names. It's required
   */
  @Input() svgIcon: ItlsCustomIconType | string;

  /**
   * Height of the Icon in pixel, the default is 14, 14 pixel. It's optional
   */
  @Input() height: number = undefined;

  /**
   * Disable the icon
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = value;
    this.updateClass("disabled", value);
  }
  _disabled = false;

  /**
   * gray out the icon the icon action work
   */
  @Input() get grayOut(): boolean {
    return this._grayOut;
  }
  set grayOut(value) {
    this._grayOut = value;
    this.updateClass("gray-out", value);
  }
  _grayOut = false;

  size = 14;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  public svgAssetPath = "assets/images/itls-icons/";
  public extension = ".svg";

  ngOnInit(): void {
    if (this.height && Number(this.height)) {
      this.size = Number(this.height);
    }
  }

  updateClass(className: "disabled" | "gray-out", value: boolean) {
    value
      ? this.renderer.addClass(this.elementRef.nativeElement, className)
      : this.renderer.removeClass(this.elementRef.nativeElement, className);
  }
}
