/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EntityPermissionUpdateDtoModel")
export class EntityPermissionUpdateDtoModel {
  @JsonProperty("domainType", Any, true)
  domainType?: "PUBLIC" | "ACCOUNT" | "ROLE" | "USER" = undefined;

  @JsonProperty("domainUid", String, true)
  domainUid?: string = "";

  @JsonProperty("entityType", Any, true)
  entityType?:
    | "FINAL_ABSTRACT"
    | "AUTO_ABSTRACT"
    | "PDF_DOCUMENT_ABSTRACT"
    | "PROVISION_FORM"
    | "PROVISION_LIBRARY"
    | "PROVISION_INFO"
    | "PROJECT"
    | "CALENDAR"
    | "DASHBOARD"
    | "REPORT"
    | "DIRECTORY"
    | "STACK"
    | "DOC_SET"
    | "QUEUE" = undefined;

  @JsonProperty("entityUid", String, true)
  entityUid?: string = "";

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    | "ALL"
    | "EDIT"
    | "COMPLETE"
    | "READ"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "DELETE"
    | "MOVE"
    | "COMMENT"
    | "EXPLORE"
    | "SHARE"
    | "SHARE_SHARE"
    | "SHARE_EDIT"
    | "SHARE_READ"
    | "SHARE_EXPORT"
    | "SHARE_MOVE"
    | "SHARE_DELETE"
    | "SHARE_COMMENT"
  > = [];
}
