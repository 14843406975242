<div class="modal-content">
  <div class="modal-header clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5>{{ modalTitle }}</h5>
  </div>
  <div class="modal-body">
    <div class="form-group form-group-default" itlsPgFormGroupDefault>
      <label> Abstract Name </label>
      <input
        dataCy="save-abstract-file-name-input"
        itlsFocusIn
        [(ngModel)]="abstractName"
        type="text"
        class="form-control"
      />

      <br />

      <input type="checkbox" [(ngModel)]="deselectIncomplete" />
      <span>
        Un-select all mentions for provisions whose status is incomplete
      </span>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      dataCy="save-abstract-save-btn"
      class="btn btn-primary btn-cons pull-left inline"
      (click)="onGiveFilename()"
    >
      Save
    </button>
  </div>
</div>
