import { Injectable, ErrorHandler, DestroyRef, inject } from "@angular/core";
import { HttpErrorResponse, HttpRequest } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { UserInfoService } from "@@intelease/web/intelease/services";
import { debounceTime, map, mergeMap, take } from "rxjs/operators";
import {
  ErrorDataModel,
  FrontendErrorInfoDtoModel,
  UtilityService,
} from "@@intelease/api-models/adex-api-model-src";
import { getUserDeviceInfo } from "@@intelease/web/utils";
import * as Bowser from "bowser";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
declare var browser;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private error: Subject<FrontendErrorInfoDtoModel> =
    new Subject<FrontendErrorInfoDtoModel>();
  private readonly errorMap: Map<string, number> = new Map();

  destroyRef = inject(DestroyRef);

  constructor(private readonly utilityService: UtilityService) {
    this.handleErrors();
  }

  private handleNewError(error: FrontendErrorInfoDtoModel): void {
    const stringifiedError = JSON.stringify(error);
    if (this.errorMap.has(stringifiedError)) {
      const frequency = this.errorMap.get(stringifiedError) as number;
      this.errorMap.set(stringifiedError, frequency + 1);
    } else {
      this.errorMap.set(stringifiedError, 1);
    }
  }

  private getErrorsFromMap(): FrontendErrorInfoDtoModel[] {
    const entries = Array.from(this.errorMap.entries());

    return entries.map(([stringifiedError, frequency]) => {
      return {
        ...JSON.parse(stringifiedError),
        frequency,
      };
    });
  }

  private handleErrors(): void {
    this.error
      .asObservable()
      .pipe(
        map((error) => this.handleNewError(error)),
        debounceTime(2000)
      )
      .pipe(
        mergeMap(() => {
          const errors = this.getErrorsFromMap();
          this.errorMap.clear();
          return this.utilityService.logFrontendErrors({
            body: {
              data: {
                errors,
              },
            },
          });
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  handleError(error: Error): void {
    console.error(error);
    if (this.isErrorHttpErrorResponse(error)) {
      const requestTime = this.getRequestTime(error.request);
      const timePeriod = this.getTimePeriod(requestTime);

      this.getJSONFromBlob<{ errors: ErrorDataModel[] }>(error.error)
        .pipe(take(1))
        .subscribe((backendResponse) => {
          this.error.next({
            apiRequestMethod: error?.request?.method,
            apiRequestPath: error?.request?.url,
            apiRequestBody: error?.request?.body,
            apiRequestQueryParams: error?.request?.params
              ? error?.request?.params.toString()
              : "",
            message: backendResponse?.errors?.[0]?.message,
            requestTime,
            timePeriod,
            ...this.commonProperties,
          });
        });
    } else {
      this.error.next({
        stacktrace: error.stack?.split("\n"),
        message: error.message,
        ...this.commonProperties,
      });
    }
  }

  private getTimePeriod(requestStart: Date): number {
    const endTimestamp: number = new Date().getTime();
    const startTimestamp: number = requestStart.getTime();
    return endTimestamp - startTimestamp;
  }

  private getRequestTimeAsString(request: HttpRequest<unknown>): string {
    return request.headers.get("X-Date-Now") as string;
  }

  private getRequestTime(request: HttpRequest<unknown>): Date {
    return new Date(this.getRequestTimeAsString(request));
  }

  private get commonProperties(): Partial<FrontendErrorInfoDtoModel> {
    const device: Bowser.Parser.ParsedResult = getUserDeviceInfo();
    return {
      email: UserInfoService.getUserEmail(),
      appUrl: window.location.href,
      browser: `${device.browser.name} ${device.browser.version}`,
      operatingSystem: `${device.os.name} ${device.os.version} ${device.os.versionName}`,
    };
  }

  private getJSONFromBlob<T>(blob: Blob): Observable<T> {
    return new Observable((observer) => {
      const fr = new FileReader();
      fr.onload = function () {
        observer.next(JSON.parse(this.result as string));
        observer.complete();
      };

      fr.readAsText(blob);
    });
  }

  private isErrorHttpErrorResponse(
    error: Error
  ): error is HttpErrorResponse & { request: HttpRequest<unknown> } {
    return error.name === HttpErrorResponse.name;
  }
}
