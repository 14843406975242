/* tslint:disable */
/* eslint-disable */
import { AbstractReviewOperationModeViewModel } from "./abstract-review-operation-mode-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ChangeReviewOperationModeResponseDtoModel")
export class ChangeReviewOperationModeResponseDtoModel {
  @JsonProperty(
    "reviewOperationMode",
    AbstractReviewOperationModeViewModel,
    true
  )
  reviewOperationMode?: AbstractReviewOperationModeViewModel = undefined;
}
