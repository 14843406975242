import { Injectable } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import {
  DeleteConfirmModalComponent,
  PermissionConfirmModalComponent,
} from "@@intelease/web/common/modals";
import { RenameModalComponent } from "@@intelease/web/common/modals/rename/rename-modal.component";
import { ChooseProvisionFormModalComponent } from "@@intelease/web/common/modals/choose-provision-form/choose-provision-form-modal.component";
import { GenericOkCancelModalComponent } from "@@intelease/web/common/modals/generic-ok-cancel/generic-ok-cancel-modal.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ComponentModeEnum, ModalsResponseTypeEnum } from "@intelease/enums";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class CommonModalService {
  constructor(private dialog: MatDialog) {}

  openPendingChangesModal(): Observable<boolean> {
    const modalData = new ModalInputModel();
    modalData.payload = {
      message:
        "You have unsaved changes, are you sure you want to discard them?",
      title: "Unsaved Changes",
      confirmButtonTitle: "Discard",
      cancelButtonTitle: "Cancel",
    };
    return this.openGenericOkCancelModal(modalData)
      .afterClosed()
      .pipe(map((res) => res?.data?.exitType === ModalsResponseTypeEnum.CLOSE));
  }

  openGenericOkCancelModal(
    modalData: ModalInputModel,
    dialogConfig?: MatDialogConfig
  ) {
    return this.dialog.open(GenericOkCancelModalComponent, {
      ...dialogConfig,
      data: modalData,
    });
  }

  openConfirmPermissionModal(modalData: ModalInputModel, modalId?: string) {
    return this.dialog.open(PermissionConfirmModalComponent, {
      data: modalData,
      id: modalId || "PermissionConfirmModalComponentDialogId",
      disableClose: true,
    });
  }

  openRenameModal(modalData: ModalInputModel) {
    return this.dialog.open(RenameModalComponent, {
      data: modalData,
    });
  }

  openChooseProvisionFormModal(modalData: ModalInputModel) {
    return this.dialog.open(ChooseProvisionFormModalComponent, {
      data: modalData,
    });
  }

  openDeleteConfirmModal(modalData: ModalInputModel) {
    return this.dialog.open(DeleteConfirmModalComponent, {
      data: modalData,
      minWidth: "300px",
      disableClose: true,
    });
  }
}
