import { Injectable } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { TableProvisionModalComponent } from "./table-provision-modal.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class TableProvisionModalService {
  constructor(private dialog: MatDialog) {}

  openTableProvisionModal(modalData: ModalInputModel) {
    return this.dialog.open(TableProvisionModalComponent, {
      data: { ...modalData },
      panelClass: "provision-table",
      disableClose: true,
      minWidth: "900px",
      maxWidth: "unset",
    });
  }
}
