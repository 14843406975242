import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PROJECTS_FEATURE_KEY, ProjectsState } from "./projects.reducer";
import { values } from "lodash";

// Lookup the 'Projects' feature state managed by NgRx
const getProjectsState =
  createFeatureSelector<ProjectsState>(PROJECTS_FEATURE_KEY);

const getLoaded = createSelector(
  getProjectsState,
  (state: ProjectsState) => state.loaded
);
const getError = createSelector(
  getProjectsState,
  (state: ProjectsState) => state.error
);

const getAllProjects = createSelector(
  getProjectsState,
  getLoaded,
  (state: ProjectsState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getProjectsState,
  (state: ProjectsState) => state.selectedId
);
const getSelectedProjects = createSelector(
  getAllProjects,
  getSelectedId,
  (projects, id) => {
    const result = projects.find((it) => it["id"] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

const loadProjectSucceeded = createSelector(
  getProjectsState,
  (state) => state.loadProjectSucceeded
);

const isLoadProjectLoading = createSelector(
  getProjectsState,
  (state) => state.isLoadProjectLoading
);

const loadProjectError = createSelector(
  getProjectsState,
  (state) => state.loadProjectError
);

const getProjectWidgets = createSelector(
  getProjectsState,
  loadProjectSucceeded,
  (state: ProjectsState, isSelectedProjectLoaded: boolean) =>
    isSelectedProjectLoaded ? values(state.projectWidgets) : undefined
);

const getProjectWidgetsMap = createSelector(
  getProjectsState,
  loadProjectSucceeded,
  (state: ProjectsState, isSelectedProjectLoaded: boolean) =>
    isSelectedProjectLoaded ? state.projectWidgetsMap : undefined
);

const getProjectDetail = createSelector(
  getProjectsState,
  loadProjectSucceeded,
  (state: ProjectsState, isSelectedProjectLoaded: boolean) =>
    isSelectedProjectLoaded ? state.selectedProjectDetail : undefined
);

const getSelectedProjectDetailMap = createSelector(
  getProjectsState,
  loadProjectSucceeded,
  (state: ProjectsState, isSelectedProjectLoaded: boolean) =>
    isSelectedProjectLoaded ? state.selectedProjectDetailMap : undefined
);

const saveProjectSucceeded = createSelector(
  getProjectsState,
  (state: ProjectsState) => state.saveProjectSucceeded
);

const getSaveProjectDetail = createSelector(
  getProjectsState,
  saveProjectSucceeded,
  (state: ProjectsState, projectSaved: boolean) =>
    projectSaved ? state.saveProjectDetail : undefined
);

const loadAllProjectsSucceeded = createSelector(
  getProjectsState,
  (state: ProjectsState) => state.loadAllProjectsSucceeded
);

const isLoadAllProjectsLoading = createSelector(
  getProjectsState,
  (state: ProjectsState) => state.isLoadAllProjectsLoading
);

const getAllProjectsList = createSelector(
  getProjectsState,
  loadAllProjectsSucceeded,
  (state: ProjectsState, isLoaded) => (isLoaded ? state.projects : undefined)
);

const deleteProjectSucceeded = createSelector(
  getProjectsState,
  (state) => state.deleteProjectSucceeded
);

const deleteBatchProjectSucceeded = createSelector(
  getProjectsState,
  (state) => state.deleteBatchProjectSucceeded
);

const isUpdateProjectLoading = createSelector(
  getProjectsState,
  (state: ProjectsState) => state.isUpdateProjectLoading
);

const updateProjectSucceeded = createSelector(
  getProjectsState,
  isUpdateProjectLoading,
  (state: ProjectsState, isLoading) =>
    !isLoading && state.updateProjectSucceeded
);

export const projectsQuery = {
  getLoaded,
  getError,
  getAllProjects,
  getSelectedProjects,
  getProjectWidgets,
  getProjectDetail,
  getProjectWidgetsMap,
  getSelectedProjectDetailMap,
  isLoadProjectLoading,
  loadProjectError,

  saveProjectSucceeded,
  getSaveProjectDetail,

  isLoadAllProjectsLoading,
  getAllProjectsList,

  deleteProjectSucceeded,
  deleteBatchProjectSucceeded,

  isUpdateProjectLoading,
  updateProjectSucceeded,
};
