import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { ItlsNavigationItem } from "@@intelease/web/intelease/interfaces";
import { AppAuthorities } from "@@intelease/web/intelease/constants";
import { ItlsNavigationComponent } from "@@intelease/web/intelease/components/navigation/navigation.component";

@Component({
  selector: "itls-nav-vertical-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.scss"],
})
export class ItlsNavVerticalGroupComponent implements OnInit {
  @HostBinding("class")
  classes = "nav-group nav-item";

  @Input()
  item: ItlsNavigationItem;

  expectedAuthorities: string[];
  expectedIconAuthorities: string[];

  constructor() {}

  ngOnInit(): void {
    this.expectedAuthorities =
      ItlsNavigationComponent.determineExpectedAuthorities(this.item.id);
    this.expectedIconAuthorities =
      ItlsNavigationComponent.determineIconExpectedAuthorities(this.item.id);
  }
}
