<div>
  <div mat-dialog-title class="clearfix text-left">
    <button (click)="cancel()" type="button" class="close" aria-hidden="true">
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5 style="margin-top: -10px">Specifications</h5>
  </div>
  <div mat-dialog-content>
    <div
      class="column-number-container"
      *ngIf="dialogData?.provisionType === ProvisionTypeEnum.TABLE"
    >
      <label>Number of Columns</label>
      <input
        class="form-control"
        type="number"
        [(ngModel)]="columnNum"
        min="2"
      />
      <span class="error-message" [class.v-hidden]="columnNum >= 2">
        Number of columns should be at least 2.
      </span>
    </div>

    <label id="radio-group-label">Append where?</label>
    <mat-radio-group class="radio-group" [(ngModel)]="isTop">
      <mat-radio-button
        class="radio-button"
        *ngFor="
          let radioOption of dialogData?.provisionType ===
          ProvisionTypeEnum.TABLE
            ? tableTypeOptions
            : commonNounTypeOptions
        "
        [value]="radioOption.value"
      >
        {{ radioOption.uiValue }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      (click)="apply()"
      [disabled]="columnNum < 2"
    >
      Apply
    </button>
    <button mat-flat-button (click)="cancel()">Cancel</button>
  </div>
</div>
