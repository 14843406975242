import { Any, JsonObject, JsonProperty } from "json2typescript";
import { CommenterModel } from "@@intelease/web/abstraction-page/src/lib/models/commenter.model";
import { DateTimeConvertService } from "@@intelease/web/common/services";
import { CommentUserMentionModel } from "@@intelease/web/abstraction-page/src/lib/models/comment-user-mention.model";
import { IMentionElement } from "@@intelease/web/utils";

@JsonObject("CommentModel")
export class CommentModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("text", String)
  text = "";

  // only for document comments
  @JsonProperty("page", Number, true)
  page = undefined;

  @JsonProperty("createdBy", CommenterModel)
  createdBy: CommenterModel = undefined;

  @JsonProperty("createdAt", DateTimeConvertService)
  createdAt: Date = undefined;

  @JsonProperty("mentions", [CommentUserMentionModel])
  mentions: CommentUserMentionModel[] = [];

  @JsonProperty("elements", Any, true)
  elements: IMentionElement[] = [];

  constructor(data?: Partial<CommentModel>) {
    Object.assign(this, data);
  }
}
