import { EnvironmentsInterface } from "../app";
import { PackageJson } from "./package";

const pkg: PackageJson = require("../../../../package.json");

export const environment: EnvironmentsInterface = {
  production: true,
  testbed: false,
  hmr: false,
  apiUrlPart: "/api",
  appUrl: "",
  isBrowserEnv: true,
  centrifugoServerUrl: "wss://centrifugo.adex.ai:8000/connection/websocket",
  googleClientId:
    "953945332786-61asq8sf5pb392qdcutdsh0704iiqe9a.apps.googleusercontent.com",
  firebase: {
    apiKey: "AIzaSyA8YIBra6WbvO55ik2L-OX3lm7xuuQAoBw",
    authDomain: "itls-cloud.firebaseapp.com",
    databaseURL: "https://itls-cloud.firebaseio.com",
    projectId: "itls-cloud",
    storageBucket: "itls-cloud.appspot.com",
    messagingSenderId: "67511115823",
    appId: "1:67511115823:web:4028a30b1a655ee4",
  },
  googleDrive: {
    appId: "celtic-bazaar-385017",
    apiKey: "AIzaSyBFRnDAJopBLZMBKqf0_WlRXZUC7qa-0vY",
    clientId:
      "953945332786-imjhg1jmssmqnvi9134gtmidfonjoehv.apps.googleusercontent.com",
  },
  datadog: {
    applicationId: "ac94b2a2-5194-4624-b51b-99e58f978484",
    clientToken: "pubb0ffcb399bd3b6d561859366738bf79b",
    site: "datadoghq.com",
    service: "adex-ui",
    env: "prod",
    version: "prod_" + pkg.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    allowedTracingUrls: ["https://app.adex.ai/api"],
    traceSampleRate: 100,
  },
};
