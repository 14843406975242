import { createFeatureSelector, createSelector } from "@ngrx/store";
import { find } from "lodash";
import { COMMON_FEATURE_KEY, CommonState } from "./common.reducer";

// Lookup the 'Common' feature state managed by NgRx
const getCommonState = createFeatureSelector<CommonState>(COMMON_FEATURE_KEY);

const getLoaded = createSelector(
  getCommonState,
  (state: CommonState) => state.loaded
);
const getError = createSelector(
  getCommonState,
  (state: CommonState) => state.error
);

const getAllCommon = createSelector(
  getCommonState,
  getLoaded,
  (state: CommonState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getCommonState,
  (state: CommonState) => state.selectedId
);
const getSelectedCommon = createSelector(
  getAllCommon,
  getSelectedId,
  (common, id) => {
    const result = common.find((it) => it["id"] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

// const isLoadProvisionsMetadataLoading = createSelector(
//     getCommonState,
//     (state: CommonState) => state.isLoadProvisionsMetadataLoading,
// )

const provisionsMetadataLoaded = createSelector(
  getCommonState,
  (state: CommonState) =>
    !state.isLoadProvisionsMetadataLoading && state.provisionsMetadataLoaded
);

const getProvisionsMetadata = createSelector(
  getCommonState,
  provisionsMetadataLoaded,
  (state: CommonState, isLoaded: boolean) =>
    isLoaded ? state.provisionsMetadata : undefined
);

const isLoadAccountSettingsPending = createSelector(
  getCommonState,
  (state: CommonState) => state.isLoadAccountSettingsPending
);

const isAccountSettingsLoaded = createSelector(
  getCommonState,
  isLoadAccountSettingsPending,
  (state: CommonState, isLoading: boolean) =>
    !isLoading && state.isAccountSettingsLoaded
);

const getAccountSettings = createSelector(
  getCommonState,
  isAccountSettingsLoaded,
  (state: CommonState, isLoaded: boolean) =>
    isLoaded ? state.accountSettings : undefined
);

const getAccountSettingByKey = createSelector(
  getCommonState,
  isAccountSettingsLoaded,
  (state: CommonState, isLoaded: boolean, props) =>
    isLoaded
      ? find(
          state?.accountSettings,
          (accountSettingItem) => accountSettingItem?.key === props?.key
        )
      : undefined
);

const isUpdateAccountSettingsPending = createSelector(
  getCommonState,
  (state: CommonState) => state.isUpdateAccountSettingsPending
);

const isUpdateAccountSettingsSucceeded = createSelector(
  getCommonState,
  isUpdateAccountSettingsPending,
  (state: CommonState, isLoading: boolean) =>
    !isLoading && state.isUpdateAccountSettingsSucceeded
);

const isLoadAccountCustomTagsPending = createSelector(
  getCommonState,
  (state: CommonState) => state.isLoadAccountCustomTagsPending
);

const isAccountCustomTagsLoaded = createSelector(
  getCommonState,
  isLoadAccountCustomTagsPending,
  (state: CommonState, isLoading: boolean) =>
    !isLoading && state.isAccountCustomTagsLoaded
);

const getAccountCustomTags = createSelector(
  getCommonState,
  isAccountCustomTagsLoaded,
  (state: CommonState, isLoaded: boolean) =>
    isLoaded ? state.accountCustomTags : undefined
);

const isProvisionsMetadataLoaded = createSelector(
  getCommonState,
  (state: CommonState) => state.provisionsMetadataLoaded
);

const getProvisionsMetadataByKey = createSelector(
  getCommonState,
  isProvisionsMetadataLoaded,
  (state: CommonState, isProvisionMetadataLoaded, props) =>
    isProvisionMetadataLoaded
      ? state.provisionsMetadata?.[props?.key]
      : undefined
);

export const commonQuery = {
  getLoaded,
  getError,
  getAllCommon,
  getSelectedCommon,

  getProvisionsMetadata,

  isAccountSettingsLoaded,
  getAccountSettings,
  getAccountSettingByKey,
  isUpdateAccountSettingsSucceeded,

  isAccountCustomTagsLoaded,
  getAccountCustomTags,

  isProvisionsMetadataLoaded,
  getProvisionsMetadataByKey,
};
