import { NgModule } from "@angular/core";
import { ItlsCalendarToolbarComponent } from "./itls-calendar-toolbar.component";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgPipesModule } from "ngx-pipes";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { ReactiveFormsModule } from "@angular/forms";

const MATERIAL_MODULES = [
  MatButtonModule,
  MatFormFieldModule,
  MatSelectModule,
  MatIconModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatInputModule,
];

@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    ...MATERIAL_MODULES,
    NgPipesModule,
    ReactiveFormsModule,
  ],
  declarations: [ItlsCalendarToolbarComponent],
  exports: [ItlsCalendarToolbarComponent],
})
export class ItlsCalendarToolbarModule {}
