/* tslint:disable */
/* eslint-disable */
import { FrontendFilterInterfaceModel } from "./frontend-filter-interface-model";
import { ReportAggRequestModel } from "./report-agg-request-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullReportSearchRequestViewModel")
export class FullReportSearchRequestViewModel {
  @JsonProperty("aggregation", ReportAggRequestModel, true)
  aggregation?: ReportAggRequestModel = undefined;

  @JsonProperty("filters", Any, true)
  filters?: any = undefined;

  @JsonProperty("generalColumns", [String], true)
  generalColumns?: Array<string> = [];

  @JsonProperty("provisionColumns", [String], true)
  provisionColumns?: Array<string> = [];

  @JsonProperty("sorts", [String], true)
  sorts?: Array<string> = [];
}
