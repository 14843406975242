/* tslint:disable */
/* eslint-disable */
import { ActorDtoModel } from "./actor-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RemoveSharedEntityRequestModel")
export class RemoveSharedEntityRequestModel {
  @JsonProperty("domains", [ActorDtoModel], true)
  domains?: Array<ActorDtoModel> = [];

  @JsonProperty("removeAll", Boolean, true)
  removeAll?: boolean = undefined;
}
