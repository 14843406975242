import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpEvent } from "@angular/common/http";
import { InteleaseProgressBarService } from "@@intelease/web/intelease/components";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import {
  ListResponseModel,
  PaginationModel,
  ServerResponseModel,
} from "@@intelease/web/intelease/models";
import { HttpContext } from "@@intelease/web/utils";

@Injectable({
  providedIn: "root",
})
export class RestClient {
  public static readonly HTTP_CONTEXT_PARAM_KEY = "httpContext";

  constructor(
    private inteleaseProgressBarService: InteleaseProgressBarService,
    private matSnackBar: MatSnackBar,
    private httpClient: HttpClient
  ) {}

  private static createPostRequestBody(data) {
    return data;
  }

  private static createPutRequestBody(data) {
    return data;
  }

  /**
   * Send a GET request and returns the response body in a given type.
   *
   * @param apiVersion The version of the API
   * @param fullUrl The endpoint URL.
   * @param classRef The class of the returned object
   * @param options The HTTP options to send with the request.
   * @param beforeDeserialization
   *
   * @return An `Observable` of the `HTTPResponse`, with a response body in the requested type.
   */
  public sendGetRequest<T extends Record<string, any>>(
    apiVersion: string,
    fullUrl: string,
    classRef: new () => T,
    options?,
    beforeDeserialization?: (e) => void
  ): Observable<T> {
    this.showProgressBar();
    return this.helpSendGetRequest(
      apiVersion,
      fullUrl,
      classRef,
      options,
      beforeDeserialization
    );
  }

  /**
   *
   * Send a GET request without View
   *
   * @param apiVersion
   * @param fullUrl
   * @param options
   * @param httpContext
   *
   * @return An `Observable` of the `HTTPResponse`,
   */
  sendGetRequestNoView<T>(
    apiVersion: string,
    fullUrl: string,
    options?,
    httpContext?: HttpContext
  ): Observable<HttpEvent<T>> {
    const _options = {
      ...options,
    };
    if (httpContext) {
      _options.params = {
        ..._options.params,
        [RestClient.HTTP_CONTEXT_PARAM_KEY]: httpContext,
      };
    }
    this.showProgressBar();
    return this.httpClient.get<T>(apiVersion + fullUrl, _options).pipe(
      map((res) => {
        this.hideProgressBar();
        return res;
      })
    );
  }

  /**
   * Send a POST request to backend and returns the response body in a given type.
   *
   * @todo confirm a generic type server response same as this `ServerResponseModel` and then use it instead of this `.post<any>`
   * @param apiVersion The version of the API
   * @param fullUrl The endpoint URL.
   * @param data The content to replace with.
   * @param classRef The class of the returned object
   * @param options The HTTP options to send with the request.
   * @param beforeDeserialization
   *
   * @return  An `Observable` of the `HTTPResponse`, with a response body in the requested type.
   */

  public sendPostRequest<T extends Record<string, any>>(
    apiVersion: string,
    fullUrl: string,
    data,
    classRef: new () => T,
    options?,
    beforeDeserialization?: (e) => void
  ): Observable<T> {
    this.showProgressBar();
    const body = RestClient.createPostRequestBody(data);
    return this.helpSendPostRequest(
      body,
      apiVersion,
      fullUrl,
      classRef,
      options,
      beforeDeserialization
    );
  }

  /**
   * Send a POST request to backend without View.
   *
   * @param apiVersion The version of the API
   * @param fullUrl The endpoint URL.
   * @param data The content to replace with.
   * @param options The HTTP options to send with the request.
   *
   * @return  An `Observable` of the `HTTPResponse`.
   */
  public sendPostRequestNoView<T>(
    apiVersion: string,
    fullUrl: string,
    data,
    options?
  ): Observable<HttpEvent<T>> {
    this.showProgressBar();
    const body = RestClient.createPostRequestBody(data);
    return this.httpClient.post<T>(apiVersion + fullUrl, body, options).pipe(
      map((res) => {
        this.hideProgressBar();
        return res;
      })
    );
  }

  /**
   * Send a PUT request to backend and returns the response body in a given type.
   *
   * @todo confirm a generic type server response same as this `ServerResponseModel` and then use it instead of this `.post<any>`
   * @param apiVersion The version of the API
   * @param fullUrl The endpoint URL.
   * @param data The content to replace with.
   * @param classRef The class of the returned object
   * @param options The HTTP options to send with the request.
   * @param beforeDeserialization
   *
   * @return  An `Observable` of the `HTTPResponse`, with a response body in the requested type.
   */
  public sendPutRequest<T extends Record<string, any>>(
    apiVersion: string,
    fullUrl: string,
    data,
    classRef: new () => T,
    options?,
    beforeDeserialization?: (e) => void
  ): Observable<T> {
    this.showProgressBar();
    const body = RestClient.createPutRequestBody(data);
    return this.helpSendPutRequest(
      body,
      apiVersion,
      fullUrl,
      classRef,
      options,
      beforeDeserialization
    );
  }

  /**
   * Send a PUT request to backend without View.
   *
   * @param apiVersion The version of the API
   * @param fullUrl The endpoint URL.
   * @param data The content to replace with.
   * @param options The HTTP options to send with the request.
   *
   * @return  An `Observable` of the `HTTPResponse`.
   */
  public sendPutRequestNoView<T>(
    apiVersion: string,
    fullUrl: string,
    data,
    options?
  ): Observable<HttpEvent<T>> {
    this.showProgressBar();
    const body = RestClient.createPutRequestBody(data);
    return this.httpClient.put<T>(apiVersion + fullUrl, body, options).pipe(
      map((res) => {
        this.hideProgressBar();
        return res;
      })
    );
  }

  /**
   * Send a DELETE request to backend and returns the response body in a given type.
   *
   * @param apiVersion
   * @param fullUrl
   * @param classRef
   * @param options
   * @param beforeDeserialization
   *
   * @return  An `Observable` of the `HTTPResponse`, with a response body in the requested type.
   */
  public sendDeleteRequest<T extends Record<string, any>>(
    apiVersion: string,
    fullUrl: string,
    classRef: new () => T,
    options?,
    beforeDeserialization?: (e) => void
  ): Observable<T> {
    this.showProgressBar();
    return this.helpSendDeleteRequest(
      apiVersion,
      fullUrl,
      classRef,
      options,
      beforeDeserialization
    );
  }

  /**
   * Send a DELETE request to backend without View.
   *
   * @param apiVersion
   * @param fullUrl
   * @param options
   *
   * @return  An `Observable` of the `HTTPResponse`.
   */
  public sendDeleteRequestNoView<T>(
    apiVersion: string,
    fullUrl: string,
    options?
  ): Observable<HttpEvent<T>> {
    this.showProgressBar();
    return this.httpClient.delete<T>(apiVersion + fullUrl, options).pipe(
      map((res) => {
        this.hideProgressBar();
        return res;
      })
    );
  }

  /**
   * Send a GET request to retrieve a listing of all existing entities from backend (as accessible by current user).
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param view the view of the objects to return
   * @param classRef the class of the returned objects
   * @param sort optional criteria by which to sort the returned list
   * @param page optional pagination data for which items to retrieve
   * @return the list of fetched objects
   */
  sendGetListRequest<T extends Record<string, any>>(
    apiVersion: string,
    baseUrl: string,
    classRef: new () => T,
    view?: string,
    sort?: string,
    page?: PaginationModel
  ): Observable<ListResponseModel<T>> {
    let pageSize = 25;
    let params = {};
    //FIXME: (@Mohammad-haji) potential extra work for handling request params
    if (sort && page) {
      pageSize = page.size;
      params = {
        params: {
          view,
          sort,
          perPage: page.size.toString(),
          page: page.page.toString(),
        },
      };
    } else if (sort) {
      params = {
        params: {
          view,
          sort,
        },
      };
    } else if (page) {
      pageSize = page.size;
      params = {
        params: {
          view,
          perPage: page.size.toString(),
          page: page.page.toString(),
        },
      };
    } else {
      params = {
        params: {
          view,
        },
      };
    }
    return this.httpClient
      .get<ServerResponseModel>(apiVersion + baseUrl, params)
      .pipe(
        map((res) =>
          Json2TypescriptHelper.convertToEntities(res.data, classRef, pageSize)
        )
      );
  }

  /**
   * Send a GET request to retrieve a listing of all existing entities from backend (as accessible by current user).
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param view the view of the objects to return
   * @param classRef the class of the returned objects
   * @param sort optional criteria by which to sort the returned list
   * @param page optional pagination data for which items to retrieve
   * @return the list of fetched objects
   */
  sendPostListRequest<T extends Record<string, any>>(
    apiVersion: string,
    baseUrl: string,
    classRef: new () => T,
    body,
    view?: string,
    sort?: string,
    page?: PaginationModel
  ): Observable<ListResponseModel<T>> {
    let pageSize = 25;
    let params = {};
    //FIXME: (@Mohammad-haji) potential extra work for handling request params
    if (sort && page) {
      pageSize = page.size;
      params = {
        params: {
          view,
          sort,
          perPage: page.size.toString(),
          page: page.page.toString(),
        },
      };
    } else if (sort) {
      params = {
        params: {
          view,
          sort,
        },
      };
    } else if (page) {
      pageSize = page.size;
      params = {
        params: {
          view,
          perPage: page.size.toString(),
          page: page.page.toString(),
        },
      };
    } else if (view) {
      params = {
        params: {
          view,
        },
      };
    } else {
      params = {};
    }
    return this.httpClient
      .post<ServerResponseModel>(apiVersion + baseUrl, body, params)
      .pipe(
        map((res) =>
          Json2TypescriptHelper.convertToEntities(res.data, classRef, pageSize)
        )
      );
  }

  public loadJsonFile(path: string): Observable<any> {
    return this.httpClient.get(path);
  }

  private helpSendGetRequest<T extends Record<string, any>>(
    apiVersion: string,
    fullUrl: string,
    classRef: new () => T,
    options?,
    beforeDeserialization?: (e) => void
  ): Observable<T> {
    return this.httpClient.get<any>(apiVersion + fullUrl, options).pipe(
      map((res) => {
        this.hideProgressBar();
        if (beforeDeserialization) {
          return Json2TypescriptHelper.convertToEntity(
            beforeDeserialization(res),
            classRef
          );
        }
        return Json2TypescriptHelper.convertToEntity(res, classRef);
      })
    );
  }

  private helpSendPostRequest<T extends Record<string, any>>(
    body,
    apiVersion: string,
    fullUrl: string,
    classRef: new () => T,
    options?,
    beforeDeserialization?: (e) => void
  ): Observable<T> {
    return this.httpClient.post<any>(apiVersion + fullUrl, body, options).pipe(
      map((res) => {
        this.hideProgressBar();
        if (beforeDeserialization) {
          return Json2TypescriptHelper.convertToEntity(
            beforeDeserialization(res),
            classRef
          );
        }
        return Json2TypescriptHelper.convertToEntity(res, classRef);
      })
    );
  }

  private helpSendPutRequest<T extends Record<string, any>>(
    body,
    apiVersion: string,
    fullUrl: string,
    classRef: new () => T,
    options?,
    beforeDeserialization?: (e) => void
  ): Observable<T> {
    return this.httpClient.put<any>(apiVersion + fullUrl, body, options).pipe(
      map((res) => {
        this.hideProgressBar();
        if (beforeDeserialization) {
          return Json2TypescriptHelper.convertToEntity(
            beforeDeserialization(res),
            classRef
          );
        }
        return Json2TypescriptHelper.convertToEntity(res, classRef);
      })
    );
  }

  private helpSendDeleteRequest<T extends Record<string, any>>(
    apiVersion: string,
    fullUrl: string,
    classRef: new () => T,
    options?,
    beforeDeserialization?: (e) => void
  ): Observable<T> {
    return this.httpClient.delete<any>(apiVersion + fullUrl, options).pipe(
      map((res) => {
        this.hideProgressBar();
        if (beforeDeserialization) {
          return Json2TypescriptHelper.convertToEntity(
            beforeDeserialization(res),
            classRef
          );
        }
        return Json2TypescriptHelper.convertToEntity(res, classRef);
      })
    );
  }

  private showProgressBar() {
    setTimeout(() => {
      this.inteleaseProgressBarService.show();
    }, 0);
  }

  private hideProgressBar() {
    setTimeout(() => {
      this.inteleaseProgressBarService.hide();
    }, 0);
  }
}
