import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import * as queueReducer from "./queue.reducer";
import { QueueEffects } from "./queue.effects";
import { EffectsModule } from "@ngrx/effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      queueReducer.QUEUE_FEATURE_KEY,
      queueReducer.reducer
    ),
    EffectsModule.forFeature([QueueEffects]),
  ],
})
export class AppStateQueueModule {}
