import { Observable } from "rxjs";
import { FetchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FetchProvisionFormService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly PROVISION_FORMS_URL = "/provisionForms";

  constructor(private fetchApiService: FetchApiService) {}

  getFormSchemaByUid<T extends object>(
    uid: string,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    return this.fetchApiService.sendRequest(
      FetchProvisionFormService.API_VERSION_1,
      FetchProvisionFormService.PROVISION_FORMS_URL,
      uid,
      view,
      classRef
    );
  }
}
