import { Injectable } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { FormModalComponent } from "./form-modal.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

export interface TooltipModel {
  emptyField: string;
  notValidField: string;
  firstMessage?: string;
}
@Injectable({
  providedIn: "root",
})
export class FormModalService {
  constructor(private dialog: MatDialog) {}

  openNewFormModal(
    modalData: ModalInputModel,
    options: MatDialogConfig = { minWidth: 700 },
    modalTooltip?: TooltipModel
  ) {
    return this.dialog.open(FormModalComponent, {
      data: { ...modalData, modalTooltip },
      disableClose: false,
      minWidth: options.minWidth,
      autoFocus: false,
    });
  }
}
