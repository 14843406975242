<div fxLayout="column" fxLayoutAlign="start center">
  <div
    class="queues-container"
    [style.maxHeight]="height === 'md' ? '300px' : '155px'"
  >
    <div class="form-group">
      <ng-select
        [items]="availableQueues"
        [multiple]="false"
        [closeOnSelect]="true"
        [clearable]="clearable"
        appendTo="body"
        bindLabel="name"
        placeholder="Select Queue"
        bindValue="uid"
        (change)="onSelectQueue($event)"
        [(ngModel)]="selectedQueue"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <span class="ng-option-label" [id]="item.uid">
            {{ item.name }} ( {{ item.createdBy?.firstName }}
            {{ item.createdBy?.lastName }} )
          </span>
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>
