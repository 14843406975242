/* tslint:disable */
/* eslint-disable */
import { CalculateTermRequestModel } from "./calculate-term-request-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCalculateTermRequestModel")
export class OApiReqCalculateTermRequestModel {
  @JsonProperty("data", CalculateTermRequestModel, true)
  data?: CalculateTermRequestModel = undefined;
}
