import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("AddressProvisionModel")
export class AddressProvisionModel {
  @JsonProperty("streetAddress", String)
  streetAddress = "";

  @JsonProperty("suite", String)
  suite = "";

  @JsonProperty("city", String)
  city = "";

  @JsonProperty("state", String)
  state = "";

  @JsonProperty("country", String)
  country = "";

  @JsonProperty("postalCode", String)
  postalCode = "";
}
