import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APP_FORMS } from "@@intelease/web/intelease/constants";

@Injectable()
export class FormSchemaLoaderService {
  constructor(private httpClient: HttpClient) {}

  getFormSchemaByName(formName: string) {
    return this.httpClient.get(APP_FORMS[formName]);
  }
}
