export enum LocalStorageKey {
  DOC_SET_CATEGORIES = "docSetCategories",
  RECORD_REVIEW_MODE = "recordReviewMode",
  USER_EMAIL = "email",
  USER_FULLNAME = "name",
  USER_HASH = "userHash",
  USER_UID = "userUid",
  ETAG = "etag",
  DOCUMENT_CACHE_EXPIRATION = "documentCacheExpirationDate",
  LEFT_MENU = "left-menu",
  DASHBOARD = "dashboard",
  QUEUE_FEATURE_ENABLED = "queueFeatureEnabled",
  AUTH = "auth",
  ACCOUNT_UID = "accountUid",
  ACCOUNT_ROLE = "accountRole",
  ACCOUNT_ROLE_NAME = "accountRoleName",
  ACCOUNT_ROLE_RANK = "accountRoleRank",
  LBX_ACCOUNT_USER = "lbxAccountUser",
  LBX_ROLES = "lbxRoles",
  FIRST_NAME = "firstName",
  NAME = "name",
  PROFILE_IMAGE_UID = "profileImageUid",
  SETTING_ITEMS = "settingItems",
  GOOGLE_DRIVE_INFO_VIEW = "googleDriveInfoView",
  SERVER_CONFIGS = "serverConfigs",
  GOOGLE_DRIVE_INFO = "googleDriveInfoView",
  DUAL_WINDOW_UID = "dualWindowUid",
  CACHED_DOCUMENTS = "cachedDocuments",
  REDIRECT_URL = "redirectUrl",
  DATABASE = "database",
}
