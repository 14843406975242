import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { FetchApiService, UserInfoService } from "../../../index";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { MultiProvisionStringsAbstractModel } from "@@intelease/web/common/models/doc-abstract/multi-provision-strings-abstract.model";
import { DatePipe } from "@angular/common";
import { EditProvisionValueService } from "@@intelease/web/intelease/services/models/provision-value";
import { RestClient } from "@@intelease/web/utils";
import { AbstractNamesSuggestionModel } from "@@intelease/web/intelease/models/abstract-names-suggestion.model";

@Injectable({
  providedIn: "root",
})
export class FetchAbstractService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(
    private fetchApiService: FetchApiService,
    private datePipe: DatePipe,
    private userInfoService: UserInfoService,
    private editProvisionValueService: EditProvisionValueService,
    private restClient: RestClient
  ) {}

  getAbstractView<T extends object>(
    abstractUid: string,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    return this.fetchApiService.sendRequest(
      FetchAbstractService.API_VERSION_1,
      FetchAbstractService.FINAL_DOC_SETS_URL,
      abstractUid,
      view,
      classRef
    );
  }

  getAbstractDetailForExport(abstractUid: string) {
    return this.getAbstractView(
      abstractUid,
      MultiProvisionStringsAbstractModel.view,
      MultiProvisionStringsAbstractModel
    ).pipe(
      map((res) => {
        res.lastModifiedDate = this.datePipe.transform(res.lastModifiedDate);
        res.provisionGroups.forEach((provisionGroup) => {
          provisionGroup.provisions.forEach((provision) => {
            provision.options = provision.options.filter((mention) => {
              if (mention.selected) {
                mention.docName = this.mapFromDocUidToName(
                  mention.docAbstractUid,
                  res
                );
                if (
                  mention.valueType === PROVISIONS_DATA_CONST.TABLE.name &&
                  mention.value
                ) {
                  mention.model = this.editProvisionValueService
                    .prepareProvisionValueForRecord(
                      PROVISIONS_DATA_CONST.TABLE.name
                    )
                    .prepareProvisionOptionModel(mention);
                  if (mention.model && mention.model.rows) {
                    mention.model.rows = mention.model.rows.map((row) => {
                      return Object.keys(row).map(function (key) {
                        return [row[key]];
                      });
                    });
                  }
                } else {
                  mention.value = mention.value || mention.textValue;
                }
                return mention;
              }
            });
          });
        });
        return res;
      })
    );
  }

  getNameSuggestions(): Observable<AbstractNamesSuggestionModel> {
    return this.restClient.sendGetRequest<AbstractNamesSuggestionModel>(
      FetchAbstractService.API_VERSION_1,
      FetchAbstractService.FINAL_DOC_SETS_URL + "/names",
      AbstractNamesSuggestionModel,
      {},
      (res) => res.data
    );
  }

  /*
   * Below are private helper methods.
   */

  private mapFromDocUidToName(
    docAbstractUid: string,
    model: MultiProvisionStringsAbstractModel
  ): string {
    const docAbstractIdx = model.docAbstractUids.indexOf(docAbstractUid);
    return docAbstractIdx === -1
      ? ""
      : model.docNames[docAbstractIdx].replace(".pdf", "");
  }
}
