import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { itls_ICONS_CONST } from "../constants";

@Injectable({
  providedIn: "root",
})
export class ItlsIconRegistryService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  private registerIcon(name: string, address: string) {
    this.iconRegistry.addSvgIcon(
      name,
      this.sanitizer.bypassSecurityTrustResourceUrl(address)
    );
  }

  setup() {
    itls_ICONS_CONST.forEach((icon) => {
      const { iconName, iconAddress } = icon;
      this.registerIcon(iconName, iconAddress);
    });
  }
}
