/* tslint:disable */
/* eslint-disable */
import { MinimalFinalAbstractViewModel } from "./minimal-final-abstract-view-model";
import { PaginationDataModel } from "./pagination-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiMultiEntityContainerResponseMinimalFinalAbstractViewModel")
export class OApiMultiEntityContainerResponseMinimalFinalAbstractViewModel {
  @JsonProperty("items", [MinimalFinalAbstractViewModel], true)
  items?: Array<MinimalFinalAbstractViewModel> = [];

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
