import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
  SortType,
} from "@swimlane/ngx-datatable";
import { ResizedEvent } from "@@intelease/web/ui/src/lib/itls-angular-resize-event";
import { ContextmenuType } from "@swimlane/ngx-datatable/lib/types/contextmenu.type";
import {
  MainDrawerService,
  ThemeConfigService,
} from "@@intelease/web/intelease/services";
import { MatMenuTrigger } from "@angular/material/menu";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-ui-data-table",
  templateUrl: "./itls-data-table.component.html",
  styleUrls: ["./itls-data-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsDataTableComponent implements OnInit {
  @ViewChild("itlsTable") table: DatatableComponent;
  @ContentChild(TemplateRef, { static: true })
  rowExpandTemplate: TemplateRef<any>;

  @Input() scrollH: boolean = window.innerWidth < 960;
  @Input() loadingIndicator = false;
  @Input() expandable = false;
  @Input() showAddBtn = false;
  @Input() hasMenu: boolean;
  @Input() messages: {
    emptyMessage: string;
    totalMessage: string;
    selectedMessage: string;
  } = {
    emptyMessage: "No data to display",
    totalMessage: "total",
    selectedMessage: "selected",
  };
  @Input() rows = [];
  @Input() columns = [];
  @Input() columnModeSetting: ColumnMode =
    window.innerWidth < 960 ? ColumnMode.flex : ColumnMode.force;
  @Input() pagination = {
    totalResults: 0,
    page: 0,
    size: 50,
  };
  @Input() rowMenu: any[] = [];
  @Input() rowBatchMenu: any[] = [];
  @Input() tableSize: "small" | "big";
  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() addBtnClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() expand: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowMenuItemClick: EventEmitter<{
    menu: any;
    row: any;
  }> = new EventEmitter<{ menu: any; row: any }>();
  reOrderable = true;
  scrollBarHorizontal = window.innerWidth < 960;
  @Input() selectedItems: any[] = [];
  perPageLimits: number[] = [5, 10, 25, 50, 100];
  tableUpdated = false;
  @Input() addBtnTooltip = "Add";
  contextMenuContent;
  contextMenuEvent: MouseEvent;
  contextMenuPos: { x: number; y: number };
  @ViewChild("contextMenuTrigger", { static: false })
  contextMenu: MatMenuTrigger;
  selectionType = SelectionType;
  @Output() selectedItemsChange: EventEmitter<any> = new EventEmitter<any>();
  tableClass: "material" | "dark" = "material";
  isShiftHold: boolean;

  sortType = SortType;

  constructor(
    public mainDrawerService: MainDrawerService,
    private themeConfigService: ThemeConfigService
  ) {
    this.themeConfigService
      .getConfig()
      .pipe(takeUntilDestroyed())
      .subscribe((config) => {
        this.tableClass =
          config.colorTheme === "theme-dark" ? "dark" : "material";
      });
  }

  ngOnInit(): void {
    //
  }

  onRowClick(evt) {
    this.rowClick.emit(evt);
  }

  onAddClick() {
    this.addBtnClick.emit();
  }

  onEditClick() {}

  onDeleteClick() {}

  onToggleColumn(e: MouseEvent, column: any) {
    e.stopPropagation();
    // if (this.columns.filter(item => !item.isHidden).length > 2) {
    column.isHidden = !column.isHidden;
    // }
    // this.columns = [...this.columns]
  }

  changePage(evt) {}

  onSelectRow(evt) {
    this.selectedItemsChange.emit(this.selectedItems);
  }

  /**
   * This is used by the html table.
   */
  getUid(row: any): string {
    return row.uid;
  }

  toggleExpandRow(evt) {
    this.table.rowDetail.toggleExpandRow(evt);
    this.expand.emit(evt);
  }

  onDetailToggle(evt) {}

  onRowMenuClick(menu, row) {
    this.rowMenuItemClick.emit({
      menu,
      row,
    });
  }

  onResized(event: ResizedEvent) {
    if (!this.tableUpdated && !this.loadingIndicator) {
      this.tableUpdated = true;
      setTimeout(() => {
        this.rows = [...this.rows];
        this.table.ngDoCheck();
        this.tableUpdated = false;
      }, 150);
    }
  }

  onTableContextMenu(contextMenuEvent: {
    event: MouseEvent;
    type: ContextmenuType;
    content: any;
  }) {
    this.contextMenu.closeMenu();
    if (contextMenuEvent.type === "body" && this.rowMenu.length) {
      if (!this.isSelectedBefore(contextMenuEvent.content)) {
        this.selectedItems = [contextMenuEvent.content];
      }
      this.contextMenuContent = contextMenuEvent.content;
      this.contextMenuEvent = contextMenuEvent.event;
      this.contextMenuPos = this.getPosition(this.contextMenuEvent);
      if (
        (this.selectedItems.length > 1 && this.rowBatchMenu.length) ||
        (this.selectedItems.length <= 1 && this.rowMenu.length)
      ) {
        setTimeout(() => {
          this.contextMenu.openMenu();
        }, 150);
      }
    } else {
      this.contextMenuContent = undefined;
      this.contextMenuEvent = undefined;
      this.contextMenuPos = undefined;
      this.contextMenu.closeMenu();
      this.selectedItems = [];
    }
    this.selectedItemsChange.emit(this.selectedItems);
    contextMenuEvent.event.preventDefault();
    contextMenuEvent.event.stopPropagation();
  }

  private isSelectedBefore(item): boolean {
    return (
      this.selectedItems.length > 0 &&
      this.selectedItems.find((selectedItem) => selectedItem.uid === item.uid)
    );
  }

  checkShiftHold(event) {
    this.isShiftHold = event.shiftKey;
  }

  getPosition(e: MouseEvent) {
    const leftMenu = document.querySelector("left-menu")?.clientWidth || 0;
    let posx = 0;
    let posy = 0;

    if (e.pageX || e.pageY) {
      posx = e.pageX - leftMenu;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }

    return {
      x: this.mainDrawerService.isOpen ? posx - 270 : posx - 25,
      y: posy - this.mainDrawerService.getTopBarHeightOffset(),
    };
  }

  @HostListener("document:click", ["$event"])
  clickedOutside($event) {
    this.contextMenu?.closeMenu();
    this.contextMenuEvent = undefined;
    this.contextMenuPos = undefined;
    this.contextMenuContent = undefined;
  }
}
