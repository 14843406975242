import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { inteleaseAnimations } from "@@intelease/web/intelease/animations";
import { DateUtil } from "@@intelease/web/utils";
import { ChangeEventInterface } from "@@intelease/web/intelease/components";
import {
  cloneDeep,
  toNumber,
  findIndex,
  values,
  filter,
  keys as _keys,
  find,
  get,
} from "lodash";
import { DatePipe } from "@angular/common";
import { CalculatorService } from "@@intelease/web/abstraction-page/src/lib/services/calculator.service";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import { RelatedDocModel } from "@@intelease/web/common/models";
import { AbstractReviewFacade } from "@@intelease/app-state/abstract-review";
import { finalize } from "rxjs/operators";
import { PriceIndexResponseModel } from "@@intelease/web/abstraction-page/src/lib/models/price-index-response.model";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DateConvertService } from "@common/services";
import { RecordReviewModeEnum } from "@@intelease/app-models/common";
import {
  OApiReqCalculateMentionDtoModel,
  OApiReqRentScheduleInputsV2DtoModel,
  OApiRespRentScheduleInputsModel,
  ProvisionMentionService,
  RentScheduleInputsApiDtoModel,
  RentScheduleService,
} from "@@intelease/api-models/adex-api-model-src";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision/src";
import { SelectMentionConfirmModalService } from "../../select-mention-confirm-modal";
import { CalculatorUtilsService } from "../calculator-utils.service";

enum dateFields {
  COMMENCEMENT_DATE = "COMMENCEMENT_DATE",
  EXPIRATION_DATE = "EXPIRATION_DATE",
}

enum RentFields {
  AnnualPerSquareFoot = "annualPerSquareFoot",
  AnnualRent = "annualRent",
  MonthlyPerSquareFoot = "monthlyPerSquareFoot",
  MonthlyRent = "monthlyRent",
  RentableSquareFoot = "rentableSquareFoot",
}

interface FormDataField {
  customValue?: FormDataField;
  dropdown: { label: string; unit: string; value: string };
  input: number;
  label: string;
  value: string;
}
export interface CalculationMethod {
  title: string;
  type: CalculationMethodType;
}

export enum CalculationMethodType {
  FIXED_RATE,
  PRICE_INDEX_BASED,
}

const CALCULATION_METHODS: CalculationMethod[] = [
  {
    title: "Fixed Rate",
    type: CalculationMethodType.FIXED_RATE,
  },
  {
    title: "CPI - Based",
    type: CalculationMethodType.PRICE_INDEX_BASED,
  },
];

interface PeriodResultInterface {
  years: any;
  months: any;
}

class TableColumn {
  selected: boolean;
  title: string;
  readonly key: string;
  readonly manual: boolean;

  constructor(title: string, key: string, manual: boolean) {
    this.title = title;
    this.selected = false;
    this.key = key;
    this.manual = manual;
  }
}

@Component({
  selector: "il-rent-schedule-calculator-modal",
  templateUrl: "./rent-schedule-calculator-modal.component.html",
  styleUrls: ["./rent-schedule-calculator-modal-component.scss"],
  animations: inteleaseAnimations,
})
export class RentScheduleCalculatorModalComponent implements OnInit {
  @ViewChild("staticTabs", { static: true }) staticTabs: TabsetComponent;
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  imported: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;
  modalTitle = "Save Abstract";
  abstractName = "";
  deselectIncomplete = false;
  formData: any;
  bsInlineValue = new Date();
  bsInlineRangeValue: Date[];
  maxDate = new Date();
  calculatedItems: any[] = [];
  CalculateMethodType = CalculationMethodType;
  calculationMethods = CALCULATION_METHODS;
  priceIndexResponse: PriceIndexResponseModel[];
  selectedCalculationMethod = this.calculationMethods[0];
  isCallingCalculationApi = false;
  isSavingCalculatedMention = false;
  defaultNewSelectionValue = this.getDefaultValueForColumn();
  readonly MAX_MANUAL_TABLE_COLUMNS = 4;
  newTableColumnTitle = "";
  readonly CALCULATED_TABLE__BEGIN_TITLE = "Begin";
  readonly CALCULATED_TABLE__END_TITLE = "End";
  periodItems: any[] = [
    {
      label: "Months",
      value: "months",
    },
    {
      label: "Years",
      value: "years",
    },
  ];

  moneyUnitItems: any[] = [
    {
      label: "EUR",
      unit: "EUR",
      value: "EUR",
    },
    {
      label: "AUD",
      unit: "AUD",
      value: "AUD",
    },
    {
      label: "GBP",
      unit: "GBP",
      value: "GBP",
    },
    {
      label: "JPY",
      unit: "JPY",
      value: "JPY",
    },
    {
      label: "USD",
      unit: "USD",
      value: "USD",
    },
  ];

  tableColumns: TableColumn[] = [
    new TableColumn("Monthly rent", "monthlyRent", false),
    new TableColumn("Annual rent", "annualRent", false),
    new TableColumn("Annual Per Square Foot", "annualPerSquareFoot", false),
    new TableColumn("Monthly Per Square Foot", "monthlyPerSquareFoot", false),
  ];

  selectedColumns = {};
  selectedColumnsCount = 0;

  selectedTab = "ChooseColumns";
  datePickerValue = new Date();
  commencementDate: any[] = [
    {
      label: "test 1",
      value: new Date(),
    },
    {
      label: "test 2",
      value: new Date(new Date().getDay() + 1),
    },
  ];
  expirationDate: any[] = [
    {
      label: "test 1",
      value: new Date(),
    },
    {
      label: "test 2",
      value: new Date(new Date().getDay() + 1),
    },
  ];
  selectedDate: dateFields;
  isTableHidden = false;
  periodResult: PeriodResultInterface;
  provisionFormUid: string;
  abstractUid: string;
  selectedField: string;
  recordReviewMode: RecordReviewModeEnum;

  listItems: any = {
    expiryDate: [],
    commencementDate: [],
    annualPerSquareFoot: [],
    monthlyBaseRent: [],
    annualRent: [],
    monthlyPerSquareFoot: [],
    rentableSqFt: [],
    rentIncreasePercentage: [],
  };
  selectedDocument: RelatedDocModel;
  currentPage: number;

  provisionsMap = {
    commencementDate: {},
    expirationDate: {},
    term: {},
  };
  rentScheduleProvision: FullValMultiPdfProvModel;
  RentFields: typeof RentFields = RentFields;

  constructor(
    public bsModalRef: BsModalRef,
    private datePipe: DatePipe,
    private _notification: MessageService,
    private calculatorService: CalculatorService,
    private rentScheduleService: RentScheduleService,
    private selectMentionConfirmModalService: SelectMentionConfirmModalService,
    public readonly abstractReviewFacade: AbstractReviewFacade,
    private provisionMentionService: ProvisionMentionService,
    private cdr: ChangeDetectorRef,
    private readonly calculatorUtilsService: CalculatorUtilsService
  ) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsInlineRangeValue = [this.bsInlineValue, this.maxDate];
    this.initForm();
  }

  ngOnInit() {
    const {
      abstractUid,
      provisionFormUid,
      selectedDocument,
      currentPage,
      rentScheduleProvision,
      abstractName,
      recordReviewMode,
    } = this.payload;
    this.abstractUid = abstractUid;
    this.abstractName = abstractName;
    this.provisionFormUid = provisionFormUid;
    this.selectedDocument = selectedDocument;
    this.currentPage = currentPage;
    this.rentScheduleProvision = rentScheduleProvision;
    this.recordReviewMode = recordReviewMode;
    this.initPriceIndex();
  }

  initPriceIndex() {
    this.calculatorService.getPriceIndex().subscribe((res) => {
      this.priceIndexResponse = res;
    });
  }

  onSelectFromList(evt, field: string) {
    this.selectedDate = undefined;
    if (evt) {
      const { value } = evt;
      if (value === "New") {
        this.selectedField = field;
      } else {
        this.selectedField = undefined;
        if (this.isRentField(field)) {
          this.adjustOtherRentFields(field as RentFields);
        }
      }
    }
  }

  private get rentableSquareFoot(): number {
    return this.formData.rentableSquareFoot?.value === "New"
      ? this.formData.rentableSquareFoot?.customValue
      : this.formData.rentableSquareFoot?.value;
  }

  private getRentScheduleCalculatorDataV2(
    abstractUid: string,
    provisionformUid: string,
    additionalhtmlnamesList: string[]
  ) {
    const data: RentScheduleInputsApiDtoModel = {
      additionalHtmlNames: additionalhtmlnamesList,
      provisionFormUid: provisionformUid,
      recordUid: abstractUid,
    };
    const body: OApiReqRentScheduleInputsV2DtoModel = {
      data,
    };
    this.rentScheduleService
      .retrieveRentScheduleInputsV2({ body })
      .subscribe((res: OApiRespRentScheduleInputsModel) => {
        const htmlNameToFullVal: any = res.data.htmlNameToFullVal;
        const listItemsMap = {
          expiryDate: [],
          commencementDate: [],
          annualPerSquareFoot: [],
          monthlyBaseRent: [],
          annualRent: [],
          monthlyPerSquareFoot: [],
          rentableSqFt: [],
          rentIncreasePercentage: [],
        };
        for (const key in htmlNameToFullVal) {
          if (
            htmlNameToFullVal[key] &&
            htmlNameToFullVal[key].views &&
            htmlNameToFullVal[key].views.length
          ) {
            const provisionOriginalHtmlName = key.substring(
              0,
              key.indexOf("_")
            );
            htmlNameToFullVal[key].views.forEach((item) => {
              if (
                item.provisionType === "LOCAL_DATE" &&
                key.endsWith("_LOCAL_DATE")
              ) {
                if (
                  item.provisionValue.value &&
                  item.provisionType === "LOCAL_DATE" &&
                  listItemsMap[provisionOriginalHtmlName]
                ) {
                  const ggg = {
                    // label: item.localDateValue.value,
                    // value: item.localDateValue.value
                    label: this.transformDate(
                      DateConvertService.deserializeDate(
                        cloneDeep(item.provisionValue.value)
                      )
                    ),
                    value: this.transformDate(
                      DateConvertService.deserializeDate(
                        cloneDeep(item.provisionValue.value)
                      )
                    ),
                  };
                  listItemsMap[provisionOriginalHtmlName].push(ggg);
                }
                if (key === "expiryDate_LOCAL_DATE") {
                  this.provisionsMap.expirationDate = htmlNameToFullVal[key];
                } else if (key === "commencementDate_LOCAL_DATE") {
                  this.provisionsMap.commencementDate = htmlNameToFullVal[key];
                }
              } else if (
                item.provisionType === "MONEY" &&
                key.endsWith("_MONEY") &&
                listItemsMap[provisionOriginalHtmlName]
              ) {
                const ggg = {
                  label:
                    item.provisionValue.majorValue +
                    " " +
                    item.provisionValue.currency,
                  value: item.provisionValue.majorValue,
                  unit: item.provisionValue.currency,
                };
                listItemsMap[provisionOriginalHtmlName].push(ggg);
              } else if (
                item.provisionType === "DOUBLE" &&
                key.endsWith("_DOUBLE") &&
                listItemsMap[provisionOriginalHtmlName]
              ) {
                const element = {
                  label: item.provisionValue.value,
                  value: item.provisionValue.value,
                };
                listItemsMap[provisionOriginalHtmlName].push(element);
              }
            });
          }
        }
        for (const listItemsMapKey in listItemsMap) {
          if (listItemsMap[listItemsMapKey]) {
            listItemsMap[listItemsMapKey].push(this.getDefaultValueForColumn());
          }
        }
        this.listItems = { ...listItemsMap };
      });
  }

  private getRentScheduleCalculatorData(
    abstractUid: string,
    provisionformUid: string,
    additionalhtmlnamesList: string[]
  ) {
    this.calculatorService
      .getRentScheduleCalculatorData({
        abstractUid,
        provisionformUid,
        additionalhtmlnamesList,
      })
      .subscribe((res: any) => {
        const htmlNameToFullVal: any = res.data.htmlNameToFullVal;
        const listItemsMap = {
          expiryDate: [],
          commencementDate: [],
          annualPerSquareFoot: [],
          monthlyBaseRent: [],
          annualRent: [],
          monthlyPerSquareFoot: [],
        };
        for (const key in res.data.htmlNameToFullVal) {
          if (
            htmlNameToFullVal[key] &&
            htmlNameToFullVal[key].views &&
            htmlNameToFullVal[key].views.length
          ) {
            htmlNameToFullVal[key].views.forEach((item) => {
              if (
                item.provisionType === "LOCAL_DATE" &&
                (key === "expiryDate" || key === "commencementDate")
              ) {
                if (
                  item.provisionValue.value &&
                  item.provisionType === "LOCAL_DATE"
                ) {
                  const ggg = {
                    // label: item.localDateValue.value,
                    // value: item.localDateValue.value
                    label: this.transformDate(
                      DateConvertService.deserializeDate(
                        cloneDeep(item.provisionValue.value)
                      )
                    ),
                    value: this.transformDate(
                      DateConvertService.deserializeDate(
                        cloneDeep(item.provisionValue.value)
                      )
                    ),
                  };
                  listItemsMap[key].push(ggg);
                }
                if (key === "expiryDate") {
                  this.provisionsMap.expirationDate = htmlNameToFullVal[key];
                } else if (key === "commencementDate") {
                  this.provisionsMap.commencementDate = htmlNameToFullVal[key];
                }
              } else if (
                item.provisionType === "MONEY" &&
                (key === "monthlyPerSquareFoot" ||
                  key === "annualPerSquareFoot" ||
                  key === "monthlyBaseRent" ||
                  key === "annualRent")
              ) {
                const ggg = {
                  label:
                    item.provisionValue.majorValue +
                    " " +
                    item.provisionValue.currency,
                  value: item.provisionValue.majorValue,
                  unit: item.provisionValue.currency,
                };
                listItemsMap[key].push(ggg);
              } else {
                console.error(
                  `not expected mention with provisionType: ${item.provisionType} AND htmlName: ${key}!!! (mention: ${item})`
                );
              }
            });
          }
        }
        for (const listItemsMapKey in listItemsMap) {
          if (listItemsMap[listItemsMapKey]) {
            listItemsMap[listItemsMapKey].push(this.getDefaultValueForColumn());
          }
        }
        this.listItems = { ...listItemsMap };
      });
  }

  private transformDate(date) {
    return this.datePipe.transform(date, "MMM-d-y");
  }

  private showTable() {
    this.isTableHidden = false;
  }

  private hideTable() {
    this.isTableHidden = true;
  }

  onNextTab() {
    const currentTabIdx = findIndex(this.staticTabs.tabs, (tab) => tab.active);
    const nextTabIdx = currentTabIdx + 1;
    this.staticTabs.tabs[nextTabIdx].active = true;
    this.selectedTab = this.staticTabs.tabs[nextTabIdx].id;
    this.setSelectedColumns();
    this.hideTable();
  }

  onPreviousTab() {
    const currentTabIdx = findIndex(this.staticTabs.tabs, (tab) => tab.active);
    const previousTabIdx = currentTabIdx - 1;
    this.staticTabs.tabs[previousTabIdx].active = true;
    this.selectedTab = this.staticTabs.tabs[previousTabIdx].id;
    this.setSelectedColumns();
    this.hideTable();
  }

  private setSelectedColumns() {
    this.selectedColumns = {};
    let selectedColumnsCount = 0;
    for (let i = 0; i < this.tableColumns.length; i++) {
      const item = this.tableColumns[i];
      this.selectedColumns[item.key] = item.selected;
      if (item.selected) {
        selectedColumnsCount++;
      }
    }
    this.selectedColumnsCount = selectedColumnsCount;
  }

  onInputDropDownValueChange(evt: ChangeEventInterface, field: string) {
    if (field !== "period") {
      this.formData[field].customValue = evt.model;
    } else {
      this.formData[field] = evt.model;
    }
    this.selectedDate = undefined;

    // If either one of the rent field input value changes, adjust the other rent fields with calculated values
    if (evt.model.input && evt.model.dropdown && this.isRentField(field)) {
      this.adjustOtherRentFields(field as RentFields);
    }
  }

  private adjustOtherRentFields(sourceField: RentFields) {
    if (!this.isRentField(sourceField)) {
      return;
    }
    const otherRents = this.getCalculatedRentData(sourceField);
    const allRentFieldKeys = [
      RentFields.AnnualPerSquareFoot,
      RentFields.AnnualRent,
      RentFields.MonthlyPerSquareFoot,
      RentFields.MonthlyRent,
    ];
    allRentFieldKeys.forEach((rentFieldKey) => {
      //It could be NaN for rentPerSquareFt fields if rentableAreaSquareFt is not provided first, skip those invalid calculations
      if (!isNaN(otherRents[rentFieldKey as RentFields].input)) {
        this.formData[rentFieldKey] = otherRents[rentFieldKey as RentFields];
      }
    });
  }

  getColumnsKey(): string[] {
    return [
      "monthlyPerSquareFoot",
      "annualPerSquareFoot",
      "annualRent",
      "monthlyBaseRent",
    ];
  }

  getColumnsKeyV2(): string[] {
    return [
      "monthlyPerSquareFoot_MONEY",
      "annualPerSquareFoot_MONEY",
      "annualRent_MONEY",
      "monthlyBaseRent_MONEY",
    ];
  }

  onSelectTab(tab) {
    this.selectedTab = tab.id;
    this.setSelectedColumns();
    this.hideTable();
    if (this.selectedTab === "ProvideInputs") {
      switch (this.recordReviewMode) {
        case RecordReviewModeEnum.V1:
          this.getRentScheduleCalculatorData(
            this.abstractUid,
            this.provisionFormUid,
            this.getColumnsKey()
          );
          break;
        case RecordReviewModeEnum.V2:
          this.getRentScheduleCalculatorDataV2(
            this.abstractUid,
            this.provisionFormUid,
            this.getColumnsKeyV2()
          );
          break;
        default:
          throw new Error(
            `Don't support rent schedule calculator for type: ${this.recordReviewMode}!`
          );
      }
    }
  }

  onSelectTableColumn(tableColumn: TableColumn) {
    tableColumn.selected = !tableColumn.selected;
  }

  onDateChange(date, key: string) {
    const value = this.formData[key] || {};
    value["customValue"] = date;
    value["label"] = this.transformDate(date) + " (New)";
    this.formData[key] = { ...value };
    this.formData = { ...this.formData };
  }

  onDateValueChange(evt, field: string, key: string) {
    this.selectedField = undefined;
    const { value } = evt;
    if (value === "New") {
      this.selectedDate = dateFields[field];
      if (field !== "TERM") {
        this.datePickerValue = this.formData[key].customValue || new Date();
        this.formData[key] = {
          value: "New",
          label: this.transformDate(this.datePickerValue) + " (New)",
        };
      } else {
        this.formData[key] = this.getDefaultValueForColumn();
      }
    } else {
      this.formData[key] = { ...{ value: value, label: evt.label } };
      this.selectedDate = undefined;
    }
    this.cdr.detectChanges();
  }

  toggleDatePicker(field: string, key: string) {
    if (key === "commencementDate" || key === "expirationDate") {
      this.selectedDate = dateFields[field];
      this.selectedField = undefined;
      this.datePickerValue = this.formData[key].customValue;
    } else {
      this.selectedDate = undefined;
      this.selectedField = key;
    }
  }

  private isRentField(columnKey: string): boolean {
    return [
      RentFields.AnnualPerSquareFoot,
      RentFields.AnnualRent,
      RentFields.MonthlyPerSquareFoot,
      RentFields.MonthlyRent,
    ].includes(columnKey as RentFields);
  }

  private getSelectedColumnValue(columnKey: string) {
    let form = this.formData;

    if (this.isRentField(columnKey)) {
      form = this.getOtherRentFieldBasedOnInput();
    }

    if (form[columnKey].value === "New") {
      return toNumber(form[columnKey].customValue.input);
    } else {
      return toNumber(form[columnKey].value);
    }
  }

  private addSingleRow(columnIndexToKey: string[], row: any) {
    const obj = {
      begin: this.transformDate(
        DateConvertService.deserializeDate(row.startDate)
      ),
      end: this.transformDate(DateConvertService.deserializeDate(row.endDate)),
    };
    for (let i = 0; i < row.values.length; i++) {
      const value = toNumber(row.values[i]).toFixed(2);
      obj[columnIndexToKey[i]] = value;
    }
    this.calculatedItems.push(obj);
  }

  onCalculateClick() {
    if (this.isValidForCalculation()) {
      this.selectedDate = undefined;
      this.selectedField = undefined;
      this.calculatedItems = [];
      const expirationDate =
        this.formData.expirationDate.value === "New"
          ? this.formData.expirationDate.customValue
          : new Date(cloneDeep(this.formData.expirationDate).value);
      const commencementDate =
        this.formData.commencementDate.value === "New"
          ? this.formData.commencementDate.customValue
          : new Date(cloneDeep(this.formData.commencementDate).value);
      this.periodResult = {
        years: DateUtil.diffDates(expirationDate, commencementDate, "years"),
        months: DateUtil.diffDates(expirationDate, commencementDate, "months"),
      };

      const { columnIndexToKey, columnBaseValues } =
        this.createColumnIndexToKeyAndBaseValues();
      const payload: any = {
        baseValues: columnBaseValues,
        commencementDate: DateUtil.serializeToExactDate(commencementDate),
        expirationDate: DateUtil.serializeToExactDate(expirationDate),
      };
      const { input, dropdown } = this.formData.period;
      if (dropdown.value === "months") {
        payload.escalationPeriodMonths = input;
      } else if (dropdown.value === "years") {
        payload.escalationPeriodYears = input;
      } else {
        console.error(`invalid period dropdown value! (${dropdown})`);
        return;
      }
      if (this.formData.effectivenessRate) {
        payload.effectivenessRate =
          toNumber(this.formData.effectivenessRate) / 100;
      }
      if (
        CalculationMethodType.FIXED_RATE === this.selectedCalculationMethod.type
      ) {
        payload.fixedEscalationRate =
          (toNumber(this.formData.rentIncreasePercentage?.value) ||
            toNumber(this.formData.rentIncreasePercentage?.customValue)) / 100; // input is in percent
      } else if (
        CalculationMethodType.PRICE_INDEX_BASED ===
        this.selectedCalculationMethod.type
      ) {
        payload.priceIndexUid = this.formData.priceIndexUid;
        payload.numPastPointsForFutureEstimate = 2;
      } else {
        console.error(
          `cannot handle calculation method: ${this.selectedCalculationMethod}`
        );
        return;
      }

      this.hideTable();
      this.isCallingCalculationApi = true;
      this.calculatorService
        .calculateRentSchedule(payload)
        .pipe(
          finalize(() => {
            this.showTable();
            this.isCallingCalculationApi = false;
          })
        )
        .subscribe((res) => this.addSingleRow(columnIndexToKey, res));
    }
  }

  getOtherRentFieldBasedOnInput():
    | boolean
    | {
        [RentFields.AnnualPerSquareFoot]: FormDataField;
        [RentFields.AnnualRent]: FormDataField;
        [RentFields.MonthlyPerSquareFoot]: FormDataField;
        [RentFields.MonthlyRent]: FormDataField;
      } {
    if (!this.rentableSquareFoot) {
      return;
    }

    const rentFieldsKey = [
      RentFields.AnnualPerSquareFoot,
      RentFields.AnnualRent,
      RentFields.MonthlyPerSquareFoot,
      RentFields.MonthlyRent,
    ];

    const [calculation, ...otherCalculations] = rentFieldsKey
      .filter((key) => this.formData[key])
      .map((key: RentFields) => this.getCalculatedRentData(key));

    if (otherCalculations.length) {
      if (
        !otherCalculations.every((calc) =>
          rentFieldsKey.every((key) =>
            this.hasSameValueAndCurrency(calc[key], calculation[key])
          )
        )
      ) {
        this._notification.error(`The data entered are contradictory.`);
        return false;
      }
    }

    return calculation;
  }

  hasSameValueAndCurrency(
    firstInput: FormDataField,
    secondInput: FormDataField
  ): boolean {
    const EPS = 1e-6;
    return (
      Math.abs(
        (firstInput.input || toNumber(firstInput.value)) -
          (secondInput.input || toNumber(secondInput.value))
      ) <= EPS &&
      (firstInput.dropdown?.value || ((firstInput as any)?.unit as string)) ===
        (secondInput.dropdown?.value || ((secondInput as any)?.unit as string))
    );
  }

  private getCalculatedRentData(key: RentFields): {
    [RentFields.AnnualPerSquareFoot]: FormDataField;
    [RentFields.AnnualRent]: FormDataField;
    [RentFields.MonthlyPerSquareFoot]: FormDataField;
    [RentFields.MonthlyRent]: FormDataField;
  } {
    switch (key) {
      case RentFields.AnnualPerSquareFoot:
        return {
          annualPerSquareFoot: {
            ...this.formData.annualPerSquareFoot,
          },
          annualRent: this.getFormValue(
            this.formData.annualPerSquareFoot,
            (this.formData.annualPerSquareFoot.input ||
              this.formData.annualPerSquareFoot.value) * this.rentableSquareFoot
          ),
          monthlyPerSquareFoot: this.getFormValue(
            this.formData.annualPerSquareFoot,
            (this.formData.annualPerSquareFoot.input ||
              this.formData.annualPerSquareFoot.value) / 12
          ),
          monthlyRent: this.getFormValue(
            this.formData.annualPerSquareFoot,
            ((this.formData.annualPerSquareFoot.input ||
              this.formData.annualPerSquareFoot.input.value) *
              this.rentableSquareFoot) /
              12
          ),
        };
      case RentFields.AnnualRent:
        return {
          annualPerSquareFoot: this.getFormValue(
            this.formData.annualRent,
            (this.formData.annualRent.input || this.formData.annualRent.value) /
              this.rentableSquareFoot
          ),
          annualRent: {
            ...this.formData.annualRent,
          },
          monthlyPerSquareFoot: this.getFormValue(
            this.formData.annualRent,
            (this.formData.annualRent.input || this.formData.annualRent.value) /
              12 /
              this.rentableSquareFoot
          ),
          monthlyRent: this.getFormValue(
            this.formData.annualRent,
            (this.formData.annualRent.input || this.formData.annualRent.value) /
              12
          ),
        };
      case RentFields.MonthlyPerSquareFoot:
        return {
          annualPerSquareFoot: this.getFormValue(
            this.formData.monthlyPerSquareFoot,
            (this.formData.monthlyPerSquareFoot.input ||
              this.formData.monthlyPerSquareFoot.value) * 12
          ),
          annualRent: this.getFormValue(
            this.formData.monthlyPerSquareFoot,
            (this.formData.monthlyPerSquareFoot.input ||
              this.formData.monthlyPerSquareFoot.value) *
              12 *
              this.rentableSquareFoot
          ),
          monthlyPerSquareFoot: {
            ...this.formData.monthlyPerSquareFoot,
          },
          monthlyRent: this.getFormValue(
            this.formData.monthlyPerSquareFoot,
            (this.formData.monthlyPerSquareFoot.input ||
              this.formData.monthlyPerSquareFoot.value) *
              this.rentableSquareFoot
          ),
        };

      case RentFields.MonthlyRent:
        return {
          annualPerSquareFoot: this.getFormValue(
            this.formData.monthlyRent,
            ((this.formData.monthlyRent.input ||
              this.formData.monthlyRent.value) *
              12) /
              this.rentableSquareFoot
          ),
          annualRent: this.getFormValue(
            this.formData.monthlyRent,
            (this.formData.monthlyRent.input ||
              this.formData.monthlyRent.value) * 12
          ),
          monthlyPerSquareFoot: this.getFormValue(
            this.formData.monthlyRent,
            (this.formData.monthlyRent.input ||
              this.formData.monthlyRent.value) / this.rentableSquareFoot
          ),
          monthlyRent: {
            ...this.formData.monthlyRent,
          },
        };
      default:
        break;
    }
  }
  private getFormValue(base: FormDataField, input: number): FormDataField {
    const value = {
      dropdown: { ...base.dropdown },
      input: toNumber(input),
      label: "New",
      value: "New",
    };

    // When the source/base input was selected from pre-existing mention value
    if (!value.dropdown?.unit && value.input) {
      value.dropdown = {
        label: (base as any)?.unit,
        unit: (base as any)?.unit,
        value: (base as any)?.unit,
      };
    }

    return {
      ...value,
      customValue: value,
    };
  }

  private hasValue(key: string): boolean {
    return (
      !this.formData[key] ||
      !this.formData[key].value ||
      (this.formData[key].value === "New" && !this.formData[key].customValue)
    );
  }

  private rentFieldsHaveValue(): boolean {
    return [
      RentFields.AnnualPerSquareFoot,
      RentFields.AnnualRent,
      RentFields.MonthlyPerSquareFoot,
      RentFields.MonthlyRent,
    ].some((key) => this.hasValue(key));
  }

  isValidForCalculation() {
    if (!this.getOtherRentFieldBasedOnInput()) {
      return false;
    }

    let isValid = false;
    let formItemWithValue = 0;
    const columnTitlesMissingValue: string[] = [];
    let isAtleastOneTableColumnSelected = false;
    _keys(this.selectedColumns).forEach((key) => {
      isAtleastOneTableColumnSelected =
        isAtleastOneTableColumnSelected || this.selectedColumns[key];
      if (this.selectedColumns[key]) {
        if (this.hasValue(key)) {
          if (!(this.isRentField(key) && this.rentFieldsHaveValue())) {
            columnTitlesMissingValue.push(
              find(this.tableColumns, (item) => item.key === key).title
            );
          }
        }
      }
    });
    if (!isAtleastOneTableColumnSelected) {
      this._notification.error(
        `Please select at least 1 column from 'Choose Columns' tab`
      );
      return false;
    }
    if (columnTitlesMissingValue.length > 0) {
      const titlesWithDoubleQuotes = columnTitlesMissingValue.map(
        (title) => `"${title}"`
      );
      this._notification.error(
        `Please set value for columns: ${titlesWithDoubleQuotes.join(", ")}`
      );
      return false;
    }

    if (
      this.formData.expirationDate &&
      this.formData.expirationDate.value &&
      this.formData.commencementDate.value &&
      this.formData.commencementDate
    ) {
      const expirationDate =
        this.formData.expirationDate.value === "New"
          ? this.formData.expirationDate.customValue
          : new Date(cloneDeep(this.formData.expirationDate).value);
      const commencementDate =
        this.formData.commencementDate.value === "New"
          ? this.formData.commencementDate.customValue
          : new Date(cloneDeep(this.formData.commencementDate).value);
      isValid =
        DateUtil.diffDates(expirationDate, commencementDate, "days") >= 1;
      if (
        this.formData.period.input &&
        this.formData.period.dropdown &&
        this.formData.period.dropdown.value
      ) {
        formItemWithValue++;
      }

      if (
        this.formData.rentIncreasePercentage &&
        this.selectedCalculationMethod.type === CalculationMethodType.FIXED_RATE
      ) {
        formItemWithValue++;
      }

      if (
        this.formData.priceIndexUid &&
        this.selectedCalculationMethod.type ===
          CalculationMethodType.PRICE_INDEX_BASED
      ) {
        formItemWithValue++;
      }

      if (!this.rentableSquareFoot) {
        formItemWithValue = 1;
      }

      if (!isValid) {
        this._notification.error(
          "Expiration Date should be after Commencement Date !"
        );
      } else if (formItemWithValue < 2) {
        this._notification.error("Please fill all fields");
      }
      return isValid && formItemWithValue === 2;
    } else {
      this._notification.error("Please fill all fields");
    }
  }

  importCalculatedProvision(
    {
      mentionUid,
      preMentionUid,
      postMentionUid,
    }: {
      mentionUid?: string;
      preMentionUid?: string;
      postMentionUid?: string;
    },
    selectedMentionIndex?: number,
    mentionName?: string
  ) {
    this.setSelectedColumns();
    // since commencementDate and expirationDate are not included in 'tableColumn', we add them manually
    const value = {
      headers: [
        this.CALCULATED_TABLE__BEGIN_TITLE,
        this.CALCULATED_TABLE__END_TITLE,
      ],
      types: ["COMMON_NOUN", "COMMON_NOUN"],
      rows: [],
    };
    for (const tableColumn of this.tableColumns) {
      if (tableColumn.selected) {
        value.headers.push(this.getTitleForCalculatedTableColumn(tableColumn));
        value.types.push("COMMON_NOUN");
      }
    }

    this.calculatedItems.forEach((item) =>
      value.rows.push(filter(values(item)))
    );

    const body: OApiReqCalculateMentionDtoModel = {
      data: {
        docAbstractUid: this.selectedDocument.uid,
        page: this.currentPage,
        provisionUid: this.rentScheduleProvision.provisionInfo.uid,
        provisionValue: value,
        mentionName,
        mentionUid,
        postMentionUid,
        preMentionUid,
        format: "TABLE",
      },
    };
    this.isSavingCalculatedMention = true;
    this.provisionMentionService
      .calculateCreateProvisionValueV2({
        recordUid: this.abstractUid,
        body,
      })
      .pipe(
        finalize(() => {
          this.isSavingCalculatedMention = false;
        })
      )
      .subscribe(
        (res) => {
          if (!res.data) {
            this._notification.error(
              res.message ||
                "An unknown error occurred while saving the options"
            );
            return;
          }
          this.calculatorUtilsService.updateViewAfterChangeInProvisions(
            res.data.multiPdfProv,
            this.rentScheduleProvision as any,
            res.data.modifiedProvisionMentionUids?.newlyCreated?.[0],
            selectedMentionIndex
          );
          this.hideTable();
          this.onDismissModal();
        },
        () => {
          this._notification.error(
            "An unknown error occurred while saving the options"
          );
        }
      );
  }

  onImportClick() {
    if (!this.isSavingCalculatedMention && this.calculatedItems.length > 0) {
      this.selectMentionConfirmModalService
        .openModalAndAskRelevantMentionUidToFillIn(this.rentScheduleProvision)
        .subscribe((res) => {
          if (!res.isCanceled) {
            this.importCalculatedProvision(
              res.relevantMentions,
              res.selectedMentionIndex,
              res?.mentionName
            );
          }
        });
    }
  }

  onSelectCalculationMethod(selectedCalculationMethod: CalculationMethod) {
    this.selectedCalculationMethod = selectedCalculationMethod;
  }

  getTitleOfSelectedField() {
    if (!this.selectedField) {
      return "";
    }

    if (this.selectedField === "rentableSquareFoot") {
      return "Rentable Square Feet";
    }

    if (this.selectedField === "rentIncreasePercentage") {
      return "Rent Escalation";
    }

    return find(this.tableColumns, (item) => item.key === this.selectedField)
      .title;
  }

  onResetClick() {
    this.initForm();
    this.resetValuesForManualColumns();
    this.selectedDate = undefined;
    this.hideTable();
    this.calculatedItems = [];
  }

  public clearField(fieldKey: RentFields): void {
    this.formData[fieldKey] = undefined;
  }

  initForm() {
    this.formData = {
      commencementDate: undefined,
      rentIncreasePercentage: "",
      expirationDate: undefined,
      period: {
        dropdown: undefined,
        input: undefined,
      },
      effectivenessRate: 100,
      monthlyPerSquareFoot: undefined,
      monthlyRent: undefined,
      annualPerSquareFoot: undefined,
      annualRent: undefined,
      rentableSquareFoot: undefined,
    };
  }

  addCustomColumn(title: string, checked?: boolean) {
    const manualTableColumnIndex = this.getManualTableColumns().length + 1;
    const tableColumn = new TableColumn(
      title,
      `customColumn${manualTableColumnIndex}`,
      true
    );
    tableColumn.selected = checked;
    this.tableColumns.push(tableColumn);
    this.formData[tableColumn.key] = this.getDefaultValueForColumn();
  }

  onDismissModal() {
    this.bsModalRef.hide();
    this.onDismiss.next(this.modalRes);
  }

  private createColumnIndexToKeyAndBaseValues() {
    const columnIndexToKey: string[] = [];
    const columnBaseValues = [];
    const columnKeys = _keys(this.selectedColumns);
    for (const columnKey of columnKeys) {
      if (this.selectedColumns[columnKey]) {
        columnIndexToKey.push(columnKey);
        columnBaseValues.push(this.getSelectedColumnValue(columnKey));
      }
    }
    return { columnIndexToKey, columnBaseValues };
  }

  private resetValuesForManualColumns() {
    for (const tableColumn of this.tableColumns) {
      if (tableColumn.manual) {
        this.formData[tableColumn.key] = this.getDefaultValueForColumn();
      }
    }
  }

  private getDefaultValueForColumn() {
    return {
      label: "New",
      value: "New",
    };
  }

  onTitleChangedForTableColumn(evt, tableColumn: TableColumn) {
    if (tableColumn.manual && tableColumn.selected && !evt.target.value) {
      tableColumn.selected = false;
    }
  }

  getTitleForCalculatedTableColumn(tableColumn: TableColumn) {
    const formDataValue = this.formData[tableColumn.key];
    if (formDataValue) {
      const metadata =
        get(formDataValue, "dropdown.value") ||
        formDataValue.unit ||
        get(formDataValue, "customValue.dropdown.value");
      if (metadata) {
        return `${tableColumn.title} (${metadata})`;
      }
    }
    return tableColumn.title;
  }

  getAnnualRentCurrency(): string {
    const formDataValue = this.formData["annualRent"];

    if (!formDataValue) {
      return "";
    }

    return `(${
      get(formDataValue, "dropdown.value") ||
      formDataValue.unit ||
      get(formDataValue, "customValue.dropdown.value")
    })`;
  }

  getManualTableColumns(): TableColumn[] {
    return this.tableColumns.filter((tableColumn) => tableColumn.manual);
  }

  addNewManualTableColumn() {
    if (this.newTableColumnTitle) {
      this.addCustomColumn(this.newTableColumnTitle, true);
      this.newTableColumnTitle = "";
    }
  }
}
