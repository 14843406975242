import {
  AfterViewInit,
  DestroyRef,
  Directive,
  inject,
  OnDestroy,
} from "@angular/core";
import { FormControl } from "@angular/forms";

import { ArrayProperty } from "./model/arrayproperty";
import { FormProperty } from "./model/formproperty";
import { ObjectProperty } from "./model/objectproperty";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export abstract class Widget<T extends FormProperty> {
  formProperty: T;
  control: FormControl;
  updateOn: "change" | "blur" | "submit";
  errorMessages: string[];

  id = "";
  name = "";
  schema: any = {};
  close() {}
}

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class ControlWidget
  extends Widget<FormProperty>
  implements AfterViewInit
{
  destroyRef = inject(DestroyRef);

  ngAfterViewInit() {
    const control = this.control;
    this.formProperty.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newValue) => {
        this.onFormPropertyValueChange(control, newValue);
      });
    this.formProperty.errorsChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((errors) => {
        control.setErrors(errors, { emitEvent: true });
        const messages = (errors || [])
          .filter((e) => {
            return e.path && e.path.slice(1) === this.formProperty.path;
          })
          .map((e) => e.message);
        this.errorMessages = messages.filter(
          (m, i) => messages.indexOf(m) === i
        );
      });
    control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newValue) => {
        this.onControlValueChange(FormProperty, newValue);
      });
  }

  onFormPropertyValueChange(control: FormControl, newValue) {
    if (control.value !== newValue) {
      control.setValue(newValue, { emitEvent: false });
    }
  }

  onControlValueChange(formProperty, newValue) {
    this.formProperty.setValue(newValue, false);
  }
}

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class ArrayLayoutWidget
  extends Widget<ArrayProperty>
  implements AfterViewInit
{
  destroyRef = inject(DestroyRef);

  ngAfterViewInit() {
    const control = this.control;
    this.formProperty.errorsChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((errors) => {
        control.setErrors(errors, { emitEvent: true });
      });
  }
}

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class ObjectLayoutWidget
  extends Widget<ObjectProperty>
  implements AfterViewInit
{
  destroyRef = inject(DestroyRef);

  ngAfterViewInit() {
    const control = this.control;

    this.formProperty.errorsChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((errors) => {
        control.setErrors(errors, { emitEvent: true });
      });
  }
}
