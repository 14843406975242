/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RecordOwnerDtoModel")
export class RecordOwnerDtoModel {
  @JsonProperty("email", String, true)
  email?: string = "";

  @JsonProperty("hasProfileImage", Boolean, true)
  hasProfileImage?: boolean = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
