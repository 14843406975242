import { Pipe, PipeTransform } from "@angular/core";
import { APP_ENUMS_CONST } from "@@intelease/web/intelease/constants";
import {
  InteleaseCommonService,
  ServerConfigsService,
} from "@@intelease/app-services/common";

@Pipe({ name: "enums" })
export class EnumsPipe implements PipeTransform {
  public static readonly DOC_SET_CATEGORY = "DocSetCategory";

  constructor(private serverConfigsService: ServerConfigsService) {}

  transform(input: string, param: string, dynamic = false): string {
    return dynamic
      ? this.getDynamicValue(input, param)
      : APP_ENUMS_CONST[param][input] || input;
  }

  private getDynamicValue(input: string, param: string) {
    switch (param) {
      case EnumsPipe.DOC_SET_CATEGORY:
        return this.serverConfigsService.getDocSetCategoryView(input)?.uiName;
    }
    throw new Error(
      'unrecognized dynamic type: "' + param + '" (input: ' + input + ")"
    );
  }
}
