import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from "@angular/core";
import { NavigateService } from "@@intelease/web/common/services/navigate.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "itls-common-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
  private activeUrl = "";
  destroyRef = inject(DestroyRef);

  constructor(
    private navigate: NavigateService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.navigate.setActiveTab();
    this.activeUrl = this.navigate.activeUrl;
    this.navigate
      .getActiveUrlChange()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.activeUrl = value;
        this.ref.detectChanges();
      });
  }

  onMenuItemClick(url: string) {
    this.navigate.setActiveTabUrl(url);
  }

  /**
   * @FIXME: remove this active methods and make them general ...
   */
  isDashboardActive(): boolean {
    return this.activeUrl === "dashboards";
  }

  isAbstractsActive(): boolean {
    return (
      this.activeUrl === "my-documents" ||
      this.activeUrl === "individual-abstract"
    );
  }

  isFormsActive(): boolean {
    return (
      this.activeUrl === "provision-forms" ||
      this.activeUrl === "individual-form"
    );
  }

  isTasksActive() {
    return this.activeUrl === "tasks";
  }

  isReportsActive() {
    return this.activeUrl === "reports";
  }

  isCalendarActive() {
    return this.activeUrl === "calendar";
  }
}
