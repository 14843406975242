/* tslint:disable */
/* eslint-disable */
import { GoogleDriveInfoViewModel } from "./google-drive-info-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("HeaderUserViewModel")
export class HeaderUserViewModel {
  @JsonProperty("accountRole", String, true)
  accountRole?: string = "";

  @JsonProperty("accountRoleName", String, true)
  accountRoleName?: string = "";

  @JsonProperty("accountRoleRank", Number, true)
  accountRoleRank?: number = undefined;

  @JsonProperty("accountUid", String, true)
  accountUid?: string = "";

  @JsonProperty("email", String, true)
  email?: string = "";

  @JsonProperty("firstName", String, true)
  firstName?: string = "";

  @JsonProperty("flags", [String], true)
  flags?: Array<string> = [];

  @JsonProperty("googleDriveInfoView", GoogleDriveInfoViewModel, true)
  googleDriveInfoView?: GoogleDriveInfoViewModel = undefined;

  @JsonProperty("lbxAccountUser", Boolean, true)
  lbxAccountUser?: boolean = undefined;

  @JsonProperty("lbxRoles", Any, true)
  lbxRoles?: Array<
    "ADMIN" | "FORMS_ADMIN" | "ANALYST" | "REVIEWER" | "DATA_SCIENTIST"
  > = [];

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("profileImageFileRegistered", Boolean, true)
  profileImageFileRegistered?: boolean = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
