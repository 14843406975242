/* tslint:disable */
/* eslint-disable */
import { CommentViewModel } from "./comment-view-model";
import { FullNameUserViewModel } from "./full-name-user-view-model";
import { MinimalDocAbstractViewModel } from "./minimal-doc-abstract-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullIssueViewModel")
export class FullIssueViewModel {
  @JsonProperty("assignees", [FullNameUserViewModel], true)
  assignees?: Array<FullNameUserViewModel> = [];

  @JsonProperty("attachments", [MinimalDocAbstractViewModel], true)
  attachments?: Array<MinimalDocAbstractViewModel> = [];

  @JsonProperty("comments", [CommentViewModel], true)
  comments?: Array<CommentViewModel> = [];

  @JsonProperty("finalAbstractUid", String, true)
  finalAbstractUid?: string = "";

  @JsonProperty("title", String, true)
  title?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
