import { JsonConvert, OperationMode, ValueCheckingMode } from "json2typescript";
import { PaginationModel } from "@@intelease/web/intelease/models";
import { ListResponseModel } from "@@intelease/web/intelease/models/list-response.model";
import { AbstractKeyValue } from "@@intelease/web/common/models";

export class Json2TypescriptHelper {
  constructor() {}

  static convertToEntity<T extends object>(
    data: any,
    entityType: new () => T
  ): T {
    try {
      const jsonConvert: JsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
      jsonConvert.operationMode = OperationMode.ENABLE;
      return jsonConvert.deserialize(data, entityType) as T;
    } catch (error) {
      console.error("Deserialization error: ", error);
      return data;
    }
  }

  static convertToEntities<T extends object>(
    data: ListResponseModel<any>,
    entityType: new () => T,
    pageSize: number = 25
  ): ListResponseModel<T> {
    const entities: any[] = [];
    const pagination: PaginationModel = new PaginationModel(
      data.pagination.page,
      pageSize,
      data.pagination.totalResults
    );
    const jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    data.items.map((v) =>
      entities.push(jsonConvert.deserialize(v, entityType))
    );
    return new ListResponseModel<T>(pagination, entities);
  }

  static convertToAbstract(
    data: ListResponseModel<any>,
    viewCols: string[],
    pageSize: number = 25
  ): ListResponseModel<AbstractKeyValue> {
    const abstract = data.items.map(
      (item) => new AbstractKeyValue(item, viewCols)
    );
    const pagination = new PaginationModel(
      data.pagination.page,
      pageSize,
      data.pagination.totalResults
    );
    return new ListResponseModel<AbstractKeyValue>(pagination, abstract);
  }
}
