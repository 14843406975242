/* tslint:disable */
/* eslint-disable */

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocPairDiffResultModel")
export class DocPairDiffResultModel {
  @JsonProperty("sourceDocAbstractUid", String, true)
  sourceDocAbstractUid?: string = "";

  @JsonProperty("sourceDocSetAbstractUid", String, true)
  sourceDocSetAbstractUid?: string = "";

  @JsonProperty("targetDocAbstractUid", String, true)
  targetDocAbstractUid?: string = "";

  @JsonProperty("targetDocSetAbstractUid", String, true)
  targetDocSetAbstractUid?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
