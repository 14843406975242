import { JsonProperty } from "json2typescript";

export class AccountRoleTypeModel {
  @JsonProperty("key", String)
  key: "ITLS_ADMIN" | "ADMIN" | "OWNER" | "USER" | undefined = undefined;

  @JsonProperty("label", String)
  label = "";

  @JsonProperty("rank", Number)
  rank = -1;
}
