/* tslint:disable */
/* eslint-disable */
import { GoogleDriveChangeAutoSyncDtoModel } from "./google-drive-change-auto-sync-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqGoogleDriveChangeAutoSyncDtoModel")
export class OApiReqGoogleDriveChangeAutoSyncDtoModel {
  @JsonProperty("data", GoogleDriveChangeAutoSyncDtoModel, true)
  data?: GoogleDriveChangeAutoSyncDtoModel = undefined;
}
