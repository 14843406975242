/* tslint:disable */
/* eslint-disable */
import { ObjectIdModel } from "./object-id-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("WeightedIdModel")
export class WeightedIdModel {
  @JsonProperty("id", ObjectIdModel, true)
  id?: ObjectIdModel = undefined;

  @JsonProperty("weight", Number, true)
  weight?: number = undefined;
}
