/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("QueueSummaryDtoModel")
export class QueueSummaryDtoModel {
  @JsonProperty("completedCount", Number, true)
  completedCount?: number = undefined;

  @JsonProperty("failedCount", Number, true)
  failedCount?: number = undefined;

  @JsonProperty("inQaReviewCount", Number, true)
  inQaReviewCount?: number = undefined;

  @JsonProperty("inReviewCount", Number, true)
  inReviewCount?: number = undefined;

  @JsonProperty("processingCount", Number, true)
  processingCount?: number = undefined;

  @JsonProperty("readyCount", Number, true)
  readyCount?: number = undefined;

  @JsonProperty("totalCount", Number, true)
  totalCount?: number = undefined;
}
