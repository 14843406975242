/* tslint:disable */
/* eslint-disable */

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("GoogleDriveFolderSyncResDtoModel")
export class GoogleDriveFolderSyncResDtoModel {
  @JsonProperty("autoSyncEnabled", Boolean, true)
  autoSyncEnabled?: boolean = undefined;

  @JsonProperty("id", String, true)
  id?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("url", String, true)
  url?: string = "";
}
