import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  ABSTRACTREVIEW_FEATURE_KEY,
  AbstractReviewState,
} from "./abstract-review.reducer";
import { values, cloneDeep } from "lodash";
import {
  DocPdfViewModel,
  FullValMultiPdfProvViewModel,
  PartialValPdfProvisionViewModel,
} from "@@intelease/api-models/adex-api-model-src";
import { ProvisionReviewStatusEnum } from "@@intelease/app-models/common/src";
import { ProvisionFormStructureCategoriesModel } from "./models/abstract-review.model";
import { RemindersTypeEnum } from "@@intelease/web/abstraction-page/src";

// Lookup the 'AbstractReview' feature state managed by NgRx
const getAbstractReviewState = createFeatureSelector<AbstractReviewState>(
  ABSTRACTREVIEW_FEATURE_KEY
);

const getLoaded = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.loaded
);

const getAllAbstractReview = createSelector(
  getAbstractReviewState,
  getLoaded,
  (state: AbstractReviewState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.selectedId
);

const getSelectedAbstractReview = createSelector(
  getAllAbstractReview,
  getSelectedId,
  (abstractReview, id) => {
    const result = abstractReview.find((it) => it["id"] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

// abstract-review
const getSelectedAbstractUid = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.selectedAbstractUid
);

const selectedAbstractDetailLoaded = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.selectedAbstractDetailLoaded
);

const getSelectedAbstractDetailLoading = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.isSelectedAbstractDetailLoading
);

const getSelectedAbstractDetail = createSelector(
  getAbstractReviewState,
  selectedAbstractDetailLoaded,
  (state: AbstractReviewState, isLoaded) =>
    isLoaded ? state.abstractDetail : undefined
);

const getSelectedAbstractForm = createSelector(
  getAbstractReviewState,
  selectedAbstractDetailLoaded,
  (state: AbstractReviewState, isLoaded) =>
    isLoaded ? state.formStructure : undefined
);

const getSelectedAbstractProvisions = createSelector(
  getAbstractReviewState,
  selectedAbstractDetailLoaded,
  (state: AbstractReviewState, isLoaded) =>
    isLoaded ? state.provisions : undefined
);

const getProvisions = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.provisions
);

const getNestedProvisionInconsistencies = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.provisionInconsistencies
);

const getAnchorProvisionUid = createSelector(
  getNestedProvisionInconsistencies,
  (inconsistencies) => inconsistencies.anchorProvisionUid
);

const getLastTouchedMentionUid = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.lastTouchedMentionUid
);

const getLastTouchedNestedSubfieldKey = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.lastNestedSubfieldTouched
);

const getMentionApprovalCompletion = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.mentionApprovalCompletion
);

const getAbstractFullProvisionGroups = createSelector(
  getAbstractReviewState,
  selectedAbstractDetailLoaded,
  (state: AbstractReviewState, isLoaded) => {
    if (isLoaded) {
      const { formStructure, provisions } = state;
      if (formStructure) {
        const abstractFormDetail = cloneDeep(formStructure);
        abstractFormDetail["provisionGroupList"] = [];
        values(formStructure.categories).forEach((category) => {
          let item: ProvisionFormStructureCategoriesModel = {
            provisions: [],
          };
          item = { ...item, expanded: true, ...cloneDeep(category) };
          category.provisionInfoUids.forEach((uid) => {
            item.provisions.push(cloneDeep(provisions[uid]));
          });
          abstractFormDetail["provisionGroupList"].push(item);
        });
        delete abstractFormDetail.categories;
        return abstractFormDetail;
      }
    }
    return undefined;
  }
);

const getProvisionsWhichNeedReview = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => {
    return Object.values(state.provisions || {}).filter(isInNeedReviewSection);
  }
);

const getProvisionCategoriesWithProvisions = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => {
    const { formStructure, provisions } = state;

    const categories = Object.values(formStructure?.categories || {});

    return categories
      .map((category) => ({
        ...category,
        provisions: category.provisionInfoUids
          .map((uid) => ({
            ...provisions[uid],
          }))
          .filter((provision) => !isInNeedReviewSection(provision)),
      }))
      .filter((category) => category.provisions.length);
  }
);

const getCommentNodes = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.commentNodes
);

const getCommentNodeLoaded = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.commentNodesLoaded
);

const getDocuments = createSelector(
  getAbstractReviewState,
  selectedAbstractDetailLoaded,
  (state: AbstractReviewState, isLoaded) =>
    isLoaded ? state.abstractDetail.documents : []
);

function getNotLocalMentions(
  provision: FullValMultiPdfProvViewModel
): PartialValPdfProvisionViewModel[] {
  return provision.multiplePdfProvision.options.filter(
    (option) => option?.source !== "TEMP_LOCAL"
  );
}

function hasNotLocalMention(provision: FullValMultiPdfProvViewModel): boolean {
  if (!provision?.multiplePdfProvision?.options?.length) {
    return false;
  }

  return !!getNotLocalMentions(provision).length;
}

function isInNeedReviewSection(
  provision: FullValMultiPdfProvViewModel
): boolean {
  if (hasNotLocalMention(provision)) {
    return (
      [ProvisionReviewStatusEnum.NEED_REVIEW_TOUCHED].includes(
        provision.reviewStatus as ProvisionReviewStatusEnum
      ) ||
      getNotLocalMentions(provision).every((option) =>
        [
          ProvisionReviewStatusEnum.NEED_REVIEW,
          ProvisionReviewStatusEnum.NEED_REVIEW_TOUCHED,
        ].includes(option.reviewStatus as ProvisionReviewStatusEnum)
      )
    );
  }

  return [
    ProvisionReviewStatusEnum.NEED_REVIEW,
    ProvisionReviewStatusEnum.NEED_REVIEW_TOUCHED,
  ].includes(provision.reviewStatus as ProvisionReviewStatusEnum);
}

const getMultiplePdfProvisionsList = createSelector(
  getAbstractReviewState,
  selectedAbstractDetailLoaded,
  (state: AbstractReviewState, isLoaded) => {
    if (!isLoaded) return undefined;
    const { formStructure, provisions } = state;
    if (formStructure)
      return values(formStructure.categories).map((category) => {
        return {
          provisions: category.provisionInfoUids.map((uid) =>
            cloneDeep(provisions[uid].multiplePdfProvision)
          ),
          expanded: true,
          isOpen: true,
          ...cloneDeep(category),
        };
      });
  }
);

const getRecordDetails = createSelector(
  getAbstractReviewState,
  selectedAbstractDetailLoaded,
  (state: AbstractReviewState, isLoaded) => {
    if (!isLoaded) return undefined;
    return state.abstractDetail;
  }
);

const getSelectedAbstractProvisionByProvisionUid = createSelector(
  getSelectedAbstractProvisions,
  (provisions, props) => (provisions ? provisions[props.uid] : undefined)
);

const getProvisionGroupByProvisionUid = createSelector(
  getAbstractReviewState,
  selectedAbstractDetailLoaded,
  (state: AbstractReviewState, isLoaded, props) => {
    if (isLoaded) {
      const { formStructure } = state;
      if (formStructure) {
        return values(formStructure.categories).find((item) => {
          if (item.provisionInfoUids.indexOf(props.uid) !== -1) {
            return item;
          }
        });
      }
    }
  }
);

const getProvisionByUid = (uid) =>
  createSelector(getProvisions, (state) => state[uid]);

// TOC

const getSelectedAbstractTOCLoaded = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.selectedAbstractTOCLoaded
);

const getSelectedAbstractTOC = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) =>
    state.selectedAbstractTOCLoaded ? state.selectedAbstractTOC : undefined
);

// Pick Snippet

const getPickSnippetSuccess = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.pickSnippetSuccess
);

// SelectedProvisionStatus

const getDocumentsShortNameMap = createSelector(
  getAbstractReviewState,
  (state) => state.documentsShortNameMap
);

const getSelectedDocument = createSelector(
  getAbstractReviewState,
  (state) => state.selectedDocument
);

const getRecordCategory = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) =>
    state?.abstractDetail?.nodeNavigation?.category
);

const getProvisionWithhtmlName = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState, htmlName: string) =>
    values(state.provisions).find(
      (provision) => provision.htmlName === htmlName
    )
);

const isCreateProvisionCommentLoading = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.isCreateProvisionCommentLoading
);

const createProvisionCommentSucceeded = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.createProvisionCommentSucceeded
);

const isLoadProvisionCommentsLoading = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.isLoadProvisionCommentsLoading
);

const isProvisionCommentsLoadSucceeded = createSelector(
  getAbstractReviewState,
  isLoadProvisionCommentsLoading,
  (state: AbstractReviewState, isLoading) =>
    !isLoading && state.provisionCommentsLoadSucceeded
);

const getProvisionComments = createSelector(
  getAbstractReviewState,
  isProvisionCommentsLoadSucceeded,
  (state: AbstractReviewState, isLoaded) =>
    isLoaded ? state.selectedProvisionComments : []
);

const isUpdateProvisionNotePending = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.isUpdateProvisionNotePending
);

const isUpdateProvisionNoteSucceeded = createSelector(
  getAbstractReviewState,
  isUpdateProvisionNotePending,
  (state: AbstractReviewState, isLoading) =>
    !isLoading && state.isUpdateProvisionNoteSucceeded
);

const getSelectedProvision = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.selectedProvision
);

const isSelectedDocumentFullyLoaded = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.selectedDocumentFullyLoaded
);

const getCommentCountInRecord = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.commentCountInRecord
);

const getCommentPanelSideBarVisibilityStatus = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.isCommentPanelSideBarVisible
);

const getPromptPlaygroundSideBarVisibilityStatus = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.isPromptPlaygroundSideBarVisible
);

const getCustomTags = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.customTags
);

const isUpdateCustomTagsPending = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.isUpdateCustomTagsPending
);

const isUpdateCustomTagsSucceeded = createSelector(
  getAbstractReviewState,
  isUpdateCustomTagsPending,
  (state: AbstractReviewState, isLoading) =>
    !isLoading && state.isUpdateCustomTagsSucceeded
);

const getUpdateNoteWithProvisionUid = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state.UpdatedNoteWithProvisionUid
);

const getAbstractReviewPermissions = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => {
    // let permissions = []
    // state?.abstractDetail?.nodeNavigation?.navigations?.forEach(
    //     (navigation) => {
    //         permissions = [...permissions, ...navigation.permissions]
    //     },
    // )
    return state?.abstractDetail?.permissions || [];
  }
);

const getQueueInfo = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state?.abstractDetail?.queueInfo
);

const getRecordUid = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state?.abstractDetail?.uid
);

const getAnnotationsByDocumentUid = (
  documentUid: DocPdfViewModel["docAbstractUid"]
) =>
  createSelector(
    getAbstractReviewState,
    (state) => state.annotations[documentUid]
  );

const getOptionRemindersData = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState, optionRemindersType: RemindersTypeEnum) => {
    return state?.optionRemindersCalculatorDataLoading
      ? undefined
      : state?.optionRemindersCalculatorData?.[optionRemindersType]?.formFields;
  }
);

const getOptionTypeToSurroundingText = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState, optionRemindersType: RemindersTypeEnum) => {
    return state?.optionRemindersCalculatorDataLoading
      ? undefined
      : state?.optionRemindersCalculatorData?.[optionRemindersType]
          ?.optionTypeToSurroundingText;
  }
);

const getOptionReminderDataLoading = createSelector(
  getAbstractReviewState,
  (state: AbstractReviewState) => state?.optionRemindersCalculatorDataLoading
);

export const abstractReviewQuery = {
  getLoaded,
  getAllAbstractReview,
  getSelectedAbstractReview,
  getSelectedAbstractUid,
  selectedAbstractDetailLoaded,
  getSelectedAbstractDetail,
  getSelectedAbstractForm,
  getSelectedAbstractProvisions,
  getSelectedAbstractProvisionByProvisionUid,
  getProvisionGroupByProvisionUid,
  getAbstractFullProvisionGroups,
  getSelectedAbstractTOCLoaded,
  getSelectedAbstractTOC,
  getPickSnippetSuccess,
  getDocumentsShortNameMap,
  getSelectedDocument,
  getRecordCategory,
  getProvisionWithhtmlName,

  isCreateProvisionCommentLoading,
  createProvisionCommentSucceeded,

  isLoadProvisionCommentsLoading,
  getProvisionComments,

  isUpdateProvisionNotePending,
  isUpdateProvisionNoteSucceeded,

  getSelectedProvision,
  getProvisions,
  isSelectedDocumentFullyLoaded,

  getNestedProvisionInconsistencies,
  getAnchorProvisionUid,
  getLastTouchedMentionUid,
  getLastTouchedNestedSubfieldKey,

  getMentionApprovalCompletion,

  getSelectedAbstractDetailLoading,

  getCommentCountInRecord,
  getCommentPanelSideBarVisibilityStatus,

  getPromptPlaygroundSideBarVisibilityStatus,

  getCustomTags,
  isUpdateCustomTagsPending,
  isUpdateCustomTagsSucceeded,

  getUpdateNoteWithProvisionUid,

  getAbstractReviewPermissions,

  getMultiplePdfProvisionsList,
  getRecordDetails,

  getProvisionCategoriesWithProvisions,

  getProvisionsWhichNeedReview,
  getProvisionByUid,
  getAnnotationsByDocumentUid,

  getQueueInfo,
  getRecordUid,

  getCommentNodes,
  getCommentNodeLoaded,
  getDocuments,

  getOptionRemindersData,
  getOptionTypeToSurroundingText,
  getOptionReminderDataLoading,
};
