import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { ItlsNavigationComponent } from "./navigation.component";
import { ItlsNavVerticalItemComponent } from "./vertical/item/item.component";
import { ItlsNavVerticalCollapsableComponent } from "./vertical/collapsable/collapsable.component";
import { ItlsNavVerticalGroupComponent } from "./vertical/group/group.component";
import { ItlsNavHorizontalItemComponent } from "./horizontal/item/item.component";
import { ItlsNavHorizontalCollapsableComponent } from "./horizontal/collapsable/collapsable.component";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ItlsTreeModule } from "@@intelease/web/ui";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { ItlsDriveTreeViewFolderPickerModule } from "@@intelease/web/ui/src/lib/itls-drive-tree-folder-picker";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatIconModule,
    MatRippleModule,

    MatTooltipModule,
    ItlsTreeModule,
    ItlsDriveTreeViewFolderPickerModule,
    InteleaseCommonModule,
  ],
  exports: [ItlsNavigationComponent],
  declarations: [
    ItlsNavigationComponent,
    ItlsNavVerticalGroupComponent,
    ItlsNavVerticalItemComponent,
    ItlsNavVerticalCollapsableComponent,
    ItlsNavHorizontalItemComponent,
    ItlsNavHorizontalCollapsableComponent,
  ],
})
export class TbNavigationModule {}
