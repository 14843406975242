import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  PgSelectModule,
  ProgressModule,
} from "@@intelease/web/intelease/components";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { FormsModule } from "@angular/forms";
import { PgCardModule } from "@@intelease/web/intelease/components/card/card.module";
import { AddProvisionFormModalComponent } from "./add-provision-form-modal.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatDialogModule } from "@angular/material/dialog";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { PopoverModule } from "ngx-bootstrap/popover";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatRadioModule } from "@angular/material/radio";
import { ImportTsvFormModalComponent } from "./import-tsv-form-modal.component";

const NGX_BOOTSTRAP = [
  BsDropdownModule.forRoot(),
  ModalModule.forRoot(),
  TooltipModule.forRoot(),
  PopoverModule.forRoot(),
  PaginationModule.forRoot(),
  TypeaheadModule.forRoot(),
];

const MODALS = [AddProvisionFormModalComponent, ImportTsvFormModalComponent];

const DECLARATIONS = [...MODALS];

const INTELEASE_MODULES = [
  ProgressModule.forRoot(),
  PgSelectModule,
  PgCardModule.forRoot(),
];

const MATERIAL_MODULES = [MatDialogModule, MatTooltipModule, MatRadioModule];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxDatatableModule,
    ...INTELEASE_MODULES,
    ...NGX_BOOTSTRAP,
    ...MATERIAL_MODULES,
    InteleaseCommonModule,
  ],
  declarations: [...DECLARATIONS],
})
export class AddProvisionFormModalModule {}
