import { Injectable } from "@angular/core";

import { select, Store } from "@ngrx/store";

import { ProjectsPartialState, ProjectsState } from "./projects.reducer";
import { projectsQuery } from "./projects.selectors";
import {
  CleanProjectState,
  DeleteBatchProject,
  DeleteProject,
  LoadAllProjects,
  LoadProject,
  LoadProjects,
  SaveProject,
  ShareProject,
  UpdateProject,
} from "./projects.actions";

@Injectable()
export class ProjectsFacade {
  loaded$ = this.store.pipe(select(projectsQuery.getLoaded));
  allProjects$ = this.store.pipe(select(projectsQuery.getAllProjects));
  selectedProjects$ = this.store.pipe(
    select(projectsQuery.getSelectedProjects)
  );

  getProjectWidgets$ = this.store.pipe<any[]>(
    select(projectsQuery.getProjectWidgets)
  );
  getProjectWidgetsMap$ = this.store.pipe(
    select(projectsQuery.getProjectWidgetsMap)
  );
  getProjectDetail$ = this.store.pipe(select(projectsQuery.getProjectDetail));
  getSelectedProjectDetailMap$ = this.store.pipe(
    select(projectsQuery.getSelectedProjectDetailMap)
  );
  isLoadProjectLoading$ = this.store.pipe(
    select(projectsQuery.isLoadProjectLoading)
  );
  loadProjectError$ = this.store.pipe(select(projectsQuery.loadProjectError));

  saveProjectSucceeded$ = this.store.pipe(
    select(projectsQuery.saveProjectSucceeded)
  );
  getSaveProjectDetail$ = this.store.pipe(
    select(projectsQuery.getSaveProjectDetail)
  );

  isLoadAllProjectsLoading$ = this.store.pipe(
    select(projectsQuery.isLoadAllProjectsLoading)
  );
  getAllProjectsList$ = this.store.pipe(
    select(projectsQuery.getAllProjectsList)
  );

  deleteProjectSucceeded$ = this.store.pipe(
    select(projectsQuery.deleteProjectSucceeded)
  );

  deleteBatchProjectSucceeded$ = this.store.pipe(
    select(projectsQuery.deleteBatchProjectSucceeded)
  );

  isUpdateProjectLoading$ = this.store.pipe(
    select(projectsQuery.isUpdateProjectLoading)
  );
  updateProjectSucceeded$ = this.store.pipe(
    select(projectsQuery.updateProjectSucceeded)
  );

  constructor(private store: Store<ProjectsPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadProjects());
  }

  loadAllProjects(payload) {
    this.store.dispatch(new LoadAllProjects(payload));
  }

  saveProject(payload) {
    this.store.dispatch(new SaveProject(payload));
  }

  updateProject(payload: { projectUid: string; data: any; replace: boolean }) {
    this.store.dispatch(new UpdateProject(payload));
  }

  deleteProject(uid: string) {
    this.store.dispatch(new DeleteProject(uid));
  }

  deleteBatchProject(uids: string[]) {
    this.store.dispatch(new DeleteBatchProject(uids));
  }

  loadProject(
    projectUid: string,
    dashboardUid: string,
    selectedInstanceId: string
  ) {
    this.store.dispatch(
      new LoadProject({ projectUid, dashboardUid }, selectedInstanceId)
    );
  }

  shareProject(projectUid: string, users: string[]) {
    this.store.dispatch(new ShareProject({ projectUid, users }));
  }

  cleanProjectState(payload: ProjectsState) {
    this.store.dispatch(new CleanProjectState(payload));
  }
}
