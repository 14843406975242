import { PartialValPdfProvisionViewModel } from "@@intelease/api-models/adex-api-model-src";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  ProvisionReviewState,
  PROVISION_REVIEW_FEATURE_KEY,
} from "./provision-review.reducer";

export const selectProvisionReview =
  createFeatureSelector<ProvisionReviewState>(PROVISION_REVIEW_FEATURE_KEY);

export const selectNotePanelExpanded = createSelector(
  selectProvisionReview,
  (state: ProvisionReviewState) => {
    return state.notePanelExpanded;
  }
);

export const selectSnippedPanelExpanded = createSelector(
  selectProvisionReview,
  (state: ProvisionReviewState) => {
    return state.snippedPanelExpanded;
  }
);

export const selectIsDeletingProvisionOption = (
  option: PartialValPdfProvisionViewModel["uid"]
) =>
  createSelector(
    selectProvisionReview,
    (state: ProvisionReviewState) =>
      !!state.deletingProvisionOption.find((uid) => option === uid)
  );

export const selectIsTableProvisionOpen = createSelector(
  selectProvisionReview,
  (state: ProvisionReviewState) => state.openTableProvisionOption || null
);

export const selectLastSelectedProvisionOption = createSelector(
  selectProvisionReview,
  (state: ProvisionReviewState) => state.selectedProvisionOption
);

export const selectSavingProvisionOption = createSelector(
  selectProvisionReview,
  (state: ProvisionReviewState) => state.savingProvisionOption || null
);

export const onScrollToProvisionOption = createSelector(
  selectProvisionReview,
  (state: ProvisionReviewState) => state.scrollToProvisionOption || null
);
