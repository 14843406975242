import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("LoginTosDtoModel")
export class LoginTosDtoModel {
  @JsonProperty("userUid", String)
  userUid = "";

  @JsonProperty("firstName", String)
  firstName = "";

  @JsonProperty("lastName", String)
  lastName = "";

  @JsonProperty("password", String)
  password = "";

  @JsonProperty("status", String)
  status = "";
}
