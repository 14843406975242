import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PdfReaderComponent } from "./pdf-reader.component";

@NgModule({
  declarations: [PdfReaderComponent],
  imports: [CommonModule],
  exports: [PdfReaderComponent],
})
export class PdfReaderModule {}
