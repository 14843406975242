/* tslint:disable */
/* eslint-disable */
import { PathNodeModel } from "./path-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocSetSearchResultModel")
export class DocSetSearchResultModel {
  @JsonProperty("docSetName", String, true)
  docSetName?: string = "";

  @JsonProperty("docSetUid", String, true)
  docSetUid?: string = "";

  @JsonProperty("notes", String, true)
  notes?: string = "";

  @JsonProperty("pathNodes", [PathNodeModel], true)
  pathNodes?: Array<PathNodeModel> = [];
}
