/* tslint:disable */
/* eslint-disable */
import { CreateReminderDtoModel } from "./create-reminder-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCreateReminderDtoModel")
export class OApiReqCreateReminderDtoModel {
  @JsonProperty("data", CreateReminderDtoModel, true)
  data?: CreateReminderDtoModel = undefined;
}
