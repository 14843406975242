/* tslint:disable */
/* eslint-disable */
import { CreateFinalAbstractTaskDtoModel } from "./create-final-abstract-task-dto-model";
import { OApiReturnParamsRequestTaskViewTypeModel } from "./o-api-return-params-request-task-view-type-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCreateFinalAbstractTaskDtoModel")
export class OApiReqCreateFinalAbstractTaskDtoModel {
  @JsonProperty("data", CreateFinalAbstractTaskDtoModel, true)
  data?: CreateFinalAbstractTaskDtoModel = undefined;

  @JsonProperty("returnParams", OApiReturnParamsRequestTaskViewTypeModel, true)
  returnParams?: OApiReturnParamsRequestTaskViewTypeModel = undefined;
}
