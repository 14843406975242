import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import {
  ModalInputModel,
  ModalResponseModel,
} from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { TableProvisionModel } from "@@intelease/web/common/models";
import { cloneDeep, values, first as _first } from "lodash";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "il-table-provision",
  templateUrl: "./table-provision-modal.component.html",
  styleUrls: ["./table-provision-modal-component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableProvisionModalComponent implements OnInit {
  @ViewChild("dynamicTable", { static: true }) dynamicTable: any;
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  onChange: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;
  modalTitle = "Table Provision";
  rows: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ModalInputModel,
    public dialogRef: MatDialogRef<TableProvisionModalComponent>
  ) {
    this.payload = this.dialogData.payload;
    if (this.dialogData.mode) this.mode = this.dialogData.mode;
  }

  ngOnInit() {}

  private prepareData() {
    const rows = cloneDeep(this.dynamicTable.rows);
    const tableProvisionModel: TableProvisionModel = new TableProvisionModel();
    tableProvisionModel.headers = values(_first(rows));
    rows.splice(0, 1);
    tableProvisionModel.rows = rows.map((row) => values(row));
    tableProvisionModel.headers.forEach((header) =>
      tableProvisionModel.types.push("COMMON_NOUN")
    );
    return tableProvisionModel;
  }

  onSaveClick() {
    this.onLeaveModal(ModalsResponseTypeEnum.CLOSE);
  }

  onDismissModal() {
    this.onLeaveModal(ModalsResponseTypeEnum.DISMISS);
  }

  private onLeaveModal(type: ModalsResponseTypeEnum) {
    this.modalRes.data = {
      tableData: { ...this.prepareData() },
      rows: this.rows,
    };
    this.modalRes.type = type;
    this.dialogRef.close(this.modalRes);
  }

  onModelChange(evt: any) {
    this.rows = evt.rows;
    // this.modalRes.data = {...this.prepareData()}
  }
}
