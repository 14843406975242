import { Observable } from "rxjs";
import { CreateApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { ActorModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/actor.model";
import { cloneDeep } from "lodash";
import { DateConvertService } from "../../../../../common/services";

@Injectable({
  providedIn: "root",
})
export class CreateTaskService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly TASKS_URL = "/tasks";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(private createApiService: CreateApiService) {}

  createTask<T extends object>(
    abstractUid: string,
    task: any,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    //TODO(reza) fix me
    return this.createApiService.sendRequest(
      CreateTaskService.API_VERSION_1,
      `${CreateTaskService.FINAL_DOC_SETS_URL}/${abstractUid}${CreateTaskService.TASKS_URL}`,
      task,
      view,
      classRef
    );
  }

  createMultiEntitiesTask<T extends object>(
    abstractUids: string[],
    task: any,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    const newTaskData = cloneDeep(task);
    newTaskData.entityUids = abstractUids;
    return this.createApiService.sendRequest(
      CreateTaskService.API_VERSION_1,
      `${CreateTaskService.FINAL_DOC_SETS_URL}${CreateTaskService.TASKS_URL}`,
      newTaskData,
      view,
      classRef
    );
  }
}
