import { Any, JsonObject, JsonProperty } from "json2typescript";
import { FullValMultiProvisionValueModel } from "@@intelease/web/common/models/multi-provision-value";

@JsonObject("RentScheduleCalculatorResponseModel")
export class RentScheduleCalculatorResponseModel {
  @JsonProperty("messageinfoList", Any)
  messageinfoList: any = "";

  @JsonProperty("htmlNameToFullVal", [FullValMultiProvisionValueModel])
  htmlNameToFullVal: FullValMultiProvisionValueModel[] = [];
}
