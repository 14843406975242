import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItlsCommentInputComponent } from "./itls-comment-input.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatInputModule } from "@angular/material/input";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";
import { MatMenuModule } from "@angular/material/menu";
import { FormsModule } from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";

const MATERIAL_MODULES = [
  MatInputModule,
  MatMenuModule,
  MatOptionModule,
  MatIconModule,
];

@NgModule({
  declarations: [ItlsCommentInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ...MATERIAL_MODULES,
    CommonDirectivesModule,
  ],
  exports: [ItlsCommentInputComponent],
})
export class ItlsCommentInputModule {}
