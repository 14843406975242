/* tslint:disable */
/* eslint-disable */
import { SimpleOpenApiDateModel } from "./simple-open-api-date-model";

/**
 * one row of the option multiple reminders calculated table
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OptionMultipleReminderItemModel")
export class OptionMultipleReminderItemModel {
  /**
   * the text value of the option corresponding to these reminders
   */

  @JsonProperty("clauseSummary", String, true)
  clauseSummary?: string = "";

  @JsonProperty("date", SimpleOpenApiDateModel, true)
  date?: SimpleOpenApiDateModel = undefined;

  /**
   * the type of the date
   */

  @JsonProperty("dateType", String, true)
  dateType?: string = "";

  /**
   * the frequency of reminders
   */

  @JsonProperty("frequency", String, true)
  frequency?: string = "";

  /**
   * the reminder number (1-based)
   */

  @JsonProperty("number", Number, true)
  number?: number = undefined;

  /**
   * the offset from the reference date
   */

  @JsonProperty("offset", String, true)
  offset?: string = "";

  /**
   * the option type
   */

  @JsonProperty("optionType", String, true)
  optionType?: string = "";
}
