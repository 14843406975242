/* tslint:disable */
/* eslint-disable */
import { OApiDeleteRequestModel } from "./o-api-delete-request-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiDeleteBatchRequestBodyModel")
export class OApiDeleteBatchRequestBodyModel {
  @JsonProperty("data", OApiDeleteRequestModel, true)
  data?: OApiDeleteRequestModel = undefined;
}
