import {
  FullValMultiPdfProvViewModel,
  PartialValPdfProvisionViewModel,
} from "@@intelease/api-models/adex-api-model-src";
import { createReducer, on } from "@ngrx/store";
import { ProvisionOptionSourceEnum } from "../models/provision-review.model";
import {
  deleteProvisionOptionCanceled,
  deleteProvisionOptionConfirmed,
  deleteProvisionOptionFailed,
  deleteProvisionOptionSuccess,
  expandNotePanel,
  expandSnippedPanel,
  saveProvisionOption,
  openTableProvisionSuccess,
  scrollToProvisionOption,
  updateProvisionOptionUid,
  selectProvisionOptionSuccess,
  savingProvisionOption,
  savingProvisionOptionFinished,
} from "./provision-review.actions";

export const PROVISION_REVIEW_FEATURE_KEY = "PROVISION_REVIEW_FEATURE_KEY";

export interface ProvisionReviewState {
  notePanelExpanded?: PartialValPdfProvisionViewModel["uid"];
  snippedPanelExpanded?: PartialValPdfProvisionViewModel["uid"];
  deletingProvisionOption: PartialValPdfProvisionViewModel["uid"][];
  scrollToProvisionOption: {
    provision: FullValMultiPdfProvViewModel;
    provisionOption: PartialValPdfProvisionViewModel;
  };
  openTableProvisionOption: {
    provision: FullValMultiPdfProvViewModel;
    provisionOption: PartialValPdfProvisionViewModel;
  };
  selectedProvisionOption: {
    provision: FullValMultiPdfProvViewModel;
    provisionOption: PartialValPdfProvisionViewModel;
  };
  savingProvisionOption:
    | {
        provisionUid: FullValMultiPdfProvViewModel["uid"];
        provisionOption: PartialValPdfProvisionViewModel;
        isTemporaryProvisionOption: boolean;
      }
    | undefined;
}

export const initialState: ProvisionReviewState = {
  notePanelExpanded: undefined,
  snippedPanelExpanded: undefined,
  deletingProvisionOption: [],
  savingProvisionOption: undefined,
  openTableProvisionOption: undefined,
  selectedProvisionOption: undefined,
  scrollToProvisionOption: undefined,
};

export const provisionReviewReducer = createReducer(
  initialState,
  on(expandNotePanel, (state: ProvisionReviewState, action) => ({
    ...state,
    notePanelExpanded:
      state.notePanelExpanded === action.uid ? undefined : action.uid,
    snippedPanelExpanded:
      state.snippedPanelExpanded === action.uid
        ? state.snippedPanelExpanded
        : undefined,
  })),
  on(expandSnippedPanel, (state: ProvisionReviewState, action) => ({
    ...state,
    snippedPanelExpanded:
      state.snippedPanelExpanded === action.uid ? undefined : action.uid,
    notePanelExpanded:
      state.notePanelExpanded === action.uid
        ? state.notePanelExpanded
        : undefined,
  })),
  on(updateProvisionOptionUid, (state: ProvisionReviewState, action) => ({
    ...state,
    snippedPanelExpanded:
      state.snippedPanelExpanded === action.payload.provisionOptionOldUid
        ? action.payload.provisionOptionNewUid
        : state.snippedPanelExpanded,
    notePanelExpanded:
      state.notePanelExpanded === action.payload.provisionOptionOldUid
        ? action.payload.provisionOptionNewUid
        : state.notePanelExpanded,
  })),
  on(deleteProvisionOptionConfirmed, (state, action) => ({
    ...state,
    deletingProvisionOption: Array.from(
      new Set([
        ...state.deletingProvisionOption,
        action.payload.provisionOption.uid,
      ])
    ),
  })),
  on(
    deleteProvisionOptionCanceled,
    deleteProvisionOptionFailed,
    deleteProvisionOptionSuccess,
    (state, action) => ({
      ...state,
      deletingProvisionOption: state.deletingProvisionOption.filter(
        (uid) => action.payload.provisionOption.uid !== uid
      ),
    })
  ),
  on(saveProvisionOption, (state, action) => ({
    ...state,
    savingProvisionOption: {
      provisionUid: action.payload.provision.uid,
      provisionOption: action.payload.provisionOption,
      isTemporaryProvisionOption:
        action.payload.provisionOption.source ===
        ProvisionOptionSourceEnum.TEMP_LOCAL,
    },
  })),
  on(savingProvisionOption, (state, action) => ({
    ...state,
    savingProvisionOption: {
      provisionUid: action.payload.provisionUid,
      provisionOption: { uid: action.payload.provisionOptionUid },
      isTemporaryProvisionOption: true,
    },
  })),
  on(savingProvisionOptionFinished, (state) => ({
    ...state,
    savingProvisionOption: undefined,
  })),
  on(openTableProvisionSuccess, (state, action) => ({
    ...state,
    openTableProvisionOption: action.payload,
  })),
  on(selectProvisionOptionSuccess, (state, action) => ({
    ...state,
    selectedProvisionOption: action.payload,
    notePanelExpanded:
      action.payload.provisionOption.uid === state.notePanelExpanded
        ? state.notePanelExpanded
        : undefined,
    snippedPanelExpanded:
      action.payload.provisionOption.uid === state.snippedPanelExpanded
        ? state.snippedPanelExpanded
        : undefined,
  })),
  on(scrollToProvisionOption, (state, action) => ({
    ...state,
    scrollToProvisionOption: action.payload,
  }))
);
