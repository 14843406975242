<div>
  <div mat-dialog-title class="clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5>
      {{ title }}
    </h5>
  </div>
  <div mat-dialog-content>
    <p style="margin: 0 0 10px 0">
      {{ message }}
    </p>
  </div>
  <div mat-dialog-actions>
    <button
      type="button"
      mat-flat-button
      color="warn"
      class="btn-cons pull-left inline mr-2"
      dataCy="cyDialogOkBtn"
      (click)="onDelete()"
    >
      Delete
    </button>
    <button
      type="button"
      mat-button
      class="btn-cons no-margin pull-left inline"
      dataCy="cyDialogCancelBtn"
      (click)="onDismissModal()"
    >
      Cancel
    </button>
  </div>
</div>
