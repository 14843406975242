import { SearchApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { SortDirectionEnum } from "@@intelease/web/common/enums/sort-direction.enum";
import { FilterRelationEnum } from "@@intelease/web/common/enums/filter-relation.enum";
import {
  ListResponseModel,
  PaginationModel,
} from "@@intelease/web/intelease/models";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AbstractKeyValue } from "@@intelease/web/common/models";

@Injectable({
  providedIn: "root",
})
export class SearchAbstractService {
  private static readonly API_VERSION_2 = "/v2";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(private searchApiService: SearchApiService) {}

  getPendingTasks<T extends object>(
    view: string,
    classRef: new () => T
  ): Observable<ListResponseModel<T>> {
    const filter = {
      field: "assigned::Assigned::" + PROVISIONS_DATA_CONST.STRING.name,
      relation: FilterRelationEnum.EQ.toString(),
      value: "TO_ME",
    };
    return this.searchApiService.sendRequest(
      SearchAbstractService.API_VERSION_2,
      `${SearchAbstractService.FINAL_DOC_SETS_URL}`,
      view,
      classRef,
      [filter],
      "lastModifiedDate::Last Modified Date::" +
        PROVISIONS_DATA_CONST.DATE.name +
        "::" +
        SortDirectionEnum.DESC,
      // TODO(apoorv): The order here should probably be by due date instead.
      new PaginationModel(1, 4)
    );
  }

  searchByDocumentName<T extends object>(
    searchStr: string,
    view: string,
    classRef: new () => T,
    pageSize = 100
  ): Observable<T[]> {
    const filters = searchStr
      ? [
          {
            field:
              "abstractFilename::Abstract Filename::" +
              PROVISIONS_DATA_CONST.STRING.name,
            relation: FilterRelationEnum.CONTAIN,
            value: searchStr,
          },
        ]
      : [];
    return this.searchApiService
      .sendRequest(
        SearchAbstractService.API_VERSION_2,
        SearchAbstractService.FINAL_DOC_SETS_URL,
        view,
        classRef,
        filters,
        "lastModifiedDate::Last Modified Date::" +
          PROVISIONS_DATA_CONST.DATE.name +
          "::" +
          SortDirectionEnum.ASC,
        new PaginationModel(1, pageSize)
      )
      .pipe(map((res) => res.items));
  }

  /**
   * search in Documents
   */
  searchFinalDocuments(
    viewCols: string[],
    filters: any[],
    pageable?: PaginationModel,
    sort?: string
  ): Observable<ListResponseModel<AbstractKeyValue>> {
    return this.searchApiService.sendTableRequest(
      SearchAbstractService.API_VERSION_2,
      SearchAbstractService.FINAL_DOC_SETS_URL,
      viewCols,
      filters,
      sort,
      new PaginationModel(pageable.page, pageable.size)
    );
  }
}
