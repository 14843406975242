<div class="itls-filter">
  <il-filter-group
    [filter]="model"
    [filterFields]="filterFields"
    [level]="1"
    [filterOperators]="filterOperators"
    (modelChange)="modelChange.emit(model)"
  >
  </il-filter-group>
</div>
