import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RestClient } from "@@intelease/web/utils";
import {
  OApiRespCalendarDisplayableFieldsDtoModel,
  OApiRespCalendarFilterFieldsDtoModel,
  OApiRespFullCalendarQueryViewModel,
  OApiRespFullCalendarViewModel,
  OApiRespMinimalCalendarQueryViewModel,
  OApiRespMinimalCalendarViewModel,
  OApiRespMultiFullCalendarViewModel,
  OApiRespMultiReportReminderResponseEntryDtoModel,
  OApiRespMultiReportResponseEntryDtoModel,
  OApiRespReminderDtoModel,
  OApiSingleEntityNoDataResponseModel,
} from "@@intelease/api-models/adex-api-model-src";

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  private static CALENDAR_BASE_URL = "/calendar";
  private static REMINDER_BASE_URL = "/reminder";

  constructor(private readonly restClient: RestClient) {}

  getCalendarFilterFields(): Observable<OApiRespCalendarFilterFieldsDtoModel> {
    return this.restClient.sendGetRequest<OApiRespCalendarFilterFieldsDtoModel>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}/filterFields`,
      OApiRespCalendarFilterFieldsDtoModel
    );
  }

  getCalendarDisplayableFields(): Observable<OApiRespCalendarDisplayableFieldsDtoModel> {
    return this.restClient.sendGetRequest<OApiRespCalendarDisplayableFieldsDtoModel>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}/displayableFields`,
      OApiRespCalendarDisplayableFieldsDtoModel
    );
  }

  searchCalendarFinalAbstracts(
    dashboardUid: string,
    payload,
    dateRange: { start: string; end: string } = {
      start: undefined,
      end: undefined,
    }
  ): Observable<OApiRespMultiReportReminderResponseEntryDtoModel> {
    const _payload = {
      ...payload,
      ...{
        page: 1,
        perPage: 9000,
        sort: [],
      },
    };
    const { start, end } = dateRange;
    let params: any = {
      start,
    };
    if (end) {
      params = { ...params, end };
    }
    if (dashboardUid) {
      params = { ...params, dashboardUid };
    }
    return this.restClient.sendPostRequest<OApiRespMultiReportReminderResponseEntryDtoModel>(
      "/v2",
      `${CalendarService.CALENDAR_BASE_URL}/finalAbstracts`,
      { data: _payload },
      OApiRespMultiReportReminderResponseEntryDtoModel,
      {
        params,
      }
    );
  }

  getCalendars(): Observable<OApiRespMultiFullCalendarViewModel> {
    return this.restClient.sendGetRequest<OApiRespMultiFullCalendarViewModel>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}`,
      OApiRespMultiFullCalendarViewModel,
      {
        params: {
          view: "full",
          page: 1,
          perPage: 9000,
        },
      }
    );
  }

  saveCalendar(payload): Observable<OApiRespMinimalCalendarViewModel> {
    const _payload = {
      returnParams: {
        view: "minimal",
      },
      data: payload,
    };
    return this.restClient.sendPostRequest<OApiRespMinimalCalendarViewModel>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}`,
      _payload,
      OApiRespMinimalCalendarViewModel
    );
  }

  deleteCalendarByUid(
    uid: string
  ): Observable<OApiRespMinimalCalendarViewModel> {
    return this.restClient.sendDeleteRequest<OApiRespMinimalCalendarViewModel>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}/${uid}`,
      OApiRespMinimalCalendarViewModel,
      {
        params: {
          view: "minimal",
        },
      }
    );
  }

  getCalendarById(
    dashboardUid,
    calendarUid: string
  ): Observable<OApiRespFullCalendarViewModel> {
    if (dashboardUid) {
      return this.restClient.sendGetRequest<OApiRespFullCalendarViewModel>(
        "/v1",
        `${CalendarService.CALENDAR_BASE_URL}/${calendarUid}`,
        OApiRespFullCalendarViewModel,
        {
          params: {
            view: "full",
            dashboardUid,
            [RestClient.HTTP_CONTEXT_PARAM_KEY]: {
              doNotNotifyNotFoundError: true,
            },
          },
        }
      );
    } else {
      return this.restClient.sendGetRequest<OApiRespFullCalendarViewModel>(
        "/v1",
        `${CalendarService.CALENDAR_BASE_URL}/${calendarUid}`,
        OApiRespFullCalendarViewModel,
        {
          params: {
            view: "full",
          },
        }
      );
    }
  }

  updateCalendarById(id: string, payload): Observable<any> {
    const _payload = {
      returnParams: {
        view: "none",
      },
      replace: false,
      data: payload,
    };
    return this.restClient.sendPutRequestNoView<any>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}/${id}`,
      _payload
    );
  }

  saveCalendarQuery(
    calendarUid: string,
    payload
  ): Observable<OApiRespMinimalCalendarQueryViewModel> {
    const _payload = {
      returnParams: {
        view: "minimal",
      },
      data: payload,
    };
    return this.restClient.sendPostRequest<OApiRespMinimalCalendarQueryViewModel>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}/${calendarUid}/query`,
      _payload,
      OApiRespMinimalCalendarQueryViewModel
    );
  }

  updateCalendarQuery(
    calendarUid: string,
    calendarQueryUid: string,
    payload
  ): Observable<OApiRespMinimalCalendarQueryViewModel> {
    const _payload = {
      returnParams: {
        view: "minimal",
      },
      replace: true,
      data: payload,
    };
    return this.restClient.sendPutRequest<OApiRespMinimalCalendarQueryViewModel>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}/${calendarUid}/query/${calendarQueryUid}`,
      _payload,
      OApiRespMinimalCalendarQueryViewModel
    );
  }

  runCalendarQuery(
    payload
  ): Observable<OApiRespMultiReportResponseEntryDtoModel> {
    const _payload = {
      ...payload,
      ...{
        page: 1,
        perPage: 9000,
        sort: [],
      },
    };
    return this.restClient.sendPostRequest<OApiRespMultiReportResponseEntryDtoModel>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}/finalAbstracts`,
      { data: _payload },
      OApiRespMultiReportResponseEntryDtoModel
    );
  }

  updateCalendarQueryActive(
    calendarUid: string,
    calendarQueryUid: string,
    payload: { active: boolean }
  ): Observable<OApiRespFullCalendarQueryViewModel> {
    const _payload = {
      returnParams: {
        view: "full",
      },
      replace: true,
      data: payload,
    };
    return this.restClient.sendPutRequest<OApiRespFullCalendarQueryViewModel>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}/${calendarUid}/query/${calendarQueryUid}/active`,
      _payload,
      OApiRespFullCalendarQueryViewModel
    );
  }

  getCalendarQueriesByCalendarUid(calendarUid: string): Observable<any> {
    return this.restClient.sendGetRequestNoView<any>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}/${calendarUid}/query`,
      {
        params: {
          view: "full",
        },
      }
    );
  }

  deleteCalendarQuery(
    calendarUid: string,
    calendarQueryUid: string
  ): Observable<OApiRespMinimalCalendarQueryViewModel> {
    return this.restClient.sendDeleteRequest<OApiRespMinimalCalendarQueryViewModel>(
      "/v1",
      `${CalendarService.CALENDAR_BASE_URL}/${calendarUid}/query/${calendarQueryUid}`,
      OApiRespMinimalCalendarQueryViewModel,
      {
        params: {
          view: "minimal",
        },
      }
    );
  }

  deleteMultipleCalendarsByUids(
    uids: string[]
  ): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.restClient.sendPostRequest<OApiSingleEntityNoDataResponseModel>(
      "/v1",
      `/deleteBatch${CalendarService.CALENDAR_BASE_URL}`,
      {
        data: {
          objectUids: uids,
        },
        returnParams: {
          view: "minimal",
        },
      },
      OApiSingleEntityNoDataResponseModel
    );
  }

  saveReminder(
    calendarUid: string,
    payload
  ): Observable<OApiRespReminderDtoModel> {
    const _payload = {
      returnParams: {
        view: "minimal",
      },
      data: payload,
    };
    return this.restClient.sendPostRequest<OApiRespReminderDtoModel>(
      "/v1",
      `${CalendarService.REMINDER_BASE_URL}`,
      _payload,
      OApiRespReminderDtoModel
    );
  }

  deleteReminder(
    calendarUid: string,
    reminderUid: string
  ): Observable<OApiRespReminderDtoModel> {
    return this.restClient.sendDeleteRequest<OApiRespReminderDtoModel>(
      "/v1",
      `${CalendarService.REMINDER_BASE_URL}/${reminderUid}`,
      OApiRespReminderDtoModel,
      {
        params: {
          view: "minimal",
        },
      }
    );
  }
}
