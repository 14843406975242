/* tslint:disable */
/* eslint-disable */
import { ProvisionInfoViewModel } from "./provision-info-view-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ProvisionCategoryModel")
export class ProvisionCategoryModel {
  @JsonProperty("categoryName", String, true)
  categoryName?: string = "";

  @JsonProperty("provisions", [ProvisionInfoViewModel], true)
  provisions?: Array<ProvisionInfoViewModel> = [];
}
