import { NgModule, InjectionToken, ModuleWithProviders } from "@angular/core";
import { ItlsCalendarComponent } from "./itls-calendar.component";
import {
  TuiCalendarDefaults,
  TuiCalendarOptions,
} from "./itls-calendar-defaults.service";

export const USER_DEFAULTS: InjectionToken<string> = new InjectionToken(
  "tuiCalendar defaults"
);

export function defaultsFactory(
  userDefaults: TuiCalendarOptions
): TuiCalendarOptions {
  const defaults: TuiCalendarDefaults = new TuiCalendarDefaults();
  Object.assign(defaults, userDefaults);
  return defaults;
}

@NgModule({
  declarations: [ItlsCalendarComponent],
  exports: [ItlsCalendarComponent],
  providers: [{ provide: TuiCalendarDefaults, useClass: TuiCalendarDefaults }],
})
export class ItlsCalendarModule {
  static forRoot(
    userDefaults: TuiCalendarOptions = {}
  ): ModuleWithProviders<ItlsCalendarModule> {
    return {
      ngModule: ItlsCalendarModule,
      providers: [
        {
          provide: USER_DEFAULTS,
          useValue: userDefaults,
        },
        {
          provide: TuiCalendarDefaults,
          useFactory: defaultsFactory,
          deps: [USER_DEFAULTS],
        },
      ],
    };
  }
}
