import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RestClient } from "@@intelease/web/utils";
import {
  CommentModel,
  CommentUserMentionModel,
  CommenterModel,
  DocumentCommentModel,
} from "@@intelease/web/abstraction-page/src/lib/models";
import {
  CreateApiService,
  DeleteApiService,
  EditApiService,
  FetchBatchApiService,
  UserInfoService,
} from "@@intelease/web/intelease/services";
import { map } from "rxjs/operators";
import { AppHighlightTypeEnum } from "@@intelease/app-models/abstract-review";

@Injectable({
  providedIn: "root",
})
export class WebAbstractionPageCommentService {
  private static readonly API_VERSION_1 = "/v1";
  public static readonly onSelectCommentFromReader: Subject<{
    commentUid: string;
  }> = new Subject<{ commentUid: string }>();

  constructor(
    private restClient: RestClient,
    private createApiService: CreateApiService,
    private editApiService: EditApiService,
    private deleteApiService: DeleteApiService,
    private fetchBatchApiService: FetchBatchApiService
  ) {}

  createComment(
    abstractUid: string,
    docAbstractUid: string,
    commenterModel: CommenterModel,
    firstPage: number,
    highlights: any[],
    text: string,
    mentions: CommentUserMentionModel[],
    elements?,
    boundingBox?,
    highlightType?: AppHighlightTypeEnum
  ): Observable<DocumentCommentModel> {
    return this.createApiService.sendRequest(
      WebAbstractionPageCommentService.API_VERSION_1,
      this.getBaseUrl(abstractUid, docAbstractUid),
      {
        page: firstPage,
        pdfHighlights: highlights,
        text,
        mentions,
        ...elements,
        boundingBox,
        highlightType,
      },
      DocumentCommentModel.VIEW,
      DocumentCommentModel
    );
  }

  replyToComment(
    abstractUid: string,
    docAbstractUid: string,
    commenterModel: CommenterModel,
    repliedToCommentUid: string,
    text: string,
    mentions: CommentUserMentionModel[],
    elements?
  ): Observable<CommentModel> {
    return this.createApiService.sendRequest(
      WebAbstractionPageCommentService.API_VERSION_1,
      this.getBaseUrl(abstractUid, docAbstractUid) +
        `/${repliedToCommentUid}/replied`,
      {
        text,
        mentions,
        ...elements,
      },
      "minimal",
      CommentModel
    );
  }

  editComment(
    abstractUid: string,
    docAbstractUid: string,
    commentUid: string,
    text: string,
    mentions: CommentUserMentionModel[],
    elements?
  ): Observable<any> {
    return this.editApiService.sendRequestNoView(
      WebAbstractionPageCommentService.API_VERSION_1,
      this.getBaseUrl(abstractUid, docAbstractUid),
      commentUid,
      {
        text,
        mentions,
        ...elements,
      },
      false
    );
  }

  deleteComment(
    abstractUid: string,
    docAbstractUid: string,
    commentUid: string
  ): Observable<any> {
    return this.deleteApiService.sendRequestNoView(
      WebAbstractionPageCommentService.API_VERSION_1,
      this.getBaseUrl(abstractUid, docAbstractUid),
      commentUid
    );
  }

  getAllComments(
    abstractUid: string,
    docAbstractUid: string
  ): Observable<DocumentCommentModel[]> {
    return this.fetchBatchApiService
      .sendBatchRequest(
        WebAbstractionPageCommentService.API_VERSION_1,
        this.getBaseUrl(abstractUid, docAbstractUid),
        DocumentCommentModel.VIEW,
        DocumentCommentModel
      )
      .pipe(map((resp) => resp.items));
  }

  getCurrentUserAsCommenter(): CommenterModel {
    return {
      uid: UserInfoService.getUserUid(),
      name: UserInfoService.getUserFullName(),
      hasProfileImage: false,
    };
  }

  private getBaseUrl(abstractUid: string, docUid: string) {
    return `/finalDocSets/${abstractUid}/documents/${docUid}/comments`;
  }
}
