<div fxLayout="column" style="height: 320px">
  <div
    fxLayout="row"
    fxLayoutAlign="space-around center"
    class="upload-dashboard-header"
  >
    <span
      style="margin-right: 20px"
      [class.selected-view]="selectedView === PanelView.UPLOAD"
      >Upload</span
    >
    <span [class.selected-view]="selectedView === PanelView.SELECT_DEST_FOLDER"
      >Select destination folder</span
    >
  </div>
  <div
    [fxShow]="selectedView === PanelView.UPLOAD"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    style="padding: 16px"
    fxFlex="1 1 auto"
  >
    <il-files-upload
      #filesUploadComponent
      (uploadingFolderEvent)="isUploadingFolder = $event"
      (modelChanged)="onModelChanged($event)"
      [verticalView]="false"
      style="height: 100%"
    ></il-files-upload>
  </div>
  <div
    [fxShow]="selectedView === PanelView.SELECT_DEST_FOLDER"
    fxFlex="1 1 auto"
  >
    <il-drive-tree
      [contentMaxHeight]="236"
      [selectedNodeUid]="selectedFolderUid"
      (nodeClicked)="selectedFolderUid = $event.id"
      [showContextMenu]="true"
    ></il-drive-tree>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="end"
    class="action-btn-container"
    *ngIf="uploadedDocSets?.length"
  >
    <button
      *ngIf="selectedView !== PanelView.UPLOAD"
      [disabled]="isUploading"
      type="button"
      class="btn btn-default upload-action-btn back-btn"
      (click)="onBackClicked()"
    >
      Back
    </button>
    <button
      *ngIf="selectedView !== PanelView.SELECT_DEST_FOLDER"
      type="button"
      class="btn btn-primary upload-action-btn"
      (click)="onNextClicked()"
    >
      <div>
        <span> Next </span>
        <span style="margin-top: -2px">
          <itls-icon svgIcon="icon-next"></itls-icon>
        </span>
      </div>
    </button>
    <button
      type="button"
      *ngIf="selectedView === PanelView.SELECT_DEST_FOLDER"
      [disabled]="
        isUploading || !this.selectedFolderUid || !this.uploadedDocSets?.length
      "
      class="btn btn-primary upload-action-btn"
      (click)="onUpload()"
    >
      Upload
      <i class="fa fa-spinner fa-spin" *ngIf="isUploading"></i>
    </button>
  </div>
</div>
