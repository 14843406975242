/* tslint:disable */
/* eslint-disable */
import { FileSearchRequestDtoModel } from "./file-search-request-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqFileSearchRequestDtoModel")
export class OApiReqFileSearchRequestDtoModel {
  @JsonProperty("data", FileSearchRequestDtoModel, true)
  data?: FileSearchRequestDtoModel = undefined;
}
