import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CastPipe } from "./cast/cast.pipe";
import {
  FilterPipe,
  HighlightSearchPipe,
  JoinArrayPipe,
  TranslateIconTypePipe,
  SafeHtmlPipe,
  SecureUrlPipe,
  SplitStringPipe,
  FindArrayPipe,
} from ".";
import { DeserializeSimpleDatePipe } from "./date-util/deserialize-simple-date.pipe";
import { SerializeToExactDatePipe } from "./date-util/serialize-to-exact-date.pipe";

const PIPES = [
  FindArrayPipe,
  SplitStringPipe,
  FilterPipe,
  SecureUrlPipe,
  HighlightSearchPipe,
  SafeHtmlPipe,
  JoinArrayPipe,
  TranslateIconTypePipe,
  CastPipe,
  DeserializeSimpleDatePipe,
  SerializeToExactDatePipe,
];

@NgModule({
  declarations: [...PIPES],
  imports: [CommonModule],
  providers: [],
  exports: [...PIPES],
})
export class CommonPipesModule {}
