import { Injectable } from "@angular/core";
import { RestClient } from "@@intelease/web/utils";
import { Observable, of, Subject, Subscription } from "rxjs";
import { cloneDeep } from "lodash";
import {
  CreateTeamModel,
  EditTeamModel,
  FullTeamModel,
  MinimalTeamModel,
  SettingItemModel,
} from "@@intelease/app-models/settings";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { ProfileUserModel } from "@@intelease/web/common/models/user";
import {
  EditUserService,
  FetchBatchUserService,
  FetchUserService,
} from "@@intelease/web/intelease/services/models";
import { map, take } from "rxjs/operators";
import { AccountRoleTypeModel } from "@@intelease/web/common/models";
import { UserProfileService } from "@@intelease/web/user-profile/src/lib/services";
import {
  OApiRespFullRoleViewModel,
  OApiRespMinimalRoleViewModel,
} from "@@intelease/api-models/adex-api-model-src";

export interface SettingsUser {
  profile: SettingsProfile;
  allowChangePassword: boolean;
}

export interface SettingsProfile {
  name: string;
  email: string;
  phoneNumber: string;
}

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly SETTINGS_BASE_URL = "/settings";
  private static readonly USERS_URL = "/users";
  private static readonly USER_GROUPS_URL = "/userGroups";

  private user$: Subject<SettingsUser> = new Subject<SettingsUser>();
  private user: SettingsUser;
  private settingItemsChanged$: Subject<Map<string, SettingItemModel>> =
    new Subject<Map<string, SettingItemModel>>();
  private settingItems: Map<string, SettingItemModel> = new Map<
    string,
    SettingItemModel
  >();
  private userUidToProfileImageSrc: Map<string, string | Observable<string>> =
    new Map<string, string | Observable<string>>();
  private accountRoleTypeModels$: Subject<AccountRoleTypeModel[]> = new Subject<
    AccountRoleTypeModel[]
  >();
  private accountRoleTypeModels: AccountRoleTypeModel[] = [];

  constructor(
    private restClient: RestClient,
    private fetchBatchUserService: FetchBatchUserService,
    private userProfileService: UserProfileService,
    private fetchUserService: FetchUserService,
    private editUserService: EditUserService
  ) {}

  setUser(user: SettingsUser) {
    this.user = cloneDeep(user);
    this.user$.next(this.user);
  }

  onUserChanged(): Observable<SettingsUser> {
    return this.user$.asObservable();
  }

  getUser(): SettingsUser {
    return this.user;
  }

  onAllAccountRoleTypesChanged(): Observable<AccountRoleTypeModel[]> {
    return this.accountRoleTypeModels$.asObservable();
  }

  getAllAccountRoleTypes(): AccountRoleTypeModel[] {
    return this.accountRoleTypeModels;
  }

  loadSettingItems(): Subscription {
    this.userUidToProfileImageSrc.clear();
    this.fetchUserService.getAllAccountRoles().subscribe((accountRoles) => {
      this.accountRoleTypeModels = accountRoles;
      this.accountRoleTypeModels$.next(this.accountRoleTypeModels);
    });
    return this.restClient
      .sendGetListRequest(
        SettingsService.API_VERSION_1,
        `${SettingsService.USERS_URL}${SettingsService.SETTINGS_BASE_URL}`,
        SettingItemModel,
        SettingItemModel.view
      )
      .subscribe((resp) => {
        this.settingItems = new Map<string, SettingItemModel>();
        resp.items.forEach((item) => this.settingItems.set(item.key, item));
        this.settingItemsChanged$.next(this.settingItems);
      });
  }

  onSettingItemsChanged(): Observable<Map<string, SettingItemModel>> {
    return this.settingItemsChanged$.asObservable();
  }

  getSettingItems(): Map<string, SettingItemModel> {
    return this.settingItems;
  }

  editSettingItem(editedSettingItem: SettingItemModel): Subscription {
    return this.restClient
      .sendPutRequest(
        SettingsService.API_VERSION_1,
        `${SettingsService.USERS_URL}${SettingsService.SETTINGS_BASE_URL}`,
        {
          items: [editedSettingItem],
        },
        ServerResponseModel
      )
      .subscribe((resp: ServerResponseModel) => {
        this.settingItems = new Map<string, SettingItemModel>();
        resp.data.items.forEach((item) =>
          this.settingItems.set(item.key, item)
        );
        this.settingItemsChanged$.next(this.settingItems);
      });
  }

  getAllUsersInTheCurrentUserAccount(): Observable<ProfileUserModel[]> {
    return this.fetchBatchUserService
      .getAllUsersInTheCurrentUserAccount(
        ProfileUserModel.view,
        ProfileUserModel
      )
      .pipe(map((data) => data.items));
  }

  editAccountRoleForUser(
    user: ProfileUserModel,
    accountRoleType: string
  ): Observable<any> {
    return this.editUserService.editAccountRoleForUser(user, accountRoleType);
  }

  getAllTeams(): Observable<MinimalTeamModel[]> {
    return this.restClient
      .sendGetListRequest(
        SettingsService.API_VERSION_1,
        SettingsService.USER_GROUPS_URL,
        MinimalTeamModel,
        MinimalTeamModel.view
      )
      .pipe(map((resp) => resp.items));
  }

  getTeamByUid(teamUid: string): Observable<FullTeamModel> {
    return this.restClient.sendGetRequest(
      SettingsService.API_VERSION_1,
      `${SettingsService.USER_GROUPS_URL}/${teamUid}`,
      FullTeamModel,
      {
        params: {
          view: FullTeamModel.view,
        },
      },
      (resp) => resp.data
    );
  }

  deleteTeamByUid(
    teamUid: string
  ): Observable<OApiRespFullRoleViewModel | OApiRespMinimalRoleViewModel> {
    return this.restClient.sendDeleteRequest<
      OApiRespFullRoleViewModel | OApiRespMinimalRoleViewModel
    >(
      SettingsService.API_VERSION_1,
      `${SettingsService.USER_GROUPS_URL}/${teamUid}`,
      OApiRespFullRoleViewModel || OApiRespMinimalRoleViewModel,
      {
        params: {
          view: MinimalTeamModel.view,
        },
      }
    );
  }

  createTeam(createTeamModel: CreateTeamModel): Observable<MinimalTeamModel> {
    return this.restClient.sendPostRequest(
      SettingsService.API_VERSION_1,
      SettingsService.USER_GROUPS_URL,
      {
        returnParams: {
          view: MinimalTeamModel.view,
        },
        data: createTeamModel,
      },
      MinimalTeamModel,
      {},
      (resp) => resp.data
    );
  }

  editTeam(
    teamUid: string,
    editTeamModel: EditTeamModel
  ): Observable<FullTeamModel> {
    return this.restClient.sendPutRequest(
      SettingsService.API_VERSION_1,
      `${SettingsService.USER_GROUPS_URL}/${teamUid}`,
      {
        returnParams: {
          view: FullTeamModel.view,
        },
        data: editTeamModel,
        replace: false,
      },
      FullTeamModel,
      {},
      (resp) => resp.data
    );
  }

  /**
   * returns img src
   */
  getUserProfileImage(userUid: string): Observable<string> {
    const imgSrc = this.userUidToProfileImageSrc.get(userUid);
    if (imgSrc) {
      if (imgSrc instanceof Observable) {
        return imgSrc.pipe(take(1));
      }
      return of(imgSrc);
    } else {
      const subject = new Subject<string>();
      const obsv = Observable.create((observer) => {
        this.userProfileService.getProfileImageForUser(userUid).subscribe(
          (profileImage) => {
            const reader = new FileReader();
            reader.addEventListener("load", (event: any) => {
              const loadedImgSrc = event.target.result;
              this.userUidToProfileImageSrc.set(userUid, loadedImgSrc);
              //TODO(reza) handle errors when loading file
              observer.next(loadedImgSrc);
              subject.next(loadedImgSrc);
            });
            reader.readAsDataURL(profileImage);
          },
          (error) => {
            if (error.status === 404) {
              this.userUidToProfileImageSrc.set(userUid, null);
              observer.next(null);
            } else {
              observer.error(error);
            }
          }
        );
      });
      this.userUidToProfileImageSrc.set(userUid, subject.asObservable());

      return obsv.pipe(take(1));
    }
  }
}
