/* tslint:disable */
/* eslint-disable */
import { AnnotatedImageViewModel } from "./annotated-image-view-model";
import { DocDataDtoModel } from "./doc-data-dto-model";
import { DocSetCategoryViewModel } from "./doc-set-category-view-model";
import { DocSetPathModel } from "./doc-set-path-model";
import { DocSetTableOfContentsViewModel } from "./doc-set-table-of-contents-view-model";
import { NodeNavigationResultModel } from "./node-navigation-result-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocSetChromeExtensionViewModel")
export class DocSetChromeExtensionViewModel {
  @JsonProperty("annotatedImageViews", [AnnotatedImageViewModel], true)
  annotatedImageViews?: Array<AnnotatedImageViewModel> = [];

  @JsonProperty("creationDate", DateTimeConvertService, true)
  creationDate?: Date | undefined = undefined;

  @JsonProperty("defaultDoc", DocDataDtoModel, true)
  defaultDoc?: DocDataDtoModel = undefined;

  @JsonProperty("docNames", [String], true)
  docNames?: Array<string> = [];

  @JsonProperty("docSetCategoryView", DocSetCategoryViewModel, true)
  docSetCategoryView?: DocSetCategoryViewModel = undefined;

  @JsonProperty("docSetPath", DocSetPathModel, true)
  docSetPath?: DocSetPathModel = undefined;

  @JsonProperty(
    "docSetTableOfContentsView",
    DocSetTableOfContentsViewModel,
    true
  )
  docSetTableOfContentsView?: DocSetTableOfContentsViewModel = undefined;

  @JsonProperty("docUids", [String], true)
  docUids?: Array<string> = [];

  @JsonProperty("documents", [DocDataDtoModel], true)
  documents?: Array<DocDataDtoModel> = [];

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("nodeNavigation", NodeNavigationResultModel, true)
  nodeNavigation?: NodeNavigationResultModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
