/* tslint:disable */
/* eslint-disable */
import { SnippetModel } from "./snippet-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SourceViewModel")
export class SourceViewModel {
  @JsonProperty("dirtyText", String, true)
  dirtyText?: string = "";

  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  @JsonProperty("filename", String, true)
  filename?: string = "";

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  @JsonProperty("pngSnippets", Any, true)
  pngSnippets?: {
    [key: string]: string;
  } = {};

  @JsonProperty("relatedOptionUids", [String], true)
  relatedOptionUids?: Array<string> = [];

  @JsonProperty("snippets", [SnippetModel], true)
  snippets?: Array<SnippetModel> = [];

  @JsonProperty("textSurroundingOriginalProvision", String, true)
  textSurroundingOriginalProvision?: string = "";

  @JsonProperty("type", String, true)
  type?: string = "";

  @JsonProperty("uiProvisionName", String, true)
  uiProvisionName?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
