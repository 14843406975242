import { Any, JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { OwnerModel } from "@@intelease/web/common/models/drive/owner-model";

@JsonObject("MediumNodeModel")
export class MediumNodeModel {
  static view = "medium";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;
}
