import { Observable } from "rxjs";
import { EditApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EditTaskService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly TASKS_URL = "/tasks";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(private editApiService: EditApiService) {}

  updateTask<T extends object>(
    taskUid: string,
    data: any,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    // data.dueDate = data.dueDate ? new Date(data.dueDate).toISOString() : new Date().toISOString();
    return this.editApiService.sendRequest(
      EditTaskService.API_VERSION_1,
      `${EditTaskService.FINAL_DOC_SETS_URL}${EditTaskService.TASKS_URL}`,
      taskUid,
      data,
      false,
      view,
      classRef
    );
  }
}
