import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { Observable } from "rxjs";
import { EditApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EditMultiProvisionValueService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly RECORDS_URL = "/records";
  private static readonly PROVISIONS_URL = "/provisions";
  private static readonly ORDERING_URL = "/ordering";
  private static readonly STATUS_URL = "/review-status";

  constructor(private editApiService: EditApiService) {}

  updateMultiProvisionOrderNoView(
    abstractUid: string,
    provisionUid: string,
    provisionValueUids: string[]
  ): Observable<ServerResponseModel> {
    const data = {
      provisionValueUids,
    };
    return this.editApiService.sendRequestNoView(
      EditMultiProvisionValueService.API_VERSION_1,
      `${EditMultiProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}${EditMultiProvisionValueService.PROVISIONS_URL}`,
      provisionUid,
      data,
      false,
      EditMultiProvisionValueService.ORDERING_URL
    );
  }

  updateMultiProvisionStatus<T extends object>(
    abstractUid: string,
    provisionUid: string,
    status: string,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    const data = {
      reviewStatus: status,
    };
    return this.editApiService.sendRequest(
      EditMultiProvisionValueService.API_VERSION_1,
      `${EditMultiProvisionValueService.RECORDS_URL}/${abstractUid}${EditMultiProvisionValueService.PROVISIONS_URL}`,
      provisionUid,
      data,
      false,
      view,
      classRef,
      EditMultiProvisionValueService.STATUS_URL
    );
  }
}
