export enum PROVISION_BOX_TABS_KEY {
  DOCUMENT_MENTIONS = "DOCUMENT_MENTIONS",
  COMMENTS = "COMMENTS",
  NOTES = "NOTES",
}
export interface IProvisionBoxTabs {
  title: string;
  key: PROVISION_BOX_TABS_KEY;
}

export const PROVISION_BOX_TABS_CONST: IProvisionBoxTabs[] = [
  {
    title: "Document Mentions",
    key: PROVISION_BOX_TABS_KEY.DOCUMENT_MENTIONS,
  },
  {
    title: "Comments",
    key: PROVISION_BOX_TABS_KEY.COMMENTS,
  },
  {
    title: "Notes",
    key: PROVISION_BOX_TABS_KEY.NOTES,
  },
];
