<div>
  <div mat-dialog-title class="clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModalClick()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5>
      {{ modalTitle }}
    </h5>
  </div>
  <div mat-dialog-content>
    <!--        <tree-root [nodes]="nodes" [options]="options" class="tree-wrapper bold-node-text all-level-document-icon">-->
    <!--        </tree-root>-->
  </div>
  <div mat-dialog-actions>
    <button
      type="button"
      class="btn btn-primary btn-cons pull-left inline"
      (click)="onMoveFolderClick()"
    >
      Move
    </button>
    <button
      type="button"
      class="btn btn-default btn-cons pull-left inline"
      (click)="onNewFolderClick()"
    >
      NewFolder
    </button>
    <button
      type="button"
      class="btn btn-default btn-cons no-margin pull-left inline"
      (click)="onDismissModalClick()"
    >
      Cancel
    </button>
  </div>
</div>
