<div class="table-responsive data-table">
  <!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading> -->
  <table class="table table-hover" style="margin-bottom: 0">
    <thead>
      <th
        class="add-btn"
        *ngIf="config.withActions && tableActions && tableActions.add.isShow"
      >
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="onActionClick('add', {}, tableActions['add'])"
        >
          {{ tableActions.add.title }}
        </button>
      </th>
      <th
        *ngIf="config.showSelectAllBtn"
        style="
          width: 135px;
          vertical-align: middle;
          padding-bottom: 12px;
          font-weight: bold;
        "
      >
        <div class="checkbox check-default select-all">
          <input type="checkbox" checked="checked" value="1" id="check-all" />
          <label for="check-all">Select All</label>
        </div>
      </th>
      <ng-container *ngFor="let header of config.columns">
        <th *ngIf="!header.hidden">
          {{ header.name }}
        </th>
      </ng-container>
    </thead>
  </table>
  <table
    class="table table-hover"
    style="border-spacing: 0 20px; border-collapse: separate"
  >
    <tbody>
      <tr *ngFor="let item of rows; trackBy: item?.id">
        <td
          [hidden]="!config.withSelection"
          style="padding-left: 12px; width: 20px; text-align: left"
        >
          <div class="checkbox check-default">
            <input
              type="checkbox"
              checked="checked"
              value="0"
              [id]="item?.id"
            />
            <label [for]="item?.id"></label>
          </div>
        </td>
        <td class="actions" *ngIf="config.withActions && tableActions">
          <ng-container *ngFor="let actionKey of objectKeys(tableActions)">
            <button
              type="button"
              class="btn btn-light btn-sm data-table-icon"
              *ngIf="tableActions[actionKey].isShow && actionKey !== 'add'"
              (click)="onActionClick(actionKey, item, tableActions[actionKey])"
            >
              <i
                tooltip="{{ tableActions[actionKey].title }}"
                aria-label="Example icon-button with a heart icon"
                class="{{ tableActions[actionKey].icon }}"
              ></i>
            </button>
          </ng-container>
        </td>
        <td
          *ngFor="let field of item.visibleFields; trackBy: trackByField"
          [ngSwitch]="field.type"
          [ngClass]="item[field.prop]"
        >
          <span *ngSwitchCase="'string'">{{ item[field.prop] }}</span>
          <span *ngSwitchCase="'text'" tooltip="{{ item[field.prop] }}">{{
            item[field.prop]
          }}</span>
          <span *ngSwitchCase="'bool'">{{ item[field.prop] }}</span>
          <span *ngSwitchCase="'enum'">{{ item[field.prop] }}</span>
          <span *ngSwitchDefault>{{ item[field.prop] }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
