import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  HostListener,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "itls-one-column-layout",
  templateUrl: "./one-column.component.html",
  styleUrls: ["./one-column.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OneColumnComponent implements OnInit {
  @ViewChild("root", { static: true }) root;
  constructor() {}

  ngOnInit() {}
}
