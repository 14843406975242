import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ReportsService } from "@@intelease/app-services/reports";
import {
  ReportFilterFieldModel,
  ReportFilterFieldsGroupModel,
} from "@@intelease/app-models/reports";
import { ProvisionTypeEnum } from "@@intelease/app-models/provision/src";
import { FILTER_OPERATORS_CONST } from "../../../itls-sidebars/components/abstract-filter/constants";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
  selector: "il-filter-item",
  templateUrl: "./itls-filter-item.component.html",
  styleUrls: ["./itls-filter-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsFilterItemComponent implements OnInit {
  @Input() filterOperators = {};

  @Input() set filter(value) {
    this._filter = value;
    const { field } = value;
    this.filterFieldControl.setValue(field.uiName);
  }

  get filter() {
    return this._filter;
  }

  @Input() filterFields: {
    fieldName: string;
    fieldType: string;
    uiName: string;
    category: string;
    docSetCategories: string[];
  }[] = [];
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterModelChange: EventEmitter<any> = new EventEmitter<any>();
  filterFieldControl = new FormControl();
  fields: Observable<ReportFilterFieldModel[]>;

  provisionTypeEnum = ProvisionTypeEnum;
  private _filter: any = {};

  constructor(private reportsService: ReportsService) {}

  ngOnInit(): void {
    this.fields = this.filterFieldControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterItems(value))
    );
  }

  hideTooltip(tooltip: MatTooltip): void {
    if (tooltip && tooltip._overlayRef) {
      tooltip._overlayRef.detach();
    }
  }

  private _filterItems(value: string) {
    const filterValue = value.toLowerCase();
    const filteredFields = this.filterFields.filter((option) =>
      option.uiName.toLowerCase().includes(filterValue)
    );
    const items =
      this.reportsService.convertToFilterFieldsGroups(filteredFields);

    return items.flatMap((group) => {
      group.fields[0].groupTitle = group.title;
      return group.fields;
    });
  }

  onFilterFieldOptionSelected(evt, field) {
    this.filter.field = field;
    this.setFilterRelationOnOptionSelected();
    this.filterModelChange.emit(this.filter);
  }

  setFilterRelationOnOptionSelected() {
    const filterOperator = this.filterOperators[
      this.filter?.field?.fieldType
    ].find((item) => item.value === FILTER_OPERATORS_CONST[0].value);
    this.filter.relation = filterOperator.value;
    if (
      !this.filter.relation &&
      this.filterOperators[this.filter?.field?.fieldType].length > 0
    ) {
      this.filter.relation =
        this.filterOperators[this.filter?.field?.fieldType][0].value;
    }
  }

  onRemoveClick() {
    this.remove.emit();
  }

  onFilterRelationChange(evt) {
    if (
      this.filter.relation !== "FIELD_EXISTS" &&
      this.filter.relation !== "FIELD_NOT_EXISTS"
    ) {
      delete this.filter.nestedFields;
    }
    this.filterModelChange.emit(this.filter);
  }

  onFieldModelChange(evt) {
    const { model } = evt;
    this.filter.value = model;
    this.filterModelChange.emit(this.filter);
  }
}
