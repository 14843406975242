import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("MoneyProvisionModel")
export class MoneyProvisionModel {
  @JsonProperty("subValue", Number)
  subValue: number;

  @JsonProperty("majorValue", Number)
  majorValue: number;

  @JsonProperty("currency", String)
  currency = "";
}
