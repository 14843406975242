/* tslint:disable */
/* eslint-disable */
import { CreateProvisionV1ApiDtoModel } from "./create-provision-v-1-api-dto-model";
import { OApiReturnParamsRequestProvisionInfoViewTypeModel } from "./o-api-return-params-request-provision-info-view-type-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqCreateProvisionV1DtoModel")
export class OApiReqCreateProvisionV1DtoModel {
  @JsonProperty("data", CreateProvisionV1ApiDtoModel, true)
  data?: CreateProvisionV1ApiDtoModel = undefined;

  @JsonProperty(
    "returnParams",
    OApiReturnParamsRequestProvisionInfoViewTypeModel,
    true
  )
  returnParams?: OApiReturnParamsRequestProvisionInfoViewTypeModel = undefined;
}
