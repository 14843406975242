import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services";
import { ActorModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/actor.model";
import { LightUserModel } from "@@intelease/web/common/models/user";

@JsonObject("ActorPermissionModel")
export class ActorPermissionModel {
  @JsonProperty("createdAt", DateTimeConvertService)
  createdAt: Date = undefined;

  @JsonProperty("createdBy", LightUserModel)
  createdBy: LightUserModel = undefined;

  @JsonProperty("actor", ActorModel)
  actor: ActorModel = undefined;

  @JsonProperty("permissionTypeGroup", String)
  permissionTypeGroup: "OWNER" | "EDITOR" | "COMMENTER" | "VIEWER" | undefined =
    undefined;

  constructor(data?: Partial<ActorPermissionModel>) {
    Object.assign(this, data);
  }
}
