/* tslint:disable */
/* eslint-disable */
import { MergeBindersRequestDtoModel } from "./merge-binders-request-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqMergeBindersRequestDtoModel")
export class OApiReqMergeBindersRequestDtoModel {
  @JsonProperty("data", MergeBindersRequestDtoModel, true)
  data?: MergeBindersRequestDtoModel = undefined;
}
