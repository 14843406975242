import { WebAbstractionProvisionService } from "@@intelease/web/abstraction-page/src/lib/services/web-abstraction-provision.service";
import { DestroyRef, inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
// TODO fix this import
import { environment } from "../../../../../../apps/ui/src/environments/environment";
import {
  FullProvisionGroupModel,
  FullProvisionModel,
  FullValProvisionValueModel,
  PartialProvisionValueModel,
  PartialValProvisionValueModel,
  ProvisionFormAbstractModel,
  RelatedDocsAbstractModel,
  TableProvisionModel,
} from "@@intelease/web/common/models";
import { HighlightedProvisionValueModel } from "@@intelease/web/abstraction-page/src/lib/models";
import { cloneDeep, values, last, first, flatten, filter, chain } from "lodash";
import {
  FetchApiService,
  UserInfoService,
} from "@@intelease/web/intelease/services";
import { MinimalProvisionModel } from "@@intelease/web/common/models/provision/minimal-provision.model";
import {
  ExportMultiNodeModel,
  ListResponseModel,
  ServerResponseModel,
} from "@@intelease/web/intelease/models";
import {
  FullValMultiProvisionValueModel,
  SearchMultiProvisionValueModel,
} from "@@intelease/web/common/models/multi-provision-value";
import { FileApiService } from "@@intelease/web/intelease/services/backend";
import { FetchAbstractService } from "@@intelease/web/intelease/services/models/doc-abstract/fetch/fetch-abstract.service";
import {
  EditAbstractService,
  FetchMultiProvisionValueService,
} from "@@intelease/web/intelease/services/models";
import { SearchProvisionService } from "@@intelease/web/intelease/services/models/provision/search/search-provision.service";
import { EditProvisionFormService } from "@@intelease/web/intelease/services/models/provision-form/edit/edit-provision-form.service";
import { HttpClient } from "@angular/common/http";
import {
  CalculatorInterface,
  ProvisionBoxEventInterface,
  ProvisionBoxSizeInterface,
  ProvisionValueFromTextHighlightInterface,
} from "../interfaces";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import { TextHighlightTypeEnum } from "@@intelease/app-models/common";
import { BreadcrumbItemModel } from "@@intelease/web/intelease/components/toolbar/breadcrumb";
import { BreadcrumbItemTypeEnum } from "@@intelease/web/intelease/components/toolbar/breadcrumb/enums";
import {
  AbstractReviewOperationModeEnum,
  AbstractReviewOperationModeModel,
  AppHighlightTypeEnum,
} from "@@intelease/app-models/abstract-review";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision";
import { LocalStorageKey } from "@@intelease/web/common/enums/local-storage.keys";
import {
  DocSetCategoryViewModel,
  FullValMultiPdfProvViewModel,
  NodeNavigationResultModel,
  PartialValPdfProvisionViewModel,
  RelatedDocumentModel,
} from "@@intelease/api-models/adex-api-model-src";
import {
  MentionPosition,
  ProvisionOptionSourceEnum,
} from "@@intelease/web/abstract-review/src/lib/models/provision-review.model";
import { DriveNodeType } from "@@intelease/app-state/drive/src";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class WebAbstractionPageService {
  constructor(
    private httpClient: HttpClient,
    private fetchApiService: FetchApiService,
    private fileApiService: FileApiService,
    private fetchAbstractService: FetchAbstractService,
    private editAbstractService: EditAbstractService,
    private searchProvisionService: SearchProvisionService,
    private editProvisionFormService: EditProvisionFormService,
    private userInfoService: UserInfoService,
    private webabstractionProvisionService: WebAbstractionProvisionService,
    private fetchMultiProvisionValueService: FetchMultiProvisionValueService,
    private _notification: MessageService
  ) {}

  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly DOCUMENTS_URL = "/documents";
  private static readonly DOC_PDF_VIEWS_URL = "/docPdfViews";
  private static readonly TSV_FORMAT_URL = "/tsv";

  private static readonly MIN_VERTICAL_SPACE_PIXEL_DIFF = 5;
  private static readonly MIN_HORIZONTAL_SPACE_PIXEL_DIFF = 30;

  private static name1 = [
    "Black",
    "White",
    "Gray",
    "Brown",
    "Red",
    "Pink",
    "Crimson",
    "Carnelian",
    "Orange",
    "Yellow",
    "Ivory",
    "Cream",
    "Green",
    "Viridian",
    "Aquamarine",
    "Cyan",
    "Blue",
    "Cerulean",
    "Azure",
    "Indigo",
    "Navy",
    "Violet",
    "Purple",
    "Lavender",
    "Magenta",
    "Rainbow",
    "Iridescent",
    "Spectrum",
    "Prism",
    "Bold",
    "Vivid",
    "Pale",
    "Clear",
    "Glass",
    "Translucent",
    "Misty",
    "Dark",
    "Light",
    "Gold",
    "Silver",
    "Copper",
    "Bronze",
    "Steel",
    "Iron",
    "Brass",
    "Mercury",
    "Zinc",
    "Chrome",
    "Platinum",
    "Titanium",
    "Nickel",
    "Lead",
    "Pewter",
    "Rust",
    "Metal",
    "Stone",
    "Quartz",
    "Granite",
    "Marble",
    "Alabaster",
    "Agate",
    "Jasper",
    "Pebble",
    "Pyrite",
    "Crystal",
    "Geode",
    "Obsidian",
    "Mica",
    "Flint",
    "Sand",
    "Gravel",
    "Boulder",
    "Basalt",
    "Ruby",
    "Beryl",
    "Scarlet",
    "Citrine",
    "Sulpher",
    "Topaz",
    "Amber",
    "Emerald",
    "Malachite",
    "Jade",
    "Abalone",
    "Lapis",
    "Sapphire",
    "Diamond",
    "Peridot",
    "Gem",
    "Jewel",
    "Bevel",
    "Coral",
    "Jet",
    "Ebony",
    "Wood",
    "Tree",
    "Cherry",
    "Maple",
    "Cedar",
    "Branch",
    "Bramble",
    "Rowan",
    "Ash",
    "Fir",
    "Pine",
    "Cactus",
    "Alder",
    "Grove",
    "Forest",
    "Jungle",
    "Palm",
    "Bush",
    "Mulberry",
    "Juniper",
    "Vine",
    "Ivy",
    "Rose",
    "Lily",
    "Tulip",
    "Daffodil",
    "Honeysuckle",
    "Fuschia",
    "Hazel",
    "Walnut",
    "Almond",
    "Lime",
    "Lemon",
    "Apple",
    "Blossom",
    "Bloom",
    "Crocus",
    "Rose",
    "Buttercup",
    "Dandelion",
    "Iris",
    "Carnation",
    "Fern",
    "Root",
    "Branch",
    "Leaf",
    "Seed",
    "Flower",
    "Petal",
    "Pollen",
    "Orchid",
    "Mangrove",
    "Cypress",
    "Sequoia",
    "Sage",
    "Heather",
    "Snapdragon",
    "Daisy",
    "Mountain",
    "Hill",
    "Alpine",
    "Chestnut",
    "Valley",
    "Glacier",
    "Forest",
    "Grove",
    "Glen",
    "Tree",
    "Thorn",
    "Stump",
    "Desert",
    "Canyon",
    "Dune",
    "Oasis",
    "Mirage",
    "Well",
    "Spring",
    "Meadow",
    "Field",
    "Prairie",
    "Grass",
    "Tundra",
    "Island",
    "Shore",
    "Sand",
    "Shell",
    "Surf",
    "Wave",
    "Foam",
    "Tide",
    "Lake",
    "River",
    "Brook",
    "Stream",
    "Pool",
    "Pond",
    "Sun",
    "Sprinkle",
    "Shade",
    "Shadow",
    "Rain",
    "Cloud",
    "Storm",
    "Hail",
    "Snow",
    "Sleet",
    "Thunder",
    "Lightning",
    "Wind",
    "Hurricane",
    "Typhoon",
    "Dawn",
    "Sunrise",
    "Morning",
    "Noon",
    "Twilight",
    "Evening",
    "Sunset",
    "Midnight",
    "Night",
    "Sky",
    "Star",
    "Stellar",
    "Comet",
    "Nebula",
    "Quasar",
    "Solar",
    "Lunar",
    "Planet",
    "Meteor",
    "Sprout",
    "Pear",
    "Plum",
    "Kiwi",
    "Berry",
    "Apricot",
    "Peach",
    "Mango",
    "Pineapple",
    "Coconut",
    "Olive",
    "Ginger",
    "Root",
    "Plain",
    "Fancy",
    "Stripe",
    "Spot",
    "Speckle",
    "Spangle",
    "Ring",
    "Band",
    "Blaze",
    "Paint",
    "Pinto",
    "Shade",
    "Tabby",
    "Brindle",
    "Patch",
    "Calico",
    "Checker",
    "Dot",
    "Pattern",
    "Glitter",
    "Glimmer",
    "Shimmer",
    "Dull",
    "Dust",
    "Dirt",
    "Glaze",
    "Scratch",
    "Quick",
    "Swift",
    "Fast",
    "Slow",
    "Clever",
    "Fire",
    "Flicker",
    "Flash",
    "Spark",
    "Ember",
    "Coal",
    "Flame",
    "Chocolate",
    "Vanilla",
    "Sugar",
    "Spice",
    "Cake",
    "Pie",
    "Cookie",
    "Candy",
    "Caramel",
    "Spiral",
    "Round",
    "Jelly",
    "Square",
    "Narrow",
    "Long",
    "Short",
    "Small",
    "Tiny",
    "Big",
    "Giant",
    "Great",
    "Atom",
    "Peppermint",
    "Mint",
    "Butter",
    "Fringe",
    "Rag",
    "Quilt",
    "Truth",
    "Lie",
    "Holy",
    "Curse",
    "Noble",
    "Sly",
    "Brave",
    "Shy",
    "Lava",
    "Foul",
    "Leather",
    "Fantasy",
    "Keen",
    "Luminous",
    "Feather",
    "Sticky",
    "Gossamer",
    "Cotton",
    "Rattle",
    "Silk",
    "Satin",
    "Cord",
    "Denim",
    "Flannel",
    "Plaid",
    "Wool",
    "Linen",
    "Silent",
    "Flax",
    "Weak",
    "Valiant",
    "Fierce",
    "Gentle",
    "Rhinestone",
    "Splash",
    "North",
    "South",
    "East",
    "West",
    "Summer",
    "Winter",
    "Autumn",
    "Spring",
    "Season",
    "Equinox",
    "Solstice",
    "Paper",
    "Motley",
    "Torch",
    "Ballistic",
    "Rampant",
    "Shag",
    "Freckle",
    "Wild",
    "Free",
    "Chain",
    "Sheer",
    "Crazy",
    "Mad",
    "Candle",
    "Ribbon",
    "Lace",
    "Notch",
    "Wax",
    "Shine",
    "Shallow",
    "Deep",
    "Bubble",
    "Harvest",
    "Fluff",
    "Venom",
    "Boom",
    "Slash",
    "Rune",
    "Cold",
    "Quill",
    "Love",
    "Hate",
    "Garnet",
    "Zircon",
    "Power",
    "Bone",
    "Void",
    "Horn",
    "Glory",
    "Cyber",
    "Nova",
    "Hot",
    "Helix",
    "Cosmic",
    "Quark",
    "Quiver",
    "Holly",
    "Clover",
    "Polar",
    "Regal",
    "Ripple",
    "Ebony",
    "Wheat",
    "Phantom",
    "Dew",
    "Chisel",
    "Crack",
    "Chatter",
    "Laser",
    "Foil",
    "Tin",
    "Clever",
    "Treasure",
    "Maze",
    "Twisty",
    "Curly",
    "Fortune",
    "Fate",
    "Destiny",
    "Cute",
    "Slime",
    "Ink",
    "Disco",
    "Plume",
    "Time",
    "Psychadelic",
    "Relic",
    "Fossil",
    "Water",
    "Savage",
    "Ancient",
    "Rapid",
    "Road",
    "Trail",
    "Stitch",
    "Button",
    "Bow",
    "Nimble",
    "Zest",
    "Sour",
    "Bitter",
    "Phase",
    "Fan",
    "Frill",
    "Plump",
    "Pickle",
    "Mud",
    "Puddle",
    "Pond",
    "River",
    "Spring",
    "Stream",
    "Battle",
    "Arrow",
    "Plume",
    "Roan",
    "Pitch",
    "Tar",
    "Cat",
    "Dog",
    "Horse",
    "Lizard",
    "Bird",
    "Fish",
    "Saber",
    "Scythe",
    "Sharp",
    "Soft",
    "Razor",
    "Neon",
    "Dandy",
    "Weed",
    "Swamp",
    "Marsh",
    "Bog",
    "Peat",
    "Moor",
    "Muck",
    "Mire",
    "Grave",
    "Fair",
    "Just",
    "Brick",
    "Puzzle",
    "Skitter",
    "Prong",
    "Fork",
    "Dent",
    "Dour",
    "Warp",
    "Luck",
    "Coffee",
    "Split",
    "Chip",
    "Hollow",
    "Heavy",
    "Legend",
    "Hickory",
    "Mesquite",
    "Nettle",
    "Rogue",
    "Charm",
    "Prickle",
    "Bead",
    "Sponge",
    "Whip",
    "Bald",
    "Frost",
    "Fog",
    "Oil",
    "Veil",
    "Cliff",
    "Volcano",
    "Rift",
    "Maze",
    "Proud",
    "Dew",
    "Mirror",
    "Shard",
    "Salt",
    "Pepper",
    "Honey",
    "Thread",
    "Bristle",
    "Ripple",
    "Glow",
    "Zenith",
  ];
  private static name2 = [
    "head",
    "crest",
    "crown",
    "tooth",
    "fang",
    "horn",
    "frill",
    "skull",
    "bone",
    "tongue",
    "throat",
    "voice",
    "nose",
    "snout",
    "chin",
    "eye",
    "sight",
    "seer",
    "speaker",
    "singer",
    "song",
    "chanter",
    "howler",
    "chatter",
    "shrieker",
    "shriek",
    "jaw",
    "bite",
    "biter",
    "neck",
    "shoulder",
    "fin",
    "wing",
    "arm",
    "lifter",
    "grasp",
    "grabber",
    "hand",
    "paw",
    "foot",
    "finger",
    "toe",
    "thumb",
    "talon",
    "palm",
    "touch",
    "racer",
    "runner",
    "hoof",
    "fly",
    "flier",
    "swoop",
    "roar",
    "hiss",
    "hisser",
    "snarl",
    "dive",
    "diver",
    "rib",
    "chest",
    "back",
    "ridge",
    "leg",
    "legs",
    "tail",
    "beak",
    "walker",
    "lasher",
    "swisher",
    "carver",
    "kicker",
    "roarer",
    "crusher",
    "spike",
    "shaker",
    "charger",
    "hunter",
    "weaver",
    "crafter",
    "binder",
    "scribe",
    "muse",
    "snap",
    "snapper",
    "slayer",
    "stalker",
    "track",
    "tracker",
    "scar",
    "scarer",
    "fright",
    "killer",
    "death",
    "doom",
    "healer",
    "saver",
    "friend",
    "foe",
    "guardian",
    "thunder",
    "lightning",
    "cloud",
    "storm",
    "forger",
    "scale",
    "hair",
    "braid",
    "nape",
    "belly",
    "thief",
    "stealer",
    "reaper",
    "giver",
    "taker",
    "dancer",
    "player",
    "gambler",
    "twister",
    "turner",
    "painter",
    "dart",
    "drifter",
    "sting",
    "stinger",
    "venom",
    "spur",
    "ripper",
    "swallow",
    "devourer",
    "knight",
    "lady",
    "lord",
    "queen",
    "king",
    "master",
    "mistress",
    "prince",
    "princess",
    "duke",
    "dutchess",
    "samurai",
    "ninja",
    "knave",
    "slave",
    "servant",
    "sage",
    "wizard",
    "witch",
    "warlock",
    "warrior",
    "jester",
    "paladin",
    "bard",
    "trader",
    "sword",
    "shield",
    "knife",
    "dagger",
    "arrow",
    "bow",
    "fighter",
    "bane",
    "follower",
    "leader",
    "scourge",
    "watcher",
    "cat",
    "panther",
    "tiger",
    "cougar",
    "puma",
    "jaguar",
    "ocelot",
    "lynx",
    "lion",
    "leopard",
    "ferret",
    "weasel",
    "wolverine",
    "bear",
    "raccoon",
    "dog",
    "wolf",
    "kitten",
    "puppy",
    "cub",
    "fox",
    "hound",
    "terrier",
    "coyote",
    "hyena",
    "jackal",
    "pig",
    "horse",
    "donkey",
    "stallion",
    "mare",
    "zebra",
    "antelope",
    "gazelle",
    "deer",
    "buffalo",
    "bison",
    "boar",
    "elk",
    "whale",
    "dolphin",
    "shark",
    "fish",
    "minnow",
    "salmon",
    "ray",
    "fisher",
    "otter",
    "gull",
    "duck",
    "goose",
    "crow",
    "raven",
    "bird",
    "eagle",
    "raptor",
    "hawk",
    "falcon",
    "moose",
    "heron",
    "owl",
    "stork",
    "crane",
    "sparrow",
    "robin",
    "parrot",
    "cockatoo",
    "carp",
    "lizard",
    "gecko",
    "iguana",
    "snake",
    "python",
    "viper",
    "boa",
    "condor",
    "vulture",
    "spider",
    "fly",
    "scorpion",
    "heron",
    "oriole",
    "toucan",
    "bee",
    "wasp",
    "hornet",
    "rabbit",
    "bunny",
    "hare",
    "brow",
    "mustang",
    "ox",
    "piper",
    "soarer",
    "flasher",
    "moth",
    "mask",
    "hide",
    "hero",
    "antler",
    "chill",
    "chiller",
    "gem",
    "ogre",
    "myth",
    "elf",
    "fairy",
    "pixie",
    "dragon",
    "griffin",
    "unicorn",
    "pegasus",
    "sprite",
    "fancier",
    "chopper",
    "slicer",
    "skinner",
    "butterfly",
    "legend",
    "wanderer",
    "rover",
    "raver",
    "loon",
    "lancer",
    "glass",
    "glazer",
    "flame",
    "crystal",
    "lantern",
    "lighter",
    "cloak",
    "bell",
    "ringer",
    "keeper",
    "centaur",
    "bolt",
    "catcher",
    "whimsey",
    "quester",
    "rat",
    "mouse",
    "serpent",
    "wyrm",
    "gargoyle",
    "thorn",
    "whip",
    "rider",
    "spirit",
    "sentry",
    "bat",
    "beetle",
    "burn",
    "cowl",
    "stone",
    "gem",
    "collar",
    "mark",
    "grin",
    "scowl",
    "spear",
    "razor",
    "edge",
    "seeker",
    "jay",
    "ape",
    "monkey",
    "gorilla",
    "koala",
    "kangaroo",
    "yak",
    "sloth",
    "ant",
    "roach",
    "weed",
    "seed",
    "eater",
    "razor",
    "shirt",
    "face",
    "goat",
    "mind",
    "shift",
    "rider",
    "face",
    "mole",
    "vole",
    "pirate",
    "llama",
    "stag",
    "bug",
    "cap",
    "boot",
    "drop",
    "hugger",
    "sargent",
    "snagglefoot",
    "carpet",
    "curtain",
  ];
  public static provisionBoxEventsSubject$: Subject<ProvisionBoxEventInterface> =
    new Subject<ProvisionBoxEventInterface>();
  // TODO(moh): This should be obtained from the settings, when user logs in.
  public static IS_DUAL_WINDOW_MODE = true;
  public static IS_DUAL_WINDOW_OPEN = false;
  public static sendSelectedDocumentMessageToDualWindow$: Subject<any> =
    new Subject<any>();
  public static sendSelectedDocumentFullyLoadedMessageToDualWindow$: Subject<boolean> =
    new Subject<boolean>();
  public static sendInitialWindowMessageToDualWindow$: Subject<any> =
    new Subject<any>();
  public static sendReinitializeWindowMessageToDualWindow$: Subject<any> =
    new Subject<any>();
  public static detachCurrentProvisionBoxInNewWindow$: Subject<void> =
    new Subject<void>();
  public static openNewWindowByFirstProvisionGroup$: Subject<any> =
    new Subject<any>();

  destroyRef = inject(DestroyRef);

  private provisionOptionsHighlightMap: {
    [provisionUid: string]: string;
  } = {};
  private highlightMap: { [highlightId: string]: string } = {};
  private searchSelectedMultiProvisionValue: PartialProvisionValueModel;
  newAnnotation$: Subject<FullValProvisionValueModel> =
    new Subject<FullValProvisionValueModel>();
  refreshProvisionBox$: Subject<string> = new Subject<string>();
  onAnnotationClick$: Subject<string> = new Subject<string>();
  onDeleteHighlightFromReader$: Subject<{
    annotationId: string;
    annotation: any;
  }> = new Subject<{ annotationId: string; annotation: any }>();
  onScrollToSearchSelectedMultiProvisionValue$: Subject<PartialProvisionValueModel> =
    new Subject<PartialProvisionValueModel>();
  onNewAbstractProvisionGroups: Subject<FullProvisionGroupModel[]> =
    new Subject<FullProvisionGroupModel[]>();

  onScrollToAnnotationClick$: Subject<{
    annotationId: string;
    page: number;
  }> = new Subject<{ annotationId: string; page: number }>();
  onGoToAnnotationByDocumentUidClick$: Subject<PartialValProvisionValueModel> =
    new Subject<PartialValProvisionValueModel>();
  openProvisionBoxInNewWindow$: Subject<any> = new Subject<any>();
  onDualWindowChange$: Subject<boolean> = new Subject<boolean>();
  onReviewOperationModeChange$: Subject<AbstractReviewOperationModeModel> =
    new Subject<AbstractReviewOperationModeModel>();

  private static capFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  private static getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  public static filterProvisionGroupsBySearchTerm(
    searchTerm: string,
    provisionGroupList: any
  ): any {
    const items: FullProvisionGroupModel[] = [];
    const provisionGroupListClone = cloneDeep(provisionGroupList);
    provisionGroupListClone.provisionGroupList.forEach((provisionGroup) => {
      const provisionList: FullProvisionModel[] = [];
      provisionGroup.provisions.forEach((provision) => {
        if (
          provision.provisionInfo.name
            .trim()
            .toLowerCase()
            .indexOf(searchTerm) !== -1
        ) {
          provisionList.push(provision);
        }
      });
      if (provisionList.length) {
        provisionGroup.provisions = provisionList;
        items.push(provisionGroup);
      }
    });
    provisionGroupListClone.provisionGroupList = items;
    return provisionGroupListClone;
  }

  public static filterProvisionByHtmlName(
    htmlName: string,
    provisionGroupList: FullProvisionGroupModel[]
  ): FullProvisionModel {
    let foundedProvision;
    for (const provisionGroup of provisionGroupList) {
      foundedProvision = provisionGroup?.provisions?.find(
        (provision) => provision.htmlName === htmlName
      );
      if (foundedProvision) {
        break;
      }
    }
    return foundedProvision;
  }

  public static filterCommentsBySearchTerm(
    searchTerm: string,
    bookMarkList: any[]
  ): any[] {
    return values(
      chain(
        flatten(bookMarkList).filter(
          (bookMark) =>
            bookMark.itlsData &&
            bookMark.itlsData.type === "BOOKMARK" &&
            bookMark.itlsData.bookmarkData &&
            bookMark.itlsData.bookmarkData.value
              .trim()
              .toLowerCase()
              .indexOf(searchTerm) !== -1
        )
      )
        .groupBy("page")
        .value()
    );
  }

  public static filterNoneAnnotations(annotations) {
    for (const pageMetaId of Object.keys(annotations.pageMetas)) {
      for (const textHighlightId of Object.keys(
        annotations.pageMetas[pageMetaId]["textHighlights"]
      )) {
        const itlsData =
          annotations.pageMetas[pageMetaId]["textHighlights"][textHighlightId][
            "itlsData"
          ];
        if (
          itlsData["type"] === "PROVISION" &&
          itlsData["highlightType"] === "NONE"
        ) {
          delete annotations.pageMetas[pageMetaId]["textHighlights"][
            textHighlightId
          ];
        }
      }
    }
    return annotations;
  }

  public static filterNoneAndLightAnnotations(annotations) {
    for (const pageMetaId of Object.keys(annotations.pageMetas)) {
      this.prepareAnnotationsForReader(
        annotations,
        pageMetaId,
        "textHighlights"
      );
      this.prepareAnnotationsForReader(
        annotations,
        pageMetaId,
        "areaHighlights"
      );
    }
    return annotations;
  }

  private static prepareAnnotationsForReader(
    annotations,
    pageMetaId,
    highlightKey: "textHighlights" | "areaHighlights"
  ) {
    for (const highlightId of Object.keys(
      annotations.pageMetas[pageMetaId][highlightKey]
    )) {
      const itlsData =
        annotations.pageMetas[pageMetaId][highlightKey][highlightId][
          "itlsData"
        ];
      annotations.pageMetas[pageMetaId][highlightKey][highlightId][
        "created"
      ] = 0;
      if (
        itlsData["type"] === "PROVISION" &&
        (itlsData["highlightType"] === "NONE" ||
          itlsData["highlightType"] === "LIGHT")
      ) {
        delete annotations.pageMetas[pageMetaId][highlightKey][highlightId];
      }
    }
  }

  private static mapAnnotationsToHighlightList(
    annotationList,
    highlightKey: "textHighlights" | "areaHighlights"
  ) {
    return Object.keys(annotationList.pageMetas).map((pageIndex) => {
      return Object.keys(annotationList.pageMetas[pageIndex][highlightKey]).map(
        (highlightId) => {
          annotationList.pageMetas[pageIndex][highlightKey][highlightId][
            "page"
          ] = pageIndex;
          return annotationList.pageMetas[pageIndex][highlightKey][highlightId];
        }
      );
    });
  }

  public static generateDocumentsShortName(documents: RelatedDocumentModel[]) {
    const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const documentsMap = {};
    const documentsShortNameMap = {};
    documents.forEach((document, index) => {
      const shortLabel = alphabets[index];
      documentsMap[document.uid] = {
        shortLabel: shortLabel,
        ...document,
        name: document.name.replace(".pdf", ""),
      };
      documentsShortNameMap[document.uid] = shortLabel;
    });
    return {
      documentsMap,
      documentsShortNameMap,
    };
  }

  public static getProvisionOptionPrevAndPostMentionUid(
    provision: FullValMultiPdfProvViewModel,
    mention: PartialValPdfProvisionViewModel
  ): MentionPosition {
    const mentionIndex = provision.multiplePdfProvision.options.findIndex(
      (option) => option.uid === mention.uid
    );
    const prevProvisionMention = provision.multiplePdfProvision.options
      .slice(0, mentionIndex)
      .reverse()
      .find((option) => option.source !== ProvisionOptionSourceEnum.TEMP_LOCAL);
    const postProvisionMention = provision.multiplePdfProvision.options
      .slice(mentionIndex)
      .find((option) => option.source !== ProvisionOptionSourceEnum.TEMP_LOCAL);

    return {
      preMentionUid: prevProvisionMention?.uid,
      postMentionUid: postProvisionMention?.uid,
    };
  }

  public getRandomFakeName(): string {
    return (
      WebAbstractionPageService.capFirst(
        WebAbstractionPageService.name1[
          WebAbstractionPageService.getRandomInt(
            0,
            WebAbstractionPageService.name1.length + 1
          )
        ]
      ) +
      " " +
      WebAbstractionPageService.capFirst(
        WebAbstractionPageService.name2[
          WebAbstractionPageService.getRandomInt(
            0,
            WebAbstractionPageService.name2.length + 1
          )
        ]
      )
    );
  }

  updateAbstractProvisionForm(
    abstractUid: string,
    provisionFormUid: string,
    newProvisionUid: string,
    chosenProvisionGroupUid: string,
    preProvisionLocationUid: string,
    postProvisionLocationUid: string
  ): Observable<ServerResponseModel> {
    return this.editProvisionFormService.updateAbstractProvisionFormNoView(
      abstractUid,
      provisionFormUid,
      newProvisionUid,
      chosenProvisionGroupUid,
      preProvisionLocationUid,
      postProvisionLocationUid
    );
  }

  addProvisionToAbstractNoView(
    abstractUid: string,
    newProvisionUid: string,
    chosenProvisionGroupUid: string,
    preProvisionLocationUid: string,
    postProvisionLocationUid: string
  ): Observable<ServerResponseModel> {
    return this.editProvisionFormService.addProvisionToAbstractNoView(
      abstractUid,
      newProvisionUid,
      chosenProvisionGroupUid,
      preProvisionLocationUid,
      postProvisionLocationUid
    );
  }

  getNewPossibleProvisions(
    abstractUid: string,
    docSetCategory: DocSetCategoryViewModel
  ): Observable<ListResponseModel<MinimalProvisionModel>> {
    return this.searchProvisionService.getNewPossibleProvisions(
      abstractUid,
      docSetCategory,
      MinimalProvisionModel.view,
      MinimalProvisionModel
    );
  }

  saveAbstract(
    abstractUid: string,
    abstractName: string,
    deselectIncomplete: boolean
  ): Observable<ServerResponseModel> {
    return this.editAbstractService.saveAbstractNoView(
      abstractUid,
      abstractName,
      deselectIncomplete,
      "COMPLETED"
    );
  }

  getRelatedDocuments(
    abstractUid: string
  ): Observable<RelatedDocsAbstractModel> {
    return this.fetchAbstractService.getAbstractView(
      abstractUid,
      RelatedDocsAbstractModel.view,
      RelatedDocsAbstractModel
    );
  }

  getDefaultDocumentUrl(abstractUid: string): string {
    return (
      `${environment.appUrl}${environment.apiUrlPart}${WebAbstractionPageService.API_VERSION_1}` +
      `${WebAbstractionPageService.FINAL_DOC_SETS_URL}/${abstractUid}/documents/default`
    );
    // return '/assets/pdf-reader/web/datacenter-as-a-computer.pdf'
  }

  getDocumentUrl(abstractUid: string, docUid: string, type?: "sync"): string {
    if (type === "sync") {
      return `${WebAbstractionPageService.API_VERSION_1}${WebAbstractionPageService.FINAL_DOC_SETS_URL}/${abstractUid}${WebAbstractionPageService.DOCUMENTS_URL}/${docUid}`;
    }
    return `${environment.appUrl}${environment.apiUrlPart}${WebAbstractionPageService.API_VERSION_1}${WebAbstractionPageService.FINAL_DOC_SETS_URL}/${abstractUid}${WebAbstractionPageService.DOCUMENTS_URL}/${docUid}`;
  }

  getAbstractAnnotations(
    docUid: string,
    abstractUid: string,
    params?: {
      pageNums?: string[];
      provisionUids?: string[];
      provisionValueUids?: string[];
    }
  ): Observable<any> {
    // TODO(apoorv): Input to this should be the docPdfViewUid instead of docUid (once all the abstracts have this populated).
    return this.fetchApiService.sendUnknownEntityRequest(
      WebAbstractionPageService.API_VERSION_1,
      `${WebAbstractionPageService.DOC_PDF_VIEWS_URL}/${docUid}/abstract`,
      abstractUid,
      params
    );
  }

  createProvisionHighlightMap(actualDocPdfView: any, state: "initialized") {
    if (state === "initialized") {
      this.provisionOptionsHighlightMap = {};
      this.highlightMap = {};
    }
    Object.values(actualDocPdfView.pageMetas).map((pageMeta: any) => {
      this._processProvisionHighlightMap(
        pageMeta.textHighlights,
        pageMeta,
        AppHighlightTypeEnum.TEXT
      );
      this._processProvisionHighlightMap(
        pageMeta.areaHighlights,
        pageMeta,
        AppHighlightTypeEnum.AREA
      );
    });
  }

  private _processProvisionHighlightMap(
    _highlights,
    pageMeta,
    highlightType: AppHighlightTypeEnum
  ) {
    Object.values(_highlights).map((highlight: any) => {
      if (
        (highlight && highlight.itlsData && highlight.itlsData.uid) ||
        (highlight &&
          highlight.itlsData &&
          highlight &&
          highlight.itlsData.type === TextHighlightTypeEnum.COMMENT) ||
        (highlight &&
          highlight.itlsData &&
          highlight &&
          highlight.itlsData.type === TextHighlightTypeEnum.BOOKMARK)
      ) {
        highlight["created"] = 0;
        highlight["page"] = pageMeta.pageInfo.num;
        this.highlightMap[highlight.id] = {
          ...highlight,
          highlightType,
        };
        // mentions can have highlights across pages, we point mention's location to first highlight for first page
        //   (so if there is an entry in the map, we already picked first highlight for first page)
        if (
          highlight.itlsData.type !== TextHighlightTypeEnum.BOOKMARK &&
          highlight.itlsData.type !== TextHighlightTypeEnum.COMMENT
        ) {
          if (!this.provisionOptionsHighlightMap[highlight.itlsData.uid]) {
            this.provisionOptionsHighlightMap[highlight.itlsData.uid] = {
              ...highlight,
              highlightType,
            };
          }
        }
      }
    });
  }

  addHighlight(highlight, highlightType): void {
    this.highlightMap[highlight.id] = {
      ...highlight,
      highlightType,
    };
  }

  /**
   * get textHighlight by Mention.uid
   * @param provisionOptionUid
   */
  getHighlightMetaByProvisionOptionUid(provisionOptionUid: string) {
    return this.provisionOptionsHighlightMap[provisionOptionUid];
  }

  getHighlightMetaByHighlightId(highlightId: string): any {
    return this.highlightMap[highlightId];
  }

  getHighlightsMetaByHighlightIds(highlightIds: string[]): any[] {
    return highlightIds.map((highlightId) => this.highlightMap[highlightId]);
  }

  // setHighlightMetaByProvisionOptionUid(provisionOptionUid: string) {
  //   return this.provisionOptionsHighlightMap[provisionOptionUid];
  // }
  //
  // setHighlightMetaByHighlightId(pageNum: number, highlightId: string, highlightData: any): any {
  //   this.highlightMap[highlightId] = highlightData;
  // }

  getProvisionCategories(
    abstractUid: string
  ): Observable<ProvisionFormAbstractModel> {
    return this.fetchAbstractService.getAbstractView(
      abstractUid,
      ProvisionFormAbstractModel.view,
      ProvisionFormAbstractModel
    );
  }

  getMultiProvisionValueByProvisionUid(
    abstractUid: string,
    provisionUid: string
  ): Observable<SearchMultiProvisionValueModel> {
    return this.fetchMultiProvisionValueService.getMultiProvisionValueView(
      abstractUid,
      provisionUid,
      SearchMultiProvisionValueModel.view,
      SearchMultiProvisionValueModel
    );
  }

  getLastTextHighlight(textHighlights, provisionId: string) {
    for (const textHighlight of textHighlights) {
      if (
        textHighlight.itlsData &&
        textHighlight.itlsData.type &&
        textHighlight.itlsData.type === "PROVISION" &&
        textHighlight.itlsData.provisionData.provisionUid === provisionId
      ) {
        return textHighlight;
      }
    }
  }

  private extractHighlightText(highlight: any) {
    let text = "";
    let previousTextSelectionRect;
    for (const key of Object.keys(highlight.textSelections)) {
      const textSelection = highlight.textSelections[key];
      const textSelectionRect = textSelection["rect"];
      if (
        this.shouldAddSpaceBetweenRects(
          previousTextSelectionRect,
          textSelectionRect
        )
      ) {
        text += " ";
      }
      text += textSelection["text"];
      previousTextSelectionRect = textSelectionRect;
    }
    return text;
  }

  private shouldAddSpaceBetweenRects(
    previousTextSelectionRect: any,
    textSelectionRect: any
  ): boolean {
    if (!previousTextSelectionRect) {
      return false;
    }
    return (
      this.shouldAddSpaceBecauseVertical(
        previousTextSelectionRect,
        textSelectionRect
      ) ||
      this.shouldAddSpaceBecauseHorizontal(
        previousTextSelectionRect,
        textSelectionRect
      )
    );
  }

  private shouldAddSpaceBecauseVertical(
    previousTextSelectionRect: any,
    textSelectionRect: any
  ): boolean {
    if (!previousTextSelectionRect.top || !previousTextSelectionRect.bottom) {
      return false;
    }
    return (
      Math.abs(textSelectionRect["top"] - previousTextSelectionRect.top) >
        WebAbstractionPageService.MIN_VERTICAL_SPACE_PIXEL_DIFF &&
      Math.abs(textSelectionRect["bottom"] - previousTextSelectionRect.bottom) >
        WebAbstractionPageService.MIN_VERTICAL_SPACE_PIXEL_DIFF
    );
  }

  private shouldAddSpaceBecauseHorizontal(
    previousTextSelectionRect: any,
    textSelectionRect: any
  ): boolean {
    if (!previousTextSelectionRect.right) {
      return false;
    }
    return (
      Math.abs(textSelectionRect["left"] - previousTextSelectionRect.right) >
      WebAbstractionPageService.MIN_HORIZONTAL_SPACE_PIXEL_DIFF
    );
  }

  private prepareProvisionValueFromSinglePageHighlight(
    evt: any,
    mode: string
  ): ProvisionValueFromTextHighlightInterface {
    const { isAreaHighlight } = evt.extraParams;
    const firstPage = evt.annotationData.pageInfo.num;
    const lastPage = evt.annotationData.pageInfo.num;
    let text = "";
    let provisionHighlight: any = "";
    const pdfProvisionHighlights: any[] = [];
    if (mode === "ADD") {
      // TODO: check which one is better and has more accuracy
      // provisionHighlight = this.getLastTextHighlight(evt.annotationData.textHighlights, evt.extraParams.data.provisionUid);
      provisionHighlight = last(
        values({
          ...evt.annotationData[
            isAreaHighlight ? "areaHighlights" : "textHighlights"
          ],
        }).sort(
          (a: any, b: any) =>
            new Date(a.created).getTime() - new Date(b.created).getTime()
        )
      );
      provisionHighlight.itlsData["highlighted"] = true;
      text = isAreaHighlight
        ? ""
        : this.extractHighlightText(provisionHighlight) ||
          provisionHighlight.text.text;
    } else {
      text = isAreaHighlight
        ? ""
        : this.extractHighlightText(evt.extraParams.annotation) ||
          provisionHighlight.text.text;
    }
    pdfProvisionHighlights.push(provisionHighlight);
    return {
      text,
      textHighlightType: "SINGLE_PAGE",
      lastPage,
      firstPage,
      provisionHighlightId: provisionHighlight.id,
      provisionHighlight,
      pdfProvisionHighlights,
    };
  }

  private prepareProvisionValueFromTwoPageHighlight(
    evt: any
  ): ProvisionValueFromTextHighlightInterface {
    const provisionHighlight: any = evt.extraParams.twoPageHighlightData;
    //TODO: @mohammad-haji
    // check for direction
    const firstPage: number = provisionHighlight.startPage;
    const lastPage: number = provisionHighlight.endPage;
    const provisionHighlightIds: string[] = [];
    const pdfProvisionHighlights: any[] = [];
    let text = "";
    for (const page of Object.keys(provisionHighlight.pagesMeta)) {
      const textHighlight = provisionHighlight.pagesMeta[page];
      if (textHighlight["page"] === lastPage) {
        provisionHighlightIds[1] = textHighlight.highlightData["id"];
        pdfProvisionHighlights.push(textHighlight.highlightData);
      } else {
        provisionHighlightIds[0] = textHighlight.highlightData["id"];
        pdfProvisionHighlights.unshift(textHighlight.highlightData);
      }
      text = textHighlight.highlightData.text.TEXT;
      if (!text) {
        text += this.extractHighlightText(textHighlight.highlightData);
      }
    }
    return {
      text,
      textHighlightType: "TWO_PAGE",
      firstPage,
      lastPage,
      provisionHighlightIds,
      provisionHighlight,
      pdfProvisionHighlights,
    };
  }

  /**
   * prepare prepareTextHighlight for Provision Mention, Bookmark, Comment
   * @param evt
   * @param mode
   */
  prepareProvisionValue(
    evt: any,
    mode: string
  ): ProvisionValueFromTextHighlightInterface {
    if (typeof evt.annotationData === "string") {
      evt.annotationData = JSON.parse(evt.annotationData);
    }
    if (evt.extraParams && evt.extraParams.twoPageHighlightData) {
      return this.prepareProvisionValueFromTwoPageHighlight(evt);
    }
    return this.prepareProvisionValueFromSinglePageHighlight(evt, mode);
  }

  prepareTextHighlightsValueByType(
    textHighlightType: TextHighlightTypeEnum,
    textHighlights: any[],
    isTwoPage: boolean
  ): any[] {
    const result = [];
    if (isTwoPage) {
      textHighlights.forEach((textHighlight) => {
        const projectedTextHighlight: any = {
          color: textHighlight.color,
          guid: textHighlight.guid,
          created: textHighlight.created,
          lastUpdated: textHighlight.lastUpdated,
          rects: textHighlight.rects,
          textSelections: textHighlight.textSelections,
          text: textHighlight.text,
          itlsData: textHighlight.itlsData,
        };
        if (textHighlightType === TextHighlightTypeEnum.COMMENT) {
          projectedTextHighlight.id = textHighlight.id;
        }
        result.push(projectedTextHighlight);
      });
    } else {
      const textHighlight = first(textHighlights);
      const projectedTextHighlight: any = {
        color: textHighlight.color,
        guid: textHighlight.guid,
        created: textHighlight.created,
        lastUpdated: textHighlight.lastUpdated,
        rects: textHighlight.rects,
        textSelections: textHighlight.textSelections,
        text: textHighlight.text,
        itlsData: textHighlight.itlsData,
      };
      if (textHighlightType === TextHighlightTypeEnum.COMMENT) {
        projectedTextHighlight.id = textHighlight.id;
      }
      result.push(projectedTextHighlight);
    }
    return result;
  }

  getParsedMention(
    res: HighlightedProvisionValueModel,
    text: string,
    selectedProvision: FullValMultiPdfProvViewModel,
    provisionHighlight: any,
    isTwoPageTextHighlight?: boolean
  ): FullValProvisionValueModel {
    const parsedProvision: FullValProvisionValueModel = res.newProvision;
    parsedProvision.highlightedText = res.textValue;
    if (isTwoPageTextHighlight) {
      parsedProvision.isTwoPageTextHighlight = true;
      parsedProvision.provisionHighlight = res.pdfMentionHighlights;
    } else {
      parsedProvision.isTwoPageTextHighlight = false;
      parsedProvision.provisionHighlight = first(res.pdfMentionHighlights);
    }
    parsedProvision.provisionTypeUid = selectedProvision.provisionInfo.uid;
    parsedProvision.originalTextValue = res.textValue;
    return parsedProvision;
  }

  changeHighlightsScale(
    highlights: any[],
    currentScale: number,
    pdfViewerPageHeight: number,
    type: "TAG" | "COMMENT" = "TAG",
    abstractPages?: { pageNumber: number; pageHeight: number }[]
  ) {
    // try {
    highlights = highlights.sort((a, b) => {
      return a.rects[0].top - b.rects[0].top;
    });
    highlights = highlights
      .map((highlight) => {
        if (highlight.rects[0].top < 5.75261803155941e307) {
          if (type === "TAG") {
            highlight["_top"] = highlight.rects[0].top * currentScale;
            // pdfViewerPageHeight * (highlight.page - 1)
            return highlight;
          } else if (type === "COMMENT") {
            if (
              abstractPages.length &&
              abstractPages[highlight.page - 1] &&
              abstractPages[highlight.page - 1].pageHeight
            ) {
              highlight["_top"] =
                highlight.rects[0].top * currentScale +
                pdfViewerPageHeight * (highlight.page - 1) +
                (highlight.page - 1) * 2;
            } else {
              highlight["_top"] =
                highlight.rects[0].top * currentScale +
                pdfViewerPageHeight * (highlight.page - 1);
            }
            return highlight;
          }
        }
      })
      .filter((highlight) => {
        if (highlight) return highlight;
      })
      .sort((a, b) => {
        return a["_top"] - b["_top"];
      });
    // highlights.forEach((highlight, index) => {
    //     highlights.forEach((_highlight, tagIndex) => {
    //         if ((Math.abs(_highlight['_top'] - highlight['_top']) <= 20) && index !== tagIndex) {
    //             if (_highlight['_top'] > highlight['_top']) {
    //                 _highlight['_top'] += 30
    //             } else {
    //                 highlight['_top'] += 30
    //             }
    //         }
    //     })
    // })
    return highlights;
    // } catch (e) {
    //     console.error('something is missing in tagList')
    //     return highlights
    // }
  }

  onExportByFileType(
    fileType: string,
    abstractUid: string,
    abstractName: string,
    includeNotes?: boolean,
    includeDocumentChronology?: boolean,
    includeAnnotatedDocs?: boolean,
    excludeEmptyProvisions?: boolean,
    includeSectionHeaders?: boolean,
    emptyProvisionText?: string
  ) {
    switch (fileType) {
      case "CSV":
        this.exportCsvFromAbstract(
          abstractUid,
          abstractName + (includeAnnotatedDocs ? ".zip" : ".tsv"),
          includeNotes,
          includeDocumentChronology,
          includeAnnotatedDocs,
          excludeEmptyProvisions,
          includeSectionHeaders,
          emptyProvisionText
        );
        break;
      case "PDF":
        this.exportPdfFromAbstract(
          abstractUid,
          includeNotes,
          includeDocumentChronology,
          includeAnnotatedDocs,
          excludeEmptyProvisions,
          includeSectionHeaders,
          emptyProvisionText
        );
        break;
      case "DOCX":
        this.exportDocxFromAbstract(
          abstractUid,
          includeNotes,
          includeDocumentChronology,
          includeAnnotatedDocs,
          excludeEmptyProvisions,
          includeSectionHeaders,
          emptyProvisionText
        );
        break;
      case "XLSX":
        this.exportXlsxFromAbstract(
          abstractUid,
          includeNotes,
          includeDocumentChronology,
          includeAnnotatedDocs,
          excludeEmptyProvisions,
          includeSectionHeaders,
          emptyProvisionText
        );
        break;
    }
  }

  private exportPdfFromAbstract(
    abstractUid: string,
    includeNotes?: boolean,
    includeDocumentChronology?: boolean,
    includeAnnotatedDocs?: boolean,
    excludeEmptyProvisions?: boolean,
    includeSectionHeaders?: boolean,
    emptyProvisionText?: string
  ) {
    this.fileApiService.export(
      new ExportMultiNodeModel(
        [
          {
            uid: abstractUid,
            type: "RECORD",
          },
        ],
        "PDF",
        includeNotes,
        includeDocumentChronology,
        false,
        includeAnnotatedDocs,
        excludeEmptyProvisions,
        includeSectionHeaders,
        emptyProvisionText,
        undefined
      )
    );
  }

  private exportCsvFromAbstract(
    abstractUid: string,
    filename: string,
    includeNotes?: boolean,
    includeDocumentChronology?: boolean,
    includeAnnotatedDocs?: boolean,
    excludeEmptyProvisions?: boolean,
    includeSectionHeaders?: boolean,
    emptyProvisionText?: string
  ) {
    this.fileApiService.sendExportOneRequest(
      WebAbstractionPageService.API_VERSION_1,
      WebAbstractionPageService.FINAL_DOC_SETS_URL,
      abstractUid,
      WebAbstractionPageService.TSV_FORMAT_URL,
      filename,
      includeNotes,
      includeDocumentChronology,
      false,
      includeAnnotatedDocs,
      excludeEmptyProvisions,
      includeSectionHeaders,
      emptyProvisionText
    );
  }

  private exportXlsxFromAbstract(
    abstractUid: string,
    includeNotes?: boolean,
    includeDocumentChronology?: boolean,
    includeAnnotatedDocs?: boolean,
    excludeEmptyProvisions?: boolean,
    includeSectionHeaders?: boolean,
    emptyProvisionText?: string
  ) {
    this.fileApiService.export(
      new ExportMultiNodeModel(
        [
          {
            uid: abstractUid,
            type: "RECORD",
          },
        ],
        "XLSX",
        includeNotes,
        includeDocumentChronology,
        false,
        includeAnnotatedDocs,
        excludeEmptyProvisions,
        includeSectionHeaders,
        emptyProvisionText,
        undefined
      )
    );
  }

  private exportDocxFromAbstract(
    abstractUid: string,
    includeNotes?: boolean,
    includeDocumentChronology?: boolean,
    includeAnnotatedDocs?: boolean,
    excludeEmptyProvisions?: boolean,
    includeSectionHeaders?: boolean,
    emptyProvisionText?: string
  ) {
    this.fileApiService.export(
      new ExportMultiNodeModel(
        [
          {
            uid: abstractUid,
            type: "RECORD",
          },
        ],
        "DOCX",
        includeNotes,
        includeDocumentChronology,
        false,
        includeAnnotatedDocs,
        excludeEmptyProvisions,
        includeSectionHeaders,
        emptyProvisionText,
        undefined
      )
    );
  }

  /**
   * calculate size of abstract name based on reader innerWidth
   * @param innerWidth inner width of pdf reader
   */
  calculateAbstractNameResponsiveSize(innerWidth: number): number {
    if (innerWidth < 1410) {
      return 15;
    } else if (innerWidth < 1555) {
      return 25;
    } else if (innerWidth > 1585) {
      return 100;
    } else if (innerWidth > 1555) {
      return 45;
    }
  }

  /**
   * @FIXME changed by Ali's API
   * @param provisionList
   */
  prepareViewerDropdownProvisionList(
    provisionList: FullValMultiPdfProvViewModel[]
  ): {
    provisionList: any[];
    provisionListMap: Record<string, string>;
  } {
    const provisionData = {
      provisionList: [],
      provisionListMap: {},
    };
    provisionData.provisionList = provisionList.map((provision, index) => {
      const { uid, htmlName, name, optionNum, type } =
        provision.provisionInfo as FullProvisionModel;
      provisionData.provisionListMap[uid] = provision.provisionInfo;

      return {
        uid,
        value: uid,
        htmlName,
        uiName: name,
        label: name,
        optionNum: optionNum || index,
        type,
        color: type,
      };
    });
    return provisionData;
  }

  getTagList(highlightList: any[]): any[] {
    return flatten(highlightList).filter(
      (tag) =>
        tag.itlsData &&
        tag.itlsData.highlightType &&
        tag.itlsData.highlightType === "HEAVY" &&
        tag.itlsData.type === "PROVISION"
    );
  }

  getCommentHighlights(highlightList: any[]): any[] {
    return flatten(highlightList).filter(
      (highlight) =>
        highlight.itlsData &&
        highlight.itlsData.commentData &&
        highlight.itlsData.commentData.commentUid
    );
  }

  extractHighlightsFromAnnotationList(annotationList: any) {
    return [
      ...WebAbstractionPageService.mapAnnotationsToHighlightList(
        annotationList,
        "textHighlights"
      ),
      ...WebAbstractionPageService.mapAnnotationsToHighlightList(
        annotationList,
        "areaHighlights"
      ),
    ];
  }

  calculateProvisionBoxSizeOnResize(
    size: any,
    provisionBoxSize: ProvisionBoxSizeInterface
  ): ProvisionBoxSizeInterface {
    const { width, height } = size;
    if (width >= provisionBoxSize.minWidth) {
      provisionBoxSize.width = width;
    } else {
      provisionBoxSize.width = provisionBoxSize.minWidth;
    }
    if (height >= provisionBoxSize.minHeight) {
      provisionBoxSize.height = height;
    } else {
      provisionBoxSize.height = provisionBoxSize.minHeight;
    }
    return provisionBoxSize;
  }

  createNewCustomProvisionValue(
    type: "NEW_TAB" | "",
    parsedProvision: any,
    multiProvisionValue: FullValMultiProvisionValueModel,
    selectedProvision: FullValMultiProvisionValueModel | MinimalProvisionModel,
    selectedDocumentUid: string,
    provisionFormSchema: any,
    parsedFailedFormSchema: any,
    provisionComment: string,
    sectionHeader: string
  ): PartialValProvisionValueModel {
    const newProvision = new PartialValProvisionValueModel();
    if (parsedProvision && type !== "NEW_TAB") {
      const { page, value, textValue, valueType } = parsedProvision;
      newProvision.page = page;
      newProvision.value = value;
      newProvision.textValue = textValue;
      newProvision.valueType = valueType || multiProvisionValue.type;
    } else {
      newProvision.valueType = multiProvisionValue.type;
      newProvision.eventType = "NEW_TAB";
      newProvision.notes = provisionComment;
      newProvision.sectionHeader = sectionHeader;
    }
    newProvision.active = true;
    newProvision.source = "CUSTOM";
    newProvision.docAbstractUid = selectedDocumentUid;
    if (newProvision.value || type === "NEW_TAB") {
      if (selectedProvision.type === "TABLE") {
        newProvision.model =
          this.webabstractionProvisionService.getDefaultTableValue();
      } else {
        newProvision.model = this.webabstractionProvisionService
          .prepareProvisionValue(selectedProvision.type)
          .prepareProvisionOptionModel(newProvision);
        newProvision.formSchema = provisionFormSchema;
      }
    } else {
      newProvision.model = { value: newProvision.textValue };
      newProvision.formSchema = parsedFailedFormSchema;
    }
    return cloneDeep(newProvision);
  }

  prepareTableProvisionOptionValue(
    documentProvision: PartialValProvisionValueModel,
    dynamicTable: any
  ) {
    let rows;
    if (documentProvision.rows) {
      rows = documentProvision.rows;
    } else {
      rows = dynamicTable.rows;
    }
    rows = cloneDeep(rows);
    const tableProvisionModel: TableProvisionModel = new TableProvisionModel();
    tableProvisionModel.headers = values(first(rows));
    rows.splice(0, 1);
    tableProvisionModel.rows = rows.map((row) => values(row));
    tableProvisionModel.headers.forEach(() =>
      tableProvisionModel.types.push(PROVISIONS_DATA_CONST.COMMON_NOUN.name)
    );
    return tableProvisionModel;
  }

  setSearchSelectedMultiProvisionValue(
    selectedMultiProvisionValue: PartialProvisionValueModel
  ) {
    this.searchSelectedMultiProvisionValue = selectedMultiProvisionValue;
  }

  getSearchSelectedMultiProvisionValue() {
    return this.searchSelectedMultiProvisionValue;
  }

  /**
   * @name isAbstractValidForSelectedCalculator
   * @description checks required provisions for the selected calculator
   * @param calculator
   * @param provisionGroupList
   */
  isAbstractValidForSelectedCalculator(
    calculator: CalculatorInterface,
    provisionGroupList: FullProvisionGroupModel[]
  ): boolean {
    let abstractProvisions = [];
    calculator.requiredProvisions.forEach((requiredProvision) => {
      abstractProvisions.push(
        WebAbstractionPageService.filterProvisionByHtmlName(
          requiredProvision.htmlName,
          provisionGroupList
        )
      );
    });
    abstractProvisions = [...filter(abstractProvisions)];
    if (abstractProvisions.length !== calculator.requiredProvisions.length) {
      this._notification.error(calculator.errorMsg);
      return false;
    } else {
      return true;
    }
  }

  onDualWindowChanged(checked: boolean) {
    WebAbstractionPageService.IS_DUAL_WINDOW_MODE = checked;
    this.onDualWindowChange$.next(checked);
  }

  createBreadcrumb(
    nodeNavigation: NodeNavigationResultModel,
    selectedDocument: RelatedDocumentModel,
    documents: RelatedDocumentModel[],
    documentsShortNameMap: { [p: string]: any },
    onClickedParam: (_doc: RelatedDocumentModel) => void,
    isAbstractProcessingCompleted
  ): BreadcrumbItemModel[] {
    const breadcrumbItems: BreadcrumbItemModel[] = [];
    nodeNavigation.navigations.forEach((navigation) => {
      breadcrumbItems.push(
        new BreadcrumbItemModel({
          title: navigation.name,
          key: navigation.uid,
          link:
            navigation.type === DriveNodeType.DIRECTORY
              ? `/drive/folders/${navigation.uid}`
              : null,
          isAbstractProcessingCompleted: isAbstractProcessingCompleted,
        })
      );
    });
    const allDocumentBreadcrumbItems = documents.map(
      (document) =>
        new BreadcrumbItemModel({
          title: document.name,
          titlePrefix: documentsShortNameMap[document.uid],
          key: document.uid,
          type: BreadcrumbItemTypeEnum.NORMAL,
          tooltip: document.name,
          onClicked: () => onClickedParam(document),
        })
    );
    const selectedDocumentBreadcrumb = new BreadcrumbItemModel({
      title: selectedDocument.name,
      key: selectedDocument.uid,
      titlePrefix: documentsShortNameMap[selectedDocument.uid],
      type: BreadcrumbItemTypeEnum.LIST,
      tooltip: selectedDocument.name,
      children: allDocumentBreadcrumbItems,
    });
    breadcrumbItems.push(selectedDocumentBreadcrumb);
    return breadcrumbItems;
  }

  loadSelectedDocument(abstractUid: string, docUid: string) {
    return this.httpClient.get(
      this.getDocumentUrl(abstractUid, docUid, "sync"),
      {
        responseType: "blob",
      }
    );
  }

  public async getETag(abstractUid: string, docUid: string): Promise<string> {
    const hasCachedDocument = await this.hasCachedDocument(abstractUid, docUid);
    if (!hasCachedDocument) {
      return;
    }

    const cacheItem = `${abstractUid}___${docUid}`;

    const etags = JSON.parse(
      (localStorage.get
        ? localStorage.get(LocalStorageKey.ETAG)
        : localStorage[LocalStorageKey.ETAG]) || "{}"
    );
    return etags?.[cacheItem];
  }

  private async hasCachedDocument(
    abstractUid: string,
    docUid: string
  ): Promise<boolean> {
    const cache = await caches.open("v1");
    const response = await cache.match(
      this.getDocumentUrl(abstractUid, docUid)
    );
    return !!response;
  }

  createDocCacheId(userUid: string, abstractUid: string, docUid: string) {
    const cacheItem = `${userUid}___${abstractUid}___${docUid}`;
    let cachedDocuments = localStorage.getItem(
      LocalStorageKey.CACHED_DOCUMENTS
    );
    if (cachedDocuments) {
      cachedDocuments = cloneDeep(JSON.parse(cachedDocuments)) || [];
      if (cachedDocuments.indexOf(cacheItem) === -1) {
        localStorage.removeItem(LocalStorageKey.CACHED_DOCUMENTS);
        localStorage.setItem(
          LocalStorageKey.CACHED_DOCUMENTS,
          JSON.stringify([...cachedDocuments, cacheItem])
        );
      }
    } else {
      localStorage.setItem(
        LocalStorageKey.CACHED_DOCUMENTS,
        JSON.stringify([cacheItem])
      );
    }
  }

  isDocCached(userUid: string, abstractUid: string, docUid: string): boolean {
    let cachedDocuments = localStorage.getItem(
      LocalStorageKey.CACHED_DOCUMENTS
    );
    if (cachedDocuments) {
      const cacheItem = `${userUid}___${abstractUid}___${docUid}`;
      cachedDocuments = JSON.parse(cachedDocuments);
      return cachedDocuments.indexOf(cacheItem) !== -1;
    }
  }

  getTempMention(provision: any | FullValMultiPdfProvModel) {
    return provision.multiplePdfProvision.options.find(
      (mention) => mention.isTempHighlight
    );
  }

  setReviewOperationMode(
    reviewOperationMode: AbstractReviewOperationModeEnum,
    abstractUid: string
  ): void {
    this.editAbstractService
      .setReviewOperationModeNoResponse(reviewOperationMode, abstractUid)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp) => {
        this.onReviewOperationModeChange$.next(resp);
      });
  }
}
