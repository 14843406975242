import { Injectable } from "@angular/core";

@Injectable()
export class SchemaFormHelperService {
  schema: any = {};

  setSchema(schema) {
    this.schema = schema;
  }

  getSchema() {
    return this.schema;
  }

  getFieldByName(name: string) {
    return this.schema.properties[name];
  }
}
