import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("NumericalProvisionModel")
export class NumericalProvisionModel {
  @JsonProperty("value", Number)
  value: number;

  @JsonProperty("unit", String)
  unit = "";
}
