import { NgModule } from "@angular/core";
import { PagesToggleService } from "./services/toggler.service";
import {
  OneColumnComponent,
  RootLayoutComponent,
  VerticalSidebarComponent,
} from "./layouts";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormGroupDefaultDirective } from "./components/forms/form-group-default.directive";
import { PgUtilModule } from "@@intelease/web/intelease/utils/pg-util.module";
import {
  BaseEntityEditComponent,
  BaseEntityListComponent,
  InteleaseProgressBarModule,
  MessageModule,
} from "@@intelease/web/intelease/components";
import { FormSchemaLoaderService } from "@@intelease/web/intelease/services";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { NgxPermissionsModule } from "ngx-permissions";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgPipesModule } from "ngx-pipes";
import { NgScrollbarModule } from "ngx-scrollbar";
import { NavbarVerticalStyle1Module } from "@@intelease/web/intelease/components/navbar/vertical/style-1/style-1.module";
import { SidebarModule } from "@@intelease/web/ui";
import { MatIconModule } from "@angular/material/icon";
import { ToolbarModule } from "./components/toolbar/toolbar.module";
import { MatSidenavModule } from "@angular/material/sidenav";
import { LeftMenuModule } from "./layouts/left-menu/left-menu.module";

const LAYOUTS = [
  RootLayoutComponent,
  OneColumnComponent,
  VerticalSidebarComponent,
];
const COMPONENTS = [
  FormGroupDefaultDirective,
  BaseEntityListComponent,
  BaseEntityEditComponent,
];

const MATERIAL_MODULES = [MatIconModule, MatSidenavModule];

@NgModule({
  declarations: [...LAYOUTS, ...COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    PgUtilModule,
    MessageModule,
    InteleaseCommonModule,
    InteleaseProgressBarModule,
    FlexLayoutModule,
    NgPipesModule,
    NgScrollbarModule,
    NavbarVerticalStyle1Module,
    SidebarModule,
    ...MATERIAL_MODULES,
    ToolbarModule,
    LeftMenuModule,
  ],
  providers: [
    PagesToggleService,
    FormSchemaLoaderService,
    DatePipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "outline",
      },
    },
  ],
  exports: [
    ...LAYOUTS,
    ...COMPONENTS,
    InteleaseProgressBarModule,
    NgxPermissionsModule,
    FlexLayoutModule,
    NgPipesModule,
  ],
})
export class InteleaseModule {}
