import { Component, Inject, OnInit } from "@angular/core";
import {
  ModalInputModel,
  ModalResponseModel,
} from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "itls-my-documents-add-filter-modal",
  templateUrl: "./my-documents-add-filter-modal.component.html",
  styleUrls: ["./my-documents-add-filter.modal-component.scss"],
})
export class MyDocumentsAddFilterModalComponent implements OnInit {
  // onClose: Subject<any> = new Subject<any>()
  // onDismiss: Subject<any> = new Subject<any>()
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload;
  modalTitle = "Save Filter";
  filterName = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ModalInputModel,
    public dialogRef: MatDialogRef<MyDocumentsAddFilterModalComponent>,
    private inputValidationService: InputValidationService
  ) {}

  ngOnInit() {
    this.payload = this.dialogData.payload;
    this.mode = this.dialogData.mode;
    if (this.mode === ComponentModeEnum.ADD) {
      this.modalTitle = "Save Filter";
    } else {
      this.filterName = this.payload.filterName;
      this.modalTitle = "Rename Folder";
    }
  }

  onCreateFilter() {
    const filterName = this.inputValidationService.sanitizeText(
      this.filterName
    );
    if (!this.validateOnUpdateFilterFields(filterName)) {
      return;
    }
    this.modalRes.data = { ...{ folderName: filterName } };
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.CLOSE,
    });
  }

  onEditFilter() {
    const filterName = this.inputValidationService.sanitizeText(
      this.filterName
    );
    if (!this.validateOnUpdateFilterFields(filterName)) {
      return;
    }
    this.modalRes.data = { ...{ folderName: filterName } };
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.CLOSE,
    });
  }

  private validateOnUpdateFilterFields(filterName: string): boolean {
    if (
      !this.inputValidationService.usefulTextElseAlert(
        filterName,
        "You must specify a filter name!"
      )
    ) {
      return false;
    }
    return true;
  }

  onDismissModal() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}
