/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqAppendToMentionValueDtoModel } from "../models/o-api-req-append-to-mention-value-dto-model";
import { OApiReqCalculateMentionDtoModel } from "../models/o-api-req-calculate-mention-dto-model";
import { OApiReqCalculateProvisionDtoModel } from "../models/o-api-req-calculate-provision-dto-model";
import { OApiReqCreateManualMentionV3DtoModel } from "../models/o-api-req-create-manual-mention-v-3-dto-model";
import { OApiReqEditMentionValueDtoModel } from "../models/o-api-req-edit-mention-value-dto-model";
import { OApiReqEditMentionValueV2DtoModel } from "../models/o-api-req-edit-mention-value-v-2-dto-model";
import { OApiReqHighlightAttributeNestedProvisionValueDtoModel } from "../models/o-api-req-highlight-attribute-nested-provision-value-dto-model";
import { OApiReqHighlightUpdateNestedProvisionValueDtoModel } from "../models/o-api-req-highlight-update-nested-provision-value-dto-model";
import { OApiReqModifyNestedMentionAttributionsApiDtoModel } from "../models/o-api-req-modify-nested-mention-attributions-api-dto-model";
import { OApiReqModifyNestedProvisionMentionAttributionsDtoModel } from "../models/o-api-req-modify-nested-provision-mention-attributions-dto-model";
import { OApiReqParseHighlightSaveMentionDtoModel } from "../models/o-api-req-parse-highlight-save-mention-dto-model";
import { OApiReqParseHighlightSaveMentionV2DtoModel } from "../models/o-api-req-parse-highlight-save-mention-v-2-dto-model";
import { OApiReqParseHighlightSaveMentionV3DtoModel } from "../models/o-api-req-parse-highlight-save-mention-v-3-dto-model";
import { OApiReqPickSnippetMentionDtoModel } from "../models/o-api-req-pick-snippet-mention-dto-model";
import { OApiReqPickSnippetReplaceMentionV2DtoModel } from "../models/o-api-req-pick-snippet-replace-mention-v-2-dto-model";
import { OApiReqRenameMentionDtoModel } from "../models/o-api-req-rename-mention-dto-model";
import { OApiReqSaveHighlightMentionDtoModel } from "../models/o-api-req-save-highlight-mention-dto-model";
import { OApiReqSaveHighlightMentionV2DtoModel } from "../models/o-api-req-save-highlight-mention-v-2-dto-model";
import { OApiReqSaveHighlightMentionV3DtoModel } from "../models/o-api-req-save-highlight-mention-v-3-dto-model";
import { OApiReqSaveManualMentionV2DtoModel } from "../models/o-api-req-save-manual-mention-v-2-dto-model";
import { OApiReqUpdateProvisionReviewCompletionInfoApiDtoModel } from "../models/o-api-req-update-provision-review-completion-info-api-dto-model";
import { OApiRespCompleteValMultiplePdfProvisionViewModel } from "../models/o-api-resp-complete-val-multiple-pdf-provision-view-model";
import { OApiRespFullPdfProvisionViewModel } from "../models/o-api-resp-full-pdf-provision-view-model";
import { OApiRespFullProvisionAndHighlightDtoModel } from "../models/o-api-resp-full-provision-and-highlight-dto-model";
import { OApiRespFullValMultiplePdfProvisionViewModel } from "../models/o-api-resp-full-val-multiple-pdf-provision-view-model";
import { OApiRespFullValPdfProvisionViewModel } from "../models/o-api-resp-full-val-pdf-provision-view-model";
import { OApiRespMultiGetProvisionPngSnippetsModel } from "../models/o-api-resp-multi-get-provision-png-snippets-model";
import { OApiRespMultiPdfProvViewAndHighlightInfoModel } from "../models/o-api-resp-multi-pdf-prov-view-and-highlight-info-model";
import { OApiRespMultiPdfProvViewAndHighlightsModel } from "../models/o-api-resp-multi-pdf-prov-view-and-highlights-model";
import { OApiRespPartialPdfProvisionViewModel } from "../models/o-api-resp-partial-pdf-provision-view-model";
import { OApiRespPartialValPdfProvisionViewModel } from "../models/o-api-resp-partial-val-pdf-provision-view-model";
import { OApiRespRealtimeAreaHighlightProvisionParseOutputModel } from "../models/o-api-resp-realtime-area-highlight-provision-parse-output-model";
import { OApiRespRealtimeTextHighlightProvisionParseOutputModel } from "../models/o-api-resp-realtime-text-highlight-provision-parse-output-model";
import { OApiRespSourceViewModel } from "../models/o-api-resp-source-view-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class ProvisionMentionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation calculateCreateProvisionValue
   */
  static readonly CalculateCreateProvisionValuePath =
    "/api/v1/finalDocSets/{recordUid}/calculatedProvisions";

  /**
   * Create a provision selected mention for the user (when he uses a calculator to get the value, not highlighting / manually).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateCreateProvisionValue()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  calculateCreateProvisionValue$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.CalculateCreateProvisionValuePath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>;
        })
      );
  }

  /**
   * Create a provision selected mention for the user (when he uses a calculator to get the value, not highlighting / manually).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calculateCreateProvisionValue$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  calculateCreateProvisionValue(params?: {
    body?: RequestModel;
  }): Observable<OApiRespFullValMultiplePdfProvisionViewModel> {
    return this.calculateCreateProvisionValue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>) =>
          r.body as OApiRespFullValMultiplePdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation highlightCreateAndUpdateProvisionValue
   */
  static readonly HighlightCreateAndUpdateProvisionValuePath =
    "/api/v1/records/{recordUid}/highlight-mentions";

  /**
   * Update the provision's only selected mention to this one that was parsed from a highlight.
   *
   * The user already highlighted for a new provision mention, but that mention was not persisted. Then he made sure the new mention value is valid. Here, we create this selected mention and remove all others.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `highlightCreateAndUpdateProvisionValue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  highlightCreateAndUpdateProvisionValue$Response(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data for this mention (which came from a highlight).
     */
    body: OApiReqSaveHighlightMentionDtoModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullValMultiplePdfProvisionViewModel
      | OApiRespCompleteValMultiplePdfProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.HighlightCreateAndUpdateProvisionValuePath,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullValMultiplePdfProvisionViewModel
            | OApiRespCompleteValMultiplePdfProvisionViewModel
          >;
        })
      );
  }

  /**
   * Update the provision's only selected mention to this one that was parsed from a highlight.
   *
   * The user already highlighted for a new provision mention, but that mention was not persisted. Then he made sure the new mention value is valid. Here, we create this selected mention and remove all others.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `highlightCreateAndUpdateProvisionValue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  highlightCreateAndUpdateProvisionValue(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data for this mention (which came from a highlight).
     */
    body: OApiReqSaveHighlightMentionDtoModel;
  }): Observable<
    | OApiRespFullValMultiplePdfProvisionViewModel
    | OApiRespCompleteValMultiplePdfProvisionViewModel
  > {
    return this.highlightCreateAndUpdateProvisionValue$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullValMultiplePdfProvisionViewModel
            | OApiRespCompleteValMultiplePdfProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespFullValMultiplePdfProvisionViewModel
            | OApiRespCompleteValMultiplePdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation highlightCreateAndUpdateProvisionValueV2
   */
  static readonly HighlightCreateAndUpdateProvisionValueV2Path =
    "/api/v2/records/{recordUid}/highlight-mentions";

  /**
   * Update the provision's only selected mention to this one that was parsed from a highlight.
   *
   * The user already highlighted for a new provision mention, but that mention was not persisted. Then he made sure the new mention value is valid. Here, we create this selected mention and remove all others.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `highlightCreateAndUpdateProvisionValueV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  highlightCreateAndUpdateProvisionValueV2$Response(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data for this mention (which came from a highlight).
     */
    body: OApiReqSaveHighlightMentionV2DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.HighlightCreateAndUpdateProvisionValueV2Path,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Update the provision's only selected mention to this one that was parsed from a highlight.
   *
   * The user already highlighted for a new provision mention, but that mention was not persisted. Then he made sure the new mention value is valid. Here, we create this selected mention and remove all others.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `highlightCreateAndUpdateProvisionValueV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  highlightCreateAndUpdateProvisionValueV2(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data for this mention (which came from a highlight).
     */
    body: OApiReqSaveHighlightMentionV2DtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.highlightCreateAndUpdateProvisionValueV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation highlightCreateAndUpdateProvisionValueV3
   */
  static readonly HighlightCreateAndUpdateProvisionValueV3Path =
    "/api/v3/records/{recordUid}/highlight-mentions";

  /**
   * Update the provision's only selected mention to this one that was parsed from a highlight.
   *
   * The user already highlighted for a new provision mention, but that mention was not persisted. Then he made sure the new mention value is valid. Here, we create this selected mention and remove all others.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `highlightCreateAndUpdateProvisionValueV3()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  highlightCreateAndUpdateProvisionValueV3$Response(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data for this mention (which came from a highlight).
     */
    body: OApiReqSaveHighlightMentionV3DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.HighlightCreateAndUpdateProvisionValueV3Path,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Update the provision's only selected mention to this one that was parsed from a highlight.
   *
   * The user already highlighted for a new provision mention, but that mention was not persisted. Then he made sure the new mention value is valid. Here, we create this selected mention and remove all others.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `highlightCreateAndUpdateProvisionValueV3$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  highlightCreateAndUpdateProvisionValueV3(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data for this mention (which came from a highlight).
     */
    body: OApiReqSaveHighlightMentionV3DtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.highlightCreateAndUpdateProvisionValueV3$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation highlightCreateProvisionValue
   */
  static readonly HighlightCreateProvisionValuePath =
    "/api/v1/finalDocSets/{recordUid}/highlightedProvisions";

  /**
   * Save a provision selected mention that was parsed from a highlight.
   *
   * Create a new selected mention in MultiplePdfProvision#getOptions() for a mention created by user's highlight
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `highlightCreateProvisionValue()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   *
   * @deprecated
   */
  highlightCreateProvisionValue$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.HighlightCreateProvisionValuePath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>;
        })
      );
  }

  /**
   * Save a provision selected mention that was parsed from a highlight.
   *
   * Create a new selected mention in MultiplePdfProvision#getOptions() for a mention created by user's highlight
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `highlightCreateProvisionValue$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   *
   * @deprecated
   */
  highlightCreateProvisionValue(params?: {
    body?: RequestModel;
  }): Observable<OApiRespFullValMultiplePdfProvisionViewModel> {
    return this.highlightCreateProvisionValue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>) =>
          r.body as OApiRespFullValMultiplePdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation manualCreateProvisionMentionV2
   */
  static readonly ManualCreateProvisionMentionV2Path =
    "/api/v2/records/{recordUid}/manual-mentions";

  /**
   * Create the provision's selected mention for the user (when he manually enters in the value, not highlighting / calculating).
   *
   * When user manually enters in a value for a provision without an existing selected mention, then save this mention as the only selected one for this provision.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualCreateProvisionMentionV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  manualCreateProvisionMentionV2$Response(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data the user is sending for creating the manual mention.
     */
    body: OApiReqSaveManualMentionV2DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.ManualCreateProvisionMentionV2Path,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Create the provision's selected mention for the user (when he manually enters in the value, not highlighting / calculating).
   *
   * When user manually enters in a value for a provision without an existing selected mention, then save this mention as the only selected one for this provision.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `manualCreateProvisionMentionV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  manualCreateProvisionMentionV2(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data the user is sending for creating the manual mention.
     */
    body: OApiReqSaveManualMentionV2DtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.manualCreateProvisionMentionV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation manualCreateProvisionMentionV3
   */
  static readonly ManualCreateProvisionMentionV3Path =
    "/api/v3/records/{recordUid}/manual-mentions";

  /**
   * Create the provision's selected mention for the user (when he manually enters in the value, not highlighting / calculating).
   *
   * When user manually enters in a value for a provision without an existing selected mention, then save this mention as the only selected one for this provision.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualCreateProvisionMentionV3()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  manualCreateProvisionMentionV3$Response(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data the user is sending for creating the manual mention.
     */
    body: OApiReqCreateManualMentionV3DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.ManualCreateProvisionMentionV3Path,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Create the provision's selected mention for the user (when he manually enters in the value, not highlighting / calculating).
   *
   * When user manually enters in a value for a provision without an existing selected mention, then save this mention as the only selected one for this provision.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `manualCreateProvisionMentionV3$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  manualCreateProvisionMentionV3(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data the user is sending for creating the manual mention.
     */
    body: OApiReqCreateManualMentionV3DtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.manualCreateProvisionMentionV3$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation manualCreateProvisionValue
   */
  static readonly ManualCreateProvisionValuePath =
    "/api/v1/finalDocSets/{recordUid}/manualProvisions";

  /**
   * Create a provision selected mention for the user (when he manually enters in the value, not highlighting / calculating).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manualCreateProvisionValue()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  manualCreateProvisionValue$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.ManualCreateProvisionValuePath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>;
        })
      );
  }

  /**
   * Create a provision selected mention for the user (when he manually enters in the value, not highlighting / calculating).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `manualCreateProvisionValue$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  manualCreateProvisionValue(params?: {
    body?: RequestModel;
  }): Observable<OApiRespFullValMultiplePdfProvisionViewModel> {
    return this.manualCreateProvisionValue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>) =>
          r.body as OApiRespFullValMultiplePdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation parseHighlightCreateAndUpdateProvisionValue
   */
  static readonly ParseHighlightCreateAndUpdateProvisionValuePath =
    "/api/v1/records/{recordUid}/parse-highlight-mentions";

  /**
   * Parse provision mention value from a highlight and save it as the only selected mention.
   *
   * When user highlights on the PDF and chooses a provision, then try parsing the text / area to get a value for that provision. If able to parse a valid value, then persist this mention as the only selected one for this provision. Else, just return a fake mention but don't persist it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseHighlightCreateAndUpdateProvisionValue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  parseHighlightCreateAndUpdateProvisionValue$Response(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data about the user's highlight and provision for which he wants to add a mention.
     */
    body: OApiReqParseHighlightSaveMentionDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespMultiPdfProvViewAndHighlightsModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.ParseHighlightCreateAndUpdateProvisionValuePath,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiPdfProvViewAndHighlightsModel>;
        })
      );
  }

  /**
   * Parse provision mention value from a highlight and save it as the only selected mention.
   *
   * When user highlights on the PDF and chooses a provision, then try parsing the text / area to get a value for that provision. If able to parse a valid value, then persist this mention as the only selected one for this provision. Else, just return a fake mention but don't persist it.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `parseHighlightCreateAndUpdateProvisionValue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  parseHighlightCreateAndUpdateProvisionValue(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data about the user's highlight and provision for which he wants to add a mention.
     */
    body: OApiReqParseHighlightSaveMentionDtoModel;
  }): Observable<OApiRespMultiPdfProvViewAndHighlightsModel> {
    return this.parseHighlightCreateAndUpdateProvisionValue$Response(
      params
    ).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiPdfProvViewAndHighlightsModel>) =>
          r.body as OApiRespMultiPdfProvViewAndHighlightsModel
      )
    );
  }

  /**
   * Path part for operation parseHighlightCreateAndUpdateProvisionValueV2
   */
  static readonly ParseHighlightCreateAndUpdateProvisionValueV2Path =
    "/api/v2/records/{recordUid}/parse-highlight-mentions";

  /**
   * Parse provision mention value from a highlight and save it as the only selected mention.
   *
   * When user highlights on the PDF and chooses a provision, then try parsing the text / area to get a value for that provision. If able to parse a valid value, then persist this mention as the only selected one for this provision. Else, just return a fake mention but don't persist it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseHighlightCreateAndUpdateProvisionValueV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  parseHighlightCreateAndUpdateProvisionValueV2$Response(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data about the user's highlight and provision for which he wants to add a mention.
     */
    body: OApiReqParseHighlightSaveMentionV2DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespMultiPdfProvViewAndHighlightInfoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.ParseHighlightCreateAndUpdateProvisionValueV2Path,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiPdfProvViewAndHighlightInfoModel>;
        })
      );
  }

  /**
   * Parse provision mention value from a highlight and save it as the only selected mention.
   *
   * When user highlights on the PDF and chooses a provision, then try parsing the text / area to get a value for that provision. If able to parse a valid value, then persist this mention as the only selected one for this provision. Else, just return a fake mention but don't persist it.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `parseHighlightCreateAndUpdateProvisionValueV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  parseHighlightCreateAndUpdateProvisionValueV2(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data about the user's highlight and provision for which he wants to add a mention.
     */
    body: OApiReqParseHighlightSaveMentionV2DtoModel;
  }): Observable<OApiRespMultiPdfProvViewAndHighlightInfoModel> {
    return this.parseHighlightCreateAndUpdateProvisionValueV2$Response(
      params
    ).pipe(
      map(
        (
          r: StrictHttpResponse<OApiRespMultiPdfProvViewAndHighlightInfoModel>
        ) => r.body as OApiRespMultiPdfProvViewAndHighlightInfoModel
      )
    );
  }

  /**
   * Path part for operation parseHighlightCreateAndUpdateProvisionValueV3
   */
  static readonly ParseHighlightCreateAndUpdateProvisionValueV3Path =
    "/api/v3/records/{recordUid}/parse-highlight-mentions";

  /**
   * Parse provision mention value from a highlight and save it as the only selected mention.
   *
   * When user highlights on the PDF and chooses a provision, then try parsing the text / area to get a value for that provision. If able to parse a valid value, then persist this mention as the only selected one for this provision. Else, just return a fake mention but don't persist it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseHighlightCreateAndUpdateProvisionValueV3()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parseHighlightCreateAndUpdateProvisionValueV3$Response(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data about the user's highlight and provision for which he wants to add a mention.
     */
    body: OApiReqParseHighlightSaveMentionV2DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.ParseHighlightCreateAndUpdateProvisionValueV3Path,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Parse provision mention value from a highlight and save it as the only selected mention.
   *
   * When user highlights on the PDF and chooses a provision, then try parsing the text / area to get a value for that provision. If able to parse a valid value, then persist this mention as the only selected one for this provision. Else, just return a fake mention but don't persist it.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `parseHighlightCreateAndUpdateProvisionValueV3$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parseHighlightCreateAndUpdateProvisionValueV3(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data about the user's highlight and provision for which he wants to add a mention.
     */
    body: OApiReqParseHighlightSaveMentionV2DtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.parseHighlightCreateAndUpdateProvisionValueV3$Response(
      params
    ).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation parseHighlightCreateAndUpdateProvisionValueV4
   */
  static readonly ParseHighlightCreateAndUpdateProvisionValueV4Path =
    "/api/v4/records/{recordUid}/parse-highlight-mentions";

  /**
   * Parse provision mention value from a highlight and save it as the only selected mention.
   *
   * When user highlights on the PDF and chooses a provision, then try parsing the text / area to get a value for that provision. If able to parse a valid value, then persist this mention as the only selected one for this provision. Else, just return a fake mention but don't persist it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseHighlightCreateAndUpdateProvisionValueV4()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parseHighlightCreateAndUpdateProvisionValueV4$Response(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data about the user's highlight and provision for which he wants to add a mention.
     */
    body: OApiReqParseHighlightSaveMentionV3DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.ParseHighlightCreateAndUpdateProvisionValueV4Path,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Parse provision mention value from a highlight and save it as the only selected mention.
   *
   * When user highlights on the PDF and chooses a provision, then try parsing the text / area to get a value for that provision. If able to parse a valid value, then persist this mention as the only selected one for this provision. Else, just return a fake mention but don't persist it.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `parseHighlightCreateAndUpdateProvisionValueV4$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parseHighlightCreateAndUpdateProvisionValueV4(params: {
    /**
     * The unique id of the record for which a mention is to be created.
     */
    recordUid: string;

    /**
     * The data about the user's highlight and provision for which he wants to add a mention.
     */
    body: OApiReqParseHighlightSaveMentionV3DtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.parseHighlightCreateAndUpdateProvisionValueV4$Response(
      params
    ).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation parseProvisionValueFromHighlight
   */
  static readonly ParseProvisionValueFromHighlightPath =
    "/api/v1/finalDocSets/{recordUid}/tempProvisions";

  /**
   * Parse provision mention value from a highlight.
   *
   * When user highlights on the PDF and chooses a provision, then try parsing the text / area to get a value for that provision. This doesn't actually persist the value, unlike most POST requests
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseProvisionValueFromHighlight()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   *
   * @deprecated
   */
  parseProvisionValueFromHighlight$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespRealtimeTextHighlightProvisionParseOutputModel
      | OApiRespRealtimeAreaHighlightProvisionParseOutputModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.ParseProvisionValueFromHighlightPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespRealtimeTextHighlightProvisionParseOutputModel
            | OApiRespRealtimeAreaHighlightProvisionParseOutputModel
          >;
        })
      );
  }

  /**
   * Parse provision mention value from a highlight.
   *
   * When user highlights on the PDF and chooses a provision, then try parsing the text / area to get a value for that provision. This doesn't actually persist the value, unlike most POST requests
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `parseProvisionValueFromHighlight$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   *
   * @deprecated
   */
  parseProvisionValueFromHighlight(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespRealtimeTextHighlightProvisionParseOutputModel
    | OApiRespRealtimeAreaHighlightProvisionParseOutputModel
  > {
    return this.parseProvisionValueFromHighlight$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespRealtimeTextHighlightProvisionParseOutputModel
            | OApiRespRealtimeAreaHighlightProvisionParseOutputModel
          >
        ) =>
          r.body as
            | OApiRespRealtimeTextHighlightProvisionParseOutputModel
            | OApiRespRealtimeAreaHighlightProvisionParseOutputModel
      )
    );
  }

  /**
   * Path part for operation pickSnippetAndReplaceProvisionValue
   */
  static readonly PickSnippetAndReplaceProvisionValuePath =
    "/api/v1/records/{recordUid}/pick-snippet-replace-mentions";

  /**
   * Update the provision's first selected mention from a snippet mention.
   *
   * Replaces the first selected mention with the current selection.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pickSnippetAndReplaceProvisionValue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pickSnippetAndReplaceProvisionValue$Response(params: {
    /**
     * The user-facing id of the record for which to select a provision mention.
     */
    recordUid: string;

    /**
     * The data the user is sending for picking a snippet mention
     */
    body: OApiReqPickSnippetMentionDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.PickSnippetAndReplaceProvisionValuePath,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Update the provision's first selected mention from a snippet mention.
   *
   * Replaces the first selected mention with the current selection.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pickSnippetAndReplaceProvisionValue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pickSnippetAndReplaceProvisionValue(params: {
    /**
     * The user-facing id of the record for which to select a provision mention.
     */
    recordUid: string;

    /**
     * The data the user is sending for picking a snippet mention
     */
    body: OApiReqPickSnippetMentionDtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.pickSnippetAndReplaceProvisionValue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation pickSnippetAndReplaceProvisionValueV2
   */
  static readonly PickSnippetAndReplaceProvisionValueV2Path =
    "/api/v2/records/{recordUid}/pick-snippet-replace-mentions";

  /**
   * Update the provision's first selected mention from a snippet mention.
   *
   * Replaces the first selected mention with the current selection.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pickSnippetAndReplaceProvisionValueV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pickSnippetAndReplaceProvisionValueV2$Response(params: {
    /**
     * The user-facing id of the record for which to select a provision mention.
     */
    recordUid: string;

    /**
     * The data the user is sending for picking a snippet mention
     */
    body: OApiReqPickSnippetReplaceMentionV2DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.PickSnippetAndReplaceProvisionValueV2Path,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Update the provision's first selected mention from a snippet mention.
   *
   * Replaces the first selected mention with the current selection.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pickSnippetAndReplaceProvisionValueV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pickSnippetAndReplaceProvisionValueV2(params: {
    /**
     * The user-facing id of the record for which to select a provision mention.
     */
    recordUid: string;

    /**
     * The data the user is sending for picking a snippet mention
     */
    body: OApiReqPickSnippetReplaceMentionV2DtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.pickSnippetAndReplaceProvisionValueV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation pickSnippetProvisionValue
   */
  static readonly PickSnippetProvisionValuePath =
    "/api/v1/finalDocSets/{recordUid}/pickSnippetProvisions";

  /**
   * Create a provision selected mention from a snippetOption from MultiplePdfProvision#getSnippetOptions().
   *
   * (adding the option to {@link MultiplePdfProvision#getOptions()})
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pickSnippetProvisionValue()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  pickSnippetProvisionValue$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.PickSnippetProvisionValuePath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>;
        })
      );
  }

  /**
   * Create a provision selected mention from a snippetOption from MultiplePdfProvision#getSnippetOptions().
   *
   * (adding the option to {@link MultiplePdfProvision#getOptions()})
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pickSnippetProvisionValue$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  pickSnippetProvisionValue(params?: {
    body?: RequestModel;
  }): Observable<OApiRespFullValMultiplePdfProvisionViewModel> {
    return this.pickSnippetProvisionValue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>) =>
          r.body as OApiRespFullValMultiplePdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation calculateCreateProvisionValue1
   */
  static readonly CalculateCreateProvisionValue1Path =
    "/api/v1/records/{recordUid}/calculated-provisions";

  /**
   * Calculate a provision.
   *
   * If it succeeds, it returns status 200 and a view of the provision. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateCreateProvisionValue1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateCreateProvisionValue1$Response(params: {
    /**
     * The unique id of the queue.
     */
    recordUid: string;

    /**
     * The data provided by the user, for calculating a provision.
     */
    body: OApiReqCalculateProvisionDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.CalculateCreateProvisionValue1Path,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>;
        })
      );
  }

  /**
   * Calculate a provision.
   *
   * If it succeeds, it returns status 200 and a view of the provision. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calculateCreateProvisionValue1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateCreateProvisionValue1(params: {
    /**
     * The unique id of the queue.
     */
    recordUid: string;

    /**
     * The data provided by the user, for calculating a provision.
     */
    body: OApiReqCalculateProvisionDtoModel;
  }): Observable<OApiRespFullValMultiplePdfProvisionViewModel> {
    return this.calculateCreateProvisionValue1$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>) =>
          r.body as OApiRespFullValMultiplePdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation calculateCreateProvisionValueV2
   */
  static readonly CalculateCreateProvisionValueV2Path =
    "/api/v2/records/{recordUid}/calculated-provisions";

  /**
   * Calculate a provision mention V2.
   *
   * If it succeeds, it returns status 200 and a view of the provision. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateCreateProvisionValueV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateCreateProvisionValueV2$Response(params: {
    /**
     * The unique id of the record.
     */
    recordUid: string;

    /**
     * The data provided by the user, for calculating a provision.
     */
    body: OApiReqCalculateMentionDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.CalculateCreateProvisionValueV2Path,
      "post"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Calculate a provision mention V2.
   *
   * If it succeeds, it returns status 200 and a view of the provision. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calculateCreateProvisionValueV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateCreateProvisionValueV2(params: {
    /**
     * The unique id of the record.
     */
    recordUid: string;

    /**
     * The data provided by the user, for calculating a provision.
     */
    body: OApiReqCalculateMentionDtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.calculateCreateProvisionValueV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation getProvisionValue
   */
  static readonly GetProvisionValuePath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/values/{mentionUid}";

  /**
   * Get a specific provision mention's value (from MultiplePdfProvision#getOptions()).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProvisionValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionValue$Response(params: {
    view: string;
  }): Observable<
    StrictHttpResponse<
      | OApiRespPartialPdfProvisionViewModel
      | OApiRespFullPdfProvisionViewModel
      | OApiRespSourceViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.GetProvisionValuePath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespPartialPdfProvisionViewModel
            | OApiRespFullPdfProvisionViewModel
            | OApiRespSourceViewModel
          >;
        })
      );
  }

  /**
   * Get a specific provision mention's value (from MultiplePdfProvision#getOptions()).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProvisionValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionValue(params: {
    view: string;
  }): Observable<
    | OApiRespPartialPdfProvisionViewModel
    | OApiRespFullPdfProvisionViewModel
    | OApiRespSourceViewModel
  > {
    return this.getProvisionValue$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespPartialPdfProvisionViewModel
            | OApiRespFullPdfProvisionViewModel
            | OApiRespSourceViewModel
          >
        ) =>
          r.body as
            | OApiRespPartialPdfProvisionViewModel
            | OApiRespFullPdfProvisionViewModel
            | OApiRespSourceViewModel
      )
    );
  }

  /**
   * Path part for operation deleteExistingProvisionValue
   */
  static readonly DeleteExistingProvisionValuePath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/values/{mentionUid}";

  /**
   * Delete a value option from MultiplePdfProvision#getOptions().
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteExistingProvisionValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExistingProvisionValue$Response(params: {
    view: "FULL_VAL" | "COMPLETE_VAL" | "NONE";
  }): Observable<
    StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.DeleteExistingProvisionValuePath,
      "delete"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>;
        })
      );
  }

  /**
   * Delete a value option from MultiplePdfProvision#getOptions().
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteExistingProvisionValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExistingProvisionValue(params: {
    view: "FULL_VAL" | "COMPLETE_VAL" | "NONE";
  }): Observable<OApiRespFullValMultiplePdfProvisionViewModel> {
    return this.deleteExistingProvisionValue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullValMultiplePdfProvisionViewModel>) =>
          r.body as OApiRespFullValMultiplePdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation deleteExistingProvisionValueV3
   */
  static readonly DeleteExistingProvisionValueV3Path =
    "/api/v3/records/{recordUid}/provisions/{provisionUid}/values/{mentionUid}";

  /**
   * Delete a selected provision mention.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteExistingProvisionValueV3()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExistingProvisionValueV3$Response(params: {
    /**
     * The user-facing id of the record for which to delete a provision mention.
     */
    recordUid: string;

    /**
     * The user-facing id of the provision for which to delete a mention.
     */
    provisionUid: string;

    /**
     * The user-facing id of the mention to delete.
     */
    mentionUid: string;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.DeleteExistingProvisionValueV3Path,
      "delete"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.path("mentionUid", params.mentionUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * Delete a selected provision mention.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteExistingProvisionValueV3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExistingProvisionValueV3(params: {
    /**
     * The user-facing id of the record for which to delete a provision mention.
     */
    recordUid: string;

    /**
     * The user-facing id of the provision for which to delete a mention.
     */
    provisionUid: string;

    /**
     * The user-facing id of the mention to delete.
     */
    mentionUid: string;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.deleteExistingProvisionValueV3$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation addAttributionForNestedGroupMention
   */
  static readonly AddAttributionForNestedGroupMentionPath =
    "/api/v1/records/{recordUid}/provisions/{provisionUid}/values/new-nested-attribution";

  /**
   * User highlighted to add an attribution for a NESTED_GROUP provision mention.
   *
   * If it succeeds, it returns status 200 and a view of the updated NESTED_GROUP provision mention. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAttributionForNestedGroupMention()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAttributionForNestedGroupMention$Response(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision mention value is to be attributed for.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose mention value is to be attributed for.
     */
    provisionUid: string;
    body?: OApiReqHighlightAttributeNestedProvisionValueDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.AddAttributionForNestedGroupMentionPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * User highlighted to add an attribution for a NESTED_GROUP provision mention.
   *
   * If it succeeds, it returns status 200 and a view of the updated NESTED_GROUP provision mention. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addAttributionForNestedGroupMention$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAttributionForNestedGroupMention(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision mention value is to be attributed for.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose mention value is to be attributed for.
     */
    provisionUid: string;
    body?: OApiReqHighlightAttributeNestedProvisionValueDtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.addAttributionForNestedGroupMention$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation appendToExistingProvisionValue
   */
  static readonly AppendToExistingProvisionValuePath =
    "/api/v1/records/{recordUid}/provisions/{provisionUid}/values/{mentionUid}/append-value";

  /**
   * User highlighted to append to an existing data field value.
   *
   * If it succeeds, it returns status 200 and a view of the updated data field mention. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appendToExistingProvisionValue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appendToExistingProvisionValue$Response(params: {
    /**
     * The unique id of the record whose provision mention value is to be appended to.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose mention value is to be appended to.
     */
    provisionUid: string;

    /**
     * The unique id of the mention whose value is to be appended to.
     */
    mentionUid: string;
    body?: OApiReqAppendToMentionValueDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.AppendToExistingProvisionValuePath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.path("mentionUid", params.mentionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * User highlighted to append to an existing data field value.
   *
   * If it succeeds, it returns status 200 and a view of the updated data field mention. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `appendToExistingProvisionValue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appendToExistingProvisionValue(params: {
    /**
     * The unique id of the record whose provision mention value is to be appended to.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose mention value is to be appended to.
     */
    provisionUid: string;

    /**
     * The unique id of the mention whose value is to be appended to.
     */
    mentionUid: string;
    body?: OApiReqAppendToMentionValueDtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.appendToExistingProvisionValue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation highlightUpdateNestedValue
   */
  static readonly HighlightUpdateNestedValuePath =
    "/api/v1/records/{recordUid}/provisions/{provisionUid}/values/nested-highlight";

  /**
   * User highlighted to add a value for a NESTED_GROUP provision's subfield.
   *
   * If it succeeds, it returns status 200 and a view of the updated NESTED_GROUP provision mention. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `highlightUpdateNestedValue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  highlightUpdateNestedValue$Response(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision mention value is to be appended to.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose mention value is to be appended to.
     */
    provisionUid: string;
    body?: OApiReqHighlightUpdateNestedProvisionValueDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.HighlightUpdateNestedValuePath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * User highlighted to add a value for a NESTED_GROUP provision's subfield.
   *
   * If it succeeds, it returns status 200 and a view of the updated NESTED_GROUP provision mention. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `highlightUpdateNestedValue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  highlightUpdateNestedValue(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision mention value is to be appended to.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose mention value is to be appended to.
     */
    provisionUid: string;
    body?: OApiReqHighlightUpdateNestedProvisionValueDtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.highlightUpdateNestedValue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation modifyAttributionsForNestedGroupMention
   */
  static readonly ModifyAttributionsForNestedGroupMentionPath =
    "/api/v1/records/{recordUid}/provisions/{provisionUid}/values/{mentionUid}/nested-attribution";

  /**
   * User modified the existing attributions for a NESTED_GROUP provision mention.
   *
   * If it succeeds, it returns status 200 and a view of the updated NESTED_GROUP provision mention. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifyAttributionsForNestedGroupMention()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyAttributionsForNestedGroupMention$Response(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision mention&#x27;s attributions are being modified.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose mention&#x27;s attributions are being modified.
     */
    provisionUid: string;

    /**
     * The unique id of the mention whose attributions are being modified.
     */
    mentionUid: string;
    body?: OApiReqModifyNestedProvisionMentionAttributionsDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.ModifyAttributionsForNestedGroupMentionPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.path("mentionUid", params.mentionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * User modified the existing attributions for a NESTED_GROUP provision mention.
   *
   * If it succeeds, it returns status 200 and a view of the updated NESTED_GROUP provision mention. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifyAttributionsForNestedGroupMention$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyAttributionsForNestedGroupMention(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision mention&#x27;s attributions are being modified.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose mention&#x27;s attributions are being modified.
     */
    provisionUid: string;

    /**
     * The unique id of the mention whose attributions are being modified.
     */
    mentionUid: string;
    body?: OApiReqModifyNestedProvisionMentionAttributionsDtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.modifyAttributionsForNestedGroupMention$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation renameProvisionMention
   */
  static readonly RenameProvisionMentionPath =
    "/api/v1/records/{recordUid}/provisions/{provisionUid}/values/{mentionUid}/rename";

  /**
   * User is renaming this provision mention.
   *
   * If it succeeds, it returns status 200 and a view of the updated data field mention. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renameProvisionMention()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameProvisionMention$Response(params: {
    /**
     * The unique id of the record whose provision mention is to be renamed.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose mention is to be renamed.
     */
    provisionUid: string;

    /**
     * The unique id of the mention which is to be renamed.
     */
    mentionUid: string;
    body?: OApiReqRenameMentionDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.RenameProvisionMentionPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.path("mentionUid", params.mentionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * User is renaming this provision mention.
   *
   * If it succeeds, it returns status 200 and a view of the updated data field mention. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `renameProvisionMention$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renameProvisionMention(params: {
    /**
     * The unique id of the record whose provision mention is to be renamed.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose mention is to be renamed.
     */
    provisionUid: string;

    /**
     * The unique id of the mention which is to be renamed.
     */
    mentionUid: string;
    body?: OApiReqRenameMentionDtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.renameProvisionMention$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation updateAllNestedSubfieldsMetadata
   */
  static readonly UpdateAllNestedSubfieldsMetadataPath =
    "/api/v1/records/{recordUid}/provisions/{provisionUid}/all-subfields-metadata";

  /**
   * User modifies the NESTED_GROUP provision mention's metadata for all subfields.
   *
   * If it succeeds, it returns status 200 and a view of the updated NESTED_GROUP provision mention. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAllNestedSubfieldsMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAllNestedSubfieldsMetadata$Response(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision mention&#x27;s metadata is being modified.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose mention&#x27;s metadata is being modified.
     */
    provisionUid: string;
    body?: OApiReqModifyNestedMentionAttributionsApiDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.UpdateAllNestedSubfieldsMetadataPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * User modifies the NESTED_GROUP provision mention's metadata for all subfields.
   *
   * If it succeeds, it returns status 200 and a view of the updated NESTED_GROUP provision mention. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAllNestedSubfieldsMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAllNestedSubfieldsMetadata(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision mention&#x27;s metadata is being modified.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose mention&#x27;s metadata is being modified.
     */
    provisionUid: string;
    body?: OApiReqModifyNestedMentionAttributionsApiDtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.updateAllNestedSubfieldsMetadata$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation updateDocProvisionValue
   */
  static readonly UpdateDocProvisionValuePath =
    "/api/v1/finalDocSets/{recordUid}/documents/{docUid}/provisions/{provisionUid}/values/{mentionUid}";

  /**
   * If either: 1) User chose a different document provision option 2) User edited the value on existing document provision option.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDocProvisionValue()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  updateDocProvisionValue$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespPartialValPdfProvisionViewModel
      | OApiRespFullValPdfProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.UpdateDocProvisionValuePath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespPartialValPdfProvisionViewModel
            | OApiRespFullValPdfProvisionViewModel
          >;
        })
      );
  }

  /**
   * If either: 1) User chose a different document provision option 2) User edited the value on existing document provision option.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDocProvisionValue$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  updateDocProvisionValue(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespPartialValPdfProvisionViewModel
    | OApiRespFullValPdfProvisionViewModel
  > {
    return this.updateDocProvisionValue$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespPartialValPdfProvisionViewModel
            | OApiRespFullValPdfProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespPartialValPdfProvisionViewModel
            | OApiRespFullValPdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation updateNestedSubfieldMetadata
   */
  static readonly UpdateNestedSubfieldMetadataPath =
    "/api/v1/records/{recordUid}/provisions/{provisionUid}/subfield-metadata";

  /**
   * User modifies the NESTED_GROUP provision mention's metadata.
   *
   * If it succeeds, it returns status 200 and a view of the updated NESTED_GROUP provision mention. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNestedSubfieldMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNestedSubfieldMetadata$Response(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision mention&#x27;s metadata is being modified.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose mention&#x27;s metadata is being modified.
     */
    provisionUid: string;
    body?: OApiReqModifyNestedMentionAttributionsApiDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.UpdateNestedSubfieldMetadataPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * User modifies the NESTED_GROUP provision mention's metadata.
   *
   * If it succeeds, it returns status 200 and a view of the updated NESTED_GROUP provision mention. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateNestedSubfieldMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNestedSubfieldMetadata(params: {
    /**
     * The unique id of the record whose NESTED_GROUP provision mention&#x27;s metadata is being modified.
     */
    recordUid: string;

    /**
     * The unique id of the NESTED_GROUP provision whose mention&#x27;s metadata is being modified.
     */
    provisionUid: string;
    body?: OApiReqModifyNestedMentionAttributionsApiDtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.updateNestedSubfieldMetadata$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation updateProvisionReviewCompletionInfo
   */
  static readonly UpdateProvisionReviewCompletionInfoPath =
    "/api/v1/records/{recordUid}/provisions/{provisionUid}/review-completion";

  /**
   * User updates the provision review completion info as review completed or not.
   *
   * If it succeeds, it returns status 200 and a view of the updated provision mention. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProvisionReviewCompletionInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProvisionReviewCompletionInfo$Response(params: {
    /**
     * The unique id of the record whose provision review completion info is being updated.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose review completion info is being updated.
     */
    provisionUid: string;
    body?: OApiReqUpdateProvisionReviewCompletionInfoApiDtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.UpdateProvisionReviewCompletionInfoPath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * User updates the provision review completion info as review completed or not.
   *
   * If it succeeds, it returns status 200 and a view of the updated provision mention. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateProvisionReviewCompletionInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProvisionReviewCompletionInfo(params: {
    /**
     * The unique id of the record whose provision review completion info is being updated.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose review completion info is being updated.
     */
    provisionUid: string;
    body?: OApiReqUpdateProvisionReviewCompletionInfoApiDtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.updateProvisionReviewCompletionInfo$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation updateRecordProvisionValue
   */
  static readonly UpdateRecordProvisionValuePath =
    "/api/v1/finalDocSets/{recordUid}/provisions/{provisionUid}/values/{mentionUid}/value";

  /**
   * User edited the value of an existing data field selected mention.
   *
   * If it succeeds, it returns status 200 and a view of the updated data field mention. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecordProvisionValue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecordProvisionValue$Response(params: {
    /**
     * The unique id of the record whose provision mention value is to be modified.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose mention value is to be modified.
     */
    provisionUid: string;

    /**
     * The unique id of the mention whose value is to be modified.
     */
    mentionUid: string;
    body?: OApiReqEditMentionValueDtoModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespPartialValPdfProvisionViewModel
      | OApiRespFullValPdfProvisionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.UpdateRecordProvisionValuePath,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.path("mentionUid", params.mentionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespPartialValPdfProvisionViewModel
            | OApiRespFullValPdfProvisionViewModel
          >;
        })
      );
  }

  /**
   * User edited the value of an existing data field selected mention.
   *
   * If it succeeds, it returns status 200 and a view of the updated data field mention. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRecordProvisionValue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecordProvisionValue(params: {
    /**
     * The unique id of the record whose provision mention value is to be modified.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose mention value is to be modified.
     */
    provisionUid: string;

    /**
     * The unique id of the mention whose value is to be modified.
     */
    mentionUid: string;
    body?: OApiReqEditMentionValueDtoModel;
  }): Observable<
    | OApiRespPartialValPdfProvisionViewModel
    | OApiRespFullValPdfProvisionViewModel
  > {
    return this.updateRecordProvisionValue$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespPartialValPdfProvisionViewModel
            | OApiRespFullValPdfProvisionViewModel
          >
        ) =>
          r.body as
            | OApiRespPartialValPdfProvisionViewModel
            | OApiRespFullValPdfProvisionViewModel
      )
    );
  }

  /**
   * Path part for operation updateRecordProvisionValueV2
   */
  static readonly UpdateRecordProvisionValueV2Path =
    "/api/v2/finalDocSets/{recordUid}/provisions/{provisionUid}/values/{mentionUid}/value";

  /**
   * User edited the value of an existing data field selected mention.
   *
   * If it succeeds, it returns status 200 and a view of the updated data field mention. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecordProvisionValueV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecordProvisionValueV2$Response(params: {
    /**
     * The unique id of the record whose provision mention value is to be modified.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose mention value is to be modified.
     */
    provisionUid: string;

    /**
     * The unique id of the mention whose value is to be modified.
     */
    mentionUid: string;
    body?: OApiReqEditMentionValueV2DtoModel;
  }): Observable<
    StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.UpdateRecordProvisionValueV2Path,
      "put"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.path("provisionUid", params.provisionUid, {});
      rb.path("mentionUid", params.mentionUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>;
        })
      );
  }

  /**
   * User edited the value of an existing data field selected mention.
   *
   * If it succeeds, it returns status 200 and a view of the updated data field mention. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRecordProvisionValueV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecordProvisionValueV2(params: {
    /**
     * The unique id of the record whose provision mention value is to be modified.
     */
    recordUid: string;

    /**
     * The unique id of the provision whose mention value is to be modified.
     */
    provisionUid: string;

    /**
     * The unique id of the mention whose value is to be modified.
     */
    mentionUid: string;
    body?: OApiReqEditMentionValueV2DtoModel;
  }): Observable<OApiRespFullProvisionAndHighlightDtoModel> {
    return this.updateRecordProvisionValueV2$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullProvisionAndHighlightDtoModel>) =>
          r.body as OApiRespFullProvisionAndHighlightDtoModel
      )
    );
  }

  /**
   * Path part for operation getProvisionPngSnippets
   */
  static readonly GetProvisionPngSnippetsPath =
    "/api/v1/finalDocSets/{recordUid}/htmlName/{htmlName}";

  /**
   * Get the actual files pointed to by PdfProvision#getPngSnippetFiles().
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProvisionPngSnippets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionPngSnippets$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMultiGetProvisionPngSnippetsModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionMentionService.GetProvisionPngSnippetsPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiGetProvisionPngSnippetsModel>;
        })
      );
  }

  /**
   * Get the actual files pointed to by PdfProvision#getPngSnippetFiles().
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProvisionPngSnippets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProvisionPngSnippets(params?: {}): Observable<OApiRespMultiGetProvisionPngSnippetsModel> {
    return this.getProvisionPngSnippets$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiGetProvisionPngSnippetsModel>) =>
          r.body as OApiRespMultiGetProvisionPngSnippetsModel
      )
    );
  }
}
