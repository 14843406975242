import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("TableColumnsModel")
export class TableColumnsModel {
  @JsonProperty("fieldName", String)
  prop = "";

  @JsonProperty("uiName", String)
  name = "";

  @JsonProperty("fieldType", String)
  type = "";

  @JsonProperty("resizeable", Boolean, true)
  resizeable = false;

  @JsonProperty("sortable", Boolean, true)
  sortable = true;
}
