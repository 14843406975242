import {
  MyFile,
  NewUploadService,
} from "@@intelease/web/ui/src/lib/itls-new-upload";

export interface DocumentReorderingEvent {
  pre: MyFile[];
  post: MyFile[];
}

export class UploadedDocSetModel {
  public static readonly FILES__FOLDER_PATH = "files";

  folderPath: string;

  myFiles: MyFile[];

  name: string;

  documentReorderingEvents: DocumentReorderingEvent[];

  lastDocumentReorderingEvent: DocumentReorderingEvent;

  constructor(
    folderPath: string,
    myFiles: MyFile[],
    name: string,
    documentReorderingEvents: DocumentReorderingEvent[],
    lastDocumentReorderingEvent: DocumentReorderingEvent
  ) {
    this.folderPath = folderPath;
    this.myFiles = myFiles;
    this.name = name;
    this.documentReorderingEvents = documentReorderingEvents;
    this.lastDocumentReorderingEvent = lastDocumentReorderingEvent;
  }

  pickDefaultNameIfEmpty() {
    if (!this.name.length) {
      if (UploadedDocSetModel.FILES__FOLDER_PATH === this.folderPath) {
        this.name = NewUploadService.getFilenameWithoutExtension(
          this.myFiles[0].file
        );
      } else {
        const folderNameStartIdx = this.folderPath.lastIndexOf("/");
        if (folderNameStartIdx === -1) {
          this.name = this.folderPath;
        } else {
          // +1 because first character is the path separator itself
          this.name = this.folderPath.substring(folderNameStartIdx + 1);
        }
      }
    }
  }
}
