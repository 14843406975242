import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DynamicTableComponent } from "./dynamic-table.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FormsModule } from "@angular/forms";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { AngularResizedEventModule } from "@@intelease/web/ui/src/lib/itls-angular-resize-event";
import { MatMenuModule } from "@angular/material/menu";

const NGX_BOOTSTRAP = [BsDropdownModule.forRoot()];

@NgModule({
  declarations: [DynamicTableComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxDatatableModule,
    ...NGX_BOOTSTRAP,
    AngularResizedEventModule,
    MatMenuModule,
  ],
  exports: [DynamicTableComponent],
})
export class DynamicTableModule {}
