import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { cloneDeep, isEmpty, isEqual, pickBy } from "lodash";
import { WebAbstractionProvisionService } from "@@intelease/web/abstraction-page/src/lib/services/web-abstraction-provision.service";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { InteleaseNotificationService } from "@@intelease/web/intelease/services";
import {
  PartialValProvisionValueModel,
  RelatedDocModel,
} from "@@intelease/web/common/models";
import {
  FinalAbstractModel,
  ProvisionBoxInitTypesEnum,
} from "@@intelease/app-models/abstract-review";
import { ProvisionBoxActionTypeEnum } from "@@intelease/web/abstraction-page/src/lib/enums";
import { AbstractReviewFacade } from "@@intelease/app-state/abstract-review";
import {
  AbstractTextHighlightsService,
  WebAbstractionPageService,
} from "@@intelease/web/abstraction-page/src/lib/services";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { TableProvisionModalService } from "@@intelease/web/common/modals";
import { ValidatorsHelper } from "@@intelease/web/utils";
import { FormComponent } from "@@intelease/schema-form";
import { Utilities } from "@@intelease/web/common/utils";
import { DynamicTableComponent } from "@@intelease/web/ui";
import { AppAuthorities } from "@@intelease/web/intelease/constants";
import { ProvisionTypeEnum } from "@@intelease/app-models/provision";
import { ModalsResponseTypeEnum } from "@intelease/enums";
import { ProvisionBoxHelperService } from "@@intelease/web/abstraction-page/src/lib/services/provision-box-helper.service";
import { has } from "lodash";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-mention",
  templateUrl: "./mention.component.html",
  styleUrls: ["./mention.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  @Input() mention = undefined;
  @Input() provision = undefined;
  @Input() selectedAbstractUid: string;
  @Input() selectedDocument: RelatedDocModel;
  @Input() abstractDocument: FinalAbstractModel;
  @Input() isInNewBrowserTab: boolean;
  @Output() mentionCreated: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  reloadProvisionData: EventEmitter<{
    mention?;
    shouldNotMutateProvision?: boolean;
    type?: "UPDATE" | "ADD" | "ADD_MENTION_FORM_READER";
  }> = new EventEmitter<{
    mention?;
    shouldNotMutateProvision?: boolean;
    type?: "UPDATE" | "ADD" | "ADD_MENTION_FORM_READER";
  }>();
  @Output()
  toggleProvisionLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  gotoMentionHighlightOnViewer: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("dynamicTable") dynamicTable: DynamicTableComponent;
  provisionDataMap = PROVISIONS_DATA_CONST;
  @Input() provisionBoxInitTypes: ProvisionBoxInitTypesEnum;
  @Input() selectedMention: PartialValProvisionValueModel;
  provisionBoxInitTypesEnum = ProvisionBoxInitTypesEnum;
  @ViewChild("sfForm") sfForm: FormComponent;
  private originalValueType: string;
  private originalValue: { value: any };
  @Input() editable: boolean;
  AUTH = AppAuthorities;
  isSelectedDocumentFullyLoaded = false;

  constructor(
    private webAbstractionProvisionService: WebAbstractionProvisionService,
    private cdr: ChangeDetectorRef,
    public readonly abstractReviewFacade: AbstractReviewFacade,
    private webAbstractionPageService: WebAbstractionPageService,
    private tableProvisionModalService: TableProvisionModalService,
    private inteleaseNotificationService: InteleaseNotificationService
  ) {}

  ngOnInit(): void {
    this.initTableMention();
    this.initListeners();
  }

  private initListeners(): void {
    this.abstractReviewFacade.isSelectedDocumentFullyLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp) => {
        this.isSelectedDocumentFullyLoaded = resp;
        this.cdr.detectChanges();
      });
  }

  private initTableMention() {
    this.originalValueType = this.mention.valueType;
    if (this.isTableProvision(this.mention.valueType)) {
      this.reloadTableTypeMention();
    }
  }

  private reloadTableTypeMention() {
    const model = cloneDeep(this.mention.model);
    this.mention.model = undefined;
    // setTimeout(() => {
    this.mention.model = model;
    try {
      this.cdr.detectChanges();
    } catch (e) {} // eslint-disable-line
    // }, 1);
  }

  onNonTableMentionModelChange(evt) {
    const { value } = evt;
    if (evt && value && !isEmpty(value)) {
      if (
        this.mention.source !== "TEMP_NEW_TAB" &&
        !this.mention.isTempHighlight
      ) {
        if (
          !isEqual(pickBy(cloneDeep(this.mention.model)), value) &&
          !this.provision.isLoading
        ) {
          if (this.isValueEmpty(value)) {
            if (this.sfForm) {
              // this happens when we're restoring 'model' from it's original 'valueType'
              //    (User didn't input anything but just changed provision format type)
              if (
                this.originalValueType === this.mention.valueType &&
                this.originalValue
              ) {
                this.originalValue = undefined;
              } else {
                this.inteleaseNotificationService.openSnackBar(
                  "Cannot save an empty value for a provision! Please delete this mention if needed."
                );
                this.sfForm.reloadValueFromModel();
              }
            }
          } else {
            if (this.isValidMentionValue(this.mention.valueType, value, true)) {
              const _mention = cloneDeep(this.mention); //FIXME state
              _mention.model = value;
              this.onUpdateMention(_mention);
              this.updateMentionHighlight(this.mention);
            }
          }
        }
      } else if (
        (this.mention.source === "TEMP_NEW_TAB" ||
          this.mention.isTempHighlight) &&
        !isEqual(this.mention.model, value) &&
        !isEmpty(value)
      ) {
        if (this.isValidMentionValue(this.mention.valueType, value)) {
          this.mention.model = value;
        }
      }
    }
  }

  private isValidMentionValue(
    mentionValueType: string,
    mentionValue: any,
    isExplicitSave: boolean = false
  ): boolean {
    let errorMessage;
    switch (mentionValueType) {
      case ProvisionTypeEnum.EMAIL: {
        const { localPart } = mentionValue;
        errorMessage = ValidatorsHelper.isValidEmailAddress(localPart)
          ? undefined
          : "Invalid email address";
        break;
      }
      case ProvisionTypeEnum.DOUBLE: {
        errorMessage = ValidatorsHelper.isValidDouble(mentionValue)
          ? undefined
          : "Please enter value and unit";
        break;
      }
      case ProvisionTypeEnum.MONEY: {
        errorMessage = ValidatorsHelper.isValidMoney(mentionValue)
          ? undefined
          : "Please enter value and currency";
        break;
      }
    }
    if (errorMessage) {
      if (isExplicitSave) {
        this.inteleaseNotificationService.openSnackBar(errorMessage);
      }
      return false;
    } else {
      return true;
    }
  }

  onTableMentionModelChange() {
    this.mention.isTouched = true;
    if (this.selectedMention) {
      ProvisionBoxHelperService.tableMentionTouched$.next({
        mentionUid: this.selectedMention.uid,
        provisionUid: this.provision.provisionInfo.uid,
        isTouched: true,
      });
    }
  }

  public onSaveNewMentionClick() {
    if (this.isValueEmpty(this.mention.model)) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot save an empty value for a provision!"
      );
    } else {
      if (
        this.isValidMentionValue(
          this.mention.valueType,
          this.mention.model,
          true
        )
      ) {
        this.toggleProvisionLoading.emit(true);
        this.createNewManualMention();
      }
    }
  }

  public onSaveNewMentionCreatedFromReaderClick() {
    if (this.isValueEmpty(this.mention.model)) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot save an empty value for a provision!"
      );
    } else {
      if (
        this.isValidMentionValue(
          this.mention.valueType,
          this.mention.model,
          true
        )
      ) {
        this.toggleProvisionLoading.emit(true);
        this.saveNewMentionCreatedFromReader(cloneDeep(this.mention));
      }
    }
  }

  private saveNewMentionCreatedFromReader(mention) {
    const { formatValues, highlightType } = mention;
    let newVal = this.webAbstractionProvisionService
      .prepareProvisionValue(mention.valueType)
      .prepareProvisionValueForSave(mention);
    if (this.isTableProvision(mention.valueType)) {
      newVal = this.prepareTableProvisionData(mention);
    }
    formatValues[mention.valueType] = newVal;
    this.preparePolarData(mention);
    const {
      indexInText,
      surroundingSentence,
      textSurroundingOriginalProvision,
    } = mention;
    this.webAbstractionProvisionService
      .createHighlightedProvisionValueOption(
        this.selectedAbstractUid,
        this.provision.provisionInfo.uid,
        mention.uid,
        "",
        "",
        mention.page,
        mention.highlightedText,
        mention.docAbstractUid,
        newVal,
        mention.isTwoPageTextHighlight
          ? mention.provisionHighlight
          : [mention.provisionHighlight],
        indexInText,
        surroundingSentence,
        textSurroundingOriginalProvision,
        mention.valueType,
        formatValues,
        highlightType
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        () => {
          mention.isTouched = false;
          AbstractTextHighlightsService.onTextHighlightActionFromProvisionBox.next(
            {
              type: ProvisionBoxActionTypeEnum.TEMP_HIGHLIGHT_SAVED,
            }
          );
          this.provision.multiplePdfProvision.options.splice(0, 1);
          this.reloadProvisionData.emit({
            type: "ADD_MENTION_FORM_READER",
          });
          // ProvisionBoxHelperService.removeTempHighlightedMention$.next()
          this.toggleProvisionLoading.emit(false);
        },
        () => {
          // ProvisionBoxHelperService.removeTempHighlightedMention$.next()
          this.toggleProvisionLoading.emit(false);
        }
      );
  }

  private preparePolarData(mention) {
    // start of Polar code
    // TODO: should place below code to polar
    if (mention.isTwoPageTextHighlight) {
      mention.provisionHighlight.forEach((textHighlight) => {
        // TODO: both two pages text highlight should have same itlsData
        if (textHighlight.itlsData) {
          delete textHighlight.itlsData.highlighted;
          textHighlight.itlsData.highlightType = "HEAVY";
        }
      });
    } else {
      delete mention.provisionHighlight.itlsData.highlighted;
      mention.provisionHighlight.itlsData.highlightType = "HEAVY";
    }
    // end of polar code
  }

  private createNewManualMention() {
    let value;
    if (this.isTableProvision(this.mention.valueType)) {
      value = cloneDeep(this.prepareTableProvisionData(this.mention));
    } else {
      value = this.webAbstractionProvisionService
        .prepareProvisionValue(this.mention.valueType)
        .prepareProvisionValueForSave(this.mention);
    }
    this.webAbstractionProvisionService
      .manualCreateProvisionValueOption(
        this.selectedAbstractUid,
        this.selectedDocument.uid,
        1, // this.getCurrentPage(), //TODO(mohammad-haji) New Provision Box
        this.provision.provisionInfo.uid,
        "",
        "",
        value,
        "",
        this.mention.valueType
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        () => {
          this.mention.isTouched = false;
          this.mentionCreated.emit();
        },
        () => this.toggleProvisionLoading.emit(false)
      );
  }

  onMentionValueTypeChange() {
    this.webAbstractionProvisionService
      .getProvisionFormSchemaByType(
        this.mention.valueType || this.mention.type,
        this.provision.multiplePdfProvision
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((provisionFormSchema) => {
        const model =
          cloneDeep(this.mention.formatValues[this.mention.valueType]) ||
          (this.mention.valueType === ProvisionTypeEnum.COMMON_NOUN ||
          this.mention.valueType === ProvisionTypeEnum.PROPER_NOUN
            ? ""
            : undefined);
        if (this.isTableProvision(this.mention.valueType)) {
          if (isEmpty(model)) {
            this.mention.value =
              this.webAbstractionProvisionService.getEmptyTableValue();
            this.mention.model = this.webAbstractionProvisionService
              .prepareProvisionValue(PROVISIONS_DATA_CONST.TABLE.name)
              .prepareProvisionOptionModel(this.mention);
          } else {
            this.mention.value = model;
            this.mention.model = this.webAbstractionProvisionService
              .prepareProvisionValue(PROVISIONS_DATA_CONST.TABLE.name)
              .prepareProvisionOptionModel(this.mention);
          }
          setTimeout(() => {
            this.dynamicTable.reset(this.mention.model);
          }, 1);
        } else {
          this.mention.value = model;
          this.mention.model = this.webAbstractionProvisionService
            .prepareProvisionValue(this.mention.valueType)
            .prepareProvisionOptionModel(this.mention);
          this.mention.formSchema = cloneDeep(provisionFormSchema);
        }
      });
  }

  private updateMentionHighlight(mention) {
    const { page, uid, valueType } = mention;
    const payload = {
      page,
      mentionUid: uid,
      valueType,
    };
    AbstractTextHighlightsService.onTextHighlightActionFromProvisionBox.next({
      type: ProvisionBoxActionTypeEnum.UPDATE_TEXT_HIGHLIGHT_TYPE,
      payload: payload,
    });
  }

  onGotoMentionHighlightOnViewer(mention) {
    this.gotoMentionHighlightOnViewer.emit({ ...mention });
  }

  private prepareTableProvisionData(mention: PartialValProvisionValueModel) {
    return this.webAbstractionPageService.prepareTableProvisionOptionValue(
      mention,
      this.dynamicTable
    );
  }

  onUpdateTableMention(mention: PartialValProvisionValueModel) {
    if (this.isValueEmpty(this.mention.model)) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot save an empty value for a provision! Please delete this mention if needed."
      );
    } else {
      this.onUpdateMention(mention);
    }
  }

  private onUpdateMention(_mention) {
    const mention = cloneDeep(_mention);
    const { valueType, formatValues } = mention;
    this.toggleProvisionLoading.emit(true);
    let newVal;
    if (this.isTableProvision(valueType)) {
      newVal = this.prepareTableProvisionData(mention);
    } else {
      newVal = this.webAbstractionProvisionService
        .prepareProvisionValue(valueType)
        .prepareProvisionValueForSave(mention);
      if (formatValues[PROVISIONS_DATA_CONST.TABLE.name]) {
        formatValues[PROVISIONS_DATA_CONST.TABLE.name] =
          this.mention.formatValues[PROVISIONS_DATA_CONST.TABLE.name];
      }
    }
    formatValues[valueType] = newVal;
    this.webAbstractionProvisionService
      .updateProvisionValueOption(
        this.selectedAbstractUid,
        this.provision.provisionInfo.uid,
        mention.uid,
        mention.notes,
        mention.sectionHeader,
        newVal,
        valueType,
        formatValues
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (res) => {
          this.originalValue = undefined;
          this.originalValueType = _mention.valueType;
          this.mention.model = _mention.model;
          this.mention.value = newVal;
          // this.mention.formatValues[this.mention.valueType] = this.mention.model
          mention.value = newVal;
          //TODO(mohammad-haji) New Provision Box
          // this.refreshOutput.emit({type: ProvisionBoxActionTypeEnum.UPDATE});
          if (this.isTableProvision(valueType)) {
            this.mention.value = res.value;
            this.mention.model = undefined;
            this.mention.model = this.webAbstractionProvisionService
              .prepareProvisionValue(valueType)
              .prepareProvisionOptionModel(cloneDeep(mention));
            this.reloadProvisionData.emit({
              mention,
              shouldNotMutateProvision: false,
              type: "UPDATE",
            });
            this.mention.isTouched = false;
            ProvisionBoxHelperService.tableMentionTouched$.next({
              mentionUid: this.selectedMention.uid,
              provisionUid: this.provision.provisionInfo.uid,
              isTouched: false,
            });
          }
          this.reloadProvisionData.emit({
            mention,
            shouldNotMutateProvision: true,
            type: "UPDATE",
          });
          this.abstractReviewFacade.updateSelectedMentionLocally({
            provisionUid: this.provision.provisionInfo.uid,
            mention,
          });
        },
        () => this.toggleProvisionLoading.emit(false)
      );
  }

  onExpandMentionTableClick(_mention: PartialValProvisionValueModel) {
    const isEditable = this.editable;
    const mention = cloneDeep(_mention);
    mention.value = this.prepareTableProvisionData(mention);
    mention.model = this.webAbstractionProvisionService
      .prepareProvisionValue(mention.valueType)
      .prepareProvisionOptionModel(mention);
    const modalInputModel = new ModalInputModel();
    modalInputModel.payload = {
      value: mention.model,
      editable: isEditable,
      selectedProvision: this.provision.provisionInfo,
    };
    const modalRef =
      this.tableProvisionModalService.openTableProvisionModal(modalInputModel);
    modalRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((modalRes) => {
        if (modalRes.type === ModalsResponseTypeEnum.CLOSE) {
          delete modalRes.type;
          if (isEditable) {
            this.mention.value = modalRes.data.tableData;
            this.mention.model = this.webAbstractionProvisionService
              .prepareProvisionValue(this.mention.valueType)
              .prepareProvisionOptionModel(this.mention);
            // this.mention.rows = modalRes.data.rows;
            const model = cloneDeep(this.mention.model);
            this.mention.model = undefined;
            this.cdr.detectChanges();
            setTimeout(() => {
              this.mention.model = model;
              this.cdr.detectChanges();
            }, 1);
          }
        }
      });
  }

  /**
   * is type of table provision
   * @param data
   */
  private isTableProvision(type: any): boolean {
    return type === PROVISIONS_DATA_CONST.TABLE.name;
  }

  //TODO(reza) remove duplication (form.component.ts)
  /** don't allow empty object / empty array or an object with all fields 'null' for provision mentions...
   *    reset back to original value if that's the case
   */
  private isValueEmpty(value) {
    if (this.isTableProvision(this.mention.valueType)) {
      return this.areRowsEmpty(this.dynamicTable.rows);
    } else {
      return (
        !value ||
        isEmpty(value) ||
        (value.value &&
          has(value.value, "length") &&
          value.value.length === 0) || // empty array / string
        Utilities.areAllFieldsNull(value)
      );
    }
  }

  private areRowsEmpty(rows: any[]): boolean {
    if (!isEmpty(rows)) {
      for (const row of rows) {
        for (const rowKey in row) {
          if (has(row, rowKey)) {
            if (!isEmpty(row[rowKey])) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }
}
