import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("LogoutDtoModel")
export class LogoutDtoModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("accessToken", String)
  accessToken = "";
}
