import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { SearchSuggestionItemModel } from "@@intelease/web/common/models/search";

@Component({
  selector: "itls-header-common-suggestionitem",
  templateUrl: "./suggestion-item.component.html",
  styleUrls: ["./suggestion-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionItemComponent implements OnInit {
  @Input() suggestion: SearchSuggestionItemModel;

  constructor() {}

  ngOnInit() {}

  isHighlighted(text: string, searchCategoryType: string) {
    const wrappedText = `<em>${text}</em>`; // highlight texts are wrapped in <em> tag from backend
    for (const searchCategory of this.suggestion.searchCategories) {
      if (searchCategory.searchCategory === searchCategoryType.toString()) {
        for (const highlight of searchCategory.highlights) {
          if (wrappedText === highlight) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
