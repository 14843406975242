import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("HeaderUserModel")
export class HeaderUserModel {
  static view = "header";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("firstName", String)
  firstName = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("email", String)
  email = "";

  @JsonProperty("flags", [String])
  flags: string[] = [];

  @JsonProperty("accountUid", String)
  accountUid = "";

  // TODO(apoorv): This should also include a small version of user's profile photo.
}
