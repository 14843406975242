import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("UltraLightDocumentModel")
export class UltraLightDocumentModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";
}
