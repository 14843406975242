import { Component, HostBinding, HostListener, Input } from "@angular/core";
import { inteleaseAnimations } from "@@intelease/web/intelease/animations";
import { ItlsNavigationComponent } from "@@intelease/web/intelease/components/navigation/navigation.component";

@Component({
  selector: "itls-nav-horizontal-collapsable",
  templateUrl: "./collapsable.component.html",
  styleUrls: ["./collapsable.component.scss"],
  animations: inteleaseAnimations,
})
export class ItlsNavHorizontalCollapsableComponent {
  tbConfig: any;
  isOpen = false;

  @HostBinding("class")
  classes = "nav-collapsable nav-item";

  @Input()
  item: any;

  expectedAuthorities: string[];

  ngOnInit(): void {
    // Subscribe to config changes
    this.expectedAuthorities =
      ItlsNavigationComponent.determineExpectedAuthorities(this.item.id);
  }

  @HostListener("mouseenter")
  open(): void {
    this.isOpen = true;
  }

  @HostListener("mouseleave")
  close(): void {
    this.isOpen = false;
  }
}
