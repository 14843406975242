/* tslint:disable */
/* eslint-disable */
import { RecordOwnerDtoModel } from "./record-owner-dto-model";
import { RecordStatusDtoModel } from "./record-status-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RecentRecordDtoModel")
export class RecentRecordDtoModel {
  @JsonProperty("createdBy", RecordOwnerDtoModel, true)
  createdBy?: RecordOwnerDtoModel = undefined;

  @JsonProperty("fileSize", Number, true)
  fileSize?: number = undefined;

  @JsonProperty("fileType", String, true)
  fileType?: string = "";

  @JsonProperty("location", String, true)
  location?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("numDocs", Number, true)
  numDocs?: number = undefined;

  @JsonProperty("shared", Boolean, true)
  shared?: boolean = undefined;

  @JsonProperty("status", RecordStatusDtoModel, true)
  status?: RecordStatusDtoModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
