/* tslint:disable */
/* eslint-disable */
import { FinalAbstractTaskFrequencyDtoModel } from "./final-abstract-task-frequency-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FinalAbstractTaskSummaryDtoModel")
export class FinalAbstractTaskSummaryDtoModel {
  @JsonProperty("statusToFreq", Any, true)
  statusToFreq?: {
    [key: string]: FinalAbstractTaskFrequencyDtoModel;
  } = {};
}
