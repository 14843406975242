<div class="itls-breadcrumb-item">
  <a
    *ngIf="
      breadcrumbItem?.type === BreadcrumbItemTypeEnum.LINK ||
      (breadcrumbItem?.type !== BreadcrumbItemTypeEnum.NORMAL &&
        breadcrumbItem?.type !== BreadcrumbItemTypeEnum.LIST &&
        breadcrumbItem?.type !== BreadcrumbItemTypeEnum.ELLIPSE &&
        breadcrumbItem?.type !== BreadcrumbItemTypeEnum.SEARCH_BOX &&
        breadcrumbItem?.link)
    "
    [ngClass]="{ root: isRoot }"
    [routerLink]="breadcrumbItem.link"
  >
    <span
      [matTooltip]="breadcrumbItem?.title"
      #linkTextSpan
      [matTooltipDisabled]="
        !(linkTextSpan.offsetWidth < linkTextSpan.scrollWidth)
      "
      class="allow-text-overflow"
      [style.max-width]="(isRoot ? 360 : contentMaxWidth) + 'px'"
      >{{ breadcrumbItem.title }}</span
    >
  </a>
  <div *ngIf="breadcrumbItem?.type === BreadcrumbItemTypeEnum.NO_LINK">
    <span
      class="cursor-default"
      [style.max-width]="(isRoot ? 360 : contentMaxWidth) + 'px'"
    >
      {{ breadcrumbItem.title }}
    </span>
  </div>
  <a
    *ngIf="
      breadcrumbItem?.type === BreadcrumbItemTypeEnum.NORMAL ||
      (breadcrumbItem?.type !== BreadcrumbItemTypeEnum.LIST &&
        breadcrumbItem?.type !== BreadcrumbItemTypeEnum.NO_LINK &&
        breadcrumbItem?.type !== BreadcrumbItemTypeEnum.ELLIPSE &&
        breadcrumbItem?.type !== BreadcrumbItemTypeEnum.SEARCH_BOX &&
        !breadcrumbItem?.link &&
        breadcrumbItem?.isAbstractProcessingCompleted)
    "
    [ngClass]="{ root: isRoot }"
    [routerLink]="
      '/individual-abstract/' + this.breadcrumbItem.key + '/related-documents'
    "
  >
    <span
      [matTooltip]="breadcrumbItem?.title"
      #normalTextSpan
      [matTooltipDisabled]="
        !(normalTextSpan.offsetWidth < normalTextSpan.scrollWidth)
      "
      class="allow-text-overflow"
      [style.max-width]="(isRoot ? 360 : contentMaxWidth) + 'px'"
    >
      {{ breadcrumbItem.title }}
    </span>
  </a>
  <div
    *ngIf="
      breadcrumbItem?.type === BreadcrumbItemTypeEnum.NORMAL ||
      (breadcrumbItem?.type !== BreadcrumbItemTypeEnum.LIST &&
        breadcrumbItem?.type !== BreadcrumbItemTypeEnum.NO_LINK &&
        breadcrumbItem?.type !== BreadcrumbItemTypeEnum.ELLIPSE &&
        breadcrumbItem?.type !== BreadcrumbItemTypeEnum.SEARCH_BOX &&
        !breadcrumbItem?.link &&
        !breadcrumbItem?.isAbstractProcessingCompleted)
    "
    [ngClass]="{ root: isRoot }"
  >
    <span
      [matTooltip]="breadcrumbItem?.title"
      #normalTextSpan
      [matTooltipDisabled]="
        !(normalTextSpan.offsetWidth < normalTextSpan.scrollWidth)
      "
      class="allow-text-overflow"
      [style.max-width]="(isRoot ? 360 : contentMaxWidth) + 'px'"
    >
      {{ breadcrumbItem.title }}
    </span>
  </div>
  <div
    class="list-item"
    *ngIf="breadcrumbItem?.type === BreadcrumbItemTypeEnum.LIST"
  >
    <span
      [matTooltip]="breadcrumbItem?.title"
      #listTextSpan
      [matTooltipDisabled]="
        !(listTextSpan.offsetWidth < listTextSpan.scrollWidth)
      "
      class="allow-text-overflow"
      [style.max-width]="(isRoot ? 360 : contentMaxWidth - 30) + 'px'"
    >
      <span class="list-item-title-prefix">{{
        breadcrumbItem?.titlePrefix
      }}</span>
      {{ breadcrumbItem?.title }}
    </span>
    <button
      style="width: 30px; height: 30px; line-height: 30px"
      mat-icon-button
      [matMenuTriggerFor]="breadcrumbList"
    >
      <mat-icon svgIcon="icon-arrow-down"></mat-icon>
    </button>
    <mat-menu
      #breadcrumbList="matMenu"
      [xPosition]="screenWidth <= 1024 ? 'before' : 'after'"
    >
      <div [style.max-height]="breadcrumbItem?.useScrollbar ? '204px' : null">
        <button
          mat-menu-item
          *ngFor="let child of breadcrumbItem?.children"
          (click)="child.onClicked ? child.onClicked() : null"
          [matTooltip]="child.title"
          [matTooltipShowDelay]="1000"
        >
          <span class="list-item-title-prefix">{{ child.titlePrefix }}</span>
          <span>{{ child.title }}</span>
        </button>
      </div>
    </mat-menu>
  </div>
  <div
    class="list-item"
    *ngIf="breadcrumbItem?.type === BreadcrumbItemTypeEnum.ELLIPSE"
  >
    <button
      style="width: 30px; height: 30px; line-height: 30px"
      mat-icon-button
      matTooltip="Show Path"
      [matMenuTriggerFor]="breadcrumbEllipse"
    >
      . . .
    </button>
    <mat-menu #breadcrumbEllipse="matMenu">
      <button
        mat-menu-item
        *ngFor="let child of breadcrumbItem?.children"
        (click)="onEllipseChildClicked(child)"
      >
        <span>{{ child.title }}</span>
      </button>
    </mat-menu>
  </div>
  <div
    class="list-item"
    *ngIf="breadcrumbItem?.type === BreadcrumbItemTypeEnum.SEARCH_BOX"
  >
    <span [style.color]="breadcrumbItem?.title === 'New' ? '#5168ff' : null">{{
      breadcrumbItem.title
    }}</span>
    <button
      style="width: 30px; height: 30px; line-height: 30px"
      mat-icon-button
      [matMenuTriggerFor]="searchBoxMenu"
    >
      <mat-icon svgIcon="icon-arrow-down"></mat-icon>
    </button>
    <mat-menu #searchBoxMenu="matMenu" class="searchbox-breadcrumb-menu">
      <div
        style="position: relative; height: 100%"
        (click)="$event.stopPropagation()"
      >
        <ng-container>
          <div class="p-8" style="margin: 0 1%">
            <mat-form-field style="margin-bottom: -18px">
              <mat-label>{{
                breadcrumbItem.searchBoxData?.placeholder
              }}</mat-label>
              <input
                type="text"
                matInput
                [formControl]="searchBoxControl"
                itlsFocusIn
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayNull"
                (optionSelected)="onOptionSelection($event)"
              >
                <mat-option
                  *ngFor="
                    let searchBoxOption of filteredSearchBoxOptions | async
                  "
                  [value]="searchBoxOption"
                  [id]="searchBoxOption.uid"
                >
                  {{ searchBoxOption.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </mat-menu>
  </div>
  <mat-icon svgIcon="icon-arrow-right" *ngIf="showArrowIcon"></mat-icon>
</div>
