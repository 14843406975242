/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateCalendarQueryDtoModel")
export class CreateCalendarQueryDtoModel {
  @JsonProperty("active", Boolean, true)
  active?: boolean = undefined;

  @JsonProperty("color", String, true)
  color?: string = "";

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";
}
