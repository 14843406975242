import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  ProvisionFormService,
  StrictHttpResponse,
} from "@@intelease/api-models/adex-api-model-src";
import { downloadFile } from "@intelease/utils";

@Component({
  selector: "il-provision-form-export",
  templateUrl: "./itls-provision-form-export.component.html",
  styleUrls: ["./itls-provision-form-export.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsProvisionFormExportComponent {
  @Input() provisionFormUid: string;
  @Input() historyExport: boolean;
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  exportFileType = [{ value: "TSV", viewValue: "TSV" }];
  selectedFileType = "TSV";
  actionMaps: { [key: string]: any };

  constructor(private provisionFormService: ProvisionFormService) {}

  onCancelClick() {
    this.cancel.emit();
  }

  onExportClick() {
    this.onButtonActionClick(this.selectedFileType);
  }

  onButtonActionClick(actionKey: any): void {
    this.onNodeExport(actionKey);
    this.export.emit();
  }

  private onNodeExport(actionKey: any) {
    const exportType:
      | "TSV"
      | "DOCX"
      | "XLSX"
      | "CSV"
      | "HTML"
      | "PDF"
      | "ZIP"
      | "WT_TSV" = actionKey;
    const params = {
      exportType: exportType,
      provisionFormUid: this.provisionFormUid,
    };
    if (exportType === "TSV") {
      if (this.historyExport) {
        this.provisionFormService
          .exportFormHistory$Response(params)
          .subscribe((res) => {
            this.handleBlobResponseAndDownloadFile(res);
          });
      } else {
        this.provisionFormService
          .exportForm$TabSeparatedValues$Response(params)
          .subscribe((res) => {
            this.handleBlobResponseAndDownloadFile(res);
          });
      }
    }
  }

  private handleBlobResponseAndDownloadFile(res: StrictHttpResponse<any>) {
    const contentDisposition = res.headers.get("Content-Disposition");
    let filename = `provision-form_${this.provisionFormUid}.tsv`;
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        let encodedFilename = matches[1].replace(/['"]/g, "");
        // Check and strip the file extension if it's part of the encoded string
        const dotIndex = encodedFilename.lastIndexOf(".");
        if (dotIndex !== -1) {
          // Extract the base64 part and decode it
          encodedFilename = encodedFilename.substring(0, dotIndex);
          const decodedFilename = atob(encodedFilename);
          // Reattach the file extension
          filename = decodedFilename + ".tsv";
        } else {
          // If no file extension is found, just decode what's there
          filename = atob(encodedFilename);
        }
      }
    }
    const contentType = res.headers.get("Content-Type") || "text/plain";
    downloadFile(`${filename}`, res.body, contentType, () => {});
  }
}
