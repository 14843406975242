/* tslint:disable */
/* eslint-disable */
import { MultiPdfProvisionReminderDtoModel } from "./multi-pdf-provision-reminder-dto-model";
import { RemindersAndValueDtoModel } from "./reminders-and-value-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ReportReminderResponseEntryDtoModel")
export class ReportReminderResponseEntryDtoModel {
  @JsonProperty("abstractName", String, true)
  abstractName?: string = "";

  @JsonProperty("abstractUid", String, true)
  abstractUid?: string = "";

  @JsonProperty("generalValues", Any, true)
  generalValues?: {
    [key: string]: RemindersAndValueDtoModel;
  } = {};

  @JsonProperty("provisionsValues", [MultiPdfProvisionReminderDtoModel], true)
  provisionsValues?: Array<MultiPdfProvisionReminderDtoModel> = [];
}
