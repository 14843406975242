/* tslint:disable */
/* eslint-disable */
import { TimeSeriesPointModel } from "./time-series-point-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("SupportingDataModel")
export class SupportingDataModel {
  @JsonProperty("periodEndData", TimeSeriesPointModel, true)
  periodEndData?: TimeSeriesPointModel = undefined;

  @JsonProperty("periodStartData", TimeSeriesPointModel, true)
  periodStartData?: TimeSeriesPointModel = undefined;

  @JsonProperty("priceIndex", Any, true)
  priceIndex?:
    | "US_CUUR0000SA0"
    | "US_CUUR0100SA0"
    | "US_CUURS12ASA0"
    | "US_CUURS12BSA0"
    | "US_CUUR0300SA0"
    | "US_CUURS35CSA0"
    | "US_CUURS35ASA0"
    | "US_CUUR0200SA0"
    | "US_CUURS23ASA0"
    | "US_CUUR0400SA0"
    | "US_CUURS49ASA0"
    | "US_CUURS49BSA0"
    | "US_CWUR0000SAH1"
    | "UK_CPIH01" = undefined;
}
