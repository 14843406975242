/* tslint:disable */
/* eslint-disable */
import { CreateStackDtoModel } from "./create-stack-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqStackDtoModel")
export class OApiReqStackDtoModel {
  @JsonProperty("data", CreateStackDtoModel, true)
  data?: CreateStackDtoModel = undefined;
}
