/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PolarPdfDocSummaryViewInfoModel")
export class PolarPdfDocSummaryViewInfoModel {
  @JsonProperty("added", DateTimeConvertService, true)
  added?: Date | undefined = undefined;

  @JsonProperty("archived", Boolean, true)
  archived?: boolean = undefined;

  @JsonProperty("fingerprint", String, true)
  fingerprint?: string = "";

  @JsonProperty("flagged", Boolean, true)
  flagged?: boolean = undefined;

  @JsonProperty("nrPages", Number, true)
  nrPages?: number = undefined;

  @JsonProperty("pageMarkType", String, true)
  pageMarkType?: string = "";

  @JsonProperty("progress", Number, true)
  progress?: number = undefined;

  @JsonProperty("properties", Any, true)
  properties?: {
    [key: string]: string;
  } = {};

  @JsonProperty("tags", Any, true)
  tags?: {
    [key: string]: string;
  } = {};

  @JsonProperty("uuid", String, true)
  uuid?: string = "";
}
