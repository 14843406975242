export enum NotificationTypesEnum {
  MENTIONED_IN_COMMENT = "MENTIONED_IN_COMMENT",
  PROVISION_MENTIONED_IN_COMMENT = "PROVISION_MENTIONED_IN_COMMENT",
  DOCUMENT_MENTIONED_IN_COMMENT = "DOCUMENT_MENTIONED_IN_COMMENT",
  ISSUE_MENTIONED_IN_COMMENT = "ISSUE_MENTIONED_IN_COMMENT",
  ADDED_COMMENT = "ADDED_COMMENT",
  DELETED_COMMENT = "DELETED_COMMENT",
  EDITED_COMMENT = "EDITED_COMMENT",
  ADDED_TASK = "ADDED_TASK",
  DELETED_TASK = "DELETED_TASK",
  EDITED_TASK = "EDITED_TASK",
  NEW_TASK_ASSIGNED = "NEW_TASK_ASSIGNED",
  ASSIGNED_TASK_DELETED = "ASSIGNED_TASK_DELETED",
  ASSIGNED_TASK_EDITED = "ASSIGNED_TASK_EDITED",
  ASSIGNED_TASK_UNASSIGNED = "ASSIGNED_TASK_UNASSIGNED",
  ABSTRACTION_FINISHED = "ABSTRACTION_FINISHED",
  MASS_UPLOAD_ABSTRACTION_FINISHED = "MASS_UPLOAD_ABSTRACTION_FINISHED",
  ADDED_PROVISION_MENTION = "ADDED_PROVISION_MENTION",
  DELETED_PROVISION_MENTION = "DELETED_PROVISION_MENTION",
  ABSTRACT_SHARED = "ABSTRACT_SHARED",
  PROJECT_SHARED = "PROJECT_SHARED",
  CALENDAR_SHARED = "CALENDAR_SHARED",
  REPORT_SHARED = "REPORT_SHARED",
  DASHBOARD_SHARED = "DASHBOARD_SHARED",
  ABSTRACT_REQUEST_ACCESS = "ABSTRACT_REQUEST_ACCESS",
  PROJECT_REQUEST_ACCESS = "PROJECT_REQUEST_ACCESS",
  CALENDAR_REQUEST_ACCESS = "CALENDAR_REQUEST_ACCESS",
  REPORT_REQUEST_ACCESS = "REPORT_REQUEST_ACCESS",
  DASHBOARD_REQUEST_ACCESS = "DASHBOARD_REQUEST_ACCESS",
  ABSTRACT_RENAMED = "ABSTRACT_RENAMED",
  ABSTRACT_ABSTRACTOR_NAME_CHANGED = "ABSTRACT_ABSTRACTOR_NAME_CHANGED",
  ABSTRACT_STATUS_CHANGED = "ABSTRACT_STATUS_CHANGED",
  ABSTRACT_DELETED = "ABSTRACT_DELETED",
  EDITED_PROVISION_MENTION = "EDITED_PROVISION_MENTION",
  NEW_DOCUMENT_APPENDED_TO_ABSTRACT = "NEW_DOCUMENT_APPENDED_TO_ABSTRACT",
  REMINDER = "REMINDER",
  DOC_SET_SHARED = "DOC_SET_SHARED",
}
