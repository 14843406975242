/* tslint:disable */
/* eslint-disable */
import { ActorDtoModel } from "./actor-dto-model";
import { EntityIdDtoModel } from "./entity-id-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CheckPermissionRequestDtoModel")
export class CheckPermissionRequestDtoModel {
  @JsonProperty("domains", [ActorDtoModel], true)
  domains?: Array<ActorDtoModel> = [];

  @JsonProperty("entities", [EntityIdDtoModel], true)
  entities?: Array<EntityIdDtoModel> = [];

  @JsonProperty("permissionTypes", Any, true)
  permissionTypes?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];
}
