import { Injectable } from "@angular/core";
import { SuggestionItem } from "./suggestion-item/suggestion-item.model";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SortDirectionEnum } from "@@intelease/web/common/enums/sort-direction.enum";
import { FilterRelationEnum } from "@@intelease/web/common/enums/filter-relation.enum";
import {
  ListResponseModel,
  PaginationModel,
} from "@@intelease/web/intelease/models";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import {
  FetchBatchApiService,
  SearchApiService,
} from "@@intelease/web/intelease/services/backend";
import { CreateApiService } from "@@intelease/web/intelease/services/backend/create";
import { SearchSuggestionItemModel } from "@@intelease/web/common/models/search";

@Injectable({
  providedIn: "root",
})
export class SuggestionService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly API_VERSION_2 = "/v2";
  private static readonly HEADER_SEARCH_RESULTS_URL = "/headerSearchResults";

  constructor(
    private http: HttpClient,
    private createApiService: CreateApiService,
    private fetchBatchApiService: FetchBatchApiService,
    private searchApiService: SearchApiService
  ) {}

  getRecentSearchHistory(): Observable<ListResponseModel<SuggestionItem>> {
    return this.fetchBatchApiService.sendBatchRequest(
      SuggestionService.API_VERSION_1,
      SuggestionService.HEADER_SEARCH_RESULTS_URL,
      SuggestionItem.view,
      SuggestionItem,
      "date::Date::" +
        PROVISIONS_DATA_CONST.DATE.name +
        "::" +
        SortDirectionEnum.DESC
    );
  }

  getSearchResults(
    searchTerm: string
  ): Observable<ListResponseModel<SearchSuggestionItemModel>> {
    const filters = [
      {
        field: "searchItem::Match::" + PROVISIONS_DATA_CONST.STRING.name,
        relation: FilterRelationEnum.CONTAIN,
        value: searchTerm,
      },
    ];
    return this.searchApiService.sendRequest(
      SuggestionService.API_VERSION_2,
      SuggestionService.HEADER_SEARCH_RESULTS_URL,
      SearchSuggestionItemModel.view,
      SearchSuggestionItemModel,
      filters,
      "relevance::Relevance::" +
        PROVISIONS_DATA_CONST.DOUBLE.name +
        "::" +
        SortDirectionEnum.DESC,
      new PaginationModel()
    );
  }

  sendSearchRequest(suggestion: SuggestionItem): void {
    const data = {
      searchItem: suggestion.searchItem,
      searchCategories: suggestion.searchCategories,
    };
    this.createApiService.sendRequestNoResponse(
      SuggestionService.API_VERSION_1,
      SuggestionService.HEADER_SEARCH_RESULTS_URL,
      data
    );
  }
}
