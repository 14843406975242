/* tslint:disable */
/* eslint-disable */
import { AuditActionDtoModel } from "./audit-action-dto-model";
import { RecentRecordDtoModel } from "./recent-record-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UserRecordActivityDtoModel")
export class UserRecordActivityDtoModel {
  @JsonProperty("activity", AuditActionDtoModel, true)
  activity?: AuditActionDtoModel = undefined;

  @JsonProperty("activityTime", DateTimeConvertService, true)
  activityTime?: Date | undefined = undefined;

  @JsonProperty("record", RecentRecordDtoModel, true)
  record?: RecentRecordDtoModel = undefined;
}
