/* tslint:disable */
/* eslint-disable */
import { GeoJsonPointModel } from "./geo-json-point-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AddressModel")
export class AddressModel {
  @JsonProperty("city", String, true)
  city?: string = "";

  @JsonProperty("country", Any, true)
  country?:
    | "AFGHANISTAN"
    | "ALAND_ISLANDS"
    | "ALBANIA"
    | "ALGERIA"
    | "AMERICAN_SAMOA"
    | "ANDORRA"
    | "ANGOLA"
    | "ANGUILLA"
    | "ANTARCTICA"
    | "ANTIGUA_AND_BARBUDA"
    | "ARGENTINA"
    | "ARMENIA"
    | "ARUBA"
    | "AUSTRALIA"
    | "AUSTRIA"
    | "AZERBAIJAN"
    | "BAHAMAS"
    | "BAHRAIN"
    | "BANGLADESH"
    | "BARBADOS"
    | "BELARUS"
    | "BELGIUM"
    | "BELIZE"
    | "BENIN"
    | "BERMUDA"
    | "BHUTAN"
    | "BOLIVIA"
    | "BONAIRE"
    | "BOSNIA_AND_HERZEGOVINA"
    | "BOTSWANA"
    | "BOUVET_ISLAND"
    | "BRAZIL"
    | "BRITISH_INDIAN_OCEAN_TERRITORY"
    | "BRUNEI_DARUSSALAM"
    | "BULGARIA"
    | "BURKINA_FASO"
    | "BURUNDI"
    | "CAMBODIA"
    | "CAMEROON"
    | "CANADA"
    | "CAPE_VERDE"
    | "CAYMAN_ISLANDS"
    | "CENTRAL_AFRICAN_REPUBLIC"
    | "CHAD"
    | "CHILE"
    | "CHINA"
    | "CHRISTMAS_ISLAND"
    | "COCOS_ISLANDS"
    | "COLOMBIA"
    | "COMOROS"
    | "CONGO"
    | "DEMOCRATIC_REPUBLIC_OF_THE_CONGO"
    | "COOK_ISLANDS"
    | "COSTA_RICA"
    | "CROATIA"
    | "CUBA"
    | "CURAAO"
    | "CYPRUS"
    | "CZECH_REPUBLIC"
    | "COTE_DIVOIRE"
    | "DENMARK"
    | "DJIBOUTI"
    | "DOMINICA"
    | "DOMINICAN_REPUBLIC"
    | "ECUADOR"
    | "EGYPT"
    | "EL_SALVADOR"
    | "EQUATORIAL_GUINEA"
    | "ERITREA"
    | "ESTONIA"
    | "ETHIOPIA"
    | "FALKLAND_ISLANDS"
    | "FAROE_ISLANDS"
    | "FIJI"
    | "FINLAND"
    | "FRANCE"
    | "FRENCH_GUIANA"
    | "FRENCH_POLYNESIA"
    | "FRENCH_SOUTHERN_TERRITORIES"
    | "GABON"
    | "GAMBIA"
    | "GEORGIA"
    | "GERMANY"
    | "GHANA"
    | "GIBRALTAR"
    | "GREECE"
    | "GREENLAND"
    | "GRENADA"
    | "GUADELOUPE"
    | "GUAM"
    | "GUATEMALA"
    | "GUERNSEY"
    | "GUINEA"
    | "GUINEA_BISSAU"
    | "GUYANA"
    | "HAITI"
    | "HEARD_ISLAND_AND_MCDONALD_MCDONALD_ISLANDS"
    | "VATICAN_CITY_STATE"
    | "HONDURAS"
    | "HONG_KONG"
    | "HUNGARY"
    | "ICELAND"
    | "INDIA"
    | "INDONESIA"
    | "IRAN"
    | "IRAQ"
    | "IRELAND"
    | "ISLE_OF_MAN"
    | "ISRAEL"
    | "ITALY"
    | "JAMAICA"
    | "JAPAN"
    | "JERSEY"
    | "JORDAN"
    | "KAZAKHSTAN"
    | "KENYA"
    | "KIRIBATI"
    | "KUWAIT"
    | "KYRGYZSTAN"
    | "LAOS"
    | "LATVIA"
    | "LEBANON"
    | "LESOTHO"
    | "LIBERIA"
    | "LIBYA"
    | "LIECHTENSTEIN"
    | "LITHUANIA"
    | "LUXEMBOURG"
    | "MACAO"
    | "MACEDONIA"
    | "MADAGASCAR"
    | "MALAWI"
    | "MALAYSIA"
    | "MALDIVES"
    | "MALI"
    | "MALTA"
    | "MARSHALL_ISLANDS"
    | "MARTINIQUE"
    | "MAURITANIA"
    | "MAURITIUS"
    | "MAYOTTE"
    | "MEXICO"
    | "MICRONESIA"
    | "MOLDOVA"
    | "MONACO"
    | "MONGOLIA"
    | "MONTENEGRO"
    | "MONTSERRAT"
    | "MOROCCO"
    | "MOZAMBIQUE"
    | "MYANMAR"
    | "NAMIBIA"
    | "NAURU"
    | "NEPAL"
    | "NETHERLANDS"
    | "NEW_CALEDONIA"
    | "NEW_ZEALAND"
    | "NICARAGUA"
    | "NIGER"
    | "NIGERIA"
    | "NIUE"
    | "NORFOLK_ISLAND"
    | "NORTH_KOREA"
    | "NORTHERN_MARIANA_ISLANDS"
    | "NORWAY"
    | "OMAN"
    | "PAKISTAN"
    | "PALAU"
    | "PALESTINE"
    | "PANAMA"
    | "PAPUA_NEW_GUINEA"
    | "PARAGUAY"
    | "PERU"
    | "PHILIPPINES"
    | "PITCAIRN"
    | "POLAND"
    | "PORTUGAL"
    | "PUERTO_RICO"
    | "QATAR"
    | "ROMANIA"
    | "RUSSIAN_FEDERATION"
    | "RWANDA"
    | "REUNION"
    | "SAINT_BARTHELEMY"
    | "SAINT_HELENA"
    | "SAINT_KITTS_AND_NEVIS"
    | "SAINT_LUCIA"
    | "SAINT_MARTIN"
    | "SAINT_PIERRE_AND_MIQUELON"
    | "SAINT_VINCENT_AND_THE_GRENADINES"
    | "SAMOA"
    | "SAN_MARINO"
    | "SAO_TOME_AND_PRINCIPE"
    | "SAUDI_ARABIA"
    | "SENEGAL"
    | "SERBIA"
    | "SEYCHELLES"
    | "SIERRA_LEONE"
    | "SINGAPORE"
    | "SINT_MAARTEN"
    | "SLOVAKIA"
    | "SLOVENIA"
    | "SOLOMON_ISLANDS"
    | "SOMALIA"
    | "SOUTH_AFRICA"
    | "SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS"
    | "SOUTH_KOREA"
    | "SOUTH_SUDAN"
    | "SPAIN"
    | "SRI_LANKA"
    | "SUDAN"
    | "SURINAME"
    | "SVALBARD_AND_JAN_MAYEN"
    | "SWAZILAND"
    | "SWEDEN"
    | "SWITZERLAND"
    | "SYRIAN_ARAB_REPUBLIC"
    | "TAIWAN"
    | "TAJIKISTAN"
    | "UNITED_REPUBLIC_OF_TANZANIA"
    | "THAILAND"
    | "TIMOR_LESTE"
    | "TOGO"
    | "TOKELAU"
    | "TONGA"
    | "TRINIDAD_AND_TOBAGO"
    | "TUNISIA"
    | "TURKEY"
    | "TURKMENISTAN"
    | "TURKS_AND_CAICOS_ISLANDS"
    | "TUVALU"
    | "UGANDA"
    | "UKRAINE"
    | "UNITED_ARAB_EMIRATES"
    | "UNITED_KINGDOM"
    | "UNITED_STATES"
    | "UNITED_STATES_MINOR_OUTLYING_ISLANDS"
    | "URUGUAY"
    | "UZBEKISTAN"
    | "VANUATU"
    | "VENEZUELA"
    | "VIET_NAM"
    | "BRITISH_VIRGIN_ISLANDS"
    | "US_VIRGIN_ISLANDS"
    | "WALLIS_AND_FUTUNA"
    | "WESTERN_SAHARA"
    | "YEMEN"
    | "ZAMBIA"
    | "ZIMBABWE" = undefined;

  @JsonProperty("geoPoint", GeoJsonPointModel, true)
  geoPoint?: GeoJsonPointModel = undefined;

  @JsonProperty("postalCode", String, true)
  postalCode?: string = "";

  @JsonProperty("state", Any, true)
  state?:
    | "ALABAMA"
    | "ALASKA"
    | "ARIZONA"
    | "ARKANSAS"
    | "CALIFORNIA"
    | "COLORADO"
    | "CONNECTICUT"
    | "DELAWARE"
    | "DISTRICT_OF_COLUMBIA"
    | "FLORIDA"
    | "GEORGIA"
    | "HAWAII"
    | "IDAHO"
    | "ILLINOIS"
    | "INDIANA"
    | "IOWA"
    | "KANSAS"
    | "KENTUCKY"
    | "LOUISIANA"
    | "MAINE"
    | "MARYLAND"
    | "MASSACHUSETTS"
    | "MICHIGAN"
    | "MINNESOTA"
    | "MISSISSIPPI"
    | "MISSOURI"
    | "MONTANA"
    | "NEBRASKA"
    | "NEVADA"
    | "NEW_HAMPSHIRE"
    | "NEW_JERSEY"
    | "NEW_MEXICO"
    | "NEW_YORK"
    | "NORTH_CAROLINA"
    | "NORTH_DAKOTA"
    | "OHIO"
    | "OKLAHOMA"
    | "OREGON"
    | "PENNSYLVANIA"
    | "RHODE_ISLAND"
    | "SOUTH_CAROLINA"
    | "SOUTH_DAKOTA"
    | "TENNESSEE"
    | "TEXAS"
    | "UTAH"
    | "VERMONT"
    | "VIRGINIA"
    | "WASHINGTON"
    | "WEST_VIRGINIA"
    | "WISCONSIN"
    | "WYOMING"
    | "ALBERTA"
    | "NUNAVUT"
    | "BRITISH_COLUMBIA"
    | "ONTARIO"
    | "MANITOBA"
    | "PRINCE_EDWARD_ISLAND"
    | "NEW_BRUNSWICK"
    | "QUEBEC"
    | "NEWFOUNDLAND"
    | "SASKATCHEWAN"
    | "NORTHWEST_TERRITORIES"
    | "YUKON_TERRITORY"
    | "NOVA_SCOTIA"
    | "NEW_SOUTH_WALES"
    | "QUEENSLAND"
    | "SOUTH_AUSTRALIA"
    | "TASMANIA"
    | "VICTORIA"
    | "WESTERN_AUSTRALIA"
    | "AVON"
    | "BEDFORDSHIRE"
    | "BERKSHIRE"
    | "BUCKINGHAMSHIRE"
    | "CAMBRIDGESHIRE"
    | "CHESHIRE"
    | "CLEVELAND"
    | "CO_DURHAM"
    | "CORNWALL"
    | "CUMBERLAND"
    | "CUMBRIA"
    | "DERBYSHIRE"
    | "DEVON"
    | "DORSET"
    | "EAST_RIDING_OF_YORKSHIRE"
    | "EAST_SUSSEX"
    | "ESSEX"
    | "GLOUCESTERSHIRE"
    | "GREATER_MANCHESTER"
    | "HAMPSHIRE"
    | "HEREFORD_AND_WORCESTER"
    | "HEREFORDSHIRE"
    | "HERTFORDSHIRE"
    | "HUMBERSIDE"
    | "HUNTINGDONSHIRE"
    | "ISLE_OF_WIGHT"
    | "KENT"
    | "LANCASHIRE"
    | "LEICESTERSHIRE"
    | "LINCOLNSHIRE"
    | "MERSEYSIDE"
    | "NORFOLK"
    | "NORTH_RIDING_OF_YORKSHIRE"
    | "NORTH_YORKSHIRE"
    | "NORTHAMPTONSHIRE"
    | "NORTHUMBERLAND"
    | "NOTTINGHAMSHIRE"
    | "OXFORDSHIRE"
    | "RUTLAND"
    | "SHROPSHIRE"
    | "SOMERSET"
    | "SOUTH_YORKSHIRE"
    | "STAFFORDSHIRE"
    | "SUFFOLK"
    | "SURREY"
    | "SUSSEX"
    | "TYNE_AND_WEAR"
    | "WARWICKSHIRE"
    | "WEST_MIDLANDS"
    | "WEST_RIDING_OF_YORKSHIRE"
    | "WEST_SUSSEX"
    | "WEST_YORKSHIRE"
    | "WESTMORLAND"
    | "WILTSHIRE"
    | "WORCESTERSHIRE"
    | "YORKSHIRE"
    | "ABERDEENSHIRE"
    | "ANGUS"
    | "ARGYLLSHIRE"
    | "AYRSHIRE"
    | "BANFFSHIRE"
    | "BERWICKSHIRE"
    | "BORDERS"
    | "BUTE"
    | "CAITHNESS"
    | "CENTRAL"
    | "CLACKMANNANSHIRE"
    | "DUMFRIES_AND_GALLOWAY"
    | "DUMFRIESSHIRE"
    | "DUNBARTONSHIRE"
    | "EAST_LOTHIAN"
    | "FIFE"
    | "GRAMPIAN"
    | "HIGHLAND"
    | "INVERNESSSHIRE"
    | "KINCARDINESHIRE"
    | "KINROSSSHIRE"
    | "KIRKCUDBRIGHTSHIRE"
    | "LANARKSHIRE"
    | "LOTHIAN"
    | "MIDLOTHIAN"
    | "MORAYSHIRE"
    | "NAIRN"
    | "ORKNEY"
    | "PEEBLESSHIRE"
    | "PERTH"
    | "RENFREWSHIRE"
    | "ROSS_AND_CROMARTY"
    | "ROXBURGHSHIRE"
    | "SELKIRKSHIRE"
    | "SHETLAND"
    | "STIRLINGSHIRE"
    | "STRATHCLYDE"
    | "SUTHERLAND"
    | "TAYSIDE"
    | "WEST_LOTHIAN"
    | "WESTERN_ISLES"
    | "WIGTOWNSHIRE"
    | "ANGLESEY"
    | "BRECONSHIRE"
    | "CAERNARVONSHIRE"
    | "CARDIGANSHIRE"
    | "CARMARTHENSHIRE"
    | "CLWYD"
    | "DENBIGHSHIRE"
    | "DYFED"
    | "FLINTSHIRE"
    | "GLAMORGAN"
    | "GWENT"
    | "GWYNEDD"
    | "MERIONETHSHIRE"
    | "MID_GLAMORGAN"
    | "MONMOUTHSHIRE"
    | "MONTGOMERYSHIRE"
    | "PEMBROKESHIRE"
    | "POWYS"
    | "RADNORSHIRE"
    | "SOUTH_GLAMORGAN"
    | "WEST_GLAMORGAN"
    | "ANDAMAN_AND_NICOBAR_ISLANDS"
    | "ANDHRA_PRADESH"
    | "ARUNACHAL_PRADESH"
    | "ASSAM"
    | "BIHAR"
    | "CHANDIGARH"
    | "CHHATTISGARH"
    | "DADRA_AND_NAGAR_HAVELI_AND_DAMAN_AND_DIU"
    | "DELHI"
    | "GOA"
    | "GUJARAT"
    | "HARYANA"
    | "HIMACHAL_PRADESH"
    | "JAMMU_AND_KASHMIR"
    | "JHARKHAND"
    | "KARNATAKA"
    | "KERALA"
    | "LADAKH"
    | "LAKSHADWEEP"
    | "MADHYA_PRADESH"
    | "MAHARASHTRA"
    | "MANIPUR"
    | "MEGHALAYA"
    | "MIZORAM"
    | "NAGALAND"
    | "ODISHA"
    | "PUDUCHERRY"
    | "PUNJAB"
    | "RAJASTHAN"
    | "SIKKIM"
    | "TAMIL_NADU"
    | "TELANGANA"
    | "TRIPURA"
    | "UTTAR_PRADESH"
    | "UTTARAKHAND"
    | "WEST_BENGAL" = undefined;

  @JsonProperty("streetAddress", String, true)
  streetAddress?: string = "";

  @JsonProperty("suite", String, true)
  suite?: string = "";
}
