/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AddNewProvisionApiDtoModel")
export class AddNewProvisionApiDtoModel {
  @JsonProperty("postProvisionLocationUid", String, true)
  postProvisionLocationUid?: string = "";

  @JsonProperty("preProvisionLocationUid", String, true)
  preProvisionLocationUid?: string = "";

  @JsonProperty("provisionGroupUid", String, true)
  provisionGroupUid?: string = "";

  @JsonProperty("provisionUid", String, true)
  provisionUid?: string = "";
}
