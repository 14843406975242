import { Any, JsonObject, JsonProperty } from "json2typescript";
import { MultiPdfProvModel } from "./multi-pdf-prov.model";
import { FullValMultiProvisionValueModel } from "@@intelease/web/common/models/multi-provision-value";
import { ProvisionInfoModel } from "../provision-info";
import { FullValMultiPdfProvViewModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("FullValMultiPdfProvModel")
export class FullValMultiPdfProvModel extends MultiPdfProvModel {
  @JsonProperty("multiplePdfProvision", FullValMultiProvisionValueModel)
  multiplePdfProvision: FullValMultiProvisionValueModel = new FullValMultiProvisionValueModel();

  @JsonProperty("provisionInfo", ProvisionInfoModel)
  provisionInfo: ProvisionInfoModel;

  @JsonProperty("subProvisionMap", Any)
  subProvisionMap: {
    [key: string]: FullValMultiPdfProvViewModel;
  } = {};
}
