import { NestedGroupProvisionSubfieldModel } from "@@intelease/api-models/adex-api-model-src";
import { Any, JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ProvisionInfoModel2")
export class ProvisionInfoModel {
  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("type", String)
  type = "";
  @JsonProperty(
    "nestedGroupSubfields",
    [NestedGroupProvisionSubfieldModel],
    true
  )
  nestedGroupSubfields?: Array<NestedGroupProvisionSubfieldModel> = [];

  @JsonProperty("nestedProcessingMode", Any, true)
  nestedProcessingMode?: "FLEXIBLE" | "STRICT" = undefined;

  @JsonProperty("allowedTypes", [String], true)
  allowedTypes: string[] = [];

  @JsonProperty("fieldType", String)
  fieldType = "";

  @JsonProperty("entityScope", String)
  entityScope = "";

  @JsonProperty("possibleCurrencies", [String], true)
  possibleCurrencies: string[] = [];

  @JsonProperty("possibleValues", [String], true)
  possibleValues: string[] = [];

  @JsonProperty("possibleUnits", [String], true)
  possibleUnits: string[] = [];

  @JsonProperty("possibleStates", [String], true)
  possibleStates: string[] = [];

  @JsonProperty("possibleCountries", [String], true)
  possibleCountries: string[] = [];

  @JsonProperty("numericalUnitCategory", String, true)
  numericalUnitCategory = "";

  @JsonProperty("defaultTableHeaders", [String])
  defaultTableHeaders: string[] = [];

  @JsonProperty("properNounType", Any, true)
  properNounType?: "ENTITY" | "PLACE" | "HOLIDAY" | "TITLE_OF_WORK" | "ID" =
    undefined;
}
