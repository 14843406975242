import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("LightNodeModel")
export class LightNodeModel {
  static view = "light";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("type", String)
  type: "RECORD" | "DIRECTORY" | "DOC_SET" | undefined = undefined;
}

@JsonObject("LightNodeV2Model")
export class LightNodeV2Model {
  static view = "light";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("type", String)
  type: "RECORD" | "DIRECTORY" | "DOC_SET" | undefined = undefined;
}
