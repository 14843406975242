import { ModalInputModel } from "@@intelease/web/intelease/models";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "intelease-add-calendar",
  templateUrl: "./add-calendar.component.html",
  styleUrls: ["./add-calendar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCalendarComponent {
  calendarName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ModalInputModel,
    public dialogRef: MatDialogRef<AddCalendarComponent>
  ) {}

  onSaveCalendarClick() {
    this.dialogRef.close({ calendarName: this.calendarName });
  }
}
