/* tslint:disable */
/* eslint-disable */
import { AbstractReviewOperationModeViewModel } from "./abstract-review-operation-mode-view-model";
import { CustomTagViewModel } from "./custom-tag-view-model";
import { DefaultDataFieldUnitsModel } from "./default-data-field-units-model";
import { DocSetCategoryViewModel } from "./doc-set-category-view-model";
import { FullValMultiPdfProvViewModel } from "./full-val-multi-pdf-prov-view-model";
import { NodeNavigationResultModel } from "./node-navigation-result-model";
import { ProvisionFormStructureViewModel } from "./provision-form-structure-view-model";
import { ProvisionInconsistenciesApiDtoModel } from "./provision-inconsistencies-api-dto-model";
import { QueueInfoDtoModel } from "./queue-info-dto-model";
import { QueueInfoViewModel } from "./queue-info-view-model";
import { RecordLockInfoDtoModel } from "./record-lock-info-dto-model";
import { RecordPathModel } from "./record-path-model";
import { RelatedDocumentModel } from "./related-document-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FinalAbstractViewCompleteModel")
export class FinalAbstractViewCompleteModel {
  @JsonProperty("abstractCreationDate", DateTimeConvertService, true)
  abstractCreationDate?: Date | undefined = undefined;

  @JsonProperty("abstractFilename", String, true)
  abstractFilename?: string = "";

  @JsonProperty("abstractName", String, true)
  abstractName?: string = "";

  @JsonProperty("commentCountInRecord", Number, true)
  commentCountInRecord?: number = undefined;

  @JsonProperty("customTags", [CustomTagViewModel], true)
  customTags?: Array<CustomTagViewModel> = [];

  @JsonProperty("defaultDataFieldUnits", DefaultDataFieldUnitsModel, true)
  defaultDataFieldUnits?: DefaultDataFieldUnitsModel = undefined;

  @JsonProperty("defaultDoc", RelatedDocumentModel, true)
  defaultDoc?: RelatedDocumentModel = undefined;

  @JsonProperty("docAbstractUids", [String], true)
  docAbstractUids?: Array<string> = [];

  @JsonProperty("docNames", [String], true)
  docNames?: Array<string> = [];

  @JsonProperty("docSetCategory", DocSetCategoryViewModel, true)
  docSetCategory?: DocSetCategoryViewModel = undefined;

  @JsonProperty("docSetUid", String, true)
  docSetUid?: string = "";

  @JsonProperty("documents", [RelatedDocumentModel], true)
  documents?: Array<RelatedDocumentModel> = [];

  @JsonProperty("formStructure", ProvisionFormStructureViewModel, true)
  formStructure?: ProvisionFormStructureViewModel = undefined;

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("lockInfo", RecordLockInfoDtoModel, true)
  lockInfo?: RecordLockInfoDtoModel = undefined;

  @JsonProperty("nodeNavigation", NodeNavigationResultModel, true)
  nodeNavigation?: NodeNavigationResultModel = undefined;

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    | "READ"
    | "EDIT"
    | "COMPLETE"
    | "DELETE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "HISTORY_EXPORT"
    | "LOCK"
    | "EXPLORE"
    | "MOVE"
    | "COMMENT"
    | "SHARE"
  > = [];

  @JsonProperty(
    "provisionInconsistencies",
    ProvisionInconsistenciesApiDtoModel,
    true
  )
  provisionInconsistencies?: ProvisionInconsistenciesApiDtoModel = undefined;

  @JsonProperty("provisions", [FullValMultiPdfProvViewModel], true)
  provisions?: Array<FullValMultiPdfProvViewModel> = [];

  @JsonProperty("queueInfo", QueueInfoDtoModel, true)
  queueInfo?: QueueInfoDtoModel = undefined;

  @JsonProperty("queueInfoView", QueueInfoViewModel, true)
  queueInfoView?: QueueInfoViewModel = undefined;

  @JsonProperty("recordReviewMode", Any, true)
  recordReviewMode?: "V1" | "V2" = undefined;

  @JsonProperty(
    "reviewOperationMode",
    AbstractReviewOperationModeViewModel,
    true
  )
  reviewOperationMode?: AbstractReviewOperationModeViewModel = undefined;

  @JsonProperty("status", String, true)
  status?: string = "";

  @JsonProperty("suiteVersionAbstractId", RecordPathModel, true)
  suiteVersionAbstractId?: RecordPathModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
