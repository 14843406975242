/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PdfBbxModel")
export class PdfBbxModel {
  @JsonProperty("bottom", Number, true)
  bottom?: number = undefined;

  @JsonProperty("height", Number, true)
  height?: number = undefined;

  @JsonProperty("left", Number, true)
  left?: number = undefined;

  @JsonProperty("right", Number, true)
  right?: number = undefined;

  @JsonProperty("top", Number, true)
  top?: number = undefined;

  @JsonProperty("width", Number, true)
  width?: number = undefined;
}
