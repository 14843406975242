import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import {
  MENTION_EDIT_TRACKER_FEATURE_KEY,
  MentionEditTrackerState,
} from "./mention-edit-tracker.reducer";
import { mentionEditTrackerQuery } from "./mention-edit-tracker.selectors";
import {
  AnchorMentionIndexChanged,
  LastEditedNestedSubfieldKey,
  LastTouchedMentionUid,
  LastTouchedNestedSubfieldKey,
  LocateNestedSubfield,
} from "./mention-edit-tracker.actions";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MentionEditTrackerFacade {
  public deleteSubfieldClick = new Subject<{
    mentionUid?: string;
    subfieldKey: string;
  }>();

  public approveOrRejectSubfield = new Subject<{
    mentionUid: string;
    subfieldKey: string;
    approvalStatus: "APPROVED" | "REJECTED";
  }>();

  public nestedMentionAttributionOpen = new Subject<{
    mentionUid?: string;
    subfieldKey: string;
  }>();

  constructor(
    private store: Store<{
      [MENTION_EDIT_TRACKER_FEATURE_KEY]: MentionEditTrackerState;
    }>
  ) {}

  getLastTouchedMentionUid$ = this.store.pipe(
    select(mentionEditTrackerQuery.getLastTouchedMentionUid)
  );

  getLastEditedNestedSubfieldKey$ = this.store.pipe(
    select(mentionEditTrackerQuery.getLastEditedNestedSubfieldKey)
  );

  getLastTouchedNestedSubfield$ = this.store.pipe(
    select(mentionEditTrackerQuery.getLastTouchedNestedSubfieldKey)
  );

  getLocateNestedMentionOperation$ = this.store.pipe(
    select(mentionEditTrackerQuery.getLocateNestedMentionOperation)
  );

  getAnchorMentionIndex$ = this.store.pipe(
    select(mentionEditTrackerQuery.getAnchorMentionChangedIndex)
  );

  touchMentionUid(mentionUid?: string) {
    this.store.dispatch(new LastTouchedMentionUid({ mentionUid }));
  }

  touchNestedSubfield(subfieldKey?: string) {
    this.store.dispatch(new LastTouchedNestedSubfieldKey({ subfieldKey }));
  }

  updateLastEditedNestedSubfield(subfieldKey?: string) {
    this.store.dispatch(new LastEditedNestedSubfieldKey({ subfieldKey }));
  }

  locateMentionSubfield(nestedMentionUid?: string, nestedSubfieldKey?: string) {
    this.store.dispatch(
      new LocateNestedSubfield({ nestedMentionUid, nestedSubfieldKey })
    );
  }

  changeAnchorMentionIndex(index?: number) {
    this.store.dispatch(new AnchorMentionIndexChanged({ index }));
  }
}
