import { JsonConverter, JsonCustomConvert } from "json2typescript";
import { SearchSuggestionItemCategoryTypeModel } from "@@intelease/web/common/models/search/search-suggestion-item-category-type.model";

@JsonConverter
export class SearchSuggestionItemCategoryTypeJsonConverter
  implements JsonCustomConvert<SearchSuggestionItemCategoryTypeModel>
{
  serialize(item: SearchSuggestionItemCategoryTypeModel): any {
    return item.toString();
  }

  deserialize(item: string): SearchSuggestionItemCategoryTypeModel {
    return SearchSuggestionItemCategoryTypeModel[item];
  }
}
