/* tslint:disable */
/* eslint-disable */
import { EditProvisionNotesApiV3DtoModel } from "./edit-provision-notes-api-v-3-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqEditNoteV3DtoModel")
export class OApiReqEditNoteV3DtoModel {
  @JsonProperty("data", EditProvisionNotesApiV3DtoModel, true)
  data?: EditProvisionNotesApiV3DtoModel = undefined;
}
