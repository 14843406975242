/* tslint:disable */
/* eslint-disable */
import { CustomCommentDataModel } from "./custom-comment-data-model";
import { CustomProvisionDataModel } from "./custom-provision-data-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CustomDataModel")
export class CustomDataModel {
  @JsonProperty("commentData", CustomCommentDataModel, true)
  commentData?: CustomCommentDataModel = undefined;

  @JsonProperty("highlightType", String, true)
  highlightType?: string = "";

  @JsonProperty("provisionData", CustomProvisionDataModel, true)
  provisionData?: CustomProvisionDataModel = undefined;

  @JsonProperty("subfieldKey", String, true)
  subfieldKey?: string = "";

  @JsonProperty("type", String, true)
  type?: string = "";

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
