/* tslint:disable */
/* eslint-disable */
import { MultiPdfProvFullValDtoModel } from "./multi-pdf-prov-full-val-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RentScheduleInputsResponseModel")
export class RentScheduleInputsResponseModel {
  @JsonProperty("htmlNameToFullVal", Any, true)
  htmlNameToFullVal?: {
    [key: string]: MultiPdfProvFullValDtoModel;
  } = {};
}
