import { NgModule } from "@angular/core";
import { ProvisionBoxModalComponent } from "./provision-box-modal.component";
import { InteleaseModule } from "@@intelease/web/intelease/intelease.module";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
import { ItlsCommentModule } from "@@intelease/web/ui/src/lib/itls-comment";
import { ProvisionMentionsModule } from "@@intelease/web/abstraction-page/src/lib/components/provision-mentions";
import { PortfolioMentionsModule } from "@@intelease/web/abstraction-page/src/lib/components/portfolio-mentions";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SchemaFormModule } from "@@intelease/schema-form";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ProvisionModule } from "./provision/provision.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRippleModule } from "@angular/material/core";
import { AngularDraggableModule } from "angular2-draggable";
import { MatDividerModule } from "@angular/material/divider";
import { ResizableModule } from "angular-resizable-element";
import { ShortenPipe } from "ngx-pipes";
import { AngularResizedEventModule } from "@@intelease/web/ui/src/lib/itls-angular-resize-event";

const MATERIAL_MODULES = [
  MatDialogModule,
  MatButtonModule,
  MatTabsModule,
  MatIconModule,
  MatTooltipModule,
  DragDropModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDividerModule,
];

const UI_MODULES = [ItlsCommentModule];

@NgModule({
  imports: [
    CommonModule,
    InteleaseModule,
    InteleaseCommonModule,
    FormsModule,
    FlexLayoutModule,
    ProvisionMentionsModule,
    PortfolioMentionsModule,
    SchemaFormModule.forRoot(),
    ...MATERIAL_MODULES,
    ...UI_MODULES,
    ProvisionModule,
    AngularDraggableModule,
    ResizableModule,
    AngularResizedEventModule,
  ],
  declarations: [ProvisionBoxModalComponent],
  exports: [ProvisionBoxModalComponent],
  providers: [ShortenPipe],
})
export class ProvisionBoxModalModule {}
