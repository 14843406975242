import { CommonModule } from "@angular/common";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { PgCardComponent } from "./card.component";
import { ProgressModule } from "../progress/progress.module";
@NgModule({
  declarations: [PgCardComponent],
  exports: [PgCardComponent],
  imports: [CommonModule, ProgressModule],
})
export class PgCardModule {
  static forRoot(): ModuleWithProviders<PgCardModule> {
    return {
      ngModule: PgCardModule,
    };
  }
}
