import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PermissionApiService } from "@@intelease/web/intelease/services";
import {
  ListResponseModel,
  PermissionCheckResponseModel,
} from "@@intelease/web/intelease/models";
import { PermissionResponseModel } from "@@intelease/web/intelease/models/permission-response.model";
import { CreateTaskService } from "@@intelease/web/intelease/services/models/task/create/create-task.service";
import { CreateBatchTaskService } from "@@intelease/web/intelease/services/models/task/create/create-batch-task.service";
import { FetchBatchTaskService } from "@@intelease/web/intelease/services/models/task/fetch/fetch-batch-task.service";
import { FetchTaskService } from "@@intelease/web/intelease/services/models/task/fetch/fetch-task.service";
import { EditTaskService } from "@@intelease/web/intelease/services/models/task/edit/edit-task.service";
import {
  FullUserAbstractTaskModel,
  MinimalUserAbstractTaskModel,
} from "@@intelease/app-models/tasks";
import { ActorModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/actor.model";
import { RestClient } from "@@intelease/web/utils";
import { DateConvertService } from "@common/services";

@Injectable({
  providedIn: "root",
})
export class TasksService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly USERS_URL = "/users";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly TASKS_URL = "/tasks";

  constructor(
    private permissionApiService: PermissionApiService,
    private createTaskService: CreateTaskService,
    private createBatchTaskService: CreateBatchTaskService,
    private fetchBatchTaskService: FetchBatchTaskService,
    private fetchTaskService: FetchTaskService,
    private editTaskService: EditTaskService,
    private restClient: RestClient
  ) {}

  checkPermission(
    assignees: ActorModel[],
    entities: any[],
    taskType: string
  ): Observable<PermissionCheckResponseModel> {
    return this.restClient.sendPostRequest(
      TasksService.API_VERSION_1,
      `${TasksService.TASKS_URL}/permissions/check`,
      {
        data: {
          assignees,
          entities,
          taskType,
        },
      },
      PermissionCheckResponseModel,
      {},
      (resp) => resp.data
    );
  }

  checkTasksPermission(
    userUid: string,
    abstractUids: string[],
    taskType: string,
    permissionTypes: string[]
  ): Observable<ListResponseModel<PermissionResponseModel>> {
    return this.permissionApiService.sendBatchRequest(
      TasksService.API_VERSION_1,
      `${TasksService.USERS_URL}/${userUid}${TasksService.FINAL_DOC_SETS_URL}`,
      abstractUids,
      taskType,
      permissionTypes
    );
  }

  createTask(
    abstractUid: string,
    task: any
  ): Observable<MinimalUserAbstractTaskModel> {
    if (task.dueDate) {
      task.dueDate = DateConvertService.serializeDate(task["dueDate"]);
    }
    return this.createTaskService.createTask(
      abstractUid,
      task,
      MinimalUserAbstractTaskModel.view,
      MinimalUserAbstractTaskModel
    );
  }

  createMultiEntitiesTask(
    abstractUids: string[],
    task: any
  ): Observable<MinimalUserAbstractTaskModel> {
    if (task.dueDate) {
      task.dueDate = DateConvertService.serializeDate(task["dueDate"]);
    }
    return this.createTaskService.createMultiEntitiesTask(
      abstractUids,
      task,
      MinimalUserAbstractTaskModel.view,
      MinimalUserAbstractTaskModel
    );
  }

  updateTask(
    taskUid: string,
    data: any
  ): Observable<MinimalUserAbstractTaskModel> {
    if (data.dueDate) {
      data.dueDate = DateConvertService.serializeDate(data["dueDate"]);
    }

    if (typeof data.status === "object") {
      data.status = data.status.name;
    }

    return this.editTaskService.updateTask(
      taskUid,
      data,
      MinimalUserAbstractTaskModel.view,
      MinimalUserAbstractTaskModel
    );
  }

  getTaskListByAbstractUid(
    abstractUid: string
  ): Observable<ListResponseModel<FullUserAbstractTaskModel>> {
    return this.fetchBatchTaskService.getTaskListByAbstractUid(
      abstractUid,
      FullUserAbstractTaskModel.view,
      FullUserAbstractTaskModel
    );
  }

  getTaskListForCurrentUser(): Observable<
    ListResponseModel<FullUserAbstractTaskModel>
  > {
    return this.fetchBatchTaskService.getTaskListForCurrentUser(
      FullUserAbstractTaskModel.view,
      FullUserAbstractTaskModel
    );
  }

  getTaskDetail(taskUid: string): Observable<MinimalUserAbstractTaskModel> {
    return this.fetchTaskService.getTaskDetail(
      taskUid,
      MinimalUserAbstractTaskModel.view,
      MinimalUserAbstractTaskModel
    );
  }
}
