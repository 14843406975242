import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostListener,
  inject,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import { ITLS_BREADCRUMS } from "./itls-breadcrumb.const";
import { ItlsBreadcrumbService } from "./itls-breadcrumb.service";
import { cloneDeep, startsWith } from "lodash";
import { combineLatest } from "rxjs";
import { BreadcrumbItemModel } from "@@intelease/web/intelease/components/toolbar/breadcrumb/models";
import { BreadcrumbItemTypeEnum } from "@@intelease/web/intelease/components/toolbar/breadcrumb/enums";
import { ToolbarService } from "@@intelease/web/intelease/components/toolbar/toolbar.service";
import { MainDrawerService } from "@@intelease/web/intelease/services";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  originalBreadcrumbsMap = new Map<string, BreadcrumbItemModel>();
  modifiedBreadcrumbs: BreadcrumbItemModel[] = [];
  itlsBreadcrumbs = ITLS_BREADCRUMS;
  toolbarWidth = 0;
  sidebarOpen = false;
  screenWidth = 0;
  isShowingEllipses = false;

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly itlsBreadcrumbService: ItlsBreadcrumbService,
    private readonly toolbarService: ToolbarService,
    private readonly mainDrawerService: MainDrawerService
  ) {
    this.onWindowResize();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(
        map(() =>
          this.findOneBeforeLastChild(this.router.routerState.root.firstChild)
        )
      )
      .pipe(
        mergeMap((route) => {
          return combineLatest(route.params, route.data, (params, data) => ({
            params,
            data,
          }));
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        this.reloadBreadcrumbs({
          params: result.params,
          breadcrumbs: result.data.breadcrumbs,
        });
      });

    this.itlsBreadcrumbService.breadcrumbChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.onBreadcrumbChange(res);
      });

    this.itlsBreadcrumbService.recreateBreadcrumb$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((breadcrumbs) => {
        this.onRecreateBreadcrumb(breadcrumbs);
      });

    this.itlsBreadcrumbService.addBreadcrumb
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        const { key } = res;
        this.originalBreadcrumbsMap.set(key, this.itlsBreadcrumbs[key]);
        this.recreateModifiedBreadcrumbs();
        this.cdr.detectChanges();
      });

    this.itlsBreadcrumbService.clearBreadcrumb
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.originalBreadcrumbsMap.clear();
        this.recreateModifiedBreadcrumbs();
        this.cdr.detectChanges();
      });

    this.toolbarService.toolbarWidth$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((toolbarWidth) => {
        this.toolbarWidth = toolbarWidth;
        this.cdr.detectChanges();
      });

    this.mainDrawerService
      .onOpenSidebar$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.sidebarOpen = true;
        this.cdr.detectChanges();
      });

    this.mainDrawerService
      .onCloseSidebar$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.sidebarOpen = false;
        this.cdr.detectChanges();
      });
  }

  private onBreadcrumbChange(res) {
    const { key, payload } = res;
    const { params } = payload;
    const breadcrumb: any = cloneDeep(this.originalBreadcrumbsMap.get(key));
    if (breadcrumb) {
      if (params && params.length) {
        params.forEach((param) => {
          breadcrumb.link = breadcrumb.link.replace(
            "${" + param.key + "}",
            param.value
          );
        });
      }
      this.originalBreadcrumbsMap.set(key, { ...breadcrumb, ...payload });
      this.recreateModifiedBreadcrumbs();
      this.cdr.detectChanges();
    }
  }

  private reloadBreadcrumbs({ params, breadcrumbs }) {
    if (breadcrumbs) {
      this.originalBreadcrumbsMap.clear();
      breadcrumbs.forEach((item) => {
        // if breadcrumb has dynamic key (prefixed with : ), try finding it's key
        let itemKey = item.key;
        const routeParamsKey = item.routeParamsKey;
        const keys = item.keys;
        if (routeParamsKey && keys && startsWith(routeParamsKey, ":")) {
          const routeParamsKeyWithoutStartingColon = routeParamsKey.substr(1);
          const dynamicValueInRouteParams =
            params[routeParamsKeyWithoutStartingColon];
          if (dynamicValueInRouteParams) {
            if (keys[dynamicValueInRouteParams]) {
              itemKey = keys[dynamicValueInRouteParams];
            }
          }
        }
        this.originalBreadcrumbsMap.set(itemKey, this.itlsBreadcrumbs[itemKey]);
      });
      this.recreateModifiedBreadcrumbs();
      this.cdr.detectChanges();
    }
  }

  private onRecreateBreadcrumb(breadcrumbItems: BreadcrumbItemModel[]) {
    this.originalBreadcrumbsMap.clear();
    breadcrumbItems.forEach((breadcrumbItem) => {
      this.originalBreadcrumbsMap.set(breadcrumbItem.key, breadcrumbItem);
    });
    this.recreateModifiedBreadcrumbs();
    this.cdr.detectChanges();
  }

  private recreateModifiedBreadcrumbs() {
    this.modifiedBreadcrumbs = [];
    this.isShowingEllipses = this.originalBreadcrumbsMap.size > 3;
    if (this.isShowingEllipses) {
      let i = 0;
      for (const [key, value] of this.originalBreadcrumbsMap) {
        if (i === 0) {
          // show first item as normal
          this.modifiedBreadcrumbs.push(value);
        } else if (i >= this.originalBreadcrumbsMap.size - 2) {
          // show two last items as normal
          this.modifiedBreadcrumbs.push(value);
        } else {
          // show items as ellipse, except first and two last items
          let ellipseBreadcrumb = this.modifiedBreadcrumbs[1];
          if (!ellipseBreadcrumb) {
            ellipseBreadcrumb = new BreadcrumbItemModel({
              type: BreadcrumbItemTypeEnum.ELLIPSE,
              children: [],
            });
            this.modifiedBreadcrumbs.push(ellipseBreadcrumb);
          }
          ellipseBreadcrumb.children.push(value);
        }
        i++;
      }
    } else {
      this.originalBreadcrumbsMap.forEach((breadcrumb) =>
        this.modifiedBreadcrumbs.push(breadcrumb)
      );
    }
  }

  private findOneBeforeLastChild(activatedRoute: ActivatedRoute) {
    let oneBeforeLastChild = activatedRoute;
    while (oneBeforeLastChild?.firstChild?.firstChild) {
      oneBeforeLastChild = oneBeforeLastChild.firstChild;
    }
    return oneBeforeLastChild;
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
