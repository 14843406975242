import { Injectable } from "@angular/core";

import { select, Store } from "@ngrx/store";

import { KanbanPartialState, KanbanState } from "./kanban.reducer";
import { kanbanQuery } from "./kanban.selectors";
import {
  AddIssueList,
  CleanKanbanState,
  DeleteKanbanComment,
  DeleteKanbanIssue,
  LoadKanban,
  LoadSelectedKanban,
  LoadSelectedKanbanIssue,
  LoadSelectedKanbanIssueHistory,
  LoadSelectedKanbanIssueTasks,
  SaveKanban,
  SaveKanbanComment,
  UpdateKanbanComment,
  UpdateSelectedKanban,
} from "./kanban.actions";

@Injectable()
export class KanbanFacade {
  loaded$ = this.store.pipe(select(kanbanQuery.getLoaded));
  allKanban$ = this.store.pipe(select(kanbanQuery.getAllKanban));
  selectedKanban$ = this.store.pipe(select(kanbanQuery.getSelectedKanban));
  saveKanbanSucceeded$ = this.store.pipe(
    select(kanbanQuery.saveKanbanSucceeded)
  );
  savedKanbanDetail$ = this.store.pipe(select(kanbanQuery.savedKanbanDetail));

  selectedKanbanLoaded$ = this.store.pipe(
    select(kanbanQuery.selectedKanbanLoaded)
  );
  isSelectedKanbanLoading$ = this.store.pipe(
    select(kanbanQuery.isSelectedKanbanLoading)
  );
  selectedKanbanDetail$ = this.store.pipe(
    select(kanbanQuery.getSelectedKanbanDetail)
  );
  selectedKanbanDetailMap$ = this.store.pipe(
    select(kanbanQuery.getSelectedKanbanDetailMap)
  );

  isSelectedKanbanIssueLoading$ = this.store.pipe(
    select(kanbanQuery.isSelectedKanbanIssueLoading)
  );
  selectedKanbanIssueDetail$ = this.store.pipe(
    select(kanbanQuery.getSelectedKanbanIssueDetail)
  );

  deleteKanbanIssueSucceeded$ = this.store.pipe(
    select(kanbanQuery.deleteKanbanIssueSucceeded)
  );

  isSaveKanbanCommentLoading$ = this.store.pipe(
    select(kanbanQuery.isSaveKanbanCommentLoading)
  );
  saveKanbanCommentSucceeded$ = this.store.pipe(
    select(kanbanQuery.saveKanbanCommentSucceeded)
  );

  isDeleteKanbanCommentPending$ = this.store.pipe(
    select(kanbanQuery.isDeleteKanbanCommentPending)
  );
  deleteKanbanCommentSucceeded$ = this.store.pipe(
    select(kanbanQuery.deleteKanbanCommentSucceeded)
  );

  isUpdateKanbanCommentPending$ = this.store.pipe(
    select(kanbanQuery.isUpdateKanbanCommentPending)
  );

  updateKanbanCommentSucceeded$ = this.store.pipe(
    select(kanbanQuery.updateKanbanCommentSucceeded)
  );

  isUpdateSelectedKanbanLoading$ = this.store.pipe(
    select(kanbanQuery.isUpdateSelectedKanbanLoading)
  );

  constructor(private store: Store<KanbanPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadKanban());
  }

  saveKanban(payload) {
    this.store.dispatch(new SaveKanban(payload));
  }

  loadSelectedKanban(
    dashboardUid: string,
    projectUid: string,
    kanbanUid: string,
    selectedInstanceId: string
  ) {
    this.store.dispatch(
      new LoadSelectedKanban(
        dashboardUid,
        projectUid,
        kanbanUid,
        selectedInstanceId
      )
    );
  }

  updateSelectedKanban(projectUid: string, kanbanUid: string, payload) {
    this.store.dispatch(
      new UpdateSelectedKanban(projectUid, kanbanUid, payload)
    );
  }

  loadSelectedKanbanIssue(
    dashboardUid: string,
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string
  ) {
    this.store.dispatch(
      new LoadSelectedKanbanIssue(
        dashboardUid,
        projectUid,
        kabanBoardUid,
        issueListUid,
        issueUid
      )
    );
  }

  loadSelectedKanbanIssueTasks(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string
  ) {
    this.store.dispatch(
      new LoadSelectedKanbanIssueTasks(
        projectUid,
        kabanBoardUid,
        issueListUid,
        issueUid
      )
    );
  }

  loadSelectedKanbanIssueHistory(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string
  ) {
    this.store.dispatch(
      new LoadSelectedKanbanIssueHistory(
        projectUid,
        kabanBoardUid,
        issueListUid,
        issueUid
      )
    );
  }

  deleteKanbanIssue(
    projectUid: string,
    kanbanBoardUid: string,
    issueListUid: string,
    issueUid: string
  ) {
    this.store.dispatch(
      new DeleteKanbanIssue(projectUid, kanbanBoardUid, issueListUid, issueUid)
    );
  }

  saveKanbanComment(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string,
    text: string,
    elements
  ) {
    this.store.dispatch(
      new SaveKanbanComment(
        projectUid,
        kabanBoardUid,
        issueListUid,
        issueUid,
        text,
        elements
      )
    );
  }

  deleteKanbanComment(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string,
    commentUid: string
  ) {
    this.store.dispatch(
      new DeleteKanbanComment(
        projectUid,
        kabanBoardUid,
        issueListUid,
        issueUid,
        commentUid
      )
    );
  }

  updateKanbanComment(
    projectUid: string,
    kabanBoardUid: string,
    issueListUid: string,
    issueUid: string,
    commentUid: string,
    text: string,
    elements
  ) {
    this.store.dispatch(
      new UpdateKanbanComment(
        projectUid,
        kabanBoardUid,
        issueListUid,
        issueUid,
        commentUid,
        text,
        elements
      )
    );
  }

  addIssueList(payload) {
    this.store.dispatch(new AddIssueList(payload));
  }

  cleanKanbanState(payload: KanbanState) {
    this.store.dispatch(new CleanKanbanState(payload));
  }
}
