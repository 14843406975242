<div
  class="px-4 default-toolbar-container"
  fxLayout
  fxLayoutAlign="space-between center"
>
  <div
    [fxFlex]="selectedCalendarView !== calendarViewsEnum.agenda ? '340px' : 0"
    class="left-tools"
  >
    <div fxLayout fxLayoutAlign="start center">
      <ng-container *ngIf="selectedCalendarView !== calendarViewsEnum.agenda">
        <div class="today" (click)="picker.open()" style="cursor: pointer">
          <span class="month">{{ todayDate | date: "MMMM" }}</span>
          <span class="year">{{ todayDate | date: "yyyy" }}</span>
          <span class="year" style="visibility: hidden; position: relative">{{
            todayDate | date: "yyyy"
          }}</span>
          <mat-icon
            svgIcon="icon-arrow-down"
            style="
              width: 12px;
              height: 12px;
              min-height: 12px;
              min-width: 12px;
              line-height: 12px;
              margin-left: 5px;
              margin-right: 5px;
            "
          >
          </mat-icon>
          <input
            [matDatepicker]="picker"
            (dateChange)="addEvent($event)"
            [formControl]="datepickerControl"
            style="
              opacity: 0;
              position: absolute;
              left: 0;
              top: -25px;
              z-index: 0;
            "
          />
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <div fxLayout fxLayoutAlign="center center" class="controls">
          <button
            class="previous ml-2"
            [matTooltip]="'Previous ' + selectedCalendarView | ucwords"
            mat-icon-button
            (click)="onPreviousClick()"
          >
            <mat-icon svgIcon="icon-arrow-left"></mat-icon>
          </button>
          <button
            [matTooltip]="todayDate | date"
            style="color: #091e42"
            mat-button
            class="mr-2"
            (click)="onTodayClick()"
          >
            Today
          </button>
          <button
            class="next mr-2"
            [matTooltip]="'Next ' + selectedCalendarView | ucwords"
            mat-icon-button
            (click)="onNextClick()"
          >
            <mat-icon svgIcon="icon-arrow-right"></mat-icon>
          </button>
        </div>
      </ng-container>
      <mat-form-field
        class="calendar-views"
        appearance="outline"
        style="
          margin-top: 17px;
          margin-left: 8px;
          position: absolute;
          right: 0;
          top: -55px;
          width: 75px;
        "
      >
        <mat-select
          [(value)]="selectedCalendarView"
          (selectionChange)="onCalendarViewSelectionChange($event)"
        >
          <mat-option
            *ngFor="let calendarView of calendarViews"
            [value]="calendarView"
          >
            {{ calendarView | ucwords }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
