import { KanbanAction, KanbanActionTypes } from "./kanban.actions";

export const KANBAN_FEATURE_KEY = "kanban";

/**
 * Interface for the 'Kanban' data used in
 *  - KanbanState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* eslint-disable-next-line */
export interface Entity {}

export interface KanbanState {
  list?: Entity[]; // list of Kanban; analogous to a sql normalized table
  selectedId?: string | number; // which Kanban record has been selected
  loaded?: boolean; // has the Kanban list been loaded
  error?: any; // last none error (if any)

  isSaveKanbanLoading?: boolean;
  saveKanbanSucceeded?: boolean;
  savedKanbanDetail?: any;
  saveKanbanError?: any;

  selectedKanbanUid?: string;
  selectedKanbanDetail?: any;
  selectedKanbanDetailMap?: any;
  isSelectedKanbanLoading?: boolean;
  selectedKanbanLoaded?: boolean;
  selectedKanbanLoadError?: any;

  isUpdateSelectedKanbanLoading?: boolean;
  updateSelectedKanbanSucceeded?: boolean;
  updateSelectedKanbanError?: any;

  selectedKanbanIssueDetail?: any;
  isSelectedKanbanIssueLoading?: boolean;
  selectedKanbanIssueLoaded?: boolean;
  selectedKanbanIssueLoadError?: any;

  isSelectedKanbanIssueTasksLoading?: boolean;
  selectedKanbanIssueTasksLoaded?: boolean;
  selectedKanbanIssueTasksLoadError?: any;

  isSelectedKanbanIssueHistoryLoading?: boolean;
  selectedKanbanIssueHistoryLoaded?: boolean;
  selectedKanbanIssueHistoryLoadError?: boolean;

  isDeleteKanbanIssueLoading?: boolean;
  deleteKanbanIssueSucceeded?: boolean;
  deleteKanbanIssueError?: any;
  deletedIssueData?: any;

  isSaveKanbanCommentLoading?: boolean;
  saveKanbanCommentSucceeded?: boolean;
  saveKanbanCommentError?: any;

  isDeleteKanbanCommentPending?: boolean;
  deleteKanbanCommentSucceeded?: boolean;
  deleteKanbanCommentFailed?: any;

  isUpdateKanbanCommentPending?: boolean;
  updateKanbanCommentSucceeded?: boolean;
  updateKanbanCommentFailed?: any;
}

export interface KanbanPartialState {
  readonly [KANBAN_FEATURE_KEY]: KanbanState;
}

export const initialState: KanbanState = {
  list: [],
  loaded: false,
  selectedKanbanDetailMap: {},
  selectedKanbanLoadError: {},
};

export function reducer(
  state: KanbanState = initialState,
  action: KanbanAction
): KanbanState {
  switch (action.type) {
    case KanbanActionTypes.KanbanLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true,
      };
      break;
    }
    case KanbanActionTypes.SaveKanban: {
      state = {
        ...state,
        isSaveKanbanLoading: false,
        saveKanbanSucceeded: false,
        savedKanbanDetail: undefined,
        saveKanbanError: undefined,
      };
      break;
    }
    case KanbanActionTypes.SaveKanbanSucceeded: {
      state = {
        ...state,
        isSaveKanbanLoading: false,
        saveKanbanSucceeded: true,
        savedKanbanDetail: action.payload,
        saveKanbanError: undefined,
      };
      break;
    }
    case KanbanActionTypes.SaveKanbanFailed: {
      state = {
        ...state,
        isSaveKanbanLoading: false,
        saveKanbanSucceeded: false,
        saveKanbanError: action.payload,
        savedKanbanDetail: undefined,
      };
      break;
    }
    case KanbanActionTypes.LoadSelectedKanban: {
      state = {
        ...state,
        selectedKanbanUid: action.kanbanUid,
        selectedKanbanDetail: undefined,
        selectedKanbanDetailMap: {
          ...state.selectedKanbanDetailMap,
          [action.selectedInstanceId]: undefined,
        },
        isSelectedKanbanLoading: true,
        selectedKanbanLoaded: false,
        selectedKanbanLoadError: {
          ...state.selectedKanbanLoadError,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case KanbanActionTypes.SelectedKanbanLoaded: {
      state = {
        ...state,
        selectedKanbanDetail: action.payload,
        selectedKanbanDetailMap: {
          ...state.selectedKanbanDetailMap,
          [action.selectedInstanceId]: action.payload,
        },
        isSelectedKanbanLoading: false,
        selectedKanbanLoaded: true,
        selectedKanbanLoadError: {
          ...state.selectedKanbanLoadError,
          [action.selectedInstanceId]: undefined,
        },
      };
      break;
    }
    case KanbanActionTypes.SelectedKanbanLoadError: {
      state = {
        ...state,
        selectedKanbanDetail: undefined,
        selectedKanbanDetailMap: {
          ...state.selectedKanbanDetailMap,
          [action.selectedInstanceId]: undefined,
        },
        isSelectedKanbanLoading: false,
        selectedKanbanLoaded: false,
        selectedKanbanLoadError: {
          ...state.selectedKanbanLoadError,
          [action.selectedInstanceId]: action.payload,
        },
      };
      break;
    }
    case KanbanActionTypes.UpdateSelectedKanban: {
      state = {
        ...state,
        isUpdateSelectedKanbanLoading: true,
        updateSelectedKanbanSucceeded: false,
        updateSelectedKanbanError: undefined,
      };
      break;
    }
    case KanbanActionTypes.UpdateSelectedKanbanSucceeded: {
      state = {
        ...state,
        selectedKanbanDetail: action.payload,
        isUpdateSelectedKanbanLoading: false,
        updateSelectedKanbanSucceeded: true,
        updateSelectedKanbanError: undefined,
      };
      break;
    }
    case KanbanActionTypes.UpdateSelectedKanbanFailed: {
      state = {
        ...state,
        isUpdateSelectedKanbanLoading: false,
        updateSelectedKanbanSucceeded: false,
        updateSelectedKanbanError: action.payload,
      };
      break;
    }
    case KanbanActionTypes.LoadSelectedKanbanIssue: {
      state = {
        ...state,
        selectedKanbanIssueDetail: undefined,
        isSelectedKanbanIssueLoading: true,
        selectedKanbanIssueLoaded: false,
        selectedKanbanIssueLoadError: undefined,
      };
      break;
    }
    case KanbanActionTypes.SelectedKanbanIssueLoaded: {
      state = {
        ...state,
        selectedKanbanIssueDetail: action.payload,
        isSelectedKanbanIssueLoading: false,
        selectedKanbanIssueLoaded: true,
        selectedKanbanIssueLoadError: undefined,
      };
      break;
    }
    case KanbanActionTypes.SelectedKanbanIssueLoadError: {
      state = {
        ...state,
        selectedKanbanIssueDetail: undefined,
        isSelectedKanbanIssueLoading: false,
        selectedKanbanIssueLoaded: false,
        selectedKanbanIssueLoadError: action.payload,
      };
      break;
    }
    case KanbanActionTypes.LoadSelectedKanbanIssueTasks: {
      state = {
        ...state,
        isSelectedKanbanIssueTasksLoading: false,
        selectedKanbanIssueTasksLoaded: false,
        selectedKanbanIssueTasksLoadError: undefined,
      };
      break;
    }
    case KanbanActionTypes.SelectedKanbanIssueTasksLoaded: {
      state = {
        ...state,
        isSelectedKanbanIssueTasksLoading: false,
        selectedKanbanIssueTasksLoaded: true,
        selectedKanbanIssueTasksLoadError: undefined,
      };
      break;
    }
    case KanbanActionTypes.SelectedKanbanIssueTasksLoadError: {
      state = {
        ...state,
        isSelectedKanbanIssueTasksLoading: false,
        selectedKanbanIssueTasksLoaded: false,
        selectedKanbanIssueTasksLoadError: action.payload,
      };
      break;
    }
    case KanbanActionTypes.LoadSelectedKanbanIssueHistory: {
      state = {
        ...state,
        isSelectedKanbanIssueHistoryLoading: true,
        selectedKanbanIssueHistoryLoaded: false,
        selectedKanbanIssueHistoryLoadError: undefined,
      };
      break;
    }
    case KanbanActionTypes.SelectedKanbanIssueHistoryLoaded: {
      state = {
        ...state,
        isSelectedKanbanIssueHistoryLoading: false,
        selectedKanbanIssueHistoryLoaded: true,
        selectedKanbanIssueHistoryLoadError: undefined,
      };
      break;
    }
    case KanbanActionTypes.SelectedKanbanIssueHistoryLoadError: {
      state = {
        ...state,
        isSelectedKanbanIssueHistoryLoading: false,
        selectedKanbanIssueHistoryLoaded: false,
        selectedKanbanIssueHistoryLoadError: action.payload,
      };
      break;
    }
    case KanbanActionTypes.DeleteKanbanIssue: {
      state = {
        ...state,
        isDeleteKanbanIssueLoading: true,
        deleteKanbanIssueSucceeded: false,
        deleteKanbanIssueError: undefined,
        deletedIssueData: {
          projectUid: action.projectUid,
          kanbanBoardUid: action.kanbanBoardUid,
          issueListUid: action.issueListUid,
          issueUid: action.issueUid,
        },
      };
      break;
    }
    case KanbanActionTypes.DeleteKanbanIssueSucceeded: {
      state = {
        ...state,
        isDeleteKanbanIssueLoading: false,
        deleteKanbanIssueSucceeded: true,
        deleteKanbanIssueError: undefined,
      };
      break;
    }
    case KanbanActionTypes.DeleteKanbanIssueFailed: {
      state = {
        ...state,
        isDeleteKanbanIssueLoading: false,
        deleteKanbanIssueSucceeded: false,
        deleteKanbanIssueError: action.payload,
        deletedIssueData: undefined,
      };
      break;
    }
    case KanbanActionTypes.SaveKanbanComment: {
      state = {
        ...state,
        isSaveKanbanCommentLoading: true,
        saveKanbanCommentSucceeded: false,
        saveKanbanCommentError: undefined,
      };
      break;
    }
    case KanbanActionTypes.SaveKanbanCommentSucceeded: {
      state = {
        ...state,
        isSaveKanbanCommentLoading: false,
        saveKanbanCommentSucceeded: true,
        saveKanbanCommentError: undefined,
      };
      break;
    }
    case KanbanActionTypes.SaveKanbanCommentFailed: {
      state = {
        ...state,
        isSaveKanbanCommentLoading: false,
        saveKanbanCommentSucceeded: false,
        saveKanbanCommentError: action.payload,
      };
      break;
    }
    case KanbanActionTypes.AddIssueList: {
      state = {
        ...state,
        selectedKanbanDetail: {
          ...state.selectedKanbanDetail,
          issueLists: [
            ...state.selectedKanbanDetail.issueLists,
            action.payload,
          ],
        },
      };
      break;
    }

    case KanbanActionTypes.DeleteKanbanComment: {
      state = {
        ...state,
        isDeleteKanbanCommentPending: true,
        deleteKanbanCommentSucceeded: false,
        deleteKanbanCommentFailed: undefined,
      };
      break;
    }
    case KanbanActionTypes.DeleteKanbanCommentSucceeded: {
      state = {
        ...state,
        isDeleteKanbanCommentPending: false,
        deleteKanbanCommentSucceeded: true,
        deleteKanbanCommentFailed: undefined,
      };
      break;
    }
    case KanbanActionTypes.DeleteKanbanCommentFailed: {
      state = {
        ...state,
        isDeleteKanbanCommentPending: false,
        deleteKanbanCommentSucceeded: false,
        deleteKanbanCommentFailed: action.payload,
      };
      break;
    }

    case KanbanActionTypes.UpdateKanbanComment: {
      state = {
        ...state,
        isUpdateKanbanCommentPending: true,
        updateKanbanCommentSucceeded: false,
        updateKanbanCommentFailed: undefined,
      };
      break;
    }
    case KanbanActionTypes.UpdateKanbanCommentSucceeded: {
      state = {
        ...state,
        isUpdateKanbanCommentPending: false,
        updateKanbanCommentSucceeded: true,
        updateKanbanCommentFailed: undefined,
      };
      break;
    }
    case KanbanActionTypes.UpdateKanbanCommentFailed: {
      state = {
        ...state,
        isUpdateKanbanCommentPending: false,
        updateKanbanCommentSucceeded: false,
        updateKanbanCommentFailed: action.payload,
      };
      break;
    }

    case KanbanActionTypes.CleanKanbanState: {
      state = {
        ...state,
        ...action.payload,
      };
      break;
    }
  }
  return state;
}
