import {
  FullValMultiPdfProvViewModel,
  PartialValPdfProvisionViewModel,
  ProvisionFormStructureViewModel,
} from "@@intelease/api-models/adex-api-model-src";
import { JsonObject, JsonProperty } from "json2typescript";

export interface ProvisionFormStructureCategoriesModel {
  categories?: Array<ProvisionFormStructureViewModel>;
  description?: string;
  expanded?: boolean;
  htmlName?: string;
  name?: string;
  provisionInfoHtmlNames?: string[];
  provisionInfoUids?: string[];
  provisions?: Array<FullValMultiPdfProvViewModel>;
  uid?: string;
  version?: number;
}

export interface DocumentsMapModel {
  uid?: string;
  shortLabel?: string;
  name?: string;
  filename?: string;
  date?: Date;
}

export enum QueueInfoStageEnum {
  COMPLETED = "COMPLETED",
  READY = "READY",
  IN_QA_REVIEW = "IN_QA_REVIEW",
  IN_REVIEW = "IN_REVIEW",
  NEED_QA_REVIEW = "NEED_QA_REVIEW",
}

@JsonObject("PartialValPdfProvisionView2Model")
export class PartialValPdfProvisionView2Model extends PartialValPdfProvisionViewModel {
  @JsonProperty("valueType", String, true)
  valueType?:
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN"
    | "NESTED_GROUP";
}
