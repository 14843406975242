/* tslint:disable */
/* eslint-disable */
import { PdfHighlightDtoModel } from "./pdf-highlight-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("BookmarkDtoModel")
export class BookmarkDtoModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("createdByUid", String, true)
  createdByUid?: string = "";

  @JsonProperty("docAbstractUid", String, true)
  docAbstractUid?: string = "";

  @JsonProperty("highlightType", Any, true)
  highlightType?: "TEXT" | "AREA" = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("notes", String, true)
  notes?: string = "";

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  @JsonProperty("pdfHighlights", [PdfHighlightDtoModel], true)
  pdfHighlights?: Array<PdfHighlightDtoModel> = [];

  @JsonProperty("text", String, true)
  text?: string = "";
}
