import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PgOptionComponent } from "./option.component";
import { OptionPipe } from "./option.pipe";
import { PgSelectComponent } from "./select.component";

@NgModule({
  imports: [CommonModule, FormsModule, OverlayModule],
  declarations: [OptionPipe, PgOptionComponent, PgSelectComponent],
  exports: [OptionPipe, PgOptionComponent, PgSelectComponent],
})
export class PgSelectModule {}
