/* tslint:disable */
/* eslint-disable */
import { RevertRecordReviewStageReqDtoModel } from "./revert-record-review-stage-req-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqRevertRecordReviewStageModel")
export class OApiReqRevertRecordReviewStageModel {
  @JsonProperty("data", RevertRecordReviewStageReqDtoModel, true)
  data?: RevertRecordReviewStageReqDtoModel = undefined;
}
