<div [ngStyle]="{ display: isChildModalOpen ? 'none' : 'block' }">
  <div mat-dialog-title class="clearfix text-left">
    <button
      type="button"
      class="close"
      (click)="onDismissModalClick()"
      aria-hidden="true"
      dataCy="closeAddProvisionFormModal"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5 class="mt-0">Create a New Provision Form</h5>
  </div>
  <div mat-dialog-content>
    <div class="form-group" itlsPgFormGroupDefault>
      <label>Name</label>
      <input
        itlsFocusIn
        [(ngModel)]="name"
        (keyup)="checkValidateFields()"
        type="text"
        class="form-control"
        dataCy="addProvisionFormName"
      />
    </div>
    <div class="form-group" itlsPgFormGroupDefault>
      <label>Description</label>
      <input
        [(ngModel)]="description"
        dataCy="addProvisionFormDescription"
        type="text"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>Document Type</label>
      <ng-select
        [items]="documentTypeOptions"
        [multiple]="false"
        [closeOnSelect]="true"
        appendTo="body"
        bindLabel="uiName"
        dataCy="addProvisionFormDocType"
        placeholder="Document Type"
        bindValue="value"
        (change)="checkValidateFields()"
        [(ngModel)]="selectedDocumentType"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <span class="ng-option-label" [id]="item.value">{{
            item.uiName
          }}</span>
        </ng-template>
      </ng-select>
    </div>
    <div class="form-group">
      <label> Build using form builder or import TSV </label>
      <br />
      <mat-radio-group
        (change)="checkValidateFields()"
        [(ngModel)]="buildOrImport"
        aria-label="Build using form builder or import TSV"
      >
        <mat-radio-button value="FORM_BUILDER">
          <div style="margin-bottom: -0.5rem !important">Use Form Builder</div>
        </mat-radio-button>
        <mat-radio-button value="IMPORT_TSV">
          <div style="margin-bottom: -0.5rem !important">Import TSV File</div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div mat-dialog-actions>
    <div [matTooltip]="tooltipCreateBtn">
      <button
        type="button"
        dataCy="submitAddProvisionFormModal"
        class="btn btn-primary btn-cons pull-left inline"
        (click)="onSaveClick()"
        [disabled]="isValidateFields"
      >
        Create
      </button>
    </div>
  </div>
</div>
