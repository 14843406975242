import { JsonProperty } from "json2typescript";

export class LbxRoleDto {
  @JsonProperty("role", String)
  role: string;
  @JsonProperty("roleName", String)
  roleName: string;
  @JsonProperty("uiName", String)
  uiName: string;
}
