import { Any, JsonObject, JsonProperty } from "json2typescript";
import { FullValMultiProvisionValueModel } from "@@intelease/web/common/models/multi-provision-value";

@JsonObject("TermCalculatorResponseModel")
export class TermCalculatorResponseModel {
  @JsonProperty("messageinfoList", Any)
  messageinfoList: any = "";

  @JsonProperty("htmlNameToFullVal", [FullValMultiProvisionValueModel])
  htmlNameToFullVal: Array<[string, FullValMultiProvisionValueModel]> = [];
}
