/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UserSettingItemModel")
export class UserSettingItemModel {
  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("format", Any, true)
  format?:
    | "BOOLEAN"
    | "SINGLE_SELECT"
    | "MULTI_SELECT"
    | "INTEGER"
    | "DOUBLE"
    | "TEXT" = undefined;

  @JsonProperty("key", String, true)
  key?: string = "";

  @JsonProperty("keyAsEnum", Any, true)
  keyAsEnum?:
    | "AUTO_SELECT_PROVISION_MENTIONS"
    | "KEEP_EARLIER_DOCUMENTS_MENTIONS"
    | "FLAG_EMPTY_PROVISIONS_FOR_REVIEW"
    | "FORCE_QUEUE_FOR_UPLOAD"
    | "SKIP_TASK_CREATION_FROM_QUEUE"
    | "IGNORE_QUEUE_DEFAULT_UNITS"
    | "INCLUDE_NOTES"
    | "INCLUDE_ANNOTATED_DOCS"
    | "INCLUDE_LINK"
    | "INCLUDE_SOURCE_ATTRIBUTION"
    | "INCLUDE_SECTION_HEADER"
    | "EXPORT_TYPES_SUPPORTED"
    | "EXCLUDE_EMPTY_PROVISIONS"
    | "EMPTY_PROVISION_TEXT"
    | "ALLOW_FULL_EXPORT"
    | "UNIT_SYSTEM"
    | "CURRENCIES"
    | "DATE_FORMATS"
    | "PROVISION_BOX_DUAL_WINDOW"
    | "ALLOW_TEXT_PROVISION_MENTIONS"
    | "ALLOW_RENAME_RECORD_PROVISION"
    | "ALLOW_REGULAR_USER_TO_EDIT_PROVISION_FORM"
    | "DISABLE_CREATE_PROVISION"
    | "EMAIL_NOTIFICATION_FOR_TASKS"
    | "EMAIL_NOTIFICATION_FOR_DOCSET_ABSTRACTION"
    | "SKIP_MENTION_AND_SUBFIELD_SELECTIONS_ON_HIGHLIGHT" = undefined;

  @JsonProperty("possibleValues", [{}], true)
  possibleValues?: Array<{}> = [];

  @JsonProperty("source", Any, true)
  source?: "GENERAL" | "PIPELINE_PROCESSING" = undefined;

  @JsonProperty("value", Any, true)
  value?: any = {};
}
