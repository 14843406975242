/* tslint:disable */
/* eslint-disable */
import { DocAbstractEntryModel } from "./doc-abstract-entry-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocAbstractDiffRequestModel")
export class DocAbstractDiffRequestModel {
  @JsonProperty("source", DocAbstractEntryModel, true)
  source?: DocAbstractEntryModel = undefined;

  @JsonProperty("targets", [DocAbstractEntryModel], true)
  targets?: Array<DocAbstractEntryModel> = [];
}
