import { NgModule } from "@angular/core";
import { OptionsCalculatorModalComponent } from "./options-calculator-modal.component";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { InteleaseModule } from "@@intelease/web/intelease/intelease.module";
import { NgScrollbarModule } from "ngx-scrollbar";
import { InputDropdownModule } from "@@intelease/web/intelease/components";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CustomInputFieldComponent } from "./components/input-field/input-field.component";
import { InputDropdownDropdownComponent } from "./components/input-dropdown-dropdown/input-dropdown.component";
import { MatDialogModule } from "@angular/material/dialog";
import { SelectMentionConfirmModalModule } from "../../select-mention-confirm-modal/select-mention-confirm-modal.module";
import { AngularDraggableModule } from "angular2-draggable";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

const INTELEASE_MODULES = [InputDropdownModule];

const NGX_BOOTSTRAP = [
  TabsModule.forRoot(),
  BsDatepickerModule.forRoot(),
  ModalModule.forRoot(),
];

@NgModule({
  imports: [
    CommonModule,
    InteleaseCommonModule,
    InteleaseModule,
    NgScrollbarModule,
    ...INTELEASE_MODULES,
    ...NGX_BOOTSTRAP,
    TooltipModule,
    MatDialogModule,
    SelectMentionConfirmModalModule,
    AngularDraggableModule,
    DragDropModule,
    ItlsCustomIconModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    OptionsCalculatorModalComponent,
    InputDropdownDropdownComponent,
    CustomInputFieldComponent,
  ],
})
export class OptionsCalculatorModalModule {}
