import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { isPlatformServer } from "@angular/common";
import { LocalStorageKey } from "../../common/enums/local-storage.keys";
import { LocalStorageService } from "../../common/services";

@Injectable()
export class AuthGuardService {
  private readonly isServer: boolean;

  constructor(@Inject(PLATFORM_ID) platformId, public router: Router) {
    this.isServer = isPlatformServer(platformId);
  }

  canActivate(): boolean {
    if (!this.isServer) {
      const auth: any = localStorage.getItem(LocalStorageKey.AUTH);
      if (auth) {
        return true;
      }
      // TODO(apoorv): Need this stupid extra reload here b/c, otherwise, for some reason, the following problem exists:
      // If user logs out and then immediately logs in as a user in PRE_TOS status,
      // the form for terms-of-service behaves incorrectly. Specifically, the "Approve" checkbox
      // does not update on the UI. So this extra reload sidesteps that issue.
      localStorage.setItem(
        LocalStorageKey.REDIRECT_URL,
        window.location.pathname
      );
      this.router
        .navigateByUrl("/auth/login")
        .then((inRes) => window.location.reload());
      return false;
    }
    return true;
  }
}
