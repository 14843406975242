/* tslint:disable */
/* eslint-disable */
import { UploadedDocumentSetResultDtoModel } from "./uploaded-document-set-result-dto-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("UploadedDocumentSetsResultDtoModel")
export class UploadedDocumentSetsResultDtoModel {
  @JsonProperty("items", [UploadedDocumentSetResultDtoModel], true)
  items?: Array<UploadedDocumentSetResultDtoModel> = [];

  @JsonProperty("selectedDirectoryUid", String, true)
  selectedDirectoryUid?: string = "";
}
