import { Injectable } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { AddQueueModalComponent } from "../modals/add-new-queue/add-queue-modal.component";
import { DuplicateQueueModalComponent } from "../modals/duplicate-queue/duplicate-queue-modal.component";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class QueueModalService {
  static openQueueModal$: Subject<any> = new Subject<any>();

  constructor(private dialog: MatDialog) {}

  openAddQueueModal(modalData: ModalInputModel) {
    return this.dialog.open(AddQueueModalComponent, {
      data: modalData,
      minWidth: "500px",
      autoFocus: false,
    });
  }

  openDuplicateQueueModal(modalData: ModalInputModel) {
    return this.dialog.open(DuplicateQueueModalComponent, {
      data: modalData,
      minWidth: "500px",
      autoFocus: false,
    });
  }
}
