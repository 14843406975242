import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UploadFilesModel } from "@@intelease/web/ui/src/lib/itls-new-upload/models/upload-files.model";
import { RestClient } from "@@intelease/web/utils";
import { map } from "rxjs/operators";
import {
  DocumentReorderingEvent,
  UploadLeaseResponseItemModel,
  UploadLeaseResponseModel,
} from "@@intelease/web/ui/src/lib/itls-new-upload";
import { ServerResponseModel } from "@intelease/models";

@Injectable({
  providedIn: "root",
})
export class NewUploadService {
  private static readonly API_VERSION_2 = "/v2";
  private static readonly API_VERSION_3 = "/v3";
  private static readonly UPLOAD_URL = "/uploadLease";

  public static getFilenameWithoutExtension(file: File): string {
    const lastDotIdx = file.name.lastIndexOf(".");
    let nameWithoutExtension = file.name;
    if (lastDotIdx !== -1) {
      nameWithoutExtension = file.name.substr(0, lastDotIdx);
    }
    return nameWithoutExtension;
  }

  constructor(private httpClient: HttpClient, private restClient: RestClient) {}

  public static getRelativePath(file: File): string {
    return file["webkitRelativePath"];
  }

  uploadLeaseV2(
    uploadFilesModel: UploadFilesModel
  ): Observable<ServerResponseModel> {
    if (
      !uploadFilesModel.isUploadingFolder &&
      uploadFilesModel.uploadedDocSets.length > 1
    ) {
      throw new Error("we must have only 1 record for non-batch uploads!");
    }

    const formData = new FormData();
    const keyToAbstractName = {};
    const keyToDocumentReorderingEvents = {};
    for (const uploadedDocSet of uploadFilesModel.uploadedDocSets) {
      for (const myFile of uploadedDocSet.myFiles) {
        formData.append(
          uploadedDocSet.folderPath,
          myFile.file,
          myFile.name + this.getExtension(myFile.file)
        );
      }
      keyToAbstractName[uploadedDocSet.folderPath] = uploadedDocSet.name;
      keyToDocumentReorderingEvents[uploadedDocSet.folderPath] =
        uploadedDocSet.documentReorderingEvents;
    }
    formData.set(
      "uploadDocEvents",
      JSON.stringify(keyToDocumentReorderingEvents)
    );
    formData.set("abstractNames", JSON.stringify(keyToAbstractName));
    formData.set("uploadingFolder", uploadFilesModel.isUploadingFolder + "");
    formData.set("provisionGroupUid", uploadFilesModel.provisionFormUid);
    if (uploadFilesModel.directoryUid) {
      formData.set("directoryUid", uploadFilesModel.directoryUid);
    }

    return this.httpClient.post<ServerResponseModel>(
      NewUploadService.API_VERSION_2 + NewUploadService.UPLOAD_URL,
      formData
    );
  }

  uploadLease(
    uploadFilesModel: UploadFilesModel
  ): Observable<UploadLeaseResponseModel> {
    const uploadingFolder = uploadFilesModel.isUploadingFolder;
    const documentSets = uploadFilesModel.uploadedDocSets.map((docSet) => ({
      directoryPath: uploadingFolder ? docSet.folderPath : undefined,
      name: docSet.name,
      uploadDocEvents: {
        actions: this.updateUploadDocEvents(docSet.documentReorderingEvents),
      },
      fileNames: docSet.myFiles.map(
        (myFile) => myFile.name + this.getExtension(myFile.file)
      ),
      fileInfos: docSet.myFiles.map((myFile) => ({
        name: myFile.name + this.getExtension(myFile.file),
        size: myFile.file.size,
      })),
    }));
    return this.restClient
      .sendPostRequestNoView(
        NewUploadService.API_VERSION_3,
        NewUploadService.UPLOAD_URL,
        {
          data: {
            provisionFormUid: uploadFilesModel.provisionFormUid,
            directoryUid: uploadFilesModel.directoryUid,
            uploadingFolder,
            documentSets,
          },
        }
      )
      .pipe(map((resp: any) => resp.data));
  }

  isFolderSelected(files: File[]): boolean {
    return (
      files.length > 0 && NewUploadService.getRelativePath(files[0])?.length > 0
    );
  }

  private updateUploadDocEvents(
    documentReorderingEvents: DocumentReorderingEvent[]
  ): any[] {
    const lastReorderingEvent =
      documentReorderingEvents[documentReorderingEvents.length - 1];
    const filenameToName = {};
    for (const myFile of lastReorderingEvent.post) {
      filenameToName[myFile.file.name] = myFile.name;
    }
    return documentReorderingEvents.map((documentReorderingEvent) => {
      return {
        pre: documentReorderingEvent.pre.map(
          (myFile) => filenameToName[myFile.file.name]
        ),
        post: documentReorderingEvent.post.map(
          (myFile) => filenameToName[myFile.file.name]
        ),
      };
    });
  }

  private getExtension(file: File) {
    const lastDotIdx = file.name.lastIndexOf(".");
    let extension = "";
    if (lastDotIdx !== -1) {
      extension = file.name.substr(lastDotIdx);
    }
    return extension;
  }
}
