/* tslint:disable */
/* eslint-disable */
import { JsonNodeModel } from "./json-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ReportChartModel")
export class ReportChartModel {
  @JsonProperty("config", Any, true)
  config?: any = undefined;

  @JsonProperty("title", String, true)
  title?: string = "";

  @JsonProperty("type", Any, true)
  type?:
    | "LINE"
    | "BAR"
    | "HORIZONTAL_BAR"
    | "RADAR"
    | "DOUGHNUT"
    | "POLAR_AREA"
    | "BUBBLE"
    | "PIE"
    | "SCATTER" = undefined;
}
