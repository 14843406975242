import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";

export const SAVE_ACTION_BUTTON_CONST: ActionButtonInterface = {
  title: "Mark Complete",
  actionKey: "SAVE",
  children: [
    {
      title: "Go to Record",
      actionKey: "RECORD",
      children: [],
    },
    {
      title: "Assign for Review",
      actionKey: "REVIEW",
      children: [],
    },
    {
      title: "Export as PDF",
      actionKey: "PDF",
      children: [],
    },
    {
      title: "Export as XLSX",
      actionKey: "XLSX",
      children: [],
    },
    {
      title: "Export as CSV",
      actionKey: "CSV",
      children: [],
    },
    {
      title: "Export as DOCX",
      actionKey: "DOCX",
      children: [],
    },
  ],
};
