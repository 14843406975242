/* tslint:disable */
/* eslint-disable */
import { NestedProvisionSubfieldDtoModel } from "./nested-provision-subfield-dto-model";

/**
 * the request body for appending a value to an existing mention
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ModifyNestedProvisionSubfieldsApiDtoModel")
export class ModifyNestedProvisionSubfieldsApiDtoModel {
  /**
   * the additional subfields to be added to the NESTED_GROUP provision
   */

  @JsonProperty("additionalSubfields", [NestedProvisionSubfieldDtoModel], true)
  additionalSubfields?: Array<NestedProvisionSubfieldDtoModel> = [];
}
