<div
  style="position: relative; border: 1px solid #0000"
  class="il-ui-data-table"
  (resized)="onResized($event)"
  [class]="isShiftHold ? 'user-select-none' : ''"
  (mousedown)="checkShiftHold($event)"
>
  <button
    dataCy="data-table-add-btn"
    *ngIf="showAddBtn"
    [matTooltip]="addBtnTooltip"
    style="position: absolute; top: 10px; right: 12px; z-index: 1"
    (click)="onAddClick()"
    mat-fab
  >
    <mat-icon>add</mat-icon>
  </button>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loadingIndicator"
  ></mat-progress-bar>
  <ngx-datatable
    [style.border.top]="showAddBtn ? '1px solid #ebebeb' : 'unset'"
    [class]="tableSize === 'small' ? tableClass + ' small-table ' : tableClass"
    #itlsTable
    *ngIf="!loadingIndicator"
    [ngClass]="{ expandable: expandable }"
    [columns]="columns"
    [rowIdentity]="getUid"
    [columnMode]="columnModeSetting"
    [loadingIndicator]="false"
    [messages]="messages"
    [scrollbarH]="scrollH"
    [headerHeight]="50"
    [footerHeight]="36"
    [rowHeight]="'auto'"
    [externalPaging]="false"
    [sortType]="sortType.single"
    [reorderable]="reOrderable"
    [count]="pagination.totalResults"
    [offset]="pagination.page - 1"
    [limit]="pagination.size"
    [virtualization]="true"
    [selected]="selectedItems"
    [selectionType]="selectionType.multi"
    [rows]="rows"
    (page)="changePage($event)"
    (select)="onSelectRow($event)"
    (tableContextmenu)="onTableContextMenu($event)"
  >
    <ng-container *ngIf="expandable">
      <ngx-datatable-row-detail
        [rowHeight]="200"
        style="overflow: scroll"
        #myDetailRow
        (toggle)="onDetailToggle($event)"
      >
        <ng-template
          let-row="row"
          let-expanded="expanded"
          ngx-datatable-row-detail-template
        >
          <ng-template
            [ngTemplateOutlet]="rowExpandTemplate"
            [ngTemplateOutletContext]="{ $implicit: row }"
          >
          </ng-template>
        </ng-template>
      </ngx-datatable-row-detail>
      <ngx-datatable-column
        [width]="50"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
      >
        <ng-template
          let-row="row"
          let-expanded="expanded"
          ngx-datatable-cell-template
        >
          <a
            href="javascript:void(0)"
            [class.datatable-icon-right]="!expanded"
            [class.datatable-icon-down]="expanded"
            matTooltip="Expand/Collapse Row"
            (click)="toggleExpandRow(row)"
          >
          </a>
        </ng-template>
      </ngx-datatable-column>
    </ng-container>

    <ng-container *ngFor="let col of columns">
      <ng-container *ngIf="!col.isHidden">
        <ngx-datatable-column
          *ngIf="col.type === 'CHECKBOX'"
          [width]="30"
          [sortable]="false"
          [canAutoResize]="false"
          [resizeable]="false"
        >
          <ng-template
            ngx-datatable-header-template
            let-value="value"
            let-allRowsSelected="allRowsSelected"
            let-selectFn="selectFn"
          >
            <mat-checkbox
              [checked]="allRowsSelected"
              (change)="selectFn(!allRowsSelected)"
            ></mat-checkbox>
          </ng-template>
          <ng-template
            ngx-datatable-cell-template
            let-id="id"
            let-value="value"
            let-isSelected="isSelected"
            let-onCheckboxChangeFn="onCheckboxChangeFn"
          >
            <mat-checkbox [checked]="isSelected"></mat-checkbox>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [sortable]="col.sortable && col.type !== 'ACTION'"
          [name]="col.name | shorten: 20:'...'"
          [prop]="col.prop"
          [minWidth]="200"
          *ngIf="col.type !== 'ACTION' && col.type !== 'CHECKBOX'"
        >
          <!--          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>-->
          <!--            <span (click)="sort()">{{ column.name|translate }}</span>-->
          <!--          </ng-template>-->
          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <div
              (dblclick)="onRowClick(row)"
              [style.backgroundColor]="
                contextMenuContent && contextMenuContent?.uid === row?.uid
                  ? '#eee'
                  : 'unset'
              "
            >
              <ng-container [ngSwitch]="col.type">
                <span
                  *ngSwitchCase="'RANGE'"
                  [class]="col.cssClass ? col.cssClass : ''"
                >
                  {{ value["min"] }} - {{ value["max"] }}
                </span>
                <span
                  style="
                    padding: 14px 14px 14px 14px !important;
                    width: 100%;
                    display: inline-block;
                  "
                  *ngSwitchCase="'DATE'"
                  [class]="col.cssClass ? col.cssClass : ''"
                >
                  {{ value | date: "medium" }}
                </span>
                <span
                  *ngSwitchCase="'LINK'"
                  [matTooltip]="value.value"
                  style="
                    padding: 14px 14px 14px 14px !important;
                    width: 100%;
                    display: inline-block;
                  "
                  [class]="col.cssClass ? col.cssClass : ''"
                >
                  <span *ngIf="value.link">
                    <a
                      *ngIf="value.value"
                      [target]="col.target"
                      [href]="value.link"
                    >
                      {{ (value.value | shorten: 20:"...") || "-" }}
                      <!--                        <mat-icon style="font-size: 14px;" *ngIf="col.target==='_blank'">launch</mat-icon>-->
                    </a>
                    <ng-container *ngIf="!value.value">{{
                      (value.value | shorten: 20:"...") || "-"
                    }}</ng-container>
                  </span>
                  <span *ngIf="!value.link">
                    {{ (value | shorten: 20:"...") || "-" }}
                  </span>
                </span>
                <span
                  style="
                    padding: 14px 14px 14px 14px !important;
                    width: 100%;
                    display: inline-block;
                  "
                  *ngSwitchCase="'NUMBER'"
                  [class]="col.cssClass ? col.cssClass : ''"
                >
                  {{ value }}
                </span>
                <span
                  style="
                    padding: 14px 14px 14px 14px !important;
                    width: 100%;
                    display: inline-block;
                  "
                  *ngSwitchCase="'ENUM'"
                  [class]="col.cssClass ? col.cssClass : ''"
                >
                  {{ value | enums: col.enumKey }}
                </span>
                <span
                  style="
                    padding: 14px 14px 14px 14px !important;
                    width: 100%;
                    display: inline-block;
                  "
                  [matTooltip]="value"
                  *ngSwitchDefault
                  [class]="col.cssClass ? col.cssClass : ''"
                >
                  {{ (value | shorten: 50:"...") || "-" }}
                </span>
              </ng-container>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <!--        <ngx-datatable-column [sortable]="false" [cellClass]="'text-center'" [style.textAlign]="'center'"-->
        <!--                              [draggable]="false"-->
        <!--                              [minWidth]="95"-->
        <!--                              [headerClass]="'text-center'"-->
        <!--                              *ngIf="col.type==='ACTION'">-->
        <!--          <ng-template let-column="column" ngx-datatable-header-template>-->
        <!--            <button style="position: absolute;top: -11px;" mat-icon-button-->
        <!--                    [matMenuTriggerFor]="columnSelectionMenu">-->
        <!--              <mat-icon>more_vert</mat-icon>-->
        <!--            </button>-->
        <!--            <mat-menu #columnSelectionMenu="matMenu">-->
        <!--              <mat-selection-list>-->
        <!--                <ng-container *ngFor="let column of columns">-->
        <!--                  <mat-list-option *ngIf="column?.name && column?.type !== 'CHECKBOX' && column?.type !== 'ACTION'"-->
        <!--                                   [selected]="!column.isHidden"-->
        <!--                                   (click)="onToggleColumn($event, column)">-->
        <!--                    {{column.name|translate}}</mat-list-option>-->
        <!--                </ng-container>-->
        <!--              </mat-selection-list>-->
        <!--            </mat-menu>-->
        <!--          </ng-template>-->
        <!--          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>-->
        <!--                        <mat-menu #menu="matMenu" class="menu-product-list" *ngIf="contextMenuEvent" style="position: absolute;"-->
        <!--                                  [style.top]="contextMenuEvent?.y" [style.left]="contextMenuEvent?.x">-->
        <!--                          <ng-container *ngFor="let menu of rowMenu">-->
        <!--                            <button mat-menu-item (click)="onRowMenuClick(menu, row);">-->
        <!--                              <mat-icon>{{menu.icon}}</mat-icon>-->
        <!--                              <span>{{menu.title|translate}}</span>-->
        <!--                            </button>-->
        <!--                          </ng-container>-->
        <!--                        </mat-menu>-->
        <!--                        <button style="position: absolute; top: 3px;" dataCy="data-table-row-options-btn" *ngIf="hasMenu"-->
        <!--                                mat-icon-button [matMenuTriggerFor]="menu">-->
        <!--                          <mat-icon>more_horiz</mat-icon>-->
        <!--                        </button>-->
        <!--          </ng-template>-->
        <!--        </ngx-datatable-column>-->
      </ng-container>
    </ng-container>
    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <span class="pl-3" *ngIf="rows?.length > pageSize"
          >{{ (curPage - 1) * pageSize + 1 }} -
          {{ rowCount < curPage * pageSize ? rowCount : curPage * pageSize }}
          of {{ rowCount | number }}</span
        >
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
        <!--        <span class="per-page-swimlane-container" *ngIf="pagination.totalResults>pageSize">-->
        <!--          <label class="per-page">{{'common.dataTable.itemsPerPage'|translate}}</label>-->
        <!--          <mat-form-field appearance="legacy" class="per-page-control">-->
        <!--          <mat-select [(value)]="pagination.size">-->
        <!--            <mat-option *ngFor="let limit of perPageLimits" [value]="limit">{{limit}}</mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
        <!--        </span>-->
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
  <div
    class="context-menu-container"
    [matMenuTriggerFor]="contextMenu"
    #contextMenuTrigger="matMenuTrigger"
    [hidden]="!contextMenuPos"
    [ngStyle]="{
      'left.px': contextMenuPos?.x,
      'top.px': contextMenuPos?.y
    }"
  >
    <mat-menu
      #contextMenu="matMenu"
      hasBackdrop="false"
      class="itls-data-table-ctx-menu"
    >
      <ng-container
        *ngIf="selectedItems.length > 1; else singleSelectionRowMenu"
      >
        <ng-container *ngFor="let menu of rowBatchMenu">
          <button
            *ngxPermissionsOnly="menu.requiredPermissions || []"
            mat-menu-item
            (click)="onRowMenuClick(menu, contextMenuContent)"
            [dataCy]="menu?.key"
          >
            <span style="margin-right: 12px; margin-top: -4px">
              <itls-icon [svgIcon]="menu.icon"></itls-icon>
            </span>
            <span class="title">{{ menu.title }}</span>
          </button>
        </ng-container>
      </ng-container>
      <ng-template #singleSelectionRowMenu>
        <ng-container *ngFor="let menu of rowMenu">
          <button
            *ngxPermissionsOnly="menu.requiredPermissions || []"
            mat-menu-item
            (click)="onRowMenuClick(menu, contextMenuContent)"
            [dataCy]="menu?.key"
          >
            <span style="margin-right: 12px; margin-top: -4px">
              <itls-icon [svgIcon]="menu.icon"></itls-icon>
            </span>
            <span class="title">{{ menu.title }}</span>
          </button>
        </ng-container>
      </ng-template>
    </mat-menu>
  </div>
</div>
