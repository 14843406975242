import {
  MentionEditTrackerAction,
  MentionEditTrackerActionTypes,
} from "./mention-edit-tracker.actions";

export const MENTION_EDIT_TRACKER_FEATURE_KEY = "mentionEditTracker";

export interface MentionEditTrackerState {
  lastTouchedMentionUid?: string;
  lastNestedSubfieldTouched?: string;
  lastEditedNestedSubfieldKey?: string;
  nestedMentionUid?: string;
  nestedSubfieldKey?: string;
  anchorMentionIndex?: number;
}

const initialState: MentionEditTrackerState = {
  lastEditedNestedSubfieldKey: undefined,
  lastNestedSubfieldTouched: undefined,
  lastTouchedMentionUid: undefined,
  nestedMentionUid: undefined,
  nestedSubfieldKey: undefined,
  anchorMentionIndex: undefined,
};

export function mentionEditTrackerReducer(
  state: MentionEditTrackerState = initialState,
  action: MentionEditTrackerAction
): MentionEditTrackerState {
  switch (action.type) {
    case MentionEditTrackerActionTypes.LastTouchedMentionUid: {
      return {
        ...state,
        lastTouchedMentionUid: action.payload.mentionUid,
      };
    }
    case MentionEditTrackerActionTypes.LastTouchedNestedSubfieldKey: {
      return {
        ...state,
        lastNestedSubfieldTouched: action.payload.subfieldKey,
      };
    }
    case MentionEditTrackerActionTypes.LastEditedNestedSubfieldKey: {
      return {
        ...state,
        lastEditedNestedSubfieldKey: action.payload.subfieldKey,
      };
    }
    case MentionEditTrackerActionTypes.LocateNestedSubfield: {
      const { nestedMentionUid, nestedSubfieldKey } = action.payload;
      return {
        ...state,
        nestedMentionUid,
        nestedSubfieldKey,
      };
    }
    case MentionEditTrackerActionTypes.AnchorMentionIndexChanged: {
      return {
        ...state,
        anchorMentionIndex: action.payload.index,
      };
    }
  }

  return state;
}
