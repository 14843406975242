import { ReportsFacade } from "@@intelease/app-state/reports/src";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { combineLatest } from "rxjs";

@Component({
  selector: "intelease-add-report",
  templateUrl: "./add-report.component.html",
  styleUrls: ["./add-report.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddReportComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  reportName: string;
  description: string;
  isValidateFields = true;
  isSavingReport = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ModalInputModel,
    public dialogRef: MatDialogRef<AddReportComponent>,
    private reportsFacade: ReportsFacade,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.reportsFacade.isReportSaved$,
      this.reportsFacade.isSavingReport$,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isReportSaved, isSavingReport]) => {
        this.isSavingReport = isSavingReport;
        if (isReportSaved) {
          this.dialogRef.close({
            reportName: this.reportName,
            description: this.description,
          });
        }
        this.cdr.markForCheck();
      });
  }

  onSaveReportClick() {
    const payload = {
      ...this.modalData.payload,
      data: {
        ...this.modalData.payload.data,
        name: this.reportName,
        description: this.description,
      },
    };
    this.reportsFacade.saveReport(payload);
  }
}
