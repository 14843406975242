/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FilterInterfaceModel")
export class FilterInterfaceModel {
  @JsonProperty("logical", Boolean, true)
  logical?: boolean = undefined;

  @JsonProperty("mongoType", String)
  mongoType: string = "";

  @JsonProperty("value", Any, true)
  value?: any = {};
}
