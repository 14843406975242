/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiRespDocPdfViewModel } from "../models/o-api-resp-doc-pdf-view-model";
import { OApiRespFullPagePdfViewViewModel } from "../models/o-api-resp-full-page-pdf-view-view-model";

@Injectable({
  providedIn: "root",
})
export class PdfViewService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getFullDocumentView
   */
  static readonly GetFullDocumentViewPath =
    "/api/v1/docPdfViews/{docPdfViewUid}/abstract/{recordUid}";

  /**
   * Get full document view of a record using the specified document.
   *
   * If request specifies a provisionValueUids, just filter based on that. Else, if request filters either pageNums or provisionUids, filter using AND of those.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFullDocumentView()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullDocumentView$Response(params: {
    /**
     * The unique id of the document.
     */
    docPdfViewUid: string;

    /**
     * The unique id of the record whose document view will be fetched.
     */
    recordUid: string;

    /**
     * If provided, just filter the pdf view based on it.
     */
    provisionValueUids?: Array<string>;

    /**
     * If provisionValueUids is not provided, filter is applied based on pageNums.
     */
    pageNums?: Array<number>;

    /**
     * If provisionValueUids is not provided, filter is applied based on provisionUids.
     */
    provisionUids?: Array<string>;
  }): Observable<StrictHttpResponse<OApiRespDocPdfViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      PdfViewService.GetFullDocumentViewPath,
      "get"
    );
    if (params) {
      rb.path("docPdfViewUid", params.docPdfViewUid, {});
      rb.path("recordUid", params.recordUid, {});
      rb.query("provisionValueUids", params.provisionValueUids, {});
      rb.query("pageNums", params.pageNums, {});
      rb.query("provisionUids", params.provisionUids, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespDocPdfViewModel>;
        })
      );
  }

  /**
   * Get full document view of a record using the specified document.
   *
   * If request specifies a provisionValueUids, just filter based on that. Else, if request filters either pageNums or provisionUids, filter using AND of those.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFullDocumentView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullDocumentView(params: {
    /**
     * The unique id of the document.
     */
    docPdfViewUid: string;

    /**
     * The unique id of the record whose document view will be fetched.
     */
    recordUid: string;

    /**
     * If provided, just filter the pdf view based on it.
     */
    provisionValueUids?: Array<string>;

    /**
     * If provisionValueUids is not provided, filter is applied based on pageNums.
     */
    pageNums?: Array<number>;

    /**
     * If provisionValueUids is not provided, filter is applied based on provisionUids.
     */
    provisionUids?: Array<string>;
  }): Observable<OApiRespDocPdfViewModel> {
    return this.getFullDocumentView$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespDocPdfViewModel>) =>
          r.body as OApiRespDocPdfViewModel
      )
    );
  }

  /**
   * Path part for operation getFullDocumentViewForDefaultDoc
   */
  static readonly GetFullDocumentViewForDefaultDocPath =
    "/api/v1/docPdfViews/default/record/{recordUid}";

  /**
   * Get full document view using the default document.
   *
   * Get full document view for the default document of a record. If request specifies a provisionValueUids, just filter based on that. Else, if request filters either pageNums or provisionUids, filter using AND of those
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFullDocumentViewForDefaultDoc()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullDocumentViewForDefaultDoc$Response(params: {
    /**
     * The unique id of the record whose document view will be fetched.
     */
    recordUid: string;

    /**
     * If provided, just filter the pdf view based on it.
     */
    provisionValueUids?: Array<string>;

    /**
     * If provisionValueUids is not provided, filter is applied based on pageNums.
     */
    pageNums?: Array<number>;

    /**
     * If provisionValueUids is not provided, filter is applied based on provisionUids.
     */
    provisionUids?: Array<string>;
  }): Observable<StrictHttpResponse<OApiRespDocPdfViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      PdfViewService.GetFullDocumentViewForDefaultDocPath,
      "get"
    );
    if (params) {
      rb.path("recordUid", params.recordUid, {});
      rb.query("provisionValueUids", params.provisionValueUids, {});
      rb.query("pageNums", params.pageNums, {});
      rb.query("provisionUids", params.provisionUids, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespDocPdfViewModel>;
        })
      );
  }

  /**
   * Get full document view using the default document.
   *
   * Get full document view for the default document of a record. If request specifies a provisionValueUids, just filter based on that. Else, if request filters either pageNums or provisionUids, filter using AND of those
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFullDocumentViewForDefaultDoc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullDocumentViewForDefaultDoc(params: {
    /**
     * The unique id of the record whose document view will be fetched.
     */
    recordUid: string;

    /**
     * If provided, just filter the pdf view based on it.
     */
    provisionValueUids?: Array<string>;

    /**
     * If provisionValueUids is not provided, filter is applied based on pageNums.
     */
    pageNums?: Array<number>;

    /**
     * If provisionValueUids is not provided, filter is applied based on provisionUids.
     */
    provisionUids?: Array<string>;
  }): Observable<OApiRespDocPdfViewModel> {
    return this.getFullDocumentViewForDefaultDoc$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespDocPdfViewModel>) =>
          r.body as OApiRespDocPdfViewModel
      )
    );
  }

  /**
   * Path part for operation getPagePdfView
   */
  static readonly GetPagePdfViewPath = "/api/v1/pagePdfViews/{pagePdfViewUid}";

  /**
   * Get a specific PagePdfView.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPagePdfView()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPagePdfView$Response(params: {
    view: "FULL" | "NONE";
  }): Observable<StrictHttpResponse<OApiRespFullPagePdfViewViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      PdfViewService.GetPagePdfViewPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullPagePdfViewViewModel>;
        })
      );
  }

  /**
   * Get a specific PagePdfView.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPagePdfView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPagePdfView(params: {
    view: "FULL" | "NONE";
  }): Observable<OApiRespFullPagePdfViewViewModel> {
    return this.getPagePdfView$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullPagePdfViewViewModel>) =>
          r.body as OApiRespFullPagePdfViewViewModel
      )
    );
  }

  /**
   * Path part for operation getPagePdfViewFromDoc
   */
  static readonly GetPagePdfViewFromDocPath =
    "/api/v1/documents/docPdfViews/{docUid}/abstract/{recordUid}/pagePdfViews/{page}";

  /**
   * Get the highlight views for a page of a document of an abstract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPagePdfViewFromDoc()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPagePdfViewFromDoc$Response(params: {
    view: "FULL" | "NONE";
  }): Observable<StrictHttpResponse<OApiRespFullPagePdfViewViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      PdfViewService.GetPagePdfViewFromDocPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespFullPagePdfViewViewModel>;
        })
      );
  }

  /**
   * Get the highlight views for a page of a document of an abstract.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPagePdfViewFromDoc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPagePdfViewFromDoc(params: {
    view: "FULL" | "NONE";
  }): Observable<OApiRespFullPagePdfViewViewModel> {
    return this.getPagePdfViewFromDoc$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespFullPagePdfViewViewModel>) =>
          r.body as OApiRespFullPagePdfViewViewModel
      )
    );
  }
}
