import { Component, Inject, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import {
  FullProvisionGroupModel,
  FullProvisionModel,
  MinimalProvisionModel,
} from "@@intelease/web/common/models";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProvisionFieldTypeEnum } from "@@intelease/app-models/provision";

@Component({
  selector: "il-web-abstraction-page-add-provision-modal",
  templateUrl: "./web-abstraction-page-add-provision-modal.component.html",
  styleUrls: ["./web-abstraction-page-add-provision.modal-component.scss"],
})
export class WebAbstractionPageAddProvisionModalComponent implements OnInit {
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;
  modalTitle = "Add a New Provision";

  // user selections
  newProvision: MinimalProvisionModel = undefined;
  chosenProvisionGroup: FullProvisionGroupModel = undefined;
  preProvisionLocation: FullProvisionModel = undefined;
  postProvisionLocation: FullProvisionModel = undefined;

  // choices for user
  possibleProvisions: MinimalProvisionModel[] = [];
  existingProvisionGroups: FullProvisionGroupModel[] = [];
  possiblePreProvisions: MinimalProvisionModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<WebAbstractionPageAddProvisionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private inputValidationService: InputValidationService
  ) {}

  ngOnInit() {
    const { possibleProvisions, existingProvisionGroups } =
      this.dialogData.payload;
    this.possibleProvisions = possibleProvisions;
    this.existingProvisionGroups = existingProvisionGroups;
  }

  onAddProvision() {
    if (
      !this.validateOnAddProvisionFields(
        this.newProvision,
        this.chosenProvisionGroup,
        this.preProvisionLocation
      )
    ) {
      return;
    }
    this.modalRes.data = {
      ...{
        newProvision: this.newProvision,
        chosenProvisionGroup: this.chosenProvisionGroup,
        preProvisionLocation: this.preProvisionLocation,
        postProvisionLocation: this.postProvisionLocation,
      },
    };
    this.dialogRef.close(this.modalRes);
  }

  getPossiblePreProvisionsOfCategory(): void {
    const startLocation = new MinimalProvisionModel();
    const toReturn = [];

    startLocation.provisionInfo.name = "Add to start of category";
    toReturn.push(startLocation);

    if (this.chosenProvisionGroup) {
      for (const provision of this.chosenProvisionGroup?.provisions) {
        if (provision.fieldType === ProvisionFieldTypeEnum.MANUAL) {
          provision.name += " (M)";
        }
        toReturn.push(provision);
      }

      this.preProvisionLocation = undefined;
      this.possiblePreProvisions = toReturn;
    } else {
      this.possiblePreProvisions = undefined;
    }
  }

  onDismissModal() {
    this.dialogRef.close();
  }

  private findIdxOfSelectedLocation(
    provisionLocation: MinimalProvisionModel,
    provisionsList: MinimalProvisionModel[]
  ): number {
    if (provisionLocation.provisionInfo.name === "Add to start of category") {
      return 0;
    }
    for (let idx = 0; idx < provisionsList.length; idx++) {
      const provision = provisionsList[idx];
      if (provision.uid === provisionLocation.uid) {
        return idx + 1;
      }
    }
    return -1;
  }

  private validateOnAddProvisionFields(
    newProvision: MinimalProvisionModel,
    chosenProvisionGroup: FullProvisionGroupModel,
    preProvisionLocation: FullProvisionModel
  ): boolean {
    if (
      !this.inputValidationService.usefulObjectTextFieldElseAlert(
        newProvision,
        "uid",
        "You must choose a new provision to add!"
      )
    ) {
      return false;
    }
    if (
      !this.inputValidationService.usefulObjectTextFieldElseAlert(
        chosenProvisionGroup,
        "uid",
        "You must choose an existing provision category to add to!"
      )
    ) {
      return false;
    }
    if (
      !(
        preProvisionLocation &&
        preProvisionLocation.provisionInfo &&
        preProvisionLocation.provisionInfo.name
      )
    ) {
      alert(
        "You must choose a provision location in the selected provision category!"
      );
      return false;
    }
    const idx = this.findIdxOfSelectedLocation(
      this.preProvisionLocation,
      this.chosenProvisionGroup.provisions
    );
    if (idx === -1) {
      alert("Invalid provision location selected in the provision category!");
      return false;
    }
    this.postProvisionLocation =
      chosenProvisionGroup.provisions.length <= idx
        ? null
        : chosenProvisionGroup.provisions[idx];
    return true;
  }
}
