/* tslint:disable */
/* eslint-disable */
import { HeadersModel } from "./headers-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("RequestModel")
export class RequestModel {
  @JsonProperty("headers", HeadersModel, true)
  headers?: HeadersModel = undefined;
}
