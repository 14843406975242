/* tslint:disable */
/* eslint-disable */
import { MentionedUserDtoModel } from "./mentioned-user-dto-model";
import { PdfHighlightDtoModel } from "./pdf-highlight-dto-model";
import { RichTextElementDtoModel } from "./rich-text-element-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateDocumentCommentDtoModel")
export class CreateDocumentCommentDtoModel {
  @JsonProperty("elements", [RichTextElementDtoModel], true)
  elements?: Array<RichTextElementDtoModel> = [];

  @JsonProperty("highlightType", Any, true)
  highlightType?: "TEXT" | "AREA" = undefined;

  @JsonProperty("mentions", [MentionedUserDtoModel], true)
  mentions?: Array<MentionedUserDtoModel> = [];

  @JsonProperty("page", Number, true)
  page?: number = undefined;

  @JsonProperty("pdfHighlights", [PdfHighlightDtoModel], true)
  pdfHighlights?: Array<PdfHighlightDtoModel> = [];

  @JsonProperty("text", String, true)
  text?: string = "";
}
