/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReturnParamsRequestProvisionGroupViewTypeModel")
export class OApiReturnParamsRequestProvisionGroupViewTypeModel {
  @JsonProperty("view", Any, true)
  view?:
    | "MINIMAL_FORM"
    | "GROUPS_FORM"
    | "FULL_FORM"
    | "MINIMAL_GROUP"
    | "FULL_GROUP"
    | "NONE" = undefined;
}
