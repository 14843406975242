/* tslint:disable */
/* eslint-disable */
import { PermissionViewModel } from "./permission-view-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullEntityPermissionViewModel")
export class FullEntityPermissionViewModel {
  @JsonProperty("domainUid", String, true)
  domainUid?: string = "";

  @JsonProperty("entityUid", String, true)
  entityUid?: string = "";

  @JsonProperty("permissionDomainType", String, true)
  permissionDomainType?: string = "";

  @JsonProperty("permissionEntityType", String, true)
  permissionEntityType?: string = "";

  @JsonProperty("permissions", [PermissionViewModel], true)
  permissions?: Array<PermissionViewModel> = [];

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
