/* tslint:disable */
/* eslint-disable */
import { AbstractProvisionGroupStringsModel } from "./abstract-provision-group-strings-model";
import { CustomTagViewModel } from "./custom-tag-view-model";
import { DocSetCategoryViewModel } from "./doc-set-category-view-model";
import { MinimalDocumentDtoModel } from "./minimal-document-dto-model";
import { QueueInfoViewModel } from "./queue-info-view-model";
import { RecordLockInfoDtoModel } from "./record-lock-info-dto-model";
import { RecordPathModel } from "./record-path-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MultiProvisionStringsExportAbstractViewModel")
export class MultiProvisionStringsExportAbstractViewModel {
  @JsonProperty("abstractCreationDate", DateTimeConvertService, true)
  abstractCreationDate?: Date | undefined = undefined;

  @JsonProperty("abstractFilename", String, true)
  abstractFilename?: string = "";

  @JsonProperty("abstractName", String, true)
  abstractName?: string = "";

  @JsonProperty("abstractorCode", String, true)
  abstractorCode?: string = "";

  @JsonProperty("customTags", [CustomTagViewModel], true)
  customTags?: Array<CustomTagViewModel> = [];

  @JsonProperty("docAbstractUids", [String], true)
  docAbstractUids?: Array<string> = [];

  @JsonProperty("docNames", [String], true)
  docNames?: Array<string> = [];

  @JsonProperty("docSetCategory", DocSetCategoryViewModel, true)
  docSetCategory?: DocSetCategoryViewModel = undefined;

  @JsonProperty("docUidToName", Any, true)
  docUidToName?: {
    [key: string]: string;
  } = {};

  @JsonProperty("documents", [MinimalDocumentDtoModel], true)
  documents?: Array<MinimalDocumentDtoModel> = [];

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("lockInfo", RecordLockInfoDtoModel, true)
  lockInfo?: RecordLockInfoDtoModel = undefined;

  @JsonProperty("provisionGroups", [AbstractProvisionGroupStringsModel], true)
  provisionGroups?: Array<AbstractProvisionGroupStringsModel> = [];

  @JsonProperty("queueInfoView", QueueInfoViewModel, true)
  queueInfoView?: QueueInfoViewModel = undefined;

  @JsonProperty("status", String, true)
  status?: string = "";

  @JsonProperty("suiteVersionAbstractId", RecordPathModel, true)
  suiteVersionAbstractId?: RecordPathModel = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";
}
