/* tslint:disable */
/* eslint-disable */
import { MultiPdfProvisionDtoModel } from "./multi-pdf-provision-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ReportResponseEntryDtoModel")
export class ReportResponseEntryDtoModel {
  @JsonProperty("abstractName", String, true)
  abstractName?: string = "";

  @JsonProperty("abstractUid", String, true)
  abstractUid?: string = "";

  @JsonProperty("generalValues", Any, true)
  generalValues?: {
    [key: string]: string;
  } = {};

  @JsonProperty("provisionsValues", [MultiPdfProvisionDtoModel], true)
  provisionsValues?: Array<MultiPdfProvisionDtoModel> = [];
}
