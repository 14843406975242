import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { generateUUID } from "@@intelease/web/utils";

const exceptionList: Array<any> = [];

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { url } = request;
    let isUrlException = false;
    exceptionList.forEach((exception) => {
      if (url.includes(exception)) {
        isUrlException = true;
      }
    });

    if (!isUrlException) {
      request = request.clone({
        headers: request.headers.set("X-Date-Now", new Date().toISOString()),
      });
      request = request.clone({
        headers: request.headers.set(
          "X-Timezone-Offset",
          new Date().getTimezoneOffset().toString()
        ),
      });
      request = request.clone({
        headers: request.headers.set("X-Request-Id", generateUUID()),
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        (
          error as HttpErrorResponse & {
            request: HttpRequest<unknown>;
          }
        ).request = request;
        return throwError(error);
      })
    );
  }
}
