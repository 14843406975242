import { BreadcrumbItemTypeEnum } from "@@intelease/web/intelease/components/toolbar/breadcrumb/enums";

export class BreadcrumbItemModel {
  title: string;

  key: string;

  link: string;

  type: BreadcrumbItemTypeEnum;

  children: BreadcrumbItemModel[];

  tooltip: string;

  onClicked: () => void;

  searchBoxData: any;

  titlePrefix: string;

  useScrollbar = false;

  isAbstractProcessingCompleted: boolean;

  constructor(data?: Partial<BreadcrumbItemModel>) {
    Object.assign(this, data);
  }
}
