<h4 mat-dialog-title>
  {{ title }}
</h4>

<mat-dialog-content>
  <div class="form-group">
    <ng-select
      [items]="options"
      [closeOnSelect]="true"
      [clearable]="false"
      bindLabel="label"
      bindValue="value"
      appendTo="body"
      [(ngModel)]="selectedValue"
      (change)="onSelectProvision($event)"
    >
    </ng-select>
  </div>

  <div class="form-group">
    <mat-form-field appearance="outline">
      <mat-label>Rename Mention?</mat-label>
      <input type="text" matInput [(ngModel)]="mentionName" />
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding-bottom: 25px">
  <button
    color="warn"
    class="btn-cons pull-left inline mr-2"
    mat-button
    mat-dialog-close
    (click)="onCancelClick()"
  >
    {{ cancelButtonText }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="btn-cons pull-left inline"
    class="mr-2"
    (click)="onConfirmClick()"
  >
    {{ confirmButtonText }}
  </button>
</mat-dialog-actions>
