import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("EntityFieldModel")
export class EntityFieldModel {
  static view = "full";

  @JsonProperty("uid", String, true)
  uid = "";

  @JsonProperty("fieldName", String)
  fieldName = "";

  @JsonProperty("uiName", String)
  uiName = "";

  @JsonProperty("uiName", String)
  name = "";

  @JsonProperty("fieldType", String)
  fieldType = "";

  @JsonProperty("possibleValues", [String], true)
  possibleValues: string[] = undefined;

  @JsonProperty("possibleUnits", [String], true)
  possibleUnits: string[] = undefined;

  @JsonProperty("possibleCurrencies", [String], true)
  possibleCurrencies: string[] = undefined;

  @JsonProperty("possibleStates", [String], true)
  possibleStates: string[] = undefined;

  @JsonProperty("possibleCountries", [String], true)
  possibleCountries: string[] = undefined;

  selected = false;

  init(fieldName: string, uiName: string, fieldType: string): EntityFieldModel {
    this.fieldName = fieldName;
    this.uiName = uiName;
    this.fieldType = fieldType;
    this.possibleValues = undefined;
    this.possibleCurrencies = undefined;
    this.possibleUnits = undefined;
    this.possibleStates = undefined;
    this.possibleCountries = undefined;
    return this;
  }
}
