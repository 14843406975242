<div class="abstract-export-modal">
  <div mat-dialog-content>
    <il-export
      [entityType]="entityType"
      [fileNode]="fileNode"
      [fileNodes]="fileNodes"
      [issueExportModel]="issueExportModel"
      [includeNotes]="exportSettings.includeNotes"
      [includeAnnotatedDocs]="exportSettings.includeAnnotatedDocs"
      [excludeEmptyProvisions]="exportSettings.excludeEmptyProvisions"
      [includeSectionHeaders]="exportSettings.includeSectionHeaders"
      [includeSourceAttribution]="exportSettings.includeSourceAttribution"
      [emptyProvisionText]="exportSettings.emptyProvisionText"
      (cancel)="onCancelExportAbstractClick()"
      (export)="onAbstractExport()"
    >
    </il-export>
  </div>
</div>
