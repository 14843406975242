import { Component, Inject, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { isEmpty } from "lodash";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { NgxTranslateService } from "@@intelease/web/common/services/ngx-translate.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "itls-permission-confirm-modal",
  templateUrl: "./permission-confirm-modal.component.html",
  styleUrls: ["./permission-confirm-modal.component.scss"],
})
export class PermissionConfirmModalComponent implements OnInit {
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.SHARE;
  payload: any;
  customMessage = false;
  title = "Confirm Permissions";
  message =
    "Are you sure you want to give the relevant permissions and share this record?";
  params: Object = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<PermissionConfirmModalComponent>,
    private ngxTranslateService: NgxTranslateService
  ) {}

  ngOnInit() {
    this.payload = this.dialogData.payload;
    this.mode = this.dialogData.mode;
    if (!isEmpty(this.payload) && this.payload.customMessage) {
      this.ngxTranslateService
        .getTranslateByKey(this.payload.message, this.payload.params)
        .subscribe((message: string) => {
          this.message = message;
          this.title = this.payload.title;
        });
    }
  }

  onPermissionConfirm() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.CLOSE,
    });
  }

  onDismissModal() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}
