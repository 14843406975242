import { Observable } from "rxjs";
import { CreateApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CreateProvisionService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly PROVISIONS_URL = "/provisions";

  constructor(private createApiService: CreateApiService) {}

  saveNewProvision<T extends object>(
    newProvData: any,
    view: string,
    classRef: new () => T
  ): Observable<T> {
    return this.createApiService.sendRequest(
      CreateProvisionService.API_VERSION_1,
      CreateProvisionService.PROVISIONS_URL,
      newProvData,
      view,
      classRef
    );
  }
}
