/* tslint:disable */
/* eslint-disable */
import { DemoDocumentSetsUploadDtoModel } from "./demo-document-sets-upload-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqDemoDocumentSetsUploadDtoModel")
export class OApiReqDemoDocumentSetsUploadDtoModel {
  @JsonProperty("data", DemoDocumentSetsUploadDtoModel, true)
  data?: DemoDocumentSetsUploadDtoModel = undefined;
}
