import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { fetch, pessimisticUpdate } from "@ngrx/router-store/data-persistence";

import {
  ABSTRACTREVIEW_FEATURE_KEY,
  AbstractReviewPartialState,
} from "./abstract-review.reducer";
import {
  AbstractReviewActionTypes,
  AbstractReviewLoaded,
  AbstractReviewLoadError,
  CreateManualMention,
  CreateManualMentionError,
  CreateManualMentionSuccess,
  CreateProvisionComment,
  CreateProvisionCommentError,
  CreateProvisionCommentSuccess,
  CreateProvisionNote,
  CreateProvisionNoteError,
  CreateProvisionNoteSuccess,
  DeSelectMention,
  DeSelectMentionError,
  DeSelectMentionSuccess,
  LoadAbstractReview,
  LoadProvisionComments,
  LoadProvisionFullDetail,
  LoadSelectedAbstractDetail,
  LoadSelectedAbstractTOC,
  PickSnippet,
  PickSnippetError,
  PickSnippetSuccess,
  ProvisionCommentsLoaded,
  ProvisionCommentsLoadError,
  ProvisionFullDetailLoaded,
  ProvisionFullDetailLoadError,
  ProvisionOptionSelected,
  ProvisionOptionSelectError,
  SelectedAbstractDetailLoaded,
  SelectedAbstractDetailLoadError,
  SelectedAbstractTOCLoaded,
  SelectedAbstractTOCLoadError,
  SelectMention,
  SelectMentionError,
  SelectMentionSuccess,
  SelectProvisionOption,
  UpdateProvisionNote,
  UpdateProvisionNoteFailed,
  UpdateProvisionNoteSucceeded,
  UpdateMention,
  UpdateMentionSucceeded,
  RemoveMention,
  RemoveMentionSucceeded,
  AddMentionSucceeded,
  AddMention,
  UpdateCustomTags,
  UpdateCustomTagsSucceeded,
  UpdateCustomTagsFailed,
  UpdateNoteWithProvisionUid,
  UpdateNoteWithProvisionUidSucceeded,
  UpdateSelectedProvisionReviewStatus,
  SelectedProvisionReviewStatusUpdated,
  SelectedProvisionReviewStatusError,
  UpdateProvisionMentionNote,
  UpdateProvisionMentionNoteSucceeded,
  UpdateProvisionMentionNoteError,
  FetchDefaultDocumentAnnotation,
  FetchDefaultDocumentAnnotationSucceeded,
  RenameProvisionMention,
  RenameProvisionMentionSucceeded,
  GetOptionRemindersData,
  GetOptionRemindersDataSuccessful,
  GetOptionRemindersDataFailed,
  AddNestedSubfields,
  AddNestedSubfieldsSucceeded,
  MakeAnchorProvisionSucceeded,
} from "./abstract-review.actions";
import { AbstractReviewService } from "@@intelease/app-services/abstract-review";
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { cloneDeep, isEmpty, keyBy } from "lodash";
import {
  AbstractReviewFacade,
  abstractReviewQuery,
} from "@@intelease/app-state/abstract-review";
import {
  ChangeCustomTagsRequestDtoModel,
  MultiProvisionValueService,
  OApiReqEditNoteV3DtoModel,
  OApiReqUpdateCustomTagsRequestDtoModel,
  OApiRespDashboardDtoModel,
  OptionRemindersCalculatorService,
  PartialValPdfProvisionViewModel,
  PdfViewService,
  ProvisionInconsistenciesApiDtoModel,
  RecordService,
} from "@@intelease/api-models/adex-api-model-src";
import { of } from "rxjs";
import { WebAbstractionPageApiService } from "@@intelease/web/abstract-review/src/lib/services/web-abstraction-api.service";
import { Store } from "@ngrx/store";
import { SelectedAbstractDetailLoadedPayloadInterface } from "@@intelease/app-models/abstract-review/src";

@Injectable()
export class AbstractReviewEffects {
  loadAbstractReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.LoadAbstractReview),
      fetch({
        run: () => {
          return new AbstractReviewLoaded([]);
        },

        onError: (action: LoadAbstractReview, error) => {
          //eslint-disable-line
          return new AbstractReviewLoadError(error);
        },
      })
    )
  );

  loadSelectedAbstractDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.LoadSelectedAbstractDetail),
      fetch({
        run: (a: LoadSelectedAbstractDetail) => {
          return this.recordService
            .getComplete({
              recordUid: a.payload.abstractUid,
            })
            .pipe(
              map(({ data }) => {
                const { formStructure, provisions, provisionInconsistencies } =
                  data;
                return {
                  abstractDetail: data,
                  formStructure: formStructure
                    ? {
                        ...formStructure,
                        categories: keyBy(formStructure.categories, "uid"),
                      }
                    : formStructure,
                  provisions: keyBy(provisions, "provisionUid"),
                  provisionInconsistencies,
                } as SelectedAbstractDetailLoadedPayloadInterface;
              }),
              map((res) => {
                return new SelectedAbstractDetailLoaded(res);
              })
            );
        },
        onError: () => {
          return new SelectedAbstractDetailLoadError(true);
        },
      })
    )
  );

  selectProvisionOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.SelectProvisionOption),
      fetch({
        run: (
          action: SelectProvisionOption, //eslint-disable-line
          state?: AbstractReviewPartialState
        ) => {
          const localState = {
            ...state[ABSTRACTREVIEW_FEATURE_KEY].abstractDetail,
          };
          localState.provisions[0].multiplePdfProvision.options[0].selected =
            true;
          return new ProvisionOptionSelected(localState);
        },
        onError: (
          action: SelectProvisionOption, //eslint-disable-line
          error: OApiRespDashboardDtoModel
        ) => {
          return new ProvisionOptionSelectError(error);
        },
      })
    )
  );

  loadSelectedAbstractTOC$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.LoadSelectedAbstractTOC),
      fetch({
        run: (action: LoadSelectedAbstractTOC) => {
          const { abstractUid } = action.payload;
          return this.abstractReviewService
            .loadTOCByAbstractUid(abstractUid)
            .pipe(map((res) => new SelectedAbstractTOCLoaded(res.data)));
        },
        onError: () => {
          return new SelectedAbstractTOCLoadError();
        },
      })
    )
  );

  updateSelectedProvisionReviewStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.UpdateSelectedProvisionReviewStatus),
      pessimisticUpdate({
        run: (action: UpdateSelectedProvisionReviewStatus) => {
          return new SelectedProvisionReviewStatusUpdated(action.payload);
        },
        onError: (
          action: UpdateSelectedProvisionReviewStatus, //eslint-disable-line
          error
        ) => {
          return new SelectedProvisionReviewStatusError(error);
        },
      })
    )
  );

  pickSnippet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.PickSnippet),
      pessimisticUpdate({
        run: (action: PickSnippet) => {
          const { abstractUid, provisionUid, snippetUid } = action.payload;
          return this.abstractReviewService
            .pickSnippetProvisions(abstractUid, provisionUid, snippetUid)
            .pipe(map((res) => new PickSnippetSuccess(res)));
        },
        onError: (action: PickSnippet, error) => {
          //eslint-disable-line
          return new PickSnippetError(error);
        },
      })
    )
  );

  selectMention$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.SelectMention),
      pessimisticUpdate({
        run: (action: SelectMention) => {
          return new SelectMentionSuccess(action.payload);
        },
        onError: (action: SelectMention, error) => {
          //eslint-disable-line
          return new SelectMentionError(error);
        },
      })
    )
  );

  deSelectMention$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.DeSelectMention),
      pessimisticUpdate({
        run: (action: DeSelectMention) => {
          return new DeSelectMentionSuccess(action.payload);
        },
        onError: (action: DeSelectMention, error) => {
          //eslint-disable-line
          return new DeSelectMentionError(error);
        },
      })
    )
  );

  createManualMention$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.CreateManualMention),
      pessimisticUpdate({
        run: (action: CreateManualMention) => {
          return new CreateManualMentionSuccess(action.payload);
        },
        onError: (action: CreateManualMention, error) => {
          //eslint-disable-line
          return new CreateManualMentionError(error);
        },
      })
    )
  );

  loadProvisionFullDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.LoadProvisionFullDetail),
      withLatestFrom(this.abstractReviewFacade.getProvisions$),
      // eslint-disable-next-line
      // @ts-ignore
      fetch({
        run: (action: LoadProvisionFullDetail, provisions) => {
          const { abstractUid, provisionUid, provisionInconsistencies } =
            action.payload;
          return this.abstractReviewService
            .getProvisionFullDetail(abstractUid, provisionUid)
            .pipe(
              map((res) => {
                const selectedProvision = cloneDeep(provisions[provisionUid]);
                selectedProvision.multiplePdfProvision = {
                  ...selectedProvision.multiplePdfProvision,
                  ...res,
                };
                return new ProvisionFullDetailLoaded({
                  provisionUid: provisionUid,
                  provisionDetail: selectedProvision,
                  provisionInconsistencies,
                });
              })
            );
        },

        onError: (action: LoadProvisionFullDetail, error) => {
          //eslint-disable-line
          return new ProvisionFullDetailLoadError(error);
        },
      })
    )
  );

  createProvisionComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.CreateProvisionComment),
      pessimisticUpdate({
        run: (action: CreateProvisionComment) => {
          return this.abstractReviewService
            .saveProvisionComment(action.payload)
            .pipe(
              map((res) => {
                return new CreateProvisionCommentSuccess(res);
              })
            );
        },
        onError: (action: CreateProvisionComment, error) => {
          //eslint-disable-line
          return new CreateProvisionCommentError(error);
        },
      })
    )
  );

  createProvisionNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.CreateProvisionNote),
      pessimisticUpdate({
        run: (action: CreateProvisionNote) => {
          return this.abstractReviewService
            .saveProvisionNote(action.payload)
            .pipe(
              map((res) => {
                return new CreateProvisionNoteSuccess(res);
              })
            );
        },
        onError: (action: CreateProvisionNote, error) => {
          //eslint-disable-line
          return new CreateProvisionNoteError(error);
        },
      })
    )
  );

  updateProvisionNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.UpdateProvisionNote),
      pessimisticUpdate({
        run: (action: UpdateProvisionNote) => {
          return this.abstractReviewService
            .updateProvisionNote(action.payload)
            .pipe(
              map((res) => {
                return new UpdateProvisionNoteSucceeded(res);
              })
            );
        },
        onError: (action: UpdateProvisionNote, error) => {
          //eslint-disable-line
          return new UpdateProvisionNoteFailed(error);
        },
      })
    )
  );

  loadProvisionComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.LoadProvisionComments),
      fetch({
        run: (action: LoadProvisionComments) => {
          const { abstractUid, provisionUid } = action.payload;
          return this.abstractReviewService
            .getProvisionCommentsByProvisionUid(abstractUid, provisionUid)
            .pipe(map((res) => new ProvisionCommentsLoaded(res.data.items)));
        },
        onError: (
          action: LoadProvisionComments, //eslint-disable-line
          error: OApiRespDashboardDtoModel
        ) => {
          return new ProvisionCommentsLoadError(error);
        },
      })
    )
  );

  UpdateMention$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.UpdateMention),
      fetch({
        run: (action: UpdateMention) => {
          return new UpdateMentionSucceeded(action.payload);
        },
      })
    )
  );

  RemoveMention$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.RemoveMention),
      fetch({
        run: (action: RemoveMention) => {
          return new RemoveMentionSucceeded(action.payload);
        },
      })
    )
  );

  AddMention$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.AddMention),
      fetch({
        run: (action: AddMention) => {
          return new AddMentionSucceeded(action.payload);
        },
      })
    )
  );

  updateCustomTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.UpdateCustomTags),
      pessimisticUpdate({
        run: (action: UpdateCustomTags) => {
          const data: ChangeCustomTagsRequestDtoModel = {
            customTags: action.payload.customTags,
          };
          const updateCustomTagsDTO: OApiReqUpdateCustomTagsRequestDtoModel = {
            data,
          };
          const params = {
            recordUid: action.payload.recordUid,
            body: updateCustomTagsDTO,
          };
          return this.recordService.editRecordCustomTags(params).pipe(
            map((res) => {
              return new UpdateCustomTagsSucceeded(res);
            })
          );
        },
        onError: () => {
          return new UpdateCustomTagsFailed();
        },
      })
    )
  );

  updateNoteWithProvisionUid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.UpdateNoteWithProvisionUid),
      fetch({
        run: (action: UpdateNoteWithProvisionUid) => {
          const { notes, provisionUid } = action.payload;
          return new UpdateNoteWithProvisionUidSucceeded({
            provisionUid,
            notes,
          });
        },
      })
    )
  );

  updateProvisionMentionNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateProvisionMentionNote),
      concatMap((action) => {
        const { abstractUid: recordUid, provisionUid } =
          action.payload.provision;
        const payload = {
          recordUid,
          provisionUid,
          body: {
            data: {
              mentionUid: this.isProvisionMentionAlreadyDefined(
                action.payload.provisionOption
              )
                ? action.payload.provisionOption.uid
                : undefined,
              notes: action.payload.notes !== "" ? action.payload.notes : null,
            },
            returnParams: {
              view: "none",
            },
          } as OApiReqEditNoteV3DtoModel,
        };

        return this.multiProvisionValueService
          .editProvisionNotesV3(payload)
          .pipe(
            map(
              () =>
                new UpdateProvisionMentionNoteSucceeded({
                  provisionUid,
                  provisionOption: action.payload.provisionOption,
                  notes: action.payload.notes,
                })
            ),
            catchError(() => of(UpdateProvisionMentionNoteError()))
          );
      })
    )
  );

  fetchDefaultDocumentAnnotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.FetchDefaultDocumentAnnotation),
      switchMap((action: FetchDefaultDocumentAnnotation) =>
        this.pdfViewService
          .getFullDocumentViewForDefaultDoc({
            recordUid: action.payload.recordUid,
          })
          .pipe(
            filter((res) => !isEmpty(res.data)),
            map(
              (response) =>
                new FetchDefaultDocumentAnnotationSucceeded(response.data)
            )
          )
      )
    )
  );

  renameProvisionMention$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.RenameProvisionMention),
      withLatestFrom(this.store.select(abstractReviewQuery.getRecordUid)),
      mergeMap(([action, recordUid]) => {
        const { mentionUid, newMentionName, provisionUid } = (
          action as RenameProvisionMention
        ).payload;
        return this.webAbstractionPageApiService
          .renameProvisionName({
            mentionUid,
            provisionUid,
            recordUid,
            body: {
              data: {
                uiProvisionName: newMentionName,
              },
            },
          })
          .pipe(
            map(() => {
              return new RenameProvisionMentionSucceeded({
                mentionUid,
                provisionUid,
                uiProvisionName: newMentionName,
              });
            })
          );
      })
    )
  );

  addNestedSubfields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.AddNestedSubfields),
      withLatestFrom(this.store.select(abstractReviewQuery.getRecordUid)),
      mergeMap(([action, recordUid]) => {
        const { provisionUid, additionalSubfields } = (
          action as AddNestedSubfields
        ).payload;
        return this.webAbstractionPageApiService
          .addNestedSubfields({
            provisionUid,
            recordUid,
            body: {
              data: {
                additionalSubfields,
              },
            },
          })
          .pipe(
            map((res) => {
              return new AddNestedSubfieldsSucceeded({
                provisionUid,
                updatedProvision: res,
              });
            })
          );
      })
    )
  );

  makeAnchorProvision$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.MakeAnchorProvision),
      withLatestFrom(this.store.select(abstractReviewQuery.getRecordUid)),
      mergeMap(([action, recordUid]) => {
        const { provisionUid } = (
          action as {
            payload: {
              provisionUid: string;
            };
          }
        ).payload;
        return this.recordService
          .updateAnchorProvision({
            recordUid: recordUid,
            body: {
              data: {
                anchorProvisionUid: provisionUid,
              },
            },
          })
          .pipe(
            map((res) => res.data),
            catchError((err) => err)
          )
          .pipe(
            map((res: ProvisionInconsistenciesApiDtoModel) => {
              return new MakeAnchorProvisionSucceeded({
                provisionUid,
                updatedProvisionInconsistencyDetails: res,
              });
            })
          );
      })
    )
  );

  getOptionRemindersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbstractReviewActionTypes.GetOptionRemindersData),
      switchMap((action: GetOptionRemindersData) => {
        const { recordUid, optionRemindersType } = action.payload;
        return this.optionReminderCalculatorService
          .retrieveOptionRemindersCalculatorData({
            recordUid,
            optionRemindersType,
          })
          .pipe(
            map(
              ({ data }) =>
                new GetOptionRemindersDataSuccessful(data, optionRemindersType)
            ),
            catchError(() => of(new GetOptionRemindersDataFailed()))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private abstractReviewService: AbstractReviewService,
    private abstractReviewFacade: AbstractReviewFacade,
    private recordService: RecordService,
    private multiProvisionValueService: MultiProvisionValueService,
    private readonly pdfViewService: PdfViewService,
    private webAbstractionPageApiService: WebAbstractionPageApiService,
    private readonly store: Store,
    private optionReminderCalculatorService: OptionRemindersCalculatorService
  ) {}

  private isProvisionMentionAlreadyDefined(
    provisionOption: PartialValPdfProvisionViewModel
  ): boolean {
    return provisionOption.source !== "TEMP_LOCAL";
  }
}
