/* tslint:disable */
/* eslint-disable */
import { CustomDataModel } from "./custom-data-model";
import { PdfBbxModel } from "./pdf-bbx-model";
import { PdfBbxAndTxtModel } from "./pdf-bbx-and-txt-model";
import { PdfTextModel } from "./pdf-text-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("PdfMentionTextHighlightDtoModel")
export class PdfMentionTextHighlightDtoModel {
  @JsonProperty("color", String, true)
  color?: string = "";

  @JsonProperty("created", DateTimeConvertService, true)
  created?: Date | undefined = undefined;

  @JsonProperty("guid", String, true)
  guid?: string = "";

  @JsonProperty("id", String, true)
  id?: string = "";

  @JsonProperty("itlsData", CustomDataModel, true)
  itlsData?: CustomDataModel = undefined;

  @JsonProperty("lastUpdated", DateTimeConvertService, true)
  lastUpdated?: Date | undefined = undefined;

  @JsonProperty("rects", Any, true)
  rects?: {
    [key: string]: PdfBbxModel;
  } = {};

  @JsonProperty("text", PdfTextModel, true)
  text?: PdfTextModel = undefined;

  @JsonProperty("textSelections", Any, true)
  textSelections?: {
    [key: string]: PdfBbxAndTxtModel;
  } = {};
}
