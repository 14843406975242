import {
  Any,
  JsonConvert,
  JsonConverter,
  JsonCustomConvert,
  OperationMode,
} from "json2typescript";
import { DateConvertService } from "./date-convert.service";
import { Utilities } from "../utils/utilities";
import { cloneDeep, isDate } from "lodash";

@JsonConverter
export class ProvisionFormatValuesConvertService
  implements JsonCustomConvert<any>
{
  jsonConvert: JsonConvert = new JsonConvert();

  constructor() {
    this.jsonConvert.operationMode = OperationMode.LOGGING;
  }

  public static deserializeFormatValues(value) {
    if (
      value["LOCAL_DATE"] &&
      Utilities.isLocalDate(value["LOCAL_DATE"].value)
    ) {
      value["LOCAL_DATE"].value = DateConvertService.deserializeDate(
        value["LOCAL_DATE"].value
      );
    }
    return value;
  }

  public static serializeFormatValues(value) {
    const _value = cloneDeep(value);
    if (
      _value["LOCAL_DATE"] &&
      _value["LOCAL_DATE"].value &&
      isDate(_value["LOCAL_DATE"].value)
    ) {
      _value["LOCAL_DATE"].value = DateConvertService.serializeDate(
        _value["LOCAL_DATE"].value
      );
    }
    return _value;
  }

  serialize(data): any {
    ProvisionFormatValuesConvertService.serializeFormatValues(data);
  }

  deserialize(data: any) {
    return ProvisionFormatValuesConvertService.deserializeFormatValues(data);
  }
}
