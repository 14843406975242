import { Pipe, PipeTransform } from "@angular/core";

import { SimpleOpenApiDateModel } from "@@intelease/api-models/adex-api-model-src";
import { DateUtil } from "@@intelease/web/utils";

@Pipe({
  name: "deserializeSimpleDate",
  pure: true,
})
export class DeserializeSimpleDatePipe implements PipeTransform {
  transform = (model: SimpleOpenApiDateModel) =>
    DateUtil.deserializeSimpleDate(model);
}
