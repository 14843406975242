import { createFeatureSelector, createSelector } from "@ngrx/store";
import { REPORTS_FEATURE_KEY, ReportsState } from "./reports.reducer";
import { values } from "lodash";

// Lookup the 'Reports' feature state managed by NgRx
const getReportsState =
  createFeatureSelector<ReportsState>(REPORTS_FEATURE_KEY);

const getReportListLoaded = createSelector(
  getReportsState,
  (state: ReportsState) => state.reportsListLoaded
);
const getReportListError = createSelector(
  getReportsState,
  (state: ReportsState) => state.reportsListLoadError
);

const getAllReports = createSelector(
  getReportsState,
  getReportListLoaded,
  (state: ReportsState, isLoaded) => {
    return isLoaded ? state.reportsList : undefined;
  }
);
const getSelectedId = createSelector(
  getReportsState,
  (state: ReportsState) => state.selectedId
);

const getSelectedReports = createSelector(
  getAllReports,
  getSelectedId,
  (reports, id) => {
    const result = reports.find((it) => it["id"] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

// report columns
const getReportColumnsLoaded = createSelector(
  getReportsState,
  (state: ReportsState) => state.reportColumnsLoaded
);

// report filter provisions
const getReportFilterFieldsLoaded = createSelector(
  getReportsState,
  (state: ReportsState) => state.reportFilterFieldsLoaded
);

const getFilterFieldsList = createSelector(
  getReportsState,
  getReportFilterFieldsLoaded,
  (state: ReportsState, isLoaded) =>
    isLoaded
      ? [
          ...values(state.reportFilterFields.generalFields).map((item) => ({
            ...item,
            category: "GENERAL",
          })),
          ...values(state.reportFilterFields.provisionFields).map((item) => ({
            ...item,
            category: "PROVISION",
          })),
        ]
      : []
);

const getFilterFields = createSelector(
  getReportsState,
  getReportFilterFieldsLoaded,
  (state: ReportsState, isLoaded) => (isLoaded ? state.reportFilterFields : {})
);

const getGroupedFilterFieldsList = createSelector(
  getReportsState,
  getReportFilterFieldsLoaded,
  (state: ReportsState, isLoaded) =>
    isLoaded
      ? [
          {
            title: "General",
            items: values(state.reportFilterFields.generalFields),
          },
          {
            title: "Provisions",
            items: values(state.reportFilterFields.provisionFields),
          },
        ]
      : []
);

const getProvisionsOperator = createSelector(
  getReportsState,
  getReportFilterFieldsLoaded,
  (state: ReportsState, isLoaded) => (isLoaded ? state.provisionsOperator : {})
);

const getFieldTypeToOperationAggregations = createSelector(
  getReportsState,
  getReportFilterFieldsLoaded,
  (state: ReportsState, isLoaded) =>
    isLoaded ? state.fieldTypeToOperationAggregations : {}
);

//LoadAbstractsReport
const getAbstractsReportLoaded = createSelector(
  getReportsState,
  (state: ReportsState) => state.abstractsReportLoaded
);

const getAbstractsReportLoadError = createSelector(
  getReportsState,
  (state: ReportsState) => state.abstractsReportLoadError
);

const getAbstractsReport = createSelector(
  getReportsState,
  getAbstractsReportLoaded,
  (state: ReportsState, abstractsReportLoaded) =>
    abstractsReportLoaded ? state.abstractsReport : undefined
);

const getAbstractsReportByInstanceId = createSelector(
  getReportsState,
  getAbstractsReportLoaded,
  (state: ReportsState, abstractsReportLoaded, props) =>
    abstractsReportLoaded ? state.abstractsReportsMap[props.uid] : undefined
);

const getAbstractsReportMap = createSelector(
  getReportsState,
  getAbstractsReportLoaded,
  (state: ReportsState, abstractsReportLoaded) =>
    abstractsReportLoaded ? state.abstractsReportsMap : undefined
);

const getSelectedReportColumns = createSelector(
  getReportsState,
  (state: ReportsState) => state.selectedColumns
);

const getSelectedReportFilters = createSelector(
  getReportsState,
  (state: ReportsState) => state.selectedFilters
);

// load selected report
const getSelectedReport = createSelector(
  getReportsState,
  (state: ReportsState) => state.selectedReportDetail
);

const getSelectedReportLoadError = createSelector(
  getReportsState,
  (state: ReportsState) => state.selectedReportLoadError
);

const getReportsQueryMap = createSelector(
  getReportsState,
  (state: ReportsState) => state.selectedReportDetailMap
);

const getReportSaved = createSelector(
  getReportsState,
  (state: ReportsState) => state.reportSaved
);

const getReportSaving = createSelector(
  getReportsState,
  (state: ReportsState) => state.isSavingReport
);

const getSelectedReportDeleteSucceeded = createSelector(
  getReportsState,
  (state: ReportsState) => state.selectedReportDeleteSucceeded
);

const getSelectedReportExportSucceeded = createSelector(
  getReportsState,
  (state: ReportsState) => state.selectedReportExportSucceeded
);

const isSelectedReportUpdatePending = createSelector(
  getReportsState,
  (state: ReportsState) => state.isSelectedReportUpdatePending
);

const getSelectedReportUpdateSucceeded = createSelector(
  getReportsState,
  isSelectedReportUpdatePending,
  (state: ReportsState, isPending) =>
    !isPending && state.selectedReportUpdateSucceeded
);

const isMultipleReportDeletePending = createSelector(
  getReportsState,
  (state: ReportsState) => state.isMultipleReportDeletePending
);

const multipleReportDeleteSucceeded = createSelector(
  getReportsState,
  isMultipleReportDeletePending,
  (state: ReportsState, isLoading) =>
    !isLoading && state.multipleReportDeleteSucceeded
);

export const reportsQuery = {
  getReportListLoaded,
  getReportListError,
  getAllReports,
  getSelectedReports,
  getReportColumnsLoaded,
  getReportFilterFieldsLoaded,
  getFilterFieldsList,
  getGroupedFilterFieldsList,
  getAbstractsReportLoaded,
  getAbstractsReportLoadError,
  getAbstractsReport,
  getAbstractsReportByInstanceId,
  getAbstractsReportMap,
  getProvisionsOperator,
  getFieldTypeToOperationAggregations,
  getFilterFields,
  getSelectedReportColumns,
  getSelectedReportFilters,
  getSelectedReport,
  getSelectedReportLoadError,
  getReportsQueryMap,
  getReportSaved,
  getReportSaving,
  getSelectedReportDeleteSucceeded,
  getSelectedReportExportSucceeded,

  isSelectedReportUpdatePending,
  getSelectedReportUpdateSucceeded,

  isMultipleReportDeletePending,
  multipleReportDeleteSucceeded,
};
