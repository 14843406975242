import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { ItlsNavigationItem } from "@@intelease/web/intelease/interfaces";
import { inteleaseAnimations } from "@@intelease/web/intelease/animations";
import { ItlsNavigationService } from "@@intelease/web/intelease/components/navigation/navigation.service";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive.service";
import { ItlsNavigationComponent } from "@@intelease/web/intelease/components/navigation/navigation.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { INode } from "@@intelease/web/common";

@Component({
  selector: "itls-nav-vertical-collapsable",
  templateUrl: "./collapsable.component.html",
  styleUrls: ["./collapsable.component.scss"],
  animations: inteleaseAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsNavVerticalCollapsableComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  @Input()
  item: ItlsNavigationItem;

  @HostBinding("class")
  classes = "nav-collapsable nav-item";

  @HostBinding("class.open")
  public isOpen = false;

  expandBasedOnRoute = false;

  driveTreeActive = false;

  expectedAuthorities: string[];
  expectedIconAuthorities: string[];

  constructor(
    private _itlsNavigationService: ItlsNavigationService,
    private _router: Router
  ) {
    //
  }

  ngOnInit(): void {
    this.expectedAuthorities =
      ItlsNavigationComponent.determineExpectedAuthorities(this.item.id);
    this.expectedIconAuthorities =
      ItlsNavigationComponent.determineIconExpectedAuthorities(this.item.id);
    if (this.expandBasedOnRoute) {
      this._router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((event: NavigationEnd) => {
          this.checkForDriveTreeActive(event.urlAfterRedirects);

          // Check if the url can be found in
          // one of the children of this item
          if (
            this.isUrlInChildren(this.item, event.urlAfterRedirects) ||
            this.isSpecialUrl(event.urlAfterRedirects)
          ) {
            this.expand();
          } else {
            this.collapse();
          }
        });

      // Listen for collapsing of any navigation item
      this._itlsNavigationService.onItemCollapsed
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((clickedItem) => {
          if (clickedItem && clickedItem.children) {
            // Check if the clicked item is one
            // of the children of this item
            if (this.isChildrenOf(this.item, clickedItem)) {
              return;
            }

            // Check if the url can be found in
            // one of the children of this item
            if (this.isUrlInChildren(this.item, this._router.url)) {
              return;
            }

            // If the clicked item is not this item, collapse...
            if (this.item !== clickedItem) {
              this.collapse();
            }
          }
        });

      // Check if the url can be found in
      // one of the children of this item
      if (
        this.isUrlInChildren(this.item, this._router.url) ||
        this.isSpecialUrl(this._router.url)
      ) {
        this.expand();
      } else {
        this.collapse();
      }
    } else {
      this.expand();
    }

    // special case for <il-drive-tree>
    this.checkForDriveTreeActive(this._router.url);
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((event: NavigationEnd) => {
        this.checkForDriveTreeActive(event.urlAfterRedirects);
      });
  }

  private checkForDriveTreeActive(url: string) {
    this.driveTreeActive = url === "/drive/owned";
  }

  toggleOpen(ev): void {
    ev.preventDefault();

    this.isOpen = !this.isOpen;

    // Navigation collapse toggled...
    this._itlsNavigationService.onItemCollapsed.next(this.item);
    this._itlsNavigationService.onItemCollapseToggled.next();
  }

  expand(): void {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    this._itlsNavigationService.onItemCollapseToggled.next();
  }

  collapse(): void {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;
    this._itlsNavigationService.onItemCollapseToggled.next();
  }

  isChildrenOf(parent, item): boolean {
    if (!parent.children) {
      return false;
    }

    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    for (const children of parent.children) {
      if (children.children) {
        return this.isChildrenOf(children, item);
      }
    }
  }

  isUrlInChildren(parent, url): boolean {
    if (!parent.children) {
      return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].children) {
        if (this.isUrlInChildren(parent.children[i], url)) {
          return true;
        }
      }

      if (
        parent.children[i].url === url ||
        url.includes(parent.children[i].url)
      ) {
        return true;
      }
    }

    return false;
  }

  private isSpecialUrl(url: string) {
    if (url === "/drive/owned") {
      return true;
    }
    const splitUrl = url?.split("/");
    return (
      splitUrl?.length === 4 &&
      splitUrl[1] === "drive" &&
      splitUrl[2] === "folders"
    );
  }

  onNodeClicked(node: INode) {
    if (node.id === ItlsDriveService.ROOT_NODE_ID) {
      this._router.navigate([`/drive/owned`]);
    } else {
      this._router.navigate([`/drive/folders/${node.id}`]);
    }
  }
}
