import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ProvisionFormsService } from "@@intelease/web/forms/src/lib/services";
import { PaginationModel } from "@@intelease/web/intelease/models";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { SortDirectionEnum } from "@@intelease/web/common/enums/sort-direction.enum";
import { finalize } from "rxjs/operators";
import { cloneDeep, findIndex } from "lodash";

export interface FilteredProvisionForm {
  uid: string;
  name: string;
  documentTypes: string[];
}

const DEFAULT_PROVISION_FORM_SORT =
  "lastModifiedDate::Last Modified Date::" +
  PROVISIONS_DATA_CONST.DATE.name +
  "::" +
  SortDirectionEnum.DESC;

export interface ProvisionsGroup {
  documentType: string;
  items: FilteredProvisionForm[];
}

let allProvisionForms = [];

@Component({
  selector: "il-choose-provision-form",
  templateUrl: "./choose-provision-form.component.html",
  styleUrls: ["./choose-provision-form.component.scss"],
})
export class ChooseProvisionFormComponent implements OnInit {
  searchInput = "";
  isLoading = false;
  @Input()
  allProvisionForms: FilteredProvisionForm[] = allProvisionForms;
  filteredProvisionsGroups: ProvisionsGroup[] = [];
  selectedProvisionForm: FilteredProvisionForm;
  @Output()
  selectedProvisionFormUidEvent: EventEmitter<FilteredProvisionForm> = new EventEmitter<FilteredProvisionForm>();
  @Input() height: "md" | "sm" = "md";
  @Input() autofocus = false;

  @ViewChild("searchProvisionForm") searchProvisionFormInput: ElementRef;

  constructor(private provisionFormsService: ProvisionFormsService) {}

  ngOnInit(): void {
    this.fetchInitialData();
  }

  private fetchInitialData() {
    if (this.allProvisionForms.length) {
      this.getFilteredProvisionForms();
      this.focusSearchBox();
      return;
    }

    this.isLoading = true;
    this.provisionFormsService
      .searchProvisionForms(
        new PaginationModel(1, 1000),
        DEFAULT_PROVISION_FORM_SORT
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res) => {
        this.allProvisionForms = res.items;
        allProvisionForms = res.items;
        this.getFilteredProvisionForms();
        this.focusSearchBox();
      });
  }

  private focusSearchBox(): void {
    if (this.autofocus) {
      setTimeout(() => {
        if (this.searchProvisionFormInput?.nativeElement) {
          this.searchProvisionFormInput.nativeElement.focus();
        }
      }, 100);
    }
  }

  private getFilteredProvisionForms() {
    if (this.searchInput?.length === 0) {
      this.filteredProvisionsGroups = this.createProvisionsGroups(
        this.allProvisionForms
      );
    } else {
      const lowerCasedSearchInput = this.searchInput.toLowerCase();
      const filteredProvisionForms = this.allProvisionForms.filter(
        (provisionForm) =>
          provisionForm.name.toLowerCase().includes(lowerCasedSearchInput)
      );
      this.filteredProvisionsGroups = this.createProvisionsGroups(
        filteredProvisionForms
      );
    }
  }

  private createProvisionsGroups(
    provisionForms: FilteredProvisionForm[]
  ): ProvisionsGroup[] {
    const provisionsGroupList = [];
    const documentTypeToProvisionsGroup = new Map<string, ProvisionsGroup>();
    for (const provisionForm of provisionForms) {
      for (const documentType of provisionForm.documentTypes) {
        let provisionsGroup = documentTypeToProvisionsGroup.get(documentType);
        if (!provisionsGroup) {
          provisionsGroup = {
            documentType: documentType,
            items: [],
          };
          documentTypeToProvisionsGroup.set(documentType, provisionsGroup);
          provisionsGroupList.push(provisionsGroup);
        }
        provisionsGroup.items.push(provisionForm);
      }
    }
    return provisionsGroupList;
  }

  onSearchInputChanged() {
    this.getFilteredProvisionForms();
  }

  onSelectProvisionForm(filteredForm: FilteredProvisionForm) {
    this.selectedProvisionForm = { ...filteredForm };
    this.selectedProvisionFormUidEvent.emit({ ...filteredForm });
  }

  reset() {
    this.searchInput = "";
    this.selectedProvisionForm = undefined;
    this.getFilteredProvisionForms();
  }
}
