import { EventEmitter, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import { UploadAdapter } from './UploadAdapter';
import { CKEditor5 } from './ckeditor';
import { isPlatformServer } from '@angular/common';

declare var CKEDITOR: any;
// import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
// import * as ClassicEditorBuild from '/assets/ckeditor-custom-build/ckeditor';

@Component({
  selector: 'il-ck-editor-cp-component',
  template: `
    <!-- <il-ckeditor -->
    <!-- [(ngModel)]="model" -->
    <!-- [editor]="Editor" -->
    <!-- [disabled]="isDisabled" -->
    <!-- [config]="ckConfig" -->
    <!-- id="classic-editor" -->
    <!-- name="classic-editor" -->
    <!-- (ready)="onReady($event)" -->
    <!-- (change)="onChange($event)" -->
    <!-- (focus)="onFocus($event)" -->
    <!-- (blur)="onBlur($event)"> -->
    <!-- </il-ckeditor> -->
    <textarea
      name="editor1"
      id="editor1"
      rows="10"
      cols="80"
      style="border: none;"
    >
    </textarea>
  `
})
export class CkeditorCpComponent implements OnInit {
  // @Input() model: any = '';
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  public isDisabled = false;
  // public Editor = ClassicEditorBuild;
  public editorData = ``;
  ckConfig: any = { plugins: ['sourcedialog'] };
  editor: any;
  isServer = false;

  _model: any;
  get model(): string {
    return this._model;
  }

  @Input('model')
  set model(value: string) {
    this._model = value;
    this.setEditorContent(value);
  }

  constructor(@Inject(PLATFORM_ID) platformId) {
    this.isServer = isPlatformServer(platformId);
  }

  setEditorContent(data: string) {
    if (this.editor) {
      // console.log(data);
      this.editor.setData(data);
    }
  }

  private setUpEditorEvents(editor: any): void {
    const modelDocument = editor;
    // const viewDocument = editor.editing.view.document;

    modelDocument.on('blur', (evt: any) => {
      const data = evt.editor.getData();
      this.modelChange.emit(data);
      evt.editor.setData(data);
    });

    // viewDocument.on('focus', (evt: CKEditor5.EventInfo<'focus'>) => {
    //   this.ngZone.run(() => {
    //     this.focus.emit({event: evt, editor});
    //   });
    // });
    //
    // viewDocument.on('blur', (evt: CKEditor5.EventInfo<'blur'>) => {
    //   this.ngZone.run(() => {
    //     if (this.cvaOnTouched) {
    //       this.cvaOnTouched();
    //     }
    //
    //     this.blur.emit({event: evt, editor});
    //   });
    // });
  }

  ngOnInit() {
    if (!this.isServer) {
      setTimeout(() => {
        this.editor = CKEDITOR.replace('editor1', {
          allowedContent: true
        });
        this.editor.setData(this.model);
        this.setUpEditorEvents(this.editor);
      }, 800);
    }
  }

  onEditor(evt) {
    // this.onAction.next({...{text: evt}, ...{actionKey: this.pageProp.data.actionKey}});
  }

  onReady(editor): void {
    // console.log('The editor is ready.');
  }

  onChange(event): void {
    this.modelChange.next(this.model);
  }

  onFocus(event): void {
    // console.log('Focused the editing view.');
  }

  onBlur(event): void {
    // console.log('Blurred the editing view.');
  }
}
