import { Any, JsonObject, JsonProperty } from "json2typescript";
import { EntityFieldModel } from "@@intelease/web/intelease/models";

@JsonObject("FilterModel")
export class FilterModel {
  @JsonProperty("namedSearchField", EntityFieldModel)
  namedSearchField: EntityFieldModel = new EntityFieldModel();

  @JsonProperty("relation", String)
  relation = "";

  @JsonProperty("value", Any)
  value = undefined;

  group?: string;

  public reset() {
    this.namedSearchField = new EntityFieldModel();
    this.relation = "";
    this.value = undefined;
    this.group = undefined;
  }
}
