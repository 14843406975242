import { Injectable } from "@angular/core";
import { isUndefined, remove } from "lodash";
import { FullValProvisionValueModel } from "@@intelease/web/common/models";
import { ProvisionBoxEventTypesEnum } from "@@intelease/web/abstraction-page/src/lib/enums";
import { WebAbstractionPageService } from "@@intelease/web/abstraction-page/src/lib/services/web-abstraction-page.service";
import { FullValMultiPdfProvViewModel } from "@@intelease/api-models/adex-api-model-src";

@Injectable({
  providedIn: "root",
})
export class ProvisionListBoxService {
  /**
   * list of provisions added to this box
   */
  public provisionList: FullValMultiPdfProvViewModel[] = [];
  public provisionBoxList: FullValMultiPdfProvViewModel[] = [];
  private currentProvisionIndex: number;

  /**
   * add selected provision to provision box list
   * @param selectedProvision selected provision
   */
  add(selectedProvision: FullValMultiPdfProvViewModel) {
    const isSelectedProvisionInProvisionListBox =
      this.isProvisionExistsInBox(selectedProvision);
    this.resetProvisionItemsZIndex();
    if (!isSelectedProvisionInProvisionListBox) {
      (selectedProvision as any) /*todo: fix types */.isTop = true;
      this.provisionBoxList.push(selectedProvision);
    }
    if (
      isSelectedProvisionInProvisionListBox &&
      !isUndefined(
        (isSelectedProvisionInProvisionListBox as any) /*todo: fix types */
          .isTop
      )
    ) {
      (
        isSelectedProvisionInProvisionListBox as any
      ) /*todo: fix types */.isTop = true;
    }
  }

  closeProvisionWindowByIndex(index: number): void {
    this.provisionBoxList.splice(index, 1);
  }

  closeProvisionWindowByProvisionUid(provisionUid: string): void {
    remove(
      this.provisionBoxList,
      (provisionBox) => provisionBox.provisionInfo.uid === provisionUid
    );
  }

  open(provision: FullValMultiPdfProvViewModel) {
    this.add(provision);
  }

  closeAll() {
    this.provisionBoxList = [];
  }

  /**
   * Returns the value of the first provision in the provisionListBox where uid of selectedProvision is equal to one of provisions in provisionListBox, and undefined otherwise.
   * @param selectedProvision
   */
  isProvisionExistsInBox(
    selectedProvision: FullValMultiPdfProvViewModel
  ): FullValMultiPdfProvViewModel | undefined {
    return this.provisionBoxList.find(
      (provision: FullValMultiPdfProvViewModel) =>
        provision.provisionInfo.uid === selectedProvision.provisionInfo.uid
    );
  }

  isProvisionOpen(
    provisionUid: string
  ): FullValMultiPdfProvViewModel | undefined {
    return this.provisionBoxList.find(
      (provision: FullValMultiPdfProvViewModel) =>
        provision.provisionInfo.uid === provisionUid
    );
  }

  /**
   * get list of provisions added to provision box
   */
  getProvisionList(): FullValMultiPdfProvViewModel[] {
    return this.provisionList;
  }

  setProvisionList(provisionList: FullValMultiPdfProvViewModel[]): void {
    this.provisionList = provisionList;
  }

  /**
   * get provision by Uid
   * @param provisionUid selected provision uid
   */
  getProvisionByUid(
    provisionUid: string
  ): FullValMultiPdfProvViewModel | undefined {
    return this.provisionList.find(
      (_provision: any) => _provision.provisionInfo.uid === provisionUid
    );
  }

  getProvisionIndexByProvisionUid(uid: string): number {
    this.currentProvisionIndex = this.provisionList.findIndex(
      (provision) => provision.provisionInfo.uid === uid
    );
    return this.currentProvisionIndex;
  }

  getNextProvision(): FullValMultiPdfProvViewModel | undefined {
    if (
      this.currentProvisionIndex === this.provisionList.length ||
      isUndefined(this.currentProvisionIndex)
    ) {
      return;
    }
    return this.provisionList[this.currentProvisionIndex + 1];
  }

  getPreviousProvision(): FullValMultiPdfProvViewModel | undefined {
    if (
      this.currentProvisionIndex === 0 ||
      isUndefined(this.currentProvisionIndex)
    ) {
      return;
    }
    return this.provisionList[this.currentProvisionIndex - 1];
  }

  resetProvisionItemsZIndex(): void {
    this.provisionBoxList.forEach((provisionBox) => {
      (provisionBox as any) /*todo: fix types */.isTop = false;
    });
  }

  public sendProvisionBoxEvent(
    eventType: ProvisionBoxEventTypesEnum,
    provisionUid: string,
    mentionUid: string,
    payload: any
  ) {
    switch (eventType) {
      case ProvisionBoxEventTypesEnum.ON_PDF_TEXT_HIGHLIGHT_CLICK: {
        WebAbstractionPageService.provisionBoxEventsSubject$.next({
          type: eventType,
          provisionUid: provisionUid,
          mentionUid: mentionUid,
          payload: payload, // TextHighlightModel
        });
        break;
      }
      case ProvisionBoxEventTypesEnum.ON_NEW_MENTION: {
        WebAbstractionPageService.provisionBoxEventsSubject$.next({
          type: eventType,
          provisionUid: provisionUid,
          mentionUid: mentionUid,
          payload: payload as FullValProvisionValueModel,
        });
        break;
      }
      case ProvisionBoxEventTypesEnum.ON_PDF_DELETE_TEXT_HIGHLIGHT: {
        WebAbstractionPageService.provisionBoxEventsSubject$.next({
          type: eventType,
          provisionUid: provisionUid,
          mentionUid: mentionUid,
          payload: payload, // { annotationId: string, annotation: any }
        });
        break;
      }
      default:
        // eslint-disable-next-line
        console.info(eventType + " Is Not Handled");
        break;
    }
  }
}
