import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItlsCommentComponent } from "./itls-comment.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Angular2PromiseButtonModule } from "angular2-promise-buttons";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ItlsCommentDetailComponent } from "@@intelease/web/ui/src/lib/itls-comment/detail/itls-comment-detail.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatOptionModule } from "@angular/material/core";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";
import { ItlsCommentInputModule } from "@@intelease/web/ui/src/lib/itls-comment-input";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";

const MATERIAL_MODULES = [
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  FormsModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatMenuModule,
];

@NgModule({
  declarations: [ItlsCommentComponent, ItlsCommentDetailComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ...MATERIAL_MODULES,
    Angular2PromiseButtonModule.forRoot(),
    MatOptionModule,
    CommonDirectivesModule,
    ItlsCommentInputModule,
    InteleaseCommonModule,
  ],
  exports: [ItlsCommentComponent],
})
export class ItlsCommentModule {}
