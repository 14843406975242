/* tslint:disable */
/* eslint-disable */
import { ObjectIdModel } from "./object-id-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("LinkedAccountModel")
export class LinkedAccountModel {
  @JsonProperty("&#x27;_id&#x27;", ObjectIdModel, true)
  "_id"?: ObjectIdModel = undefined;

  @JsonProperty("providerKey", String, true)
  providerKey?: string = "";

  @JsonProperty("providerUserId", String, true)
  providerUserId?: string = "";
}
