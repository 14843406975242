import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { ServerResponseModel } from "@@intelease/web/intelease/models";

@Injectable({
  providedIn: "root",
})
export class DeleteBatchApiService {
  private static readonly DELETE_BATCH_URL_PART = "/deleteBatch";

  constructor(private httpClient: HttpClient) {}

  /**
   * Send a POST request to delete a set of existing entities in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url (not including final uid)
   * @param objectUids the uids, representing the objects to be deleted
   * @param allVersions whether to delete all versions of the provided objectUids' objects
   */
  sendBatchRequestNoResponse(
    apiVersion: string,
    baseUrl: string,
    objectUids: string[],
    allVersions: boolean
  ): void {
    this.sendBatchRequestNoView(
      apiVersion,
      baseUrl,
      objectUids,
      allVersions
    ).subscribe((res) => {});
  }

  /**
   * Send a POST request to delete a set of existing entities in backend.
   *
   * @param apiVersion the version of the API
   * @param baseUrl the url
   * @param objectUids the uids, representing the objects to be deleted
   * @param allVersions whether to delete all versions of the provided objectUids' objects
   * @return response, not containing any objects
   */
  sendBatchRequestNoView(
    apiVersion: string,
    baseUrl: string,
    objectUids: string[],
    allVersions: boolean
  ): Observable<ServerResponseModel> {
    const body = this.createBatchDeleteRequestBody(objectUids, allVersions);
    return this.helpSendRequestNoView(body, apiVersion, baseUrl);
  }

  /**
   * Send a POST request to delete all existing entities in backend (for current user).
   *
   * @param apiVersion the versino of the API
   * @param baseUrl the url
   * @returns response, not containing any objects
   */
  sendAllRequestNoView(
    apiVersion: string,
    baseUrl: string
  ): Observable<ServerResponseModel> {
    const body = this.createAllDeleteRequestBody();
    return this.helpSendRequestNoView(body, apiVersion, baseUrl);
  }

  /*
   * Below are private helper methods
   */

  private helpSendRequestNoView(
    body: any,
    apiVersion: string,
    baseUrl: string
  ): Observable<ServerResponseModel> {
    return this.httpClient.post<ServerResponseModel>(
      apiVersion + DeleteBatchApiService.DELETE_BATCH_URL_PART + baseUrl,
      body
    );
  }

  private createAllDeleteRequestBody(): any {
    return {
      data: {
        selectAll: true,
      },
    };
  }

  private createBatchDeleteRequestBody(
    objectUids: string[],
    allVersions: boolean
  ): any {
    return {
      data: {
        objectUids: objectUids,
        selectAll: false,
        affectAllVersions: allVersions,
      },
    };
  }
}
