export enum RecordReviewTooltipTypeEnum {
  MENTION_PANEL_DONE = "MENTION_PANEL_DONE",
  MENTION_PANEL_LOCATION = "MENTION_PANEL_LOCATION",

  PROVISION_HEADER_LOCATION = "PROVISION_HEADER_LOCATION",
  PROVISION_HEADER_MENTION = "PROVISION_HEADER_MENTION",
  PROVISION_HEADER_NOTE = "PROVISION_HEADER_NOTE",

  SNIPPET_VALUE = "SNIPPET_VALUE",
  PROVISION_MENTION_FIELD = "PROVISION_MENTION_FIELD",
  PROVISION_MENTION_TITLE = "PROVISION_MENTION_TITLE",

  PROVISION_REMOVE_ICON = "PROVISION_REMOVE_ICON",
}
