/* tslint:disable */
/* eslint-disable */
import { KeywordInfoModel } from "./keyword-info-model";
import { ProvisionValueCriteriaModel } from "./provision-value-criteria-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("KeyphraseInfoModel")
export class KeyphraseInfoModel {
  @JsonProperty("creationDate", DateTimeConvertService, true)
  creationDate?: Date | undefined = undefined;

  @JsonProperty("example", String, true)
  example?: string = "";

  @JsonProperty("inKeywords", [KeywordInfoModel], true)
  inKeywords?: Array<KeywordInfoModel> = [];

  @JsonProperty("postKeywords", [KeywordInfoModel], true)
  postKeywords?: Array<KeywordInfoModel> = [];

  @JsonProperty("preKeywords", [KeywordInfoModel], true)
  preKeywords?: Array<KeywordInfoModel> = [];

  @JsonProperty("provisionValueCriteria", ProvisionValueCriteriaModel, true)
  provisionValueCriteria?: ProvisionValueCriteriaModel = undefined;

  @JsonProperty("weight", Number, true)
  weight?: number = undefined;
}
