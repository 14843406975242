import { Any, JsonObject, JsonProperty } from "json2typescript";
import { AccountRoleTypeEnum } from "../account";
import { RecordReviewModeEnum } from "../../../../app-models/common/src";
import { GoogleDriveInfoViewModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("LoggedInUserModel2")
export class LoggedInUserModel {
  static view = "loggedIn";

  @JsonProperty("accessToken", String)
  accessToken = "";

  @JsonProperty("status", String)
  status = "";

  @JsonProperty("profileImageUid", String)
  profileImageUid = "";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("uidHash", String)
  uidHash = "";

  @JsonProperty("firstName", String)
  firstName = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("email", String)
  email = "";

  @JsonProperty("flags", [String])
  flags: string[] = [];

  @JsonProperty("accountUid", String)
  accountUid = "";

  @JsonProperty("googleDriveInfoView", GoogleDriveInfoViewModel, true)
  googleDriveInfoView?: GoogleDriveInfoViewModel = undefined;

  @JsonProperty("recordReviewMode", String)
  recordReviewMode: RecordReviewModeEnum = RecordReviewModeEnum.V1;

  @JsonProperty("queueFeatureEnabled", Boolean)
  queueFeatureEnabled: boolean = undefined;

  @JsonProperty("lbxAccountUser", Boolean)
  lbxAccountUser: boolean = undefined;

  @JsonProperty("accountRole", String, true)
  accountRole: AccountRoleTypeEnum | undefined = undefined;
  @JsonProperty("lbxRoles", Any, true)
  lbxRoles?: Array<
    "ADMIN" | "FORMS_ADMIN" | "ANALYST" | "REVIEWER" | "DATA_SCIENTIST"
  > = [];

  @JsonProperty("accountRoleName", String, true)
  accountRoleName = "";

  @JsonProperty("accountRoleRank", Number, true)
  accountRoleRank = -1;

  @JsonProperty("docSetCategories", Any, true)
  docSetCategories: any[] = [];

  // TODO(apoorv): This should also include a small version of user's profile photo.

  @JsonProperty("settingItems", Any, true)
  settingItems: any[] = [];

  @JsonProperty("provisionMetadatas", Any, true)
  provisionMetadatas: any = {};
}
