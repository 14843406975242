import { NgModule } from "@angular/core";
import { WebAbstractionPageAddProvisionModalComponent } from "./web-abstraction-page-add-provision-modal.component";
import { InteleaseModule } from "@@intelease/web/intelease/intelease.module";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { ModalModule } from "ngx-bootstrap/modal";
import { MatIconModule } from "@angular/material/icon";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";
import {
  TooltipOnAddProvisionFieldsPipe,
  ValidateOnAddProvisionFieldsPipe,
} from "./validate-on-add-provision-fields.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";

const NGX_BOOTSTRAP = [ModalModule.forRoot()];

const MATERIAL_MODULES = [
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatTooltipModule,
];

const PIPES = [
  ValidateOnAddProvisionFieldsPipe,
  TooltipOnAddProvisionFieldsPipe,
];

@NgModule({
  imports: [
    CommonModule,
    InteleaseModule,
    InteleaseCommonModule,
    ItlsCustomIconModule,
    FormsModule,
    ...NGX_BOOTSTRAP,
    ...MATERIAL_MODULES,
  ],
  declarations: [WebAbstractionPageAddProvisionModalComponent, ...PIPES],
  exports: [WebAbstractionPageAddProvisionModalComponent],
})
export class WebAbstractionPageAddProvisionModalModule {}
