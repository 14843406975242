/* tslint:disable */
/* eslint-disable */

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("MediumReportQueryViewModel")
export class MediumReportQueryViewModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("description", String, true)
  description?: string = "";

  @JsonProperty("lastRunAt", DateTimeConvertService, true)
  lastRunAt?: Date | undefined = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("numTimesRun", Number, true)
  numTimesRun?: number = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("updatedAt", DateTimeConvertService, true)
  updatedAt?: Date | undefined = undefined;
}
