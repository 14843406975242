import { Any, JsonObject, JsonProperty } from "json2typescript";
import { PartialValProvisionValueModel } from "./partial-val-provision-value.model";
import { SnippetModel } from "./snippet.model";
import { ProvisionValueConvertService } from "../../services/provision-value-convert.service";

@JsonObject("PartialProvisionValueModel")
export class PartialProvisionValueModel extends PartialValProvisionValueModel {
  static view = "partial";

  @JsonProperty("uid", String, true)
  uid = "";

  @JsonProperty("value", ProvisionValueConvertService)
  value: any = "";

  @JsonProperty("textValue", Any, true)
  textValue: any = "";

  @JsonProperty("notes", String)
  notes = "";

  @JsonProperty("sectionHeader", String)
  sectionHeader = "";

  @JsonProperty("page", Number)
  page = -1;

  @JsonProperty("source", String)
  source = "";

  @JsonProperty("docAbstractUid", String)
  docAbstractUid = "";

  @JsonProperty("pdfHighlightIds", [String])
  pdfHighlightIds: string[] = [];

  @JsonProperty("selected", Boolean)
  selected = false;

  @JsonProperty("surroundingText", String)
  surroundingText = "";

  @JsonProperty("relatedOptionUids", [String], true)
  relatedOptionUids?: string[] = [];

  @JsonProperty("snippets", [SnippetModel], true)
  snippets?: SnippetModel[] = [];

  docName = "";
}
