/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("NotificationCategoryDtoModel")
export class NotificationCategoryDtoModel {
  @JsonProperty("key", String, true)
  key?: string = "";

  @JsonProperty("title", String, true)
  title?: string = "";

  @JsonProperty("unseenCount", Number, true)
  unseenCount?: number = undefined;
}
