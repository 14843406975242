/* tslint:disable */
/* eslint-disable */
import { DefaultDataFieldUnitsModel } from "./default-data-field-units-model";
import { DoubleProvisionUnitOrCurrencyChangeDtoModel } from "./double-provision-unit-or-currency-change-dto-model";
import { JsonNodeModel } from "./json-node-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("EditMentionValueApiDtoModel")
export class EditMentionValueApiDtoModel {
  @JsonProperty("defaultDataFieldUnits", DefaultDataFieldUnitsModel, true)
  defaultDataFieldUnits?: DefaultDataFieldUnitsModel = undefined;

  @JsonProperty(
    "doubleProvisionUnitOrCurrencyChanged",
    DoubleProvisionUnitOrCurrencyChangeDtoModel,
    true
  )
  doubleProvisionUnitOrCurrencyChanged?: DoubleProvisionUnitOrCurrencyChangeDtoModel =
    undefined;

  @JsonProperty("formatValues", Any, true)
  formatValues?: {
    [key: string]: JsonNodeModel;
  } = {};

  @JsonProperty("notes", String, true)
  notes?: string = "";

  @JsonProperty("provisionValue", Any, true)
  provisionValue?: any = undefined;

  @JsonProperty("sectionHeader", String, true)
  sectionHeader?: string = "";

  /**
   * the format of a structured value
   */

  @JsonProperty("type", Any, true)
  type?:
    | "NESTED_GROUP"
    | "LOCAL_DATE"
    | "COMMON_NOUN"
    | "PROPER_NOUN"
    | "MONEY"
    | "ADDRESS"
    | "DOUBLE"
    | "SINGLE_CAT"
    | "MULTIPLE_CAT"
    | "TABLE"
    | "YEAR"
    | "EMAIL"
    | "PHONE"
    | "TIME_PLAN" = undefined;
}
