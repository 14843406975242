import { Action } from "@ngrx/store";

export enum MentionEditTrackerActionTypes {
  LastTouchedMentionUid = "[MentionEditTracker] Last touched mention Uid",

  LastTouchedNestedSubfieldKey = "[MentionEditTracker] Last touched nested mention subfield",
  LastEditedNestedSubfieldKey = "[MentionEditTracker] Last edited nested subfield",

  LocateNestedSubfield = "[MentionEditTracker] Locate the Nested Mention Subfield",

  AnchorMentionIndexChanged = "[MentionEditTracker] Anchor Mention Index Changed",
}

export class LastTouchedMentionUid implements Action {
  readonly type = MentionEditTrackerActionTypes.LastTouchedMentionUid;
  constructor(public payload: { mentionUid?: string }) {}
}

export class LastTouchedNestedSubfieldKey implements Action {
  readonly type = MentionEditTrackerActionTypes.LastTouchedNestedSubfieldKey;
  constructor(public payload: { subfieldKey?: string }) {}
}

export class LastEditedNestedSubfieldKey implements Action {
  readonly type = MentionEditTrackerActionTypes.LastEditedNestedSubfieldKey;
  constructor(public payload: { subfieldKey?: string }) {}
}

export class LocateNestedSubfield implements Action {
  readonly type = MentionEditTrackerActionTypes.LocateNestedSubfield;

  constructor(
    public payload: {
      nestedMentionUid?: string;
      nestedSubfieldKey?: string;
    }
  ) {}
}

export class AnchorMentionIndexChanged implements Action {
  readonly type = MentionEditTrackerActionTypes.AnchorMentionIndexChanged;

  constructor(public payload: { index?: number }) {}
}

export type MentionEditTrackerAction =
  | LastTouchedMentionUid
  | LastTouchedNestedSubfieldKey
  | LastEditedNestedSubfieldKey
  | LocateNestedSubfield
  | AnchorMentionIndexChanged;

export const mentionEditTrackerActions = {
  LastTouchedMentionUid,
  LastTouchedNestedSubfieldKey,
  LastEditedNestedSubfieldKey,
  LocateNestedSubfield,
  AnchorMentionIndexChanged,
};
