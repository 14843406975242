/* tslint:disable */
/* eslint-disable */
import { AbstractProvisionModel } from "./abstract-provision-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("AbstractProvisionGroupModel")
export class AbstractProvisionGroupModel {
  @JsonProperty("htmlName", String, true)
  htmlName?: string = "";

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("provisions", [AbstractProvisionModel], true)
  provisions?: Array<AbstractProvisionModel> = [];

  @JsonProperty("version", Number, true)
  version?: number = undefined;
}
