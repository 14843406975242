/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("DocSetCategoryViewModel")
export class DocSetCategoryViewModel {
  /**
   * the user-facing name of this doc set category
   */

  @JsonProperty("uiName", String, true)
  uiName?: string = "";

  /**
   * the unique human-readable id of this doc set category
   */

  @JsonProperty("value", String, true)
  value?: string = "";
}
