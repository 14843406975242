import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WebTasksRoutingModule } from "./web-tasks-routing.module";
import { PermissionsGuard } from "@@intelease/web/intelease/guards";

@NgModule({
  imports: [CommonModule, WebTasksRoutingModule],
  declarations: [],
  providers: [PermissionsGuard],
})
export class WebTasksModule {}
