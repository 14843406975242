import {
  DoubleProvisionUnitOrCurrencyChangeDtoModel,
  FullValMultiPdfProvViewModel,
  PartialValPdfProvisionViewModel,
} from "@@intelease/api-models/adex-api-model-src";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { FormModel } from "../models/provision-review.model";
import {
  deleteProvisionOption,
  expandNotePanel,
  expandSnippedPanel,
  goToProvisionOptionHighlightOnViewer,
  saveProvisionOption,
  openTableProvision,
  selectProvisionOption,
  scrollToProvisionOption,
  pickSnippet,
  duplicateProvisionOption,
  savingProvisionOption,
  savingProvisionOptionFinished,
} from "./provision-review.actions";
import {
  onScrollToProvisionOption,
  selectNotePanelExpanded,
  selectSnippedPanelExpanded,
} from "./provision-review.selectors";

@Injectable({
  providedIn: "root",
})
export class ProvisionReviewUIFacade {
  public snippedPanelExpanded$: Observable<
    PartialValPdfProvisionViewModel["uid"]
  > = this.store.select(selectSnippedPanelExpanded);

  public scrollProvisionOptionUid$: Observable<
    PartialValPdfProvisionViewModel["uid"]
  > = this.store
    .select(onScrollToProvisionOption)
    .pipe(map((data) => data.provisionOption.uid));

  public notePanelExpanded$: Observable<
    PartialValPdfProvisionViewModel["uid"]
  > = this.store.select(selectNotePanelExpanded);

  constructor(private readonly store: Store) {}

  public expandSnippedPanel(uid: PartialValPdfProvisionViewModel["uid"]): void {
    this.store.dispatch(expandSnippedPanel({ uid }));
  }

  public expandNotePanel(uid: PartialValPdfProvisionViewModel["uid"]): void {
    this.store.dispatch(expandNotePanel({ uid }));
  }

  public goToProvisionOptionHighlightOnViewer(
    provisionSnippet: PartialValPdfProvisionViewModel
  ): void {
    this.store.dispatch(
      goToProvisionOptionHighlightOnViewer({ payload: provisionSnippet })
    );
  }

  /**
   *
   * @param doubleProvisionUnitOrCurrencyChanged for Double Provision and Money Provision only, if Double unit is changed or currency is changed it will be available, for other kind of provision it will be undefined
   */
  public saveProvisionMention(
    provision: FullValMultiPdfProvViewModel,
    provisionOption: PartialValPdfProvisionViewModel,
    model: FormModel,
    doubleProvisionUnitOrCurrencyChanged?: DoubleProvisionUnitOrCurrencyChangeDtoModel
  ): void {
    this.store.dispatch(
      saveProvisionOption({
        payload: {
          provision,
          provisionOption,
          model,
          doubleProvisionUnitOrCurrencyChanged,
        },
      })
    );
  }

  public deleteProvisionMention(
    provision: FullValMultiPdfProvViewModel,
    provisionOption: PartialValPdfProvisionViewModel
  ): void {
    this.store.dispatch(
      deleteProvisionOption({ payload: { provision, provisionOption } })
    );
  }

  public shouldFlashProvision(
    provisionOption: PartialValPdfProvisionViewModel["uid"]
  ): Observable<boolean> {
    return this.scrollProvisionOptionUid$.pipe(
      map((uuid) => uuid === provisionOption),
      filter((val) => !!val)
    );
  }

  public openTableProvision(
    provision: FullValMultiPdfProvViewModel,
    provisionOption: PartialValPdfProvisionViewModel
  ): void {
    this.store.dispatch(
      openTableProvision({
        payload: { provision, provisionOption },
      })
    );
  }

  public markProvisionOptionAsSelected(
    provision: FullValMultiPdfProvViewModel,
    provisionOption: PartialValPdfProvisionViewModel
  ): void {
    this.store.dispatch(
      selectProvisionOption({
        payload: { provision, provisionOption },
      })
    );
  }

  public savingProvisionOptionFinished(): void {
    this.store.dispatch(savingProvisionOptionFinished());
  }

  public savingProvisionOption(
    provisionUid: FullValMultiPdfProvViewModel["uid"],
    provisionOptionUid: PartialValPdfProvisionViewModel["uid"]
  ): void {
    this.store.dispatch(
      savingProvisionOption({
        payload: { provisionUid, provisionOptionUid },
      })
    );
  }

  public scrollToProvisionOption(
    provision: FullValMultiPdfProvViewModel,
    provisionOption: PartialValPdfProvisionViewModel
  ): void {
    if (!provisionOption) {
      return;
    }

    this.markProvisionOptionAsSelected(provision, provisionOption);

    this.store.dispatch(
      scrollToProvisionOption({
        payload: { provision, provisionOption },
      })
    );
  }

  public pickSnippet(
    provision: FullValMultiPdfProvViewModel,
    snippet: PartialValPdfProvisionViewModel,
    provisionOption: PartialValPdfProvisionViewModel
  ): void {
    this.store.dispatch(
      pickSnippet({
        payload: {
          provision,
          snippet,
          provisionOption,
        },
      })
    );
  }

  public duplicateProvisionOption(
    provision: FullValMultiPdfProvViewModel,
    provisionOption: PartialValPdfProvisionViewModel
  ): void {
    this.store.dispatch(
      duplicateProvisionOption({
        payload: {
          provision,
          provisionOption,
        },
      })
    );
  }
}
