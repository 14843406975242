/* tslint:disable */
/* eslint-disable */
import { CustomTagViewModel } from "./custom-tag-view-model";

/**
 * the response data
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("ChangeCustomTagsResponseDtoModel")
export class ChangeCustomTagsResponseDtoModel {
  @JsonProperty("accountCustomTags", [CustomTagViewModel], true)
  accountCustomTags?: Array<CustomTagViewModel> = [];

  @JsonProperty("addedToAccount", Boolean, true)
  addedToAccount?: boolean = undefined;

  @JsonProperty("customTags", [CustomTagViewModel], true)
  customTags?: Array<CustomTagViewModel> = [];
}
