import { Injectable } from "@angular/core";
import { AbstractionPageRequestPayloadService } from "./abstraction-page-request-payload.service";
import { HttpClient } from "@angular/common/http";
import { from, Observable } from "rxjs";
import {
  RentScheduleCalculatorResponseModel,
  TermCalculatorResponseModel,
} from "@@intelease/web/abstraction-page/src/lib/models";
import { RentScheduleCalculationResponseModel } from "@@intelease/web/abstraction-page/src/lib/models/rent-schedule-calculation-response.model";
import { flatMap, map } from "rxjs/operators";
import { PriceIndexResponseModel } from "@@intelease/web/abstraction-page/src/lib/models/price-index-response.model";

@Injectable({
  providedIn: "root",
})
export class CalculatorService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly CALCULATOR_URL = "/calculator";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";

  constructor(
    private abstractionPageRequestPayloadService: AbstractionPageRequestPayloadService,
    private httpClient: HttpClient
  ) {}

  getTermCalculatorData(payload): Observable<TermCalculatorResponseModel> {
    return this.httpClient.post<TermCalculatorResponseModel>(
      `${CalculatorService.API_VERSION_1}${CalculatorService.CALCULATOR_URL}/term`,
      this.abstractionPageRequestPayloadService.TermCalculatorRequest(payload)
    );
  }

  getRentScheduleCalculatorData(
    payload
  ): Observable<RentScheduleCalculatorResponseModel> {
    return this.httpClient.post<RentScheduleCalculatorResponseModel>(
      `${CalculatorService.API_VERSION_1}${CalculatorService.CALCULATOR_URL}/rent-schedule`,
      this.abstractionPageRequestPayloadService.RentScheduleCalculatorRequest(
        payload
      )
    );
  }

  calculateRentSchedule(
    payload
  ): Observable<RentScheduleCalculationResponseModel> {
    return this.httpClient
      .post<any>(
        `${CalculatorService.API_VERSION_1}${CalculatorService.CALCULATOR_URL}/calc-rent-schedule`,
        this.abstractionPageRequestPayloadService.RentScheduleCalculationRequest(
          payload
        )
      )
      .pipe(flatMap((res) => from(res.data.items)))
      .pipe(
        map((item: any) => {
          const rentScheduleCalculationResponseModel =
            new RentScheduleCalculationResponseModel();
          rentScheduleCalculationResponseModel.endDate = item.endDate;
          rentScheduleCalculationResponseModel.startDate = item.startDate;
          rentScheduleCalculationResponseModel.values = item.values;
          return rentScheduleCalculationResponseModel;
        })
      );
  }

  getPriceIndex(): Observable<PriceIndexResponseModel[]> {
    return this.httpClient
      .get<any>(
        `${CalculatorService.API_VERSION_1}${CalculatorService.CALCULATOR_URL}/price-index`
      )
      .pipe(
        map((res) => {
          return res.data.items.map((item: any) => {
            const priceIndexResponseModel = new PriceIndexResponseModel();
            priceIndexResponseModel.uid = item.uid;
            priceIndexResponseModel.description = item.description;
            priceIndexResponseModel.name = item.name;
            return priceIndexResponseModel;
          });
        })
      );
  }

  importCalculatedProvisions(abstractUid: string, payload) {
    return this.httpClient.post(
      `${CalculatorService.API_VERSION_1}${CalculatorService.FINAL_DOC_SETS_URL}/${abstractUid}/calculatedProvisions`,
      payload
    );
  }
}
