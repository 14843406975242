/* tslint:disable */
/* eslint-disable */
import { MentionedUserDtoModel } from "./mentioned-user-dto-model";
import { RichTextElementDtoModel } from "./rich-text-element-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("CreateCommentDtoModel")
export class CreateCommentDtoModel {
  @JsonProperty("elements", [RichTextElementDtoModel], true)
  elements?: Array<RichTextElementDtoModel> = [];

  @JsonProperty("mentions", [MentionedUserDtoModel], true)
  mentions?: Array<MentionedUserDtoModel> = [];

  @JsonProperty("text", String, true)
  text?: string = "";
}
