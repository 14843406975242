import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("DateModel")
export class DateModel {
  @JsonProperty("year", Number, true)
  year: number = undefined;

  @JsonProperty("month", Number, true)
  month: number = undefined;

  @JsonProperty("day", Number, true)
  day: number = undefined;
}
