/* tslint:disable */
/* eslint-disable */
import { ShareMultiEntitiesRequestModel } from "./share-multi-entities-request-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqShareMultiEntitiesRequestModel")
export class OApiReqShareMultiEntitiesRequestModel {
  @JsonProperty("data", ShareMultiEntitiesRequestModel, true)
  data?: ShareMultiEntitiesRequestModel = undefined;
}
