/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiReqMergeDocSetsDtoModel } from "../models/o-api-req-merge-doc-sets-dto-model";
import { OApiReqUploadDocSetFileDtoModel } from "../models/o-api-req-upload-doc-set-file-dto-model";
import { OApiReqUploadDocSetGroupDataDtoModel } from "../models/o-api-req-upload-doc-set-group-data-dto-model";
import { OApiRespDocSetChromeExtensionViewModel } from "../models/o-api-resp-doc-set-chrome-extension-view-model";
import { OApiRespDocSetFullViewModel } from "../models/o-api-resp-doc-set-full-view-model";
import { OApiRespMergeDocSetsDtoModel } from "../models/o-api-resp-merge-doc-sets-dto-model";
import { OApiRespMultiDocSetDataDtoModel } from "../models/o-api-resp-multi-doc-set-data-dto-model";
import { OApiRespUploadDocSetFileDtoModel } from "../models/o-api-resp-upload-doc-set-file-dto-model";

@Injectable({
  providedIn: "root",
})
export class DocumentSetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation uploadData
   */
  static readonly UploadDataPath = "/api/v1/doc-sets/data";

  /**
   * Upload document set meta data.
   *
   * If it succeeds, it returns status 200 and a view of the successful document set data. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadData$Response(params: {
    /**
     * The data is collected from document set, to store meta data information about the document set.
     */
    body: OApiReqUploadDocSetGroupDataDtoModel;
  }): Observable<StrictHttpResponse<OApiRespMultiDocSetDataDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentSetService.UploadDataPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiDocSetDataDtoModel>;
        })
      );
  }

  /**
   * Upload document set meta data.
   *
   * If it succeeds, it returns status 200 and a view of the successful document set data. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadData(params: {
    /**
     * The data is collected from document set, to store meta data information about the document set.
     */
    body: OApiReqUploadDocSetGroupDataDtoModel;
  }): Observable<OApiRespMultiDocSetDataDtoModel> {
    return this.uploadData$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiDocSetDataDtoModel>) =>
          r.body as OApiRespMultiDocSetDataDtoModel
      )
    );
  }

  /**
   * Path part for operation uploadFiles
   */
  static readonly UploadFilesPath = "/api/v1/doc-sets/files";

  /**
   * Upload document set files.
   *
   * Returns 200 status upon the document set being successfully uploaded.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFiles()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFiles$Response(params: {
    /**
     * The files to upload and parameters
     */
    body: OApiReqUploadDocSetFileDtoModel;
  }): Observable<StrictHttpResponse<OApiRespUploadDocSetFileDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentSetService.UploadFilesPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "multipart/form-data");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespUploadDocSetFileDtoModel>;
        })
      );
  }

  /**
   * Upload document set files.
   *
   * Returns 200 status upon the document set being successfully uploaded.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadFiles$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFiles(params: {
    /**
     * The files to upload and parameters
     */
    body: OApiReqUploadDocSetFileDtoModel;
  }): Observable<OApiRespUploadDocSetFileDtoModel> {
    return this.uploadFiles$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespUploadDocSetFileDtoModel>) =>
          r.body as OApiRespUploadDocSetFileDtoModel
      )
    );
  }

  /**
   * Path part for operation get
   */
  static readonly GetPath = "/api/v1/doc-sets/{docSetUid}";

  /**
   * Get the DocumentSet with document list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: {
    docSetUid: string;
  }): Observable<StrictHttpResponse<OApiRespDocSetFullViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentSetService.GetPath,
      "get"
    );
    if (params) {
      rb.path("docSetUid", params.docSetUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespDocSetFullViewModel>;
        })
      );
  }

  /**
   * Get the DocumentSet with document list.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: { docSetUid: string }): Observable<OApiRespDocSetFullViewModel> {
    return this.get$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespDocSetFullViewModel>) =>
          r.body as OApiRespDocSetFullViewModel
      )
    );
  }

  /**
   * Path part for operation deleteByUid1
   */
  static readonly DeleteByUid1Path = "/api/v1/doc-sets/{docSetUid}";

  /**
   * Delete a document set.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteByUid1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByUid1$Response(params?: {}): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentSetService.DeleteByUid1Path,
      "delete"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "*/*",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Delete a document set.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteByUid1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByUid1(params?: {}): Observable<void> {
    return this.deleteByUid1$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDocSetInfoForChromeExtension
   */
  static readonly GetDocSetInfoForChromeExtensionPath =
    "/api/v1/doc-sets/chrome-extension/{docSetUid}";

  /**
   * Get the doc set info for google drive extension.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocSetInfoForChromeExtension()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocSetInfoForChromeExtension$Response(params: {
    docSetUid: string;
  }): Observable<StrictHttpResponse<OApiRespDocSetChromeExtensionViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentSetService.GetDocSetInfoForChromeExtensionPath,
      "get"
    );
    if (params) {
      rb.path("docSetUid", params.docSetUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespDocSetChromeExtensionViewModel>;
        })
      );
  }

  /**
   * Get the doc set info for google drive extension.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocSetInfoForChromeExtension$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocSetInfoForChromeExtension(params: {
    docSetUid: string;
  }): Observable<OApiRespDocSetChromeExtensionViewModel> {
    return this.getDocSetInfoForChromeExtension$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespDocSetChromeExtensionViewModel>) =>
          r.body as OApiRespDocSetChromeExtensionViewModel
      )
    );
  }

  /**
   * Path part for operation getDocument
   */
  static readonly GetDocumentPath =
    "/api/v1/doc-sets/{docSetUid}/documents/{docUid}";

  /**
   * Get the original PDF file associated with the given document set and given file name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument$Response(params: {
    docSetUid: string;
    docUid: string;
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentSetService.GetDocumentPath,
      "get"
    );
    if (params) {
      rb.path("docSetUid", params.docSetUid, {});
      rb.path("docUid", params.docUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/pdf",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Get the original PDF file associated with the given document set and given file name.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument(params: { docSetUid: string; docUid: string }): Observable<any> {
    return this.getDocument$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getGoogleDriveDocSet
   */
  static readonly GetGoogleDriveDocSetPath =
    "/api/v1/google-drive-doc-sets/{googleDriveFileId}";

  /**
   * Get a document set.
   *
   * Get a view of a document set, based on a Google Drive file id. Do not send zip format requests to this endpoint.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGoogleDriveDocSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoogleDriveDocSet$Response(params: {
    /**
     * The unique id of the Google Drive file whose doc set to fetch.
     */
    googleDriveFileId: string;

    /**
     * The view of the doc set to fetch.
     */
    view: "FULL" | "MINIMAL_EXPORT" | "CHROME_EXTENSION";
  }): Observable<
    StrictHttpResponse<
      OApiRespDocSetFullViewModel | OApiRespDocSetChromeExtensionViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentSetService.GetGoogleDriveDocSetPath,
      "get"
    );
    if (params) {
      rb.path("googleDriveFileId", params.googleDriveFileId, {});
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            OApiRespDocSetFullViewModel | OApiRespDocSetChromeExtensionViewModel
          >;
        })
      );
  }

  /**
   * Get a document set.
   *
   * Get a view of a document set, based on a Google Drive file id. Do not send zip format requests to this endpoint.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGoogleDriveDocSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoogleDriveDocSet(params: {
    /**
     * The unique id of the Google Drive file whose doc set to fetch.
     */
    googleDriveFileId: string;

    /**
     * The view of the doc set to fetch.
     */
    view: "FULL" | "MINIMAL_EXPORT" | "CHROME_EXTENSION";
  }): Observable<
    OApiRespDocSetFullViewModel | OApiRespDocSetChromeExtensionViewModel
  > {
    return this.getGoogleDriveDocSet$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            OApiRespDocSetFullViewModel | OApiRespDocSetChromeExtensionViewModel
          >
        ) =>
          r.body as
            | OApiRespDocSetFullViewModel
            | OApiRespDocSetChromeExtensionViewModel
      )
    );
  }

  /**
   * Path part for operation mergeDocSets
   */
  static readonly MergeDocSetsPath = "/api/v1/doc-sets/merge";

  /**
   * Merge multiple DocSets into one by taking user provided DocDatas only and remove the original DocSets.
   *
   * If it succeeds, it returns status 200 and a view of the successful document set view. If it fails, it returns status 400.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeDocSets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mergeDocSets$Response(params: {
    /**
     * Given DocDatas/Files information from existing multiple DocSets
     */
    body: OApiReqMergeDocSetsDtoModel;
  }): Observable<StrictHttpResponse<OApiRespMergeDocSetsDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentSetService.MergeDocSetsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMergeDocSetsDtoModel>;
        })
      );
  }

  /**
   * Merge multiple DocSets into one by taking user provided DocDatas only and remove the original DocSets.
   *
   * If it succeeds, it returns status 200 and a view of the successful document set view. If it fails, it returns status 400.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mergeDocSets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mergeDocSets(params: {
    /**
     * Given DocDatas/Files information from existing multiple DocSets
     */
    body: OApiReqMergeDocSetsDtoModel;
  }): Observable<OApiRespMergeDocSetsDtoModel> {
    return this.mergeDocSets$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMergeDocSetsDtoModel>) =>
          r.body as OApiRespMergeDocSetsDtoModel
      )
    );
  }
}
