import { JsonObject, JsonProperty } from "json2typescript";
import { FullProvisionGroupModel } from "@@intelease/web/common/models/provision-group";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NestedGroupProvisionSubfieldModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("FullProvisionFormModel")
export class FullProvisionFormModel {
  static view = "fullForm";

  @JsonProperty("uid", String)
  uid = "";

  editStatus = undefined;

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("htmlName", String)
  htmlName = "";

  @JsonProperty("version", Number)
  version = 0;

  @JsonProperty("description", String)
  description = "";

  @JsonProperty("creationDate", DateTimeConvertService)
  creationDate: Date = undefined;

  @JsonProperty("createdByEmail", String)
  createdByEmail = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("provisionGroups", [FullProvisionGroupModel])
  provisionGroups: FullProvisionGroupModel[] = [];

  @JsonProperty("numProvisions", Number)
  numProvisions = 0;

  @JsonProperty("source", String)
  source = "";

  scope = "";

  flags = [];

  @JsonProperty("docSetCategory", String)
  docSetCategory = "";

  @JsonProperty("permissions", [String])
  permissions?: Array<
    "READ" | "EDIT" | "DELETE" | "EXPORT" | "MOVE" | "COMMENT" | "SHARE"
  > = [];

  @JsonProperty("anchorProvisionUid", String)
  anchorProvisionUid?: string = undefined;
}
