import { ViewContainerRef, ComponentRef, Injectable } from "@angular/core";
import { WidgetRegistry } from "./widgetregistry";

@Injectable()
export class WidgetFactory {
  private registry: WidgetRegistry;

  constructor(registry: WidgetRegistry) {
    this.registry = registry;
  }

  createWidget(container: ViewContainerRef, type: string): ComponentRef<any> {
    const componentClass = this.registry.getWidgetType(type);
    return container.createComponent(componentClass);
  }
}
