import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  inject,
  OnInit,
} from "@angular/core";
import { ProjectsFacade } from "@@intelease/app-state/projects";
import { KanbanFacade } from "@@intelease/app-state/kanban";
import { ComponentStateHooksInterface } from "@@intelease/app-models/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ModalInputModel } from "@@intelease/web/intelease/models";

@Component({
  selector: "intelease-add-project",
  templateUrl: "./add-project.component.html",
  styleUrls: ["./add-project.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProjectComponent
  implements OnInit, ComponentStateHooksInterface
{
  destroyRef = inject(DestroyRef);
  projectName: string;
  description: string;
  kanbanUid: string;
  kanbanVersion: string;
  requiredFieldEmpty = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ModalInputModel,
    public dialogRef: MatDialogRef<AddProjectComponent>,
    private readonly projectsFacade: ProjectsFacade,
    private readonly kanbanFacade: KanbanFacade
  ) {}

  ngOnInit() {
    this.initActions();
    this.initListeners();
  }

  initActions(): void {
    this.projectsFacade.cleanProjectState({
      saveProjectDetail: undefined,
    });
    this.kanbanFacade.cleanKanbanState({
      savedKanbanDetail: undefined,
    });
  }

  initListeners(): void {
    this.kanbanFacade.savedKanbanDetail$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          const { uid, version } = res;
          this.kanbanUid = uid;
          this.kanbanVersion = version;
          const payload = {
            name: this.projectName,
            description: this.description,
            widgets: [{ uid: this.kanbanUid, type: "KANBAN", uiData: {} }],
          };
          this.projectsFacade.saveProject(payload);
        }
      });

    this.projectsFacade.getSaveProjectDetail$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          const { uid } = res;
          const payload = {
            uid: this.kanbanUid,
            version: this.kanbanVersion,
            issueLists: [],
          };
          this.kanbanFacade.updateSelectedKanban(uid, this.kanbanUid, payload);
          this.dialogRef.close(uid);
        }
      });
  }

  onSaveProjectClick() {
    this.kanbanFacade.saveKanban({});
  }
}
