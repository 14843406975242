import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { FullProvisionFormModel } from "@@intelease/web/common/models/provision-form";

@JsonObject("ProvisionFormAbstractModel")
export class ProvisionFormAbstractModel {
  static view = "provisionForm";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("provisionForm", FullProvisionFormModel)
  provisionForm: FullProvisionFormModel = undefined;

  @JsonProperty("abstractFilename", String)
  name = "";

  @JsonProperty("abstractName", String)
  abstractName = "";

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;
}
