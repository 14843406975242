export const ITLS_BREADCRUMS = {
  NEW_DASHBOARD: {
    title: "Dashboards",
    link: "/dashboards",
  },
  DRIVE_SEARCH: {
    title: "Search Results",
  },
  DRIVE_OWNED: {
    title: "My Drive",
    link: "/drive/owned",
  },
  DRIVE_SHARED: {
    title: "Shared with Me",
    link: "/drive/shared",
  },
  DRIVE_ALL: {
    title: "All Records",
    link: "/drive/all",
  },
  DRIVE_DOCUMENTS: {
    title: "All Documents",
    link: "/drive/documents",
  },
  DRIVE_DELETED: {
    title: "Trash",
    link: "/drive/deleted",
  },
  DRIVE_FAVORITE: {
    title: "Favorites",
    link: "/drive/favorite",
  },
  GOOGLE_DRIVE: {
    title: "Google Drive",
    link: "/drive/google-drive",
  },
  T_DOCUMENT_COMPARISION: {
    title: "Document comparison",
    link: "/my-documents/documents",
  },
  RECORDS: {
    title: "All Records",
    link: "/my-documents/documents",
  },
  MY_RECORDS: {
    title: "My Records",
    link: "/my-documents/my-records",
  },
  SHARED_WITH_ME_RECORDS: {
    title: "Shared with Me",
    link: "/my-documents/shared-with-me",
  },
  TRASH_RECORDS: {
    title: "Trash",
    link: "/my-documents/trash",
  },
  RECORD_DETAIL: {
    title: "Detail",
    link: "/individual-abstract/${abstractUid}/related-documents",
    params: [
      {
        key: "abstractUid",
        value: "",
      },
    ],
  },
  RECORD_REVIEW: {
    title: "Detail",
    link: "/abstract-review/${abstractUid}/pdf",
    params: [
      {
        key: "abstractUid",
        value: "",
      },
    ],
  },
  FORMS: {
    title: "Forms",
    link: "/provision-forms/forms",
  },
  FORM_DETAIL: {
    title: "Detail",
    link: "/individual-form/${formUid}",
    params: [
      {
        key: "formUid",
        value: "",
      },
    ],
  },
  TASKS: {
    title: "Tasks",
    link: "/tasks/list",
  },
  USER_PROFILE: {
    title: "Profile",
    link: "/user-profile/${userUid}/info",
    params: [
      {
        key: "userUid",
        value: "",
      },
    ],
  },
  SETTINGS: {
    title: "Settings",
    link: "/settings",
  },

  QUEUES: {
    title: "Queues",
    link: "/queues/list",
  },
  QUEUE_DETAIL: {
    title: "Queue",
    link: "/queues/${queueUid}",
    params: [
      {
        key: "queueUid",
        value: "",
      },
    ],
  },
  QUEUE_DETAIL_TO_REVIEW: {
    title: "Ready To Review",
    link: "/queues/${queueUid}/to-review",
    params: [
      {
        key: "queueUid",
        value: "",
      },
    ],
  },
  QUEUE_DETAIL_PROCESSING: {
    title: "Processing",
    link: "/queues/${queueUid}/processing",
    params: [
      {
        key: "queueUid",
        value: "",
      },
    ],
  },
  QUEUE_DETAIL_IN_REVIEW: {
    title: "In Review",
    link: "/queues/${queueUid}/in-review",
    params: [
      {
        key: "queueUid",
        value: "",
      },
    ],
  },
  QUEUE_DETAIL_IN_QA_REVIEW: {
    title: "In QA Review",
    link: "/queues/${queueUid}/in-qa-review",
    params: [
      {
        key: "queueUid",
        value: "",
      },
    ],
  },
  QUEUE_DETAIL_COMPLETED: {
    title: "Completed",
    link: "/queues/${queueUid}/completed",
    params: [
      {
        key: "queueUid",
        value: "",
      },
    ],
  },
  QUEUE_DETAIL_FAILED: {
    title: "Failed",
    link: "/queues/${queueUid}/failed",
    params: [
      {
        key: "queueUid",
        value: "",
      },
    ],
  },

  QUEUE_SETTINGS: {
    title: "Settings",
    link: "/queues/${queueUid}/settings",
    params: [
      {
        key: "queueUid",
        value: "",
      },
    ],
  },

  PROJECTS: {
    title: "Projects",
    link: "/projects/list",
  },
  PROJECT_DETAIL: {
    title: "Detail",
    link: "/projects/${projectUid}/kanban",
    params: [
      {
        key: "projectUid",
        value: "",
      },
    ],
  },
  REPORTS: {
    title: "Reports",
    link: "/reports/list",
  },
  REPORT_DETAIL: {
    title: "Detail",
    link: "reports/${reportId}/edit",
    params: [
      {
        key: "reportId",
        value: "",
      },
    ],
  },
  REPORTS_ADD: {
    title: "Add",
    link: "/reports/add",
  },
  REPORTS_VISUALIZE: {
    title: "Detail",
    link: "/reports/${reportUid}/visualize",
    params: [
      {
        key: "reportUid",
        value: "",
      },
    ],
  },
  CALENDARS: {
    title: "Calendars",
    link: "/calendars/list",
  },
  CALENDAR_DETAIL: {
    title: "Detail",
    link: "calendars/${calendarUid}/detail",
    params: [
      {
        key: "calendarUid",
        value: "",
      },
    ],
  },
  CALENDARS_ADD: {
    title: "Add",
    link: "/calendars/add",
  },
  ADExSETTINGS: {
    title: "Settings",
    link: "/settings",
  },
  PROFILE: {
    title: "Profile",
    link: "/settings/profile",
  },
  ADExzACCOUNT: {
    title: "Account",
    link: "/settings/account",
  },
  NOTIFICATIONS: {
    title: "Notifications",
    link: "/settings/notifications",
  },
  LANGUAGE: {
    title: "Language & region",
    link: "/settings/language",
  },
  MEMBERS: {
    title: "Members",
    link: "/settings/members",
  },
  PERMISSIONS: {
    title: "Permissions",
    link: "/settings/permissions",
  },
  TEAMS: {
    title: "Teams",
    link: "/settings/teams",
  },
  EXTENSION: {
    title: "Extension",
    link: "/settings/extension",
  },
};
