/* tslint:disable */
/* eslint-disable */
import { GetOffsetInSearchResultRequestModel } from "./get-offset-in-search-result-request-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiReqGetOffsetInSearchResultRequestModel")
export class OApiReqGetOffsetInSearchResultRequestModel {
  @JsonProperty("data", GetOffsetInSearchResultRequestModel, true)
  data?: GetOffsetInSearchResultRequestModel = undefined;
}
