import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../apps/ui/src/environments/environment";

const exceptionList: Array<any> = [
  "/assets/",
  "assets/i18n/",
  "http",
  "assets/forms",
  "assets/images",
];

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { url } = request;
    let isUrlException = false;
    exceptionList.forEach((exception) => {
      if (url.includes(exception)) {
        isUrlException = true;
      }
    });
    if (!isUrlException) {
      url.startsWith(environment.apiUrlPart)
        ? (request = request.clone({
            url: environment.appUrl + url,
          }))
        : (request = request.clone({
            url: environment.appUrl + environment.apiUrlPart + url,
          }));
    }
    return next.handle(request);
  }
}
