import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ToolbarComponent } from "./toolbar.component";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatBadgeModule } from "@angular/material/badge";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FlexLayoutModule } from "@angular/flex-layout";
// import {ItlsBreadcrumbModule} from '@intelease/components/itls-breadcrumb';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppStateNotificationsModule } from "@@intelease/app-state/notifications";
import { BreadcrumbModule } from "./breadcrumb";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";

const MATERIAL_MODULES = [
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatToolbarModule,
  MatTooltipModule,
  MatInputModule,
  MatListModule,
  MatBadgeModule,
  MatFormFieldModule,
  MatInputModule,
  MatAutocompleteModule,
];

const UI_MODULES = [];

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    RouterModule,
    CommonModule,
    ItlsCustomIconModule,
    FormsModule,
    ReactiveFormsModule,
    ...MATERIAL_MODULES,
    ...UI_MODULES,
    // ItlsBreadcrumbModule,
    FlexLayoutModule,
    AppStateNotificationsModule,
    BreadcrumbModule,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
