/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiDeleteRequestModel } from "../models/o-api-delete-request-model";
import { OApiReqCloneReportDtoModel } from "../models/o-api-req-clone-report-dto-model";
import { OApiReqCreateReportDtoModel } from "../models/o-api-req-create-report-dto-model";
import { OApiRespFullReportQueryViewModel } from "../models/o-api-resp-full-report-query-view-model";
import { OApiRespMediumReportQueryViewModel } from "../models/o-api-resp-medium-report-query-view-model";
import { OApiRespMinimalReportQueryViewModel } from "../models/o-api-resp-minimal-report-query-view-model";
import { OApiRespMultiFullReportQueryViewModel } from "../models/o-api-resp-multi-full-report-query-view-model";
import { OApiRespMultiMediumReportQueryViewModel } from "../models/o-api-resp-multi-medium-report-query-view-model";
import { OApiRespMultiMinimalReportQueryViewModel } from "../models/o-api-resp-multi-minimal-report-query-view-model";
import { OApiRespMultiReportResponseEntryDtoModel } from "../models/o-api-resp-multi-report-response-entry-dto-model";
import { OApiRespReportFieldNamesResponseDtoModel } from "../models/o-api-resp-report-field-names-response-dto-model";
import { OApiRespReportResultDtoModel } from "../models/o-api-resp-report-result-dto-model";
import { OApiSingleEntityNoDataResponseModel } from "../models/o-api-single-entity-no-data-response-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class ReportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation cloneReport
   */
  static readonly CloneReportPath = "/api/v1/report/clone";

  /**
   * Clone a report query.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cloneReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloneReport$Response(params: {
    body: OApiReqCloneReportDtoModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMediumReportQueryViewModel
      | OApiRespMinimalReportQueryViewModel
      | OApiRespFullReportQueryViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.CloneReportPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
          >;
        })
      );
  }

  /**
   * Clone a report query.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cloneReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloneReport(params: {
    body: OApiReqCloneReportDtoModel;
  }): Observable<
    | OApiRespMediumReportQueryViewModel
    | OApiRespMinimalReportQueryViewModel
    | OApiRespFullReportQueryViewModel
  > {
    return this.cloneReport$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
          >
        ) =>
          r.body as
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
      )
    );
  }

  /**
   * Path part for operation runReportFinalAbstracts
   */
  static readonly RunReportFinalAbstractsPath = "/api/v1/report/finalAbstracts";

  /**
   * Run a report as specified by the inputs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runReportFinalAbstracts()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  runReportFinalAbstracts$Response(params?: {
    body?: RequestModel;
  }): Observable<StrictHttpResponse<OApiRespReportResultDtoModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.RunReportFinalAbstractsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespReportResultDtoModel>;
        })
      );
  }

  /**
   * Run a report as specified by the inputs.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runReportFinalAbstracts$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  runReportFinalAbstracts(params?: {
    body?: RequestModel;
  }): Observable<OApiRespReportResultDtoModel> {
    return this.runReportFinalAbstracts$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespReportResultDtoModel>) =>
          r.body as OApiRespReportResultDtoModel
      )
    );
  }

  /**
   * Path part for operation getReportFinalAbstractQueries
   */
  static readonly GetReportFinalAbstractQueriesPath =
    "/api/v1/report/finalAbstracts/query";

  /**
   * Get the report queries for current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportFinalAbstractQueries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportFinalAbstractQueries$Response(params: {
    view: "FULL" | "MEDIUM" | "MINIMAL" | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiMediumReportQueryViewModel
      | OApiRespMultiMinimalReportQueryViewModel
      | OApiRespMultiFullReportQueryViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.GetReportFinalAbstractQueriesPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiMediumReportQueryViewModel
            | OApiRespMultiMinimalReportQueryViewModel
            | OApiRespMultiFullReportQueryViewModel
          >;
        })
      );
  }

  /**
   * Get the report queries for current user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReportFinalAbstractQueries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportFinalAbstractQueries(params: {
    view: "FULL" | "MEDIUM" | "MINIMAL" | "NONE";
  }): Observable<
    | OApiRespMultiMediumReportQueryViewModel
    | OApiRespMultiMinimalReportQueryViewModel
    | OApiRespMultiFullReportQueryViewModel
  > {
    return this.getReportFinalAbstractQueries$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiMediumReportQueryViewModel
            | OApiRespMultiMinimalReportQueryViewModel
            | OApiRespMultiFullReportQueryViewModel
          >
        ) =>
          r.body as
            | OApiRespMultiMediumReportQueryViewModel
            | OApiRespMultiMinimalReportQueryViewModel
            | OApiRespMultiFullReportQueryViewModel
      )
    );
  }

  /**
   * Path part for operation saveReportFinalAbstractQuery
   */
  static readonly SaveReportFinalAbstractQueryPath =
    "/api/v1/report/finalAbstracts/query";

  /**
   * Create a report query.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveReportFinalAbstractQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveReportFinalAbstractQuery$Response(params: {
    body: OApiReqCreateReportDtoModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMediumReportQueryViewModel
      | OApiRespMinimalReportQueryViewModel
      | OApiRespFullReportQueryViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.SaveReportFinalAbstractQueryPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
          >;
        })
      );
  }

  /**
   * Create a report query.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveReportFinalAbstractQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveReportFinalAbstractQuery(params: {
    body: OApiReqCreateReportDtoModel;
  }): Observable<
    | OApiRespMediumReportQueryViewModel
    | OApiRespMinimalReportQueryViewModel
    | OApiRespFullReportQueryViewModel
  > {
    return this.saveReportFinalAbstractQuery$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
          >
        ) =>
          r.body as
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
      )
    );
  }

  /**
   * Path part for operation deleteReportQueries
   */
  static readonly DeleteReportQueriesPath =
    "/api/v1/deleteBatch/report/finalAbstracts/query";

  /**
   * Delete list of report queries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteReportQueries()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteReportQueries$Response(params: {
    body: OApiDeleteRequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.DeleteReportQueriesPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Delete list of report queries.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteReportQueries$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteReportQueries(params: {
    body: OApiDeleteRequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.deleteReportQueries$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation getReportFinalAbstractQuery
   */
  static readonly GetReportFinalAbstractQueryPath =
    "/api/v1/report/finalAbstracts/query/{reportQueryUid}";

  /**
   * Get the definition of the specified report query (not running it).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportFinalAbstractQuery()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportFinalAbstractQuery$Response(params: {
    view: "FULL" | "MEDIUM" | "MINIMAL" | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMediumReportQueryViewModel
      | OApiRespMinimalReportQueryViewModel
      | OApiRespFullReportQueryViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.GetReportFinalAbstractQueryPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
          >;
        })
      );
  }

  /**
   * Get the definition of the specified report query (not running it).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReportFinalAbstractQuery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportFinalAbstractQuery(params: {
    view: "FULL" | "MEDIUM" | "MINIMAL" | "NONE";
  }): Observable<
    | OApiRespMediumReportQueryViewModel
    | OApiRespMinimalReportQueryViewModel
    | OApiRespFullReportQueryViewModel
  > {
    return this.getReportFinalAbstractQuery$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
          >
        ) =>
          r.body as
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
      )
    );
  }

  /**
   * Path part for operation editReportQuery
   */
  static readonly EditReportQueryPath =
    "/api/v1/report/finalAbstracts/query/{reportQueryUid}";

  /**
   * Edit a report query.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editReportQuery()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editReportQuery$Response(params: {
    view: "FULL" | "MEDIUM" | "MINIMAL" | "NONE";
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMediumReportQueryViewModel
      | OApiRespMinimalReportQueryViewModel
      | OApiRespFullReportQueryViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.EditReportQueryPath,
      "put"
    );
    if (params) {
      rb.query("view", params.view, {});
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
          >;
        })
      );
  }

  /**
   * Edit a report query.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editReportQuery$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  editReportQuery(params: {
    view: "FULL" | "MEDIUM" | "MINIMAL" | "NONE";
    body?: RequestModel;
  }): Observable<
    | OApiRespMediumReportQueryViewModel
    | OApiRespMinimalReportQueryViewModel
    | OApiRespFullReportQueryViewModel
  > {
    return this.editReportQuery$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
          >
        ) =>
          r.body as
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
      )
    );
  }

  /**
   * Path part for operation deleteReportQuery
   */
  static readonly DeleteReportQueryPath =
    "/api/v1/report/finalAbstracts/query/{reportQueryUid}";

  /**
   * Delete a report query.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteReportQuery()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReportQuery$Response(params: {
    view: "FULL" | "MEDIUM" | "MINIMAL" | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMediumReportQueryViewModel
      | OApiRespMinimalReportQueryViewModel
      | OApiRespFullReportQueryViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.DeleteReportQueryPath,
      "delete"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
          >;
        })
      );
  }

  /**
   * Delete a report query.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteReportQuery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReportQuery(params: {
    view: "FULL" | "MEDIUM" | "MINIMAL" | "NONE";
  }): Observable<
    | OApiRespMediumReportQueryViewModel
    | OApiRespMinimalReportQueryViewModel
    | OApiRespFullReportQueryViewModel
  > {
    return this.deleteReportQuery$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
          >
        ) =>
          r.body as
            | OApiRespMediumReportQueryViewModel
            | OApiRespMinimalReportQueryViewModel
            | OApiRespFullReportQueryViewModel
      )
    );
  }

  /**
   * Path part for operation export2
   */
  static readonly Export2Path =
    "/api/v1/report/finalAbstracts/export/{reportQueryUid}";

  /**
   * Export a report query.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export2$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  export2$Pdf$Response(params?: {}): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.Export2Path,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/pdf",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export a report query.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export2$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  export2$Pdf(params?: {}): Observable<any> {
    return this.export2$Pdf$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Export a report query.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export2$VndOpenxmlformatsOfficedocumentWordprocessingmlDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  export2$VndOpenxmlformatsOfficedocumentWordprocessingmlDocument$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.Export2Path,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export a report query.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export2$VndOpenxmlformatsOfficedocumentWordprocessingmlDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  export2$VndOpenxmlformatsOfficedocumentWordprocessingmlDocument(params?: {}): Observable<any> {
    return this.export2$VndOpenxmlformatsOfficedocumentWordprocessingmlDocument$Response(
      params
    ).pipe(map((r: StrictHttpResponse<any>) => r.body as any));
  }

  /**
   * Export a report query.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export2$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method doesn't expect any request body.
   */
  export2$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params?: {}): Observable<
    StrictHttpResponse<any>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.Export2Path,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export a report query.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export2$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  export2$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(params?: {}): Observable<any> {
    return this.export2$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(
      params
    ).pipe(map((r: StrictHttpResponse<any>) => r.body as any));
  }

  /**
   * Export a report query.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export2$Csv()` instead.
   *
   * This method doesn't expect any request body.
   */
  export2$Csv$Response(params?: {}): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.Export2Path,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "text/csv",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export a report query.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export2$Csv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  export2$Csv(params?: {}): Observable<any> {
    return this.export2$Csv$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getReportFields
   */
  static readonly GetReportFieldsPath = "/api/v1/report/reportFieldNames";

  /**
   * Get the allowed fields which can be used for generating a report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportFields()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportFields$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespReportFieldNamesResponseDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.GetReportFieldsPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespReportFieldNamesResponseDtoModel>;
        })
      );
  }

  /**
   * Get the allowed fields which can be used for generating a report.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReportFields$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportFields(params?: {}): Observable<OApiRespReportFieldNamesResponseDtoModel> {
    return this.getReportFields$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespReportFieldNamesResponseDtoModel>) =>
          r.body as OApiRespReportFieldNamesResponseDtoModel
      )
    );
  }

  /**
   * Path part for operation runReportFinalAbstractQuery
   */
  static readonly RunReportFinalAbstractQueryPath =
    "/api/v1/report/finalAbstracts/queryRun/{reportQueryUid}";

  /**
   * Get the result of actually running the reportQuery.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runReportFinalAbstractQuery()` instead.
   *
   * This method doesn't expect any request body.
   */
  runReportFinalAbstractQuery$Response(params?: {}): Observable<
    StrictHttpResponse<OApiRespMultiReportResponseEntryDtoModel>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReportService.RunReportFinalAbstractQueryPath,
      "get"
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMultiReportResponseEntryDtoModel>;
        })
      );
  }

  /**
   * Get the result of actually running the reportQuery.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runReportFinalAbstractQuery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  runReportFinalAbstractQuery(params?: {}): Observable<OApiRespMultiReportResponseEntryDtoModel> {
    return this.runReportFinalAbstractQuery$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMultiReportResponseEntryDtoModel>) =>
          r.body as OApiRespMultiReportResponseEntryDtoModel
      )
    );
  }
}
