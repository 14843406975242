<div>
  <div class="clearfix text-left" mat-dialog-title>
    <button
      type="button"
      class="close"
      (click)="onDismissModal()"
      aria-hidden="true"
    >
      <i class="pg pg-close fs-14"></i>
    </button>
    <h5>{{ payload?.selectedProvision?.name }}</h5>
  </div>
  <div class="modal-body mt-4" mat-dialog-content style="min-width: 400px">
    <il-dynamic-table
      (change)="onModelChange($event)"
      [model]="payload.value"
      [editable]="payload.editable"
      #dynamicTable
      [minimumColumns]="1"
      [minimumRows]="2"
    ></il-dynamic-table>
  </div>
  <div mat-dialog-actions>
    <button
      type="button"
      mat-raised-button
      class="pull-right inline"
      (click)="onSaveClick()"
    >
      Close
    </button>
  </div>
</div>
