import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromCalendar from "./calendar.reducer";
import { CalendarEffects } from "./calendar.effects";
import { CalendarFacade } from "./calendar.facade";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCalendar.CALENDAR_FEATURE_KEY,
      fromCalendar.reducer
    ),
    EffectsModule.forFeature([CalendarEffects]),
  ],
  providers: [CalendarFacade],
})
export class AppStateCalendarModule {}
