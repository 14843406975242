import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
import {
  UploadData,
  UploadDataStatus,
  UploadingBoxService,
  UploadRecordData,
} from "@@intelease/web/ui/src/lib/itls-new-upload/services/uploading-box.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonModalService } from "@common/services";
import { ModalInputModel } from "@intelease/models";
import { finalize } from "rxjs/operators";
import { ModalsResponseTypeEnum } from "@intelease/enums";
import { Router } from "@angular/router";
import { LightNodeModel } from "@common/models";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive.service";
import { DriveFacade } from "@@intelease/app-state/drive/src";

@Component({
  selector: "il-uploading-box",
  templateUrl: "./uploading-box.component.html",
  styleUrls: ["./uploading-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadingBoxComponent implements OnDestroy {
  subscription: Subscription = new Subscription();
  isMinimized = false;
  isLoading = true;
  items: UploadData[] = [];
  UploadDataStatus = UploadDataStatus;
  lastIndexToUpload = 0;
  private isUploading = false;
  private isClosingDialog = false;
  private currentUploadingRecordUid: string;
  private currentSubscription: Subscription;
  private isCancelingUpload = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private modalData: UploadData,
    private uploadingBoxService: UploadingBoxService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private commonModalService: CommonModalService,
    private itlsDriveService: ItlsDriveService,
    private driveFacade: DriveFacade
  ) {
    this.items.push(this.modalData);
    this.subscription.add(
      this.uploadingBoxService.$onNewUpload.subscribe((newUploadData) => {
        this.items.push(newUploadData);
        this.uploadNextRecord();
        this.cdr.detectChanges();
      })
    );
    this.uploadNextRecord();
  }

  @HostListener("window:beforeunload", ["$event"])
  doSomething($event) {
    if (this.isUploading) {
      $event.returnValue =
        "Some records are still uploading. This action may cancel these uploads.";
    }
  }

  private uploadNextRecord() {
    if (this.isUploading || this.isClosingDialog) {
      return;
    }

    // check to see if we have any upload in QUEUED
    if (this.lastIndexToUpload !== this.items.length) {
      // start uploading records 1 by 1 (change this later so we can upload X records at the same time)
      const item = this.items[this.lastIndexToUpload];
      let uploadRecordData: UploadRecordData;
      if (item.isFolder) {
        uploadRecordData = item.records.find(
          (record) => record.status === UploadDataStatus.UPLOADING
        );
        if (!uploadRecordData) {
          console.log(
            `ERROR: why we couldn't find a record with UPLOADING status!\n\t` +
              JSON.stringify(item)
          );
          this.lastIndexToUpload += 1;
          return this.uploadNextRecord();
        }
      } else {
        uploadRecordData = item.record;
      }
      // the record is cancelled (it shouldn't be in other states), continue to next item in the queue
      if (uploadRecordData.status !== UploadDataStatus.UPLOADING) {
        this.lastIndexToUpload += 1;
        return this.uploadNextRecord;
      }

      this.isUploading = true;
      this.currentUploadingRecordUid = uploadRecordData.uid;
      this.currentSubscription = this.uploadingBoxService
        .uploadLeaseFiles(uploadRecordData.uid, uploadRecordData.files)
        .pipe(
          finalize(() => {
            this.driveFacade.updateRecordStatus({
              recordUid: this.currentUploadingRecordUid,
              status: "PROCESSING",
            });
            this.isUploading = false;
            this.currentUploadingRecordUid = undefined;
            this.currentSubscription = undefined;
            if (this.isCancelingUpload) {
              this.isCancelingUpload = false;
            } else {
              this.uploadNextRecord();
            }
            this.cdr.detectChanges();
          })
        )
        .subscribe({
          next: (resp) => {
            if (item.isFolder) {
              uploadRecordData.parentFolderUid = resp.parentDirectoryUid;
            }
            this.updateStatus(
              uploadRecordData.uid,
              item,
              UploadDataStatus.COMPLETED
            );
          },
          error: (error) => {
            this.updateStatus(
              uploadRecordData.uid,
              item,
              UploadDataStatus.CANCELED
            );
          },
        });
      this.subscription.add(this.currentSubscription);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onToggleMinimized() {
    this.isMinimized = !this.isMinimized;
    this.cdr.detectChanges();
  }

  onCloseClicked() {
    // do not close the dialog if we have a record in UPLOADING state
    if (
      this.items.every((item) =>
        item.isFolder
          ? item.folderStatus !== UploadDataStatus.UPLOADING
          : item.record.status !== UploadDataStatus.UPLOADING
      )
    ) {
      this.closeDialog();
    } else {
      const modalData = new ModalInputModel();
      modalData.payload = {
        customMessage: false,
        message:
          "Some records are still uploading, do you want to cancel them?",
        title: "Cancel Record Uploading",
      };
      // confirm with User to stop record(s) in UPLOADING state
      this.commonModalService
        .openGenericOkCancelModal(modalData)
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed?.type === ModalsResponseTypeEnum.CLOSE) {
            this.closeDialog();
          }
        });
    }
  }

  private closeDialog() {
    this.isClosingDialog = true;
    this.uploadingBoxService.close();
  }

  private updateStatus(
    recordUid: string,
    item: UploadData,
    newStatus: UploadDataStatus,
    shouldContinueToNextTimeInTheQueue = true
  ) {
    if (item.isFolder) {
      const foundedRecord = item.records.find(
        (record) => record.uid === recordUid
      );
      foundedRecord.status = newStatus;
      if (
        !item.records.every(
          (record) => record.status !== UploadDataStatus.UPLOADING
        )
      ) {
        // if we still have at-least 1 record in UPLOADING
        item.folderStatus = UploadDataStatus.UPLOADING;
      } else if (
        item.records.every(
          (record) => record.status === UploadDataStatus.CANCELED
        )
      ) {
        // if all records are canceled
        item.folderStatus = UploadDataStatus.CANCELED;
        this.lastIndexToUpload += 1;
      } else if (
        item.records.every(
          (record) => record.status === UploadDataStatus.COMPLETED
        )
      ) {
        // if all records are canceled
        item.folderStatus = UploadDataStatus.COMPLETED;
        if (shouldContinueToNextTimeInTheQueue) {
          this.lastIndexToUpload += 1;
        }
      } else {
        // if at-least 1 record is completed
        item.folderStatus = UploadDataStatus.PARTIALLY_COMPLETED;
        if (shouldContinueToNextTimeInTheQueue) {
          this.lastIndexToUpload += 1;
        }
      }
    } else {
      const record = item.record;
      record.status = newStatus;
      if (shouldContinueToNextTimeInTheQueue) {
        this.lastIndexToUpload += 1;
      }
    }
  }

  onUploadedDataChildRecordClicked(record: UploadRecordData) {
    if (record.status === UploadDataStatus.COMPLETED) {
      const url = `/abstract-review/${record.uid}`;
      this.router.navigateByUrl(url);
    }
  }

  onUploadedDataClicked(item: UploadData) {
    const isCompleted = item.isFolder
      ? item.folderStatus === UploadDataStatus.COMPLETED ||
        item.folderStatus === UploadDataStatus.PARTIALLY_COMPLETED
      : item.record.status === UploadDataStatus.COMPLETED;
    if (isCompleted) {
      if (item.isFolder) {
        const targetNode: LightNodeModel = {
          uid: item.folderUid,
          type: "DIRECTORY",
        };
        this.navigateToDrivePanel(item.parentFolderUid, targetNode);
      } else {
        const url = `/abstract-review/${item.record.uid}`;
        this.router.navigateByUrl(url);
      }
    }
  }

  private navigateToDrivePanel(
    parentFolderUid: string,
    targetNode: LightNodeModel
  ) {
    this.subscription.add(
      this.itlsDriveService
        .getOffsetInSearchResult(parentFolderUid, targetNode)
        .subscribe((resp) => {
          const queryParams = {
            targetNodeOffset: resp.offset,
          };
          if (parentFolderUid) {
            this.router.navigate(["/drive/folders/" + parentFolderUid], {
              queryParams,
            });
          } else {
            this.router.navigate(["/drive/owned"], {
              queryParams,
            });
          }
        })
    );
  }

  onCancelUpload(item: UploadData, records: UploadRecordData[]) {
    // confirm cancellation
    const modalData = new ModalInputModel();
    modalData.payload = {
      isHtmlMessage: true,
      message:
        "Are you sure you want to cancel this upload?<br>" +
        "<br>" +
        "  Note: The record(s) may still show up as Uploading status for a few minutes.",
      title: "Cancel Record(s) Uploading",
    };
    this.commonModalService
      .openGenericOkCancelModal(modalData, {})
      .afterClosed()
      .subscribe((result) => {
        if (result?.type === ModalsResponseTypeEnum.CLOSE) {
          // User could have been wait a long time and some of the selected records might not be with UPLOADING status
          const stillUploadingRecords = records.filter(
            (record) => record.status === UploadDataStatus.UPLOADING
          );
          if (stillUploadingRecords.length > 0) {
            this.cancelUpload(item, stillUploadingRecords);
          }
        }
      });
  }

  cancelUpload(item: UploadData, records: UploadRecordData[]) {
    // check and possibly cancel current upload
    let isCancellingCurrentUpload;
    let timeoutToCallCancelApi;
    if (
      !records.every((record) => record.uid !== this.currentUploadingRecordUid)
    ) {
      timeoutToCallCancelApi = 100;
      isCancellingCurrentUpload = true;
      this.isCancelingUpload = true;
      this.currentSubscription.unsubscribe();
    } else {
      timeoutToCallCancelApi = 0;
      isCancellingCurrentUpload = false;
    }

    // use timeout to avoid concurrency issue (we don't want to call "this.uploadNextRecord()" at the same time)
    setTimeout(() => {
      this.subscription.add(
        this.uploadingBoxService
          .cancelUploadingLeaseFiles(records.map((record) => record.uid))
          .pipe(
            finalize(() => {
              this.uploadNextRecord();
              this.cdr.detectChanges();
            })
          )
          .subscribe((resp) => {
            records.forEach((record) => {
              if (resp.recordUids.includes(record.uid)) {
                this.updateStatus(
                  record.uid,
                  item,
                  UploadDataStatus.CANCELED,
                  isCancellingCurrentUpload
                );
              }
            });
          })
      );
    }, timeoutToCallCancelApi);
  }
}
