<div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 1 auto">
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    [style.margin-right]="isUploadingFolder ? '12px' : null"
  >
    <label
      class="document-set-name-label"
      [ngStyle]="{
        'margin-top': isUploadingFolder && recordIndex !== 0 ? '18px' : null
      }"
      >Document set {{ isUploadingFolder ? recordIndex + 1 : "Name" }}</label
    >
    <input
      type="text"
      class="name-input"
      [(ngModel)]="uploadedDocSet.name"
      (focusout)="onRecordNameChanged()"
    />
  </div>
  <div
    style="margin-top: 15px"
    [style.overflow-y]="isUploadingFolder ? null : 'auto'"
    [style.max-height]="
      isUploadingFolder ? null : verticalView ? '105px' : '145px'
    "
  >
    <div>
      <table
        class="table"
        id="selectedFileListTable"
        cdkDropList
        (cdkDropListDropped)="onDropDocument($event)"
        [cdkDropListData]="this.uploadedDocSet.myFiles"
      >
        <tbody>
          <tr
            *ngFor="let item of this.uploadedDocSet.myFiles; let i = index"
            cdkDrag
          >
            <td>
              <div class="number-container">
                <span>{{ i + 1 }}</span>
              </div>
            </td>
            <td>
              <div fxLayout="row" fxLayoutAlign="start center">
                <div
                  fxLayout="column"
                  fxLayoutAlign="start stretch"
                  fxFlex="1 1 auto"
                >
                  <input
                    type="text"
                    class="name-input"
                    [style.paddingBottom]="item.inProgress ? '2px' : null"
                    [(ngModel)]="item.name"
                    (focusout)="onDocumentNameChanged(item)"
                  />
                  <mat-progress-bar
                    mode="determinate"
                    class="selected-file-progress-bar"
                    *ngIf="item.inProgress"
                    [value]="item.progress"
                  >
                  </mat-progress-bar>
                </div>
              </div>
            </td>
            <td class="text-right" style="width: 24px">
              <itls-icon
                class="icon-remove"
                svgIcon="icon-remove"
                (click)="onRemoveFile(i)"
              ></itls-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
