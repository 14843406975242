/* tslint:disable */
/* eslint-disable */
import { ErrorDataModel } from "./error-data-model";
import { OApiMultiEntityContainerResponseOptionRollingReminderItemModel } from "./o-api-multi-entity-container-response-option-rolling-reminder-item-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OApiRespMultiOptionRollingReminderItemModel")
export class OApiRespMultiOptionRollingReminderItemModel {
  @JsonProperty(
    "data",
    OApiMultiEntityContainerResponseOptionRollingReminderItemModel,
    true
  )
  data?: OApiMultiEntityContainerResponseOptionRollingReminderItemModel = undefined;

  @JsonProperty("devErrors", [ErrorDataModel], true)
  devErrors?: Array<ErrorDataModel> = [];

  @JsonProperty("errors", [ErrorDataModel], true)
  errors?: Array<ErrorDataModel> = [];

  @JsonProperty("message", String, true)
  message?: string = "";
}
