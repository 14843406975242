/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("OldPermissionViewModel")
export class OldPermissionViewModel {
  @JsonProperty("abstractUid", String, true)
  abstractUid?: string = "";

  @JsonProperty("creationDate", DateTimeConvertService, true)
  creationDate?: Date | undefined = undefined;

  @JsonProperty("expirationDate", DateTimeConvertService, true)
  expirationDate?: Date | undefined = undefined;

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("permissionType", String, true)
  permissionType?: string = "";

  @JsonProperty("reason", String, true)
  reason?: string = "";

  @JsonProperty("userUid", String, true)
  userUid?: string = "";
}
