import { GoogleDriveInfoViewModel } from "@@intelease/api-models/adex-api-model-src";
import { ServerConfigsModel } from "@@intelease/app-models/common";
import { LocalStorageKey } from "@common/enums/local-storage.keys";

export class LocalStorageService {
  public static clear(): void {
    localStorage.removeItem(LocalStorageKey.SERVER_CONFIGS);
  }

  static storeServerConfigs(serverConfigs: ServerConfigsModel): void {
    localStorage.setItem(
      LocalStorageKey.SERVER_CONFIGS,
      JSON.stringify(serverConfigs)
    );
  }

  static getServerConfigs(): ServerConfigsModel {
    const serverConfigs = localStorage.getItem(LocalStorageKey.SERVER_CONFIGS);
    if (serverConfigs) {
      return JSON.parse(serverConfigs);
    }
    return undefined;
  }

  static setGoogleDriveViewInfo(folder: GoogleDriveInfoViewModel) {
    localStorage.setItem(
      LocalStorageKey.GOOGLE_DRIVE_INFO,
      JSON.stringify(folder)
    );
  }

  static getGoogleDriveViewInfo(): GoogleDriveInfoViewModel {
    const googleDriveInfoView = localStorage.getItem(
      LocalStorageKey.GOOGLE_DRIVE_INFO
    );
    return JSON.parse(googleDriveInfoView);
  }

  static clearGoogleDriveViewInfo() {
    localStorage.removeItem(LocalStorageKey.GOOGLE_DRIVE_INFO);
  }
}
