import { Injectable } from "@angular/core";
import { DeleteApiService } from "@@intelease/web/intelease/services";
import { Observable } from "rxjs";
import { ServerResponseModel } from "@@intelease/web/intelease/models";

@Injectable({
  providedIn: "root",
})
export class DeleteSnippetService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly PROVISIONS_URL = "/provisions";
  private static readonly SNIPPET_URL = "/snippets";

  constructor(private deleteApiService: DeleteApiService) {}

  deleteSnippet(
    abstractUid: string,
    provisionUid: string,
    snippetUid: string
  ): Observable<ServerResponseModel> {
    return this.deleteApiService.sendRequestNoView(
      DeleteSnippetService.API_VERSION_1,
      `${DeleteSnippetService.FINAL_DOC_SETS_URL}/${abstractUid}` +
        `${DeleteSnippetService.PROVISIONS_URL}/${provisionUid}${DeleteSnippetService.SNIPPET_URL}`,
      snippetUid
    );
  }
}
