/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("FullStateViewModel")
export class FullStateViewModel {
  /**
   * the full name of the country containing this state
   */

  @JsonProperty("country", String, true)
  country?: string = "";

  /**
   * the full name of this state
   */

  @JsonProperty("name", String, true)
  name?: string = "";
}
