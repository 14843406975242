<!--<div-->
<!--  class="comment-item"-->
<!--  *ngFor="let comment of comments; let i = index"-->
<!--  [style.borderBottomWidth]="-->
<!--                    i < comments.length - 1 ? '1px' : '0'-->
<!--                "-->
<!--&gt;-->
<!--    <img-->
<!--      src="/assets/images/user.png"-->
<!--      alt=""-->
<!--      matTooltipClass="multi-line-tooltip"-->
<!--      [matTooltip]="-->
<!--                        'Name: ' +-->
<!--                        comment?.createdBy?.fullName +-->
<!--                        '\n Email: ' +-->
<!--                        comment?.createdBy?.email-->
<!--                    "-->
<!--    />-->
<!--    <div>-->
<!--                    <span-->
<!--                      class="user"-->
<!--                      matTooltipClass="multi-line-tooltip"-->
<!--                      [matTooltip]="-->
<!--                            'Name: ' +-->
<!--                            comment?.createdBy?.fullName +-->
<!--                            '\n Email: ' +-->
<!--                            comment?.createdBy?.email-->
<!--                        "-->
<!--                    >{{ comment?.createdBy?.fullName }}</span-->
<!--                    >-->
<!--        <span class="text" [innerHTML]="comment?.text"></span>-->
<!--        <span class="date">{{-->
<!--            comment?.createdAt | date: 'medium'-->
<!--            }}</span>-->
<!--    </div>-->
<!--</div>-->

<div class="root-container" fxLayout="column" fxLayoutAlign="center">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div style="padding-right: 6px">
      <span class="commenter-text-img" fxFlex="1 1" *ngIf="!profileImageSrc">{{
        commenterNameFirstCharacter
      }}</span>
      <img
        class="commenter-img"
        fxFlex="1 1"
        *ngIf="profileImageSrc"
        [src]="profileImageSrc"
        alt="profile image"
      />
    </div>
    <div fxLayout="column" fxLayoutAlign="center start">
      <span class="commenter-name">{{ comment.createdBy.name }}</span>
      <span class="comment-date">{{
        comment?.createdAt | date: "MMM d 'at' h:mm a"
      }}</span>
    </div>
    <button
      mat-icon-button
      disableRipple
      class="more-btn"
      *ngIf="canEdit"
      [matMenuTriggerFor]="editCommentMenu"
    >
      <mat-icon svgIcon="icon-more-vertical"></mat-icon>
    </button>
    <mat-menu #editCommentMenu="matMenu" xPosition="before">
      <button mat-menu-item (click)="onEditComment()">Edit</button>
      <button mat-menu-item (click)="onDeleteComment()">Remove</button>
    </mat-menu>
  </div>
  <p
    *ngIf="!editing"
    class="comment-rich-text"
    [innerHTML]="commentRichText | safeHTML"
  ></p>
  <div
    *ngIf="editing"
    class="comment-edit-text-container"
    style="background-color: #f5f6f7"
  >
    <il-comment-input
      [mentionList]="mentionList"
      [(model)]="mentionModel"
      [id]="comment.uid"
      [placeholder]="'Change comment text'"
    >
    </il-comment-input>
    <!--        <textarea-->
    <!--            matInput-->
    <!--            cdkTextareaAutosize-->
    <!--            aria-label="Change comment text"-->
    <!--            class="comment-edit-text"-->
    <!--            (focusout)="onCommentTextFocusOut($event)"-->
    <!--            [(ngModel)]="commentText"-->
    <!--        ></textarea>-->
    <!--        <button-->
    <!--            type="button"-->
    <!--            class="comment-mentions-btn"-->
    <!--            [matMenuTriggerFor]="userMentionMenu"-->
    <!--            (menuOpened)="onUserMentionMenuOpened()"-->
    <!--        >-->
    <!--            @-->
    <!--        </button>-->
    <mat-menu #userMentionMenu="matMenu" xPosition="before">
      <div style="max-height: 250px">
        <input
          aria-label="search users to mention them in comment"
          mat-menu-item
          type="text"
          style="width: 100%"
          [(ngModel)]="userMentionMenuSearchInput"
          (ngModelChange)="onSearchInputChanged()"
        />
        <mat-option
          *ngFor="let filteredAccountUser of filteredAccountUsers"
          (onSelectionChange)="onMentionUserSelected(filteredAccountUser)"
        >
          <span
            >{{ filteredAccountUser.name }}
            <span style="font-size: 10px"
              >({{ filteredAccountUser.email }})</span
            ></span
          >
        </mat-option>
      </div>
    </mat-menu>
  </div>
  <div
    *ngIf="editing"
    fxLayout="row"
    fxLayoutAlign="end center"
    class="footer-actions"
  >
    <button
      mat-flat-button
      disableRipple
      class="mr-2"
      (click)="onEditCommentCancel()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      disableRipple
      color="primary"
      (click)="onEditCommentSave()"
      [disabled]="mentionModel.elements.length === 0"
    >
      Save
    </button>
  </div>
</div>
