import { EditApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { Observable } from "rxjs";
import {
  AbstractReviewOperationModeEnum,
  AbstractReviewOperationModeModel,
} from "@@intelease/app-models/abstract-review";
import { RestClient } from "@@intelease/web/utils";

@Injectable({
  providedIn: "root",
})
export class EditAbstractService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly NAME_URL = "/name";
  private static readonly STATUS_URL = "/status";
  private static readonly ABSTRACTOR_CODE_URL = "/abstractorCode";

  constructor(
    private editApiService: EditApiService,
    private restClient: RestClient
  ) {}

  saveAbstractNoView(
    abstractUid: string,
    abstractName: string,
    deselectIncomplete: boolean,
    status?: string
  ): Observable<ServerResponseModel> {
    let data;
    if (status) {
      data = {
        abstractName: abstractName,
        deselectIncomplete: deselectIncomplete,
        status: status,
      };
    } else {
      data = {
        abstractName: abstractName,
        deselectIncomplete: deselectIncomplete,
      };
    }
    return this.editApiService.sendRequestNoView(
      EditAbstractService.API_VERSION_1,
      EditAbstractService.FINAL_DOC_SETS_URL,
      abstractUid,
      data,
      false,
      EditAbstractService.NAME_URL
    );
  }

  saveAbstractNoResponse(
    abstractUid: string,
    abstractName: string,
    deselectIncomplete: boolean
  ): void {
    const data = {
      abstractName: abstractName,
      deselectIncomplete: deselectIncomplete,
    };
    this.editApiService.sendRequestNoResponse(
      EditAbstractService.API_VERSION_1,
      EditAbstractService.FINAL_DOC_SETS_URL,
      abstractUid,
      data,
      false,
      EditAbstractService.NAME_URL
    );
  }

  setStatusNoResponse(status: string, abstractUid: string): void {
    const data = {
      status: status,
    };
    this.editApiService.sendRequestNoResponse(
      EditAbstractService.API_VERSION_1,
      EditAbstractService.FINAL_DOC_SETS_URL,
      abstractUid,
      data,
      false,
      EditAbstractService.STATUS_URL
    );
  }

  setReviewOperationModeNoResponse(
    reviewOperationMode: AbstractReviewOperationModeEnum,
    abstractUid: string
  ): Observable<AbstractReviewOperationModeModel> {
    const reqBody = {
      data: {
        reviewOperationMode,
      },
    };
    return this.restClient.sendPutRequest(
      EditAbstractService.API_VERSION_1,
      `${EditAbstractService.FINAL_DOC_SETS_URL}/${abstractUid}/reviewOperationMode`,
      reqBody,
      AbstractReviewOperationModeModel,
      {},
      (resp) => resp.data.reviewOperationMode
    );
  }

  setAbstractorCodeNoResponse(
    abstractorCode: string,
    abstractUid: string
  ): Observable<void> {
    const data = {
      abstractorCode: abstractorCode,
    };
    return this.editApiService.sendRequestNoResponseWithSubscribe(
      EditAbstractService.API_VERSION_1,
      EditAbstractService.FINAL_DOC_SETS_URL,
      abstractUid,
      data,
      false,
      EditAbstractService.ABSTRACTOR_CODE_URL
    );
  }
}
