import { Observable } from "rxjs";
import { CreateApiService } from "@@intelease/web/intelease/services";
import { Injectable } from "@angular/core";
// import { HighlightedProvisionValueModel } from '@@intelease/web/abstraction-page/src/lib/models/highlighted-provision-value.model';
import { ServerResponseModel } from "@@intelease/web/intelease/models";
import { AppHighlightTypeEnum } from "@@intelease/app-models/abstract-review";
import { DateTimeConvertService } from "../../../../../common/services";
import { ProvisionValueConvertService } from "../../../../../common/services/provision-value-convert.service";
import { ProvisionFormatValuesConvertService } from "../../../../../common/services/provision-format-values-convert.service";

@Injectable({
  providedIn: "root",
})
export class CreateProvisionValueService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly FINAL_DOC_SETS_URL = "/finalDocSets";
  private static readonly MANUAL_PROVISIONS_URL = "/manualProvisions";
  private static readonly TEMP_PROVISIONS_URL = "/tempProvisions";
  private static readonly HIGHLIGHTED_PROVISIONS_URL = "/highlightedProvisions";

  constructor(private createApiService: CreateApiService) {}

  /**
   * When user highlights some text on the PDF and chooses a provision,
   * call this API to retrieve a provision value to display for this provision,
   * as parsed from the highlighted text.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param highlightedText - the text that the user highlighted on the pdf
   * @param firstPage - the first page of the highlight - lowest value is 1
   * @param lastPage - the last page of the highlight (same as firstPage unless user's highlight spanned multiple pages) - lowest value is 1
   * @param docUid - the uid of the specific document on which user highlighted
   * @param provisionUid - the uid of the provision the user chose
   * @param pdfProvisionHighlightIds - the list of string ids for the highlights for this highlighted provision value (1 for each page)
   * @param pdfProvisionHighlights
   * @param view - the view of the individual mention
   * @param classRef - the class of the individual mention to return
   * @param numHead - TODO add description here
   * @param boundingBox
   * @param highlightType
   * @return the parsed provision value (else textValue, if unable to be parsed)
   */
  parseProvisionFromHighlight<T extends object>(
    abstractUid: string,
    highlightedText: string,
    firstPage: number,
    lastPage: number,
    docUid: string,
    provisionUid: string,
    pdfProvisionHighlightIds: string[],
    pdfProvisionHighlights: any[],
    view: string,
    classRef: new () => T,
    numHead?: number,
    boundingBox?,
    highlightType?: AppHighlightTypeEnum,
    primaryFormatOnly?: boolean
  ): Observable<T> {
    const data = {
      text: highlightedText,
      firstPage: firstPage,
      lastPage: lastPage,
      docAbstractUid: docUid,
      provisionUid: provisionUid,
      pdfProvisionHighlightIds: pdfProvisionHighlightIds,
      pdfProvisionHighlights,
      numHead,
      boundingBox,
      highlightType,
      primaryFormatOnly,
    };
    return this.createApiService.sendRequest(
      CreateProvisionValueService.API_VERSION_1,
      `${CreateProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}${CreateProvisionValueService.TEMP_PROVISIONS_URL}`,
      data,
      view,
      classRef
    );
  }

  /**
   * When user manually creates a provision value himself (when backend had found no provision value options automatically),
   * then call this API when user saves his new manual value. Don't call this if user got his value from highlight.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param docAbstractUid - the uid of the document the user was at when he entered in the value
   * @param page - the page the user was at when he entered in the value
   * @param provisionUid - the uid of the provision whose possible values we want to show the user
   * @param notes - the notes associated with this provision
   * @param sectionHeader - the section header associated with this provision
   * @param value - the new, user-created value for this provision (data model from provision-type directory)
   * @param textValue - the new, user-created text value for this provision
   * @param type
   * @return response from server
   */
  manualCreateProvisionValueOptionForReviewNoView(
    abstractUid: string,
    docAbstractUid: string,
    page: number,
    provisionUid: string,
    notes: string,
    sectionHeader: string,
    value: any,
    textValue: string,
    type: string
  ): Observable<ServerResponseModel> {
    let data;
    if (value) {
      data = {
        docAbstractUid: docAbstractUid,
        page: page,
        provisionUid: provisionUid,
        provisionValue:
          ProvisionValueConvertService.serializeProvisionValue(value),
        notes: notes,
        sectionHeader: sectionHeader,
        type,
      };
    } else {
      data = {
        docAbstractUid: docAbstractUid,
        page: page,
        provisionUid: provisionUid,
        notes: notes,
        sectionHeader: sectionHeader,
        textValue: textValue,
        type,
      };
    }
    return this.createApiService.sendRequestNoView(
      CreateProvisionValueService.API_VERSION_1,
      `${CreateProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}${CreateProvisionValueService.MANUAL_PROVISIONS_URL}`,
      data
    );
  }

  /**
   * In the upper-right provision box, when user selects a provision value whose {source
   * is "CUSTOMER_HIGHLIGHTED" or "CUSTOMER_HIGHLIGHTED_AND_MODIFY"} and which was unable to be parsed
   * from parseProvisionFromHighlight function (i.e. stored in textValue instead of value), then call this API.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value user is choosing
   * @param provisionValueUid - the uid of the provision value option the user is choosing
   * @param notes - the additional notes associated with this provision
   * @param sectionHeader - the section header associated with this provision
   * @param page - the first page of the pdf highlight for this value - lowest value is 1
   * @param highlightedText - the text that the user highlighted on the pdf
   * @param docUid - the uid of the specific document on which user highlighted
   * @param textValue - the provision textValue
   * @param pdfProvisionHighlights - list of objects (exactly from the Polar library)
   * @param indexInText
   * @param textSurroundingOriginalProvision
   * @param surroundingSentence
   * representing the highlight for this provision (1 for each page on which it's highlighted)
   * @return response from server
   */
  createHighlightedProvisionTextValueOptionNoView(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string,
    notes: string,
    sectionHeader: string,
    page: number,
    highlightedText: string,
    docUid: string,
    textValue: string,
    pdfProvisionHighlights: any[],
    indexInText: number,
    textSurroundingOriginalProvision: string,
    surroundingSentence: string
  ): Observable<ServerResponseModel> {
    const data = {
      provisionValueUid: provisionValueUid,
      notes: notes,
      sectionHeader: sectionHeader,
      page: page,
      text: highlightedText,
      docAbstractUid: docUid,
      provisionUid: provisionUid,
      provisionTextValue: textValue,
      pdfProvisionHighlights: pdfProvisionHighlights,
      indexInText,
      textSurroundingOriginalProvision,
      surroundingSentence,
    };
    return this.createApiService.sendRequestNoView(
      CreateProvisionValueService.API_VERSION_1,
      `${CreateProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}${CreateProvisionValueService.HIGHLIGHTED_PROVISIONS_URL}`,
      data
    );
  }

  /**
   * In the upper-right provision box, when user selects a provision value whose {source
   * is "CUSTOMER_HIGHLIGHTED" or "CUSTOMER_HIGHLIGHTED_AND_MODIFY"} and which was just obtained
   * from parseProvisionFromHighlight function, then call this API.
   *
   * @param abstractUid - the uid of the abstract (made from a set of related documents)
   * @param provisionUid - the uid of the provision whose value user is choosing
   * @param provisionValueUid - the uid of the provision value option the user is choosing
   * @param notes - the additional notes associated with this provision
   * @param sectionHeader - the section header associated with this provision
   * @param page - the first page of the pdf highlight for this value - lowest value is 1
   * @param highlightedText - the text that the user highlighted on the pdf
   * @param docUid - the uid of the specific document on which user highlighted
   * @param value - data model (from provision-type directory) representing the provision value
   * @param pdfProvisionHighlights - list of objects (exactly from the Polar library)
   * representing the highlight for this provision (1 for each page on which it's highlighted)
   * @param indexInText
   * @param textSurroundingOriginalProvision
   * @param surroundingSentence
   * @param type
   * @param formatValues
   * @param highlightType
   * @return response from server
   */
  createHighlightedProvisionValueOptionNoView(
    abstractUid: string,
    provisionUid: string,
    provisionValueUid: string,
    notes: string,
    sectionHeader: string,
    page: number,
    highlightedText: string,
    docUid: string,
    value: any,
    pdfProvisionHighlights: any[],
    indexInText: number,
    textSurroundingOriginalProvision: string,
    surroundingSentence: string,
    type: string,
    formatValues: any,
    highlightType: AppHighlightTypeEnum
  ): Observable<ServerResponseModel> {
    const data = {
      provisionValueUid: provisionValueUid,
      notes: notes,
      sectionHeader: sectionHeader,
      page: page,
      text: highlightedText,
      docAbstractUid: docUid,
      provisionUid: provisionUid,
      provisionValue:
        ProvisionValueConvertService.serializeProvisionValue(value),
      pdfProvisionHighlights: pdfProvisionHighlights,
      indexInText,
      textSurroundingOriginalProvision,
      surroundingSentence,
      type,
      formatValues:
        ProvisionFormatValuesConvertService.serializeFormatValues(formatValues),
      highlightType,
    };
    return this.createApiService.sendRequestNoView(
      CreateProvisionValueService.API_VERSION_1,
      `${CreateProvisionValueService.FINAL_DOC_SETS_URL}/${abstractUid}${CreateProvisionValueService.HIGHLIGHTED_PROVISIONS_URL}`,
      data
    );
  }
}
